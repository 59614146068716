import React from 'react';

import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';

import { LABEL_COMUNS, MODELO_ARQUIVO } from '../../../../util/contantes-comuns/constantes-comuns';
import ModalInstrucoesExemploCSV from './modal-instrucoes-exemplo-csv';
import ModalInstrucoesExemploCNAB from './modal-instrucoes-exemplo-cnab';

const ModalInstrucoesTransferenciaEmLote = ({ exibirModal, operacao, tipoArquivo, contextBppPag, fecharModal }) => {
    if (!tipoArquivo || !operacao) {
        return null;
    }

    const isCSV = tipoArquivo === MODELO_ARQUIVO.MODELO_BPP.tipoArquivo;

    return (
        <div>
            <ModalComponent
        bsSize="large"
        contextoBppPag={contextBppPag}
        exibirModal={exibirModal}
        name={isCSV ? 'mdExemploArquivoLoteCSV' : 'mdExemploArquivoLoteCNAB'}
        header={isCSV ? 'Exemplo de Arquivo .CSV' : 'Exemplo de Arquivo - CNAB'}
        body={
          getModalBody(tipoArquivo, operacao)
        }
        footer={
            <FooterBotoesModalComponent
            contextoBppPag={contextBppPag}
            parentClassName="row"
            cols="12 12 12 12"
            chieldClassName="alinhamento-direita"
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={fecharModal}
          />
        } />
        </div>
    );
};

const getModalBody = (tipoArquivo, operacao) => ({
    [MODELO_ARQUIVO.MODELO_BPP.tipoArquivo]: ModalInstrucoesExemploCSV(operacao),
    [MODELO_ARQUIVO.MODELO_CNAB.tipoArquivo]: ModalInstrucoesExemploCNAB(operacao)
})[tipoArquivo];

export default ModalInstrucoesTransferenciaEmLote;
