import React, { Component } from 'react';
import { Button, ControlLabel, FormGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import { STATUS_VALIDACAO_COMPONENTES, ESTILO } from '../../util/estilo_para_componentes/estiloUtil';
import { LABEL_COMUNS, MASCARA_CAMPOS } from '../../util/contantes-comuns/constantes-comuns';
import If from '../if/if';
import Grid from '../grid/grid';
import ExportarCSV from '../exportarCSV/exportarCSV';
import ShowLineError from '../../comuns/showLineError/show-line-error';

class FiltroComponent extends Component {

    render() {
        const corDeFundoParaBotao = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG : ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        return (
            <Grid>
                <div className="form-group">
                    <If test={this.props.exportarCsv}>
                        <div className="col-sm-1 col-md-1 col-lg-1 posicionamento_icone_2">
                            <ExportarCSV
                listaParaSerconvertidaEmCSV={this.props.listaParaSerconvertidaEmCSV}
                nomeDoArquivoCsv={this.props.nomeDoArquivoCsv} />
                        </div>
                    </If>
                    <Grid cols="2 2 2 2">
                        <ControlLabel>{this.props.dataInicialLabel}</ControlLabel>
                        <FormGroup validationState={this.props.dataInicialErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                mask="99/99/9999"
                maskChar=" "
                className="form-control mensagens-erro"
                id="dataInicial"
                name="dataInicial"
                placeholder={this.props.dataInicialPlaceHolder}
                value={this.props.dataInicialValue}
                onChange={this.props.dataIncialChange}
                style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                            {this.props.dataInicialErro ? <span className="mensagens-erro-span" role="alert">{this.props.dataInicialErro}</span> : null}
                        </FormGroup>
                    </Grid>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                        <ControlLabel>{this.props.dataFinalLabel ? this.props.dataFinalLabel : <span>&nbsp;</span>}</ControlLabel>
                        <FormGroup validationState={this.props.dataFinalErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                mask="99/99/9999"
                maskChar=" "
                className="form-control mensagens-erro"
                id="dataFinal"
                name="dataFinal"
                placeholder={this.props.dataFinalPlaceHolder}
                value={this.props.dataFinalValue}
                onChange={this.props.dataFinalChange}
                style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                            {this.props.dataFinalErro ? <span className="mensagens-erro-span" role="alert">{this.props.dataFinalErro}</span> : null}
                        </FormGroup>
                    </div>
                    <If test={this.props.exibirFiltroDeDatas2}>
                        <Grid cols="2 2 2 2">
                            <ControlLabel>{this.props.dataInicial2Label}</ControlLabel>
                            <FormGroup validationState={this.props.dataInicial2Erro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask
                  mask="99/99/9999"
                  maskChar=" "
                  className="form-control mensagens-erro"
                  id="dataInicial2"
                  name="dataInicial2"
                  placeholder={this.props.dataInicial2PlaceHolder}
                  value={this.props.dataInicial2}
                  onChange={this.props.setDataInicial2}
                  style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                                {this.props.dataInicial2Erro ? <span className="mensagens-erro-span" role="alert">{this.props.dataInicial2Erro}</span> : null}
                            </FormGroup>
                        </Grid>
                        <Grid cols="2 2 2 2">
                            <ControlLabel>{this.props.dataFinal2Label ? this.props.dataFinal2Label : <span>&nbsp;</span>}</ControlLabel>
                            <FormGroup validationState={this.props.dataFinal2Erro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask
                  mask="99/99/9999"
                  maskChar=" "
                  className="form-control mensagens-erro"
                  id="dataFinal2"
                  name="dataFinal2"
                  placeholder={this.props.dataFinal2PlaceHolder}
                  value={this.props.dataFinal2}
                  onChange={this.props.setDataFinal2}
                  style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                                {this.props.dataFinal2Erro ? <span className="mensagens-erro-span" role="alert">{this.props.dataFinal2Erro}</span> : null}
                            </FormGroup>
                        </Grid>
                    </If>
                    <If test={this.props.exibirFiltroDeValor}>
                        <Grid cols="2 2 2 2">
                            <div className="form-group">
                                <ControlLabel>{LABEL_COMUNS.VALOR_DE}</ControlLabel>
                                <CurrencyInput decimalSeparator="," thousandSeparator="."
                  id="valorDe" className="form-control mensagens-erro"
                  style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                  value={this.props.valorDe}
                  onChange={this.props.setValorDe} />
                            </div>
                        </Grid>
                        <Grid cols="2 2 2 2">
                            <div className="form-group">
                                <ControlLabel>{LABEL_COMUNS.VALOR_ATE}</ControlLabel>
                                <CurrencyInput decimalSeparator="," thousandSeparator="."
                  className="form-control mensagens-erro"
                  style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                  id="valorAte"
                  value={this.props.valorAte}
                  onChange={this.props.setValorAte} />
                            </div>
                        </Grid>
                    </If>
                    <If test={this.props.mostrarFiltroDeIdNumerico}>
                        <Grid cols="3 3 3 3">
                            <ControlLabel>{LABEL_COMUNS.ID}</ControlLabel>
                            <FormGroup validationState={this.props.idNumericoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask
                  maskChar=""
                  id="idNumerico"
                  className="mensagens-erro form-control"
                  value={this.props.idNumerico}
                  mask={MASCARA_CAMPOS.ID}
                  placeholder={this.props.idNumericoPlaceHolder || LABEL_COMUNS.ID}
                  onChange={this.props.setIdNumerico}
                  style={{
                    height: '32px',
                    paddingTop: '8px',
                    paddingLeft: '10px',
                    width: '105px'
                  }}
                />
                                <ShowLineError error={this.props.idNumericoErro} />
                            </FormGroup>
                        </Grid>
                    </If>
                    <If test={this.props.exibirFiltroDeAnexoContratual}>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                            <ControlLabel>{LABEL_COMUNS.ANEXO_CONTRATUAL}</ControlLabel>
                            <FormGroup>
                                <Select id="anexoContratual"
                  name="anexoContratual"
                  placeholder={LABEL_COMUNS.SELECIONE}
                  value={this.props.anexoContratualSelecionadoId}
                  onChange={this.props.setAnexoContratual}
                  options={this.props.listaDeAnexosContratuais}
                  noResultsText={LABEL_COMUNS.NENHUM_ANEXO} />
                            </FormGroup>
                        </div>
                    </If>
                    <If test={this.props.exibirFiltroDeOperacao}>
                        <br /><br /><br /><br /><br />
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                            <ControlLabel>{LABEL_COMUNS.OPERACAO}</ControlLabel>
                            <FormGroup>
                                <Select id="operacao"
                  name="operacao"
                  placeholder={LABEL_COMUNS.SELECIONE}
                  value={this.props.operacaoSelecionadaId}
                  onChange={this.props.setOperacao}
                  options={this.props.listaDeOperacoes}
                  noResultsText={LABEL_COMUNS.NENHUM_LANCAMENTO} />
                            </FormGroup>
                        </div>
                    </If>
                    <If test={this.props.exibirFiltroDeStatus}>
                        <div className="col-sm-4 col-md-4 col-lg-4">
                            <div className="form-group">
                                <ControlLabel>{LABEL_COMUNS.STATUS}</ControlLabel>
                                <FormGroup validationState={this.props.statusErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <Select id="status"
                    placeholder={LABEL_COMUNS.SELECIONE}
                    value={this.props.statusSelecionado}
                    onChange={this.props.setStatus}
                    options={this.props.listaDeStatus}
                    noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                  />
                                    {this.props.statusErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.statusErro}</span> : null}
                                </FormGroup >
                            </div>
                        </div>
                    </If>
                    <div className="col-sm-3 col-md-3 col-lg-3 alinhamento_botao_buscar">
                        <br />
                        <If test={this.props.exibirBotaoLimpar}>
                            <Button bsStyle="default" onClick={this.props.limparFiltro}
                style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}>
                                {LABEL_COMUNS.LIMPAR}
                            </Button>
              &nbsp;&nbsp;&nbsp;
                        </If>
                        <Button bsStyle={corDeFundoParaBotao}
              style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
              onClick={() => {this.props.filtrar(); this.props.filtrarCSV();}}>
                            {LABEL_COMUNS.SET_HISTORICO}
                        </Button>
                    </div>
                    <div className="col-sm-4 col-md-6 col-lg-6"></div>
                </div>
                <div className="col-sm-1 col-md-1 col-lg-1">&nbsp;</div>
            </Grid>
        );
    }
}

export default FiltroComponent;
