import { connect } from 'react-redux';

import app from './app';

import { bindActionCreators } from 'redux';
import * as AppActions from './AppAction';
import * as anexoContratualAction from './action/anexo-contratual/anexo-contratual-action';
import { getPoderesOutorga } from './action/outorga/outorga-action';
import * as cartoesActions from './action/cartoes/cartoes-action';

import { withRouter } from 'react-router-dom';

const mapStateToProps = state => {
    return {
        app: state.AppReducer
    };
};

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ ...AppActions, ...anexoContratualAction, ...cartoesActions, getPoderesOutorga }, dispatch);
};

const AppContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(app));

export default AppContainer;
