import React, { Component } from 'react';
import { Button, FormGroup, ControlLabel, Dropdown } from 'react-bootstrap';
import { STATUS_VALIDACAO_COMPONENTES } from '../../../util/estilo_para_componentes/estiloUtil';
import InputMask from 'react-input-mask';
import { pedidoCartaoImpressoBppCorp } from '../../../../reducer/pedidos/pedidos-reducer';
import { bindActionCreators } from 'redux';
import * as pedidosAction from '../../../../action/pedidos/pedidos-action';
import ContaSelecionada from '../../../contas/conta-selecionada';
import { connect } from 'react-redux';
import ExibirImagemComTitulo from '../../../comuns/exibir-imagem/exibir-imagem-com-titulo';
import If from '../../../componentes-uteis/if/if';
import Resumo from '../../../comuns/pedidos/cartao-impresso/Resumo';
import { LABEL_COMUNS, FORMA_ENTREGA, TIPO_ENTREGA, MENSAGENS, MASCARA_CAMPOS } from '../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { Redirect } from 'react-router-dom';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import { retornaProdutoFELId, recuperaValorPedidoEmbossingCartao } from '../../../../servicos/pedidos/pedido-service';
import { retornaEnderecoBpp } from '../../../../servicos/localidade/localidade-service';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../reducer/outorga/outorga-operacao-reducer';
import ShowLineError from '../../../comuns/showLineError/show-line-error';

export class PedidoEmbossingCartaoBppCorpResumo extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pedido: props.location.state.pedido, contaSelecionada: props.location.state.pedido.contaSelecionada, exibirModalCancelar: false,
            exibirModalConfirmacao: false, mensagemConfirmacao: '', mensagemFinalizacao: '', exibirLoading: false,
            redirecionarParaTelaPedidoBppCorp: false, exibirModalConfirmacaoVoltar: false, exbirModalDeFinalizacao: false
        };
    }

    valorImpressao = 0;
    valorEntrega = 0;
    valorPedido = 0;
    quantidade = 0;

    componentDidMount() {
        this.recuperaTarifasPedido(this.props.location.state.pedido);
    }

    componentWillReceiveProps(nextProps) {
        const { solicitacaoDePedidoEmbossingCartao } = nextProps.pedido;
        let mensagemFinalizacao;
        if (solicitacaoDePedidoEmbossingCartao && Object.getOwnPropertyNames(solicitacaoDePedidoEmbossingCartao).length) {
            if (solicitacaoDePedidoEmbossingCartao.erro) {
                mensagemFinalizacao = solicitacaoDePedidoEmbossingCartao.mensagemDeErro || MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.FINALIZACAO.ERRO;
            }
            else if (solicitacaoDePedidoEmbossingCartao.requisicaoPedidoEmbossingId !== this.props.pedido.solicitacaoDePedidoEmbossingCartao.requisicaoPedidoEmbossingId) {
                mensagemFinalizacao = nextProps.pedidoReducer.msg || MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.FINALIZACAO.SUCESSO;
            }
            this.setState({ exibirModalConfirmacao: false, exibirModalCancelar: false, exbirModalDeFinalizacao: true, exibirLoading: false, mensagemFinalizacao });
        }
    }

    retornaRodapeModalDeFinalizacao = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    if (this.props.pedido.solicitacaoDePedidoEmbossingCartao && this.props.pedido.solicitacaoDePedidoEmbossingCartao.sucesso) {
                        this.setState({ redirecionarParaDashBoard: true });
                    }
                    this.props.setLimparSolicitacaoDePedidoEmbossingCartao();
                    this.setState({ exbirModalDeFinalizacao: false });
                }} />
        );
    }

    retornaRodapeModalConfirmacao() {
        const pedido = {
            ... this.props.location.state.pedido, ...this.state.pedido, valorImpressao: this.valorImpressao,
            valorEntrega: this.valorEntrega, valorPedido: this.valorPedido, quantidade: this.quantidade
        };
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO}
                btn2Label={LABEL_COMUNS.SIM}
                btn1OnClick={() => {
                    this.setState({ exibirModalConfirmacao: false, exibirModalCancelar: false, exibirModalConfirmacaoVoltar: false, mensagemConfirmacao: '' });
                }}
                btn2OnClick={() => {
                    if (this.state.exibirModalCancelar) {
                        this.setState({ redirecionarParaDashBoard: true });
                    }
                    if (this.state.exibirModalConfirmacao) {
                        const necessitaToken = this.props.outorgaPedidoEmbossingCartao.aprovacao;
                        const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
                        this.props.efetuarPedidoEmbossingCartao(pedido, necessitaToken, outorgaPodeAprovar);
                        this.setState({ exibirLoading: true });
                    }
                    if (this.state.exibirModalConfirmacaoVoltar) {
                        this.setState({ redirecionarParaTelaPedidoBppCorp: true });
                    }
                    this.setState({ exibirModalConfirmacao: false, exibirModalCancelar: false, exibirModalConfirmacaoVoltar: false, mensagemConfirmacao: '' });
                }} />
        );
    }

    recuperaTarifasPedido(pedido) {
        const { anexoSelecionado } = pedido;
        const produtoFELId = retornaProdutoFELId(anexoSelecionado);
        this.setState({ pedido: { ...this.state.pedido, produtoFELId } });
        const { valor, valorEntrega, valorImpressao, quantidade } = recuperaValorPedidoEmbossingCartao(pedido);
        const valorPedido = recuperaValorPedidoEmbossingCartao(pedido);
        this.valorImpressao = valorImpressao;
        this.valorEntrega = valorEntrega;
        this.valorPedido = valor;
        this.quantidade = quantidade;
    }

    tratarEventoBotaoVoltar = () => {
        if (!this.state.pedido.arquivoSelecionado) {
            this.setState({ redirecionarParaTelaPedidoBppCorp: true });
        } else {
            this.setState({ exibirModalConfirmacaoVoltar: true });
        }
    }


    verificaNecessitaOtp(){
        this.props.solicitacaoReenviarToken(this.props.pedidoReducer.relId);
    }
    
    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.pedidoReducer.solicitacaoTokenErro || this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token</ControlLabel>
                    <InputMask
            id="token"
            className="form-control mensagens-erro"
            placeholder="Digite o TOKEN"
            maxLength={6}
            value={!this.state.tokenId ? '' : this.state.tokenId}
            onChange={(e) => this.setState({ tokenId: e.target.value })}
            mask={MASCARA_CAMPOS.TOKEN}
            maskChar={null}
          />
                    <ShowLineError error={this.props.pedidoReducer.solicitacaoTokenErro || this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.verificaNecessitaOtp()}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken() {

        return (
            <FooterBotoesModalComponent
        parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
        btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
        btn1OnClick={() => {
          this.props.exibiModalToken(false);
          this.setState({ tokenId: '' });
        }}
        btn2OnClick={() => {
          if (this.state.tokenId && this.state.tokenId.length === 6) {
            const necessitaToken = this.props.outorgaPedidoEmbossingCartao.aprovacao;
            const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
            
            this.props.efetivarPedidoComToken(this.props.pedidoReducer.relId, this.valorEntrega, this.state.tokenId);
          } else {
            this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
          }
        }} />
        );
    }

    render() {
        if (this.state.redirecionarParaTelaPedidoBppCorp) {
            return <Redirect to={{ pathname: ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_CORP, state: this.state.pedido }} />;
        }
        if (this.state.redirecionarParaDashBoard) {
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_SOLUCOES} />;
        }

        return (
            <div>
                {this.state.contaSelecionada && <ContaSelecionada exibirSaldo={true}
                    contaSelecionada={this.state.contaSelecionada} />
                }
                <If test={!this.props.pedidoReducer.exibirModalToken && this.state.exibirLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>

                <If test={this.state.pedido.urlImagemCartao}>
                    <ExibirImagemComTitulo
                        tituloImagem="Cartão"
                        urlImagem={this.state.pedido.urlImagemCartao}
                    />
                </If>

                <If test={this.state.pedido.urlImagemCartaBerco}>
                    <ExibirImagemComTitulo
                        tituloImagem="Carta Berço"
                        urlImagem={this.state.pedido.urlImagemCartaBerco}
                    />
                </If>

                <Resumo
                    quantidadeCartoes={this.state.pedido.quantidadeCartoes}
                    tipoEntregaSelecionada={this.state.pedido.tipoEntregaSelecionada}
                    formaEntregaSelecionada={this.state.pedido.formaEntregaSelecionada}
                    cep={this.state.pedido.cep}
                    bairro={this.state.pedido.bairro}
                    logradouro={this.state.pedido.logradouro}
                    numero={this.state.pedido.numero}
                    localidadeRecuperada={this.state.pedido.localidadeRecuperada}
                    enderecoBPP={retornaEnderecoBpp()}
                    valorImpressao={this.valorImpressao}
                    valorEntrega={this.valorEntrega}
                    valorPedido={this.valorPedido}
                />

                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 posicicao_botoes_confirmacao">
                    <Button bsStyle="default" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} onClick={() => this.tratarEventoBotaoVoltar()}>{LABEL_COMUNS.VOLTAR}</Button>&nbsp;&nbsp;
                    <Button bsStyle="default" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} onClick={() => this.setState({ exibirModalCancelar: true })}>{LABEL_COMUNS.CANCELAR}</Button>&nbsp;&nbsp;
                    <Button bsStyle={'primary'} style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} onClick={() => this.setState({ exibirModalConfirmacao: true })}>{LABEL_COMUNS.CONFIRMAR}</Button>
                </div>


                <ModalComponent name="mdConfirmacao"
                    exibirModal={this.state.exibirModalConfirmacao || this.state.exibirModalCancelar || this.state.exibirModalConfirmacaoVoltar}
                    header={LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.MODAL_HEADER}
                    body={MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.CONFIRMACAO(this.state.exibirModalConfirmacao, 
                        this.state.exibirModalConfirmacaoVoltar,
                        this.state.pedido.arquivoSelecionado,
                        this.props.outorgaPerfils.podeAprovar)}
                    footer={this.retornaRodapeModalConfirmacao()} />


                <ModalComponent
                    name="mdFinalizacao"
                    exibirModal={this.state.exbirModalDeFinalizacao}
                    header={LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.MODAL_HEADER}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalDeFinalizacao()} />

                <ModalComponent
                    name="mdToken"
                    exibirModal={this.props.pedidoReducer.exibirModalToken}
                    header={LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.MODAL_HEADER}
                    body={this.montaCorpoModalToken()}
                    footer={this.montaRodapeModalToken()} />


            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    pedido: pedidoCartaoImpressoBppCorp(state.PedidosReducer),
    pedidoReducer: state.PedidosReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    outorgaPedidoEmbossingCartao: outorgaParametroOperacao(state.OutorgaOperacaoReducer, 'pedidoEmbossingCartao')
});

const mapDispatchToProps = dispatch => bindActionCreators(pedidosAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedidoEmbossingCartaoBppCorpResumo);