import { LABELS, METODOS, NOME_DOS_CAMPOS } from './pedido-carga-constantes';
const INITIAL_STATE = {
    valorCarga: '',
    msgValorCargaErro: '',
    bancoSelecionadoId: '',
    msgBancoErro: '',
    agencia: '',
    msgAgenciaErro: '',
    conta: '',
    msgContaErro: '',
    digito: '',
    msgDigitoErro: '',
    tipoConta: '',
    msgtipoContaErro: '',
    descricao: '',
    msgDescricaoErro: '',
    listaDeBancos: [],
    exibirModalConfirmacao: false,
    msgFinalizacao: '',
    statusFinalizacao: '',
    exibirModalCancelar: false,
    exibirModalSucesso: false,
    exibirLoading: true,
    bancoDestinoId: '',
    desabilitaComboBanco: false,
    solicitacaoTransferenciaRealizada: {
        nomeBanco: '',
        documento: '',
        contaCorrente: '',
        agencia: '',
        requisicaoId: '',
        valorCarga: ''
    }
    /* hoje: false, dataAgendada: "", dataAgendamentoErro: "",
    dataAgendamentoObrigatoriaErro: "" */
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case METODOS.EXIBIR_MODAL_CONFIRMACAO_CHANGE:
            return {
                ...state, exibirModalConfirmacao: action.payload
            };
        case METODOS.EXIBIR_MODAL_CANCELAR_CHANGE:
            return {
                ...state, exibirModalCancelar: action.payload, exibirModalConfirmacao: false
            };
        case METODOS.EXIBIR_MODAL_SUCESSO_CHANGE:
            return {
                ...state, exibirModalSucesso: action.payload
            };
        case METODOS.EXIBIR_LOADING_CHANGE:
            return {
                ...state, exibirLoading: action.payload
            };
        case METODOS.VALOR_CARGA_CHAGE:
            return {
                ...state, valorCarga: action.payload
            };
        case METODOS.BANCO_CHANGE:
            let bancoId = action.payload !== null && action.payload.value ? action.payload.value : action.payload;
            return {
                ...state, bancoSelecionadoId: bancoId
            };
        case METODOS.BANCO_DESTINO_CHANGE:
            return {
                ...state, bancoDestinoId: action.payload
            };
        case METODOS.DESABILITA_COMOBO_BANCO_CHANGE:
            return {
                ...state, desabilitaComboBanco: action.payload
            };
        case METODOS.AGENCIA_CHANGE:
            return {
                ...state, agencia: action.payload
            };
        case METODOS.CONTA_CHANGE:
            return {
                ...state, conta: action.payload
            };
        case METODOS.DIGITO_CHANGE:
            return {
                ...state, digito: action.payload
            };
        case METODOS.TIPO_CONTA_CHAGE:
            return {
                ...state, tipoConta: action.payload
            };
        case METODOS.DESCRICAO_CHANGE:
            return {
                ...state, descricao: action.payload
            };
        /* case METODOS.SET_HOJE:
            debugger;
            return {
                ...state,
                hoje: action.payload,
                dataAgendamentoErro: "",
                dataAgendamentoObrigatoriaErro: "",
                dataAgendada: ""
            }
        case METODOS.SET_DATA_AGENDADA:
            return {
                ...state,
                dataAgendada: action.payload,
                dataAgendamentoErro: "",
                dataAgendamentoObrigatoriaErro: "",
                hoje: false
            } */
        case METODOS.BUSCAR_BANCOS:
            return {
                ...state, listaDeBancos: action.payload, exibirLoading: false
            };
        case METODOS.BUSCAR_BANCOS_ERRO:
            let buscaBancosErro = {
                banco: LABELS.MENSAGEM_BUSCAR_BANCOS_ERRO, exibirLoading: false
            };
            return {
                ...state, camposErros: buscaBancosErro
            };
        case METODOS.EFETUAR_CARGA:
            return {
                ...state,
                msgFinalizacao: LABELS.MENSAGEM_CARGA_EFETUADA_COM_SUCESSO,
                statusFinalizacao: true,
                exibirModalConfirmacao: false,
                exibirLoading: false,
                exibirModalSucesso: true,
                solicitacaoTransferenciaRealizada: preencheRespostaSucesso(action.payload)
            };
        case METODOS.EFETUAR_CARGA_ERRO:
            let erros = preencheMensagensDeErro(action.payload.erros);
            return {
                ...state,
                msgFinalizacao: LABELS.MENSAGEM_CARGA_NAO_EFETUADA,
                statusFinalizacao: false,
                exibirModalConfirmacao: false,
                exibirLoading: false,
                msgValorCargaErro: erros.valorCarga,
                msgBancoErro: erros.bancoSelecionadoId,
                msgAgenciaErro: erros.agencia,
                msgContaErro: erros.conta,
                msgDigitoErro: erros.digito,
                msgtipoContaErro: erros.tipoConta,
                msgDescricaoErro: erros.descricao

            };
        /*
        MSG_ERRO_DESCRICAO: "msgErroDescricao"
        */
        case METODOS.MSG_ERRO_BANCO:
            return {
                ...state,
                msgBancoErro: action.payload
            };
        case METODOS.MSG_ERRO_AGENCIA:
            return {
                ...state,
                msgAgenciaErro: action.payload
            };
        case METODOS.MSG_ERRO_CONTA:
            return {
                ...state,
                msgContaErro: action.payload
            };
        case METODOS.MSG_ERRO_DIGITO:
            return {
                ...state,
                msgDigitoErro: action.payload
            };
        case METODOS.MSG_ERRO_VALOR_DA_CARGA:
            return {
                ...state,
                msgValorCargaErro: action.payload
            };
        /* case METODOS.MSG_ERRO_DATA_AGENDAMENTO:
            return {
                ...state,
                dataAgendamentoErro: action.payload,
            }
        case METODOS.MSG_ERRO_DATA_AGENDAMENTO_OBRIGATORIA:
            return {
                ...state,
                dataAgendamentoObrigatoriaErro: action.payload,
            } */
        default:
            return state;
    }
};

export function preencheMensagensDeErro(erros) {
    let mensagensDeErro = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case NOME_DOS_CAMPOS.AGENCIA.campo:
                mensagensDeErro.agencia = erros[i].mensagem;
                break;
            case NOME_DOS_CAMPOS.BANCO.campo:
                mensagensDeErro.banco = erros[i].mensagem;
                break;
            case NOME_DOS_CAMPOS.TIPO_CONTA.campo:
                mensagensDeErro.tipoConta = erros[i].mensagem;
                break;
            case NOME_DOS_CAMPOS.CONTA.campo:
                mensagensDeErro.conta = erros[i].mensagem;
                break;
            case NOME_DOS_CAMPOS.DESCRICAO.campo:
                mensagensDeErro.digito = erros[i].mensagem;
                break;
            case NOME_DOS_CAMPOS.DIGITO.campo:
                mensagensDeErro.digito = erros[i].mensagem;
                break;
            default:
                mensagensDeErro.valorCarga = erros[i].mensagem;
                break;
        }
    }
    return mensagensDeErro;
}

export function preencheRespostaSucesso(response) {
    let resposta = {
        codigoBanco: response.codigoBanco,
        nomeBanco: response.nomeBanco,
        nomeBeneficiario: response.nomeBeneficiario,
        documento: response.contaDocumento,
        contaCorrente: response.contaNumero,
        agencia: response.contaAgencia,
        valorCarga: response.valorCarga
    };
    return resposta;
}
