import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Glyphicon, Dropdown, MenuItem } from 'react-bootstrap';
import If from '../../../componentes-uteis/if/if';
import {
    LABEL_COMUNS,
    NAVEGADOR_NIVEL
} from '../../../util/contantes-comuns/constantes-comuns';

import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as NavegadorNivel from '../navegador-nivel';
import { parse } from 'querystring';
import ListaCheck from '../lista-conta-departamento/lista-check';

class ListaContaComponente extends Component {
  calcZIndex = maxZIndex => {
      return (maxZIndex -= 1);
  };

  calcPaddingTop = (multiply = 1) => {
      return `calc((70px) * ${multiply})`;
      // return `calc(100%)`;
  };

  render() {
      const {
          lista,
          setContaSelecionada,
          setExibirModalEditarConta,
          setExibirModalCriarContaDepartamento
      } = this.props;

      let maxZIndex = this.props.maxZIndex || 100;
      const zIndex = this.calcZIndex(maxZIndex);

      return (
          <div className="item-container">
              {lista.map((item, idx, array) => {
          return (
              <div key={idx} className="item-parent">
                  <div className="item">
                      <li className="list-group-item-cd">
                          <div className="row titulo-tabelas">
                              <div
                      className="col-xs-1 col-sm-1 col-md-1 col-lg-1"
                      style={{
                        zIndex: maxZIndex
                      }}
                    >
                                  <div
                        className="span-half-left-border"
                        style={
                          this.props.parent
                            ? { border: 'none' }
                            : {
                              paddingTop: this.calcPaddingTop(item.ancestrais),
                              background: 'none',
                              position: 'relative',
                              zIndex: '1'
                            }
                        }
                      >
                                      <img
                          className="avatar-conta"
                          src={item.avatarPreSignedURL}
                          alt=""
                        />
                                  </div>
                              </div>

                              <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 alinhamento-esquerda espacamento-conteudo-departamento">
                                  <div className="row">
                                      <Link to={this.props.parent ? ROTAS_CAMINHOS.DASHBOARD_CONTA : ROTAS_CAMINHOS.DASHBOARD_DEPARTAMENTO}>
                                          <div
                            className="row cursor-pointer"
                            onClick={() => {
                              this.acessarConta(item);
                            }}
                          >
                                              {item.apelido}
                                          </div>
                                      </Link>
                                  </div>
                                  <If test={item.descricao === 'contaMovimento'}>
                                      <div className="row">
                                          {item.agencia}/{item.numero}-{item.digito}
                                      </div>
                                  </If>
                                  <If test={item.descricao === 'contaDepartamento'}>
                                      <div className="row">BPP Corp - Conta Departamento</div>
                                  </If>
                              </div>
                              <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                  <Dropdown id="menuConta" pullRight>
                                      <Dropdown.Toggle
                          noCaret
                          className="fundo-branco dropdown-sem-bordas"
                        >
                                          <Glyphicon glyph="option-horizontal" />
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu>
                                          <MenuItem
                            className="menu-item-conta-dropdown"
                            onSelect={() => {
                              setContaSelecionada(item);
                            }}
                            onClick={setExibirModalEditarConta}
                          >
                                              {LABEL_COMUNS.EDITAR_CONTA}
                                          </MenuItem>
                                          <MenuItem
                            onSelect={() => {
                              setContaSelecionada(item);
                            }}
                            onClick={setExibirModalCriarContaDepartamento}
                          >
                                              {LABEL_COMUNS.CRIAR_CONTA_DEPARTAMENTO}
                                          </MenuItem>
                                      </Dropdown.Menu>
                                  </Dropdown>
                              </div>
                          </div>
                      </li>
                      {item.contaFilha && (
                      <ListaContaComponente
                    maxZIndex={zIndex}
                    lista={item.contaFilha}
                    setContaSelecionada={setContaSelecionada}
                    setExibirModalEditarConta={setExibirModalEditarConta}
                    setExibirModalCriarContaDepartamento={
                      setExibirModalCriarContaDepartamento
                    }
                  />
                )}
                  </div>
              </div>
          );
        })}
          </div>
      );
  }

  acessarConta(conta) {
      reactLocalStorage.setObject(
          LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA,
          conta
      );
      NavegadorNivel.proximo(conta.id, NAVEGADOR_NIVEL.VALUE);
  }
}

export default ListaContaComponente;
