
export const METODOS = {
    PEDIDO_CARTAO_VIRTUAL: {
        GET_COMBO_ANEXO: 'getComboAnexo',
        GET_COMBO_ANEXO_ERRO: 'getComboAnexoErro',
        GET_IMAGEM: 'getImagem',
        GET_IMAGEM_ERRO: 'getImagemErro',
        SAVE_FORM_PEDIDO_CARTAO_VIRTUAL: 'saveFormPedidoCartaoVirtual',
        SET_ANEXO_ORIGINAL_PEDIDO_CARTAO_VIRTUAL: 'setAnexoOriginalPedidoCartaoVirtual',
        SET_CELULAR_PORTADOR_PEDIDO_CARTAO_VIRTUAL: 'setCelularPortadorPedidoCartaoVirtual',
        SET_CONTA_SELECIONADA_PEDIDO_CARTAO_VIRTUAL: 'setContaSelcionadaPedidoCartaoVirtual',
        SET_DATA_NASCIMENTO_PORTADOR_PEDIDO_CARTAO_VIRTUAL: 'setDataNascimentoPedidoPortadorCartaoVirtual',
        SET_EMAIL_PORTADOR_PEDIDO_CARTAO_VIRTUAL: 'setEmailPortadorPedidoCartaoVirtual',
        SET_PROPS_CARTAO: 'setPropsCartao',
        SET_NUMERO_DOCUMENTO_PORTADOR_PEDIDO_CARTAO_VIRTUAL: 'setNumeroDocumentoPortadorPedidoCartaoVirtual',
        SOLICITAR_CARTAO_VIRTUAL: 'solicitarCartaoVirtual',
        SOLICITAR_CARTAO_VIRTUAL_ERRO: 'solicitarCartaoVirtualErro'
    }

};

export const NOME_DOS_CAMPOS_DE_ERROS = {
    DATA_INICIAL: { campo: 'dataInicial' },
    DATA_FINAL: { campo: 'dataFinal' }
};
