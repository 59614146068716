import { CARTAO_STATUS, MENSAGENS, OPERACAO, TIPO_DE_TRANSACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { abreOperacao, efetivaTransacao } from '../operacoes/operacoes-base';
import { fetchEdicaoDeConta } from '../contas/contas-base';
import { fetchAdicionaCartaoFavorito, fetchBuscaCartaoComOperacaoEmProcessamento, fetchCheckExistsCardUser, fetchCreateCardUser, fetchVinculaCartao } from './cartoes-base';
import { recuperaSomenteNumerosdeUmaString } from '../../componentes/util/numeros/numeroUtil';
import { hasPropertyName, isEmptyObject, isNoEmptyObject } from 'servicos/base/service-base';
import { saldoMaiorQueZero } from 'servicos/contas/conta-service';
import { stringParaNumero } from 'componentes/util/formata-string';
import { formatarDataUS } from 'componentes/util/data/dataUtil';

const PROPERTY_DATA = 'data';


export const adicionaNovoFavoritoFavorito = async (body, contaId) => {
    const response = await fetchAdicionaCartaoFavorito(body, contaId);
    const favoriteResponse = {};
    favoriteResponse.error = response.error;
    if (response.error) {
        if (response.data && response.data.erros.length > 0) {
            favoriteResponse.message = response.data.erros;
        } else {
            favoriteResponse.message = MENSAGENS.CARTAO.FAVORITOS.CADASTRO.ERRO;
        }
    } else {
        favoriteResponse.message = MENSAGENS.CARTAO.FAVORITOS.CADASTRO.SUCESSO;
    }
    return favoriteResponse;
};

export const alterarApelidoDaContaCartao = async (contaId, apelido) => {
    return await fetchEdicaoDeConta(contaId, apelido);
};

export const getVincularCartao = async (dadosVincular, contaId) => {
    const response = await fetchVinculaCartao(dadosVincular, contaId).catch(async error => (await { ...error }));
    const { error, data } = response;
    if (error) {
        if (data.erros) {
            return data.erros[0].mensagem;
        }
        return data.erros && data.erros.length ? data.erros[0].mensagem : MENSAGENS.CARTAO.VINCULAR_CARTAO.FINALIZACAO.ERRO;
    } else {
        return data.erros && data.erros.length ? data.erros[0].mensagem : MENSAGENS.CARTAO.VINCULAR_CARTAO.FINALIZACAO.SUCESSO;
    }
};

export const montaContaCartaoParaEdicao = (cartaoSelecionado) => {
    const { apelido, cartaoId, agencia, numeroConta } = cartaoSelecionado;
    const contaCartao = { agencia, apelido, id: cartaoId, numero: numeroConta };
    return contaCartao;
};

export const getJsonForBindCard = (data) => {
    const { cardPan } = data;
    const jsonBindCard = { pan: recuperaSomenteNumerosdeUmaString(cardPan) };
    return JSON.stringify(jsonBindCard);
};

export const bloquearCartao = async (data, necessitaOtp) => {
    const { lockSelectedCard, canApprove } = data;
    const contaDestinoId = lockSelectedCard.cartaoId;
    const cartaoId = lockSelectedCard.idCartaoImpresso;
    const operacaoSelecionada = OPERACAO.NOME.ALTERA_STATUS_CARTAO;
    const operationBody = retornaCorpoDeAlterarStatusCartao(operacaoSelecionada, contaDestinoId, CARTAO_STATUS.BLOQUEADO);
    return await fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
        .then(resp => ({ cardWithOperationExists: resp && resp.data && resp.data.quantidadeTotalRegistros && resp.data.quantidadeTotalRegistros > 0 }))
        .then(({ cardWithOperationExists }) => ({ cardWithOperationExists: !!cardWithOperationExists }))
        .then(async ({ cardWithOperationExists }) => !cardWithOperationExists ? await abreOperacao(operationBody) : ({ message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.OPERACAO_EM_ANDAMENTO }))
        .then(({ data, message }) => data ? ({ operationId: data.rel }) : ({ message }))
        .then(async ({ operationId, message }) => {
            if (operationId) {
                if (!necessitaOtp) {
                    if (canApprove) {
                        return { callTransaction: true, operationId, ...await efetivaTransacao(operationId, TIPO_DE_TRANSACAO.APROVACAO) };
                    }
                    return { operationId, message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(operacaoSelecionada) };
                }
                return { operationId };
            }
            return { message };
        })
        .then(respTransaction => {
            if (respTransaction.operationId) {
                const { callTransaction, operationId } = respTransaction;
                if (callTransaction) {
                    const { error, data, operationId } = respTransaction;
                    if (!error) {
                        return { operationId, requisitionId: data.idRequisicao, message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(operacaoSelecionada) };
                    }
                }
                return { operationId };
            } else {
                const { message } = respTransaction;
                return { message };
            }
        })
        .catch(error => {
            if (error && error.data) {
                const { data: { erros } } = error;
                return { message: erros[0].mensagem };
            }
            return { message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO(operacaoSelecionada) };
        });
};

export const desbloquearCartao = async (data, necessitaOtp) => {
    const { unlockSelectedCard, canApprove } = data;
    const contaDestinoId = unlockSelectedCard.cartaoId;
    const cartaoId = unlockSelectedCard.idCartaoImpresso;
    const previusStatusCard = unlockSelectedCard.statusAnterior;
    const operacaoSelecionada = OPERACAO.NOME.DESBLOQUEIO_CARTAO;
    const operationBody = retornaCorpoDeAlterarStatusCartao(operacaoSelecionada, contaDestinoId, previusStatusCard);
    return await fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
        .then(resp => ({ cardWithOperationExists: resp && resp.data && resp.data.quantidadeTotalRegistros && resp.data.quantidadeTotalRegistros > 0 }))
        .then(({ cardWithOperationExists }) => ({ cardWithOperationExists: !!cardWithOperationExists }))
        .then(async ({ cardWithOperationExists }) => !cardWithOperationExists ? await abreOperacao(operationBody) : ({ message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.OPERACAO_EM_ANDAMENTO }))
        .then(({ data, message }) => data ? ({ operationId: data.rel }) : ({ message }))
        .then(async ({ operationId, message }) => {
            if (operationId) {
                if (!necessitaOtp) {
                    if (canApprove) {
                        return { callTransaction: true, operationId, ...await efetivaTransacao(operationId, TIPO_DE_TRANSACAO.APROVACAO) };
                    }
                    return { operationId, message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(operacaoSelecionada) };
                }
                return { operationId };
            }
            return { message };
        })
        .then(respTransaction => {
            if (respTransaction.operationId) {
                const { callTransaction, operationId } = respTransaction;
                if (callTransaction) {
                    const { error, data, operationId } = respTransaction;
                    if (!error) {
                        return { operationId, requisitionId: data.idRequisicao, message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(operacaoSelecionada) };
                    }
                }
                return { operationId };
            } else {
                const { message } = respTransaction;
                return { message };
            }
        })
        .catch(error => {
            if (error && error.data) {
                const { data: { erros } } = error;
                return { message: erros[0].mensagem };
            }
            return { message: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO(operacaoSelecionada) };
        });
};

export const cancelarCartao = async (contaDestinoId, selectedCard, necessitaOtp) => {
    const cartaoId = selectedCard.idCartaoImpresso;
    const cartaoStatus = selectedCard.status;
    const lastFourDigits = selectedCard.quatroUltimosDigitos;
    const operacaoSelecionada = OPERACAO.NOME.ALTERA_STATUS_CARTAO;
    const operationBody = retornaCorpoDeAlterarStatusCartao(operacaoSelecionada, contaDestinoId, CARTAO_STATUS.CANCELADO);
    return await fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
        .then(resp => ({ cardWithOperationExists: resp && resp.data && resp.data.quantidadeTotalRegistros && resp.data.quantidadeTotalRegistros > 0 }))
        .then(({ cardWithOperationExists }) => ({ cardWithOperationExists: !!cardWithOperationExists }))
        .then(async ({ cardWithOperationExists }) => ({ cardWithOperationExists, checkBalance: !!(cartaoStatus !== CARTAO_STATUS.PRONTOS_PARA_ATIVACAO) }))
        .then(async ({ cardWithOperationExists, checkBalance }) => checkBalance ? ({ cardWithOperationExists, existsBalance: await saldoMaiorQueZero(contaDestinoId) }) : ({ cardWithOperationExists }))
        .then(({ cardWithOperationExists, existsBalance }) => existsBalance ? ({ message: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.ERRO_SALDO(lastFourDigits) }) : ({ cardWithOperationExists }))
        .then(async ({ cardWithOperationExists, message }) => {
            if (!cardWithOperationExists) {
                if (message) {
                    return { openOperation: false, message };
                }
                return { openOperation: true, ...await abreOperacao(operationBody) };
            }
            return { openOperation: false, message: MENSAGENS.CARTAO.CANCELAR_CARTAO.OPERACAO_EM_ANDAMENTO };
        })
        .then(async respOpenOperation => {
            const { openOperation, message } = respOpenOperation;
            if (openOperation) {
                const { data: { rel } } = respOpenOperation;
                if (!necessitaOtp) {
                    return { callTransaction: true, operationId: rel, ...await efetivaTransacao(rel, TIPO_DE_TRANSACAO.APROVACAO) };
                }
                return { operationId: rel };
            }
            return { message };
        })
        .then(respTransaction => {
            if (respTransaction.operationId) {
                const { callTransaction, operationId } = respTransaction;
                if (callTransaction) {
                    const { error, data, operationId } = respTransaction;
                    if (!error) {
                        return { operationId, requisitionId: data.idRequisicao, message: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.SUCESSO(operacaoSelecionada) };
                    }
                }
                return { operationId };
            } else {
                const { message } = respTransaction;
                return { message };
            }
        })
        .catch(error => {
            if (error && error.data) {
                const { data: { erros } } = error;
                return { message: erros[0].mensagem };
            }
            return { message: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.ERRO(operacaoSelecionada) };
        });
};

export const activateCard = async (data) => {
    const { cardId, destinyAccountId } = data;
    const operationBody = retornaCorpoDeAlterarStatusCartao(OPERACAO.NOME.ALTERA_STATUS_CARTAO, destinyAccountId, CARTAO_STATUS.ATIVO);
    return await fetchBuscaCartaoComOperacaoEmProcessamento(cardId)
        .then(({ data: { quantidadeTotalRegistros } }) => ({ cardWithOperationExists: !!(quantidadeTotalRegistros > 0) }))
        .then(({ cardWithOperationExists }) => {
            if (cardWithOperationExists) {
                throw new Error(MENSAGENS.CARTAO.CANCELAR_CARTAO.OPERACAO_EM_ANDAMENTO);
            }
            return ({ ckeckCardUserExists: true });
        })
        .then(async ({ ckeckCardUserExists }) => ckeckCardUserExists && await fetchCheckExistsCardUser(getBodyCheckCardUser(data)))
        .then(({ data }) => ({ createUser: !!(data.quantidadeTotalRegistros <= 0) }))
        .then(async resp => resp.createUser ? ({ callOperationChangeStatusCard: true, ...await fetchCreateCardUser(getBodyCreateCardUser(data)) }) : ({ callOperationChangeStatusCard: true }))
        .then(async ({ callOperationChangeStatusCard }) => callOperationChangeStatusCard && ({ callTransaction: true, ...await abreOperacao(operationBody) }))
        .then(async ({ callTransaction, data }) => callTransaction && ({ callTransaction: true, operationId: data.rel }))
        .then(async ({ operationId }) => operationId && ({ operationId, ...await efetivaTransacao(operationId, TIPO_DE_TRANSACAO.APROVACAO) }))
        .then(async ({ operationId }) => operationId && ({ ...await changePasswordCard(data) }))
        .catch(error => hasPropertyName(error, PROPERTY_DATA) ? ({ message: error && error.data && error.data.erros && error.data.erros[0].mensagem }) : ({ message: error.message }));
};

export const changePasswordCard = async (data) => {

    const { cardId, canApprove, destinyAccountId, needToken, originalContractualAttachmentId, password } = data;

    const operationBody = generateBodyChangePasswordCard(destinyAccountId, originalContractualAttachmentId, password);

    return await fetchBuscaCartaoComOperacaoEmProcessamento(cardId)
        .then(resp => {
            if (resp.data && resp.data.quantidadeTotalRegistros && resp.data.quantidadeTotalRegistros > 0) {
                const openOperations = resp.data.resultado;
                const changePasswordCardOperation = openOperations.find(op => op.operacao === OPERACAO.NOME.ALTERACAO_PIN_CARTAO);
                if (isNoEmptyObject(changePasswordCardOperation)) {
                    return { cardWithOperationExists: true };
                }
                return { cardWithOperationExists: false };
            }
            return { cardWithOperationExists: false };
        })
        .then(({ cardWithOperationExists }) => ({ cardWithOperationExists: !!cardWithOperationExists }))
        .then(async ({ cardWithOperationExists }) => {
            if (!cardWithOperationExists) {
                return { openOperation: true, ...await abreOperacao(operationBody) };
            }
            return { openOperation: false, message: MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.OPERACAO.EM_PROCESSAMENTO };
        })
        .then(async respOpenOperation => {
            const { openOperation, message } = respOpenOperation;
            if (openOperation) {
                const { data: { rel } } = respOpenOperation;

                if (!needToken) {
                    if (canApprove) {
                        return { callTransaction: true, operationId: rel, ...await efetivaTransacao(rel, TIPO_DE_TRANSACAO.APROVACAO) };
                    }
                    return { message: MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.FINALIZACAO.SUCESSO(cardId, canApprove) };
                }
                return { operationId: rel };
            }
            return { message };
        })
        .then(respTransaction => {
            if (respTransaction.operationId) {
                const { callTransaction, operationId } = respTransaction;
                if (callTransaction) {
                    const { error, data, operationId } = respTransaction;
                    if (!error) {
                        return { operationId, requisitionId: data.idRequisicao, message: MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.FINALIZACAO.SUCESSO(cardId, canApprove) };
                    }
                }
                return { operationId };
            } else {
                const { message } = respTransaction;
                return { message };
            }
        })
        .catch(error => {
            if (error && error.data) {
                const { data: { erros } } = error;
                return { message: erros[0].mensagem };
            }
            return { message: MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.FINALIZACAO.ERRO(cardId) };
        });

};


export const chargeOrDischargeCard = async (data) => {

    const { canApprove, needToken } = data;

    const operationBody = getBodyOperationChargeOrDischargeCard(data);

    return await abreOperacao(operationBody)
        .then(async ({ data }) => ({ operationId: data.rel }))
        .then(({ operationId }) => canApprove ? ({ operationId }) : ({ message: getFinalizationMessageChargeOrDischargeCard(data, false) }))
        .then(({ operationId, message }) => message ? ({ message }) : needToken ? ({ operationId }) : ({ operationId, ...getBodyTransactionChargeOrDischargeCard(data) }))
        .then(async ({ operationId, transacao, dataAgendamento, message }) => message ? ({ message }) : !transacao && isEmptyObject(transacao) ? ({ operationId }) : ({ ...await efetivaTransacao(operationId, { transacao, dataAgendamento }) }))
        .then(({ data: { idRequisicao } }) => ({ idRequisicao, message: getFinalizationMessageChargeOrDischargeCard(data, false) }))
        .catch(error => hasPropertyName(error, PROPERTY_DATA) ? ({ message: error && error.data && error.data.erros && error.data.erros[0].mensagem }) : ({ message: getFinalizationMessageChargeOrDischargeCard(data, true) }));

};

const getBodyOperationChargeOrDischargeCard = (data) => {
    let contaOrigemSelecionadaId = data.contaSelecionada.id;
    let contaDestinoSelecionadaId = data.cartaoSelecionado.cartaoId;
    let valorOperacao = stringParaNumero(data.valorOperacao);


    if (data.operacaoSelecionada === OPERACAO.NOME.DESCARGA_CARTAO) {
        contaOrigemSelecionadaId = data.cartaoSelecionado.cartaoId;
        contaDestinoSelecionadaId = data.contaSelecionada.id;
    }

    let dataToReturn = {
        operacao: data.operacaoSelecionada,
        valor: valorOperacao,
        contaOrigemId: contaOrigemSelecionadaId,
        contaDestinoId: contaDestinoSelecionadaId,
        tagCliente: data.tagCliente ? data.tagCliente : null
    };


    return dataToReturn;
};

const getBodyTransactionChargeOrDischargeCard = (data) => {
    let operationTransaction = {};
    if (data.dataAgendamento) {
        operationTransaction = TIPO_DE_TRANSACAO.AGENDAMENTO;
        operationTransaction.dataAgendamento = formatarDataUS(data.dataAgendamento);
    } else {
        operationTransaction = TIPO_DE_TRANSACAO.APROVACAO;
    }
    return operationTransaction;
};

const getFinalizationMessageChargeOrDischargeCard = (data, error) => {
    const { dataAgendamento, grantProfile, operacaoSelecionada } = data;
    let message = '';
    const AGENDAMENTO = dataAgendamento ? 'agendamento de ' : '';
    const outorgaPodeAprovar = grantProfile.podeAprovar;
    const APROVADOR = outorgaPodeAprovar ? 'e aprovação' : '';
    if (error) {
        if (operacaoSelecionada === OPERACAO.NOME.CARGA_CARTAO) {
            message = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.ERRO.CARGA_CARTAO(AGENDAMENTO, APROVADOR);
        } else {
            message = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.ERRO.DESCARGA_CARTAO(AGENDAMENTO, APROVADOR);
        }
    } else {
        if (operacaoSelecionada === OPERACAO.NOME.CARGA_CARTAO) {
            message = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.SUCESSO.CARGA_CARTAO(AGENDAMENTO, APROVADOR);
        } else {
            message = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.SUCESSO.DESCARGA_CARTAO(AGENDAMENTO, APROVADOR);
        }
    }
    return message;
};


const generateBodyChangePasswordCard = (accountCardId, originalContractualAttachmentId, password) => {
    const body = {
        anexoContratualOriginalId: originalContractualAttachmentId,
        operacao: OPERACAO.NOME.ALTERACAO_PIN_CARTAO,
        contaDestinoId: accountCardId,
        valor: 0,
        dadosIntegracao: {
            pin: password
        }
    };

    return body;
};

const getBodyCheckCardUser = (data) => {
    const { cardId } = data;
    const body = { nome: cardId };
    return body;
};

const getBodyCreateCardUser = (data) => {
    const { cardId } = data;
    const body = { cartaoId: cardId };
    return body;
};

const retornaCorpoDeAlterarStatusCartao = (operacao, contaDestinoId, status) => {
    const corpoRequisicao = {
        operacao: operacao,
        valor: 0,
        contaDestinoId: contaDestinoId,
        dadosIntegracao: {
            status: status
        }
    };
    return corpoRequisicao;
};
