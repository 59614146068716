import React, { Component } from 'react';
import styles from './styles.css';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';

class MenuCustom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            needRender: props.isOpen
        };
    }

    render() {
        if (!this.state.needRender && !this.props.isOpen) {
            return null;
        }

        return <div className={styles.bppModalFilterDesk} >

            <div className={`${styles.bppModalFilterDeskBackground} animated ${this.props.isOpen ? styles.fadeInModalDesk : styles.fadeOutModalDesk} faster`} />
            <div style={{
        width: 15,
        background: 'white',
        height: 15,
        transform: 'rotate(45deg)',
        position: 'absolute',
        zIndex: 190,
        left: '10px',
        top: '-6px'
      }} />
            <div className={`${styles.bppModalFilterDeskContent} animated ${this.props.isOpen ? styles.slideInUpModalDesk : styles.slideOutDownModalDesk} fast`}>

                <div style={{ boxShadow: '2px 3px 3px 2px rgba(48, 61, 99, 0.3)', zIndex: 130 }}>
                    <div className="customMenuClass">
                        <div className="divRowMenu" onClick={() => { this.props.filtrar(1); }}>{LABEL_COMUNS.REPORTS_FILTER_ONTEM}</div>
                        <div className="divRowMenu" onClick={() => { this.props.filtrar(7); }}>{LABEL_COMUNS.REPORTS_FILTER_SEVEN_DAYS}</div>
                        <div className="divRowMenu" onClick={() => { this.props.filtrar(30); }}>{LABEL_COMUNS.REPORTS_FILTER_30_DAYS}</div>
                        <div className="divRowMenu" onClick={() => { this.props.filtrar(90); }}>{LABEL_COMUNS.REPORTS_FILTER_90_DAYS}</div>
                        <div className="divRowMenu" onClick={() => { this.props.filtrar('other'); }}>{LABEL_COMUNS.REPORTS_FILTER_OTHERS}</div>
                    </div>
                </div>

            </div>

        </div>;
    }
}

export default MenuCustom;
