import { actionTypes } from './comprovante-extrato-constantes';
import { constantes } from './comprovante-extrato-constantes';

const estadoInicial = {
    exibirModalComprovanteConta: false,
    linhaDoExtratoSelecionada: undefined,
    tipoEmailDestinatario: constantes.TIPO_EMAIL_COMPROVANTE_PESSOAL_CADASTRADO,
    emailDestinatario: '',
    mostrarFormularioEnvio: true,
    sucessoEnvioEmailComprovante: false
};

export default function reducer(state = estadoInicial, action = {}) {
    switch (action.type) {
        case actionTypes.EXIBIR_COMPROVANTE_CONTA:
            return {
                ...state,
                exibirModalComprovanteConta: action.payload,
                linhaDoExtratoSelecionada: action.linhaDoExtratoSelecionada,
                tipoEmailDestinatario: constantes.TIPO_EMAIL_COMPROVANTE_PESSOAL_CADASTRADO,
                emailDestinatario: '',
                mostrarFormularioEnvio: true,
                sucessoEnvioEmailComprovante: false
            };

        case actionTypes.TIPO_EMAIL_DESTINATARIO:
            return {
                ...state,
                tipoEmailDestinatario: action.payload
            };

        case actionTypes.EMAIL_DESTINATARIO_HANDLE_CHANGE:
            return {
                ...state,
                emailDestinatario: action.payload
            };

        case actionTypes.EMAIL_COMPROVANTE_ENVIADO_SUCESSO:
            return {
                ...state,
                mostrarFormularioEnvio: false,
                sucessoEnvioEmailComprovante: action.payload
            };

        case actionTypes.MOSTRAR_FORMULARIO_ENVIO_EMAIL:
            return {
                ...state,
                emailDestinatario: '',
                mostrarFormularioEnvio: action.payload,
                sucessoEnvioEmailComprovante: false
            };

        default:
            return state;
    }
}
