import axios from 'axios';
import { URL_ANEXO_PESSOA, HEADER_API, urlServicos, montaUrlFetch, HTTP_METODOS } from '../../servicos/url-servicos';
import { CAMPOS, METODOS, DESCRICAO_CONTA } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { environment } from '../../configuracoes/environment';
import { stringify } from 'querystring';
import { fetchGetAnexosContratuais, fetchGeraAnexoContratualDeCriacaoDeContaPessoaFisica, fetchRecuperaOuGeraAnexoPessoaFisica } from '../../servicos/anexo-contratual/anexo-contratual-base';
import { retornaAnexosParaCombo, retornaAnexoCartaoVirtual } from '../../servicos/anexo-contratual/anexo-contratual-service';

export function getAnexosContratuais(pessoaTitularId, produtoId, contaDescricao) {
    return async dispatch => {
        return await fetchGetAnexosContratuais(pessoaTitularId, produtoId, contaDescricao)
            .then((response) => {
                dispatch({
                    type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS,
                    payload: response.data
                });
                return response.data;
            }).catch((err) => {
                dispatch({
                    type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS_ERRO,
                    payload: err.response || err.message || err
                });
            });
    };
}
/*
export function getAnexosContratuais(pessoaTitularId, produtoId, contaDescricao) {
    return (dispatch) => {
        getAxiosAnexosContratuais(dispatch, pessoaTitularId, produtoId, contaDescricao);
    }
} */

export const geraAnexoContratualPessoaFisica = (pessoaTitularId) => {
    return dispatch => {
        fetchGeraAnexoContratualDeCriacaoDeContaPessoaFisica(pessoaTitularId)
            .then(response => {
                dispatch({
                    type: METODOS.ANEXO_CONTRATUAL.GERA_ANEXO_PESSOA_FISICA,
                    payload: response.data
                });
                return response.data.registros;
            })
            .catch(err => {
                dispatch({
                    type: METODOS.ANEXO_CONTRATUAL.GERA_ANEXO_PESSOA_FISICA_ERRO,
                    payload: err.data
                });
            });
    };
};

export const recuperaOuGeraAnexoPessoaFisica = (pessoaTitularId) => {
    return dispatch => {
        fetchGetAnexosContratuais(pessoaTitularId)
            .then(({ data }) => !data.registros.length ? fetchRecuperaOuGeraAnexoPessoaFisica(pessoaTitularId) : dispatch({ type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS, payload: data }) && undefined)
            .then((result) => result && ({ registros: [{ id: result.data.id, nome: 'Anexo', contaDescricao: DESCRICAO_CONTA.CONTA_MOVIMENTO }] }))
            .then((payload) => {
                payload && dispatch({ type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS, payload });
            }).catch((err) => {
                dispatch({ type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS_ERRO, payload: err.data });
            });
    };
};

export function getAnexoPedidoVirtual(pessoaTitularId, produtoId) {
    return function(dispatch) {
        getAxiosAnexosContratuais(dispatch, pessoaTitularId, produtoId)
            .then(listaDeAnexos => {
                listaDeAnexos = retornaAnexosParaCombo(listaDeAnexos);
                return getFetchAnexoPorId(dispatch, retornaAnexoCartaoVirtual(listaDeAnexos).id);
            })
            .then(anexoRetornado => {
                if (anexoRetornado) {
                    dispatch({
                        type: METODOS.ANEXO_CONTRATUAL.GET_ANEXO_CARTAO_VIRTUAL,
                        payload: anexoRetornado
                    });
                } else {
                    dispatch({
                        type: METODOS.ANEXO_CONTRATUAL.GET_ANEXO_CARTAO_VIRTUAL_ERRO,
                        payload: anexoRetornado
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: METODOS.ANEXO_CONTRATUAL.GET_ANEXO_CARTAO_VIRTUAL_ERRO,
                    payload: err
                });
            });
    };
}

export const getAnexoPorId = (anexoId) => {
    return dispatch => {
        getFetchAnexoPorId(dispatch, anexoId);
    };
};

export const getSolucoes = (pessoaTitularId, listaDeProdutosId) => {
    return dispatch => {
        dispatch({
            type: METODOS.ANEXO_CONTRATUAL.FETCHING
        });
        getAxiosAnexosContratuais(dispatch, pessoaTitularId).then(resp => {
            let listaSolucoes;
            if (resp && resp.registros) {
                listaSolucoes = {};
                let listaResp = resp.registros.map(({ produtoId }) => produtoId);
                listaDeProdutosId.map(solucao => {
                    return listaSolucoes[solucao.name] = solucao.listaProdutosIds.filter(prodId => {
                        return listaResp.includes(prodId.produtoId);
                    });
                });
            }
            dispatch({
                type: listaSolucoes ? METODOS.ANEXO_CONTRATUAL.GET_SOLUCOES : METODOS.ANEXO_CONTRATUAL.GET_SOLUCOES_ERRO,
                payload: listaSolucoes
            });
        });
    };
};

function getAxiosAnexosContratuais(dispatch, pessoaTitularId, produtoId, contaDescricao) {
    return axios.get(URL_ANEXO_PESSOA(pessoaTitularId, produtoId, contaDescricao), HEADER_API())
        .then((response) => {
            dispatch({
                type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS,
                payload: response.data
            });
            return response.data;
        })
        .catch((err) => {
            dispatch({
                type: METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS_ERRO,
                payload: err.response || err.message || err
            });
        });
}

function getFetchAnexoPorId(dispatch, anexoId) {
    return fetch(montaUrlFetch(urlServicos.ANEXO_CONTRATUAL, { [CAMPOS.ANEXO_CONTRATUAL_ID]: anexoId }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json() }))
        .then(response => {
            dispatch({
                type: METODOS.ANEXO_CONTRATUAL.GET_ANEXO_BY_ID,
                payload: response.data
            });
            return response.data.registros[0];
        })
        .catch(err => {
            dispatch({
                type: METODOS.ANEXO_CONTRATUAL.GET_ANEXO_BY_ID_ERRO,
                payload: err.data
            });
        });
}
