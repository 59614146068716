import './prestacao-conta.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import { CAMPOS, LABEL_COMUNS, MENSAGENS } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';
import GridRow from '../../componentes-uteis/grid/grid-row';
import If from '../../componentes-uteis/if/if';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import InputImageFilePreview from '../../comuns/input-image-file-preview/input-image-file-preview';
import { formatarDataHoraBR } from '../../util/data/dataUtil';
import { formataMoedaComSimboloESinalNegacao } from '../../util/numeros/numeroUtil';
import { getPrestacaoDeContasClassificacoes, getPrestacaoDeContasProjetos, salvarPrestacaoDeContas } from '../../../servicos/pessoas/pessoa-service';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { retornaObjetoTarget } from '../../../servicos/base/service-base';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../comuns/showLineError/show-line-error';

class PrestacaoConta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            cancelarPrestacaoDeContas: false, classificacaoSelecionada: '', contaSelecionada: {}, descricaoSolicitacao: '', exibirImagem: false,
            exibirLoading: false, exibirModalConfirmacao: false, exibirModalFinalizacao: false, imagePreview: '', imagens: [],
            listaDePrestacaoDeContasClassificacoes: [], listaDePrestacaoDeContasProjetos: [], linhaDoExtratoSelecionada: {}, mensagemConfirmacao: '',
            mensagemFinalizacao: '', prestacaoDeContasId: '', prestacaoDeContasRecuperada: {}, projetoSelecionado: '', descricaoSolicitacaoErro: '',
            erroAoSalvarPrestacao: false
        };
    }

    async componentDidMount() {
        this.setState({ exibirLoading: true });
        const { contaSelecionada, linhaDoExtratoSelecionada, prestacaoDeContasRecuperada } = this.props.location.state;

        const pessoaId = contaSelecionada.pessoaTitularId;
        const contaId = contaSelecionada.id;
        const listaDePrestacaoDeContasClassificacoes = await getPrestacaoDeContasClassificacoes(pessoaId);
        const listaDePrestacaoDeContasProjetos = await getPrestacaoDeContasProjetos(pessoaId);

        this.setState({
            contaId, contaSelecionada, linhaDoExtratoSelecionada, listaDePrestacaoDeContasClassificacoes,
            listaDePrestacaoDeContasProjetos, pessoaId,
        });

        if (Object.getOwnPropertyNames(prestacaoDeContasRecuperada).length) {
            let { classificacao, descricaoSolicitacao, id, imagem, projeto } = prestacaoDeContasRecuperada;
            const imagens = [];
            if (imagem && imagem.length) {
                imagem.map((img, idx) => {
                    const nomeImagem = 'imagem'.concat('_'.concat(idx + 1));
                    const imagemMontada = { nomeDoCampo: nomeImagem, file: img, existeImagemPrestacao: true };
                    imagens.push(imagemMontada);
                    this.setState({ [nomeImagem]: img, imagens });
                });
            }
            classificacao = classificacao || '';
            descricaoSolicitacao = descricaoSolicitacao || '';
            projeto = projeto || '';
            this.setState({
                prestacaoDeContasRecuperada, classificacaoSelecionada: classificacao,
                descricaoSolicitacao, projetoSelecionado: projeto, prestacaoDeContasId: id
            });
        }
        this.setState({ exibirLoading: false });
    }

    setExibirModalCancelamento = (exibirModalCancelamento) => {
        this.setState({ exibirModalCancelamento });
    };

    setExibirModalConfirmacao = (exibirModalConfirmacao) => {
        this.setState({ exibirModalConfirmacao });
    };


    setPropsCampos = (campo, nomeDoCampo) => {
        const valorDoCampo = campo && campo.value || campo && campo.target && campo.target.value;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    };

    setPropsFile = (file, nomeDoCampo) => {
        const { imagens } = this.state;
        const novasImagens = imagens.filter(e => e.nomeDoCampo !== nomeDoCampo);
        if (file instanceof File) {
            novasImagens.push({ file, nomeDoCampo });
            this.setState({ imagens: novasImagens });
        } else {
            this.setState({ imagens: novasImagens, [nomeDoCampo]: undefined });
        }
    };

    retornaRodapeModalConfirmacao = () => (
        <FooterBotoesModalComponent
            btn1Label={LABEL_COMUNS.NAO}
            btn1OnClick={() => {
                const cancelarPrestacaoDeContas = false;
                this.setState({ cancelarPrestacaoDeContas });
                this.setExibirModalConfirmacao(false);

            }}
            btn2Label={LABEL_COMUNS.SIM}
            btn2OnClick={async () => {
                if (this.state.cancelarPrestacaoDeContas) {
                    this.props.history.goBack();
                } else {
                    await this.salvarPrestacao();
                }
            }}
        />
    );

    salvarPrestacao = async () => {
        this.setExibirModalConfirmacao(false);
        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
        const respostaPrestacao = await salvarPrestacaoDeContas(this.state);


        if (!respostaPrestacao.error) {
            this.setPropsCampos(retornaObjetoTarget(MENSAGENS.PESSOA.PRESTACAO_DE_CONTAS.FINALIZACAO.SUCESSO), CAMPOS.MENSAGEM_FINALIZACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
        } else {
            const { data: { erros } } = respostaPrestacao;
            this.trataErrosDaPrestacaoDeContas(erros);
        }
        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
    };

    trataErrosDaPrestacaoDeContas = (erros) => {
        const erroAoSalvarPrestacao = true;
        this.setState({ erroAoSalvarPrestacao });
        const existeCamposNosErros = !!(erros.filter(erro => Object.keys(erro).find(key => key === LABEL_COMUNS.CAMPO)).length);
        //const existeCamposNosErros = erros.filter(erro => erro[LABEL_COMUNS.CAMPO] !== undefined).length > 0;
        if (existeCamposNosErros) {
            const errosParaRetornar = {};
            erros.forEach(e => (errosParaRetornar[(e.campo + LABEL_COMUNS.ERRO)] = e.mensagem));
            this.setState(errosParaRetornar);
        } else {
            const ErroComponent = (
                <ul>{erros.map(err => <li>{err.mensagem}</li>)}</ul>
            );
            this.setPropsCampos(retornaObjetoTarget(ErroComponent), CAMPOS.MENSAGEM_FINALIZACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
        }
    };

    retornaRodapeModalFinalizacao = () => (
        <FooterBotoesModalComponent
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={this.props.history.goBack}
        />
    );

    setExibirImagem = (exibirImagem, imagePreview) => {
        this.setState({ exibirImagem, imagePreview });
    };

    retornaCorpoModalImagem = () => {
        return (
            <div className="alinhamento-centro">
                <img className="prestacao-de-contas-image-preview" src={this.state.imagePreview} />
            </div>
        );
    };

    retornaRodapeModalImagem = () => (
        <FooterBotoesModalComponent btn2Label={LABEL_COMUNS.OK} btn2OnClick={() => this.setExibirImagem(false)} />
    );

    render() {
        const { imagem_1, imagem_2, imagem_3 } = this.state;
        const { contaSelecionada } = this.props.location.state;
        const { apelido, descricao, idCartaoImpresso } = contaSelecionada;

        return (
            <div>
                <ModalComponent
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.PESSOA.PRESTACAO_DE_CONTAS.PRESTACAO_DE_CONTA(apelido, descricao, idCartaoImpresso)}
                    exibirModal={this.state.exibirModalConfirmacao}
                    body={this.state.mensagemConfirmacao}
                    footer={this.retornaRodapeModalConfirmacao()} />
                <ModalComponent
                    name="modal-finalizacao"
                    header={LABEL_COMUNS.PESSOA.PRESTACAO_DE_CONTAS.PRESTACAO_DE_CONTA(apelido, descricao, idCartaoImpresso)}
                    exibirModal={this.state.exibirModalFinalizacao}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()} />
                <ModalComponent
                    name="modal-exibirImagem"
                    header={LABEL_COMUNS.PESSOA.PRESTACAO_DE_CONTAS.PRESTACAO_DE_CONTA(apelido, descricao, idCartaoImpresso)}
                    exibirModal={this.state.exibirImagem}
                    body={this.retornaCorpoModalImagem()}
                    footer={this.retornaRodapeModalImagem()} />
                <Grid className="alinhamento-centro">
                    <If test={this.state.exibirLoading}>
                        <LoadingFenix />
                    </If>
                </Grid>
                <GridRow>
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.PESSOA.PRESTACAO_DE_CONTAS.PRESTACAO_DE_CONTA(apelido, descricao, idCartaoImpresso)}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <div className="row form-group">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="conteudo_extratoconta">
                                                <div className="list-group">
                                                    <li className="list-group-item fundo-cinza-claro">
                                                        <div className="row">
                                                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                                <b><span className="titulo-tabelas">{LABEL_COMUNS.DATA}</span></b>
                                                            </div>
                                                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                                                <b><span className="titulo-tabelas">{LABEL_COMUNS.ESTABELECIMENTO}</span></b>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 alinhamento_valor">
                                                                <b><span className="titulo-tabelas">{LABEL_COMUNS.VALOR}</span></b>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <div>
                                                    </div>
                                                    <li className="list-group-item">
                                                        <div className="row">
                                                            <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                                <span className="texto-extrato-detalhe">
                                                                    {formatarDataHoraBR(this.state.linhaDoExtratoSelecionada.dataHora)}
                                                                </span>
                                                            </div>
                                                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                                                <span className="texto-extrato-detalhe">
                                                                    {this.state.linhaDoExtratoSelecionada.descricao}
                                                                </span>
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 alinhamento_valor">
                                                                <b>
                                                                    {formataMoedaComSimboloESinalNegacao(this.state.linhaDoExtratoSelecionada.valor, this.state.linhaDoExtratoSelecionada.tipo, '')}
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <p className="texto_filtro_ip"><b>{LABEL_COMUNS.CLIENTE_PROJETO}</b></p>
                                            <FormGroup controlId="formControlsSelect">
                                                <FormControl componentClass="select"
                                                    style={{ height: '32px', paddingTop: '4px', paddingLeft: '10px' }}
                                                    inputRef={(ref) => this.prestacaoContaProjeto = ref}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.PROJETO_SELECIONADO)}
                                                    value={this.state.projetoSelecionado}>
                                                    <option value="">Selecione o Cliente/Projeto</option>
                                                    {
                                                        this.state.listaDePrestacaoDeContasProjetos.map((projeto, idx) =>
                                                            <option key={idx} value={projeto.nome}>{projeto.nome}</option>
                                                        )
                                                    }
                                                </FormControl>
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                            <p className="texto_filtro_ip"><ControlLabel>{LABEL_COMUNS.CLASSIFICACAO}</ControlLabel></p>
                                            <FormGroup controlId="formControlsSelect">
                                                <FormControl componentClass="select"
                                                    style={{ height: '32px', paddingTop: '4px', paddingLeft: '10px' }}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.CLASSIFICACAO_SELECIONADA)}
                                                    value={this.state.classificacaoSelecionada}>
                                                    <option value="">{LABEL_COMUNS.SELECIONE}</option>
                                                    {this.state.listaDePrestacaoDeContasClassificacoes.map((classificacao, idx) => {
                                                        return (
                                                            <option key={idx} value={classificacao.nome}>{classificacao.nome}</option>
                                                        );
                                                    })
                                                    }
                                                </FormControl>
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                            <p className="texto_filtro_ip"><b>{LABEL_COMUNS.OBSERVACOES}</b></p>
                                            <FormGroup validationState={this.state.descricaoSolicitacaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl
                                                    type="text"
                                                    maxLength={100}
                                                    className="mensagens-erro"
                                                    placeholder={LABEL_COMUNS.OBSERVACOES}
                                                    id={CAMPOS.DESCRICAO_SOLICITACAO}
                                                    name={CAMPOS.DESCRICAO_SOLICITACAO}
                                                    value={this.state.descricaoSolicitacao}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.DESCRICAO_SOLICITACAO)}>
                                                </FormControl>
                                                <ShowLineError error={this.state.descricaoSolicitacaoErro} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="row espacamento_campos">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-esquerda">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-esquerda">
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                                                    <InputImageFilePreview
                                                        imagePreview={imagem_1}
                                                        setExibirImagem={this.setExibirImagem}
                                                        onChange={(file) => this.setPropsFile(file, CAMPOS.IMAGENS_PRESTACAO_DE_CONTAS.IMAGEM_1)}
                                                    />
                                                </div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                                                    <InputImageFilePreview
                                                        imagePreview={imagem_2}
                                                        setExibirImagem={this.setExibirImagem}
                                                        onChange={(file) => this.setPropsFile(file, CAMPOS.IMAGENS_PRESTACAO_DE_CONTAS.IMAGEM_2)}
                                                    />
                                                </div>
                                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                                                    <InputImageFilePreview
                                                        imagePreview={imagem_3}
                                                        setExibirImagem={this.setExibirImagem}
                                                        onChange={(file) => this.setPropsFile(file, CAMPOS.IMAGENS_PRESTACAO_DE_CONTAS.IMAGEM_3)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">

                                            <FooterBotoesModalComponent
                                                btn1Label={LABEL_COMUNS.CANCELAR}
                                                btn1OnClick={() => {
                                                    const cancelarPrestacaoDeContas = true;
                                                    this.setPropsCampos(retornaObjetoTarget(MENSAGENS.PESSOA.PRESTACAO_DE_CONTAS.CONFIRMACAO(cancelarPrestacaoDeContas)), CAMPOS.MENSAGEM_CONFIRMACAO);
                                                    this.setExibirModalConfirmacao(true);
                                                    this.setState({ cancelarPrestacaoDeContas });
                                                }}
                                                btn2Label={LABEL_COMUNS.SALVAR}
                                                btn2OnClick={() => {
                                                    this.setExibirModalConfirmacao(true);
                                                    this.setPropsCampos(retornaObjetoTarget(MENSAGENS.PESSOA.PRESTACAO_DE_CONTAS.CONFIRMACAO()), CAMPOS.MENSAGEM_CONFIRMACAO);
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Grid cols="11 11 11 11" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                    </Grid>
                </GridRow>
            </div >
        );
    }
}

const mapStateToProps = state => ({ prestacaoConta: state.PessoaReducer });

export default connect(mapStateToProps, null)(PrestacaoConta);
