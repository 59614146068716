import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import React, { Component } from 'react';

import { formatarCampoValor } from '../util/extrato/extrato-util';
import { formatarDataCompletaBR, formatarDataBR } from '../util/data/dataUtil';
import { getComprovantePDF, getHistoricoDeComprovantes, getOperacoesComprovante } from '../../servicos/comprovante/comprovante-service';
import { getPrestacaoDeContas } from '../../servicos/pessoas/pessoa-service';
import { LABEL_COMUNS, TIPO_DE_COMPROVANTE, TIPO_DE_MOVIMENTACAO } from '../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import ComprovanteModal from '../componentes-uteis/comprovante/comprovante-modal';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import If from '../componentes-uteis/if/if';
import Loading from '../loading-fenix/loading';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import Grid from '../componentes-uteis/grid/grid';

class GridExtrato extends Component {
    constructor() {
        super();

        this.state = {
            exibirModalComprovante: false,
            operacoesComprovante: [],
            exibirModalComprovanteErro: false
        };

        this.buscaOperacoesComprovante = this.buscaOperacoesComprovante.bind(this);
        this.getDadosComprovante = this.getDadosComprovante.bind(this);
        this.downloadComprovantePDF = this.downloadComprovantePDF.bind(this);
    }

    componentDidMount() {
        this.buscaOperacoesComprovante();        
    }

    async buscaOperacoesComprovante() {
        const { data } = await getOperacoesComprovante('operacoesComprovante');
        this.setState({ operacoesComprovante: data.valor.reduce((acc, cur) => acc.concat(cur.operacao), []) });
    }

    async getDadosComprovante(operacaoId) {
        const { data } = await getHistoricoDeComprovantes(this.props.contaSelecionada.id, { operacaoId });

        if (data.quantidadeTotalRegistros > 0) {
            this.setState({ dadosComprovante: data.resultado[0], exibirModalComprovante: true });
        } else {
            this.setState({ exibirModalComprovanteErro: true });
        }
    }

    async downloadComprovantePDF(comprovante) {
        const data = await getComprovantePDF(this.props.contaSelecionada.id, comprovante.operacaoId, comprovante.comprovanteId);

        const linkSource = URL.createObjectURL(data);
        const downloadLink = document.createElement('a');
        const fileName = 'Comprovante.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(linkSource);
    }

    render() {
        const TARIFA = 'tarifa';
        return (
            <div>
                <ModalComponent
                    exibirModal={this.state.exibirModalComprovanteErro}
                    header="Comprovante"
                    body={<p>Algo inesperado aconteceu. Tente novamente!</p>}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label="Ok"
                            btn2OnClick={() => this.setState({ exibirModalComprovanteErro: false })}
                        />
                    }
                />

                {this.state.exibirModalComprovante && (
                    <ComprovanteModal
                        header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE(this.props.contaSelecionada.descricao)}
                        bsSize="lg"
                        onClose={() => this.setState({ exibirModalComprovante: false })}
                        exibirModal={this.state.exibirModalComprovante}
                        dadosComprovante={this.state.dadosComprovante}
                        downloadComprovantePDF={this.downloadComprovantePDF}
                    />
                )}
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo centralizado">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{this.props.titulo}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <div className="row form-group">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="conteudo_extratoconta">
                                                {this.props.exibirLoadingExtrato ?
                                                    <div className="alinhamento-centro">
                                                        <Loading />
                                                    </div>
                                                    :
                                                    this.props.exibirLoadingExtrato !== undefined &&
                                                    <React.Fragment>
                                                        <If test={this.props.linhasDoExtrato && this.props.linhasDoExtrato.length > 0}>
                                                            <div className="list-group">
                                                                <li className="list-group-item fundo-cinza-claro">
                                                                    <div className="row">
                                                                        <div className="col-sm-2 col-md-2 col-lg-2">
                                                                            <b><span className="titulo-tabelas">{this.props.titulo === 'Extrato Conta' ? 'Data' : 'Data/Hora'}</span></b>
                                                                        </div>
                                                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                                                            <b><span className="titulo-tabelas">{LABEL_COMUNS.NOME}</span></b>
                                                                        </div>
                                                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                                                            <b><span className="titulo-tabelas">{LABEL_COMUNS.DETALHE}</span></b>
                                                                        </div>
                                                                        <div className="col-sm-1 col-md-1 col-lg-1">
                                                                            <b><span className="titulo-tabelas">{LABEL_COMUNS.DESCRICAO}</span></b>
                                                                        </div>
                                                                        <div className="col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                                            <b><span className="titulo-tabelas-valor-extrato">{LABEL_COMUNS.VALOR}</span></b>
                                                                        </div>
                                                                        <div className="titulo-tabelas-acao">
                                                                            <div className="col-sm-1 col-md-1 col-lg-1 alinhamento-direita">
                                                                                <b><span className="titulo-tabelas">{LABEL_COMUNS.ACAO}</span></b>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li className="list-group-item">
                                                                    <div className="row">
                                                                        <div cols="col-md-12" />
                                                                    </div>
                                                                </li>
                                                                {
                                                                    this.props.linhasDoExtrato.map((linhaExtrato, idx) => {
                                                                        linhaExtrato.tipoPrestacaoDeContas = this.props.tipoPrestacaoDeContas;
                                                                        const alinhamentoSaldo = linhaExtrato.saldoFinalDoDia ? 'alinhamento-esquerda' : 'alinhamento-direita';
                                                                        //const exibeComprovantes = this.state.operacoesComprovante.includes(linhaExtrato.nome) && linhaExtrato.tipo === TIPO_DE_MOVIMENTACAO.DEBITO && !linhaExtrato.nomeExibicao.toLowerCase().includes(TARIFA);
                                                                        const exibeComprovantes = false;
                                                                        const exibirPrestacaoContas = linhaExtrato.nome !== LABEL_COMUNS.CARGA && linhaExtrato.nome !== LABEL_COMUNS.DESCARGA;
                                                                        return (
                                                                            <li key={idx} className="list-group-item">
                                                                                <If test={!linhaExtrato.saldoFinalDoDia}>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-2 col-md-2 col-lg-2">
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {linhaExtrato.dataHora && this.props.titulo === 'Extrato Conta' ? formatarDataBR(linhaExtrato.dataHora) : formatarDataCompletaBR(linhaExtrato.dataHora) }
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {linhaExtrato.nomeExibicao || linhaExtrato.nome}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {linhaExtrato.descricao}
                                                                                            </span>
                                                                                        </div>
                                                                                        <div className="col-sm-1 col-md-1 col-lg-1">
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {linhaExtrato.tagCliente}
                                                                                            </span>
                                                                                        </div>

                                                                                        <Grid cols="2 2 2 2" className={alinhamentoSaldo}>
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {formatarCampoValor(linhaExtrato.tipo, linhaExtrato.valor, linhaExtrato.moeda)}
                                                                                            </span>
                                                                                        </Grid>

                                                                                        <div className="titulo-tabelas-acao-btn">
                                                                                            <div className="col-sm-1 col-md-1 col-lg-1 alinhamento-direita">
                                                                                                <Dropdown id="menuOpcoes" pullRight >
                                                                                                    <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas botao-acao-extrato">
                                                                                                        <Glyphicon glyph="option-horizontal" />
                                                                                                    </Dropdown.Toggle>
                                                                                                    <Dropdown.Menu >
                                                                                                        <If test={(linhaExtrato.nome === TIPO_DE_COMPROVANTE.TRANSFERENCIA_ENTRE_CONTAS_DIGITAIS)}>
                                                                                                            <MenuItem onClick={this.props.setExibirModalComprovante}>{LABEL_COMUNS.CARREGAR_CARTAO}</MenuItem>
                                                                                                        </If>
                                                                                                        <If test={this.props.exibirLinkPrestacaoDeContas && exibirPrestacaoContas}>
                                                                                                            <MenuItem onClick={() => this.getPrestacaoDeContas(linhaExtrato)}>{LABEL_COMUNS.PRESTAR_CONTAS}</MenuItem>
                                                                                                        </If>
                                                                                                        <MenuItem
                                                                                                            onSelect={() => this.props.setLinhaDoExtratoSelecionada(linhaExtrato)}
                                                                                                            onClick={() => {
                                                                                                                if (linhaExtrato.listaDeTransacoes) {
                                                                                                                    this.props.setExbirModalDetalhesTransacao();
                                                                                                                } else {
                                                                                                                    this.props.setExibirModalDetalhes();
                                                                                                                }
                                                                                                            }}>{LABEL_COMUNS.DETALHES_DA_TRANSACAO()}
                                                                                                        </MenuItem>
                                                                                                        <If test={exibeComprovantes}>
                                                                                                            <MenuItem
                                                                                                                onSelect={() => this.props.setLinhaDoExtratoSelecionada(linhaExtrato)}
                                                                                                                onClick={() => { this.getDadosComprovante(linhaExtrato.operacaoId); }}>
                                                                                                                {LABEL_COMUNS.COMPROVANTE}
                                                                                                            </MenuItem>
                                                                                                        </If>
                                                                                                    </Dropdown.Menu>
                                                                                                </Dropdown>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </If>
                                                                                <If test={linhaExtrato.saldoFinalDoDia}>
                                                                                    <div className="row">
                                                                                        <Grid className={alinhamentoSaldo}>
                                                                                            <span style={{ fontSize: '11px', fontWeight: 'bold' }}>
                                                                                                {linhaExtrato.nomeExibicao}
                                                                                            </span>&nbsp;
                                                                                            <span style={{ fontSize: '11px' }}>
                                                                                                {formatarCampoValor(linhaExtrato.tipo, linhaExtrato.valor, linhaExtrato.moeda)}
                                                                                            </span>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </If>
                                                                            </li>
                                                                        );
                                                                    }
                                                                    )}
                                                                <br />
                                                                <If test={this.props.exibirMaisRegistros}>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-default btn-block fonte-tamanho-11"
                                                                        onClick={this.props.buscarMaisRegistros}>
                                                                        {LABEL_COMUNS.VISUALIZAR_MAIS_MOVIMENTACOES}
                                                                    </button>
                                                                </If>
                                                            </div>
                                                        </If>
                                                        <If test={this.props.linhasDoExtrato.length <= 0 && !this.props.exibirLoadingExtrato}>
                                                            <span>{LABEL_COMUNS.NAO_HA_ITENS_EXTRATO}</span>
                                                        </If>
                                                    </React.Fragment>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                    </div>
                </div>
            </div>
        );
    }

    getPrestacaoDeContas = async (linhaDoExtratoSelecionada) => {
        this.props.setLinhaDoExtratoSelecionada(linhaDoExtratoSelecionada);
        this.props.exibirLoadingPrestacao();

        const { contaSelecionada } = this.props;

        const prestacaoDeContasRecuperada = await getPrestacaoDeContas(contaSelecionada, linhaDoExtratoSelecionada) || {};

        if (Object.getOwnPropertyNames(prestacaoDeContasRecuperada).length) {
            this.props.setPrestacaoDeContasRecuperada(prestacaoDeContasRecuperada);
            this.props.exibirModalConfirmaPrestacaoConta();
        } else {
            this.props.history.push({ pathname: ROTAS_CAMINHOS.PRESTAR_CONTAS, state: { contaSelecionada, linhaDoExtratoSelecionada, prestacaoDeContasRecuperada } });
        }

        this.props.exibirLoadingPrestacao();
    }
}

export default GridExtrato;
