import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InputMask from 'react-input-mask';
import CurrencyInput from 'react-currency-input';
import { Button, Checkbox, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import ContaSelecionada from '../../contas/conta-selecionada';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import Grid from '../grid/grid';
import If from '../../componentes-uteis/if/if';
import { LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, TIPO_DE_COMPROVANTE, TIPO_DOCUMENTO} from '../../util/contantes-comuns/constantes-comuns';
import * as actions from './pagamento-action';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import ModalComponent from '../modal-component/modal-component';
import FooterBotoesModalComponent from '../modal-component/footer-botoes-modal-component';
import { dataInvalida, dataMenorIgualAHoje, dataMenorQueHoje, validaDataMaiorQueUmAno} from 'componentes/util/data/dataUtil';
import { CNPJ, CPF } from 'cpf_cnpj';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar} from '../../../reducer/outorga/outorga-operacao-reducer';

class PagamentoContas extends Component {

    constructor(props) {
        super(props);
        this.state = { dadosForm: {}, exibirModalConfirmacao: false };
    }

    componentDidMount() {
        this.props.setSolicitacaoPagamentoContaSelecionada(this.props.contaSelecionada);
        this.props.setSolicitacaoPagamentoTipoDocumento(TIPO_DOCUMENTO.CPF.value);
        this.props.setSolicitacaoPagamentoAgendamentoHoje({target:{checked:true}});
    }

    dashboardConta() {
        return (
            window.location.href = ROTAS_CAMINHOS.DASHBOARD_CONTA
        );
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.estadoPagamentoContas.tokenErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token </ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={this.props.estadoPagamentoContas.tokenSMS}
                        onChange={this.props.setSolicitacaoPagamentoArmazenarTokenSMS}
                        mask="999999"
                        maskChar={null}
                    />
                    {this.props.estadoPagamentoContas.tokenErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoPagamentoContas.tokenErro}</span> : null}
                    {this.props.estadoPagamentoContas.reenviarErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoPagamentoContas.reenviarErro}</span> : null}
                    {this.props.estadoPagamentoContas.reenviarToken ? <span role="alert">{this.props.estadoPagamentoContas.reenviarToken}</span> : null}
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.solicitacaoPagamentoReenviarToken(this.props.estadoPagamentoContas.operacaoId)}> Reenviar Token </a><br />
            </div>
        );
    }

    montaRodapeModalToken = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => this.props.setExibirModalToken(false)}
                btn2OnClick={() => this.solicitacaoPagamentoTransacao(this.props.estadoPagamentoContas)} />
        );
    }

    montaCorpoModalSucesso() {
        return (
            <div>
                <span>{this.props.estadoPagamentoContas.msg || MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.SUCESSO}</span>
            </div>
        );
    }

    montaRodapeModalSucesso = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => this.dashboardConta()} />
        );
    }

    solicitacaoPagamentoToken(dadosForm) {
        let saldoSuficiente = true;
        let codigoBarrasValido = true;
        let numeroDocumentoValido = true;
        let dataDeVencimentoValida = true;
        let dataDeAgendamentoValida = true;
        let nomeBeneficiarioValido = true;

        if (!dadosForm.codigoBarras || dadosForm.codigoBarras.length < 46) {
            this.props.getSolicitacaoPagamentoExibirErroCodigoBarras(true);
            codigoBarrasValido = false;
        }

        if (!dadosForm.dataAgendamento && dadosForm.valor > this.props.saldoconta.saldo) {
            this.props.getSolicitacaoPagamentoExibirErroSaldo(true);
            saldoSuficiente = false;
        }

        if (!dadosForm.dataAgendamento && dadosForm.valor <= 0) {
            this.props.getSolicitacaoPagamentoExibirErroValor(true);
            saldoSuficiente = false;
        }

        if (!dadosForm.dataVencimento) {
            this.props.getSolicitacaoPagamentoExibirErroDataVencimento(true);
            dataDeVencimentoValida = false;
        }

        if (dadosForm.dataVencimento) {
            if (dataMenorQueHoje(dadosForm.dataVencimento)) {
                dataDeVencimentoValida = false;
                this.props.getSolicitacaoPagamentoExibirErroDataVencimentoMenor(true);
            }
            if (dataInvalida(dadosForm.dataVencimento)) {
                this.props.getSolicitacaoPagamentoExibirErroDataVencimento(true);
                dataDeVencimentoValida = false;
            }
        }

        if (!dadosForm.nomeBeneficiario) {
            this.props.getSolicitacaoPagamentoExibirErroNomeBeneficiario(true);
            nomeBeneficiarioValido = false;
        }

        if (!dadosForm.numeroDocumentoBeneficiario) {
            this.props.getSolicitacaoPagamentoExibirErroDocumentoBeneficiario(true);
        }

        if (dadosForm.tipoDocumentoBeneficiario === TIPO_DOCUMENTO.CPF.value) {
            if (!CPF.isValid(dadosForm.numeroDocumentoBeneficiario)) {
                numeroDocumentoValido = false;
                this.props.getSolicitacaoPagamentoExibirErroDocumentoBeneficiario(true);
            }
        }

        if (dadosForm.tipoDocumentoBeneficiario === TIPO_DOCUMENTO.CNPJ.value) {
            if (!CNPJ.isValid(dadosForm.numeroDocumentoBeneficiario)) {
                numeroDocumentoValido = false;
                this.props.getSolicitacaoPagamentoExibirErroDocumentoBeneficiario(true);
            }
        }

        if (!dadosForm.dataAgendamento && !dadosForm.dataAgendamentoHoje) {
            this.props.getSolicitacaoPagamentoExibirErroDataAgendamento(true);
            dataDeAgendamentoValida = false;
        }

        if (dadosForm.dataAgendamento) {
            if (dataMenorIgualAHoje(dadosForm.dataAgendamento)) {
                dataDeAgendamentoValida = false;
                this.props.getSolicitacaoPagamentoExibirErroDataAgendamentoMenorIgualHoje(true);
            }
            if (dataInvalida(dadosForm.dataAgendamento)) {
                this.props.getSolicitacaoPagamentoExibirErroDataAgendamento(true);
                dataDeAgendamentoValida = false;
            }
            if (validaDataMaiorQueUmAno(dadosForm.dataAgendamento)) {
                this.props.getSolicitacaoPagamentoExibirErroDataAgendamentoMaiorDoQueUmAno(true);
                dataDeAgendamentoValida = false;
            }
        }

        if (saldoSuficiente && codigoBarrasValido && numeroDocumentoValido && dataDeVencimentoValida && dataDeAgendamentoValida && nomeBeneficiarioValido) {
            this.solicitarPagamento(dadosForm);
        }

    }

    solicitacaoPagamentoReenviarToken(operacaoId) {
        let pagamentoAgendado = (this.props.estadoPagamentoContas.dataAgendamento !== '' && this.props.estadoPagamentoContas.dataAgendamentoHoje === '') ? true : false;
        this.props.reenviarTokenPagamento(operacaoId, pagamentoAgendado);
    }

    solicitacaoPagamentoTransacao(estadoPagamentoContas) {
        let agendamento;
        if (this.props.estadoPagamentoContas.dataAgendamentoHoje !== '' && this.props.estadoPagamentoContas.dataAgendamento === '') {
            agendamento = {
                tipoTransacao: 'aprovacao',
            };
        } else if (this.props.estadoPagamentoContas.dataAgendamentoHoje === '' && this.props.estadoPagamentoContas.dataAgendamento !== '') {
            agendamento = {
                tipoTransacao: 'agendamento',
                dataAgendamento: this.props.estadoPagamentoContas.dataAgendamento
            };
        } else {
            return;
        }
        this.props.solicitarPagamento(estadoPagamentoContas.operacaoId, estadoPagamentoContas.tokenSMS, agendamento);
    }

    retornaCorpoModalConfirmacao = () => {
        const { dadosForm: { dataAgendamento } } = this.state;
        return (
            <div dangerouslySetInnerHTML={{ __html: MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.CONFIRMACAO(dataAgendamento) }} />
        );
    }

    retornaRodapeModalConfirmacao = () => {
        const { dadosForm } = this.state;
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setState({ exibirModalConfirmacao: false });
                }}
                btn2OnClick={() => {
                    this.setState({ exibirModalConfirmacao: false });
                    const necessitaToken = dadosForm.dataAgendamento ? this.props.outorgaPagamentoBoletoBancario.agendamento : this.props.outorgaPagamentoBoletoBancario.aprovacao;
                    const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
                    this.props.requisitarTokenPagamento(dadosForm, necessitaToken, outorgaPodeAprovar);
                }} />
        );
    }

    retornaCorpoModalContinuarComOperacao = () => {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.exibirModalContinuarComOperacao }} />
        );
    }

    retornaRodapeModalContinuarComOperacao = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                }}
                btn2OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined, exibirModalConfirmacao: true });
                }} />
        );
    }

    solicitarPagamento = (dadosForm) => {
        const { outorgaPerfils: { podeAprovar, podeConsultar, podeCriar } } = this.props;
        const { dataAgendamento } = dadosForm;

        const cen1 = 'Confirma o cadastro da operação?';
        const cen2 = 'Confirma a aprovação da operação?';
        const cen3 = 'Confirma o cadastro e a aprovação da operação?';
        const cen4 = 'Confirma o agendamendo da operação?';

        const msg = dataAgendamento ? cen4 : (podeAprovar && podeCriar ? cen3 : (podeAprovar ? cen2 : cen1));
        this.setState({ exibirModalContinuarComOperacao: msg, dadosForm });
    }

    render() {
        const contaSelecionada = this.props.contaSelecionada;
        return (
            <div>
                <ContaSelecionada contaSelecionada={contaSelecionada} />

                <ModalComponent bsSize="small" exibirModal={this.props.estadoPagamentoContas.exibirModalToken}
                    name="modal-token" header={LABEL_COMUNS.PAGAMENTO_DE_CONTAS}
                    body={this.montaCorpoModalToken()} footer={this.montaRodapeModalToken()} />

                <ModalComponent bsSize="small" exibirModal={!!this.props.estadoPagamentoContas.exibirModalSucesso}
                    name="modal-sucesso" header={LABEL_COMUNS.PAGAMENTO_DE_CONTAS}
                    body={this.montaCorpoModalSucesso()} footer={this.montaRodapeModalSucesso()} />

                <ModalComponent exibirModal={this.state.exibirModalConfirmacao}
                    name="modalConfirmacao" header={LABEL_COMUNS.PAGAMENTO_DE_CONTAS}
                    body={this.retornaCorpoModalConfirmacao()} footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent exibirModal={this.state.exibirModalContinuarComOperacao}
                    name="modalContinuarComOperacao" header={LABEL_COMUNS.PAGAMENTO_DE_CONTAS}
                    body={this.retornaCorpoModalContinuarComOperacao()} footer={this.retornaRodapeModalContinuarComOperacao()} />

                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 ">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.PAGAMENTO_DE_CONTAS}</div>
                            <div className="panel-body">
                                <Grid cols="12 12 12 12">
                                    <strong>{LABEL_COMUNS.CODIGO_BARRAS}*</strong>
                                    <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroCodigoBarras ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            id="codigoBarras"
                                            className="mensagens-erro form-control"
                                            mask={MASCARA_CAMPOS.CODIGO_DE_BARRAS} maskChar=""
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                            placeholder={LABEL_COMUNS.CODIGO_BARRAS}
                                            value={this.props.estadoPagamentoContas.codigoBarras}
                                            onChange={this.props.setSolicitacaoPagamentoCodigoBarras}
                                        />
                                        <If test={this.props.estadoPagamentoContas.exibirErroCodigoBarras || this.props.setSolicitacaoPagamentoCodigoBarras.lenght < '44'} >
                                            <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.CODIGO_BARRAS}</span>
                                        </If>
                                    </FormGroup>
                                </Grid>
                                <Grid cols="2 2 2 2">
                                    <strong>{LABEL_COMUNS.VALOR}*</strong>
                                    <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroValor ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <CurrencyInput
                                            id="valor"
                                            thousandSeparator=""
                                            decimalSeparator=","
                                            className="form-control mensagens-erro"
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                            placeholder={LABEL_COMUNS.VALOR}
                                            maxLength={14}
                                            value={this.props.estadoPagamentoContas.valor}
                                            onChange={this.props.setSolicitacaoPagamentoValor}
                                        />
                                        <If test={this.props.estadoPagamentoContas.exibirErroValor} >
                                            <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.VALOR}</span>
                                        </If>
                                        <If test={this.props.estadoPagamentoContas.exibirErroSaldo} >
                                            <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.SALDO}</span>
                                        </If>
                                    </FormGroup>
                                </Grid>
                                <Grid cols="2 2 2 2">
                                    <strong>{LABEL_COMUNS.VENCIMENTO}*</strong>
                                    <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroDataVencimento || this.props.estadoPagamentoContas.exibirErroDataVencimentoMenor ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            id="dataVencimento"
                                            className="mensagens-erro form-control"
                                            mask={MASCARA_CAMPOS.DATA} maskChar=""
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                            placeholder={'DD/MM/AAAA'}
                                            value={this.props.estadoPagamentoContas.dataVencimento}
                                            onChange={this.props.setSolicitacaoPagamentoDataVencimento}
                                        />
                                        <If test={this.props.estadoPagamentoContas.exibirErroDataVencimento} >
                                            <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.VENCIMENTO}</span>
                                        </If>
                                        <If test={this.props.estadoPagamentoContas.exibirErroDataVencimentoMenor} >
                                            <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.VENCIMENTO_MENOR}</span>
                                        </If>
                                    </FormGroup>
                                </Grid>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <Grid cols="4 4 4 4">
                                            <strong>{LABEL_COMUNS.BENEFICIARIO}*</strong>
                                            <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroNomeBeneficiario ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl
                                                    type="text"
                                                    id="beneficiario"
                                                    maxLength={150}
                                                    className="mensagens-erro form-control"
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                    placeholder={LABEL_COMUNS.BENEFICIARIO}
                                                    value={this.props.estadoPagamentoContas.nomeBeneficiario}
                                                    onChange={this.props.setSolicitacaoPagamentoNomeBeneficiario}
                                                />
                                                <If test={this.props.estadoPagamentoContas.exibirErroNomeBeneficiario} >
                                                    <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.NOME_BENEFICIARIO}</span>
                                                </If>
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <strong>{LABEL_COMUNS.TIPO_DOCUMENTO}*</strong>
                                            <FormControl className="mensagens-erro"
                                                id="tipoDocumento"
                                                componentClass="select"
                                                placeholder={LABEL_COMUNS.SELECIONE}
                                                inputRef={(ref) => this.tipoDocumentoSelecionado = ref}
                                                value={this.props.estadoPagamentoContas.tipoDocumento}
                                                onChange={() => this.props.setSolicitacaoPagamentoTipoDocumento(this.tipoDocumentoSelecionado.value)}
                                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}>
                                                <option value={TIPO_DOCUMENTO.CPF.value} defaultValue>CPF</option>
                                                <option value={TIPO_DOCUMENTO.CNPJ.value}>CNPJ</option>
                                            </FormControl>
                                        </Grid>
                                        <Grid cols="3 3 3 3">
                                            <strong>{LABEL_COMUNS.DOCUMENTO}*</strong>
                                            <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroNumeroDocumentoBeneficiario ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <InputMask
                                                    id="documentoBeneficiario"
                                                    className="form-control mensagens-erro"
                                                    placeholder={LABEL_COMUNS.DOCUMENTO}
                                                    mask={this.props.estadoPagamentoContas.defaultMaskPattern} maskChar=""
                                                    value={this.props.estadoPagamentoContas.numeroDocumentoBeneficiario}
                                                    onChange={this.props.setSolicitacaoPagamentoDocumentoBeneficiario}
                                                    maxLength={18}
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                                                <If test={this.props.estadoPagamentoContas.exibirErroNumeroDocumentoBeneficiario} >
                                                    <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.DOCUMENTO}</span>
                                                </If>
                                            </FormGroup>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <Grid cols="12 12 12 12">
                                            <strong>{LABEL_COMUNS.DESCRICAO}</strong>
                                            <FormGroup>
                                                <InputMask
                                                    id="codigoBarras"
                                                    className="mensagens-erro form-control"
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                    placeholder={LABEL_COMUNS.DESCRICAO}
                                                    value={this.props.estadoPagamentoContas.descricao}
                                                    onChange={this.props.setSolicitacaoPagamentoDescricao}
                                                    maxLength={20}
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <div className="row">
                                        <Grid cols="2 2 2 2">
                                            <strong>{LABEL_COMUNS.DATA_DO_PAGAMENTO}*</strong>
                                            <FormGroup validationState={this.props.estadoPagamentoContas.exibirErroDataAgendamento || this.props.estadoPagamentoContas.exibirErroDataAgendamentoMenorIgualHoje ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Checkbox checked={this.props.estadoPagamentoContas.agendamentoHojeChecked} onClick={(e) => this.props.setSolicitacaoPagamentoAgendamentoHoje(e)}><strong>{LABEL_COMUNS.HOJE}</strong></Checkbox>
                                            </FormGroup>
                                        </Grid>
                                        <strong>&nbsp;</strong>
                                        {/* 
                                        O código foi comentado porque é uma retirada provisória
                                        <FormGroup validationState={
                                            this.props.estadoPagamentoContas.exibirErroDataAgendamento ||
                                                this.props.estadoPagamentoContas.exibirErroDataAgendamentoMenorIgualHoje ||
                                                this.props.estadoPagamentoContas.exibirErroDataAgendamentoMaiorDoQueUmAno ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita" style={{ paddingTop: '9px' }}>
                                                <strong>{LABEL_COMUNS.AGENDAR_PARA}</strong>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                <InputMask
                                                    id="dataAgendamento"
                                                    className="mensagens-erro form-control"
                                                    disabled={this.props.estadoPagamentoContas.agendamentoHojeChecked}
                                                    mask={MASCARA_CAMPOS.DATA} maskChar=""
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                    placeholder={'DD/MM/AAAA'}
                                                    value={this.props.estadoPagamentoContas.dataAgendamento}
                                                    onChange={this.props.setSolicitacaoPagamentoDataAgendamento} />
                                                <If test={this.props.estadoPagamentoContas.exibirErroDataAgendamento} >
                                                    <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.AGENDAMENTO_DATA}</span>
                                                </If>
                                                <If test={this.props.estadoPagamentoContas.exibirErroDataAgendamentoMenorIgualHoje} >
                                                    <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.AGENDAMENTO_DATA_MENORIGUAL}</span>
                                                </If>
                                                <If test={this.props.estadoPagamentoContas.exibirErroDataAgendamentoMaiorDoQueUmAno} >
                                                    <span className="mensagens-erro-span" role="alert">{MENSAGENS.CONTAS.PAGAMENTOS_DE_CONTAS.AGENDAMENTO_DATA_MAIOR_QUE_UM_ANO}</span>
                                                </If>
                                            </div>
                                        </FormGroup> */}
                                    </div>
                                    <div className="row">&nbsp;</div>
                                </div>
                                <div className="row">
                                    <Grid cols="12 12 12 12" className="alinhamento-centro">
                                        <Button bsStyle="primary" onClick={() => this.solicitacaoPagamentoToken(this.props.estadoPagamentoContas)}>{LABEL_COMUNS.SOLICITAR_PAGAMENTO}</Button>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>
                <div className="row alinhamento-centro">
                    <Grid cols="12 12 12 12">
                        <If test={this.props.estadoPagamentoContas.exibirLoading}>
                            <LoadingFenix />
                        </If>
                    </Grid>
                </div>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    estadoPagamentoContas: state.PagamentoContas,
    saldoconta: state.MostrarSaldoReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    outorgaPagamentoBoletoBancario: outorgaParametroOperacao(state.OutorgaOperacaoReducer, TIPO_DE_COMPROVANTE.PAGAMENTO_BOLETO_BANCARIO)

});

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PagamentoContas);
