import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './dados-pf-action';
import DadosPessoaFisica from './dados-pf';

function mapStateToProps(state) {
    return {
        dadospessoafisicareducer: state.DadosPessoaFisicaReducer
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DadosPessoaFisica);
