import { HEADER_API, HEADER_UPLOAD_FILE, HTTP_METODOS, montaUrlFetch, montaUrlFetchWebShim, montaUrlPathParam, urlServicos, HEADER_WITHOUT_AUTORIZATION } from '../url-servicos';
import { environment } from '../../configuracoes/environment';
import { DESCRICAO_CONTA, METODOS, MENSAGENS} from '../../componentes/util/contantes-comuns/constantes-comuns';

export const getSaldoContaOuCartao = contaId => {
    return fetch(montaUrlFetch(urlServicos.SALDO_CONTA(contaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const salvarEdicaoDeContaEAvatar = async dadosConta => {
    const { contaId } = dadosConta;
    const { apelido } = dadosConta;
    const { avatarSelecionado } = dadosConta;
    return await fetchEdicaoDeConta(contaId, apelido)
        .then(async resp => avatarSelecionado instanceof File ? { ...Object.assign({}, await salvarAvatar(contaId, avatarSelecionado)), conta: resp } : { conta: resp })
        .then(result => result)
        .catch(error => error);
};

export const fetchEdicaoDeConta = (contaId, apelido) => {
    return fetch(montaUrlFetch(montaUrlPathParam(urlServicos.GET_CONTA_ID(contaId))), {
        method: HTTP_METODOS.PATCH,
        headers: HEADER_API().headers,
        body: JSON.stringify({ apelido })
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const salvarAvatar = (contaId, avatar) => {
    return gerarUrlPreAssinadaImagemAvatarConta(contaId).then(async resp => ({
        urlPreAssinada: resp,
        upload: await uploadImagemAvatarContaS3(
            resp.data.presignedUrlRequest,
            avatar
        )
    }));
};

export const fetchGetConta = (pessoaId, pessoaTitularId, exibeSaldo = true, tamanhoPagina = 1000) => {
    const parametrosQueryString = { contaDescricao: DESCRICAO_CONTA.CONTA_MOVIMENTO, exibeSaldo: exibeSaldo, tamanhoPagina, status: 'ativo' };
    if (pessoaTitularId) {
        parametrosQueryString.pessoaTitularId = pessoaTitularId;
    }
    return fetch(montaUrlFetch(urlServicos.CONTA_PESSOA_ID(pessoaId), parametrosQueryString), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetGetContaById = contaId => {
    return fetch(montaUrlFetch(urlServicos.CONTA_ID(contaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetFavorecidosConta = contaId => {
    return fetch(montaUrlFetch(urlServicos.FAVORECIDO(contaId), { tamanhoPagina: 1000 }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchCadastrarFavorecido = (contaId, dadosFavorecido) => {
    return fetch(montaUrlFetch(urlServicos.FAVORECIDO(contaId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: dadosFavorecido
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchAlterarFavorecido = (contaId, favorecidoId, dadosFavorecido) => {
    return fetch(montaUrlFetch(urlServicos.FAVORECIDO_ID(contaId, favorecidoId)), {
        method: HTTP_METODOS.PUT,
        headers: HEADER_API().headers,
        body: dadosFavorecido
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchExcluirFavorecido = (contaId, favorecidoId) => {
    return fetch(montaUrlFetch(urlServicos.FAVORECIDO_ID(contaId, favorecidoId)), {
        method: HTTP_METODOS.DELETE,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetBancos = () => {
    return fetch(montaUrlFetch(urlServicos.LISTA_BANCOS), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetPermitirFazerTed = async () => {  
    return await fetch(montaUrlFetchWebShim(urlServicos.CALENDAR_TED), {
        method: HTTP_METODOS.GET,
        headers: HEADER_WITHOUT_AUTORIZATION().headers        
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json(), action: METODOS.TRANSFERENCIA_BANCARIA.GET_HORARIO_PERMITIDO_FAZER_TED }) : Promise.reject({ error: !resp.ok, data: await resp.json(), action: METODOS.TRANSFERENCIA_BANCARIA.GET_HORARIO_PERMITIDO_FAZER_TED_ERRO }))
        .catch(() => ({ solicitacaoDeTransferenciaBancariaRealizada: { erro: true, erros: { message: MENSAGENS.CONTAS.TRANSFERENCIA_BANCARIA.FINALIZACAO.ERRO()}}, action: METODOS.TRANSFERENCIA_BANCARIA.GET_HORARIO_PERMITIDO_FAZER_TED_ERRO}));
};


const gerarUrlPreAssinadaImagemAvatarConta = contaId => {
    return fetch(montaUrlFetch(urlServicos.ARQUIVOS), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify({ bucket: environment.bucket_avatar_conta, id: contaId.toUpperCase() })
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

const uploadImagemAvatarContaS3 = (urlPreAssinada, arquivo) => {
    return fetch(urlPreAssinada, {
        method: HTTP_METODOS.PUT,
        headers: HEADER_UPLOAD_FILE.headers,
        body: arquivo
    }).then(resp => resp.ok ? { error: !resp.ok, data: { arquivoSalvoComSucesso: true } } : Promise.reject({ error: !resp.ok, data: { arquivoSalvoComSucesso: false } }));
};
