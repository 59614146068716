
import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import promise from 'redux-promise-middleware';

import todosReducers from './reducer';

const middleware = applyMiddleware(promise(), thunk);

export default createStore(
    todosReducers,
    compose(
        middleware
    )
);
