import React, { Component } from 'react';
import Avatar from '../../listar-contas/componente/avatar';
import { ControlLabel, Button } from 'react-bootstrap';
import ModalComponent from '../modal-component/modal-component';
import If from '../../componentes-uteis/if/if';
import { HEADER_API, montaUrlFetch, urlServicos, HTTP_METODOS, HEADER_UPLOAD_FILE } from '../../../servicos/url-servicos';
import { environment } from '../../../configuracoes/environment';
import { METODOS } from '../../contas/conta-departamento/conta-departamento-constantes';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import Axios from 'axios';

class ModalEditarConta extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillReceiveProps(nextProps) {
        let imagemAvatarUrl;

        if (this.props.flagListaContaDepartamento) {
            imagemAvatarUrl = nextProps.contaSelecionada.avatarPreSignedURL;
        } else {
            imagemAvatarUrl = nextProps.contaSelecionada.urlAvatar;
        }

        if (nextProps.contaSelecionada.id === this.props.contaSelecionada.id) {
            this.setState({ apelido: this.state.apelido || nextProps.contaSelecionada.apelido, contaSelecionada: nextProps.contaSelecionada, imagemAvatarUrl });
        } else {
            this.setState({ apelido: nextProps.contaSelecionada.apelido, contaSelecionada: nextProps.contaSelecionada, imagemAvatarUrl });
        }
    }

    render() {
        return (
            <div>
                <ModalComponent name="mdalEditarConta" className="fundo_bloqueado" exibirModal={this.props.exibirModalEditarConta}
                    header="Editar Conta"
                    body={
                        <div>
                            <div>
                                <div className="row">
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <label id="labelApelidoId">Apelido</label>
                                        <input type="text" className="form-control" value={this.state.apelido} onChange={(e) => this.setState({ apelido: e.target.value })} />
                                    </div>
                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6"></div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <label id="labelAvatarId">Avatar</label>
                                    <label id="label-file" htmlFor="imagem1" className="alterar-avatar">Alterar/inserir avatar<br />
                                    </label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                    <input onChange={this.carregarArquivoImagem1} onChangeCapture={this.setArquivoImagemAvatar} id="imagem1" type="file" accept="image/*" capture="camera" />
                                    <If test={this.state.imagemAvatarUrl !== ''}>
                                        <img className="avatar-img" src={this.state.imagemAvatarUrl} />
                                    </If>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.props.msgValidarImagemEditarConta ? <span className="mensagens-erro-span" role="alert">{this.props.msgValidarImagemEditarConta}</span> : null}
                                </div>
                            </div>
                        </div>
                    }
                    footer={<div className="alinhamento-direita">
                        <Button
                            bsStyle="default"
                            onClick={() => this.props.setExibirModalEditarConta()}>Cancelar
                        </Button>
                        <Button
                            bsStyle="primary"
                            onClick={() => {
                                Promise.all([
                                    this.props.salvarEditarConta(this.state.contaSelecionada.id, this.state.apelido),
                                    this.chamarApiUploadImagemAvatar()
                                ])
                                    .then(result => {
                                        if (this.props.flagListaContaDepartamento) {
                                            this.props.getListaContaDepartamento();
                                        }
                                    })
                                    .catch(err => {

                                    });
                            }
                            }>Salvar
                        </Button>
                    </div>}
                />
                <ModalComponent name="mdCriarContaDepartamentoSucessoErro" exibirModal={this.props.exibirModalSucessoErro}
                    header={'Alterar Conta'}
                    body={<div>{this.props.msgModalSucessoErro}</div>}
                    footer={<div
                        className="alinhamento-direita">
                        <Button
                            bsStyle="primary"
                            onClick={() => {
                                this.props.setExibirModalSucessoErroEditarConta();
                                this.props.recarregarMinhasContas();
                            }}>Ok
                        </Button>
                    </div>} />
            </div>
        );
    }

    carregarArquivoImagem1 = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];

        if (this.validarImagem(file)) {
            reader.onloadend = () => {
                this.setState({
                    imagemAvatarUrl: reader.result,
                    arquivoImagemAvatar: file
                });
            };
            this.props.setMsgValidarImagemEditarConta();
            reader.readAsDataURL(file);
        }
    }

    setArquivoImagemAvatar = (e) => {
        this.setState({ arquivoImagemAvatar: e.target.files[0] });
    }

    chamarApiUploadImagemAvatar() {
        (this.gerarUrlAssinadaImagemAvatar())
            .then((response) => {
                this.state.arquivoImagemAvatar && this.uploadImagemAvatarS3(response, this.state.arquivoImagemAvatar);
            }).catch((err) => {

            });
    }

    gerarUrlAssinadaImagemAvatar() {
        return fetch(montaUrlFetch(urlServicos.ARQUIVOS), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: JSON.stringify(
                {
                    bucket: environment.bucket_avatar_conta,
                    id: this.state.contaSelecionada.id.toUpperCase()
                }
            )
        })
            .then(response => response.json());
    }

    uploadImagemAvatarS3(urlPreAssinada, arquivo) {
        return fetch(urlPreAssinada.presignedUrlRequest, {
            method: HTTP_METODOS.PUT,
            headers: HEADER_UPLOAD_FILE.headers,
            body: arquivo
        })
            .then((response) => {

            });
    }

    validarImagem(arquivo) {
        if (arquivo.size < 10e+6) {
            if (arquivo.type != 'image/png' && arquivo.type != 'image/jpeg' && arquivo.type != 'image/jpg') {
                this.props.setMsgValidarImagemEditarConta('Tipo de arquivo não permitido! Extensões disponíveis: *.jpg, *.jpeg, *.png');
                return false;
            }
        } else {
            this.props.setMsgValidarImagemEditarConta('Tamanho do arquivo não é permitido! O tamanho máximo é 10MB!');
            return false;
        }
        return true;
    }
}
export default ModalEditarConta;