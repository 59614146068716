import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    arquivoId: undefined, erroNoEnvioDeArquivo: ''
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case METODOS.ARQUIVO.CONFIRMA_ENVIO_DE_ARQUIVO_PARA_PROCESSAMENTO:
            return {
                ...state, data: action.payload, dataErro: false, arquivoId: undefined
            };
        case METODOS.ARQUIVO.CONFIRMA_ENVIO_DE_ARQUIVO_PARA_PROCESSAMENTO_ERRO:
            return {
                ...state, data: action.payload, dataErro: true, arquivoId: undefined
            };
        case METODOS.ARQUIVO.ENVIA_ARQUIVO:
            return { ...state, arquivoId: action.payload.arquivoId };
        case METODOS.ARQUIVO.ENVIA_ARQUIVO_ERRO:
            return { ...state, erroNoEnvioDeArquivo: action.payload };
        case METODOS.ARQUIVO.LIMPAR:
            return INITIAL_STATE;
        case METODOS.ARQUIVO.BUSCA_ARQUIVO_POR_ID:
            return { ...state, arquivoRetornado: action.payload };
        case METODOS.ARQUIVO.BUSCA_ARQUIVO_POR_ID_ERRO:
            return { ...state, erroAoBuscarArquivo: action.payload };
        default:
            return state;
    }
};
