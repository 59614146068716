import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';
import { montarBodyOperacaoTransacao } from './pedido-service';

// TODO: os requisitantes dessa chamada só podem executálos, quando tiver permissão de podeAprovar (outorga-negocio)
export const efetivaPedidoPedidoEmbossingCartao = (operacacaoId, valorEntrega, token) => {
    let bodyPedidoEmbossingCartao = montarBodyOperacaoTransacao(valorEntrega, token);
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: bodyPedidoEmbossingCartao
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetOrders = (accountId, orderParams) => {
    return fetch(montaUrlFetch(urlServicos.CONSULTA_PEDIDOS(accountId), orderParams), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers,
    });
};
