import './listar-contas.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as actions from '../../action/contas/contas-action';
import { TIPO_DE_ACESSO, LABEL_COMUNS, PESSOA_LOGADA, EMPRESAS, MENSAGENS } from '../util/contantes-comuns/constantes-comuns';
import If from '../componentes-uteis/if/if';
import { LISTAR_CONTAS_LABELS } from './listar-contas-constantes';
import Grid from '../componentes-uteis/grid/grid';
import ExibirContas from '../comuns/exibir-contas/exbir-contas';
import LoadingFenix from '../loading-fenix/loading-fenix';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import { getEmpresasQueSouUsuariosAdmin } from '../../servicos/empresas/empresa-service';


class ListarContas extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pessoaLogada: PESSOA_LOGADA(),
            empresas: EMPRESAS(),
            exibirModalFinalizacaoCriacaoConta: false
        };
    }

    componentDidMount() {
        this.buscarContas();

    }

    buscarContas = () => {
        const { pessoaLogada } = this.state;
        this.props.getContas(pessoaLogada);
    }

    setExibirModalFinalizacaoCriacaoConta = (exibirModalFinalizacaoCriacaoConta) => {
        this.setState({ exibirModalFinalizacaoCriacaoConta });
    }

    setMensagemFinalizacaoCriacaoConta = (mensagemFinalizacaoCriacaoConta) => {
        this.setState({ mensagemFinalizacaoCriacaoConta });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.listarContas.refreshContas !== this.props.listarContas.refreshContas && nextProps.listarContas.refreshContas) {
            this.buscarContas();
        }

        /*  if (nextProps.listarContas.criacaoContaRealizada) {
             const { criacaoContaRealizada } = nextProps.listarContas;
             if (criacaoContaRealizada !== this.props.listarContas.criacaoContaRealizada) {
                 let mensagemFinalizacaoCriacaoConta = MENSAGENS.CONTAS.CRIACAO_CONTA.FINALIZACAO.SUCESSO;
                 if (criacaoContaRealizada.erro) {
                     const { mensagem } = criacaoContaRealizada;
                     mensagemFinalizacaoCriacaoConta = mensagem || MENSAGENS.CONTAS.CRIACAO_CONTA.FINALIZACAO.ERRO;
                 }
                 this.setState({
                     mensagemFinalizacaoCriacaoConta,
                     exibirModalFinalizacaoCriacaoConta: true
                 });
             }
         } */
    }

    render() {
        const { contasRecuperadas, exibirLoadingConta } = this.props.listarContas;
        const { pessoaLogada, empresas } = this.state;
        if (exibirLoadingConta) {
            return <LoadingFenix />;
        }
        return (
            <div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                    {
                        contasRecuperadas &&
                        <ExibirContas
                            pessoaLogada={this.state.pessoaLogada}
                            empresas={empresas}
                            getContas={this.props.getContas}
                            setExibirModalFinalizacaoCriacaoConta={this.setExibirModalFinalizacaoCriacaoConta}
                            setMensagemFinalizacaoCriacaoConta={this.setMensagemFinalizacaoCriacaoConta}
                            buscarContas={this.buscarContas}
                            listaDeContas={contasRecuperadas} />
                    }
                </div>
                <ModalComponent
                    name="modal-resultado-criacao-conta"
                    exibirModal={this.state.exibirModalFinalizacaoCriacaoConta}
                    header={LABEL_COMUNS.CONTAS.TITULOS.CRIAR_CONTA_DIGITAL}
                    body={this.state.mensagemFinalizacaoCriacaoConta}
                    footer={this.retornaRodapeModalFinalizacao()} />
            </div>
        );
    }

    retornaRodapeModalFinalizacao = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setState({ exibirModalFinalizacaoCriacaoConta: false });
                    this.props.getContas(this.state.pessoaLogada);
                }} />
        );
    }

    limparLocalStorage() {
        reactLocalStorage.set(LABEL_COMUNS.TAMANHO_PAGINA_LISTA_CARTOES, null);
        reactLocalStorage.set(LABEL_COMUNS.CONTA_SELECIONADA, null);
        reactLocalStorage.set(LABEL_COMUNS.SALDO_CONTA_SELECIONADA, null);
        reactLocalStorage.set(LABEL_COMUNS.TIPO_ACESSO, null);
        reactLocalStorage.set(LABEL_COMUNS.EMPRESA_SELECIONADA, null);
    }
}

const mapStateToProps = state => ({ listarContas: state.ContasReducer });

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListarContas);
