import { METODOS } from './mostrar-saldo-constantes';

const INITIAL_STATE = {
    saldo: undefined,
    saldoBloqueado: undefined,
    mensagemDeErroNaBuscaDoSaldo: '',
    exibirSaldo: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case METODOS.BUSCAR_SALDO_CONTA:
            return {
                ...state,
                saldo: action.payload.saldo,
                saldoBloqueado: action.payload.saldoBloqueado
            };
        case METODOS.BUSCAR_SALDO_CONTA_ERRO:
            return { ...state, mensagemDeErroNaBuscaDoSaldo: action.payload };
        case METODOS.SET_MOSTRAR_SALDO:
            return { ...state, exibirSaldo: action.payload };
        case METODOS.SET_ATUALIZANDO_SALDO:
            return {
                ...state,
                saldo: undefined,
                saldoBloqueado: undefined
            };
        default:
            return state;
    }
};
