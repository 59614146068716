import { bindActionCreators } from 'redux';
import { Button, ControlLabel, Dropdown, FormGroup, Glyphicon, MenuItem } from 'react-bootstrap';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import React, { Component } from 'react';
import { CONTA_SELECIONADA, FILTROS, LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, OPERACAO, RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO, STATUS, TIPO_DE_TRANSACAO, TIPO_DE_ACESSO, TIPO_DE_PESSOA } from '../../../util/contantes-comuns/constantes-comuns';
import { ESTILO, STATUS_VALIDACAO_COMPONENTES } from '../../../util/estilo_para_componentes/estiloUtil';
import { formatarCampoValor } from '../../../util/extrato/extrato-util';
import { formatarDataBR } from '../../../util/data/dataUtil';
import { getHistoricoDeComprovantes, getOperacoesComprovante, getComprovantePDF } from '../../../../servicos/comprovante/comprovante-service';
import { podeAprovar } from '../../../../negocio/outorga/outorga-negocio';
import * as actions from './agendamento-e-aprovacoes-action';
import ComprovanteModal from '../../../componentes-uteis/comprovante/comprovante-modal';
import ContaSelecionada from '../../conta-selecionada';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import Grid from '../../../componentes-uteis/grid/grid';
import If from '../../../componentes-uteis/if/if';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import { outorgaParametroOperacao } from '../../../../reducer/outorga/outorga-operacao-reducer';
import { getNecessitaOtp } from '../../../../servicos/outorga/outorga-service';
import ShowLineError from '../../../comuns/showLineError/show-line-error';


class AgendamentoAprovacoesConta extends Component {
    constructor() {
        super();

        this.state = {
            operacoesComprovante: [],
            dadosComprovante: {},
            exibirModalComprovante: false,
            exibirModalComprovanteErro: false
        };

        this.getDadosComprovante = this.getDadosComprovante.bind(this);
        this.carregaOperacoesComprovante = this.carregaOperacoesComprovante.bind(this);
        this.downloadComprovantePDF = this.downloadComprovantePDF.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.props.limparFiltroAgendamentoEAprovacoes();
        let contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.getAgendamentos({
            contaId: contaSelecionada.id,
            status: STATUS.AGENDAMENTO_APROVACOES_CONTA,
            solucao: this.props.solucaoBppPag
        });
        this.carregaOperacoesComprovante();
    }

    async carregaOperacoesComprovante() {
        const { data } = await getOperacoesComprovante('operacoesComprovante');

        this.setState({ operacoesComprovante: data.valor.reduce((acc, cur) => acc.concat(cur.operacao), []) });
    }

    async getDadosComprovante(operacaoId) {
        const { data } = await getHistoricoDeComprovantes(CONTA_SELECIONADA.OBJETO().id, { operacaoId });

        if (data.quantidadeTotalRegistros > 0) {
            this.setState({ dadosComprovante: data.resultado[0], exibirModalComprovante: true });
        } else {
            this.setState({ exibirModalComprovanteErro: true });
        }
    }

    async downloadComprovantePDF(comprovante) {
        const data = await getComprovantePDF(CONTA_SELECIONADA.OBJETO().id, comprovante.operacaoId, comprovante.comprovanteId);

        const linkSource = URL.createObjectURL(data);
        const downloadLink = document.createElement('a');
        const fileName = 'Comprovante.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(linkSource);
    }

    getAgendamentos({ contaId, status, dataInicial, dataFinal, valorDe, valorAte, solucao }) {
        this.props.buscarTodosAgendamentosDashboardConta(
            [FILTROS.AGENDAMENTOS_CONTA_ORIGEM(contaId,
                RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO(status),
                dataInicial,
                dataFinal,
                valorDe,
                valorAte),
            FILTROS.AGENDAMENTOS_CONTA_DESTINO(contaId,
                RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO(status),
                dataInicial,
                dataFinal,
                valorDe,
                valorAte)],
            solucao);
    }


    solicitaOperacao({ operacao, id, status }, transacao) {
        const msg = MENSAGENS.OPERACAO.CONFIRMACAO(transacao.transacao);
        this.setState({ exibirModalContinuarComOperacao: msg, operacao: { nome: operacao, id, transacao } });
    }


    verificaNecessitaOtp() {
        const { id, transacao, nome } = this.state.operacao || {};

        if (id && transacao && nome) {

            const outorga = outorgaParametroOperacao(this.props.outorga, nome);

            const necessitaToken = getNecessitaOtp(transacao.transacao, outorga);

            if (necessitaToken) {
                this.props.solicitaTokenTransacao(id, transacao);
            } else {
                this.props.efetivaTransacao(id, transacao);
            }
        }
    }

    retornaCorpoModalContinuarComOperacao() {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.exibirModalContinuarComOperacao }} />
        );
    }

    retornaRodapeModalContinuarComOperacao() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                }}
                btn2OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                    this.verificaNecessitaOtp();
                }} />
        );
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.agendamentoConta.solicitacaoTokenErro || this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token </ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.tokenId ? '' : this.state.tokenId}
                        onChange={(e) => this.setState({ tokenId: e.target.value })}
                        mask={MASCARA_CAMPOS.TOKEN}
                        maskChar={null}
                    />
                    <ShowLineError error={this.props.agendamentoConta.solicitacaoTokenErro || this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.verificaNecessitaOtp()}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.props.setExibirModalToken(false);
                    this.setState({ tokenId: '' });
                }}
                btn2OnClick={() => {
                    if (this.state.tokenId && this.state.tokenId.length === 6) {
                        const { operacao: { id, transacao }, tokenId } = this.state;
                        this.props.efetivaTransacao(id, transacao, tokenId);
                    } else {
                        this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
                    }
                }} />
        );
    }

    rodapeModal() {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={
                    () => {
                        this.props.setExibirModalFinalizacaoAgendamentoEAprovacoes(false);
                        this.init();
                    }} />
        );
    }


    render() {
        const podeAprovarOutorga = podeAprovar(this.props.perfils);
        return (
            <div>
                <ModalComponent
                    exibirModal={this.state.exibirModalComprovanteErro}
                    header="Comprovante"
                    body={<p>Algo inesperado aconteceu. Tente novamente!</p>}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label="Ok"
                            btn2OnClick={() => this.setState({ exibirModalComprovanteErro: false })}
                        />
                    }
                />

                <ModalComponent bsSize="small" exibirModal={this.props.agendamentoConta.exibiModalFinalizacao} header={this.props.agendamentoConta.tituloModalFinalizacao}
                    body={this.props.agendamentoConta.mensagemFinalizacao} footer={this.rodapeModal()} />

                <ModalComponent exibirModal={this.props.agendamentoConta.exibirModalToken}
                    name="modal-token" header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.montaCorpoModalToken()} footer={this.montaRodapeModalToken()} />

                <ModalComponent exibirModal={!!this.state.exibirModalContinuarComOperacao}
                    name="modalContinuarComOperacao" header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.retornaCorpoModalContinuarComOperacao()} footer={this.retornaRodapeModalContinuarComOperacao()} />

                {this.state.exibirModalComprovante && (
                    <ComprovanteModal
                        header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE(CONTA_SELECIONADA.OBJETO().descricao)}
                        bsSize="lg"
                        onClose={() => this.setState({ exibirModalComprovante: false })}
                        exibirModal={this.state.exibirModalComprovante}
                        dadosComprovante={this.state.dadosComprovante}
                        downloadComprovantePDF={this.downloadComprovantePDF}
                    />
                )}

                <Grid cols="12 12 12 12" className="espacamento_conteudo_paginas">
                    {this.props.agendamentoConta.contaSelecionada && <ContaSelecionada

                        mostrarFiltroDeDatas
                        exibirFiltroDeValor
                        exibirSaldo

                        contaSelecionada={this.props.agendamentoConta.contaSelecionada}

                        dataInicialLabel={LABEL_COMUNS.DATA_INICIAL}
                        dataInicialPlaceHolder={LABEL_COMUNS.DATA_DE}
                        dataInicialValue={this.props.agendamentoConta.dataInicial}
                        dataIncialChange={this.props.setFiltroAgendamentosEAprovacoesDataInicial}
                        dataInicialErro={this.props.agendamentoConta.dataInicialErro}

                        dataFinalPlaceHolder={LABEL_COMUNS.DATA_ATE}
                        dataFinalValue={this.props.agendamentoConta.dataFinal}
                        dataFinalChange={this.props.setFiltroAgendamentosEAprovacoesDataFinal}
                        dataFinalErro={this.props.agendamentoConta.dataFinalErro}

                        valorDe={this.props.agendamentoConta.valorDe}
                        setValorDe={this.props.setFiltroAgendamentosEAprovacoesValorDe}
                        valorDeErro={this.props.agendamentoConta.valorDeErro}

                        valorAte={this.props.agendamentoConta.valorAte}
                        setValorAte={this.props.setValsetFiltroAgendamentosEAprovacoesValorAte}
                        valorAteErro={this.props.agendamentoConta.valorAteErro}

                        exibirBotaoLimpar
                        limparFiltro={this.props.limparFiltroAgendamentoEAprovacoes}
                        filtrar={() =>
                            this.getAgendamentos({
                                contaId: this.props.agendamentoConta.contaSelecionada.id,
                                status: STATUS.AGENDAMENTO_APROVACOES_CONTA,
                                dataInicial: this.props.agendamentoConta.dataInicial,
                                dataFinal: this.props.agendamentoConta.dataFinal,
                                valorDe: this.props.agendamentoConta.valorDe,
                                valorAte: this.props.agendamentoConta.valorAte,
                                solucao: this.props.solucaoBppPag
                            })}

                    />}
                </Grid>
                <Grid cols="11 11 11 11">
                    <div className="panel panel-default ">
                        <div className={ESTILO.COR_DE_FUNDO.TITULO.PADRAO}><b>{LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}</b></div>
                        <div className="panel-body">
                            {
                                this.props.agendamentoConta.exibirLoadingAgendamentoEAprovacoes
                                    ? <div className="alinhamento-centro">
                                        <LoadingFenix />
                                    </div>
                                    : this.props.agendamentoConta.exibirLoadingAgendamentoEAprovacoes !== undefined &&
                                    <If test={this.props.agendamentoConta.listaDeAgendamentos.length > 0}>
                                        <div className="row">
                                            <Grid cols="12 12 12 12" className="grids-dashboards">
                                                <Grid cols="2 2 2 2">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DATA_AGENDAMENTO}</span>
                                                </Grid>
                                                <Grid cols={podeAprovarOutorga ? '4 4 4 4' : '5 5 5 5'} >
                                                    <span className="titulo-dashboards-conta-solucao" style={{ marginLeft: '5px' }}>{LABEL_COMUNS.OPERACAO}/{LABEL_COMUNS.DESCRICAO}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                    <span className="titulo-dashboards-conta-solucao" style={{ marginRight: '-20px' }}>{LABEL_COMUNS.VALOR}</span>
                                                </Grid>
                                                <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao" style={{ marginLeft: '42px' }}>{LABEL_COMUNS.STATUS}</span>
                                                </Grid>

                                                {podeAprovarOutorga && <Grid cols="1 1 1 1">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ACAO}</span>
                                                </Grid>}
                                            </Grid>
                                        </div>
                                        <div className="row">
                                            <div className={TIPO_DE_ACESSO === TIPO_DE_PESSOA.PESSOA_FISICA ? 'grid-agendamentoPf' : 'grid-agendamentoConta'}>
                                                {
                                                    this.props.agendamentoConta.listaDeAgendamentos.map((linhaAgendamento, idx) => {
                                                        const desabilitaBotoes = ((linhaAgendamento.operacao === OPERACAO.NOME.MENSALIDADE_CARTAO_CARTAO_COBRADA_CONTA) || (linhaAgendamento.statusInterno !== STATUS.INTERNO.ABERTO && linhaAgendamento.statusInterno !== STATUS.INTERNO.AGENDADO));
                                                        return (
                                                            <div className="row" key={idx}>
                                                                <Grid cols="12 12 12 12">
                                                                    <Grid cols="2 2 2 2">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {linhaAgendamento.dataAgendamento ? formatarDataBR(linhaAgendamento.dataAgendamento) : formatarDataBR(linhaAgendamento.dataCriacao)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols={podeAprovarOutorga ? '4 4 4 4' : '5 5 5 5'} >
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {linhaAgendamento.operacaoNome}
                                                                        </span>
                                                                        <br />
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {linhaAgendamento.tagCliente}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {formatarCampoValor(linhaAgendamento.tipo, linhaAgendamento.valor, linhaAgendamento.moeda)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {linhaAgendamento.statusExibicao}
                                                                        </span>
                                                                    </Grid>

                                                                    {podeAprovarOutorga && <Grid cols="1 1 1 1">
                                                                        <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-30px' }} disabled={desabilitaBotoes}>
                                                                            <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas" >
                                                                                <Glyphicon glyph="option-horizontal" />
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                {linhaAgendamento.statusInterno === STATUS.INTERNO.ABERTO && (
                                                                                    <MenuItem onClick={() => this.solicitaOperacao(linhaAgendamento, TIPO_DE_TRANSACAO.APROVACAO)}>{LABEL_COMUNS.APROVAR}</MenuItem>
                                                                                )}
                                                                                {linhaAgendamento.statusInterno === STATUS.INTERNO.AGENDADO && this.state.operacoesComprovante.includes(linhaAgendamento.operacao) && (
                                                                                    <MenuItem onClick={() => this.getDadosComprovante(linhaAgendamento.id)}>Comprovante</MenuItem>
                                                                                )}
                                                                                <MenuItem onClick={() => this.solicitaOperacao(linhaAgendamento, TIPO_DE_TRANSACAO.CANCELAMENTO)}>{LABEL_COMUNS.CANCELAR}</MenuItem>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Grid>}
                                                                </Grid>
                                                            </div>
                                                        );
                                                    }
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </If>}
                            <If test={this.props.agendamentoConta.listaDeAgendamentos.length <= 0 && !this.props.agendamentoConta.exibirLoadingAgendamentoEAprovacoes}>
                                <span>{LABEL_COMUNS.NENHUM_AGENDAMENTO_ENCONTRADO}</span>
                            </If>
                        </div>
                    </div>
                </Grid>
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.PADRAO} onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>

                </Grid>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    agendamentoConta: state.AgendamentoReducer,
    outorga: state.OutorgaOperacaoReducer
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoAprovacoesConta);
