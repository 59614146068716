import { CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX } from './consulta-carga-lote-controlador';

const st = {
    defaultMaskPattern: '99/99/9999',
    usuarios: '',
    nomeArquivo: '',
    dataDe: '',
    dataAte: '',
    usuario: '',
    cargaLote: [],
    err: undefined,
    idDoArquivoProcessado: undefined,
    carregando: true,
    totalResultado: 0,
    cargaLoteParaCSV: [],
    exibirModalErro: false,
    ocultarGrid: true,
    erroDataInicio: undefined,
    erroDataFim: undefined

};

export default function reducer(state = st, action) {
    switch (action.type) {
    // Listar Cargas em Lote
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[0] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
            var exibirModal = action.payload.quantidadeTotalRegistros === 0;

            {
                return {
                    ...state,
                    cargaLote: action.payload.registros,
                    totalResultado: action.payload.quantidadeTotalRegistros,
                    // respostaProcessarArquivo: undefined,
                    idDoArquivoProcessado: undefined,
                    carregando: false,
                    exibirModalResultadosNaoEncontrados: exibirModal,
                    err: undefined,
                    ocultarGrid: false,
                    erroDataInicio: undefined,
                    erroDataFim: undefined
                };
            }
        // Buscar Usuários
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[1] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                usuarios: action.payload,
                carregando: false
            };
        }
        // Alterar nome do Arquivo
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[3] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                nomeArquivo: action.payload,
                carregando: false
            };
        }
        // Erro
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME: {
        // precisa decompor a mensagem de data inicial e final inválida
            var msgDataInicio = undefined;
            var msgDataFim = undefined;
            switch (action.payload) {
                case ('Data inicial inválida') :
                    msgDataInicio = action.payload;
                    break;
                case ('Data inicial e Data Final são inválidas') :
                    msgDataInicio = 'Data inicial inválida';
                    msgDataFim = 'Data final inválida';
                    break;
                case ('Data final inválida') :
                    msgDataFim = action.payload;
                    break;
                case ('Data inicial maior que data final') :
                    msgDataInicio = action.payload;
                    break;

                case ('Data inicial e data final são maiores que a data atual') :
                    msgDataInicio = 'Data inicial é maior que a data atual';
                    msgDataFim = 'Data final é maior que a data atual';
                    break;

                case ('Data inicial é maior que a data atual') :
                    msgDataInicio = 'Data inicial é maior que a data atual';
                    break;

                case ('Data final é maior que a data atual') :
                    msgDataFim = action.payload;
                    break;

                default: { msgDataInicio = undefined; msgDataFim = undefined; }
            }

            return {
                ...state,
                err: action.payload,
                carregando: false,
                erroDataInicio: msgDataInicio,
                erroDataFim: msgDataFim
            };
        }
        // Mudar data início
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                dataDe: action.payload,
                carregando: false
            };
        }

        // Mudar data fim
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[5] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                dataAte: action.payload,
                carregando: false
            };
        }

        // Mudar usuario
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[6] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                usuario: action.payload,
                carregando: false
            };
        }

        // Limpar Campos
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[7] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                nomeArquivo: '',
                dataDe: '',
                dataAte: '',
                usuario: '',
                cargaLote: [],
                carregando: false,
                totalResultado: 0,
                err: undefined,
                ocultarGrid: true,
                erroDataInicio: undefined,
                erroDataFim: undefined

            };
        }

        // Setar buscando true
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                carregando: action.payload

            };
        }

        // Buscar carga em lote para CSV
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[11] +
        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                cargaLoteParaCSV: action.payload.registros,
                carregando: false

            };
        }

        // Buscar carga em lote para CSV
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[12] +
            CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                cargaLoteParaCSV: action.payload,
                erroDataInicio: undefined,
                erroDataFim: undefined

            };
        }

        // Setar exibir modal erro
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[13] +
        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                exibirModalErro: true
            };
        }

        // ocultar modal erro
        case CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[14] +
        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                err: undefined,
                exibirModalErro: false
                // carregando: true

            };
        }
        default: return state;
    }
}
