import React from 'react';
import { MENSAGENS } from '../../../../util/contantes-comuns/constantes-comuns';

const getMensagens = () => {
    const {
        ITEM_1,
        ITEM_2,
        ITEM_3
    } = MENSAGENS.TRANSFERENCIA_EM_LOTE.MODAL_TRANSFERENCIA_EM_LOTE_INSTRUCOES.CNAB;

    const {
        PREFIXO,
        SUFIXO,
        LINK,
        LINK_LABEL
    } = ITEM_3;

    return {
        linha1: ITEM_1,
        linha2: ITEM_2,
        linha3: {
            sufixo: SUFIXO,
            prefixo: PREFIXO,
            link: LINK,
            linkLabel: LINK_LABEL
        }
    };
};

const ModalInstrucoesExemploCNAB = () => {
    const {
        linha1,
        linha2,
        linha3
    } = getMensagens();

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>1. </strong>
                        {linha1}
                    </span>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>2. </strong>
                        {linha2}
                    </span>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>{linha3.prefixo}</strong>
                        {` ${linha3.sufixo}`}
                        <i>
                            <u>
                                <a href={linha3.link || ''} target="_blank" rel="noopener noreferrer" download="MODELO-ARQUIVO-CNAB-BPP-PAG.pdf">
                                    {` ${linha3.linkLabel}`}
                                </a>
                            </u>
                        </i>
                    </span>
                </div>
            </div>
            <br />
            <br />
        </div>
    );
};

export default ModalInstrucoesExemploCNAB;
