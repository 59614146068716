import React, { Component } from 'react';
import {
    CONTA_SELECIONADA,
    LABEL_COMUNS,
    MENSAGENS,
    NOME_MESES
} from 'componentes/util/contantes-comuns/constantes-comuns';
import Select from 'react-select';
import RelatorioAnalyticRow from 'componentes/relatorio-analytic-row/relatorio-analytic-row';
import Loading from 'componentes/loading-fenix/loading';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import { Button, Grid } from 'react-bootstrap';

class RelatorioAnaliticoBppCorp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            mesValue: { value: new Date() },
            rangeMes: '',
            listaDeMeses: [],
            exibirModalErroGerarRelatorio: false,
            dias: [],
            mesAtual: 13,
            idContaMovimento: CONTA_SELECIONADA.OBJETO().id
        };
    }

    componentDidMount() {
        this.montaComboMeses();
        this.montaListagemDias();
    }

    montaComboMeses() {
        let hoje = new Date();
        let mesValue = { value: hoje, label: NOME_MESES[hoje.getMonth()] + ' ' + hoje.getFullYear() };
        let listaDeMeses = [];

        for (let i = 0; i < 6; i++) {
            let mesAnterior = new Date();
            let newMonth = mesAnterior.getMonth() - i;
            mesAnterior.setMonth(newMonth);
            let mesAnteriorObject = { value: mesAnterior, label: NOME_MESES[mesAnterior.getMonth()] + ' ' + mesAnterior.getFullYear() };
            listaDeMeses.push(mesAnteriorObject);
        }

        this.setState({ mesValue, listaDeMeses, mesAtual: hoje.getMonth() });
    }

    montaListagemDias() {
        let hoje = new Date();
        let dias = [];
        let quantidadeDias = hoje.getDate();
        // o dia de hoje não será exibido, somente dias D-1
        for (let i = 1; i < quantidadeDias; i++) {
            let diaAtual = new Date();
            diaAtual.setDate(diaAtual.getDate() - i);
            let diaFormatado = ('0' + diaAtual.getDate()).slice(-2) + '/' + ('0' + (diaAtual.getMonth() + 1)).slice(-2) + '/' + diaAtual.getFullYear();
            dias.push(diaFormatado);
        }

        this.setState({ dias });
    }

    alteraMes(mesSelect) {
        let mesToSet = mesSelect.value;
        let mesValue = { value: mesToSet, label: NOME_MESES[mesToSet.getMonth()] + ' ' + mesToSet.getFullYear() };

        mesToSet.setDate(1);
        let diaInicioFormatado = ('0' + mesToSet.getDate()).slice(-2) + '/' + ('0' + (mesToSet.getMonth() + 1)).slice(-2) + '/' + mesToSet.getFullYear();

        mesToSet.setMonth(mesToSet.getMonth() + 1);
        mesToSet.setDate(0);
        let diaFimFormatado = ('0' + mesToSet.getDate()).slice(-2) + '/' + ('0' + (mesToSet.getMonth() + 1)).slice(-2) + '/' + mesToSet.getFullYear();

        let dataRange = diaInicioFormatado + ' - ' + diaFimFormatado;
        this.setState({ mesValue, rangeMes: dataRange });
    }

    rodapeErrorGerarRelatorio() {
        return (
            <FooterBotoesModalComponent
        parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
        btn2Label={LABEL_COMUNS.OK}
        btn2OnClick={() => this.setState({ exibirModalErroGerarRelatorio: false })}
      />
        );
    }

    renderizaTabela() {
        let mesAtualSelecionado = this.state.mesValue;
        mesAtualSelecionado = mesAtualSelecionado.value.getMonth();

        return (
            <div>
                <table className="table table-striped">
                    <thead>
                        <tr style={{ color: '#3F84C7', textAligntextAlign: 'center' }}>
                            <th scope="col">Data</th>
                            <th scope="col">Transações</th>
                            <th scope="col">Cartões</th>

                        </tr>
                    </thead>
                    <tbody>

                        {this.state.mesAtual === mesAtualSelecionado ? this.state.dias.map((dia, idx) => {
                        return (<RelatorioAnalyticRow key={idx} dia={dia} precisaDia
                                                      idContaMovimento={this.state.idContaMovimento}
                                                      setModalErrorGerarRelatorio={(valor) => this.setState({ exibirModalErroGerarRelatorio: valor })}
                                                      changeLoading={(status) => this.setState({ isLoading: status })}/>);
                    }) : <RelatorioAnalyticRow dia={this.state.rangeMes} precisaDia={false}
                                               idContaMovimento={this.state.idContaMovimento}
                                               changeLoading={(status) => this.setState({ isLoading: status })}
                                               setModalErrorGerarRelatorio={(valor) => this.setState({ exibirModalErroGerarRelatorio: valor })}/>

                    }
                    </tbody>
                </table>
                <div className="col-xs-5 col-sm-5 col-md-3 col-lg-3 alinhamento-direita">
                    <Grid cols="11 11 11 11">
                        <Button style={{ marginLeft: '-25px' }} type="button" bsStyle="primary"
                                onClick={() => this.props.history.goBack()}
                        >Voltar</Button>
                        <br/><br/>
                    </Grid>
                </div>

            </div>
        );
    }

    render() {
        return (

            <div className="row">
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas" style={{ width: '600px' }}>
                    <div className="panel-heading fundo-azul-claro" style={{ fontWeight: 900 }}>Relatórios analíticos</div>
                    <div className="panel panel-default">
                        <div>
                            <Select id="mes"
                style={{ fontWeight: '600' }}
                placeholder="Selecione"
                ref={(ref) => { this.select = ref; }}
                value={this.state.mesValue}
                clearable={false}
                onChange={(selectValue) => this.alteraMes(selectValue)}
                options={this.state.listaDeMeses}
              />
                        </div>
                        {this.state.isLoading
              ? <div className="alinhamento-centro">
                  <Loading />
              </div>
              : <div style={{ marginTop: 33 }}>
                  {this.renderizaTabela()}
              </div>
            }

                        <ModalComponent bsSize="large" exibirModal={this.state.exibirModalErroGerarRelatorio}
              name="modalErrorGerarRelatorio"
              header={LABEL_COMUNS.GERAR_RELATORIO}
              body={MENSAGENS.RELATORIOS_CARTOES.ERRO} footer={this.rodapeErrorGerarRelatorio()} />
                    </div>
                </div>
            </div>

        );
    }
}

export default RelatorioAnaliticoBppCorp;
