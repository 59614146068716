import axios from 'axios';
import {
    HEADER_API, URL_LISTA_BANCOS,
    URL_POST_OPERACOES, URL_GET_TIPO_BANCO
} from '../../servicos/url-servicos';
import { reactLocalStorage } from 'reactjs-localstorage';
import { stringParaNumero } from '../util/formata-string';
import { METODOS, OPERACAO } from './pedido-carga-constantes';
import { dataInvalida, dataMaiorQueHoje } from '../util/data/dataUtil';
import { trim } from '../util/string/string-util';
import { TIPO_CONTA } from '../util/contantes-comuns/constantes-comuns';

export function exibirModalConfirmacaoChange(value) {
    return {
        type: METODOS.EXIBIR_MODAL_CONFIRMACAO_CHANGE,
        payload: value
    };
}

export function exibirModalCancelarChange(value) {
    return {
        type: METODOS.EXIBIR_MODAL_CANCELAR_CHANGE,
        payload: value
    };
}

export function exibirModalSucessoChange(value) {
    return {
        type: METODOS.EXIBIR_MODAL_SUCESSO_CHANGE,
        payload: value
    };
}

export function desabilitaComboBancoChange(value) {
    return {
        type: METODOS.DESABILITA_COMOBO_BANCO_CHANGE,
        payload: value
    };
}

export function exibirLoadingChange(value) {
    return {
        type: METODOS.EXIBIR_LOADING_CHANGE,
        payload: value
    };
}

export function valorCargaChange(value) {
    return function(dispatch) {
        dispatch({
            type: METODOS.VALOR_CARGA_CHAGE,
            payload: value
        });
        dispatch({ type: METODOS.MSG_ERRO_VALOR_DA_CARGA, payload: '' });
    };
}

export function bancoChange(value) {
    return function(dispatch) {
        dispatch({
            type: METODOS.BANCO_CHANGE,
            payload: value
        });
        dispatch({ type: METODOS.MSG_ERRO_BANCO, payload: '' });
    };
}

export function bancoDestinoChange(value) {
    return {
        type: METODOS.BANCO_DESTINO_CHANGE,
        payload: value
    };
}

export function agenciaChange(event) {
    return function(dispatch) {
        dispatch({
            type: METODOS.AGENCIA_CHANGE,
            payload: event.target.value
        });
        dispatch({ type: METODOS.MSG_ERRO_AGENCIA, payload: '' });
    };
}

export function contaChange(event) {
    return function(dispatch) {
        dispatch({
            type: METODOS.CONTA_CHANGE,
            payload: event.target.value
        });
        dispatch({ type: METODOS.MSG_ERRO_CONTA, payload: '' });
    };
}

export function digitoChange(event) {
    return function(dispatch) {
        dispatch({
            type: METODOS.DIGITO_CHANGE,
            payload: event.target.value
        });
        dispatch({ type: METODOS.MSG_ERRO_DIGITO, payload: '' });
    };
}

export function tipoContaChange(event) {
    return {
        type: METODOS.TIPO_CONTA_CHANGE,
        payload: event.target.value
    };
}

export function descricaoChange(event) {
    return {
        type: METODOS.DESCRICAO_CHANGE,
        payload: event.target.value
    };
}

/* export function setHoje(valor) {
    return {
        type: METODOS.SET_HOJE,
        payload: valor
    }
}

export function setDataAgendada(valor) {
    return {
        type: METODOS.SET_DATA_AGENDADA,
        payload: valor
    }
} */

export function buscarBancos(value) {
    return (dispatch) => {
        axios.get(URL_LISTA_BANCOS(), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.BUSCAR_BANCOS,
                    payload: montaBancosParaCombo(response.data)
                });
            })
            .catch((err) => {
                dispatch({
                    type: METODOS.BUSCAR_BANCOS_ERRO,
                    payload: err.response.data
                });
            });
    };
}

export function efetuarCarga(contaDigitalId, dadosForm) {
    let pedidoDeCarga = montaDadosJsonParaBackend(contaDigitalId, dadosForm);
    if (!pedidoDeCarga) {
        return null;
    }
    return function(dispatch) {
        axios.post(URL_POST_OPERACOES(), pedidoDeCarga, HEADER_API())
            .then((response) => {
                axios.get(URL_GET_TIPO_BANCO(dadosForm.bancoDestinoId), HEADER_API())
                    .then((resp) => {
                        resp.data.valorCarga = dadosForm.valorCarga;
                        dispatch({
                            type: METODOS.EFETUAR_CARGA,
                            payload: resp.data
                        });
                    })
                    .catch((err) => {
                        dispatch({
                            type: METODOS.EFETUAR_CARGA_ERRO,
                            payload: err.response.data
                        });
                    });
            }).catch((err) => {
                let msgErro = '';
                if (err !== undefined && err.response !== undefined && err.response.data !== undefined) {
                    if (err.response.data.codigo !== '0000') {
                        msgErro = err.response.data;
                    } else {
                        msgErro = 'Campo';
                    }
                }
                dispatch(
                    {
                        type: METODOS.EFETUAR_CARGA_ERRO,
                        payload: err.response.data
                    }
                );
            });
    };
}

function montaBancosParaCombo(bancos) {
    let listaDeBancos = [];
    bancos.map((banco, idx) => {
        return listaDeBancos.push({ value: banco.codigo, label: banco.codigo + ' - ' + banco.nome });
    });
    return listaDeBancos;
}

export function validarParametroObrigatorio(dadosForm) {
    let result = true;
    return function(dispatch) {
        if (dadosForm.bancoSelecionadoId === '' || dadosForm.bancoSelecionadoId === null) {
            dispatch({ type: METODOS.MSG_ERRO_BANCO, payload: 'Selecione um Banco' });
            result = false;
        } else {
            dispatch({ type: METODOS.MSG_ERRO_AGENCIA, payload: '' });
        }
        if (dadosForm.agencia === '' || dadosForm.agencia === null) {
            dispatch({ type: METODOS.MSG_ERRO_AGENCIA, payload: 'Digite agência' });
            result = false;
        } else {
            dispatch({ type: METODOS.MSG_ERRO_AGENCIA, payload: '' });
        }
        if (dadosForm.conta === '' || dadosForm.conta === null) {
            dispatch({ type: METODOS.MSG_ERRO_CONTA, payload: 'Digite a conta' });
            result = false;
        } else {
            dispatch({ type: METODOS.MSG_ERRO_CONTA, payload: '' });
        }
        if (dadosForm.digito === '' || dadosForm.digito === null) {
            dispatch({ type: METODOS.MSG_ERRO_DIGITO, payload: 'Digite o dígito' });
            result = false;
        } else {
            dispatch({ type: METODOS.MSG_ERRO_DIGITO, payload: '' });
        }
        if (dadosForm.valorCarga === '0,00' || dadosForm.valorCarga === '' || dadosForm.valorCarga === null) {
            dispatch({ type: METODOS.MSG_ERRO_VALOR_DA_CARGA, payload: 'Digite um valor' });
            result = false;
        } else {
            dispatch({ type: METODOS.MSG_ERRO_VALOR_DA_CARGA, payload: '' });
        }
        return result;
    };
}

/* export function validarDataDoPedido(hoje, dataAgendada) {
    let retorno = true;

    return function(dispatch) {
        if(hoje === false && dataAgendada === "") {
            dispatch({type: METODOS.MSG_ERRO_DATA_AGENDAMENTO_OBRIGATORIA, payload: "Selecione a data de agendamento"});
            retorno = false;
        } else if(hoje === false) {
            if(dataInvalida(dataAgendada)) {
                dispatch({type: METODOS.MSG_ERRO_DATA_AGENDAMENTO, payload: "Data inválida"});
                retorno = false;
            } else if(!dataMaiorQueHoje(dataAgendada)) {
                dispatch({type: METODOS.MSG_ERRO_DATA_AGENDAMENTO, payload: "Data deve ser maior que a atual."});
                retorno = false;
            }
        }

        return retorno;
    }
} */

const montaDadosJsonParaBackend = (contaDigitalId, dadosForm) => {
    let solicitacaoTransferencia = {
        operacao: OPERACAO,
        contaDestinoId: contaDigitalId,
        valor: stringParaNumero(dadosForm.valorCarga),
        dadosIntegracao: {
            bancoId: dadosForm.bancoSelecionadoId,
            agencia: dadosForm.agencia,
            conta: dadosForm.conta,
            digito: dadosForm.digito,
            tipoConta: TIPO_CONTA.conta
        }
    };

    if (dadosForm.descricao) {
        let descricao = trim(dadosForm.descricao);
        if (descricao !== '') {
            solicitacaoTransferencia.dadosIntegracao.descricao = descricao;
        }
    }
    solicitacaoTransferencia = JSON.stringify(solicitacaoTransferencia);
    return solicitacaoTransferencia;
};
