import { COMBO, LABEL_COMUNS, MENSAGENS, METODOS, OPERACAO } from '../../../util/contantes-comuns/constantes-comuns';

const ESTADO_INICIAL = {
    dataInicial: '',
    dataInicialErro: '',
    dataFinal: '',
    dataFinalErro: '',
    valorAte: 0,
    valorDe: 0,
    contaSelecionada: '',
    listaDeAgendamentos: [],
    erroNaBuscaDeAgendamento: '',
    exibiModalFinalizacao: false,
    mensagemFinalizacao: '',
    tituloModalFinalizacao: '',
    exibirLoadingAgendamentoEAprovacoes: undefined,
    listaDeStatus: COMBO.STATUS.OPERACAO.filter(status => (status.status !== OPERACAO.STATUS.AGENDADO)),
    listaDeTransferenciasEmLote: [],
    erroBuscTransferenciaEmLote: '',
    transacaoEmLoteEfetuada: undefined,
    exibirLoadingAgendamentosEAprovacoesTransferenciaEmLote: undefined,
    transferenciaEmLoteTokenId: '',
    transferenciaEmLoteTokenIdErro: ''
};

export default (state = ESTADO_INICIAL, action) => {
    let transacaoRealizada = '';
    let mensagem = '';
    let tituloDoModal = '';
    let transacaoEmLoteEfetuada = {};
    let mensagemDeErro;
    switch (action.type) {
        case METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_MODAL_TOKEN_AGENDAMENTO_E_APROVACOES:
            return { ...state, exibirModalToken: action.payload, solicitacaoTokenErro: '' };
        case METODOS.AGENDAMENTO_E_APROVACOES.TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES:
            return { ...state, exibirModalToken: true, solicitacaoTokenErro: '' };
        case METODOS.AGENDAMENTO_E_APROVACOES.TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO:
            mensagemDeErro = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            return { ...state, exibirModalToken: true, solicitacaoTokenErro: mensagemDeErro };
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_INICIAL:
            return { ...state, dataInicial: action.payload, dataInicialErro: '' };
        case METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_FINAL:
            return { ...state, dataFinal: action.payload, dataFinalErro: '' };
        case METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_DE:
            return { ...state, valorDe: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_ATE:
            return { ...state, valorAte: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA:
            return { ...state, listaDeAgendamentos: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA_ERRO:
            return { ...state, erroNaBuscaDeAgendamento: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.LIMPAR_FILTRO_AGENDAMENTO_E_APROVACOES:
            return { ...state, dataInicial: action.payload, dataFinal: action.payload, valorAte: 0, valorDe: 0 };
        case METODOS.AGENDAMENTO_E_APROVACOES.EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES:
            transacaoRealizada = action.payload.transacaoRealizada;
            tituloDoModal = LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.MODAL_APROVACAO_CANCELAMENTO(transacaoRealizada.transacao);
            mensagem = MENSAGENS.AGENDAMENTO_E_APROVACOES.DASHBOARD_CONTA.EFETIVACAO_DA_OPERACAO.SUCESSO(transacaoRealizada.transacao);
            return { ...state, exibirModalToken: false, exibiModalFinalizacao: true, mensagemFinalizacao: mensagem, tituloModalFinalizacao: tituloDoModal };
        case METODOS.AGENDAMENTO_E_APROVACOES.EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO:
            transacaoRealizada = action.payload.transacaoRealizada;
            tituloDoModal = LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.MODAL_APROVACAO_CANCELAMENTO(transacaoRealizada.transacao);
            mensagem = MENSAGENS.AGENDAMENTO_E_APROVACOES.DASHBOARD_CONTA.EFETIVACAO_DA_OPERACAO.ERRO(transacaoRealizada.transacao);
            return { ...state, exibiModalFinalizacao: true, mensagemFinalizacao: mensagem, tituloModalFinalizacao: tituloDoModal };
        case METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_LOADING_AGENDAMENTO_E_APROVACOES:
            return { ...state, exibirLoadingAgendamentoEAprovacoes: action.payload };
        case METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_MODAL_FINALIZACAO_AGENDAMENTO_E_APROVACOES:
            return { ...state, exibiModalFinalizacao: action.payload };
        case METODOS.TRANSFERENCIA_EM_LOTE.BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE:
            return { ...state, listaDeTransferenciasEmLote: action.payload.resultado, transacaoEmLoteEfetuada: undefined };
        case METODOS.TRANSFERENCIA_EM_LOTE.BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE_ERRO:
            return { ...state, erroBuscaTransferenciaEmLote: action.payload.erros, transacaoEmLoteEfetuada: undefined };
        case METODOS.TRANSFERENCIA_EM_LOTE.SET_EXIBIR_LOADING_AGENDAMENTOS_E_APROVACOES_TRANSFERENCIA_EM_LOTE:
            return { ...state, exibirLoadingAgendamentosEAprovacoesTransferenciaEmLote: action.payload };
        case METODOS.TRANSFERENCIA_EM_LOTE.EFETIVAR_TRANSACAO_EM_LOTE:
            transacaoEmLoteEfetuada = { sucesso: true, ...action.payload };
            return { ...state, transacaoEmLoteEfetuada: transacaoEmLoteEfetuada };
        case METODOS.TRANSFERENCIA_EM_LOTE.EFETIVAR_TRANSACAO_EM_LOTE_ERRO:
            mensagem = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            transacaoEmLoteEfetuada = { erro: true, mensagem };
            return { ...state, transacaoEmLoteEfetuada: transacaoEmLoteEfetuada };
        case METODOS.TRANSFERENCIA_EM_LOTE.SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE:
            return { ...state, transferenciaEmLoteTokenId: action.payload.id, contato: action.payload.contato };
        case METODOS.TRANSFERENCIA_EM_LOTE.SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE_ERRO:
            return { ...state, transferenciaEmLoteTokenIdErro: action.payload };
        default:
            return state;
    }
};
