export default class Controle {
    static getListaContaDigitalControl() {
        return 'PREENCHER_CONTA_DIGITAL';
    }
    static getListaPortadorControl() {
        return 'PREENCHER_NOME_PORTADOR';
    }
    static getListaClienteProjetoControl() {
        return 'PREENCHER_CLIENTE_PROJETO';
    }
    static getListaTransacoesControl() {
        return 'PREENCHER_TRANSACOES';
    }
    static getListaTransacoesControlErro() {
        return 'PREENCHER_TRANSACOES_ERRO';
    }
    static getListaTipoDespesaControl() {
        return 'PREENCHER_TIPO_DESPESA';
    }
    static getListaPrestacaoContaRegistrosControl() {
        return 'PREENCHER_PRESTACAO_CONTA_REGISTROS';
    }
    static getListaPrestacaoContaControl() {
        return 'PREENCHER_PRESTACAO_CONTA';
    }
    static getListaPrestacaoContaControlErro() {
        return 'PREENCHER_PRESTACAO_CONTA_ERRO';
    }
    static getListaPrestacaoContaListaClienteProjetoControl() {
        return 'PREENCHER_PRESTACAO_CONTA_PREENCHER_CLIENTE_PROJETO';
    }
    static getBindPrestacaoContaControl() {
        return 'BIND_RESTACAO_CONTA';
    }
    static getSelecionarCheckBoxControl() {
        return 'SELECIONAR_CHECKBOX';
    }
    static getPreencherObservacaoControl() {
        return 'PREENCHER_OBSERVACAO';
    }
    static getAbrirTelaDeConfirmacaoControl() {
        return 'ABRIR_TELA_CONFIRMACAO';
    }
    static getToggleTelaDeImagensControl() {
        return 'ABRIR_TELA_IMAGENS';
    }
    static getDefinirTipoOperacaoControl() {
        return 'DEFINIR_TIPO_OPERACAOO';
    }
    static getDefinirMessagemOperacaoControl() {
        return 'DEFINIR_TIPO_MSG_OPERACAOO';
    }
    static getDefinirFiltrosControl() {
        return 'DEFINIR_FILTROS';
    }
    static getLimparFiltrosControl() {
        return 'LIMPAR_FILTROS';
    }
    static getLimparCheckBoxSelecionadoControl() {
        return 'LIMPAR_CHECKBOX_SELECIONADO';
    }
    static getProcessarPrestacaoContaControl() {
        return 'PROCESSAR_PRESTACAO_CONTA';
    }
    static getLimparPrestacaoContaControl() {
        return 'LIMPAR_PRESTACAO_CONTA';
    }
    static getLimparPrestacaoContaBindControl() {
        return 'LIMPAR_PRESTACAO_CONTA_BIND';
    }
    static getLoadingControl() {
        return 'LOADING';
    }
    static getViewImagensControl() {
        return 'DEFINIR_VIEW_IMAGENS';
    }
    static getMsgErroCheckBoxControl() {
        return 'SETAR_MSG_ERR_CHECKBOX';
    }
    static getLimparMsgErroPrestacaoControl() {
        return 'SETAR_MSG_ERR_COMO_NULL';
    }
    static getShowMsgDialogSucessoControl() {
        return 'EXIBIR_MSG_SEUCESSO';
    }
    static getMsgDialogSucessoControl() {
        return 'MSG_SEUCESSO';
    }
    static getShowMsgDialogErrosControl() {
        return 'EXIBIR_MSG_ERROS';
    }
    static getMsgDialogErrosControl() {
        return 'MSG_ERROS';
    }
    static getResetarUltimaChaveControl() {
        return 'RESETAR_ULTIMA_CHAVE';
    }
    static getResetarUltimaChaveTamanhoPaginaControl() {
        return 'RESETAR_ULTIMA_CHAVE_TAMANHO_PAGINACAO';
    }
    static getClickBtnLimparControl() {
        return 'CLICK_BTN_LIMPAR';
    }

    static getListaPrestacaoContaCSVControl() {
        return 'PREENCHER_PRESTACAO_CONTA_CSV';
    }
    static limparDadosCSV() {
        return 'LIMPAR_DADOS_CSV';
    }
    static setMsgErroExportarCSV() {
        return 'ERRO_EXPORTAR_CSV';
    }
    static getToggleCheckAllControl() {
        return 'TOGGLE_CHECK_ALL';
    }
    static getResponseControl() {
        return 'RESULT_RESPONSE';
    }
    static getUltimaChavePaginacaoControl() {
        return 'ULTIMA_CHAVE_PAGINACAO';
    }
    static getQuantidadesRegistro() {
        return 'QUANTIDADES_REGISRO';
    }
    static getBotoesGerenciarShowControl() {
        return 'BOTOES_GERENCIAR_SHOW_CONTROL';
    }
    static getLimparDataInicialErroControl() {
        return 'LIMPAR_DATA_INICIAL_ERRO';
    }
    static getLimparDataFinalErroControl() {
        return 'LIMPAR_DATA_FINAL_ERRO';
    }
    static getListaClassificacaoControl() {
        return 'LISTA_CLASSIFICACAO';
    }
    static getReloadControl() {
        return 'RELOAD';
    }

    static setFiltro() {
        return 'setFiltro';
    }
    static getVisualizarImagemControl() {
        return 'VISUALIZAR_IMAGEM';
    }
}
