import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { InputText } from 'primereact/inputtext';
import { CAMPOS, TIPO_DE_TELEFONE, LABEL_COMUNS, DDI, MASCARA_CAMPOS, MENSAGENS } from '../../../util/contantes-comuns/constantes-comuns';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { removeMascaraTelefoneCpfCnpj } from '../../../util/formata-string';
import { alteraPortadorService, getPessoaByCpf, getVerificaAlteracaoPortadorEmAndamento } from '../../../../action/portador/portador-actions';
import { converterDataBRParaUS, formatarDataBR, dataInvalida, validarDataBR } from '../../../util/data/dataUtil';
import { STATUS_VALIDACAO_COMPONENTES } from '../../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../showLineError/show-line-error';
import { CPF } from 'cpf_cnpj';
import { FormGroup, FormControl } from 'react-bootstrap';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import If from '../../../componentes-uteis/if/if';
import { returnStringWithoutNumbers } from 'componentes/util/string/string-util';


class AlterarPortador extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            cartaoSelecionado: undefined, dataNascimento: '', exibirModalConfirmacao: false,
            exibirModalFinalizacao: false, nomePortador: '', numeroDocumento: '', telefone: '', fieldsLock: false,
            mensagemConfirmacao: '', mensagemFinalizacao: '', disabledFields: true, disabledSave: true, nomePortadorErro: false,
            dataNascimentoErro: false, telefoneErro: false, exibirLoading: false, responseEmAndamento: true, numeroDocumentoErro: false
        };
        this.state = this.INITIAL_STATE;
    }


    populateState = async (nextProps) => {
        if (nextProps && nextProps.cartaoSelecionado) {
            const { cartaoSelecionado } = nextProps;
            let telefoneRecuperado = '';
            const populateState = {
                cartaoSelecionado: cartaoSelecionado,
                dataNascimento: formatarDataBR(cartaoSelecionado.dataNascimento),
                nomePortador: cartaoSelecionado.nomePortador,
                mensagemConfirmacao: MENSAGENS.CARTAO.ALTERAR_PORTADOR.CONFIRMACAO(cartaoSelecionado.idCartaoImpresso, cartaoSelecionado.panFormatado),
            };
            const { numero } = cartaoSelecionado.documento || {};
            let response;
            if (numero) {
                response = await getPessoaByCpf(numero);
                populateState.numeroDocumento = numero;
            }
            if (response) {
                telefoneRecuperado = this.extractTelefone(response.telefone);
                if (telefoneRecuperado) {
                    populateState.telefone = telefoneRecuperado.ddd + telefoneRecuperado.numero;
                }
            }
            this.setState({ ...this.INITIAL_STATE, ...populateState }, () => {
                this.verificarOpAlteracaoEmAndamento();
            });
        }
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    extractTelefone = (telefones) => {
        return telefones.find(tel => tel.principal === true);
    }

    componentWillReceiveProps = (nextProps) => {
        if (this.state.cartaoSelecionado !== nextProps.cartaoSelecionado) {
            this.populateState(nextProps);
        }
    }

    setLimpaCampos = () => {
        this.setState(this.INITIAL_STATE);
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        let valorDoCampo = (campo != null && campo.value) || (campo != null && campo.target != null && campo.target.value);
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }


    setNomePortador = (nomePortador) => {
        const nomePortadorValue = nomePortador && nomePortador.target && nomePortador.target.value;
        if (returnStringWithoutNumbers(nomePortadorValue)) {
            this.setState({ nomePortador: nomePortadorValue, nomePortadorErro: '' });
        }
        else {
            this.setState({ nomePortador: '' });
        }
    }

    setCpf = async (campo, nomeDoCampo) => {

        let valorDoCampo = (campo != null && campo.value) || (campo != null && campo.target != null && campo.target.value);
        if (valorDoCampo.length === 14) {
            this.setExibirLoading(true);
            const response = await getPessoaByCpf(valorDoCampo);
            let telefoneRecuperado = '';
            if (response) {
                telefoneRecuperado = this.extractTelefone(response.telefone);
                const populateState = {
                    dataNascimento: formatarDataBR(response.pessoaFisica.dataNascimento),
                    nomePortador: response.pessoaFisica.nomeCompleto,
                    telefone: telefoneRecuperado ? telefoneRecuperado.ddd + telefoneRecuperado.numero : ''
                };

                this.setState({
                    [nomeDoCampo]: valorDoCampo,
                    [nomeDoCampo + 'Erro']: '',
                    ...populateState,
                    disabledSave: removeMascaraTelefoneCpfCnpj(valorDoCampo) === removeMascaraTelefoneCpfCnpj(this.props.cartaoSelecionado.documento.numero),
                    disabledFields: true,
                    dataNascimentoErro: undefined,
                    nomePortadorErro: undefined,
                    telefoneErro: undefined
                });
            } else {
                this.setState({
                    [nomeDoCampo]: valorDoCampo,
                    [nomeDoCampo + 'Erro']: '',
                    disabledFields: false,
                    disabledSave: false,
                    dataNascimento: '',
                    nomePortador: '',
                    telefone: '',
                    dataNascimentoErro: undefined,
                    nomePortadorErro: undefined,
                    telefoneErro: undefined
                });
            }
        } else {
            this.setState({
                [nomeDoCampo]: valorDoCampo,
                [nomeDoCampo + 'Erro']: '',
                dataNascimentoErro: undefined,
                nomePortadorErro: undefined,
                telefoneErro: undefined
            });
        }
        this.setExibirLoading(false);
    }

    bodyModalAlterarPortador = () => {
        const { dataNascimento, nomePortador, numeroDocumento, telefone, exibirLoading } = this.state;
        const cartaoSelecionado = this.state.cartaoSelecionado || this.props.cartaoSelecionado;
        return (
            <div>
                <div className="alinhamento-centro">
                    <span className="titulo-dashboards-conta-solucao">ID {' '} </span>
                    <span style={{ fontSize: 13 }}>{cartaoSelecionado.idCartaoImpresso} {' '}</span>

                    <span className="titulo-dashboards-conta-solucao"> {' '} Cartão {' '}</span>
                    <span style={{ fontSize: 13 }}>{cartaoSelecionado.panFormatado}</span>
                </div>

                <center>
                    <table style={{ marginTop: 13, width: '80%' }}>
                        <tbody />
                        <thead>
                            <tr>
                                <td style={{ paddingBottom: 22, textAlign: 'right' }}><span className="titulo-dashboards-conta-solucao" style={{ marginRight: 13 }}> {LABEL_COMUNS.CPF}: </span></td>
                                <td>
                                    <FormGroup validationState={this.state.numeroDocumentoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask className="mensagens-erro form-control" mask={MASCARA_CAMPOS.CPF} maskChar="" value={numeroDocumento} onChange={(e) => this.setCpf(e, CAMPOS.NUMERO_DOCUMENTO)} />
                                        <ShowLineError error={this.state.numeroDocumentoErro} />
                                    </FormGroup>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ paddingBottom: 22, textAlign: 'right' }}><span className="titulo-dashboards-conta-solucao" style={{ marginRight: 13 }}> {LABEL_COMUNS.NOME_COMPLETO}: </span></td>

                                <td>
                                    <FormGroup validationState={this.state.nomePortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <FormControl type="text" className="mensagens-erro form-control"
                                            disabled={this.state.disabledFields} value={nomePortador}
                                            onChange={(event) => this.setNomePortador(event)}
                                        />
                                        <ShowLineError error={this.state.nomePortadorErro} />
                                    </FormGroup>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ paddingBottom: 22, textAlign: 'right' }}><span className="titulo-dashboards-conta-solucao" style={{ marginRight: 13 }}> {LABEL_COMUNS.DATA_NASCIMENTO}: </span></td>
                                <td>
                                    <FormGroup validationState={this.state.dataNascimentoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask className="mensagens-erro form-control" disabled={this.state.disabledFields} value={dataNascimento} mask={MASCARA_CAMPOS.DATA} maskChar="" onChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_NASCIMENTO)} />
                                        <ShowLineError error={this.state.dataNascimentoErro} />
                                    </FormGroup>
                                </td>
                            </tr>

                            <tr>
                                <td style={{ paddingBottom: 22, textAlign: 'right' }}><span className="titulo-dashboards-conta-solucao" style={{ marginRight: 13 }}>{TIPO_DE_TELEFONE.CELULAR.label}: </span></td>
                                <td>
                                    <FormGroup validationState={this.state.telefoneErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask className="mensagens-erro form-control" disabled={this.state.disabledFields} value={telefone} mask={MASCARA_CAMPOS.MOBILE} maskChar="" onChange={(e) => this.setPropsCampos(e, CAMPOS.TELEFONE)} />
                                        <ShowLineError error={this.state.telefoneErro} />
                                    </FormGroup>
                                </td>
                            </tr>

                        </thead>
                    </table>
                </center>

            </div>
        );
    }


    verificarOpAlteracaoEmAndamento = async () => {
        const verificaOpEmAndamento = await getVerificaAlteracaoPortadorEmAndamento(this.state.cartaoSelecionado.cartaoId);
        if (verificaOpEmAndamento.error || verificaOpEmAndamento.data.resultado.length !== 0) {
            let mensagemFinalizacao = MENSAGENS.CARTAO.ALTERAR_PORTADOR.EM_PROCESSAMENTO;
            if (this.props.exibirModalAlterarPortador) {
                this.setState({ mensagemFinalizacao, exibirModalFinalizacao: true, responseEmAndamento: verificaOpEmAndamento.error || verificaOpEmAndamento.data.resultado.length !== 0 });
            }
        } else {
            this.setState({ responseEmAndamento: verificaOpEmAndamento.error || verificaOpEmAndamento.data.resultado.length !== 0 });
        }
    }


    alterarPortador = async () => {
        this.setExibirLoading(true);
        const resultado = await alteraPortadorService(this.montaBodyJson());
        let mensagemFinalizacao = resultado.error
            ? `${MENSAGENS.CARTAO.ALTERAR_PORTADOR.ERRO} ${resultado.data.erros.map(e => e.mensagem).join(' ')}`
            : MENSAGENS.CARTAO.ALTERAR_PORTADOR.SUCESSO;
        this.setState({ mensagemFinalizacao, exibirModalFinalizacao: true });
        this.setExibirLoading(false);
    }

    existeErrosCampos = () => {
        const { dataNascimento, nomePortador, numeroDocumento, telefone } = this.state;
        const campoInvalido = 'Campo inválido';
        let hasError = false;
        if (!dataNascimento || (dataNascimento.length === 10 && !validarDataBR(dataNascimento))) {
            this.setState({ dataNascimentoErro: campoInvalido });
            hasError = true;
        }
        if (!nomePortador) {
            this.setState({ nomePortadorErro: campoInvalido });
            hasError = true;
        }
        if (!CPF.isValid(numeroDocumento)) {
            this.setState({ numeroDocumentoErro: campoInvalido });
            hasError = true;
        }

        const telefoneParaSerValidado = removeMascaraTelefoneCpfCnpj(telefone);

        if (!telefoneParaSerValidado || telefoneParaSerValidado.length !== 11) {
            this.setState({ telefoneErro: campoInvalido });
            hasError = true;
        }
        return hasError;
    }

    montaBodyJson = () => {
        const { cartaoSelecionado, dataNascimento, nomePortador, numeroDocumento, telefone } = this.state;
        const telefoneSemMascara = removeMascaraTelefoneCpfCnpj(telefone);
        let ddd = telefoneSemMascara.substring(0, 2);
        let numeroTel = telefoneSemMascara.substring(2);
        const body = {
            operacao: 'alteracaoPortadorCartao',
            contaDestinoId: cartaoSelecionado.cartaoId,
            valor: 0,
            dadosIntegracao: {
                nomePortador: nomePortador,
                documento: {
                    tipo: 'CPF',
                    numero: removeMascaraTelefoneCpfCnpj(numeroDocumento)
                },
                celular: {
                    numero: numeroTel,
                    ddd: ddd,
                    ddi: DDI.BRASIL
                },
                dataNascimento: converterDataBRParaUS(dataNascimento)
            }
        };
        return JSON.stringify(body);
    }

    footerModalAlterarPortador = () => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.SALVAR}
                btn2Disabled={this.state.disabledSave}
                btn2OnClick={() => {
                    if (!this.existeErrosCampos()) {
                        this.props.setExibirModalAlterarPortador(false);
                        this.setState({ exibirModalConfirmacao: true });
                    }
                }}
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.props.setExibirModalAlterarPortador(false);
                    this.setLimpaCampos();
                }}
            />
        );
    }
    footerModalConfirmacao = () => {
        const exibirLoading = this.state.exibirLoading;
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.SIM}
                exibirLoading={exibirLoading}
                btn2OnClick={() => this.alterarPortador()}
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => {
                    this.setState({ exibirModalConfirmacao: false });
                    this.props.setExibirModalAlterarPortador(false);
                    this.setLimpaCampos();
                }}
            />
        );
    }
    footerModalFinalizacao = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.OK}
                btn1OnClick={() => {
                    this.setState({ exibirModalFinalizacao: false });
                    this.props.setExibirModalAlterarPortador(false);
                    this.setLimpaCampos();
                    this.props.getCartoes();
                }}
            />
        );
    }
    render() {
        const exibirLoading = this.state.exibirLoading;
        return (
            <div>
                <If test={exibirLoading}>
                    <LoadingFenix />
                </If>
                <ModalComponent exibirModal={this.state.exibirModalConfirmacao}
                    header={LABEL_COMUNS.ALTERAR_PORTADOR}
                    name="modal-alterar-portador-confirmacao"
                    body={<div dangerouslySetInnerHTML={{ __html: this.state.mensagemConfirmacao }} />}
                    footer={this.footerModalConfirmacao()}
                />
                <ModalComponent exibirModal={this.state.exibirModalFinalizacao}
                    header={LABEL_COMUNS.ALTERAR_PORTADOR}
                    name="modal-alterar-portador-finalizacao"
                    body={this.state.mensagemFinalizacao}
                    footer={this.footerModalFinalizacao()}
                />
                {this.state.cartaoSelecionado && <ModalComponent exibirModal={this.props.exibirModalAlterarPortador && !this.state.responseEmAndamento}
                    header={LABEL_COMUNS.ALTERAR_PORTADOR}
                    name="modal-alterar-portador"
                    body={this.bodyModalAlterarPortador()}
                    footer={this.footerModalAlterarPortador()}
                />}

            </div>
        );
    }
}

export default AlterarPortador;