import React, { Component } from 'react';

class Grid extends Component {
    toCssClassses(numbers) {
        const cols = numbers ? numbers.split(' ') : [];

        let classes = '';
        if (cols[0]) {
            classes += `col-xs-${cols[0]}`;
        }

        if (cols[1]) {
            classes += ` col-sm-${cols[1]}`;
        }

        if (cols[2]) {
            classes += ` col-md-${cols[2]}`;
        }

        if (cols[3]) {
            classes += ` col-lg-${cols[3]}`;
        }
        return classes;
    }

    render() {
        const gridClasses = this.toCssClassses(this.props.cols || '12');
        const customStyle = this.props.customStyle ? this.props.customStyle : {};

        return (
            <div className={this.props.className ? gridClasses + ' ' + this.props.className : gridClasses} style={customStyle}>
                {this.props.children}
            </div>
        );
    }
}

export default Grid;
