import React from 'react';
import './loading-fenix.css';

let cssPadrao = 'glyphicon glyphicon-refresh gly-spin loading-fenix_carregamento';
let cssBppPag = 'glyphicon glyphicon-refresh gly-spin loading-fenix_carregamento-bpp-pag';

function identificarCssCustomUndefined(valor) {
    if (valor === undefined) return false;
    return valor;
}

const LoadingFenix = (props) => (
    <div className="carregando_arquivo">
        <span>
            <i
        className={
          identificarCssCustomUndefined(props.cssBppPag) !== false ? cssBppPag : cssPadrao
        } />
        </span>
    </div>
);
export default LoadingFenix;
