
import { atualizaOutorga, fetchExcluirAutorizadoEOutorga, fetchGetPoderesOutorgaPorPessoa, fetchGetParametroParametroOperacao } from './outorga-base';
import { extrairMensagemDeRespostaBackend } from '../base/service-base';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { fetchAtualizaDadosPessoa } from '../pessoas/pessoas-base';
import { TIPO_DE_TRANSACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const atualizaAutorizadoOutorga = async (dadosAutorizadoOutorga) => {
    let resposta;
    const dadosPessoa = {};
    const { autorizadoSelecionado, celular, pessoaAlterada, pessoaId } = dadosAutorizadoOutorga;
    if (pessoaAlterada) {
        const numeroTelefone = removeMascaraTelefoneCpfCnpj(celular);
        const { ddi, principal, tipo } = autorizadoSelecionado.telefone;
        const ddd = numeroTelefone.substr(0, 2);
        const numero = numeroTelefone.substr(2, numeroTelefone.length);
        const telefone = [{ ddi, ddd, numero, principal, tipo }];
        const email = [{ email: dadosAutorizadoOutorga.email, tipo: autorizadoSelecionado.email.tipo, principal: autorizadoSelecionado.email.principal, emailValido: true }];
        dadosPessoa.telefone = telefone;
        dadosPessoa.email = email;
    }

    await atualizaOutorga(dadosAutorizadoOutorga)
    // .then(async resp => pessoaAlterada ? { respPessoa: await fetchAtualizaDadosPessoa(pessoaId, JSON.stringify(dadosPessoa)), ...resp } : resp)
        .then((result) => {
            resposta = result;
        }).catch((err) => {
            resposta = err;
        });
    return resposta;
};

export const excluirAutorizado = async (contaId, pessoaId) => {
    let response;
    await fetchExcluirAutorizadoEOutorga(contaId, pessoaId)
        .then((result) => {
            response = extrairMensagemDeRespostaBackend(result);
        }).catch((error) => {
            response = extrairMensagemDeRespostaBackend(error);
        });
    return response;
};

export const getPoderesOutorgaPorPessoa = async (contaId, pessoaId) => {
    return fetchGetPoderesOutorgaPorPessoa(contaId, pessoaId)
        .then((result) => {
            return result.data;
        });
};

export const getParametoParametroOperacao = async () => {
    return fetchGetParametroParametroOperacao()
        .then((result) => {
            return result.data;
        });
};

export const getNecessitaOtp = (tipoDeTransacao, outorga) => {
    switch (tipoDeTransacao) {
        case TIPO_DE_TRANSACAO.APROVACAO.transacao:
            return outorga.aprovacao;
        case TIPO_DE_TRANSACAO.AGENDAMENTO.transacao:
            return outorga.agendamento;
        case TIPO_DE_TRANSACAO.CANCELAMENTO.transacao:
        case TIPO_DE_TRANSACAO.CANCELAMENTO_AGENDAMENTO.transacao:
            return outorga.cancelamento;
        default:
            return false;
    }
};
