import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import MostrarSaldoESaldoBloqueado from '../saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import PanelAcoes from '../../componentes-uteis/panel-acoes/panel-acoes';
import PrestacaoDeContasDashboardBppCorp from '../../solucoes/dashboard/bpp-corp/prestacao-de-contas/prestacao-de-contas-dashboard-bpp-corp';
import ConsultaDePedidosDashboardBppCorp from '../../solucoes/dashboard/bpp-corp/consulta-de-pedidos/consulta-de-pedidos-dashboard-bpp-corp';
import { LABEL_COMUNS, NAVEGADOR_NIVEL } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';
import { Button } from 'react-bootstrap';
import ListaCartoesDashboardBppCorp from '../../solucoes/dashboard/bpp-corp/cartoes/lista-cartoes-dashboard-bpp-corp';
import * as NavegadorNivel from './navegador-nivel';

export default class DashboardDepartamento extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        if (this.state.redirecionar) {
            return <Redirect to={this.state.rota} />;
        }
        const contaDepartamentoSelecionada = reactLocalStorage.getObject(LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA);
        return (
            <div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <MostrarSaldoESaldoBloqueado contaSelecionada={contaDepartamentoSelecionada}
                    exibirSaldo
                    mostrarSaldoBloqueado
                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ListaCartoesDashboardBppCorp contaSelecionada={contaDepartamentoSelecionada} />
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <PanelAcoes titulo={LABEL_COMUNS.ACOES_DO_PRODUTO}
                    labelBotao1={LABEL_COMUNS.PEDIR_CARTAO}
                    btn1Link={ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_CORP}
                    labelBotao2={LABEL_COMUNS.DEPARTAMENTO}
                    btn2Link={ROTAS_CAMINHOS.LISTA_CONTA_DEPARTAMENTOS}
                    labelBotao3={LABEL_COMUNS.TRANSFERIR}
                    btn3Link={this.props.contextoMeusDepartamentos && ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_CONTA_DEPARTAMENTO}
                  />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <PrestacaoDeContasDashboardBppCorp conta={contaDepartamentoSelecionada} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ConsultaDePedidosDashboardBppCorp conta={contaDepartamentoSelecionada} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <Grid cols="12 12 12 12" className="alinhamento-direita">
                        {
              /* <Link to={ROTAS_CAMINHOS.LISTA_CONTA_DEPARTAMENTOS}>
                                <Button type="button" bsStyle="primary">{ LABEL_COMUNS.VOLTAR }</Button>
                            </Link> */
            }
                        <Button type="button" bsStyle="primary" onClick={() => {
              var totalDeNivel = NavegadorNivel.anterior(NAVEGADOR_NIVEL.VALUE);

              if (!totalDeNivel) {
                this.setState({ redirecionar: true, rota: ROTAS_CAMINHOS.DASHBOARD_DEPARTAMENTO });
              } else {
                this.setState({ redirecionar: true, rota: ROTAS_CAMINHOS.LISTA_CONTA_DEPARTAMENTOS });
              }
            }}>{LABEL_COMUNS.VOLTAR}</Button>
                    </Grid>
                </div>
            </div>
        );
    }
}
