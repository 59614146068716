
import React from 'react';
import { Button } from 'react-bootstrap';

const AtualizarPagina = props => {
    return (
        <Button style={{
            color: 'white',
            background: 'transparent',
            border: 'none',
            marginTop: props.marginTop

        }} onClick={props.onClick} title={props.title}>
            <span className="fa fa-refresh" aria-hidden="true" />
        </Button>
    );
};
export default AtualizarPagina;
