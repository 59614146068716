import React, { Component } from 'react';
import PieChartMinimal from 'react-minimal-pie-chart';
import { MENSAGENS } from '../util/contantes-comuns/constantes-comuns';

const defaultLabelStyle = {
    fontSize: '3.5px',
    fontFamily: 'sans-serif',
    fill: 'white'
};

const arrayColor = ['#113f67', '#4089b4', '#226597', '#65c6c4'];

class PieChart extends Component {
    constructor(props) {
        super(props);
        let dataStateWithValue = props.dataState;

        if (dataStateWithValue !== 'error' && dataStateWithValue !== undefined && dataStateWithValue !== null) {
            dataStateWithValue = dataStateWithValue.filter(p => parseFloat(p.percentage) > 0);

            for (let i = 0; i < dataStateWithValue.length; i++) {
                dataStateWithValue[i].value = parseFloat(dataStateWithValue[i].percentage);
                dataStateWithValue[i].percentage = parseFloat(dataStateWithValue[i].percentage).toFixed(1);
                dataStateWithValue[i].color = arrayColor[i];
            }
        }

        if (dataStateWithValue != null && dataStateWithValue !== 'error') {
            dataStateWithValue = dataStateWithValue.sort((a, b) => b.value - a.value);
        }
        let labelPosition = 50;
        if (this.props.prestacao) {
            labelPosition = 87;
        }

        this.state = {
            isLoading: false,
            dataState: dataStateWithValue,
            labelPosition: labelPosition
        };
    }

    render() {
        const dataState = this.state.dataState;
        const name = this.props.name;
        return (
            <div className="row" style={{ width: '100%' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div className="panel panel-default" style={{ width: '95%', minHeight: '338px', maxHeight: '338px' }}>
                        <div className="panel-heading fundo-azul-claro" style={{ fontWeight: 900 }}>{name}</div>
                        <div className="panel-body">
                            <div style={{ fontWeight: 900 }}>Status</div>
                            {dataState === 'error'
                ? <div style={{ textAlign: 'center' }}>{MENSAGENS.REPORTS.MANAGEMENT.ERROR}</div>
                : dataState === null || dataState === undefined || dataState.length === 0 ? <div style={{ textAlign: 'center' }}> {name === 'Cartões' ? MENSAGENS.REPORTS.EMPTY_CARTAO : name === 'Prestação de contas' ? MENSAGENS.REPORTS.EMPTY_PRESTACAO : MENSAGENS.REPORTS.MANAGEMENT.EMPTY_RESPONSE} </div>
                  : <div style={{ flexDirection: 'row', flex: 1 }}>
                      <div style={{ flex: 0.5, width: '60%', float: 'left', maxWidth: '310px' }}>
                          <PieChartMinimal
                        label={({ data, dataIndex }) =>
                          data[dataIndex].percentage + '%'
                        }
                        radius={33}
                        labelStyle={defaultLabelStyle}
                        labelPosition={this.state.labelPosition}
                        animate
                        data={dataState}
                      />
                      </div>
                      <div style={{ flex: 0.5, width: '35%', float: 'right', marginTop: 70 }}>
                          <div style={{ fontWeight: 900 }}>Descrição</div>
                          {dataState && dataState.map((dataChart, idx) => {
                        return (
                            <div key={idx} style={{ marginTop: 10, flexDirection: 'row', display: 'flex' }}>
                                <div style={{ width: 10, height: 10, backgroundColor: dataChart.color, marginTop: 5 }} />
                                <div style={{ marginLeft: 10 }}>{dataChart.status}</div>
                            </div>
                        );
                      })}
                      </div>
                  </div>
              }

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default PieChart;
