import { CPF } from 'cpf_cnpj';
import * as EmailValidator from 'email-validator';
import { getSaldoContaOuCartao } from '../contas/contas-base';
import {
    CAMPOS, DDI, FORMA_ENTREGA, FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER, INDICE_COLUNA_ARQUIVO, LABEL_COMUNS, MENSAGENS, OPERACAO,
    OPERADORES_BOOLEANOS, TIPO_DOCUMENTO, TIPO_ENTREGA, TIPO_IMAGEM, TIPO_PEDIDO, TIPO_DE_TRANSACAO
} from '../../componentes/util/contantes-comuns/constantes-comuns';
import { formatarDataUS, formatarDataEHoraBR, converterDataBRParaUS, dataInvalida } from '../../componentes/util/data/dataUtil';
import { stringParaNumero, removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { HEADER_API, URL_GET_IMAGEM_PERSONALIZADA_STATUS, URL_ARQUIVOS } from '../url-servicos';
import { environment } from '../../configuracoes/environment';
import { isEmptyObject } from '../base/service-base';


export const saldoSuficienteParaEfetuarPedidoEmbossingCartao = async (pedido) => {
    const { contaSelecionada } = pedido;
    const { data: { saldo } } = await getSaldoContaOuCartao(contaSelecionada.id);
    const valorDoPedido = recuperaValorPedidoEmbossingCartao(pedido);
    return saldo >= valorDoPedido.valor;
};

export const recuperaValorPedidoEmbossingCartao = (pedido) => {
    const {
        anexoSelecionado,
        formaEntregaSelecionada,
        tipoEntregaSelecionada,
        quantidadeCartoes
    } = pedido;

    const quantidade = parseInt(quantidadeCartoes, 10);
    let valorEntrega = 0;
    let valorImpressao = 0;

    if (anexoSelecionado.pedidoEmbossingCartao && anexoSelecionado.pedidoEmbossingCartao.tarifa) {
        const {
            pedidoEmbossingCartao: {
                tarifa: {
                    tarifaPedidoEmbossingCartao,
                    tarifaPedidoEmbossingCartaoEntregaSedex,
                    tarifaPedidoEmbossingCartaoEntregaSedex10,
                    tarifaPedidoEmbossingCartaoEntregaCartaRegistrada,
                    tarifaPedidoEmbossingCartaoEntregaRetiradaBPP,
                    tarifaPedidoEmbossingCartaoEntregaTransportadora
                }
            }
        } = anexoSelecionado;

        switch (formaEntregaSelecionada) {
            case FORMA_ENTREGA.SEDEX.value:
                valorEntrega = calculaValorParaTipoEntregaSelecionada(quantidade, tipoEntregaSelecionada, tarifaPedidoEmbossingCartaoEntregaSedex);
                valorImpressao = quantidade * tarifaPedidoEmbossingCartao.valor;
                break;

            case FORMA_ENTREGA.SEDEX_10.value:
                valorEntrega = calculaValorParaTipoEntregaSelecionada(quantidade, tipoEntregaSelecionada, tarifaPedidoEmbossingCartaoEntregaSedex10);
                valorImpressao = quantidade * tarifaPedidoEmbossingCartao.valor;
                break;

            case FORMA_ENTREGA.CARTA_REGISTRADA.value:
                valorEntrega = calculaValorParaTipoEntregaSelecionada(quantidade, tipoEntregaSelecionada, tarifaPedidoEmbossingCartaoEntregaCartaRegistrada);
                valorImpressao = quantidade * tarifaPedidoEmbossingCartao.valor;
                break;

            case FORMA_ENTREGA.RETIRADA_BPP.value:
                valorEntrega = tarifaPedidoEmbossingCartaoEntregaRetiradaBPP.valor;
                valorImpressao = quantidade * tarifaPedidoEmbossingCartao.valor;
                break;

            case FORMA_ENTREGA.TRANSPORTADORA.value:
                valorEntrega = calculaValorParaTipoEntregaSelecionada(quantidade, tipoEntregaSelecionada, tarifaPedidoEmbossingCartaoEntregaTransportadora);
                valorImpressao = quantidade * tarifaPedidoEmbossingCartao.valor;
                break;

            default:
                break;
        }
    }

    const valorPedido = {
        valor: (parseFloat(valorImpressao) + parseFloat(valorEntrega)).toFixed(2),
        valorEntrega: parseFloat(valorEntrega).toFixed(2),
        valorImpressao: parseFloat(valorImpressao).toFixed(2),
        quantidade
    };

    return valorPedido;
};

export const retornaProdutoFELId = (anexo) => {
    let produtoFELId;
    if (anexo && anexo.midia) {
        produtoFELId = anexo.midia.produtoFELId;
    }
    return produtoFELId;
};

export function montaListaPedidos(pedidosExistentes, listaDePedidos) {
    let pedidosAux = [];
    let pedidosParaRetornar = [];
    pedidosParaRetornar.quantidadeTotalRegistros = listaDePedidos.quantidadeTotalRegistros;
    pedidosParaRetornar.tamanhoPagina = listaDePedidos.tamanhoPagina;
    pedidosParaRetornar.totalElementos = listaDePedidos.totalElementos;
    pedidosParaRetornar.ultimaChave = listaDePedidos.ultimaChave;
    if (listaDePedidos && listaDePedidos.resultado) {
        listaDePedidos.resultado.map((pedido) => {
            return pedidosAux.push(montaPedido(pedido));
        });
    }

    if (pedidosExistentes && pedidosExistentes.length > 0) {
        pedidosAux.map((pedido) => {
            if (!pedidosExistentes.indexOf(pedido) > -1) {
                return pedidosExistentes.push(pedido);
            }
        });
    } else {
        pedidosExistentes = pedidosAux;
    }
    pedidosParaRetornar.pedidos = pedidosExistentes;
    return pedidosParaRetornar;
}

export function montaFiltroQueryString(produto, dataInicial, dataFinal, anexoContratualId, valorDe, valorAte, operacao, status, ultimaChave, tamanhoPagina) {
    if (!tamanhoPagina) {
        tamanhoPagina = 50;
    }
    let filtro = '?tamanhoPagina=' + tamanhoPagina;

    if (produto) {
        filtro = filtro + '&produto=' + produto;
    }

    if (dataInicial) {
        filtro = filtro + '&dataInicial=' + formatarDataUS(dataInicial);
    }

    if (dataFinal) {
        filtro = filtro + '&dataFinal=' + formatarDataUS(dataFinal);
    }

    if (anexoContratualId) {
        filtro = filtro + '&anexoContratualOriginal=' + anexoContratualId;
    }

    if (valorDe) {
        filtro = filtro + '&valorDe=' + stringParaNumero(valorDe);
    }

    if (valorAte) {
        filtro = filtro + '&valorAte=' + stringParaNumero(valorAte);
    }

    if (operacao) {
        filtro = filtro + '&operacao=' + operacao;
    }

    if (status) {
        filtro = filtro + '&status=' + status;
    }

    if (ultimaChave) {
        filtro = filtro + '&ultimaChave=' + ultimaChave;
    }

    return filtro;
}

export function preencheCamposDeErros(erros) {
    let camposDeErros = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case CAMPOS.IMAGEM_CARTAO:
                camposDeErros.imagemCartaoErro = erros[i].mensagem;
                break;
            case CAMPOS.IMAGEM_CARTA_BERCO:
                camposDeErros.imagemCartaBercoErro = erros[i].mensagem;
                break;
            case CAMPOS.DATA_INICIAL:
                camposDeErros.dataInicialErro = erros[i].mensagem;
                break;
            case CAMPOS.DATA_FINAL:
                camposDeErros.dataInicialErro = erros[i].mensagem;
                break;
            default:
                camposDeErros.quantidadeErro = erros[i].mensagem;
                break;
        }
    }
    return camposDeErros;
}

export const montaJsonPedidoCartaoImpressoParaBackEnd = (pedido) => {
    const PEDIDO_EMBOSSING_CARTAO = 'pedidoEmbossingCartao';
    const pedidoEmbossingJson = {};
    let cartao = [];

    let enderecoLote = {
        logradouro: pedido.logradouro,
        numero: pedido.numero,
        bairro: pedido.bairro,
        cidade: pedido.nomeDaCidadeSelecionada,
        estado: pedido.siglaDoEstadoSelecionado,
        pais: 'Brasil',
        cep: pedido.cep,
        destinatario: pedido.destinatario,
        aosCuidados: pedido.aosCuidados
    };

    if (pedido.complemento) {
        enderecoLote.complemento = pedido.complemento;
    }

    if (pedido.pontoReferencia) {
        enderecoLote.pontoReferencia = pedido.pontoReferencia;
    }

    let dadosIntegracao = {
        formaEntrega: pedido.formaEntregaSelecionada,
        tipoEntrega: pedido.tipoEntregaSelecionada,
        validadeMinima: 0,
        quantidade: parseInt(pedido.quantidade),
        comboId: pedido.comboFelIdSelecionado,
        produtoFELId: pedido.produtoFELId,
        imprimirNomeCartao: OPERADORES_BOOLEANOS.FALSE,
        imprimirCampoLivreCartao: OPERADORES_BOOLEANOS.FALSE,
        imprimirCampoLivreTrilhaMagnetica: OPERADORES_BOOLEANOS.FALSE,
        imprimirCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        imprimirNomeCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        imprimirSenhaPINCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        imprimirCampoLivreCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        imprimirEnderecoCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        imprimirProdutoCartaBerco: OPERADORES_BOOLEANOS.FALSE,
        enderecoLote: pedido.tipoEntregaSelecionada === TIPO_ENTREGA.EM_LOTE.value && pedido.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value ? enderecoLote : {}
    };

    if (pedido.arquivoSelecionado) {
        for (var i = 0; i < pedido.dadosArquivoSelecionado.length; i++) {
            if (pedido.dadosArquivoSelecionado[i].length > 0) {
                let enderecoIndividual = {};
                let linhaSemAcentos = substituirAcentosECaracteresEspeciais(pedido.dadosArquivoSelecionado[i]);
                var linhaDoArquivo = linhaSemAcentos.split(';');

                if (linhaDoArquivo.length == 0 || linhaDoArquivo.length == 1) {
                    linhaDoArquivo = linhaSemAcentos.split(',');
                }

                let cartaBerco = {
                    nomeImpresso: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO] : null,
                    campoLivreCartaBerco: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTA_BERCO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTA_BERCO] : null
                };

                if (pedido.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value) {
                    let complemento = linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.COMPLEMENTO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.COMPLEMENTO] : null;
                    let pontoReferencia = linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PONTO_REFERENCIA] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PONTO_REFERENCIA] : null;
                    let logradouro = linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO] : null;
                    enderecoIndividual = {
                        logradouro: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO] : null,
                        numero: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO] : null,
                        bairro: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO] : null,
                        cidade: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE] : null,
                        estado: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF] : null,
                        pais: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS] : null,
                        cep: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP] : null
                    };

                    if (logradouro) {
                        if (complemento) {
                            enderecoIndividual.complemento = complemento;
                        }
                        if (pontoReferencia) {
                            enderecoIndividual.pontoReferencia = pontoReferencia;
                        }
                    }
                }
                let campoLivreTrilhaMagneticaTratado = linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_TRILHA] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_TRILHA].replace(/\n|\r/g, '') : null;

                cartao.push({
                    nomeImpresso: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO] : null,
                    ddiCelularPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR] : null,
                    dddCelularPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR] : null,
                    celularPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR] : null,
                    emailPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR] : null,
                    dataNascimentoPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR] ? converterDataBRParaUS(linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]) : null,
                    tipoDocumentoPortador: TIPO_DOCUMENTO.CPF.value,
                    numeroDocumentoPortador: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR] : null,
                    campoLivreCartao: linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTAO] ? linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTAO] : null,
                    campoLivreTrilhaMagnetica: campoLivreTrilhaMagneticaTratado || null,
                    cartaBerco: cartaBerco,
                    enderecoIndividual: pedido.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value ? enderecoIndividual : {}
                });
                const existeEnderecoIndividual = Object.keys(enderecoIndividual).some(key => enderecoIndividual[key]);
                dadosIntegracao.imprimirNomeCartao = cartao[i].nomeImpresso ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirCampoLivreCartao = cartao[i].campoLivreCartao ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirCartaBerco = cartao[i].cartaBerco && (cartao[i].cartaBerco.nomeImpresso || cartao[i].cartaBerco.campoLivreCartaBerco) ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirNomeCartaBerco = cartao[i].nomeImpresso ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirSenhaPINCartaBerco = OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirCampoLivreCartaBerco = cartaBerco.campoLivreCartaBerco ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirEnderecoCartaBerco = existeEnderecoIndividual ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                dadosIntegracao.imprimirProdutoCartaBerco = OPERADORES_BOOLEANOS.TRUE;

                if (dadosIntegracao.imprimirCampoLivreTrilhaMagnetica === OPERADORES_BOOLEANOS.FALSE) {
                    dadosIntegracao.imprimirCampoLivreTrilhaMagnetica = linhaDoArquivo[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_TRILHA].replace(/\s/g, '') ? OPERADORES_BOOLEANOS.TRUE : OPERADORES_BOOLEANOS.FALSE;
                }
            }
        }
    } else {
        for (var i = 0; i < pedido.quantidade; i++) {
            cartao.push({});
        }
    }

    pedidoEmbossingJson.operacao = PEDIDO_EMBOSSING_CARTAO;
    pedidoEmbossingJson.contaOrigemId = pedido.contaSelecionada.id;
    pedidoEmbossingJson.anexoContratualOriginalId = pedido.anexoSelecionado.id;
    pedidoEmbossingJson.valor = parseFloat(pedido.valorPedido);
    dadosIntegracao.cartao = cartao;
    pedidoEmbossingJson.dadosIntegracao = dadosIntegracao;

    return JSON.stringify(pedidoEmbossingJson);
};

export const montarBodyOperacaoTransacao = (valorEntrega, token) => {
    return JSON.stringify({ ...TIPO_DE_TRANSACAO.APROVACAO, valor: valorEntrega, token });
};

export function montaPedidosImagens(listaDeImagens) {
    let listaRetorno = [];
    let resposta = { registros: '', listaStatus: '' };
    listaDeImagens.map((imagem) => {
        var registro = imagem;
        let idArquivo = imagem.urlImagem;
        idArquivo = idArquivo.replace('https://', '');
        idArquivo = idArquivo.replace('.s3.amazonaws.com/', '');
        idArquivo = idArquivo.replace(environment.arquivosPedidos, '');
        imagem.urlImagem = 'alterar';

        var request = require('sync-request');

        var res = request('GET', URL_ARQUIVOS() + '?bucket=' + environment.arquivosPedidos + '&key=' + idArquivo, HEADER_API());
        if (res.statusCode === 200) {
            var assinatura = JSON.parse(res.getBody());
            imagem.urlImagem = assinatura.presignedUrl;
        }

        listaRetorno.push(registro);
        return true;
    });

    var request = require('sync-request');
    var res = request('GET', URL_GET_IMAGEM_PERSONALIZADA_STATUS(), HEADER_API());
    if (res.statusCode === 200) {
        var listaStatus = JSON.parse(res.getBody());
    }
    resposta.registros = listaRetorno;
    resposta.listaStatus = listaStatus;
    return resposta;
}

export function validarFormPedidoCartaoVirtual(cartao) {
    let erros = {};
    Object.keys(cartao).map(key => {
        const value = cartao[key];
        if (key !== CAMPOS.DDD_CELULAR_PORTADOR) {
            if (!value) erros[key + 'Erro'] = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }
    });

    if (cartao.celularPortador.length < 14 && !erros.celularPortadorErro) {
        erros.celularPortadorErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CELULAR_INVALIDO;
    }

    if (dataInvalida(cartao.dataNascimentoPortador) && !erros.dataNascimentoPortadorErro) {
        erros.dataNascimentoPortadorErro = MENSAGENS.VALIDACAO_DE_CAMPOS.DATA_INVALIDA;
    }

    if (cartao.nomeImpresso.length < 10 && erros.nomeImpressoErro) {
        erros.nomeImpressoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.NOME);
    }

    if (!CPF.isValid(cartao.numeroDocumentoPortador)) {
        erros.numeroDocumentoPortadorErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.CPF);
    }

    if (!EmailValidator.validate(cartao.emailPortador)) {
        erros.emailPortadorErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.EMAIL);
    }
    return erros;
}

export function montaDadosParaPedidoCartaoVirtual(dados) {
    let operacaoPedidoCartaoVirtual = getOperacaoDoAnexo(dados.anexoContratual, OPERACAO.NOME.PEDIDO_DE_CARTAO_VIRTUAL);
    let pedidoDeCartaoVirtual = {
        operacao: OPERACAO.NOME.PEDIDO_DE_CARTAO_VIRTUAL,
        contaOrigemId: dados.contaSelecionada.id,
        anexoContratualOriginalId: dados.anexoContratual.id,
        valor: operacaoPedidoCartaoVirtual.tarifa.tarifaPedidoCartaoVirtual.valor,
        dadosIntegracao: {
            formaEntrega: FORMA_ENTREGA.RETIRADA_BPP.value,
            tipoEntrega: TIPO_ENTREGA.INDIVIDUAL.value,
            validadeMinima: 0,
            quantidade: 1,
            comboId: dados.comboRecuperado.felId,
            produtoFELId: dados.anexoContratual.midia.produtoFELId,
            imprimirNomeCartao: true,
            imprimirCampoLivreCartao: false,
            imprimirCampoLivreTrilhaMagnetica: false,
            imprimirCartaBerco: false,
            imprimirNomeCartaBerco: false,
            imprimirSenhaPINCartaBerco: false,
            imprimirCampoLivreCartaBerco: false,
            imprimirEnderecoCartaBerco: false,
            imprimirProdutoCartaBerco: false,
            cartao: []
        }
    };
    pedidoDeCartaoVirtual.dadosIntegracao.cartao.push(montaDadosCartaoParaPedidoCartaoVirtual(dados.cartao));
    return JSON.stringify(pedidoDeCartaoVirtual);
}

export function getOperacaoDoAnexo(anexo, operacaoNome) {
    const operacaoFiltrada = Object.keys(anexo)
        .filter(key => key === CAMPOS.OPERACAO)
        .reduce((obj, key) => {
            obj[key] = anexo[key];
            return obj.operacao[operacaoNome];
        }, {});
    return operacaoFiltrada;
}

export function montaImagensParaCombo(imagens, tipoDaImagem) {
    let listaDeImangens = [];
    imagens.map((img) => {
        if (img.tipoPedido === tipoDaImagem) {
            listaDeImangens.push({ value: img.id, label: img.nome });
        }
    });
    return listaDeImangens;
}

export function getImagemById(imagens, imagemId) {
    let imagem = '';
    imagens.map((img) => {
        if (img.id === imagemId) {
            imagem = img;
        }
    });
    return imagem;
}

export const getJsonPedidoEmbossingBppConsumer = (data) => {

    const {
        comboId, contractualAttachmentId, bearerAddress, bearerNameDocumentNumber, bearerNameDocumentType,
        bearerName, deliveryWay, selectedAccount, orderTax, productFELId
    } = data;

    const orderBodyToReturn = {
        operacao: OPERACAO.NOME.PEDIDO_EMBOSSING_CARTAO,
        valor: calculateValueOrderEmbossingCardBppConsumer(orderTax, deliveryWay),
        contaOrigemId: selectedAccount.id,
        anexoContratualOriginalId: contractualAttachmentId,
        dadosIntegracao:
        {
            formaEntrega: deliveryWay,
            tipoEntrega: TIPO_ENTREGA.INDIVIDUAL.value,
            validadeMinima: 100,
            quantidade: 1,
            comboId,
            produtoFELId: productFELId,
            imprimirNomeCartao: OPERADORES_BOOLEANOS.TRUE,
            imprimirCampoLivreCartao: OPERADORES_BOOLEANOS.FALSE,
            imprimirCampoLivreTrilhaMagnetica: OPERADORES_BOOLEANOS.FALSE,
            imprimirCartaBerco: OPERADORES_BOOLEANOS.TRUE,
            imprimirNomeCartaBerco: OPERADORES_BOOLEANOS.TRUE,
            imprimirSenhaPINCartaBerco: OPERADORES_BOOLEANOS.FALSE,
            imprimirCampoLivreCartaBerco: OPERADORES_BOOLEANOS.FALSE,
            imprimirEnderecoCartaBerco: OPERADORES_BOOLEANOS.FALSE,
            imprimirProdutoCartaBerco: OPERADORES_BOOLEANOS.FALSE,
            vincularPortador: OPERADORES_BOOLEANOS.TRUE,
            cartao:
                [
                    {
                        nomeImpresso: bearerName,
                        tipoDocumentoPortador: bearerNameDocumentType,
                        numeroDocumentoPortador: bearerNameDocumentNumber,
                        cartaBerco:
                        {
                            nomeImpresso: bearerName
                        },
                        enderecoIndividual: isEmptyObject(bearerAddress) ? null : bearerAddress
                    }
                ]
        }
    };

    return JSON.stringify(orderBodyToReturn);
};


export const calculateValueOrderEmbossingCardBppConsumer = (orderTax, deliveryWay) => {
    const { orderValue, orderValueDeliveryWayBPP, orderValueDeliveryWaySedex } = orderTax;
    let valueToReturn = parseFloat(orderValue) + parseFloat(orderValueDeliveryWayBPP);
    if (deliveryWay === FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER.CORREIOS.value) {
        valueToReturn = parseFloat(orderValue) + parseFloat(orderValueDeliveryWaySedex);
    }
    return valueToReturn;
};

export function montaJsonArrayParaEnvioParaBackEnd(contaDigitalId, dadosForm) {
    let pedidoEstoque = { pedidos: [] };

    if (dadosForm.cartaoSelecionado) {
        pedidoEstoque.pedidos.push(montaDadosJson(contaDigitalId, dadosForm.cartaoSelecionado, dadosForm.quantidade));
    }
    if (dadosForm.cartaBercoSelecionada) {
        pedidoEstoque.pedidos.push(montaDadosJson(contaDigitalId, dadosForm.cartaBercoSelecionada, dadosForm.quantidade));
    }
    return pedidoEstoque;
}

function montaDadosJson(contaDigitalId, dados, quantidade) {
    let jsonToSendBackEnd = {
        contaDigitalId: contaDigitalId,
        tipoPedido: dados.tipoPedido === TIPO_IMAGEM.CARTAO ? TIPO_PEDIDO.ESTOQUE_CARTAO : TIPO_PEDIDO.ESTOQUE_CARTA_BERCO,
        quantidade: quantidade,
        idImagem: dados.id
    };
    return jsonToSendBackEnd;
}

function montaPedido(pedido) {
    let pedidoParaRetornar = {};
    pedidoParaRetornar.dataCriacao = formatarDataEHoraBR(pedido.dataCriacao);
    pedidoParaRetornar.nomeAnexoContratual = pedido.nomeAnexoContratual;
    pedidoParaRetornar.operacao = pedido.operacao;
    pedidoParaRetornar.lancamentoFinanceiroExibicao = pedido.lancamentoFinanceiroExibicao;
    pedidoParaRetornar.statusExterno = pedido.statusExterno;
    pedidoParaRetornar.statusInterno = pedido.statusInterno;
    pedidoParaRetornar.statusExibicao = pedido.statusExibicao;
    // pedidoParaRetornar.status = OPERACAO_STATUS.filter(status => (status.value === pedido.statusInterno))[0].label;
    pedidoParaRetornar.quantidade = pedido.quantidade;
    pedidoParaRetornar.valor = pedido.valor;
    pedidoParaRetornar.pedidoFelId = pedido.pedidoFelId;
    return pedidoParaRetornar;
}

const substituirAcentosECaracteresEspeciais = (texto) => {
    // Adicionar caracteres e/ou acentuações no mapeamento abaixo conforme necessário
    let mapeamento = [
        { comAcento: 'ã', semAcento: 'a' }, { comAcento: 'á', semAcento: 'a' }, { comAcento: 'à', semAcento: 'a' },
        { comAcento: 'â', semAcento: 'a' }, { comAcento: 'ª', semAcento: 'a' }, { comAcento: 'Ã', semAcento: 'A' },
        { comAcento: 'Á', semAcento: 'A' }, { comAcento: 'À', semAcento: 'A' }, { comAcento: 'Â', semAcento: 'A' },
        { comAcento: 'é', semAcento: 'e' }, { comAcento: 'è', semAcento: 'e' }, { comAcento: 'ê', semAcento: 'e' },
        { comAcento: 'É', semAcento: 'E' }, { comAcento: 'È', semAcento: 'E' }, { comAcento: 'Ê', semAcento: 'E' },
        { comAcento: 'í', semAcento: 'i' }, { comAcento: 'ì', semAcento: 'i' }, { comAcento: 'ï', semAcento: 'i' },
        { comAcento: 'Í', semAcento: 'I' }, { comAcento: 'Ì', semAcento: 'I' }, { comAcento: 'Ï', semAcento: 'I' },
        { comAcento: 'ó', semAcento: 'o' }, { comAcento: 'ò', semAcento: 'o' }, { comAcento: 'ô', semAcento: 'o' },
        { comAcento: 'õ', semAcento: 'o' }, { comAcento: 'º', semAcento: 'o' }, { comAcento: 'Ó', semAcento: 'O' },
        { comAcento: 'Ò', semAcento: 'O' }, { comAcento: 'Ô', semAcento: 'O' }, { comAcento: 'Õ', semAcento: 'O' },
        { comAcento: 'ú', semAcento: 'u' }, { comAcento: 'ù', semAcento: 'u' }, { comAcento: 'ü', semAcento: 'u' },
        { comAcento: 'û', semAcento: 'u' }, { comAcento: 'Ú', semAcento: 'U' }, { comAcento: 'Ù', semAcento: 'U' },
        { comAcento: 'Ü', semAcento: 'U' }, { comAcento: 'Û', semAcento: 'U' }, { comAcento: 'ç', semAcento: 'c' },
        { comAcento: 'Ç', semAcento: 'C' }, { comAcento: '`', semAcento: ' ' }, { comAcento: '´', semAcento: ' ' },
        { comAcento: '\'', semAcento: ' ' }, { comAcento: '"', semAcento: ' ' }, { comAcento: '~', semAcento: ' ' },
        { comAcento: '?', semAcento: ' ' }, { comAcento: '^', semAcento: ' ' }, { comAcento: '#', semAcento: ' ' },
        { comAcento: '$', semAcento: ' ' }, { comAcento: '%', semAcento: ' ' }, { comAcento: '¨', semAcento: ' ' },
        { comAcento: '&', semAcento: ' ' }, { comAcento: '*', semAcento: ' ' }, { comAcento: '<', semAcento: ' ' },
        { comAcento: '>', semAcento: ' ' }, { comAcento: ':', semAcento: ' ' }, { comAcento: '+', semAcento: ' ' },
        { comAcento: '-', semAcento: ' ' }, { comAcento: '_', semAcento: ' ' }, { comAcento: '=', semAcento: ' ' },
        { comAcento: '{', semAcento: ' ' }, { comAcento: '}', semAcento: ' ' }, { comAcento: '[', semAcento: ' ' },
        { comAcento: ']', semAcento: ' ' }, { comAcento: '|', semAcento: ' ' }
    ];

    let novoTexto = '';
    for (let i = 0; i < texto.length; i++) {
        let achou = false;
        for (let j = 0; j < mapeamento.length; j++) {
            if (texto.charAt(i) === mapeamento[j].comAcento) {
                novoTexto = novoTexto + mapeamento[j].semAcento;
                achou = true;
                continue;
            }
        }
        if (!achou) {
            novoTexto = novoTexto + texto.charAt(i);
        }
    }
    return novoTexto;
};

function montaDadosCartaoParaPedidoCartaoVirtual(cartao) {
    let cartaoParaRetornar = cartao;
    let telefone = removeMascaraTelefoneCpfCnpj(cartao.celularPortador);
    cartaoParaRetornar.ddiCelularPortador = DDI.BRASIL;
    cartaoParaRetornar.dddCelularPortador = telefone.substring(0, 2);
    cartaoParaRetornar.celularPortador = telefone.substring(2, telefone.length);
    cartaoParaRetornar.numeroDocumentoPortador = removeMascaraTelefoneCpfCnpj(cartao.numeroDocumentoPortador);
    cartaoParaRetornar.dataNascimentoPortador = formatarDataUS(cartao.dataNascimentoPortador);
    cartaoParaRetornar.tipoDocumentoPortador = TIPO_DOCUMENTO.CPF.value;

    return cartaoParaRetornar;
}

const calculaValorParaTipoEntregaSelecionada = (quantidade, tipo, tarifa) => {
    const { valor } = tarifa;

    if (tipo === TIPO_ENTREGA.EM_LOTE.value) {
        return parseFloat(valor);
    } else {
        return quantidade * parseFloat(valor);
    }
};
