import React from 'react';
import * as moment from 'moment';
import { MenuItem, Glyphicon, Dropdown, Button } from 'react-bootstrap';
import FooterBotoesModalComponent from '../modal-component/footer-botoes-modal-component';
import ModalComponent from '../modal-component/modal-component';
import { LABEL_COMUNS, CAMPOS } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../grid/grid';
import If from '../if/if';
import { formatarCampoValor, exibeTransacaoValor, ordenarExtratoPorDataDecrescente } from '../../util/extrato/extrato-util';
import { formataHoraPtBr, formatarDataBR, formatarDataCompletaBR } from '../../util/data/dataUtil';

const DetalheExtratoTransacoes = (props) => {
    let listaDeTransacoes = props.linhaDoExtrato && props.linhaDoExtrato.listaDeTransacoes || [];
    if (listaDeTransacoes.length) {
        listaDeTransacoes.sort((a, b) => moment(b.dataHora).diff(moment(a.dataHora)));
    }
    const corpoModal = () => {
        return (
            <div className="row">
                <div className="panel-body" style={{ marginBottom: '-50px' }}>
                    <div className="list-group">
                        <div className="row form-group">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="conteudo_extratoconta">
                                    <div className="list-group">
                                        <li className="list-group-item fundo-cinza-claro">
                                            <div className="row">
                                                <div className="col-sm-2 col-md-2 col-lg-2">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.DATA_HORA}</span></b>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.NOME}</span></b>
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 posicao-titulo-transacao-detalhe">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.DETALHE}</span></b>
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 posicao-titulo-transacao-detalhe">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.DESCRICAO}</span></b>
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.VALOR}</span></b>
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <b><span className="titulo-tabelas">{LABEL_COMUNS.ACAO}</span></b>
                                                </div>
                                            </div>
                                        </li>
                                        <li className="list-group-item">
                                            <div className="row">
                                                <div cols="col-md-12" />
                                            </div>
                                        </li>
                                        <div className="div-transacoes-pag">
                                            {
                        listaDeTransacoes.map((transacao, idx) => {
                          return (
                              <li key={idx} className="list-group-item">
                                  <div className="row">
                                      <div className="col-sm-2 col-md-2 col-lg-2">
                                          <span style={{ fontSize: '11px' }}>
                                              {formatarDataCompletaBR(transacao.dataHora)}
                                          </span>
                                      </div>
                                      <div className="col-sm-3 col-md-3 col-lg-3">
                                          <span style={{ fontSize: '11px' }}>
                                              {transacao.nome}
                                          </span>
                                      </div>
                                      <div className="col-sm-3 col-md-3 col-lg-3">
                                          <span style={{ fontSize: '11px' }}>
                                              {transacao.descricao}
                                          </span>
                                      </div>
                                      <div className="col-sm-1 col-md-1 col-lg-1">
                                          <span style={{ fontSize: '11px' }}>
                                              {transacao.tagCliente}
                                          </span>
                                      </div>

                                      <div className="col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                          <span style={{ fontSize: '11px' }}>
                                              {formatarCampoValor(transacao.tipo, transacao.valor, transacao.moeda)}
                                          </span>
                                      </div>

                                      <div className="titulo-tabelas-acao-btn">
                                          <div className="col-sm-1 col-md-1 col-lg-1 alinhamento-direita">
                                              <Dropdown id="menuOpcoes" pullRight >
                                                  <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas botao-acao-transacao">
                                                      <Glyphicon glyph="option-horizontal" />
                                                  </Dropdown.Toggle>
                                                  <Dropdown.Menu >
                                                      <MenuItem
                                          onSelect={() => props.setDetalhesTransacaoSelecionada(transacao)}
                                          onClick={props.setExibiModalDetalhes}>{LABEL_COMUNS.DETALHES_DA_TRANSACAO()}
                                                      </MenuItem>
                                                  </Dropdown.Menu>
                                              </Dropdown>
                                          </div>
                                      </div>
                                  </div>
                              </li>
                          );
                        }
                        )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
            </div>
        );
    };

    const rodapeModal = () => {
        return (
            <FooterBotoesModalComponent
        parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
        btn2Label={LABEL_COMUNS.OK}
        btn2OnClick={props.onClose} />
        );
    };

    return (
        <ModalComponent
      header={props.header}
      exibirModal={props.exibirModal}
      bsSize={props.bsSize}
      name="detalhe-extrato-transacoes"
      body={corpoModal()}
      footer={rodapeModal()} />
    );
};

export default DetalheExtratoTransacoes;
