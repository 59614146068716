import './pedido-cartao-virtual.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContaSelecionada from '../../contas/conta-selecionada';
import { Button } from 'react-bootstrap';
import { LABEL_COMUNS, MENSAGENS, OPERACAO, TIPO_MOVIMENTACAO } from '../../util/contantes-comuns/constantes-comuns';
import * as actions from './pedido-cartao-virtual-action';
import { formatarDataBR } from '../../util/data/dataUtil';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { formataMoeda } from '../../util/numeros/numeroUtil';
import { getOperacaoDoAnexo, montaDadosParaPedidoCartaoVirtual } from '../../../servicos/pedidos/pedido-service';

class PedidoCartaoVirtualResumo extends Component {
    constructor(props) {
        super(props);
        this.state = { ...props.location.state, exibirModalConfirmacao: false, exibirModalFinalizacao: false, mensagem: '' };
    }
    render() {
        const contaSelecionada = this.state.contaSelecionada;
        return (
            <div>
                <ContaSelecionada contaSelecionada={contaSelecionada} />
                <ModalComponent
          header={LABEL_COMUNS.PEDIDO_CARTAO_VIRTUAL}
          bsSize="small"
          exibirModal={this.state.exibirModalConfirmacao}
          name={LABEL_COMUNS.MODAL.CONFIRMACAO}
          body={this.state.mensagem}
          footer={this.rodapeModal(LABEL_COMUNS.MODAL.CONFIRMACAO)} />

                <ModalComponent bsSize="small"
          header={LABEL_COMUNS.PEDIDO_CARTAO_VIRTUAL}
          exibirModal={this.state.exibirModalFinalizacao && !!this.props.cartaoVirtualResumo.mensagem}
          name={LABEL_COMUNS.MODAL.FINALIZACAO}
          body={this.props.cartaoVirtualResumo.mensagem}
          footer={this.rodapeModal(LABEL_COMUNS.MODAL.FINALIZACAO)} />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.IMAGEM_CARTAO} </b></div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row">
                                            <div className="col-md-4 alinhamento-cartao">
                                                <div className="row">
                                                    <img id="img-cartao" src={this.state.comboRecuperado.urlImagem}
                            alt="Cartão" style={{ position: 'absolute' }} width="371" height="237" />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>

                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.RESUMO_DO_PEDIDO} </b></div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-esquerda">
                                                <div className="texto_resumo">Pedido de 1 Cartão Virtual</div>
                                                <br />
                                                <p className="texto_resumo">Data do Pedido: {formatarDataBR()}</p>
                                                <p className="texto_resumo">Tipo de Entrega - via e-mail({this.state.cartao.emailPortador})</p>
                                                <p className="texto_total alinhamento-esquerda">TOTAL DO PEDIDO: R$ {formataMoeda(getOperacaoDoAnexo(this.state.anexoContratual, OPERACAO.NOME.PEDIDO_DE_CARTAO_VIRTUAL).tarifa.tarifaPedidoCartaoVirtual.valor)}</p>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>

                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 posicicao_botoes_confirmacao">
                    <Button bsStyle="default" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} onClick={() => this.props.history.goBack({ state: this.state })}>{LABEL_COMUNS.VOLTAR}</Button>&nbsp;&nbsp;
                    <Button bsStyle="default" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}>{LABEL_COMUNS.CANCELAR}</Button>&nbsp;&nbsp;
                    <Button bsStyle="primary" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} onClick={() => {
            this.exibirModal(LABEL_COMUNS.MODAL.CONFIRMACAO);
            this.setState({ mensagem: MENSAGENS.PEDIDOS.CONFIRMACAO });
          }}>
                        {LABEL_COMUNS.CONFIRMAR}
                    </Button>
                </div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
            </div>
        );
    }

    exibirModal(nomeModal, valor = true) {
        if (nomeModal === LABEL_COMUNS.MODAL.CONFIRMACAO) {
            this.setState({ exibirModalConfirmacao: valor });
        } else {
            this.setState({ exibirModalConfirmacao: false, exibirModalFinalizacao: valor });
        }
    }

    rodapeModal(nomeModal) {
        if (nomeModal === LABEL_COMUNS.MODAL.CONFIRMACAO) {
            return (
                <FooterBotoesModalComponent
          parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
          btn1Label={LABEL_COMUNS.CANCELAR}
          btn1OnClick={() => this.exibirModal(nomeModal, false)}
          btn2Label={LABEL_COMUNS.CONFIRMAR}
          btn2OnClick={() => {
            let dados = {
              anexoContratual: this.state.anexoContratual,
              cartao: this.state.cartao,
              contaSelecionada: this.state.contaSelecionada,
              comboRecuperado: this.state.comboRecuperado
            };
            this.props.solicitarCartaoVirtual(montaDadosParaPedidoCartaoVirtual(dados));
            this.exibirModal(LABEL_COMUNS.MODAL.FINALIZACAO, true);
          }} />
            );
        } else {
            return (
                <FooterBotoesModalComponent
          parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
          btn2Label={LABEL_COMUNS.OK}
          btn2OnClick={() => this.exibirModal(LABEL_COMUNS.MODAL.FINALIZACAO, false)} />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    cartaoVirtualResumo: state.PedidoCartaoVirtualReducer
});
const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedidoCartaoVirtualResumo);
