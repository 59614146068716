import ExtratoViewModel from './extrato-view-model';
import ImagemViewModel from './imagem-view-model';

function PrestacaoContaViewModel({
    id = undefined,
    cartaoId = undefined,
    contaId = undefined,
    classificacao = undefined,
    bppMovimentoProcessadoraId = undefined,
    dataAlteracao = undefined,
    dataCriacao = undefined,
    descricaoAprovacao = undefined,
    descricaoSolicitacao = undefined,
    nomePortador = undefined,
    nomeEstabelecimento = undefined,
    pessoaTitularId = undefined,
    produtoId = undefined,
    produtoPublicadoId = undefined,
    projeto = undefined,
    quatroUltimosDigitos = undefined,
    status = undefined,
    statusExibicao = undefined,
    tipo = undefined,
    dadosExtrato = new ExtratoViewModel(),
    imagem = []
}={}){
    
    this.id = id;
    this.cartaoId = cartaoId;
    this.contaId = contaId;
    this.classificacao = classificacao;
    this.bppMovimentoProcessadoraId = bppMovimentoProcessadoraId;
    this.dataAlteracao = dataAlteracao;
    this.dataCriacao = dataCriacao;
    this.descricaoAprovacao = descricaoAprovacao;
    this.descricaoSolicitacao = descricaoSolicitacao;
    this.nomePortador = nomePortador;
    this.nomeEstabelecimento = nomeEstabelecimento;
    this.pessoaTitularId = pessoaTitularId;
    this.produtoId = produtoId;
    this.produtoPublicadoId = produtoPublicadoId;
    this.projeto = projeto;
    this.quatroUltimosDigitos = quatroUltimosDigitos;
    this.status = status;
    this.statusExibicao = statusExibicao;
    this.tipo = tipo;
    this.dadosExtrato = dadosExtrato;
    this.imagem = imagem;
}

PrestacaoContaViewModel.buildMock = function() {
    return new PrestacaoContaViewModel({
        id: '5a5c1bc6-e4eb-4de8-b000-f1fb1dd4221a',
        bppMovimentoProcessadoraId: '190723735893941723',
        cartaoId: '9819980899',
        contaId: 'a6c05375-905f,-436f-bd88-893c8d9f0bb5',
        dataAlteracao: '2019-07,-24T09:32:24.913',
        dataCriacao: '2019-07,-24T09:30:48.400',
        descricaoAprovacao: '',
        descricaoSolicitacao: 'TESTE',
        nomePortador: 'BPP3797',
        pessoaTitularId: 'BPP_4c8bd2b2-0bcb,-44e6-9309-d7017f476b04',
        produtoId: 'c0b06942-b6f6,-11e8-96f8-529269fb1459',
        produtoPublicadoId: 'be8b79bf-cc9e,-4b94-839a-ebfe13cd22ec',
        quatroUltimosDigitos: '0830',
        status: 'pendenteAprovacao',
        statusExibicao: 'pendenteAprovacao',
        tipo: 'CREDITO',
        dadosExtrato: ExtratoViewModel.buildMock(),
        imagem: [ImagemViewModel.buildMock()]
    });
};

export default PrestacaoContaViewModel;