import { montaUrlFetch, urlServicos, HTTP_METODOS, HEADER_API } from '../../servicos/url-servicos';
import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { jsonPagamentos } from '../../componentes/componentes-uteis/pagamento/pagamento-util';
// import { retornaJsonCriacaoDeConta } from '../../componentes/util/operacoes/operacoes-util';
import { abreOperacao, efetivaTransacao } from '../../servicos/operacoes/operacoes-base';

export const setExibirLoadingOperacao = (exibirLoadingOperacao) => {
    return {
        type: METODOS.CONTA.SET_EXIBIR_LOADING_CONTA,
        payload: exibirLoadingOperacao
    };
};

export const criarConta = (dadosConta) => {
    return dispatch => {
        dispatch(setExibirLoadingOperacao(true));
        /*  return abreOperacao(retornaJsonCriacaoDeConta(dadosConta))
             .then(async ({ data: { rel } }) => await efetivaTransacao(rel, { transacao: 'aprovacao' }))
             .then((result) => {
                 dispatch({
                     type: METODOS.CONTA.CRIAR_CONTA,
                     payload: result
                 })
             }).catch((err) => {
                 dispatch({
                     type: METODOS.CONTA.CRIAR_CONTA_ERRO,
                     payload: err.data
                 })
             }).finally(() => dispatch(setExibirLoadingOperacao(false))); */
        dispatch(setExibirLoadingOperacao(false));
    };
};

const solicitacaoToken = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};
