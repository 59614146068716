import axios from 'axios';
import { METODOS, TIPO_IMAGEM, TIPO_PEDIDO } from './pedido-estoque-constantes';
import {
    HEADER_API, URL_GET_IMAGEM_PERSONALIZADA,
    URL_ARQUIVOS, URL_GET_IMAGEM_PERSONALIZADA_STATUS,
    URL_POST_PEDIDO_ESTOQUE_CARTAO,
    URL_GET_PEDIDOS
} from '../../servicos/url-servicos';
import { environment } from '../../configuracoes/environment';
import ConsultaImagemPersonalizadaAction from '../pedido-personalizacao/consulta/consulta-imagem-personalizada.action';
import PedidoEstoqueReducer from './pedido-estoque-reducer';
import { reactLocalStorage } from 'reactjs-localstorage';
import { montaJsonArrayParaEnvioParaBackEnd } from '../../servicos/pedidos/pedido-service';

export default class PedidoEstoqueAction {
    quantidadeCartoesHandleChange(event) {
        return function(dispatch) {
            dispatch({
                type: METODOS.QUANTIDADE_CARTOES_HANDLE_CHANGE,
                payload: event.target.value.trim()
            });
        };
    }

    imagemCartaoHandLeChange(object) {
        return function(dispatch) {
            dispatch({
                type: METODOS.IMAGEM_CARTAO_HANDLE_CHANGE,
                payload: object
            });
        };
    }

    imagemCartaBercoHandLeChange(object) {
        return function(dispatch) {
            dispatch({
                type: METODOS.IMAGEM_CARTA_BERCO_HANDLE_CHANGE,
                payload: object
            });
        };
    }

    buscarImagensCartao(contaDigitalId) {
        reactLocalStorage.set('tamanhoPaginaConsultaImagensPersonalizadas', 10000);

        return function(dispatch) {
            let resposta = [];
            let filtro = { 'tipoPedido': TIPO_IMAGEM.CARTAO, 'statusAprovacaoExterna': 'APROVADO' };
            let queryString = montarQueryString(filtro);

            axios.get(URL_GET_PEDIDOS(queryString), HEADER_API())
                .then((response) => {
                    resposta = response;
                }).then(() => {
                    let listaRetorno = [];
                    let listaDeImagens = resposta.data.registros;
                    listaDeImagens.map((imagem) => {
                        var registro = imagem;
                        let idArquivo = imagem.urlImagem;
                        idArquivo = idArquivo.replace('https://', '');
                        idArquivo = idArquivo.replace('.s3.amazonaws.com/', '');
                        idArquivo = idArquivo.replace(environment.arquivosPedidos, '');
                        imagem.urlImagem = 'alterar';

                        var request = require('sync-request');
                        var res = request('GET', URL_ARQUIVOS() + '?bucket=' + environment.arquivosPedidos + '&key=' + idArquivo, HEADER_API());
                        if (res.statusCode === 200) {
                            var assinatura = JSON.parse(res.getBody());
                            imagem.urlImagem = assinatura.presignedUrl;
                        }
                        listaRetorno.push(registro);
                    });
                    resposta.data.registros = listaRetorno;
                    dispatch(
                        {
                            type: METODOS.LISTAR_IMAGENS_CARTAO_OK,
                            payload: resposta.data.registros
                        }
                    );
                }).catch((err) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_IMAGENS_CARTAO_ERRO,
                            payload: err.response
                        }
                    );
                });
        };
    }

    buscarImagensCartaBerco(contaDigitalId) {
        reactLocalStorage.set('tamanhoPaginaConsultaImagensPersonalizadas', 10000);

        return function(dispatch) {
            let resposta = [];
            let filtro = { 'tipoPedido': TIPO_IMAGEM.CARTA_BERCO, 'statusAprovacaoExterna': 'APROVADO' };
            let queryString = montarQueryString(filtro);

            axios.get(URL_GET_PEDIDOS(queryString), HEADER_API())
                .then((response) => {
                    resposta = response;
                }).then(() => {
                    let listaRetorno = [];
                    let listaDeImagens = resposta.data.registros;
                    listaDeImagens.map((imagem) => {
                        var registro = imagem;
                        let idArquivo = imagem.urlImagem;
                        idArquivo = idArquivo.replace('https://', '');
                        idArquivo = idArquivo.replace('.s3.amazonaws.com/', '');
                        idArquivo = idArquivo.replace(environment.arquivosPedidos, '');
                        imagem.urlImagem = 'alterar';

                        var request = require('sync-request');
                        var res = request('GET', URL_ARQUIVOS() + '?bucket=' + environment.arquivosPedidos + '&key=' + idArquivo, HEADER_API());
                        if (res.statusCode === 200) {
                            var assinatura = JSON.parse(res.getBody());
                            imagem.urlImagem = assinatura.presignedUrl;
                        }
                        listaRetorno.push(registro);
                    });
                    resposta.data.registros = listaRetorno;
                    dispatch(
                        {
                            type: METODOS.LISTAR_IMAGENS_CARTA_BERCO_OK,
                            payload: resposta.data.registros
                        }
                    );
                }).catch((err) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_IMAGENS_CARTA_BERCO_ERRO,
                            payload: err.response
                        }
                    );
                });
        };
    }

    exibeCartaoSelecionadoHadleChange(value) {
        return function(dispatch) {
            dispatch({
                type: METODOS.EXIBE_CARTAO_SELECIONADO_HANDLE_CHANGE,
                payload: value
            });
        };
    }
    exibeCartaBercoSelecionadaHandleChange(value) {
        return function(dispatch) {
            dispatch({
                type: METODOS.EXIBE_CARTA_BERCO_SELECIONADA_HANDLE_CHANGE,
                payload: value
            });
        };
    }

    exibeModalFinalizarHandleChange(value) {
        return function(dispatch) {
            dispatch({
                type: METODOS.EXIBE_MODAL_FINALIZAR_HANDLE_CHANGE,
                payload: value
            });
        };
    }

    exibeModalConfirmacaoCancelarHandleChange(value) {
        return function(dispatch) {
            dispatch({
                type: METODOS.EXIBE_MODAL_CONFIRMACAO_CANCELAR_HANDLE_CHANGE,
                payload: value
            });
        };
    }

    salvarPedido(dadosForm, contaDigitalId) {
        dadosForm = montaJsonArrayParaEnvioParaBackEnd(contaDigitalId, dadosForm);
        return function(dispatch) {
            axios.post(URL_POST_PEDIDO_ESTOQUE_CARTAO(contaDigitalId), dadosForm, HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: METODOS.SALVAR_PEDIDO_STATUS_OK,
                            payload: response.data
                        }
                    );
                }).catch((err) => {
                    dispatch(
                        {
                            type: METODOS.SALVAR_PEDIDO_STATUS_ERRO,
                            payload: err.response.data
                        }
                    );
                });
        };
    }

    exibeLoadingHandleChange(value) {
        return function(dispatch) {
            dispatch({
                type: METODOS.EXIBE_LOADING_HANDLE_CHANGE,
                payload: value
            });
        };
    }
}

function montarQueryString(filtro) {
    let url = '';

    if (filtro.tipoPedido) {
        url = url + '&tipoPedido=' + filtro.tipoPedido;
    }

    if (filtro.statusAprovacaoExterna) {
        url = url + '&statusAprovacaoExterna=' + filtro.statusAprovacaoExterna;
    }

    return url;
}
