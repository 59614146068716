import METODOS from './cabecalho-constantes';

const initialState = {
    texto: ''
};

const cabecalhoReducer = (state = initialState, action) => {
    switch (action.type) {
        case METODOS.ATUALIZA_TEXTO:
            return {
                ...state,
                texto: action.payload
            };

        default:
            return state;
    }
};

export default cabecalhoReducer;
