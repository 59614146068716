import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    cidades: [], estados: [], paises: [], localidadeRecuperada: undefined, exibirLoadingLocalidade: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case METODOS.LOCALIDADE.GET_LOCALIDADE_POR_CEP:
            return { ...state, localidadeRecuperada: action.payload };
        case METODOS.LOCALIDADE.GET_LOCALIDADE_POR_CEP_ERRO:
            return { ...state, localidadeRecuperada: {} };
        case METODOS.LOCALIDADE.GET_PAISES:
            return { ...state, paises: action.payload.paises };
        case METODOS.LOCALIDADE.GET_PAISES_ERRO:
            return { ...state };
        case METODOS.LOCALIDADE.GET_ESTADOS:
            return { ...state, estados: action.payload.estados };
        case METODOS.LOCALIDADE.GET_ESTADOS_ERRO:
            return { ...state };
        case METODOS.LOCALIDADE.GET_CIDADES:
            return { ...state, cidades: action.payload.cidades };
        case METODOS.LOCALIDADE.GET_CIDADES_ERRO:
            return { ...state };
        case METODOS.LOCALIDADE.SET_EXIBIR_LOADING_LOCALIDADE:
            return { ...state, exibirLoadingLocalidade: action.payload };
        default:
            return state;
    }
};
