export const METODOS = {
    GET_LISTA_CONTA_DEPARTAMENTO: 'getListaContaDepartamento',
    GET_LISTA_CONTA_DEPARTAMENTO_ERRO: 'getListaContaDepartamentoErro',
    SET_EXIBIR_MODAL_EDITAR_CONTA: 'setExibirModalEditarContaDepartamento',
    SET_EXIBIR_MODAL_SUCESSO_ERRO_EDITAR_CONTA: 'setExibirModalSucessoErroEditarListaContaDepartamento',
    SALVAR_EDITAR_CONTA: 'salvarEditarContaListaConta',
    SET_CONTA_SELECIONADA: 'setContaSelecionadaListaContaDepartamento',
    SET_EXIBIR_MODAL_CRIAR_CONTA_DEPARTAMENTO: 'setExibirModalCriarContaDepartamento',
    SET_MSG_VALIDAR_IMAGEM_EDITAR_CONTA: 'setMsgValidarImagemEditarConta',
    SET_LOADING: 'setLoading'
};
