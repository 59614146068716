import { ACAO } from '../../../util/contantes-comuns/constantes-comuns';
import { generateUUID } from '../../../util/string/string-util';
import moment from 'moment';

export function montaListaParaTela(listaDeAgendamentos, listaDeOperacoes, listaDeProdutosBppPagIds) {
    /* return listaDeAgendamentos.resultado
        .filter(agendamento => listaDeOperacoes.some(({ id, ...operacao }) => {
            agendamento.operacaoNome = operacao.lancamentoFinanceiroClassificacao.operacao[0].lancamentoFinanceiroExibicao.pt_BR
            return id === agendamento.operacao
        }))
        .filter(agendamento => listaDeProdutosBppPagIds.some(({ produtoId }) => produtoId !== agendamento.produtoId)); */

    /* Paliativo até que seja ajustado a estrutura de operação no backend,
    quando a estrutura for ajustada apagar o código abaixo e descomentar o código acima */
    const listaDeOperacoesPag = [{ operacao: 'TEDPag' }, { operacao: 'cargaCartaoDiferentesTitularidadesPag' }, { operacao: 'transferenciaEntreContasMovimentoDiferentesTitularidadesPag' }];
    return listaDeAgendamentos.resultado
        .filter(agendamento => listaDeOperacoes.some(({ id, ...operacao }) => {
            agendamento.operacaoNome = operacao.lancamentoFinanceiroClassificacao.operacao[0].lancamentoFinanceiroExibicao.pt_BR;
            return id === agendamento.operacao;
        }))
        .filter(agendamento => !listaDeOperacoesPag.some(({ operacao }) => operacao === agendamento.operacao));
}

export function montaAgendamentosListasParaTela(listaDeAgendamentos, listaDeOperacoes, listaDeProdutosBppPagIds) {
    /* return listaDeAgendamentos.resultado
        .filter(agendamento => listaDeOperacoes.some(({ id, ...operacao }) => {
            agendamento.operacaoNome = operacao.lancamentoFinanceiroClassificacao.operacao[0].lancamentoFinanceiroExibicao.pt_BR
            return id === agendamento.operacao
        }))
        .filter(agendamento => listaDeProdutosBppPagIds.some(({ produtoId }) => produtoId !== agendamento.produtoId)); */

    /* Paliativo até que seja ajustado a estrutura de operação no backend,
    quando a estrutura for ajustada apagar o código abaixo e descomentar o código acima */
    const listaDeOperacoesPag = [{ operacao: 'TEDPag' }, { operacao: 'cargaCartaoDiferentesTitularidadesPag' }, { operacao: 'transferenciaEntreContasMovimentoDiferentesTitularidadesPag' }];
    const retornoDestino = listaDeAgendamentos[0].resultado
        .filter(agendamento => listaDeOperacoes.some(({ id, ...operacao }) => {
            agendamento.operacaoNome = operacao.lancamentoFinanceiroClassificacao.operacao[0].lancamentoFinanceiroExibicao.pt_BR;
            return id === agendamento.operacao;
        }))
        .filter(agendamento => !listaDeOperacoesPag.some(({ operacao }) => operacao === agendamento.operacao));
    const retornoOrigem = listaDeAgendamentos[1].resultado
        .filter(agendamento => listaDeOperacoes.some(({ id, ...operacao }) => {
            agendamento.operacaoNome = operacao.lancamentoFinanceiroClassificacao.operacao[0].lancamentoFinanceiroExibicao.pt_BR;
            return id === agendamento.operacao;
        }))
        .filter(agendamento => !listaDeOperacoesPag.some(({ operacao }) => operacao === agendamento.operacao));

    const retorno = retornoDestino.concat(retornoOrigem).sort(
        (a, b) => moment(b.dataCriacao) - moment(a.dataCriacao));
    return retorno;
}

export function concatenarLista(lista1, lista2) {
    var response = {
        tamanhoPagina: '',
        totalElementos: '',
        ultimaChave: '',
        resultado: [],
        quantidadeTotalRegistros: ''
    };
    if (lista1.resultado &&
        lista2.resultado &&
        lista1.resultado.length > 0 &&
        lista2.resultado.length > 0) {
        response.tamanhoPagina = lista1.tamanhoPagina > lista2.tamanhoPagina ? lista1.tamanhoPagina : lista2.tamanhoPagina;
        response.totalElementos = lista1.totalElementos + lista2.totalElementos;
        response.ultimaChave = lista2.ultimaChave;
        response.resultado = ordenarLista(lista1.resultado.concat(lista2.resultado));
        response.quantidadeTotalRegistros = response.resultado.length;
    } else if (lista1.resultado && lista1.resultado.length > 0 && !lista2.resultado || lista2.resultado.length === 0) {
        return lista1;
    } else if (!lista1.resultado || lista1.resultado.length === 0 && lista2.resultado && lista2.resultado.length > 0) {
        return lista2;
    }
    return response;
}

function ordenarLista(lista) {
    var listaN = [];
    try {
        listaN = lista.sort(predicateDataAsc);
    } catch (err) {
        listaN = lista;
    }
    return listaN;
}

function predicateDataAsc(obj1, obj2) {
    return obj1.dataCriacao < obj2.dataCriacao ? 1 : -1;
}

export const montaCorpoRequisicaoAprovarOuReprovarLote = (acao, token, tokenId) => {
    let corpoDaRequisicao = acao;
    if (corpoDaRequisicao.acao === ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value) {
        corpoDaRequisicao.token = token;
        corpoDaRequisicao.tokenId = tokenId;
    }
    return JSON.stringify(corpoDaRequisicao);
};

export const montaCorpoRequisicaoSolicitarTokenTransferenciaEmLote = (pessoaId) => {
    let corpoDaRequisicao = {};
    corpoDaRequisicao.canal = 'SMS';
    corpoDaRequisicao.transacaoId = generateUUID();
    corpoDaRequisicao.pessoaId = pessoaId;
    return JSON.stringify(corpoDaRequisicao);
};
