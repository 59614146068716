import { Button } from 'react-bootstrap';
import React, { Component } from 'react';

import { salvarTransferenciaEmLote as salvarCargaLoteCartao } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import AgendamentoEAprovacoesDashboardBppCorp from '../agendamento-e-aprovacoes-bpp-corp/agendamento-e-aprovacoes-dashboard-bpp-corp';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import ModeloInstrucoesCargaLoteCartao from '../../bpp-pag/transferencia-lote/modal-instrucoes-transferencia-lote';
import MostrarSaldoESaldoBloqueado from '../../../../contas/saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { getExtensoesAceitasPorModeloArquivo, isExtensaoValida } from '../../../../util/valida-extensao';
import { LABEL_COMUNS, MENSAGENS, MODELO_ARQUIVO, PRODUTOS, TRANSFERENCIA_EM_LOTE_NOVO } from '../../../../util/contantes-comuns/constantes-comuns';
import { convertCharsetToUtf8 } from '../../../../util/handleCharset';
import { podeConsultar, podeCriar, podeAprovar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';
class ImportacaoCargaLoteCartao extends Component {
    constructor() {
        super();

        this.state = {
            arquivoSelecionado: '',
            arquivoEnviado: {},
            exibirLoading: false,
            exibirModalConfirmacao: false,
            exibirModalFinalizacao: false,
            exibirModalExemplo: false,
            exibirModalExtensaoInvalida: false,
            mensagemFinalizacao: ''
        };

        this.initialState = this.state;

        this.contaSelecionada = JSON.parse(localStorage.getItem('contaDepartamentoSelecionada')) || JSON.parse(localStorage.getItem('contaSelecionada'));

        this.onAlterarArquivo = this.onAlterarArquivo.bind(this);
        this.onImportar = this.onImportar.bind(this);
        this.onLimpar = this.onLimpar.bind(this);
        this.setArquivoEnviado = this.setArquivoEnviado.bind(this);
    }

    onAlterarArquivo(e) {
        const file = e.target.files[0];

        if (file) {
            if (file.name) {
                const extensao = file.name.substring(file.name.lastIndexOf('.'), file.name.length);

                if (!isExtensaoValida(extensao, MODELO_ARQUIVO.MODELO_BPP.value)) {
                    this.setState({
                        exibirModalExtensaoInvalida: true,
                        extensaoArquivo: extensao,
                        arquivoSelecionado: undefined
                    });
                    return;
                }
            }

            convertCharsetToUtf8(file, 'text/csv', (file) => {
                this.setState({ arquivoSelecionado: file });
            });
        }
    }

    async onImportar() {
        const { arquivoSelecionado, arquivoEnviado } = this.state;
        const { id, pessoaTitularId } = this.contaSelecionada;

        this.setState({ exibirLoading: true });

        const { error } = await salvarCargaLoteCartao({
            arquivoSelecionado,
            arquivoEnviado,
            contaId: id,
            pessoaId: pessoaTitularId,
            produto: PRODUTOS.corp,
            modeloArquivoSelecionado: 'padraoBPP',
            operacaoTransferenciaEmLoteSelecionada: 'cargaCartao'
        });

        if (!error) {
            this.setState({
                exibirLoading: false,
                exibirModalConfirmacao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: MENSAGENS.OPERACOES_EM_LOTE.CARGA_CARTAO.FINALIZACAO.SUCESSO
            });
        } else {
            this.setState({
                exibirLoading: false,
                exibirModalConfirmacao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: 'Não foi possível efetuar a solicitação de transferência em arquivo.'
            });
        }
    }

    onLimpar() {
        this.setState({ arquivoSelecionado: '' });
        if (this.arquivoRef) this.arquivoRef.value = '';
    }

    setArquivoEnviado(cargaLoteCartao) {
        this.setState({ arquivoEnviado: cargaLoteCartao });
    }

    render() {
        const {
            arquivoSelecionado,
            exibirModalConfirmacao,
            exibirModalFinalizacao,
            exibirModalExemplo,
            mensagemFinalizacao,
            exibirLoading
        } = this.state;

        return (
            <div>
                {exibirLoading && (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
        )}
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                        <MostrarSaldoESaldoBloqueado
              className="panel-heading fundo-azul-claro"
              contaSelecionada={this.contaSelecionada}
              exibirSaldo
              mostrarSaldoBloqueado
              podeConsultar={this.props.outorgaPerfils.podeConsultar}
            />
                        {this.props.outorgaPerfils.podeCriar &&
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">Novo Arquivo</div>
                            <div className="panel-body">
                                <div>
                                    <input
                      type="file"
                      id="cargaLoteCartaoArquivo"
                      accept={getExtensoesAceitasPorModeloArquivo(MODELO_ARQUIVO.MODELO_BPP.value)}
                      multiple={false}
                      ref={ref => this.arquivoRef = ref}
                      onChange={this.onAlterarArquivo}
                      style={{ display: 'inline-block' }}
                    />
                                    <span
                      className="pull-right"
                      style={{ textDecoration: 'underline', cursor: 'pointer' }}
                      onClick={() => this.setState({ exibirModalExemplo: true })}
                    >
                      Ver arquivo de exemplo
                                    </span>
                                </div>
                                <div className="row alinhamento-direita" style={{ marginRight: 0, marginTop: 15 }}>
                                    <Button onClick={() => this.onLimpar()}>
                      Limpar
                                    </Button>{' '}
                                    <Button
                      bsStyle="primary"
                      disabled={!arquivoSelecionado}
                      onClick={() => this.setState({ exibirModalConfirmacao: true })}
                    >
                      Importar
                                    </Button>
                                </div>
                            </div>
                        </div>}
                        <AgendamentoEAprovacoesDashboardBppCorp
              {...this.props}
              titulo="Histórico de Arquivos"
              onImportar={this.onImportar}
              onLimpar={this.onLimpar}
              onAlterarArquivo={this.onAlterarArquivo}
              setArquivoEnviado={this.setArquivoEnviado}
              arquivoSelecionado={arquivoSelecionado}
            />
                        <Button className="pull-right" bsStyle="primary" onClick={() => this.props.history.goBack()}>Voltar</Button>
                    </div>
                </div>
                <ModalComponent
          exibirModal={exibirModalConfirmacao}
          header="Envio de arquivo"
          body="Confirma o envio do arquivo para processamento?"
          footer={
              <FooterBotoesModalComponent
              btn1Label="Não"
              btn1OnClick={() => this.setState({ exibirModalConfirmacao: false })}
              btn2Label="Sim"
              btn2OnClick={() => this.onImportar()}
            />
          }
        />
                <ModalComponent
          exibirModal={exibirModalFinalizacao}
          header="Envio de arquivo"
          body={mensagemFinalizacao}
          footer={
              <FooterBotoesModalComponent
              btn1Label="Ok"
              isPrimary
              btn1OnClick={() => {
                this.setState(this.initialState);
                this.onLimpar();
                this.props.history.push(ROTAS_CAMINHOS.DASHBOARD_BPP_CORP);
              }}
            />
          }
        />
                <ModeloInstrucoesCargaLoteCartao
          exibirModal={exibirModalExemplo}
          operacao="cargaCartaoLoteBPPCorp"
          fecharModal={() => this.setState({ exibirModalExemplo: false })}
          tipoArquivo="CSV"
        />
                <ModalComponent
          exibirModal={this.state.exibirModalExtensaoInvalida}
          name="modalExtensaoInvalida"
          header={LABEL_COMUNS.EXTENSAO_INVALIDA}
          body={MENSAGENS.TRANSFERENCIA_EM_LOTE.EXTENSAO_INVALIDA(this.state.extensaoArquivo)}
          footer={
              <FooterBotoesModalComponent
              btn2Label={LABEL_COMUNS.OK}
              btn2OnClick={() => this.setState({ exibirModalExtensaoInvalida: false })}
            />
          } />
            </div>
        );
    }
}
const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

export default connect(mapStateToProps)(ImportacaoCargaLoteCartao);
