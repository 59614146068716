import axios from 'axios';
import { urlServicos, montaUrlFetch, HTTP_METODOS, HEADER_API, URL_GET_PRESTACAO_CONTA_PROJETO, URL_GET_PRESTACAO_CONTA_CLASSIFICACAO } from '../../../../../servicos/url-servicos';
import { METODOS_COMUNS, METODOS } from '../../../../util/contantes-comuns/constantes-comuns';

export const setContaSelecionada = (contaSelecionada) => ({
    type: METODOS.SET_CONTA_SELECIONADA,
    payload: contaSelecionada
});

export const setFiltroPrestacaoDataInicial = (e) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DATA_INICIAL,
    payload: e.target.value
});

export const setFiltroPrestacaoDataFinal = (e) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DATA_FINAL,
    payload: e.target.value
});

export const setClassificacaoSelecionada = (classificacaoSelecionada) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_CLASSIFICACAO_SELECIONADA,
    payload: classificacaoSelecionada
});

export const setProjetoSelecionado = (projetoSelecionado) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_PROJETO_SELECIONADO,
    payload: projetoSelecionado
});

export const setPrestacaoDeContasStatus = (statusSelecionado) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_PRESTACAO_DE_CONTAS_STATUS,
    payload: statusSelecionado
});

export const setFiltro = (filtro) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO,
    payload: filtro
});

export const limparFiltroPrestacaoDeContas = () => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.LIMPAR_FILTRO_PRESTACAO_DE_CONTAS,
    payload: ''
});

export function setFiltroPrestacaoDeConstasPorValor(obj) {
    return {
        type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DE_CONTAS_POR_VALOR,
        payload: obj
    };
}

export const setExibirLoadingDashboardPrestacaoDeContas = (exibirLoadingDashboardPrestacaoDeContas) => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_EXIBIR_LOADING_DASHBOARD_PRESTACAO_DE_CONTAS,
    payload: exibirLoadingDashboardPrestacaoDeContas
});

export const limparPrestacoesDeContas = () => ({
    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.LIMPAR_LISTA_DE_PRESTACOES_DE_CONTAS,
    payload: []
});

export function getPrestacaoDeContasClassificacoes(pessoaTitularId) {
    return (dispatch) => {
        axios.get(URL_GET_PRESTACAO_CONTA_CLASSIFICACAO(pessoaTitularId), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES,
                    payload: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES_ERROS,
                    payload: error
                });
            });
    };
}

export function getPrestacaoDeContasProjetos(pessoaTitularId) {
    return (dispatch) => {
        axios.get(URL_GET_PRESTACAO_CONTA_PROJETO(pessoaTitularId), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS,
                    payload: response.data
                });
            })
            .catch((error) => {
                dispatch({
                    type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS_ERROS,
                    payload: error
                });
            });
    };
}

export const getPrestacoesDeConta = (contaSelecionada, filtro) => {
    return function(dispatch) {
        dispatch(setExibirLoadingDashboardPrestacaoDeContas(true));
        fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA(contaSelecionada.pessoaTitularId), filtro), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        }).then((response) => {
            response.json().then(dadosRetornados => {
                dispatch({
                    type: response.ok ? METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACOES_DE_CONTA : METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACOES_DE_CONTA_ERRO,
                    payload: dadosRetornados
                });
            });
        }).catch((error) => {
            dispatch({
                type: METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACOES_DE_CONTA_ERRO,
                payload: error
            });
        }).finally(() => dispatch(setExibirLoadingDashboardPrestacaoDeContas(false)));
    };
};
