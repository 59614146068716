import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { ESTILO } from '../../util/estilo_para_componentes/estiloUtil';

const PanelAcoes = props => {
    const style1 = props.style1 || { width: '120px', height: '30px', paddingTop: '4px', paddingLeft: '10px', ...(props.contextoBppPag && { background: '#92c27c', color: '#fff', borderColor: '#4cae4c' }) };
    const style2 = props.style2 || { width: '120px', height: '30px', paddingTop: '4px', paddingLeft: '10px', ...(props.contextoBppPag && { background: '#92c27c', color: '#fff', borderColor: '#4cae4c' }) };
    const style3 = props.style3 || { width: '120px', height: '30px', paddingTop: '4px', paddingLeft: '10px', ...(props.contextoBppPag && { background: '#92c27c', color: '#fff', borderColor: '#4cae4c' }) };
    const style4 = props.style4 || { width: '120px', height: '30px', paddingTop: '4px', paddingLeft: '10px', ...(props.contextoBppPag && { background: '#92c27c', color: '#fff', borderColor: '#4cae4c' }) };
    const corDeFundoParaTitulo = props.contextoBppPag ? ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG : ESTILO.COR_DE_FUNDO.TITULO.PADRAO;

    const Button1 = () => (
        <Button bsStyle="primary"
      style={style1}
      onClick={props.btn1OnClick}>
            {props.labelBotao1}
        </Button>
    );

    const Button2 = () => (
        <Button bsStyle="primary" style={style2}
      onClick={props.btn2OnClick}>
            {props.labelBotao2}
        </Button>
    );

    const Button3 = () => (
        <Button bsStyle="primary" style={style3}
      onClick={props.btn3OnClick}>
            {props.labelBotao3}
        </Button>
    );

    const Button4 = () => (
        <Button bsStyle="primary" style={style4}
      onClick={props.btn4OnClick}>
            {props.labelBotao4}
        </Button>
    );

    return (
        <div>
            <div className="row">
                <div className="panel panel-default">
                    <div className={corDeFundoParaTitulo}>{props.titulo}</div>
                    <div className="panel-body">
                        <div style={{ marginTop: '-5px', height: '35px', textAlign: 'center' }}>
                            {props.btn1Link
                ? <Link to={props.btn1Link} >
                    <Button1 />
                </Link>
                : props.btn1OnClick && <Button1 />
              }
              &nbsp;&nbsp;&nbsp;
                            {props.btn2Link
                ? <Link to={props.btn2Link}>
                    <Button2 />
                </Link>
                : props.btn2OnClick && <Button2 />
              }
              &nbsp;&nbsp;&nbsp;
                            {props.btn3Link
                ? <Link to={props.btn3Link}>
                    <Button3 />
                </Link>
                : props.btn3OnClick && <Button3 />
              }
              &nbsp;&nbsp;&nbsp;
                            {props.btn4Link
                ? <Link to={props.btn4Link}>
                    <Button4 />
                </Link>
                : props.btn4OnClick && <Button4 />
              }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PanelAcoes;
