/* let valor = undefined; */
export const LABELS = {
    TRANSFERENCIA: [
        { value: 341, label: 'TRANSFERÊNCIA ITAÚ - ITAÚ' },
        { value: 237, label: 'TRANSFERÊNCIA BRADESCO - BRADESCO' },
        { value: 954, label: 'TED' }/* ,
        { value: "CARTAO_CREDITO", label: "CARTÃO DE CRÉDITO" } */
    ],
    DESEJA_CARREGAR: 'Como deseja carregar?',
    DESEJA_CARREGAR_DIALOG: 'Deseja depositar?',
    VALOR_CARGA: 'Valor carga R$',
    BANCO: 'Banco Origem',
    AGENCIA: 'Agência Origem',
    CONTA: 'Conta Origem',
    DIGITO: 'Díg. Conta Origem',
    TIPO_CONTA: 'Tipo da Conta',
    DESCRICAO: 'Descrição',
    BOTAO_SOLICITAR: 'Solicitar',
    TOKEN: 'Token',
    REENVIAR: 'Reenviar',
    MODAL_TITULO: 'Pedido de Carga',
    CONFIRMA_ENVIO_DO_PEDIDO: 'Confirma o envio do Pedido de Carga?',
    MENSAGEM_CARGA_EFETUADA_COM_SUCESSO: 'Solicitação de carga enviada!',
    MENSAGEM_CARGA_NAO_EFETUADA: 'Carga Não Realizada',
    MENSAGEM_BUSCAR_BANCOS_ERRO: 'Nenhum Registro Encontrado',
    MENSAGEM_CANCELAR: 'Todas as informações de pedido de carga serão perdidas. Deseja continuar?',
    COMBO_TIPO_DE_CONTA: [
        { label: 'Conta Corrente', value: 'CONTA_CORRENTE' },
        { label: 'Conta Poupança', value: 'CONTA_POUPANÇA' }
    ],
    MENSAGEM_PEDIDO_TRANSFERENCIA_REALIZADO: {
        DOCUMENTO: 'Documento: ',
        AGENCIA: 'Agência: ',
        CONTA: ' Conta Corrente: '
    },
    VOLTAR: 'Voltar'
};

export const METODOS = {
    EXIBIR_MODAL_CONFIRMACAO_CHANGE: 'exibirModalConfirmacaoChange',
    EXIBIR_MODAL_CANCELAR_CHANGE: 'exibirModalCancelarChange',
    EXIBIR_MODAL_SUCESSO_CHANGE: 'exibirModalSucessoChange',
    EXIBIR_LOADING_CHANGE: 'exibirLoadingChange',
    VALOR_CARGA_CHAGE: 'valorCargaChange',
    BANCO_CHANGE: 'bancoChange',
    BANCO_DESTINO_CHANGE: 'bancoDestinoChange',
    AGENCIA_CHANGE: 'agenciaChange',
    CONTA_CHANGE: 'contaChange',
    DIGITO_CHANGE: 'digitoChange',
    TIPO_CONTA_CHANGE: 'tipoContaChange',
    DESCRICAO_CHANGE: 'descricaoChange',
    SET_HOJE: 'setHoje',
    BUSCAR_BANCOS: 'buscarBancos',
    BUSCAR_BANCOS_ERRO: 'buscarBancosErro',
    EFETUAR_CARGA: 'efetuarCarga',
    EFETUAR_CARGA_ERRO: 'efetuarCargaErro',
    DESABILITA_COMOBO_BANCO_CHANGE: 'desabilitaComboBancoChange',
    MSG_ERRO_BANCO: 'msgErroBanco',
    MSG_ERRO_AGENCIA: 'msgErroAgencia',
    MSG_ERRO_CONTA: 'msgErroConta',
    MSG_ERRO_DIGITO: 'msgErroDigito',
    MSG_ERRO_DESCRICAO: 'msgErroDescricao',
    MSG_ERRO_VALOR_DA_CARGA: 'msgErroValorDaCarga',
    MSG_ERRO_DATA_AGENDAMENTO: 'msgErroDataAgendamento',
    MSG_ERRO_DATA_AGENDAMENTO_OBRIGATORIA: 'msgErroDataAgendamentoObrigatoria',
    SET_DATA_AGENDADA: 'setDataAgendada'
};
export const NOME_DOS_CAMPOS = {
    VALOR_CARGA: { campo: 'valorCarga' },
    BANCO: { campo: 'codigoBanco' },
    AGENCIA: { campo: 'agencia' },
    CONTA: { campo: 'conta' },
    DIGITO: { campo: 'digito' },
    TIPO_CONTA: { campo: 'tipoConta' },
    DESCRICAO: { campo: 'descricao' }
};

export const OPERACAO = 'pedidoCargaContaMovimentoTED';
