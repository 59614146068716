import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    CAMPOS, COMBO,
    CONTA_SELECIONADA,
    LABEL_COMUNS,
    MENSAGENS
} from '../../../../util/contantes-comuns/constantes-comuns';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import Grid from '../../../../componentes-uteis/grid/grid';
import If from '../../../../componentes-uteis/if/if';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import Loading from '../../../../loading-fenix/loading';

import { buscarLotesDoArquivo, } from '../../../../../action/transferencia-em-lote/transferencia-em-lote-action';
import { getHistoricoLote } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-base';
import './lote.css';
import { formatarDataBR } from '../../../../util/data/dataUtil';
import { formataMoedaComSimboloESinalNegacao } from '../../../../util/numeros/numeroUtil';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
class LotesDoArquivo extends Component {

    constructor(props) {
        super(props);
        this.state = this.getInitialState(props);
    }

    getInitialState = (props, abrirCollapse = false) => (
        {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            arquivo: props.location && props.location.state && props.location.state.arquivo,
            abrirCollapse: abrirCollapse,
            exbirMaisRegistros: false,
            dataInicial: '',
            dataInicialErro: '',
            dataFinal: '',
            dataFinalErro: '',
            listaOperacoesTransferenciaEmLote: COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE() || [],
            operacaoErro: '',
            statusTransferenciaEmLoteSelecionado: {},
            listaStatusTransferenciaEmLote: COMBO.STATUS.TRANSFERENCIA_EM_LOTE || [],
            statusErro: '',
            ultimaChave: '',
        }
    );

    componentDidMount = () => {
        const {
            contaSelecionada,
            arquivo,
        } = this.state;

        const contaId = contaSelecionada.id;
        const pessoaId = contaSelecionada.pessoaTitularId;
        const arquivoId = arquivo.id;

        const params = {
            contaId,
            pessoaId,
            arquivoId
        };

        this.props.buscarLotesDoArquivo(params);
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.erroArquivosDoLote) {
            this.setState({ erroArquivo: true });
        }
        if (nextProps.ultimaChave) {
            this.setState({ ultimaChave: nextProps.ultimaChave });
            this.setState({ exbirMaisRegistros: true });
        }
        if (!nextProps.ultimaChave) {
            this.setState({ exbirMaisRegistros: false });
        }
    }

    setPropsCampos = (event, data) => (
        this.setState({
            [data]: event.target.value,
            [data + 'Erro']: '',
        })
    );

    setDataInicial = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_INICIAL)
    );

    setDataFinal = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_FINAL)
    );

    setOperacaoLotesDoArquivo = (value) => {

        this.setPropsCampos({ target: { value: value } }, CAMPOS.OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA);
    };

    setStatusLotesDoArquivo = (value) => {
        this.setPropsCampos({ target: { value: value } }, CAMPOS.STATUS_TRANSFERENCIA_EM_LOTE_SELECIONADO);
    };

    onLimparFiltroClick = () => (
        this.setState(this.getInitialState(this.props, this.state.abrirCollapse))
    );

    onIdLoteClick = (id, idLote, operacaoExibicao) => (
        this.props.history.push({ pathname: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_ITENS_DO_LOTE, state: { id: id, idLote: idLote, operacaoExibicao } })
    );

    buscarComFiltro(objeto) {
        this.setState({ ultimaChave: '' }, () => {
            this.props.buscarLotesDoArquivo(
                {
                    ...objeto.params,
                    dataInicial: objeto.dataInicial,
                    dataFinal: objeto.dataFinal,
                    statusTransferenciaEmLoteSelecionado: objeto.statusTransferenciaEmLoteSelecionado,

                },
                []
            );
        });
    }

    onMouseEnterStatusTooltip(status) {
        let tooltipMessage = '';

        switch (status) {
            case 'emValidacao':
                tooltipMessage = 'O arquivo está sendo validado. A seguir ele ficará disponível para correção ou aprovação.';
                break;
            case 'pendenteCorrecao':
                tooltipMessage = 'O arquivo possui linhas com erro e precisa ser corrigido e importado novamente.';
                break;
            case 'pendenteAprovacao':
                tooltipMessage = 'O arquivo está aguardando aprovação para ser processado.';
                break;
            case 'reprovado':
                tooltipMessage = 'O arquivo foi reprovado pelo gestor da conta.';
                break;
            case 'cancelado':
                tooltipMessage = 'Lote cancelado pelo gestor.';
                break;
            case 'aprovado':
                tooltipMessage = 'O arquivo foi aprovado e os lotes foram gerados. Acompanhe o status do lote clicando no ID.';
                break;
            case 'agendado':
                tooltipMessage = 'Lote agendado.';
                break;
            case 'emProcessamento':
                tooltipMessage = 'Lote em processamento.';
                break;
            case 'processado':
                tooltipMessage = 'Lote processado.';
                break;
            case 'canceladoFaltaSaldo':
                tooltipMessage = 'Lote cancelado por falta de saldo.';
                break;
            case 'canceladoErro':
                tooltipMessage = 'Lote cancelado por erro.';
            default:
                break;
        }

        return (
            <Tooltip id="tooltip">{tooltipMessage}</Tooltip>
        );
    }


    render() {
        const {
            contaSelecionada,
            arquivo,
            abrirCollapse,
            dataInicial,
            dataInicialErro,
            dataFinal,
            dataFinalErro,
            listaOperacoesTransferenciaEmLote: listaOperacoesLotesDoArquivo,
            operacaoErro,
            statusTransferenciaEmLoteSelecionado,
            listaStatusTransferenciaEmLote,
            statusErro,
        } = this.state;

        const {
            history,
            // exibirLoading: this.props.exibirLoadingLotesDoArquivo,
            lotesDoArquivo,
        } = this.props;
        const {
            LOTES_DO_ARQUIVO,
            LOTE,
            OPERACAO,
            QUANTIDADE_DE_OPERACOES,
            VALOR,
            DATA_DE_AGENDAMENTO,
            DATA_AGENDADA,
            STATUS,
            VOLTAR,
        } = LABEL_COMUNS;

        const header = [LOTE, OPERACAO, VALOR, QUANTIDADE_DE_OPERACOES, DATA_AGENDADA, STATUS];

        const params = {
            contaId: contaSelecionada.id,
            pessoaId: contaSelecionada.pessoaTitularId,
            arquivoId: arquivo.id,
            ultimaChave: this.state.ultimaChave,
            dataInicial,
            dataFinal,
            statusTransferenciaEmLoteSelecionado,
        };

        const { ultimaChave } = this.state;

        return (
            <div>
                <ContaSelecionada contextoBppPag={true} contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel-sem-sombra">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }}>
                                <div className="cabecalho">
                                    <span>{LOTES_DO_ARQUIVO(arquivo.idArquivoSequencialPorPessoaId)}</span>
                                    <Button className="botao-fundo-verde-claro" onClick={() => this.setState({ abrirCollapse: !abrirCollapse })}>
                                        <span className="glyphicon glyphicon-filter" />
                                    </Button>
                                </div>
                            </div>

                            <FiltroGenerico
                                abrirCollapse={abrirCollapse}
                                contextoBppPag={true}
                                mostrarFiltroDeDatas={true}
                                labelDataInicial={DATA_DE_AGENDAMENTO}

                                dataInicial={dataInicial}
                                dataInicialErro={dataInicialErro}
                                setDataInicial={this.setDataInicial}
                                classNameData={'col-sm-2 col-md-2 col-lg-2'}

                                dataFinal={dataFinal}
                                dataFinalErro={dataFinalErro}
                                setDataFinal={this.setDataFinal}

                                listaDeStatus={listaStatusTransferenciaEmLote}
                                statusSelecionado={statusTransferenciaEmLoteSelecionado && statusTransferenciaEmLoteSelecionado.value}
                                setStatus={this.setStatusLotesDoArquivo}
                                statusErro={statusErro}

                                mostrarFiltroDeStatusMesmaLinha={true}

                                validationState={'success'}

                                exibirBotaoLimpar={true}
                                limparFiltro={this.onLimparFiltroClick}

                                buscar={() => this.buscarComFiltro({
                                    params: { ...params, ultimaChave: '' },
                                    dataInicial,
                                    dataFinal,
                                    statusTransferenciaEmLoteSelecionado
                                })}
                                exibirBotaoLimpar={true}
                                limparFiltro={this.onLimparFiltroClick}
                            />
                            {
                                this.props.exibirLoadingLotesDoArquivo ?
                                    <div className="alinhamento-centro"><Loading /></div> :
                                    lotesDoArquivo.length > 0 ?
                                        <div className="panel-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {header.map((el, idx) => <th key={idx} style={{ textAlign: 'center' }} className="fundo-verde-escuro">{el}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (lotesDoArquivo || []).map((el, idx) => (
                                                            <tr key={idx}>
                                                                <td className="alinhamento-centro id" style={{ borderTop: 'none' }}>
                                                                    <b
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => this.onIdLoteClick(el.id, el.idLote, el.operacaoExibicao)}>
                                                                        {el && el.idLote || ''}
                                                                    </b>
                                                                </td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el && el.operacaoExibicao || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el && formataMoedaComSimboloESinalNegacao(el.valorTotalLote) || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el && el.quantidadeLote || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el && formatarDataBR(el.dataPagamento) || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>
                                                                    {el ? el.statusExibicao : ''}
                                                                    <OverlayTrigger overlay={this.onMouseEnterStatusTooltip(el.status)}>
                                                                        <span className="d-inline-block">
                                                                            <i className="fa fa-question-circle radio-modelo-arquivo-exemplo" />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <If test={this.state.exbirMaisRegistros}>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block fonte-tamanho-20"
                                                    onClick={() => this.props.buscarLotesDoArquivo(params, lotesDoArquivo)}
                                                    style={{
                                                        height: '50px'
                                                    }}
                                                >
                                                    <b>{LABEL_COMUNS.MOSTRAR_MAIS}</b>
                                                </button>
                                            </If>
                                        </div>
                                        : this.state.erroArquivo ?
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.HISTORICO.ARQUIVOS.ERRO_VAZIO}</p>
                                            </div>
                                            :
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.HISTORICO.ARQUIVOS.ERRO_CHAMADA}</p>
                                            </div>
                            }
                            <Grid cols="12 12 12 12" className="alinhamento-direita">
                                <Button bsStyle="success" className="botao-voltar-telas" onClick={history.goBack}>{VOLTAR}</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({ ...state.TrasferenciaEmLoteReducer });

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        buscarLotesDoArquivo,
        getHistoricoLote,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(LotesDoArquivo);
