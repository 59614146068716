import { HEADER_API, HEADER_PDF, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';

export const fetchHistoricoDeComprovantes = (contaId, queryParameters) => {
    return fetch(montaUrlFetch(urlServicos.COMPROVANTE_HISTORICO(contaId), queryParameters), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchComprovanteId = (contaId, comprovanteId) => {
    return fetch(montaUrlFetch(urlServicos.COMPROVANTE_ID(contaId, comprovanteId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchOperacoesComprovante = (nomeParametro) => {
    return fetch(montaUrlFetch(urlServicos.RECUPERA_PARAMETROS(nomeParametro)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchComprovantePDF = (contaId, operacaoId, comprovanteId) => {
    return fetch(montaUrlFetch(urlServicos.COMPROVANTE_PDF(contaId, operacaoId, comprovanteId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_PDF()
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.blob() } : Promise.reject({ error: !resp.ok, data: null }))
        .then(async resp => await resp.data);
};
