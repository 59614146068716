import React, { Component } from 'react';
import { CPF } from 'cpf_cnpj';
import InputMask from 'react-input-mask';
import { FormControl, FormGroup } from 'react-bootstrap';
import { LABEL_COMUNS, MASCARA_CAMPOS, CONTA_SELECIONADA } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';
import GridRow from '../../componentes-uteis/grid/grid-row';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { formatarDataBR, formatarDataCompletaBR } from '../../util/data/dataUtil';
import Tab from '../../../componentes/tab/Tab';
import { getHistoricoPortadorContaCartao } from '../../../servicos/historico/historico-portador-service';
import { reactLocalStorage } from 'reactjs-localstorage';
import Loading from '../../loading-fenix/loading';
import moment from 'moment';
import If from 'componentes/componentes-uteis/if/if';

class DetalhesDoPortador extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            selectTab: '',
            isFetchingHistory: false,
            historico: [],
            showOnlyDetails: false
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillUpdate(nextProps) {
        if (nextProps.exibirModal !== this.props.exibirModal && nextProps.exibirModal) {
            const cartaoSelecionado = reactLocalStorage.getObject(LABEL_COMUNS.CARTAO_SELECIONADO);
            cartaoSelecionado.id = cartaoSelecionado.cartaoId;
            cartaoSelecionado.contaPaiId = CONTA_SELECIONADA.OBJETO().id;

            this.setState({ isFetchingHistory: true, historicoErro: false });
            getHistoricoPortadorContaCartao(cartaoSelecionado.contaPaiId, nextProps.cartaoSelecionado.cartaoId)
                .then(({ data }) => {
                    const historicoOrdenado = data.sort((a, b) => moment(b.dataAlteracao || b.dataAlteracao).diff(moment(a.dataAlteracao || a.dataAlteracao)));
                    this.setState({ historico: historicoOrdenado });
                })
                .catch(() => this.setState({ historicoErro: true }))
                .finally(() => this.setState({ isFetchingHistory: false }));
        }
    }

    retornaRodapeModal() {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={this.props.contextoBppPag}
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.props.setExibirModalDetalhePortador(false);
                    this.setState(this.INITIAL_STATE);
                }}
            />
        );
    }

    montaCorpoTabela() {
        return this.state.historico.map(({ dataAlteracao, portadorParaDocumento, portadorParaNome, portadorParaDataNascimento, usuarioNome }, idx) => (
            <tr style={{ textAlign: 'center' }} key={idx}>
                <td>{formatarDataCompletaBR(dataAlteracao)}</td>
                <td>{CPF.format(portadorParaDocumento)}</td>
                <td style={{ maxWidth: 230 }}>{portadorParaNome}</td>
                <td>{formatarDataBR(portadorParaDataNascimento)}</td>
                <td>{usuarioNome}</td>
            </tr>
        )
        );
    }

    retornaCorpoModalDetalhes() {
        const { dataNascimento, documento, nomePortador, idCartaoImpresso, panFormatado } = this.props.cartaoSelecionado;
        const { showOnlyDetails } = this.props;

        return (
            <GridRow>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                <Tab
                                    isSelected={this.state.selectTab === 'detalhe' || this.state.selectTab === ''}
                                    title={'Detalhe do Portador'}
                                    onClick={() => this.setState({ selectTab: 'detalhe' })}
                                />
                            </td>
                            <td>
                                <If test={!showOnlyDetails}>
                                    <Tab
                                        isSelected={this.state.selectTab === 'historico'}
                                        title={'Histórico de Portadores'}
                                        onClick={() => this.setState({ selectTab: 'historico' })}
                                        isRight
                                    />
                                </If>
                            </td>
                        </tr>
                    </tbody>
                </table>
                {!documento || !nomePortador
                    ? <div className="alinhamento-centro" style={{ padding: '60px' }}>
                        <span>
                            Esse cartão ainda não possui um portador vinculado.
                        </span>
                    </div>
                    : <Grid className="alinhamento-direita">
                        <FormGroup>

                            <div className="alinhamento-centro">
                                <br />
                                <span style={{ fontSize: '16px' }}> <b>{LABEL_COMUNS.ID}</b> {idCartaoImpresso} &nbsp;&nbsp;&nbsp; <b> {' '} {LABEL_COMUNS.CARTAO}</b> {panFormatado}</span>
                            </div>
                            <br />
                            <center>
                                <table style={{ textAlign: 'right' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ paddingBottom: 10 }}><span><b style={{ marginRight: 10 }}> {LABEL_COMUNS.CPF}{':'} </b></span></td>
                                            <td style={{ width: '70%' }}>
                                                <FormGroup>
                                                    <InputMask
                                                        id="numeroDocumento"
                                                        className="mensagens-erro form-control"
                                                        mask={MASCARA_CAMPOS.CPF} maskChar=""
                                                        style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                        placeholder={LABEL_COMUNS.CPF}
                                                        value={documento && documento.numero}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ paddingBottom: 10 }}><span><b style={{ marginRight: 10 }}>{LABEL_COMUNS.NOME_COMPLETO}{':'} </b></span></td>
                                            <td style={{ width: '70%' }}>
                                                <FormGroup >
                                                    <FormControl
                                                        type="text"
                                                        id="nomeCompleto"
                                                        className="mensagens-erro form-control"
                                                        style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                        placeholder={LABEL_COMUNS.NOME_COMPLETO}
                                                        value={nomePortador}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style={{ paddingBottom: 10 }}><span><b style={{ marginRight: 10 }}>{LABEL_COMUNS.DATA_DE_NACIMENTO}{':'} </b></span></td>
                                            <td style={{ width: '70%' }}>
                                                <FormGroup>
                                                    <InputMask
                                                        id="dataNascimento"
                                                        className="mensagens-erro form-control"
                                                        mask={MASCARA_CAMPOS.DATA} maskChar=""
                                                        style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                        placeholder={LABEL_COMUNS.PLACEHOLDER_DATANASC}
                                                        value={formatarDataBR(dataNascimento)}
                                                        disabled
                                                    />
                                                </FormGroup>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </center>
                        </FormGroup>
                    </Grid>

                }
            </GridRow>

        );
    }

    conteudoAbaHistorico() {
        const { idCartaoImpresso, panFormatado } = this.props.cartaoSelecionado;
        if (!this.state.historico.length || this.state.historicoErro) {
            return <div className="alinhamento-centro" style={{ padding: '60px' }}>
                <span>
                    {this.state.historicoErro ? 'Não foi possível solicitar o histórico, tente novamente mais tarde' : 'Nenhum histórico encontrado.'}
                </span>
            </div>;
        }
        return <Grid className="tabela-historico-portador">
            <span>

                <div className="alinhamento-centro">
                    <br />
                    <span style={{ fontSize: '16px' }}> <b>{LABEL_COMUNS.ID}</b> {idCartaoImpresso} &nbsp;&nbsp;&nbsp; <b> {' '} {LABEL_COMUNS.CARTAO}</b> {panFormatado}</span>
                </div>
                <br />

                <table className="table table-bordered">
                    <thead>
                        <tr>
                            <th scope="col" style={{ textAlign: 'center' }}>Data de Inclusão</th>
                            <th scope="col" style={{ textAlign: 'center' }}>CPF</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Nome Completo</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Data de Nascimento</th>
                            <th scope="col" style={{ textAlign: 'center' }}>Usuário</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.montaCorpoTabela()}
                    </tbody>
                </table>

            </span>
        </Grid>;
    }

    retornaCorpoModalHistorico() {
        const { idCartaoImpresso, panFormatado } = this.props.cartaoSelecionado;
        return (
            <GridRow>
                <table style={{ width: '100%' }}>
                    <tbody>
                        <tr>
                            <td>
                                <Tab
                                    isSelected={this.state.selectTab === 'detalhe' || this.state.selectTab === ''}
                                    title={'Detalhe do Portador'}
                                    onClick={() => this.setState({ selectTab: 'detalhe' })}
                                />
                            </td>
                            <td>
                                <Tab
                                    isSelected={this.state.selectTab === 'historico'}
                                    title={'Histórico de Portadores'}
                                    onClick={() => this.setState({ selectTab: 'historico' })}
                                    isRight
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                {this.state.isFetchingHistory
                    ? <div className="alinhamento-centro" style={{ padding: '60px' }}> <Loading /> </div>
                    : this.conteudoAbaHistorico()
                }

            </GridRow>
        );
    }

    render() {
        return (
            <GridRow>
                <ModalComponent
                    exibirModal={this.props.exibirModal}
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.DETALHE_PORTADOR}
                    body={this.state.selectTab === '' || this.state.selectTab === 'detalhe' ? this.retornaCorpoModalDetalhes() : this.retornaCorpoModalHistorico()}
                    footer={this.retornaRodapeModal()}
                    bsSize="lg"
                />
            </GridRow>
        );
    }
}

export default DetalhesDoPortador;
