import React from 'react';
import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import { LABEL_COMUNS, LOTE_ARQUIVO_TRANSACAO, STATUS } from '../../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { aprovarOuReprovarLote } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';

const Types = {
    FUNCTION: 'function'
};

const TIPOS_DE_RESPOSTA = {
    ERRO: 'ERRO',
    SUCESSO: 'SUCESSO'
};

const TIPOS_DE_ACAO = {
    APROVAR: 'APROVAR',
    CANCELAR: 'CANCELAR'
};

/**
 * aprovar, altera o status de um LoteArquivo para aprovado.
 *
 * @param id {number}, id da transsferência em lote.
 * @param anexoContratual {number}, id do anexoCotrantual da conta selecionada.
 * @param onCloseModalConfimacao {function}, function para tratar o side effect quando alguma ação for tomada na confirmação.
 * @param onOpenModalFinalizacao {function} para tratar o side effect quando alguma ação for tomada na finalização.
 *
 * @returns retorna o resultado da promessa executada.
 * */
export const aprovar = (id, anexoContratual, onCloseModalConfimacao, onOpenModalFinalizacao) => (
    new Promise(resolve => {
        onCloseModalConfimacao && typeof onCloseModalConfimacao === Types.FUNCTION && onCloseModalConfimacao();

        aprovarOuReprovarLote({
            id: id,
            anexoContratualOriginal: anexoContratual,
            transacao: LOTE_ARQUIVO_TRANSACAO.APROVAR.transacao
        })
            .then(resolve);
    })
        .then(({ data }) => onOpenModalFinalizacao && onOpenModalFinalizacao(TIPOS_DE_RESPOSTA.SUCESSO, data))
        .catch(() => onOpenModalFinalizacao && onOpenModalFinalizacao(TIPOS_DE_RESPOSTA.ERRO, { mensagem: TIPOS_DE_ACAO.APROVAR }))
);

export async function aproveArchive(id, anexoContratual) {
    let response = {};
    try {
        response = await aprovarOuReprovarLote({
            id: id,
            anexoContratualOriginal: anexoContratual,
            transacao: LOTE_ARQUIVO_TRANSACAO.APROVAR.transacao
        });
    } catch (err) { 
        return err; 
    }
    return response;
}

/**
 * reprovar, altera o status de um LoteArquivo para reprovado.
 *
 * @param id {number}, id da transsferência em lote.
 * @param anexoContratual {number}, id do anexoCotrantual da conta selecionada.
 * @param onCloseModalConfimacao {function}, function para tratar o side effect quando alguma ação for tomada na confirmação.
 * @param onOpenModalFinalizacao {function}, para tratar o side effect quando alguma ação for tomada na finalização.
 *
 * @returns retorna o resultado da promessa executada.
 * */
export const reprovar = (id, anexoContratual, onCloseModalConfimacao, onOpenModalFinalizacao) => (
    new Promise(resolve => {
        onCloseModalConfimacao && typeof onCloseModalConfimacao === Types.FUNCTION && onCloseModalConfimacao();

        aprovarOuReprovarLote({
            id: id,
            anexoContratualOriginal: anexoContratual,
            transacao: LOTE_ARQUIVO_TRANSACAO.REPROVAR.transacao
        }).then(resolve);
    })
        .then(({ data }) => onOpenModalFinalizacao && onOpenModalFinalizacao(TIPOS_DE_RESPOSTA.SUCESSO, data))
        .catch(() => onOpenModalFinalizacao && onOpenModalFinalizacao(TIPOS_DE_RESPOSTA.ERRO, { mensagem: TIPOS_DE_ACAO.CANCELAR}))
);

/**
 * Corrigir, redireciona para a tela de inserir um novo arquivo.
 *
 * @param transferenciaEmLote {number}, id da transferencia em lote a ser corrigida.
 * @param history {object}, objeto que controla as rotas, nesse caso redireciona para a tela de inserir um novo arquivo
 *
 * @returns {void}
 * */
export const corrigir = (transferenciaEmLote, history) => (
    history.push({ pathname: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_NOVO, state: { acao: 'corrigir', transferenciaEmLote } })
);

/**
 * Detalhes, redireciona para a tela dos detalhes da transferencia em lote.
 *
 * @param transferenciaEmLote {number}, id da transferencia em lote a ser visualizado.
 * @param history {object}, objeto que controla as rotas, nesse caso redireciona para a tela de visualizar detalhes  da transferencia em lote.
 *
 * @returns {void}
 * */
export const detalhes = (transferenciaEmLote, history) => (
    history.push({ pathname: ROTAS_CAMINHOS.DETALHES_BPP_PAG, state: { transferenciaEmLote } })
);

/**
 * Menu de ações de acordo com o status de  uma transferencia em lote.
 *
 * @param transferenciaEmLote, id da transferencia em lote selecionada
 * @param history {object}, controla as ações nas rotas. Ex: ir para.../voltar para...
 * @param onClickAprovar {function}, function que será executada quando o menu aprovar for clicado.
 * @param onClickReprovar {function}, function que será executada quando o menu reprovar for clicado.
 * */
export const getMenuAcoes = ({
    transferenciaEmLote,
    history,
    onClickAprovar = () => { },
    onClickReprovar = () => { },
    podeAprovar
}) => {
    const AgendamentoAprovacoesBPPPagAcoes = {
        APROVAR: { key: LABEL_COMUNS.APROVAR, acao: onClickAprovar },
        CORRIGIR: { key: LABEL_COMUNS.CORRIGIR, acao: corrigir },
        DETALHAR: { key: LABEL_COMUNS.DETALHES, acao: detalhes },
        REPROVAR: { key: LABEL_COMUNS.REPROVAR, acao: onClickReprovar }
    };

    const getMenuAcoesAgendamentoAprovacoesBPPPag = () => {
        const { APROVAR, CORRIGIR, DETALHAR, REPROVAR } = AgendamentoAprovacoesBPPPagAcoes;

        const { TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS } = STATUS;

        return {
            [TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value]: [podeAprovar && CORRIGIR, podeAprovar && REPROVAR, DETALHAR].filter(elem => elem),
            [TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value]: [podeAprovar && APROVAR, podeAprovar && REPROVAR, DETALHAR].filter(elem => elem),
            [TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.EM_VALIDACAO.value]: [DETALHAR],
            [TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.REPROVADO.value]: [DETALHAR],
            [TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.APROVADO.value]: [DETALHAR]
        }[transferenciaEmLote.status] || [];
    };

    const opcoes = getMenuAcoesAgendamentoAprovacoesBPPPag();
    const isMenuHabilitado = opcoes && opcoes.length > 0;

    return (
        <Dropdown
      id="menuOpcoes"
      pullRight
      style={{ marginLeft: '-20px' }}
      disabled={!isMenuHabilitado}
            >
            <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                <Glyphicon glyph="option-horizontal" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {opcoes.map(el => (
                    <MenuItem
            key={el.key}
            onClick={() => el.acao(transferenciaEmLote, history)}
          >
                        {el.key}
                    </MenuItem>
        ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};
