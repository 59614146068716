
import { dataAtualPtBr, FORMATO_DE_DATA_BR } from '../../util/data/dataUtil';
import { TIPO_DOCUMENTO, MASCARA_CAMPOS, METODOS } from '../../util/contantes-comuns/constantes-comuns';

const ESTADO_INICIAL = {
    contaSelecionada: '',
    codigoBarras: '',
    exibirErroCodigoBarras: '',
    valor: '',
    exibirErroValor: false,
    exibirErroSaldo: false,
    dataVencimento: '',
    exibirErroDataVencimento: '',
    exibirErroDataVencimentoMenor: '',
    nomeBeneficiario: '',
    exibirErroNomeBeneficiario: '',
    numeroDocumentoBeneficiario: '',
    exibirErroNumeroDocumentoBeneficiario: '',
    defaultMaskPattern: '',
    tipoDocumentoBeneficiario: '',
    descricao: '',
    agendamentoHojeChecked: false,
    dataAgendamentoHoje: '',
    dataAgendamento: '',
    exibirErroDataAgendamento: false,
    exibirErroDataAgendamentoMenorIgualHoje: false,
    exibirErroDataAgendamentoMaiorDoQueUmAno: false,
    operacaoId: '',
    tokenSMS: '',
    tokenErro: '',
    reenviarToken: '',
    reenviarErro: '',
    exibirModalToken: false,
    exibirModalSucesso: false,
    exibirLoading: false
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_CODIGO_BARRAS:
            return { ...state, codigoBarras: action.payload, exibirErroCodigoBarras: action.payload ? false : state.exibirErroCodigoBarras };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_CODIGO_BARRAS:
            return { ...state, exibirErroCodigoBarras: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_VALOR:
            return {
                ...state, valor: action.payload, exibirErroValor: false, exibirErroSaldo: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_VALOR:
            return { ...state, exibirErroValor: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_SALDO:
            return { ...state, exibirErroSaldo: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DATA_VENCIMENTO:
            return { ...state, dataVencimento: action.payload, exibirErroDataVencimento: false, exibirErroDataVencimentoMenor: false };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO:
            return { ...state, exibirErroDataVencimento: action.payload, exibirErroDataVencimentoMenor: '' };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO_MENOR:
            return { ...state, exibirErroDataVencimentoMenor: action.payload, exibirErroDataVencimento: '' };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_NOME_BENEFICIARIO:
            return { ...state, nomeBeneficiario: action.payload, exibirErroNomeBeneficiario: false };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_NOME_BENEFICIARIO:
            return { ...state, exibirErroNomeBeneficiario: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DOCUMENTO_BENEFICIARIO:
            return { ...state, numeroDocumentoBeneficiario: action.payload, exibirErroNumeroDocumentoBeneficiario: false };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DOCUMENTO_BENEFICIARIO:
            return { ...state, exibirErroNumeroDocumentoBeneficiario: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_TIPO_DOCUMENTO:
            let mask = action.payload === TIPO_DOCUMENTO.CPF.value ? MASCARA_CAMPOS.CPF : MASCARA_CAMPOS.CNPJ;
            return { ...state, tipoDocumentoBeneficiario: action.payload, defaultMaskPattern: mask, numeroDocumentoBeneficiario: '' };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DESCRICAO:
            return {
                ...state,
                descricao: action.payload
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_AGENDAMENTO_HOJE:
            return {
                ...state,
                agendamentoHojeChecked: action.payload,
                dataAgendamentoHoje: action.payload ? dataAtualPtBr(FORMATO_DE_DATA_BR) : '',
                dataAgendamento: '',
                exibirErroDataAgendamento: false,
                exibirErroDataAgendamentoMenorIgualHoje: false,
                exibirErroDataAgendamentoMaiorDoQueUmAno: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DATA_AGENDAMENTO:
            return {
                ...state,
                agendamentoHojeChecked: false,
                dataAgendamentoHoje: '',
                dataAgendamento: action.payload,
                exibirErroDataAgendamento: false,
                exibirErroDataAgendamentoMenorIgualHoje: false,
                exibirErroDataAgendamentoMaiorDoQueUmAno: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO:
            return {
                ...state,
                exibirErroDataAgendamento: action.payload,
                exibirErroDataAgendamentoMenorIgualHoje: false,
                exibirErroDataAgendamentoMaiorDoQueUmAno: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MENORIGUAL_HOJE:
            return {
                ...state,
                exibirErroDataAgendamento: false,
                exibirErroDataAgendamentoMenorIgualHoje: action.payload,
                exibirErroDataAgendamentoMaiorDoQueUmAno: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MAIOR_QUE_UM_ANO:
            return {
                ...state,
                exibirErroDataAgendamento: false,
                exibirErroDataAgendamentoMenorIgualHoje: false,
                exibirErroDataAgendamentoMaiorDoQueUmAno: action.payload
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_EXIBIR_MODAL_TOKEN:
            return {
                ...state,
                exibirModalToken: action.payload,
                tokenSMS: ''
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.EXIBIR_MODAL_SUCESSO:
            return { ...state, exibirModalSucesso: action.payload };
        case METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_TOKEN_PAGAMENTO:
            return {
                ...state,
                operacaoId: action.payload.rel,
                exibirModalToken: true,
                reenviarErro: '',
                exibirLoading: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_PAGAMENTO_SUCCESS:
            return {
                ...state,
                operacaoId: action.payload.rel,
                exibirLoading: false,
                exibirModalSucesso: action.payload,
                msg: action.payload.msg
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS:
            return {
                ...state, exibirModalToken: false, exibirModalSucesso: action.payload, msg: action.payload.msg
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO:
            return {
                ...state, tokenErro: action.payload.erros[0].mensagem, reenviarErro: ''
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.REENVIAR_TOKEN_PAGAMENTO:
            return {
                ...state,
                reenviarToken: action.payload.msg,
                tokenErro: '',
                reenviarErro: '',
                exibirLoading: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.REENVIAR_TOKEN_PAGAMENTO_ERRO:
            return {
                ...state,
                reenviarErro: action.payload.erros[0].mensagem,
                tokenErro: '',
                reenviarToken: '',
                exibirLoading: false
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.ARMAZENAR_TOKEN_SMS:
            return {
                ...state, tokenSMS: action.payload
            };
        case METODOS.PAGAMENTOS_DE_CONTAS.SET_EXIBIR_LOADING:
            return {
                ...state,
                exibirLoading: action.payload
            };
        default:
            return state;
    }
};
