import React from 'react';
import { ENDERECO_BPP } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';

export const retornaEnderecoBpp = () => {
    return <div dangerouslySetInnerHTML={{ __html: ENDERECO_BPP() }
  } />;
};

export const getLocalidadePorCep = async (cep) => {
    return fetch(montaUrlFetch(urlServicos.CEPS(cep)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};
