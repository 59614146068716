import Controle from './lista-favorecido-controlador';
const localState = {
    'showMsg': false,
    'dispararTodasSolicitacoes': false,
    'favorecidos': [
        {
            'id': '1',
            'transferir': false,
            'favorecido': {
                'id': '1',
                'tipoFavorecido': 'CONTA_DIGITAL',
                'tipoConta': 'CONTA',
                'nome': 'TESTE-1',
                'contaId': '123456789123',
                'agencia': '123',
                'conta': '55548',
                'digito': '1',
                'banco': '254',
                'nomeBanco': 'BANCO DO BRASIL',
                'tipoDocumento': 'NAO SEI',
                'numeroDocumento': 'NAO SEI',
                'telefone': '(19)9999999',
                'email': 'email@teste.com.br',
                'ativo': 'true'
            }
        },
        {
            'id': '2',
            'transferir': false,
            'favorecido': {
                'id': '2',
                'tipoFavorecido': 'CONTA_DIGITAL',
                'tipoConta': 'CONTA',
                'nome': 'TESTE-2',
                'contaId': '123450789123',
                'agencia': '103',
                'conta': '22598',
                'digito': '1',
                'banco': '234',
                'nomeBanco': 'BANCO ITAU',
                'tipoDocumento': 'NAO SEI',
                'numeroDocumento': 'NAO SEI',
                'telefone': '(19)9999999',
                'email': 'email@teste.com.br',
                'ativo': 'true'
            }
        },
        {
            'id': '3',
            'transferir': false,
            'favorecido': {
                'id': '3',
                'tipoFavorecido': 'CONTA_DIGITAL',
                'tipoConta': 'CONTA',
                'nome': 'TESTE-3',
                'contaId': '888888888',
                'agencia': '9993',
                'conta': '21548',
                'digito': '1',
                'banco': '254',
                'nomeBanco': 'BANCO DO BRASIL',
                'tipoDocumento': 'NAO SEI',
                'numeroDocumento': 'NAO SEI',
                'telefone': '(19)9999999',
                'email': 'email@teste.com.br',
                'ativo': 'true'
            }
        }
    ]
};
export default function reducer(state = localState, action) {
    switch (action.type) {
        case Controle.getFavorecidosControle():
        {
            return {
                ...state,
                favorecidos: action.payload
            };
        }
        case Controle.getStatusShowMsgControle(): {
            return {
                ...state,
                showMsg: !state.showMsg
            };
        }
        case Controle.getStatusDispararTodasSolicitacoesControle(): {
            return {
                ...state,
                dispararTodasSolicitacoes: !state.dispararTodasSolicitacoes
            };
        }
        default:
            return state;
    }
}
