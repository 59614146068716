import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Button, Panel, Row, Col, ListGroup, FormGroup, FormControl, Alert, Collapse
} from 'react-bootstrap';
import * as prestacaoContaActions from './parametro-prestacao-action';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FilteredMultiSelect from 'react-filtered-multiselect';
import * as constantes from './constantes';
import './parametro.css';
import { reactLocalStorage } from 'reactjs-localstorage';
import Grid from '../../componentes-uteis/grid/grid';
import { LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';

class ParametroPrestacao extends Component {
    componentDidMount() {
        let pessoaTitularId = reactLocalStorage.getObject('contaSelecionada').pessoaTitularId;
        this.props.getObterParametrosDeClienteProjeto(pessoaTitularId);
        this.props.getObterParametrosDeClassificacao(pessoaTitularId);
    }
    /* componentWillMount(){
        let pessoaTitularId =  reactLocalStorage.getObject('contaSelecionada').pessoaTitularId;
        this.props.getObterParametrosDeClienteProjeto(pessoaTitularId);
        this.props.getObterParametrosDeClassificacao(pessoaTitularId);
    } */

    render() {
        let clienteProjetoSelecionados = [];
        let classificacaoSelecionadas = [];
        return (
            <div>
                <Row>
                    <Col xs={11} sm={11} md={11} lg={11} className="espacamento_conteudo_lote">
                        <Panel>
                            <Panel.Heading className="fundo-azul-claro">{constantes.CABECALHO_PAINEL_PRINCIPAL}</Panel.Heading>
                            <Panel.Body>
                                <ListGroup>
                                    <FormGroup>
                                        {/* ----- PARAMETROS DE CLIENTE/PROJETO ----- */}
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                {
                          this.props.parametroprestacao.exibirAlertaErroAoObterClienteProjeto
                            ? <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Alert
                                  bsStyle="danger"
                                  onDismiss={() => this.props.setExibirAlertaErroAoObterClienteProjeto(false)}>
                                        {constantes.TEXTO_ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO}
                                    </Alert>
                                </Col>
                            </Row>
                            : <div />
                        }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Button
                          className="botao_despesaseclientes"
                          onClick={() => this.props.setExpandirPainelDeClienteProjeto(!this.props.parametroprestacao.expandirPainelDeClienteProjeto)}>
                                                    {constantes.TEXTO_BOTAO_ACCORDION_CLIENTE_PROJETO}<span>&nbsp;&nbsp;</span>
                                                    {this.props.parametroprestacao.expandirPainelDeClienteProjeto
                            ? <span className="glyphicon glyphicon-chevron-up" />
                            : <span className="glyphicon glyphicon-chevron-down" />}
                                                </Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Collapse in={this.props.parametroprestacao.expandirPainelDeClienteProjeto}>
                                                    <Panel>
                                                        <ListGroup>
                                                            <Row className="conteudo_areas">
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <form>
                                                                        {/* CRIACAO NOVO CLIENTE/PROJETO */}
                                                                        <Col xs={8} sm={9} md={10} lg={10}>
                                                                            <FormGroup>
                                                                                <FormControl
                                          id="inputNovoParametroClienteProjeto"
                                          style={{ marginLeft: '-15px', width: '100%', height: '32px', paddingTop: '8px' }}
                                          type="text"
                                          placeholder={constantes.PLACEHOLDER_CAIXA_TEXTO_CLIENTE_PROJETO}
                                          maxLength={100}
                                          value={this.props.parametroprestacao.nomeDoClienteProjetoDigitado ? this.props.parametroprestacao.nomeDoClienteProjetoDigitado : ''}
                                          onChange={this.props.setArmazenarNomeClienteProjetoDigitado} />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs={4} sm={3} md={2} lg={2}>
                                                                            <Button
                                        style={{ width: '155px', height: '30px', paddingTop: '4px', marginLeft: '-30px' }}
                                        className="btn btn-primary"
                                        disabled={this.props.parametroprestacao.desabilitarBotaoCriarNovoClienteProjeto}
                                        onClick={() => this.props.setExibirConfirmacaoCriacaoDeClienteProjeto(true)}>
                                                                                {constantes.TEXTO_BOTAO_CRIAR_PARAMETRO_CLIENTE_PROJETO}
                                                                            </Button>
                                                                            {/* MODAL CONFIRMACAO CRIACAO DO CLIENTE/PROJETO */}
                                                                            <ModalComponent
                                        name="modalCriacaoDeClienteProjeto"
                                        exibirModal={this.props.parametroprestacao.exibirModalConfirmacaoCriacaoDeClienteProjeto}
                                        header={constantes.TEXTO_CABECALHO_MODAL_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO}
                                        body={this.corpoModalConfirmacaoCriacao(this.props.parametroprestacao.nomeDoClienteProjetoDigitado)}
                                        footer={this.rodapeModalConfirmacaoCriacao(this.props.parametroprestacao.nomeDoClienteProjetoDigitado)} />
                                                                            {/* MODAL ERRO CRIACAO DO CLIENTE/PROJETO */}
                                                                            <ModalComponent
                                        name="modalErroAoCriarClienteProjeto"
                                        exibirModal={this.props.parametroprestacao.exibirModalErroCriacaoDeClienteProjeto}
                                        header={constantes.TEXTO_ERRO_CRIAR_PARAMETRO_CLIENTE_PROJETO}
                                        body={this.corpoModalErroCriacao()}
                                        footer={this.rodapeModalErroCriacao()} />
                                                                            {/* --------------- REMOCAO CLIENTES/PROJETOS --------------- */}
                                                                        </Col>
                                                                    </form>
                                                                    <FilteredMultiSelect
                                    className="tamanho_select"
                                    classNames={{ select: 'form-control', button: 'btn btn-remover' }}
                                    options={this.props.parametroprestacao.parametrosClienteProjeto ? this.props.parametroprestacao.parametrosClienteProjeto : { id: '1', nome: 'Não há itens cadastrados' }}
                                    onChange={(clienteProjetoSelecionados) => this.props.setArmazenarClienteProjetoSelecionadosParaRemocao(clienteProjetoSelecionados)}
                                    selectedOptions={clienteProjetoSelecionados}
                                    showFilter={false}
                                    buttonText={constantes.TEXTO_BOTAO_REMOVER_PARAMETRO_CLIENTE_PROJETO}
                                    size={8}
                                    textProp="nome"
                                    disabled={!this.props.parametroprestacao.parametrosClienteProjeto[0]}
                                    valueProp="id" />
                                                                    {/* MODAL CONFIRMACAO REMOCAO DO CLIENTE/PROJETO */}
                                                                    <ModalComponent
                                    name="modalRemocaoDeClienteProjeto"
                                    exibirModal={this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClienteProjeto}
                                    header={constantes.TEXTO_CABECALHO_MODAL_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO}
                                    body={this.corpoModalConfirmacaoRemocao(this.props.parametroprestacao.parametrosClienteProjetoParaRemocao)}
                                    footer={this.rodapeModalConfirmacaoRemocao(this.props.parametroprestacao.parametrosClienteProjetoParaRemocao)} />
                                                                    {/* MODAL ERRO REMOCA DO CLIENTE/PROJETO */}
                                                                    <ModalComponent
                                    name="modalErroAoRemoverClienteProjeto"
                                    exibirModal={this.props.parametroprestacao.exibirModalErroRemocaoDeClienteProjeto}
                                    header={constantes.TEXTO_ERRO_REMOVER_PARAMETRO_CLIENTE_PROJETO}
                                    body={this.corpoModalErroRemocao()}
                                    footer={this.rodapeModalErroRemocao()} />
                                                                </Col>
                                                            </Row>
                                                        </ListGroup>
                                                    </Panel>
                                                </Collapse>
                                            </Col>
                                        </Row>
                                        {/* ----- PARAMETROS DE CLASSIFICACAO ----- */}
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                {
                          this.props.parametroprestacao.exibirAlertaErroAoObterClassificacao
                            ? <Row>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <Alert
                                  bsStyle="danger"
                                  onDismiss={() => this.props.setExibirAlertaErroAoObterClassificacao(false)}>
                                        {constantes.TEXTO_ERRO_OBTER_PARAMETROS_CLASSIFICACAO}
                                    </Alert>
                                </Col>
                            </Row>
                            : <div />
                        }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Button
                          className="botao_despesaseclientes"
                          onClick={() => this.props.setExpandirPainelDeClassificacao(!this.props.parametroprestacao.expandirPainelDeClassificacao)}>
                                                    {constantes.TEXTO_BOTAO_ACCORDION_CLASSIFICACAO}<span>&nbsp;&nbsp;</span>
                                                    {this.props.parametroprestacao.expandirPainelDeClassificacao
                            ? <span className="glyphicon glyphicon-chevron-up" />
                            : <span className="glyphicon glyphicon-chevron-down" />}
                                                </Button>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={12}>
                                                <Collapse in={this.props.parametroprestacao.expandirPainelDeClassificacao}>
                                                    <Panel>
                                                        <ListGroup>
                                                            <Row className="conteudo_areas">
                                                                <Col xs={12} sm={12} md={12} lg={12}>
                                                                    <form>
                                                                        {/* CRIACAO NOVA CLASSIFICACAO */}
                                                                        <Col xs={8} sm={9} md={10} lg={10}>
                                                                            <FormGroup>
                                                                                <FormControl
                                          id="inputNovoParametroClassificacao"
                                          style={{ marginLeft: '-15px', width: '100%', height: '32px', paddingTop: '8px' }}
                                          type="text"
                                          placeholder={constantes.PLACEHOLDER_CAIXA_TEXTO_CLASSIFICACAO}
                                          maxLength={100}
                                          value={this.props.parametroprestacao.nomeDaClassificacaoDigitado ? this.props.parametroprestacao.nomeDaClassificacaoDigitado : ''}
                                          onChange={this.props.setArmazenarNomeClassificacaoDigitado} />
                                                                            </FormGroup>
                                                                        </Col>
                                                                        <Col xs={4} sm={3} md={2} lg={2}>
                                                                            <Button
                                        style={{ width: '155px', height: '30px', paddingTop: '4px', marginLeft: '-30px' }}
                                        className="btn btn-primary"
                                        disabled={this.props.parametroprestacao.desabilitarBotaoCriarNovaClassificacao}
                                        onClick={() => this.props.setExibirConfirmacaoCriacaoDeClassificacao(true)}>
                                                                                {constantes.TEXTO_BOTAO_CRIAR_PARAMETRO_CLASSIFICACAO}
                                                                            </Button>
                                                                            {/* MODAL CONFIRMACAO CRIACAO DA CLASSIFICACAO */}
                                                                            <ModalComponent
                                        name="modalCriacaoDeClassificacao"
                                        exibirModal={this.props.parametroprestacao.exibirModalConfirmacaoCriacaoDeClassificacao}
                                        header={constantes.TEXTO_CABECALHO_MODAL_CONFIRMACAO_CRIACAO_CLASSIFICACAO}
                                        body={this.corpoModalConfirmacaoCriacao(this.props.parametroprestacao.nomeDaClassificacaoDigitado)}
                                        footer={this.rodapeModalConfirmacaoCriacao(this.props.parametroprestacao.nomeDaClassificacaoDigitado)} />
                                                                            {/* MODAL ERRO CRIACAO DA CLASSIFICACAO */}
                                                                            <ModalComponent
                                        name="modalErroAoCriarClassificacao"
                                        exibirModal={this.props.parametroprestacao.exibirModalErroCriacaoDeClassificacao}
                                        header={constantes.TEXTO_ERRO_CRIAR_PARAMETRO_CLASSIFICACAO}
                                        body={this.corpoModalErroCriacao()}
                                        footer={this.rodapeModalErroCriacao()} />
                                                                            {/* --------------- REMOCAO CLASSIFICACOES --------------- */}
                                                                        </Col>
                                                                    </form>
                                                                    <FilteredMultiSelect
                                    className="tamanho_select"
                                    classNames={{ select: 'form-control', button: 'btn btn-remover' }}
                                    options={this.props.parametroprestacao.parametrosClassificacao ? this.props.parametroprestacao.parametrosClassificacao : { id: '1', nome: 'Não há itens cadastrados' }}
                                    onChange={(classificacaoSelecionadas) => this.props.setArmazenarClassificaoSelecionadasParaRemocao(classificacaoSelecionadas)}
                                    selectedOptions={classificacaoSelecionadas}
                                    showFilter={false}
                                    buttonText={constantes.TEXTO_BOTAO_REMOVER_PARAMETRO_CLASSIFICACAO}
                                    size={8}
                                    textProp="nome"
                                    disabled={!this.props.parametroprestacao.parametrosClassificacao[0]}
                                    valueProp="id" />
                                                                    {/* MODAL CONFIRMACAO REMOCAO DA CLASSIFICACAO */}
                                                                    <ModalComponent
                                    name="modalRemocaoDeClassificacao"
                                    exibirModal={this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClassificacao}
                                    header={constantes.TEXTO_CABECALHO_MODAL_CONFIRMACAO_REMOCAO_CLASSIFICACAO}
                                    body={this.corpoModalConfirmacaoRemocao(this.props.parametroprestacao.parametrosClassificacaoParaRemocao)}
                                    footer={this.rodapeModalConfirmacaoRemocao(this.props.parametroprestacao.parametrosClassificacaoParaRemocao)} />
                                                                    {/* MODAL ERRO REMOCA DO CLIENTE/PROJETO */}
                                                                    <ModalComponent
                                    name="modalErroAoRemoverClassificacao"
                                    exibirModal={this.props.parametroprestacao.exibirModalErroRemocaoDeClassificacao}
                                    header={constantes.TEXTO_ERRO_REMOVER_PARAMETRO_CLASSIFICACAO}
                                    body={this.corpoModalErroRemocao()}
                                    footer={this.rodapeModalErroRemocao()} />
                                                                </Col>
                                                            </Row>
                                                        </ListGroup>
                                                    </Panel>
                                                </Collapse>
                                            </Col>
                                        </Row>

                                    </FormGroup>
                                </ListGroup>
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Grid cols="11 11 11 11" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                    </Grid>
                </Row>
                {/* MODAL SUCESSO CRIACAO GENERICO */}
                <ModalComponent
          name="modalSucessoCriacao"
          exibirModal={this.props.parametroprestacao.exibirSucessoCriacao}
          header={constantes.TEXTO_CABECALHO_MODAL_SUCESSO_CRIACAO}
          body={this.corpoModalSucessoCriacao()}
          footer={this.rodapeModalSucessoCriacao()} />
                {/* MODAL SUCESSO REMOCAO GENERICO */}
                <ModalComponent
          name="modalSucessoRemocao"
          exibirModal={this.props.parametroprestacao.exibirSucessoRemocao}
          header={constantes.TEXTO_CABECALHO_MODAL_SUCESSO_REMOCAO}
          body={this.corpoModalSucessoRemocao()}
          footer={this.rodapeModalSucessoRemocao()} />
            </div>
        );
    }

    // CRIACAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalConfirmacaoCriacao(nomeDoParametro) {
        return <div><span>Confirma a criação do parâmetro <b>{nomeDoParametro}</b>?</span></div>;
    }

    rodapeModalConfirmacaoCriacao(nomeDoParametro) {
        let pessoaTitularId = this.props.parametroprestacao.contaSelecionadaPessoaTitularId ? this.props.parametroprestacao.contaSelecionadaPessoaTitularId : reactLocalStorage.getObject('contaSelecionada').pessoaTitularId;
        if (this.props.parametroprestacao.exibirModalConfirmacaoCriacaoDeClienteProjeto) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirConfirmacaoCriacaoDeClienteProjeto(false)}>
                            {constantes.TEXTO_BOTAO_CANCELAR}
                        </Button>
                        <Button
              bsStyle="primary"
              onClick={
                () => {
                  this.props.setCriarParametrosDeClienteProjeto(pessoaTitularId, nomeDoParametro);
                }
              }>
                            {constantes.TEXTO_BOTAO_CRIAR_PARAMETRO_CLIENTE_PROJETO}
                        </Button>
                    </div>
                </div>
            );
        }
        if (this.props.parametroprestacao.exibirModalConfirmacaoCriacaoDeClassificacao) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirConfirmacaoCriacaoDeClassificacao(false)}>
                            {constantes.TEXTO_BOTAO_CANCELAR}
                        </Button>
                        <Button
              bsStyle="primary"
              onClick={() => {
                this.props.setCriarParametrosDeClassificacao(pessoaTitularId, nomeDoParametro);
              }
              }>
                            {constantes.TEXTO_BOTAO_CRIAR_PARAMETRO_CLASSIFICACAO}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    // SUCESSO CRIACAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalSucessoCriacao() {
        return <div><span>{constantes.TEXTO_SUCESSO_CRIACAO_PARAMETRO}</span></div>;
    }

    rodapeModalSucessoCriacao() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button
            bsStyle="primary"
            onClick={() => this.props.setExibirSucessoCriacao(false)}>
                        {constantes.TEXTO_BOTAO_OK}
                    </Button>
                </div>
            </div>
        );
    }

    // ERRO CRIACAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalErroCriacao() {
        let mensagem = '';
        if (this.props.parametroprestacao.exibirModalErroCriacaoDeClienteProjeto && this.props.parametroprestacao.mensagemErroCriacaoDeClienteProjeto) {
            mensagem = this.props.parametroprestacao.mensagemErroCriacaoDeClienteProjeto.erros[0].mensagem;
        }
        if (this.props.parametroprestacao.exibirModalErroCriacaoDeClassificacao && this.props.parametroprestacao.mensagemErroCriacaoDeClassificacao) {
            mensagem = this.props.parametroprestacao.mensagemErroCriacaoDeClassificacao.erros[0].mensagem;
        }
        if (mensagem === constantes.TEXTO_REGISTRO_JA_EXISTENTE.projetoExiste || constantes.TEXTO_REGISTRO_JA_EXISTENTE.classificacaoExiste) {
            return <div><span>{mensagem}</span></div>;
        } else {
            return (
                <div className="alert alert-danger">
                    <span className="glyphicon glyphicon-exclamation-sign" />&nbsp;&nbsp;
                    <span>{mensagem}</span>
                </div>
            );
        }
    }

    rodapeModalErroCriacao() {
        if (this.props.parametroprestacao.exibirModalErroCriacaoDeClienteProjeto) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirModalErroCriacaoDeClienteProjeto(false)}>
                            {constantes.TEXTO_BOTAO_OK}
                        </Button>
                    </div>
                </div>
            );
        }
        if (this.props.parametroprestacao.exibirModalErroCriacaoDeClassificacao) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirModalErroCriacaoDeClassificacao(false)}>
                            {constantes.TEXTO_BOTAO_OK}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    // REMOCAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalConfirmacaoRemocao() {
        if (this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClienteProjeto) {
            return (
                <div>
                    <span>{constantes.TEXTO_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO}</span><br /><br />
                    {
            this.props.parametroprestacao.parametrosClienteProjetoParaRemocao
              .map((obj) => {
                return (
                    <div key={obj.id}>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;{obj.nome}</b><br />
                    </div>
                );
              })
          }
                </div>
            );
        }
        if (this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClassificacao) {
            return (
                <div>
                    <span>{constantes.TEXTO_CONFIRMACAO_REMOCAO_CLASSIFICACAO}</span><br /><br />
                    {
            this.props.parametroprestacao.parametrosClassificacaoParaRemocao
              .map((obj) => {
                return (
                    <div key={obj.id}>
                        <b>&nbsp;&nbsp;&nbsp;&nbsp;{obj.nome}</b><br />
                    </div>
                );
              })
          }
                </div>
            );
        }
    }

    rodapeModalConfirmacaoRemocao() {
        let pessoaTitularId = this.props.parametroprestacao.contaSelecionadaPessoaTitularId ? this.props.parametroprestacao.contaSelecionadaPessoaTitularId : reactLocalStorage.getObject('contaSelecionada').pessoaTitularId;
        if (this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClienteProjeto) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirConfirmacaoRemocaoDeClienteProjeto(false)}>
                            {constantes.TEXTO_BOTAO_CANCELAR}
                        </Button>
                        <Button
              bsStyle="primary"
              onClick={() => this.props.setRemoverParametrosDeClienteProjeto(
                pessoaTitularId, this.props.parametroprestacao.parametrosClienteProjetoParaRemocao)}>
                            {constantes.TEXTO_BOTAO_REMOVER_PARAMETRO_CLIENTE_PROJETO}
                        </Button>
                    </div>
                </div>
            );
        }
        if (this.props.parametroprestacao.exibirModalConfirmacaoRemocaoDeClassificacao) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirConfirmacaoRemocaoDeClassificacao(false)}>
                            {constantes.TEXTO_BOTAO_CANCELAR}
                        </Button>
                        <Button
              bsStyle="primary"
              onClick={() => this.props.setRemoverParametrosDeClassificacao(
                pessoaTitularId, this.props.parametroprestacao.parametrosClassificacaoParaRemocao)}>
                            {constantes.TEXTO_BOTAO_REMOVER_PARAMETRO_CLASSIFICACAO}
                        </Button>
                    </div>
                </div>
            );
        }
    }

    // SUCESSO REMOCAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalSucessoRemocao() {
        return <div><span>{constantes.TEXTO_SUCESSO_REMOCAO_PARAMETRO}</span></div>;
    }

    rodapeModalSucessoRemocao() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button
            bsStyle="primary"
            onClick={() => this.onClickRodapeModalSucessoRemocao()}>
                        {constantes.TEXTO_BOTAO_OK}
                    </Button>
                </div>
            </div>
        );
    }

    onClickRodapeModalSucessoRemocao() {
        this.props.setExibirSucessoRemocao(false);
        let pessoaTitularId = this.props.parametroprestacao.contaSelecionadaPessoaTitularId ? this.props.parametroprestacao.contaSelecionadaPessoaTitularId : reactLocalStorage.getObject('contaSelecionada').pessoaTitularId;
        this.props.getObterParametrosDeClienteProjeto(pessoaTitularId);
        this.props.getObterParametrosDeClassificacao(pessoaTitularId);
    }

    // ERRO REMOCAO CLIENTE/PROJETO e CLASSIFICACAO
    corpoModalErroRemocao() {
        let mensagem = '';
        if (this.props.parametroprestacao.exibirModalErroRemocaoDeClienteProjeto && this.props.parametroprestacao.mensagemErroRemocaoDeClienteProjeto) {
            mensagem = this.props.parametroprestacao.mensagemErroRemocaoDeClienteProjeto.erros;
        }
        if (this.props.parametroprestacao.exibirModalErroRemocaoDeClassificacao && this.props.parametroprestacao.mensagemErroRemocaoDeClassificacao) {
            mensagem = this.props.parametroprestacao.mensagemErroRemocaoDeClassificacao.erros;
        }
        return (
            <div className="alert alert-danger">
                <span className="glyphicon glyphicon-exclamation-sign" />&nbsp;&nbsp;
                <span>{mensagem} :)
                    {/* <span>
                {
                    mensagem.map((obj) => {
                        return (
                            <div key={obj.id}>
                                <b>&nbsp;&nbsp;&nbsp;&nbsp;{obj.nome}</b><br />
                            </div>
                        )
                    })
                } */}
                </span>
            </div>
        );
    }

    rodapeModalErroRemocao() {
        if (this.props.parametroprestacao.exibirModalErroRemocaoDeClienteProjeto) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirModalErroRemocaoDeClienteProjeto(false)}>
                            {constantes.TEXTO_BOTAO_OK}
                        </Button>
                    </div>
                </div>
            );
        }
        if (this.props.parametroprestacao.exibirModalErroRemocaoDeClassificacao) {
            return (
                <div>
                    <div className="alinhamento-direira">
                        <Button
              bsStyle="default"
              onClick={() => this.props.setExibirModalErroRemocaoDeClassificacao(false)}>
                            {constantes.TEXTO_BOTAO_OK}
                        </Button>
                    </div>
                </div>
            );
        }
    }
}

function mapStateToProps(state) {
    return {
        parametroprestacao: state.ParametroPrestacaoReducer
    };
}

const mapDispatchToProps = (dispacth) => bindActionCreators(prestacaoContaActions, dispacth);

export default connect(mapStateToProps, mapDispatchToProps)(ParametroPrestacao);
