import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';
import { ORDENACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const gravaOutorga = (dadosOutorga) => {
    return fetchSalvaOutorga(dadosOutorga, HTTP_METODOS.POST);
};

export const atualizaOutorga = async (dadosOutorga) => {
    return await fetchSalvaOutorga(dadosOutorga, HTTP_METODOS.PUT);
};

export const retornaPerfisEPoderesOutorga = () => {
    const DADOS_OUTORGA = 'dadosOutorga';
    return fetch(montaUrlFetch(urlServicos.RECUPERA_PARAMETROS(DADOS_OUTORGA)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const retornaPoderesOutorga = () => {
    return fetch(montaUrlFetch(urlServicos.OUTORGAS), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const retornaAutorizadosDaConta = (contaId) => {
    return fetch(montaUrlFetch(urlServicos.OUTORGAS_CONTA_ID(contaId), { tamanhoPagina: 1000, ordem: ORDENACAO.ASC }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchExcluirAutorizadoEOutorga = (contaId, pessoaId) => {
    return fetch(montaUrlFetch(urlServicos.OUTORGA_CONTA_ID_PESSOA_PESSOA_ID(contaId, pessoaId)), {
        method: HTTP_METODOS.DELETE,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetPoderesOutorgaPorPessoa = (contaId, pessoaId) => {
    return fetch(montaUrlFetch(urlServicos.OUTORGA_CONTA_ID_PESSOA_PESSOA_ID(contaId, pessoaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetParametroParametroOperacao = () => {
    return fetch(montaUrlFetch(urlServicos.PARAMETRO_OPERACAO), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

const fetchSalvaOutorga = (dadosOutorga, httpMetodo) => {
    const { contaId, pessoaId } = dadosOutorga;
    return fetch(montaUrlFetch(urlServicos.OUTORGA_CONTA_ID_PESSOA_PESSOA_ID(contaId, pessoaId)), {
        method: httpMetodo,
        headers: HEADER_API().headers,
        body: JSON.stringify(retornaDadosJsonPostOutorga(dadosOutorga))
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

const retornaDadosJsonPostOutorga = (dadosOutorga) => {
    const permissoesConcedidas = dadosOutorga.permissoesConcedidas;
    const { listaDePoderesOutorga } = dadosOutorga;
    const perfil = Object.keys(permissoesConcedidas).map(key => permissoesConcedidas[key].nomePerfil);
    const poderes = Object.keys(listaDePoderesOutorga).map(key => listaDePoderesOutorga[key].poder);
    return { poderes, perfil };
};
