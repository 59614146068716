import React, { Component } from 'react';
import Axios from 'axios';
import { environment } from '../../../configuracoes/environment';
import avatarConta from '../../../imagens/imagem-conta.PNG';
import { URL_ARQUIVOS, HEADER_API } from '../../../servicos/url-servicos';

export default class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            url: avatarConta
        };
    }
    // componentDidMount() {
    //     if (this.props.conta && this.props.conta.urlAvatar) {
    //         this.setState({ url: this.props.conta.urlAvatar });
    //     }
    // }
    render() {
        return (
            <div>
                <img src={avatarConta} className="avatar-conta" alt="imagem conta" onError={(e) => { e.target.src = this.avatarConta; }} />
            </div>
        );
    }
}
