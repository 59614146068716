import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaErrosExtrato } from '../../componentes/util/extrato/extrato-util';

const INITIAL_ESTATE = {
    contaSelecionada: '',
    exibirLoadingExtrato: undefined,
    erroGenerico: '',
    linhasDoExtratoCSV: [],
    linhasDoExtrato: [],
    linhasDoExtratoDashboard: [],
    linhaDoExtratoSelecionada: undefined,
    ultimaChave: '',
    periodoExcedidoErro: '',
    erros: undefined

};

export default (state = INITIAL_ESTATE, action) => {
    let linhasDoExtratoRetornada;
    let errosRetornadosDoBackEnd;
    switch (action.type) {
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.EXTRATO.SET_EXIBIR_LOADING_EXTRATO:
            return { ...state, exibirLoadingExtrato: action.payload };
        case METODOS.EXTRATO.GET_EXTRATO_CSV:
            return {
                ...state,
                linhasDoExtratoCSV: action.payload.resultado || []
            };
        case METODOS.EXTRATO.GET_EXTRATO:
            linhasDoExtratoRetornada = action.payload.resultado || [];
            let existeItens = linhasDoExtratoRetornada && linhasDoExtratoRetornada.length > 0;
            let existeUltimaChave = action.payload && action.payload.ultimaChave;
            return {
                ...state,
                linhasDoExtrato: linhasDoExtratoRetornada,
                ultimaChave: existeUltimaChave ? action.payload.ultimaChave : '',
                botaoExibirMaisRegistros: existeUltimaChave,
                erros: undefined,
                dataInicialErro: '',
                dataFinalErro: '',
                erroGenerico: '',
                periodoExcedidoErro: ''
            };
        case METODOS.EXTRATO.GET_EXTRATO_ERRO:
            errosRetornadosDoBackEnd = action.payload && action.payload.erros || {};
            return {
                ...state,
                linhasDoExtrato: [],
                erros: retornaErrosExtrato(errosRetornadosDoBackEnd),
                existemItensExtratoNoPeriodo: false,
                botaoExibirMaisRegistros: false
            };
        case METODOS.EXTRATO.GET_EXTRATO_DASHBOARD_CONTA:
            linhasDoExtratoRetornada = action.payload.resultado || [];
            return { ...state, linhasDoExtratoDashboard: linhasDoExtratoRetornada, erros: undefined };
        case METODOS.EXTRATO.GET_EXTRATO_DASHBOARD_CONTA_ERRO:
            errosRetornadosDoBackEnd = action.payload && action.payload.erros || {};
            return { ...state, linhasDoExtratoDashboard: [], erros: retornaErrosExtrato(errosRetornadosDoBackEnd) };
        case METODOS.EXTRATO.SET_LINHA_DO_EXTRATO_SELECIONADA:
            return { ...state, linhaDoExtratoSelecionada: action.payload, detalhesTransacaoSelecionada: action.payload };
        case METODOS.EXTRATO.SET_DETALHES_TRANSACAO_SELECIONADA:
            return { ...state, detalhesTransacaoSelecionada: action.payload };
        case METODOS.EXTRATO.LIMPAR_EXTRATO:
            return INITIAL_ESTATE;
        default:
            return state;
    }
};
