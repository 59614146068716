import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Button } from 'react-bootstrap';
import Grid from '../componentes-uteis/grid/grid';
import ContaSelecionada from '../contas/conta-selecionada';
import If from '../componentes-uteis/if/if';
import * as actions from '../../action/extrato/extrato-action';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import LoadingFenix from '../loading-fenix/loading-fenix';
import { CAMPOS, LABEL_COMUNS, TIPO_PRESTACAO_CONTAS, MENSAGENS, DESCRICAO_CONTA, TIPO_DE_ACESSO, } from '../util/contantes-comuns/constantes-comuns';
import GridExtrato from './grid-extrato';
import { dataAtualPtBr, formatarDataBR, FORMATO_DE_DATA_BR, retornaDataDeDiasPassados, } from '../util/data/dataUtil';
import DetalheExtrato from '../componentes-uteis/extrato/detalhe-extrato';
import DetalheExtratoTransacoes from '../componentes-uteis/extrato/detalhe-extrato-transacoes';
import { montaRespostaExportarExtratoCSV } from '../util/extrato/extrato-util';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';

class Extrato extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            dataInicial: formatarDataBR(retornaDataDeDiasPassados()), dataFinal: dataAtualPtBr(FORMATO_DE_DATA_BR),
            exibirModalDetalhe: false, exibirModalDetalheTransacao: false, exibirModalConfirmaPrestacaoConta: false
        };
        this.state = this.INITIAL_STATE;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.extrato.erros && nextProps.extrato.erros !== this.props.extrato.erros) {
            this.setState(nextProps.extrato.erros);
        }
    }

    componentDidMount() {
        this.props.limparExtrato();
        this.props.setContaSelecionada(this.props.contaSelecionada);
        this.props.getExtratoCSV(this.props.contaSelecionada, this.state.dataInicial, this.state.dataFinal);
        this.props.getExtrato(this.props.contaSelecionada, this.state.dataInicial, this.state.dataFinal);
    }

    setLinhaDoExtratoSelecionada = (linhaDoExtratoSelecionada) => {
        this.setState({ linhaDoExtratoSelecionada });
        this.props.setLinhaDoExtratoSelecionada(linhaDoExtratoSelecionada);
    }

    setPrestacaoDeContasRecuperada = (prestacaoDeContasRecuperada) => {
        this.setState({ prestacaoDeContasRecuperada });
    }

    setPropsCampos(e, data) {
        const valorDoCampo = e.target.value;
        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: '',
        });
    }

    rodapeModalConfirmacao = () => {
        const { contaSelecionada } = this.props;
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setState(state => ({ exibirModalConfirmaPrestacaoConta: !state.exibirModalConfirmaPrestacaoConta }))}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={async () => {
                    const state = {
                        contaSelecionada,
                        linhaDoExtratoSelecionada: this.state.linhaDoExtratoSelecionada,
                        prestacaoDeContasRecuperada: this.state.prestacaoDeContasRecuperada
                    };
                    this.props.history.push({ pathname: ROTAS_CAMINHOS.PRESTAR_CONTAS, state: state });
                }}
            />
        );
    }

    render() {
        const { contaSelecionada } = this.props;
        const tipoDeAcesso = TIPO_DE_ACESSO.SELECIONADO();
        const { botaoExibirMaisRegistros, erros, exibirLoadingConta, exibirLoadingExtrato, linhasDoExtrato, ultimaChave } = this.props.extrato;
        const { exibirLoadingPrestacao, prestacaoDeContasRecuperada } = this.state;
        const tituloExtrato = LABEL_COMUNS.EXTRATO_TITULO.DESCRICAO_CONTA_SELECIONADA(contaSelecionada.descricao);
        const tipoPrestacaoDeContas = TIPO_PRESTACAO_CONTAS.DESCRICAO_CONTA_SELECIONADA(contaSelecionada.descricao);
        const exibirLinkPrestacaoDeContas = ((contaSelecionada && contaSelecionada.descricao !== DESCRICAO_CONTA.CONTA_MOVIMENTO) && tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA);
        const mensagemConfirmacao = prestacaoDeContasRecuperada && <div dangerouslySetInnerHTML={{ __html: MENSAGENS.PESSOA.PRESTACAO_DE_CONTAS.CONFIRMACAO_COM_VALIDACAO(prestacaoDeContasRecuperada.status) }} />;
        return (
            <React.Fragment>
                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmaPrestacaoConta}
                    name="exibirModalConfirmaPrestacaoConta"
                    header={LABEL_COMUNS.PRESTACAO_DE_CONTAS}
                    body={mensagemConfirmacao}
                    footer={this.rodapeModalConfirmacao()} />

                <Grid className="12 12 12 12">

                    <DetalheExtrato
                        header={LABEL_COMUNS.DETALHES_DA_TRANSACAO(contaSelecionada.descricao)}
                        onClose={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE)}
                        exibirModal={this.state.exibirModalDetalhe}
                        linhaDoExtrato={this.props.extrato.detalhesTransacaoSelecionada} />

                    <DetalheExtratoTransacoes
                        header={LABEL_COMUNS.DETALHES_DA_TRANSACAO(contaSelecionada.descricao)}
                        bsSize="lg"
                        onClose={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE_TRANSACAO)}
                        exibirModal={this.state.exibirModalDetalheTransacao}
                        linhaDoExtrato={this.props.extrato.linhaDoExtratoSelecionada}
                        setDetalhesTransacaoSelecionada={transacao => this.props.setDetalhesTransacaoSelecionada(transacao)}
                        setExibiModalDetalhes={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE)}
                    />

                    <If test={exibirLoadingConta || exibirLoadingPrestacao}>
                        <Grid className="alinhamento-centro">
                            <LoadingFenix />
                        </Grid>
                    </If>
                    <ContaSelecionada
                        contaSelecionada={contaSelecionada}
                        mostrarFiltroDeDatas={true}
                        tipoDeConta={this.props.tipoDeConta}

                        dataInicialLabel={LABEL_COMUNS.DATA_INICIAL}
                        dataInicialValue={this.state.dataInicial}
                        dataIncialChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_INICIAL)}
                        dataInicialErro={this.state.dataInicialErro}

                        dataFinalLabel={LABEL_COMUNS.DATA_FINAL}
                        dataFinalValue={this.state.dataFinal}
                        dataFinalChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_FINAL)}
                        dataFinalErro={this.state.dataFinalErro}
                        filtrar={() => this.props.getExtrato(contaSelecionada, this.state.dataInicial, this.state.dataFinal)}
                        filtrarCSV={() => this.props.getExtratoCSV(contaSelecionada, this.state.dataInicial, this.state.dataFinal)}

                        exportarCsv={true}
                        listaParaSerconvertidaEmCSV={montaRespostaExportarExtratoCSV(this.props.extrato.linhasDoExtratoCSV || this.props.extrato.linhasDoExtrato)}
                        nomeDoArquivoCsv={LABEL_COMUNS.ARQUIVO.NOME.EXTRATO_CSV.DESCRICAO_CONTA_SELECIONADA(contaSelecionada.descricao)}
                    />
                    <Grid className="row">
                        <If test={erros && erros.periodoExcedidoErro}>
                            <Grid cols="11 11 11 11" className="alinhamento-esquerda">
                                <Alert bsStyle={erros && !erros.periodoExcedidoErro ? STATUS_VALIDACAO_COMPONENTES.SUCESSO : STATUS_VALIDACAO_COMPONENTES.PERIGO}>
                                    {erros && erros.periodoExcedidoErro}
                                </Alert>
                            </Grid >
                        </If>
                    </Grid>
                    <GridExtrato
                        contaSelecionada={contaSelecionada}
                        history={this.props.history}
                        titulo={tituloExtrato}
                        exibirLoadingPrestacao={() => this.setState(state => ({ exibirLoadingPrestacao: !state.exibirLoadingPrestacao }))}
                        exibirModalConfirmaPrestacaoConta={() => this.setState(state => ({ exibirModalConfirmaPrestacaoConta: !state.exibirModalConfirmaPrestacaoConta }))}
                        tipoPrestacaoDeContas={tipoPrestacaoDeContas}
                        linhasDoExtrato={linhasDoExtrato}
                        setLinhaDoExtratoSelecionada={this.setLinhaDoExtratoSelecionada}
                        setPrestacaoDeContasRecuperada={this.setPrestacaoDeContasRecuperada}
                        exibirLoadingExtrato={exibirLoadingExtrato}
                        exibirMaisRegistros={botaoExibirMaisRegistros}
                        exibirLinkPrestacaoDeContas={exibirLinkPrestacaoDeContas}
                        setDetalhesTransacaoSelecionada={transacao => this.props.setDetalhesTransacaoSelecionada(transacao)}
                        setExbirModalDetalhesTransacao={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE_TRANSACAO)}
                        setExibirModalDetalhes={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE)}
                        buscarMaisRegistros={() => this.props.getExtrato(contaSelecionada, this.state.dataInicial, this.state.dataFinal, ultimaChave, undefined, linhasDoExtrato)}
                        setExibirModalComprovante={() => this.props.setExibirModalComprovante(true)}
                    />
                    <br />
                    <Grid cols="11 11 11 11" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={() => {
                            this.props.history.goBack();
                        }}>{LABEL_COMUNS.VOLTAR}</Button>
                        <br /><br />
                    </Grid>
                </Grid >
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    extrato: { ...state.ExtratoReducer, cartaoSelecionado: state.CartoesReducer.cartaoSelecionado }
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Extrato);