import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import Grid from '../componentes-uteis/grid/grid';
import { CONTA_SELECIONADA, LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';
import PagamentoContas from '../componentes-uteis/pagamento/pagamento-contas';

class Pagamento extends Component {
    render() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        return (
            <div>
                <PagamentoContas contaSelecionada={contaSelecionada} mostrarSaldoBloqueado />
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <a href={ROTAS_CAMINHOS.DASHBOARD_CONTA}><Button type="button" bsStyle="primary">{LABEL_COMUNS.VOLTAR}</Button></a>
                    <br /><br />
                </Grid>
            </div>
        );
    }
}

export default Pagamento;
