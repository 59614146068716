import axios from 'axios';
import { HEADER_API, URL_GET_SALDO_CONTA } from '../../../servicos/url-servicos';
import { METODOS } from './mostrar-saldo-constantes';

export function buscarSaldoConta(contaId) {
    return function(dispatch) {
        axios.get(URL_GET_SALDO_CONTA(contaId), HEADER_API())
            .then((response) => {
                dispatch(
                    {
                        type: METODOS.BUSCAR_SALDO_CONTA,
                        payload: response.data
                    }
                );
            }).catch((err) => {
                dispatch(
                    {
                        type: METODOS.BUSCAR_SALDO_CONTA_ERRO,
                        payload: err
                    }
                );
            });
    };
}

export function setMostrarSaldo(value) {
    return {
        type: METODOS.SET_MOSTRAR_SALDO,
        payload: value
    };
}

export function setAtualizandoSaldo() {
    return {
        type: METODOS.SET_ATUALIZANDO_SALDO
    };
}
