import './pedido-carga.css';
import React, { Component } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { Button, FormControl, FormGroup, Modal, Panel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ContaSelecionada from '../../componentes/contas/conta-selecionada';
import { LABELS } from './pedido-carga-constantes';
import Select from 'react-select';
import { DESCRICAO_CONTA, LABEL_COMUNS, TIPO_DE_PESSOA } from '../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import Grid from '../componentes-uteis/grid/grid';
import { CPF, CNPJ } from 'cpf_cnpj';

const TRANSFERENCIA_SELECIONADA = 'transferenciaSelecionadaId';

class PedidoCarga extends Component {
    constructor(props) {
        super(props);
        this.state = {
            voltar: false
        };
    }
    componentDidMount() {
        reactLocalStorage.setObject(TRANSFERENCIA_SELECIONADA, null);
    }

    /*
    O Código abaixo é a antiga tela de recarga que exibe os botões como transferencia de itau->itau, bradesco->bradesco e TED
    render() {
        const contaSelecionada = reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA);
        return (
            <div>
                <ContaSelecionada contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 espacamento_topo">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABELS.DESEJA_CARREGAR}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row form-group">
                                            <div className="row">
                                                <div className="col-sm-9 col-md-9 col-lg-9 alinhamento-centro">
                                                    {
                                                        LABELS.TRANSFERENCIA.map((tranferencia, idx) => {
                                                            return (
                                                                <p key={idx}><Button value={tranferencia.value} className="botao_transferencias" onClick={(event) => this.mostrarSolicitacaoCarga(event)}>{tranferencia.label}</Button></p>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" />
                </div>
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <a href={ROTAS_CAMINHOS.DASHBOARD_CONTA}><Button type="button" bsStyle="primary">{LABEL_COMUNS.VOLTAR}</Button></a>
                    <br /><br />
                </Grid>
            </div>
        )
    }*/
    render() {
        if (this.state.voltar) {
            return (<Redirect to={ROTAS_CAMINHOS.DASHBOARD_CONTA} />);
        }
        const contaSelecionada = reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA);
        const { dadosPessoa } = contaSelecionada;
        return (
            <div>
                <ContaSelecionada contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 espacamento_topo">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABELS.DESEJA_CARREGAR_DIALOG}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <p><strong>A partir de agora a BPP recebe TED direto em sua conta digital!</strong></p>
                                    <br />
                                    <p><strong>Para realizar depósitos em sua conta BPP, faça uma transferência de sua conta bancária para:</strong></p>
                                    <br />
                                    <p><strong>Banco 301 - BPP Instituição de Pagamento S.A</strong></p>
                                    <p><strong>Agência:</strong> {contaSelecionada && contaSelecionada.agencia && contaSelecionada.agencia}</p>
                                    <p><strong>Conta:</strong> {contaSelecionada && contaSelecionada.numero && contaSelecionada.numero}-{contaSelecionada && contaSelecionada.digito}</p>
                                    {this.montarBody(dadosPessoa)}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" />
                </div>
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button type="button" bsStyle="primary" onClick={() => {
                        this.setState({
                            voltar: true
                        });
                    }}>{LABEL_COMUNS.VOLTAR}</Button>
                    <br /><br />
                </Grid>
            </div>
        );
    }

    montarBody = (dadosPessoa) => {

        if (dadosPessoa && dadosPessoa !== null && dadosPessoa.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
            let numeroCnpj = dadosPessoa.documento && dadosPessoa.documento[0].numero;//CNPJ
            return (
                <div>
                    <p><strong>CNPJ:</strong> {CNPJ.format(numeroCnpj)}</p>
                    <p><strong>Razão Social:</strong> {dadosPessoa.pessoaJuridica.razaoSocial}</p>
                </div>
            );
        } else {
            let numeroCpf = dadosPessoa && dadosPessoa.documento && dadosPessoa.documento[0].numero;
            return (
                <div>
                    <p><strong>CPF:</strong> {CPF.format(numeroCpf)}</p>
                    <p><strong>Nome:</strong> {dadosPessoa && dadosPessoa.pessoaFisica && dadosPessoa.pessoaFisica.nomeCompleto}</p>
                </div>
            );
        }
    }
    mostrarSolicitacaoCarga(event) {
        reactLocalStorage.setObject(TRANSFERENCIA_SELECIONADA, event.target.value);
        return (<Redirect to={ROTAS_CAMINHOS.PEDIDO_DE_CARGA_SOLICITACAO} />);
    }
}
export default PedidoCarga;
