import React from 'react';
import { CAMPOS, TIPO_DE_MOVIMENTACAO, OPERACAO, LABEL_COMUNS } from '../contantes-comuns/constantes-comuns';
import { formataMoedaComSimboloESinalNegacao } from '../numeros/numeroUtil';
import { formatarDataCompletaBR, formatarDataUS, parseDate, parseDateTime, setHourAndMinutesAndSeconds } from '../data/dataUtil';
import moment from 'moment';

export const montaRespostaExtratoConta = (linhasExtratoNaTela, responseBackEnd) => {
    let linhasExtratoAux = [];
    let extrato = {
        ultimaChave: responseBackEnd.ultimaChave,
        quantidadeTotalRegistros: responseBackEnd.quantidadeTotalRegistros,
        resultado: linhasExtratoNaTela || []
    };

    responseBackEnd.resultado = responseBackEnd.resultado.sort(ordenarDataDecrescente);

    responseBackEnd.resultado.map((diaDoMes) => {
        diaDoMes.lancamentos.map((lancamento) => {
            linhasExtratoAux.push(lancamento);
        });
        if (diaDoMes.lancamentos.length > 0) {
            linhasExtratoAux.push(montaSaldoDoDia(diaDoMes));
        }
    });

    let resultadoPrev = filtrarLista(extrato.resultado, linhasExtratoAux);
    let result = resultadoPrev.sort((a, b) => moment(b.data || b.dataHora).diff(moment(a.data || a.dataHora)));

    if (result.length > 0) {
        extrato.resultado = result;
    } else {
        extrato.resultado = linhasExtratoAux;
    }
    return extrato;
};

export const montarRespostaExtratoContaCartao = (listaAtual = [], response, tamanhoPagina = 50, ultimaChave) => {
    let newArray = [...listaAtual];

    let count = 0;

    let foundTheKey = false;

    for (let i = 0; i < response.resultado.length; i++) {
        const extrato = response.resultado[i];

        if (count === tamanhoPagina) {
            break;
        }

        for (let j = 0; j < extrato.lancamentos.length; j++) {
            const lancamento = extrato.lancamentos[j];

            if (ultimaChave === undefined) {
                ultimaChave = lancamento.bppMovimentoProcessadoraId;
            }

            if (lancamento.bppMovimentoProcessadoraId === ultimaChave) {
                foundTheKey = true;
            }

            if (foundTheKey) {
                if (count < tamanhoPagina) {
                    newArray.push(lancamento);
                    count++;
                }

                ultimaChave = lancamento.bppMovimentoProcessadoraId;
            }
        }
    }

    // criando chave para tornar lista unica.
    const auxArray = newArray.map(a => `${a.bppMovimentoProcessadoraId}-${a.nome}`);

    // busca o item no array por bppMovimentoProcessadoraId e nome.
    const findInArray = (item) => {
        return newArray.find((obj) => {
            const [id, nome] = item.split('-');
            return obj.bppMovimentoProcessadoraId === id && obj.nome === nome;
        });
    };

    // passo realizado para não inserir nenhum registro duplicado.
    newArray = Array.from(new Set(auxArray)).map(item => ({ ...findInArray(item) }));

    return {
        resultado: newArray,
        ultimaChave: ultimaChave,
        quantidadeTotalRegistros: newArray.length
    };
};

export const ordenarExtratoPorDataDecrescente = ({ resultado, ...rest }) => {
    resultado.sort((a, b) => moment(b.data || b.dataHora).diff(moment(a.data || a.dataHora)));
    return { ...rest, resultado };
};

export const oredenaLancamentosExtratoDashboard = ({ resultado, ...rest }) => {
    resultado.map(result => result.lancamentos.sort((a, b) => moment(b.dataHora).diff(moment(a.dataHora))));
    return { ...rest, resultado };
};

export const montaRespostaExportarExtratoCSV = (respostaExtrato) => {
    let extratoProntoParaExportarCSV = [];
    if (respostaExtrato) {
        respostaExtrato.map((item, idx) => {
            if (item.saldoFinalDoDia) {
                extratoProntoParaExportarCSV.push({
                    dataHora: item.nomeExibicao.concat(' ').concat(formataMoedaComSimboloESinalNegacao(item.valor, item.tipo, item.moeda)),
                    nome: '',
                    detalhe: '',
                    descricao: '',
                    moeda: '',
                    valor: '',
                    tipo: ''
                });
            } else {
                extratoProntoParaExportarCSV.push({
                    dataHora: formatarDataCompletaBR(item.dataHora),
                    nome: item.nome,
                    detalhe: item.descricao ? item.descricao : '',
                    descricao: item.tagCliente ? item.tagCliente : '',
                    moeda: item.moeda,
                    valor: item.valor,
                    tipo: item.tipo
                });
            }
        });
    }
    return extratoProntoParaExportarCSV;
};

export const retornaErrosExtrato = (erros) => {
    let mensagensDeErros = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case CAMPOS.DATA_INICIAL:
                mensagensDeErros.dataInicialErro = erros[i].mensagem;
                break;
            case CAMPOS.DATA_FINAL:
                mensagensDeErros.dataFinalErro = erros[i].mensagem;
                break;
            case CAMPOS.PERIODO:
                mensagensDeErros.periodoExcedidoErro = erros[i].mensagem;
                break;
            default:
                mensagensDeErros.erroGenerico = erros[i].mensagem;
                break;
        }
    }
    return mensagensDeErros;
};

export const formatarCampoValor = (tipo, valor, moeda) => (
    isNaN(valor)
        ? <span className="texto-extrato-preto-zero">
            {formataMoedaComSimboloESinalNegacao(0, tipo, moeda)}
        </span>
        : <span className={numeroIgualZero(valor) ? 'texto-extrato-preto-zero' : formatarCorTexto(tipo)}>
            {formataMoedaComSimboloESinalNegacao(valor, tipo, moeda)}
        </span>
);

export function formataSaldoDiaExtratoDashboard(tipo, valor) {
    return (
        <span className={numeroIgualZero(valor) ? 'texto-extrato-preto-zero' : formatarCorTextoPorValor(valor)}>
            {formataMoedaComSimboloESinalNegacao(valor, tipo, 'BRL')}
      &nbsp; &nbsp;
        </span>
    );
}

export const formataCorDeSetasDosExtrato = (tipoDeMovimentacao) => {
    return (tipoDeMovimentacao === TIPO_DE_MOVIMENTACAO.DEBITO)
        ? 'glyphicon glyphicon glyphicon glyphicon-arrow-down texto-extrato-vermelho'
        : 'glyphicon glyphicon glyphicon glyphicon-arrow-up texto-extrato-verde';
};

function formatarCorTexto(tipoMovimentacao) {
    return (tipoMovimentacao === TIPO_DE_MOVIMENTACAO.DEBITO) ? 'texto-extrato-vermelho' : 'texto-extrato-verde';
}

export const formatarCorTextoPorValor = (valor) => (
    valor < 0 ? 'texto-extrato-vermelho' : 'texto-extrato-verde'
);

export function exibeTransacaoValor(linhaDoExtrato) {
    return (linhaDoExtrato.integracaoTransacaoMoeda && linhaDoExtrato.moeda !== linhaDoExtrato.integracaoTransacaoMoeda);
}

export const retornaNomeDasOperacoesBppPag = (nomeDaOperacao) => {
    const NOME_DA_OPERACAO_TRADUZIDO = {
        CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG: 'Carga Cartão em Lote',
        TED_PAG: 'TED em Lote',
        TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_PAG: 'Transferência em Lote'
    };
    switch (nomeDaOperacao) {
        case OPERACAO.NOME.CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG:
            return NOME_DA_OPERACAO_TRADUZIDO.CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG;
        case OPERACAO.NOME.TED_PAG:
            return NOME_DA_OPERACAO_TRADUZIDO.TED_PAG;
        default:
            return NOME_DA_OPERACAO_TRADUZIDO.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_PAG;
    }
};

const ordenarExtratoContaPorDataDecrescente = ({ resultado, ...rest }) => {
    resultado.sort((a, b) => moment(b.dataHora).diff(moment(a.dataHora)));
    return { ...rest, resultado };
};

const montaSaldoDoDia = (diaDoMes) => {
    const tipo = diaDoMes.saldoDia < 0 ? TIPO_DE_MOVIMENTACAO.DEBITO : TIPO_DE_MOVIMENTACAO.CREDITO;
    return { dataHora: moment(diaDoMes.data), saldoFinalDoDia: true, nomeExibicao: LABEL_COMUNS.SALDO_FINAL_DO_DIA.concat(':'), tipo, moeda: diaDoMes.moeda, valor: diaDoMes.saldoDia };
};

function numeroIgualZero(numero) {
    if (!isNaN(numero) && numero === 0) {
        return true;
    }
    return false;
}

const filtrarLista = (newList, oldList) => { // filtro para evitar registros duplicados na lista
    if (newList === null || newList === undefined) return;
    if (oldList === null || oldList === undefined) return;
    let auxList = [];
    if (Array.isArray(newList)) {
        newList.forEach(key => {
            if (Array.isArray(oldList)) {
                let objectFind = oldList.filter(
                    value => key.id === value.id &&
            key.nome === value.nome &&
            compararData(key, value) === true &&
            key.nomeExibicao === value.nomeExibicao &&
            key.valor === value.valor);
                if (objectFind.length === 0) { // se nao encontrou nada então a key é um registro novo
                    auxList.push(key);
                }
            }
        });
    }
    if (auxList.length > 0) {
        auxList.forEach(newKey => {
            oldList.push(newKey);
        });
    }
    return oldList;
    // return oldList.sort(ordenarPorDataCrescente);
};

const compararData = (a, b) => {
    let dataA = moment(a.dataHora);
    let dataB = moment(b.dataHora);
    let result = moment.duration(dataA.diff(dataB, 'days'));
    return result.asHours() === 0;
};

const ordenarPorDataCrescente = (a, b) => {
    let dataA = new Date(a.dataHora);
    let dataB = new Date(b.dataHora);
    return dataA < dataB ? -1 : 0;
};
const ordenarDataDecrescente = (a, b) => {
    let dataA = new Date(a.data);
    let dataB = new Date(b.data);
    return dataA < dataB ? 0 : -1;
};
