import React, { Component } from 'react';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { LABEL_COMUNS, MENSAGENS, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import { FormControl, FormGroup } from 'react-bootstrap';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { STATUS_VALIDACAO_COMPONENTES } from 'componentes/util/estilo_para_componentes/estiloUtil';
import ShowLineError from 'componentes/comuns/showLineError/show-line-error';
import { activateCard } from 'servicos/cartoes/cartoes-service';
import Token from 'componentes/comuns/token/token';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import If from 'componentes/componentes-uteis/if/if';

class AtivarCartao extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            cardId: '', canApprove: {}, selectedCard: {}, destinyAccountId: '', needToken: false, originalContractualAttachmentId: '',
            message: '', operationId: '', password: '', passwordConfirm: '', passwordErro: '', passwordConfirmErro: '',
            showActivateLoading: false, showActivateFinalizationModal: false, showActivateModalToken: false
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillReceiveProps = (nextProps) => {
        const { selectedCard, grantProfile } = nextProps;
        const destinyAccountId = selectedCard.cartaoId;
        const cardId = selectedCard.idCartaoImpresso;
        const originalContractualAttachmentId = selectedCard.anexoContratualOriginal;
        const needToken = nextProps.needToken && nextProps.needToken.aprovacao;
        const canApprove = grantProfile.podeAprovar;
        this.setState({ cardId, canApprove, selectedCard, destinyAccountId, originalContractualAttachmentId, needToken });
    };

    setStateClear = () => {
        this.setState(this.INITIAL_STATE);
    };

    setShowActivateFinalizationModal = (showActivateFinalizationModal) => {
        this.setState({ showActivateFinalizationModal });
    };

    setShowActivateModalToken = (showActivateModalToken) => {
        this.setState({ showActivateModalToken });
    };

    setMessage = (message) => {
        this.setState({ message });
    };

    setShowActivateLoading = (showActivateLoading) => {
        this.setState({ showActivateLoading });
    };

    solicitarAtivarCartao = async () => {
        const { needToken, canApprove } = this.state;
        let message = '';
        this.props.setShowActivateCardModal(false);
        this.setMessage('');
        this.setShowActivateLoading(true);
        const response = await activateCard(this.state);
        if (response.error) {
            const { data: { erros } } = response;
            message = erros[0].mensagem;
        } else {
            const { operationId } = response;
            if (operationId && needToken && (canApprove)) {
                this.setOperationId(operationId);
                this.setShowActivateModalToken(true);
            }
            else {
                message = response.message;
            }
        }
        this.setShowActivateLoading(false);
        if (message) {
            this.setMessageFinalization(message);
            this.setShowActivateFinalizationModal(true);
        }

    };

    setOperationId = (operationId) => {
        this.setState({ operationId });
    }

    validForm = () => {
        const { password, passwordConfirm } = this.state;

        let passwordErro = undefined;
        let passwordConfirmErro = undefined;

        if (!password) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!passwordErro && (password.length < 4 || password.length > 4)) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.SENHA_INVALIDA;
        }
        if (!passwordConfirm) {
            passwordConfirmErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!passwordConfirmErro && (passwordConfirm.length < 4 || passwordConfirm.length > 4)) {
            passwordConfirmErro = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;
        }
        if ((password && passwordConfirm) && (password !== passwordConfirm)) {
            passwordConfirmErro = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;

        }
        if (passwordErro || passwordConfirmErro) {
            if (passwordErro) {
                this.setState({ passwordErro });
            }
            if (passwordConfirmErro) {
                this.setState({ passwordConfirmErro });
            }
        } else {
            this.solicitarAtivarCartao();
        }
    };

    changeOnlyNumbersPassword(field, fieldName) {
        const re = /^[0-9\b]+$/;
        if (field === '' || re.test(field)) {
            this.setState({
                [fieldName]: field,
                [fieldName + 'Erro']: '',
            });
        }
    }

    setPropsCampos = (field, nameField) => {
        let valorDoCampo = field != null && field.value || field != null && field.target != null && field.target.value;
        this.setState({
            [nameField]: valorDoCampo,
            [nameField + 'Erro']: '',
        });
    };

    returnBodyModal = () => {
        const { password, passwordConfirm, passwordErro, passwordConfirmErro, cardId } = this.state;
        return (
            <div className="panel-body">
                <div className="row">
                    <Grid cols="12 12 12 12" className="alinhamento-centro">
                        <span>
                            <div dangerouslySetInnerHTML={{ __html: MENSAGENS.CARTAO.ATIVAR_CARTAO.CONFIRMACAO(cardId) }} />
                        </span>
                    </Grid>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="row">
                    <Grid cols="3 3 3 3" />
                    <Grid cols="6 6 6 6" className="alinhamento-centro">
                        <FormGroup validationState={passwordErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                id="password"
                                type="password"
                                className="mensagens-erro form-control changePassword"
                                placeholder={LABEL_COMUNS.INSERIR_SUA_SENHA}
                                value={password}
                                onChange={(e) => this.changeOnlyNumbersPassword(e.target.value, 'password')}
                                maxLength={4}
                            />
                            <ShowLineError error={passwordErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="3 3 3 3" />
                </div>
                <div className="row">
                    <Grid cols="3 3 3 3" />
                    <Grid cols="6 6 6 6" className="alinhamento-centro">
                        <FormGroup validationState={passwordConfirmErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                id="passwordConfirm"
                                type="password"
                                className="mensagens-erro form-control changePassword"
                                placeholder={LABEL_COMUNS.CONFIRME_SUA_SENHA}
                                value={passwordConfirm}
                                onChange={(e) => this.changeOnlyNumbersPassword(e.target.value, 'passwordConfirm')}
                                maxLength={4}
                            />
                            <ShowLineError error={passwordConfirmErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="3 3 3 3" />

                </div>
            </div>
        );
    };

    returnFooterModal = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.props.setShowActivateCardModal(false);
                    this.setStateClear();
                }}
                btn2Label={LABEL_COMUNS.CARTOES.TITULOS.ATIVAR_CARTAO}
                btn2OnClick={this.validForm}
            />
        );
    };

    returnFooterModalFinalization = () => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setShowActivateFinalizationModal(false);
                    this.setStateClear();
                    this.props.setShowActivateCardModal(false);
                }}
            />
        );
    };

    retornoDaEfetivacao = (retorno) => {
        this.setShowActivateModalToken(false);
        const { cardId } = this.state;
        let message = undefined;
        if (retorno.error) {
            message = MENSAGENS.CARTAO.ATIVAR_CARTAO.FINALIZACAO.ERRO(cardId);
        } else {
            message = MENSAGENS.CARTAO.ATIVAR_CARTAO.FINALIZACAO.SUCESSO(cardId);
            this.props.setShowActivateCardModal(false);
        }
        this.setMessageFinalization(message);
    };

    closeModal = () => {
        this.setShowActivateModalToken(false);
    };

    setMessageFinalization(message) {
        this.setMessage(message);
        this.setShowActivateFinalizationModal(true);

    }

    render() {
        const { operationId, showActivateModalToken, showActivateLoading } = this.state;

        return (
            <div>
                <If test={showActivateLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>
                <ModalComponent
                    header={LABEL_COMUNS.CARTOES.TITULOS.ATIVAR_CARTAO}
                    exibirModal={this.props.showActivateCardModal}
                    name="modal-ativar-cartao"
                    body={this.returnBodyModal()}
                    footer={this.returnFooterModal()}
                />

                <Token
                    titulo={LABEL_COMUNS.CARTOES.TITULOS.ATIVAR_CARTAO}
                    operacaoId={operationId}
                    dadosIntegracao={TIPO_DE_TRANSACAO.APROVACAO}
                    dadosOtp={{ canalToken: 'SMS', transacao: 'aprovacao' }}
                    fecharModal={this.closeModal}
                    resultadoCallback={this.retornoDaEfetivacao}
                    exibirModal={showActivateModalToken} />

                <ModalComponent
                    header={LABEL_COMUNS.CARTOES.TITULOS.ATIVAR_CARTAO}
                    exibirModal={this.state.showActivateFinalizationModal}
                    name="modal-ativar-cartao"
                    body={<div dangerouslySetInnerHTML={{ __html: this.state.message }} />}
                    footer={this.returnFooterModalFinalization()}
                />

            </div>
        );
    }
}

export default AtivarCartao;
