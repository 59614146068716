import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { HEADER_API, URL_ARQUIVOS, URL_ARQUIVOS_CARGAS_POST, URL_ARQUIVOS_CARGA_LOTE_ID, URL_ARQUIVOS_CARGAS_DADOSCONTA } from '../../../servicos/url-servicos';
import { CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX } from './novo-carga-lote-controlador';
import Controle from './novo-carga-lote-controlador';
import { environment } from '../../../configuracoes/environment';
import { MENSAGENS } from './novo-carga-lote-constante';

const API = environment.urlAPI;

export default class NovoCargaLoteAction {
    mudarArquivo(arquivo) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[0] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: arquivo
                }
            );
            dispatch({
                type: Controle.getStatusCarregarArquivoControl(),
                payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.SUCESSO
            });
        };
    }
    obterUrlPreAssinada() {
        return function(dispatch) {
            Axios
                .post(URL_ARQUIVOS(), { bucket: environment.arquivosBancariosBucket }, HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[1] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }
    putArquivosDocumentosEmS3(url, file) {
        const HEADER = {
            headers: {
                'Content-type': 'application/octet-stream'
            }
        };
        return function(dispatch) {
            Axios
                .put(url, file, HEADER)
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[3] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                    dispatch({
                        type: Controle.getStatusCarregarArquivoControl(),
                        payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.SUCESSO
                    });
                })
                .catch((err) => {
                    let erro = '';
                    if (err.response) {
                        erro = err;
                    } else if (!err.status) {
                        erro = MENSAGENS.ERRO_CARREGAR_ARQUIVO;
                    }
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: erro
                        }
                    );
                    dispatch({
                        type: Controle.getStatusCarregarArquivoControl(),
                        payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
                    });
                });
        };
    }
    postConteudoArquivoCargaDois(idArquivo, nomeArquivoParam, usuarioParam, contaId) {
        var mapaDeTitulares = new Map();
        return async function(dispatch) {
            var resposta;
            let mensagem = '';
            try {
                resposta = await Axios.post(URL_ARQUIVOS_CARGAS_POST(),
                    { idArquivoS3: idArquivo, nomeArquivo: nomeArquivoParam, usuario: usuarioParam, tipoArquivoDeCarga: 'contaMovimento', contaId: contaId },
                    HEADER_API()).catch((error) => {
                    mensagem = error.response.data.erros[0].mensagem;
                    throw new Error();
                });
            } catch (error) {
                dispatch(
                    {
                        type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                        payload: mensagem
                    }
                );
                dispatch({
                    type: Controle.getStatusCarregarArquivoControl(),
                    payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
                });
                return;
            }
            var promisesTitular = [];
            if (resposta.status === 200) {
                resposta.data.detalhes.map((registro) => {
                    if (mapaDeTitulares.get(parseInt(registro.contaOrigem, 10).toString()) != '') {
                        promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaOrigem), HEADER_API()));
                        mapaDeTitulares.set(parseInt(registro.contaOrigem, 10).toString(), '');
                    }
                    if (mapaDeTitulares.get(parseInt(registro.contaDestino, 10).toString()) != '') {
                        promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaDestino), HEADER_API()));
                        mapaDeTitulares.set(parseInt(registro.contaDestino, 10).toString(), '');
                    }
                });
            } else {
                return function(dispatch) {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: MENSAGENS.ERRO_FORMATO_ARQUIVO
                        }
                    );
                };
            }
            var listaTitulares = await Promise.all(promisesTitular.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitulares.length; i++) {
                if (listaTitulares[i]) {
                    mapaDeTitulares.set(parseInt(listaTitulares[i].data['numeroConta'], 10).toString(), listaTitulares[i].data['nomeTitular']);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularContaOrigem = mapaDeTitulares.get(parseInt(detalhe.contaOrigem).toString());
                detalhe.titularContaDestino = mapaDeTitulares.get(parseInt(detalhe.contaDestino).toString());
            });
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: resposta.data
                }
            );
        };
    }
    procurarTitular(numeroConta) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_POST(numeroConta), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[5] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                }).catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                    dispatch({
                        type: Controle.getStatusCarregarArquivoControl(),
                        payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
                    });
                });
        };
    }
    limparTitular(numeroConta) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[6] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: undefined
                }
            );
        };
    }
    gravarArquivo(idArquivo, rotuloArquivoCargaLote) {
        return function(dispatch) {
            Axios
                .put(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), {
                    arquivoId: idArquivo,
                    importar: false,
                    gravar: true,
                    rotulo: rotuloArquivoCargaLote
                },
                HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[7] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                    dispatch({
                        type: Controle.getStatusCarregarArquivoControl(),
                        payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
                    });
                });
        };
    }
    gravarProcessarArquivo(idArquivo, rotuloArquivoCargaLote) {
        return function(dispatch) {
            Axios
                .put(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), {
                    arquivoId: idArquivo,
                    importar: true,
                    token: reactLocalStorage.get('token'),
                    rotulo: rotuloArquivoCargaLote
                },
                HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[8] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                    dispatch({
                        type: Controle.getStatusCarregarArquivoControl(),
                        payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
                    });
                });
        };
    }
    setOcultarErro() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[9] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: undefined
                }
            );
        };
    }
    criarErroArquivoNaoSelecionado(valor) {
        if (valor === MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE) {
            return function(dispatch) {
                dispatch(
                    {
                        type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                        payload: MENSAGENS.ERRO_FILE_INPUT_FALHA
                    }
                );
            };
        } else if (valor === MENSAGENS.STATUS_CARREGAR_ARQUIVO.SUCESSO) {
            /* return function (dispatch) {
                dispatch(
                    {
                        type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                        payload:MENSAGENS.ERRO_FILE_INPUT_VAZIO
                    }
                );
            } */
        }
    }
    criarErroExtensaoArquivo() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: MENSAGENS.ERRO_EXTENSAO_INVALIDA
                }
            );
            dispatch({
                type: Controle.getStatusCarregarArquivoControl(),
                payload: MENSAGENS.STATUS_CARREGAR_ARQUIVO.PENDENTE
            });
        };
    }
    obterDadosArquivo(idArquivo) {
        var mapaDeTitulares = new Map();
        return async function(dispatch) {
            var resposta = await Axios.get(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), HEADER_API()).catch((error) => { });
            var promisesTitular = [];
            resposta.data.detalhes.map((registro) => {
                if (mapaDeTitulares.get(parseInt(registro.contaOrigem, 10).toString()) != '') {
                    promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaOrigem), HEADER_API()));
                    mapaDeTitulares.set(parseInt(registro.contaOrigem, 10).toString(), '');
                }
                if (mapaDeTitulares.get(parseInt(registro.contaDestino, 10).toString()) != '') {
                    promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaDestino), HEADER_API()));
                    mapaDeTitulares.set(parseInt(registro.contaDestino, 10).toString(), '');
                }
            });
            var listaTitulares = await Promise.all(promisesTitular.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitulares.length; i++) {
                if (listaTitulares[i]) {
                    mapaDeTitulares.set(parseInt(listaTitulares[i].data['numeroConta'], 10).toString(), listaTitulares[i].data['nomeTitular']);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularContaOrigem = mapaDeTitulares.get(parseInt(detalhe.contaOrigem).toString());
                detalhe.titularContaDestino = mapaDeTitulares.get(parseInt(detalhe.contaDestino).toString());
            });
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: resposta.data,
                    rotulo: resposta.data.rotulo
                }
            );
        };
    }
    setarBuscandoTrue(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[12] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setarEstadoModalGravar(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[13] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setarEstadoModalGravarSucesso(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[14] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setarEstadoModalProcessar(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[15] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setarEstadoModalProcessarSucesso(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[16] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setarFinalizado(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[17] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }
    setRotuloArquivoCargaLoteAction(valor) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[18] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: valor
                }
            );
        };
    }
    limparAction(valor) {
        return function(dispatch) {
            dispatch(
                {
                    type: Controle.getLimparControl()
                }
            );
        };
    }
}
