import React from 'react';
import { LABEL_COMUNS, TIPO_ENTREGA, FORMA_ENTREGA, RETORNA_TIPO_ENTREGA_SELECIONADA, RETORNA_FORMA_ENTREGA_SELECIONADA } from '../../../util/contantes-comuns/constantes-comuns';
import PedidoLabelCartao from '../../../util/pedido/pedido-label-cartao';
import { formatarDataHoraBR } from '../../../util/data/dataUtil';
import If from '../../../componentes-uteis/if/if';
import { formataMoeda } from '../../../util/numeros/numeroUtil';

const Resumo = props => {
    return (
        <div>
            <div className="row">
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div className="panel panel-default">
                        <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.RESUMO_DO_PEDIDO} </b></div>
                        <div className="panel-body">
                            <div className="list-group">
                                <form className="editForm" id="myForm">
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="texto_resumo">Pedido de Impressão de {props.quantidadeCartoes} <PedidoLabelCartao quantidade={props.quantidadeCartoes} /></div>
                                            <br />
                                            <p className="texto_resumo">Data do Pedido: {formatarDataHoraBR(null)}</p>
                                            <p className="texto_resumo">Tipo de Entrega - {RETORNA_TIPO_ENTREGA_SELECIONADA(props.tipoEntregaSelecionada).label}/{RETORNA_FORMA_ENTREGA_SELECIONADA(props.formaEntregaSelecionada).label}</p>
                                            <If test={props.tipoEntregaSelecionada === 'lote' &&
                        props.formaEntregaSelecionada !== 'retiradaBPP'}>
                                                <p className="texto_resumo_dados_entrega">{props.logradouro}, {props.numero} - {props.bairro} - {props.localidadeRecuperada ? `${props.localidadeRecuperada.cidade}/${props.localidadeRecuperada.uf}` : ''}
                                                    <br />{props.cep} </p>
                                            </If>
                                            <If test={props.formaEntregaSelecionada === 'retiradaBPP'}>
                                                <span className="texto_resumo_dados_entregaBpp">{props.enderecoBPP}</span>
                                            </If>
                                            <br />
                                            <p className="texto_resumo">VALOR DE IMPRESSÃO:  R${formataMoeda(props.valorImpressao)}</p>
                                            <p className="texto_resumo">VALOR DE ENTREGA:  R${formataMoeda(props.valorEntrega)}</p>
                                            <br />
                                            <p className="texto_total alinhamento-esquerda">TOTAL DO PEDIDO: R${formataMoeda(props.valorPedido)}</p>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
            </div>
        </div>
    );
};

export default Resumo;
