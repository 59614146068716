import { reactLocalStorage } from 'reactjs-localstorage';
import { formatarDataUS } from '../data/dataUtil';
import { stringParaNumero } from '../formata-string';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';

export const COMPROVANTE_TIPO_OPERACAO = [
    { label: 'Efetivação', value: 'aprovacao' },
    { label: 'Agendamento', value: 'agendamento' },
    { label: 'Cancelamento', value: 'cancelamento' },
    { label: 'Efetivação', value: 'aprovacaoAgendamento' }
];

export const COMPROVANTE_OPERACOES = [
    { value: 'TED', label: 'TED' },
    {
        value: 'transferenciaEntreContasMovimento',
        label: 'Transferência entre contas BPP'
    },
    {
        value: 'transferenciaEntreContasMovimentoDiferentesTitularidades',
        label: 'Transferência entre contas BPP'
    },
    { value: 'pagamentoBoletoBancario', label: 'Pagamento de Contas' },
    { value: 'cargaCartao', label: 'Carga Cartão' }
];

export const COMPROVANTE_OPERACOES_FILTRO = [
    { value: 'TED', label: 'TED' },
    {
        value: 'transferenciaEntreContasMovimento',
        label: 'Transferência entre contas BPP'
    },
    { value: 'pagamentoBoletoBancario', label: 'Pagamento de Contas' },
    { value: 'cargaCartao', label: 'Carga Cartão' }
];

export const NOME_MESES = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro'
];

export const COMPROVANTE_CANAIS = [
    { value: 'BPP_DIGITAL', label: 'BPP Digital' },
    { value: 'BPP_FENIX', label: 'BPP Web' }
];

export const ACAO = {
    TRANSFERENCIA_EM_LOTE_ARQUIVO: {
        APROVAR: { label: 'Aprovar', value: 'aprovar' },
        CORRIGIR: { label: 'Corrigir', value: 'corrigir' },
        CANCELAR: { label: 'Cancelar', value: 'cancelar' },
        REPROVAR: { label: 'Reprovar', value: 'reprovar' }
    }
};

export const BANCO = { BPP: { label: 'BPP', value: 'BPP' } };

export const CAMPOS = {
    ACAO_ARMAZENADA: 'acaoArmazenada',
    AGENCIA: 'agencia',
    AGENCIA_DEFAULT_VALUE: '0001',
    AGENCIA_SELECIONADA: 'agenciaSelecionada',
    ANEXO_CONTRATUAL_ID: 'anexoContratualId',
    ANEXO_CONTRATUAL_ORIGINAL_ID: 'anexoContratualOriginalId',
    ANEXO_SELECIONADO_ID: 'anexoSelecionadoId',
    APELIDO: 'apelido',
    ARQUIVO_SELECIONADO: 'arquivoSelecionado',
    AOS_CUIDADOS: 'aosCuidados',
    BAIRRO: 'bairro',
    BANCO: 'banco',
    BANCO_SELECIONADO_ID: 'bancoSelecionadoId',
    CELULAR: 'celular',
    CELULAR_PORTADOR: 'celularPortador',
    CELULAR_TITULAR: 'celularTitular',
    CEP: 'cep',
    CIDADE_SELECIONADA_ID: 'cidadeSelecionadaId',
    CLASSIFICACAO: 'classificacao',
    CLASSIFICACAO_SELECIONADA: 'classificacaoSelecionada',
    COMBO_FEL_ID_SELECIONADO: 'comboFelIdSelecionado',
    COMPLEMENTO: 'complemento',
    CONTA: 'conta',
    CONTA_FAVORECIDO_SELECIONADA: 'contaFavorecidoSelecionada',
    CONTA_SELECIONADA: 'contaSelecionada',
    DADOS_ARQUIVO_SELECIONADO: 'dadosArquivoSelecionado',
    DATA_AGENDAMENTO: 'dataAgendamento',
    DATA_CRIACAO_ATE: 'dataCriacaoAte',
    DATA_CRIACAO_DE: 'dataCriacaoDe',
    DATA_CRIACAO_INICIAL: 'dataCriacaoInicial',
    DATA_CRIACAO_FINAL: 'dataCriacaoFinal',
    DATA_DE_INCLUSAO_DE: 'dataDeInclusaoDe',
    DATA_DE_INCLUSAO_ATE: 'dataDeInclusaoAte',
    DATA_INICIAL: 'dataInicial',
    DATA_FINAL: 'dataFinal',
    DATA_NASCIMENTO: 'dataNascimento',
    DATA_NASCIMENTO_PORTADOR: 'dataNascimentoPortador',
    DATA_NASCIMENTO_TITULAR: 'dataNascimentoTitular',
    DATA_PAGAMENTO_ATE: 'dataPagamentoAte',
    DATA_PAGAMENTO_DE: 'dataPagamentoDe',
    DATA_PROCESSAMENTO_INICIAL: 'dataProcessamentoInicial',
    DATA_PROCESSAMENTO_FINAL: 'dataProcessamentoFinal',
    DDD_CELULAR_PORTADOR: 'dddCelularPortador',
    DDI_CELULAR_PORTADOR: 'ddiCelularPortador',
    DESCRICAO_SOLICITACAO: 'descricaoSolicitacao',
    DESTINATARIO: 'destinatario',
    DIGITO: 'digito',
    DIGITO_SELECIONADO: 'digitoSelecionado',
    DOCUMENTO_PORTADOR_ID: 'idDocumentoPortador',
    EMAIL: 'email',
    EMAIL_PORTADOR: 'emailPortador',
    EMAIL_SELECIONADO: 'emailSelecionado',
    ERROS: {
        AGENCIA_ERRO: 'agenciaErro',
        BANCO_SELECIONADO_ID_ERRO: 'bancoSelecionadoIdErro',
        CELULAR_ERRO: 'celularErro',
        CONTA_ERRO: 'contaErro',
        DATA_AGENDAMENTO_ERRO: 'dataAgendamentoErro',
        DATA_INICIAL_ERRO: 'dataInicialErro',
        DATA_FINAL_ERRO: 'dataFinalErro',
        DATA_NASCIMENTO_PORTADOR_ERRO: 'dataNascimentoPortadorErro',
        DATA_NASCIMENTO_TITULAR_ERRO: 'dataNascimentoTitularErro',
        DIGITO_ERRO: 'digitoErro',
        EMAIL_ERRO: 'emailErro',
        ERRO_GENERICO: 'erroGenerico',
        FAVORECIDO_SELECIONADO_ID_ERRO: 'favorecidoSelecionadoIdErro',
        HOJE_ERRO: 'hojeErro',
        NOME_ERRO: 'nomeErro',
        NUMERO_DOCUMENTO_ERRO: 'numeroDocumentoErro',
        NUMERO_DOCUMENTO_PORTADOR_ERRO: 'numeroDocumentoPortadorErro',
        NUMERO_DOCUMENTO_TITULAR_ERRO: 'numeroDocumentoTitularErro',
        PERIODO_EXEDIDO_ERRO: 'periodoExcedidoErro',
        TELEFONE_ERRO: 'telefoneErro',
        TIPO_CONTA_ERRO: 'tipoContaErro',
        VALOR_ERRO: 'valorErro'
    },
    ESTA_AUTENTICADO: 'estaAutenticado',
    ESTADO_SELECIONADO_ID: 'estadoSelecionadoId',
    EXIBIR_LOADING: 'exibirLoading',
    EXIBIR_LOADING_ALTERAR_TITULARIDADE_CARTAO: 'exibirLoadingAlterarTitularidadeCartao',
    EXIBIR_MODAL: {
        EXIBIR_MODAL_CANCELAMENTO: 'exibirModalCancelamento',
        EXIBIR_MODAL_CONFIRMACAO: 'exibirModalConfirmacao',
        EXIBIR_MODAL_COMPROVANTE: 'exibirModalComprovante',
        EXIBIR_MODAL_DETALHE: 'exibirModalDetalhe',
        EXIBIR_MODAL_DETALHE_TRANSACAO: 'exibirModalDetalheTransacao',
        EXIBIR_MODAL_FINALIZACAO: 'exibirModalFinalizacao',
        EXIBIR_MODAL_INSTRUCAO: 'exibirModalInstrucao',
        EXIBIR_MODAL_TOKEN: 'exibirModalToken',
        PADRAO: 'exibirModal',
        TRANSFERENCIA_EM_LOTE: {
            CONFIRMACAO: 'exibirModalTransferenciaEmLoteConfirmacao',
            FINALIZACAO: 'exibirModalTransferenciaEmLoteFinalizacao',
            TOKEN: 'exibirModalTransferenciaEmLoteToken'
        }
    },
    EXIBIR_MODAL_TOKEN_ALTERA_TITULARIDADE_CARTAO: 'exibirModalTokenAlteraTitularidadeCartao',
    EXIBIR_MODAL_CONFIRMACAO_ALTERA_TITULARIDADE_CARTAO: 'exibirModalConfirmacaoAlteraTitularidadeCartao',
    EXIBIR_MODAL_FINALIZACAO_ALTERA_TITULARIDADE_CARTAO: 'exibirModalFinalizacaoAlteraTitularidadeCartao',
    FAVORECIDO_SELECIONADO_ID: 'favorecidoSelecionadoId',
    FEL_ID: 'felId',
    FORMA_ENTREGA_SELECIONADA: 'formaEntregaSelecionada',
    ID_ARQUIVO_SEQUENCIAL_POR_PESSOA_ID: 'idArquivoSequencialPorPessoaId',
    ID: 'ID',
    ID_CARTAO_ID: 'idCartaoID',
    ID_NOME_PORTADOR: 'idNomePortador',
    IMAGEM_CARTAO: 'imagem_cartao',
    IMAGEM_CARTA_BERCO: 'imagem_carta_berco',
    IMAGENS_PRESTACAO_DE_CONTAS: {
        IMAGEM_1: 'imagem_1',
        IMAGEM_2: 'imagem_2',
        IMAGEM_3: 'imagem_3'
    },
    HOJE: 'hoje',
    LINHA_DO_EXTRATO_SELECIONADA: 'linhaDoExtratoSelecionada',
    LEMBRAR_ME: 'lembrarMe',
    LOGRADOURO: 'logradouro',
    MENSAGEM_CONFIRMACAO: 'mensagemConfirmacao',
    MENSAGEM_FINALIZACAO: 'mensagemFinalizacao',
    MODELO_ARQUIVO_SELECIONADO: 'modeloArquivoSelecionado',
    NOME: 'nome',
    NOME_BANCO: 'nomeBanco',
    NOME_DA_CIDADE_SELECIONADA: 'nomeDaCidadeSelecionada',
    NOME_IMPRESSO: 'nomeImpresso',
    NOME_PORTADOR: 'nomePortador',
    NOME_SELECIONADO: 'nomeSelecionado',
    NOME_TITULAR: 'nomeTitular',
    NOME_USUARIO: 'nomeUsuario',
    NUMERO: 'numero',
    NUMERO_CONTA: 'numeroConta',
    NUMERO_DOCUMENTO: 'numeroDocumento',
    NUMERO_DOCUMENTO_PORTADOR: 'numeroDocumentoPortador',
    NUMERO_DOCUMENTO_SELECIONADO: 'numeroDocumentoSelecionado',
    NUMERO_DOCUMENTO_TITULAR: 'numeroDocumentoTitular',
    OPERACAO: 'operacao',
    OPERACAO_SELECIONADA_ID: 'operacaoSelecionadaId',
    OPERACAO_SELECIONADA_NOME: 'operacaoSelecionadaNome',
    OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA: 'operacaoTransferenciaEmLoteSelecionada',
    ORDEM_DESC: 'DESC',
    PAIS_SELECIONADO_ID: 'paisSelecionadoId',
    PASSWORD: 'password',
    PERIODO: 'periodo',
    PESSOA_ID: 'pessoaId',
    PONTO_REFERENCIA: 'pontoReferencia',
    PROJETO: 'projeto',
    PROJETO_SELECIONADO: 'projetoSelecionado',
    QUANTIDADE: 'quantidade',
    QUANTIDADE_CARTOES: 'quantidadeCartoes',
    QUANTIDADE_TOTAL_DE_REGISTROS: 'quantidadeTotalRegistros',
    REDIRECIONAR_DASHBOAR_BPP_PAG: 'redirecionarDashboardBppPag',
    SENHA: 'senha',
    SIGLA_DO_ESTADO_SELECIONADO: 'siglaDoEstadoSelecionado',
    STATUS_SELECIONADO: 'statusSelecionado',
    STATUS_TRANSFERENCIA_EM_LOTE_SELECIONADO: 'statusTransferenciaEmLoteSelecionado',
    STATUS_TRANSFERENCIA_EM_LOTE_ITEN_SELECIONADO: 'statusTransferenciaEmLoteItenSelecionado',
    STATUS_USUARIO: 'statusUsuario',
    TELEFONE: 'telefone',
    TELEFONE_SELECIONADO: 'telefoneSelecionado',
    TIPO_CONTA: 'tipoConta',
    TIPO_CONTA_SELECIONADA: 'tipoContaSelecionada',
    TIPO_DOCUMENTO: 'tipoDocumento',
    TIPO_DOCUMENTO_PORTADOR: 'tipoDocumentoPortador',
    TIPO_DOCUMENTO_SELECIONADO: 'tipoDocumentoSelecionado',
    TIPO_DOCUMENTO_TITULAR: 'tipoDocumentoTitular',
    TIPO_ENTREGA_SELECIONADA: 'tipoEntregaSelecionada',
    TIPO_FAVORECIDO_SELECIONADO: 'tipoFavorecidoSelecionado',
    TRANSFERENCIA_SELECIONADA_ID: 'transferenciaSelecionadaId',
    TOKEN: 'token',
    TOKEN_ID: 'tokenId',
    TOKEN_SMS_ALTERAR_TITULARIDADE_CARTAO: 'tokenSMSAlterarTitularidadeCartao',
    TRANSFERENCIA_EM_LOTE_ARMAZENADA: 'transferenciaEmLoteArmazenada',
    TRANSFERENCIA_EM_LOTE_ID: 'transferenciaEmLoteId',
    URL_IMAGEM_CARTAO: 'urlImagemCartao',
    URL_IMAGEM_CARTA_BERCO: 'urlImagemCartaBerco',
    USUARIO: 'usuario',
    USUARIO_ADMIN: 'usuarioAdmin',
    VALOR: 'valor',
    VALOR_DE: 'valorDe',
    VALOR_ATE: 'valorAte',
    VALOR_CARGA: 'valorCargaCartao',
    VALORES_PADRAO: {
        AGENCIA: '0001'
    },
    DATA_AGENDADA_NAO_PERMITIDA_INICIO: '04/01/2020',
    DATA_AGENDADA_NAO_PERMITIDA_FINAL: '08/01/2020',


    DESCRICAO: 'descricao',
    SECRET_MFA: 'secretMFA',
    TOKEN_JWT: 'tokenJWT',
    TOKEN_MFA: 'tokenMFA',
    CODE_MFA: 'codeMFA'
};

export const CANAL_TOKEN = {
    CORPO_REQUISICAO_SMS: { canalToken: 'SMS' },
    SMS: 'SMS'
};

export const ENDERECO_BPP = () => {
    return `Alphaville Industrial - Barueri/SP <br/>
    Alameda Rio Negro, nº 585 <br/> Bloco Padauiri - 3º andar <br/>
    CEP: 06454-000`;
};

export const ENTER = 'Enter';
/*
fora da ordem alfabética porque é utilizado dentro do COMBO,
se vier após o COMBO, o COMBO não funciona
*/

export const FORMA_ENTREGA = {
    SEDEX: { label: 'Sedex', value: 'sedex' },
    SEDEX_10: { label: 'Sedex 10', value: 'sedex10' },
    CARTA_REGISTRADA: { label: 'Carta Registrada', value: 'cartaRegistrada' },
    RETIRADA_BPP: { label: 'Retirada BPP', value: 'retiradaBPP' },
    TRANSPORTADORA: { label: 'Transportadora', value: 'transportadora' }
};

export const FORMA_ENTREGA_SEM_RETIRADA_BPP = {
    SEDEX: { label: 'Sedex', value: 'sedex' },
    SEDEX_10: { label: 'Sedex 10', value: 'sedex10' },
    CARTA_REGISTRADA: { label: 'Carta Registrada', value: 'cartaRegistrada' },
    TRANSPORTADORA: { label: 'Transportadora', value: 'transportadora' }
};

export const FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER = {
    CORREIOS: { label: 'Correios', value: 'sedex' }
};

export const MODELO_ARQUIVO = {
    MODELO_BPP: { label: 'Modelo BPP', value: 'padraoBPP', tipoArquivo: 'CSV' },
    MODELO_CNAB: { label: 'Modelo CNAB', value: 'cnab', tipoArquivo: 'CNAB' }
};

export const TIPO_DOCUMENTO = {
    CPF: { label: 'CPF', value: 'CPF' },
    CNPJ: { label: 'CNPJ', value: 'CNPJ' }
};

export const TIPO_ENTREGA = {
    EM_LOTE: { label: 'Em Lote', value: 'lote' },
    INDIVIDUAL: { label: 'Individual', value: 'individual' }
};

export const TIPO_CONTA = {
    CONTA_CORRENTE: { value: 'CONTA_CORRENTE', label: 'Conta Corrente' },
    CONTA_POUPANCA: { value: 'CONTA_POUPANCA', label: 'Conta Poupança' }
};

export const TIPO_TRANSFERENCIA_BANCARIA = {
    CONTAS_BPP: { label: 'ENTRE CONTAS BPP', value: 'CONTAS_BPP' },
    OUTROS_BANCOS: { label: 'OUTROS BANCOS', value: 'OUTROS_BANCOS' },
    CARGA_LOTE_CONTA: { label: 'TRANSFERÊNCIA EM LOTE', value: 'CARGA_LOTE_CONTA' }
};

export const TIPO_FAVORECIDO = {
    CONTA_INTERNA: { label: 'BPP Digital', value: 'CONTA_INTERNA' },
    CONTA_EXTERNA: { label: 'Outros Bancos', value: 'CONTA_EXTERNA' }
};

export const OPERACOES_TRANSFERENCIA_EM_LOTE = {
    CARGA_CARTAO: { value: 'cargaCartao', label: 'Carga Cartão' },
    TED: { value: 'TED', label: 'TED' },
    TRANSFERENCIA_ENTRE_CONTAS: {
        value: 'transferenciaEntreContas',
        label: 'Transferência entre contas'
    }
};

export const TRANSFERENCIA_EM_LOTE_CORP = {
    CARGA_CARTAO: { value: 'cargaCartao', label: 'Carga Cartão' }
};

export const CARGA_LOTE_CARTAO_BPP_CORP = {
    value: 'cargaCartaoLoteBPPCorp',
    label: 'Carga Cartão'
};

export const PERFIS = {
    ADMINISTRADOR: {
        label: 'Administrador',
        value: 'administrador',
        aprovar: 'X',
        consultar: 'X',
        criar: 'X',
        descricao: 'Pode criar, consultar e aprovar as operações'
    },
    ANALISTA: {
        label: 'Analista',
        value: 'analista',
        aprovar: undefined,
        consultar: 'X',
        criar: 'X',
        descricao: 'Consulta e cria operações'
    },
    APROVADOR: {
        label: 'Aprovador',
        value: 'aprovador',
        aprovar: 'X',
        consultar: 'X',
        criar: undefined,
        descricao: 'Pode aprovar somente operações criadas por outras pessoas'
    },
    APROVADOR_MASTER: {
        label: 'Aprovador Master',
        value: 'aprovadorMaster',
        aprovar: 'X',
        consultar: 'X',
        criar: undefined,
        descricao: 'Pode aprovar qualquer operação, inclusive as que ele próprio criou'
    },
    CONSULTA: {
        label: 'Consulta',
        value: 'consulta',
        aprovar: undefined,
        consultar: 'X',
        criar: undefined,
        descricao: 'Consulta conta e operações'
    }
};

export const STATUS = {
    AGENDAMENTO_APROVACOES_CONTA: ['aberto', 'agendado'],
    INTERNO: { ABERTO: 'aberto', AGENDADO: 'agendado', APROVADO: 'aprovado' },
    OPERACAO: { ABERTO: 'aberto', AGENDADO: 'agendado' },
    TRANSFERENCIA_EM_LOTE: {
        AGENDADO: { label: 'Agendado', value: 'agendado' },
        CANCELADO: { label: 'Cancelado', value: 'cancelado' },
        CANCELADO_POR_FALTA_DE_SALDO: {
            label: 'Cancelado por falta de saldo',
            value: 'canceladoFaltaSaldo'
        },
        CANCELADO_ERRO: { label: 'Cancelado erro', value: 'canceladoErro' },
        EM_PROCESSAMENTO: { label: 'Em Processamento', value: 'emProcessamento' },
        PROCESSADO: { label: 'Processado', value: 'processado' }
    },
    TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS: {
        EM_VALIDACAO: { value: 'emValidacao', label: 'Em validação' },
        PENDENTE_CORRECAO: {
            value: 'pendenteCorrecao',
            label: 'Pendente de Correção'
        },
        PENDENTE_APROVACAO: {
            value: 'pendenteAprovacao',
            label: 'Pendente de Aprovação'
        },
        APROVADO: { value: 'aprovado', label: 'Aprovado' },
        REPROVADO: { value: 'reprovado', label: 'Reprovado' },
        CORRECAO: {
            UPLOAD_ARQUIVO: 'Faça upload do arquivo corrigido.'
        }
    },
    TRANSFERENCIA_EM_LOTE_ITENS: {
        PROCESSADO: {
            value: 'processado',
            label: 'Processado'
        },
        NAOPROCESSADO: {
            value: 'naoProcessado',
            label: 'Não Processado'
        }
    },
    PRESTACAO_DE_CONTAS: {
        AGUARDANDO_APROVACAO: 'aguardandoAprovacao',
        APROVADO: 'aprovado',
        PENDENTE_PRESTACAO: 'pendentePrestacao',
        REPROVADO: 'reprovado'
    }
};

/* fim */

export const COMBO = {
    LOCALIDADE: {
        GET_PAISES: listaDePaises => {
            return listaDePaises.map(pais => ({
                label: pais.nome,
                value: pais.id,
                ...pais
            }));
        },
        GET_ESTADOS: listaDeEstados => {
            return listaDeEstados.map(estado => ({
                estado,
                label: estado.nome,
                value: estado.sigla
            }));
        },
        GET_CIDADES: listaDeCidades => {
            return listaDeCidades.map(cidade => ({
                cidade,
                label: cidade.nome,
                value: cidade.nome
            }));
        },
        GET_OPERACOES: listaDeOperacoes => {
            let comboOperacoes = [];
            listaDeOperacoes.valor.map(operacao => {
                return comboOperacoes.push({
                    value: operacao.operacaoId,
                    label: operacao.operacaoNome,
                    operacao
                });
            });
            return comboOperacoes;
        },
        GET_PAIS_BRASIL: listaDePaises => {
            return listaDePaises
                .filter(pais => pais.sigla === 'BRA')
                .map(pais => ({ pais, label: pais.nome, value: pais.id }));
        }
    },
    MODELO_ARQUIVO_TRANSFERENCIA_EM_LOTE: Object.keys(MODELO_ARQUIVO).map(
        key => MODELO_ARQUIVO[key]
    ),
    OPERACOES: {
        OPERACOES_TRANSFERENCIA_EM_LOTE: () => {
            return Object.keys(OPERACOES_TRANSFERENCIA_EM_LOTE).map(
                key => OPERACOES_TRANSFERENCIA_EM_LOTE[key]
            );
        }
    },
    PEDIDOS: {
        EMBOSSING_CARTAO: {
            PEDIDO_DE_CARTAO_BPP_CONSUMER: Object.keys(
                FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER
            ).map(key => FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER[key])
        },
        IMPRESSAO: {
            COMBOS_FEL: anexo => {
                if (anexo && anexo.midia && anexo.midia.comboFELDetalhes) {
                    return anexo.midia.comboFELDetalhes.map(comboFel => ({
                        comboFel,
                        value: comboFel.id,
                        label: comboFel.descricao
                    }));
                }
                return [];
            },

            COMBO_TIPO_ENTREGA: Object.keys(TIPO_ENTREGA).map(
                key => TIPO_ENTREGA[key]
            ),

            COMBO_FORMA_ENTREGA: Object.keys(FORMA_ENTREGA).map(
                key => FORMA_ENTREGA[key]
            ),

            COMBO_FORMA_ENTREGA_SEM_RETIRADA_BPP: Object.keys(FORMA_ENTREGA_SEM_RETIRADA_BPP).map(
                key => FORMA_ENTREGA_SEM_RETIRADA_BPP[key]
            )
        }
    },
    STATUS: {
        OPERACAO: [
            { value: 'aberto', label: 'Aberto' },
            { value: 'agendado', label: 'Agendado' },
            { value: 'emProcessamento', label: 'Em Processamento' },
            { value: 'finalizadoCancelado', label: 'Cancelado' },
            { value: 'finalizadoComSucesso', label: 'Finalizado' },
            { value: 'finalizadoRecusado', label: 'Recusado' },
            {
                value: 'finalizadoPendenciaCadastro',
                label: 'Finalizado por Pendência de Cadastro'
            },
            {
                value: 'finalizadoSaldoInsuficiente',
                label: 'Finalizado por Saldo Insuficiente'
            }
        ],
        PRESTACAO_DE_CONTAS: [
            {
                value: 'pendentePrestacao',
                label: 'Pendente Prestação',
                labelCss: 'label label-default'
            },
            {
                value: 'aguardandoAprovacao',
                label: 'Aguardando Aprovação',
                labelCss: 'label label-orange'
            },
            { value: 'aprovado', label: 'Aprovado', labelCss: 'label label-success' },
            { value: 'reprovado', label: 'Reprovado', labelCss: 'label label-danger' }
        ],
        /*  TRANSFERENCIA_EM_LOTE: [
             { value: "aguardandoAprovacao", label: "Pendente de aprovação" },
             { value: "cancelado", label: "Cancelado" },
             { value: "emProcessamento", label: "Em processamento" },
             { value: "processado", label: "Processado" },
             { value: "rejeitado", label: "Rejeitado" },
         ] */
        /** Agendado (agendado), Em processamento (e
mProcessamento), Processado (processado), Cancelado (cancelado) e Cancelado por falta de saldo (canceladoFaltaSaldo). */
        TRANSFERENCIA_EM_LOTE: Object.keys(STATUS.TRANSFERENCIA_EM_LOTE).map(
            key => STATUS.TRANSFERENCIA_EM_LOTE[key]
        ),
        TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS: Object.keys(
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS
        ).map(key => STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS[key]),
        TRANSFERENCIA_EM_LOTE_ITENS: Object.keys(
            STATUS.TRANSFERENCIA_EM_LOTE_ITENS
        ).map(key => STATUS.TRANSFERENCIA_EM_LOTE_ITENS[key])
    },
    TIPO_CONTA: Object.keys(TIPO_CONTA).map(key => TIPO_CONTA[key]),
    TIPO_DOCUMENTO: Object.keys(TIPO_DOCUMENTO).map(key => TIPO_DOCUMENTO[key]),
    TIPO_FAVORECIDO: Object.keys(TIPO_FAVORECIDO).map(
        key => TIPO_FAVORECIDO[key]
    ),
    TRANSFERENCIA_BANCARIA: Object.keys(TIPO_TRANSFERENCIA_BANCARIA).map(
        key => TIPO_TRANSFERENCIA_BANCARIA[key]
    )
};

export const CONTA_SELECIONADA = {
    OBJETO: () => {
        const contaDepartamentoSelecionada = reactLocalStorage.get(
            LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA
        );
        return contaDepartamentoSelecionada
            ? JSON.parse(contaDepartamentoSelecionada)
            : reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA);
    }
};

export const EXPORT_EXCEL = {
    CONVERTER_ARRAY_PARA_CSV: objArray => {
        var array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
        var str = '';

        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line !== '') line += ';';

                line += array[i][index];
            }

            str += line + '\r\n';
        }

        return str;
    }
};

export const DDI = {
    BRASIL: 55
};

export const DESCRICAO_CONTA = {
    CONTA_CARTAO: 'contaCartao',
    CONTA_DEPARTAMENTO: 'contaDepartamento',
    CONTA_MOVIMENTO: 'contaMovimento'
};

export const EMPRESAS = () =>
    reactLocalStorage.get(LABEL_COMUNS.LISTA_EMPRESAS)
        ? JSON.parse(reactLocalStorage.get(LABEL_COMUNS.LISTA_EMPRESAS))
        : [];

export const FILTROS = {
    CARTOES: (
        produtoId,
        IDCartao,
        nomePortador,
        numeroDocumentoPortador,
        cartaoQuatroUltimosDigitos,
        statusContaCartao
    ) => {
        const filtro = {};

        if (produtoId) {
            filtro.produtoId = produtoId;
        }

        if (IDCartao) {
            filtro.idCartao = IDCartao;
        }

        if (nomePortador) {
            filtro.nomePortador = nomePortador;
        }

        if (numeroDocumentoPortador) {
            filtro.cartaoQuatroUltimosDigitos = cartaoQuatroUltimosDigitos;
        }

        if (cartaoQuatroUltimosDigitos) {
            filtro.cartaoQuatroUltimosDigitos = cartaoQuatroUltimosDigitos;
        }

        if (statusContaCartao) {
            filtro.statusContaCartao = statusContaCartao;
        }

        filtro.tamanhoPagina = 2000;

        return filtro;
    },
    CARTOES_FAVORITOS: (cartaoId, tamanhoPagina, ultimaPosicao) => {
        let filtro ={};
        if (cartaoId) {
            filtro.cartaoId = cartaoId;
        }
        if (tamanhoPagina) {
            filtro.tamanhoPagina = tamanhoPagina;
        }
        if (ultimaPosicao) {
            filtro.ultimaPosicao = ultimaPosicao;
        }
        return filtro;
    },
    AGENDAMENTOS_CONTA_ORIGEM: (
        contaOrigemId,
        status,
        dataInicial,
        dataFinal,
        valorDe,
        valorAte
    ) => {
        let filtro = { contaOrigemId: `${contaOrigemId}` };

        if (status) {
            filtro.statusInterno = `${status}`;
        }

        if (dataInicial) {
            filtro.dataInicial = formatarDataUS(`${dataInicial}`);
        }
        if (dataFinal) {
            filtro.dataFinal = formatarDataUS(`${dataFinal}`);
        }
        if (valorDe) {
            filtro.valorDe = stringParaNumero(`${valorDe}`);
        }
        if (valorAte) {
            filtro.valorAte = stringParaNumero(`${valorAte}`);
        }
        filtro.tamanhoPagina = 50;

        return filtro;
    },
    AGENDAMENTOS_CONTA_DESTINO: (
        contaDestinoId,
        status,
        dataInicial,
        dataFinal,
        valorDe,
        valorAte
    ) => {
        let filtro = {
            contaDestinoId: `${contaDestinoId}`,
            operacao: 'descargaCartao'
        };

        if (status) {
            filtro.statusInterno = `${status}`;
        }

        if (dataInicial) {
            filtro.dataInicial = formatarDataUS(`${dataInicial}`);
        }
        if (dataFinal) {
            filtro.dataFinal = formatarDataUS(`${dataFinal}`);
        }
        if (valorDe) {
            filtro.valorDe = stringParaNumero(`${valorDe}`);
        }
        if (valorAte) {
            filtro.valorAte = stringParaNumero(`${valorAte}`);
        }
        filtro.tamanhoPagina = 50;

        return filtro;
    },
    EXTRATO: (dataInicial, dataFinal, ultimaChave, tamanhoPagina) => {
        let filtro = {};
        if (dataInicial) {
            filtro.dataInicial = formatarDataUS(`${dataInicial}`);
        }
        if (dataFinal) {
            filtro.dataFinal = formatarDataUS(`${dataFinal}`);
        }
        if (ultimaChave) {
            filtro.ultimaChave = `${ultimaChave}`;
        }
        filtro.ordem = CAMPOS.ORDEM_DESC;

        // filtro.tamanhoPagina = tamanhoPagina || 50;

        filtro.ordem = ORDENACAO.DESC;
        return filtro;
    },
    TRANSFERENCIA_EM_LOTE: {
        AGENDAMENTOS_E_APROVACOES: (
            contaId,
            pessoaId,
            dataDeInclusaoDe,
            dataDeInclusaoAte,
            operacao,
            status,
            idArquivoSequencialPorPessoaId,
            produto,
            ultimaChave
        ) => {
            let filtro = { contaId, pessoaId, produto };
            if (dataDeInclusaoDe) {
                filtro.dataDeInclusaoDe = formatarDataUS(dataDeInclusaoDe);
            }
            if (dataDeInclusaoAte) {
                filtro.dataDeInclusaoAte = formatarDataUS(dataDeInclusaoAte);
            }
            if (operacao) {
                filtro.operacao = operacao;
            }
            if (status) {
                filtro.status = status;
            }
            if (idArquivoSequencialPorPessoaId) {
                filtro.idArquivoSequencialPorPessoaId = idArquivoSequencialPorPessoaId;
            }
            if (ultimaChave) {
                filtro.ultimaChave = ultimaChave;
            }
            filtro.tamanhoPagina = 100;
            return filtro;
        },
        HISTORICO: (
            contaId,
            pessoaId,
            produto,
            dataDeInclusaoDe,
            dataDeInclusaoAte,
            operacao,
            status,
            idArquivoSequencialPorPessoaId,
            ultimaChave
        ) => {
            let filtro = { contaId, pessoaId, produto, ultimaChave };
            if (dataDeInclusaoDe) {
                filtro.dataDeInclusaoDe = formatarDataUS(`${dataDeInclusaoDe}`);
            }
            if (dataDeInclusaoAte) {
                filtro.dataDeInclusaoAte = formatarDataUS(`${dataDeInclusaoAte}`);
            }
            if (operacao) {
                filtro.operacao = operacao;
            }
            if (status) {
                filtro.status = status;
            }
            if (idArquivoSequencialPorPessoaId) {
                filtro.idArquivoSequencialPorPessoaId = idArquivoSequencialPorPessoaId;
            }
            filtro.tamanhoPagina = 15;
            return filtro;
        },
        LOTES_DO_ARQUIVO: (
            arquivoId,
            contaId,
            pessoaId,
            dataAgendamentoDe,
            dataAgendamentoAte,
            operacao,
            status,
            ultimaChave
        ) => {
            let filtro = { arquivoId, contaId, pessoaId, ultimaChave };
            if (dataAgendamentoDe) {
                filtro.dataAgendamentoDe = formatarDataUS(`${dataAgendamentoDe}`);
            }
            if (dataAgendamentoAte) {
                filtro.dataAgendamentoAte = formatarDataUS(`${dataAgendamentoAte}`);
            }
            if (operacao) {
                filtro.operacao = operacao;
            }
            if (status) {
                filtro.status = status;
            }
            filtro.tamanhoPagina = 15;
            return filtro;
        },
        ITENS_DO_LOTE: (loteId, valorDe, valorAte, status, ultimaChave) => {
            let filtro = { loteId, ultimaChave };
            if (valorDe) {
                filtro.valorDe = stringParaNumero(`${valorDe}`);
            }
            if (valorAte) {
                filtro.valorAte = stringParaNumero(`${valorAte}`);
            }
            if (status) {
                filtro.status = status;
            }
            filtro.tamanhoPagina = 15;
            return filtro;
        },
        COLUNA_TRANSFERENCIA_LOTE_DETALHES: nomeDaColuna => {
            return LABEL_COMUNS.TRANSFERENCIA_EM_LOTE.DETALHES.COLUNAS[nomeDaColuna];
        },
        OPERACAO: operacao => {
            return COMBO.OPERACOES
                .OPERACOES_TRANSFERENCIA_EM_LOTE()
                .filter(op => op.value === operacao)[0];
        },
        STATUS: status => {
            return STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS[
                Object.keys(STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS).find(
                    key => STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS[key].status === status
                )
            ];
        }
    },

    /*
    export const RETORNA_STATUS_DA_OPERACAO_FILTRADO = (statusDaOperacao) => {
    return COMBO.STATUS.OPERACAO.filter(status => (status.value === statusDaOperacao))[0].value;
};
    */
    BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO: cartaoId => {
        let filtro = {
            statusInterno: 'emProcessamento,emProcessamentoEnviadoIntegracao'
        };
        if (cartaoId) {
            filtro.cartaoId = cartaoId;
        }
        return filtro;
    }
};

export const CARTAO_STATUS = {
    ATIVO: 'ativo',
    BLOQUEADO: 'bloqueado',
    CANCELADO: 'cancelado',
    CANCELADOS_E_OUTROS: 'canceladosEOutros',
    PRONTOS_PARA_ATIVACAO: 'prontoParaAtivacao'
};

export const FILTRO_LABEL_COLUNA_TRANSFERENCIA_LOTE_DETALHES = nomeDaColuna => {
    const colunas = LABEL_COMUNS.TRANSFERENCIA_EM_LOTE.DETALHES.COLUNAS;
    return colunas[Object.keys(colunas).find(key => key === nomeDaColuna)];
};

/*  Não apagar esse trecho
export const FILTRO_LABEL_TRANFERENCIA_EM_LOTE = (status) => {
    const statusTransferenciaEmLote = STATUS.TRANSFERENCIA_EM_LOTE;
    return statusTransferenciaEmLote[Object.keys(statusTransferenciaEmLote).find(key => statusTransferenciaEmLote[key].status === status)];
} */

export const FILTRO_PRIME_REACT_DATA_TABLE = {
    CONTAINS: 'contains',
    CUSTOM: 'custom',
    ENDS_WIDTH: 'endsWidth',
    EQUALS: 'equals',
    FILTER_IN: 'in',
    NOT_EQUALS: 'notEquals',
    START_WITH: 'startsWith'
};

export const FORMATOS_PERMITIDOS_DE_DATA_NO_PEDIDO_EMBOSSING_CARTAO =
  'DD/MM/YY ou DD/MM/YYYY';

export const INDICE_COLUNA_ARQUIVO = {
    PEDIDO_EMBOSSING_CARTAO: {
        NOME_IMPRESSO_CARTAO: 0,
        NOME_IMPRESSO_CARTA_BERCO: 1,
        CPF_PORTADOR: 2,
        DATA_NASCIMENTO_PORTADOR: 3,
        DDI_CELULAR_PORTADOR: 4,
        DDD_CELULAR_PORTADOR: 5,
        CELULAR_PORTADOR: 6,
        EMAIL_PORTADOR: 7,
        LOGRADOURO: 8,
        NUMERO: 9,
        COMPLEMENTO: 10,
        BAIRRO: 11,
        CIDADE: 12,
        UF: 13,
        PAIS: 14,
        CEP: 15,
        PONTO_REFERENCIA: 16,
        CAMPO_LIVRE_CARTA_BERCO: 17,
        CAMPO_LIVRE_CARTAO: 18,
        CAMPO_LIVRE_TRILHA: 19
    }
};

export const ERROS_FILTROS_PRESTACAO = {
    CAMPO_OBRIGATORIO: 'campo obrigatório',
    INTERVALO_TEMPO: 'Intervalo máximo de 60 dias entre data início e data fim.'
};

export const LABEL_COMUNS = {
    ABERTURA: 'Abertura',
    ACAO: 'Ação',
    ACESSAR: 'Acessar',
    ACOES: 'Ações',
    ACOES_DA_CONTA_DIGITAL: 'Ações da Conta Digital',
    ACOES_DO_PRODUTO: 'Ações do Produto',
    AGENCIA: 'Agência',
    AGENDAMENTOS_APROVACOES: {
        TITULOS: {
            PADRAO: 'Agendamentos e Aprovações',
            MODAL_APROVACAO_CANCELAMENTO: transacao =>
                `${transacao === TIPO_DE_TRANSACAO.APROVACAO.transacao ? LABEL_COMUNS.APROVACAO : (transacao === TIPO_DE_TRANSACAO.AGENDAMENTO.transacao ? LABEL_COMUNS.APROVACAO : LABEL_COMUNS.CANCELAMENTO)}`
        }
    },
    ALTERAR_PORTADOR: 'Alterar Portador',
    AGENDAR_PARA: 'Agendar para',
    AGENDAMENTO: 'Agendamento',
    ALTERAR_TITULARIDADE_CARTAO: {
        TITULOS: { PADRAO: 'Alterar Titularidade Cartão' }
    },
    ANEXO: 'ANEXO',
    ANEXO_COMBO_IMPRESSAO: 'Anexo e Combo de Impressão',
    ANEXO_CONTRATUAL: 'Anexo Contratual',
    ANEXO_SOLUCAO: 'Anexo/Produto',
    AOS_CUIDADOS: 'Aos cuidados',
    APELIDO: 'Apelido',
    APROVADOR: 'Aprovador',
    APROVADOR_MASTER: 'Aprovador Master',
    APROVAR: 'Aprovar',
    APROVACAO: 'Aprovação',
    ARQUIVO: {
        TITULOS: {
            ARQUIVO_RETORNO: 'Arquivo Retorno',
            HISTORICO_DE_ARQUIVOS: 'Histórico de Arquivos',
            HISTORICO_DE_ARQUIVOS_ENVIADOS: 'Histórico de Arquivos Enviados',
            NOVO_ARQUIVO: 'Novo Arquivo',
            PADRAO: 'Arquivo',
            ARQUIVOS: 'Arquivos'
        },
        NOME: {
            EXTRATO_CSV: {
                DESCRICAO_CONTA_SELECIONADA: contaSelecionadaDescricao => {
                    let nomeArquivo = 'extrato';
                    // extrato-conta.csv
                    if (contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_MOVIMENTO) {
                        nomeArquivo = nomeArquivo.concat('-conta.csv');
                    }
                    // extrato-cartao.csv
                    if (contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_CARTAO) {
                        nomeArquivo = nomeArquivo.concat('-cartao.csv');
                    }
                    // extrato-conta-depto.csv
                    if (
                        contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO
                    ) {
                        nomeArquivo = nomeArquivo.concat('-conta-depto.csv');
                    }
                    return nomeArquivo;
                }
            }
        }
    /*   CONTENT_TYPE_BODY: (nomeArquivo) => { contentType: retornaTipoDoArquivo(`${nomeArquivo}`) }, */
    /*  CONTENT_TYPE_HEADER: (type) => { "Content-Type": `${type}` }, */
    },
    ARQUIVO_RETORNO: 'Arquivo Retorno',
    ATENCAO: 'Atenção',
    ATUALIZAR: 'Atualizar',
    AVATAR: 'Avatar',
    BAIRRO: 'Bairro',
    BANCO: 'Banco',
    BENEFICIARIO: 'Beneficiário',
    BPP_VIRTUAL: 'BPP VIRTUAL',
    BUSCAR: 'Buscar',
    BUSCAR_MAIS: 'Buscar Mais',
    SET_HISTORICO: 'Buscar',
    CADASTRAR_FAVORITO: 'Cadastrar Favorito',
    CAMPO: 'campo',
    CANCELAMENTO: 'Cancelamento',
    CANCELAR: 'Cancelar',
    CANCELAR_O_LOTE: 'Cancelar o lote',
    CARGA: 'Carga',
    CARGA_LOTE_CARTAO: 'Carga em Lote',
    CARREGAR: 'Carregar',
    CARREGAR_CARTAO: 'Carregar Cartão',
    CARREGAR_A_CONTA: 'Carregar a conta',
    CARTAO: 'Cartão',
    CARTAO_ID: cartaoId => `Cartão - ID: ${cartaoId}`,
    CARTAO_INVALIDO: 'Número do cartão inválido',
    CARTAO_NAO_ENCONTRADO: {
        ATIVO: 'ativo',
        BLOQUEADO: 'bloqueado',
        CANCELADO_E_OUTRO: 'cancelado',
        PRONTO_PARA_ATIVACAO: 'pronto para ativação',
        PRONTOS_PARA_ATIVACAO: 'Prontos para ativação'
    },
    CARTAO_QUATRO_ULTIMOS_DIGITOS: '4 Últimos Dígitos',
    CARTAO_SELECIONADO: 'cartaoSelecionado',
    CARTOES: {
        APELIDO_DO_CARTAO: 'Apelido do Cartão',
        CARTAO_ID: 'ID',
        MIDIA: { FISICA: 'FÍSICO' },
        MUDAR_TITULARIDADE: 'Mudar Titularidade',
        PORTADOR: 'Portador',
        NUMERO: 'N\u00famero',
        NUMERO_DO_CARTAO: 'N\u00famero do Cart\u00e3o',
        PADRAO: 'Cart\u00f5es',
        SALDO_DO_CARTAO: 'Saldo',
        STATUS_DO_CARTAO: {
            ATIVOS: 'Ativos',
            BLOQUEADOS: 'Bloqueados',
            CANCELADOS: 'Cancelados',
            CANCELADOS_E_OUTROS: 'Cancelados e Outros',
            PRONTOS_PARA_ATIVACAO: 'Prontos para Ativação'
        },
        TITULOS: {
            PADRAO: 'Cart\u00f5es',
            ATIVAR: 'Ativar',
            ATIVAR_CARTAO: 'Ativar Cart\u00e3o',
            ALTERAR_APELIDO_CARTAO: 'Alterar Apelido Cart\u00e3o',
            ALTERAR_SENHA_CARTAO: 'Alterar Senha Cart\u00e3o',
            BLOQUEAR_CARTAO: 'Bloquear Cart\u00e3o',
            BUSCAR_CARTOES: 'Buscar Cart\u00f5es',
            CARGA_CARTAO: 'Carga Cart\u00e3o',
            DESBLOQUEAR_CARTAO: 'Desbloquear Cart\u00e3o',
            DESCARGA_CARTAO: 'Descarga Cart\u00e3o',
            MEUS_CARTOES: 'Meus Cart\u00f5es',
            PEDIR_CARTAO: 'Pedir Cart\u00e3o',
            VALIDADE: 'Validade',
            VINCULAR_CARTAO: 'Vincular Cart\u00e3o',
            VINCULAR_PORTADOR: 'Vincular Portador',
            CANCELAR_CARTAO: 'Cancelar Cart\u00e3o',
            ALTERAR_SENHA: 'Alterar Senha'
        },
        VENCIMENTO: 'Vencimento'
    },
    CELULAR: 'Celular',
    CEP: 'CEP',
    CIDADE: 'Cidade',
    CLASSIFICACAO: 'Classificação',
    CLIENTE_PROJETO: 'Cliente/Projeto',
    CNPJ: 'CNPJ',
    CODIGO_BARRAS: 'Código de Barras',
    CODIGO_ERRO_MIGRACAO_CORE: '9999',
    COMPRA: 'Compra',
    COMPLEMENTO: 'Complemento',
    COMPROVANTE: 'Comprovante',
    COMPROVANTES: 'Comprovantes',
    CONFIRMAR: 'Confirmar',
    CONFIRME_A_SENHA: 'Confirme a senha',
    INSERIR_SUA_SENHA: 'Insira sua senha',
    CONFIRME_SUA_SENHA: 'Confirme sua senha',
    CORRIGIR: 'Corrigir',
    CONSULTAR: 'Consultar',
    CONTA: 'Conta:',
    CONTAS: {
        TITULOS: {
            ALTERAR_APELIDO: 'Alterar Apelido',
            ALTERAR_OU_INSERIR_AVATAR: 'Alterar/inserir avatar',
            APELIDO_CONTA_E_DIGITO: conta => {
                const { apelido, numero, digito } = conta;
                return `${apelido} - ${numero}-${digito}`;
            },
            CONTA: 'Conta',
            CRIAR_CONTA_DIGITAL: 'Criar Conta Digital',
            PADRAO: 'Contas',
            EDITAR_CONTA: (numeroConta, digito) => {
                let titulo = 'Editar Conta';
                if (numeroConta && digito) {
                    titulo = titulo.concat(
                        ' - '.concat(numeroConta.concat('-'.concat(digito)))
                    );
                }
                return titulo;
            },
            EDITAR_AUTORIZADO: 'Editar Autorizado',
            EDITAR_PERMISSOES: 'Editar Permissões',
            FAVORECIDO: {
                APELIDO_CONTA_DIGITAL: apelido => `Conta Digital: ${apelido}`,
                EDITAR_FAVORECIDO: 'Editar Favorecido',
                INCLUIR_FAVORECIDO: 'Incluir Favorecido'
            },
            INCLUIR_AUTORIZADO: 'Incluir Autorizado',
            INFORMACOES_GERAIS: 'Informações Gerais',
            AUTORIZACOES: 'Autorizações',
            AUTORIZADOS: 'Lista de Autorizados',
            PERMISSOES: 'Permissões',
            NOME_CPF_AUTORIZADO: 'Nome e CPF do Autorizado',
            EXCLUIR_AUTORIZADO: 'Excluir Autorizado'
        }
    },
    CONTA_CARTAO: 'Conta Cartão',
    CONTA_DEPARTAMENTO: 'Conta Departamento',
    CONTA_DEPARTAMENTO_SELECIONADA: 'contaDepartamentoSelecionada',
    CONTA_SELECIONADA: 'contaSelecionada',
    CONTA_DIGITAL: 'Conta Digital',
    CONTINUAR: 'Continuar',
    CPF: 'CPF',
    CPF_DO_PORTADOR: 'CPF do Portador',
    CRIAR: 'Criar',
    CRIAR_CONTA: 'Criar Conta',
    CRIAR_CONTA_DEPARTAMENTO: 'Criar Conta Departamento',
    DADOS_BANCARIOS_FAVORECIDOS: 'Banco / Agência / Conta',
    DADOS_CONTA_DEBITADA: 'Dados da conta debitada:',
    DADOS_DA_TED: 'Dados da TED',
    DADOS_DE_ENTREGA: 'Dados de Entrega',
    DATA: 'Data',
    DATA_ATE: 'Até',
    DATA_DE_INCLUSAO: 'Data de Inclusão',
    DATA_AGENDADA: 'Data Agendada',
    DATA_AGENDAMENTO: 'Data Agendamento',
    DATA_DE_AGENDAMENTO: 'Data de Agendamento',
    DATA_CRIACAO: 'Data Criação',
    DATA_DE: 'De',
    DATA_HORA: 'Data/Hora',
    DATA_INCLUSAO: 'Data de Inclusão',
    DATA_INICIAL: 'Data Inicial',
    DATA_INICIO: 'Data Início',
    DATA_FIM: 'Data Fim',
    DATA_FINAL: 'Data Final',
    DATA_HORARIO: 'Data/Horário',
    DATA_NASCIMENTO: 'Data Nascimento',
    DATA_DE_NACIMENTO: 'Data de Nascimento',
    DATA_DO_PAGAMENTO: 'Data do Pagamento',
    DATA_PAGAMENTO: 'Data Pagamento',
    DATA_PROCESSAMENTO: 'Data Processamento',
    DEPARTAMENTO: 'Departamento',
    DEPOSITAR: 'Depositar',
    DESCARREGAR: 'Descarregar',
    DESCARREGAR_CARTAO: 'Descarregar Cartão',
    DESCARGA: 'Descarga',
    DESCRICAO: 'Descrição',
    DESEJA_TRANSFERIR: 'Como deseja transferir?',
    DESEJA_REMOVER: 'Deseja excluir o cartão da lista de favorito?',
    DESPESA: 'Despesa',
    DETALHE: 'Detalhe',
    DETALHE_PORTADOR: 'Detalhe Portador',
    DETALHES: 'Detalhes',
    DETALHES_DA_TRANSACAO: (contaDescricao = '') => {
        let detalhesDaTransacao = 'Detalhes da Transação';
        switch (contaDescricao) {
            case DESCRICAO_CONTA.CONTA_MOVIMENTO:
            case DESCRICAO_CONTA.CONTA_DEPARTAMENTO:
                return `${detalhesDaTransacao} - ${LABEL_COMUNS.EXTRATO_CONTA}`;
            case DESCRICAO_CONTA.CONTA_CARTAO:
                return `${detalhesDaTransacao} - ${LABEL_COMUNS.EXTRATO_CARTAO}`;
            default:
                return detalhesDaTransacao;
        }
    },
    DETALHES_DO_COMPROVANTE: (contaDescricao = '') => {
        let detalhesDoComprovante = 'Detalhes do Comprovante';
        switch (contaDescricao) {
            case DESCRICAO_CONTA.CONTA_MOVIMENTO:
            case DESCRICAO_CONTA.CONTA_DEPARTAMENTO:
                return `${detalhesDoComprovante} - ${LABEL_COMUNS.EXTRATO_CONTA}`;
            case DESCRICAO_CONTA.CONTA_CARTAO:
                return `${detalhesDoComprovante} - ${LABEL_COMUNS.EXTRATO_CARTAO}`;
            default:
                return detalhesDoComprovante;
        }
    },
    DESTALHES_DO_ARQUIVO: 'Detalhes do Arquivo',
    DESTINATARIO: 'Destinatário',
    DIGITE_SENHA: 'Digite aqui sua senha',
    DIGITE_CPF: 'Digite aqui seu CPF',
    DIGITE_O_TOKEN: 'Digite o TOKEN',
    DIGITO: 'Dígito',
    DOCUMENTO: 'Documento',
    DOCUMENTO_FAVORECIDO: 'Documento Favorecido',
    DOWNLOAD: 'Download',
    EMAIL: 'Email',
    EMPRESA: 'Empresa',
    EMPRESA_SELECIONADA: 'empresaSelecionada',
    EMPRESA_SELECIONADA_ID: 'empresaIdSelecionada',
    ENDERECO: 'Endere\u00e7o',
    ENDERECO_PARA_ENTREGA: 'Endere\u00e7o para Entrega',
    ENDERECO_PARA_RETIRADA: 'Endere\u00e7o para Retirada',
    ENVIO_DE_ARQUIVO: 'Envio de arquivo',
    ERRO: 'Erro',
    ERRO_SOLICITACAO: 'Não foi possível processar a sua solicitação no momento. Tente novamente mais tarde.',
    ERROS_PENDENTES_DE_CORREÇÃO: 'Erros Pendentes de Corre\u00e7\u00e3o',
    ESTABELECIMENTO: 'Estabelecimento',
    ESTADO: 'Estado',
    EXCLUIR: 'Excluir',
    EXCLUIR_CARTAO_FAVORITO: 'Excluir Cartão Favorito',
    EXPANDIR: 'Expandir',
    EXTENSAO_INVALIDA: 'Extens\u00E3o inv\u00E1lida.',
    EXTRATO: 'Extrato',
    EXTRATO_CONTA: 'Extrato Conta',
    EXTRATO_CARTAO: 'Extrato Cartão',
    EXTRATO_CONTA_DEPARTAMENTO: 'Extrato Conta Departamento',
    EXTRATO_TITULO: {
        DESCRICAO_CONTA_SELECIONADA: contaSelecionadaDescricao => {
            switch (contaSelecionadaDescricao) {
                case DESCRICAO_CONTA.CONTA_CARTAO:
                    return LABEL_COMUNS.EXTRATO_CARTAO;
                case DESCRICAO_CONTA.CONTA_MOVIMENTO:
                    return LABEL_COMUNS.EXTRATO_CONTA;
                default:
                    return LABEL_COMUNS.EXTRATO;
            }
        }
    },
    FAVORECIDO: 'Favorecido',
    FAVORITO: 'Favorito',
    FAVORITOS: 'Favoritos',
    FECHAR: 'Fechar',
    FORMA_DE_ENTREGA: 'Forma de Entrega',
    GERAR_RELATORIO: 'Gerar Relatório',
    GERENCIAR_ESTOQUE_DE_CARTOES: 'Gerenciar Cartões',
    HTTP_METHOD: { GET: 'GET', POST: 'POST', PUT: 'PUT' },
    HISTORICO: 'Histórico',
    HISTORICO_DE_ACOES: 'Histórico de ações',
    HISTORICO_DE_ARQUIVOS_ENVIADOS: 'Histórico de Arquivos Enviados',
    HOJE: 'Hoje',
    ID: 'ID',
    ID_CARTAO: 'ID Cartão',
    IMAGEM_CARTAO: 'IMAGEM CARTÃO',
    IMPORTAR: 'Importar',
    INFORMACOES: 'INFORMAÇÕES DO PORTADOR',
    INFORMACOES_GERAIS: 'Informações Gerais',
    INFORMACOES_IMPRESSAS: 'Informações Impressas',
    INFORMACOES_LOTE: 'Informações do Lote',
    INSTABILIDADE_NO_SISTEMA: 'No momento estamos passando por uma instabilidade no sistema, por favor tente novamente.',
    INSTITUICAO_PAGAMENTO: 'Instituição financeira/pagamento:',
    LANCAMENTO: 'Lançamento',
    LEMBRAR_ME: 'Lembrar-me',
    LIMITE_CARTOES: 'Limite de 100 cartões por pedido.',
    LINHA_DO_EXTRATO_SELECIONADA: 'linhaDoExtratoSelecionada',
    LIMPAR: 'Limpar',
    LISTA_DE_AUTORIZADOS: 'Lista de Autorizados',
    LISTA_DE_FAVORECIDOS: 'Lista de Favorecidos',
    LISTA_EMPRESAS: 'listaEmpresas',
    LOGRADOURO: 'Logradouro',
    LOTE: 'Lote',
    LOTES_DO_ARQUIVO: arquivoId => `Lotes - Arquivo ${arquivoId}`,
    ITENS_DO_LOTE: idLote => `Itens - Lote ${idLote}`,
    MEUS_COMPROVANTES: 'Meus Comprovantes',
    MIDIA: 'Mídia',
    MINHAS_CONTAS: 'Minhas Contas',
    MODAL: {
        CONFIRMACAO: 'confirmacao',
        FINALIZACAO: 'finalizacao',
        SUCESSO: 'sucesso',
        ERRO: 'erro'
    },
    MODAL_EXEMPLO_ARQUIVO_CSV_HEADER: 'Exemplo de Arquivo .CSV',
    MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO: {
        ITEM_1: 'Baixe o arquivo .CSV de exemplo e utilize o MS Excel para abrí-lo',
        ITEM_2: 'Preencha-o com seus dados conforme a tabela abaixo.',
        ITEM_2_1: 'Não apague a 1ª linha da planilha e mantenha a formatação das células como texto.',
        ITEM_2_2: 'Para solicitar apenas nome impresso, preencha somente as duas primeiras colunas.',
        ITEM_2_3: 'O nome impresso no cartão tem um limite de 19 caracteres e não aceita acentos.',
        ITEM_2_4: 'Seu arquivo ficará semelhante ao abaixo:',
        ITEM_3: 'Se o seu pedido de cartões for em lote basta preencher apenas as colunas (A e B).',
        ITEM_4: 'Pronto. Agora é só fazer o upload. Caso ocorra algum erro no arquivo o analisador irá apontá-lo na tela.'
    },
    MOSTRAR_MAIS: 'Mostrar mais',
    NAO: 'Não',
    NAO_HA_ITENS_EXTRATO: 'Não há itens de extrato para este período',
    NAO_HA_REGISTROS: 'Não há registros para o filtro informado',
    NENHUM_ANEXO_SOLUCAO: 'Nenhum Anexo/Solução encontrado(a)',
    NENHUM_BANCO_ENCONTRADO: 'Nenhum Banco Encontrado',
    NENHUM_CARTAO_ENCONTRADO: (param = '') => `Nenhum cartão ${param} encontrado`,
    NENHUM_FAVORECIDO_ENCONTRADO: 'Nenhum Favorecido Encontrado',
    NENHUMA_FORMA_ENTREGA_ENCONTRADA: 'Nenhuma Forma de Entrega Encontrado',
    NENHUMA_OPERACAO_ENCONTRADA: 'Nenhuma Operação Encontrada',
    NENHUM_PAIS: 'Nenhum País Encontrado',
    NENHUM_ESTADO: 'Nenhum Estado Encontrado',
    NENHUMA_CIDADE: 'Nenhuma Cidade Encontrada',
    NENHUM_COMBO: 'Nenhum Combo de Impressão Encontrado',
    NENHUM_ANEXO: 'Nenhum Anexo Encontrado',
    NENHUM_LANCAMENTO: 'Nenhum Lançamento Encontrado',
    NENHUM_REGISTRO_ENCONTRADO: 'Nenhum registro encontrado',
    NENHUM_STATUS_ENCONTRADO: 'Nenhum status encontrado',
    NENHUMA_PRESTACAO_ENCONTRADA: 'Nenhuma prestação de contas encontrada',
    NENHUM_PEDIDO_ENCONTRADO: 'Nenhum pedido encontrado',
    NENHUM_AGENDAMENTO_ENCONTRADO: 'Nenhum agendamento encontrado',
    NOME: 'Nome',
    NOME_COMPLETO: 'Nome Completo',
    NOME_FAVORECIDO: 'Nome do Favorecido',
    NOME_PORTADOR: 'Nome Portador',
    NOME_TITULAR: 'Nome Titular',
    NOVA_CONTA: 'Nova Conta',
    NUMERO: 'Número',
    OBSERVACAO: 'Observação',
    OBSERVACOES: 'Observações',
    OK: 'Ok',
    OPCOES_DO_FAVORECIDO: 'Opções do Favorecido',
    OPERACAO: 'Operação',
    PAGAMENTO_DE_CONTAS: 'Pagamento de Contas',
    PAGAR_CONTA: 'Pagar Conta',
    PAIS: 'País',
    PAN: 'PAN',
    PEDIDO_CARTAO_VIRTUAL: 'Pedido de Cartão Virtual',
    PEDIR_CARTAO: 'Pedir Cartão',
    PEDIR_CARTAO_VIRTUAL: 'Pedir Cartão Virtual',
    PEDIDOS: {
        CONSULTA_DE_PEDIDOS: 'Consulta de Pedidos',
        EMBOSSING_CARTAO: {
            CELULAR_PORTADOR: 'Celular Portador',
            CPF_PORTADOR: 'CPF Portador',
            DDD_PORTADOR: 'DDD Portador',
            DDI_PORTADOR: 'DDI Portador',
            EMAIL_PORTADOR: 'Email Portador',
            MODAL_HEADER: 'Pedido Cartão Impresso',
            NOME_IMPRESSO_CARTAO: 'Nome Impresso no Cartao',
            NOME_IMPRESSO_NA_CARTA_BERCO: 'Nome Impresso na Carta Berco',
            RESUMO_DO_PEDIDO: 'Resumo do Pedido'
        },
        MEUS: 'Meus Pedidos',
        NAO_HA_ITENS_CONSULTA_PEDIDOS: 'Não há registros para o período informado',
        PADRAO: 'Pedidos',
        VISUALIZAR_MAIS_PEDIDOS: 'Visualizar Mais Pedidos'
    },
    PERFIL: 'Perfil',
    PESSOA: {
        PADRAO: '',
        PRESTACAO_DE_CONTAS: {
            PRESTACAO_DE_CONTA: (apelido, descricao, idCartaoImpresso) => {
                let strPrestacaDeContas = 'Prestação de Conta';
                if (descricao !== DESCRICAO_CONTA.CONTA_CARTAO) {
                    strPrestacaDeContas = strPrestacaDeContas.concat(
                        ' - '.concat(
                            LABEL_COMUNS.CONTA_DIGITAL.concat(': ').concat(apelido)
                        )
                    );
                } else {
                    strPrestacaDeContas = strPrestacaDeContas.concat(
                        ' - '.concat(LABEL_COMUNS.CARTAO_ID(idCartaoImpresso))
                    );
                }
                return strPrestacaDeContas;
            }
        }
    },
    PESSOA_ID: 'pessoaId',
    PESSOA_LOGADA: 'pessoaLogada',
    PESSOA_TITULAR_ID: 'pessoaTitularId',
    PLACEHOLDER_DATANASC: 'DD/MM/AAAA',
    PONTO_REFERENCIA: 'Ponto de Referência',
    PORTADOR: 'Portador',
    PRESTAR_CONTAS: 'Prestar Contas',
    PRESTACAO_DE_CONTA: 'Prestação de Conta',
    PRESTACAO_DE_CONTAS: 'Prestação de Contas',
    PRESTACAO_DE_CONTAS_COMPROVANTE_ORIGINAL: 'Comprovante de Prestação de Contas',
    RELATORIOS: {
        ANALITICO: 'Analítico',
        RELATORIO_GERENCIAL: 'Relatório gerencial',
        GERENCIAL: 'Gerencial',
        PADRAO: 'Relatórios'
    },
    RELATORIO: {
        CABECALHO: {
            ID_CARTAO: 'ID Cart\u00e3o',
            NOME_PORTADOR: 'Nome Portador',
            CPF: 'CPF',
            DATA_TRANSACAO: 'Data da Transa\u00e7\u00e3o',
            HORA_TRANSACAO: 'Hora da Transa\u00e7\u00e3o',
            TIPO_TRANSACAO: 'Tipo da Transa\u00e7\u00e3o',
            CODIGO_AUTORIZACAO: 'C\u00f3digo de Autoriza\u00e7\u00e3o',
            STATUS_TRANSACAO: 'Status de Transa\u00e7\u00e3o',
            MOTIVO_RECUSA_TRANSACAO: 'Motivo Recusa Transa\u00e7\u00e3o',
            NOME_ESTABELECIMENTO: 'Nome do Estabelecimento',
            TIPO_ESTABELECIMENTO: 'Tipo Estabelecimento',
            MOEDA_CARTAO: 'Moeda do cart\u00e3o',
            VALOR_DEBITADO_BRL: 'Valor Debitado BRL',
            MOEDA_TRANSACAO: 'Moeda da Transa\u00e7\u00e3o',
            VALOR_TRANSACAO: 'Valor da Transa\u00e7\u00e3o',
            PRESTACAO_CONTAS: 'Presta\u00e7\u00e3o de Contas',
            STATUS_PRESTACAO_CONTAS: 'Status Presta\u00e7\u00e3o de Contas',
            DATA_PRESTACAO_CONTAS: 'Data da Presta\u00e7\u00e3o de Contas',
            CLASSIFICACAO_CLIENTE_PROJETO: 'Classifica\u00e7\u00e3o Cliente ou Projeto',
            CLASSIFICACAO_TIPO_DEPESA: 'Classifica\u00e7\u00e3o Tipo de Despesa',
            DESCRICAO_PRESTACAO_CONTAS: 'Descri\u00e7\u00e3o da Presta\u00e7\u00e3o de Contas',
            DATA_ANALISE_PRESTACAO_CONTAS: 'Data da Analise de Presta\u00e7\u00e3o de Contas',
            OBSERVACAO_PRESTACAO_CONTA: 'Observa\u00e7\u00e3o da Presta\u00e7\u00e3o de Conta',
            IMAGEM_ANEXADA: 'Imagem Anexada',
            SALDO_ATE_DIA_ANTERIOR: 'Saldo até o dia Anterior',
            SALDO_DIA: 'Saldo (dia 01)',
            STATUS_CARTAO: 'Status Cart\u00e3o',
            DATA_VALIDADE_CARTAO: 'Data Validade Cart\u00e3o'
        }
    },
    RESUMO_DO_PEDIDO: 'RESUMO DO PEDIDO',
    PRODUTO_PAG: 'PAG',
    PRODUTOS: 'Produtos',
    PRODUTOS_IDS_BPP_CORP: 'produtosIdsBppCorp',
    PRODUTOS_IDS_BPP_PAG: 'produtosIdsBppPag',
    QUANTIDADE: 'Quantidade',
    QUANTIDADE_DE_LOTES: 'Quantidade de Lotes',
    QUANTIDADE_DE_OPERACOES: 'Quantidade de Operações',
    QTDE_CARTOES: 'Quantidade de Cartões',
    QUATRO_ULTIMOS_DIGITOS: 'Quatro Últimos Dígitos',
    REENVIAR_TOKEN: 'Reenviar Token',
    REPROVAR: 'Reprovar',
    REVERSOES: 'Reversões',
    REPORTS_FILTER_ONTEM: 'ONTEM',
    REPORTS_FILTER_SEVEN_DAYS: '7 DIAS',
    REPORTS_FILTER_30_DAYS: '30 DIAS',
    REPORTS_FILTER_90_DAYS: '90 DIAS',
    REPORTS_FILTER_OTHERS: 'OUTROS PERÍODOS',
    SAIR: 'Sair',
    SALDO: 'Saldo',
    SALDO_CONTA_SELECIONADA: 'saldoContaSelecionada',
    SALDO_FINAL: 'Saldo final',
    SALDO_FINAL_DO_DIA: 'Saldo final do dia',
    SALDO_INICIAL: 'Saldo inicial',
    SALDO_INICIAL_DO_DIA: 'Saldo inicial do dia',
    SAQUE: 'Saque',
    SALVAR: 'Salvar',
    SALVAR_FAVORECIDO: 'Salvar Favorecido',
    SALVAR_PDF: 'Salvar como PDF',
    SELECIONE: 'Selecione',
    SELECIONE_ANEXO: 'Selecione o Anexo',
    SELECIONE_ANEXO_SOLUCAO: 'Selecione o Anexo/Solução',
    SELECIONE_COMBO_IMPRESSAO: 'Selecione o Combo de Impressão',
    SELECIONE_FAVORECIDO: 'Selecione o Favorecido',
    SELECIONE_TIPO_DE_CARTAO: 'Selecione o Tipo de Cartão',
    SENHA: 'Senha',
    SENHA_CRIADA_NO_APP: 'Senha criada no aplicativo BPP DIGITAL.',
    SIM: 'Sim',
    SOLICITAR_CARTAO: 'Solicitar Cartão',
    SOLICITAR_ESTOQUE: 'Solicitar Estoque',
    SOLICITAR_PAGAMENTO: 'Solicitar Pagamento',
    SOLICITAR_TRANSFERENCIA: 'Solicitar Transferência',
    SOLUCAO: {
        BPP_CONSUMER: 'BPP Consumer',
        BPP_CORP: 'BPP Corp',
        BPP_PAG: 'BPP Pag',
        MEU_BPP: 'Meu BPP',
    },
    SOLUCOES: 'Soluções',
    STATUS: 'Status',
    TAG_CLIENTE: 'TagCliente',
    TAMANHO_PAGINA_LISTA_CARTOES: 'tamanhoPaginaListaCartoes',
    TARIFAS: 'Tarifas',
    TED: 'TED',
    TENTE_NOVAMENTE: 'Tente Novamente',
    TIPO: 'Tipo',
    TIPO_ACESSO: 'tipoAcesso',
    TIPO_CONTA: 'Tipo Conta',
    TIPO_DE_CONTA: 'Tipo de Conta',
    TIPO_DOCUMENTO: 'Tipo Documento',
    TIPO_DE_ENTREGA: 'Tipo de Entrega',
    TITULAR: 'Titular',
    TOKEN: 'Token',
    TOOLTIP_FILTRO_FAVORITO: 'Os cartões favoritos não podem ser buscados pelos 4 últimos dígitos.',
    TRANSFERENCIA_BANCARIA: 'Transferência Bancária',
    TRANSFERENCIA_EM_LOTE: {
        ID: 'ID',
        DETALHES: {
            COLUNAS: {
                agencia: 'Agência',
                REMOVE_FAVORITO_SUCESSO: 'Exclusão do cartão favorito efetuada com sucesso',
                banco: 'Banco',
                conta: 'Conta',
                dataPagamento: 'Data do Pagamento',
                descricao: 'Descrição',
                digito: 'Dígito',
                documentoFavorecido: 'Documento do favorecido',
                favorecido: 'Favorecido',
                idCartao: 'ID Cartão',
                valor: 'Valor'
            }
        },
        INCLUIR_NOVO_ARQUIVO: (operacao = '') => {
            let tituloParaRetornar = 'Incluir novo arquivo: ';
            switch (operacao) {
                case OPERACAO.NOME.TRANSFERENCIA_EM_LOTE.CARGA_CARTAO_LOTE_BPP_PAG:
                    tituloParaRetornar = tituloParaRetornar.concat(
                        LABEL_COMUNS.CARTOES.TITULOS.CARGA_CARTAO
                    );
                    break;
                case OPERACAO.NOME.TRANSFERENCIA_EM_LOTE.TED_LOTE_BPP_PAG:
                    tituloParaRetornar = tituloParaRetornar.concat(LABEL_COMUNS.TED);
                    break;
                default:
                    tituloParaRetornar = tituloParaRetornar.concat(
                        LABEL_COMUNS.TRANSFERENCIA_ENTRE_CONTAS
                    );
                    break;
            }
            return tituloParaRetornar;
        },
        QUAL_O_MODELO_DE_ARQUIVO: 'Qual o modelo do arquivo?',
        TITULO: (parametro = '') => `Transferência em Lote ${parametro}`,
        TITULO_LINK_INSTRUCOES_EXEMPLO_CSV: 'Ver arquivo de exemplo'
    },
    TRANSFERENCIA_ENTRE_CONTAS: 'Transferência entre Contas',
    TRANSFERENCIA_SELECIONADA_ID: 'transferenciaSelecionadaId',
    TRANSFERIR: 'Transferir',
    TRANSACAO: 'Transação',
    UF: 'UF',
    USUARIO: 'Usuário',
    VALOR: 'Valor',
    VALOR_A_PROCESSAR: 'Valor a processar',
    VALOR_ATE: 'Valor até',
    VALOR_DA_TRANSACAO: 'Valor da Transação',
    VALOR_DE: 'Valor de',
    VALOR_TRANSFERENCIA: 'Valor transferência R$',
    VENCIMENTO: 'Vencimento',
    VER_ARQUIVO_EXEMPLO: 'Ver arquivo de exemplo',
    VER_ERROS: 'Ver Erros',
    VINCULAR: 'Vincular',
    VINCULAR_CARTAO: 'Vincular Cartão',
    VINCULAR_PORTADOR: 'Vincular Portador',
    VISUALIZAR: 'Visualizar',
    VISUALIZAR_ARQUIVO: 'Visualizar Arquivo',
    VISUALIZAR_INFORMACOES_DOS_PERFIS: 'Visualizar Informações dos Perfis',
    VISUALIZAR_MAIS_MOVIMENTACOES: ' VISUALIZAR MAIS MOVIMENTAÇÕES',
    VOLTAR: 'Voltar'
};

export const MASCARA_CAMPOS = {
    AGENCIA: '9999',
    CONTA_BPP: '9999999999',
    CONTA_OUTROS_BANCOS: '99999999999999999999',
    CARTAO_ID: '999999999999999',
    ID: '999999999',
    CARTAO_QUATRO_ULTIMOS_DIGITOS: '9999',
    CODIGO_DE_BARRAS: '999999999999999999999999999999999999999999999999',
    DIGITO: '9',
    CPF: '999.999.999-99',
    CNPJ: '99.999.999/9999-99',
    DATA: '99/99/9999',
    MOBILE: '(99) 99999-9999',
    NUMERO_CARTAO: '9999.9999.9999.9999',
    TOKEN: '999999'
};

export const MENSAGENS = {
    ACESSO_SOLUCAO: {
        BPP_CORP: () => {
            return `Para quem ama produtividade BPP Corp reúne sob uma única plataforma uma solução digital empresarial completa e uma robusta central de 
            pré-autorização de gastos, gerenciamento de cartões e comprovação de gastos corporativos.<br /><br /> LIGUE AGORA, <b>É GRÁTIS</b> (11) 3588-0301`;
        },
        BPP_PAG: () => {
            return `Para quem ama simplicidade. Com o BPP Pag na sua conta digital, você poderá realizar pagamentos à outras empresas e pessoas 
                    físicas com a flexibilidade e periodicidade que desejar.
                    O BPP Pag simplifica e automatiza o processo de contas a pagar da sua empresa, realizando o pagamento a seus fornecedores,
                    prestadores de serviços ou parceiros.<br /><br />
                    LIGUE AGORA, <b>É GRÁTIS</b> (11) 3588-0301`;
        }
    },
    AGENDAMENTO_E_APROVACOES: {
        DASHBOARD_BPP_PAG: {},
        DASHBOARD_CONTA: {
            EFETIVACAO_DA_OPERACAO: {
                SUCESSO: transacao =>
                    `${transacao === TIPO_DE_TRANSACAO.APROVACAO.transacao ? ' Aprovação realizada com sucesso' : (transacao === TIPO_DE_TRANSACAO.AGENDAMENTO.transacao ? ' Aprovação realizada com sucesso' : ' Cancelamento realizado com sucesso')}`,
                ERRO: transacao =>
                    `Não foi possíver realizar ${transacao === TIPO_DE_TRANSACAO.APROVACAO.transacao ? 'a aprovação' : (transacao === TIPO_DE_TRANSACAO.AGENDAMENTO.transacao ? 'a aprovação' : 'o cancelamento')}`
            }
        }
    },
    ANEXO_CONTRATUAL: {
        ANEXO_NAO_ENCONTRADO_PARA_CRIACAO_DE_CONTAS: `Para quem ama simplicidade. Sua conta digital empresarial que possibilita a gestão por meio de 
        aplicativo móvel ou plataforma Web, de forma 100% segura e automatizada, onde você centraliza as Soluções BPP contratadas.<br /><br />
        LIGUE AGORA, É GRÁTIS (11) 3588-0301`
    },
    CARTAO: {
        ALTERAR_PORTADOR: {
            ERRO: 'Ocorreu um erro ao alterar o portador.',
            SUCESSO: 'Solicitação de alteração de portador realizada com sucesso! Lembre-se: aconselhamos que o novo portador altere a senha do cartão antes de utilizá-lo.',
            EM_PROCESSAMENTO: 'Existe uma alteração de portador em andamento para este cartão. Por favor, aguarde alguns instantes.',
            CONFIRMACAO: (embossingId, pan) =>
                `Deseja realmente alterar o portador do cartão <b>ID</b> <b>${embossingId}</b> <b>PAN</b> <b>${pan}</b>?`
        },
        ALTERAR_APELIDO_CARTAO: {
            CONFIRMACAO: cartaoId =>
                `Confirma a altera\u00e7\u00e3o de apelido para o cart\u00e3o <b>${cartaoId}</b>?`,
            FINALIZACAO: {
                ERRO: 'Erro ao solicitar a alteração de apelido. Tente novamente!',
                SUCESSO: 'Apelido do cartão alterado com sucesso!'
            },
            VALIDACAO: {
                TAMANHO_EXCEDIDO: 'Tamanho do apelido não deve exceder 15 caracteres'
            }
        },
        ALTERAR_PIN_CARTAO: {
            CONFIRMACAO: cartaoId =>
                `Confirma a altera\u00e7\u00e3o de senha para o cart\u00e3o <b>${cartaoId}</b>?`,
            FINALIZACAO: {
                ERRO: cartaoId =>
                    `Erro ao solicitar a altera\u00e7\u00e3o de senha do cart\u00e3o <b>${cartaoId}</b>. Tente novamente!`,
                SUCESSO: (cartaoId, podeAprovar) => {
                    let messageToReturn = `Solicita\u00e7\u00e3o de altera\u00e7\u00e3o de senha do cart\u00e3o ${cartaoId} efetuada com sucesso.`;
                    if (podeAprovar) {
                        messageToReturn = `Senha do cart\u00e3o <b>${cartaoId}</b> alterado com sucesso!`;
                    }
                    return messageToReturn;
                }
            },
            OPERACAO: {
                EM_PROCESSAMENTO: 'Existe uma solicita\u00e7\u00e3o de altera\u00e7\u00e3o de senha em andamento para este cart\u00e3o. Por favor, aguarde alguns instantes.'
            }
        },
        ALTERAR_TITULARIDADE_CARTAO: {
            CONFIRMACAO: 'Confirma a transferência de titularidade do cartão?',
            FINALIZACAO: {
                ERRO: 'Erro ao Transferir a  Titularidade do Cartão.',
                SUCESSO: 'Titularidade do cartão transferida com sucesso.'
            }
        },
        ATIVAR_CARTAO: {
            CONFIRMACAO: cartaoId =>
                `Por favor, cadastre sua senha com 4 d\u00edgitos do cart\u00e3o <b>${cartaoId}</b>`,
            FINALIZACAO: {
                ERRO: cartaoId =>
                    `Erro ao ativar do cart\u00e3o <b>${cartaoId}</b>. Tente novamente!`,
                SUCESSO: cartaoId => {
                    return `Cart\u00e3o <b>${cartaoId}</b> ativado com sucesso.`;
                }
            }
        },
        BLOQUEAR_OU_DES_BLOQUEAR: {
            CONFIRMACAO: (nomeDaOperacao, id, pan) => {
                if (nomeDaOperacao === OPERACAO.NOME.ALTERA_STATUS_CARTAO) {
                    return `Deseja realmente bloquear o cartão <b>ID</b> ${id} <b>N\u00famero</b> ${pan}?`;
                }
                return `Deseja realmente desbloquear o cartão <b>ID</b> ${id} <b>N\u00famero</b> ${pan}?`;
            },
            FINALIZACAO: {
                ERRO: nomeDaOperacao => {
                    if (nomeDaOperacao === OPERACAO.NOME.ALTERA_STATUS_CARTAO) {
                        return 'Erro ao efetuar o bloqueio de cartão';
                    }
                    return 'Erro ao efetuar o desbloqueio de cartão';
                },
                SUCESSO: nomeDaOperacao => {
                    if (nomeDaOperacao === OPERACAO.NOME.ALTERA_STATUS_CARTAO) {
                        return 'Solicitação de bloqueio efetuada com sucesso.';
                    }
                    return 'Solicitação de desbloqueio efetuada com sucesso.';
                }
            },
            OPERACAO_EM_ANDAMENTO: 'Um processo de atualização está em andamento. Aguarde.'
        },
        CANCELAR_CARTAO: {
            CONFIRMACAO: quatroUltimosDigitos => {
                return `Deseja realmente cancelar o cartão com final <strong>${quatroUltimosDigitos}</strong>?`;
            },
            FINALIZACAO: {
                ERRO: () => {
                    return 'Erro ao realizar o cancelamento do cartão. Tente novamente.';
                },
                ERRO_SALDO: quatroUltimosDigitos => {
                    return `Cartão com final <strong>${quatroUltimosDigitos}</strong> possui saldo e não pode ser cancelado. Para realizar o cancelamento, descarregue o saldo total do cartão e tente novamente!`;
                },
                SUCESSO: () => {
                    return 'Solicitação de cancelamento de cartão efetuada com sucesso.';
                }
            },
            OPERACAO_EM_ANDAMENTO: 'Um processo de atualização está em andamento. Aguarde.'
        },
        CARGA_E_DESCARGA: {
            FINALIZACAO: {
                ERRO: {
                    CARGA_CARTAO: (parametro = '', podeAprovar = '', errorMessage) => {
                        return errorMessage ? `Não foi possível efetuar a solicitação de carga cartão. Motivo: ${errorMessage}` : `Não foi possível efetuar a solicitação ${podeAprovar} de ${parametro}carga cartão.`;
                    },
                    DESCARGA_CARTAO: (parametro = '', podeAprovar = '', errorMessage) => {
                        return errorMessage ? `${errorMessage == 'Erro ao Efetuar Descarga Cartão' ? 'Não foi possível efetuar a solicitação, devido o valor máximo por descarga ser de R$500,00.' : errorMessage }` : `Não foi possível efetuar a solicitação de descarga cartão  ${podeAprovar} de ${parametro}.`;
                    }
                },
                SUCESSO: {
                    CARGA_CARTAO: (parametro = '', podeAprovar = '') =>
                        `Solicitação ${podeAprovar} de ${parametro}carga cartão efetuada com sucesso.
            \nAtenção: para que a operação seja concluída com êxito, é necessário ter saldo suficiente no momento da efetivação.`,
                    DESCARGA_CARTAO: (parametro = '', podeAprovar = '') =>
                        `Solicitação ${podeAprovar} de ${parametro}descarga cartão efetuada com sucesso.
            \nAtenção: para que a operação seja concluída com êxito, é necessário ter saldo suficiente no momento da efetivação.`
                }
            }
        },
        FAVORITOS: {
            CADASTRO: {
                CONFIRMACAO: '',
                ERRO: 'Não foi possível cadastrar o cartão como favorito. Confirme os dados inseridos e tente novamente.',
                SUCESSO: 'Cadastro de cartão favorito realizado com sucesso.'
            },
            EXCLUSAO: {
                SUCESSO: 'Exclusão do cartão favorito efetuada com sucesso'
            }
        },
        VINCULAR_CARTAO: {
            CANCELAMENTO: 'Deseja cancelar a vinculação do cartão nesta conta?',
            CONFIRMACAO: 'Deseja vincular este cartão a conta?',
            FINALIZACAO: {
                ERRO: 'Erro ao solicitar o vínculo. Tente novamente!',
                SUCESSO: 'Cartão vinculado com sucesso!'
            }
        }
    },
    CONTAS: {
        CONTA_OU_DIGITO_NAO_ENCONTRADA: 'Conta não encontrada',
        CRIACAO_CONTA: {
            FINALIZACAO: {
                SUCESSO: 'Sua numeração da conta digital foi reservada e estará disponível no próximo dia útil. Aguarde este período para realizar o depósito inicial (via TED ou transferência de uma conta BPP).',
                ERRO: 'Não foi possível efetuar sua solicitação.'
            }
        },
        EDICAO: {
            INSERIR_OUTORGA_PESSOA: {
                CONFIRMACAO: (nome, numeroConta, digito) =>
                    `Confirma ${nome} como autorizado da conta ${numeroConta + '-' + digito}?`,
                FINALIZACAO: {
                    ERRO: 'Erro ao inserir autorizado',
                    SUCESSO: 'Autorizado inserido com sucesso.'
                }
            },
            EDICAO_OUTORGA_PESSOA: {
                CONFIRMACAO: (nome, numeroConta, digito) =>
                    `Confirma a edição do autorizado (a) ${nome} para a conta ${numeroConta}-${digito}?`,
                FINALIZACAO: {
                    ERRO: 'Erro ao editar autorizações do autorizado',
                    SUCESSO: 'Alterações do autorizado (a) efetuada com sucesso.'
                }
            },
            EXCLUSAO_OUTORGA: {
                CONFIRMACAO: nome =>
                    `Confirma a exclusão do (a) autorizado (a)  ${nome}?`,
                FINALIZACAO: {
                    ERRO: (nome, numeroConta, digito) =>
                        `Erro ao excluir o autorizado (a) ${nome} da conta ${numeroConta}-${digito}`,
                    SUCESSO: (nome, numeroConta, digito) =>
                        `Exclusão do autorizado (a) ${nome} da conta ${numeroConta}-${digito} efetuada com sucesso.`
                }
            },
            CONFIRMACAO: (numeroConta, digito) =>
                `Confirma a edição da conta ${numeroConta}-${digito}?`,
            FINALIZACAO: {
                ERRO: 'Não foi possível editar a conta',
                SUCESSO: 'Conta editada com sucesso.'
            },
            CANCELAMENTO: numeroConta =>
                `Deseja realmente cancelar a edição da conta ${numeroConta}?`
        },
        FAVORECIDO: {
            CADASTRO: {
                CONFIRMACAO: nomeFavorecido =>
                    `Deseja adicionar o favorecido ${nomeFavorecido}?`,
                FINALIZACAO: {
                    ERRO: nomeFavorecido =>
                        `Erro ao salvar o favorecido ${nomeFavorecido}.`,
                    SUCESSO: nomeFavorecido =>
                        `Favorecido ${nomeFavorecido} adicionado com sucesso!`
                }
            },
            CANCELAMENTO: 'As informações serão perdidas. Deseja continuar?',
            EDICAO: {
                CONFIRMACAO: nomeFavorecido =>
                    `Deseja editar o favorecido ${nomeFavorecido}?`,
                FINALIZACAO: {
                    ERRO: nomeFavorecido =>
                        `Erro ao editar o favorecido ${nomeFavorecido}.`,
                    SUCESSO: nomeFavorecido =>
                        `Favorecido ${nomeFavorecido} editado com sucesso!`
                }
            },
            EXCLUSAO: {
                CONFIRMACAO: nomeFavorecido =>
                    `Deseja excluir o favorecido ${nomeFavorecido}?`,
                FINALIZACAO: {
                    ERRO: nomeFavorecido =>
                        `Erro ao excluir o favorecido ${nomeFavorecido}.`,
                    SUCESSO: nomeFavorecido =>
                        `Favorecido ${nomeFavorecido} excluído com sucesso!`
                }
            },
            VALIDACAO: {
                MESMO_FAVORECIDO: 'O Favorecido não deve ser a própria conta.'
            }
        },
        PAGAMENTOS_DE_CONTAS: {
            CODIGO_BARRAS: 'Código de barras inválido',
            CONFIRMACAO: dataAgendamento => {
                let mensagemParaRetornar = 'Ol\u00E1! Fique atento!<br/>';
                if (dataAgendamento) {
                    mensagemParaRetornar = mensagemParaRetornar.concat(
                        'Pagamentos de conta agendados para finais de semana ser\u00E3o processados no pr\u00F3ximo dia \u00FAtil.'
                    );
                } else {
                    mensagemParaRetornar = mensagemParaRetornar.concat(
                        'A BPP n\u00E3o realiza pagamento de boletos vencidos. Por favor, antecipe seus pagamentos!<br />'
                    );
                    mensagemParaRetornar = mensagemParaRetornar.concat(
                        'Pagamentos realizados ap\u00F3s as 18:00 ou aos finais de semana ser\u00E3o processados no pr\u00F3ximo dia \u00FAtil.'
                    );
                }
                return mensagemParaRetornar;
            },
            DOCUMENTO: 'Documento inválido',
            NOME_BENEFICIARIO: 'Nome beneficiário inválido',
            VALOR: 'Valor inválido',
            SALDO: 'Não há saldo suficiente para realizar o pagamento.',
            VENCIMENTO: 'Data inválida',
            VENCIMENTO_MENOR: 'Data menor que a atual',
            AGENDAMENTO_DATA: 'Data inválida',
            AGENDAMENTO_DATA_MENORIGUAL: 'Data menor ou igual a atual',
            AGENDAMENTO_DATA_MAIOR_QUE_UM_ANO: 'Data maior do que um ano',
            AGENDAMENTO_DATA_NAO_PERMITIDA: 'Entre os dias 04/01/2020 e 08/01/2020 não será possível fazer agendamentos. Você poderá realizar as operações online (aplicativo e web) durante esse período normalmente ou fazer agendamento a partir do dia 09/01/2020.',
            SUCESSO: 'Solicitação de pagamento efetuada com sucesso',
            ERRO: 'Erro ao realizar a solicitação de pagamento. Tente novamente',
            ERRO_TOKEN: 'Token inválido'
        },
        TRANSFERENCIA_BANCARIA: {
            CONFIRMACAO: dataAgendamento => {
                let mensagemParaRetornar = 'Hor\u00E1rio dispon\u00edvel para envio de TED at\u00e9 as 16:30h em dias \u00FAteis.<br/>';
                if (dataAgendamento) {
                    mensagemParaRetornar = mensagemParaRetornar.concat(
                        'TEDs agendadas para finais de semana ser\u00E3o processadas no pr\u00F3ximo dia \u00FAtil.'
                    );
                } else {
                    mensagemParaRetornar = mensagemParaRetornar.concat(                       
                        'Transfer\u00eancias realizadas para outros bancos fora deste hor\u00E1rio, feriados ou finais de semana poder\u00E3o ser agendadas para o pr\u00f3ximo dia \u00FAtil.'
                    );
                }
                return mensagemParaRetornar;
            },
            FINALIZACAO: {
                ERRO: (agendamento, erroAoSalvarFavorecido) => {
                    let mensagemErro = `Não foi possível efetuar sua solicitação de ${agendamento ? 'agendamento' : 'transferência'}.`;
                    if (erroAoSalvarFavorecido) {
                        mensagemErro = mensagemErro.concat(
                            `<br/> ${erroAoSalvarFavorecido}`
                        );
                    }
                    return mensagemErro;
                },
                SUCESSO: (agendamento, erroAoSalvarFavorecido) => {
                    let mensagemSucesso = `Solicitação de ${agendamento ? 'agendamento' : 'transferência'} efetuada com sucesso.`;
                    if (erroAoSalvarFavorecido) {
                        mensagemSucesso = mensagemSucesso.concat(
                            `<br/> <br />OBS: ${erroAoSalvarFavorecido} `
                        );
                    }
                    return mensagemSucesso;
                }
            },
            TRANSFERENCIA_NAO_PERMITIDA: 'Transferência entre as contas informadas não permitida.'
        }
    },
    HISTORICO: {
        ARQUIVOS: {
            ERRO_CHAMADA: 'No momento não foi possível exibir o histórico de arquivos solicitado. Tente novamente mais tarde.',
            ERRO_VAZIO: 'Você não inseriu nenhum arquivo no período solicitado.'
        },
        OPERACOES: {
            ERRO_CHAMADA: 'No momento não foi possível exibir o histórico de operações solicitado. Tente novamente mais tarde.',
            ERRO_VAZIO: 'Você não tem nenhuma operação no valor e/ou status solicitado.'
        }
    },
    ERRO_DE_REQUISICAO_GENERICA: 'No momento n\u00e3o foi poss\u00edvel atender a sua solicitac\u00e7\u00e3o. Tente novamente mais tarde.',
    LOGIN: {
        CADASTRO: 'Para cadastrar-se afim de ter uma conta BPP Digital, É necessário baixar o aplicativo nas lojas:',
        FINALIZACAO: {
            ERRO: 'Usuário e/ou senha inválidos.'
        }
    },
    OPERACAO: {
        CONFIRMACAO: transacao => {
            let mensagem;
            switch (transacao) {
                case TIPO_DE_TRANSACAO.CANCELAMENTO.transacao:
                    mensagem = 'Confirma o cancelamento da operação?';
                    break;
                case TIPO_DE_TRANSACAO.CANCELAMENTO_AGENDAMENTO.transacao:
                    mensagem = 'Confirma o cancelamento da operação agendada?';
                    break;
                default:
                    mensagem = 'Confirma a aprovação da operação?';
                    break;
            }
            return mensagem;
        }
    },
    OPERACOES_EM_LOTE: {
        CARGA_CARTAO: {
            FINALIZACAO: {
                SUCESSO: 'Solicitação de carga cartão em lote efetuada com sucesso.'
            }
        }
    },
    PEDIDOS: {
        EMBOSSING_CARTAO: {
            CANCELAMENTO: 'Deseja realmente cancelar o pedido de cart\u00e3o?',
            CONFIRMA_ENVIO_PEDIDO: 'Confirma o envio de pedido de cart\u00e3o?',
            CONFIRMACAO: (
                exibirModalConfirmacao,
                exibirModalConfirmacaoVoltar,
                arquivoSelecionado,
                podeAprovar
            ) => {
                let mensagemRetorno =
          'Todos os dados inseridos serão perdidos. Deseja confirmar o cancelamento?';
                if (exibirModalConfirmacao) {
                    if (podeAprovar) {
                        mensagemRetorno = 'Confirma o cadastro e a aprovação da operação?';
                    } else {
                        mensagemRetorno = 'Confirma o cadastro da operação?';
                    }
                } else if (arquivoSelecionado && exibirModalConfirmacaoVoltar) {
                    mensagemRetorno =
            'Arquivo não será salvo. Deseja voltar mesmo assim?';
                }
                return mensagemRetorno;
            },
            FINALIZACAO: {
                ERRO: 'Não foi possível efetuar o pedido de cartão.',
                SUCESSO: 'Pedido de cartão efetuado com sucesso.'
            },
            VALIDADACAO_ARQUIVO: {
                ARQUIVO_INVALIDO: 'Arquivo não é compatível com a operação solicitada, favor conferir o modelo disponibilizado.',
                CAMPO_INVALIDO: (
                    linha,
                    nomeDoCampo,
                    valorDoCampo,
                    erroData,
                    formatos
                ) => {
                    let mensagemRetorno = `Linha ${linha}: Campo "${nomeDoCampo}" não preenchido.`;

                    if (
                        erroData.tipo ===
            TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO
                    ) {
                        mensagemRetorno = `Linha ${linha}: Limite de ${erroData.numeroMaximoDeCaracter} caracteres excedido no campo "${nomeDoCampo}".`;
                    }

                    if (
                        erroData.tipo ===
            TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO
                    ) {
                        mensagemRetorno = `Linha ${linha}: Campo "${nomeDoCampo}" inválido. Verifique o arquivo modelo.`;
                    }

                    /*  if (formatos) {
                         mensagemRetorno = mensagemRetorno + ` Formato(s) correto(s): ${formatos}. Verifique o arquivo modelo.`;
                     } */
                    return mensagemRetorno;
                },
                CAMPOS_ENDERECO_LOTE_NAO_PREENCHIDOS: (
                    linha,
                    listaCamposNaoPreenchido
                ) => {
                    let campos = '';
                    let plural = '';
                    let mensagemRetorno = '';
                    for (let i = 0; i < listaCamposNaoPreenchido.length; i++) {
                        campos = campos.concat(
                            '"'.concat(listaCamposNaoPreenchido[i]).concat('"')
                        );
                        if (i === listaCamposNaoPreenchido.length - 2) {
                            campos = campos.concat(' e ');
                            plural = 's';
                        } else if (i < listaCamposNaoPreenchido.length - 1) {
                            campos = campos.concat(', ');
                            plural = 's';
                        }
                        mensagemRetorno = `Linha ${linha}: Campo${plural} ${campos} não preenchido${plural}.`;
                    }
                    return mensagemRetorno;
                },
                DADOS_INVALIDOS: linha => `Linha ${linha}: Dados inválidos.`,
                ENDERECO_NAO_INFORMADO: linha =>
                    `Linha ${linha}: Endereço não informado.`,
                LIMITE_CARACTERE_EXCEDIDO: (linha, nomeDoCampo, valorDoCampo) =>
                    `Linha ${linha}: Limite de caractere excedido do campo ${nomeDoCampo} (${valorDoCampo}) `,
                LINHA_VAZIA: linha => `Linha ${linha}: vazia.`,
                NUMERO_DE_CARACTER_EXCEDE_PERMITIDO: (
                    linha,
                    nomeDoCampo,
                    quantidadeCaracteresPermitidos
                ) => {
                    let mensagemRetorno = `Linha ${linha}: Campo ${nomeDoCampo} excede ${quantidadeCaracteresPermitidos} caracters.`;
                    return mensagemRetorno;
                },
                NUMERO_DE_CARTOES_DIFERENTE_QUE_NUMERO_DE_LINHAS: 'Quantidade de cartões diferente do número de linhas do arquivo.'
            },
            VALIDACAO_DE_SALDO: () => {
                return `Ops! <br/> Você não possui saldo para solicitar cartões. <br/> Carregue sua <a href="${ROTAS_CAMINHOS.PEDIDO_DE_CARGA}"><b>conta digital</b></a> agora!`;
            }
        },
        CARTAO_VIRTUAL: {
            FINALIZACAO: {
                SUCESSO: 'Pedido de cartão virtual efetuado com sucesso',
                ERRO: 'Não foi possível efetuar seu pedido de cartão virtual'
            }
        }
    },
    PESSOA: {
        PRESTACAO_DE_CONTAS: {
            CONFIRMACAO: cancelarPrestacaoDeContas =>
                `Confirma ${cancelarPrestacaoDeContas ? 'cancelar' : ''} a prestação de conta?`,
            CONFIRMACAO_COM_VALIDACAO: (status, posicaoMensagem) => {
                let mensagemRetorno;
                if (status) {
                    mensagemRetorno = 'Prestação de conta já realizada';
                    switch (status) {
                        case STATUS.PRESTACAO_DE_CONTAS.APROVADO:
                            mensagemRetorno = mensagemRetorno.concat(
                                ' e aprovada! <br/><br/>  Deseja editar mesmo assim?'
                            );
                            break;
                        case STATUS.PRESTACAO_DE_CONTAS.REPROVADO:
                            mensagemRetorno = mensagemRetorno.concat(
                                ' e reprovada! <br/><br/> Deseja editar e reencaminhar para aprovação?'
                            );
                            break;

                        default:
                            mensagemRetorno = mensagemRetorno.concat(
                                ' e pendente de aprovação! <br/>  Deseja editar mesmo assim?'
                            );
                            break;
                    }
                }

                /*  if (status) {
                     if (status === STATUS.PRESTACAO_DE_CONTAS.APROVADO) {
                         if (posicaoMensagem === 1) {
                             mensagemRetorno = "Prestação de conta já realizada e aprovada!"
                         } else if (posicaoMensagem === 2) {
                             mensagemRetorno = "Deseja editar mesmo assim?"
                         }
                     }
                     if (status === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_DE_APROVACAO) {
                         if (posicaoMensagem === 1) {
                             mensagemRetorno = "Prestação de conta já realizada e pendente de aprovação."
                         } else if (posicaoMensagem === 2) {
                             mensagemRetorno = "Deseja editar mesmo assim?"
                         }
                     }
                     if (status === STATUS.PRESTACAO_DE_CONTAS.RECUSADO) {
                         if (posicaoMensagem === 1) {
                             mensagemRetorno = "Prestação de conta já realizada e reprovada."
                         } else if (posicaoMensagem === 2) {
                             mensagemRetorno = "Deseja editar e reencaminhar para aprovação?"
                         }
                     }
                 } */
                return mensagemRetorno;
            },
            FINALIZACAO: {
                ERRO: 'Não foi possível efetuar a presatação de contas.',
                SUCESSO: 'Prestação de conta realizada com sucesso.'
            }
        }
    },
    REPORTS: {
        EMPTY_CARTAO: 'Você não possui cartões para o período selecionado.',
        EMPTY_CLIENTE_PROJETO: 'Você não possui nenhum parâmetro de cliente/projeto cadastrado. Caso você queira cadastrar agora, ',
        EMPTY_DESPESA: 'Você não possui nenhum parâmetro de tipo de despesa cadastrado. Caso você queira cadastrar agora, ',
        EMPTY_MOVIMENTACAO: 'Você não possui movimentação no período solicitado',
        EMPTY_PRESTACAO: 'Você não possui prestação de contas para o período selecionado.',
        MANAGEMENT: {
            MSG_ERRO_CSV: 'No momento não foi possível gerar o arquivo solicitado',
            EMPTY_RESPONSE: 'Voc\u00ea n\u00e3o possui nenhuma movimenta\u00e7\u00e3o no per\u00edodo solicitado',
            ERROR: 'No momento n\u00e3o foi poss\u00edvel exibir o relat\u00f3rio solicitado. Tente novamente mais tarde.'
        }
    },
    TRANSFERENCIA_EM_LOTE: {
        ARQUIVO_INVALIDO: 'Arquivo não é compatível com a operação solicitada, favor conferir o modelo disponibilizado.',
        ARQUIVOS_RETORNO: {
            ERRO_CHAMADA: 'No momento não foi possível exibir os arquivos de retorno solicitado. Tente novamente mais tarde.',
            ERRO_VAZIO: 'Não há dados para serem mostrados.'
        },
        ARQUIVO_SEM_LINHAS: 'Não há linhas preenchidas no arquivo.',
        CONFIRMACAO: {
            ENVIO: {
                APROVAR_OU_REPROVAR_ARQUIVO: acao =>
                    `Deseja ${acao === ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value ? 'aprovar' : 'reprovar'} o processamento do lote?`,
                SOLICITACAO_ENVIO_ARQUIVO: () =>
                    'Confirma o envio do arquivo para processamento?'
            },
            SALDO_INSUFICIENTE: 'Saldo insuficiente para aprovar o lote.',
            APROVACAO: 'Confirma a aprovação do arquivo?',
            REPROVACAO: 'Confirma a reprovação do arquivo?'
        },
        DATAS_DVERGENTES_NO_MESMO_ARQUIVO: 'Lançamentos no lote devem conter a mesma data de pagamento.',
        EXTENSAO_INVALIDA: extensao =>
            `extens\u00E3o ${extensao} inv\u00E1lida para o modelo de arquivo.`,
        FINALIZACAO: {
            ERRO: {
                APROVAR_OU_REPROVAR_ARQUIVO: acao =>
                    `Não foi possível  ${acao === ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value ? 'aprovar' : 'reprovar'} o arquivo.`,
                SOLICITACAO_ENVIO_ARQUIVO: () =>
                    'Não foi possível efetuar a solicitação de transferência em arquivo.'
            },
            SUCESSO: {
                APROVAR_OU_REPROVAR_ARQUIVO: mensagem => mensagem,
                SOLICITACAO_ENVIO_ARQUIVO: () =>
                    'Solicitação de transferência em lote efetuada com sucesso.'
            }
        },
        NUMERO_DE_LINHAS_DO_ARQUIVO_EXCEDIDAS: 'Lote não pode conter mais de mil operações.',
        UPLOAD_CANCELADO: 'Upload do arquivo será cancelado. Deseja continuar?',
        VALIDADACAO_ARQUIVO: {
            CAMPO_INVALIDO: (linha, nomeDoCampo, valorDoCampo, formato) => {
                let mensagemRetorno = `Linha ${linha}: Campo ${nomeDoCampo} inválido (${valorDoCampo}).`;
                if (formato) {
                    mensagemRetorno =
            mensagemRetorno +
            ` Formato correto: ${formato}. Verifique o arquivo modelo.`;
                }
                return mensagemRetorno;
            },
            DADOS_INVALIDOS: linha => `Linha ${linha}: Dados inválidos.`,
            LIMITE_CARACTERE_ARQUIVO_EXCEDIDO: (linha, nomeDoCampo, valorDoCampo) =>
                `Linha ${linha}: Limite de caractere excedido do campo ${nomeDoCampo} (${valorDoCampo}) `,
            LINHA_VAZIA: linha => `Linha ${linha}: vazia.`,
            TIPO_DE_CONTA_ERRO: linha =>
                `Linha ${linha}: Campo tipoConta inválido. Tipos permitidos: CORRENTE ou POUPANÇA`
        },
        MODAL_TRANSFERENCIA_EM_LOTE_INSTRUCOES: {
            CSV: {
                // Instruções comuns aos 3 tipos de transferencias em lote
                ITEM_COMUM_1: 'Baixe o arquivo .CSV de exemplo e utilize o MS Excel para abrí-lo',
                ITEM_COMUM_2: 'Preencha-o com seus dados conforme a tabela exemplo abaixo.',
                ITEM_COMUM_21: 'Não apague a 1ª linha da planilha e mantenha a formatação das células como texto.',
                // Sub-itens (bullets) do item acima. Retorna a instruções específicas dependendo do tipo da operação de transferencia.
                ITENS_ESPECIFICOS: operacao => {
                    let instrucoes = [
                        'Não apague a 1ª linha da planilha e mantenha a formatação das células como texto.',
                        'O preenchimento da coluna "descrição" é opcional. ',
                    ];
                    if (operacao === OPERACOES_TRANSFERENCIA_EM_LOTE.CARGA_CARTAO.value) {
                        instrucoes.push(
                            'As demais colunas são de preenchimento obrigatório.'
                        );
                    }
                    instrucoes.push('Atente-se aos campos numéricos e separe os decimais de valor com ponto ou vírgula.');
                    if (
                        operacao ===
            OPERACOES_TRANSFERENCIA_EM_LOTE.TRANSFERENCIA_ENTRE_CONTAS.value
                    ) {
                        instrucoes.push(
                            'Preenchimento obrigatório dos campos "agencia", "conta", "digito", "valor" e "dataPagamento".'
                        );
                    }
                    if (operacao === OPERACOES_TRANSFERENCIA_EM_LOTE.TED.value) {
                        instrucoes.push(
                            'Preenchimento obrigatório dos campos "banco", "agencia", "conta", "digito", "documentoFavorecido", "valor", "dataPagamento" e "tipoConta";'
                        );
                        instrucoes.push(
                            'Na coluna tipo de conta preencha CORRENTE ou POUPANÇA. Contas de pagamento você pode inserir como CORRENTE.'
                        );
                    }

                    /* Removido as condições para atender a necessidade do BPP-2484
                    if (operacao === OPERACAO.NOME.TRANSFERENCIA_EM_LOTE.CARGA_CARTAO_LOTE_BPP_PAG) {
                        instrucoes.push('Preenchimento obrigatório dos campos "idCartao", "valor" e "dataPagamento".');
                    }
                    if (operacao === OPERACAO.NOME.TRANSFERENCIA_EM_LOTE.TRANSFERENCIA_ENTRE_CONTAS_BPP_LOTE_BPP_PAG) {
                        instrucoes.push('Preenchimento obrigatório dos campos "agencia", "conta", "digito", "valor" e "dataPagamento".');
                    }
                    if (operacao === OPERACAO.NOME.TRANSFERENCIA_EM_LOTE.TED_LOTE_BPP_PAG) {
                        instrucoes.push('Preenchimento obrigatório dos campos "banco", "agencia", "conta", "digito", "documentoFavorecido", "valor", "dataPagamento" e "tipoConta";');
                        instrucoes.push('Tipo de Conta deve ser CORRENTE ou POUPANÇA.');
                    } */
                    // TODO Adicionar mais sub-itens específicos conforme a necessidade.
                    return instrucoes;
                },
                // Instruções comuns aos 3 tipos de transferencias em lote
                ITEM_COMUM_IMAGEM: 'Seu arquivo ficará semelhante ao abaixo:',
                ITEM_COMUM_3: 'Pronto! Agora é só aguardar a validação do arquivo e acompanhar o status no box de \'Agendamentos e Aprovações\'.'
            },
            CNAB: {
                ITEM_1: 'Fa\u00E7a upload do arquivo, conforme gerado em seu sistema no modelo CNAB',
                ITEM_2: 'Agora \u00E9 s\u00F3 aguardar a valida\u00E7\u00E3o do arquivo e acompanhar o status no box de \'Agendamentos e Aprova\u00E7\u00F5es\'',
                ITEM_3: {
                    PREFIXO: 'Aten\u00E7\u00E3o!',
                    SUFIXO: 'Caso o seu arquivo apresente alguma pend\u00EAncia de corre\u00E7\u00E3o, consulte Manual do Arquivo CNAB BPP PAG',
                    LINK_LABEL: 'clicando aqui.',
                    LINK: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-CNAB-BPP-PAG.pdf'
                }
            }
        },
        MODAL_INSTRUCOES_LINK_ARQUIVO_MODELO: operacao =>
            ({
                [CARGA_LOTE_CARTAO_BPP_CORP.value]: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-CORP-CARGA-CARTAO.csv',
                [OPERACOES_TRANSFERENCIA_EM_LOTE.CARGA_CARTAO
                    .value]: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-CARGA-CARTAO.csv',
                [OPERACOES_TRANSFERENCIA_EM_LOTE.TRANSFERENCIA_ENTRE_CONTAS
                    .value]: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-TRANSFERENCIA.csv',
                [OPERACOES_TRANSFERENCIA_EM_LOTE.TED
                    .value]: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-ARQUIVO-BPP-PAG-TED.csv'
            }[operacao] || '')
    },
    VALIDACAO_DE_CAMPOS: {
        ARQUIVO: {
            EXTENSAO_NAO_SUPORTADA: {
                IMAGEM: 'Tipo de arquivo não permitido!<br />Extensões disponíveis: *.jpg, *.jpeg, *.png'
            },
            TAMANHO_NAO_SUPORTADO: 'Tamanho do arquivo não é permitido <br />O tamanho máximo é 10MB!'
        },
        CAMPO_INVALIDO: campo => `${campo} inválido.`,
        TEM_CARACTERES_ESPECIAIS_NOME: 'Nome inválido: o nome não pode conter acentos ou caracteres especiais.',
        TEM_CARACTERES_ESPECIAIS_DESCRICAO: 'Descrição inválida: a descrição não pode conter acentos ou caracteres especiais.',
        CAMPO_OBRIGATORIO: 'Campo obrigatório.',
        CELULAR_INVALIDO: 'Número de celular inválido.',
        DATA: {
            DATA_INVALIDA: 'Data inválida.',
            DATA_DENTRO_DO_PERIODO_DE_UM_ANO: (linha, data) => {
                if (linha) {
                    return `Linha ${linha}: A data de pagamento (${data}) não pode ser inferior a data atual e superior a um ano.`;
                }
                return `A data ${data} não pode ser inferior a data atual e não pode ser superior a um ano.`;
            },
            DATA_DEVE_SER_IGUAL_OU_MENOR_QUE_A_DATA_ATUAL: (linha, data) => {
                if (linha) {
                    return `Linha ${linha}: A data ${data} não pode ser superior a data atual.`;
                }
                return `A data ${data} não pode ser superior a data atual.`;
            },
            DATA_DEVE_SER_MAIOR_QUE_A_DATA_ATUAL: (parametro = '') =>
                `Data ${parametro} deve ser maior que a atual.`,
            INFORME_A_DATA: (parametro = '') => `Informe a data ${parametro}`,
            INFORME_A_DATA_PARA_TRANSFERENCIA: 'Informe a data para transferência.',
            SELECIONAR_DATA_DE_AGENDAMENTO: 'Selecione a data de agendamento',
            DATA_MAIOR_QUE_UM_ANO: (parametro = '') =>
                `Data ${parametro} não pode ser maior que 1 ano`
        },
        LIMITE_CARACTERE_EXCEDIDO: 'Limite de caracteres excedido',
        SENHA: {
            CONFIRMACAO_SENHA_INVALIDA: 'Confirma\u00e7\u00e3o de senha inv\u00e1lida',
            SENHA_INVALIDA: 'Senha inv\u00e1lida',
            SENHAS_DIFERENTES: 'Senha diferente de confirma\u00e7\u00e3o de senha.'
        },
        SELECIONE_AO_MENOS_UMA_PERMISSAO: 'Selecione ao menos uma permissão.',
        TOKEN_INVALIDO: 'Token inválido',
        TRANSFERENCIA_BANCARIA: {
            MESMO_FAVORECIDO_ERRO: 'O Favorecido não deve ser a própria conta.',
            SALDO_INSUFICIENTE: 'Não há saldo suficiente para realizar a transferência',
            VALOR_INVALIDO: 'O valor a transferir deve ser maior do que zero'
        },
        VALOR: {
            VALOR_MAIOR_QUE_ZERO: 'Valor deve ser maior que 0',
            VALOR_MAIOR_QUE_500: 'Valor máximo por descarga é R$500,00'
        }
    },
    RELATORIOS_CARTOES: {
        ERRO: 'Não foi possível extrair o relatório solicitado. Tente novamente mais tarde.'
    }
};

export const METODOS = {
    ANEXO_CONTRATUAL: {
        GERA_ANEXO_PESSOA_FISICA: 'geraAnexoPessoaFisica',
        GERA_ANEXO_PESSOA_FISICA_ERRO: 'geraAnexoPessoaFisicaErro',
        GET_ANEXO_CARTAO_VIRTUAL: 'getAnexoCartaoVirtual',
        GET_ANEXO_CARTAO_VIRTUAL_ERRO: 'getAnexoCartaoVirtualErro',
        GET_ANEXO_BY_ID: 'getAnexoById',
        GET_ANEXO_BY_ID_ERRO: 'getAnexoByIdErro',
        GET_ANEXOS_CONTRATUAIS: 'getAnexosContratuais',
        GET_ANEXOS_CONTRATUAIS_ERRO: 'getAnexosContratuaisErro',
        GET_SOLUCOES: 'getSolucoes',
        GET_SOLUCOES_ERRO: 'getSolucoesErro',
        FETCHING: 'isFetching'
    },
    ARQUIVO: {
        BUSCA_ARQUIVO_POR_ID: 'buscaArquivoPorId',
        BUSCA_ARQUIVO_POR_ID_ERRO: 'buscaArquivoPorIdErro',
        CONFIRMA_ENVIO_DE_ARQUIVO_PARA_PROCESSAMENTO: 'confirmaEnvioDeArquivoParaProcessamento',
        CONFIRMA_ENVIO_DE_ARQUIVO_PARA_PROCESSAMENTO_ERRO: 'confirmaEnvioDeArquivoParaProcessamentoErro',
        ENVIA_ARQUIVO: 'enviaArquivo',
        ENVIA_ARQUIVO_ERRO: 'enviaArquivoErro',
        LIMPAR: 'limpar'
    },
    AGENDAMENTO_E_APROVACOES: {
        BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA: 'buscarAgendamentosDashBoardConta',
        BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA_ERRO: 'buscarAgendamentosDashBoardContaErro',
        EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES: 'efetivarTransacaoAgendamentoEAprovacoes',
        EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO: 'efetivarTransacaoAgendamentoEAprovacoesErro',
        LIMPAR_FILTRO_AGENDAMENTO_E_APROVACOES: 'limparFiltroAgendamentoEAprovacoes',
        FILTRO: {
            SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_INICIAL: 'setFiltroAgendamentosEAprovacoesDataInicial',
            SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_FINAL: 'setFiltroAgendamentosEAProvacoesDataFinal',
            SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_DE: 'setFiltroAgendamentosEAprovacoesValorDe',
            SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_ATE: 'setValsetFiltroAgendamentosEAprovacoesValorAte'
        },
        SET_EXIBIR_LOADING_AGENDAMENTO_E_APROVACOES: 'setExibirLoadingAgendamentoEAprovacoes',
        SET_EXIBIR_MODAL_FINALIZACAO_AGENDAMENTO_E_APROVACOES: 'setExibirModalFinalizacaoAgendamentoEAprovacoes',
        TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES: 'tokenTransacaoAgendamentoEAprovacoes',
        TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO: 'tokenTransacaoAgendamentoEAprovacoesErro',
        SET_EXIBIR_MODAL_TOKEN_AGENDAMENTO_E_APROVACOES: 'setExibirModalTokenAgendamentosEAprovacoes'
    },
    CARTAO: {
        BLOQUEAR_CARTAO: 'bloquearCartao',
        BLOQUEAR_CARTAO_ERRO: 'bloquearCartaoErro',
        CANCELAR_CARTAO: 'cancelarCartao',
        CANCELAR_CARTAO_ERRO: 'cancelarCartaoErro',
        CANCELAR_CARTAO_SALDO_ERRO: 'cancelarCartaoSaldoErro',
        BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO: 'buscarCartoesComOperacaoEmProcessamento',
        BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO: 'buscarCartoesComOperacaoEmProcessamentoErro',
        DECREMENT_CAROTES_FAVORITOS_QTD: 'decrementCartoesFavoritosQuantidade',
        DESBLOQUEIO_DE_CARTAO: 'desbloqueioDeCartao',
        DESBLOQUEIO_DE_CARTAO_ERRO: 'desbloqueioDeCartaoErro',
        GET_CARTOES: 'getCartoes',
        GET_CARTOES_PAGINADO: 'getCartoesPaginado',
        GET_CARTOES_ERRO: 'getCartoesErros',
        GET_CAROTES_FAVORITOS: 'getCartoesFavoritos',
        GET_CAROTES_FAVORITOS_QTD: 'getCartoesFavoritosQuantidade',
        GET_CAROTES_FAVORITOS_POSICAO: 'getCartoesFavoritosPosicao',
        GET_CAROTES_FAVORITOS_ERRO: 'getCartoesFavoritosErros',
        LIMPAR_CARTOES: 'limparCartoes',
        LIMPAR_ESTADO_CARTOES: 'limparEstadoCartoes',
        LIMPAR_FILTRO_CARTAO: 'limparFiltroCartao',
        LIMPAR_MENSAGEM_DE_ERRO_DE_ALTERACAO_DE_TITULARIDADE_CARTAO: 'limparMensagemDeErroDeAlteracaoDeTitularidadeCartao',
        SET_ARMAZENAR_CARTAO: 'setArmazenarCartao',
        SET_CARTAO_ID: 'setCartaoId',
        SET_ARMAZENAR_TOKEN_SMS_CARTAO: 'setArmazenarTokenSMSCartao',
        SET_CARTAO_QUATRO_ULTIMOS_DIGITOS: 'setCartaoQuatroUltimosDigitos',
        SET_CARTOES_FAVORITOS: 'setCartoesFavoritos',
        SET_CLEAR_STATE: 'setClearState',
        SET_EXIBIR_LOADING_CARTAO: 'setExibirLoadingCartao',
        SET_EXIBIR_MODAL_CARGA_OU_DESCARGA_CARTAO: 'setExibirModalCargaOuDescargaCartao',
        SET_EXIBIR_MODAL_CONFIRMACAO_CARTAO: 'setExibirModalConfirmacaoCartao',
        SET_EXIBIR_MODAL_FINALIZACAO_CARTAO: 'setExibirModalFinalizacaoCartao',
        SET_EXIBIR_MODAL_CARTAO_OPERACAO_EM_ANDAMENTO: 'exibirModalCartaoOperacaoEmAndamento',
        SET_EXIBIR_MODAL_TOKEN_CARTAO: 'setExibirModalTokenCartao',
        SET_NOME_PORTADOR_CARTAO: 'setNomePortadorCartao',
        SET_NUMERO_DOCUMENTO_PORTADOR_CARTAO: 'setNumeroDocumentoPortadorCartao',
        SET_DATA_NASCIMENTO_PORTADOR_CARTAO: 'setDataNascimentoPortadorCartao',
        SET_CARTAO_SELECIONADO: 'setCartaoSelecionado',
        SET_OPERACAO_CARTAO_SELECIONADA: 'setOperacaoCartaoSelecionada',
        SOLICITAR_DESBLOQUEIO_DE_CARTAO: 'solicitarDesbloqueioDeCartao',
        SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO: 'solicitarDesbloqueioDeCartaoErro',
        SOLICITAR_TOKEN_CARTAO: 'solicitarTokenCartao',
        SOLICITAR_TOKEN_CARTAO_ERRO: 'solicitarTokenCartaoErro',
        SOLICITAR_TOKEN_ALTERAR_TITULARIDADE_CARTAO: 'solicitarTokenAlterarTitularidadeCartao',
        SOLICITAR_TOKEN_ALTERAR_TITULARIDADE_CARTAO_ERRO: 'solicitarTokenAlterarTitularidadeCartaoErro',
        SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO: 'solicitarTransferenciaTitularidadeCartao',
        SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO_ERRO: 'solicitarTransferenciaTitularidadeCartaoErro',
        SET_MSG_LISTA_VAZIA: 'msgListaVazia'
    },
    CONTA: {
        CRIAR_CONTA: 'criarConta',
        CRIAR_CONTA_ERRO: 'criarContaErro',
        GET_AUTORIZADOS_CONTA: 'getAutorizadosDaConta',
        GET_AUTORIZADOS_CONTA_ERRO: 'getAutorizadosDaContaErro',
        GET_CONTAS: 'getContas',
        GET_CONTAS_ERRO: 'getContasErro',
        REFRESH_CONTAS: 'refreshContas',
        GET_CONTA_POR_ID: 'getContaById',
        GET_CONTA_POR_ID_ERRO: 'getContaByIdErro',
        GET_CONTA_POR_NUMERO_E_DIGITO: 'getContaPorNumeroEDigito',
        GET_CONTA_POR_NUMERO_E_DIGITO_ERRO: 'getContaPorNumeroEDigitoErro',
        INSERE_AUTORIZADO_E_PODERES: 'insereAutorizadoEPoderes',
        INSERE_AUTORIZADO_E_PODERES_ERRO: 'insereAutorizadoEPoderesErro',
        ATUALIZA_AUTORIZADO_E_PODERES: 'atualizaAutorizadoEPoderes',
        ATUALIZA_AUTORIZADO_E_PODERES_ERRO: 'atualizaAutorizadoEPoderesErro',
        SALVAR_AVATAR: 'salvarAvatar',
        SALVAR_AVATAR_ERRO: 'salvarAvatarErro',
        SALVAR_FAVORECIDO_CONTA_ERRO: 'salvarFavorecidoConta',
        SALVAR_FAVORECIDO_CONTA: 'salvarFavorecidoContaErro',
        SALVAR_EDICAO_DE_CONTA: 'salvarEdicaoDeConta',
        SALVAR_EDICAO_DE_CONTA_ERRO: 'salvarEdicaoDeContaErro',
        SET_ABRIR_AUTORIZACOES: 'setAbrirAutorizacoes',
        SET_EXIBIR_LOADING_CONTA: 'setExibirLoadingConta',
        SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA: 'solicitarTokenTransferenciaBancaria',
        SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO: 'solicitarTokenTransferenciaBancariaErro',
        SOLICITAR_TRANSFERENCIA_BANCARIA: 'solicitarTransferenciaBanciaria',
        SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO: 'solicitarTransferenciaBanciariaErro',
        VALIDAR_TRANSFERENCIA_ENTRE_CONTAS: 'validaTransferenciaEntreContas',
        VALIDAR_TRANSFERENCIA_ENTRE_CONTAS_ERRO: 'validaTransferenciaEntreContasErro'
    },
    GET_BANCOS: 'getBancos',
    GET_BANCOS_ERRO: 'getBancosErro',
    GET_FAVORECIDOS: 'getFavorecidos',
    GET_FAVORECIDOS_ERRO: 'getFavorecidosErro',
    LOCALIDADE: {
        SET_EXIBIR_LOADING_LOCALIDADE: 'setExibirLoadingLocalidade',
        GET_LOCALIDADE_POR_CEP: 'getLocalidadePorCep',
        GET_LOCALIDADE_POR_CEP_ERRO: 'getLocalidadePorCepErro',
        GET_CIDADES: 'getCidades',
        GET_CIDADES_ERRO: 'getCidadesErro',
        GET_ESTADOS: 'getEstados',
        GET_ESTADOS_ERRO: 'getEstadosErro',
        GET_PAISES: 'getPaises',
        GET_PAISES_ERRO: 'getPaisesErro'
    },
    EXTRATO: {
        GET_EXTRATO: 'getExtrato',
        GET_EXTRATO_ERRO: 'getExtratoErro',
        GET_EXTRATO_CSV: 'getExtratoCSV',
        GET_EXTRATO_DASHBOARD_CONTA: 'getExtratoDashBoardConta',
        GET_EXTRATO_DASHBOARD_CONTA_ERRO: 'getExtratoDashBoardContaErro',
        LIMPAR_EXTRATO: 'limparExtrato',
        SET_EXIBIR_LOADING_EXTRATO: 'setExibirLoadingExtrato',
        SET_LINHA_DO_EXTRATO_SELECIONADA: 'setLinhaDoExtratoSelecionada',
        SET_DETALHES_TRANSACAO_SELECIONADA: 'setDetalhesTransacaoSelecionada'
    },
    LOGIN: {
        LIMPA_DADOS_LOGIN: 'limpaDadosLogin',
        POST_LOGIN: 'postLogin',
        POST_LOGIN_ERRO: 'postLoginErro'
    },
    OPERACAO: {
        SOLICITAR_TOKEN_OPERACAO: 'solicitarTokenOperacao',
        SOLICITAR_TOKEN_OPERACAO_ERRO: 'solicitarTokenOperacaoErro'
    },
    OUTORGA: {
        GET_PODERES_OUTORGA: 'getPoderesOutorga',
        GET_PODERES_OUTORGA_ERRO: 'getPoderesOutorgaErro',
        SET_EXIBIR_LOADING_OUTORGA: 'setExibirLoadingOutorga',
        SET_LISTA_PARAMETRO_OPERACAO: 'setListaParametroOperacao',
        CLEAN_LISTA_PARAMETRO_OPERACAO: 'cleanListaParametroOperacao',
        SET_PERFILS: 'setOutorgaParametroPerfils',
        CLEAN_PERFILS: 'cleanOutorgaParametroPerfils'
    },
    PAGAMENTOS_DE_CONTAS: {
        SET_EXIBIR_LOADING: 'setExibirLoading',
        SET_SOLICITACAO_PAGAMENTO_CONTA_SELECIONADA: 'setSolicitacaoPagamentoContaSelecionada',
        SET_SOLICITACAO_PAGAMENTO_CODIGO_BARRAS: 'setSolicitacaoPagamentoCodigoBarras',
        SET_SOLICITACAO_PAGAMENTO_VALOR: 'setSolicitacaoPagamentoValor',
        SET_SOLICITACAO_PAGAMENTO_DATA_VENCIMENTO: 'setSolicitacaoPagamentoDataVencimento',
        SET_SOLICITACAO_PAGAMENTO_NOME_BENEFICIARIO: 'setSolicitacaoPagamentoNomeBeneficiario',
        SET_SOLICITACAO_PAGAMENTO_DOCUMENTO_BENEFICIARIO: 'setSolicitacaoPagamentoDocumentoBeneficiario',
        SET_SOLICITACAO_PAGAMENTO_TIPO_DOCUMENTO: 'setSolicitacaoPagamentoTipoDocumento',
        SET_SOLICITACAO_PAGAMENTO_DESCRICAO: 'setSolicitacaoPagamentoDescricao',
        SET_SOLICITACAO_PAGAMENTO_AGENDAMENTO_HOJE: 'setSolicitacaoPagamentoAgendamentoHoje',
        SET_SOLICITACAO_PAGAMENTO_DATA_AGENDAMENTO: 'setSolicitacaoPagamentoDataAgendamento',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO: 'getSolicitacaoPagamentoExibirErroDataAgendamento',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MENORIGUAL_HOJE: 'getSolicitacaoPagamentoExibirErroDataAgendamentoMenorIgualHoje',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MAIOR_QUE_UM_ANO: 'getSolicitacaoPagamentoExibirErroDataAgendamentoMaiorDoQueUmAno',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_CODIGO_BARRAS: 'getSolicitacaoPagamentoExibirErroCodigoBarras',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_VALOR: 'getSolicitacaoPagamentoExibirErroValor',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_SALDO: 'getSolicitacaoPagamentoExibirErroSaldo',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO: 'getSolicitacaoPagamentoExibirErroDataVencimento',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO_MENOR: 'getSolicitacaoPagamentoExibirErroDataVencimentoMenor',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_NOME_BENEFICIARIO: 'getSolicitacaoPagamentoExibirErroNomeBeneficiario',
        GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DOCUMENTO_BENEFICIARIO: 'getSolicitacaoPagamentoExibirErroDocumentoBeneficiario',
        SET_EXIBIR_MODAL_TOKEN: 'setExibirModalToken',
        EXIBIR_MODAL_SUCESSO: 'exibirModalSucesso',
        EXIBIR_MODAL_ERRO: 'exibirModalErro',
        REQUISITAR_TOKEN_PAGAMENTO: 'requisitarTokenPagamento',
        REQUISITAR_PAGAMENTO_SUCCESS: 'requisitarPagamentoSucesso',
        REQUISITAR_TOKEN_PAGAMENTO_ERRO: 'requisitarTokenPagamentoErro',
        ARMAZENAR_TOKEN_SMS: 'setArmazenarTokenSMS',
        REENVIAR_TOKEN_PAGAMENTO: 'reenviarTokenPagamento',
        REENVIAR_TOKEN_PAGAMENTO_ERRO: 'reenviarTokenPagamentoErro',
        SOLICITAR_PAGAMENTOS: 'solicitarPagamentos',
        SOLICITAR_PAGAMENTOS_ERRO: 'solicitarPagamentosErro'
    },
    PEDIDOS: {
        EMBOSSING_CARTAO: {
            REQUISITAR_TOKEN_MODAL: 'pedidoEmbossingTokenModal',
            EFETUAR_PEDIDO_EMBOSSING_CARTAO: 'efetuarPedidoEmbossingCartao',
            EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO: 'efetuarPedidoEmbossingCartaoErro',
            EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO_CORE: 'efetuarPedidoEmbossingCartaoErroCore',
            RESETA_SOLICITACAO_PEDIDO_EMBOSSING_CARTAO: 'RESETA_SOLICITACAO_PEDIDO_EMBOSSING_CARTAO',
            GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID: 'getUrlPreAssinadaCartaoOuCartaBercoPorArquivoId',
            GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID_ERRO: 'getUrlPreAssinadaCartaoOuCartaBercoPorArquivoIdErro',
            SET_EXIBIR_LOADING_PEDIDO_CARTAO_IMPRESSO: 'setExibirLoadingPedidoCartaoImpresso',
            SET_REQUISICAO_PEDIDO_EMBOSSING_ID: 'setRequisicaoPedidoEmbossingId',
            SET_LIMPAR_SOLICITACAO_DE_PEDIDO_EMBOSSING_CARTAO: 'setLimparSolicitacaoDePedidoEmbossingCartao'
        },
        CONSULTA: {
            GET_CONSULTA_DE_PEDIDOS: 'getConsultaDePedidos',
            GET_CONSULTA_DE_PEDIDOS_ERRO: 'getConsultaPedidosErro',
            GET_CONSULTA_DE_OPERACOES: 'getOperacoes',
            GET_CONSULTA_DE_OPERACOES_ERROS: 'getOperacoesErro',
            SET_ANEXO_CONTRATUAL_CONSULTA_DE_PEDIDOS: 'setAnexoContratualConsultaDePedidos',
            SET_CONSULTA_DE_PEDIDOS_DATA_INICIAL: 'setConsultaDePedidosDataInicial',
            SET_CONSULTA_DE_PEDIDOS_DATA_FINAL: 'setConsultaDePedidosDataFinal',
            SET_FILTRO_CONSULTA_DE_PERIDOS_POR_VALOR: 'setFiltroConsultaDePedidosPorValor',
            SET_CONSULTA_DE_PEDIDOS_OPERACAO: 'setConsultaDePedidosOperacao',
            SET_CONSULTA_DE_PEDIDOS_STATUS: 'setConsultaDePedidosStatus',
            SET_EXIBIR_LOADING_DASHBOARD_CONSULTA_PEDIDO: 'setExibirLoadingDashboardConsultaPedido'
        },
        LIMPAR_FILTRO_PEDIDOS: 'limparFiltroPedidos',
        LIMPAR_PEDIDOS: 'limparPedidos'
    },
    PESSOA: {
        GET_PESSOA_POR_DOCUMENTO: 'getPessoaPorDocumento',
        GET_PESSOA_POR_DOCUMENTO_ERRO: 'getPessoaPorDocumentoErro',
        SET_LIMPAR_PESSOA_RECUPERADA: 'setLimparPessoaRecuperada',
        SET_EXIBIR_LOADING_PESSOA: 'setExibirLoadingPessoa',
        PRESTACAO_DE_CONTAS: {
            CLEAR: 'clearPrestacaoConta',
            GET_CONTA: 'getConta',
            GET_CONTA_ERRO: 'getContaErro',
            SET_CLIENTE_PROJETO_ID: 'setClienteProjetoId',
            SET_LINHA_EXTRATO_SELECIONADA: 'setLinhaExtratoSelecionada',
            SET_CARTAO: 'setCartao',
            SET_EXIBIR_LOADING: 'setLoadingPrestacaoConta',
            GET_FORM_PRESTACAO_CONTA: 'getFormPrestacaoContas',
            GET_FORM_PRESTACAO_CONTA_ERRO: 'getFormPrestacaoContaErro',
            GET_PRESTACOES_DE_CONTA: 'getPrestacoesDeConta',
            GET_PRESTACOES_DE_CONTA_ERRO: 'getPrestacoesDeContaErro',
            GET_PRESTACAO_CONTA_PROJETO: 'getPrestacaoContaProjeto',
            GET_PRESTACAO_CONTA_PROJETO_ERRO: 'getPrestacaoContaProjetoErro',
            GET_PRESTACAO_CONTA_CLASSIFICACAO: 'getPrestacaoContaClassificacao',
            GET_PRESTACAO_CONTA_CLASSIFICACAO_ERRO: 'getPrestacaoContaClassificacaoErro',
            GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES: 'getPrestacaoDeContasClassificacoes',
            GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES_ERROS: 'getPrestacaoDeContasClassificacoesErros',
            GET_PRESTACAO_DE_CONTAS_PROJETOS: 'getPrestacaoDeContasProjetos',
            GET_PRESTACAO_DE_CONTAS_PROJETOS_ERROS: 'getPrestacaoDeContasProjetosErros',
            LIMPAR_FILTRO_PRESTACAO_DE_CONTAS: 'limparFiltroPrestacaoDeContas',
            LIMPAR_FORMULARIO: 'limparFormulario',
            LIMPAR_LISTA_DE_PRESTACOES_DE_CONTAS: 'listaDePrestacoesDeContas',
            SET_FILTRO: 'setFiltro',
            SET_FILTRO_PRESTACAO_DATA_INICIAL: 'setFiltroPrestacaoDataInicial',
            SET_FILTRO_PRESTACAO_DATA_FINAL: 'setFiltroPrestacaoDataFinal',
            SET_FILTRO_PRESTACAO_DE_CONTAS_POR_VALOR: 'setFiltroPrestacaoDeConstasPorValor',
            SET_PROJETO: 'setProjeto',
            SET_CLASSIFICACAO: 'setClassificacao',
            SET_OBSERVACAO: 'setObservacao',
            SET_ID_IMG_COMPROVANTE: 'setIdImgComprovante',
            VALIDA_IMG_ERRO: 'validaImgErro',
            POST_URL_ARQUIVO_ERRO: 'recuperarUrlPreAssinadaErro',
            PUT_UPLOAD_IMAGEM_S3_ERRO: 'uploadImagemS3Erro',
            SET_CLASSIFICACAO_SELECIONADA: 'setClassificacaoSelecionada',
            SET_EXIBIR_LOADING_DASHBOARD_PRESTACAO_DE_CONTAS: 'setExibirLoadingDashboardPrestacaoDeContas',
            SET_EXIBIR_MODAL_CANCELAMENTO: 'setExibirModalCancelamento',
            SET_EXIBIR_MODAL_CONFIRMACAO: 'setExibirModalConfirmacao',
            SET_EXIBIR_MODAL_IMAGEM_ORIGINAL: 'setExibirModalImagemOriginal',
            SET_EXIBIR_MODAL_UPLOAD_IMAGEM_ERRO: 'setExibirModalUploadImagemErro',
            SET_EXIBIR_MODAL_IMAGEM_ORIGINAL_ERRO: 'setExibirModalImagemOriginalErro',
            SET_FECHAR_MODAL_IMAGEM_ORIGINAL: 'setFecharModalImagemOriginal',
            SET_PRESTACAO_CONTA: 'setPrestacaoConta',
            SET_PRESTACAO_CONTA_ERRO: 'setPrestacaoContaErro',
            SET_PRESTACAO_DE_CONTAS_STATUS: 'setPrestacaoDeContasStatus',
            SET_PROJETO_SELECIONADO: 'setProjetoSelecionado'
        }
    },
    TRANSFERENCIA_BANCARIA: {        
        BUSCAR_FAVORECIDOS_TRANSFERENCIA_BANCARIA: 'buscarFavorecidosTransferenciaBancaria',
        BUSCAR_FAVORECIDOS_TRANSFERENCIA_BANCARIA_ERRO: 'buscarFavorecidosTransferenciaBancariaErro',
        BUSCAR_BANCOS_TRANSFERENCIA_BANCARIA: 'buscarBancosTransferenciaBancaria',
        BUSCAR_BANCOS_TRANSFERENCIA_BANCARIA_ERRO: 'buscarBancosTransferenciaBancariaErro',
        GET_HORARIO_PERMITIDO_FAZER_TED: 'getHorarioPermitidoFazerTed', 
        GET_HORARIO_PERMITIDO_FAZER_TED_ERRO: 'getHorarioPermitidoFazerTedErro', 
        REENVIAR_TOKEN_TRANSFERENCIA_BANCARIA: 'reenviarTokenTransferenciaBancaria',
        REENVIAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO: 'reenviarTokenTransferenciaBancariaErro',
        REQUISITAR_TOKEN_TRANSFERENCIA_BANCARIA: 'requisitarTokenTransferenciaBancaria',
        REQUISITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO: 'requisitarTokenTransferenciaBancariaErro',
        SET_HORARIO_PERMITIDO_FAZER_TED:'setHorarioPermitidoFazerTed', 
        SOLICITAR_TRANSFERENCIA: 'solicitarTransferencia',
        SOLICITAR_TRANSFERENCIA_ERRO: 'solicitarTransferenciaErro'
    },
    TRANSFERENCIA_EM_LOTE: {
        SET_EXIBIR_LOADING_AGENDAMENTOS_E_APROVACOES_TRANSFERENCIA_EM_LOTE: 'setExibirLoadingTransferenciaEmLoteHistorico',
        BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE: 'buscarAgendamentoEAprovacoesDeTransferenciaEmLote',
        BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE_ERRO: 'buscarAgendamentoEAprovacoesDeTransferenciaEmLoteErro',
        EFETIVAR_TRANSACAO_EM_LOTE: 'efetivarTransacaoEmLote',
        EFETIVAR_TRANSACAO_EM_LOTE_ERRO: 'efetivarTransacaoEmLoteErro',
        SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE: 'solicitarTokenTransferenciaEmLote',
        SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE_ERRO: 'solicitarTokenTransferenciaEmLoteErro',
        DETALHES_DO_ARQUIVO: {
            SET_DETALHES_DO_ARQUIVO: 'setDetalhesDoArquivo',
            SET_DETALHES_DO_ARQUIVO_ERRO: 'setDetalhesDoArquivoErro',
            SET_EXIBIR_LOADING: 'setExibirLoadingDetalhesDoArquivo'
        },
        HISTORICO: {
            SET_HISTORICO: 'setHistoricoTransferenciaEmLote',
            SET_HISTORICO_ERRO: 'setHistoricoTransferenciaEmLoteErro',
            SET_EXIBIR_LOADING: 'setExibirLoadingHistoricoTransferenciaEmLote'
        },
        LOTES_DO_ARQUIVO: {
            SET_ARQUIVOS_DO_LOTE: 'setArquivosDoLote',
            SET_ARQUIVOS_DO_LOTE_ERRO: 'setArquivoDoLoteErro',
            SET_EXIBIR_LOADING: 'setExibirLoadingArquivosDoLote'
        },
        ITENS_DO_LOTE: {
            SET_ITENS_DO_LOTE: 'setItensDoLote',
            SET_ITENS_DO_LOTE_ERRO: 'setItensDoLoteErro',
            SET_ITENS_DO_LOTE_LOADING: 'setExibirLoadingItensDoLote'
        }
    },
    SET_CONTA_SELECIONADA: 'setContaSelecionada'
};

export const NAVEGADOR_NIVEL = { VALUE: 'navegadorNivelContaDepartamento' };

export const OPERACAO = {
    NOME: {
        ALTERA_STATUS_CARTAO: 'alteracaoStatusCartao',
        ALTERACAO_PIN_CARTAO: 'alteracaoPINCartao',
        ALTERACAO_PORTADOR_CARTAO: 'alteracaoPortadorCartao',
        CANCELA_CARTAO: 'cancelaCartao',
        CARGA_CARTAO: 'cargaCartao',
        CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG: 'cargaCartaoDiferentesTitularidadesPag',
        CRIACAO_CONTA_MOVIMENTO: 'criacaoContaMovimento',
        DESCARGA_CARTAO: 'descargaCartao',
        DESBLOQUEIO_CARTAO: 'desbloqueioCartao',
        PEDIDO_EMBOSSING_CARTAO: 'pedidoEmbossingCartao',
        PEDIDO_DE_CARTAO_VIRTUAL: 'pedidoCartaoVirtual',
        MENSALIDADE_CARTAO_CARTAO_COBRADA_CONTA: 'mensalidadeCartaoCobradaConta',
        MIDIA: { FISICO: 'FÍSICO', VIRTUAL: 'VIRTUAL' },
        TED: 'TED',
        TED_PAG: 'TEDPag',
        TRANSFERENCIA_EM_LOTE: {
            CARGA_CARTAO_LOTE_BPP_PAG: 'cargaCartaoLoteBppPag',
            TED_LOTE_BPP_PAG: 'tedLoteBppPag',
            TRANSFERENCIA_ENTRE_CONTAS_BPP_LOTE_BPP_PAG: 'transferenciaBppLoteBppPag'
        },
        TRANSFERENCIA_CONTA_MOVIMENTO_CONTA_DEPARTAMENTO: 'transferenciaContaMovimentoContaDepartamento',
        TRANSFERENCIA_CONTA_DEPARTAMENTO_CONTA_MOVIMENTO: 'transferenciaContaDepartamentoContaMovimento',
        TRANSFERENCIA_CONTA_DEPARTAMENTO_CONTA_DEPARTAMENTO: 'transferenciaEntreContasDepartamento',
        TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO: 'transferenciaEntreContasMovimento',
        TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES: 'transferenciaEntreContasMovimentoDiferentesTitularidades',
        TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_EM_LOTE: 'transferenciaEntreContasMovimentoDiferentesTitularidadesEmLote',
        TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_PAG: 'transferenciaEntreContasMovimentoDiferentesTitularidadesPag'
    },
    STATUS: {
        ABERTO: 'aberto',
        AGENDADO: 'agendado',
        EM_PROCESSAMENTO: 'emProcessamento',
        FINALIZADO: 'finalizadoComSucesso',
        RECUSADO: 'finalizadoRecusado',
        CANCELADO: 'finalizadoCancelado',
        FINALIZADO_PENDENCIA_CADASTRO: 'finalizadoPendenciaCadastro',
        FINALIZADO_SALDO_INSUFICIENTE: 'finalizadoSaldoInsuficiente'
    }
};

export const OPERADORES_BOOLEANOS = { TRUE: 'true', FALSE: 'false' };

export const ORDENACAO = { ASC: 'ASC', DESC: 'DESC' };

export const PARCEIRO = { BPP: { label: 'BPP', value: 'BPP' } };

export const PESSOA_LOGADA = () => {
    return reactLocalStorage.getObject(LABEL_COMUNS.PESSOA_LOGADA);
};

export const PRODUTOS_IDS_SOLUCOES = {
    BPP_CORP: () => reactLocalStorage.get(LABEL_COMUNS.PRODUTOS_IDS_BPP_CORP),
    BPP_PAG: () => reactLocalStorage.get(LABEL_COMUNS.PRODUTOS_IDS_BPP_PAG)
};

export const TIPO_DE_ACESSO = {
    PESSOA_JURIDICA: 'PJ',
    PESSOA_FISICA: 'PF',
    SELECIONADO: () => reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)
};

export const TIPO_DE_ERRO = {
    PEDIDO: {
        EMBOSSING_CARTAO: {
            CAMPO_INVALIDO: 'campoInvalido',
            CAMPO_NAO_PREENCHIDO: 'campoNaoPreenchido',
            LIMITE_DE_CARACTER_EXCEDIDO: 'limiteDeCaracterExcedido'
        }
    }
};

export const TIPO_PRESTACAO_CONTAS = {
    CONTA: 'conta',
    CARTAO: 'cartao',
    DEPARTAMENTO: 'departamento',
    DESCRICAO_CONTA_SELECIONADA: contaSelecionadaDescricao => {
        if (contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_MOVIMENTO) {
            return TIPO_PRESTACAO_CONTAS.CONTA;
        }
        if (contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_CARTAO) {
            return TIPO_PRESTACAO_CONTAS.CARTAO;
        }
        if (contaSelecionadaDescricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO) {
            return TIPO_PRESTACAO_CONTAS.DEPARTAMENTO;
        }
    }
};

export const TIPO_DE_COMPROVANTE = {
    PAGAMENTO_BOLETO_BANCARIO: 'pagamentoBoletoBancario',
    TRANSFERENCIA_ENTRE_CONTAS_DIGITAIS: 'transferenciaBancaria',
    TRANSFERENCIA_ENTRE_CONTAS_TITULARIDADE_DIFERENTES: 'transferenciaEntreContasMovimentoDiferentesTitularidades'
};

export const TIPO_DE_EMAIL = {
    PARTICULAR: { label: 'Particular', value: 'PARTICULAR' }
};

export const TIPO_IMAGEM = { CARTAO: 'CARTAO', CARTA_BERCO: 'CARTA_BERCO' };

export const TIPO_DE_MOVIMENTACAO = { DEBITO: 'DEBITO', CREDITO: 'CREDITO' };

export const TIPO_PEDIDO = {
    ESTOQUE_CARTAO: 'ESTOQUE_CARTAO',
    ESTOQUE_CARTA_BERCO: 'ESTOQUE_CARTA_BERCO'
};

export const TIPO_DE_PESSOA = { PESSOA_JURIDICA: 'PJ', PESSOA_FISICA: 'PF' };

export const TIPO_DE_TRANSACAO = {
    APROVACAO: { transacao: 'aprovacao' },
    AGENDAMENTO: { transacao: 'agendamento' },
    CANCELAMENTO: { transacao: 'cancelamento' },
    CANCELAMENTO_AGENDAMENTO: { transacao: 'cancelamentoAgendamento' }
};

export const LOTE_ARQUIVO_TRANSACAO = {
    APROVAR: { transacao: 'aprovar' },
    REPROVAR: { transacao: 'reprovar' }
};

export const USUARIO_ARMAZENADO = () => reactLocalStorage.get(CAMPOS.USUARIO);

export const TIPO_DE_TELEFONE = {
    CELULAR: { label: 'Celular', value: 'CELULAR' },
    COMERCIAL: { label: 'Comercial', value: 'COMERCIAL' },
    RESIDENCIAL: { label: 'Residencial', value: 'RESIDENCIAL' }
};

export const RETORNA_STATUS_DA_OPERACAO_FILTRADO = statusDaOperacao => {
    const retorno = COMBO.STATUS.OPERACAO.find(
        status => status.value === statusDaOperacao
    );
    return retorno && retorno.value;
};

export const RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO = statusDaOperacaoArray => {
    return COMBO.STATUS.OPERACAO
        .filter(status => statusDaOperacaoArray.includes(status.value))
        .map(a => a.value)
        .join(',');
};

export const RETORNA_TIPO_ENTREGA_SELECIONADA = tipoEntregaSelecionadaId => {
    return TIPO_ENTREGA[
        Object.keys(TIPO_ENTREGA).find(
            key => TIPO_ENTREGA[key].value === tipoEntregaSelecionadaId
        )
    ];
};

export const RETORNA_FORMA_ENTREGA_SELECIONADA = formaEntregaSelecionadaId => {
    return FORMA_ENTREGA[
        Object.keys(FORMA_ENTREGA).find(
            key => FORMA_ENTREGA[key].value === formaEntregaSelecionadaId
        )
    ];
};

export const PRODUTOS = { corp: 'CORP', pag: 'PAG' };

export const GET_TOKEN = () => {
    return reactLocalStorage.get(CAMPOS.TOKEN);
};

export const APROVAR_OU_REPROVAR_ARQUIVO = {
    MENSAGENS: {
        SUCESSO: {
            REPROVADO: 'Arquivo reprovado com sucesso',
            APROVADO: 'Arquivo aprovado com sucesso'
        },
        ERRO: {
            GENERICO: 'Opss! Algo inesperado aconteceu... Tente novamente mais tarde.',
            DATA_POSTERIOR: {
                CODIGO: 'LOT_0024',
                MENSAGEM: 'Erro. Data do lote diferente da data de hoje.'
            },
            ERROR: 'error'
        }
    },
    STATUS:{
        APROVADO: 'aprovado'
    },
    ACAO: {
        APROVAR: 'aprovar',
        REPROVAR: 'reprovar'
    }
};

export const ARQUIVO_RETORNO = {
    MENSAGENS: {
        ERRO: {
            GENERICO: 'Opss! Algo inesperado aconteceu... Tente novamente mais tarde.',
            ERROR: 'error'
        }
    },
    MODAL_TITULO: 'Arquivo Retorno'
};
