export const CONTEXTO = {
    DASHBOARD: '/dashboard',
    MEUS_DEPARTAMENTOS: '/minha-conta/minhas-solucoes/bpp-corp/meus-departamentos',
    MINHA_CONTA: '/minha-conta',
    RELATORIOS_BPP_CORP: '/relatorios',
    SOLUCAO_BPP_CONSUMER: '/minha-conta/minhas-solucoes/bpp-consumer',
    SOLUCAO_BPP_CORP: '/minha-conta/minhas-solucoes/bpp-corp',
    SOLUCAO_BPP_PAG: '/minha-conta/minhas-solucoes/bpp-pag'
};

export const ROTAS_CAMINHOS = {
    AGENDAMENTO_APROVACOES_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/agendamento-aprovacoes'),
    AGENDAMENTO_APROVACOES_CONTA: CONTEXTO.MINHA_CONTA.concat('/agendamento-aprovacoes'),
    AGENDAMENTO_APROVACOES_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat('/agendamento-aprovacoes'),
    ALTERAR_TITULARIDADE_CARTAO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/alterar-titularidade-cartao'),
    CARGA_LOTE_CARTAO: CONTEXTO.SOLUCAO_BPP_CORP.concat('/carga-lote-cartao'),
    CARGA_LOTE_CARTAO_DETALHES: CONTEXTO.SOLUCAO_BPP_CORP.concat('/carga-lote-cartao-detalhes'),
    CARGA_LOTE_CONTA: CONTEXTO.MINHA_CONTA.concat('/carga-lote'),
    CARGA_LOTE_CONTA_DEPARTAMENTO: CONTEXTO.MEUS_DEPARTAMENTOS.concat('/carga-lote'),
    CARGA_LOTE_NOVO: CONTEXTO.MINHA_CONTA.concat('/carga-lote-novo'),
    CARTOES: CONTEXTO.SOLUCAO_BPP_CORP.concat('/cartoes'),
    CARTOES_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/cartoes'),
    CARTOES_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat('/cartoes'),
    COMPROVANTES: CONTEXTO.MINHA_CONTA.concat('/comprovantes'),
    CONSULTA_PEDIDO_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat('/consulta-pedido'),
    CONSULTA_PEDIDO_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat('/consulta-pedido'),
    CONSULTA_PEDIDO_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/consulta-pedido'),
    DASHBOARD_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat(CONTEXTO.DASHBOARD),
    DASHBOARD_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat(CONTEXTO.DASHBOARD),
    DASHBOARD_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat(CONTEXTO.DASHBOARD),
    DASHBOARD_CONTA: CONTEXTO.MINHA_CONTA.concat(CONTEXTO.DASHBOARD),
    DASHBOARD_DEPARTAMENTO: CONTEXTO.MEUS_DEPARTAMENTOS.concat(CONTEXTO.DASHBOARD),
    DASHBOARD_RELATORIOS_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat(CONTEXTO.RELATORIOS_BPP_CORP).concat(CONTEXTO.DASHBOARD),
    DASHBOARD_SOLUCOES: CONTEXTO.SOLUCAO_BPP_CORP.concat(CONTEXTO.DASHBOARD),
    DETALHES_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat('/detalhe-bpp-corp'),
    DETALHES_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/detalhe-bpp-pag'),
    EDITAR_CONTA: '/editar-conta',
    EXTRATO_CARTAO_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat('/extrato-cartao'),
    EXTRATO_CARTAO_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat('/extrato-cartao'),
    EXTRATO_CARTAO_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/extrato-cartao'),
    EXTRATO_CONTA: CONTEXTO.MINHA_CONTA.concat('/extrato-conta'),
    GERENCIAR_PRESTACOES_DE_CONTAS: CONTEXTO.SOLUCAO_BPP_CORP.concat('/gerenciar-prestacao-conta'),
    LISTA_CONTA_DEPARTAMENTOS: CONTEXTO.SOLUCAO_BPP_CORP.concat('/lista-conta-departamento'),
    PAGAMENTO_DE_CONTAS: CONTEXTO.MINHA_CONTA.concat('/pagamento'),
    PEDIDO_DE_CARGA: CONTEXTO.MINHA_CONTA.concat('/pedido-carga'),
    PEDIDO_DE_CARGA_SOLICITACAO: CONTEXTO.MINHA_CONTA.concat('/pedido-carga-solicitacao'),
    PEDIDO_EMBOSSING_CARTAO_BPP_CORP: CONTEXTO.SOLUCAO_BPP_CORP.concat('/pedido-cartao-impresso'),
    PEDIDO_EMBOSSING_CARTAO_BPP_CORP_RESUMO: CONTEXTO.SOLUCAO_BPP_CORP.concat('/pedido-cartao-impresso-resumo'),
    PEDIDO_EMBOSSING_CARTAO_BPP_PAG: CONTEXTO.SOLUCAO_BPP_PAG.concat('/pedido-cartao-impresso'),
    PEDIDO_EMBOSSING_CARTAO_BPP_PAG_RESUMO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/pedido-cartao-impresso-resumo'),
    PEDIDO_CARTAO_VIRTUAL: CONTEXTO.SOLUCAO_BPP_CORP.concat('/pedido-cartao-virtual'),
    PEDIDO_CARTAO_VIRTUAL_RESUMO: CONTEXTO.SOLUCAO_BPP_CORP.concat('/pedido-cartao-virtual-resumo'),
    PEDIR_CARTAO_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat('/pedir-cartao'),
    PODERES: '/poderes',
    PRESTAR_CONTAS: CONTEXTO.SOLUCAO_BPP_CORP.concat('/prestacao-conta'),
    PROCESSAMENTO_LOTE_HISTORICO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/processamento-lote-historico'),
    RELATORIO_GERENCIAL: CONTEXTO.SOLUCAO_BPP_CORP.concat(CONTEXTO.RELATORIOS_BPP_CORP).concat('/relatorio-gerencial'),
    RELATORIO_ANALITICO: CONTEXTO.SOLUCAO_BPP_CORP.concat(CONTEXTO.RELATORIOS_BPP_CORP).concat('/relatorio-analitico'),
    TRANSFERENCIA_BANCARIA_CONTA_MOVIMENTO: CONTEXTO.MINHA_CONTA.concat('/transferencia'),
    TRANSFERENCIA_BANCARIA_SOLICITACAO_CONTA_MOVIMENTO: CONTEXTO.MINHA_CONTA.concat('/transferencia-bancaria-solicitacao'),
    TRANSFERENCIA_BANCARIA_CONTA_DEPARTAMENTO: CONTEXTO.MEUS_DEPARTAMENTOS.concat('/transferencia'),
    TRANSFERENCIA_BANCARIA_SOLICITACAO_CONTA_DEPARTAMENTO: CONTEXTO.MEUS_DEPARTAMENTOS.concat('/transferencia-bancaria-solicitacao'),
    TRANSFERENCIA_EM_LOTE: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote'),
    TRANSFERENCIA_EM_LOTE_NOVO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote-novo'),
    TRANSFERENCIA_EM_LOTE_DETALHES: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote-detalhes/'),
    TRANSFERENCIA_EM_LOTE_HISTORICO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote-historico'),
    TRANSFERENCIA_EM_LOTE_RETORNO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote-retorno'),
    TRANSFERENCIA_EM_LOTE_LOTES_DO_ARQUIVO: CONTEXTO.SOLUCAO_BPP_PAG.concat('/lotes-arquivo'),
    TRANSFERENCIA_EM_LOTE_ITENS_DO_LOTE: CONTEXTO.SOLUCAO_BPP_PAG.concat('/itens-lote'),
    TRANSFERENCIA_LOTE: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote/entre-contas'),
    TRANSFERENCIA_LOTE_TED: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote/ted'),
    TRANSFERENCIA_LOTE_CARGA: CONTEXTO.SOLUCAO_BPP_PAG.concat('/transferencia-lote/carga'),
    VINCULAR_CARTAO_BPP_CONSUMER: CONTEXTO.SOLUCAO_BPP_CONSUMER.concat('/vincular-cartao')
};
