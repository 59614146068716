import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ListaContaDepartamento from './lista-conta-departamento';
import * as actions from './lista-conta-departamento-action';

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(actions, dispatch);
};

const mapStateToProps = state => {
    return {
        listaContaDepartamento: state.ListaContaDepartamentoReducer,
        listaConta: state.ListaContaReducer
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListaContaDepartamento);
