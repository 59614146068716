import { METODOS } from '../../../../util/contantes-comuns/constantes-comuns';

const initialState = {
    historicoTransferenciaEmLote: [],
    erroHistoricoTransferenciaEmLote: {},
    exibirLoading: false,
    exibirLoadinghHistoricoTransferenciaEmLote: false,
    lotesDoArquivo: [],
    erroArquivosDoLote: {},
    exibirLoadingLotesDoArquivo: false,
    itensDoLote: [],
    erroItensDoLote: {},
    exibirLoadingItensDoLote: false,
    detalhesDoArquivo: [],
    erroDetalhesDoArquivo: {},
    exibirLoadingDetalhesDoArquivo: false,
    ultimaChave: ''
};

export default (state = initialState, action) => (
    {
        [METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_HISTORICO]: ({
            ...state,
            historicoTransferenciaEmLote: (action.payload && action.payload.resultado) || [],
            ultimaChave: (action.payload && action.payload.ultimaChave) || ''
        }),
        [METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_HISTORICO_ERRO]: ({ ...state, erroHistoricoTransferenciaEmLote: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_EXIBIR_LOADING]: ({ ...state, exibirLoadinghHistoricoTransferenciaEmLote: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_ARQUIVOS_DO_LOTE]: ({
            ...state,
            lotesDoArquivo: (action.payload && action.payload.resultado) || [],
            ultimaChave: (action.payload && action.payload.ultimaChave) || ''
        }),
        [METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_ARQUIVOS_DO_LOTE_ERRO]: ({ ...state, erroArquivosDoLote: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_EXIBIR_LOADING]: ({ ...state, exibirLoadingLotesDoArquivo: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE]: ({
            ...state,
            itensDoLote: (action.payload && action.payload.resultado) || [],
            ultimaChave: (action.payload && action.payload.ultimaChave) || ''
        }),
        [METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE_ERRO]: ({ ...state, erroItensDoLote: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE_LOADING]: ({ ...state, exibirLoadingItensDoLote: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_DETALHES_DO_ARQUIVO]: ({ ...state, detalhesDoArquivo: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_DETALHES_DO_ARQUIVO_ERRO]: ({ ...state, erroDetalhesDoArquivo: action.payload }),
        [METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_EXIBIR_LOADING]: ({ ...state, exibirLoadingDetalhesDoArquivo: action.payload })
    }[action.type] || state
);
