export const CONSTANTE = {
    NENHUMA_PRESTACAO_CONTAS_ENCONTRADA: 'Nenhuma prestação de contas encontrada.',
    TITULO_PANEL_RESULTADO_BUSCA_PRESTACOES: 'Consulta de Prestações'
};
export const EMesageErroConfirm = {
    '_msgPadraoAprovado': 'Deseja aprovar a prestação de conta?',
    '_msgPadraoReprovado': 'Deseja reprovar a prestação de conta?'
};
export const EMesagemErroHelpBlock = {
    '_SUCESSO': 'SUCESSO',
    '_ERRO': 'ERRO'
};
export const TipoOperacao = {
    '_APROVAR': 'aprovado',
    '_CANCELAR': 'cancelado',
    '_REPROVAR': 'recusado',
    '_PENDENTE': 'pendenteAprovacao',
    '_JUSTIFICADO': 'JUSTIFICADO',
    '_TODOS': 'TODOS',
    '_SELECIONE': 'SELECIONE'
};
export const controls = [
    {
        'type': 'select',
        'id': 'periodo',
        'index': '0'
    },
    {
        'type': 'select',
        'id': 'status',
        'index': '0'
    },
    /*  {
         "type": "select",
         "id": "modulo",
         "index": "0"
     },
    {
        "type": "input",
        "id": "clienteprojeto"
    }, */
    {
        'type': 'input',
        'id': 'dataDe'
    },
    {
        'type': 'input',
        'id': 'dataAte'
    },
    {
        'type': 'inputMaskReact',
        'id': 'cartaoId'
    }, /*
    {
        "type": "input",
        "id": "nomeportador"
    },
     {
        "type": "input",
        "id": "documentoportador"
    }, */
    {
        'type': 'SelectReact',
        'id': 'clienteprojeto',
        'index': '0'
    },
    {
        'type': 'SelectReact',
        'id': 'classificacao',
        'index': '0'
    }
];
export const TipoPeriodo = {
    '_MES_ANTERIOR': { 'NOME': 'MES_ANTERIOR', 'VALOR': 0 },
    '_MES_ATUAL': { 'NOME': 'MES_ATUAL', 'VALOR': 1 }
};

export const STATUS_VALIDACAO_COMPONENTES = {
    ERRO: 'error',
    SUCESSO: 'sucesso',
    NULL: null
};

export const PAGINACAO = 3;
export const PAGINACAO_CSV = 10000;
export const HEADER_MSG = '[STATUS] - [PRESTACAO] [MOTIVO]';
export const TipoStatusHeaderMsg = {
    '_FALHA': 'FALHA',
    '_SUCESSO': 'SUCESSO'
};
