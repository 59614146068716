import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import Extrato from '../extrato/extrato';
import { DESCRICAO_CONTA, LABEL_COMUNS, CONTA_SELECIONADA } from '../util/contantes-comuns/constantes-comuns';

const DetalheCartao = (props) => {
    const cartaoSelecionado = reactLocalStorage.getObject(LABEL_COMUNS.CARTAO_SELECIONADO);
    cartaoSelecionado.id = cartaoSelecionado.cartaoId;
    cartaoSelecionado.descricao = DESCRICAO_CONTA.CONTA_CARTAO;
    cartaoSelecionado.contaPaiId = CONTA_SELECIONADA.OBJETO().id;
    cartaoSelecionado.pessoaTitularId = CONTA_SELECIONADA.OBJETO().pessoaTitularId;
    return (
        <div>
            <div className="row">
                <Extrato {...props} contaSelecionada={cartaoSelecionado} />
            </div>
        </div>
    );
};
export default DetalheCartao;
