import { DESCRICAO_CONTA, OPERACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { fetchContractualAttachment } from './anexo-contratual-base';
import { environment } from '../../configuracoes/environment';

export const retornaAnexosDoTipoContaMovimento = (listaDeAnexosContratuais) => {
    return listaDeAnexosContratuais.filter(anexo => anexo.contaDescricao === DESCRICAO_CONTA.CONTA_MOVIMENTO);
};

export function retornaAnexosParaCombo(anexos) {
    let listaAnexos = [];
    anexos.map((anexo) => {
        return listaAnexos.push({ value: anexo.id, label: anexo.nome, anexoCompleto: anexo });
    });
    return listaAnexos;
}

export function retornaAnexoCartaoVirtual(listaDeAnexos) {
    let anexoContratualOriginal = {};
    listaDeAnexos.map(anexo => {
        if (anexo.anexoCompleto.contaDescricao === DESCRICAO_CONTA.CONTA_CARTAO) {
            if (anexo.anexoCompleto.midia) {
                if (anexo.anexoCompleto.midia.tipoCartao === OPERACAO.NOME.MIDIA.VIRTUAL) {
                    anexoContratualOriginal = anexo.anexoCompleto;
                }
            }
        }
    });
    return anexoContratualOriginal;
}

export const getContractualAttachmentCreateCard = async (pessoaTitularId) => {
    const produtoPublicadoId = environment.produtoPublicado.pedidoCartaoPessoaFisica;
    return await fetchContractualAttachment(produtoPublicadoId, pessoaTitularId);
};