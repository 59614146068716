import { METODOS } from '../pedidos-constantes';
import { montaUrlFetch, HTTP_METODOS, HEADER_API, urlServicos, URL_ARQUIVO } from '../../../servicos/url-servicos';
import { CAMPOS } from '../../util/contantes-comuns/constantes-comuns';

var syncRequest = require('sync-request');

export const saveFormPedidoCartaoVirtual = (payload) => ({
    type: METODOS.PEDIDO_CARTAO_VIRTUAL.SAVE_FORM_PEDIDO_CARTAO_VIRTUAL,
    payload
});

export function getImagem(felId) {
    let comboDeImpressaoRecuperado = {};
    return function(dispatch) {
        return Promise.all([
            getComboPadraopPorFelId(felId)
        ]).then(result => {
            comboDeImpressaoRecuperado = result[0].resultado[0];
            comboDeImpressaoRecuperado.urlImagem = getUlrPreAssinada(comboDeImpressaoRecuperado.imagemCartaoId);
            dispatch({
                type: METODOS.PEDIDO_CARTAO_VIRTUAL.GET_IMAGEM,
                payload: comboDeImpressaoRecuperado
            });
        }).catch(err => {
            dispatch({
                type: METODOS.PEDIDO_CARTAO_VIRTUAL.GET_IMAGEM_ERRO,
                payload: err
            });
        });
    };
}

function getUlrPreAssinada(imagemId) {
    let url = URL_ARQUIVO() + '/' + imagemId;
    var resposta = syncRequest(HTTP_METODOS.GET, url, HEADER_API());
    if (resposta.statusCode === 200) {
        var assinatura = JSON.parse(resposta.getBody());
        return assinatura.urlPreAssinada;
    }
    return null;
}

function getComboPadraopPorFelId(felId) {
    let filtro = { [CAMPOS.FEL_ID]: felId };
    return fetch(montaUrlFetch(urlServicos.COMBO_IMPRESSAO, filtro), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(response => response.json());
}

export function solicitarCartaoVirtual(dadosJson) {
    return function(dispatch) {
        return fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: dadosJson
        }).then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then(response => {
                if (response.error) {
                    dispatch({
                        type: METODOS.PEDIDO_CARTAO_VIRTUAL.SOLICITAR_CARTAO_VIRTUAL_ERRO,
                        payload: response.data
                    });
                } else {
                    dispatch({
                        type: METODOS.PEDIDO_CARTAO_VIRTUAL.SOLICITAR_CARTAO_VIRTUAL,
                        payload: response.data
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: METODOS.GET_ANEXO_BY_ID_ERRO,
                    payload: err
                });
            });
    };
}
