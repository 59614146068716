//import './lista-cartoes.css';
import React, { Component } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import If from 'componentes/componentes-uteis/if/if';
import * as actions from 'action/cartoes/cartoes-action';
import {
    setCartaoSelecionadoParaVincularPortador,
    setExibirModalDeVincularPortador
} from '../../../../comuns/portador/vincular-portador/vincular-portador-action';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import { CONTA_SELECIONADA, LABEL_COMUNS, OPERACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import GridCartoesBppCorp from 'componentes/solucoes/dashboard/bpp-corp/cartoes/grid-cartoes-bpp-corp';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import CargaEDescargaCartao from 'componentes/cartao/carga-e-descarga-cartao/carga-e-descarga-cartao';
import VincularPortador from 'componentes/comuns/portador/vincular-portador/vincular-portador';
import DetalhesDoPortador from 'componentes/comuns/portador/detalhes-do-portador';
import {
    outorgaParametroOperacao,
    podeAprovar,
    podeConsultar,
    podeCriar
} from 'reducer/outorga/outorga-operacao-reducer';
import AlterarPortador from 'componentes/comuns/portador/alterar-portador/alterar-portador';
import BloquearCartao from 'componentes/cartao/bloquear-cartao/bloquear-cartao';
import DesbloquearCartao from 'componentes/cartao/desbloquear-cartao/desbloquear-cartao';
import CancelarCartao from 'componentes/cartao/cancelar-cartao/cancelar-cartao';


class ListaCartoesBppCorp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recarregarTela: false, exibirModalAlterarPortador: false, showCancelCardModal: false,
            showLockCardModal: false, showFinalizationModal: false, showUnlockCardModal: false, showUnlockFinalizationModal: false,
            showCancelFinalizationModal: false, showLockFinalizationModal: false, abrirCartoesProntosParaAtivacao: false,
            abrirCartoesAtivos: false, abrirCartoesBloqueados: false, abrirCartoesCancelados: false,
            showChargeOrDischargeCard: false, posicaoDoGetCartoes: 0
        };
    }


    componentDidMount() {
        this.props.setClearState();
        this.getCartoes();
        this.props.limparFiltroCartao();
        this.props.limparCartoes();
        const posicao = this.state.posicaoDoGetCartoes + 1;
        this.setPosicaoDoGetCartoes(posicao);
    }

    getCartoes = () => {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getCartoesExpandido(contaSelecionada.id, this.props.produtosIdsBppCorp);
    };

    buscarMaisCartoes = () => {
        const posicao = this.state.posicaoDoGetCartoes + 1;
        this.setPosicaoDoGetCartoes(posicao);
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getCartoesExpandido(contaSelecionada.id, this.state.posicaoDoGetCartoes);
    }

    setPosicaoDoGetCartoes = (posicaoDoGetCartoes) => {
        this.setState({ posicaoDoGetCartoes });
    }

    setShowLockCardModal = (showLockCardModal) => {
        this.setState({ showLockCardModal });
    };

    setShowUnlockCardModal = (showUnlockCardModal) => {
        this.setState({ showUnlockCardModal });
    };

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setShowUnlockFinalizationModal = (showUnlockFinalizationModal) => {
        this.setState({ showUnlockFinalizationModal });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    setAbrirCartoesProntosParaAtivacao = (abrirCartoesProntosParaAtivacao) => {
        this.setState({ abrirCartoesProntosParaAtivacao });
    };
    setAbrirCartoesAtivos = (abrirCartoesAtivos) => {
        this.setState({ abrirCartoesAtivos });
    };
    setAbrirCartoesBloqueados = (abrirCartoesBloqueados) => {
        this.setState({ abrirCartoesBloqueados });
    };
    setAbrirCartoesCancelados = (abrirCartoesCancelados) => {
        this.setState({ abrirCartoesCancelados });
    };

    setExibirModalDetalhePortador = (exibirModalDetalhePortador) => {
        this.setState({ exibirModalDetalhePortador });
    };

    setExibirModalAlterarPortador = (exibirModalAlterarPortador) => {
        this.setState({ exibirModalAlterarPortador });
    };

    setShowChargeOrDischargeCard = (showChargeOrDischargeCard) => {
        this.setState({ showChargeOrDischargeCard });
    }

    render() {
        const { showCancelCardModal,
            showLockCardModal, showUnlockCardModal, showUnlockFinalizationModal, showCancelFinalizationModal,
            showLockFinalizationModal, showChargeOrDischargeCard
        } = this.state;

        const { contaSelecionada, cartaoSelecionado, exibirLoadingCartao, operacaoSelecionada } = this.props.estadoCartoes;

        const { grantChangeStatusCard, grantUnlockCard,
            grantChargeCard, grantDisChargeCard, outorgaPerfils } = this.props;

        if (this.state.recarregarTela) {
            return <Redirect to={ROTAS_CAMINHOS.CARTOES} />;
        }

        return (
            <div>
                {exibirLoadingCartao ? <LoadingFenix /> : false}
                {contaSelecionada && <ContaSelecionada contaSelecionada={this.props.estadoCartoes.contaSelecionada}
                    exibirSaldo={true}
                    exibirFiltroCartoes={true}
                    exportarCsv={true}
                    exibirNomeECpfDoPortador={true}

                    cartaoId={this.props.estadoCartoes.cartaoId}
                    setCartaoId={this.props.setCartaoId}
                    cartaoIdErro={this.props.cartaoIdErro}

                    nomePortador={this.props.estadoCartoes.nomePortador}
                    setNomePortador={this.props.setNomePortadorCartao}
                    nomePortadorErro={this.props.estadoCartoes.nomePortadorErro}

                    numeroDocumentoPortador={this.props.estadoCartoes.numeroDocumentoPortador}
                    setNumeroDocumentoPortador={this.props.setNumeroDocumentoPortadorCartao}
                    numeroDocumentoPortadorErro={this.props.estadoCartoes.numeroDocumentoPortadorErro}

                    cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                    setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                    cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}

                    buscar={() => {
                        this.props.limparCartoes();
                        this.props.setExibirLoadingCartao(true);
                        this.setAbrirCartoesAtivos(false);
                        this.setAbrirCartoesProntosParaAtivacao(false);
                        this.setAbrirCartoesBloqueados(false);
                        this.setAbrirCartoesCancelados(false);

                        if (this.props.estadoCartoes.cartaoId || this.props.estadoCartoes.nomePortador ||
                            this.props.estadoCartoes.numeroDocumentoPortador || this.props.estadoCartoes.cartaoQuatroUltimosDigitos) {

                            this.props.searchCartoes(contaSelecionada.id, this.props.estadoCartoes.cartaoId,
                                this.props.estadoCartoes.nomePortador,
                                this.props.estadoCartoes.numeroDocumentoPortador,
                                this.props.estadoCartoes.cartaoQuatroUltimosDigitos);
                        }else {
                            this.state.posicaoDoGetCartoes = 0;
                            this.buscarMaisCartoes();
                        }

                    }}
                    limparFiltro={this.props.limparFiltroCartao}
                    listaParaSerconvertidaEmCSV={this.props.estadoCartoes.listaDeCartoesRetornados} />}

                <div className="alinhamento-centro">
                    <If test={this.props.estadoCartoes.exibirLoadingCartao}>
                        <LoadingFenix />
                    </If>
                </div>

                <CargaEDescargaCartao
                    exibirModal={showChargeOrDischargeCard}
                    contaSelecionada={contaSelecionada}
                    cartaoSelecionado={cartaoSelecionado}
                    operacaoSelecionada={operacaoSelecionada}
                    setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                    grantChargeCard={grantChargeCard}
                    grantDisChargeCard={grantDisChargeCard}
                    grantProfile={outorgaPerfils}
                />

                <VincularPortador
                    produtoId={this.props.produtosIdsBppCorp}
                    cartaoSelecionado={cartaoSelecionado}
                    contaSelecionada={contaSelecionada}
                    setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador} />

                <DetalhesDoPortador
                    cartaoSelecionado={cartaoSelecionado}
                    exibirModal={this.state.exibirModalDetalhePortador}
                    setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                />

                <BloquearCartao
                    showLockCardModal={showLockCardModal}
                    setShowLockLoading={this.props.setExibirLoadingCartao}
                    lockSelectedCard={cartaoSelecionado}
                    lockGetCards={this.getCartoes}
                    setShowLockCardModal={this.setShowLockCardModal}
                    showLockFinalizationModal={showLockFinalizationModal}
                    setShowLockFinalizationModal={this.setShowLockFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />
                <DesbloquearCartao
                    showUnlockCardModal={showUnlockCardModal}
                    setShowUnlockLoading={this.props.setExibirLoadingCartao}
                    unlockSelectedCard={cartaoSelecionado}
                    unlockGetCards={this.getCartoes}
                    setShowUnlockCardModal={this.setShowUnlockCardModal}
                    showUnlockFinalizationModal={showUnlockFinalizationModal}
                    setShowUnlockFinalizationModal={this.setShowUnlockFinalizationModal}
                    grantUnlockCard={grantUnlockCard}
                    grantProfile={outorgaPerfils}
                />

                <CancelarCartao
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />

                <AlterarPortador
                    cartaoSelecionado={this.state.exibirModalAlterarPortador && this.props.estadoCartoes.cartaoSelecionado}
                    getCartoes={this.getCartoes}
                    exibirModalAlterarPortador={this.state.exibirModalAlterarPortador}
                    setExibirModalAlterarPortador={this.setExibirModalAlterarPortador} />

                <div className="row">
                    <If test={this.props.estadoCartoes.listaDeCartoesAtivos.length > 0}>
                        <GridCartoesBppCorp
                            outorgaPerfils={outorgaPerfils}
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.ATIVOS}
                            setExibirModalAlerarPortadorCartao={this.setExibirModalAlterarPortador}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesAtivos.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesAtivos}
                            abrirCartoes={this.state.abrirCartoesAtivos}
                            setAbriSecaoCartao={() => this.setAbrirCartoesAtivos(!this.state.abrirCartoesAtivos)}
                            setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowCancelCardModal={this.setShowCancelCardModal}
                            setShowLockCardModal={this.setShowLockCardModal}
                            mostrarSaldoCartao={true}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length > 0}>
                        <GridCartoesBppCorp
                            outorgaPerfils={outorgaPerfils}
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            setExibirModalAlerarPortadorCartao={this.setExibirModalAlterarPortador}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length}
                            vincularPortador={true}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao}
                            abrirCartoes={this.state.abrirCartoesProntosParaAtivacao}
                            setAbriSecaoCartao={() => this.setAbrirCartoesProntosParaAtivacao(!this.state.abrirCartoesProntosParaAtivacao)}
                            tipoDeAcesso={reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)} exibirModalVincularPortador={() => this.props.setExibirModalVincularPortador(true)}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowCancelCardModal={this.setShowCancelCardModal}
                            setShowLockCardModal={this.setShowLockCardModal}
                            setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                            setCartaoSelecionadoParaVincularPortador={this.props.setCartaoSelecionadoParaVincularPortador}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesBloqueados.length > 0}>
                        <GridCartoesBppCorp
                            outorgaPerfils={outorgaPerfils}
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.BLOQUEADOS}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados}
                            abrirCartoes={this.state.abrirCartoesBloqueados}
                            setAbriSecaoCartao={() => this.setAbrirCartoesBloqueados(!this.state.abrirCartoesBloqueados)}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowUnlockCardModal={this.setShowUnlockCardModal}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length > 0}>
                        <GridCartoesBppCorp
                            outorgaPerfils={outorgaPerfils}
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS_E_OUTROS}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros}
                            abrirCartoes={this.state.abrirCartoesCancelados}
                            setAbriSecaoCartao={() => this.setAbrirCartoesCancelados(!this.state.abrirCartoesCancelados)}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        />
                    </If>
                </div>
                {this.props.estadoCartoes.msgListaVazia}
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita alinhamento-button">
                    <div className="row">
                        <Button type="button" bsStyle="primary" disabled={this.props.estadoCartoes.quantidadeRegistros < 100} onClick={this.buscarMaisCartoes}>{LABEL_COMUNS.BUSCAR_MAIS}</Button>
                        <br /><br />
                    </div>
                </Grid>
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <div className="row">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                        <br /><br />
                    </div>
                </Grid>
            </div>
        );
    }

    recarregarTela() {
        this.setState({ recarregarTela: true });
    }
}

function mapStateToProps(state) {
    return {
        estadoCartoes: state.CartoesReducer,
        outorgaPerfils: {
            podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
            podeCriar: podeCriar(state.OutorgaOperacaoReducer),
            podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
        },
        grantChangeStatusCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERA_STATUS_CARTAO),
        grantUnlockCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESBLOQUEIO_CARTAO),
        grantChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.CARGA_CARTAO),
        grantDisChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESCARGA_CARTAO)
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesBppCorp);
