import { METODOS, DESCRICAO_CONTA } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaAnexosParaCombo } from '../../servicos/anexo-contratual/anexo-contratual-service';

const ESTADO_INICIAL = {
    solucoes: {}, listaDeAnexosContratuais: [], erroNaBuscaDosAnexo: '', anexoContratualRecuperado: undefined, anexoContratualCartaoVirtual: undefined
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case METODOS.ANEXO_CONTRATUAL.FETCHING:
            return { ...state, isFetching: true };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS:
            const { registros } = action.payload;
            return { ...state, listaDeAnexosContratuais: retornaAnexosParaCombo(registros) };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXOS_CONTRATUAIS_ERRO:
            return { ...state, erroNaBuscaDosAnexo: action.payload };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXO_BY_ID:
            return { ...state, anexoContratualRecuperado: action.payload.registros[0] };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXO_BY_ID_ERRO:
            return { ...state, erroNaBuscaDosAnexo: action.payload };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXO_CARTAO_VIRTUAL:
            return { ...state, anexoContratualCartaoVirtual: action.payload };
        case METODOS.ANEXO_CONTRATUAL.GET_ANEXO_CARTAO_VIRTUAL_ERRO:
            return { ...state, erroNaBuscaDosAnexo: action.payload };
        case METODOS.ANEXO_CONTRATUAL.GET_SOLUCOES:
            return { ...state, solucoes: action.payload, erroNaBuscaDosAnexo: '', isFetching: false };
        case METODOS.ANEXO_CONTRATUAL.GET_SOLUCOES_ERRO:
            return { ...ESTADO_INICIAL, erroNaBuscaDosAnexo: state.erroNaBuscaDosAnexo };
        case METODOS.ANEXO_CONTRATUAL.GERA_ANEXO_PESSOA_FISICA:
            return { ...state, listaDeAnexosContratuais: action.payload.registros };
        case METODOS.ANEXO_CONTRATUAL.GERA_ANEXO_PESSOA_FISICA_ERRO:
            return { ...state, erroNaGeracaoDeAnexoPessoaFisica: action.payload };
        default:
            return state;
    }
};

export function getSolucaoBppCorp(state) {
    return state.solucoes && state.solucoes.BppCorp;
}

export function getSolucaoBppPag(state) {
    return state.solucoes && state.solucoes.BppPag;
}

export function hasBppCorpSolucao(state) {
    return state.solucoes.BppCorp && state.solucoes.BppCorp.length;
}

export function produtosIdsBppCorp(state) {
    return state.solucoes.BppCorp && state.solucoes.BppCorp.map(({ produtoId }) => produtoId).join(',');
}

export function produtosIdsBppPag(state) {
    return state.solucoes.BppPag && state.solucoes.BppPag.map(({ produtoId }) => produtoId).join(',');
}

export function hasBppPagSolucao(state) {
    return state.solucoes.BppPag && state.solucoes.BppPag.length;
}

export function getAnexosBppCorp(state) {
    const produtoIdsBppCorp = state.solucoes.BppCorp && state.solucoes.BppCorp.map(({ produtoId }) => produtoId);
    return state.listaDeAnexosContratuais.filter(anexo => produtoIdsBppCorp.includes(anexo.anexoCompleto.produtoId));
}

export function getAnexosBppPag(state) {
    const produtoIdsBppPag = state.solucoes.BppCorp && state.solucoes.BppPag.map(({ produtoId }) => produtoId);
    return state.listaDeAnexosContratuais.filter(anexo => produtoIdsBppPag.includes(anexo.anexoCompleto.produtoId));
}

export function getAnexoRecuperadoPorId(state) {
    return state.anexoContratualRecuperado;
}

export function getAnexoParaPedidoEmbossingCartao(state) {
    if (state.anexoContratualRecuperado && state.anexoContratualRecuperado.operacao) {
        let anexoContratualPedidoEmbossingCartao = state.anexoContratualRecuperado;
        const { operacao: { pedidoEmbossingCartao } } = state.anexoContratualRecuperado;
        anexoContratualPedidoEmbossingCartao.pedidoEmbossingCartao = pedidoEmbossingCartao;
        return anexoContratualPedidoEmbossingCartao;
    }
    return null;
}

export const getAnexosDoTipoContaMovimento = (state) => {
    return state.listaDeAnexosContratuais.filter(anexo => anexo.anexoCompleto.contaDescricao === DESCRICAO_CONTA.CONTA_MOVIMENTO);
};

export const getAnexosCartaoBppCorp = (state) => {
    return getAnexosBppCorp(state).filter(anexo => anexo.anexoCompleto.contaDescricao === DESCRICAO_CONTA.CONTA_CARTAO);
};

export const getAnexosCartaoBppPag = (state) => {
    return getAnexosBppPag(state).filter(anexo => anexo.anexoCompleto.contaDescricao === DESCRICAO_CONTA.CONTA_CARTAO);
};

export const existeAnexoCartaoBppPag = (state) => {
    return getAnexosCartaoBppPag(state).length > 0;
};
