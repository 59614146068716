import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { CARTAO_STATUS, LABEL_COMUNS, OPERACAO } from '../../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import Grid from '../../../../componentes-uteis/grid/grid';
import If from '../../../../componentes-uteis/if/if';
import imagemCartao from '../../../../../imagens/cartao-chip.jpg';
import { formatarStatus } from '../../../../util/cartoes/cartoes-util';

class GridCartoesDashboardBppPag extends Component {
    render() {
        const { podeCriar } = this.props.outorgaPerfils;
        return (
            <div>
                <If test={this.props.listaDeCartoes && this.props.listaDeCartoes.length > 0}>
                    <div className="row" style={{ marginTop: '15px' }}>
                        <Grid cols="12 12 12 12">
                            <Grid cols="2 2 2 2" />

                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CARTOES.NUMERO_DO_CARTAO}</span>
                            </Grid>
                            <Grid cols="2 2 2 2 " className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CARTOES.VENCIMENTO}</span>
                            </Grid>
                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CARTOES.CARTAO_ID}</span>
                            </Grid>
                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.STATUS}</span>
                            </Grid>
                            <Grid cols="1 1 1 1" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao acao-lista-cartoes-pag">{LABEL_COMUNS.ACAO}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="grid-cartoes-bpppag-dashboard" style={{ maxHeight: '265px' }} >
                        <div className="grids-cartoes-bpppag" >
                            {this.props.listaDeCartoes.map((cartao, idx) => {
                                return (
                                    <div className="row" key={idx}>
                                        <Grid cols="12 12 12 12">
                                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                <img src={imagemCartao} className="avatar-cartao" alt=" imagem cartao" />
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                <span style={{ fontSize: '11px' }}>
                                                    {cartao.panFormatado}
                                                </span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                <span style={{ fontSize: '11px' }}>
                                                    {cartao.validade}
                                                </span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                <span style={{ fontSize: '11px' }}>
                                                    <b>
                                                        {cartao.idCartaoImpresso}
                                                    </b>
                                                </span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                <span style={{ fontSize: '11px' }}>
                                                    {formatarStatus(cartao.status)}
                                                </span>
                                            </Grid>
                                            <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-10px' }} disabled={cartao.status === CARTAO_STATUS.CANCELADO}>
                                                    <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                        <Glyphicon glyph="option-horizontal" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <If test={podeCriar}>
                                                            <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowCancelCardModal(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CANCELA_CARTAO);
                                                                }}>
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                                                                </MenuItem>

                                                                <li>
                                                                    <Link className="link_expandir" to={{ pathname: ROTAS_CAMINHOS.ALTERAR_TITULARIDADE_CARTAO, state: { cartaoSelecionado: cartao, contextoBppPag: true, dashboardBppPag: true } }}
                                                                        onClick={() => this.props.setArmazenarCartao(cartao)}>
                                                                        {LABEL_COMUNS.CARTOES.MUDAR_TITULARIDADE}
                                                                    </Link>
                                                                </li>
                                                            </If>
                                                        </If>
                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            }
                            )}
                        </div>
                    </div>
                </If>
                <If test={this.props.listaDeCartoes.length <= 0}>
                    <div className="nenhum-cartao-bpppag">
                        <span>{LABEL_COMUNS.NENHUM_CARTAO_ENCONTRADO()}</span>
                    </div>
                </If>
            </div >
        );
    }
}
export default GridCartoesDashboardBppPag;
