import React from 'react';
import { stringParaNumero, removeMascaraTelefoneCpfCnpj } from 'componentes/util/formata-string';
import { formatarDataUS } from 'componentes/util/data/dataUtil';
import { recuperaSomenteNumerosdeUmaString } from 'componentes/util/numeros/numeroUtil';
import { CAMPOS, CARTAO_STATUS, LABEL_COMUNS, OPERACAO, TIPO_DOCUMENTO, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';

export function preencheCamposDeErros(erros) {
    let camposDeErros = {};
    for (let i in erros) {
        camposDeErros.cargaDescargaCartaoErro = erros[i].mensagem;
    }
    return camposDeErros;
}

export function preencheCamposDeErrosVincularPortador(erros) {
    let camposDeErros = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case CAMPOS.NOME_PORTADOR:
                camposDeErros.nomeDoPortadorErro = erros[i].mensagem;
                break;
            case CAMPOS.DATA_NASCIMENTO:
                camposDeErros.dataDeNascimentoDoPortadorErro = erros[i].mensagem;
                break;
            default:
                camposDeErros.numeroDoDocumentoDoPortadorErro = erros[i].mensagem;
                break;
        }
    }
    return camposDeErros;
}

export function montaDadosParaSolicitarOperacao(dadosForm) {
    let contaOrigemSelecionadaId = dadosForm.contaSelecionada.id;
    let contaDestinoSelecionadaId = dadosForm.cartaoSelecionado.cartaoId;
    let valorOperacao = stringParaNumero(dadosForm.valorOperacao);

    if (dadosForm.operacaoSolicitada === OPERACAO.NOME.DESCARGA_CARTAO) {
        contaOrigemSelecionadaId = dadosForm.cartaoSelecionado.cartaoId;
        contaDestinoSelecionadaId = dadosForm.contaSelecionada.id;
    }

    let dadosNormalizados = {
        operacao: dadosForm.operacaoSolicitada,
        valor: valorOperacao,
        contaOrigemId: contaOrigemSelecionadaId,
        contaDestinoId: contaDestinoSelecionadaId,
        tagCliente: dadosForm.tagCliente ? dadosForm.tagCliente : null
    };
    return JSON.stringify(dadosNormalizados);
}

export function montaDadosTransacao(dadosForm) {
    let transacao = {};
    if (dadosForm.dataAgendamento) {
        transacao = TIPO_DE_TRANSACAO.AGENDAMENTO;
        transacao.dataAgendamento = formatarDataUS(dadosForm.dataAgendamento);
    } else {
        transacao = TIPO_DE_TRANSACAO.APROVACAO;
    }
    return transacao;
}

export function montarQueryString(produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos, status) {
    let url = '';

    if (produtoId) {
        url = url + '&produtoId=' + produtoId;
    }

    if (IDCartao) {
        url = url + '&idCartao=' + IDCartao;
    }

    if (nomePortador) {
        url = url + '&nomePortador=' + nomePortador;
    }

    if (numeroDocumentoPortador) {
        url = url + '&numeroDocumento=' + recuperaSomenteNumerosdeUmaString(numeroDocumentoPortador);
    }

    if (status) {
        url = url + '&tipoFiltro=' + status;
    }

    if (cartaoQuatroUltimosDigitos) {
        url = url + '&cartaoQuatroUltimosDigitos=' + cartaoQuatroUltimosDigitos;
    }
    return url;
}

export function montaDadosJsonDoPortadorParaBackEnd(dadosForm) {
    let dadosNormalizados = {};
    try {
        dadosNormalizados = {
            cartaoId: dadosForm.cartaoSelecionado.idCartaoImpresso,
            dataNascimento: formatarDataUS(dadosForm.dataDeNascimentoDoPortador),
            nomePortador: dadosForm.nomeDoPortador,
            numeroDocumento: removeMascaraTelefoneCpfCnpj(dadosForm.numeroDoDocumentoDoPortador),
            tipoDocumento: TIPO_DOCUMENTO.CPF.value
        };
        dadosNormalizados = JSON.stringify(dadosNormalizados);
    } catch (err) {

    }
    return dadosNormalizados;
}

export function formatarStatus(status) {
    switch (status) {
        case CARTAO_STATUS.ATIVO:
            return (<span className="status-ativo">Ativo</span>);
        case CARTAO_STATUS.PRONTOS_PARA_ATIVACAO:
            return (<span className="status-pronto-ativacao">Pronto para ativação</span>);
        case CARTAO_STATUS.BLOQUEADO:
            return (<span className="status-bloqueada">Bloqueado</span>);
        case CARTAO_STATUS.CANCELADO:
            return (<span className="status-cancelado">Cancelado</span>);
        default:
            return (<span className="status-encerrada">Encerrado</span>);
    }
}

export function retornaTitulDialogCartao(operacao) {
    switch (operacao) {
        case OPERACAO.NOME.CARGA_CARTAO:
            return LABEL_COMUNS.CARTOES.TITULOS.CARGA_CARTAO;
        case OPERACAO.NOME.DESCARGA_CARTAO:
            return LABEL_COMUNS.CARTOES.TITULOS.DESCARGA_CARTAO;
        case OPERACAO.NOME.ALTERA_STATUS_CARTAO:
            return LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO;
        case OPERACAO.NOME.CANCELA_CARTAO:
            return LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO;
        case OPERACAO.NOME.DESBLOQUEIO_CARTAO:
            return LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO;
        default:
            return LABEL_COMUNS.CARTOES.TITULOS.VINCULAR_PORTADOR;
    }
}

export function retornaCorpoRequiscaoCancelarCartao(contaDestinoId) {
    let corpoRequisicao = {
        operacao: OPERACAO.NOME.ALTERA_STATUS_CARTAO,
        valor: 0,
        contaDestinoId,
        dadosIntegracao: {
            status: CARTAO_STATUS.CANCELADO
        }
    };
    return JSON.stringify(corpoRequisicao);
}

export function retornaCorpoRequiscaoBloquearCartao(contaDestinoId) {
    let corpoRequisicao = {
        operacao: OPERACAO.NOME.ALTERA_STATUS_CARTAO,
        valor: 0,
        contaDestinoId: contaDestinoId,
        dadosIntegracao: {
            status: CARTAO_STATUS.BLOQUEADO
        }
    };
    return JSON.stringify(corpoRequisicao);
}

export function retornaCorpoRequiscaoDesbloquearCartao(contaDestinoId, statusCartao) {
    let corpoRequisicao = {
        operacao: OPERACAO.NOME.DESBLOQUEIO_CARTAO,
        valor: 0,
        contaDestinoId: contaDestinoId,
        dadosIntegracao: {
            status: statusCartao
        }
    };
    return JSON.stringify(corpoRequisicao);
}

export function retornaCorpoRequisicaoTransacao(tokenSMS) {
    let dadosTransacao = {};
    try {
        dadosTransacao = {
            transacao: TIPO_DE_TRANSACAO.APROVACAO.transacao,
            token: tokenSMS
        };
    } catch (err) {
    // console.log("Erro na criação do Json de para transação de desbloqueio de cartão", err)
    }
    return JSON.stringify(dadosTransacao);
}

export function montaObjetoTokenParaRequisicacao(tokenSMS) {
    let tokenParaRetornar = { token: tokenSMS };
    return tokenParaRetornar;
}

export function filtraCartoes(listaDeCartoes, status) {
    let cartoesParaRetornar = [];
    if (listaDeCartoes) {
        return listaDeCartoes.filter(cartao => regraParaValidacaoDeStatus(cartao, status));
    }
    return cartoesParaRetornar;
}

const regraParaValidacaoDeStatus = (cartao, status) => {
    if (status === CARTAO_STATUS.CANCELADOS_E_OUTROS) {
        if ((cartao.status !== CARTAO_STATUS.ATIVO) &&
      (cartao.status !== CARTAO_STATUS.BLOQUEADO) &&
      (cartao.status !== CARTAO_STATUS.PRONTOS_PARA_ATIVACAO)) {
            return true;
        }
        return false;
    }
    return cartao.status === status;
};

export function montaDadosParaAlteracaoDeTitularidadeCartao(dados, tokenId) {
    let dadosParaRetornar = {};
    if (tokenId) {
        dadosParaRetornar.tokenId = tokenId;
        dadosParaRetornar.token = dados.tokenSMSAlterarTitularidadeCartao;
    }
    dadosParaRetornar.pessoaTitular = {};
    dadosParaRetornar.pessoaTitular.nomeTitular = dados.nomeTitular;
    dadosParaRetornar.pessoaTitular.tipoDocumentoTitular = dados.tipoDocumentoTitular;
    dadosParaRetornar.pessoaTitular.numeroDocumentoTitular = removeMascaraTelefoneCpfCnpj(dados.numeroDocumentoTitular);
    dadosParaRetornar.pessoaTitular.dataNascimentoTitular = formatarDataUS(dados.dataNascimentoTitular);
    dadosParaRetornar.pessoaTitular.celularTitular = removeMascaraTelefoneCpfCnpj(dados.celularTitular);
    if (dados.tipoDocumentoTitular === TIPO_DOCUMENTO.CNPJ.value) {
        dadosParaRetornar.pessoaPortador = {};
        dadosParaRetornar.pessoaPortador.nomePortador = dados.nomePortador;
        dadosParaRetornar.pessoaPortador.numeroDocumentoPortador = removeMascaraTelefoneCpfCnpj(dados.numeroDocumentoPortador);
        dadosParaRetornar.pessoaPortador.dataNascimentoPortador = formatarDataUS(dados.dataNascimentoPortador);
        dadosParaRetornar.pessoaPortador.celularPortador = removeMascaraTelefoneCpfCnpj(dados.celularPortador);
    }
    return JSON.stringify(dadosParaRetornar);
}

export const verificaStatusEmProcessamentoDoCartao = (listaDeOperacoes) => {
    return listaDeOperacoes.filter(op => (op.statusInterno === OPERACAO.STATUS.EM_PROCESSAMENTO))[0];
};

export const trataErrosAlterarTitularidadeCartao = (erros) => {
    const errosParaRetornar = {};
    erros.map((erro, i) => {
        switch (erro.campo) {
            case 'pessoaTitular.dataNascimentoTitular':
                errosParaRetornar.dataNascimentoTitularErro = erro.mensagem;
                break;
            case 'pessoaPortador.dataNascimentoPortador':
                errosParaRetornar.dataNascimentoPortadorErro = erro.mensagem;
                break;
            case 'pessoaTitular.numeroDocumentoTitular':
                errosParaRetornar.numeroDocumentoTitularErro = erro.mensagem;
                break;
            case 'pessoaPortador.numeroDocumentoPortador':
                errosParaRetornar.numeroDocumentoPortadorErro = erro.mensagem;
                break;
            case 'pessoaTitular.celularTitular':
                errosParaRetornar.celularTitularErro = erro.mensagem;
                break;
            case 'pessoaPortador.numeroDocumentoPortador':
                errosParaRetornar.celularPortadorErro = erro.mensagem;
                break;
            default:
                errosParaRetornar.erroGenerico = erro.mensagem;
                break;
        }
    });
    return errosParaRetornar;
};

/* export function filtraCartoes(listaDeCartoes, status) {
    let cartoesParaRetornar = [];
    if (listaDeCartoes) {
        listaDeCartoes.map((cartao) => {
            if (status === CARTOES_STATUS.CANCELADOS_E_OUTROS) {
                if ((cartao.status !== CARTOES_STATUS.ATIVO) &&
                    (cartao.status !== CARTOES_STATUS.BLOQUEADO) &&
                    (cartao.status !== CARTOES_STATUS.PRONTOS_PARA_ATIVACAO)) {
                    cartoesParaRetornar.push(cartao);
                }
            } else {
                if (cartao.status === status) {
                    cartoesParaRetornar.push(cartao);
                }
            }
        });
    }
    return cartoesParaRetornar;
} */
