import React, { Component } from 'react';
import Highlight from 'react-highlighter';
import { Button, ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { CAMPOS, COMBO, CONTA_SELECIONADA, LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, TIPO_DOCUMENTO, TIPO_FAVORECIDO } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';
import GridRow from '../../componentes-uteis/grid/grid-row';
import If from '../../componentes-uteis/if/if';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../comuns/showLineError/show-line-error';
import { salvarFavorecido, trataErrosFavorecidos, excluirFavorecido } from '../../../servicos/contas/conta-service';
import { retornaObjetoTarget, retornaTelefoneSemDDI } from '../../../servicos/base/service-base';
class ModalEditarFavorecido extends Component {

    constructor(props) {
        super(props);
        this.CONTA_SELECIONADA = CONTA_SELECIONADA.OBJETO();
        this.state = {
            listaDeBancos: props.listaDeBancos, contaSelecionada: this.CONTA_SELECIONADA, contaId: this.CONTA_SELECIONADA.id, favorecidoId: undefined,
            mostraCamposOcultos: false, defaultMask: '', agenciaSelecionada: '', agenciaSelecionadaErro: false, bancoSelecionadoId: '',
            bancoSelecionadoIdErro: false, contaFavorecidoSelecionada: '', contaFavorecidoSelecionadaErro: false, digitoSelecionado: '',
            digitoSelecionadoErro: false, emailSelecionado: '', emailSelecionadoErro: false, excluirFavorecido: false, exibirModalConfirmacao: false,
            exibirModalFinalizacao: false, fecharModalEditarFavorecido: false, mensagemConfirmacao: '', mensagemFinalizacao: '', nomeSelecionado: '',
            nomeSelecionadoErro: false, numeroDocumentoSelecionado: '', numeroDocumentoSelecionadoErro: false, telefoneSelecionado: '',
            telefoneSelecionadoErro: false, tipoContaSelecionada: '', tipoContaSelecionadaErro: false, tipoDocumentoSelecionado: '',
            tipoDocumentoSelecionadoErro: false, tipoFavorecidoSelecionado: '', tipoFavorecidoSelecionadoErro: false,
        };
    }

    componentDidMount = () => {
        this.preencheDadosFormulario();
    }

    preencheDadosFormulario = () => {
        const { favorecidoSelecionado } = this.props;
        if (favorecidoSelecionado) {
            const {
                agencia, banco, conta, digito, email, id, nome, numeroDocumento, telefone, tipoConta, tipoDocumento, tipoFavorecido
            } = favorecidoSelecionado;

            this.setState({
                agenciaSelecionada: agencia, bancoSelecionadoId: banco, contaFavorecidoSelecionada: conta, digitoSelecionado: digito,
                defaultMask: tipoDocumento === TIPO_DOCUMENTO.CPF.value ? MASCARA_CAMPOS.CPF : MASCARA_CAMPOS.CNPJ, emailSelecionado: email || '',
                favorecidoId: id, nomeSelecionado: nome, numeroDocumentoSelecionado: numeroDocumento,
                telefoneSelecionado: retornaTelefoneSemDDI(telefone) || '', tipoContaSelecionada: tipoConta, tipoDocumentoSelecionado: tipoDocumento,
                tipoFavorecidoSelecionado: tipoFavorecido
            });
        }
    }

    setExibirModalConfirmacao = (exibirModalConfirmacao) => {
        if (!exibirModalConfirmacao) {
            this.setState({ excluirFavorecido: false, mensagemConfirmacao: '' });
        }
        this.setState({ exibirModalConfirmacao });
    }

    setExibirModalFinalizacao = (exibirModalFinalizacao) => {
        this.setState({ exibirModalFinalizacao });
    }

    setMensagemConfirmacao = (mensagemConfirmacao) => {
        this.setState({ mensagemConfirmacao });
    }

    setMensagemFinalizacao = (mensagemFinalizacao) => {
        this.setState({ mensagemFinalizacao });
    }

    setFecharModalEditarFavorecido = (fecharModalEditarFavorecido) => {
        this.setState({ fecharModalEditarFavorecido });
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        const valorDoCampo = campo && campo.value || campo && campo.target && campo.target.value;
        if (valorDoCampo === TIPO_DOCUMENTO.CPF.value) {
            this.setState({ defaultMask: MASCARA_CAMPOS.CPF, numeroDocumentoSelecionado: '' });
        }
        if (valorDoCampo === TIPO_DOCUMENTO.CNPJ.value) {
            this.setState({
                defaultMask: MASCARA_CAMPOS.CNPJ, numeroDocumentoSelecionado: ''
            });
        }
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }

    editarFavorecido = async () => {
        let mensagemFinalizacao;
        let fecharModalEditarFavorecido = false;
        this.props.setExibirLoading(true);
        this.setExibirModalConfirmacao(false);
        const resp = await salvarFavorecido(this.state);

        if (resp.error) {
            const { data: { erros } } = resp;
            this.setState(trataErrosFavorecidos(erros));
            // mensagemFinalizacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EDICAO.FINALIZACAO.ERRO(this.state.nomeSelecionado)}</Highlight>;
        }
        else {
            mensagemFinalizacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EDICAO.FINALIZACAO.SUCESSO(this.state.nomeSelecionado)}</Highlight>;
            this.setExibirModalFinalizacao(true);
            this.setFecharModalEditarFavorecido(true);
        }
        this.setExibirModalConfirmacao(false);
        this.setState({ mensagemFinalizacao, mensagemConfirmacao: '' });
        this.props.setExibirLoading(false);
    }

    excluirFavorecido = async () => {
        const { contaId, favorecidoId } = this.state;
        let mensagemFinalizacao;
        let fecharModalEditarFavorecido = false;
        this.props.setExibirLoading(true);
        this.setExibirModalConfirmacao(false);
        const resp = await excluirFavorecido(contaId, favorecidoId);
        if (resp.error) {
            mensagemFinalizacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EXCLUSAO.FINALIZACAO.ERRO(this.state.nomeSelecionado)}</Highlight>;
        }
        else {
            mensagemFinalizacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EXCLUSAO.FINALIZACAO.SUCESSO(this.state.nomeSelecionado)}</Highlight>;
            this.setFecharModalEditarFavorecido(true);
        }
        this.setExibirModalConfirmacao(false);
        this.setState({ mensagemFinalizacao, mensagemConfirmacao: '' });
        this.setExibirModalFinalizacao(true);
        this.props.setExibirLoading(false);
    }

    retornaCorpoModalEditarFavorecido = () => (
        <div>
            <GridRow>
                <Grid cols="4">
                    <ControlLabel>{LABEL_COMUNS.OPCOES_DO_FAVORECIDO}*</ControlLabel>
                    <FormGroup validationState={this.state.tipoFavorecidoSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <FormControl
                            className="mensagens-erro"
                            id="tipoFavorecido"
                            componentClass="select"
                            placeholder={LABEL_COMUNS.SELECIONE}
                            value={this.state.tipoFavorecidoSelecionado}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.TIPO_FAVORECIDO_SELECIONADO)}
                            disabled={true}
                            style={{ height: '40px', paddingTop: '6px', paddingLeft: '10px' }}>
                            {COMBO.TIPO_FAVORECIDO.map((tipoFavorecido, idx) => {
                                return (
                                    <option key={idx} value={tipoFavorecido.value}>{tipoFavorecido.label}</option>
                                );
                            })}
                        </FormControl>
                        <ShowLineError error={this.state.tipoFavorecidoSelecionadoErro} />
                    </FormGroup>
                </Grid>
                <Grid cols="8">
                    <ControlLabel>{LABEL_COMUNS.NOME}*</ControlLabel>
                    <FormGroup validationState={this.state.nomeSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <FormControl
                            className="mensagens-erro"
                            id="nomeSelecionado"
                            type="text"
                            placeholder={LABEL_COMUNS.NOME}
                            value={this.state.nomeSelecionado}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.NOME_SELECIONADO)}
                            maxLength={150}
                        />
                        <ShowLineError error={this.state.nomeSelecionadoErro} />
                    </FormGroup>
                </Grid>
            </GridRow>
            <If test={this.state.tipoFavorecidoSelecionado === TIPO_FAVORECIDO.CONTA_EXTERNA.value}>
                <GridRow>
                    <Grid cols="2">
                        <ControlLabel>{LABEL_COMUNS.TIPO_DOCUMENTO}*</ControlLabel>
                        <FormGroup validationState={this.state.tipoDocumentoSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                className="mensagens-erro"
                                id="tipoDocumento"
                                componentClass="select"
                                placeholder={LABEL_COMUNS.SELECIONE}
                                value={this.state.tipoDocumentoSelecionado}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.TIPO_DOCUMENTO_SELECIONADO)} >
                                <option value={TIPO_DOCUMENTO.CPF.value} defaultValue>{TIPO_DOCUMENTO.CPF.label}</option>
                                <option value={TIPO_DOCUMENTO.CNPJ.value}>{TIPO_DOCUMENTO.CNPJ.label}</option>
                            </FormControl>
                            <ShowLineError error={this.state.tipoDocumentoSelecionadoErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="3">
                        <ControlLabel>{LABEL_COMUNS.DOCUMENTO}*</ControlLabel>
                        <FormGroup validationState={this.state.numeroDocumentoSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                                className="form-control mensagens-erro"
                                id="numeroDocumentoSelecionado"
                                placeholder={LABEL_COMUNS.DOCUMENTO}
                                mask={this.state.defaultMask}
                                maskChar=""
                                value={this.state.numeroDocumentoSelecionado}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_DOCUMENTO_SELECIONADO)}
                                maxLength={18}
                                style={{ height: '40px', paddingTop: '6px', paddingLeft: '10px' }} />
                            <ShowLineError error={this.state.numeroDocumentoSelecionadoErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="7">
                        <ControlLabel>{LABEL_COMUNS.BANCO}*</ControlLabel>
                        <FormGroup validationState={this.state.bancoSelecionadoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <Select
                                id="banco"
                                placeholder={LABEL_COMUNS.SELECIONE}
                                value={this.state.bancoSelecionadoId}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.BANCO_SELECIONADO_ID)}
                                options={this.state.listaDeBancos}
                                noResultsText={LABEL_COMUNS.NENHUM_BANCO_ENCONTRADO}
                                style={this.state.bancoSelecionadoIdErro ? { borderColor: '#a94442', height: '40px' } : { borderColor: '#e7e7e7', height: '40px' }}
                            />
                            <ShowLineError error={this.state.bancoSelecionadoIdErro} />
                        </FormGroup >
                    </Grid>
                </GridRow>
            </If>
            <GridRow>
                <If test={this.state.tipoFavorecidoSelecionado === TIPO_FAVORECIDO.CONTA_EXTERNA.value}>
                    <Grid cols="3">
                        <ControlLabel>{LABEL_COMUNS.TIPO_DE_CONTA}*</ControlLabel>
                        <FormGroup validationState={this.state.tipoContaSelecionadaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                className="mensagens-erro"
                                id="tipoContaSelecionada"
                                componentClass="select"
                                placeholder={LABEL_COMUNS.TIPO_DE_CONTA}
                                value={this.state.tipoContaSelecionada}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.TIPO_CONTA_SELECIONADA)}
                                style={{ height: '35px', paddingTop: '6px', paddingLeft: '10px' }}>
                                <option value={-1} defaultValue>{LABEL_COMUNS.TIPO_DE_CONTA}</option>
                                {COMBO.TIPO_CONTA.map((tipoConta, idx) => {
                                    return (<option key={idx} value={tipoConta.value}>{tipoConta.label}</option>);
                                })}
                            </FormControl>
                            <ShowLineError error={this.state.tipoContaSelecionadaErro} />
                        </FormGroup >
                    </Grid>
                </If>
                <Grid cols="3">
                    <ControlLabel>{LABEL_COMUNS.AGENCIA}*</ControlLabel>
                    <FormGroup validationState={this.state.agenciaSelecionadaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <InputMask
                            id="agenciaSelecionada"
                            className="form-control mensagens-erro"
                            mask={MASCARA_CAMPOS.AGENCIA}
                            maskChar=""
                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                            placeholder="Agência"
                            value={this.state.agenciaSelecionada}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.AGENCIA_SELECIONADA)}
                            disabled={this.state.tipoFavorecidoSelecionado === TIPO_FAVORECIDO.CONTA_INTERNA.value}
                            maxLength={4}
                        />
                        <ShowLineError error={this.state.agenciaSelecionadaErro} />
                    </FormGroup>
                </Grid>
                <Grid cols="3">
                    <ControlLabel>{LABEL_COMUNS.CONTAS.TITULOS.CONTA}*</ControlLabel>
                    <FormGroup validationState={this.state.contaFavorecidoSelecionadaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <FormControl
                            className="mensagens-erro"
                            id="contaFavorecidoSelecionada"
                            type="text"
                            placeholder={LABEL_COMUNS.CONTAS.TITULOS.CONTA}
                            value={this.state.contaFavorecidoSelecionada}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.CONTA_FAVORECIDO_SELECIONADA)}
                            maxLength={10}
                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                        <ShowLineError error={this.state.contaFavorecidoSelecionadaErro} />
                    </FormGroup >
                </Grid>
                <Grid cols="3">
                    <ControlLabel>{LABEL_COMUNS.DIGITO}*</ControlLabel>
                    <FormGroup validationState={this.state.digitoSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <FormControl
                            className="mensagens-erro"
                            id="digito"
                            type="text"
                            placeholder={LABEL_COMUNS.DIGITO}
                            value={this.state.digitoSelecionado}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.DIGITO_SELECIONADO)}
                            maxLength={1}
                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                        <ShowLineError error={this.state.digitoSelecionadoErro} />
                    </FormGroup>
                </Grid>
            </GridRow>
            <GridRow>
                <Grid cols="6">
                    <ControlLabel>{LABEL_COMUNS.CELULAR}</ControlLabel>
                    <FormGroup validationState={this.state.telefoneSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <InputMask
                            id="telefoneSelecionado"
                            className="form-control mensagens-erro"
                            placeholder={LABEL_COMUNS.CELULAR}
                            mask={MASCARA_CAMPOS.MOBILE}
                            maskChar=""
                            value={this.state.telefoneSelecionado}
                            onChange={(e) => this.setPropsCampos(e, CAMPOS.TELEFONE_SELECIONADO)}
                            maxLength={15}
                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                        <ShowLineError error={this.state.telefoneSelecionadoErro} />
                    </FormGroup>
                </Grid>
                <Grid cols="6">
                    <ControlLabel>{LABEL_COMUNS.EMAIL}</ControlLabel>
                    <FormGroup validationState={this.state.emailSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                        <InputGroup>
                            <InputGroup.Addon>@</InputGroup.Addon>
                            <FormControl
                                className="mensagens-erro"
                                id="emailSelecionado"
                                type="text"
                                placeholder={LABEL_COMUNS.EMAIL}
                                value={this.state.emailSelecionado}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.EMAIL_SELECIONADO)}
                                maxLength={50}
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px', zIndex: '0' }} />
                        </InputGroup>
                        <ShowLineError error={this.state.emailSelecionadoErro} />
                    </FormGroup>
                </Grid>
            </GridRow>
        </div>
    )

    retornaRodapeModalEditarFavorecido = () => (
        <FooterBotoesModalComponent
            btn1Label={LABEL_COMUNS.CANCELAR}
            btn1OnClick={() => this.props.setExibirModalEditarFavorecido(false)}
            btn2Label={LABEL_COMUNS.EXCLUIR}
            btn2OnClick={() => {
                const mensagemConfirmacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EXCLUSAO.CONFIRMACAO(this.state.nomeSelecionado)}</Highlight>;
                this.setMensagemConfirmacao(mensagemConfirmacao);
                this.setExibirModalConfirmacao(true);
                this.setState({ excluirFavorecido: true });
            }}
            btn3Label={LABEL_COMUNS.SALVAR}
            btn3OnClick={() => {
                const mensagemConfirmacao = <Highlight search={this.state.nomeSelecionado}>{MENSAGENS.CONTAS.FAVORECIDO.EDICAO.CONFIRMACAO(this.state.nomeSelecionado)}</Highlight>;
                this.setMensagemConfirmacao(mensagemConfirmacao);
                this.setExibirModalConfirmacao(true);
            }}
        />
    )

    montaRodapeModalConfirmacao = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setExibirModalConfirmacao(false)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    if (this.state.excluirFavorecido) {
                        this.excluirFavorecido();
                    } else {
                        this.editarFavorecido();
                    }
                }} />
        );
    }

    montaRodapeModalFinalizacao = () => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setExibirModalFinalizacao(false);
                    if (this.state.fecharModalEditarFavorecido) {
                        this.props.setExibirModalEditarFavorecido(false);
                        this.props.getFavorecidosContaByContaId(this.state.contaSelecionada.id);
                    }
                }} />
        );
    }


    render() {
        const tituloModal = LABEL_COMUNS.CONTAS.TITULOS.FAVORECIDO.EDITAR_FAVORECIDO.concat(' - '.concat(LABEL_COMUNS.CONTAS.TITULOS.FAVORECIDO.APELIDO_CONTA_DIGITAL(this.state.contaSelecionada.apelido)));
        return (
            <div>
                <ModalComponent
                    name="modal-editar-favorecido"
                    bsSize="lg"
                    header={tituloModal}
                    exibirModal={this.props.exibirModalEditarFavorecido}
                    body={this.retornaCorpoModalEditarFavorecido()}
                    footer={this.retornaRodapeModalEditarFavorecido()}
                />

                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={tituloModal}
                    body={this.state.mensagemConfirmacao}
                    footer={this.montaRodapeModalConfirmacao()} />

                <ModalComponent
                    exibirModal={this.state.exibirModalFinalizacao}
                    name="modal-finalizacao"
                    header={tituloModal}
                    body={this.state.mensagemFinalizacao}
                    footer={this.montaRodapeModalFinalizacao()} />
            </div>
        );
    }
}

export default ModalEditarFavorecido;