import { Button } from 'react-bootstrap';
import React, { Component } from 'react';
import { formatarDataCompletaBR } from '../../util/data/dataUtil';
import {
    LABEL_COMUNS,
    STATUS
} from '../../util/contantes-comuns/constantes-comuns';
import If from '../../componentes-uteis/if/if';
import { ESTILO } from '../../util/estilo_para_componentes/estiloUtil';

class LoteArquivoHistorico extends Component {
    constructor(props) {
        super(props);
        this.state = { loteArquivoHistorico: [] };
    }

    componentDidMount() {
        if (this.props.loteArquivoHistorico) {
            const loteArquivoHistorico = this.props.loteArquivoHistorico;
            this.setState({ loteArquivoHistorico: loteArquivoHistorico });
        }
    }

    render() {
        const stylePanel = this.props.contextoBppPag ? 'panel-heading fundo-verde-claro' : 'panel-heading fundo-azul-claro';
        const stylePanelTable = this.props.contextoBppPag ? 'panel-heading fundo-verde-escuro' : 'panel-heading fundo-azul-escuro';
        const styleButton = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG : ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        const { loteArquivoHistorico } = this.state;
        const TRACO = '-';
        return (
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                <div className={stylePanel}>
                    <span>{LABEL_COMUNS.HISTORICO_DE_ACOES}</span>
                </div>
                <br />
                <If test={loteArquivoHistorico.length}>
                    <div style={{ height: 500, overflowY: 'scroll' }}>
                        <table className="table">
                            <thead style={{ position: 'sticky' }}>
                                <tr>
                                    <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.DATA_HORARIO}</th>
                                    <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.ACAO}</th>
                                    {/* <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.USUARIO}</th> */}
                                    <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.ARQUIVO.TITULOS.PADRAO}</th>
                                </tr>
                            </thead>
                            <tbody style={{ borderTop: 'none' }}>
                                {loteArquivoHistorico.map((historico, index) => {
                  const exibirNomeAgenteENomeArquivo = !!historico.nomeArquivo;
                //   const exibirNomeAgenteENomeAgente = !!historico.nomeAgente;
                  return (
                      <tr key={index}>
                          <td style={{ textAlign: 'center', border: 'none' }}>{formatarDataCompletaBR(historico.dataHora)}</td>
                          <td style={{ textAlign: 'center', border: 'none' }}>{historico.descricao}</td>
                          {/* <td style={{ textAlign: 'center', border: 'none' }}>{exibirNomeAgenteENomeAgente ? historico.nomeAgente : TRACO}</td> */}
                          <td style={{ textAlign: 'center', border: 'none' }}>{exibirNomeAgenteENomeArquivo ? historico.nomeArquivo : TRACO}</td>
                      </tr>
                  );
                })}
                            </tbody>
                        </table>
                    </div>
                </If>
                <If test={!loteArquivoHistorico.length}>
                    <div className="alinhamento-esquerda">
                        {LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                    </div>
                </If>
                <br />
                <div className="alinhamento-direita">
                    <Button bsStyle={styleButton} title={LABEL_COMUNS.VOLTAR} onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                </div>
            </div >
        );
    }
}

export default LoteArquivoHistorico;
