import React, { Component } from 'react';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';

import If from '../componentes-uteis/if/if';

class DadosEntregaBpp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false
        };
    }

    render() {
        return (
            <div >
                <If test={this.props.showAddress}>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading fundo-cinza-claro" style={{ textAlign: 'left' }}><b> {LABEL_COMUNS.ENDERECO_PARA_RETIRADA} </b></div>
                                <div style={{ alignItems: 'flex-start', display: 'flex', flexDirection: 'column', marginLeft: 30 }}>
                                    <div >Alphaville Industrial - Barueri/SP</div>
                                    <div >Alameda Rio Negro, Nº 585</div>
                                    <div >Bloco Padauiri - 3º andar</div>
                                    <div >CEP: 06454-000</div>
                                </div>
                            </div>
                        </div>
                    </div>

                </If>
            </div>
        );
    }
}

export default DadosEntregaBpp;
