import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos, HEADER_UPLOAD_FILE } from '../url-servicos';
import { retornaTipoDoArquivo, retornaExtensaoDoArquivo } from '../../componentes/util/arquivos/arquivos-util';

export const fetchUpload = (urlPreAssinned, file) => {
    return fetch(urlPreAssinned, {
        method: HTTP_METODOS.PUT,
        headers: { 'Content-Type': retornaTipoDoArquivo(file) },
        body: file
    }).then(resp => resp.ok ? ({ error: !resp.ok }) : Promise.reject({ error: !resp.ok }));
};

export const fetchGeraUrlPreAssinada = (arquivo) => {
    return fetch(montaUrlFetch(urlServicos.ARQUIVO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify({ 'contentType': retornaTipoDoArquivo(arquivo) })
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGeraUrlPreAssinadaS3Arquivos = (corpoDaRequisicao) => {
    return fetch(montaUrlFetch(urlServicos.ARQUIVOS), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: corpoDaRequisicao
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchUploadS3Arquivos = (urlPreAssinada, arquivo) => {
    return fetch(urlPreAssinada, {
        method: HTTP_METODOS.PUT,
        headers: HEADER_UPLOAD_FILE.headers,
        body: arquivo
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

/* export const fetchUpload = (urlPreAssinned, file) => {
    return axios.put(urlPreAssinned, file, {
        headers: { "Content-Type": retornaTipoDoArquivo(file) },
    }).then(resp => resp.ok).catch(error => error);
} */
