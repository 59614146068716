export const CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX =
    {
        CLASSE_NAME: 'NOVO-CARGA-LOTE',
        COMANDO: [
            'MUDAR_ARQUIVO_',
            'OBTER_URL_POST_',
            'ERRO_',
            'PUT_ARQUIVO_S3_',
            'POST_CONTEUDO_ARQUIVO_',
            'PROCURAR_TITULAR_',
            'LIMPAR_DADOS_TITULAR',
            'GRAVAR_ARQUIVO_',
            'GRAVAR_E_PROCESSAR_ARQUIVO_',
            'OCULTAR_ERRO_',
            'CRIAR_ERRO_ARQUIVO_NAO_SELECIONADO_',
            'OBTER_ARQUIVO_',
            'SETAR_FLAG_CARREGANDO_',
            'SETAR_FLAG_EXIBIR_MODAL_GRAVAR',
            'SETAR_FLAG_EXIBIR_MODAL_GRAVAR_SUCESSO_',
            'SETAR_FLAG_EXIBIR_MODAL_PROCESSAR',
            'SETAR_FLAG_EXIBIR_MODAL_PROCESSAR_SUCESSO_',
            'SETAR_FLAG_FINALIZADO_',
            'SETAR_ROTULO_ARQUIVO_CARGA_LOTE'

        ]
    };

export default class Controle {
    static getStatusCarregarArquivoControl() {
        return 'STATUS_CARREGAR_ARQUIVO';
    }
    static getLimparControl() {
        return 'LIMPAR';
    }
}
