export const actionTypes = {
    EXIBIR_COMPROVANTE_CONTA: 'EXIBIR_COMPROVANTE_CONTA',
    ENVIAR_EMAIL_COMPROVANTE_PAGTO_CONTA: 'ENVIAR_EMAIL_COMPROVANTE_PAGTO_CONTA',
    EMAIL_COMPROVANTE_ENVIADO_SUCESSO: 'EMAIL_COMPROVANTE_ENVIADO_SUCESSO',
    EMAIL_COMPROVANTE_ENVIADO_ERRO: 'EMAIL_COMPROVANTE_ENVIADO_ERRO',
    EMAIL_DESTINATARIO_HANDLE_CHANGE: 'EMAIL_DESTINATARIO_HANDLE_CHANGE',
    TIPO_EMAIL_DESTINATARIO: 'TIPO_EMAIL_DESTINATARIO',
    MOSTRAR_FORMULARIO_ENVIO_EMAIL: 'MOSTRAR_FORMULARIO_ENVIO_EMAIL'
};

export const constantes = {
    TIPO_COMPROVANTE_PAGAMENTO_BOLETO_BANCARIO: 'pagamentoBoletoBancario',
    TIPO_COMPROVANTE_TRANSF_ENTRE_CONTAS_DIGITAIS: 'transferenciaBancaria',
    TIPO_COMPROVANTE_TRANSF_ENTRE_CONTAS_TITULARIDADE_DIFERENTES: 'transferenciaEntreContasMovimentoDiferentesTitularidades',
    PDF_TITULO_COMPROVANTE: 'PDF_TITULO_COMPROVANTE',
    PDF_SUBTITULO_COMPROVANTE: 'PDF_SUBTITULO_COMPROVANTE',
    TIPO_EMAIL_COMPROVANTE_PESSOAL_CADASTRADO: 'TIPO_EMAIL_COMPROVANTE_PESSOAL_CADASTRADO',
    TIPO_EMAIL_COMPROVANTE_NOVO_ENDERECO: 'TIPO_EMAIL_COMPROVANTE_NOVO_ENDERECO'
};

// TIPO_COMPROVANTE_PAGAMENTO_CONTA = 'pagamentoBoletoBancario'
// TIPO_COMPROVANTE_TRANSF_ENTRE_CONTAS_DIGITAIS = 'transferenciaBancaria'
// TIPO_COMPROVANTE_CARREGAMENTO_CARTAO = 'CargaCartao'
// TIPO_COMPROVANTE_TARIFA_CARREGAMENTO_CARTAO = 'TarifaCargaCartao'

// Tipos operações que tem comprovante no extrato

// - pagamentoBoletoBancario
// - tarifaPagamentoBoletoBancario

// - transferenciaEntreContasMovimentoDiferentesTitularidades
// - tarifaTransferenciaEntreContasMovimentoDiferentesTitularidades

// - tarifaTransferenciaBancaria
// - transferenciaBancaria

// DEVERÁ MOSTRAR COMPROVANTES PARA:
// cargaCartao
// transferenciaEntreContasMovimento
// transferenciaEntreContasMovimentoDiferentesTitularidades
// transferenciaEntreContasMovimentoDiferentesTitularidadesEmLote
// transferenciaBancaria
// pagamentoBoletoBancario
// cargaContaMovimentoBoleto
// compraPOS
// saqueATM
