import { formatarDataUS } from '../../util/data/dataUtil';
import { DESCRICAO_CONTA } from '../../util/contantes-comuns/constantes-comuns';
import { removeMascaraTelefoneCpfCnpj } from '../../util/formata-string';

export function jsonPagamentos(dadosForm) {
    let dadosNormalizados = {};
    try {
        dadosNormalizados = {
            operacao: 'pagamentoBoletoBancario',
            valor: dadosForm.valor,
            contaOrigemId: dadosForm.contaSelecionada.id,
            // canalToken: "SMS",
            dadosIntegracao: {
                codigoBarras: dadosForm.codigoBarras,
                dataVencimento: formatarDataUS(dadosForm.dataVencimento),
                tipoDocumentoBeneficiario: dadosForm.tipoDocumentoBeneficiario,
                numeroDocumentoBeneficiario: removeMascaraTelefoneCpfCnpj(dadosForm.numeroDocumentoBeneficiario),
                nomeBeneficiario: dadosForm.nomeBeneficiario
            }
        };
        if (dadosForm.descricao !== '') dadosNormalizados['tagCliente'] = dadosForm.descricao;
        dadosNormalizados = JSON.stringify(dadosNormalizados);
    } catch (err) {

    }
    return dadosNormalizados;
}

export function jsonAprovacaoTransacao(token, agendamento) {
    let dadosTransacao = {};
    try {
        dadosTransacao = {
            transacao: agendamento.tipoTransacao,
            token
        };
        if (agendamento.dataAgendamento) dadosTransacao['dataAgendamento'] = formatarDataUS(agendamento.dataAgendamento);
        dadosTransacao = JSON.stringify(dadosTransacao);
    } catch (err) {

    }
    return dadosTransacao;
}
