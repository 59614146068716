import { CAMPOS_ERROS } from './contestacao-constantes';

export function preencheCamposDeErros(erros) {
    let camposDeErros = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case CAMPOS_ERROS.MOTIVO.campo:
                camposDeErros.motivoErro = erros[i].mensagem;
                break;
            case CAMPOS_ERROS.DESCRICAO.campo:
                camposDeErros.descricaoErro = erros[i].mensagem;
                break;
            default:
                camposDeErros.motivoErro = erros[i].mensagem;
                break;
        }
    }
    return camposDeErros;
}
