import React, { Component } from 'react';
import { LABEL_COMUNS, COMBO } from '../util/contantes-comuns/constantes-comuns';
import { FormControl, FormGroup, div } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import If from '../componentes-uteis/if/if';
import LoadingFenix from '../loading-fenix/loading-fenix';
import * as localidadeAction from '../../action/localidade/localidade-action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getLocalidadePorCep } from '../../servicos/localidade/localidade-service';

class DadosEntregaCartao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false,
            cep: '',
            paisSelecionadoId: 'c0387529-a4e4-410d-8792-a938728e81e9',
            listaPaises: [],
            siglaDoEstadoSelecionado: '',
            listaEstados: [],
            nomeDaCidadeSelecionada: '',
            listaCidades: [],
            bairro: '',
            logradouro: '',
            numero: '',
            complemento: '',
            pontoReferencia: ''
        };
    }

    async componentDidMount() {
        await this.props.getPaises();
        this.carregaPaises();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            cepErro: nextProps.cepErro, paisErro: nextProps.paisErro,
            estadoErro: nextProps.estadoErro, logradouroErro: nextProps.logradouroErro,
            numeroErro: nextProps.numeroErro, cidadeErro: nextProps.cidadeErro, bairroErro: nextProps.bairroErro
        });
    }

    async carregaPaises() {
        this.setState({ listaPaises: COMBO.LOCALIDADE.GET_PAIS_BRASIL(this.props.pedido.localidade.paises) });
        await this.props.getEstados(this.state.paisSelecionadoId);
        this.setState({ listaEstados: COMBO.LOCALIDADE.GET_ESTADOS(this.props.pedido.localidade.estados) });
    }

    changeCep(cep) {
        this.setState({ cep: cep });
    }

    preencheCamposLocalidade(cep) {
        if (cep.length === 9) {
            this.setState({ isLoading: true });
            getLocalidadePorCep(cep).then(resultado => {
                if (!resultado.error) {
                    let result = resultado.data;
                    this.preencheCamposComData(result);
                } else {
                    this.setState({ isLoading: false });
                }
            });
        }
    }

    async preencheCamposComData(result) {
        await this.props.getEstados(result.paisId);
        this.setState({ listaEstados: COMBO.LOCALIDADE.GET_ESTADOS(this.props.pedido.localidade.estados) });
        await this.props.getCidades(result.paisId, result.estadoId);
        this.setState({ listaCidades: COMBO.LOCALIDADE.GET_CIDADES(this.props.pedido.localidade.cidades), bairro: result.bairro, nomeDaCidadeSelecionada: result.cidade, siglaDoEstadoSelecionado: result.uf, logradouro: result.logradouro, paisSelecionadoId: result.paisId, isLoading: false });
    }

    async setPais(pais) {
        this.setState({ paisSelecionadoId: pais.value });
        await this.props.getEstados(pais.value);
        this.setState({ listaEstados: COMBO.LOCALIDADE.GET_ESTADOS(this.props.pedido.localidade.estados) });
    }

    async setEstado(estado) {
        if (estado) {
            this.setState({ isLoading: true });
            await this.props.getCidades(this.state.paisSelecionadoId, estado.estado.id);
            this.setState({ isLoading: false, listaCidades: COMBO.LOCALIDADE.GET_CIDADES(this.props.pedido.localidade.cidades), siglaDoEstadoSelecionado: estado.value });
        } else {
            this.setState({ siglaDoEstadoSelecionado: '' });
        }
    }

    async setCidade(cidade) {
        if (cidade) {
            this.setState({ nomeDaCidadeSelecionada: cidade.value });
        } else {
            this.setState({ nomeDaCidadeSelecionada: '' });
        }
    }

    setBairro(bairro) {
        this.setState({ bairro: bairro });
        this.props.updateBtn();
    }

    setLogradouro(logradouro) {
        this.setState({ logradouro: logradouro });
        this.props.updateBtn();
    }

    setNumero(numero) {
        this.setState({ numero: numero });
        this.props.updateBtn();
    }

    setComplemento(complemento) {
        this.setState({ complemento: complemento });
    }

    setPontoReferencia(referencia) {
        this.setState({ pontoReferencia: referencia });
    }

    render() {
        return (
            <div>
                <If test={this.props.showAddress}>
                    <div className="row">
                        <If test={this.state.isLoading}>
                            <div className="alinhamento-centro"><LoadingFenix /></div>
                        </If>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="panel panel-default">
                                <div className="panel-heading fundo-cinza-claro" style={{ textAlign: 'left' }}><b> {LABEL_COMUNS.ENDERECO_PARA_ENTREGA} </b></div>
                                <div className="panel-body espacamento_div_endereco">
                                    <div className="list-group">
                                        <form className="editForm" id="myForm">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 espacamento">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.CEP}*</div>
                                                            <FormGroup >
                                                                <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    className="form-control mensagens-erro" mask="99999-999" maskChar=" "
                                                                    placeholder={LABEL_COMUNS.CEP} id="cepPessoa"
                                                                    value={this.state.cep}
                                                                    onChange={(e) => this.changeCep(e.target.value)}
                                                                    onBlur={(e) => this.preencheCamposLocalidade(e.target.value)}
                                                                />
                                                                {this.state.cepErro ? <span className="mensagens-erro-span" role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 espacamento">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.PAIS}*</div>
                                                            <FormGroup>
                                                                <Select id="pais"
                                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                                    value={this.state.paisSelecionadoId}
                                                                    onChange={(e) => { this.setPais(e); }}
                                                                    options={this.state.listaPaises}
                                                                    noResultsText={LABEL_COMUNS.NENHUM_PAIS} />
                                                                {this.state.paisErro ? <span className="mensagens-erro-span" role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.ESTADO}*</div>
                                                            <FormGroup>
                                                                <Select id="estado"
                                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                                    value={this.state.siglaDoEstadoSelecionado}
                                                                    onChange={(e) => { this.setEstado(e); }}
                                                                    options={this.state.listaEstados}
                                                                    disabled={!this.state.paisSelecionadoId}
                                                                    noResultsText={LABEL_COMUNS.NENHUM_ESTADO} />
                                                                {this.state.estadoErro ? <span className="mensagens-erro-span" role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.CIDADE}*</div>
                                                            <FormGroup >
                                                                <Select id="cidade"
                                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                                    value={this.state.nomeDaCidadeSelecionada}
                                                                    onChange={(e) => { this.setCidade(e); }}
                                                                    options={this.state.listaCidades}
                                                                    disabled={!this.state.paisSelecionadoId || !this.state.siglaDoEstadoSelecionado}
                                                                    noResultsText={LABEL_COMUNS.NENHUMA_CIDADE} />
                                                                {this.state.cidadeErro ? <span className="mensagens-erro-span" role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.BAIRRO}*</div>
                                                            <FormGroup >
                                                                <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    className="mensagens-erro" maxLength="50" placeholder="Bairro"
                                                                    value={this.state.bairro}
                                                                    onChange={(e) => { this.setBairro(e.target.value); }} />
                                                                {this.state.bairroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.LOGRADOURO}*</div>
                                                            <FormGroup>
                                                                <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    className="mensagens-erro" maxLength="150" placeholder="Logradouro"
                                                                    value={this.state.logradouro}
                                                                    onChange={(e) => { this.setLogradouro(e.target.value); }} />
                                                                {this.state.logradouroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.NUMERO}*</div>
                                                            <FormGroup >
                                                                <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    className="mensagens-erro" maxLength="5" placeholder="Número"
                                                                    value={this.state.numero}
                                                                    onChange={(e) => { this.setNumero(e.target.value); }} />
                                                                {this.state.numeroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">Campo obrigatório</span> : null}
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_complemento">
                                                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.COMPLEMENTO}</div>
                                                            <FormGroup>
                                                                <FormGroup>
                                                                    <FormControl style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                        maxLength="50" placeholder="Complemento"
                                                                        value={this.state.complemento}
                                                                        onChange={(e) => { this.setComplemento(e.target.value); }} />
                                                                </FormGroup>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                            <div style={{ textAlign: 'left', marginLeft: 6, fontWeight: 900, fontSize: 13, marginBottom: 10 }}>{LABEL_COMUNS.PONTO_REFERENCIA}</div>
                                                            <FormGroup>
                                                                <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    className="mensagens-erro" maxLength="50" placeholder="Ponto de Referência"
                                                                    value={this.state.pontoReferencia}
                                                                    onChange={(e) => { this.setPontoReferencia(e.target.value); }} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                    </div>
                </If>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pedido: {
        localidade: state.LocalidadeReducer
    }
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...localidadeAction }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(DadosEntregaCartao);
