
import { COMBO, TIPO_DE_MOVIMENTACAO } from '../../util/contantes-comuns/constantes-comuns';

export function montaComboDeClassificacoes(classificacoes) {
    let classificacoesParaRetornar = [];
    classificacoes.map((classificacao, idx) => {
        return classificacoesParaRetornar.push({ value: classificacao.id, label: classificacao.nome });
    });
    return classificacoesParaRetornar;
}

export function montaComboDeProjetos(projetos) {
    let projetosParaRetornar = [];
    projetos.map((projeto, idx) => {
        return projetosParaRetornar.push({ value: projeto.id, label: projeto.nome });
    });
    return projetosParaRetornar;
}

export function montaPrestacoes(listaDePrestacoesDeContas) {
    let prestacoesDeContas = [];
    if (listaDePrestacoesDeContas && listaDePrestacoesDeContas.length > 0) {
        listaDePrestacoesDeContas.map((prestacao) => {
            if (prestacao.dadosExtrato.valor < 0) {
                prestacao.tipo = TIPO_DE_MOVIMENTACAO.DEBITO;
            } else {
                prestacao.tipo = TIPO_DE_MOVIMENTACAO.CREDITO;
            }
            if (!prestacao.descricaoAprovacao) {
                prestacao.descricaoAprovacao = '';
            }
            const prestacaoDeContasCombo = COMBO.STATUS.PRESTACAO_DE_CONTAS.find(statusPrestacao => (statusPrestacao.value === prestacao.status));
      
      
            prestacao.statusExibicao = (prestacaoDeContasCombo || {label: prestacao.status}).label;
            prestacoesDeContas.push(prestacao);
        });
    }
    return prestacoesDeContas;
}
