


import { HEADER_WITHOUT_AUTORIZATION, HTTP_METODOS, 
    montaUrlFetchSemParametro, montaUrlMFAFetchSemParametro, 
    urlServicos, HEADER_API, HEADER_API_MFA } from '../url-servicos';

export const fetchLogin = (dadosLogin) => {
    return fetch(montaUrlFetchSemParametro(urlServicos.TOKEN), {
        method: HTTP_METODOS.POST, 
        headers: HEADER_WITHOUT_AUTORIZATION.headers,
        body: dadosLogin
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchLoginMfa = (dadosLogin) => {
    return fetch(montaUrlMFAFetchSemParametro(urlServicos.TOKEN), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_MFA().headers,
        body: dadosLogin
    })
        .then(resp => resp.ok ? resp.json() : Promise.reject({ error: !resp.ok, data: resp.json() }));
};

export const fetchCriacaoMfa = (dadosMfa) => {
    return fetch(montaUrlMFAFetchSemParametro(urlServicos.CRIACAO_MFA), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_MFA().headers,
        body: dadosMfa
    })
        .then(resp => resp.ok ? resp.json() : Promise.reject({ error: !resp.ok, data: resp.json() }));
};

export const fetchValidarMfaCode = (dadosMfa) => {
    return fetch(montaUrlMFAFetchSemParametro(urlServicos.VALIDACAO_MFA), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_MFA().headers,
        body: dadosMfa
    })
        .then(resp => resp.ok ? resp.json() : Promise.reject({ error: !resp.ok, data: resp.json() }));
};