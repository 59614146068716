import { Button, Tooltip, OverlayTrigger } from 'react-bootstrap';
import converter from 'json-2-csv';
import React from 'react';

const NOME_DO_ARQUIVO_DOCUMENTO = 'lista-cartoes.csv';

const converterJsonParaCsv = (dados) => {
    const opcoes = {
        delimiter: {
            field: ';'
        },
        prependHeader: true,
        sortHeader: false,
        excelBOM: true,
        trimHeaderValues: true,
        trimFieldValues: false,
        keys: Object.keys(dados[0])
    };

    return converter.json2csvAsync(dados, opcoes);
};

const ExportarCSV = ({ listaParaSerconvertidaEmCSV, nomeDoArquivoCsv }) => {
    const TOOLTIP = (
        <Tooltip id="tooltip">
            Exportar para .CSV
        </Tooltip>
    );

    const downloadCSV = async (dados) => {
        if (dados.length) {
            const csv = await converterJsonParaCsv(dados);

            if (csv) {
                const filename = nomeDoArquivoCsv || NOME_DO_ARQUIVO_DOCUMENTO;
                const link = document.createElement('a');
                const universalBOM = '\uFEFF';
                link.setAttribute('href', encodeURI(`data:text/csv;charset=utf-8,${universalBOM} ${csv}`));
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };

    return (
        <OverlayTrigger placement="top" overlay={TOOLTIP}>
            <Button className="btn btn-success btn-xs" placeholder="Teste"
        onClick={() => downloadCSV(listaParaSerconvertidaEmCSV)}>
                <i className="fa fa-file-excel-o" aria-hidden="true" />
            </Button>
        </OverlayTrigger>
    );
};

export default ExportarCSV;
