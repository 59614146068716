import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import * as actions from './prestacao-de-contas-dashboard-bpp-corp-action';
import { Button, Glyphicon, Dropdown } from 'react-bootstrap';
import { COMBO, LABEL_COMUNS } from '../../../../util/contantes-comuns/constantes-comuns';
import Grid from '../../../../componentes-uteis/grid/grid';
import { formatarDataBR, formatarDataUS } from '../../../../util/data/dataUtil';
import { formatarCampoValor } from '../../../../util/extrato/extrato-util';
import FiltroGenerico, { procurarErroPorCampo } from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import If from '../../../../componentes-uteis/if/if';
import { stringParaNumero } from '../../../../util/formata-string';
import Loading from '../../../../loading-fenix/loading';



class PrestacaoDeContasDashboardBppCorp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abrirCollapse: false
        };
    }

    componentDidMount() {
        let contaSelecionada = this.props.conta;
        this.props.limparPrestacoesDeContas();
        this.props.limparFiltroPrestacaoDeContas();
        let filtro = {
            contaPaiId: contaSelecionada.id
        };
        this.props.setFiltro(filtro);
        this.props.setContaSelecionada(contaSelecionada);
        filtro.produtoId = this.props.produtosIdsBppCorp;
        this.props.getPrestacoesDeConta(contaSelecionada, filtro);
        this.props.getPrestacaoDeContasClassificacoes(contaSelecionada.pessoaTitularId);
        this.props.getPrestacaoDeContasProjetos(contaSelecionada.pessoaTitularId);
    }

    identificarTipoPrestacaoConta = (obj) => {
        let tipo = '';
        if (obj.dadosExtrato && obj.dadosExtrato.tipoMovimentacao) {
            tipo = obj.dadosExtrato.tipoMovimentacao;
        } else {
            tipo = obj.tipo;
        }
        return formatarCampoValor(tipo, obj.dadosExtrato.valor, obj.dadosExtrato.moeda);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="panel panel-default">
                        <div className="panel-heading fundo-azul-claro" style={{ height: '40px' }}>
                            <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                                {LABEL_COMUNS.PRESTACAO_DE_CONTAS}
                            </div>
                            <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                                <Button className="estilo-filtro" onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                    <span className="glyphicon glyphicon-filter" />
                                </Button>
                                &nbsp;&nbsp;
                                <Dropdown id="menuOpcoes" pullRight >
                                    <Dropdown.Toggle noCaret className="fundo-azul-claro dropdown-sem-bordas">
                                        <Glyphicon glyph="option-horizontal" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <li>
                                            <Link className="link_expandir" to={ROTAS_CAMINHOS.GERENCIAR_PRESTACOES_DE_CONTAS}>
                                                {LABEL_COMUNS.EXPANDIR}
                                            </Link>
                                        </li>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <FiltroGenerico
                            abrirCollapse={this.state.abrirCollapse}
                            mostrarFiltroDeDatas={true} mostrarFiltroDeStatus={true}

                            labelDataInicial={LABEL_COMUNS.DATA_INICIO}
                            dataInicial={this.props.prestacaoDeContasDashboard.dataInicial}
                            setDataInicial={this.props.setFiltroPrestacaoDataInicial}
                            dataInicialErro={procurarErroPorCampo(this.props.prestacaoDeContasDashboard.errosNaBuscaDasPrestacoesDeContas, 'dataInicial')}

                            labelDataFinal={LABEL_COMUNS.DATA_FIM}
                            dataFinal={this.props.prestacaoDeContasDashboard.dataFinal}
                            setDataFinal={this.props.setFiltroPrestacaoDataFinal}
                            dataFinalErro={procurarErroPorCampo(this.props.prestacaoDeContasDashboard.errosNaBuscaDasPrestacoesDeContas, 'dataFinal')}

                            listaDeClassificacoes={this.props.prestacaoDeContasDashboard.listaDeClassificacoes}
                            setClassificacaoSelecionada={this.props.setClassificacaoSelecionada}
                            classificacaoSelecionada={this.props.prestacaoDeContasDashboard.classificacaoSelecionada}
                            listaDeProjetos={this.props.prestacaoDeContasDashboard.listaDeProjetos}
                            setProjetoSelecionado={this.props.setProjetoSelecionado}
                            projetoSelecionado={this.props.prestacaoDeContasDashboard.projetoSelecionado}
                            listaDeStatus={COMBO.STATUS.PRESTACAO_DE_CONTAS}
                            setStatus={this.props.setPrestacaoDeContasStatus}
                            statusSelecionado={this.props.prestacaoDeContasDashboard.statusSelecionado}
                            mostrarFiltroDePrestacaoDeContas={true}
                            mostrarFiltroDeValor={true}
                            valorDe={this.props.prestacaoDeContasDashboard.valorDe}
                            valorDeError={procurarErroPorCampo(this.props.prestacaoDeContasDashboard.errosNaBuscaDasPrestacoesDeContas, 'valorDe')}
                            setValorDe={(e) => this.props.setFiltroPrestacaoDeConstasPorValor({ data: { valorDe: e }, erro: { valorDeErro: '' } })}
                            valorAte={this.props.prestacaoDeContasDashboard.valorAte}
                            valorAteError={procurarErroPorCampo(this.props.prestacaoDeContasDashboard.errosNaBuscaDasPrestacoesDeContas, 'valorAte')}
                            setValorAte={(e) => this.props.setFiltroPrestacaoDeConstasPorValor({ data: { valorAte: e }, erro: { valorAteErro: '' } })}
                            exibirBotaoLimpar={true}
                            limparFiltro={this.props.limparFiltroPrestacaoDeContas}
                            buscar={() => {
                                this.props.getPrestacoesDeConta(this.props.prestacaoDeContasDashboard.contaSelecionada, this.montaFiltroPrestacaoDeContas(this.props.prestacaoDeContasDashboard));
                            }} />
                        <div className="panel-body" style={{ height: '170px' }}>
                            {
                                this.props.prestacaoDeContasDashboard.exibirLoadingDashboardPrestacaoDeContas ?
                                    <div className="alinhamento-centro">
                                        <Loading />
                                    </div>
                                    :
                                    this.props.prestacaoDeContasDashboard.exibirLoadingDashboardPrestacaoDeContas !== undefined &&
                                    <If test={this.props.prestacaoDeContasDashboard.listaDePrestacoesDeContas.length > 0}>
                                        <div className="row">
                                            <Grid cols="12 12 12 12" className="grids-dashboards">
                                                <Grid cols="2 2 2 2">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DATA}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2" >
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.PORTADOR}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2" >
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CLASSIFICACAO}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CLIENTE_PROJETO}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.VALOR}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.STATUS}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="grid-prestacao grids-dashboards">
                                            {
                                                this.props.prestacaoDeContasDashboard.listaDePrestacoesDeContas.map((linhaPrestacaoDeContas, idx) => {
                                                    return (
                                                        <div className="row" key={idx}>
                                                            <Grid cols="12 12 12 12">
                                                                <Grid cols="2 2 2 2">
                                                                    <span style={{ fontSize: '11px' }}>
                                                                        {formatarDataBR(linhaPrestacaoDeContas.dataCriacao)}
                                                                    </span>
                                                                </Grid>
                                                                <Grid cols="2 2 2 2" >
                                                                    <span style={{ fontSize: '11px' }}>
                                                                        {linhaPrestacaoDeContas.nomePortador}
                                                                    </span>
                                                                </Grid>
                                                                <Grid cols="2 2 2 2" >
                                                                    <span style={{ fontSize: '11px' }}>
                                                                        {linhaPrestacaoDeContas.classificacao}
                                                                    </span>
                                                                </Grid>
                                                                <Grid cols="2 2 2 2" >
                                                                    <span style={{ fontSize: '11px' }}>
                                                                        {linhaPrestacaoDeContas.projeto}
                                                                    </span>
                                                                </Grid>
                                                                <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                                    <span className="posicao-grid-cartao">
                                                                        {
                                                                            //formatarCampoValor(linhaPrestacaoDeContas.tipo, linhaPrestacaoDeContas.dadosExtrato.valor, linhaPrestacaoDeContas.dadosExtrato.moeda)
                                                                            this.identificarTipoPrestacaoConta(linhaPrestacaoDeContas)
                                                                        }
                                                                        {/*{ formataMoeda(linhaPrestacaoDeContas.dadosExtrato.valor) }*/}
                                                                    </span>
                                                                </Grid>
                                                                <Grid cols="2 2 2 2" className="posicao-grid-status">
                                                                    <span>
                                                                        {linhaPrestacaoDeContas.statusExibicao}
                                                                    </span>
                                                                </Grid>
                                                            </Grid>
                                                        </div>
                                                    );
                                                }
                                                )
                                            }
                                        </div>
                                    </If>
                            }
                            <If test={this.props.prestacaoDeContasDashboard.listaDePrestacoesDeContas.length <= 0 && !this.props.prestacaoDeContasDashboard.exibirLoadingDashboardPrestacaoDeContas}>
                                <span>{LABEL_COMUNS.NENHUMA_PRESTACAO_ENCONTRADA}</span>
                            </If>
                        </div>
                    </div>
                </div>
            </div >
        );
    }

    montaFiltroPrestacaoDeContas(dadosForm) {
        let filtroDePrestacaoDeContas = {};
        filtroDePrestacaoDeContas.contaPaiId = dadosForm.filtro.contaPaiId;
        if (dadosForm.filtro.produtoId) {
            filtroDePrestacaoDeContas.produtoId = dadosForm.filtro.produtoId;
        }
        if (dadosForm.dataInicial) {
            filtroDePrestacaoDeContas.dataInicial = formatarDataUS(dadosForm.dataInicial);
        }
        if (dadosForm.dataFinal) {
            filtroDePrestacaoDeContas.dataFinal = formatarDataUS(dadosForm.dataFinal);
        }
        if (dadosForm.classificacaoSelecionada) {
            filtroDePrestacaoDeContas.classificacao = dadosForm.classificacaoSelecionada.label;
        }
        if (dadosForm.projetoSelecionado) {
            filtroDePrestacaoDeContas.projeto = dadosForm.projetoSelecionado.label;
        }
        if (dadosForm.statusSelecionado) {
            filtroDePrestacaoDeContas.status = dadosForm.statusSelecionado.value;
        }
        if (dadosForm.valorDe) {
            filtroDePrestacaoDeContas.valorDe = stringParaNumero(dadosForm.valorDe);
        }
        if (dadosForm.valorAte) {
            filtroDePrestacaoDeContas.valorAte = stringParaNumero(dadosForm.valorAte);
        }

        return filtroDePrestacaoDeContas;
    }

}

const mapStateToProps = (state) => ({
    prestacaoDeContasDashboard: state.PrestacaoDeContasDashboardBppCorpReducer
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrestacaoDeContasDashboardBppCorp);
