import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import PanelAcoes from '../../componentes-uteis/panel-acoes/panel-acoes';
import AgendamentoEAprovacoesDashboardConta from './agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-dashboard-conta';
import MostrarSaldoESaldoBloqueado from '../saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import { reactLocalStorage } from 'reactjs-localstorage';
import ExtratoDashboardConta from './extrato-dashboard-conta/extrato-dashboard-conta';
import { LABEL_COMUNS, NAVEGADOR_NIVEL, MENSAGENS, CONTA_SELECIONADA, TIPO_DE_ACESSO } from '../../util/contantes-comuns/constantes-comuns';
import If from '../../componentes-uteis/if/if';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import ImgAvatar from '../../../imagens/cartao-novo.png';
import ImgAvatarBppPag from '../../../imagens/cartao-novo-bpppag.png';
import ImgAvatarBppConsumer from '../../../imagens/cartao-novo-bppconsumer.png';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import { podeConsultar, podeCriar } from '../../../reducer/outorga/outorga-operacao-reducer';


class DashboardConta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(), exibirModal: false, exibirModalConfirmaPrestacaoConta: false,
            mensagemAcessoSolucao: '', tituloModal: '', redirecionarParaTransferencia: false,
            redirecionarParaDashboardSolucoes: false, redirecionarParaPedidoCarga: false, redirecionarParaDashboardBppPag: false,
            prestacaoDeContasRecuperada: {}, tipoDeAcesso: TIPO_DE_ACESSO.SELECIONADO(),
        };
        this.liberaAcessoSolucaoBppCorp = this.liberaAcessoSolucaoBppCorp.bind(this);
        this.liberaAcessoSolucaoBppPag = this.liberaAcessoSolucaoBppPag.bind(this);
    }

    componentWillMount() {
        localStorage.removeItem(LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA);
        localStorage.removeItem(NAVEGADOR_NIVEL.VALUE);
    }

    liberaAcessoSolucaoBppConsumer = () => {
        this.props.history.replace(ROTAS_CAMINHOS.DASHBOARD_BPP_CONSUMER);
    }


    liberaAcessoSolucaoBppCorp() {
        if (this.props.temPermissaoBppCorp) {
            this.setState({ redirecionarParaDashboardSolucoes: true });
        }
        else {
            this.setState({ exibirModal: true, mensagemAcessoSolucao: MENSAGENS.ACESSO_SOLUCAO.BPP_CORP(), tituloModal: LABEL_COMUNS.SOLUCAO.BPP_CORP });
        }
    }

    liberaAcessoSolucaoBppPag(value) {
        if (this.props.temPermissaoBppPag) {
            reactLocalStorage.set(LABEL_COMUNS.PRODUTOS_IDS_BPP_PAG, this.props.produtosIdsBppPag);
            this.setState({ redirecionarParaDashboardBppPag: value });
        }
        else {
            this.setState({ exibirModal: true, mensagemAcessoSolucao: MENSAGENS.ACESSO_SOLUCAO.BPP_PAG(), tituloModal: LABEL_COMUNS.SOLUCAO.BPP_PAG });
        }
    }

    corpoModalSolucao = () => {
        return <div dangerouslySetInnerHTML={{ __html: this.state.mensagemAcessoSolucao }
        } />;
    }

    rodapeModalSolucao = () => {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="primary" onClick={(exibirModal) => this.setState({ exibirModal: false })}>{LABEL_COMUNS.OK}</Button>
                </div>
            </div>
        );
    }


    rodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setState(state => ({ exibirModalConfirmaPrestacaoConta: !state.exibirModalConfirmaPrestacaoConta }))}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    const { contaSelecionada, linhaDoExtratoSelecionada } = this.props.extrato;
                    const { prestacaoDeContasRecuperada } = this.state;
                    const state = { contaSelecionada, linhaDoExtratoSelecionada, prestacaoDeContasRecuperada };
                    this.props.history.push({ pathname: ROTAS_CAMINHOS.PRESTAR_CONTAS, state: state });
                }}
            />
        );
    }
    setPrestacaoDeContasRecuperada = (prestacaoDeContasRecuperada) => {
        this.setState({ prestacaoDeContasRecuperada });
    }


    render() {
        const className = 'panel-heading fundo-azul-claro';
        const classNameFiltro = 'estilo-filtro';

        const { prestacaoDeContasRecuperada, tipoDeAcesso } = this.state;
        const mensagemConfirmacao = prestacaoDeContasRecuperada && <div dangerouslySetInnerHTML={{ __html: MENSAGENS.PESSOA.PRESTACAO_DE_CONTAS.CONFIRMACAO_COM_VALIDACAO(prestacaoDeContasRecuperada.status) }} />;


        if (this.state.redirecionarParaDashboardSolucoes) {
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_SOLUCOES} />;
        }

        if (this.state.redirecionarParaDashboardBppPag) {
            return <Redirect to={{ pathname: ROTAS_CAMINHOS.DASHBOARD_BPP_PAG, state: { contextoBppPag: true } }} />;
        }

        const { podeConsultar, podeCriar } = this.props.outorgaPerfils;

        const panelAcoesPropsPodeCriar = podeCriar ? {
            className,
            titulo: LABEL_COMUNS.ACOES_DA_CONTA_DIGITAL,
            labelBotao1: LABEL_COMUNS.DEPOSITAR,
            btn1Link: ROTAS_CAMINHOS.PEDIDO_DE_CARGA,
            labelBotao2: LABEL_COMUNS.TRANSFERIR,
            btn2Link: ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_CONTA_MOVIMENTO,
            labelBotao3: LABEL_COMUNS.PAGAR_CONTA,
            btn3Link: ROTAS_CAMINHOS.PAGAMENTO_DE_CONTAS,
            labelBotao4: LABEL_COMUNS.COMPROVANTES,
            btn4Link: ROTAS_CAMINHOS.COMPROVANTES,
        } : {
            className,
            titulo: LABEL_COMUNS.ACOES_DA_CONTA_DIGITAL,
        };

        const panelAcoesPropsPodeConsultar = podeConsultar ? {
            className,
            labelBotao1: LABEL_COMUNS.DEPOSITAR,
            btn1Link: ROTAS_CAMINHOS.PEDIDO_DE_CARGA,
            // labelBotao2: LABEL_COMUNS.TRANSFERIR,
            // btn2Link: ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_CONTA_MOVIMENTO,
            labelBotao3: LABEL_COMUNS.COMPROVANTES,
            btn3Link: ROTAS_CAMINHOS.COMPROVANTES,
            titulo: LABEL_COMUNS.ACOES_DA_CONTA_DIGITAL,
            //labelBotao4:  LABEL_COMUNS.PAGAR_CONTA, 
            //btn4Link:  ROTAS_CAMINHOS.PAGAMENTO_DE_CONTAS,
        } : {
            className,
            titulo: LABEL_COMUNS.ACOES_DA_CONTA_DIGITAL,
        };

        const panelAcoesProps = { ...panelAcoesPropsPodeConsultar, ...panelAcoesPropsPodeCriar };

        return (
            <React.Fragment>
                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmaPrestacaoConta}
                    name="exibirModalConfirmaPrestacaoConta"
                    header={LABEL_COMUNS.PRESTACAO_DE_CONTAS}
                    body={mensagemConfirmacao}
                    footer={this.rodapeModalConfirmacao()} />

                <If test={this.state.exibirLoadingPrestacao}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>
                <div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                        <div className="row">
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <MostrarSaldoESaldoBloqueado
                                            className={className}
                                            contaSelecionada={this.state.contaSelecionada}
                                            exibirSaldo={true}
                                            mostrarSaldoBloqueado={true}
                                            podeConsultar={podeConsultar}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <If test={podeConsultar}>
                                            <AgendamentoEAprovacoesDashboardConta
                                                {...this.props}
                                                solucaoBppPag={this.props.solucaoBppPag}
                                                produtosIdsBppCorp={this.props.produtosIdsBppCorp}
                                                produtosIdsBppPag={this.props.produtosIdsBppPag}
                                                className={className}
                                                classNameBotao={classNameFiltro}
                                                contaSelecionada={this.state.contaSelecionada}
                                            />
                                        </If>
                                    </div>
                                </div>

                                <div className="row">
                                    <ModalComponent name="modalSolucao" exibirModal={this.state.exibirModal}
                                        header={this.state.tituloModal} body={this.corpoModalSolucao()} footer={this.rodapeModalSolucao()} />
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="panel panel-default">
                                            <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.PRODUTOS}</div>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <If test={tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA}>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 avatar-solucoes">
                                                                <img src={ImgAvatar} className="avatar-bppcorp" alt="imagem conta" />
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 link-solucoes">
                                                                <a onClick={this.liberaAcessoSolucaoBppCorp} className="bpp-corp">{LABEL_COMUNS.SOLUCAO.BPP_CORP}</a>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 avatar-solucoes">
                                                                <img src={ImgAvatarBppPag} className="avatar-bppcorp" alt="imagem conta" />
                                                            </div>
                                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 link-solucoes">
                                                                <a onClick={this.liberaAcessoSolucaoBppPag} className="bpp-pag">{LABEL_COMUNS.SOLUCAO.BPP_PAG}</a>
                                                            </div>
                                                        </If>
                                                        <If test={tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_FISICA}>
                                                            <div
                                                                className="col-xs-2 col-sm-2 col-md-2 col-lg-2 avatar-solucoes">
                                                                <img src={ImgAvatarBppConsumer} className="avatar-bppcorp" alt="imagem conta" />
                                                            </div>
                                                            <div
                                                                className="col-xs-4 col-sm-4 col-md-4 col-lg-4 link-solucoes">
                                                                <a onClick={this.liberaAcessoSolucaoBppConsumer} className="bpp-corp">{LABEL_COMUNS.SOLUCAO.MEU_BPP}</a>
                                                            </div>
                                                        </If>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <PanelAcoes {...panelAcoesProps} />
                                    </div>
                                </div>
                                <If test={podeConsultar}>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <ExtratoDashboardConta
                                                setPrestacaoDeContasRecuperada={this.setPrestacaoDeContasRecuperada}
                                                exibirLoadingPrestacao={() => this.setState(state => ({ exibirLoadingPrestacao: !state.exibirLoadingPrestacao }))}
                                                exibirModalConfirmaPrestacaoConta={() => this.setState(state => ({ exibirModalConfirmaPrestacaoConta: !state.exibirModalConfirmaPrestacaoConta }))}
                                                contaSelecionada={this.state.contaSelecionada}
                                                history={this.props.history} />
                                        </div>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                </div >
            </React.Fragment >
        );
    }
}


const mapStateToProps = state => ({
    extrato: state.ExtratoReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
    }
});

export default connect(mapStateToProps, null)(DashboardConta);
