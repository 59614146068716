import React from 'react';
import { Button } from 'react-bootstrap';

export default function AlgoAconteceu(props) {
    const center = { textAlign: 'center' };
    return (
        <div >
            <h2 style={center}>Encontramos um problema para verificar seus acessos.</h2>
            <h3 style={center}>Por favor, volte e tente novamente</h3>
            <center>
                <Button className="btn btn-sm" title="voltar" onClick={() => props.history.replace('/')} >
voltar
                </Button>
            </center>
        </div>
    );
}
