import { reactLocalStorage } from 'reactjs-localstorage';
import { URL_GET_PESSOA_ID } from '../../../servicos/url-servicos';
import { CONSTANTES } from './dados-pj-constantes';

export function obterDadosPessoaJuridica(pessoaId) {
    return function(dispatch) {
        fetch(URL_GET_PESSOA_ID(pessoaId), {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: reactLocalStorage.get('token'),
                parceiro: 'BPP'
            }
        })
            .then(promiseResponse => {
                promiseResponse.json().then(data => {
                    dispatch({
                        type: promiseResponse.ok ? CONSTANTES.OBTER_DADOS_PJ_SUCESSO : CONSTANTES.OBTER_DADOS_PJ_ERRO,
                        payload: promiseResponse.ok ? data : CONSTANTES.LABELS.ERRO_OBTER_DADOS
                        // payload: promiseResponse.ok ? data : err.erros[0].mensagem // Nota: ficou definido que sempre será exibida uma msg genérica.
                    });
                });
            })
            .catch(() => {
                dispatch({
                    type: CONSTANTES.OBTER_DADOS_PJ_ERRO,
                    payload: CONSTANTES.LABELS.ERRO_OBTER_DADOS
                });
            });
    };
}
