import Controle from './conta-selecionada-controlador';
const localState = {
    'exibirEmBreve': false
};
export default function reducer(state = localState, action) {
    switch (action.type) {
        case Controle.buscarSaldoConta():
        {
            return {
                ...state,
                contaSelecionadaShow: action.payload
            };
        }
        case Controle.erroSaldoConta(): {
            return {
                ...state,
                contaSelecionadaShow: action.payload
            };
        }
        case Controle.exibirEmbreveControl(): {
            return {
                ...state,
                exibirEmBreve: action.payload
            };
        }
        default:
            return state;
    }
}
