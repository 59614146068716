import { HTTP_METODOS, HEADER_API, montaUrlFetch, urlServicos } from '../../../../servicos/url-servicos';
import { stringify } from 'querystring';
import { montaListaParaTela, concatenarLista, montaCorpoRequisicaoAprovarOuReprovarLote, montaCorpoRequisicaoSolicitarTokenTransferenciaEmLote, montaAgendamentosListasParaTela } from './agendamento-e-aprovacoes-util';
import { METODOS } from '../../../util/contantes-comuns/constantes-comuns';
import moment from 'moment';

export const setContaSelecionada = (contaSelecionada) => ({
    type: METODOS.SET_CONTA_SELECIONADA,
    payload: contaSelecionada
});

export const setExibirModalToken = (value) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_MODAL_TOKEN_AGENDAMENTO_E_APROVACOES,
    payload: value
});

export const setFiltroAgendamentosEAprovacoesDataInicial = (event) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_INICIAL,
    payload: event.target.value
});

export const setFiltroAgendamentosEAprovacoesDataFinal = (event) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_DATA_FINAL,
    payload: event.target.value
});

export const setFiltroAgendamentosEAprovacoesValorDe = (valor) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_DE,
    payload: valor
});

export const setValsetFiltroAgendamentosEAprovacoesValorAte = (valor) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.FILTRO.SET_FILTRO_AGENDAMENTO_E_APROVACOES_VALOR_ATE,
    payload: valor
});

export const setExibirModalFinalizacaoAgendamentoEAprovacoes = (valor) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_MODAL_FINALIZACAO_AGENDAMENTO_E_APROVACOES,
    payload: valor
});

export const limparFiltroAgendamentoEAprovacoes = () => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.LIMPAR_FILTRO_AGENDAMENTO_E_APROVACOES,
    payload: ''
});

export const setExibirLoadingAgendamentoEAprovacoes = (exibirAgendamentoEAprovacoes) => ({
    type: METODOS.AGENDAMENTO_E_APROVACOES.SET_EXIBIR_LOADING_AGENDAMENTO_E_APROVACOES,
    payload: exibirAgendamentoEAprovacoes
});

const getAgendamentos = (filtro) => {
    return fetch(montaUrlFetch(urlServicos.OPERACAO, filtro), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(response => response.json());
};

const buscaOperacoes = () => {
    return fetch(montaUrlFetch(urlServicos.LISTA_OPERACOES), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(response => response.json());
};

export const setExibirLoadingAgendamentosEAprovacoesTransferenciaEmLote = (exibirLoadingAgendamentosEAprovacoesTransferenciaEmLote) => ({
    type: METODOS.TRANSFERENCIA_EM_LOTE.SET_EXIBIR_LOADING_AGENDAMENTOS_E_APROVACOES_TRANSFERENCIA_EM_LOTE,
    payload: exibirLoadingAgendamentosEAprovacoesTransferenciaEmLote
});

export const buscarAgendamentosDashboardConta = (filtro, listaDeProdutosBppPagIds) => {
    return function(dispatch) {
        dispatch(setExibirLoadingAgendamentoEAprovacoes(true));
        return Promise.all([
            getAgendamentos(filtro),
            buscaOperacoes()
        ])
            .then(result => {
                let agendamentosRecuperados = result[0];
                let operacoesRecuperadas = result[1];

                dispatch({
                    type: METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA,
                    payload: montaListaParaTela(agendamentosRecuperados, operacoesRecuperadas.tipoOperacao, listaDeProdutosBppPagIds)
                });
            })
            .catch(err => {
                dispatch({
                    type: METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA_ERRO,
                    payload: err
                });
            })
            .finally(() => dispatch(setExibirLoadingAgendamentoEAprovacoes(false)));
    };
};

export const buscarTodosAgendamentosDashboardConta = (filtro, listaDeProdutosBppPagIds) => {
    return function(dispatch) {
        dispatch(setExibirLoadingAgendamentoEAprovacoes(true));
        return Promise.all([
            getAgendamentos(filtro[0]),
            getAgendamentos(filtro[1]),
            buscaOperacoes()
        ])
            .then(result => {
                let agendamentosRecuperados = [result[0], result[1]];
                let operacoesRecuperadas = result[2];

                dispatch({
                    type: METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA,
                    payload: montaAgendamentosListasParaTela(agendamentosRecuperados, operacoesRecuperadas.tipoOperacao, listaDeProdutosBppPagIds)
                });
            })
            .catch(err => {
                dispatch({
                    type: METODOS.AGENDAMENTO_E_APROVACOES.BUSCAR_AGENDAMENTOS_DASHBOARD_CONTA_ERRO,
                    payload: err
                });
            })
            .finally(() => dispatch(setExibirLoadingAgendamentoEAprovacoes(false)));
    };
};

export const solicitaTokenTransacao = (operacaoId, transacao) => {
    return dispatch => {
        fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS', ...transacao }), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        }).then(response => {
            response.json().then(dadosRetornados => {
                dispatch({
                    type: response.ok ? METODOS.AGENDAMENTO_E_APROVACOES.TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES : METODOS.AGENDAMENTO_E_APROVACOES.TOKEN_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO,
                    payload: dadosRetornados
                });
            });
        });
    };
};
// TODO: os requisitantes dessa chamada só podem executálos, quando tiver permissão de podeAprovar (outorga-negocio)
export const efetivaTransacao = (operacaoId, transacao, token, dataAgendamento) => {
    return dispatch => {
        dispatch(setExibirLoadingAgendamentoEAprovacoes(true));
        fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: JSON.stringify({
                ...transacao,
                dataAgendamento,
                token
            })
        }).then(response => {
            response.json().then(dadosRetornados => {
                dadosRetornados.transacaoRealizada = transacao;
                dispatch({
                    type: response.ok ? METODOS.AGENDAMENTO_E_APROVACOES.EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES : METODOS.AGENDAMENTO_E_APROVACOES.EFETIVAR_TRANSACAO_AGENDAMENTOS_E_APROVACOES_ERRO,
                    payload: dadosRetornados
                });
            });
        }).finally(() => dispatch(setExibirLoadingAgendamentoEAprovacoes(false)));
    };
};

export const buscarAgendamentoEAprovacoesDeTransferenciaEmLote = (filtro) => {
    return dispatch => {
        dispatch(setExibirLoadingAgendamentosEAprovacoesTransferenciaEmLote(true));
        return fetch(montaUrlFetch(urlServicos.LOTE, filtro), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        })
            .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then(resp => {
                dispatch({
                    type: resp.error ? METODOS.TRANSFERENCIA_EM_LOTE.BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE_ERRO : METODOS.TRANSFERENCIA_EM_LOTE.BUSCAR_AGENDAMENTOS_EAPROVAVOES_DE_TRANSFERENCIA_EM_LOTE,
                    payload: resp.data
                });
            }).finally(() => dispatch(setExibirLoadingAgendamentosEAprovacoesTransferenciaEmLote(false)));
    };
};

export const efetivaTransacaoDeOperacoesEmLote = (loteId, acao, token, tokenId) => {
    return async dispatch => {
        return await fetch(montaUrlFetch(urlServicos.LOTE_ID(loteId)), {
            method: HTTP_METODOS.PUT,
            headers: HEADER_API().headers,
            body: montaCorpoRequisicaoAprovarOuReprovarLote(acao, token, tokenId)
        }).then(response => {
            response.json().then(dadosRetornados => {
                dadosRetornados.acaoRealizada = acao;
                dispatch({
                    type: response.ok ? METODOS.TRANSFERENCIA_EM_LOTE.EFETIVAR_TRANSACAO_EM_LOTE : METODOS.TRANSFERENCIA_EM_LOTE.EFETIVAR_TRANSACAO_EM_LOTE_ERRO,
                    payload: dadosRetornados
                });
            });
        }); /* .finally(() => dispatch(buscarAgendamentoEAprovacoesDeTransferenciaEmLote(filtro))); */
    };
};

export const solicitarTokenTransferenciaEmLote = (pessoaId) => {
    return async dispatch => {
        return await fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OTP), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: montaCorpoRequisicaoSolicitarTokenTransferenciaEmLote(pessoaId)
        })
            .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then(resp => {
                dispatch({
                    type: resp.error ? METODOS.TRANSFERENCIA_EM_LOTE.SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE_ERRO : METODOS.TRANSFERENCIA_EM_LOTE.SOLICITAR_TOKEN_TRANSFERENCIA_EM_LOTE,
                    payload: resp.data
                });
            });
    };
};

const buscarArquivoAgendamentoTransferenciaEmLotePorArquivoId = async (arquivoId) => {
    return await fetch(montaUrlFetch(urlServicos.ARQUIVO_ID(arquivoId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};
