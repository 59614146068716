import React, { Component } from 'react';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';
import LoadingRotas from '../loading-fenix/loading-rotas';

export default class Tentativas extends Component {

    state = {
        tentativas: 0
    }

    render() {

        if (this.props.isFetching) {
            return <LoadingRotas />;
        }

        const rodape = <FooterBotoesModalComponent
            parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={() => {
                this.setState({ exibirModal: false });
                this.props.history.push('/sair');
            }}
        />;

        return (
            <div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                    {this.state.tentativas < 3 && <div className="row">
                        Erro {this.state.tentativas}, tente novamente:
                        <button onClick={() => {
                            this.setState(state => ({ tentativas: state.tentativas + 1, exibirModal: state.tentativas + 1 >= 3 }));
                            this.props.onRetry();
                        }
                        }>{LABEL_COMUNS.TENTE_NOVAMENTE}</button>
                    </div>}
                    <ModalComponent name="tentativas" exibirModal={this.state.exibirModal}
                        body={LABEL_COMUNS.INSTABILIDADE_NO_SISTEMA} header={LABEL_COMUNS.ATENCAO} footer={rodape}
                    />
                </div>
            </div>
        );
    }
}
