function ImagemViewModel({
    ativo = undefined,
    dataCriacao = undefined,
    imagem = undefined,
    miniatura = undefined,
    nomeOriginal = undefined,
    urlImagem = undefined,
    urlMiniatura = undefined
}={}){
    
    this.ativo = ativo;
    this.dataCriacao = dataCriacao;
    this.imagem = imagem;
    this.miniatura = miniatura;
    this.nomeOriginal = nomeOriginal;
    this.urlImagem = urlImagem;
    this.urlMiniatura = urlMiniatura;

}

ImagemViewModel.buildMock = function() {
    return new ImagemViewModel({
        ativo: true,
        dataCriacao: '2019-07-29T09:41:28.315',
        imagem: '75ac4d73-b2d2-4435-bed3-e4f5bc80857e',
        miniatura: '75ac4d73-b2d2-4435-bed3-e4f5bc80857e_thumbnail',
        nomeOriginal: '2019-07-26_164040.png',
        urlImagem: 'https://arquivolambda-arquivopermanentebucket-1fnx03egljzig.s3.amazonaws.com/75ac4d73-b2d2-4435-bed3-e4f5bc80857e?X-Amz-Security-Token=AgoJb3JpZ2luX2VjEAAaCXVzLWVhc3QtMSJHMEUCIQDjOfnkihCaac1nIa9sBeqqF8uqltVS5aaChllLeirtMgIgB%2FULrTJ275CLeIScqxvAeZkEyaAcUwVtguEzJg4UcikqpQIIeRAAGgw2NTM5NDE1ODU2MjQiDDXEbdD1YtRY9pTgPCqCApvUdEyONbKLH2KHFaaHE%2B9nWr%2FRB0Ch38%2Bi412mdf%2FUwuHOuvwGuZbMGTuDGzBuRSSWMQKdixENdNAVXfX9uMnScs3L8aufDnXuP5%2BGRgTJq17GuU%2FI6rSmyShlHZFekxvGn60PZdRBKpi1mwbjMNqG8ueeE24P7IusFucsWgVPP%2BG6fYOWOXtH1SSaz1C2flfdt1nh9TxTDqEnuXx31cL70l1tn8bFx1lvXJsWTiOXGemDH0STvfYg7Yxr3iNGbWuLTupplxCRUoqmTDZpQfEo4KZO%2BYatP1ISw4PhVZ3p8wEUQxhNX3cpEHF6K5zQrL90uKeb9sfVQNniRw4pGju7lzC4qPzpBTq0AYM87F5dNcWRGADXnKaVg4rd%2FB7LneyeJS5VNyjncAyk3bt8%2FwsCxP8HA2EZOaQP3bYDHzy84y6opEfSpSdWfZNWWFMHbR7NfE99IIiFgLzSnXZejQJOk2dwcROhLRZulchAuCPhdiss3XdrlCIawwhOaZYz9%2BCq7Z3lUQKI2R67iZr%2BH4MnlAh2OsdCNvSOm4SqUuFkh6VN9D6TyBHzY50jJYvXszkmeyBlZIw3NjFhI396MA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20190729T171553Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7200&X-Amz-Credential=ASIAZQQPUW3MF6WA3OUC%2F20190729%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=ad49246564812a9ece1587ef5c3aa76f440d713456ba3d4ba49f62ac2818025c',
        urlMiniatura: 'https://arquivolambda-arquivopermanentebucket-1fnx03egljzig.s3.amazonaws.com/75ac4d73-b2d2-4435-bed3-e4f5bc80857e_thumbnail?X-Amz-Security-Token=AgoJb3JpZ2luX2VjEAAaCXVzLWVhc3QtMSJHMEUCIQDjOfnkihCaac1nIa9sBeqqF8uqltVS5aaChllLeirtMgIgB%2FULrTJ275CLeIScqxvAeZkEyaAcUwVtguEzJg4UcikqpQIIeRAAGgw2NTM5NDE1ODU2MjQiDDXEbdD1YtRY9pTgPCqCApvUdEyONbKLH2KHFaaHE%2B9nWr%2FRB0Ch38%2Bi412mdf%2FUwuHOuvwGuZbMGTuDGzBuRSSWMQKdixENdNAVXfX9uMnScs3L8aufDnXuP5%2BGRgTJq17GuU%2FI6rSmyShlHZFekxvGn60PZdRBKpi1mwbjMNqG8ueeE24P7IusFucsWgVPP%2BG6fYOWOXtH1SSaz1C2flfdt1nh9TxTDqEnuXx31cL70l1tn8bFx1lvXJsWTiOXGemDH0STvfYg7Yxr3iNGbWuLTupplxCRUoqmTDZpQfEo4KZO%2BYatP1ISw4PhVZ3p8wEUQxhNX3cpEHF6K5zQrL90uKeb9sfVQNniRw4pGju7lzC4qPzpBTq0AYM87F5dNcWRGADXnKaVg4rd%2FB7LneyeJS5VNyjncAyk3bt8%2FwsCxP8HA2EZOaQP3bYDHzy84y6opEfSpSdWfZNWWFMHbR7NfE99IIiFgLzSnXZejQJOk2dwcROhLRZulchAuCPhdiss3XdrlCIawwhOaZYz9%2BCq7Z3lUQKI2R67iZr%2BH4MnlAh2OsdCNvSOm4SqUuFkh6VN9D6TyBHzY50jJYvXszkmeyBlZIw3NjFhI396MA%3D%3D&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20190729T171553Z&X-Amz-SignedHeaders=host&X-Amz-Expires=7199&X-Amz-Credential=ASIAZQQPUW3MF6WA3OUC%2F20190729%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=b11afbc0152aadb4aff4fb23458ad23b4355a03cadae2044573bad88ff23c867'
    });
};

export default ImagemViewModel;