import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Alert, Button, ControlLabel, FormControl, FormGroup, Checkbox } from 'react-bootstrap';
import { InputSwitch } from 'primereact/inputswitch';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import ContaSelecionada from '../contas/conta-selecionada';
import Grid from '../componentes-uteis/grid/grid';
import If from '../componentes-uteis/if/if';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import LoadingFenix from '../loading-fenix/loading-fenix';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import { CAMPOS, COMBO, CONTA_SELECIONADA, LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, OPERACAO, TIPO_DOCUMENTO, TIPO_TRANSFERENCIA_BANCARIA} from '../util/contantes-comuns/constantes-comuns';
import DataAgendamento from '../componentes-uteis/data-agendamento/data-agendamento';
import * as actions from '../../action/contas/contas-action';
import './transferencia.css';
import ShowLineError from '../comuns/showLineError/show-line-error';
import { validaErrosTransferenciaBancaria } from './transferencia-bancaria-util';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import {
    outorgaParametroOperacao,
    podeAprovar,
    podeConsultar,
    podeCriar
} from '../../reducer/outorga/outorga-operacao-reducer';
import { retornaObjetoTarget } from '../../servicos/base/service-base';
import { fetchGetPermitirFazerTed } from '../../servicos/contas/contas-base';
class TransferenciaBancariaSolicitacao extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = {
            agencia: '', bancoSelecionadoId: '', numeroConta: '', digito: '', descricao: '', dataAgendamento: '', 
            defaultMaskPattern: MASCARA_CAMPOS.CPF, email: '', favorecidoSelecionadoId: '', hoje: false, nome: '', 
            numeroDocumento: '', telefone: '', tipoConta: '', exibirModalToken: false,
            transferenciaSelecionadaId: props.location.state.transferenciaSelecionadaId,
            valorDaTransferencia: 0, salvarFavorecidoConta: false, tagCliente: '', tipoDocumento: TIPO_DOCUMENTO.CPF.value,
            tokenId: undefined, disabledEmail: false, exibirModalFinalizacao: false, redirecionarDashboardConta: false,
            exibirModalConfirmacao: false, showModalMessage: false, disabledHoje: false, showOkButton: false
        };
        this.state = this.ESTADO_INICIAL;
    }

    async componentDidMount() {   
        let contaSelecionada = CONTA_SELECIONADA.OBJETO();
        //  this.props.permitidoFazerTed();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.buscarBancos();
        this.props.buscarFavorecidos(contaSelecionada, this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value ? OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO : OPERACAO.NOME.TED);
        if (this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value) {
            this.setState({ agencia: CAMPOS.AGENCIA_DEFAULT_VALUE });
        }
        if (this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
            const permitidoFazerTedResponse = await fetchGetPermitirFazerTed();
            if (!permitidoFazerTedResponse.solicitacaoDeTransferenciaBancariaRealizada) {
                this.setState({ disabledHoje: !permitidoFazerTedResponse.data.valid});
            }
        }
    }

    componentWillReceiveProps(nextProps) {
        const { horarioPermitidoTed } = nextProps.solicitacao;
        if (nextProps.solicitacao.transferenciaBancariaOperacaoId && nextProps.solicitacao.transferenciaBancariaOperacaoId !== this.props.solicitacao.transferenciaBancariaOperacaoId) {
            this.setState({ transferenciaBancariaOperacaoId: nextProps.solicitacao.transferenciaBancariaOperacaoId });
            //Validação colocado por causa do reenviar token
            if (!this.state.exibirModalToken) {
                this.setState({ exibirModalToken: true, tokenId: undefined });
            }
        } 
        //console.log('nextProps.solicitacao', nextProps.solicitacao);
        if (horarioPermitidoTed === false){                
            this.setHoje({ target: { checked : false }});
            this.setState({ disabledHoje: true, dataAgendamento: !!horarioPermitidoTed, showOkButton:true});
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
            this.props.setHorarioPermitidoFazerTed(true);
        }


        if (nextProps.solicitacao.solicitacaoDeTransferenciaBancariaRealizada) {
            const { solicitacaoDeTransferenciaBancariaRealizada, responseSalvarFavorecido } = nextProps.solicitacao;
            let exibirModalFinalizacao = true;
            if (solicitacaoDeTransferenciaBancariaRealizada !== this.props.solicitacao.solicitacaoDeTransferenciaBancariaRealizada) {
                let mensagemFinalizacao = MENSAGENS.CONTAS.TRANSFERENCIA_BANCARIA.FINALIZACAO.SUCESSO(this.state.dataAgendamento, responseSalvarFavorecido.erro);
                if (solicitacaoDeTransferenciaBancariaRealizada.erro) {
                    const { erros } = solicitacaoDeTransferenciaBancariaRealizada;
                    if (erros) {
                        if (erros.tokenIdErro) {
                            exibirModalFinalizacao = false;
                        }
                        this.setState(erros);
                    } else {
                        this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_TOKEN);
                    }
                    mensagemFinalizacao = erros.horarioErro ? erros.horarioErro : MENSAGENS.CONTAS.TRANSFERENCIA_BANCARIA.FINALIZACAO.ERRO(this.state.dataAgendamento, responseSalvarFavorecido.erro);
                }

                if (exibirModalFinalizacao) {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_TOKEN);
                    this.setState({ mensagemFinalizacao: this.props.solicitacao.msg || mensagemFinalizacao, exibirModalFinalizacao });
                }
            }
        }

        if (nextProps.solicitacao.solicitacaoTokenTransferenciaBancaria && nextProps.solicitacao.solicitacaoTokenTransferenciaBancaria !== this.props.solicitacao.solicitacaoTokenTransferenciaBancaria) {
            const { solicitacaoTokenTransferenciaBancaria } = nextProps.solicitacao;
            if (solicitacaoTokenTransferenciaBancaria && solicitacaoTokenTransferenciaBancaria.erro) {
                this.setState({ tokenIdErro: solicitacaoTokenTransferenciaBancaria.erro.mensagem });
            }
        }
    }

    setPropsCampos = (e, nomeDoCampo) => {       
        let valorDoCampo = ((e && e.value) || (e && e.target && e.target.value)) ;

        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
        this.preencheCamposSelecionandoFavorecido(nomeDoCampo, e);
    }

    definirMascaraCpfCnpjOnchange = (tipo) => {
        if (tipo === TIPO_DOCUMENTO.CPF.value) {
            this.setState({ 'defaultMaskPattern': MASCARA_CAMPOS.CPF });
        } else {
            this.setState({ 'defaultMaskPattern': MASCARA_CAMPOS.CNPJ });
        }
    }

    preencheCamposSelecionandoFavorecido = (nomeDoCampo, valorDoCampo) => {
        if (nomeDoCampo === CAMPOS.FAVORECIDO_SELECIONADO_ID) {


            if (valorDoCampo) {
                const { favorecido } = valorDoCampo;
                const { email, telefone, tipoDocumento } = favorecido;

                if (tipoDocumento === TIPO_DOCUMENTO.CPF.value) {
                    this.setState({ defaultMaskPattern: MASCARA_CAMPOS.CPF, numeroDocumento: '', numeroDocumentoErro: '' });
                }
                else {
                    this.setState({ defaultMaskPattern: MASCARA_CAMPOS.CNPJ, numeroDocumento: '', numeroDocumentoErro: '' });
                }

                if (email) {
                    this.setState({ email, disabledEmail: true });
                }
                if (telefone) {
                    this.setState({ telefone: telefone.substring(2), disabledTelefone: true });
                }
                this.setState({
                    nome: favorecido.nome, nomeErro: '', numeroConta: favorecido.conta, numeroContaErro: '',
                    digito: favorecido.digito, digitoErro: '', descricaoErro: ''
                });

                if (this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
                    this.setState({
                        agencia: favorecido.agencia, agenciaErro: '', bancoSelecionadoId: favorecido.banco, bancoSelecionadoIdErro: '',
                        tipoDocumento: favorecido.tipoDocumento, numeroDocumento: favorecido.numeroDocumento, tipoConta: favorecido.tipoConta
                    });
                }
            }
            else {
                this.setState({
                    bancoSelecionadoId: '', nome: '', numeroConta: '', digito: '',
                    disabledEmail: false, disabledTelefone: false, email: '', telefone: ''
                });
                if (this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
                    this.setState({
                        agencia: '', agenciaErro: '', numeroDocumento: '', numeroDocumentoErro: '',
                        tipoDocumento: TIPO_DOCUMENTO.CPF.value, tipoDocumentoErro: '',
                        defaultMaskPattern: MASCARA_CAMPOS.CPF, tipoConta: '', tipoContaErro: '',

                    });
                }
            }
        }
    }

    validarForm = () => {
        const { transferenciaSelecionadaId } = this.state;
        const dadosForm = this.retornaDadosForm();
        let erros = validaErrosTransferenciaBancaria(dadosForm, transferenciaSelecionadaId);
        if (Object.keys(erros).length > 0) {
            this.setState(erros);
        } else {
            if (transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
                this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
            } else {
                this.solicitarTransferencia();
            }

        }
    }

    solicitarTransferencia() {
        const { outorgaPerfils: { podeAprovar, podeConsultar, podeCriar } } = this.props;
        const { dataAgendamento } = this.state;

        const cen1 = 'Confirma a aprovação da operação?';
        const cen2 = 'Confirma o agendamento e aprovação da operação?';
        const cen3 = 'Confirma o cadastro da operação?';
        const cen4 = 'Confirma o cadastro do agendamento da operação?';

        const msg = podeAprovar && dataAgendamento ? cen2 : (dataAgendamento ? cen4 : (podeAprovar ? cen1 : cen3));
        this.setState({ exibirModalContinuarComOperacao: msg });

    }

    efetuarTransferencia() {
        const dadosForm = this.retornaDadosForm();
        const op = this.props.location.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value ? OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO : OPERACAO.NOME.TED;
        const parametroOperacao = outorgaParametroOperacao(this.props.outorga, op);
        const parametroOperacaoDifTit = outorgaParametroOperacao(this.props.outorga, OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES);
        const necessitaToken = dadosForm.dataAgendamento ? parametroOperacao.agendamento : parametroOperacao.aprovacao;
        const necessitaTokenDifTit = dadosForm.dataAgendamento ? parametroOperacaoDifTit.agendamento : parametroOperacaoDifTit.aprovacao;
        const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
        this.props.solicitarTransferenciaBancaria(dadosForm, necessitaToken, necessitaTokenDifTit, outorgaPodeAprovar, !this.state.hoje);
    }

    setHoje = (event) => {
        const valorDoCampo = event.target.checked;
        this.setState({ hoje: valorDoCampo });
        if(valorDoCampo){
            this.setState({ dataAgendamentoObrigatoriaErro: '', dataAgendamento: '', dataAgendamentoErro: ''});
        }
        

    /*  hojeIDOnchange={() => {
                               this.setState({ hoje: !this.state.hoje, dataAgendamentoObrigatoriaErro: '', dataAgendamento: !!this.state.hoje && '', dataAgendamentoErro: '' }); }} */
    }

    setShowOkButton = (showOkButton) => {
        this.setState({showOkButton});
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token </ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.tokenId ? '' : this.state.tokenId}
                        onChange={(e) => this.setPropsCampos(e, CAMPOS.TOKEN_ID)}
                        mask={MASCARA_CAMPOS.TOKEN}
                        maskChar={null}
                    />
                    <ShowLineError error={this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.props.solicitarTokenTransferenciaBancaria(this.state.transferenciaBancariaOperacaoId, this.state)}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken = () => {
        return (
            <FooterBotoesModalComponent              
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_TOKEN);
                    this.setPropsCampos({ target: { value: '' } }, CAMPOS.TOKEN_ID);
                }}
                btn2OnClick={() => {
                    if (this.state.tokenId && this.state.tokenId.length === 6) {
                        this.props.solicitaEfetivacaoTransacaoDeTransferenciaBancaria(this.state.transferenciaBancariaOperacaoId, this.state.tokenId, this.state.dataAgendamento, this.retornaDadosForm());
                        //this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_TOKEN);
                    }
                    else {
                        this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
                    }
                }} />
        );
    }

    retornaRodapeModalFinalizacao = () => {
        return (
            <FooterBotoesModalComponent               
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_TOKEN);
                    this.setState({ exibirModalFinalizacao: false, tokenId: undefined });

                    if (this.props.solicitacao.solicitacaoDeTransferenciaBancariaRealizada && this.props.solicitacao.solicitacaoDeTransferenciaBancariaRealizada.sucesso) {
                        this.setState({ redirecionarDashboardConta: true });
                    }
                }} />
        );
    }

    retornaCorpoModalConfirmacao = () => {
        const { dataAgendamento } = this.state;
        return (
            <div dangerouslySetInnerHTML={{ __html: MENSAGENS.CONTAS.TRANSFERENCIA_BANCARIA.CONFIRMACAO(dataAgendamento) }} />
        );
    } 

    retornaRodapeModalConfirmacao = () => {
        const {showOkButton} = this.state;
        return (
            <div>
                <If test={showOkButton}>
                    <FooterBotoesModalComponent
                        btn2Label={LABEL_COMUNS.OK}                      
                        btn2OnClick={() => {
                            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);  
                            this.setShowOkButton(false);                         
                        }} />
                </If>               
                <If test={!showOkButton}>
                    <FooterBotoesModalComponent               
                        btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                        btn1OnClick={() => {
                            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                        }}
                        btn2OnClick={() => {
                            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                            this.solicitarTransferencia();
                        }} />
                </If>
            </div>
        );
    }

    retornaDadosForm = () => {
        const { saldoConta: { saldo }, contaSelecionada } = this.props.solicitacao;
        const dadosForm = { ...this.state, saldo, contaSelecionada };
        return dadosForm;
    }

    exibirPagina = (contaSelecionada) => (
        <div>
            <ContaSelecionada contaSelecionada={contaSelecionada} />
            <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9">
                <div className="panel panel-default">
                    <div className="panel-heading fundo-azul-claro" style={{ height: '40px' }}>
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-esquerda">
                            {LABEL_COMUNS.TRANSFERENCIA_BANCARIA}
                        </div>
                    </div>
                    <div className="panel-body">
                        <If test={this.state.mensagemErroGenerico || this.state.mensagemErroMesmaConta}>
                            <Alert bsStyle={STATUS_VALIDACAO_COMPONENTES.PERIGO}>
                                {this.state.mensagemErroGenerico || this.state.mensagemErroMesmaConta}
                            </Alert>
                        </If>
                        <If test={this.props.solicitacao.exibirLoadingConta}>
                            <div className="alinhamento-centro">
                                <LoadingFenix />
                            </div>
                        </If>
                        <Grid cols="12 12 12 12">
                            <ControlLabel>{LABEL_COMUNS.SELECIONE_FAVORECIDO}*</ControlLabel>
                            <FormGroup validationState={this.state.favorecidoSelecionadoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <Select id="favorecido"
                                    placeholder="Selecione o favorecido"
                                    value={this.state.favorecidoSelecionadoId}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.FAVORECIDO_SELECIONADO_ID)}
                                    options={this.props.solicitacao.listaDeFavorecidos}
                                    noResultsText={LABEL_COMUNS.NENHUM_FAVORECIDO_ENCONTRADO}
                                />
                                <ShowLineError error={this.state.favorecidoSelecionadoIdErro} />
                            </FormGroup>
                        </Grid>
                        <Grid cols="12 12 12 12">
                            <ControlLabel>{LABEL_COMUNS.NOME_COMPLETO}*</ControlLabel>
                            <FormGroup validationState={this.state.nomeErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <FormControl className="mensagens-erro" id="nome" type="text"
                                    placeholder="Nome"
                                    value={this.state.nome}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.NOME)}
                                    maxLength={40}
                                    disabled={this.state.favorecidoSelecionadoId}
                                    style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }} />
                                <ShowLineError error={this.state.nomeErro} />
                            </FormGroup>
                        </Grid>
                        <If test={this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value}>
                            <Grid cols="12 12 12 12">
                                <ControlLabel>{LABEL_COMUNS.TIPO_DOCUMENTO}*</ControlLabel>
                                <FormGroup>
                                    <FormControl className="mensagens-erro" id="tipoDocumento" componentClass="select" placeholder="Selecione"
                                        inputRef={(ref) => this.tipoDocumentoSelecionado = ref}
                                        value={this.state.tipoDocumento}
                                        onChange={
                                            (e) => {
                                                this.setPropsCampos(e, CAMPOS.TIPO_DOCUMENTO);
                                                if (e.target.value !== 'object') {
                                                    this.definirMascaraCpfCnpjOnchange(e.target.value);
                                                }
                                            }
                                        }
                                        disabled={this.state.favorecidoSelecionadoId}
                                        style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}>
                                        {COMBO.TIPO_DOCUMENTO.map((tipoDocumento, idx) => {
                                            return (
                                                <option key={idx} value={tipoDocumento.value}>{tipoDocumento.label}</option>
                                            );
                                        })}
                                    </FormControl>
                                </FormGroup>
                            </Grid>
                            <Grid cols="12 12 12 12">
                                <ControlLabel>{LABEL_COMUNS.DOCUMENTO}*</ControlLabel>
                                <FormGroup validationState={this.state.numeroDocumentoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <InputMask id="numeroDocumento"
                                        className="form-control mensagens-erro"
                                        mask={this.state.defaultMaskPattern}
                                        maskChar=""
                                        placeholder="Documento"
                                        value={this.state.numeroDocumento}
                                        disabled={this.state.favorecidoSelecionadoId}
                                        onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_DOCUMENTO)}
                                        maxLength={18}
                                        style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }} />
                                    <ShowLineError error={this.state.numeroDocumentoErro} />
                                </FormGroup>
                            </Grid>
                            <Grid cols="12 12 12 12">
                                <ControlLabel>{LABEL_COMUNS.TIPO_DE_CONTA}*</ControlLabel>
                                <FormGroup validationState={this.state.tipoContaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <FormControl
                                        className="mensagens-erro"
                                        id="tipoConta"
                                        componentClass="select"
                                        placeholder="Tipo de Conta"
                                        value={this.state.tipoConta}
                                        disabled={this.state.favorecidoSelecionadoId}
                                        onChange={(e) => this.setPropsCampos(e, CAMPOS.TIPO_CONTA)}
                                        style={{ height: '35px', paddingTop: '6px', paddingLeft: '10px' }}>
                                        <option defaultValue value={-1} >
                                            {LABEL_COMUNS.SELECIONE}
                                        </option>
                                        {
                                            COMBO.TIPO_CONTA.map((tipoConta, idx) => {
                                                return (
                                                    <option key={idx} value={tipoConta.value}>
                                                        {tipoConta.label}
                                                    </option>
                                                );
                                            })
                                        }
                                    </FormControl>
                                    <ShowLineError error={this.state.tipoContaErro} />
                                </FormGroup>
                            </Grid>
                            <Grid cols="12 12 12 12">
                                <ControlLabel>{LABEL_COMUNS.BANCO}*</ControlLabel>
                                <FormGroup validationState={this.state.bancoSelecionadoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <Select id="banco"
                                        placeholder="Selecione"
                                        value={this.state.bancoSelecionadoId}
                                        onChange={(e) => this.setPropsCampos(e, CAMPOS.BANCO_SELECIONADO_ID)}
                                        options={this.props.solicitacao.listaDeBancos}
                                        disabled={!!this.state.favorecidoSelecionadoId}
                                        noResultsText={LABEL_COMUNS.NENHUM_BANCO_ENCONTRADO}
                                        style={this.state.bancoSelecionadoIdErro ? { borderColor: '#a94442' } : { borderColor: '#e7e7e7   ' }}
                                    />
                                    <ShowLineError error={this.state.bancoSelecionadoIdErro} />
                                </FormGroup>
                            </Grid>
                        </If>

                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                                <Grid cols="4 4 4 4">
                                    <ControlLabel>{LABEL_COMUNS.AGENCIA}*</ControlLabel>
                                    <FormGroup validationState={this.state.agenciaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            id="agencia"
                                            className="form-control mensagens-erro"
                                            value={this.state.agencia}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.AGENCIA)}
                                            mask={MASCARA_CAMPOS.AGENCIA} maskChar=""
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                            disabled={this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value || this.state.favorecidoSelecionadoId}
                                            placeholder={LABEL_COMUNS.AGENCIA} />
                                        <ShowLineError error={this.state.agenciaErro} />
                                    </FormGroup>
                                </Grid>
                                <Grid cols="4 4 4 4">
                                    <ControlLabel>{LABEL_COMUNS.CONTAS.TITULOS.CONTA}*</ControlLabel>
                                    <FormGroup validationState={this.state.numeroContaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            id="numeroConta"
                                            className="form-control mensagens-erro"
                                            placeholder={LABEL_COMUNS.CONTAS.TITULOS.CONTA}
                                            maxLength={this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value ? 10 : 20}
                                            value={this.state.numeroConta}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_CONTA)}
                                            mask={this.state.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value ? MASCARA_CAMPOS.CONTA_BPP : MASCARA_CAMPOS.CONTA_OUTROS_BANCOS}
                                            disabled={this.state.favorecidoSelecionadoId}
                                            maskChar={null}
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />

                                        <ShowLineError error={this.state.numeroContaErro} />
                                    </FormGroup>
                                </Grid>
                                <Grid cols="4 4 4 4">
                                    <ControlLabel>{LABEL_COMUNS.DIGITO}*</ControlLabel>
                                    <FormGroup validationState={this.state.digitoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            id="digito"
                                            className="form-control mensagens-erro"
                                            placeholder={LABEL_COMUNS.DIGITO}
                                            maxLength={1}
                                            value={this.state.digito}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.DIGITO)}
                                            mask={MASCARA_CAMPOS.DIGITO}
                                            disabled={this.state.favorecidoSelecionadoId}
                                            maskChar={null}
                                            style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                        />
                                        <ShowLineError error={this.state.digitoErro} />
                                    </FormGroup>
                                </Grid>
                                <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 conta-digito">
                                    <If test={this.props.solicitacao.contaEDigitoMensagemErro} >
                                        <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.contaEDigitoMensagemErro}</span>
                                    </If>
                                </div>
                            </div>
                        </div>
                        <Grid cols="6 6 6 6">
                            <ControlLabel>{LABEL_COMUNS.EMAIL}</ControlLabel>
                            <FormGroup>
                                <InputMask
                                    id="email"
                                    className="form-control mensagens-erro"
                                    value={this.state.email}
                                    disabled={this.state.disabledEmail}
                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                    placeholder="Email"
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.EMAIL)} />
                            </FormGroup>
                        </Grid>
                        <Grid cols="6 6 6 6">
                            <ControlLabel>{LABEL_COMUNS.TELEFONE}</ControlLabel>
                            <FormGroup>
                                <InputMask
                                    id="telefone"
                                    className="form-control mensagens-erro"
                                    mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                    value={this.state.telefone}
                                    disabled={this.state.disabledTelefone}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.TELEFONE)}
                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                    placeholder="Telefone" />
                            </FormGroup>
                        </Grid>
                        <Grid cols="12 12 12 12">
                            <ControlLabel>{LABEL_COMUNS.VALOR_TRANSFERENCIA}*</ControlLabel>
                            <FormGroup validationState={this.state.valorDaTransferenciaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <CurrencyInput
                                    thousandSeparator="."
                                    decimalSeparator=","
                                    className="form-control mensagens-erro"
                                    id="valorCarga"
                                    maxLength={14}
                                    value={this.state.valorDaTransferencia}
                                    onChangeEvent={(e, maskedvalue, floatvalue) => this.setState({ valorDaTransferencia: floatvalue, valorDaTransferenciaErro: '' })}
                                />
                                <ShowLineError error={this.state.valorDaTransferenciaErro} />
                            </FormGroup>
                        </Grid>
                        <Grid cols="12 12 12 12">
                            <ControlLabel>{LABEL_COMUNS.DESCRICAO}</ControlLabel>
                            <FormGroup validationState={this.state.descricaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <FormControl id="descricao" componentClass="textarea"
                                    placeholder="Descrição"
                                    value={this.state.descricao}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.DESCRICAO)}
                                    maxLength={20}
                                />
                                <ShowLineError error={this.state.descricaoErro} />
                            </FormGroup>
                        </Grid>
                        <Grid cols="12 12 12 12" className="alinhamento-direita div-salvar-favorecido">
                            <Grid cols="10 10 10 10" className="salvar-favorecido">
                                <ControlLabel>{LABEL_COMUNS.SALVAR_FAVORECIDO}</ControlLabel>
                            </Grid>
                            <Grid cols="2 2 2 2" className="opcao-salvar-favorecido">
                                <InputSwitch disabled={!!this.state.favorecidoSelecionadoId}
                                    checked={this.state.salvarFavorecidoConta} onChange={(e) => this.setState({ salvarFavorecidoConta: e.value })} />
                            </Grid>
                        </Grid>
                        <Grid cols="12 12 12 12">
                            {/*     <FormGroup>
                                <Checkbox defaultChecked={this.state.hoje} 
                                    disabled={this.state.disabledHoje} onChange={this.state.setHoje} ><strong>{LABEL_COMUNS.HOJE}</strong>
                                </Checkbox>
                            </FormGroup> */}
                            <DataAgendamento
                                titulo={LABEL_COMUNS.DATA_AGENDAMENTO}
                                labelTransferenciaClassName="alinhamento-agendar-para"
                                inputDataAgendamentoClassName="form-control alinhamento-campo-tela-transferencia"
                                inputDataAgendamentoMensagemErroClassName="mensagens-erro-tela-transferencia"
                                paraHojeErro={this.state.dataAgendamentoObrigatoriaErro}
                                setHoje={this.setHoje}
                               /*  hojeIDOnchange={() => { 
                                    this.setState({ hoje: !this.state.hoje, dataAgendamentoObrigatoriaErro: '', dataAgendamento: !!this.state.hoje && '', dataAgendamentoErro: '' }); }} */
                                dataAgendamentoOnChange={(dataAgendamento) => this.setState({ dataAgendamento, dataAgendamentoErro: '', dataAgendamentoObrigatoriaErro: '', disabledHoje: !!this.state.disabledHoje })}
                                dataAgendamentoErro={this.state.dataAgendamentoErro}
                                dataAgendamentoObrigatoriaErro={this.state.dataAgendamentoObrigatoriaErro}
                                dataAgendamentoValue={this.state.dataAgendamento}
                                checkedValue={this.state.hoje}
                               // paraHoje={this.state.hoje}
                                disabledHoje={this.state.disabledHoje}
                            />
                        </Grid>
                        <div className="row">
                            <Grid cols="12 12 12 12" className="alinhamento-centro">
                                <Button bsStyle="primary" onClick={this.validarForm}>{LABEL_COMUNS.SOLICITAR_TRANSFERENCIA}</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                    <Button bsStyle="primary" className="botao-voltar-telas" onClick={this.voltarPagina}>{LABEL_COMUNS.VOLTAR}</Button>
                </div>
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ margin: '0px auto' }}></div>
        </div >
    );

    retornaCorpoModalContinuarComOperacao = () => {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.exibirModalContinuarComOperacao }} />
        );
    }

    retornaRodapeModalContinuarComOperacao = () => {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                }}
                btn2OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                    this.efetuarTransferencia();
                }} />
        );
    }
    setShowModalMessage = (showModalMessage) => {
        this.setState({ showModalMessage });
    }

    render() {
        const msg = this.props.solicitacao.msg || this.state.mensagemFinalizacao;

        const mensagemFinalizacao = <div dangerouslySetInnerHTML={{ __html: msg }} />;
        if (this.state.redirecionarDashboardConta) {
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_CONTA} />;
        }

        if (this.state.redirecionarTransferenciaBancaria) {
            return <Redirect to={this.state.pathName} />;
        }

        let contaSelecionada = this.props.solicitacao.contaSelecionada;
        let titleHeader = LABEL_COMUNS.TRANSFERENCIA_BANCARIA;
        if (this.state.agencia === CAMPOS.AGENCIA_DEFAULT_VALUE) {
            titleHeader = LABEL_COMUNS.TRANSFERENCIA_ENTRE_CONTAS;
        }
        return (
            <div>
                <div className="row alinhamento-centro">
                    <Grid cols="12 12 12 12">
                        <If test={this.props.solicitacao.exibirLoadingConta}>
                            <LoadingFenix />
                        </If>
                    </Grid>
                </div>
                {contaSelecionada && this.exibirPagina(contaSelecionada)}
                <ModalComponent exibirModal={this.state.exibirModalToken}
                    name="modal-token" header={titleHeader}
                    body={this.montaCorpoModalToken()} footer={this.montaRodapeModalToken()} />

                <ModalComponent exibirModal={this.state.exibirModalFinalizacao} 
                    name="modal-finalizacao" header={titleHeader}
                    body={mensagemFinalizacao} footer={this.retornaRodapeModalFinalizacao()} />

                <ModalComponent exibirModal={this.state.exibirModalConfirmacao}
                    name="modalConfirmacao" header={titleHeader}
                    body={this.retornaCorpoModalConfirmacao()} footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent exibirModal={!!this.state.exibirModalContinuarComOperacao}
                    name="modalContinuarComOperacao" header={titleHeader}
                    body={this.retornaCorpoModalContinuarComOperacao()} footer={this.retornaRodapeModalContinuarComOperacao()} />

            </div>
        );
    }

    voltarPagina = () => {
        const pathName = this.props.contextoMeusDepartamentos ? ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_CONTA_DEPARTAMENTO : ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_CONTA_MOVIMENTO;
        this.setState({ redirecionarTransferenciaBancaria: true, pathName });

    }
}



function mapStateToProps(state) {
    return {
        outorgaPerfils: {
            podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
            podeCriar: podeCriar(state.OutorgaOperacaoReducer),
            podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
        },
        outorga: state.OutorgaOperacaoReducer,
        solicitacao: { ...state.ContasReducer, saldoConta: state.MostrarSaldoReducer }
    };
}
const mapDispatchToProps = dispatch =>
    bindActionCreators(actions, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(TransferenciaBancariaSolicitacao);
