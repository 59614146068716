import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import {
    HEADER_API, URL_POST_CONTA, URL_GET_PRESTACAO_CONTA, URL_CONTA_ID, URL_PRESTACAO_CONTA_ID,
    URL_PARAMETRO_CONTA, URL_ARQUIVO, URL_GET_PRESTACAO_CONTA_PROJETO,
    URL_GET_PRESTACAO_CONTA_CLASSIFICACAO
} from '../../../servicos/url-servicos';
import {
    CONSTANTE, EMesageErroConfirm, EMesagemErroHelpBlock, controls, TipoPeriodo, TipoOperacao,
    PAGINACAO, HEADER_MSG, TipoStatusHeaderMsg
} from './gerenciar-prestacao-conta-constante';
import { formatarMascaraCnpj } from '../../util/formata-string';
import Controle from './gerenciar-prestacao-conta-controlador';
import { environment } from '../../../configuracoes/environment';
import { formatarDataUS, formatarDataHoraBR } from '../../util/data/dataUtil';
import { formataMoeda } from '../../util/numeros/numeroUtil';
import { LABEL_COMUNS, CONTA_SELECIONADA, COMBO } from '../../util/contantes-comuns/constantes-comuns';
import { debug } from 'util';
import moment from 'moment';
import { getPrestacaoDeContasOrExtrato } from '../../../servicos/pessoas/pessoa-service';

let prestacaoConta = '';
let statusTipoOperacaoSelecionado;
var request = require('sync-request');
let filtroSelecionado = null;


export default class GerenciarPrestacaoContaAction {



    async setExecutarPromises(promises, dispatch) {
        var response = new Array();
        var responseFiltrado = new Array();

        this.abrirTelaDeConfirmacaoAction(null, false, dispatch);
        this.setLoadingAction(dispatch);//abre o loading

        response = await Promise.all(promises);//executa a lista de promessa

        if (Array.isArray(response)) {
            if (response.length > 0) {
                responseFiltrado = response.filter((resp) => {
                    return resp !== null;
                });
            }
        }
        this.setLoadingAction(dispatch);
        dispatch({
            type: Controle.getResponseControl(),
            payload: responseFiltrado,
        });
        if (responseFiltrado.length > 0) {
            this.setShowMsgDialogErrosAction(true, dispatch);//abre a tela de erros 
        } else {
            this.setMsgDialogSucessoAction('Operação concluída com sucesso!', dispatch);
            this.setShowDialogSucessoAction(true, dispatch);//abre a tela de sucesso
        }
    }
    setHabilitarBotoesAprovarReprovarAction(valor) {
        return valor.length > 1 ? false : true;
    }
    setMudarFocus = (e, proximoCampo) => {
        if (e.key === 'Enter' && proximoCampo !== null) {
            try {
                document.getElementById(proximoCampo).focus();
            } catch (err) {
                //console.log(" provavelmente o proximoCampo para o focus nao existe ", err);
            }
        }
    }
    setNenhumaPrestacaoEncontrada(valor) {
        if (valor !== undefined && valor.length === 0) {
            return CONSTANTE.NENHUMA_PRESTACAO_CONTAS_ENCONTRADA;
        }
    }
    setPrestacoesExistentes(valor) {
        if (valor !== undefined && valor.length === 0) {
            return false;
        }
        return true;
    }
    setDesmarcarCheckBoxSelecionarTodos() {
        document.getElementById('inputChecarTodos').checked = false;
    }
    setDesmarcarCheckBoxSelecionarTodos() {
        document.getElementById('inputChecarTodos').checked = false;
    }
    setDesmarcarCheckBox(className, disable, dispatch) {
        var elements = document.getElementsByName(className);
        if (dispatch !== undefined) {
            this.setLimparCheckBoxSelecionadoAction(dispatch);
        }
        for (var i = 0; i < elements.length; i++) {
            var data = elements[i].value;
            if (disable) {
                this.setSelecionarCheckBoxAction(data, dispatch, i);
            } else {
                document.getElementById('observacao_aprovacao' + i).disabled = true;
            }
            elements[i].checked = disable;
        }

    }
    setLimparCheckBoxSelecionadoAction(dispatch) {
        dispatch({
            type: Controle.getLimparCheckBoxSelecionadoControl()
        });
    }
    setToggleCheckBoxAllAction(chequed, dispatch) {
        dispatch({
            type: Controle.getToggleCheckAllControl(),
            payload: !chequed
        });
        this.setDesmarcarCheckBox('checkBoxPrestacaoConta', !chequed, dispatch);
    }
    setSelecionarCheckBoxAction(valor, dispatch, idx, checkedAll) {
        if (document.getElementById('checkbox_prestacao' + idx).checked === true && checkedAll !== undefined) {
            document.getElementById('observacao_aprovacao' + idx).disabled = false;
        } else if (document.getElementById('checkbox_prestacao' + idx).checked === false && checkedAll !== undefined) {
            document.getElementById('observacao_aprovacao' + idx).disabled = true;
        } else {
            document.getElementById('observacao_aprovacao' + idx).disabled = false;
        }
        dispatch({
            type: Controle.getSelecionarCheckBoxControl(),
            payload: JSON.parse(valor)
        });
    }
    executarLimpezaDeFormulario() {
        controls.map((control) => {
            try {
                if (control.type === 'input') {
                    document.getElementById(control.id).value = '';
                } else if (control.type === 'select') {
                    document.getElementById(control.id).options[control.index].selected = 'selected';
                } else if (control.type == 'SelectReact') {
                    document.getElementById(control.id).options[control.index].selected = 'selected';
                } else if (control.type === 'inputMaskReact') {
                    //document.getElementById(control.id).value = '';
                    //console.log("VALOR DO INPUT:::" + document.getElementById(control.id).value, document.getElementById(control.id))
                }
            } catch (err) {
            }
        });
    }
    getListaPrestacaoContaActionCSV(filtro, dispatch) {
        let conta = CONTA_SELECIONADA.OBJETO();
        let pessoaTitularId = conta.pessoaTitularId;

        let url = URL_GET_PRESTACAO_CONTA(pessoaTitularId,
            this._montarQueryString(filtro)
        );
        this.setLoadingAction(dispatch);
        Axios
            .get(url, HEADER_API())
            .then((response) => {
                if (response.data !== undefined) {
                    dispatch({
                        type: Controle.getListaPrestacaoContaCSVControl(),
                        payload: response.data.resultado
                    });
                }
                this.setLoadingAction(dispatch);
            }).catch((erro) => {
                this.setLoadingAction(dispatch);
                if (erro.response.data !== undefined) {
                    dispatch({
                        type: Controle.setMsgErroExportarCSV(),
                        payload: erro.response.data.erros[0].mensagem
                    });
                }
            });
    }
    preencherComboClienteProjeto(dispatch) {
        let conta = CONTA_SELECIONADA.OBJETO();
        let pessoaTitularId = conta.pessoaTitularId;

        Axios
            .get(URL_GET_PRESTACAO_CONTA_PROJETO(pessoaTitularId), HEADER_API())
            .then((responseGetParametrosConta) => {
                dispatch({
                    type: Controle.getListaClienteProjetoControl(),
                    payload: responseGetParametrosConta.data
                });
            })
            .catch((erro) => {
                console.log('erro ao preencher o combo cliente projeto ', erro.response);
            });
    }
    preencherComboClassificacao(dispatch) {
        let conta = CONTA_SELECIONADA.OBJETO();
        let pessoaTitularId = conta.pessoaTitularId;

        Axios
            .get(URL_GET_PRESTACAO_CONTA_CLASSIFICACAO(pessoaTitularId), HEADER_API())
            .then((responseGetParametrosConta) => {
                dispatch({
                    type: Controle.getListaClassificacaoControl(),
                    payload: responseGetParametrosConta.data,
                });
            })
            .catch((erro) => {
                console.log('erro ao preencher o combo classificação ', erro.response);
            });
    }
    async setListaPrestacaoContaAction(queryParams, dispatch, atualizarListaDeParametrosClienteProjeto) {
        this.setLoadingAction(dispatch);

        const prestacoesRecuperadas = await getPrestacaoDeContasOrExtrato(queryParams);
        if (!prestacoesRecuperadas.error) {
            dispatch({
                type: Controle.getUltimaChavePaginacaoControl(),
                payload: prestacoesRecuperadas.ultimaChave || ''
            });
            dispatch({
                type: Controle.getListaPrestacaoContaRegistrosControl(),
                payload: prestacoesRecuperadas.quantidadeTotalRegistros || 0
            });
            dispatch({
                type: Controle.getListaPrestacaoContaControl(),
                payload: prestacoesRecuperadas.registros || []
            });
        } else {
            if (prestacoesRecuperadas && prestacoesRecuperadas.data && prestacoesRecuperadas.data.codigo) {
                if (prestacoesRecuperadas.data.codigo === '9999') {
                    dispatch({
                        type: Controle.getListaPrestacaoContaControl(),
                        payload: []
                    });
                }
            }
            dispatch({
                type: Controle.getResponseControl(),
                payload: prestacoesRecuperadas.data
            });
            let error = '';
            if (prestacoesRecuperadas !== undefined
                && prestacoesRecuperadas.data !== undefined) {
                error = prestacoesRecuperadas;
            }
            dispatch({
                type: Controle.getListaPrestacaoContaControlErro(),
                payload: error
            });
        }

        /*  let conta = reactLocalStorage.get(LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA) ? JSON.parse(reactLocalStorage.get(LABEL_COMUNS.CONTA_DEPARTAMENTO_SELECIONADA)) : reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA);
         let pessoaTitularId = conta.pessoaTitularId
         let contaDigitalSelecionada = conta.id;
         let tamanhoPagina = filtro.totalElementos;
 
         if (filtro === null)
             filtro = { "status": TipoOperacao._PENDENTE };queryParams
 
         filtro.contaPaiId = contaDigitalSelecionada;
 
         if (tamanhoPagina)
             filtro.tamanhoPagina = tamanhoPagina
 
         this.preencherComboClienteProjeto(pessoaTitularId, dispatch);
         this.preencherComboClassificacao(pessoaTitularId, dispatch);
         let url = URL_GET_PRESTACAO_CONTA(pessoaTitularId, this._montarQueryString(filtro));
         this.setLoadingAction(dispatch);
 
         Axios
             .get(url, HEADER_API())
             .then((response) => {
                 this.setLimparResponse(dispatch);
                 dispatch({
                     type: Controle.getUltimaChavePaginacaoControl(),
                     payload: response.data.ultimaChave !== undefined ? response.data.ultimaChave : ''
                 });
                 let registros = [];
                 if (response.data.resultado) {
                     registros = response.data.resultado;
                 }
                 dispatch({
                     type: Controle.getQuantidadesRegistro(),
                     payload: {
                         quantidadeTotalRegistros: response.data.quantidadeTotalRegistros !== undefined ? response.data.quantidadeTotalRegistros : '',
                         totalElementos: response.data.totalElementos !== undefined ? response.data.totalElementos : ''
                     }
                 });
                 dispatch({
                     type: Controle.getListaPrestacaoContaControl(),
                     payload: registros
                 });
                 this.setLoadingAction(dispatch);
             }).catch((prestacaoContaErro) => {
                 let error = "";
                 if (prestacaoContaErro.response && prestacaoContaErro.response.data && prestacaoContaErro.response.data.codigo) {
                     if (prestacaoContaErro.response.data.codigo === "9999") {
                         dispatch({
                             type: Controle.getListaPrestacaoContaControl(),
                             payload: []
                         });
                     }
                 }
                 dispatch({
                     type: Controle.getResponseControl(),
                     payload: prestacaoContaErro.response
                 });
                 if (prestacaoContaErro.response !== undefined
                     && prestacaoContaErro.response.data !== undefined) {
                     error = prestacaoContaErro.response;
                 }
                 dispatch({
                     type: Controle.getListaPrestacaoContaControlErro(),
                     payload: error
                 });
                 this.setLoadingAction(dispatch);
             }) */
        this.setLoadingAction(dispatch);
    }
    setShowDialogSucessoAction(valor, dispatch) {
        dispatch({
            type: Controle.getShowMsgDialogSucessoControl(),
            payload: valor
        });
    }
    setMsgDialogSucessoAction(valor, dispatch) {
        dispatch({
            type: Controle.getMsgDialogSucessoControl(),
            payload: valor
        });
    }
    setShowMsgDialogErrosAction(valor, dispatch) {
        dispatch({
            type: Controle.getShowMsgDialogErrosControl(),
            payload: valor
        });
    }
    setLoadingAction(dispatch) {
        dispatch({
            type: Controle.getLoadingControl(),
        });
    }
    setViewImagensAction(valor, dispatch) {
        this.toggleTelaDeImagensAction(dispatch);
        dispatch({
            type: Controle.getViewImagensControl(),
            payload: this.setTratarImagemPreAssinada(valor, dispatch) /*this.setBuscarUrlsAssinadas(valor, dispatch)*/
        });
    }

    setTratarImagemPreAssinada(valor, dispatch) {
        let listaRetorno = [];
        valor && valor.map((img) => {
            let imagem = {
                'tipo': (img.nomeOriginal || '').split('.')[1],
                'urlComprovante': img.urlImagem
            };
            listaRetorno.push(imagem);
        });
        return listaRetorno;
    }
    setPreencherObservacaoAction(event, valor, dispatch) {
        valor.descricaoAprovacao = event.target.value;
        dispatch({
            type: Controle.getPreencherObservacaoControl(),
            payload: valor
        });
    }
    setDefinirFiltrosAction(filtro, dispatch) {
        this.liparMsgErroFiltro(filtro, dispatch);
        dispatch({
            type: Controle.getResetarUltimaChaveControl(),
            payload: filtro
        });
        dispatch({
            type: Controle.getDefinirFiltrosControl(),
            payload: filtro
        });
    }
    liparMsgErroFiltro(filtro, dispatch) {
        if (filtro.chave === 'dataInicial') {
            dispatch({
                type: Controle.getLimparDataInicialErroControl(),
                payload: null
            });
        }

        if (filtro.chave === 'dataFinal') {
            dispatch({
                type: Controle.getLimparDataFinalErroControl(),
                payload: null
            });
        }
    }
    setLimparFiltrosAction(dispatch) {
        this.setLimparResponse(dispatch);
        dispatch({
            type: Controle.getLimparFiltrosControl(),
        });
        this.executarLimpezaDeFormulario();
        this.limparPrestacaoContaAction(dispatch);
        this.setClickBtnLimparAction(dispatch, true);
    }
    setLimparResponse(dispatch) {
        var resp = [
            {
                'codigo': '',
                'erros': [
                    {
                        'mensagem': null,
                        'campo': null
                    }
                ]
            }
        ];

        dispatch({
            type: Controle.getResponseControl(),
            payload: resp
        });
    }
    setDefinirTipoOperacaoAction(tipoOperacao, dispatch) {
        dispatch({
            type: Controle.getDefinirTipoOperacaoControl(),
            payload: tipoOperacao,
        });
    }
    setDefinirMessagemOperacaoAction(tipoOperacao, dispatch) {
        statusTipoOperacaoSelecionado = tipoOperacao;
        dispatch({
            type: Controle.getDefinirMessagemOperacaoControl(),
            payload: tipoOperacao === TipoOperacao._APROVAR ? EMesageErroConfirm._msgPadraoAprovado : EMesageErroConfirm._msgPadraoReprovado
        });
    }
    setAprovarPrestacaoContaAction(valores, dispatch) {
        this.executarWebMetodAction(valores, dispatch);
    }
    processarPrestacaoContaAction(valores, dispatch) {
        this.setAprovarPrestacaoContaAction(valores, dispatch);
    }
    limparPrestacaoContaAction(dispatch) {
        dispatch({
            type: Controle.getLimparPrestacaoContaControl()
        });
    }
    limparPrestacaoContaBindAction(dispatch) {
        dispatch({
            type: Controle.getLimparPrestacaoContaBindControl()
        });
    }
    bindListaPrestacaoContasAction(filtro, dispatch) {

        dispatch({
            type: Controle.getToggleCheckAllControl(),
            payload: false
        });

        this.setListaPrestacaoContaAction(filtro, dispatch, false);
        dispatch({
            type: Controle.getBindPrestacaoContaControl(),
        });
        this.limparPrestacaoContaAction(dispatch);
        this.setDesmarcarCheckBox('checkBoxPrestacaoConta', false);
        this.setClickBtnLimparAction(dispatch, false);
    }
    abrirTelaDeConfirmacaoAction(tipoOperacao, valor, dispatch) {
        if (tipoOperacao !== null) {
            this.setDefinirTipoOperacaoAction(tipoOperacao, dispatch);
        }
        this.setDefinirMessagemOperacaoAction(tipoOperacao, dispatch);
        dispatch({
            type: Controle.getAbrirTelaDeConfirmacaoControl(),
            payload: valor
        });
    }
    visualizarImagem(valor, dispatch) {
        dispatch({
            type: Controle.getVisualizarImagemControl(),
            payload: valor
        });
    }

    // patch prestação contas
    executarWebMetodAction(valores, dispatch) {
        let msg = {
            'info': '',
            'decricao': ''
        };
        let promises = [];
        this.setLimparMsgErroPrestacaoAction(dispatch);
        valores.map((prestacao) => {
            if (prestacao.contaDigitalId === null
                && prestacao.clienteProjetoId === null
                && prestacao.status === null) return;
            let obj = {
                'status': statusTipoOperacaoSelecionado,
                'descricaoAprovacao': prestacao.descricaoAprovacao
            };
            let url = URL_PRESTACAO_CONTA_ID(reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA).pessoaTitularId, prestacao.id);
            promises
                .push(Axios.patch(url, JSON.stringify(obj), HEADER_API())
                    .then(
                        (response) => {
                            // this.setReloadAction(dispatch, true);
                            return null;
                        })
                    .catch((erro) => {
                        var msgErro = '';

                        if (erro && erro.response && erro.response.data && erro.response.data.erros[0] && erro.response.data.erros[0].mensagem) {
                            msgErro = erro.response.data.erros[0].mensagem;
                        }

                        var response =
                        {
                            dataHora: this.setValidarInfo(formatarDataHoraBR(prestacao.dadosExtrato.dataHora)),
                            valor: this.setValidarInfo(formataMoeda(prestacao.dadosExtrato.valor)),
                            resultado: this.setValidarInfo(msgErro)
                        };
                        return response;
                    }));
        });
        this.setExecutarPromises(promises, dispatch);
    }
    setLimparMsgErroPrestacaoAction(dispatch) {
        dispatch({
            type: Controle.getLimparMsgErroPrestacaoControl(),
        });
    }
    setClickBtnLimparAction(dispatch, valor) {
        dispatch({
            type: Controle.getClickBtnLimparControl(),
            payload: valor
        });
    }
    setResetarUltimaChaveTamanhoPaginaAction(dispatch) {
        /*dispatch({
            type:Controle.getResetarUltimaChaveTamanhoPaginaControl(),
        });*/
    }
    setHelpBlockGrideCssMsgErro(valor) {
        if (valor === EMesagemErroHelpBlock._ERRO) {
            return 'helpBlock errorPrestacaoConta';
        } else {
            return 'helpBlock sucessPrestacaoConta';
        }
    }
    setHelpBlockGrideMsgErro(valor) {
        if (valor !== undefined) {
            return valor;
        }
    }
    toggleTelaDeImagensAction(dispatch) {
        dispatch({
            type: Controle.getToggleTelaDeImagensControl(),
        });
    }
    _montarQueryString(filtro) {
        let queryString = '?';
        if (filtro !== null) {
            if (filtro.contaPaiId)
                queryString = queryString + 'contaPaiId=' + filtro.contaPaiId + '&';

            if (filtro.clienteProjetoId)
                queryString = queryString + 'projeto=' + filtro.clienteProjetoId + '&';

            if (filtro.classificacao)
                queryString = queryString + 'classificacao=' + filtro.classificacao + '&';

            if (filtro.contaCartaoId)
                queryString = queryString + 'contaCartaoId=' + filtro.contaCartaoId + '&';

            if (filtro.cartaoId)
                queryString = queryString + 'cartaoId=' + filtro.cartaoId + '&';

            if (filtro.documentoPortador)
                queryString = queryString + 'documentoPortador=' + filtro.documentoPortador + '&';

            if (filtro.periodo) {
                var rangeData = null;

                if (filtro.periodo === TipoPeriodo._MES_ATUAL.NOME) {
                    rangeData = this.buscarRangeDataPeriodoMesAnteriorOuAtual('mesAtual');
                }

                if (filtro.periodo === TipoPeriodo._MES_ANTERIOR.NOME) {
                    rangeData = this.buscarRangeDataPeriodoMesAnteriorOuAtual('mesAnterior');
                }

                if (rangeData !== null) {
                    queryString = queryString + 'dataInicial=' + rangeData.dataDe + '&' + 'dataFinal=' + rangeData.dataAte + '&';
                }

            } else {
                
                if (filtro.dataInicial){
                    queryString = queryString + 'dataInicial=' + filtro.dataInicial + '&';
                }

                if (filtro.dataFinal) {
                    queryString = queryString + 'dataFinal=' + filtro.dataFinal + '&';
                }
            }

            if (filtro.status && filtro.status !== TipoOperacao._TODOS) {
                queryString = queryString + 'status=' + filtro.status + '&';
            }

            if (filtro.tamanhoPagina !== undefined && filtro.tamanhoPagina !== null)
                queryString = queryString + 'tamanhoPagina=' + filtro.tamanhoPagina + '&';

            if (filtro.ultimaChave !== undefined && filtro.ultimaChave !== null)
                queryString = queryString + 'ultimaChave=' + filtro.ultimaChave + '&';

            if (filtro.produtoId !== undefined && filtro.produtoId !== null)
                queryString = queryString + 'produtoId=' + filtro.produtoId + '&';

        }
        queryString = queryString.substring(0, (queryString.length - 1));
        return queryString;
    }
    getCssSinalizador(tipoOperacao) {
        let label = 'label ';

        const { labelCss } = COMBO.STATUS.PRESTACAO_DE_CONTAS.find(({ value }) => value === tipoOperacao) || {};
        if (labelCss) {
            label = labelCss;
        }

        return label;
    }
    limparDadosCSV() {
        return function(dispatch) {
            dispatch(
                {
                    type: Controle.limparDadosCSV(),
                    payload: [],
                }
            );
        };
    }
    exibirListaDeResponse(valor) {
        var responses = [];
        if (valor !== undefined) {
            for (var i = 0; i < valor.length; i++) {
                if (valor[i].erros !== undefined) {
                    valor[i].erros.map((msg, campo) => {
                        responses.push(msg.mensagem);
                    });
                }
            }
        }
        return responses;
    }
    exibirMensagemDeResponse(valor, campo) {
        let result = '';
        try {
            for (var i = 0; i < valor.erros.length; i++) {
                if (valor.erros[i].campo === campo) {
                    result = valor.erros[i].mensagem;
                }
            }
        } catch (err) { }

        /*valor.erros.map((msg, index) => {
            if (msg.campo === campo) {
                result = msg.mensagem;
            }
        })*/
        return result;
    }
    exibirMensagemDeResponseStatus(valor, campo) {
        let result = '';
        if (valor.data === undefined || valor.data.erros === undefined) return result;
        valor.data.erros.map((msg, index) => {
            if (msg.campo === campo) {
                result = msg.mensagem;
            }
        });
        return result;
    }
    setValidarInfo(valor) {
        if (valor !== undefined) {
            return valor;
        } else {
            return '';
        }
    }

    buscarRangeDataPeriodoMesAnteriorOuAtual(mesPeriodo) {
        let dateNow = moment(), dateEnd = moment();
        let dateStart = moment().date(1);
        if (mesPeriodo !== 'mesAtual') {
            let dateMonthBefore = dateNow.subtract(1, 'months');
            dateStart = dateMonthBefore.date(1).clone();
            dateEnd = dateMonthBefore.date(dateMonthBefore.daysInMonth()).clone();
        }
        return { dataDe: dateStart.format('YYYY-MM-DD'), dataAte: dateEnd.format('YYYY-MM-DD') };
    }

    diasNoMes(mes, ano) {
        var data = new Date(ano, mes, 0);
        return data.getDate();
    }
    obterMsgDialogSucessoClassName(valor) {
        if (valor.indexOf(TipoStatusHeaderMsg._SUCESSO) !== -1) {
            return 'msg-dialog-sucesso';
        }
        return 'msg-dialog-falha';
    }
    setReloadAction(dispatch, valor) {
        dispatch({
            type: Controle.getReloadControl(),
            payload: valor
        });
    }
    setFiltro(filtro, dispatch) {
        dispatch({
            type: Controle.setFiltro(),
            payload: filtro
        });
    }

    getDownloadImagem(url, nome) {
        url.IMAGEM(function(err, name, eventEmitter) {
            let buffers = [];
            eventEmitter.on('data', function(chunk) {
                buffers.push(chunk);
            });
            eventEmitter.once('end', function() {
                //connection.detach();
                let buffer = Buffer.concat(buffers);
                var base64 = buffer.toString('base64');
                return base64;
            });
        });
    }

}
