import { Card } from 'primereact/card';
import moment from 'moment';
import React, { Fragment } from 'react';

import { formataMoedaReal } from '../../util/numeros/numeroUtil';
import { formataNumeroCodigoDeBarras } from '../../util/formata-string';
import { formatarCampoValor } from '../../util/extrato/extrato-util';
import { formatarDataEHoraBR } from '../../util/data/dataUtil';
import { LABEL_COMUNS, COMPROVANTE_OPERACOES, COMPROVANTE_TIPO_OPERACAO, COMPROVANTE_CANAIS, TIPO_DOCUMENTO } from '../../util/contantes-comuns/constantes-comuns';
import FooterBotoesModalComponent from '../modal-component/footer-botoes-modal-component';
import Grid from '../grid/grid';
import GridRow from '../grid/grid-row';
import ModalComponent from '../modal-component/modal-component';
import { CPF, CNPJ } from 'cpf_cnpj';

const ComprovanteModal = ({ exibirModal, onClose, dadosComprovante, downloadComprovantePDF, contextoBppPag }) => {
    const corpoModalDetalheExtrato = () => {
        const tipoComprovante = COMPROVANTE_TIPO_OPERACAO.find(e => e.value === dadosComprovante.contextoTemplate.transacao) || '';
        const operacaoComprovante = COMPROVANTE_OPERACOES.find(e => e.value === dadosComprovante.operacao) || '';
        const dataCriacao = moment(dadosComprovante.dataCriacao, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY [às] HH:mm[h]');
        const numeroDocumentoBeneficiario = dadosComprovante.contextoTemplate.tipoDocumentoBeneficiario === TIPO_DOCUMENTO.CNPJ.value ? CNPJ.format(dadosComprovante.contextoTemplate.numeroDocumentoBeneficiario) : CPF.format(dadosComprovante.contextoTemplate.numeroDocumentoBeneficiario);
        const canal = COMPROVANTE_CANAIS.some(({ value }) => dadosComprovante.contextoTemplate.canal === value) ? COMPROVANTE_CANAIS.find(({ value }) => dadosComprovante.contextoTemplate.canal === value).label : 'BPP';
        return (
            <div>
                <Card style={{ backgroundColor: '#f5f5f5', marginBottom: 20 }}>
                    <div className="alinhamento-centro">
                        <span className="texto-detalhe-extrato texto-extrato-verde">{formataMoedaReal(dadosComprovante.contextoTemplate.valorOperacao - (dadosComprovante.contextoTemplate.valorTarifa || 0))}</span>
                        <br />
                        <strong>{tipoComprovante.label} - {operacaoComprovante.label}</strong>
                    </div>
                </Card>

                <GridRow>
                    <Grid cols="10 10 10 10">
                        <strong>{`Dados da conta ${tipoComprovante.value !== 'aprovacao' ? 'a ser' : ''} debitada:`}</strong>
                    </Grid>
                </GridRow>

                <GridRow>
                    <Grid cols="1 1 1 1" />
                    <Grid cols="5 5 5 5">
                        {dadosComprovante.contextoTemplate.tipoPessoaContaOrigem === 'PJ' && <strong>Razão Social:</strong>}
                        {dadosComprovante.contextoTemplate.tipoPessoaContaOrigem === 'PF' && <strong>Nome:</strong>}
                    </Grid>
                    <Grid cols="5 5 5 5">
                        {dadosComprovante.contextoTemplate.nomePessoaContaOrigem}
                    </Grid>
                </GridRow>

                {operacaoComprovante.value === 'TED' || operacaoComprovante.value === 'pagamentoBoletoBancario' ? (
                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Instituição financeira/pagamento:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            301 - BPP Instituição de Pagamentos
                        </Grid>
                    </GridRow>
        ) : null}

                <GridRow>
                    <Grid cols="1 1 1 1" />
                    <Grid cols="5 5 5 5">
                        <strong>Agência:</strong>
                    </Grid>
                    <Grid cols="5 5 5 5">
                        {dadosComprovante.contextoTemplate.agenciaContaOrigem}
                    </Grid>
                </GridRow>

                <GridRow>
                    <Grid cols="1 1 1 1" />
                    <Grid cols="5 5 5 5">
                        <strong>Conta:</strong>
                    </Grid>
                    <Grid cols="5 5 5 5">
                        {dadosComprovante.contextoTemplate.numeroContaOrigem}-{dadosComprovante.contextoTemplate.digitoContaOrigem}
                    </Grid>
                </GridRow>

                {operacaoComprovante.value === 'TED' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimento' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ? (
                        <Fragment>
                            <br />
                            <GridRow>
                                <Grid cols="10 10 10 10">
                                    <strong>{`Dados da conta ${tipoComprovante.value !== 'aprovacao' ? 'a ser' : ''} creditada:`}</strong>
                                </Grid>
                            </GridRow>
                        </Fragment>
          ) : null}

                {operacaoComprovante.value === 'pagamentoBoletoBancario' && (
                <Fragment>
                    <br />
                    <GridRow>
                        <Grid cols="10 10 10 10">
                            <strong>Dados do beneficiário:</strong>
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Nome/Razão Social:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.nomeBeneficiario}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>CPF/CNPJ:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {numeroDocumentoBeneficiario}
                        </Grid>
                    </GridRow>
                </Fragment>
        )}

                {operacaoComprovante.value === 'cargaCartao' && (
                <Fragment>
                    <br />
                    <GridRow>
                        <Grid cols="10 10 10 10">
                            <strong>Dados do cartão:</strong>
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>ID da operação:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.operacaoId}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Produto:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.produtoCartaoContaDestino}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>ID do cartão:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.cartaoIdContaDestino}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Nome do titular:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.nomeTitularContaDestino}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Nome do portador:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.nomePortadorContaDestino}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Valor:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {formataMoedaReal(dadosComprovante.contextoTemplate.valorOperacao)}
                        </Grid>
                    </GridRow>

                    {tipoComprovante.value === 'agendamento' || tipoComprovante.value === 'cancelamento' ? (
                        <Fragment>
                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>Data agendada:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {moment(dadosComprovante.contextoTemplate.dataAgendamento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                </Grid>
                            </GridRow>
                        </Fragment>
            ) : null}

                    {dadosComprovante.descricao && (
                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Descrição:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.descricao}
                        </Grid>
                    </GridRow>
            )}
                </Fragment>
        )}

                {operacaoComprovante.value === 'TED' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimento' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ? (
                        <GridRow>
                            <Grid cols="1 1 1 1" />
                            <Grid cols="5 5 5 5">
                                <strong>Nome do favorecido:</strong>
                            </Grid>
                            <Grid cols="5 5 5 5">
                                {dadosComprovante.contextoTemplate.nomeFavorecido}
                            </Grid>
                        </GridRow>
          ) : null}

                {operacaoComprovante.value === 'TED' && (
                <Fragment>
                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>CPF/CNPJ:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.documentoFavorecido}
                        </Grid>
                    </GridRow>
                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Instituição financeira/pagamento:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.bancoDestinoNumero} - {dadosComprovante.contextoTemplate.bancoDestinoDescricao}
                        </Grid>
                    </GridRow>
                </Fragment>
        )}

                {operacaoComprovante.value === 'TED' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimento' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ? (
                        <Fragment>
                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>Agência:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {dadosComprovante.contextoTemplate.agenciaContaDestino}
                                </Grid>
                            </GridRow>

                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>Conta:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {dadosComprovante.contextoTemplate.numeroContaDestino}-{dadosComprovante.contextoTemplate.digitoContaDestino}
                                </Grid>
                            </GridRow>
                        </Fragment>
          ) : null}

                {operacaoComprovante.value === 'TED' && (
                <GridRow>
                    <Grid cols="1 1 1 1" />
                    <Grid cols="5 5 5 5">
                        <strong>Tipo:</strong>
                    </Grid>
                    <Grid cols="5 5 5 5">
                        {dadosComprovante.contextoTemplate.tipoContaDestino.replace('_', ' ')}
                    </Grid>
                </GridRow>
        )}

                {operacaoComprovante.value === 'TED' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimento' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ? (
                        <Fragment>
                            <br />
                            <GridRow>
                                <Grid cols="10 10 10 10">
                                    <strong>Dados da transferência:</strong>
                                </Grid>
                            </GridRow>

                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>ID da operação:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {dadosComprovante.operacaoId}
                                </Grid>
                            </GridRow>

                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>Valor:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {formataMoedaReal(dadosComprovante.contextoTemplate.valorTransferencia)}
                                </Grid>
                            </GridRow>

                            {/* {(tipoComprovante.value === 'cancelamento' &&
                                operacaoComprovante.value === 'transferenciaEntreContasMovimento') ||
                                (tipoComprovante.value === 'cancelamento' &&
                                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades') ? (
                                    <GridRow>
                                        <Grid cols="1 1 1 1"></Grid>
                                        <Grid cols="5 5 5 5">
                                            <strong>Tarifa:</strong>
                                        </Grid>
                                        <Grid cols="5 5 5 5">
                                            {formataMoedaReal(dadosComprovante.contextoTemplate.valorTarifa)}
                                        </Grid>
                                    </GridRow>
                                ) : null} */}

                            {/* {(tipoComprovante.value === 'cancelamento' &&
                                operacaoComprovante.value === 'transferenciaEntreContasMovimento') ||
                                (tipoComprovante.value === 'cancelamento' &&
                                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades') ? (
                                    <GridRow>
                                        <Grid cols="1 1 1 1"></Grid>
                                        <Grid cols="5 5 5 5">
                                            <strong>Valor total:</strong>
                                        </Grid>
                                        <Grid cols="5 5 5 5">
                                            {formataMoedaReal(dadosComprovante.contextoTemplate.valorOperacao)}
                                        </Grid>
                                    </GridRow>
                                ) : null} */}

                            {tipoComprovante.value === 'agendamento' || tipoComprovante.value === 'cancelamento' ? (
                                <GridRow>
                                    <Grid cols="1 1 1 1" />
                                    <Grid cols="5 5 5 5">
                                        <strong>Data agendada:</strong>
                                    </Grid>
                                    <Grid cols="5 5 5 5">
                                        {moment(dadosComprovante.contextoTemplate.dataAgendamento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                    </Grid>
                                </GridRow>
              ) : null}

                            {dadosComprovante.descricao && (
                            <GridRow>
                                <Grid cols="1 1 1 1" />
                                <Grid cols="5 5 5 5">
                                    <strong>Descrição:</strong>
                                </Grid>
                                <Grid cols="5 5 5 5">
                                    {dadosComprovante.descricao}
                                </Grid>
                            </GridRow>
              )}
                        </Fragment>
          ) : null}

                {operacaoComprovante.value === 'pagamentoBoletoBancario' && (
                <Fragment>
                    <br />
                    <GridRow>
                        <Grid cols="10 10 10 10">
                            <strong>Dados do pagamento:</strong>
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>ID da operação:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.operacaoId}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Código de barras:</strong>
                        </Grid>
                        <Grid cols="6 6 6 6">
                            {dadosComprovante.contextoTemplate.codigoBarras}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Valor:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.contextoTemplate.valorOperacao && formataMoedaReal(dadosComprovante.contextoTemplate.valorOperacao)}
                        </Grid>
                    </GridRow>

                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Data vencimento:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {moment(dadosComprovante.contextoTemplate.dataVencimentoPagamentoConta, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                        </Grid>
                    </GridRow>

                    {tipoComprovante.value === 'agendamento' || tipoComprovante.value === 'cancelamento' ? (
                        <GridRow>
                            <Grid cols="1 1 1 1" />
                            <Grid cols="5 5 5 5">
                                <strong>Data agendada:</strong>
                            </Grid>
                            <Grid cols="5 5 5 5">
                                {moment(dadosComprovante.contextoTemplate.dataAgendamento, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </Grid>
                        </GridRow>
            ) : null}

                    {dadosComprovante.descricao && (
                    <GridRow>
                        <Grid cols="1 1 1 1" />
                        <Grid cols="5 5 5 5">
                            <strong>Descrição:</strong>
                        </Grid>
                        <Grid cols="5 5 5 5">
                            {dadosComprovante.descricao}
                        </Grid>
                    </GridRow>
            )}
                </Fragment>
        )}

                <br />

                <GridRow>
                    <Grid cols="10 10 10 10"><strong>Autenticação:</strong> <span>{dadosComprovante.comprovanteId}</span></Grid>
                </GridRow>

                {operacaoComprovante.value === 'TED' && (
                <center style={{ marginTop: 20 }}>
                    {tipoComprovante.value !== 'cancelamento' ? (
                        <div className="panel-heading fundo-roxo-escuro"><strong>TED solicitada em {dataCriacao} via {canal}.</strong></div>
            ) : (
                <div className="panel-heading fundo-roxo-escuro"><strong>Cancelamento solicitado em {dataCriacao} via {canal}.</strong></div>
            )}
                </center>
        )}

                {operacaoComprovante.value === 'TED' && (
                    <GridRow>
                        <Grid cols="10 10 10 10"><span>Esta operação está sujeita a cobrança de tarifa</span></Grid>                    
                    </GridRow>
                )}

                {operacaoComprovante.value === 'transferenciaEntreContasMovimento' ||
                    operacaoComprovante.value === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ? (
                        <center style={{ marginTop: 20 }}>
                            {tipoComprovante.value !== 'cancelamento' ? (
                                <div className="panel-heading fundo-roxo-escuro"><strong>Transferência solicitada em {dataCriacao} via {canal}.</strong></div>
              ) : (
                  <div className="panel-heading fundo-roxo-escuro"><strong>Cancelamento solicitado em {dataCriacao} via {canal}.</strong></div>
              )}
                        </center>
          ) : null}

                {operacaoComprovante.value === 'pagamentoBoletoBancario' && (
                <center style={{ marginTop: 20 }}>
                    {tipoComprovante.value !== 'cancelamento' ? (
                        <div className="panel-heading fundo-roxo-escuro"><strong>Pagamento solicitado em {dataCriacao} via {canal}.</strong></div>
            ) : (
                <div className="panel-heading fundo-roxo-escuro"><strong>Cancelamento solicitado em {dataCriacao} via {canal}.</strong></div>
            )}
                </center>
        )}

                {operacaoComprovante.value === 'cargaCartao' && (
                <center style={{ marginTop: 20 }}>
                    {tipoComprovante.value !== 'cancelamento' ? (
                        <div className="panel-heading fundo-roxo-escuro"><strong>Carga solicitada em {dataCriacao} via {canal}.</strong></div>
            ) : (
                <div className="panel-heading fundo-roxo-escuro"><strong>Cancelamento solicitado em {dataCriacao} via {canal}.</strong></div>
            )}
                </center>
        )}
            </div>
        );
    };

    return (
        <ModalComponent
      bsSize="lg"
      contextoBppPag={contextoBppPag}
      exibirModal={exibirModal}
      header={LABEL_COMUNS.COMPROVANTE}
      body={corpoModalDetalheExtrato()}
      footer={
          <FooterBotoesModalComponent
          btn2Label={LABEL_COMUNS.OK}
          contextoBppPag={contextoBppPag}
          btn2OnClick={onClose}
          btnPDFLabel={<strong>{LABEL_COMUNS.SALVAR_PDF} </strong>}
          btnPDFOnClick={() => downloadComprovantePDF(dadosComprovante)}
        />
      }
    />
    );
};

export default ComprovanteModal;
