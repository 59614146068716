import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import { Modal, Button, ControlLabel, ButtonToolbar, MenuItem, Glyphicon, Dropdown, Alert, FormControl, Carousel, FormGroup, HelpBlock, Fade, Well, Tooltip, OverlayTrigger } from 'react-bootstrap';
import Moment from 'moment';
import * as moment from 'moment';
import GerenciarPrestacaoContaAction from './gerenciar-prestacao-conta-action';
import { EMesageErroConfirm, TipoPeriodo, PAGINACAO, PAGINACAO_CSV, STATUS_VALIDACAO_COMPONENTES } from './gerenciar-prestacao-conta-constante';
import './gerenciar-prestacao-conta.css';
import InputMask from 'react-input-mask';
import If from '../../componentes-uteis/if/if';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { formataMoeda } from '../../util/numeros/numeroUtil';
import { COMBO, LABEL_COMUNS, CONTA_SELECIONADA, MASCARA_CAMPOS, ERROS_FILTROS_PRESTACAO, STATUS } from '../../util/contantes-comuns/constantes-comuns';
import { CONSTANTE } from './gerenciar-prestacao-conta-constante';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import { formataMoedaComSimboloESinalNegacao } from '../../util/numeros/numeroUtil';
import saveAs from 'file-saver';
import { podeConsultar, podeCriar, podeAprovar } from '../../../reducer/outorga/outorga-operacao-reducer';
import PrestacaoContaViewModel from './prestacao-conta-view-model';
import { dataBRParaMoment } from '../../util/data/dataUtil';


let filtroPrestacao = {
    'chave': '',
    'valor': ''
};

let gerenciarPrestacaoAction = new GerenciarPrestacaoContaAction();

const tooltipExportar = (
    <Tooltip id="tooltipExportar">
        Exportar para .CSV
    </Tooltip>
);

const tooltipVisualizar = (
    <Tooltip id="tooltipVisualizar">
        Visualizar Comprovante
    </Tooltip>
);
const tooltipVisualizarImagem = (
    <Tooltip id="tooltipVisualizarImagem">
        Visualizar comprovante no tamanho real em uma nova aba do navegador
    </Tooltip>
);
class GerenciarPrestacaoConta extends Component {

    constructor(props) {
        super(props);
        this.state = { cartaoId: '', dataInicial: '', dataFinal: '', status: STATUS.PRESTACAO_DE_CONTAS.AGUARDANDO_APROVACAO, erroFiltro: false, errorSpan: '', exibirSelecionarTodos: false };
    }

    componentWillMount() {
        let queryString = this.props.stateGerenciarPrestacaoContaReducer.queryString;
        gerenciarPrestacaoAction.setLimparFiltrosAction(this.props.dispatch);
        queryString.status = STATUS.PRESTACAO_DE_CONTAS.AGUARDANDO_APROVACAO;
        queryString.produtoId = this.props.produtosIdsBppCorp;
        queryString.tamanhoPagina = PAGINACAO;
        queryString.ultimaChave = null;
        queryString.totalElementos = 0;
        gerenciarPrestacaoAction.setFiltro(queryString, this.props.dispatch);
        gerenciarPrestacaoAction.setListaPrestacaoContaAction(queryString, this.props.dispatch, true);
        gerenciarPrestacaoAction.preencherComboClienteProjeto(this.props.dispatch);
        gerenciarPrestacaoAction.preencherComboClassificacao(this.props.dispatch);


    }
    exibirLoading() {
        if (this.props.stateGerenciarPrestacaoContaReducer.loading) {
            return (<div className="alinhamento-centro"><LoadingFenix /></div>);
        } else {
            return (
                <div>{
                    gerenciarPrestacaoAction.setNenhumaPrestacaoEncontrada(
                        this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta
                    )
                }</div>
            );
        }
    }
    exibirIconeExcel = () => {
        if (this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta.length > 0) {
            if (this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoContaCSV && this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoContaCSV.length > 0) {
                downloadCSV({ filename: 'Prestação de Conta.csv' }, this.props);
                this.props.dispatch(gerenciarPrestacaoAction.limparDadosCSV());
            }
            return (
                <OverlayTrigger placement="top" overlay={tooltipExportar}>
                    <div className="posicao_icone">
                        <Button className="btn btn-success btn-xs"
                            onClick={
                                () => {
                                    let queryString = this.props.stateGerenciarPrestacaoContaReducer.queryString;
                                    queryString.tamanhoPagina = PAGINACAO_CSV;
                                    queryString.ultimaChave = null;
                                    queryString.produtoId = this.props.produtosIdsBppCorp;
                                    gerenciarPrestacaoAction.getListaPrestacaoContaActionCSV(queryString, this.props.dispatch);
                                }}>
                            <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                        </Button>
                    </div>
                </OverlayTrigger>
            );
        }
    }
    procurarErroPorCampo(erros, campoRecebido) {
        return erros.find(({ campo }) => campo === campoRecebido) && erros.find(({ campo }) => campo === campoRecebido).mensagem;
    }

    removeValidacoes(status) {
        if (status !== STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO) {
            this.setState({ erroFiltro: false, errorSpan: '' });
        }
    }

    validaCampos() {
        let erro = undefined;
        if (this.state.status === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO) {
            if (!(this.state.cartaoId) || !(this.state.dataInicial) || !(this.state.dataFinal)) {
                erro = true;
                this.setState({ erroFiltro: true });
            }
            else if (this.state.dataInicial || this.state.dataFinal) {
                erro = this.verificaErroIntervaloData();
            }
        }
        if (!erro) {
            let exibirSelecionarTodos = true;
            if (this.state.status === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO) {
                exibirSelecionarTodos = false;
            }
            this.setState({ erroFiltro: false, errorSpan: false, exibirSelecionarTodos: exibirSelecionarTodos });
            this.gerenciaLista();
        }
    }

    verificaErroIntervaloData() {
        if (this.state.dataInicial === '' || this.state.dataFinal === '') {
            return true;
        } else {
            let dataInicial = dataBRParaMoment(this.state.dataInicial);
            let dataFinal = dataBRParaMoment(this.state.dataFinal);
            if (dataInicial != 'Invalid date' && dataFinal != 'Invalid date') {
                let daysDiff = dataFinal.diff(dataInicial, 'days');
                if (daysDiff > 60) {
                    this.setState({ errorSpan: ERROS_FILTROS_PRESTACAO.INTERVALO_TEMPO, erroFiltro: true });
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }
    }

    gerenciaLista() {
        let queryString = this.props.stateGerenciarPrestacaoContaReducer.queryString;
        queryString.status = queryString.status || STATUS.PRESTACAO_DE_CONTAS.AGUARDANDO_APROVACAO;
        queryString.tamanhoPagina = PAGINACAO;
        queryString.ultimaChave = null;
        queryString.totalElementos = 0;
        queryString.produtoId = this.props.produtosIdsBppCorp;
        gerenciarPrestacaoAction.bindListaPrestacaoContasAction(queryString, this.props.dispatch);
    }

    render() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        if (this.props.stateGerenciarPrestacaoContaReducer.reload) {
            let queryString = this.props.stateGerenciarPrestacaoContaReducer.queryString;
            queryString.tamanhoPagina = PAGINACAO;
            queryString.ultimaChave = null;
            queryString.produtoId = this.props.produtosIdsBppCorp;
            gerenciarPrestacaoAction.bindListaPrestacaoContasAction(queryString, this.props.dispatch);
            gerenciarPrestacaoAction.setReloadAction(this.props.dispatch, false);
        }
        return (
            <div>
                <div id="filtro_prestcontas">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.CONTA_DIGITAL} - {contaSelecionada.apelido} - {contaSelecionada.agencia} / {contaSelecionada.numero + '-' + contaSelecionada.digito}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_filtro">
                                                <div className="form-group">
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <ControlLabel>Data Início</ControlLabel>
                                                        <FormGroup validationState={this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataInicial') ? STATUS_VALIDACAO_COMPONENTES.ERRO : STATUS_VALIDACAO_COMPONENTES.NULL}>
                                                            <InputMask className="form-control mensagens-erro" placeholder="DD/MM/AAAA" mask={'99/99/9999'} maskChar=" "
                                                                id="dataDe" name="dataDe" style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }}
                                                                onKeyPress={
                                                                    (e) => { gerenciarPrestacaoAction.setMudarFocus(e, 'dataAte'); }
                                                                }
                                                                value={this.state.dataInicial} onChange={(e) => {
                                                                    this.setState({ dataInicial: e.target.value });
                                                                    filtroPrestacao.chave = 'dataInicial';
                                                                    filtroPrestacao.valor = e.target.value;
                                                                    gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                }}
                                                            />
                                                            {this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataInicial') ? <span className="mensagens-erro-span" role="alert">{this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataInicial')}</span> : null}
                                                            {this.state.erroFiltro && this.state.dataInicial === '' ? <span className="mensagens-erro-span" role="alert">{ERROS_FILTROS_PRESTACAO.CAMPO_OBRIGATORIO}</span> : false}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <ControlLabel>Data Fim</ControlLabel>
                                                        <FormGroup validationState={this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataFinal') ? STATUS_VALIDACAO_COMPONENTES.ERRO : STATUS_VALIDACAO_COMPONENTES.NULL}>
                                                            <InputMask
                                                                className="form-control mensagens-erro" mask={'99/99/9999'} maskChar=" " id="dataAte" name="dataAte"
                                                                style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} placeholder="DD/MM/AAAA"
                                                                onKeyPress={
                                                                    (e) => { gerenciarPrestacaoAction.setMudarFocus(e, 'status'); }
                                                                }
                                                                value={this.state.dataFinal}
                                                                onChange={(e) => {
                                                                    this.setState({ dataFinal: e.target.value });
                                                                    filtroPrestacao.chave = 'dataFinal';
                                                                    filtroPrestacao.valor = e.target.value;
                                                                    gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                }}
                                                            />
                                                            {this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataFinal') ? <span className="mensagens-erro-span" role="alert">{this.procurarErroPorCampo(this.props.stateGerenciarPrestacaoContaReducer.errosNaBuscaDasPrestacoesDeContas, 'dataFinal')}</span> : null}
                                                            {this.state.erroFiltro && this.state.dataFinal === '' ? <span className="mensagens-erro-span" role="alert">{ERROS_FILTROS_PRESTACAO.CAMPO_OBRIGATORIO}</span> : false}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                        <ControlLabel>{LABEL_COMUNS.STATUS}</ControlLabel>
                                                        <FormGroup validationState={gerenciarPrestacaoAction.exibirMensagemDeResponse(this.props.stateGerenciarPrestacaoContaReducer.response, 'status') !== '' ? 'error' : null} >
                                                            <select id="status"
                                                                name="status"
                                                                className="form-control mensagens-erro"
                                                                onKeyPress={
                                                                    (e) => {
                                                                        gerenciarPrestacaoAction.setMudarFocus(e, 'clienteprojeto');
                                                                    }
                                                                }
                                                                style={{ height: '32px', paddingTop: '2px', paddingBottom: '2px', paddingLeft: '10px' }}
                                                                onChange={
                                                                    (e) => {
                                                                        this.removeValidacoes(e.target.value);
                                                                        this.setState({ status: e.target.value });
                                                                        filtroPrestacao.chave = 'status';
                                                                        filtroPrestacao.valor = e.target.value;
                                                                        gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                    }}
                                                                defaultValue={STATUS.PRESTACAO_DE_CONTAS.AGUARDANDO_APROVACAO}>
                                                                <option value="">Selecione</option>
                                                                {COMBO.STATUS.PRESTACAO_DE_CONTAS.map(({ value, label }) => {
                                                                    return <option key={label} value={value}>{label}</option>;
                                                                })}
                                                            </select>
                                                            <HelpBlock className="helpBlock DataFim">{
                                                                gerenciarPrestacaoAction.exibirMensagemDeResponse(this.props.stateGerenciarPrestacaoContaReducer.response, 'status')
                                                            }</HelpBlock>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                        <ControlLabel>{LABEL_COMUNS.CLIENTE_PROJETO}</ControlLabel>
                                                        <FormGroup>
                                                            <FormControl
                                                                id="clienteprojeto"
                                                                componentClass="select"
                                                                inputRef={(ref) => this.nomeModificado = ref}
                                                                onChange={

                                                                    (e) => {
                                                                        filtroPrestacao.chave = 'clienteProjetoId';
                                                                        filtroPrestacao.valor = (e.target.value !== '-1' && e.target.value !== '0') ? e.target.value : null;
                                                                        gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                    }
                                                                }
                                                                onKeyPress={
                                                                    (e) => {
                                                                        gerenciarPrestacaoAction.setMudarFocus(e, 'classificacao');
                                                                    }
                                                                }
                                                                style={
                                                                    {
                                                                        height: '32px',
                                                                        paddingTop: '2px',
                                                                        paddingBottom: '2px',
                                                                        paddingLeft: '10px'
                                                                    }
                                                                }>
                                                                <option value="0">Selecione</option>
                                                                {
                                                                    this.props.stateGerenciarPrestacaoContaReducer.listaClienteProjeto
                                                                        ? this.props.stateGerenciarPrestacaoContaReducer.listaClienteProjeto.map((clienteProjeto, idx) => {
                                                                            return <option key={idx} value={clienteProjeto.nome}>{clienteProjeto.nome}</option>;
                                                                        })
                                                                        : []
                                                                }
                                                            </FormControl>
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_filtro">
                                                <div className="form-group">
                                                    <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                                        <ControlLabel>{LABEL_COMUNS.CLASSIFICACAO}</ControlLabel>
                                                        <FormGroup>
                                                            <FormControl
                                                                id="classificacao"
                                                                componentClass="select"
                                                                inputRef={(ref) => this.nomeModificado = ref}
                                                                onChange={
                                                                    (e) => {
                                                                        filtroPrestacao.chave = 'classificacao';
                                                                        filtroPrestacao.valor = (e.target.value !== '-1' && e.target.value !== '0') ? e.target.value : null;
                                                                        gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                    }
                                                                }
                                                                onKeyPress={
                                                                    (e) => {
                                                                        gerenciarPrestacaoAction.setMudarFocus(e, 'idcartao');
                                                                    }
                                                                }
                                                                style={
                                                                    {
                                                                        height: '32px',
                                                                        paddingTop: '2px',
                                                                        paddingBottom: '2px',
                                                                        paddingLeft: '10px'
                                                                    }
                                                                }>
                                                                <option value="0">Selecione</option>
                                                                {
                                                                    this.props.stateGerenciarPrestacaoContaReducer.listaClassificacao
                                                                        ? this.props.stateGerenciarPrestacaoContaReducer.listaClassificacao.map((classificacao, idx) => {
                                                                            return <option key={idx} value={classificacao.nome}>{classificacao.nome}</option>;
                                                                        }) : []
                                                                }
                                                            </FormControl>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        <ControlLabel>{LABEL_COMUNS.ID_CARTAO}</ControlLabel>
                                                        <FormGroup>
                                                            <InputMask
                                                                id="cartaoId"
                                                                className="mensagens-erro form-control"
                                                                mask={MASCARA_CAMPOS.CARTAO_ID} maskChar=""
                                                                value={
                                                                    this.props.stateGerenciarPrestacaoContaReducer.queryString.cartaoId
                                                                        ? this.props.stateGerenciarPrestacaoContaReducer.queryString.cartaoId
                                                                        : ''
                                                                }
                                                                placeholder={LABEL_COMUNS.ID_CARTAO}
                                                                style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                onChange={
                                                                    (e) => {
                                                                        this.setState({ cartaoId: e.target.value });
                                                                        filtroPrestacao.chave = 'cartaoId';
                                                                        filtroPrestacao.valor = e.target.value;
                                                                        //console.log("clicando no cartao id::", filtroPrestacao)
                                                                        gerenciarPrestacaoAction.setDefinirFiltrosAction(filtroPrestacao, this.props.dispatch);
                                                                    }}
                                                            />
                                                            {this.state.erroFiltro && this.state.cartaoId === '' ? <span className="mensagens-erro-span" role="alert">{ERROS_FILTROS_PRESTACAO.CAMPO_OBRIGATORIO}</span> : false}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">

                                                        {this.state.erroFiltro ? <span className="mensagens-erro-span" role="alert" style={{ paddingLeft: 30 }}>{this.state.errorSpan}</span> : false}
                                                        {/*  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 botoes_filtro"> */}
                                                        <div style={{ marginTop: '25px' }}>
                                                            <Button bsStyle="default"
                                                                onClick={() => {
                                                                    this.setState({ dataInicial: '', dataFinal: '', cartaoId: '', status: '', erroFiltro: undefined });
                                                                    gerenciarPrestacaoAction.setLimparFiltrosAction(this.props.dispatch);
                                                                }}
                                                                style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}>
                                                                {LABEL_COMUNS.LIMPAR}
                                                            </Button>&nbsp;&nbsp;&nbsp;
                                                            <Button bsStyle="primary" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                                                                onClick={() => { this.validaCampos(); }}>
                                                                {LABEL_COMUNS.BUSCAR}
                                                            </Button>

                                                            {/*  </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/*           <div className="row">
                                            {this.state.erroFiltro ? <span className="mensagens-erro-span" role="alert" style={{ paddingLeft: 30 }}>{this.state.errorSpan}</span> : false}
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 botoes_filtro">
                                                <div style={{ marginTop: "20px" }}>
                                                    <Button bsStyle="default"
                                                        onClick={() => {
                                                            this.setState({ dataInicial: '', dataFinal: '', cartaoId: '', status: '', erroFiltro: undefined });
                                                            gerenciarPrestacaoAction.setLimparFiltrosAction(this.props.dispatch);
                                                        }}
                                                        style={{ width: "90px", height: "30px", paddingTop: "4px", paddingLeft: "10px" }}>
                                                        {LABEL_COMUNS.LIMPAR}
                                                    </Button>&nbsp;&nbsp;&nbsp;
                                                    <Button bsStyle="primary" style={{ width: "90px", height: "30px", paddingTop: "4px", paddingLeft: "10px" }}
                                                        onClick={() => { this.validaCampos(); }}>
                                                        {LABEL_COMUNS.BUSCAR}
                                                    </Button>

                                                </div>
                                            </div>
                                        </div> */}
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                    <div className="panel panel-default posicionamento_grid">
                        <div className="panel-heading fundo-azul-claro">{CONSTANTE.TITULO_PANEL_RESULTADO_BUSCA_PRESTACOES}</div>
                        <div className="panel-body">
                            {this.exibirLoading()}
                            {this.state.status === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO ? null : this.exibirIconeExcel()}
                            <GridePrestacaoConta
                                qtdRegistro={this.props.stateGerenciarPrestacaoContaReducer.queryString.quantidadeTotalRegistros}
                                toggleCheckAll={this.props.stateGerenciarPrestacaoContaReducer.toggleCheckAll}
                                dados={this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta}
                                dispatch={this.props.dispatch}
                                exibirSelecionarTodos={this.state.exibirSelecionarTodos}
                                podeAprovar={this.props.outorgaPerfils.podeAprovar}
                            />
                            <MsgDialog
                                titulo={this.props.stateGerenciarPrestacaoContaReducer.tipoOperacao}
                                msg={this.props.stateGerenciarPrestacaoContaReducer.mensagem}
                                showMsg={this.props.stateGerenciarPrestacaoContaReducer.exibirTelaDeConfirmacao}
                                dados={this.props.stateGerenciarPrestacaoContaReducer.listCheckboxSelecionado}
                                dispatch={this.props.dispatch}
                            />
                            <MsgDialogSucesso
                                queryString={this.props.stateGerenciarPrestacaoContaReducer.queryString}
                                produtosIdsBppCorp={this.props.produtosIdsBppCorp}
                                showMsg={this.props.stateGerenciarPrestacaoContaReducer.showDialogSucesso}
                                msg={this.props.stateGerenciarPrestacaoContaReducer.msgDialogSucesso}
                                dispatch={this.props.dispatch}
                            />

                            <MsgDialogErros
                                titulo={this.props.stateGerenciarPrestacaoContaReducer.tipoOperacao}
                                ativar={this.props.stateGerenciarPrestacaoContaReducer.showDialogErros}
                                response={this.props.stateGerenciarPrestacaoContaReducer.response}
                                dispatch={this.props.dispatch}
                                queryString={this.props.stateGerenciarPrestacaoContaReducer.queryString}
                            />
                            <ModalImagens
                                dispatch={this.props.dispatch}
                                showDialog={this.props.stateGerenciarPrestacaoContaReducer.showDialogImages}
                                imagens={this.props.stateGerenciarPrestacaoContaReducer.viewImagens}
                            />
                            <ModalImagensVisualizar
                                dispatch={this.props.dispatch}
                                visualizarImagem={this.props.stateGerenciarPrestacaoContaReducer.visualizarImagem}
                            />
                            <BotaoPaginar
                                qtdRegistro={this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta.length}
                                qtdTotalRegistro={this.props.stateGerenciarPrestacaoContaReducer.queryString.quantidadeTotalRegistros}
                                show={(this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta.length
                                    < this.props.stateGerenciarPrestacaoContaReducer.queryString.quantidadeTotalRegistros)
                                    && (this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta.length > 0)}
                                dispatch={this.props.dispatch}
                                queryString={this.props.stateGerenciarPrestacaoContaReducer.queryString}
                                produtosIdsBppCorp={this.props.produtosIdsBppCorp}
                            />

                            {/*<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita botoes-aprovar-reprovar">
                                <Button bsStyle="success"
                                    disabled={gerenciarPrestacaoAction.setHabilitarBotoesAprovarReprovarAction(this.props.stateGerenciarPrestacaoContaReducer.listCheckboxSelecionado)}
                                    onClick={
                                        () => {
                                            gerenciarPrestacaoAction.abrirTelaDeConfirmacaoAction(STATUS.PRESTACAO_DE_CONTAS._APROVAR, true, this.props.dispatch)
                                        }}>Aprovar</Button>&nbsp;&nbsp;&nbsp;
                            <Button bsStyle="danger"
                                    disabled={this.props.stateGerenciarPrestacaoContaReducer.listCheckboxSelecionado.length > 1 ? false : true}
                                    onClick={
                                        () => {
                                            gerenciarPrestacaoAction.abrirTelaDeConfirmacaoAction(STATUS.PRESTACAO_DE_CONTAS._REPROVAR, true, this.props.dispatch)
                                        }}
                                >Reprovar</Button>
                                    </div>*/}
                            {this.props.outorgaPerfils.podeAprovar && <BotoesGerenciar
                                listCheckboxSelecionado={this.props.stateGerenciarPrestacaoContaReducer.listCheckboxSelecionado}
                                dispatch={this.props.dispatch}
                                showBotoes={gerenciarPrestacaoAction.setPrestacoesExistentes(this.props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoConta)}

                            />}
                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                        </div>
                    </div>
                </div>
                <br /> <br />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-direita">
                    <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                    <br /><br />
                </div>
            </div >
        );
    }
}
function mapStateToProps(state) {
    return {
        stateGerenciarPrestacaoContaReducer: state.GerenciarPrestacaoContaReducer,
        outorgaPerfils: {
            podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
            podeCriar: podeCriar(state.OutorgaOperacaoReducer),
            podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
        },
    };
}
export default connect(mapStateToProps)(GerenciarPrestacaoConta);


function formatarDataHora(dataHora) {
    Moment.locale('en');
    if (dataHora !== undefined) {
        return (Moment(dataHora).format('DD/MM/YYYY HH:mm'));
    }
    return dataHora;
}
const GridePrestacaoConta = (props) => {
    /**
     * BPP-3884
     *
     * TODO: Popular o ViewModel com resposta do serviço,
     * talvez no próprio reducer ou reducer novo.
     *
     * Remover mock após integração com servico.
     *
     * @antes props.dados
     * @depois PrestacaoContaViewModel
     */


    const prestacoes = props.dados;
    if (!props.dados) return (<div></div>);
    return (
        <div id="conteudo-gride">
            {
                prestacoes.length > 0 ?
                    <div>
                        {/* <div className="list-group"> */}
                        {/* <li className="list-group-item"> */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_prestacao">
                            {props.podeAprovar && props.exibirSelecionarTodos == true && <div className="col-xs-3 col-sm-8 col-md-4 col-lg-4 alinhamento_marcartodos">
                                <label id="labelChecarTodos">
                                    <input
                                        id="inputChecarTodos"
                                        name="nameCheckAll"
                                        type="checkbox"
                                        checked={props.toggleCheckAll}
                                        onChange={
                                            () => {
                                                gerenciarPrestacaoAction.setToggleCheckBoxAllAction(props.toggleCheckAll, props.dispatch);
                                            }
                                        }>
                                    </input>
                                    Selecionar todas as prestações
                                </label>
                            </div>}
                            <div className="col-xs-9 col-sm-4 col-md-8 col-lg-8 alinhamento_total">
                                <div id="form-total-registros">
                                    <div>
                                        <b>Total de registros: {props.qtdRegistro}</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* </li> */}
                        {/* </div> */}
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            {
                                prestacoes.map((valor, idx) => {
                                    return (
                                        valor !== undefined ?
                                            <div key={idx} id="filtro_prestcontas" className="row" >
                                                <div className="panel panel-default">
                                                    <div className="panel-heading fundo-azul-claro tamanho_panelprestacao">
                                                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 alinhamento-esquerda">
                                                            {props.podeAprovar && valor.status !== 'pendentePrestacao' && <input
                                                                id={'checkbox_prestacao' + idx}
                                                                type="checkbox"
                                                                className="checkbox_prestacao"
                                                                name="checkBoxPrestacaoConta"
                                                                value={JSON.stringify(valor)}
                                                                onChange={
                                                                    () => {
                                                                        gerenciarPrestacaoAction.setSelecionarCheckBoxAction(JSON.stringify(valor), props.dispatch, idx, props.toggleCheckAll);
                                                                    }
                                                                }>
                                                            </input>}
                                                            <span className="posicao_descricao"> {formatarDataHora(valor.dadosExtrato.dataHora) + ' | '} {valor.nomePortador !== undefined ? valor.nomePortador : ' '} {' | ' + valor.dadosExtrato.nome} </span>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                            <span className={gerenciarPrestacaoAction.getCssSinalizador(valor.status)}>{valor.statusExibicao}</span>
                                                        </div>
                                                    </div>
                                                    <div className="panel-body">
                                                        <form className="editForm" id="myForm">
                                                            {/* <div class="table-responsive"> */}
                                                            <table className="table">
                                                                <thead className="fundo-cinza-claro">
                                                                    <tr>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.VALOR_DA_TRANSACAO}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.CLIENTE_PROJETO}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.CLASSIFICACAO}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.ID_CARTAO}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.QUATRO_ULTIMOS_DIGITOS}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.ESTABELECIMENTO}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.OBSERVACOES}</p></th>
                                                                        <th scope="col"><p className="list-group-item-heading alinhamento-centro">{LABEL_COMUNS.VISUALIZAR}</p></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <td className="fundo-tabela">
                                                                            <p className="alinhamento-centro">
                                                                                <b>
                                                                                    {
                                                                                        //(valor.dadosExtrato.valor).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
                                                                                    }
                                                                                    {
                                                                                        formataMoedaComSimboloESinalNegacao(valor.dadosExtrato.valor, valor.dadosExtrato.tipoMovimentacao, '')
                                                                                    }
                                                                                </b>
                                                                            </p>
                                                                        </td>
                                                                        <td className="fundo-tabela"><p className="alinhamento-centro"> <b>{valor.projeto}</b></p></td>
                                                                        <td className="fundo-tabela"><p className="alinhamento-centro"> <b>{valor.classificacao}</b></p></td>
                                                                        <td className="fundo-tabela"><p className="alinhamento-centro"> <b>{valor.cartaoId}</b></p></td>
                                                                        <td className="fundo-tabela"><p className="alinhamento-centro"> <b>
                                                                            {
                                                                                valor.quatroUltimosDigitos ? valor.quatroUltimosDigitos : ''
                                                                            }
                                                                        </b></p></td>
                                                                        <td className="fundo-tabela"><p className="texto-prestacao  alinhamento-centro"> <b>{valor.dadosExtrato.descricao} </b></p></td>
                                                                        <td className="fundo-tabela"><p className="texto-prestacao  alinhamento-centro"> <b>{valor.descricaoSolicitacao} </b></p></td>
                                                                        <td className="alinhamento-centro fundo-tabela">
                                                                            <OverlayTrigger placement="top" overlay={tooltipVisualizar}>
                                                                                <i className="glyphicon glyphicon-inbox btn_visualizar_prestacao"
                                                                                    aria-hidden="true"
                                                                                    onClick={
                                                                                        () => {
                                                                                            gerenciarPrestacaoAction.setViewImagensAction(valor.imagem, props.dispatch);
                                                                                        }}
                                                                                >
                                                                                </i>
                                                                            </OverlayTrigger></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                            {/* </div> */}
                                                            <div className="row">

                                                            </div>
                                                            <div className="row">
                                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                                    <h6 className="list-group-item-heading">Prestação realizada em {formatarDataHora(valor.dataCriacao)}</h6>
                                                                    <FormGroup >
                                                                        <FormControl
                                                                            id={'observacao_aprovacao' + idx}
                                                                            maxLength="100"
                                                                            disabled={true}
                                                                            className="form-control"
                                                                            maxLength={100}
                                                                            rows="1"
                                                                            type="text"
                                                                            placeholder={'Digite uma observação'}
                                                                            value={valor.descricaoAprovacao}
                                                                            onChange={
                                                                                (e) => {
                                                                                    gerenciarPrestacaoAction.setPreencherObservacaoAction(e, valor, props.dispatch);
                                                                                }
                                                                            } />
                                                                        <HelpBlock className={gerenciarPrestacaoAction.setHelpBlockGrideCssMsgErro(valor.tipoErro)}>
                                                                            {gerenciarPrestacaoAction.setHelpBlockGrideMsgErro(valor.msgErro)}</HelpBlock>
                                                                    </FormGroup>
                                                                </div>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                            : '');
                                })
                            }
                        </div>
                    </div> : ''
            }
        </div>
    );
};
const MsgDialog = (props) => {
    if (!props.showMsg) return (<div></div>);
    let idModalCss = '';
    if (props.msg === EMesageErroConfirm._msgPadraoAprovado ||
        props.msg === EMesageErroConfirm._msgPadraoReprovado) {
        idModalCss = 'msgConfirm';
    } else {
        idModalCss = 'msgErroConfirm';
    }
    const montarBoddy = () => {
        return (
            <div>
                {props.msg}
            </div>
        );
    };
    const montarFooter = () => {
        return (
            <FooterBotoesModalComponent
                cols="12 12 12 12"
                btn1OnClick={
                    (e) => {
                        gerenciarPrestacaoAction.abrirTelaDeConfirmacaoAction(null, false, props.dispatch);
                    }
                }
                btn1Label="Cancelar"
                btn2OnClick={
                    (e) => {
                        gerenciarPrestacaoAction.processarPrestacaoContaAction(props.dados, props.dispatch);

                    }
                }
                btn2Label="Confirmar"
            />
        );
    };
    return (
        <div>
            <ModalComponent
                exibirModal={props.showMsg}
                closeOnclick={
                    () => {
                        gerenciarPrestacaoAction.toggleTelaDeImagensAction(props.dispatch);
                    }}
                header="Prestação de Contas"
                body={montarBoddy(props)}
                footer={montarFooter(props)}
            />
        </div>
    );
};
const MsgDialogSucesso = (props) => {
    if (!props.showMsg) return (<div></div>);
    const montarBoddy = () => {
        return (
            <div>
                {props.msg}
            </div>
        );
    };
    const montarFooter = () => {
        return (
            <FooterBotoesModalComponent
                btn2OnClick={
                    (e) => {
                        gerenciarPrestacaoAction.setShowDialogSucessoAction(false, props.dispatch);
                        let queryString = props.queryString;
                        queryString.tamanhoPagina = PAGINACAO;
                        queryString.ultimaChave = null;
                        queryString.produtoId = props.produtosIdsBppCorp;
                        gerenciarPrestacaoAction.bindListaPrestacaoContasAction(queryString, props.dispatch);
                        /*gerenciarPrestacaoAction.setReloadAction(props.dispatch, true);*/
                    }
                }
                btn2Label="Ok"
            />
        );
    };
    return (
        <div>
            <ModalComponent
                exibirModal={props.showMsg}
                closeOnclick={
                    () => {
                        gerenciarPrestacaoAction.toggleTelaDeImagensAction(props.dispatch);
                    }}
                header="Prestação de Contas"
                body={montarBoddy(props)}
                footer={montarFooter(props)}
            />
        </div>
    );
};

const MsgDialogErros = (props) => {
    let idModalCss = '';
    if (!props.ativar) return (<div></div>);

    const montarBoddy = (props) => {

        return (
            <div style={{ overflow: 'auto', width: '575px', height: '150px' }}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">DATA / HORA</th>
                            <th scope="col">VALOR</th>
                            <th scope="col">RESULTADO</th>

                        </tr>
                    </thead>
                    {props.response.map((resp, idx) => {
                        return (
                            <tbody key={idx}>
                                <tr>
                                    <td>{resp ? resp.dataHora : ''}</td>
                                    <td>{resp ? resp.valor : ''}</td>
                                    <td>{resp ? resp.resultado : ''}</td>
                                </tr>
                            </tbody>
                        );
                    }
                    )}
                </table>
            </div>
        );
    };
    const montarFooter = (props) => {
        return (
            <FooterBotoesModalComponent
                cols="12 12 12 12"
                btn2OnClick={
                    (e) => {
                        gerenciarPrestacaoAction.setShowMsgDialogErrosAction(false, props.dispatch);
                        props.queryString.tamanhoPagina = PAGINACAO;
                        props.queryString.ultimaChave = null;
                        gerenciarPrestacaoAction.bindListaPrestacaoContasAction(props.queryString, props.dispatch);
                    }
                }
                btn2Label="Ok"
            />
        );
    };
    return (
        <div>
            <ModalComponent
                exibirModal={props.ativar}
                closeOnclick={
                    () => {
                        gerenciarPrestacaoAction.setMsgDialogErrosAction(false, props.dispatch);
                        gerenciarPrestacaoAction.bindListaPrestacaoContasAction(props.queryString, props.dispatch);
                    }
                }
                header="Prestação de Contas"
                body={montarBoddy(props)}
                footer={montarFooter(props)}
            />
        </div>
    );
};

const ModalImagens = (props) => {
    const montarBoddy = (props) => {
        return (
            <div>
                {
                    props.imagens && props.imagens.length > 0 ?
                        <div>
                            <h4>Comprovante (s)</h4>
                            <CarrocelImagem
                                dispatch={props.dispatch}
                                imagens={props.imagens}
                            />
                        </div> : <h4>Não há comprovantes para esta prestação.</h4>
                }
            </div>
        );
    };
    const montarFooter = () => {
        return (
            <FooterBotoesModalComponent
                cols="12 12 12 12"
                btn2OnClick={
                    () => {
                        gerenciarPrestacaoAction.toggleTelaDeImagensAction(props.dispatch);
                    }}
                btn2Label="Ok"
            />
        );
    };
    return (
        <div>
            <ModalComponent
                exibirModal={props.showDialog}
                closeOnclick={
                    () => {
                        gerenciarPrestacaoAction.toggleTelaDeImagensAction(props.dispatch);
                    }}
                header="Prestação de Contas"
                body={montarBoddy(props)}
                footer={montarFooter(props)}
            />
        </div>
    );
};

const ModalImagensVisualizar = (props) => {
    const montarBoddy = (props) => {
        return (
            <p style={{ textAlign: 'center' }}>
                <img style={{ objectFit: 'contain', width: '100%' }} src={props.visualizarImagem} />
            </p>
        );
    };
    const montarFooter = () => {
        return (
            <FooterBotoesModalComponent
                cols="12 12 12 12"
                btn2OnClick={
                    () => {
                        gerenciarPrestacaoAction.visualizarImagem('', props.dispatch);
                    }}
                btn2Label="Ok"
            />
        );
    };
    return (
        <div>
            <ModalComponent
                exibirModal={props.visualizarImagem && props.visualizarImagem.length > 0 ? true : false}
                closeOnclick={
                    () => {
                        gerenciarPrestacaoAction.visualizarImagem('', props.dispatch);
                    }}
                header="Imagem"
                ariaLabelledby="example-custom-modal-styling-title"
                dialogClassName="dialog-exibir"
                body={montarBoddy(props)}
                footer={montarFooter(props)}
            />
        </div>
    );
};
const filtrarThumbnail = (imagens) => {
    if (imagens === undefined) return '';
    var imagensFiltrada = new Array();
    if (Array.isArray(imagens)) {
        imagensFiltrada = imagens.filter(predicadoThumbnail);
    }
    return imagensFiltrada;
};
const predicadoThumbnail = (url) => {
    let urlImg = url.urlComprovante;
    return urlImg.indexOf('_thumbnail') == -1;
};
const CarrocelImagem = (props) => {
    let imagens = props.imagens;

    return (
        <Carousel>
            {
                imagens !== undefined ?
                    filtrarThumbnail(imagens).map((item, idx) => {

                        return (
                            <Carousel.Item key={idx}>
                                <img id={'img-' + idx} width={900} height={500} alt="900x500" src={item.urlComprovante} onError={(e) => { console.log('Erro na imagem', e.target); }} />
                                <div
                                    className="legend"
                                    style={
                                        {
                                            background: '#337ab7',
                                            textAlign: 'center'
                                        }
                                    }>
                                    {
                                        /* <OverlayTrigger placement="top" overlay={tooltipVisualizarImagem}>
                                         {
                                             <a
                                                 href={item.urlComprovante}
                                                 target="_blank"
                                                 style={
                                                     {
                                                         color: "#ffffff",
                                                         textDecoration: "none"
                                                     }
                                                 }>
                                                 visualizar
                                             </a>
                                         }
                                         </OverlayTrigger>*/
                                    }
                                    <OverlayTrigger placement="top" overlay={tooltipVisualizarImagem}>
                                        <div style={{ cursor: 'pointer' }} onClick={() => {
                                            gerenciarPrestacaoAction.visualizarImagem(item.urlComprovante, props.dispatch);
                                        }}>
                                            <div style={
                                                {
                                                    color: '#ffffff',
                                                    textDecoration: 'none'
                                                }
                                            }>visualizar</div>
                                        </div>
                                    </OverlayTrigger>

                                </div>
                            </Carousel.Item>);
                    }) : ''
            }
        </Carousel>
    );
};
const BotaoPaginar = (props) => {
    if (props.show) {
        return (
            <div>
                <ButtonToolbar>
                    <Button id="btn-ver-mais"
                        className="btn btn-default btn-block fonte-tamanho-11"
                        bsSize="large"
                        onClick={() => {
                            props.queryString.produtoId = props.produtosIdsBppCorp;
                            gerenciarPrestacaoAction.setListaPrestacaoContaAction(props.queryString, props.dispatch, true);
                        }}
                    ><span className="texto-vermais">VER MAIS</span></Button>
                </ButtonToolbar>
            </div>
        );
    } else {
        return (<div></div>);
    }
};
const BotoesGerenciar = (props) => {
    if (!props.showBotoes) {
        return (<div></div>);
    } else {
        return (<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita botoes-aprovar-reprovar">
            <Button
                bsStyle="success"
                disabled={gerenciarPrestacaoAction.setHabilitarBotoesAprovarReprovarAction(props.listCheckboxSelecionado)}
                onClick={() => { gerenciarPrestacaoAction.abrirTelaDeConfirmacaoAction(STATUS.PRESTACAO_DE_CONTAS.APROVADO, true, props.dispatch); }}>
                Aprovar
            </Button>&nbsp;&nbsp;&nbsp;
            <Button
                bsStyle="danger"
                disabled={props.listCheckboxSelecionado.length > 1 ? false : true}
                onClick={() => { gerenciarPrestacaoAction.abrirTelaDeConfirmacaoAction(STATUS.PRESTACAO_DE_CONTAS.REPROVADO, true, props.dispatch); }}>
                Reprovar
            </Button>
        </div>);
    }


};
function downloadCSV(args, props) {
    var data, filename, link;
    var resultado = [];
    if (props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoContaCSV) {
        let arr = props.stateGerenciarPrestacaoContaReducer.listaDePrestacaoContaCSV;
        var csv = converterArrayParaCSV({ data: arr }, props.dispatch);
        if (csv == null) return;
        filename = args.filename || 'Prestação de Conta.csv';
        if (!csv.match(/^data:text\/csv/i)) {
            const universalBOM = '\uFEFF';
            csv = 'data:text/csv;charset=utf-8,' + universalBOM + csv;
        }
        data = encodeURI(csv);
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    }
}

function converterArrayParaCSV(registros, dispatch) {
    const items = registros.data;
    let tempComprovantes = [];
    let maiorQuantidadeComprovantes = 0;
    let cabecalhos = [];
    let delimitador = ';';
    for (let i in items) {
        var dataCriacaoPrestacao = '';
        if (items[i].dataCriacao !== undefined) {
            dataCriacaoPrestacao = moment(items[i].dataCriacao).format('DD/MM/YYYY');
        }
        let tempItems = {
            'CARD ID': items[i].cartaoId,
            /*'DATA DA CRIACAO': moment(items[i].dataCriacao).format("DD/MM/YYYY"),*/
            'DATA DA CRIACAO': dataCriacaoPrestacao,
            'NOME DO PORTADOR': items[i].nomePortador,
            'VALOR': formataMoeda(items[i].dadosExtrato.valor),
            'TIPO DESPESA': items[i].classificacao,
            'CLIENTE/PROJETO': items[i].projeto,
            'OBSERVACAO': items[i].descricaoSolicitacao,
            'OBSERVACAO APROVACAO': items[i].descricaoAprovacao,
            'STATUS PRESTACO CONTA': items[i].status
        };
        let comprovantes = [];
        if (items[i].listaComprovantes !== undefined) {
            comprovantes = items[i].listaComprovantes;
        } else {
            comprovantes = [
                {
                    'objectKey': 'sem chave',
                    'urlComprovante': 'sem imagem'
                }
            ];
        }
        if (comprovantes) {
            if (comprovantes.length > maiorQuantidadeComprovantes) {
                maiorQuantidadeComprovantes = comprovantes.length;
            }
        }
        //let urlsPreAssinadas = obtemURLsPreAssinadasDosComprovantes(comprovantes, dispatch)
        let urlsPreAssinadas = comprovantes;
        if (!urlsPreAssinadas) continue;
        for (let j in urlsPreAssinadas) {
            tempItems['COMPROVANTE_' + j] = urlsPreAssinadas[j];
        }
        tempComprovantes.push(tempItems);
    }
    for (let t = 0; t < maiorQuantidadeComprovantes; t++) {
        let tituloCabecalho = 'COMPROVANTE_' + t;
        //cabecalhos.push(tituloCabecalho);
    }
    const replacer = (key, value) => value === null ? '' : value;
    const cabecalhos2 = [ /*'CARTAO ID',*/'CARD ID', 'DATA DA CRIACAO', 'NOME DO PORTADOR', /*'ID CONTA DIGITAL',*/ 'VALOR', /*'ID TIPO DESPESA',*/ 'TIPO DESPESA', 'CLIENTE/PROJETO', 'OBSERVACAO', 'OBSERVACAO APROVACAO', 'STATUS PRESTACO CONTA'];
    const header = cabecalhos2.concat(cabecalhos);
    let csv = tempComprovantes.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(delimitador));
    csv.unshift(header.join(delimitador));
    csv = csv.join('\r\n');
    return csv;
}
function obtemURLsPreAssinadasDosComprovantes(comprovantes, dispatch) {
    let objetosComURLsPreAssinadas = gerenciarPrestacaoAction.setBuscarUrlsAssinadas(comprovantes, dispatch);
    if (!objetosComURLsPreAssinadas) {
        return null;
    }
    let urlsPreAssinadas = [];
    for (let u in objetosComURLsPreAssinadas) {
        let url = objetosComURLsPreAssinadas[u].urlComprovante;
        urlsPreAssinadas.push(url);
    }
    return urlsPreAssinadas;
}



