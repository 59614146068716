import { uploadFileS3 } from '../arquivo/arquivo-service';
import {
    fetchGetItensDoLote,
    fetchGetLoteArquivo,
    fetchGetLoteArquivoId,
    fetchGetLotes,
    fetchPatchLoteArquivo,
    fetchPostLoteArquivo,
    fetchPutLoteArquivo
} from './transferencia-em-lote-base';
import { PRODUTOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { formatarDataUS } from '../../componentes/util/data/dataUtil';
import { fetchGetLoteRetorno } from 'servicos/transferencia-em-lote/transferencia-em-lote-base';

export const montaRespostaPaginada = (historicoPaginaAnterior, respostaBackend) => {
    let historicoPaginaAnteriorTratado = historicoPaginaAnterior;
    if (!historicoPaginaAnterior) {
        historicoPaginaAnteriorTratado = [];
    }

    const array = [...historicoPaginaAnteriorTratado, ...respostaBackend.resultado];

    if (!respostaBackend.ultimaChave) {
        respostaBackend.ultimaChave = '';
    }

    return respostaBackend
        ? (
            {
                ultimaChave: respostaBackend.ultimaChave,
                quantidadeTotalRegistros: respostaBackend.quantidadeTotalRegistros,
                resultado: Array.from(new Set(array.map(a => a.id))).map(item => ({ ...array.find(obj => obj.id === item) }))
            }
        )
        : (
            {}
        );
};

export const getHistoricoTransferenciaEmLote = (params, linhasAnteriores) => (
    fetchGetLoteArquivo({ ...params })
        .then(result => montaRespostaPaginada(linhasAnteriores, result.data))
        .catch(err => err)
);

export const aprovarOuReprovarLote = (body) => {
    return fetchPatchLoteArquivo(JSON.stringify(body))
        .then(result => result)
        .catch(error => error);
};

export const salvarTransferenciaEmLote = async (dadosTransferencia) => {
    const { arquivoSelecionado, arquivoEnviado } = dadosTransferencia;
    let resultado;
    await uploadFileS3(arquivoSelecionado)
        .then(({ idArquivoS3 }) => ({ ...dadosTransferencia, idArquivoS3 }))
        .then(async (dadosTransferencia) => {
            if (arquivoEnviado && arquivoEnviado.id) {
                return await fetchPutLoteArquivo(arquivoEnviado.id, montaCorpoDaRequisicaoSalvarTransferenciaEmLote(dadosTransferencia));
            } else {
                return await fetchPostLoteArquivo(montaCorpoDaRequisicaoSalvarTransferenciaEmLote(dadosTransferencia));
            }
        })
        .then((result) => {
            resultado = result;

        }).catch((error) => {
            resultado = error;
        });
    return resultado;
};

export const getListaDeLoteArquivos = (params, linhaAnteriores) => {
    return fetchGetLoteArquivo(params)
        .then(result => linhaAnteriores ? {data: montaRespostaPaginada(linhaAnteriores, result.data)} : result)
        .catch(error => error);
};

export const getLotesDoArquivo = (params, linhaAnteriores) => (
    fetchGetLotes({ ...params })
        .then(result => montaRespostaPaginada(linhaAnteriores, result.data))
        .catch(err => err)

);

export const getItensDoLote = (loteId, params, linhaAnteriores) => (
    fetchGetItensDoLote(loteId, { ...params })
        .then(result => montaRespostaPaginada(linhaAnteriores, result.data))
        .catch(err => err)
);

export const getDetalhesArquivo = (arquivoId, params) => {
    return fetchGetLoteArquivoId(arquivoId, { ...params })
        .then(result => result.data)
        .catch(err => err);
};

export const getLoteArquivoId = (arquivoId) => {
    return fetchGetLoteArquivoId(arquivoId)
        .then(result => result.data)
        .catch(err => err);
};

export const getLoteRetorno = (params) => {
    return fetchGetLoteRetorno(params)
        .then(result => result.data)
        .catch(err => err);
};

export const getBodyApproveOrReproveLoteArquivo = (loteArquivoId, anexoContratualOriginal, transacaoAcao) => {
    const body = {
        id: loteArquivoId,
        anexoContratualOriginal,
        transacao: transacaoAcao
    };
    return body;
};

export const mountParamsGetLoteArquivosBppCorp = (contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, status, idArquivoSequencialPorPessoaId, operacao) => {
    const params = {
        contaId,
        produto: PRODUTOS.corp,
        pessoaId,
        dataDeInclusaoDe: dataDeInclusaoDe ? formatarDataUS(dataDeInclusaoDe) : '',
        dataDeInclusaoAte: dataDeInclusaoAte ? formatarDataUS(dataDeInclusaoAte) : '',
        status,
        idArquivoSequencialPorPessoaId,
        operacao,
        tamanhoPagina: 10000
    };
    return params;
};

const montaCorpoDaRequisicaoSalvarTransferenciaEmLote = (dadosTransferencia) => {
    const {
        arquivoSelecionado, contaId, idArquivoS3, modeloArquivoSelecionado,
        operacaoTransferenciaEmLoteSelecionada, pessoaId, produto
    } = dadosTransferencia;
    const bodyToReturn = { contaId, idArquivoS3, formato: modeloArquivoSelecionado, nomeArquivo: arquivoSelecionado.name, operacao: operacaoTransferenciaEmLoteSelecionada, pessoaId, produto };
    return JSON.stringify(bodyToReturn);
};
