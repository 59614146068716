import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { HEADER_API, URL_ARQUIVOS_CARGAS_CSV, URL_ARQUIVOS_CARGAS, URL_ARQUIVOS_CARGAS_USUARIOS, URL_ARQUIVOS_CARGA_LOTE_ID } from '../../servicos/url-servicos';
import { CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX } from './consulta-carga-lote-controlador';

export default class ConsultaCargaLoteAction {
    constructor() {

    }

    listarAction(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[0] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data,
                            carregando: true
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err.response.data.erros[0].mensagem
                        }
                    );
                });
        };
    }

    buscarUsuarios() {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_USUARIOS(), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[1] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err.data
                        }
                    );
                });
        };
    }

    changeNomeArquivo(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[3] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: event.target.value
                }
            );
        };
    }

    changeDataDe(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: event.target.value
                }
            );
        };
    }

    changeDataAte(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[5] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: event.target.value
                }
            );
        };
    }

    changeUsuario(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[6] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: event.target.value
                }
            );
        };
    }

    limparCampos(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[7] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: undefined
                }
            );
        };
    }

    setarBuscandoTrue(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    buscarCargaLoteParaCSV(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_CSV(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[11] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data,
                            carregando: false
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err.response.data.erros[0].mensagem
                        }
                    );
                });
        };
    }

    limparDadosCSV() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[12] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: []
                }
            );
        };
    }

    setarExibirErroModal() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[13] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME
                }
            );
        };
    }

    setarOcultarErro() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.COMANDO[14] +
                        CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME
                }
            );
        };
    }
}
