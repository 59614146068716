import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Grid from '../../../../componentes-uteis/grid/grid';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import { COMBO, CONTA_SELECIONADA, LABEL_COMUNS, OPERACAO, FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER, MENSAGENS, } from '../../../../util/contantes-comuns/constantes-comuns';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import DadosEntregaCartao from '../../../../dados-entrega-cartao/dados-entrega-cartao';
import imagemCartaoPf from '../../../../../imagens/imagem_cartao_pf.png';
import ShowLineError from '../../../../comuns/showLineError/show-line-error';
import { STATUS_VALIDACAO_COMPONENTES } from '../../../../util/estilo_para_componentes/estiloUtil';
import { getJsonPedidoEmbossingBppConsumer, calculateValueOrderEmbossingCardBppConsumer } from '../../../../../servicos/pedidos/pedido-service';
import { podeAprovar, podeConsultar, podeCriar, outorgaParametroOperacao } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import * as pedidosAction from '../../../../../action/pedidos/pedidos-action';
import { getContractualAttachmentCreateCard } from '../../../../../servicos/anexo-contratual/anexo-contratual-service';
import { getComboFelByPublishedProductId } from '../../../../../servicos/negociacoes/negociacoes-service';
import { environment } from '../../../../../configuracoes/environment';
import { formataMoedaReal } from '../../../../util/numeros/numeroUtil';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import If from '../../../../componentes-uteis/if/if';
import DadosEntregaBpp from '../../../../dados-entrega-cartao/dados-entrega-bpp';
import ValidaSaldoParaPedidoEmbossingCartao from '../../../../comuns/valida-saldo-para-pedido-embossing-cartao/valida-saldo-para-pedido-embossing-cartao';

class PedirCartaoBppConsumer extends Component {
    constructor(props) {
        super(props);
        this.SELECTED_ACCOUNT = CONTA_SELECIONADA.OBJETO();

        this.INITIAL_STATE = {
            update: 0,
            selectedAccount: this.SELECTED_ACCOUNT,
            bearerAddress: {},
            comboId: '',
            contractualAttachmentId: '',
            bearerNameDocumentNumber: this.SELECTED_ACCOUNT.dadosPessoa.documento[0].numero,
            bearerNameDocumentType: this.SELECTED_ACCOUNT.dadosPessoa.documento[0].tipo,
            bearerName: this.SELECTED_ACCOUNT.dadosPessoa.pessoaFisica.nomeCompleto,
            deliveryWay: '',
            deliveryWayError: false,
            showModalOrderCard: false,
            isConfirmation: false,
            isSuccess: false,
            isCancelation: false,
            bodyModal: '',
            productFELId: '',
            showAddressComponent: false,
            showAddressBppComponent: false,
            showLoading: false,
            orderTax: { orderValue: 0, orderValueDeliveryWayBPP: 0, orderValueDeliveryWaySedex: 0 },
            exibirModalSaldoInsuficiente: false
        };
        this.state = this.INITIAL_STATE;
        this.dadosEntregaRef = React.createRef();
    }

    abreviaNome(name) {
        var split_names = name.trim().split(' ');

        if (split_names.length > 1) {
            let nameToReturn = '';
            for (let i = 0; i < split_names.length; i++) {
                if (i === 0) {
                    nameToReturn = split_names[0];
                } else {
                    nameToReturn = nameToReturn + ' ' + split_names[i].charAt(0);
                }
            }
            return nameToReturn;
        }
        return split_names[0];
    }

    componentWillUnmount = () => {
        this.props.resetaSolicitacaoPedidoEmbossingCartao();
    }

    componentDidMount = async () => {
        this.setShowLoading(true);
        let bearerName = this.state.bearerName;

        if (bearerName.length > 19) {
            let bearerNameAbrev = this.abreviaNome(bearerName);
            this.setState({ bearerName: bearerNameAbrev });
        }

        const comboFelRetrivied = await getComboFelByPublishedProductId(environment.produtoPublicado.pedidoCartaoPessoaFisica);
        if (!comboFelRetrivied.error) {

            const { operacao: { pedidoEmbossingCartao: { tarifa: { tarifaPedidoEmbossingCartao, tarifaPedidoEmbossingCartaoEntregaRetiradaBPP, tarifaPedidoEmbossingCartaoEntregaSedex } } } } = comboFelRetrivied;

            const orderTax = {
                orderValue: tarifaPedidoEmbossingCartao.valor,
                orderValueDeliveryWayBPP: tarifaPedidoEmbossingCartaoEntregaRetiradaBPP.valor,
                orderValueDeliveryWaySedex: tarifaPedidoEmbossingCartaoEntregaSedex.valor
            };

            const comboId = comboFelRetrivied.midia.comboFELId[0];

            const productFELId = comboFelRetrivied.midia.produtoFELId;

            this.setState({ comboId, productFELId, orderTax });
        }
        this.setShowLoading(false);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pedidoReducer.solicitacaoDePedidoEmbossingCartao !== this.props.pedidoReducer.solicitacaoDePedidoEmbossingCartao) {
            if (nextProps.pedidoReducer.solicitacaoDePedidoEmbossingCartao.sucesso) {
                this.renderModalSuccess();
            }
            if (nextProps.pedidoReducer.solicitacaoDePedidoEmbossingCartao.erro) {
                if (nextProps.pedidoReducer.solicitacaoDePedidoEmbossingCartao.mensagemDeErro.indexOf('saldo insuficiente') !== -1) {
                    this.setState({ exibirModalSaldoInsuficiente: true });
                }
                else {
                    this.renderModalError('Ocorreu um erro ao realizar o pedido.');
                }
            }
            this.setShowLoading(false);
        }
    }

    footerModalPedirCartao = () => {
        const { deliveryWay, orderTax, showLoading, selectedAccount } = this.state;

        var btn2Label;
        var btn2OnClick;
        var btn1Label;
        var btn1OnClick;
        if (this.state.isCancelation) {
            btn2Label = LABEL_COMUNS.SIM;
            btn2OnClick = () => this.props.history.goBack();
            btn1Label = LABEL_COMUNS.NAO;
            btn1OnClick = () => { this.setShowModalOrderCard(false); };
        }
        if (this.state.isConfirmation) {
            btn2Label = LABEL_COMUNS.SIM;
            btn2OnClick = async () => {
                const emptyDeliveryWayError = this.validateDeliveryWay();
                if (!emptyDeliveryWayError) {
                    this.setShowLoading(true);
                    const contractualAttachmentId = await getContractualAttachmentCreateCard(selectedAccount.pessoaTitularId);
                    if (!contractualAttachmentId.error) {
                        const { outorgaPedidoEmbossingCartao: { aprovacao }, outorgaPerfils: { podeAprovar } } = this.props;
                        const orderBppConsumer = this.state;
                        orderBppConsumer.contractualAttachmentId = contractualAttachmentId.data.id;
                        const necessitaToken = aprovacao;
                        const outorgaPodeAprovar = podeAprovar;
                        if (deliveryWay === FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER.CORREIOS.value) {
                            orderBppConsumer.bearerAddress = this.getDadosEntrega();
                        }
                        this.props.efetuarPedidoEmbossingCartao(null, necessitaToken, outorgaPodeAprovar, orderBppConsumer);
                        this.setState({ isConfirmation: false });
                    }
                }
                this.setShowModalOrderCard(false);
                // this.clearState();
            };
            btn1Label = LABEL_COMUNS.NAO;
            btn1OnClick = () => {
                this.setShowModalOrderCard(false);
            };
        }
        if (this.state.isSuccess) {
            btn2Label = LABEL_COMUNS.OK;
            btn2OnClick = () => { this.props.history.goBack(); };
            btn1Label = null;
            btn1OnClick = null;
        }
        return (
            <FooterBotoesModalComponent
                btn2Label={btn2Label}
                btn2OnClick={btn2OnClick}
                btn1Label={btn1Label}
                btn1OnClick={btn1OnClick}
            />
        );
    };


    clearState = () => {
        this.setState(this.INITIAL_STATE);
    }

    renderModalSuccess = () => {
        this.setShowLoading(false);
        this.setState({ bodyModal: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.FINALIZACAO.SUCESSO });
        this.setState({ isSuccess: true });
        this.setShowModalOrderCard(true);
    }

    renderModalError = (message) => {
        this.setShowLoading(false);
        this.setState({ bodyModal: message });
        this.setState({ isSuccess: true });
        this.setShowModalOrderCard(true);
    }

    setShowModalOrderCard(showModalOrderCard) {
        this.setState({ showModalOrderCard });
    }

    getDadosEntrega() {
        let dados = this.dadosEntregaRef.state;
        let dadosObject = {
            logradouro: dados.logradouro,
            numero: dados.numero,
            bairro: dados.bairro,
            cidade: dados.nomeDaCidadeSelecionada,
            estado: dados.siglaDoEstadoSelecionado,
            pais: 'BRASIL',
            cep: dados.cep,
            complemento: dados.complemento,
            pontoReferencia: dados.pontoReferencia
        };

        return dadosObject;
    }

    renderModalCancelation() {
        this.setState({ bodyModal: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.CANCELAMENTO });
        this.setState({ isCancelation: true });
        this.setShowModalOrderCard(true);
    }

    renderModalConfirmation() {
        this.setState({ bodyModal: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.CONFIRMA_ENVIO_PEDIDO });
        this.setState({ isConfirmation: true });
        this.setShowModalOrderCard(true);
    }

    updateBtnFunc() {
        this.setState({
            update: 1
        }, () => {
            this.setState({ update: 0 });
        });
    }

    getBtnEnabled() {

        if (this.state.deliveryWay === '') {
            return true;
        }
        else if (this.state.deliveryWay === 'retiradaBPP') {
            return false;
        }

        else {
            let dados = this.dadosEntregaRef.state;

            let erros = 0;

            if (dados.logradouro === '') {
                erros = erros + 1;
            }
            if (dados.numero === '') {
                erros = erros + 1;
            }
            if (dados.bairro === '') {
                erros = erros + 1;
            }
            if (dados.nomeDaCidadeSelecionada === '') {
                erros = erros + 1;
            }
            if (dados.siglaDoEstadoSelecionado === '') {
                erros = erros + 1;
            }
            if (dados.paisSelecionadoId === '') {
                erros = erros + 1;
            }
            if (dados.cep === '') {
                erros = erros + 1;
            }

            if (erros === 0) {
                return false;
            } else {
                return true;
            }
        }
    }

    setDeliveryWay(deliveryWay) {
        if (deliveryWay.target.value !== 'sedex' && deliveryWay.target.value !== 'retiradaBPP') {
            this.setShowAddressBppComponent(false);
            this.setShowAddressComponent(false);
            this.setState({ deliveryWay: '' });
        }
        else {
            const deliveryWayValue = deliveryWay && deliveryWay.target && deliveryWay.target.value;
            this.setState({ deliveryWay: deliveryWayValue, deliveryWayError: '' }, () => {
                if (deliveryWayValue === FORMA_ENTREGA_PEDIDO_CARTAO_BPP_CONSUMER.CORREIOS.value) {
                    this.setShowAddressComponent(true);
                    this.setShowAddressBppComponent(false);
                }
                else {

                    this.setShowAddressBppComponent(true);
                    this.setShowAddressComponent(false);
                    this.setBearerAddress({});
                }
            });
        }
    }

    setDeliveryWayError = (deliveryWayError) => {
        this.setState({ deliveryWayError });
    }

    setBearerAddress = (bearerAddress) => {
        this.setState({ bearerAddress });
    }

    validateDeliveryWay = () => {
        let error = false;
        const { deliveryWay } = this.state;
        if (!deliveryWay) {
            error = true;
            this.setDeliveryWayError(MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO);
        }
        return error;
    }

    setShowAddressBppComponent = (showAddressBppComponent) => {
        this.setState({ showAddressBppComponent });
    }

    setShowAddressComponent = (showAddressComponent) => {
        this.setState({ showAddressComponent });
    }

    setShowLoading = (showLoading) => {
        this.setState({ showLoading });
    }
    render() {
        const { selectedAccount, bearerName, deliveryWay, deliveryWayError, orderTax, showAddressComponent, showLoading, showAddressBppComponent } = this.state;
        return (
            <div>
                <ValidaSaldoParaPedidoEmbossingCartao exibirModal={this.state.exibirModalSaldoInsuficiente}
                    setExibirModal={() => this.setState({ exibirModalSaldoInsuficiente: false })}
                />
                <ContaSelecionada contaSelecionada={selectedAccount} />
                <div className="row">
                    <If test={showLoading}>
                        <div className="alinhamento-direita">
                            <LoadingFenix />
                        </div>
                    </If>
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro"><b> {LABEL_COMUNS.PEDIDO_DE_CARTAO} </b></div>
                            <div className="panel-body">
                                <Grid cols="3 3 3 3" className="alinhamento-direita">
                                    <img src={imagemCartaoPf} className="imagem-cartao-pf" alt=" imagem cartao" />
                                </Grid>
                                <Grid cols="9 9 9 9" className="alinhamento-esquerda">
                                    <Grid customStyle={{ lineHeight: '50px' }}>
                                        <Grid cols="3 3 3 3" className="alinhamento-esquerda"><b>{LABEL_COMUNS.VALOR}:</b></Grid>
                                        <Grid cols="5 5 5 5">
                                            {formataMoedaReal(calculateValueOrderEmbossingCardBppConsumer(orderTax, deliveryWay))}
                                        </Grid>
                                    </Grid>
                                    <Grid customStyle={{ lineHeight: '50px' }}>
                                        <Grid cols="3 3 3 3" className="alinhamento-esquerda"><b>{LABEL_COMUNS.NOME}:</b></Grid>
                                        <Grid cols="5 5 5 5">
                                            <FormControl
                                                type="text"
                                                id="bearerName"
                                                className="mensagens-erro form-control"
                                                placeholder={LABEL_COMUNS.NOME_TITULAR}
                                                value={bearerName}
                                                disabled={true}
                                                maxLength={19}
                                                bsSize="sm"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid customStyle={{ lineHeight: '10px', display: 'flex', alignItems: 'baseline' }}>
                                        <Grid cols="3 3 3 3" className="alinhamento-esquerda"><b>{LABEL_COMUNS.FORMA_DE_ENTREGA}:</b></Grid>
                                        <Grid cols="5 5 5 5">
                                            <FormGroup validationState={deliveryWayError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl
                                                    className="mensagens-erro"
                                                    id="tipoFavorecido"
                                                    componentClass="select"
                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                    value={deliveryWay}
                                                    onChange={(formaEntregaSelected) => {
                                                        this.setDeliveryWay(formaEntregaSelected);
                                                    }}
                                                    style={{ height: '40px', paddingTop: '6px', paddingLeft: '10px' }}>
                                                    <option value="">{LABEL_COMUNS.SELECIONE}</option>
                                                    {COMBO.PEDIDOS.EMBOSSING_CARTAO.PEDIDO_DE_CARTAO_BPP_CONSUMER.map((delivery, idx) => {
                                                        return (
                                                            <option key={idx} value={delivery.value}>{delivery.label}</option>
                                                        );
                                                    })}}
                                                </FormControl>

                                                <ShowLineError error={deliveryWayError} />
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="3 3 3 3">
                                            {this.state.showAddressBppComponent ? <div>Prazo: 2 dias úteis.</div> :
                                                this.state.showAddressComponent ? <div>Prazo: 10 dias úteis.</div> : <div></div>}
                                        </Grid>
                                    </Grid>
                                    <Grid customStyle={{ lineHeight: '10px', display: 'flex', alignItems: 'baseline' }}>
                                        <Grid cols="3 3 3 3" />
                                        <Grid cols="5 5 5 5" className="alinhamento-direta">
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        <div className="alinhamento-direita">

                            <DadosEntregaCartao
                                updateBtn={() => { this.updateBtnFunc(); }}
                                showAddress={showAddressComponent} ref={ref => { ref !== null ? this.dadosEntregaRef = ref.getWrappedInstance() : this.dadosEntregaRef = React.createRef(); }} />

                            <DadosEntregaBpp showAddress={showAddressBppComponent} />

                            <div style={{ display: 'flex', justifyContent: 'flex-end', paddingBottom: '30px' }}>
                                <Button type="button" className="rectangle-button" bsStyle="default" style={{ minWidth: 159, maxWidth: 159, marginRight: 20 }} onClick={() => this.renderModalCancelation()}>{LABEL_COMUNS.CANCELAR}</Button>
                                <Button disabled={this.getBtnEnabled()} type="button" className="rectangle-button" bsStyle="primary" style={{ minWidth: 159, maxWidth: 159 }} onClick={() => this.renderModalConfirmation()}>{LABEL_COMUNS.SOLICITAR_CARTAO}</Button>
                            </div>
                            <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                        </div>
                    </div>
                </div>
                <ModalComponent exibirModal={this.state.showModalOrderCard}
                    header={LABEL_COMUNS.PEDIR_CARTAO}
                    name="modal-alterar-portador-confirmacao"
                    body={<p> {this.state.bodyModal} </p>}
                    footer={this.footerModalPedirCartao()} />
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    pedidoReducer: state.PedidosReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    outorgaPedidoEmbossingCartao: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.PEDIDO_EMBOSSING_CARTAO)
});

const mapDispatchToProps = dispatch => bindActionCreators(pedidosAction, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedirCartaoBppConsumer);

