import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    listaOperacao: [],
    perfils: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case METODOS.OUTORGA.SET_LISTA_PARAMETRO_OPERACAO:
            return { ...state, listaOperacao: payload };
        case METODOS.OUTORGA.CLEAN_LISTA_PARAMETRO_OPERACAO:
            return { ...state, listaOperacao: [] };
        case METODOS.OUTORGA.SET_PERFILS:
            return { ...state, perfils: payload };
        case METODOS.OUTORGA.CLEAN_PERFILS:
            return { ...state, perfils: [] };
        default:
            return state;
    }
};

export const outorgaParametroOperacao = (state, parametroOperacao) => {
    const ret = state.listaOperacao.filter(e => Object.keys(e)[0] === parametroOperacao);
    if (ret && ret.length) { return ret[0][parametroOperacao]['transacao']; }
    return undefined;
};

export function podeConsultar(state) {
    return !!state.perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeConsultar).length;
}

export function podeAprovar(state) {
    return !!state.perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeAprovar).length;
}

export function podeCriar(state) {
    return !!state.perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeCriar).length;
}
