import React from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import Extrato from '../extrato/extrato';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';

const DetalheConta = (props) => {
    return (
        <div className="row">
            <Extrato {...props} contaSelecionada={reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA)} />
        </div>
    );
};

export default DetalheConta;
