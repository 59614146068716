import React, { Component } from 'react';

import { Button, Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import NovoCargaLoteAction from './novo-carga-lote-action';
import { reactLocalStorage } from 'reactjs-localstorage';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import '../consulta-carga-lote.css';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { environment } from '../../../configuracoes/environment';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import { Redirect } from 'react-router-dom';

const novoCargaLoteAction = new NovoCargaLoteAction();

class NovoCargaLote extends Component {
    constructor(props){
        super(props);
        this.state = {
            'redirecionar':false
        };
    }
    componentWillMount() {
        this.carregarArquivo();
    }
    /* FUNÇÕES PARA OS MODAIS */
    corpoModalGravar() {
        return (
            <div>
                <span>Confirma a importação do arquivo <b>{this.nomeArquivo}</b>?</span>
            </div>
        );
    }
    rodapeModalGravar() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="default" onClick={() => this.props.dispatch(novoCargaLoteAction.setarEstadoModalGravar(false))} >Cancelar</Button>
                    <Button bsStyle="primary" onClick={() => this.fecharModaleDispararGravacao()} >Confirmar</Button>
                </div>
            </div>);
    }
    corpoModalSucessoGravar() {
        return (
            <div>
                <span>Arquivo importado com sucesso.</span>
            </div>
        );
    }
    rodapeModalSucessoGravar() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="primary" onClick={() => this.fecharModalGravarSucesso()} >OK</Button>
                </div>
            </div>);
    }
    corpoModalProcessar() {
        return (
            <div>
                <span>Confirma o processamento do arquivo <b>{this.nomeArquivo}</b>?</span>
            </div>
        );
    }
    rodapeModalProcessar() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="default" onClick={() => this.props.dispatch(novoCargaLoteAction.setarEstadoModalProcessar(false))} >Cancelar</Button>
                    <Button bsStyle="primary" onClick={() => this.fecharModaleDispararProcessamento()} >Confirmar</Button>
                </div>
            </div>);
    }
    corpoModalProcessarSucesso() {
        return (
            <div>
                <span>Arquivo enviado para processamento.</span>
            </div>
        );
    }
    rodapeModalProcessarSucesso() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="primary" onClick={() => this.fecharModalProcessarSucesso()} >OK</Button>
                </div>
            </div>);
    }
    render() {
        //this.carregarArquivo();
        if(this.state.redirecionar) {
            return <Redirect to={ROTAS_CAMINHOS.CARGA_LOTE_CONTA} />;
        }
        if (this.props.meuStore.dadosUrlPreAssinada) {
            if (this.props.meuStore.respostaPutArquivosS3 === undefined && this.props.meuStore.err === undefined) {
                this.putArquivosEmS3();
            }
            else if (this.props.meuStore.respostaPutArquivosS3 !== undefined) {
                if (this.props.meuStore.respostaPostConteudoArquivo === undefined && this.props.meuStore.err === undefined) {
                    this.postConteudoArquivoCarga();
                }
            }
        }
        return (
            <div>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro"> Importação de Arquivo de Transferência em Lote</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row form-group">
                                            <div className="col-sm-12 col-md-12 col-lg-12">
                                                <li className="list-group-item fundo-cinza-claro">
                                                    {this.mostrarBotoes()}
                                                </li>
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                                                <div className="div-botao-voltar">
                                                    <Button bsStyle="default" className="botao_voltar" title="Voltar" onClick={
                                                        ()=>{
                                                            this.setState({
                                                                redirecionar:true
                                                            });
                                                        }    
                                                        /*this.voltar*/
                                                    } >Voltar</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div>
                            {this.exibirErro()}
                        </div>
                        <div>
                            {this.exibirLoading()}
                        </div>

                        {
                            mostrarGrid(this.props)
                        }
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div>
                <div className="list-group">
                    {this.exibirModalGravar()}
                    {this.exibirModalSucessoGravar()}
                    {this.exibirModalProcessar()}
                    {this.exibirModalSucessoProcessar()}
                    {this.exibirMensagemDeSucesso()}
                </div>
            </div>
        );
    }
    voltar = (ev) => {
        //window.location.href = ROTAS_CAMINHOS.CARGA_LOTE_CONTA;
        
    }
    handleChangeArquivo = (ev) => {
        if (ev.target.files[0]) {
            this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(true));
            this.arquivo = ev.target.files[0];
            this.nomeArquivo = this.arquivo.name;
            this.tipoArquivo = 'text/csv';

            var extensao = this.arquivo.name.split('.').pop();

            if (extensao !== 'csv' && extensao !== 'CSV') {
                document.getElementById('arquivoSelecionado').value = null;
                return this.props.dispatch(novoCargaLoteAction.criarErroExtensaoArquivo());
            }

            this.props.dispatch(novoCargaLoteAction.mudarArquivo(this.arquivo, this.nomeArquivo, this.tipoArquivo));
            this.obterUrlPreassinada();
        }
    }
    setRotuloArquivoCargaLote() {
        let rotulo = document.getElementById('rotuloArquivoCargaLote').value;
        this.props.dispatch(novoCargaLoteAction.setRotuloArquivoCargaLoteAction(rotulo));
    }
    obterUrlPreassinada() {
        this.props.dispatch(novoCargaLoteAction.obterUrlPreAssinada());
    }
    putArquivosEmS3() {
        this.props.dispatch(novoCargaLoteAction.putArquivosDocumentosEmS3(this.props.meuStore.dadosUrlPreAssinada.presignedUrlRequest, this.props.meuStore.arquivo));
    }
    postConteudoArquivoCarga() {
        this.props.dispatch(novoCargaLoteAction.postConteudoArquivoCargaDois(this.props.meuStore.dadosUrlPreAssinada.id, this.props.meuStore.arquivo.name, reactLocalStorage.get('usuario'), reactLocalStorage.getObject('contaSelecionada').id));
    }
    procurarTitular(contaId) {
        this.props.dispatch(novoCargaLoteAction.procurarTitular(contaId));
    }
    gravarArquivo = (ev) => {
        if (this && this.props && this.props.meuStore && this.props.meuStore.respostaPostConteudoArquivo) {
            if (this.props.meuStore.respostaGravarArquivo == undefined) {
                this.props.dispatch(novoCargaLoteAction.setarEstadoModalGravar(true));
            } 
        } else {
            this.criarErroArquivoNaoSelecionado();
        }
    }
    gravarProcessarArquivo = (ev) => {
        if (this && this.props && this.props.meuStore && this.props.meuStore.respostaPostConteudoArquivo) {
            if (this.props.meuStore.respostaProcessarArquivo === undefined) {
                this.props.dispatch(novoCargaLoteAction.setarEstadoModalProcessar(true));
            } 
        } else {
            this.criarErroArquivoNaoSelecionado();
        }
    }
    exibirLoading = () => {
        if (this.props.meuStore.carregando) {
            return (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    }
    exibirErro() {
        if (this.props.meuStore.err) {
            return (
                <Alert bsStyle="danger" onDismiss={this.handleDismiss}>
                    <div className="row">
                        <div className="col-md-12 ">
                            <p>{this.props.meuStore.err}</p>
                        </div>
                    </div>
                </Alert>
            );
        }
    }
    ocultarErro() {
        this.props.dispatch(novoCargaLoteAction.setOcultarErro());
    }
    criarErroArquivoNaoSelecionado() {
        let status = '';
        if(this.props.meuStore.statusCarregarArquivo){
            status = this.props.meuStore.statusCarregarArquivo;
        }
        this.props.dispatch(novoCargaLoteAction.criarErroArquivoNaoSelecionado(status));
    }
    mostrarBotoes() {
        if (reactLocalStorage.get('idArquivoCargaLote') === '-1') {
            return (
                <div>
                    <div className="row">
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="col-xs-5 col-sm-7 col-md-7 col-lg-7">
                                    Arquivo*
                                    <div style={{ background: '#FFF', padding: '5px', borderRadius: '3px', marginTop: '1px', height: '34px' }}>
                                        <input type="file" className="texto_arquivo" id="arquivoSelecionado" accept=".csv" onChange={
                                                this.handleChangeArquivo 
                                        } />
                                    </div>
                                    <p className="extensaoCSV">Extensão disponível: *.csv</p>
                                </div>
                                <div className="col-xs-7 col-sm-5 col-md-5 col-lg-5">
                                    Rótulo
                                    <div>
                                        <input className="form-control" type="text" id="rotuloArquivoCargaLote" style={{ width: '100%', height: '35px' }} maxLength="100"
                                            onChange={(() => { this.setRotuloArquivoCargaLote(); })} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                <div className="div-botoes">
                                    <Button bsStyle="link" className="botoes" href={this.obterModeloDeArquivoCargaLote()}>Modelo de arquivo</Button>
                                </div>
                            </div>
                            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 alinhamento-direita">
                                <div className="div-botoes">
                                    <Button bsStyle="primary" className="botoes" onClick={this.gravarArquivo} >Importar</Button>&nbsp;&nbsp;
                                    <Button bsStyle="default" className="botoes" onClick={this.gravarProcessarArquivo}>Importar e Processar</Button>
                                </div>
                            </div>
                        </div>
                        <div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                        <Button bsStyle="primary" className="botao_processar" onClick={this.gravarProcessarArquivo}>Processar</Button>
                    </div>
                </div>);
        }
    }
    carregarArquivo() {
        this.props.dispatch(novoCargaLoteAction.limparAction());
        if (reactLocalStorage.get('idArquivoCargaLote') !== '-1') {
            this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(true));
            this.props.dispatch(novoCargaLoteAction.obterDadosArquivo(reactLocalStorage.get('idArquivoCargaLote')));
        }
    }
    exibirModalGravar = () => {

        return (
            <ModalComponent name="ModalGravar" exibirModal={this.props.meuStore.exibirModalGravar}
                body={this.corpoModalGravar()} header="Carga em Lote" footer={this.rodapeModalGravar()}
            />
        );
    }
    exibirModalSucessoGravar() {
        return (
            <ModalComponent name="ModalSucessoGravar" exibirModal={this.props.meuStore.exibirModalSucessoGravar}
                body={this.corpoModalSucessoGravar()} header="Carga em Lote" footer={this.rodapeModalSucessoGravar()}
            />
        );
    }
    exibirModalProcessar = () => {
        let nomeArquivo = '';
        let nomeArquivoArray = [];
        if (this.props.meuStore.respostaPostConteudoArquivo) {
            nomeArquivoArray = this.props.meuStore.respostaPostConteudoArquivo.nomeArquivo.split('.');

            if (nomeArquivoArray[nomeArquivoArray.length - 1] === 'csv') {
                nomeArquivo = this.props.meuStore.respostaPostConteudoArquivo.nomeArquivo;
            } else {
                nomeArquivo = this.props.meuStore.respostaPostConteudoArquivo.nomeArquivoBucket;
            }
        }
        return (
            <ModalComponent name="ModalProcessar" exibirModal={this.props.meuStore.exibirModalProcessar}
                body={this.corpoModalProcessar()} header="Carga em Lote" footer={this.rodapeModalProcessar()}
            />
        );
    }
    exibirModalSucessoProcessar = () => {
        return (
            <ModalComponent name="ModalProcessar" exibirModal={this.props.meuStore.exibirModalSucessoProcessar}
                body={this.corpoModalProcessarSucesso()} header="Carga em Lote" footer={this.rodapeModalProcessarSucesso()}
            />
        );
    }
    fecharModalGravarSucesso() {
        this.props.dispatch(novoCargaLoteAction.setarEstadoModalGravarSucesso(false));
        this.setState({
            redirecionar:true
        });
        //this.voltar();
    }
    fecharModalProcessarSucesso() {
        this.props.dispatch(novoCargaLoteAction.setarEstadoModalProcessarSucesso(false));
        this.setState({
            redirecionar:true
        });
        //this.voltar();
    }
    fecharModaleDispararGravacao() {
        this.props.dispatch(novoCargaLoteAction.setarEstadoModalGravar(false));
        this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(true));
        this.props.dispatch(novoCargaLoteAction.gravarArquivo(this.props.meuStore.respostaPostConteudoArquivo.id, this.props.meuStore.rotuloArquivoCargaLote));
    }
    fecharModaleDispararProcessamento() {
        this.props.dispatch(novoCargaLoteAction.setarEstadoModalProcessar(false));
        this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(true));
        this.props.dispatch(novoCargaLoteAction.gravarProcessarArquivo(this.props.meuStore.respostaPostConteudoArquivo.id, this.props.meuStore.rotuloArquivoCargaLote));
    }
    exibirMensagemDeSucesso() {
        if (this.props && this.props.meuStore && this.props.meuStore.respostaGravarArquivo) {
            if (!this.props.meuStore.finalizado) {
                this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(false));
                this.props.dispatch(novoCargaLoteAction.setarEstadoModalGravarSucesso(true));
                this.props.dispatch(novoCargaLoteAction.setarFinalizado(true));
            }
        }
        if (this.props && this.props.meuStore && this.props.meuStore.respostaProcessarArquivo) {
            if (!this.props.meuStore.finalizado) {
                this.props.dispatch(novoCargaLoteAction.setarBuscandoTrue(false));
                this.props.dispatch(novoCargaLoteAction.setarEstadoModalProcessarSucesso(true));
                this.props.dispatch(novoCargaLoteAction.setarFinalizado(true));
            }
        }
    }
    obterModeloDeArquivoCargaLote() {
        return environment.modeloArquivoCargaConta;
    }
}

function mostrarGrid(props) {
    if (props) {
        if (props.meuStore) {
            if (props.meuStore.respostaPostConteudoArquivo) {
                return (
                    <div className="panel panel-default posicionamento_grid">
                        <div className="panel-body ">
                            <div className="list-group">
                                <div className="well col-md-12">
                                    <div>
                                        <div className="row">
                                            <b>{mostrarQuantidadeEValor(props)}</b>
                                        </div>
                                        <li className="list-group-item fundo-cinza-claro">
                                            <div className="row">
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Conta Origem</b></div>
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Titular Conta Origem</b></div>
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Valor</b></div>
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Conta Destino</b></div>
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Titular Conta Destino</b></div>
                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Descrição</b></div>
                                            </div>
                                        </li>
                                        {mostrarResultados(props)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }
        }
    }
}
function mostrarResultados(props) {
    return (
        props.meuStore.respostaPostConteudoArquivo.detalhes.map((registro, idx) => {
            return (
                <li key={idx} className="list-group-item">
                    <div className="row">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {registro.contaOrigem}
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {registro.titularContaOrigem}
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {'R$ ' + formataMoeda(registro.valor)}
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {registro.contaDestino}
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {registro.titularContaDestino}
                        </div>

                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe lote_grid_texto">
                            {registro.descricao}
                        </div>
                    </div>
                </li>
            );
        }
        )
    );
}
function mostrarQuantidadeEValor(props) {
    if (props.meuStore.respostaPostConteudoArquivo) {
        return (
            <div className="panel-heading col-md-10">
                <div className="col-md-5"> Quantidade de Transferência: {props.meuStore.respostaPostConteudoArquivo.quantidade}</div>
                <div className="col-md-5"> Valor Total: R$ {formataMoeda(props.meuStore.respostaPostConteudoArquivo.valorTotalCarga)}</div>
            </div>
        );
    }
}
function formataMoeda(valor) {
    var formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
    let valorFormatado = formatter.format(valor).replace('R$', '').replace('-', '');
    return valorFormatado;
}
function mapStateToProps(state) {
    return {
        meuStore: state.NovoCargaLoteReducer
    };
}
export default connect(mapStateToProps)(NovoCargaLote);