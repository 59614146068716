import React from 'react';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { LABEL_COMUNS } from '../../../../util/contantes-comuns/constantes-comuns';
import imagemExemploArquivoCSV from '../../../../../imagens/exemplo-arquivo-csv-pedido-cartao.png';

const ModalInstrucoesPedidoCartaoImpresso = props => {
    const corpoModal = () => {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span><strong>1. </strong><i><u>
                            <a href="https://s3.amazonaws.com/fenix-arquivos-publicos-prod/MODELO-DADOS-IMPRESSAO.csv" download>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_1}</a></u></i>
                        </span>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span><strong>2. </strong>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_2}</span><br />
                        <ul>
                            <li>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_2_1}</li>
                            <li>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_2_2}</li>
                            <li>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_2_3}</li>
                        </ul>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_2_4}</span>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div id="img-exemplo-csv-scroll-modal">
                            <img src={imagemExemploArquivoCSV} alt="Exemplo arquivo .CSV" />
                        </div>
                    </div>
                </div><br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span><strong>3. </strong>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_3}</span>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span><strong>4. </strong>{LABEL_COMUNS.MODAL_INSTRUCAO_PEDIDO_CARTAO_IMPRESSO.ITEM_4}</span>
                    </div>
                </div>
            </div>
        );
    };
    const rodapeModal = (props) => {
        return <FooterBotoesModalComponent
      contextoBppPag={props.contextoBppPag}
      parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
      btn2Label={LABEL_COMUNS.OK}
      btn2OnClick={() => props.setExibirModal(false)} />;
    };

    return (
        <div>
            <ModalComponent
        contextoBppPag={props.contextoBppPag}
        exibirModal={props.exibirModal}
        bsSize="lg"
        name="modal-instrucoes-pedido-cartao-impresso"
        header={LABEL_COMUNS.MODAL_EXEMPLO_ARQUIVO_CSV_HEADER}
        body={corpoModal()}
        footer={rodapeModal(props)} />
        </div>
    );
};

export default ModalInstrucoesPedidoCartaoImpresso;
