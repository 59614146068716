import { environment } from '../configuracoes/environment';
import { urlServicos } from './url-servicos';
import { reactLocalStorage } from 'reactjs-localstorage';

const API = environment.urlAPI;
const token = reactLocalStorage.get('token');

export function getPessoaId() {
    let url = urlServicos.PESSOAS + '?numeroDocumento=' + reactLocalStorage.get('usuario') + '&ordem=ASC&parametroOrdenacao=DATACRIACAO';

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    }).then((response) => response.json());
}

export function getContas(ultimaChave) {
    let idPessoa = reactLocalStorage.get('pessoaId');
    let url = urlServicos.CONTA.replace('{pessoaId}', idPessoa) + '?tamanhoPagina=1000';

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token

        }
    }).then((response) => response.json());
}

export function getExtratoConta(dataInicial, dataFinal) {
    let conta = reactLocalStorage.getObject('contaSelecionada').id;
    let url = urlServicos.EXTRATO_CONTA.replace('{contaDigitalId}', conta) + '?dataInicial=2018-01-01&dataFinal=2018-02-28';

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token,
        }
    }).then((response) => response.json());
}

export function getSaldoConta(contaId) {
    let url = urlServicos.SALDO_CONTA.replace('{contaDigitalId}', contaId);

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token

        }
    }).then((response) => response.json());
}

export function getCartoes() {
    let contaId = reactLocalStorage.getObject('contaSelecionada').id;
    let url = urlServicos.CARTOES(contaId) + `?tamanhoPagina=2000&posicao=${0}`;

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, parceiro',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => {

        return response.json();
    });
}

export function getExtratoCartao(dataInicial, dataFinal) {
    let conta = reactLocalStorage.getObject('contaSelecionada').id;
    let cartao = reactLocalStorage.getObject('cartaoSelecionado').cartaoId;
    let url = urlServicos.EXTRATO_CARTAO.replace('{contaDigitalId}', conta);
    url = url.replace('{cartaoId}', cartao) + '?dataInicial=2018-01-01&dataFinal=2018-02-28';

    return fetch(API + url, {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, parceiro',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => response.json());
}

export function getUsuarios() {
    return fetch(API + '/meiospagamento/arquivosCargas/usuarios', {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, parceiro',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token

        }
    }).then((response) => response.json());
}

export function getArquivosCargas(nomeArquivo, dataInicio, dataFim, usuario) {
    return fetch(API + '/meiospagamento/arquivosCargas?' +
    'nomeArquivo=' + nomeArquivo +
    '&dataInicio=' + dataInicio +
    '&dataFim=' + dataFim +
    '&usuario=' + usuario,
    {
        method: 'GET',
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, parceiro',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        }
    }).then((response) => response.json());
}

export function postFavorecido(dadosForm) {
    return fetch(API + urlServicos.FAVORECIDO, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept, Authorization, parceiro',
            'parceiro': 'BPP',
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': token
        },
        body: dadosForm
    }).then((response) => response.json());
}
