import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TextareaAutosize from 'react-autosize-textarea';
import Select from 'react-select';
import InputMask from 'react-input-mask';
import { FormGroup, Button, FormControl } from 'react-bootstrap';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import { MENSAGENS, LABEL_COMUNS, CAMPOS, TIPO_DE_PESSOA } from '../util/contantes-comuns/constantes-comuns';
import Grid from '../componentes-uteis/grid/grid';
import * as anexoActions from '../../action/anexo-contratual/anexo-contratual-action';
import * as operacaoActions from '../../action/operacao/operacao-action';
import If from '../componentes-uteis/if/if';
import { criarConta } from '../../servicos/contas/conta-service';
import { extrairMensagemDeRespostaBackend, isEmptyObject } from '../../servicos/base/service-base';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../comuns/showLineError/show-line-error';

class CriarConta extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = { anexoContratualOriginalId: '', apelidoConta: '', anexoSelecionadoIdErro: '' };
        this.state = this.ESTADO_INICIAL;
    }

    componentWillReceiveProps(nextProps) {
        const { listaDeAnexosContratuais, pessoaSelecionada } = nextProps;
        if (listaDeAnexosContratuais && listaDeAnexosContratuais.length) {
            if (listaDeAnexosContratuais.length === 1 && this.props.tipoPessoa === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
                this.setState({ anexoContratualOriginalId: listaDeAnexosContratuais[0].value });
            }
            if (this.props.tipoPessoa === TIPO_DE_PESSOA.PESSOA_FISICA) {
                this.setState({ anexoContratualOriginalId: listaDeAnexosContratuais[0].id });
            }
        }
    }

    render() {
        const { listaDeAnexosContratuais, nomePessoa, tipoPessoa } = this.props;
        return (
            <div>
                <ModalComponent
                    contextoBppPag={false}
                    exibirModal={this.props.exibirModal}
                    name="mdCriarConta"
                    header={LABEL_COMUNS.CRIAR_CONTA}
                    body={this.corpoModalCriarConta()}
                    footer={this.rodapeModalCriarConta()} />
            </div>
        );
    }

    criarConta = async () => {
        this.props.setExibirLoading(true);
        const respostaConta = await criarConta(this.state);
        this.props.setExibirLoading(false);
        const { error } = respostaConta;
        const mensagens = extrairMensagemDeRespostaBackend(respostaConta);

        if (error) {
            if (isEmptyObject(mensagens)) {
                this.props.setExibirModalCriarConta(false);
                this.props.setExibirModalFinalizacaoCriacaoConta(true);
                this.props.setMensagemFinalizacaoCriacaoConta(MENSAGENS.CONTAS.CRIACAO_CONTA.FINALIZACAO.ERRO);
            } else {
                this.setState(mensagens);
            }
        } else {
            this.props.setExibirModalCriarConta(false);
            this.props.setExibirModalFinalizacaoCriacaoConta(true);
            this.props.setMensagemFinalizacaoCriacaoConta(MENSAGENS.CONTAS.CRIACAO_CONTA.FINALIZACAO.SUCESSO);
            this.props.buscarContas();
            //this.setState(this.ESTADO_INICIAL);
        }

        //this.props.setExibirLoading(false);
    }

    corpoModalCriarConta() {
        return (
            <div className="row">
                <If test={this.props.tipoPessoa === TIPO_DE_PESSOA.PESSOA_JURIDICA}>
                    <Grid cols="12 12 12 12">
                        <strong>{LABEL_COMUNS.EMPRESA}</strong>
                        <FormGroup>
                            <InputMask id="empresas"
                                style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }}
                                className="form-control"
                                placeholder={LABEL_COMUNS.EMPRESA}
                                value={this.props.nomePessoa}
                                disabled
                            />
                        </FormGroup>
                    </Grid>
                    <Grid cols="12 12 12 12">
                        <strong>{LABEL_COMUNS.ANEXO_SOLUCAO}*</strong>
                        <FormGroup validationState={this.state.anexoContratualOriginalIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <Select
                                id="anexo_solucao"
                                value={this.state.anexoContratualOriginalId}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.ANEXO_CONTRATUAL_ORIGINAL_ID)}
                                options={this.props.listaDeAnexosContratuais}
                                placeholder={LABEL_COMUNS.SELECIONE_ANEXO_SOLUCAO}
                                noResultsText={LABEL_COMUNS.NENHUM_ANEXO_SOLUCAO}
                                style={this.state.anexoContratualOriginalIdErro ? { borderColor: '#a94442' } : { borderColor: '#e7e7e7   ' }}
                            />
                            <ShowLineError error={this.state.anexoContratualOriginalIdErro} />
                        </FormGroup>
                    </Grid>
                </If>
                <Grid cols="12 12 12 12">
                    <strong>{LABEL_COMUNS.APELIDO}</strong>
                    <FormGroup>
                        <InputMask id="apelido"
                            style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }}
                            className="form-control"
                            maxLength={20}
                            onChange={(e) => this.setPropsCampos(e, 'apelidoConta')}
                            placeholder={LABEL_COMUNS.APELIDO}
                        />
                    </FormGroup>
                </Grid>
            </div>
        );
    }

    rodapeModalCriarConta() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => this.props.setExibirModalCriarConta(false)}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn2OnClick={() => this.criarConta()}
            /*   btn2OnClick={() => {
                  this.props.criarConta(this.state);
                  this.props.setExibirModalCriarConta(false);
                  this.setState(this.ESTADO_INICIAL);
              }} */
            //  btn2Disabled={!this.state.anexoContratualOriginalId}
            />
        );
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        let valorDoCampo = campo && campo.value || campo && campo.target && campo.target.value;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }
}

function mapStateToProps(state) {
    return {
        anexoContratural: state.AnexoContratualReducer
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...anexoActions, ...operacaoActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(CriarConta);