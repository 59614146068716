import { environment }from 'configuracoes/environment';

import { reactLocalStorage } from 'reactjs-localstorage';

const { urlCoreWebShim, urlCoreBppPag, urlAPI } = environment;

export function bppWebShimFetch(url, options) {
    const defaultHeader = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'parceiro': 'BPP' } };

    options = { ...defaultHeader, ...options };
    const fetchUrl = `${urlCoreWebShim + url}`;
    return fetch(fetchUrl, options)
        .then(async response => {
            const result = ({ error: !response.ok, data: options.headers['Content-Type'] === 'application/json' ? await response.json() : await response.text(), response, request: { url, options } });
            return result;
        });
}

export function bppPagFetch(url, options) {
    const defaultHeader = { headers: { 'Content-Type': 'application/x-www-form-urlencoded'} };

    options = { ...defaultHeader, ...options };
    const fetchUrl = `${urlCoreBppPag + url}`;
    return fetch(fetchUrl, options)
        .then(async response => {
            const result = ({ error: !response.ok, data: options.headers['Content-Type'] === 'application/x-www-form-urlencoded' ? await response.json() : await response.text(), response, request: { url, options } });
            return result;
        });
}

export function bppFetch(url, options) {

    const authorization = { 'Authorization': reactLocalStorage.get('token') };

    const defaultHeader = { headers: { 'Accept': 'application/json', 'Content-Type': 'application/json', 'parceiro': 'BPP', 'Keep-Alive': 'timeout=25, max=100', ...authorization } };

    options = { ...defaultHeader, ...options };
    const fetchUrl = `${urlAPI + url}`;
    return fetch(fetchUrl, options)
        .then(async response => {
            const result = ({ error: !response.ok, data: options.headers['Content-Type'] === 'application/x-www-form-urlencoded' ? await response.json() : await response.text(), response, request: { url, options } });
            return result;
        });
}

