import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as actions from '../../../../../action/pedidos/pedidos-action';
import * as anexoContratualActions from '../../../../../action/anexo-contratual/anexo-contratual-action';
import { CONTA_SELECIONADA, LABEL_COMUNS, TIPO_DE_MOVIMENTACAO, COMBO, OPERACAO } from '../../../../util/contantes-comuns/constantes-comuns';
import { bindActionCreators } from 'redux';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import If from '../../../../componentes-uteis/if/if';
import Grid from '../../../../componentes-uteis/grid/grid';
import { formatarCampoValor } from '../../../../util/extrato/extrato-util';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import { FORMATO_DE_DATA_BR } from '../../../../util/data/dataUtil';

class ConsultaDePedidosBppCorp extends Component {
    componentDidMount() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();

        this.props.limparFiltroPedidos();
        this.props.limparPedidos();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getOperacoes(this.props.produtosIdsBppCorp);
        this.props.getAnexosContratuais(contaSelecionada.pessoaTitularId, this.props.produtosIdsBppCorp);
        /* if (this.props.consultaPedido.listaDePedidos.length <= 0) {
                                this.props.getPedidos(contaSelecionada.id, this.props.produtosIdsBppCorp, "", "", "", "", "", "");
                            } */
        this.props.getPedidos(contaSelecionada.id, this.props.produtosIdsBppCorp, '', '', '', '', '', '');
    }

    render() {
        return (
            <div>
                <Grid cols="12 12 12 12" className="alinhamento-centro">
                    <If test={this.props.consultaPedido.exibirLoading}>
                        <LoadingFenix />
                    </If>
                </Grid>
                <div>
                    {this.props.consultaPedido.contaSelecionada && <ContaSelecionada
                        contaSelecionada={this.props.consultaPedido.contaSelecionada}
                        mostrarFiltro
                        mostrarFiltroDeDatas
                        exibirFiltroDeAnexoContratual
                        exibirFiltroDeOperacao
                        exibirFiltroDeStatus
                        exibirFiltroDeValor

                        dataInicialLabel={LABEL_COMUNS.DATA_INICIAL}
                        dataInicialPlaceHolder={FORMATO_DE_DATA_BR}
                        dataInicialValue={this.props.consultaPedido.dataInicial}
                        dataIncialChange={this.props.setConsultaDePedidosDataInicial}
                        dataInicialErro={this.props.consultaPedido.dataInicialErro}

                        dataFinalLabel={LABEL_COMUNS.DATA_FINAL}
                        dataFinalPlaceHolder={FORMATO_DE_DATA_BR}
                        dataFinalValue={this.props.consultaPedido.dataFinal}
                        dataFinalChange={this.props.setConsultaDePedidosDataFinal}
                        dataFinalErro={this.props.consultaPedido.dataFinalErro}

                        anexoContratualSelecionadoId={this.props.consultaPedido.anexoContratualSelecionadoId}
                        setAnexoContratual={this.props.setAnexoContratualConsultaDePedidos}
                        listaDeAnexosContratuais={this.props.anexoContratual.listaDeAnexosContratuais}
                        operacaoSelecionadaId={this.props.consultaPedido.operacaoSelecionadaId}
                        setOperacao={this.props.setConsultaDePedidosOperacao}
                        listaDeOperacoes={this.props.consultaPedido.listaDeOperacoes}
                        valorDe={this.props.consultaPedido.valorDe}
                        setValorDe={(e) => this.props.setFiltroConsultaDePedidosPorValor({ data: { valorDe: e }, erro: { valorDeErro: '' } })}
                        valorAte={this.props.consultaPedido.valorAte}
                        setValorAte={(e) => this.props.setFiltroConsultaDePedidosPorValor({ data: { valorAte: e }, erro: { valorAteErro: '' } })}
                        listaDeStatus={this.props.consultaPedido.listaDeStatus}
                        setStatus={this.props.setConsultaDePedidosStatus}
                        statusSelecionado={this.props.consultaPedido.statusSelecionado}
                        statusErro={this.props.consultaPedido.statusErro}
                        exibirBotaoLimpar
                        limparFiltro={this.props.limparFiltroPedidos}
                        filtrar={() => this.props.getPedidos(this.props.consultaPedido.contaSelecionada.id,
                            this.props.produtosIdsBppCorp,
                            this.props.consultaPedido.dataInicial,
                            this.props.consultaPedido.dataFinal,
                            this.props.consultaPedido.anexoContratualSelecionadoId,
                            this.props.consultaPedido.valorDe,
                            this.props.consultaPedido.valorAte,
                            this.props.consultaPedido.operacaoSelecionadaId,
                            this.props.consultaPedido.statusSelecionado)} />}

                    <div className="row form-group">
                        <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                            <div className="panel panel-default">
                                <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.PEDIDOS.CONSULTA_DE_PEDIDOS}</div>
                                <div className="panel-body">
                                    <If test={this.props.consultaPedido.listaDePedidos.length > 0}>
                                        <div className="list-group">
                                            <li className="list-group-item fundo-cinza-claro">
                                                <div className="row">
                                                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                                        <b><span className="titulo-tabelas">Pedido</span></b>
                                                    </div>
                                                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                                        <b><span className="titulo-tabelas">Data/Hora</span></b>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                        <b><span className="titulo-tabelas">Nome do Anexo</span></b>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                        <b><span className="titulo-tabelas">Lançamento Financeiro</span></b>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                        <b><span className="titulo-tabelas">Quantidade</span></b>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                        <b><span className="titulo-tabelas">Status</span></b>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                        <b><span className="titulo-tabelas">Valor</span></b>
                                                    </div>
                                                </div>
                                            </li>
                                            {this.props.consultaPedido.listaDePedidos.map((linhaConsulta, idx) => {
                                                return (
                                                    <li key={idx} className="list-group-item">
                                                        <div className="row">
                                                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                                                <span className="texto-extrato-detalhe">{linhaConsulta.pedidoFelId}</span>
                                                            </div>
                                                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                                                <span className="texto-extrato-detalhe">{linhaConsulta.dataCriacao}</span>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                                <span className="texto-extrato-detalhe">{linhaConsulta.nomeAnexoContratual}</span>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                                <span className="texto-consulta-pedido">{linhaConsulta.lancamentoFinanceiroExibicao}</span>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                                <span className="texto-extrato-detalhe">{linhaConsulta.quantidade}</span>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                                <span className="texto-extrato-detalhe">{linhaConsulta.statusExibicao}</span>
                                                            </div>
                                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                                <span className="texto-extrato-detalhe">{formatarCampoValor(TIPO_DE_MOVIMENTACAO.DEBITO, linhaConsulta.valor)}</span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                );
                                            }
                                            )}
                                            <If test={this.props.consultaPedido.exbirMaisRegistros}>
                                                <Button type="button" bsStyle="default"
                                                    className="btn btn-default btn-block fonte-tamanho-11"
                                                    onClick={() => this.props.getPedidos(this.props.consultaPedido.contaSelecionada.id, '', '',
                                                        '', '', '', '', '', this.props.consultaPedido.ultimaChave, this.props.consultaPedido.listaDePedidos)}>
                                                    {LABEL_COMUNS.PEDIDOS.VISUALIZAR_MAIS_PEDIDOS}
                                                </Button>
                                                <br />
                                            </If>
                                        </div>
                                    </If>
                                    <If test={this.props.consultaPedido.listaDePedidos.length <= 0}>
                                        <span>{LABEL_COMUNS.PEDIDOS.NAO_HA_ITENS_CONSULTA_PEDIDOS}</span>
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br />
                    <Grid cols="11 11 11 11" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                        <br /><br />
                    </Grid>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>
            </div>

        );
    }
}

function mapStateToProps(state) {
    return {
        consultaPedido: state.PedidosReducer,
        anexoContratual: state.AnexoContratualReducer
    };
}
const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, ...anexoContratualActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaDePedidosBppCorp);
