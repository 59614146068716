
import { FILTROS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../../servicos/url-servicos';
import PrestacaoContaViewModel from '../../componentes/prestacao-contas/gerenciar/prestacao-conta-view-model';
import { setLinhaDoExtratoSelecionada } from '../../action/extrato/extrato-action';
export const fetchGetExtrato = async (contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina) => {
    return await fetch(montaUrlFetch(urlServicos.EXTRATO(contaId), FILTROS.EXTRATO(dataInicial, dataFinal, ultimaChave, tamanhoPagina)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async (resp) => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

// const prestacoes = await getExtratoInterno().then(({ data }) => data.map(linha =>
// new PrestacaoContaViewModel({ id: linha.id, dadosExtrato: new ExtratoViewModel({}) }))) ;
