import { Button } from 'react-bootstrap';
import React from 'react';
import Grid from '../grid/grid';
import If from '../if/if';

import imagePDF from '../../../imagens/pdf.png';

const classFooterContext = props => {
    if (props.contextoBppPag) {
        return 'success';
    }
    return 'primary';
};

const classFooterPrimaryButton = props => {
    if (props.isPrimary) {
        return 'primary';
    }
    return 'default';
};

const FooterBotoesModalComponent = props => (
    <div className={props.parentClassName ? props.parentClassName : 'row'}>
        <Grid cols={props.cols}>
            <div className={props.chieldClassName ? props.chieldClassName : 'alinhamento-direita'}>
                <If test={props.btnPDFOnClick}>
                    <div style={{ cursor: 'pointer' }} onClick={props.btnPDFOnClick}>
                        <span style={{ position: 'relative', float: 'left', paddingTop: 5 }}>{props.btnPDFLabel}</span>
                        <img src={imagePDF} style={{ position: 'relative', float: 'left', cursor: 'pointer', width: 20, height: 20, margin: 5 }} />
                    </div>
                </If>
                <If test={props.btn1OnClick}>
                    <Button onClick={props.btn1OnClick} disabled={props.btn1Disabled} bsStyle={classFooterPrimaryButton(props)}>{props.btn1Label}</Button>&nbsp;&nbsp;
                </If>
                <If test={props.btn2OnClick}>
                    <Button onClick={props.btn2OnClick} disabled={props.btn2Disabled} bsStyle={classFooterContext(props)}>{props.btn2Label}</Button>
                </If>
                <If test={props.btn3OnClick}>
                    <Button onClick={props.btn3OnClick} disabled={props.btn3Disabled} bsStyle={classFooterContext(props)}>{props.btn3Label}</Button>
                </If>
            </div>
        </Grid>
    </div>
);

export default FooterBotoesModalComponent;
