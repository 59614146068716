import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    pessoaRecuperada: undefined, exibirLoadingPessoa: false, listaDePrestacaoDeContasClassificacoes: [], listaDePrestacaoDeContasProjetos: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case METODOS.PESSOA.GET_PESSOA_POR_DOCUMENTO:
            return { ...state, pessoaRecuperada: payload.registros[0], buscaDePessoaRealizada: true };
        case METODOS.PESSOA.GET_PESSOA_POR_DOCUMENTO_ERRO:
            return { ...state, erroNaBuscaDePessoaPorDocumento: payload, buscaDePessoaRealizada: true };
        case METODOS.PESSOA.SET_EXIBIR_LOADING_PESSOA:
            return { ...state, exibirLoadingPessoa: payload };
        case METODOS.PESSOA.SET_LIMPAR_PESSOA_RECUPERADA:
            return { ...state, pessoaRecuperada: undefined };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES:
            return { ...state, listaDePrestacaoDeContasClassificacoes: payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES_ERROS:
            return { ...state, errosNaBuscaDePrestacaoDeContasClassificacoes: payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS:
            return { ...state, listaDePrestacaoDeContasProjetos: payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS_ERROS:
            return { ...state, errosNaBuscaDePrestacaoDeContasProjetos: payload };
        default:
            return state;
    }
};

export const getPessoaRecuperada = (state) => ({ pessoaRecuperada: state.pessoaRecuperada });

export const getExibirLoadingPessoa = (state) => ({ exibirLoadingPessoa: state.exibirLoadingPessoa });
