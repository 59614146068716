import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { CPF } from 'cpf_cnpj';
import emailValidator from 'email-validator';
import InputMask from 'react-input-mask';
import { PickList } from 'primereact/picklist';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { CAMPOS, LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, TIPO_DOCUMENTO } from '../../util/contantes-comuns/constantes-comuns';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import Grid from '../../componentes-uteis/grid/grid';
import GridRow from '../../componentes-uteis/grid/grid-row';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../comuns/showLineError/show-line-error';
import { trim } from '../../util/string/string-util';
import { getExibirLoadingConta, getInserirPoderesConta } from '../../../reducer/contas/contas-reducer';
import { retornaObjetoTarget } from '../../../servicos/base/service-base';
import ModalExibirPerfilDetalhes from './modal-exibir-perfil-detalhes';


class ModalInserirPoderPessoaConta extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL_CAMPOS = {
            celular: '', celularErro: '', email: '', emailErro: '', mascaraDocumento: MASCARA_CAMPOS.CPF, nome: '', nomeErro: '',
            tipoDocumento: TIPO_DOCUMENTO.CPF.value, numeroDocumento: '', numeroDocumentoErro: '', exibirLoading: false,
            contaSelecionada: this.props.contaSelecionada, contaId: this.props.contaSelecionada.id, disableCelular: false,
            disableEmail: false, disableNome: false, exibirModalFinalizacao: false, exibirModalConfirmacao: false,
            permissoesConcedidasErro: undefined, mensagemConfirmacao: undefined
        };
        this.state = {
            perfis: [], permissoesConcedidas: [], ...this.ESTADO_INICIAL_CAMPOS
        };
    }


    componentDidMount() {
        const { contaSelecionada, listaDePerfisOutorga, listaDePoderesOutorga } = this.props;
        this.setState({ contaSelecionada, perfis: listaDePerfisOutorga, listaDePoderesOutorga });
    }


    componentWillReceiveProps(nextProps) {
        if (nextProps.estadoInserirPoderConta.buscaDePessoaRealizada) {
            this.limpaDadosPessoa();
        }

        if (nextProps.estadoInserirPoderConta.inserirPoderes) {
            if (nextProps.estadoInserirPoderConta.inserirPoderes !== this.props.estadoInserirPoderConta.inserirPoderes) {
                if (nextProps.estadoInserirPoderConta.inserirPoderes.outorgaId && nextProps.estadoInserirPoderConta.inserirPoderes.outorgaId !== this.props.estadoInserirPoderConta.inserirPoderes.outorgaId) {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                    this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
                    if (nextProps.estadoInserirPoderConta.inserirPoderes.sucesso) {
                        this.setPropsCampos({ target: { value: MENSAGENS.CONTAS.EDICAO.INSERIR_OUTORGA_PESSOA.FINALIZACAO.SUCESSO } }, CAMPOS.MENSAGEM_FINALIZACAO);
                    }
                } else {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                    this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
                    this.setPropsCampos({ target: { value: nextProps.estadoInserirPoderConta.inserirPoderes.mensagem } }, CAMPOS.MENSAGEM_FINALIZACAO);
                }
            }
        }
    }

    limparForm = () => {
        this.setState(this.ESTADO_INICIAL_CAMPOS);
        this.setState({
            perfis: this.props.listaDePerfisOutorga,
            permissoesConcedidas: []
        });
    }


    limpaDadosPessoa = () => {
        this.setState({ celular: '', email: '', nome: '', disableCelular: false, disableEmail: false, disableNome: false, exibirModalPerfilDetalhes: false });
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        const valorDoCampo = campo.target && campo.target.value;
        if (nomeDoCampo === CAMPOS.NUMERO_DOCUMENTO) {
            this.setState({ disableCelular: false, disableEmail: false, disableNome: false });
        }
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }

    validarDados = () => {
        let erro = false;

        if (!CPF.isValid(this.state.numeroDocumento)) {
            this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.CPF) } }, CAMPOS.ERROS.NUMERO_DOCUMENTO_ERRO);
            erro = true;
        }

        if (!trim(this.state.nome)) {
            this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.NOME) } }, CAMPOS.ERROS.NOME_ERRO);
            erro = true;
        }

        if (!this.state.celular || this.state.celular.length < 15) {
            this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.CELULAR) } }, CAMPOS.ERROS.CELULAR_ERRO);
            erro = true;
        }

        if (!emailValidator.validate(this.state.email)) {
            this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(LABEL_COMUNS.EMAIL) } }, CAMPOS.ERROS.EMAIL_ERRO);
            erro = true;
        }

        if (!this.state.permissoesConcedidas.length) {
            this.setState({ permissoesConcedidasErro: MENSAGENS.VALIDACAO_DE_CAMPOS.SELECIONE_AO_MENOS_UMA_PERMISSAO });
            erro = true;
        }

        return erro;
    }

    onChange = (event) => {
        this.setState({
            perfis: event.source, permissoesConcedidas: event.target,
            permissoesConcedidasErro: event.target ? '' : this.state.permissoesConcedidasErro
        });
    }

    renderItems = (item) => {
        return (
            <div>
                {item.nomeExibicao}
            </div>
        );
    }

    salvarAutorizado = () => {
        let erro = this.validarDados();

        if (!erro) {
            const { numero, digito } = this.state.contaSelecionada;
            this.setPropsCampos(retornaObjetoTarget(MENSAGENS.CONTAS.EDICAO.INSERIR_OUTORGA_PESSOA.CONFIRMACAO(this.state.nome.toUpperCase(), numero, digito)), CAMPOS.MENSAGEM_CONFIRMACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
        }
    }

    retornaCorpoModal = () => {

        return (
            <div>
                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.CONTAS.TITULOS.INCLUIR_AUTORIZADO}
                    body={this.state.mensagemConfirmacao}
                    footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent
                    exibirModal={this.state.exibirModalFinalizacao}
                    name="modal-cancelamento"
                    header={LABEL_COMUNS.CONTAS.TITULOS.INCLUIR_AUTORIZADO}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()} />

                <GridRow>
                    <Grid cols="3 3 3 3">
                        <ControlLabel>{LABEL_COMUNS.CPF}*</ControlLabel>
                        <FormGroup validationState={this.state.numeroDocumentoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                                className="mensagens-erro form-control"
                                mask={this.state.mascaraDocumento} maskChar=""
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                placeholder={LABEL_COMUNS.CPF}
                                maxLength="50"
                                value={this.state.numeroDocumento}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_DOCUMENTO)}
                            />
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.numeroDocumentoErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="6 6 6 6">
                        <ControlLabel>{LABEL_COMUNS.NOME}*</ControlLabel>
                        <FormGroup validationState={this.state.nomeErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                type="text"
                                id="nome"
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                className="mensagens-erro form-control"
                                placeholder={LABEL_COMUNS.NOME}
                                value={this.state.nome}
                                maxLength={100}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.NOME)}
                            />
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.nomeErro} />
                        </FormGroup>
                    </Grid>
                </GridRow>
                <GridRow>
                    <Grid cols="3 3 3 3">
                        <ControlLabel>{LABEL_COMUNS.CELULAR}*</ControlLabel>
                        <FormGroup validationState={this.state.celularErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                                className="mensagens-erro form-control"
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                placeholder={LABEL_COMUNS.CELULAR}
                                maxLength="50"
                                disabled={this.state.disableCelular}
                                value={this.state.celular}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.CELULAR)}
                            />
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.celularErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="6 6 6 6">
                        <ControlLabel>{LABEL_COMUNS.EMAIL}*</ControlLabel>
                        <FormGroup validationState={this.state.emailErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputGroup>
                                <InputGroup.Addon>@</InputGroup.Addon>
                                <FormControl className="mensagens-erro" id="digito" type="text"
                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                    className="form-control mensagens-erro" placeholder="Email"
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.EMAIL)}
                                    disabled={this.state.disableEmail}
                                    maxLength={70}
                                    value={this.state.email}
                                />
                            </InputGroup>
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.emailErro} />
                        </FormGroup>
                    </Grid>
                </GridRow>
                <GridRow>
                    <Grid cols="10 10 10 10">
                        <ControlLabel>{LABEL_COMUNS.CONTAS.TITULOS.PERMISSOES}*</ControlLabel>
                        <PickList
                            source={this.state.perfis}
                            target={this.state.permissoesConcedidas}
                            itemTemplate={this.renderItems}
                            onChange={this.onChange}
                            showSourceControls={false}
                            showTargetControls={false}
                            responsive={true}
                            style={{ width: '800px' }}
                        />
                        <ShowLineError error={this.state.permissoesConcedidasErro} />
                    </Grid>
                </GridRow>
                {/*   <GridRow>
                    <Grid cols="11 11 11 11">
                        <a href="#" onClick={() => this.setExibirModalPerfilDetalhes(true)}> {LABEL_COMUNS.VISUALIZAR_INFORMACOES_DOS_PERFIS}</a>
                    </Grid>
                </GridRow> */}
            </div>
        );
    }

    retornaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                    this.props.insereAutorizadoEPoderes(this.state);
                }} />
        );
    }

    retornaRodapeModalFinalizacao() {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.props.getAutorizadosDaConta(this.state.contaId);
                    this.props.setExibirModalInserirPoderPessoa(false);
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
                    this.limparForm();
                    this.setState({ abrirAutorizacoes: false });
                }} />
        );
    }
    render

    retornaRodapeModal = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.limparForm();
                    this.props.setExibirModalInserirPoderPessoa(false);
                }}
                btn2Label={LABEL_COMUNS.SALVAR}
                btn2OnClick={() => this.salvarAutorizado()}
            />
        );
    }

    setExibirModalPerfilDetalhes = (exibirModalPerfilDetalhes) => {
        this.setState({ exibirModalPerfilDetalhes });
    }

    render() {
        return (
            <div>
                <ModalComponent
                    bsSize="lg"
                    exibirModal={this.props.exibirModalInserirPoderPessoa}
                    header={LABEL_COMUNS.CONTAS.TITULOS.INCLUIR_AUTORIZADO}
                    body={this.retornaCorpoModal()}
                    footer={this.retornaRodapeModal()}
                />
                <ModalExibirPerfilDetalhes
                    exibirModalPerfilDetalhes={this.state.exibirModalPerfilDetalhes}
                    setExibirModalPerfilDetalhes={this.setExibirModalPerfilDetalhes}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    estadoInserirPoderConta: { ...state.PessoaReducer, ...getExibirLoadingConta(state.ContasReducer), ...getInserirPoderesConta(state.ContasReducer) }
});

//const mapDispatchToProps = dispatch => bindActionCreators({ getAutorizadosDaConta, insereAutorizadoEPoderes, getPessoaPorDocumento }, dispatch);

export default connect(mapStateToProps, null)(ModalInserirPoderPessoaConta);

//export default ModalInserirPoderPessoaConta;