import React, { Component } from 'react';

export default class ListaCheck extends Component {
    constructor(props) {
        super(props);
        this.state = {
            left: '24px',
            top: '8px'
        };
    }

    render() {
        return (
            <span
        style={{
          zIndex: 100,
          position: 'absolute',
          left: this.state.left,
          top: this.state.top,
          fontSize: '40px',
          cursor: 'pointer',
          color: this.props.marcarCheck ? '#3f84c7' : '#fff',
          display: 'inline',
          textShadow: `
                    -1px -1px 0 #3f84c7,  
                    1px -1px 0 #3f84c7,
                    -1px 1px 0 #3f84c7,
                    1px 1px 0 #3f84c7`

        }}
        title="Marcador"
        onClick={e => this.props.handleMarcarCheck(this.props.marcarCheckId)}
                >
        &bull;
            </span>
        );
    }
}
