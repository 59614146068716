import iconv from 'iconv-lite';

export const handleCharset = (file, callback) => {
    const fr = new FileReader();

    fr.onloadend = e => {
        let decodeUtf8 = iconv.decode(Buffer.from(fr.result), 'utf8');
        let decodeIso = iconv.decode(Buffer.from(fr.result), 'iso88591');

        if (decodeUtf8.indexOf(iconv.defaultCharUnicode) > -1) {
            callback(decodeIso);
        } else {
            callback(decodeUtf8);
        }
    };

    fr.readAsArrayBuffer(file);
};

export const fileDecodified = (arrayBufferToDecodified) => {
    const decodedUtf8 = iconv.decode(Buffer.from(arrayBufferToDecodified), 'utf8');
    const decodedIso = iconv.decode(Buffer.from(arrayBufferToDecodified), 'iso88591');
    if (decodedUtf8.indexOf(iconv.defaultCharUnicode) > -1) {
        return decodedIso;
    }
    return decodedUtf8;
};

export const convertCharsetToUtf8 = (file, mimeType, callback) => (
    handleCharset(file, (result) => callback(
        new File([new Blob([result], { type: `${mimeType};charset=utf-8` })], file.name))
    )
);
