import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import {
    EXPORT_EXCEL,
    GET_TOKEN,
    LABEL_COMUNS,
    MENSAGENS,
    NOME_MESES
} from 'componentes/util/contantes-comuns/constantes-comuns';
import Grid from 'componentes/componentes-uteis/grid/grid';
import If from 'componentes/componentes-uteis/if/if';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import {
    getFirstDayByYearAndMonth,
    getLastDayCurrentByYearAndMonth,
    getLastSixMonthCombo
} from 'componentes/util/data/dataUtil';
import { getReportCardManagement } from 'servicos/relatorios/relatorios-bpp-corp-service';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';

const ZERO = '0';


class RelatorioGerencialBppCorp extends Component {
    constructor(props) {
        super(props);
        this.ZERO_VALUE = 'R$ 0,00';
        this.INITIAL_STATE = {
            balanceDays: { firstDay: { totalAmount: this.ZERO_VALUE, day: '' }, lastDay: { totalAmount: this.ZERO_VALUE, day: '' } },
            contaSelecionada: this.props.history.location.state.contaSelecionada, showLoading: false, showModal: false, messageError: '', messageErrorCSV: '',
            selectdDay: 0, selectedDate: this.generateCurrentSelectedDate(), listOfMoths: getLastSixMonthCombo(), mesFoiAlterado: false,
            selectedToken: GET_TOKEN(), transactions: {
                reload: { description: '', amount: '', total: undefined },
                unload: { description: '', amount: '', total: undefined },
                purchase: { description: '', status: { approved: { description: '', amount: '', total: undefined }, rejected: { description: '', amount: '', total: undefined } } },
                withdraw: { description: '', status: { approved: { description: '', amount: '', total: undefined }, rejected: { description: '', amount: '', total: undefined } } },
                fess: { description: '', amount: '', total: undefined },
                reversal: { description: '', amount: '', total: undefined },
                accountability: {
                    description: '',
                    status: {
                        approved: { description: '', amount: '', total: undefined }, rejected: { description: '', amount: '', total: undefined },
                        awaitingApproval: { description: '', amount: '', total: undefined }, pending: { description: '', amount: '', total: undefined }
                    }
                }
            }
        };
        this.state = this.INITIAL_STATE;
    }

    downloadCSV() {
        var reportName = 'relatorio-gerencial.csv';
        var data, link;
        var dados = this.getDadosItem();
        if (dados === 'error') {
            this.setMessageErrorCSV(MENSAGENS.REPORTS.MANAGEMENT.MSG_ERRO_CSV);
            this.setShowModal(true);
        }
        else {
            var jsonObject = JSON.stringify(dados);
            let csvConverted = EXPORT_EXCEL.CONVERTER_ARRAY_PARA_CSV(jsonObject);
            var BOM = '\uFEFF';
            data = 'data:text/csv;charset=UTF-8,' + BOM +this.geraCabecalho() + encodeURIComponent(csvConverted);
            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', reportName);
            link.click();
        }
    }
    geraCabecalho() {
        return `${LABEL_COMUNS.CARTOES.TITULOS.PADRAO};Status;Quantidade;Valor \r\n`;
    }

    getDadosItem() {
        var gerencialData = this.state.transactions;
        const { selectedDate, balanceDays: { totalCardsAmountFirstDay, totalCardsAmountLastDay } } = this.state;
        if (!gerencialData || !this.state.selectedDate || !this.state.balanceDays) {
            return 'error';
        }
        else {
            const firstDay = getFirstDayByYearAndMonth(selectedDate.value.getFullYear(), selectedDate.value.getMonth());
            const lastDay = getLastDayCurrentByYearAndMonth(selectedDate.value.getFullYear(), selectedDate.value.getMonth());
            var resultado = [];
            var CARTOES = LABEL_COMUNS.CARTOES.TITULOS.PADRAO;
            var STATUS = LABEL_COMUNS.STATUS;
            var QUANTIDADE = LABEL_COMUNS.QUANTIDADE;
            var VALOR = LABEL_COMUNS.VALOR;

            var item = {
                CARTOES: 'Saldo inicial',
                STATUS: firstDay,
                QUANTIDADE: '',
                VALOR: `"${totalCardsAmountFirstDay}"`
            };
            resultado.push(item);


            item = {
                CARTOES: !gerencialData.reload.description ? '' : gerencialData.reload.description,
                STATUS: '',
                QUANTIDADE: !gerencialData.reload.total ? ZERO : gerencialData.reload.total,
                VALOR: !gerencialData.reload.amount ? '' : `"${gerencialData.reload.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.unload.description ? '' : gerencialData.unload.description,
                STATUS: '',
                QUANTIDADE: !gerencialData.unload.total ? ZERO : gerencialData.unload.total,
                VALOR: !gerencialData.unload.amount ? '' : `"${gerencialData.unload.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.purchase.description ? '' : gerencialData.purchase.description,
                STATUS: !gerencialData.purchase.status.approved.description ? '' : gerencialData.purchase.status.approved.description,
                QUANTIDADE: !gerencialData.purchase.status.approved.total ? ZERO : gerencialData.purchase.status.approved.total,
                VALOR: !gerencialData.purchase.status.approved.amount ? '' : `"${gerencialData.purchase.status.approved.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.purchase.description ? '' : gerencialData.purchase.description,
                STATUS: !gerencialData.purchase.status.rejected.description ? '' : gerencialData.purchase.status.rejected.description,
                QUANTIDADE: !gerencialData.purchase.status.rejected.total ? ZERO : gerencialData.purchase.status.rejected.total,
                VALOR: !gerencialData.purchase.status.rejected.amount ? '' : `"${gerencialData.purchase.status.rejected.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.withdraw.description ? '' : gerencialData.withdraw.description,
                STATUS: !gerencialData.withdraw.status.approved.description ? '' : gerencialData.withdraw.status.approved.description,
                QUANTIDADE: !gerencialData.withdraw.status.approved.total ? ZERO : gerencialData.withdraw.status.approved.total,
                VALOR: !gerencialData.withdraw.status.approved.amount ? '' : `"${gerencialData.withdraw.status.approved.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.withdraw.description ? '' : gerencialData.withdraw.description,
                STATUS: !gerencialData.withdraw.status.rejected.description ? '' : gerencialData.withdraw.status.rejected.description,
                QUANTIDADE: !gerencialData.withdraw.status.rejected.total ? ZERO : gerencialData.withdraw.status.rejected.total,
                VALOR: !gerencialData.withdraw.status.rejected.amount ? '' : `"${gerencialData.withdraw.status.rejected.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.fess.description ? '' : gerencialData.fess.description,
                STATUS: '',
                QUANTIDADE: !gerencialData.fess.total ? ZERO : gerencialData.fess.total,
                VALOR: !gerencialData.fess.amount ? '' : `"${gerencialData.fess.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.reversal.description ? '' : gerencialData.reversal.description,
                STATUS: '',
                QUANTIDADE: !gerencialData.reversal.total ? ZERO : gerencialData.reversal.total,
                VALOR: !gerencialData.reversal.amount ? '' : `"${gerencialData.reversal.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.accountability.description ? '' : gerencialData.accountability.description,
                STATUS: !gerencialData.accountability.status.approved.description ? '' : gerencialData.accountability.status.approved.description,
                QUANTIDADE: !gerencialData.accountability.status.approved.total ? ZERO : gerencialData.accountability.status.approved.total,
                VALOR: !gerencialData.accountability.status.approved.amount ? '' : `"${gerencialData.accountability.status.approved.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.accountability.description ? '' : gerencialData.accountability.description,
                STATUS: !gerencialData.accountability.status.rejected.description ? '' : gerencialData.accountability.status.rejected.description,
                QUANTIDADE: !gerencialData.accountability.status.rejected.total ? ZERO : gerencialData.accountability.status.rejected.total,
                VALOR: !gerencialData.accountability.status.rejected.amount ? '' : `"${gerencialData.accountability.status.rejected.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.accountability.description ? '' : gerencialData.accountability.description,
                STATUS: !gerencialData.accountability.status.awaitingApproval.description ? '' : gerencialData.accountability.status.awaitingApproval.description,
                QUANTIDADE: !gerencialData.accountability.status.awaitingApproval.total ? ZERO : gerencialData.accountability.status.awaitingApproval.total,
                VALOR: !gerencialData.accountability.status.awaitingApproval.amount ? '' : `"${gerencialData.accountability.status.awaitingApproval.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: !gerencialData.accountability.description ? '' : gerencialData.accountability.description,
                STATUS: !gerencialData.accountability.status.pending.description ? '' : gerencialData.accountability.status.pending.description,
                QUANTIDADE: !gerencialData.accountability.status.pending.total ? ZERO : gerencialData.accountability.status.pending.total,
                VALOR: !gerencialData.accountability.status.pending.amount ? '' : `"${gerencialData.accountability.status.pending.amount}"`
            };
            resultado.push(item);

            item = {
                CARTOES: 'Saldo final',
                STATUS: lastDay,
                QUANTIDADE: '',
                VALOR: `"${totalCardsAmountLastDay}"`
            };
            resultado.push(item);
            return resultado;
        }
    }

    componentDidMount = async () => {
        this.setSelectedDate(this.generateCurrentSelectedDate());
        var primeiraEntrada;
        await this.getReportCardManagement(true);
    }

    generateCurrentSelectedDate = () => {
        const today = new Date();
        const selectedDate = { value: today, label: NOME_MESES[today.getMonth()] + ' ' + today.getFullYear() };
        return selectedDate;
    }

    getReportCardManagement = async (primeiraEntrada) => {
        this.setShowLoading(true);
        const response = await getReportCardManagement(this.state);
        const { error } = response;

        if (error) {
            if (primeiraEntrada) { this.setState({ showModal: true, messageError: MENSAGENS.REPORTS.MANAGEMENT.ERROR }); }
        }
        else {
            const { balanceDays, transactions } = response;
            this.setBalanceDays(balanceDays);
            this.setTransactions(transactions);
        }
        this.setShowLoading(false);
    }


    setBalanceDays = (balanceDays) => {
        this.setState({ balanceDays });
    }

    setFirstDay = (firstDay) => {
        this.setState({ firstDay });
    }

    setLastDay = (lastDay) => {
        this.setState({ lastDay });
    }

    setMessageError = (messageError) => {
        this.setState({ messageError });
    }

    setMessageErrorCSV = (messageErrorPDF) => {
        this.setState({ messageErrorPDF });
    }

    setSelectedDate = (selectedDate) => {
        var primeiraEntrada;
        this.setState({ selectedDate }, () => {
            const primeiraEntrada = false;
            this.getReportCardManagement(primeiraEntrada);
        });
    }

    setShowLoading = (showLoading) => {
        this.setState({ showLoading });
    }

    setShowModal = (showModal) => {
        this.setState({ showModal });
    }

    setTransactions = (transactions) => {
        this.setState({ transactions });
    }

    getFooterModal = (messageError) => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setShowModal(false);
                    if (messageError) {
                        this.props.history.push(ROTAS_CAMINHOS.DASHBOARD_RELATORIOS_BPP_CORP);
                    }
                }}
            />
        );
        this.setMessageError('');
        this.setMessageErrorCSV('');
    }

    render() {
        const { messageErrorPDF, balanceDays, showLoading, listOfMoths, messageError, selectedDate, showModal, transactions: { reload, unload, purchase, fess, withdraw, reversal, accountability } } = this.state;

        return (
            <div>
                <If test={showLoading}>
                    <LoadingFenix />
                </If>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                    <div style={{ minWidth: '600px' }} className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                        <ModalComponent
                            header={LABEL_COMUNS.RELATORIOS.RELATORIO_GERENCIAL}
                            exibirModal={showModal}
                            body={messageError || messageErrorPDF}
                            footer={this.getFooterModal(messageError)}
                        />
                        <div className="panel panel-default" style={{ fontSize: 12 }}>
                            <div className=" panel-heading fundo-azul-claro" style={{ fontWeight: 'bold' }}>{LABEL_COMUNS.RELATORIOS.RELATORIO_GERENCIAL}</div>
                            <div>
                                <Select id="mes"
                                    style={{ fontWeight: '600' }}
                                    placeholder={LABEL_COMUNS.SELECIONE}
                                    ref={(ref) => { this.select = ref; }}
                                    value={selectedDate}
                                    clearable={false}
                                    onChange={(selectValue) => this.setSelectedDate(selectValue)}
                                    options={listOfMoths}
                                />
                            </div>
                            <div className="panel-body" style={{ padding: 0 }}>
                                <If test={messageError && !showModal}>
                                    <p>{messageError}</p>
                                </If>
                                <If test={!messageError}>
                                    <div>
                                        <Grid customStyle={{ backgroundColor: '#f6f9fc', minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="negrito alinhamento-esquerda">{LABEL_COMUNS.CARTOES.TITULOS.PADRAO}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="negrito alinhamento-centro">{LABEL_COMUNS.STATUS}</Grid>
                                                <Grid cols="2 2 2 2" className="negrito alinhamento-direita">{LABEL_COMUNS.QUANTIDADE}</Grid>
                                                <Grid cols="5 5 5 5" className="negrito alinhamento-direita">{LABEL_COMUNS.VALOR}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /><br /><br />
                                        <Grid customStyle={{ minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="4 4 4 4" className="negrito alinhamento-esquerda" customStyle={{ fontWeight: 'bold', color: '#3F84C7' }}>{LABEL_COMUNS.SALDO_INICIAL} {getFirstDayByYearAndMonth(selectedDate.value.getFullYear(), selectedDate.value.getMonth())}</Grid>
                                            <Grid cols="8 8 8 8">
                                                <Grid cols="5 5 5 5" />
                                                <Grid cols="2 2 2 2" />
                                                <Grid cols="5 5 5 5" className="alinhamento-direita" customStyle={{ fontWeight: 'bold', color: '#3F84C7' }}>{balanceDays.totalCardsAmountFirstDay}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /><br /><br />
                                        <Grid customStyle={{ backgroundColor: '#f6f9fc', minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">{LABEL_COMUNS.CARGA}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">-</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{reload !== null && reload !== undefined ? reload.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{reload !== null && reload !== undefined ? reload.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /><br /><br />
                                        <Grid customStyle={{ minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda" >{LABEL_COMUNS.DESCARGA}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">-</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{unload !== null && unload !== undefined ? unload.total : 'R$ 0,00'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{unload !== null && unload !== undefined ? unload.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /><br /><br />
                                        <Grid customStyle={{ backgroundColor: '#f6f9fc', minHeight: 99, paddingTop: 27 }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda" customStyle={{ paddingTop: 18 }}>{LABEL_COMUNS.COMPRA}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{purchase !== null && purchase !== undefined ? purchase.status.approved.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{purchase !== null && purchase !== undefined ? purchase.status.approved.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{purchase !== null && purchase !== undefined ? purchase.status.approved.amount : 'R$ 0,00'}</Grid>
                                                <br /><br />
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{purchase !== null && purchase !== undefined ? purchase.status.rejected.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{purchase !== null && purchase !== undefined ? purchase.status.rejected.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{purchase !== null && purchase !== undefined ? purchase.status.rejected.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /> <br /> <br /><br /><br />
                                        <Grid customStyle={{ minHeight: 99, paddingTop: '27px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda" customStyle={{ paddingTop: 18 }} >{LABEL_COMUNS.SAQUE}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{withdraw !== null && withdraw !== undefined ? withdraw.status.approved.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{withdraw !== null && withdraw !== undefined ? withdraw.status.approved.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{withdraw !== null && withdraw !== undefined ? withdraw.status.approved.amount : 'R$ 0,00'}</Grid>
                                                <br /><br />
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{withdraw !== null && withdraw !== undefined ? withdraw.status.rejected.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{withdraw !== null && withdraw !== undefined ? withdraw.status.rejected.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{withdraw !== null && withdraw !== undefined ? withdraw.status.rejected.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /> <br /> <br /><br /><br />
                                        <Grid customStyle={{ backgroundColor: '#f6f9fc', minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">{LABEL_COMUNS.TARIFAS}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">-</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{fess !== null && fess !== undefined ? fess.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{fess !== null && fess !== undefined ? fess.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /> <br /> <br />
                                        <Grid customStyle={{ minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda" >{LABEL_COMUNS.REVERSOES}</Grid>
                                            <Grid cols="9 9 9 9">
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">-</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{reversal !== null && reversal !== undefined ? reversal.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{reversal !== null && reversal !== undefined ? reversal.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /> <br /> <br />
                                        <Grid customStyle={{ backgroundColor: '#f6f9fc', minHeight: 52, paddingTop: '20px' }}>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda"><br /><br /><br />{LABEL_COMUNS.PRESTACAO_DE_CONTAS}</Grid>
                                            <Grid cols="9 9 9 9">

                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{accountability !== null && accountability !== undefined ? accountability.status.pending.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.pending.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.pending.amount : 'R$ 0,00'}</Grid>
                                                <br /><br />

                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{accountability !== null && accountability !== undefined ? accountability.status.awaitingApproval.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.awaitingApproval.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5 " className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.awaitingApproval.amount : 'R$ 0,00'}</Grid>
                                                <br /><br />

                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{accountability !== null && accountability !== undefined ? accountability.status.approved.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.approved.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.approved.amount : 'R$ 0,00'}</Grid>
                                                <br /><br />
                                                <Grid cols="5 5 5 5" className="alinhamento-centro">{accountability !== null && accountability !== undefined ? accountability.status.rejected.description : '0'}</Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.rejected.total : '0'}</Grid>
                                                <Grid cols="5 5 5 5" className="alinhamento-direita">{accountability !== null && accountability !== undefined ? accountability.status.rejected.amount : 'R$ 0,00'}</Grid>
                                            </Grid>
                                            <br /><br /><br /><br /><br /><br /><br /><br />
                                        </Grid>
                                        <br /> <br /> <br /><br /><br /><br />
                                        <Grid customStyle={{ paddingTop: 20 }}>
                                            <Grid cols="4 4 4 4" className="negrito alinhamento-esquerda" customStyle={{ fontWeight: 'bold', color: '#3F84C7' }}>{LABEL_COMUNS.SALDO_FINAL} {getLastDayCurrentByYearAndMonth(selectedDate.value.getFullYear(), selectedDate.value.getMonth())}</Grid>
                                            <Grid cols="8 8 8 8">
                                                <Grid cols="5 5 5 5" />
                                                <Grid cols="2 2 2 2" />
                                                <Grid cols="5 5 5 5" className="alinhamento-direita" customStyle={{ fontWeight: 'bold', color: '#3F84C7' }}>{balanceDays.totalCardsAmountLastDay}</Grid>
                                            </Grid>
                                        </Grid>
                                        <br /> <br /> <br /><br /><br />
                                        <Grid customStyle={{ paddingTop: 20, paddingBottom: 20 }}>
                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 alinhamento-esquerda" style={{ fontWeight: 'bold', color: '#073863', marginTop: 8 }} >{LABEL_COMUNS.DOWNLOAD}</div>
                                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 alinhamento-direita">
                                                <Button className="btn btn-success btn-sm" style={{ marginRight: 10, cursor: 'pointer' }} title="Exportar para .CSV" onClick={() => this.downloadCSV()}>
                                                    <i className="fa fa-file-excel-o" aria-hidden="true" />
                                                </Button>
                                            </div>
                                        </Grid>
                                        <br /> <br />
                                    </div>
                                </If>
                            </div>
                        </div>
                    </div>
                    &nbsp;&nbsp;&nbsp;
                    <div className="row">
                        <Grid className="alinhamento-direita">
                            <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                        </Grid>
                    </div>
                </div>
            </div >
        );
    }
}
export default RelatorioGerencialBppCorp;
