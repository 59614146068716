export const MENSAGEM = {
    CONTESTACAO_SUCESSO: 'Sua Contestação de Compra realizada com sucesso',
    CONTESTACAO_FALHA: 'Erro ao solicitar a Contestação de Compra'
};

export const METODOS = {
    LISTAR_MOTIVOS_CONTESTACAO: 'LISTAR_MOTIVOS_CONTESTACAO',
    LISTAR_MOTIVOS_CONTESTACAO_ERRO: 'LISTAR_MOTIVOS_CONTESTACAO_ERRO',
    MOTIVO_CHANGE_HANDLER: 'MOTIVO_CHANGE_HANDLER',
    DESCRICAO_HANDLE_CHANGE: 'DESCRICAO_HANDLE_CHANGE',
    SALVAR_CONTESTACAO: 'SALVAR_CONTESTACAO',
    ERRO_SALVAR: 'ERRO_SALVAR',
    FECHAR_MODAL: 'FECHAR_MODAL'
};

export const CAMPOS_ERROS = {
    MOTIVO: { campo: 'motivos' },
    DESCRICAO: { campo: 'descricao' }
};

export const LABELS = {
    TITULO_MODAL: 'Contestação de Compra',
    MENSAGEM_CONTESTACAO: 'Confirma a contestação da compra no estabelecimento ',
    COMPRA_EM: 'Compra em ',
    VALOR_TRANSACAO: 'Valor da Transação',
    MOEDA: ' (BRL)',
    CATEGORIA: 'Categoria',
    DATA: 'Data',
    MOTIVO: 'Motivo',
    DESCRICAO: 'Descrição',
    BOTAO_VOLTAR: 'Voltar',
    BOTAO_CONFIRMAR: 'Confirmar'
};
