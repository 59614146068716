import { HEADER_API, urlServicos, montaUrlFetch, HTTP_METODOS } from '../../servicos/url-servicos';
import { environment } from '../../configuracoes/environment';
import { stringify } from 'querystring';
import { TIPO_DE_TRANSACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';

export async function getPessoaByCpf(cpf) {
    if (cpf.length === 14) {
        cpf = removeMascaraTelefoneCpfCnpj(cpf);
    }

    let personData = await getPessoaByCPF(cpf);
    if (personData.data.registros && personData.data.registros.length > 0) {
        if (personData.data.registros.length === 1) {
            return personData.data.registros[0];
        } else {
            let person = extraiPessoaParceiroBpp(personData.data.registros);
            return person;
        }
    }
}

function extraiPessoaParceiroBpp(personList) {
    for (let i = 0; i < personList.length; i++) {
        if (personList[i].id.substring(0, 3) === 'BPP') {
            return personList[i];
        }
    }
}

function getPessoaByCPF(numeroDocumento) {
    return fetch(montaUrlFetch(urlServicos.PESSOA, { numeroDocumento }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json() }));
}

export function alteraPortadorService(dadosBody) {

    return fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: dadosBody
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json() }))
        .then(async resp => ({ ...(await postTransacao(resp.data.rel)) }));

}

function postTransacao(operacaoId) {
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(TIPO_DE_TRANSACAO.APROVACAO)
    }).then(async resp => ({ error: !resp.ok, data: await resp.json() }));
}

export function getVerificaAlteracaoPortadorEmAndamento(contaDestinoId) {
    const params = { operacao: 'alteracaoPortadorCartao', statusExterno: 'emProcessamento', contaDestinoId };
    return fetch(montaUrlFetch(urlServicos.OPERACAO, params),
        {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        }
    ).then(async resp => ({ error: !resp.ok, data: await resp.json() }));
}
