import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, OPERACAO, PARCEIRO, TIPO_DOCUMENTO } from '../../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import * as cartaoAction from '../../../../../action/cartoes/cartoes-action';
import { setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador } from '../../../../comuns/portador/vincular-portador/vincular-portador-action';
import { ESTILO } from '../../../../util/estilo_para_componentes/estiloUtil';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import Loading from '../../../../loading-fenix/loading';
import { produtosIdsBppPag } from '../../../../../reducer/anexo-contratual/anexo-contratual-reducer';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { fetchRemoveCartaoFavorito } from '../../../../../servicos/cartoes/cartoes-base';
import { adicionaNovoFavoritoFavorito } from '../../../../../servicos/cartoes/cartoes-service';
import GridCartoesDashboardBppPag from './grid-cartoes-dashboard-bpp-pag';
import GridCartoesDashboardBppPagFavoritos from './favoritos/grid-cartoes-dashboard-bpp-pag-favoritos';
import If from '../../../../componentes-uteis/if/if';
import AtualizarPagina from '../../../../comuns/atualizar-pagina/atualizar-pagina';
import CancelarCartao from '../../../../cartao/cancelar-cartao/cancelar-cartao';
import { removeMascaraTelefoneCpfCnpj } from 'componentes/util/formata-string';

class ListaCartoesDashBoardBppPag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cartaoToRemove: null, favoriteRemoveModalOpen: false, favoriteRemoveStep: 0,
            exibirLoadingCartao: true, listaDeCartoesFavoritos: [], idCartao: '', numeroDocumento: '',
            favoriteModalOpen: false, favoriteStep: 0, current: 0, abrirCollapse: false, contaSelecionada: props.contaSelecionada,
            exibirModalBloqueioTelaPedidoCartaoEmbossing: false, tipoDeAcesso: props.tipoDeAcesso, finalizedMessage: '',
            showCancelCardModal: false, showCancelFinalizationModal: false
        };
    }



    componentDidMount() {
        this.props.setClearState();
        this.props.setContaSelecionada(this.state.contaSelecionada);
        if (this.props.produtosIdsBppPag) {
            const { contaSelecionada } = this.state;
            const { produtosIdsBppPag } = this.props;
            this.getFavoritos(contaSelecionada, produtosIdsBppPag);

        }
        this.props.limparFiltroCartao();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.produtosIdsBppPag && this.props.produtosIdsBppPag !== nextProps.produtosIdsBppPag) {
            this.getFavoritos(this.state.contaSelecionada.id, nextProps.produtosIdsBppPag);
        }
    }

    validaFavorito() {
        const { idCartao, numeroDocumento } = this.state;
        if (idCartao !== '' && numeroDocumento !== '') {
            return false;
        }
        return true;
    }


    cadastraFavorito = async () => {
        const { id } = this.state.contaSelecionada;
        this.setState({ favoriteStep: 3 });
        let body = {
            cartaoId: this.state.idCartao,
            documentoTitular: removeMascaraTelefoneCpfCnpj(this.state.numeroDocumento),
        };

        const resp = await adicionaNovoFavoritoFavorito(JSON.stringify(body), id);
        if(resp.error) {
            this.mensagensDeErro = resp.message.map((item, key) =>
                <li key={item.id}>{item.mensagem}</li>
            );
            this.setState({ finalizedMessage: this.mensagensDeErro, favoriteStep: 1 });
            return;
        }

        this.setState({ finalizedMessage: resp.message, favoriteStep: 1 });
    }


    getCartoes = () => {
        const { contaSelecionada } = this.props.estadoCartoes;
        const { produtosIdsBppPag } = this.props;
        this.props.getCartoes(contaSelecionada.id, produtosIdsBppPag);
    }

    async getFavoritos(contaSelecionada) {
        const { contaSelecionada: conta } = this.props.estadoCartoes;
        const { produtosIdsBppPag } = this.props;
        await this.props.getCartoesFavoritos(contaSelecionada ? contaSelecionada.id : conta.id, produtosIdsBppPag, '', '', '', '', true);
    }

    excluiCartao(cartao) {
        this.setState({ favoriteRemoveModalOpen: true, cartaoToRemove: cartao });
    }

    removeFavorito() {
        const { id } = this.state.contaSelecionada;
        const { cartaoToRemove } = this.state;
        this.setState({ favoriteRemoveStep: 3 });
        let body = {
            id: cartaoToRemove.id,
            cartaoId: cartaoToRemove.favoriteCardId,
            documentoTitular: removeMascaraTelefoneCpfCnpj(cartaoToRemove.favoriteOwnerDocument)
        };

        fetchRemoveCartaoFavorito(JSON.stringify(body), id).then(response => {
            if (response === 'error') {
                this.setState({ favoriteRemoveStep: 2 });
            } else {
                this.setState({ favoriteRemoveStep: 1 });
            }
        });
    }


    refresh = async () => {
        const { contaSelecionada } = this.state;
        const { produtosIdsBppPag } = this.props;
        this.getFavoritos();
    }

    corpoModalFavorito() {
        let step = this.state.favoriteStep;
        const { finalizedMessage } = this.state;

        if (step === 0) {
            return (
                <div style={{ padding: 3 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>ID Cartão *</span>
                        <InputMask
                            className="mensagens-erro form-control"
                            onChange={(e) => this.setState({ idCartao: e.target.value })}
                            placeholder={LABEL_COMUNS.ID_CARTAO}
                            maxLength="13"
                            value={this.state.idCartao}
                        />
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>CPF *</span>
                        <InputMask
                            className="mensagens-erro form-control"
                            mask={MASCARA_CAMPOS.CPF} maskChar=""
                            onChange={(e) => this.setState({ numeroDocumento: e.target.value })}
                            placeholder={LABEL_COMUNS.CPF}
                            maxLength="50"
                            value={this.state.numeroDocumento}
                        />
                    </div>
                </div>
            );
        }
        else if (step === 1) {
            return (
                <div>
                    <span>{finalizedMessage}</span>
                </div>
            );
        }
        return (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Loading />
            </div>
        );
    }

    rodapeModalFavorito() {
        if (this.state.favoriteStep === 0) {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteModalOpen: false }); }} type="button" style={{ backgroundColor: 'white', color: 'black', borderColor: '#ddd' }} className="btn btn-primary">Cancelar</button>
                    <button disabled={this.validaFavorito()} onClick={() => this.cadastraFavorito()} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Cadastrar</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteModalOpen: false, favoriteStep: 0, idCartao: '', numeroDocumento: '' }); this.refresh(); }} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Ok</button>
                </div>
            );
        }
    }

    rodapeRemoveFavorito() {
        if (this.state.favoriteRemoveStep === 0) {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteRemoveModalOpen: false }); }} type="button" style={{ backgroundColor: 'white', color: 'black', borderColor: '#ddd' }} className="btn btn-primary">Não</button>
                    <button onClick={() => this.removeFavorito()} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Sim</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteRemoveModalOpen: false, favoriteRemoveStep: 0 }); this.refresh(); }} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Ok</button>
                </div>
            );
        }
    }

    corpoRemoveFavorito() {
        let step = this.state.favoriteRemoveStep;
        if (step === 0) {
            return <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>{LABEL_COMUNS.DESEJA_REMOVER}</span>;
        }
        else if (step === 1) {
            return (
                <div>
                    <span>{MENSAGENS.CARTAO.FAVORITOS.EXCLUSAO.SUCESSO}</span>
                </div>
            );
        }
        else if (step === 2) {
            return (
                <div>
                    <span>{LABEL_COMUNS.ERRO_SOLICITACAO}</span>
                </div>
            );
        }
        return (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Loading />
            </div>
        );
    }

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    
    limpaFiltro() {
        const obj = {target: {value: ''}};
        this.props.setCartaoQuatroUltimosDigitos(obj);
        this.props.setCartaoId(obj);
    }

    render() {
        const corDeFundoParaTitulo = ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG;

        const { cartaoId, cartaoSelecionado, cartaoQuatroUltimosDigitos, contaSelecionada, exibirLoadingCartao, listaDeCartoesCancelados, listaDeCartoesFavoritos, listaDeCartoesProntosParaAtivacao } = this.props.estadoCartoes;

        const { current, tipoDeAcesso, showCancelCardModal, showCancelFinalizationModal } = this.state;

        const { produtosIdsBppPag, grantChangeStatusCard, outorgaPerfils } = this.props;

        const underlineStyle = show => {
            return show ? { textDecoration: 'underline', fontWeight: 'bold' } : {};
        };

        return (
            <div>

                <CancelarCartao
                    contextoBppPag={true}
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />


                <ModalComponent
                    contextoBppPag={true}

                    bsSize="small"
                    exibirModal={this.state.favoriteModalOpen}
                    name="modal-favorite"
                    header={'Cadastrar Cartão Favorito'}
                    body={this.corpoModalFavorito()}
                    footer={this.rodapeModalFavorito()} />


                <ModalComponent
                    contextoBppPag={true}

                    bsSize="small"
                    exibirModal={this.state.favoriteRemoveModalOpen}
                    name="modal-favorite" listaDeCartoesCancelados
                    header={LABEL_COMUNS.EXCLUIR_CARTAO_FAVORITO}
                    body={this.corpoRemoveFavorito()}
                    footer={this.rodapeRemoveFavorito()} />



                <div className="panel panel-default" style={{ marginBottom: 0 }}>
                    <div className={corDeFundoParaTitulo} style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.GERENCIAR_ESTOQUE_DE_CARTOES}
                        </div>

                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <AtualizarPagina onClick={() => this.getFavoritos()} />
                            <Button className="estilo-filtro-bbpppag" onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            &nbsp;&nbsp;
                            {!this.props.estadoCartoes.exibirLoadingCartao &&
                                <Dropdown id="menuOpcoes" pullRight >
                                    <Dropdown.Toggle noCaret className="estilo-filtro-bbpppag dropdown-sem-bordas">
                                        <Glyphicon glyph="option-horizontal" />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu >
                                        <li>
                                            <Link className="link_expandir" to={{ pathname: ROTAS_CAMINHOS.CARTOES_BPP_PAG, state: { listaFavoritos: this.state.listaDeCartoesFavoritos, listaCancelados: this.props.estadoCartoes.listaDeCartoesCanceladosEOutros } }} >
                                                {LABEL_COMUNS.EXPANDIR}
                                            </Link>
                                        </li>
                                        <MenuItem onClick={async () => {
                                            this.setState({ favoriteModalOpen: true });
                                        }}>
                                            {LABEL_COMUNS.CADASTRAR_FAVORITO}
                                        </MenuItem>
                                    </Dropdown.Menu>
                                </Dropdown>}

                        </div>
                    </div>


                    <FiltroGenerico
                        contextoBppPag={true}
                        exibirTooltip={this.state.current === 1 ? true : false}
                        abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeCartoes={true}
                        cartaoId={this.props.estadoCartoes.cartaoId}
                        setCartaoId={this.props.setCartaoId}
                        cartaoIdErro={this.props.estadoCartoes.portadorCartaoIdErro}
                        cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                        setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                        cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}
                        exibirBotaoLimpar={true}
                        limparFiltro={() => { this.getCartoes(); this.getFavoritos(); this.limpaFiltro(); }}
                        buscar={() => {
                            this.props.getCartoesFavoritos(contaSelecionada.id, produtosIdsBppPag, cartaoId, cartaoQuatroUltimosDigitos, '', '', true);
                        }} />
                    <div className="panel-body" style={{ height: '350px' }}>

                        {
                            this.props.estadoCartoes.exibirLoadingCartao ?
                                <div className="alinhamento-centro">
                                    <Loading />
                                </div>
                                :
                                <div>
                                    <If test={!exibirLoadingCartao} >
                                        <If test={current === 0}>
                                            <GridCartoesDashboardBppPag
                                                exibirMudarTitularidade={true}
                                                tipoDeAcesso={tipoDeAcesso}
                                                outorgaPerfils={outorgaPerfils}
                                                statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.PRONTO_PARA_ATIVACAO}
                                                setArmazenarCartao={this.props.setArmazenarCartao}
                                                setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                                setShowCancelCardModal={this.setShowCancelCardModal}
                                                setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                                                listaDeCartoes={listaDeCartoesProntosParaAtivacao}
                                            />
                                        </If>
                                        <If test={current === 1}>
                                            <GridCartoesDashboardBppPagFavoritos
                                                excluiCartao={(cartao) => { this.excluiCartao(cartao); }}
                                                setArmazenarCartao={this.props.setArmazenarCartao}
                                                tipoDeAcesso={tipoDeAcesso}
                                                statusCartao={LABEL_COMUNS.FAVORITO}
                                                listaDeCartoes={listaDeCartoesFavoritos} />
                                        </If>
                                        <If test={current === 2}>
                                            <GridCartoesDashboardBppPag
                                                tipoDeAcesso={tipoDeAcesso}
                                                statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.CANCELADO_E_OUTRO}
                                                setArmazenarCartao={this.props.setArmazenarCartao}
                                                listaDeCartoes={listaDeCartoesCancelados}
                                                outorgaPerfils={outorgaPerfils}
                                            />
                                        </If>
                                    </If>
                                </div>
                        }
                    </div>
                </div>

                {!this.props.estadoCartoes.exibirLoadingCartao &&
                    <div className="cartoes-status" >
                        <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 0 })} style={underlineStyle(this.state.current === 0)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}&nbsp;({this.props.estadoCartoes.listaDeCartoesProntosParaAtivacaoEBloqueados.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => { this.setState({ current: 1 }); }} style={underlineStyle(this.state.current === 1)}>{'Favoritos'}&nbsp;({this.props.estadoCartoes.quantidadeTotalRegistros})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 2 })} style={underlineStyle(this.state.current === 2)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS}&nbsp;({this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length})</span>
                    </div>
                }

            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    estadoCartoes: {
        ...state.CartoesReducer, produtosIdsBppPag: produtosIdsBppPag(state.AnexoContratualReducer),
    },
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    grantChangeStatusCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERA_STATUS_CARTAO),
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...cartaoAction, setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesDashBoardBppPag);
