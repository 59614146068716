import { removeMascaraTelefoneCpfCnpj } from '../formata-string';
import { TIPO_DE_PESSOA, TIPO_DOCUMENTO, LABEL_COMUNS } from '../contantes-comuns/constantes-comuns';
import { CPF, CNPJ } from 'cpf_cnpj';

export const retornaBancosParaCombo = (bancos) => {
    let listaDeBancos = [];
    bancos.map((banco) => {
        return listaDeBancos.push({ value: banco.codigo, label: banco.codigo + ' - ' + banco.nome });
    });
    return listaDeBancos;
};

export function retornaFavorecidosParaCombo(favorecidos) {
    let listaFavorecidos = [];
    favorecidos.map((favorecido, idx) => {
        return listaFavorecidos.push({
            value: favorecido.id,
            label: favorecido.conta + ' - ' + favorecido.nome,
            favorecido: favorecido
        });
    });
    return listaFavorecidos;
}

function retornaFavorecido(favorecidoId, favorecidos) {
    let favorecidoParaRetornar = {};
    favorecidos.map((favorecido) => {
        if (favorecidoId === favorecido.id) {
            favorecidoParaRetornar = removeMascaraTelefoneCpfCnpj(favorecido);
        }
    });
    return favorecidoParaRetornar;
}

export const montaTitulo = (pessoaSelecionada) => {
    let titulo;
    let numeroDoDocumento;
    if (pessoaSelecionada.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
        numeroDoDocumento = pessoaSelecionada.documento.find(d => (d.tipo === TIPO_DOCUMENTO.CNPJ.value)).numero;
        const { pessoaJuridica: { razaoSocial } } = pessoaSelecionada;
        titulo = LABEL_COMUNS.EMPRESA.concat(': '.concat(razaoSocial.concat(', '.concat(TIPO_DOCUMENTO.CNPJ.value.concat(': '.concat(CNPJ.format(numeroDoDocumento)))))));
    } else {
        numeroDoDocumento = pessoaSelecionada.documento.find(d => (d.tipo === TIPO_DOCUMENTO.CPF.value)).numero;
        const { pessoaFisica: { nomeCompleto } } = pessoaSelecionada;
        titulo = LABEL_COMUNS.NOME.concat(': '.concat(nomeCompleto.concat(', '.concat(TIPO_DOCUMENTO.CPF.value.concat(': '.concat(CPF.format(numeroDoDocumento)))))));
    }
    return titulo;
};
