import { montaComboDeClassificacoes, montaComboDeProjetos, montaPrestacoes } from '../../../../prestacao-contas/prestacao/prestacao-conta-util';
import { METODOS_COMUNS, TIPO_DE_MOVIMENTACAO, COMBO_PRESTACAO_DE_CONTAS_STATUS, METODOS } from '../../../../util/contantes-comuns/constantes-comuns';
import { formatarDataBR, retornaDataDeDiasPassados, dataAtualPtBr, FORMATO_DE_DATA_BR } from '../../../../util/data/dataUtil';

const ESTADO_INICIAL = {
    dataInicial: formatarDataBR(retornaDataDeDiasPassados()),
    dataFinal: dataAtualPtBr(FORMATO_DE_DATA_BR),
    dataInicialErro: '',
    dataFinalErro: '',
    contaSelecionada: '',
    listaDePrestacoesDeContas: [],
    errosNaBuscaDasPrestacoesDeContas: [],
    listaDeClassificacoes: [],
    errosNaBuscaDeClassificacoes: [],
    listaDeProjetos: [],
    errosNaBuscaDosProjetos: [],
    statusSelecionado: '',
    projetoSelecionado: '',
    classificacaoSelecionada: '',
    filtro: {},
    valorDe: 0,
    valorAte: 0,
    exibirLoadingDashboardPrestacaoDeContas: undefined
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACOES_DE_CONTA:
            return { ...state, listaDePrestacoesDeContas: action.payload && action.payload.resultado ? montaPrestacoes(action.payload.resultado) : [] };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACOES_DE_CONTA_ERRO:
            return { ...state, errosNaBuscaDasPrestacoesDeContas: action.payload.erros };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES:
            return { ...state, listaDeClassificacoes: montaComboDeClassificacoes(action.payload) };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_CLASSIFICACOES_ERROS:
            return { ...state, errosNaBuscaDeClassificacoes: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS:
            return { ...state, listaDeProjetos: montaComboDeProjetos(action.payload) };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.GET_PRESTACAO_DE_CONTAS_PROJETOS_ERROS:
            return { ...state, errosNaBuscaDosProjetos: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_CLASSIFICACAO_SELECIONADA:
            return { ...state, classificacaoSelecionada: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_PROJETO_SELECIONADO:
            return { ...state, projetoSelecionado: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_PRESTACAO_DE_CONTAS_STATUS:
            return { ...state, statusSelecionado: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO:
            return { ...state, filtro: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DATA_INICIAL:
            return { ...state, dataInicial: action.payload, dataInicialErro: '', errosNaBuscaDasPrestacoesDeContas: [] };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DATA_FINAL:
            return { ...state, dataFinal: action.payload, dataFinalErro: '', errosNaBuscaDasPrestacoesDeContas: [] };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.LIMPAR_FILTRO_PRESTACAO_DE_CONTAS:
            return {
                ...state,
                statusSelecionado: action.payload,
                classificacaoSelecionada: action.payload,
                projetoSelecionado: action.payload,
                valorDe: 0,
                valorAte: 0,
                errosNaBuscaDasPrestacoesDeContas: [],
                dataInicial: formatarDataBR(retornaDataDeDiasPassados()),
                dataFinal: dataAtualPtBr(FORMATO_DE_DATA_BR)
            };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_FILTRO_PRESTACAO_DE_CONTAS_POR_VALOR:
            return { ...state, ...action.payload.data, errosNaBuscaDasPrestacoesDeContas: [] };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.SET_EXIBIR_LOADING_DASHBOARD_PRESTACAO_DE_CONTAS:
            return { ...state, exibirLoadingDashboardPrestacaoDeContas: action.payload };
        case METODOS.PESSOA.PRESTACAO_DE_CONTAS.LIMPAR_LISTA_DE_PRESTACOES_DE_CONTAS:
            return { ...state, listaDePrestacoesDeContas: action.payload };
        default:
            return state;
    }
};
