import { METODOS } from '../conta-departamento-constantes';
import { montaUrlFetch, HTTP_METODOS, urlServicos, HEADER_API, montaUrlPathParam } from '../../../../servicos/url-servicos';
import { DESCRICAO_CONTA } from '../../../util/contantes-comuns/constantes-comuns';

export function getListaContaDepartamento(contaId) {
    let queryParams = { contaDescricao: DESCRICAO_CONTA.CONTA_DEPARTAMENTO };
    return dispatch => {
        dispatch({
            type: METODOS.GET_LISTA_CONTA_DEPARTAMENTO,
            payload: [],
            loading: true
        });
        fetch(montaUrlFetch(montaUrlPathParam(urlServicos.GET_CONTA_FILHA, contaId), queryParams), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        }).then(response => {
            response.json().then(json => {
                let listaContas = [json];
                dispatch({
                    type: response.status === 200 ? METODOS.GET_LISTA_CONTA_DEPARTAMENTO : METODOS.GET_LISTA_CONTA_DEPARTAMENTO_ERRO,
                    payload: listaContas,
                    loading: false
                });
            });
        })
            .catch(err => {
                dispatch({
                    type: METODOS.GET_LISTA_CONTA_DEPARTAMENTO_ERRO,
                    payload: err,
                    loading: false
                });
            });
    };
}

export function setExibirModalEditarConta(value) {
    return {
        type: METODOS.SET_EXIBIR_MODAL_EDITAR_CONTA,
        payload: value
    };
}

export const setExibirModalCriarContaDepartamento = (value) => ({
    type: METODOS.SET_EXIBIR_MODAL_CRIAR_CONTA_DEPARTAMENTO,
    payload: value
});

export function setExibirModalSucessoErroEditarConta(value) {
    return {
        type: METODOS.SET_EXIBIR_MODAL_SUCESSO_ERRO_EDITAR_CONTA,
        payload: value
    };
}

export function setContaSelecionada(value) {
    return {
        type: METODOS.SET_CONTA_SELECIONADA,
        payload: value
    };
}

export function setMsgValidarImagemEditarConta(value) {
    return {
        type: METODOS.SET_MSG_VALIDAR_IMAGEM_EDITAR_CONTA,
        payload: value
    };
}

export function salvarEditarConta(contaId, apelido) {
    return dispatch => {
        return fetch(montaUrlFetch(montaUrlPathParam(urlServicos.GET_CONTA_ID(contaId))), {
            method: HTTP_METODOS.PATCH,
            headers: HEADER_API().headers,
            body: JSON.stringify({
                apelido: apelido
            })
        })
            .then(response => response.json())
            .then((response) => {
                dispatch({
                    type: METODOS.SALVAR_EDITAR_CONTA,
                    payload: response.msg
                });
            })
            .catch(erro => {
                dispatch({
                    type: 'ERRO',
                    payload: erro.data
                });
            });
    };
}
