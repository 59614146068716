import React, { Component } from 'react';
import { Button, ControlLabel, Dropdown, FormGroup, Glyphicon, MenuItem } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { bindActionCreators } from 'redux';
import { podeAprovar } from '../../../../negocio/outorga/outorga-negocio';
import { outorgaParametroOperacao } from '../../../../reducer/outorga/outorga-operacao-reducer';
import { getComprovantePDF, getHistoricoDeComprovantes, getOperacoesComprovante } from '../../../../servicos/comprovante/comprovante-service';
import { getNecessitaOtp } from '../../../../servicos/outorga/outorga-service';
import ComprovanteModal from '../../../componentes-uteis/comprovante/comprovante-modal';
import FiltroGenerico from '../../../componentes-uteis/filtro-generico/filtro-generico';
import If from '../../../componentes-uteis/if/if';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import AtualizarPagina from '../../../comuns/atualizar-pagina/atualizar-pagina';
import ShowLineError from '../../../comuns/showLineError/show-line-error';
import Loading from '../../../loading-fenix/loading-fenix';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { FILTROS, LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, OPERACAO, RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO, STATUS, TIPO_DE_TRANSACAO } from '../../../util/contantes-comuns/constantes-comuns';
import { formatarDataBR } from '../../../util/data/dataUtil';
import { CPF, CNPJ } from 'cpf_cnpj';
import { ESTILO, STATUS_VALIDACAO_COMPONENTES } from '../../../util/estilo_para_componentes/estiloUtil';
import { formatarCampoValor } from '../../../util/extrato/extrato-util';
import * as actions from './agendamento-e-aprovacoes-action';

class AgendamentoEAprovacoesDashboardConta extends Component {
    constructor() {
        super();

        this.state = {
            abrirCollapse: false,
            operacoesComprovante: [],
            dadosComprovante: {},
            exibirModalComprovante: false,
            exibirModalComprovanteErro: false,
            exibirModalDetalheTransacao: false,
            aprovarOuComprovante: '',
            linhaAgendamento: []
        };

        this.getDadosComprovante = this.getDadosComprovante.bind(this);
        this.carregaOperacoesComprovante = this.carregaOperacoesComprovante.bind(this);
        this.downloadComprovantePDF = this.downloadComprovantePDF.bind(this);
    }

    componentDidMount() {
        this.init();
    }

    init() {
        this.props.limparFiltroAgendamentoEAprovacoes();
        let contaSelecionada = this.props.contaSelecionada;
        this.props.setContaSelecionada(contaSelecionada);
        this.getAgendamentos({
            contaId: contaSelecionada.id,
            status: STATUS.AGENDAMENTO_APROVACOES_CONTA,
            solucao: this.props.solucaoBppPag
        });
        this.carregaOperacoesComprovante();
    }

    async carregaOperacoesComprovante() {
        const { data } = await getOperacoesComprovante('operacoesComprovante');

        this.setState({ operacoesComprovante: data.valor.reduce((acc, cur) => acc.concat(cur.operacao), []) });
    }

    async getDadosComprovante(operacaoId) {
        this.setState({ exibirModalDetalheTransacao: false });
        const { data } = await getHistoricoDeComprovantes(this.props.contaSelecionada.id, { operacaoId });

        if (data.quantidadeTotalRegistros > 0) {
            this.setState({ dadosComprovante: data.resultado[0], exibirModalComprovante: true });
        } else {
            this.setState({ exibirModalComprovanteErro: true });
        }
    }

    async downloadComprovantePDF(comprovante) {
        const data = await getComprovantePDF(this.props.contaSelecionada.id, comprovante.operacaoId, comprovante.comprovanteId);

        const linkSource = URL.createObjectURL(data);
        const downloadLink = document.createElement('a');
        const fileName = 'Comprovante.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(linkSource);
    }

    retornaCorpoModalContinuarComOperacao() {
        return (
            <div dangerouslySetInnerHTML={{ __html: this.state.exibirModalContinuarComOperacao }} />
        );
    }

    retornaRodapeModalContinuarComOperacao() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                }}
                btn2OnClick={() => {
                    this.setState({ exibirModalContinuarComOperacao: undefined });
                    this.verificaNecessitaOtp();
                }} />
        );
    }

    verificaNecessitaOtp() {

        const { id, transacao, nome, dataAgendamento } = this.state.operacao || {};

        if (id && transacao && nome) {

            const outorga = outorgaParametroOperacao(this.props.outorga, nome);

            const necessitaToken = getNecessitaOtp(transacao.transacao, outorga);

            if (necessitaToken) {
                this.props.solicitaTokenTransacao(id, transacao);
            } else {
                this.props.efetivaTransacao(id, transacao, dataAgendamento);
            }
        }
    }

    solicitaOperacao({ operacao, id, dataAgendamento }, transacao) {
        this.setState({ exibirModalDetalheTransacao: false });
        const msg = MENSAGENS.OPERACAO.CONFIRMACAO(transacao.transacao);
        this.setState({ exibirModalContinuarComOperacao: msg, operacao: { nome: operacao, id, dataAgendamento, transacao } });
    }

    abrirDetalhesDeAgendamento(linhaAgendamento, transacao) {
        if (linhaAgendamento.statusInterno === STATUS.INTERNO.ABERTO) {
            this.setState({ aprovarOuComprovante: 'Aprovar' });
        }
        if (linhaAgendamento.statusInterno === STATUS.INTERNO.AGENDADO && this.state.operacoesComprovante.includes(linhaAgendamento.operacao)) {
            this.setState({ aprovarOuComprovante: 'Comprovante' });
        }
        this.setState({ linhaAgendamento: linhaAgendamento });
        const msg = MENSAGENS.OPERACAO.CONFIRMACAO(transacao.transacao);
        this.setState({ exibirModalDetalheTransacao: true });
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.agendamentoDashboardConta.solicitacaoTokenErro || this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token </ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.tokenId ? '' : this.state.tokenId}
                        onChange={(e) => this.setState({ tokenId: e.target.value })}
                        mask={MASCARA_CAMPOS.TOKEN}
                        maskChar={null}
                    />
                    <ShowLineError error={this.props.agendamentoDashboardConta.solicitacaoTokenErro || this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.verificaNecessitaOtp()}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.props.setExibirModalToken(false);
                    this.setState({ tokenId: '' });
                }}
                btn2OnClick={() => {
                    if (this.state.tokenId && this.state.tokenId.length === 6) {
                        const { operacao: { id, transacao, dataAgendamento }, tokenId } = this.state;
                        this.props.efetivaTransacao(id, transacao, tokenId, dataAgendamento);
                    } else {
                        this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
                    }
                }} />
        );
    }

    getAgendamentos({ contaId, status, dataInicial, dataFinal, valorDe, valorAte, solucao }) {
        this.props.buscarTodosAgendamentosDashboardConta(
            [FILTROS.AGENDAMENTOS_CONTA_ORIGEM(contaId,
                RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO(status),
                dataInicial,
                dataFinal,
                valorDe,
                valorAte),
            FILTROS.AGENDAMENTOS_CONTA_DESTINO(contaId,
                RETORNA_DOIS_STATUS_DA_OPERACAO_FILTRADO(status),
                dataInicial,
                dataFinal,
                valorDe,
                valorAte)],
            solucao);
    }

    render() {
        let styles = {
            marginLeft: '3px'
        };
        const podeAprovarOutorga = true && podeAprovar(this.props.perfils);
        return (
            <div className="panel panel-default">
                <ModalComponent
                    bsSize="small"
                    exibirModal={this.state.exibirErroDownloadComprovantePDFModal}
                    header={this.state.tituloErroDownloadComprovantePDFModal}
                    body={this.state.mensagemErroDownloadComprovantePDFModal}
                    footer={
                        <FooterBotoesModalComponent
                            chieldClassName="alinhamento-direita"
                            btn2Label="Ok"
                            btn2OnClick={() => { this.setState({ exibirErroDownloadComprovantePDFModal: false }); }}
                        />
                    }
                />
                <ModalComponent bsSize="small" exibirModal={this.props.agendamentoDashboardConta.exibiModalFinalizacao} header={this.props.agendamentoDashboardConta.tituloModalFinalizacao}
                    body={this.props.agendamentoDashboardConta.mensagemFinalizacao} footer={this.rodapeModal()} />

                <ModalComponent exibirModal={this.props.agendamentoDashboardConta.exibirModalToken}
                    name="modal-token" header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.montaCorpoModalToken()} footer={this.montaRodapeModalToken()} />

                <ModalComponent exibirModal={!!this.state.exibirModalContinuarComOperacao}
                    name="modalContinuarComOperacao" header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.retornaCorpoModalContinuarComOperacao()} footer={this.retornaRodapeModalContinuarComOperacao()} />

                <ModalComponent
                    exibirModal={this.state.exibirModalComprovanteErro}
                    header="Comprovante"
                    body={<p>Algo inesperado aconteceu. Tente novamente!</p>}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label="Ok"
                            btn2OnClick={() => this.setState({ exibirModalComprovanteErro: false })}
                        />
                    }
                />

                <ModalComponent
                    exibirModal={this.state.exibirModalDetalheTransacao}
                    header="Detalhes da Transação"
                    closeButton={true}
                    closeOnclick={() => this.setState({ exibirModalDetalheTransacao: false })}
                    body={
                        <div>
                            <p>
                                Transação feita dia {formatarDataBR(this.state.linhaAgendamento.dataCriacao)}, pelo executor
                                <strong style={styles}>
                                    {this.state.linhaAgendamento.nomePessoaExecutor}
                                </strong>
                            </p>
                            {this.state.linhaAgendamento.operacao !== 'pagamentoBoletoBancario' && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <h4>Conta de Destino</h4>

                                        <p>Nome do titular:
                                            {this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimentoDiferentesTitularidades' || 
                                             this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimento' && (
                                             <strong style={styles}>
                                                 {this.state.linhaAgendamento.nomePessoaContaDestino}
                                             </strong>
                                            )}

                                            {this.state.linhaAgendamento.operacao === 'TED' && (
                                                <strong style={styles}>
                                                    {this.state.linhaAgendamento.dadosIntegracao.nomeFavorecido}
                                                </strong>
                                            )}
                                        </p>

                                        {this.state.linhaAgendamento.operacao === 'TED' && (
                                            <p>Codigo do Banco:
                                                <strong style={styles}>
                                                    {this.state.linhaAgendamento.dadosIntegracao.codigoBanco}
                                                </strong>
                                            </p>
                                        )}

                                        <p>Agência:
                                            {this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ||
                                             this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimento' && (
                                             <strong style={styles}>
                                                 {this.state.linhaAgendamento.agenciaContaDestino}
                                             </strong>
                                            )}
                                            {this.state.linhaAgendamento.operacao === 'TED' && (
                                                <strong style={styles}>
                                                    {this.state.linhaAgendamento.dadosIntegracao.agencia}
                                                </strong>
                                            )}
                                        </p>

                                        <p>Conta:
                                            {this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimentoDiferentesTitularidades' ||
                                             this.state.linhaAgendamento.operacao === 'transferenciaEntreContasMovimento' && (
                                             <strong style={styles}>
                                                 {this.state.linhaAgendamento.numeroContaDestino}-{this.state.linhaAgendamento.digitoContaDestino}
                                             </strong>
                                            )}
                                            {this.state.linhaAgendamento.operacao === 'TED' && (
                                                <strong style={styles}>
                                                    {this.state.linhaAgendamento.dadosIntegracao.conta}-{this.state.linhaAgendamento.dadosIntegracao.digito}
                                                </strong>
                                            )}
                                        </p>

                                        <p>Valor:
                                            <strong style={styles}>
                                                {formatarCampoValor(this.state.linhaAgendamento.tipo, this.state.linhaAgendamento.valor, this.state.linhaAgendamento.moeda)}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            )}

                            {this.state.linhaAgendamento.operacao === 'pagamentoBoletoBancario' && (
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div>
                                        <h4>Dados do Boleto</h4>

                                        <p>Código de Barras:
                                            <strong style={styles}>
                                                {this.state.linhaAgendamento.dadosIntegracao.codigoBarras}
                                            </strong>
                                        </p>

                                        <p>Nome do Beneficiário:
                                            <strong style={styles}>
                                                {this.state.linhaAgendamento.dadosIntegracao.nomeBeneficiario}
                                            </strong>
                                        </p>

                                        <p>Número Documento Beneficiário:
                                            {this.state.linhaAgendamento.dadosIntegracao.tipoDocumentoBeneficiario === 'CPF' && (
                                                <strong style={styles}>
                                                    {CPF.format(this.state.linhaAgendamento.dadosIntegracao.numeroDocumentoBeneficiario)}
                                                </strong>
                                            )}
                                            {this.state.linhaAgendamento.dadosIntegracao.tipoDocumentoBeneficiario === 'CNPJ' && (
                                                <strong style={styles}>
                                                    {CNPJ.format(this.state.linhaAgendamento.dadosIntegracao.numeroDocumentoBeneficiario)}
                                                </strong>
                                            )}
                                        </p>

                                        <p>Data Vencimento:
                                            <strong style={styles}>
                                                {formatarDataBR(this.state.linhaAgendamento.dadosIntegracao.dataVencimento)}
                                            </strong>
                                        </p>

                                        <p>Valor:
                                            <strong style={styles}>
                                                {formatarCampoValor(this.state.linhaAgendamento.tipo, this.state.linhaAgendamento.valor, this.state.linhaAgendamento.moeda)}
                                            </strong>
                                        </p>
                                    </div>
                                </div>
                            )}
                        </div>
                    }
                    footer={
                        < FooterBotoesModalComponent
                            btn1Label="Reprovar"
                            btn1OnClick={() => this.solicitaOperacao(this.state.linhaAgendamento, TIPO_DE_TRANSACAO.CANCELAMENTO)}
                            btn2Label={this.state.aprovarOuComprovante}
                            btn2OnClick={() => {
                                // eslint-disable-next-line no-unused-expressions
                                this.state.aprovarOuComprovante === 'Aprovar'(
                                    this.solicitaOperacao(this.state.linhaAgendamento, TIPO_DE_TRANSACAO.APROVACAO)
                                );
                                // eslint-disable-next-line no-unused-expressions
                                this.state.aprovarOuComprovante === 'Comprovante'(
                                    this.getDadosComprovante(this.state.linhaAgendamento.id)
                                );
                            }}
                        />
                    }
                />

                {
                    this.state.exibirModalComprovante && (
                        <ComprovanteModal
                            header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE(this.props.contaSelecionada.descricao)}
                            bsSize="lg"
                            onClose={() => this.setState({ exibirModalComprovante: false })}
                            exibirModal={this.state.exibirModalComprovante}
                            dadosComprovante={this.state.dadosComprovante}
                            downloadComprovantePDF={this.downloadComprovantePDF}
                        />
                    )
                }

                <div className={this.props.className} style={{ height: '40px' }}>
                    <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                        {LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    </div>
                    <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                        <AtualizarPagina onClick={() => this.getAgendamentos({
                            contaId: this.props.agendamentoDashboardConta.contaSelecionada.id,
                            status: STATUS.AGENDAMENTO_APROVACOES_CONTA,
                            solucao: this.props.solucaoBppPag
                        })} title={LABEL_COMUNS.ATUALIZAR} />

                        <Button className={this.props.classNameBotao} onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                            <span className="glyphicon glyphicon-filter" />
                        </Button>

                        <Dropdown id="menuOpcoes" pullRight >
                            <Dropdown.Toggle noCaret className={ESTILO.COR_DE_FUNDO.FILTRO.PADRAO}>
                                <Glyphicon glyph="option-horizontal" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu >
                                <li>
                                    <Link className="link_expandir" to={ROTAS_CAMINHOS.AGENDAMENTO_APROVACOES_CONTA}>
                                        {LABEL_COMUNS.EXPANDIR}
                                    </Link>
                                </li>
                            </Dropdown.Menu>

                        </Dropdown>
                    </div>
                </div>
                <FiltroGenerico
                    contextoBppPag={this.props.contextoBppPag}
                    abrirCollapse={this.state.abrirCollapse}
                    mostrarFiltroDeDatas
                    mostrarFiltroDeValor

                    dataInicial={this.props.agendamentoDashboardConta.dataInicial}
                    setDataInicial={this.props.setFiltroAgendamentosEAprovacoesDataInicial}
                    dataInicialErro={this.props.agendamentoDashboardConta.dataInicialErro}

                    dataFinal={this.props.agendamentoDashboardConta.dataFinal}
                    setDataFinal={this.props.setFiltroAgendamentosEAprovacoesDataFinal}
                    dataFinalErro={this.props.agendamentoDashboardConta.dataFinalErro}

                    valorDe={this.props.agendamentoDashboardConta.valorDe}
                    setValorDe={this.props.setFiltroAgendamentosEAprovacoesValorDe}

                    /*  valorDeErro={this.props.agendamentoDashboardConta.valorDeErro} VERIFICAR SE SERÁ NECESSÁRIO FAZER TRATAMENTO DE ERROS */

                    valorAte={this.props.agendamentoDashboardConta.valorAte}
                    setValorAte={this.props.setValsetFiltroAgendamentosEAprovacoesValorAte}
                    /*   valorAteErro={this.props.agendamentoDashboardConta.valorAteErro} */
                    exibirBotaoLimpar
                    limparFiltro={this.props.limparFiltroAgendamentoEAprovacoes}
                    buscar={() =>
                        this.getAgendamentos({
                            contaId: this.props.agendamentoDashboardConta.contaSelecionada.id,
                            status: STATUS.AGENDAMENTO_APROVACOES_CONTA,
                            dataInicial: this.props.agendamentoDashboardConta.dataInicial,
                            dataFinal: this.props.agendamentoDashboardConta.dataFinal,
                            valorDe: this.props.agendamentoDashboardConta.valorDe,
                            valorAte: this.props.agendamentoDashboardConta.valorAte,
                            solucao: this.props.solucaoBppPag
                        })}
                />
                <div className="panel-body" style={reactLocalStorage.get('tipoAcesso') === 'PF' ? { minHeight: '430px' } : { minHeight: '300px' }}>
                    {
                        this.props.agendamentoDashboardConta.exibirLoadingAgendamentoEAprovacoes
                            ? <div className="alinhamento-centro">
                                <Loading />
                            </div>
                            : this.props.agendamentoDashboardConta.exibirLoadingAgendamentoEAprovacoes !== undefined &&
                            <If test={this.props.agendamentoDashboardConta.listaDeAgendamentos && this.props.agendamentoDashboardConta.listaDeAgendamentos.length > 0}>
                                <div className="flex-div">
                                    <div style={{ display: 'flex', flex: '0.24' }} className="nowrap-ellipsis">
                                        <span className="titulo-dashboards-conta-solucao nowrap-ellipsis">{LABEL_COMUNS.DATA_AGENDAMENTO}</span>
                                    </div>
                                    <div style={{ display: 'flex', flex: '0.33' }} className="nowrap-ellipsis" >
                                        <span className="titulo-dashboards-conta-solucao nowrap-ellipsis">{LABEL_COMUNS.OPERACAO}/{LABEL_COMUNS.DESCRICAO}</span>
                                    </div>
                                    <div style={{ display: 'flex', flex: '0.15' }}>
                                        <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.VALOR}</span>
                                    </div>
                                    <div style={{ display: 'flex', flex: '0.15' }} >
                                        <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.STATUS}</span>
                                    </div>
                                    {podeAprovarOutorga && <div style={{ display: 'flex', flex: '0.10' }}>
                                        <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ACAO}</span>
                                    </div>}
                                </div>

                                <div className="grid-agendamentoDashboardConta" style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                                    {
                                        this.props.agendamentoDashboardConta.listaDeAgendamentos && this.props.agendamentoDashboardConta.listaDeAgendamentos.map((linhaAgendamento, idx) => {
                                            const desabilitaBotoes = ((linhaAgendamento.operacao === OPERACAO.NOME.MENSALIDADE_CARTAO_CARTAO_COBRADA_CONTA) || (linhaAgendamento.statusInterno !== STATUS.INTERNO.ABERTO && linhaAgendamento.statusInterno !== STATUS.INTERNO.AGENDADO));
                                            return (
                                                <div style={{ display: 'flex' }} key={idx}>
                                                    <div style={{ display: 'flex', flex: '0.24' }}>
                                                        <span style={{ fontSize: '11px' }}>
                                                            {linhaAgendamento.dataAgendamento ? formatarDataBR(linhaAgendamento.dataAgendamento) : formatarDataBR(linhaAgendamento.dataCriacao)}
                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: '0.34' }} >
                                                        <span style={{ fontSize: '11px' }}>
                                                            {linhaAgendamento.operacaoNome}
                                                        </span>
                                                        <br />
                                                        <span style={{ display: 'flex', fontSize: '11px' }}>
                                                            {linhaAgendamento.tagCliente}
                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: '0.15' }}>
                                                        <span style={{ fontSize: '11px' }}>
                                                            {formatarCampoValor(linhaAgendamento.tipo, linhaAgendamento.valor, linhaAgendamento.moeda)}
                                                        </span>
                                                    </div>
                                                    <div style={{ display: 'flex', flex: '0.15' }}>
                                                        <span style={{ fontSize: '11px' }}>
                                                            {linhaAgendamento.statusExibicao}
                                                        </span>
                                                    </div>
                                                    {
                                                        podeAprovarOutorga && <div style={{ display: 'flex', flex: '0.10' }}>
                                                            <span style={{ cursor: 'pointer' }} onClick={() => this.abrirDetalhesDeAgendamento(linhaAgendamento, TIPO_DE_TRANSACAO.APROVACAO)} className="glyphicon glyphicon-list-alt" />
                                                        </div>
                                                    }
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </If>
                    }
                    <If test={this.props.agendamentoDashboardConta.listaDeAgendamentos.length <= 0 && !this.props.agendamentoDashboardConta.exibirLoadingAgendamentoEAprovacoes}>
                        <span>{LABEL_COMUNS.NENHUM_AGENDAMENTO_ENCONTRADO}</span>
                    </If>

                </div>
            </div >
        );
    }
    rodapeModal() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={
                    () => {
                        this.props.setExibirModalFinalizacaoAgendamentoEAprovacoes(false);
                        this.init();
                    }} />
        );
    }
}

const mapStateToProps = state => ({
    agendamentoDashboardConta: state.AgendamentoReducer,
    outorga: state.OutorgaOperacaoReducer

});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoEAprovacoesDashboardConta);
