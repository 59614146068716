import React, { Component } from 'react';
import { Alert, Button, Col, FormControl, Panel, Row, FormGroup } from 'react-bootstrap';
import { reactLocalStorage } from 'reactjs-localstorage';
import { CONSTANTES, STATUS_VALIDACAO_COMPONENTES } from './dados-pf-constantes';
import { formatarDataBR } from '../../../componentes/util/data/dataUtil';
import { CPF } from 'cpf_cnpj';
import Grid from '../../componentes-uteis/grid/grid';
import { LABEL_COMUNS, MENSAGENS, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import TokenPassword from 'componentes/comuns/token/token_password';
 
import ShowLineError from 'componentes/comuns/showLineError/show-line-error';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { environment } from '../../../configuracoes/environment';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
 

class DadosPessoaFisica extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            password: '', passwordConfirm: '', passwordNow: '',
            message: '', needToken: false, operationId: '',
            originalContractualAttachmentId: '', passwordErro: '',
			  passwordConfirmError: '', isLoading: false, showModalConfirmation: false, showModalFinalization: false,
			  showModalToken: false, showModalPassword: false, showCredenciaisInvalida:false, responseToken: '', cpf: '', telefone: '',showModalSuccess:false
        };
        this.state = this.INITIAL_STATE;
    }

    componentDidMount() {
        this.props.obterDadosPessoaFisica(reactLocalStorage.get(CONSTANTES.LABELS.PESSOA_FISICA_ID));
      
    }

   
    setShowModalConfirmation = (showModalConfirmation) => {
        this.setState({ showModalConfirmation });
    }

     

    closeModal = () =>{
        this.setState({ showModalToken: false });
    }

    setShowModalFinalization = (showModalFinalization) => {
        this.setState({ showModalFinalization });
    }

    closeModalPassword = () =>{
        this.setState({ showModalPassword: false });
    }

    validForm = () => {
        let { selectedCard, password, passwordConfirm, passwordNow, showModalToken, showModalPassword, responseToken,cpf,telefone } = this.state;

        let passwordErro = undefined;
        let passwordConfirmError = undefined;

        if (!password) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (passwordErro) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.SENHA_INVALIDA;
        }
        if (!passwordConfirm) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (passwordConfirmError) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;
        }
        if ((passwordNow && passwordConfirm) && (passwordNow !== passwordConfirm)) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;
        }
        if (passwordErro || passwordConfirmError) {
            if (passwordErro) {
                this.setState({ passwordErro });
            }
            if (passwordConfirmError) {
                this.setState({ passwordConfirmError });
            }
        } else {
              
            
            this.setState({ passwordErro:false });
			
            this.setState({ passwordConfirmError:false });

            cpf = this.props.dadospessoafisicareducer.dadosPessoaFisica.documento.map((item) => {
                return item.ativo && item.tipo === 'CPF' ? item.numero : '';
            }).join('');


            telefone = this.props.dadospessoafisicareducer.dadosPessoaFisica.telefone.map((item) => {
                return item.principal ? item.ddi + item.ddd + item.numero : '';
            }).join('');



            fetch(environment.urlAPIFenix + 'v1/auth/token', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8',
                },
                body: '{"id":"' + environment.tokenId + '","chaveSecreta":"' + environment.tokenChaveSecreta + '","usuario":"' + cpf + '","senha":"' + password + '"}'
            })
                .then(function(response) {
                    if (response.ok)
                        return response.json();
                    throw response;
                })
                .then(respostaToken => {

						 this.setState({ isLoading: true });

                    fetch(environment.urlAPIFenix + 'v1/tokens', {
                        method: 'POST',

                        headers: {
                            'Content-Type': 'application/json; charset=UTF-8',
                        },
                        body: '{"cpf":"' + cpf + '","telefone":"' + telefone + '"}'
                    })
                        .then(function(response) {
                            if (response.ok)
                                return response.json();
                            throw response;
                        })
                        .then(responseTokens => {
                            
                            this.setState({ 
                                showModalToken: true, 
                                cpf: cpf, 
                                responseToken: respostaToken.token,
                                password: password, 
                                passwordNow: passwordNow, 
                                telefone: telefone,
										  isLoading:false
                            });

								 


                        })
                        .catch(error => {
                            console.log(error);
                            this.setState({ isLoading: false });
                        });


                })
                .catch(error => {
                    console.log(error);
                    error.text().then(errorMessage => {
							  this.setState({ showCredenciaisInvalida: true});
                    });
						 this.setState({ isLoading: false });
                });



        }
    }
    returnFooterModalFinalization = () => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    window.location.href='dados-pessoa-fisica';
                }}
            />
        );
    }

	retornoDaEfetivacao = (retorno) => {
	    this.setState({
	        showModalToken: false,
	        showModalSuccess:true
	    });
		 
	}

  retornaDadosForm = () => {
      const { saldoConta: { saldo }, contaSelecionada } = this.props.solicitacao;
      const dadosForm = { ...this.state, saldo, contaSelecionada };
      return dadosForm;
  }

  render() {
		 let { operationId, showModalToken, isLoading, showModalSuccess,showCredenciaisInvalida,password, passwordConfirm, passwordNow, passwordErro, passwordConfirmError, responseToken,cpf,telefone } = this.state;

       

 
      return (
          <div>
              <Row>&nbsp;</Row>
              <Row>
                  <br />
                  <Col className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                      <Panel>
                          <Panel.Heading className="fundo-azul-claro">{CONSTANTES.LABELS.LISTA_DADOS_PF}</Panel.Heading>
                          <Panel.Body>
                              {
                  this.props.dadospessoafisicareducer.erroBuscarDadosPessoaFisica &&
                  <Row>
                      <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <Alert bsStyle="danger">{CONSTANTES.LABELS.ERRO_OBTER_DADOS}</Alert>
                      </Col>
                  </Row>
                }


                               
                              <Row>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Documento</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.documento.map((item) => {
                        return item.ativo && item.tipo === 'CPF' ? CPF.format(item.numero) : '';
                      }).join('')}
                      placeholder="Documento"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Data de Nascimento</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && formatarDataBR(this.props.dadospessoafisicareducer.dadosPessoaFisica.pessoaFisica.dataNascimento)}
                      placeholder="Data de Nascimento"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                      <strong>Nome Completo</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.pessoaFisica.nomeCompleto}
                      placeholder="Nome completo"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                      <strong>Nome da Mãe</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.pessoaFisica.nomeMae}
                      placeholder="Nome da Mãe"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                      <strong>E-mail</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.email.map((item) => {
                        return (item.principal && item.email) ? item.email : ' ';
                      }).join('')}
                      placeholder="E-mail"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Celular</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.telefone.map((item) => {
                        if (item.principal & item.tipo === 'CELULAR') {
                          if (item.ddi) {
                            return '+' + item.ddi + ' ' + item.ddd + ' ' + item.numero;
                          } else {
                            return '(' + item.ddd + ') ' + item.numero;
                          }
                        } else {
                          return ' ';
                        }
                      }).join('')}
                      placeholder="Celular"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                      <strong>Logradouro</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.logradouro) ? item.logradouro : ' ';
                      }).join('')}
                      placeholder="Logradouro"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                      <strong>Número</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.numero) ? item.numero : ' ';
                      }).join('')}
                      placeholder="Número"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Complemento</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.complemento) ? item.complemento : ' ';
                      }).join('')}
                      placeholder="Complemento"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                      <strong>Bairro</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.bairro) ? item.bairro : ' ';
                      }).join('')}
                      placeholder="Bairro"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                      <strong>CEP</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.cep) ? this.formataCEP(item.cep) : ' ';
                      }).join('')}
                      placeholder="CEP"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                      <strong>Cidade</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.cidade) ? item.cidade : ' ';
                      }).join('')}
                      placeholder="Cidade"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                      <strong>Estado</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.estado) ? item.estado : ' ';
                      }).join('')}
                      placeholder="Estado"
                      disabled />
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>País</strong>
                                      <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoafisicareducer.dadosPessoaFisica && this.props.dadospessoafisicareducer.dadosPessoaFisica.endereco.map((item) => {
                        return (item.ativo && item.pais) ? item.pais : ' ';
                      }).join('')}
                      placeholder="País"
                      disabled />
                                  </Col>
                              </Row>
                              <Row>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Senha Atual</strong>
                                        
                                      <FormGroup validationState={passwordErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                          <FormControl
                                                id="password"
                                                type="password"
                                                className="mensagens-erro form-control changePassword"
                                                placeholder={LABEL_COMUNS.SENHA}
                                                value={password}
                                                onChange={(e) => this.setState({ password: e.target.value }) }
                                            />
                                          <ShowLineError error={passwordErro} />
                                      </FormGroup>
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Nova Senha</strong>
                                      <FormGroup validationState={passwordConfirmError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                          <FormControl
                                                id="passwordNow"
                                                type="password"
                                                className="mensagens-erro form-control changePassword"
                                                placeholder={LABEL_COMUNS.SENHA}
                                                value={passwordNow}
                                                onChange={(e) => this.setState({ passwordNow: e.target.value })}
                                            />
                                          <ShowLineError error={passwordConfirmError} />
                                          <strong>A senha deve ter 8 à 16 caracteres contendo letra maiúscula, minúscula e número.</strong>
                                            
                                            
                                            
                                      </FormGroup>
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong>Repetir Senha</strong>
                                      <FormGroup validationState={passwordConfirmError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                          <FormControl
                                                id="passwordConfirm"
                                                type="password"
                                                className="mensagens-erro form-control changePassword"
                                                placeholder={LABEL_COMUNS.SENHA}
                                                value={passwordConfirm}
                                                onChange={(e) => this.setState({ passwordConfirm: e.target.value })}
                                            />
                                          <ShowLineError error={passwordConfirmError} />
                                      </FormGroup>
                                  </Col>
                                  <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                      <strong><br/></strong>
                                      <Button type="button" bsStyle="primary" onClick={this.validForm} >{LABEL_COMUNS.SALVAR}</Button>
                                  </Col>


                                  <TokenPassword
                                        titulo={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA}
                                        operacaoId={operationId}
                                        dadosIntegracao={TIPO_DE_TRANSACAO.APROVACAO}
                                        dados={{ cpf: cpf, token: responseToken, password: password,passwordNow: passwordNow,telefone: telefone }}
                                        fecharModal={this.closeModal}
                                        resultadoCallback={this.retornoDaEfetivacao}
                                        exibirModal={showModalToken} 
										 			 isLoading = {isLoading}
													 />

                                  <ModalComponent
                                        header={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA}
                                        exibirModal={showModalSuccess}
                                        body={<div dangerouslySetInnerHTML={{ __html: 'Senha Alterada com sucesso!' }} />}
                                        footer={this.returnFooterModalFinalization()}
                                    />

                                  <ModalComponent
													header={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA}
													exibirModal={showCredenciaisInvalida}
													body={<div dangerouslySetInnerHTML={{ __html: 'Credenciais Inválidas' }} />}
													footer={this.returnFooterModalFinalization()}
												/>
                                   
                              </Row>
                              {
                                    this.props.dadospessoafisicareducer.senhaNaoConfere &&
                                    <Alert bsStyle="danger">{CONSTANTES.LABELS.SENHA_NAO_CONFERE}</Alert>
                                    }
                                 
                          </Panel.Body>
                      </Panel>
                  </Col>
                  <Grid cols="11 11 11 11" className="alinhamento-direita">
                      <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                  </Grid>
              </Row>
          </div>
      );
  }

  formataCEP(cepApenasNumeros) {
      return cepApenasNumeros.substring(0, 5) + '-' + cepApenasNumeros.substring(5, 8); // 17400123 --> 17400-123
  }
}

export default DadosPessoaFisica;
