import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Glyphicon } from 'react-bootstrap';
import * as actions from 'action/cartoes/cartoes-action';
import GridCartoesDashboardBppConsumer from 'componentes/solucoes/dashboard/bpp-consumer/cartoes/grid-cartoes-dashboard-bpp-consumer';
import Loading from 'componentes/loading-fenix/loading';
import CargaEDescargaCartao from 'componentes/cartao/carga-e-descarga-cartao/carga-e-descarga-cartao';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { LABEL_COMUNS, OPERACAO, TIPO_DE_ACESSO } from 'componentes/util/contantes-comuns/constantes-comuns';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';
import AlterarSenhaCartao from 'componentes/comuns/cartao/alterar-senha-cartao';
import AtualizarPagina from 'componentes/comuns/atualizar-pagina/atualizar-pagina';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import BloquearCartao from 'componentes/cartao/bloquear-cartao/bloquear-cartao';
import CancelarCartao from 'componentes/cartao/cancelar-cartao/cancelar-cartao';
import DesbloquearCartao from 'componentes/cartao/desbloquear-cartao/desbloquear-cartao';
import DetalhesDoPortador from 'componentes/comuns/portador/detalhes-do-portador';
import AtivarCartao from 'componentes/cartao/ativar-cartao/ativar-cartao';
import If from 'componentes/componentes-uteis/if/if';
import FiltroGenerico from 'componentes/componentes-uteis/filtro-generico/filtro-generico';

class ListaCartoesDashboardBppConsumer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            abrirCollapse: false,
            current: undefined,
            showModalAlterarSenhaCartao: false,
            exibirModalDetalhePortador: false,
            showCancelCardModal: false,
            showLockCardModal: false,
            showFinalizationModal: false,
            showUnlockCardModal: false,
            showUnlockFinalizationModal: false,
            showCancelFinalizationModal: false,
            showLockFinalizationModal: false,
            showActivateCardModal: false,
            showChargeOrDischargeCard: false
        };
    }

    componentDidMount = () => {
        this.props.setClearState();
        this.props.limparCartoes();
        let contaSelecionada = this.props.contaSelecionada;
        this.props.setContaSelecionada(contaSelecionada);
        this.getCartoes();
        this.props.limparFiltroCartao();
    }

    componentWillReceiveProps = (nextProps) => {
        const naoExisteCartoes = !nextProps.estadoCartoes.listaDeCartoesAtivos.length
            && !nextProps.estadoCartoes.listaDeCartoesBloqueados.length
            && !nextProps.estadoCartoes.listaDeCartoesCanceladosEOutros.length
            && !nextProps.estadoCartoes.listaDeCartoesProntosParaAtivacao.length;


        if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesAtivos.length) {
            this.setState({ current: 0 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesBloqueados.length) {
            this.setState({ current: 1 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesCanceladosEOutros.length) {
            this.setState({ current: 2 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesProntosParaAtivacao.length) {
            this.setState({ current: 3 });
        }
        else if (naoExisteCartoes) {
            this.setState({ current: 0 });
        }
    }

    setShowModalAlterarSenhaCartao = (showModalAlterarSenhaCartao) => {
        this.setState({ showModalAlterarSenhaCartao });
    };

    getCartoes = () => {
        this.props.getCartoes(this.props.contaSelecionada.id);
    };

    setShowLockCardModal = (showLockCardModal) => {
        this.setState({ showLockCardModal });
    };

    setShowFinalizationModal = (showFinalizationModal) => {
        this.setState({ showFinalizationModal });
    };

    setShowUnlockCardModal = (showUnlockCardModal) => {
        this.setState({ showUnlockCardModal });
    };

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setShowUnlockFinalizationModal = (showUnlockFinalizationModal) => {
        this.setState({ showUnlockFinalizationModal });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    setExibirModalDetalhePortador = (exibirModalDetalhePortador) => {
        this.setState({ exibirModalDetalhePortador });
    };

    setShowActivateCardModal = (showActivateCardModal) => {
        this.setState({ showActivateCardModal });
    };
    setShowChargeOrDischargeCard = (showChargeOrDischargeCard) => {
        this.setState({ showChargeOrDischargeCard });
    }

    render() {
        const { showModalAlterarSenhaCartao, showCancelCardModal,
            showLockCardModal, showUnlockCardModal, showUnlockFinalizationModal, showCancelFinalizationModal,
            showLockFinalizationModal, showActivateCardModal, showChargeOrDischargeCard
        } = this.state;

        const { cartaoSelecionado, exibirLoadingCartao } = this.props.estadoCartoes;

        const { grantChangeStatusCard, grantUnlockCard, grantChangePinCard,
            grantChargeCard, grantDisChargeCard, outorgaPerfils } = this.props;

        const underlineStyle = show => {
            return show ? { textDecoration: 'underline', fontWeight: 'bold' } : {};
        };

        return (
            <div>

                <CargaEDescargaCartao
                    exibirModal={showChargeOrDischargeCard}
                    contaSelecionada={this.props.contaSelecionada}
                    cartaoSelecionado={this.props.estadoCartoes.cartaoSelecionado}
                    operacaoSelecionada={this.props.estadoCartoes.operacaoSelecionada}
                    setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                    grantChargeCard={grantChargeCard}
                    grantDisChargeCard={grantDisChargeCard}
                    grantProfile={outorgaPerfils}
                    />

                <AlterarSenhaCartao
                    showModalAlterarSenhaCartao={showModalAlterarSenhaCartao}
                    selectedCard={cartaoSelecionado}
                    reloadCards={this.getCartoes}
                    needToken={this.props.grantChangePinCard}
                    setShowModalAlterarSenhaCartao={this.setShowModalAlterarSenhaCartao}
                    grantProfile={outorgaPerfils}
                />

                <DetalhesDoPortador
                    cartaoSelecionado={this.props.estadoCartoes.cartaoSelecionado}
                    exibirModal={this.state.exibirModalDetalhePortador}
                    setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                    showOnlyDetails={true}
                />


                <BloquearCartao
                    showLockCardModal={showLockCardModal}
                    setShowLockLoading={this.props.setExibirLoadingCartao}
                    lockSelectedCard={cartaoSelecionado}
                    lockGetCards={this.getCartoes}
                    setShowLockCardModal={this.setShowLockCardModal}
                    showLockFinalizationModal={showLockFinalizationModal}
                    setShowLockFinalizationModal={this.setShowLockFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}

                />

                <DesbloquearCartao
                    showUnlockCardModal={showUnlockCardModal}
                    setShowUnlockLoading={this.props.setExibirLoadingCartao}
                    unlockSelectedCard={cartaoSelecionado}
                    unlockGetCards={this.getCartoes}
                    setShowUnlockCardModal={this.setShowUnlockCardModal}
                    showUnlockFinalizationModal={showUnlockFinalizationModal}
                    setShowUnlockFinalizationModal={this.setShowUnlockFinalizationModal}
                    grantUnlockCard={grantUnlockCard}
                    grantProfile={outorgaPerfils}

                />

                <CancelarCartao
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />

                <AtivarCartao
                    needToken={this.props.grantChangePinCard}
                    showActivateCardModal={showActivateCardModal}
                    setShowActivateLoading={this.props.setExibirLoadingCartao}
                    selectedCard={cartaoSelecionado}
                    setShowActivateCardModal={this.setShowActivateCardModal}
                    grantChangePinCard={grantChangePinCard}
                    grantProfile={outorgaPerfils}
                />

                <div className="panel panel-default">
                    <div className={ESTILO.COR_DE_FUNDO.TITULO.PADRAO} style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.CARTOES.TITULOS.MEUS_CARTOES}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <AtualizarPagina onClick={() => this.getCartoes()} title={LABEL_COMUNS.CARTOES.TITULOS.BUSCAR_CARTOES} />
                            <Button className="estilo-filtro" onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            &nbsp;&nbsp;
                            <Dropdown id="menuOpcoes" pullRight >
                                <Dropdown.Toggle noCaret className="fundo-azul-claro dropdown-sem-bordas">
                                    <Glyphicon glyph="option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <li>
                                        <Link className="link_expandir" to={ROTAS_CAMINHOS.CARTOES_BPP_CONSUMER}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico
                        abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeCartoes={true}
                        cartaoId={this.props.estadoCartoes.cartaoId}
                        setCartaoId={this.props.setCartaoId}
                        cartaoIdErro={this.props.estadoCartoes.portadorCartaoIdErro}

                        cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                        setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                        cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}

                        exibirBotaoLimpar={true}
                        limparFiltro={this.props.limparFiltroCartao}
                        buscar={() => this.props.getCartoes(this.props.estadoCartoes.contaSelecionada.id,
                            this.props.produtosIdsBppCorp, this.props.estadoCartoes.cartaoId, this.props.estadoCartoes.nomePortador,
                            this.props.estadoCartoes.numeroDocumentoPortador, this.props.estadoCartoes.cartaoQuatroUltimosDigitos)} />
                    <div className="panel-body" style={{ height: '350px' }}>
                        <If test={exibirLoadingCartao}>
                            <div className="alinhamento-centro">
                                <Loading />
                            </div>
                        </If>
                        <If test={!exibirLoadingCartao}>
                            <div>
                                <If test={this.state.current === 0}>
                                    <GridCartoesDashboardBppConsumer
                                        outorgaPerfils={outorgaPerfils}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        mostrarSaldoCartao={true}
                                        setShowModalAlterarSenhaCartao={this.setShowModalAlterarSenhaCartao}
                                        setShowCancelCardModal={this.setShowCancelCardModal}
                                        setShowLockCardModal={this.setShowLockCardModal}
                                        setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        statusCartaoNaoEncontrado={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.ATIVO}
                                        setShowModalAlterarApelidoCartao={this.setShowModalAlterarApelidoCartao}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesAtivos} />
                                </If>
                                <If test={this.state.current === 1}>
                                    <GridCartoesDashboardBppConsumer
                                        outorgaPerfils={outorgaPerfils}
                                        statusCartaoNaoEncontrado={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.BLOQUEADO}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        setShowUnlockCardModal={this.setShowUnlockCardModal}
                                    />
                                </If>
                                <If test={this.state.current === 2}>
                                    <GridCartoesDashboardBppConsumer
                                        outorgaPerfils={outorgaPerfils}
                                        statusCartaoNaoEncontrado={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.CANCELADO_E_OUTRO}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        setShowUnlockCardModal={this.setShowUnlockCardModal}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros} />
                                </If>
                                <If test={this.state.current === 3}>
                                    <GridCartoesDashboardBppConsumer
                                        outorgaPerfils={outorgaPerfils}
                                        setShowModalCancelCard={this.setShowModalCancelCard}
                                        statusCartaoNaoEncontrado={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.PRONTO_PARA_ATIVACAO}
                                        tipoDeAcesso={TIPO_DE_ACESSO.SELECIONADO()}
                                        setShowActivateCardModal={this.setShowActivateCardModal}
                                        setShowModalAlterarApelidoCartao={this.setShowModalAlterarApelidoCartao}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        setCartaoSelecionadoParaVincularPortador={this.props.setCartaoSelecionadoParaVincularPortador}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        setShowLockCardModal={this.setShowLockCardModal}
                                        setShowCancelCardModal={this.setShowCancelCardModal}
                                        setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao} />
                                </If>
                            </div>
                        </If>

                    </div>
                    <div className="cartoes-status">
                        <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 0 })} style={underlineStyle(this.state.current === 0)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.ATIVOS}&nbsp;({this.props.estadoCartoes.listaDeCartoesAtivos.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 1 })} style={underlineStyle(this.state.current === 1)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.BLOQUEADOS}&nbsp;({this.props.estadoCartoes.listaDeCartoesBloqueados.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 2 })} style={underlineStyle(this.state.current === 2)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS_E_OUTROS}&nbsp;({this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 3 })} style={underlineStyle(this.state.current === 3)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}&nbsp;({this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length})</span>
                    </div>
                </div>



            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    estadoCartoes: state.CartoesReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    grantChangeStatusCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERA_STATUS_CARTAO),
    grantUnlockCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESBLOQUEIO_CARTAO),
    grantChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.CARGA_CARTAO),
    grantDisChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESCARGA_CARTAO),
    grantChangePinCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERACAO_PIN_CARTAO)
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesDashboardBppConsumer);
