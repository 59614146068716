import React, { Component } from 'react';
import { Alert, Button, Col, FormControl, Panel, Row } from 'react-bootstrap';
import { reactLocalStorage } from 'reactjs-localstorage';
import { CONSTANTES } from './dados-pj-constantes';
import { formatarDataBR } from '../../util/data/dataUtil';
import { CNPJ } from 'cpf_cnpj';
import { LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';

class DadosPessoaJuridica extends Component {
    componentDidMount() {
        this.props.obterDadosPessoaJuridica(reactLocalStorage.getObject(CONSTANTES.LABELS.CONTA_SELECIONADA).pessoaTitularId);
    }

    render() {
        return (
            <div>
                <Row>&nbsp;</Row>
                <Row>
                    <br />
                    <Col className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <Panel>
                            <Panel.Heading className="fundo-azul-claro">{CONSTANTES.LABELS.LISTA_DADOS_PJ}</Panel.Heading>
                            <Panel.Body>
                                {
                  this.props.dadospessoajuridicareducer.erroBuscarDadosPessoaJuridica &&
                  <Row>
                      <Col className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                          <Alert bsStyle="danger">{CONSTANTES.LABELS.ERRO_OBTER_DADOS}</Alert>
                      </Col>
                  </Row>
                }
                                <Row>
                                    <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <strong>Documento</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.documento.map((item) => {
                        return item.ativo && item.tipo === 'CNPJ' ? CNPJ.format(item.numero) : '';
                      }).join('')}
                      placeholder="Documento"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <strong>Inscrição Estadual</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={(this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.pessoaJuridica.inscricaoEstadual) || ' '}
                      placeholder="Inscrição Estadual"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <strong>Data Abertura</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && formatarDataBR(this.props.dadospessoajuridicareducer.dadosPessoaJuridica.pessoaJuridica.dataConstituicao)}
                      placeholder="Data de Abertura"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <strong>Razão Social</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.pessoaJuridica.razaoSocial}
                      placeholder="Razão Social"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <strong>Nome Fantasia</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={(this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.pessoaJuridica.nomeFantasia) || ' '}
                      placeholder="Nome Fantasia"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <strong>Forma de Constituição</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={(this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.pessoaJuridica.formaConstituicao) || ' '}
                      placeholder="Forma de Constituição"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                        <strong>E-mail</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.email.map((item) => {
                        return (item.principal && item.email) ? item.email : ' ';
                      }).join('')}
                      placeholder="E-mail"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <strong>Telefone Comercial 1</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.telefone.map((item) => {
                        if (item.principal & item.tipo === 'COMERCIAL') {
                          if (item.ddi) {
                            return '+' + item.ddi + ' ' + item.ddd + ' ' + item.numero;
                          } else {
                            return '(' + item.ddd + ') ' + item.numero;
                          }
                        } else {
                          return ' ';
                        }
                      }).join('')}
                      placeholder="Comercial"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <strong>Telefone Comercial 2</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.telefone.map((item) => {
                        if (!item.principal & item.tipo === 'COMERCIAL') {
                          if (item.ddi) {
                            return '+' + item.ddi + ' ' + item.ddd + ' ' + item.numero;
                          } else {
                            return '(' + item.ddd + ') ' + item.numero;
                          }
                        } else {
                          return ' ';
                        }
                      }).join('')}
                      placeholder="Comercial"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <strong>Celular</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.telefone.map((item) => {
                        if (item.tipo === 'CELULAR') {
                          if (item.ddi) {
                            return '+' + item.ddi + ' ' + item.ddd + ' ' + item.numero;
                          } else {
                            return '(' + item.ddd + ') ' + item.numero;
                          }
                        } else {
                          return ' ';
                        }
                      }).join('')}
                      placeholder="Celular"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                        <strong>Logradouro</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.logradouro) ? item.logradouro : ' ';
                      }).join('')}
                      placeholder="Logradouro"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <strong>Número</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.numero) ? item.numero : ' ';
                      }).join('')}
                      placeholder="Número"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <strong>Complemento</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.complemento) ? item.complemento : ' ';
                      }).join('')}
                      placeholder="Complemento"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-7 col-sm-7 col-md-7 col-lg-7">
                                        <strong>Bairro</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.bairro) ? item.bairro : ' ';
                      }).join('')}
                      placeholder="Bairro"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                        <strong>CEP</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.cep) ? this.formataCEP(item.cep) : ' ';
                      }).join('')}
                      placeholder="CEP"
                      disabled />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                                        <strong>Cidade</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.cidade) ? item.cidade : ' ';
                      }).join('')}
                      placeholder="Cidade"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                        <strong>Estado</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.estado) ? item.estado : ' ';
                      }).join('')}
                      placeholder="Estado"
                      disabled />
                                    </Col>
                                    <Col className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                                        <strong>País</strong>
                                        <FormControl
                      type="text"
                      style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                      value={this.props.dadospessoajuridicareducer.dadosPessoaJuridica && this.props.dadospessoajuridicareducer.dadosPessoaJuridica.endereco.map((item) => {
                        return (item.ativo && item.pais) ? item.pais : ' ';
                      }).join('')}
                      placeholder="País"
                      disabled />
                                    </Col>
                                </Row>
                            </Panel.Body>
                        </Panel>
                    </Col>
                    <Grid cols="11 11 11 11" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                    </Grid>
                </Row>
            </div>
        );
    }

    formataCEP(cepApenasNumeros) {
        return cepApenasNumeros.substring(0, 5) + '-' + cepApenasNumeros.substring(5, 8); // 17400123 --> 17400-123
    }
}

export default DadosPessoaJuridica;
