import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import React, { Component } from 'react';
import { CONTA_SELECIONADA, TIPO_DE_ACESSO, PESSOA_LOGADA } from './util/contantes-comuns/constantes-comuns';
import { environment } from '../configuracoes/environment';
import AgendamentoAprovacoesConta from '../componentes/contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamentos-aprovacoes-conta';
import AgendamentoEAprovacoesBPPCorp from '../componentes/solucoes/dashboard/bpp-corp/agendamento-e-aprovacoes-bpp-corp/agendamento-e-aprovacoes-bpp-corp';
import AgendamentoEAprovacoesBppPag from '../componentes/solucoes/dashboard/bpp-pag/agendamentos-e-aprovacoes-bpp-pag/agendamento-e-aprovacoes-bpp-pag';
import AlterarTitularidadeCartao from '../componentes/solucoes/dashboard/bpp-pag/cartoes/alterar-titularidade-cartao';
import CargaLoteCartao from '../componentes/carga-lote-cartao/consulta-carga-lote-cartao';
import ConsultaCargaLote from '../componentes/carga-lote/consulta-carga-lote';
import ConsultaDePedidosBPPConsumer from 'componentes/solucoes/dashboard/bpp-consumer/consulta-de-pedidos/consulta-de-pedidos-bpp-consumer';
import ConsultaDePedidosBppCorp from './solucoes/dashboard/bpp-corp/consulta-de-pedidos/consulta-de-pedidos-bpp-corp';
import ConsultaDePedidosBppPag from './solucoes/dashboard/bpp-pag/consulta-de-pedidos/consulta-de-pedidos-bpp-pag';
import DadosPessoaFisicaContainer from './listar-dados/pf/dados-pf-container';
import DadosPessoaJuridicaContainer from './listar-dados/pj/dados-pj-container';
import DashboardBppConsumer from './solucoes/dashboard/bpp-consumer/dashboard-bpp-consumer';
import DashboardBppCorp from './solucoes/dashboard/bpp-corp/dashboard-bpp-corp';
import DashboardBppPag from './solucoes/dashboard/bpp-pag/dashboard-bpp-pag';
import DashboardConta from './contas/dashboard/dashboard-conta';
import DashboradRelatoriosBppCorp from './solucoes/dashboard/bpp-corp/relatorios/dashboard-relatorios-bpp-corp';
import DashboardDepartamento from './contas/conta-departamento/dashboard-departamento';
import DetalhesBppCorp from './solucoes/dashboard/bpp-corp/detalhes-bpp-corp/detalhes-bpp-corp';
import DetalhesBppPag from './solucoes/dashboard/bpp-pag/detalhes-bpp-pag/detalhes-bpp-pag';
import DetalheCartao from '../componentes/cartao/detalhe-cartao';
import DetalheConta from '../componentes/contas/detalhe-conta';
import GerenciarPrestacaoConta from '../componentes/prestacao-contas/gerenciar/gerenciar-prestacao-contas';
import ListaCartoesBppConsumer from './solucoes/dashboard/bpp-consumer/cartoes/lista-cartoes-bpp-consumer';
import ListaCartoesBppCorp from './solucoes/dashboard/bpp-corp/cartoes/lista-cartoes-bpp-corp';
import ListaCartoesBppPag from '../componentes/solucoes/dashboard/bpp-pag//cartoes/lista-cartoes-bpp-pag';
import ListaContaDepartamentoContainer from './contas/conta-departamento/lista-conta-departamento/lista-conta-departamento-container';
import ListarAutorizadosDaConta from '../componentes/contas/poderes/listar-autorizados-conta';
import ListarComprovantes from './contas/comprovante/listar-comprovantes';
import ListarContas from '../componentes/listar-contas/listar-contas';
import ListarFavorecido from '../componentes/contas/favorecidos/listar-favorecidos';
import NovoCargaLote from '../componentes/carga-lote/novo/novo-carga-lote';
import NovoCargaLoteCartao from '../componentes/carga-lote-cartao/novo/novo-carga-lote-cartao';
import Pagamento from '../componentes/pagamento/pagamento';
import ParametroPrestacao from '../componentes/contas/parametro-prestacao/parametro-prestacao';
import PedidoCarga from '../componentes/pedido-carga/pedido-carga';
import PedidoCargaSolicitacao from '../componentes/pedido-carga/pedido-carga-solicitacao';
import PedirCartaoBppConsumer from '../componentes/solucoes/dashboard/bpp-consumer/cartoes/pedir-cartao-bpp-consumer';
import PedidoCartaoVirtual from '../componentes/pedidos/cartao-virtual/pedido-cartao-virtual';
import PedidoCartaoVirtualResumo from '../componentes/pedidos/cartao-virtual/pedido-cartao-virtual-resumo';
import PedidoEmbossingCartaoBppCorp from './pedidos/embossing-cartao/bpp-corp/pedido-embossing-cartao-bpp-corp';
import PedidoEmbossingCartaoBppCorpResumo from './pedidos/embossing-cartao/bpp-corp/pedido-embossing-cartao-bpp-corp-resumo';
import PedidoEmbossingCartaoBppPag from './pedidos/embossing-cartao/bpp-pag/pedido-embossing-cartao-bpp-pag';
import PedidoEmbossingCartaoBppPagResumo from './pedidos/embossing-cartao/bpp-pag/pedido-embossing-cartao-bpp-pag-resumo';
import PedidoEstoque from '../componentes/pedido-estoque/pedido-estoque';
import PrestacaoConta from '../componentes/prestacao-contas/prestacao/prestacao-conta';
import RelatorioAnaliticoBppCorp from './solucoes/dashboard/bpp-corp/relatorios/relatorio-analitico-bpp-corp';
import RelatorioGerencialBPPCorp from './solucoes/dashboard/bpp-corp/relatorios/relatorio-gerencial-bpp-corp';
import Sair from '../componentes/login/sair';
import TranferenciaEmLoteDetalhes from './solucoes/dashboard/bpp-pag/transferencia-lote/transferencia-em-lote-detalhes';
import TranferenciaEmLoteHistorico from './solucoes/dashboard/bpp-pag/transferencia-lote/transferencia-em-lote-historico';
import TranferenciaEmLoteUploadoArquivo from './solucoes/dashboard/bpp-pag/transferencia-lote/transferencia-em-lote-upload-arquivo';
import ArquivosTransferenciaLote from './solucoes/dashboard/bpp-pag/transferencia-lote/arquivosTransferenciaLote';
import TransferenciaBancaria from '../componentes/transferencia/transferencia-bancaria';
import TransferenciaBancariaSolicitacao from '../componentes/transferencia/transferencia-bancaria-solicitacao';
import VincularCartaoBppConsumer from '../componentes/solucoes/dashboard/bpp-consumer/cartoes/vincular-cartao-bpp-consumer';

import {
    getSolucaoBppCorp,
    getSolucaoBppPag,
    hasBppCorpSolucao,
    hasBppPagSolucao,
    produtosIdsBppCorp,
    produtosIdsBppPag
} from '../reducer/anexo-contratual/anexo-contratual-reducer';
import ProcessamentoLoteHistorico from './solucoes/dashboard/bpp-pag/transferencia-lote/processamento-lote-historico';
import LoadingRotas from './loading-fenix/loading-rotas';
import Tentativas from './tentativas/tentativas';
import LotesDoArquivo from './solucoes/dashboard/bpp-pag/transferencia-lote/lotes-do-arquivo';
import ItensDoLote from './solucoes/dashboard/bpp-pag/transferencia-lote/itens-do-lote';
import ImportacaoCargaLoteCartao from './solucoes/dashboard/bpp-corp/carga-lote/importacao-carga-lote-cartao-bpp-corp';
import { getPoderesOutorgaPorPessoa, getParametoParametroOperacao } from '../servicos/outorga/outorga-service';
import { reactLocalStorage } from 'reactjs-localstorage';
import { podeConsultar, podeCriar } from '../negocio/outorga/outorga-negocio';
import AlgoAconteceu from './algo-aconteceu/algo-aconteceu';
import { bindActionCreators } from 'redux';
import { setListaParametroOperacao, cleanListaParametroOperacao, setPerfils } from '../action/outorga/outorga-action';
import TransferenciaEmLoteRetorno
    from 'componentes/solucoes/dashboard/bpp-pag/transferencia-lote/transferencia-em-lote-retorno';

const { produtoIdAnexo, produtoIdAnexoBppPag } = environment;
const solucoes = [{ name: 'BppCorp', listaProdutosIds: produtoIdAnexo }, { name: 'BppPag', listaProdutosIds: produtoIdAnexoBppPag }];

class MinhaContaContextRouteView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tipoDeAcesso: TIPO_DE_ACESSO.SELECIONADO(),
            carregandoOutorga: true
        };
    }

    componentDidMount() {
        this.props.menuInicio(false);
        this.props.menuInicioContaSelecionada(true);
        this.props.getSolucoes(CONTA_SELECIONADA.OBJETO().pessoaTitularId, solucoes);
        this.props.menuTitular(false);
        this.props.menuTitularContaSelecionada(true);
        this.props.menuContaDigital(true);
        if (this.state.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA) {
            this.props.menuEmpresa(true);
        }
        let contaId = reactLocalStorage.getObject('contaSelecionada').id;
        let pessoaLogadaId = PESSOA_LOGADA().id;

        Promise.all([
            getPoderesOutorgaPorPessoa(contaId, pessoaLogadaId),
            getParametoParametroOperacao()
        ])
            .then(result => {
                this.setState({ carregandoOutorga: false, perfils: result[0].perfil });
                this.props.setPerfils(result[0].perfil);
                this.props.setListaParametroOperacao(result[1].valor.operacao);
            }).catch(err => {
                // TODO: Tela de erro -> manda para tela generica de erro
                this.props.history.replace('/algo-aconteceu');
            });
    }

    componentWillUnmount() {
        this.props.menuInicio(true);
        this.props.menuInicioContaSelecionada(false);
        this.props.menuTitular(true);
        this.props.menuTitularContaSelecionada(false);
        this.props.menuEmpresa(false);
        this.props.menuContaDigital(false);
        this.props.cleanListaParametroOperacao();
    }

    render() {
        const rootRouter = `${this.props.match.url}`;
        const hasBppCorp = this.props.produtosIdsBppCorp !== undefined && this.props.solucaoBppCorp !== undefined;
        const hasBppPag = this.props.produtosIdsBppPag !== undefined && this.props.solucaoBppPag !== undefined;

        if (this.props.erroNaBuscaDosAnexo) {
            return (
                <Tentativas
                    {...this.props}
                    isFetching={this.props.isFetching}
                    onRetry={() => this.props.getSolucoes(CONTA_SELECIONADA.OBJETO().pessoaTitularId, solucoes)} />);
        }

        if ((this.state.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA &&
            !hasBppCorp &&
            !hasBppPag) || this.state.carregandoOutorga) {
            return <LoadingRotas url={this.props.location.pathname} />;
        }

        const podeConsultarOutorga = podeConsultar(this.state.perfils);
        const podeCriarOutorga = podeCriar(this.state.perfils);

        return <div>
            <Route path={`${rootRouter}/minhas-solucoes/bpp-consumer`} render={(props) => <MinhasSolucoesBppConsumerContextRoute {...this.props} {...props} tipoDeAcesso={this.state.tipoDeAcesso} />} />
            <Route path={`${rootRouter}/minhas-solucoes/bpp-corp`} render={(props) => <MinhasSolucoesBppCorpContextRoute {...this.props} {...props} tipoDeAcesso={this.state.tipoDeAcesso} />} />
            <Route path={`${rootRouter}/minhas-solucoes/bpp-pag`} render={(props) => <MinhasSolucoesBppPagContextRoute {...this.props} {...props} tipoDeAcesso={this.state.tipoDeAcesso} />} />
            <Route path={`${rootRouter}/dashboard`} render={(props) => <DashboardConta {...this.props} {...props} perfils={this.state.perfils} />} />
            <Route path={`${rootRouter}/comprovantes`} render={(props) => <ListarComprovantes {...this.props} {...props} perfils={this.state.perfils} />} />
            {podeConsultarOutorga && <Route path={`${rootRouter}/agendamento-aprovacoes`} render={(props) => <AgendamentoAprovacoesConta {...this.props} {...props} perfils={this.state.perfils} />} />}
            <Route path={`${rootRouter}/dados-pessoa-fisica`} component={DadosPessoaFisicaContainer} />
            <Route path={`${rootRouter}/dados-pessoa-juridica`} component={DadosPessoaJuridicaContainer} />
            <Route path={`${rootRouter}/favorecido`} render={(props) => <ListarFavorecido {...this.props} {...props} tipoDeAcesso={this.state.tipoDeAcesso} />} />
            {podeConsultarOutorga && <Route path={`${rootRouter}/carga-lote`} render={(props) => <ConsultaCargaLote {...this.props} {...props} perfils={this.state.perfils} />} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/carga-lote-novo`} component={NovoCargaLote} />}
            {podeConsultarOutorga && <Route path={`${rootRouter}/carga-lote-cartao`} render={(props) => <CargaLoteCartao {...this.props} {...props} perfils={this.state.perfils} />} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/carga-lote-cartao-novo`} component={NovoCargaLoteCartao} />}
            <Route path={`${rootRouter}/extrato-conta`} render={(props) => <DetalheConta {...props} />} />
            {podeCriarOutorga && <Route path={`${rootRouter}/pagamento`} component={Pagamento} />}
            <Route path={`${rootRouter}/parametro-prestacao`} component={ParametroPrestacao} />
            {podeCriarOutorga && <Route path={`${rootRouter}/pedido-estoque`} component={PedidoEstoque} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/pedido-carga`} component={PedidoCarga} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/pedido-carga-solicitacao/`} handler={(props, state, params) => <PedidoCargaSolicitacao bancoSelecionado={params.bancoSelecionado} />} component={PedidoCargaSolicitacao} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/transferencia`} component={TransferenciaBancaria} />}
            {podeCriarOutorga && <Route path={`${rootRouter}/transferencia-bancaria-solicitacao/`} render={(props) => <TransferenciaBancariaSolicitacao {...this.props} {...props} />} />}
        </div>;
    }
}

const mapStatetoPropsConta = (state) => ({
    temPermissaoBppCorp: hasBppCorpSolucao(state.AnexoContratualReducer),
    temPermissaoBppPag: hasBppPagSolucao(state.AnexoContratualReducer),
    produtosIdsBppCorp: produtosIdsBppCorp(state.AnexoContratualReducer),
    produtosIdsBppPag: produtosIdsBppPag(state.AnexoContratualReducer),
    solucaoBppCorp: getSolucaoBppCorp(state.AnexoContratualReducer),
    solucaoBppPag: getSolucaoBppPag(state.AnexoContratualReducer),
    erroNaBuscaDosAnexo: state.AnexoContratualReducer.erroNaBuscaDosAnexo,
    isFetching: state.AnexoContratualReducer.isFetching
});

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({ cleanListaParametroOperacao, setListaParametroOperacao, setPerfils }, dispatch);
};

const MinhaContaContextRoute = withRouter(connect(mapStatetoPropsConta, mapDispatchToProps)(MinhaContaContextRouteView));

class MinhasSolucoesBppConsumerContextRouteView extends Component {
    componentDidMount() {
        this.props.menuInicio(false);
        this.props.menuInicioContaSelecionada(true);
        this.props.menuTitular(false);
        this.props.menuTitularContaSelecionada(true);
        this.props.menuContaDigital(true);
        if (this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA) {
            this.props.menuEmpresa(true);
            this.props.menuSolucao(false); // Ver Jira FXD-826
        }
    }

    componentWillUnmount() {
        this.props.menuSolucao(false);
    }

    render() {
        const rootRouter = `${this.props.match.url}`;
        return <div>
            <Route path={`${rootRouter}/dashboard`} render={(props) => <DashboardBppConsumer contextBppConsumer {...props} />} />
            <Route path={`${rootRouter}/consulta-pedido`} render={(props) => <ConsultaDePedidosBPPConsumer contextBppConsumer {...props} />} />
            <Route path={`${rootRouter}/extrato-cartao`} render={(props) => <DetalheCartao contextBppConsumer {...props} />} />
            <Route path={`${rootRouter}/cartoes`} render={(props) => <ListaCartoesBppConsumer contextBppConsumer {...props} />} />
            <Route path={`${rootRouter}/pedir-cartao`} render={(props) => <PedirCartaoBppConsumer contextBppConsumer {...props} />} />
            <Route path={`${rootRouter}/vincular-cartao`} render={(props) => <VincularCartaoBppConsumer contextBppConsumer {...props} />} />

        </div>;
    }
}

const mapStatetoPropsBppConsumer = (state) => ({
    // produtosIdsBppConsumer: produtosIdsBppConsumer(state.AnexoContratualReducer)
});

const MinhasSolucoesBppConsumerContextRoute = withRouter(connect(mapStatetoPropsBppConsumer)(MinhasSolucoesBppConsumerContextRouteView));

class MinhasSolucoesBppCorpContextRouteView extends Component {
    componentDidMount() {
        this.props.menuInicio(false);
        this.props.menuInicioContaSelecionada(true);
        this.props.menuTitular(false);
        this.props.menuTitularContaSelecionada(true);
        this.props.menuContaDigital(true);
        if (this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA) {
            this.props.menuEmpresa(true);
            this.props.menuSolucao(false); // Ver Jira FXD-826
        }
    }

    componentWillUnmount() {
        this.props.menuSolucao(false);
    }

    render() {
        const rootRouter = `${this.props.match.url}`;
        if (!this.props.produtosIdsBppCorp) {
            return <LoadingRotas url={this.props.location.pathname} />;
        }
        return <div>
            <Route path={`${rootRouter}/agendamento-aprovacoes`} render={(props) => <AgendamentoEAprovacoesBPPCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/consulta-pedido`} render={(props) => <ConsultaDePedidosBppCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/extrato-cartao`} render={(props) => <DetalheCartao contextBppCorp produtosIdsBppCorp={this.props.produtosIdsBppCorp} {...props} />} />
            <Route exact path={`${rootRouter}/relatorios/relatorio-analitico`} render={(props) => <RelatorioAnaliticoBppCorp contextBppCorp {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route exact path={`${rootRouter}/relatorios/relatorio-gerencial`} render={(props) => <RelatorioGerencialBPPCorp contextBppCorp {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route exact path={`${rootRouter}/relatorios/dashboard`} render={(props) => <DashboradRelatoriosBppCorp contextBppCorp {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/meus-departamentos`} render={(props) => <MeusDepartamentosContextRoute contextBppCorp {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} tipoDeAcesso={this.props.tipoDeAcesso} />} />
            <Route path={`${rootRouter}/dashboard`} render={(props) => <DashboardBppCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/favorecido`} component={(props) => <ListarFavorecido contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/cartoes`} render={(props) => <ListaCartoesBppCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/prestacao-conta`} render={(props) => <PrestacaoConta contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/gerenciar-prestacao-conta`} render={(props) => <GerenciarPrestacaoConta contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/pedido-cartao-impresso`} render={(props) => <PedidoEmbossingCartaoBppCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/pedido-cartao-impresso-resumo`} render={(props) => <PedidoEmbossingCartaoBppCorpResumo contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/pedido-cartao-virtual`} render={(props) => <PedidoCartaoVirtual contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/pedido-cartao-virtual-resumo`} render={(props) => <PedidoCartaoVirtualResumo contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/lista-conta-departamento`} component={(props) => <ListaContaDepartamentoContainer contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/carga-lote-cartao`} render={(props) => <ImportacaoCargaLoteCartao contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/detalhe-bpp-corp`} render={(props) => <DetalhesBppCorp contextBppCorp {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
        </div>;
    }
}

const mapStatetoPropsBppCorp = (state) => ({
    produtosIdsBppCorp: produtosIdsBppCorp(state.AnexoContratualReducer)
});

const MinhasSolucoesBppCorpContextRoute = withRouter(connect(mapStatetoPropsBppCorp)(MinhasSolucoesBppCorpContextRouteView));

class MinhasSolucoesBppPagContextRouteView extends Component {
    componentDidMount() {
        this.props.menuInicio(false);
        this.props.menuInicioContaSelecionada(true);
        this.props.menuTitular(false);
        this.props.menuTitularContaSelecionada(true);
        this.props.menuContaDigital(true);
        this.props.setContextBppPag(true);
        if (this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA) {
            this.props.menuEmpresa(true);
            this.props.menuSolucao(false); // Ver Jira FXD-826
        }
    }

    componentWillUnmount() {
        this.props.menuSolucao(false);
        this.props.setContextBppPag(false);
    }

    render() {
        const rootRouter = `${this.props.match.url}`;
        if (!this.props.produtosIdsBppPag) {
            return <LoadingRotas url={this.props.location.pathname} />;
        }
        return <div>
            <Route path={`${rootRouter}/agendamento-aprovacoes`} render={(props) => <AgendamentoEAprovacoesBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/alterar-titularidade-cartao`} render={(props) => <AlterarTitularidadeCartao contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/consulta-pedido`} render={(props) => <ConsultaDePedidosBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/dashboard`} render={(props) => <DashboardBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/cartoes`} render={(props) => <ListaCartoesBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/pedido-cartao-impresso`} render={(props) => <PedidoEmbossingCartaoBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/pedido-cartao-impresso-resumo`} render={(props) => <PedidoEmbossingCartaoBppPagResumo contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/transferencia-lote`} render={(props) => <ArquivosTransferenciaLote contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/transferencia-lote-novo`} render={(props) => <TranferenciaEmLoteUploadoArquivo contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/transferencia-lote-detalhes`} render={(props) => <TranferenciaEmLoteDetalhes contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            {/* <Route path={`${rootRouter}/transferencia-lote-historico`} render={(props) => <TranferenciaEmLoteHistorico contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} /> */}
            <Route path={`${rootRouter}/transferencia-lote-retorno`} render={(props) => <TransferenciaEmLoteRetorno contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            <Route path={`${rootRouter}/processamento-lote-historico`} render={(props) => <ProcessamentoLoteHistorico contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
            {/* <Route path={`${rootRouter}/lotes-arquivo`} render={(props) => <LotesDoArquivo contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} /> */}
            {/* <Route path={`${rootRouter}/itens-lote`} render={(props) => <ItensDoLote contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} /> */}
            <Route path={`${rootRouter}/detalhe-bpp-pag`} render={(props) => <DetalhesBppPag contextBppPag {...props} produtosIdsBppPag={this.props.produtosIdsBppPag} />} />
        </div>;
    }
}

const mapStatetoPropsBppPag = (state) => ({
    produtosIdsBppPag: produtosIdsBppPag(state.AnexoContratualReducer)
});

const MinhasSolucoesBppPagContextRoute = withRouter(connect(mapStatetoPropsBppPag)(MinhasSolucoesBppPagContextRouteView));

class MeusDepartamentosContextRouteView extends Component {
    componentDidMount() {
        this.props.menuInicio(false);
        this.props.menuInicioContaSelecionada(true);
        this.props.menuTitular(false);
        this.props.menuTitularContaSelecionada(true);
        this.props.menuDepartamento(true);
        this.props.setContextoMeusDepartamentos(true);
        if (this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_FISICA) {
            this.props.menuEmpresa(true);
            this.props.menuSolucao(false); // Ver Jira FXD-826
            this.props.menuDepartamento(true);
        }
    }

    componentWillUnmount() {
        this.props.menuInicioContaSelecionada(false);
        this.props.menuEmpresa(false);
        this.props.setContextoMeusDepartamentos(false);
        this.props.menuSolucao(false);
        this.props.menuDepartamento(false);
    }

    render() {
        const rootRouter = `${this.props.match.url}`;
        if (!this.props.produtosIdsBppCorp) {
            return <LoadingRotas />;
        }
        return <div>
            <Route path={`${rootRouter}/carga-lote`} render={(props) => <ConsultaCargaLote {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/dashboard`} render={(props) => <DashboardDepartamento {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/favorecido`} render={(props) => <ListarFavorecido {...this.props} {...props} produtosIdsBppCorp={this.props.produtosIdsBppCorp} />} />
            <Route path={`${rootRouter}/transferencia`} render={(props) => <TransferenciaBancaria {...this.props} {...props} />} />
            <Route path={`${rootRouter}/transferencia-bancaria-solicitacao/`} render={(props) => <TransferenciaBancariaSolicitacao {...this.props} {...props} />} />
        </div>;
    }
}

const mapStatetoPropsMeusDepartamentos = (state) => ({
    contextoMeusDepartamentos: state.AppReducer.contextoMeusDepartamentos
});

const MeusDepartamentosContextRoute = withRouter(connect(mapStatetoPropsMeusDepartamentos)(MeusDepartamentosContextRouteView));

class Rotas extends Component {
    componentDidMount() {
        this.props.getPoderesOutorga();
    }

    render() {
        const props = this.props;
        return (
            <div>
                <Switch>
                    <Route exact path="/" component={ListarContas} />
                    <Route exact path="/algo-aconteceu" component={AlgoAconteceu} />
                    {/** TODO: Criar rota de ssesão expirada e redirecionar para login */}
                    <Route exact path="/dados-pessoa-fisica" component={DadosPessoaFisicaContainer} />
                    <Route exact path="/poderes" render={(routeProps) => <ListarAutorizadosDaConta {...props} {...routeProps} />} />
                    <Route path="/sair" component={Sair} />

                    //Contexts
                    <Route path="/minha-conta" render={routeProps => <MinhaContaContextRoute {...props} {...routeProps} />} />

                </Switch>
            </div>
        );
    }
}
export default Rotas;
