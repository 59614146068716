import React, { Component } from 'react';
import { Alert, Button, Collapse, ControlLabel, FormControl, FormGroup, Well, Tooltip, OverlayTrigger } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import CurrencyInput from 'react-currency-input';
import If from '../if/if';
import { LABEL_COMUNS, MASCARA_CAMPOS } from '../../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES, ESTILO } from '../../util/estilo_para_componentes/estiloUtil';
import GridRow from '../grid/grid-row';
import Grid from '../grid/grid';
import ShowLineError from '../../comuns/showLineError/show-line-error';
const tooltip = <Tooltip id="tooltip">
    {LABEL_COMUNS.TOOLTIP_FILTRO_FAVORITO}
</Tooltip>;

export function procurarErroPorCampo(erros, campoRecebido) {
    return erros.find(({ campo }) => campo === campoRecebido) && erros.find(({ campo }) => campo === campoRecebido).mensagem;
}
export default class FiltroGenerico extends Component {
    render() {
        const corDeFundoParaBotao = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG : ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        return (
            <div>
                <Collapse in={this.props.abrirCollapse}>
                    <div>
                        <Well>
                            {/* INICIO DA LINHA DE PORTADOR */}
                            <If test={this.props.mostrarFiltroDeCartoes}>
                                <div className="col-sm-12 col-md-12 col-lg-12 collapse-filtro">
                                    <div className={this.props.className}>
                                        <Grid cols="3 3 3">
                                            <ControlLabel>{LABEL_COMUNS.ID_CARTAO}</ControlLabel>
                                            <FormGroup validationState={this.props.cartaoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : STATUS_VALIDACAO_COMPONENTES.SUCESSO}>
                                                <InputMask
                          className="mensagens-erro form-control"
                          mask={MASCARA_CAMPOS.CARTAO_ID} maskChar=""
                          id="cartaId"
                          placeholder={LABEL_COMUNS.ID_CARTAO}
                          style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}

                          value={this.props.cartaoId}
                          onChange={this.props.setCartaoId}
                        />
                                                {this.props.cartaoIdErro ? <span className="mensagens-erro-span" role="alert">{this.props.cartaoIdErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <If test={this.props.exibirNomeECpfDoPortador}>
                                            <Grid cols="4 4 4">
                                                <ControlLabel>{LABEL_COMUNS.NOME_COMPLETO}</ControlLabel>
                                                <FormGroup validationState={this.props.nomePortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <FormControl
                            type="text"
                            id="nomeCompleto"
                            className="mensagens-erro form-control"
                            style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                            placeholder={LABEL_COMUNS.NOME_COMPLETO}
                            value={this.props.nomePortador}
                            onChange={this.props.setNomePortador}
                          />
                                                    {this.props.nomePortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.nomePortadorErro}</span> : null}
                                                </FormGroup>
                                            </Grid>
                                            <Grid cols="3 3 3">
                                                <ControlLabel>{LABEL_COMUNS.CPF}</ControlLabel>
                                                <FormGroup validationState={this.props.numeroDocumentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <InputMask
                            id="numeroDocumento"
                            className="mensagens-erro form-control"
                            mask={MASCARA_CAMPOS.CPF} maskChar=""
                            style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                            placeholder={LABEL_COMUNS.CPF}
                            value={this.props.numeroDocumentoPortador}
                            onChange={this.props.setNumeroDocumentoPortador}
                          />
                                                    {this.props.numeroDocumentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.numeroDocumentoPortadorErro}</span> : null}
                                                </FormGroup>
                                            </Grid>
                                        </If>
                                        <Grid cols="4 4 4">
                                            <ControlLabel>{LABEL_COMUNS.CARTAO_QUATRO_ULTIMOS_DIGITOS}</ControlLabel>
                                            <FormGroup validationState={this.props.cartaoQuatroUltimosDigitosErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <InputMask
                          id="cartaoQautroUltimosDigitos"
                          className="mensagens-erro form-control"
                          mask={MASCARA_CAMPOS.CARTAO_QUATRO_ULTIMOS_DIGITOS} maskChar=""
                          style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                          placeholder={LABEL_COMUNS.CARTAO_QUATRO_ULTIMOS_DIGITOS}

                          value={this.props.cartaoQuatroUltimosDigitos}
                          onChange={this.props.setCartaoQuatroUltimosDigitos}
                        />
                                                {this.props.cartaoQuatroUltimosDigitosErro ? <span className="mensagens-erro-span" role="alert">{this.props.cartaoQuatroUltimosDigitosErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        {this.props.exibirTooltip && <div style={{ paddingTop: 23 }}>

                                            <OverlayTrigger lacement="top" overlay={tooltip}>
                                                <span className="d-inline-block">
                                                    <i className="fa fa-question-circle radio-modelo-arquivo-exemplo" style={{ marginLeft: 0, marginTop: 10 }} />
                                                </span>
                                            </OverlayTrigger>

                                        </div>}
                                    </div>
                                </div>
                            </If>
                            {/* FIM DA LINHA DE PORTADOR */}

                            {/* INICIO DA LINHA DE DATAS */}

                            <div className="col-sm-12 col-md-12 col-lg-12 collapse-filtro">
                                <If test={this.props.mostrarFiltroDeDatas}>
                                    <div className={this.props.classNameData || 'col-sm-3 col-md-3 col-lg-3'}>
                                        <ControlLabel style={{ whiteSpace: 'nowrap' }} >{this.props.labelDataInicial ? this.props.labelDataInicial : LABEL_COMUNS.DATA}</ControlLabel>
                                        <FormGroup validationState={this.props.dataInicialErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                        id="dataInicial"
                        className="mensagens-erro form-control"
                        mask={MASCARA_CAMPOS.DATA} maskChar=""
                        style={{ height: '38px', paddingTop: '8px', paddingLeft: '10px' }}
                        placeholder={LABEL_COMUNS.DATA_DE}
                        value={this.props.dataInicial}
                        onChange={this.props.setDataInicial}
                      />
                                            {this.props.dataInicialErro ? <span className="mensagens-erro-span" role="alert">{this.props.dataInicialErro}</span> : null}
                                        </FormGroup>
                                    </div>
                                    <div className={this.props.classNameData || 'col-sm-3 col-md-3 col-lg-3'}>
                                        <ControlLabel>{this.props.labelDataFinal ? this.props.labelDataFinal : <span>&nbsp;</span>}</ControlLabel>
                                        <FormGroup validationState={this.props.dataFinalErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                        id="dataFinal"
                        className="mensagens-erro form-control"
                        mask={MASCARA_CAMPOS.DATA} maskChar=""
                        style={{ height: '38px', paddingTop: '8px', paddingLeft: '10px' }}
                        placeholder={LABEL_COMUNS.DATA_ATE}
                        value={this.props.dataFinal}
                        onChange={this.props.setDataFinal}
                      />
                                            {this.props.dataFinalErro ? <span className="mensagens-erro-span" role="alert">{this.props.dataFinalErro}</span> : null}
                                        </FormGroup>
                                    </div>
                                    <If test={this.props.mostrarFiltroDeStatusMesmaLinha}>
                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <span><b>{LABEL_COMUNS.STATUS}</b></span>
                                                <FormGroup validationState={this.props.statusErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <Select id="status"
                            placeholder={LABEL_COMUNS.SELECIONE}
                            value={this.props.statusSelecionado}
                            onChange={this.props.setStatus}
                            options={this.props.listaDeStatus}
                            style={{ height: '-10px', marginTop: '5px' }}
                            noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                          />
                                                    {this.props.statusErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.statusErro}</span> : null}
                                                </FormGroup >
                                            </div>
                                        </div>
                                    </If>
                                    <If test={this.props.mostrarFiltroDeOperacoesMesmaLinha}>
                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <span><b>{LABEL_COMUNS.OPERACAO}</b></span>
                                                <FormGroup validationState={this.props.operacaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <Select
                            id="operacao"
                            placeholder={LABEL_COMUNS.SELECIONE}
                            value={this.props.operacaoSelecionadaId}
                            onChange={this.props.setOperacao}
                            options={this.props.listaDeOperacoes}
                            style={{ height: '-10px', marginTop: '5px' }}
                            noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                          />
                                                    {this.props.operacaoErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.operacaoErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </If>
                                </If>
                                <If test={this.props.mostrarFiltroDeidNumerico}>
                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                        <ControlLabel>{LABEL_COMUNS.ID}</ControlLabel>
                                        <FormGroup validationState={this.props.idNumericoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                        maskChar=""
                        id="idNumerico"
                        className="mensagens-erro form-control"
                        value={this.props.idNumerico}
                        mask={MASCARA_CAMPOS.ID}
                        placeholder={this.props.idNumericoPlaceHolder}
                        onChange={this.props.setIdNumerico}
                        style={{
                          height: '32px',
                          paddingTop: '8px',
                          paddingLeft: '10px',
                          width: '105px'
                        }}
                      />
                                            <ShowLineError error={this.props.idNumericoErro} />
                                        </FormGroup>
                                    </div>
                                </If>
                                <If test={this.props.mostrarFiltroDeDatas2}>
                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                        <ControlLabel>{this.props.labelData2 ? this.props.labelData2 : LABEL_COMUNS.DATA}</ControlLabel>
                                        <FormGroup validationState={this.props.dataInicial2Erro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                        id="dataInicial"
                        className="mensagens-erro form-control"
                        mask={MASCARA_CAMPOS.DATA} maskChar=""
                        style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px', width: '105px' }}
                        placeholder={LABEL_COMUNS.DATA_DE}
                        value={this.props.dataInicial2}
                        onChange={this.props.setDataInicial2}
                      />
                                            {this.props.dataInicial2Erro ? <span className="mensagens-erro-span" role="alert">{this.props.dataInicial2Erro}</span> : null}
                                        </FormGroup>
                                    </div>
                                    <div className="col-sm-3 col-md-3 col-lg-3">
                                        <ControlLabel>&nbsp;</ControlLabel>
                                        <FormGroup validationState={this.props.dataFinal2Erro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                        id="dataFinal"
                        className="mensagens-erro form-control"
                        mask={MASCARA_CAMPOS.DATA} maskChar=""
                        style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px', width: '105px' }}
                        placeholder={LABEL_COMUNS.DATA_ATE}
                        value={this.props.dataFinal2}
                        onChange={this.props.setDataFinal2}
                      />
                                            {this.props.dataFinal2Erro ? <span className="mensagens-erro-span" role="alert">{this.props.dataFinal2Erro}</span> : null}
                                        </FormGroup>
                                    </div>
                                </If>
                                <If test={this.props.mostrarFiltroDeValor}>
                                    <div className={this.props.classNameData || 'col-sm-3 col-md-3 col-lg-3'}>
                                        <div className="form-group">
                                            <ControlLabel>{LABEL_COMUNS.VALOR_DE}</ControlLabel>
                                            <FormGroup validationState={this.props.valorDeError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <CurrencyInput decimalSeparator="," thousandSeparator="."
                          id="valorDe" className="form-control mensagens-erro"
                          style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                          value={this.props.valorDe}
                          onChange={this.props.setValorDe} />
                                                {this.props.valorDeError ? <span className="mensagens-erro-span" role="alert">{this.props.valorDeError}</span> : null}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className={this.props.classNameData || 'col-sm-3 col-md-3 col-lg-3'}>
                                        <div className="form-group">
                                            <ControlLabel>{LABEL_COMUNS.VALOR_ATE}</ControlLabel>
                                            <FormGroup validationState={this.props.valorAteError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <CurrencyInput decimalSeparator="," thousandSeparator="."
                          className="form-control mensagens-erro"
                          style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                          id="valorAte"
                          value={this.props.valorAte}
                          onChange={this.props.setValorAte} />
                                                {this.props.valorAteError ? <span className="mensagens-erro-span" role="alert">{this.props.valorAteError}</span> : null}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <If test={this.props.mostrarFiltroDeStatusMesmaLinhaDoValor}>
                                        <div className="col-sm-3 col-md-3 col-lg-3">
                                            <div className="form-group">
                                                <span><b>{LABEL_COMUNS.STATUS}</b></span>
                                                <FormGroup validationState={this.props.statusErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <Select id="status"
                            placeholder={LABEL_COMUNS.SELECIONE}
                            value={this.props.statusSelecionado}
                            onChange={this.props.setStatus}
                            options={this.props.listaDeStatus}
                            style={{ height: '-10px', marginTop: '5px' }}
                            noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                          />
                                                    {this.props.statusErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.statusErro}</span> : null}
                                                </FormGroup >
                                            </div>
                                        </div>
                                    </If>
                                </If>
                            </div>

                            {/* FIM DA LINHA DE DATAS */}

                            {/* INÍCIO DA LINHA DE OPERAÇões/STATUS */}

                            <div className="col-sm-12 col-md-12 col-lg-12 collapse-filtro">
                                <If test={this.props.mostrarFiltroDeOperacao}>
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <span><b>{LABEL_COMUNS.OPERACAO}</b></span>
                                            <FormGroup validationState={this.props.operacaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select
                          id="operacao"
                          placeholder={LABEL_COMUNS.SELECIONE}
                          value={this.props.operacaoSelecionadaId}
                          onChange={this.props.setOperacao}
                          options={this.props.listaDeOperacoes}
                          noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                        />
                                                {this.props.operacaoErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.operacaoErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                    </div>
                                </If>
                                <If test={this.props.mostrarFiltroDeStatus}>
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <span><b>{LABEL_COMUNS.STATUS}</b></span>
                                            <FormGroup validationState={this.props.statusErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select id="status"
                          placeholder={LABEL_COMUNS.SELECIONE}
                          value={this.props.statusSelecionado}
                          onChange={this.props.setStatus}
                          options={this.props.listaDeStatus}
                          noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                        />
                                                {this.props.statusErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.statusErro}</span> : null}
                                            </FormGroup >
                                        </div>
                                    </div>
                                </If>
                                <If test={this.props.mostrarFiltroDePrestacaoDeContas}>
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <span><b>{LABEL_COMUNS.CLIENTE_PROJETO}</b></span>
                                            <FormGroup validationState={this.props.projetoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select id="operacao"
                          placeholder={LABEL_COMUNS.SELECIONE}
                          value={this.props.projetoSelecionado}
                          onChange={this.props.setProjetoSelecionado}
                          options={this.props.listaDeProjetos}
                          noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                        />
                                                {this.props.projetoErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.projetoErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 col-md-4 col-lg-4">
                                        <div className="form-group">
                                            <span><b>{LABEL_COMUNS.CLASSIFICACAO}</b></span>
                                            <FormGroup validationState={this.props.classificacaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select id="status"
                          placeholder={LABEL_COMUNS.SELECIONE}
                          value={this.props.classificacaoSelecionada}
                          onChange={this.props.setClassificacaoSelecionada}
                          options={this.props.listaDeClassificacoes}
                          noResultsText={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                        />
                                                {this.props.classificacaoErro ? <span className="mensagens-erro-favorecido-banco" role="alert">{this.props.classificacaoErro}</span> : null}
                                            </FormGroup >
                                        </div>
                                    </div>
                                </If>
                            </div>
                            {/* FIM DA LINHA DE OPERAÇões/STATUS */}

                            {/* NÃO APAGAR ESTE TRECHO */}

                            <GridRow>
                                <Grid>
                                    <div className="form-group">
                                        <Grid className="alinhamento-direita">
                                            <If test={this.props.exibirBotaoLimpar}>
                                                <Button bsStyle="default" onClick={this.props.limparFiltro}
                          style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}>
                                                    {LABEL_COMUNS.LIMPAR}
                                                </Button>
                        &nbsp;&nbsp;&nbsp;
                                            </If>
                                            <Button bsStyle={corDeFundoParaBotao}
                        style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                        onClick={this.props.buscar}>
                                                {LABEL_COMUNS.SET_HISTORICO}
                                            </Button>
                                        </Grid>
                                    </div>
                                </Grid>
                            </GridRow>
                        </Well>
                    </div>
                </Collapse>
                <div className="row">
                    <If test={this.props.erroGenerico}>
                        <div className="col-sm-12 col-md-12 col-lg-12">
                            <Alert bsStyle={!this.props.erroGenerico ? STATUS_VALIDACAO_COMPONENTES.SUCESSO : STATUS_VALIDACAO_COMPONENTES.PERIGO}>
                                {this.props.erroGenerico}
                            </Alert>
                        </div>
                    </If>
                </div>
            </div>

        );
    }
}
