import React, { Component } from 'react';
import Grid from '../../../../componentes-uteis/grid/grid';
import { Button, FormGroup } from 'react-bootstrap';
import { LABEL_COMUNS, CONTA_SELECIONADA, MASCARA_CAMPOS, MENSAGENS } from '../../../../util/contantes-comuns/constantes-comuns';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import imagemCartaoPf from '../../../../../imagens/imagem_cartao_pf.png';
import InputMask from 'react-input-mask';
import { getVincularCartao, getJsonForBindCard } from '../../../../../servicos/cartoes/cartoes-service';
import { STATUS_VALIDACAO_COMPONENTES } from '../../../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../../../comuns/showLineError/show-line-error';
import If from '../../../../componentes-uteis/if/if';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';

export default class VincularCartaoBppConsumer extends Component {
    constructor(props) {
        super(props);
        this.SELECTED_ACCOUNT = CONTA_SELECIONADA.OBJETO();

        this.INITIAL_STATE = {
            contaSelecionada: this.SELECTED_ACCOUNT,
            showModal: false,
            isConfirmation: false,
            isFinalization: false,
            isCancelation: false,
            isBindcardError: false,
            bodyModal: '',
            cardPan: '',
            cardNickname: '',
            cardNicknameError: false,
            cardPanError: false,
            showLoading: false
        };

        this.state = this.INITIAL_STATE;
    }

    footerModalVincularCartao() {
        const exibirLoading = this.state.exibirLoading;
        var btn2Label;
        var btn2OnClick;
        var btn1Label;
        var btn1OnClick;
        if (this.state.isCancelation) {
            btn2Label = LABEL_COMUNS.SIM;
            btn2OnClick = () => this.props.history.goBack();
            btn1Label = LABEL_COMUNS.NAO;
            btn1OnClick = () => { this.setState({ showModal: false }); };
        }
        if (this.state.isConfirmation) {
            btn2Label = LABEL_COMUNS.SIM;
            btn2OnClick = async () => { await this.bindCard(); };
            btn1Label = LABEL_COMUNS.NAO;
            btn1OnClick = () => { this.setState({ showModal: false }); };
        }
        if (this.state.isFinalization) {
            btn2Label = LABEL_COMUNS.OK;
            btn2OnClick = () => this.props.history.goBack();
            btn1Label = null;
            btn1OnClick = null;
        }
        if (this.state.isBindcardError) {
            btn2Label = LABEL_COMUNS.OK;
            btn2OnClick = () => { this.setState({ showModal: false }); };
            btn1Label = null;
            btn1OnClick = null;
        }
        return (
            <FooterBotoesModalComponent
        exibirLoading={exibirLoading}
        btn2Label={btn2Label}
        btn2OnClick={btn2OnClick}
        btn1Label={btn1Label}
        btn1OnClick={btn1OnClick}
      />
        );
    }

  setShowLoading = (showLoading) => {
      this.setState({ showLoading });
  }

  bindCard = async () => {
      this.setShowLoading(true);
      const resp = await getVincularCartao(getJsonForBindCard(this.state), this.state.contaSelecionada.id);
      this.renderModalSuccess(resp);
  }

  renderModalSuccess(bodyModal) {
      this.setShowLoading(false);
      this.setState({ bodyModal });
      this.setState({ isFinalization: true });
      this.setState({ showModal: true });
  }

  renderModalCancelation() {
      this.setState({ bodyModal: MENSAGENS.CARTAO.VINCULAR_CARTAO.CANCELAMENTO });
      this.setState({ isCancelation: true });
      this.setState({ showModal: true });
  }

  renderModalConfirmation() {
      this.setState({ bodyModal: MENSAGENS.CARTAO.VINCULAR_CARTAO.CONFIRMACAO });
      this.setState({ isConfirmation: true });
      this.setState({ showModal: true });
  }

  renderModalError() {
      this.setState({ bodyModal: MENSAGENS.CARTAO.VINCULAR_CARTAO.FINALIZACAO.ERRO });
      this.setState({ isBindcardError: true });
      this.setState({ showModal: true });
  }

  handleChangeInput(field, fieldName) {
      let fieldValue = field && field.target && field.target.value;

      this.setState({
          [fieldName]: fieldValue,
          [fieldName + 'Error']: '',
      });
  }

  setNicknameError = (cardNicknameError) => {
      this.setState({ cardNicknameError });
  }

  setCardPanError = (cardPanError) => {
      this.setState({ cardPanError });
  }

  validaInputs() {
      const { cardNickname, cardPan } = this.state;
      let error = false;

      const mastercardRegex = /^(?:5[1-5][0-9]{14})$/;
      const visaRegex = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;

      let cardPanNoMask = cardPan;

      if (cardPanNoMask.indexOf('.') !== -1) {
          cardPanNoMask = cardPanNoMask.split('.').join('');
      }

      const visaMatch = visaRegex.test(cardPanNoMask);
      const masterMatch = mastercardRegex.test(cardPanNoMask);

      if (!cardPan) {
          this.setCardPanError(MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO);
          error = true;
      }

      if (!(visaMatch || masterMatch)) {
          this.setCardPanError(LABEL_COMUNS.CARTAO_INVALIDO);
          error = true;
      }

      if (!error) {
          this.renderModalConfirmation();
      }
  }

  render() {
      const { cardPan, cardPanError, cardNickname, cardNicknameError, contaSelecionada, showLoading } = this.state;
      return (
          <div>
              <ContaSelecionada contaSelecionada={contaSelecionada} />
              <div className="row">
                  <If test={showLoading}>
                      <div className="alinhamento-direita">
                          <LoadingFenix />
                      </div>
                  </If>
                  <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                      <div className="panel panel-default">
                          <div className="panel-heading fundo-azul-claro"><b> {LABEL_COMUNS.VINCULAR_CARTAO} </b></div>
                          <div className="panel-body">
                              <Grid cols="3 3 3 3" className="alinhamento-direita">
                                  <img src={imagemCartaoPf} className="imagem-cartao-pf" alt=" imagem cartao" />
                              </Grid>
                              <Grid cols="9 9 9 9" className="alinhamento-esquerda">
                                  <Grid customStyle={{ display: 'flex', alignItems: 'baseline', marginTop: '50px' }}>
                                      <Grid customStyle={{ height: '100%' }} cols="3 3 3 3" className="alinhamento-esquerda"><b>{LABEL_COMUNS.CARTOES.NUMERO_DO_CARTAO}:</b></Grid>
                                      <Grid customStyle={{ height: '100%', paddingTop: '10px' }} cols="5 5 5 5">
                                          <FormGroup validationState={cardPanError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                              <InputMask
                          name="cardPan"
                          className="mensagens-erro form-control"
                          mask={MASCARA_CAMPOS.NUMERO_DO_CARTAO}
                          maskChar=""
                          placeholder={LABEL_COMUNS.CARTOES.NUMERO_DO_CARTAO}
                          maxLength="19"
                          onChange={(field) => this.handleChangeInput(field, field.target.name)}
                          value={cardPan}
                        />
                                              <ShowLineError error={cardPanError} />
                                          </FormGroup>
                                      </Grid>
                                  </Grid>
                                  <Grid customStyle={{ lineHeight: '10px', display: 'flex', alignItems: 'baseline', padding: '0px' }}>
                                      <div style={{ width: '100%' }}>
                                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                              <Button type="button" className="rectangle-button" bsStyle="default" style={{ minWidth: 159, maxWidth: 159, marginRight: 20 }} onClick={() => this.renderModalCancelation()}>{LABEL_COMUNS.CANCELAR}</Button>
                                              <Button type="button" className="rectangle-button" bsStyle="primary" style={{ minWidth: 159, maxWidth: 159 }} onClick={() => this.validaInputs()}>{LABEL_COMUNS.VINCULAR}</Button>
                                          </div>
                                      </div>
                                  </Grid>
                              </Grid>
                          </div>
                      </div>
                      <div className="alinhamento-direita">
                          <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                      </div>
                  </div>

                  <ModalComponent exibirModal={this.state.showModal}
            header={LABEL_COMUNS.CARTOES.TITULOS.VINCULAR_CARTAO}
            name="modal-vincular-cartao"
            body={<p> {this.state.bodyModal} </p>}
            footer={this.footerModalVincularCartao()}
          />
              </div >
          </div>
      );
  }
}
