import { Button } from 'react-bootstrap';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { LABEL_COMUNS, STATUS, LOTE_ARQUIVO_TRANSACAO, OPERACAO, FORMATOS_PERMITIDOS_DE_DATA_NO_PEDIDO_EMBOSSING_CARTAO, MENSAGENS, ACAO, APROVAR_OU_REPROVAR_ARQUIVO } from '../../util/contantes-comuns/constantes-comuns';
import { formatarDataCompletaBR, formatarDataBR } from '../../util/data/dataUtil';
import { formatarCampoValor } from '../../util/extrato/extrato-util';
import { aprovarOuReprovarLote, getBodyApproveOrReproveLoteArquivo } from '../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import If from '../../componentes-uteis/if/if';
import DetalhesLinhasArquivo from './detalhes-linhas-arquivo';
import { approveOrReproveArchiveApi } from 'core/api/transferenciaEmLoteRetornoApi';

class DetalhesArquivo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            arquivo: {}, exibirModalConfirmacao: false, exibirModalFinalizacao: false,
            acaoSelecionada: '', nameBotao: '', disabledButtonApprove: true, disabledButtonReprove: true,
            disabledButtonCorrect: true, mensagemConfirmacao: '', mensagemFinalizacao: '',
            redirectDashboard: false, exibirLoading: false, showModalDetailsFile: false, disabledShowError: false
        };
    }

    componentDidMount() {
        const arquivo = this.props.arquivoLote;

        const disabledButtonApprove = !(arquivo.status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value);
        const disabledButtonReprove = !((arquivo.status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value) || (arquivo.status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value));
        const disabledButtonCorrect = !(arquivo.status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value);
        const { contaSelecionada } = this.props;
        const disabledShowError = !(arquivo.status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value);
        this.setState({ arquivo, contaSelecionada, disabledButtonApprove, disabledButtonReprove, disabledButtonCorrect, disabledShowError });
    }

    setExibirModalConfirmacao = (exibirModalConfirmacao, acaoSelecionada) => {

        if (acaoSelecionada) {
            const nameBotao = acaoSelecionada === LOTE_ARQUIVO_TRANSACAO.APROVAR.transacao ? LABEL_COMUNS.APROVAR : LABEL_COMUNS.REPROVAR;
            const mensagemConfirmacao = MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.ENVIO.APROVAR_OU_REPROVAR_ARQUIVO(acaoSelecionada);
            this.setState({ acaoSelecionada, exibirModalConfirmacao, mensagemConfirmacao, nameBotao });
        } else {
            this.setState({ exibirModalConfirmacao });
        }
    }

    setExibirModalFinalizacao = (exibirModalFinalizacao) => {
        this.setState({ exibirModalFinalizacao });
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    setRedirectDashboard = (redirectDashboard) => {
        this.setState({ redirectDashboard });
    }

    aprovarOuReprovarLoteArquivo = async () => {
        const { acaoSelecionada, arquivo, contaSelecionada: { anexoContratualId } } = this.state;
        this.setExibirLoading(true);
        this.setExibirModalConfirmacao(false);
        const response = await approveOrReproveArchiveApi(arquivo.id, anexoContratualId, acaoSelecionada);

        if (response.error || response === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.ERROR) {
            if (response.data && response.data.codigo === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.CODIGO) {
                this.setState({
                    exibirModalFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.MENSAGEM
                });
            } else {
                this.setState({
                    exibirModalFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.GENERICO
                });
            }
        }
        if (response.data && response.data.status) {
            const status = response.data.status;
            this.setState({
                exibirModalFinalizacao: true,
                mensagemFinalizacao: status === APROVAR_OU_REPROVAR_ARQUIVO.STATUS.APROVADO ?
                    APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.SUCESSO.APROVADO :
                    APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.SUCESSO.REPROVADO
            });
        }

        this.setExibirLoading(false);
    }

    returnFooterModalConfirmacao = () => (
        <FooterBotoesModalComponent
            contextoBppPag={this.props.contextoBppPag}
            btn1Label={LABEL_COMUNS.CANCELAR}
            btn1OnClick={() => this.setExibirModalConfirmacao(false)}
            btn2Label={this.state.nameBotao}
            btn2OnClick={() => this.aprovarOuReprovarLoteArquivo()}
        />
    )

    returnFooterModalFinalizacao = () => (
        <FooterBotoesModalComponent
            contextoBppPag={this.props.contextoBppPag}
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={() => {
                this.setExibirModalFinalizacao(false);
                this.setRedirectDashboard(true);
            }} />
    )

    setShowModalDetailsFile = (showModalDetailsFile) => {
        this.setState({ showModalDetailsFile });
    }

    render() {
        const stylePanel = this.props.contextoBppPag ? 'panel-heading fundo-verde-claro' : 'panel-heading fundo-azul-claro';
        const stylePanelTable = this.props.contextoBppPag ? 'panel-heading fundo-verde-escuro' : 'panel-heading fundo-azul-escuro';
        const { arquivo, contaSelecionada, disabledButtonApprove, disabledButtonReprove, disabledButtonCorrect, disabledShowError,
            exibirLoading, mensagemConfirmacao, mensagemFinalizacao, redirectDashboard, showModalDetailsFile
        } = this.state;

        if (redirectDashboard) {
            if (this.props.contextoBppPag) {
                return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_BPP_PAG} />;
            }
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_BPP_CORP} />;
        }
        return (
            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas" >
                <ModalComponent
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.DESTALHES_DO_ARQUIVO}
                    exibirModal={this.state.exibirModalConfirmacao}
                    body={mensagemConfirmacao}
                    footer={this.returnFooterModalConfirmacao()}
                />
                <ModalComponent
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.DESTALHES_DO_ARQUIVO}
                    exibirModal={this.state.exibirModalFinalizacao}
                    body={mensagemFinalizacao}
                    footer={this.returnFooterModalFinalizacao()}
                />
                {arquivo && arquivo.linhasDoArquivo && <DetalhesLinhasArquivo
                    header={arquivo.operacaoExibicao + ' - ' + LABEL_COMUNS.ERROS_PENDENTES_DE_CORREÇÃO}
                    showModal={showModalDetailsFile}
                    fileLines={arquivo.linhasDoArquivo}
                    setShowModalDetailsFile={this.setShowModalDetailsFile}
                    contextoBppPag={this.props.contextoBppPag}
                    operation={arquivo.operacao}
                    error={arquivo.error}
                />}
                <div className={stylePanel}>
                    <span>{LABEL_COMUNS.DESTALHES_DO_ARQUIVO}</span>
                </div>
                <div className="alinhamento-centro">
                    <If test={exibirLoading}>
                        <LoadingFenix cssBppPag={this.props.contextoBppPag} />
                    </If>
                </div>
                <br />
                <div>
                    <table className="table">
                        <tbody>
                            <tr>
                                <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.ID}</th>
                                <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.OPERACAO}</th>
                                <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.VALOR}</th>
                                <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.DATA_INCLUSAO}</th>
                                <th style={{ textAlign: 'center' }} className={stylePanelTable}>{LABEL_COMUNS.STATUS}</th>
                            </tr>
                        </tbody>
                        <tbody>
                            <tr>
                                <td style={{ textAlign: 'center' }}>{arquivo.idArquivoSequencialPorPessoaId}</td>
                                <td style={{ textAlign: 'center' }}>{arquivo.operacaoExibicao}</td>
                                <td style={{ textAlign: 'center' }}>{arquivo.valorProcessadoLote ? formatarCampoValor(null, arquivo.valorProcessadoLote) : ''}</td>
                                <td style={{ textAlign: 'center' }}>{formatarDataBR(arquivo.data)}</td>
                                <td style={{ textAlign: 'center' }}>{arquivo.statusExibicao}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">

                    <Button bsStyle="primary" className={stylePanelTable} disabled={disabledShowError} onClick={() => this.setShowModalDetailsFile(true)}>{LABEL_COMUNS.VER_ERROS}</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <Button bsStyle="primary" className={stylePanelTable} disabled={disabledButtonApprove} onClick={() => this.setExibirModalConfirmacao(true, LOTE_ARQUIVO_TRANSACAO.APROVAR.transacao)}>{LABEL_COMUNS.APROVAR}</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <Button bsStyle="primary" className={stylePanelTable} disabled={disabledButtonReprove} onClick={() => this.setExibirModalConfirmacao(true, LOTE_ARQUIVO_TRANSACAO.REPROVAR.transacao)}>{LABEL_COMUNS.REPROVAR}</Button>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

                    <Button bsStyle="primary" className={stylePanelTable} disabled={disabledButtonCorrect} onClick={() => {
                        if (this.props.contextoBppPag) {
                            this.props.history.push({ pathname: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_NOVO, state: { acao: ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.CORRIGIR.value, transferenciaEmLote: arquivo } });
                        }
                        if (!this.props.contextoBppPag && arquivo.operacao === OPERACAO.NOME.CARGA_CARTAO) {
                            this.props.history.push({ pathname: ROTAS_CAMINHOS.CARGA_LOTE_CARTAO, state: { acao: ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.CORRIGIR.value, cargaLoteCartao: arquivo } });
                        }
                    }}>
                        {LABEL_COMUNS.CORRIGIR}
                    </Button>
                </div>
            </div>
        );
    }
}
export default DetalhesArquivo;
