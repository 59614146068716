import { fetchComprovanteId, fetchComprovantePDF, fetchHistoricoDeComprovantes, fetchOperacoesComprovante } from './compravante-base';

export const getHistoricoDeComprovantes = (contaId, queryParameters) => {
    return fetchHistoricoDeComprovantes(contaId, queryParameters);
};

export const getComprovanteId = (contaId, operacaoId, comprovanteId) => {
    return fetchComprovanteId(contaId);
};

export const getOperacoesComprovante = (nomeParametro) => {
    return fetchOperacoesComprovante(nomeParametro);
};

export const getComprovantePDF = (contaId, operacaoId, comprovanteId) => {
    return fetchComprovantePDF(contaId, operacaoId, comprovanteId);
};
