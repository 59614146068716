import { fetchGeraUrlPreAssinada, fetchUpload, fetchGeraUrlPreAssinadaS3Arquivos, fetchUploadS3Arquivos } from './arquivo-base';
import { environment } from '../../configuracoes/environment';
import { generateUUID } from '../../componentes/util/string/string-util';

export const uploadFile = async (file) => {
    return await fetchGeraUrlPreAssinada(file)
        .then(({ data: { id, urlPreAssinada } }) => ({ id, urlPreAssinada }))
        .then(async ({ id, urlPreAssinada }) => ({ resp: await fetchUpload(urlPreAssinada, file), imagem: id }))
        .catch((error) => error);
};

export const uploadFileS3 = async (arquivo) => {
    return await fetchGeraUrlPreAssinadaS3Arquivos(montaCorpoRequisicaoUploadFileS3())
        .then(async ({ data: { id, presignedUrlRequest } }) => ({ resp: await fetchUploadS3Arquivos(presignedUrlRequest, arquivo), idArquivoS3: id }))
        .then(({ idArquivoS3 }) => ({ idArquivoS3 }));
};

const montaCorpoRequisicaoUploadFileS3 = () => {
    const corpoDaRequisicao = JSON.stringify({
        bucket: environment.bucket_lote_arquivo
    });
    return corpoDaRequisicao;
};