import { environment } from '../../configuracoes/environment';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';
import { DESCRICAO_CONTA } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const fetchGetAnexosContratuais = async (pessoaTitularId, produtoId, contaDescricao) => {
    const parametros = { pessoaTitularId, produtoId, contaDescricao, status: 'ativo', tamanhoPagina: 100 };
    return await fetch(montaUrlFetch(urlServicos.ANEXO_CONTRATUAL, parametros), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchRecuperaOuGeraAnexoPessoaFisica = async (pessoaTitularId) => {
    return await fetchGetAnexosContratuais(pessoaTitularId)
        .then(async ({ data }) => !data.registros.length ? await fetchGeraAnexoContratualDeCriacaoDeContaPessoaFisica(pessoaTitularId) : data)
        .then((result) => result && result.registros ? ({ data: { ...result } }) : ({ data: { registros: [{ id: result.data.id, nome: 'Anexo', contaDescricao: DESCRICAO_CONTA.CONTA_MOVIMENTO }] } }));
};

export const fetchGeraAnexoContratualDeCriacaoDeContaPessoaFisica = async (pessoaTitularId) => {
    const produtoPublicadoId = environment.produtoPublicadoIdCriacaoContaPessoaFisica;
    return await fetchContractualAttachment(produtoPublicadoId, pessoaTitularId);
};

export const fetchContractualAttachment = async (produtoPublicadoId, pessoaTitularId) => {
    return await fetch(montaUrlFetch(urlServicos.ANEXO_CONTRATUAL), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify({ produtoPublicadoId, pessoaTitularId })
    })
        .then(async (resp) => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

