export const TIPO_DOCUMENTO = [ 'ACORDO OPERACIONAL PARCERIA BPP X CLIENTE',
    'ALTERAÇÃO DE CNPJ', 'ATESTADO DE ANTECEDENTES CRIMINAIS', 'BALANCETE',
    'BALANÇO PATRIMONIAL', 'CARTÃO CNPJ', 'CCMEI - CERTIFIVCADO DE CONDIÇÃO DO EMPREENDEDOR INDIVIDUAL',
    'COMPROVANTE DE ENDEREÇO', 'CONTRATO SOCIAL', 'CPF SÓCIO/REPRESENTANTE LEGAL', 'ESTATUTO',
    'FATURAMENTO', 'FICHA CADASTRAL (BPP)', 'FICHA CADASTRAL (CLIENTE)', 'INSTRUMENTO DE PROCURAÇÃO',
    'MINUTA CONTRATUAL ENTRE CLIENTE X BPP', 'PROPOSTA COMERCIAL BPP', 'RG SÓCIO/REPRESENTANTE LEGAL'];

export const DEPARTAMENTOS = ['ADMINISTRATIVO', 'ATENDIMENTO', 'COMERCIAL', 'COMUNICAÇÃO', 'DIRETORIA', 'FINACEIRO',
    'JURÍDICO', 'MARKETING', 'OPERAÇÕES', 'OUTROS', 'RECEPÇÃO', 'RECURSOS HUMANOS', 'TECNOLOGIA DA INFORMAÇÃO'];

export const STATUS_REPRESENTANTE = { EXISTE: 'EXISTE', REMOVE: 'REMOVE', NOVO: 'NOVO' };

export const ERRO_CARREGAR_PAGINA = 'Erro ao carregar página de alteração de cadastro!';
