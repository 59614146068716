import React from 'react';
import './tab.css';

export default function Tab({ title, isSelected, isRight, ...props }) {
    if (isSelected) {
        return (
            <div className={isRight ? 'selectTabContainerRight' : 'selectTabContainer'} {...props}>
                <span className={'selectTabTitleStyle'}>{title}</span>
            </div>
        );
    }
    return (
        <div className={isRight ? 'selectTabContainerSelectedRight' : 'selectTabContainerSelected'} {...props}>
            <span className={'selectTabTitleStyleSelected'}>{title}</span>
        </div>
    );
}
