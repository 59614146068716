import axios from 'axios';
import { CARTAO_STATUS, METODOS, TIPO_DE_TRANSACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, URL_GET_CARTOES, urlServicos, URL_GET_CARTOES_PAGINADO } from '../../servicos/url-servicos';
import { montarQueryString, retornaCorpoRequiscaoBloquearCartao, retornaCorpoRequiscaoCancelarCartao, retornaCorpoRequiscaoDesbloquearCartao } from '../../componentes/util/cartoes/cartoes-util';
import { fetchBuscaCartaoComOperacaoEmProcessamento, fetchGetCartoes, fetchGetCartoesFavoritos } from '../../servicos/cartoes/cartoes-base';
import contaSelecionada from 'componentes/contas/conta-selecionada';

export const setContaSelecionada = (contaSelecionada) => ({
    type: METODOS.SET_CONTA_SELECIONADA,
    payload: contaSelecionada
});

export const setExibirLoadingCartao = (exibirLoadingCartao) => ({
    type: METODOS.CARTAO.SET_EXIBIR_LOADING_CARTAO,
    payload: exibirLoadingCartao
});

export const setArmazenarCartao = (cartaoSelecionado) => ({
    type: METODOS.CARTAO.SET_ARMAZENAR_CARTAO,
    payload: cartaoSelecionado
});

export const setExibirModalCargaOuDescargaCartao = (show) => ({
    type: METODOS.CARTAO.SET_EXIBIR_MODAL_CARGA_OU_DESCARGA_CARTAO,
    payload: show
});

export const setOperacaoCartaoSelecionada = (operacaoSelecionada) => ({
    type: METODOS.CARTAO.SET_OPERACAO_CARTAO_SELECIONADA,
    payload: operacaoSelecionada
});

export function setCartaoId(e) {
    return {
        type: METODOS.CARTAO.SET_CARTAO_ID,
        payload: e.target.value
    };
}

export function setNomePortadorCartao(e) {
    return {
        type: METODOS.CARTAO.SET_NOME_PORTADOR_CARTAO,
        payload: e.target.value
    };
}

export function setNumeroDocumentoPortadorCartao(e) {
    return {
        type: METODOS.CARTAO.SET_NUMERO_DOCUMENTO_PORTADOR_CARTAO,
        payload: e.target.value
    };
}

export function setCartaoQuatroUltimosDigitos(e) {
    return {
        type: METODOS.CARTAO.SET_CARTAO_QUATRO_ULTIMOS_DIGITOS,
        payload: e.target.value
    };
}

export const limparFiltroCartao = () => ({
    type: METODOS.CARTAO.LIMPAR_FILTRO_CARTAO,
    payload: ''
});

export const setExibirModalConfirmacaoCartao = (exibirModarConfirmacaoCartao) => ({
    type: METODOS.CARTAO.SET_EXIBIR_MODAL_CONFIRMACAO_CARTAO,
    payload: exibirModarConfirmacaoCartao
});

export const setExibirModalFinalizacao = (exibirModalFinalizacao) => ({
    type: METODOS.CARTAO.SET_EXIBIR_MODAL_FINALIZACAO_CARTAO,
    payload: exibirModalFinalizacao
});


export function setExibirModalTokenCartao(exibirModalTokenCartao) {
    return {
        type: METODOS.CARTAO.SET_EXIBIR_MODAL_TOKEN_CARTAO,
        payload: exibirModalTokenCartao
    };
}

export function setArmazenarTokenSMSCartao(event) {
    return {
        type: METODOS.CARTAO.SET_ARMAZENAR_TOKEN_SMS_CARTAO,
        payload: event.target.value
    };
}

export const limparEstadoCartoes = () => {
    return {
        type: METODOS.CARTAO.LIMPAR_ESTADO_CARTOES
    };
};

export const limparMensagemDeErroDeAlteracaoDeTitularidadeCartao = () => {
    return {
        type: METODOS.CARTAO.LIMPAR_MENSAGEM_DE_ERRO_DE_ALTERACAO_DE_TITULARIDADE_CARTAO,
        payload: ''
    };
};

export const limparCartoes = () => ({
    type: METODOS.CARTAO.LIMPAR_CARTOES,
    payload: []
});

export function getCartoes(contaId, produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos, posicao) {

    let filtroCartoes = montarQueryString(produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos);

    return function(dispatch) {
        dispatch(setExibirLoadingCartao(true));
        const cartoes = axios.get(URL_GET_CARTOES(contaId, filtroCartoes, posicao), HEADER_API());
        Promise.all([cartoes])
            .then(([responseCartoes]) => {
                dispatch({
                    type: METODOS.CARTAO.SET_MSG_LISTA_VAZIA,
                    payload: ''
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES,
                    payload: responseCartoes.data
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES,
                    payload: {
                        posicao: 0,
                        quantidadeTotalRegistros: 0,
                        registros: []
                    }
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_ERRO,
                    payload: err && err.response && err.response.data ? err.response.data : err
                });
            }).finally(() => dispatch(setExibirLoadingCartao(false)));
    };
}

export function getCartoesDashboard(contaId, produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos) {
    let quantidadeCartoes = 100;
    let filtroCartoes = montarQueryString(produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos);

    return function(dispatch) {
        dispatch(setExibirLoadingCartao(true));
        const cartoes = axios.get(URL_GET_CARTOES_PAGINADO(contaId, filtroCartoes, undefined, quantidadeCartoes), HEADER_API());
        Promise.all([cartoes])
            .then(([responseCartoes]) => {
                dispatch({
                    type: METODOS.CARTAO.SET_MSG_LISTA_VAZIA,
                    payload: ''
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES,
                    payload: responseCartoes.data
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES,
                    payload: {
                        posicao: 0,
                        quantidadeTotalRegistros: 0,
                        registros: []
                    }
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_ERRO,
                    payload: err && err.response && err.response.data ? err.response.data : err
                });
            }).finally(() => dispatch(setExibirLoadingCartao(false)));
    };
}

export function getCartoesExpandido(contaId, posicao) {

    return function(dispatch) {
        dispatch(setExibirLoadingCartao(true));
        const cartoes = axios.get(URL_GET_CARTOES_PAGINADO(contaId, posicao), HEADER_API());
        Promise.all([cartoes])
            .then(([responseCartoes]) => {
                dispatch({
                    type: METODOS.CARTAO.SET_MSG_LISTA_VAZIA,
                    payload: ''
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_PAGINADO,
                    payload: responseCartoes.data
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_PAGINADO,
                    payload: {
                        posicao: 0,
                        quantidadeTotalRegistros: 0,
                        registros: []
                    }
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_ERRO,
                    payload: err && err.response && err.response.data ? err.response.data : err
                });
            }).finally(() => dispatch(setExibirLoadingCartao(false)));
    };
}

export function searchCartoes(contaId, idCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos) {

    const isSearchAll = nomePortador !== null && nomePortador !== undefined && nomePortador !== '' ||
        numeroDocumentoPortador !== null && numeroDocumentoPortador !== undefined && numeroDocumentoPortador !== '' ||
        cartaoQuatroUltimosDigitos !== null && cartaoQuatroUltimosDigitos !== undefined && cartaoQuatroUltimosDigitos !== '' ? true : false;

    let enabled = true;

    const getPage = (page) => {
        return new Promise((resolve, reject) => {
            axios.get(URL_GET_CARTOES_PAGINADO(contaId, page), HEADER_API()).then(resp => {
                if (resp && resp.data.registros && resp.data.registros.length > 0) {
                    const registroEncontrado = resp.data.registros.filter(cartao =>
                        idCartao && cartao.idCartaoImpresso && cartao.idCartaoImpresso === idCartao ||
                        nomePortador && cartao.nomePortador && cartao.nomePortador === nomePortador ||
                        numeroDocumentoPortador && cartao.documento && cartao.documento.numero === numeroDocumentoPortador.replace(/[^\w\s]/gi, '') ||
                        cartaoQuatroUltimosDigitos && cartao.quatroUltimosDigitos && cartao.quatroUltimosDigitos === cartaoQuatroUltimosDigitos
                    );
                    if (registroEncontrado.length > 0) {
                        if (isSearchAll) {
                            resolve(registroEncontrado);
                        } else {
                            enabled = false;
                            resolve(registroEncontrado);
                        }
                    } else {
                        resolve();
                    }
                } else {
                    enabled = false;
                    resolve(null);
                }
            });
        });
    };

    const exec = () => {
        return new Promise((resolve, reject) => {
            const resultPages = [];
            let page = 0;
            const running = () => {
                resultPages.push(getPage(page));
                setTimeout(() => {
                    page++;
                    if (enabled) {
                        running(page);
                    } else {
                        resolve(resultPages);
                    }
                }, 3000);
            };
            running(page);
        });
    };

    return function(dispatch) {
        let carts = [];
        exec().then((pages) => {
            Promise.all(pages).then((responseCarts) => {
                const response = responseCarts.filter(cart => cart);

                if (isSearchAll) {
                    for (let resp of response) {
                        if (resp.length > 0) {
                            carts = carts.concat(resp);
                        }
                    }
                } else {
                    carts = response[0];
                }

                if (carts && carts.length > 0) {
                    dispatch(setExibirLoadingCartao(false));
                    const data = {
                        registros: carts,
                        quantidadeTotalRegistros: carts.length
                    };
                    dispatch({
                        type: METODOS.CARTAO.SET_MSG_LISTA_VAZIA,
                        payload: ''
                    });
                    dispatch({
                        type: METODOS.CARTAO.GET_CARTOES_PAGINADO,
                        payload: data
                    });
                } else {
                    dispatch(setExibirLoadingCartao(false));
                    dispatch({
                        type: METODOS.CARTAO.GET_CARTOES_PAGINADO,
                        payload: {
                            posicao: 0,
                            quantidadeTotalRegistros: 0,
                            registros: []
                        }
                    });
                }
            });
        });
    };
}

export function bloquearCartao(contaDestinoId, cartaoId, necessitaToken, outorgaPodeAprovar) {

    return async dispatch => {
        dispatch(setExibirModalConfirmacaoCartao(false));
        dispatch(setExibirLoadingCartao(true));
        fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
            .then(resp => {
                if (!resp.data.quantidadeTotalRegistros) {
                    efetuarBloqueioDeCartao(contaDestinoId, dispatch, necessitaToken, outorgaPodeAprovar);
                } else {
                    dispatch({
                        type: resp.error ? METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO : METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO,
                        payload: resp.data
                    });
                }
                dispatch(setExibirLoadingCartao(false));
            });
    };
}

export function solicitarDesbloqueioDeCartao(contaDestinoId, cartaoId, statusCartao, necessitaToken, outorgaPodeAprovar) {
    return async dispatch => {
        fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
            .then(resp => {
                if (!resp.data.quantidadeTotalRegistros) {
                    efetuarSolicitacaoDeDesbloqueioDeCartao(contaDestinoId, statusCartao, dispatch, necessitaToken, outorgaPodeAprovar);
                } else {
                    dispatch({
                        type: resp.error ? METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO : METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO,
                        payload: resp.data
                    });
                }
            });
    };
}

export const solicitarTokenCartao = operacaoId => {
    return dispatch => {
        solicitarToken(operacaoId)
            .then(resp =>
                dispatch({
                    type: !resp.error ? METODOS.CARTAO.SOLICITAR_TOKEN_CARTAO : METODOS.CARTAO.SOLICITAR_TOKEN_CARTAO_ERRO,
                    payload: resp.data
                })
            );
    };
};

const solicitarToken = operacaoId => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS', transacao: 'aprovacao' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};

const efetivaTransacaoBloqueioDeCartao = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(TIPO_DE_TRANSACAO.APROVACAO)
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};

export function desbloqueioDeCartao(operacaoId, corpoDaRequisicao) {
    return async dispatch => {
        return await fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: corpoDaRequisicao
        })
            .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then(response => {
                dispatch({
                    type: response.error ? METODOS.CARTAO.DESBLOQUEIO_DE_CARTAO_ERRO : METODOS.CARTAO.DESBLOQUEIO_DE_CARTAO,
                    payload: response.data
                });
            });
    };
}

const alterarTitularidadeCartao = async (dispatch, contaId, corpoDaRequisicao) => {
    return await fetch(montaUrlFetch(urlServicos.TRANSFERENCIA_TITULARIDADE_CARTAO(contaId)), {
        method: HTTP_METODOS.PATCH,
        headers: HEADER_API().headers,
        body: corpoDaRequisicao
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then(response => {
            dispatch({
                type: response.error ? METODOS.CARTAO.SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO_ERRO : METODOS.CARTAO.SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO,
                payload: response.data
            });
        });
};



const fetchSolicitacaoPagamentoReenviarToken = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS', transacao: 'aprovacao' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    });
};

export function solicitarAlteracaoTitularidadeCartao(cartaoId, contaId, corpoDaRequisicao) {
    return async dispatch => {
        fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId)
            .then(resp => {
                if (!resp.data.quantidadeTotalRegistros) {
                    alterarTitularidadeCartao(dispatch, contaId, corpoDaRequisicao);
                } else {
                    dispatch({
                        type: resp.error ? METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO : METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO,
                        payload: resp.data
                    });
                }
            });
    };
}

const efetuarSolicitacaoDeDesbloqueioDeCartao = async (contaDestinoId, statusCartao, dispatch, necessitaToken, outorgaPodeAprovar) => {
    if (necessitaToken && outorgaPodeAprovar) {
        return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: retornaCorpoRequiscaoDesbloquearCartao(contaDestinoId, statusCartao)
        })
            .then(response => response.json())
            .then(async dadosRetornados => ({ response: await fetchSolicitacaoPagamentoReenviarToken(dadosRetornados.rel), operacaoId: dadosRetornados.rel }))
            .then(({ response, operacaoId }) => {
                dispatch({
                    type: response.error ? METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO : METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO,
                    payload: operacaoId
                });
            })
            .catch(err => {
                dispatch({
                    type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                    payload: { erros: ['Tente novamente mais tarde' || err] }
                });
            });
    }

    if (outorgaPodeAprovar) {
        return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: retornaCorpoRequiscaoDesbloquearCartao(contaDestinoId, statusCartao)
        })
            .then(response => response.json())
            .then(async dadosRetornados => ({ response: await solicitarToken(dadosRetornados.rel), operacaoId: dadosRetornados.rel }))
            .then(({ response, operacaoId }) => {
                dispatch({
                    type: response.error ? METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO : METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO,
                    payload: operacaoId
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO,
                    payload: { erros: ['Tente novamente mais tarde'] }
                });
            });
    }

    return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: retornaCorpoRequiscaoDesbloquearCartao(contaDestinoId, statusCartao)
    })
        .then(response => response.json())
        .then(({ response, operacaoId }) => {
            dispatch({
                type: response.error ? METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO : METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO,
                payload: operacaoId
            });
        }).catch((err) => {
            dispatch({
                type: METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO,
                payload: { erros: ['Tente novamente mais tarde'] }
            });
        });
};

const buscaSaldoCartao = async (contaId) => {
    return await fetch(montaUrlFetch(urlServicos.SALDO_CARTAO(contaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};

const efetuarBloqueioDeCartao = async (contaDestinoId, dispatch, necessitaToken, outorgaPodeAprovar) => {
    if (necessitaToken && outorgaPodeAprovar) {
        return dispatch => {
            fetch(montaUrlFetch(urlServicos.OPERACAO), {
                method: HTTP_METODOS.POST,
                headers: HEADER_API().headers,
                body: retornaCorpoRequiscaoBloquearCartao(contaDestinoId)
            })
                .then(response => response.json())
                .then(async dadosRetornados => ({ response: await fetchSolicitacaoPagamentoReenviarToken(dadosRetornados.rel), operacaoId: dadosRetornados.rel }))
                .then(({ response, operacaoId }) => {
                    dispatch({
                        type: response.ok ? METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_TOKEN_PAGAMENTO : METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_TOKEN_PAGAMENTO_ERRO,
                        payload: { rel: operacaoId }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                        payload: { erros: ['Tente novamente mais tarde' || err] }
                    });
                });
        };
    }
    if (outorgaPodeAprovar) {
        return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: retornaCorpoRequiscaoBloquearCartao(contaDestinoId)
        })
            .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then(response => response.data.rel)
            .then(operacaoId => efetivaTransacaoBloqueioDeCartao(operacaoId))
            .then(respTransacao => {
                dispatch({
                    type: respTransacao.error ? METODOS.CARTAO.BLOQUEAR_CARTAO_ERRO : METODOS.CARTAO.BLOQUEAR_CARTAO,
                    payload: respTransacao
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.CARTAO.BLOQUEAR_CARTAO_ERRO,
                    payload: err
                });
            });
    }

    return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: retornaCorpoRequiscaoBloquearCartao(contaDestinoId)
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then(response => response.data.rel)
        .then(respTransacao => {
            dispatch({
                type: respTransacao.error ? METODOS.CARTAO.BLOQUEAR_CARTAO_ERRO : METODOS.CARTAO.BLOQUEAR_CARTAO,
                payload: respTransacao
            });
        }).catch((err) => {
            dispatch({
                type: METODOS.CARTAO.BLOQUEAR_CARTAO_ERRO,
                payload: err
            });
        });
};

export function cancelaCartao(contaDestinoId, cartaoId, quatroUltimosDigitos, status) {
    return async dispatch => {
        const efetivaCancelamento = () => {
            fetchBuscaCartaoComOperacaoEmProcessamento(cartaoId).then(resp => {
                if (!resp.data.quantidadeTotalRegistros) {
                    efetuarCancelamentoDeCartao(contaDestinoId, dispatch);
                } else {
                    dispatch({
                        type: resp.error ? METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO : METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO,
                        payload: resp.data
                    });
                }
                dispatch(setExibirLoadingCartao(false));
            });
        };

        dispatch(setExibirModalConfirmacaoCartao(false));
        dispatch(setExibirLoadingCartao(true));

        if (status !== 'prontoParaAtivacao') {
            buscaSaldoCartao(contaDestinoId).then(resp => {
                if (!resp.error) {
                    if (resp.data && resp.data.saldo !== 0) {
                        dispatch(setExibirLoadingCartao(false));
                        dispatch({
                            type: METODOS.CARTAO.CANCELAR_CARTAO_SALDO_ERRO,
                            payload: {
                                quatroUltimosDigitos
                            }
                        });
                    } else {
                        efetivaCancelamento();
                    }
                } else {
                    dispatch({
                        type: METODOS.CARTAO.CANCELAR_CARTAO_ERRO,
                        payload: resp.data
                    });
                    dispatch(setExibirLoadingCartao(false));
                }
            });
        } else {
            efetivaCancelamento();
        }
    };
}



export const getCartoesFavoritos = (contaId, produtoId, IDCartao, cartaoQuatroUltimosDigitos, tamanho, ultimaPosicao, limparListaCartoes) => {
    return dispatch => {
        dispatch(setExibirLoadingCartao(true));
        if (limparListaCartoes) {
            dispatch(limparCartoes());
        }
        Promise.all([
            fetchGetCartoes(contaId, produtoId, IDCartao, '', '', cartaoQuatroUltimosDigitos),
            fetchGetCartoesFavoritos(contaId, IDCartao, tamanho, ultimaPosicao)
        ])
            .then(result => {
                const cartoes = result[0].data;
                let listaDeCartoesFavoritos = result[1].data.registros;
                let quantidadeTotalRegistros = result[1].data.quantidadeTotalRegistros;
                let posicao = result[1].data.posicao;
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES,
                    payload: cartoes
                });

                dispatch({
                    type: METODOS.CARTAO.GET_CAROTES_FAVORITOS,
                    payload: listaDeCartoesFavoritos,
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CAROTES_FAVORITOS_POSICAO,
                    payload: posicao
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CAROTES_FAVORITOS_QTD,
                    payload: quantidadeTotalRegistros,
                });

            }).catch(error => {
                dispatch({
                    type: METODOS.CARTAO.GET_CAROTES_FAVORITOS_ERRO,
                    payload: error
                });
                dispatch({
                    type: METODOS.CARTAO.GET_CARTOES_ERRO,
                    payload: error && error.response && error.response.data ? error.response.data : error
                });
            }).finally(() => dispatch(setExibirLoadingCartao(false)));
    };
};

export const setCartoesFavoritos = (listaDeCartoesFavoritos) => {
    return dispatch => {
        dispatch(setExibirLoadingCartao(true));
        dispatch({
            type: METODOS.CARTAO.SET_CARTOES_FAVORITOS,
            payload: listaDeCartoesFavoritos
        });
        dispatch(setExibirLoadingCartao(false));
    };
};

export const setListaCartoesFavoritos = (listaDeCartoesFavoritos) => {
    return dispatch => {
        dispatch({
            type: METODOS.CARTAO.SET_CARTOES_FAVORITOS,
            payload: listaDeCartoesFavoritos
        });
    };
};

export const decrementQuantidadeTotalRegistros = () => {
    return dispatch => {
        dispatch({
            type: METODOS.CARTAO.DECREMENT_CAROTES_FAVORITOS_QTD
        });
    };
};

export const setClearState = () => ({
    type: METODOS.CARTAO.SET_CLEAR_STATE,
    payload: ''
});

const efeTivaTransacaoCancelamentoDeCartao = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(TIPO_DE_TRANSACAO.APROVACAO)
    }).then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};

const efetuarCancelamentoDeCartao = async (contaDestinoId, dispatch) => {
    return await fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: retornaCorpoRequiscaoCancelarCartao(contaDestinoId)
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then(response => response.data.rel)
        .then(operacaoId => efeTivaTransacaoCancelamentoDeCartao(operacaoId))
        .then(respTransacao => {
            dispatch({
                type: respTransacao.error ? METODOS.CARTAO.CANCELAR_CARTAO_ERRO : METODOS.CARTAO.CANCELAR_CARTAO,
                payload: respTransacao
            });
        }).catch((err) => {
            dispatch({
                type: METODOS.CARTAO.CANCELAR_CARTAO_ERRO,
                payload: err
            });
        });
};
