export const ESTILO_ERRO_PARA_OS_CAMPOS = {
    color: 'red',
    margin: '-0em !important'
};

export const STATUS_VALIDACAO_COMPONENTES = {
    AVISO: 'warning',
    ERRO: 'error',
    PERIGO: 'danger',
    SUCESSO: 'success'
};

export const ESTILO = {
    COR_DE_FUNDO: {
        TITULO: {
            BPP_PAG: 'panel-heading fundo-verde-claro',
            PADRAO: 'panel-heading fundo-azul-claro'
        },
        BOTAO: { PADRAO: 'primary', BPP_PAG: 'success' },
        TEXTO_ATENDIMENTO: { PADRAO: 'modal-atendimento-bppCorp', BPP_PAG: 'modal-atendimento-bppPag' },
        TEXTO_ATENDIMENTO_CHAT_EMAIL: { PADRAO: 'modal-atendimento-bppCorp-chat-email', BPP_PAG: 'modal-atendimento-bppPag-chat-email' },
        FILTRO: {
            PADRAO: 'estilo-filtro dropdown-sem-bordas', BPP_PAG: 'estilo-filtro-bbpppag dropdown-sem-bordas'
        }
    }
};
