import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'react-bootstrap';
import { CONTA_SELECIONADA, FILTRO_PRIME_REACT_DATA_TABLE, LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import If from '../../componentes-uteis/if/if';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import { getFavorecidosConta, getBancos } from '../../../servicos/contas/conta-service';
import Grid from '../../componentes-uteis/grid/grid';
import ModalIncluirFavorecido from './modal-incluir-favorecido';
import ModalEditarFavorecido from './modal-editar-favorecido';


class ListarFavorecidos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            listaDeBancos: [], listaDeFavorecidos: [], exibirLoading: false, favorecidoSelecionado: undefined, exibirModalEditarFavorecido: false,
            exibirModalIncluirFavorecido: false,
        };
    }

    async componentDidMount() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.setState({ contaSelecionada });
        await this.getFavorecidosContaByContaId(contaSelecionada.id);
    }

    getFavorecidosContaByContaId = async (contaId) => {
        this.setState({ exibirLoading: true });
        if (!this.state.listaDeBancos.length) {
            const listaDeBancos = await getBancos();
            this.setState({ listaDeBancos });
        }
        const listaDeFavorecidos = await getFavorecidosConta(contaId);
        this.setState({ exibirLoading: false, listaDeFavorecidos });
        return listaDeFavorecidos;
    }

    setFavorecidoSelecionado = (e) => {
        this.setState({ favorecidoSelecionado: e.value });
        this.setExibirModalEditarFavorecido(true);
    }

    setExibirModalIncluirFavorecido = (exibirModalIncluirFavorecido) => {
        this.setState({ exibirModalIncluirFavorecido });
    }

    setExibirModalEditarFavorecido = (exibirModalEditarFavorecido) => {
        if (!exibirModalEditarFavorecido) {
            this.setState({ favorecidoSelecionado: undefined });
        }
        this.setState({ exibirModalEditarFavorecido });
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    render() {
        const { CONTAINS, FILTER_IN } = FILTRO_PRIME_REACT_DATA_TABLE;
        const header = (
            <div className="titulo-lista_autorizados"><span className="lista-autorizado">{LABEL_COMUNS.LISTA_DE_FAVORECIDOS}</span>
                <Button bsStyle="primary" style={{ position: 'absolute', left: 10 }} onClick={() => this.setExibirModalIncluirFavorecido(true)}>
                    {LABEL_COMUNS.CONTAS.TITULOS.FAVORECIDO.INCLUIR_FAVORECIDO}
                </Button>
            </div >
        );

        const filter = (value, filter) => {
            const valueString = value && value.replace(/[^0-9]/g, '') + '';
            const filterString = filter.replace(/[^0-9]/g, '') + '';
            return valueString && valueString.indexOf(filterString) !== -1;
        };

        return (
            <div>
                <If test={this.state.exibirLoading}>
                    <LoadingFenix />
                </If>
                <If test={this.state.listaDeBancos.length}>
                    <ModalIncluirFavorecido
                        exibirModalIncluirFavorecido={this.state.exibirModalIncluirFavorecido}
                        setExibirModalIncluirFavorecido={this.setExibirModalIncluirFavorecido}
                        listaDeBancos={this.state.listaDeBancos}
                        setExibirLoading={this.setExibirLoading}
                        getFavorecidosContaByContaId={this.getFavorecidosContaByContaId}
                    />

                    {this.state.favorecidoSelecionado && <ModalEditarFavorecido
                        exibirModalEditarFavorecido={this.state.exibirModalEditarFavorecido}
                        setExibirModalEditarFavorecido={this.setExibirModalEditarFavorecido}
                        listaDeBancos={this.state.listaDeBancos}
                        setExibirLoading={this.setExibirLoading}
                        favorecidoSelecionado={this.state.favorecidoSelecionado}
                        getFavorecidosContaByContaId={this.getFavorecidosContaByContaId}
                    />}
                </If>

                <div className="espacamento_conteudo_lote">
                    <Grid cols="11 11 11 11">
                        {this.state.listaDeFavorecidos && <DataTable
                            emptyMessage={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                            ref={(el) => this.dt = el}
                            value={this.state.listaDeFavorecidos}
                            paginator={true}
                            rows={10}
                            header={header}
                            selectionMode="single"
                            onRowSelect={this.setFavorecidoSelecionado}
                            selection={this.state.favorecidoSelecionado}
                            onSelectionChange={this.setFavorecidoSelecionado}>
                            <Column field="nome" header={LABEL_COMUNS.NOME} style={{ width: '35%' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.NOME} />
                            <Column
                                field="numeroDoDocumento"
                                header={LABEL_COMUNS.DOCUMENTO}
                                style={{ width: '15%', textAlign: 'center' }}
                                filter={true}
                                filterMatchMode="custom"
                                filterFunction={filter}
                                filterMaxlength={14}
                                filterPlaceholder={LABEL_COMUNS.DOCUMENTO} />
                            <Column field="dadosBancarios" header={LABEL_COMUNS.DADOS_BANCARIOS_FAVORECIDOS} style={{ width: '35%' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.DADOS_BANCARIOS_FAVORECIDOS} />
                        </DataTable>}
                        <br /><br />
                        <Button bsStyle="primary" style={{ position: 'absolute', right: 10 }} onClick={this.props.history.goBack}>
                            {LABEL_COMUNS.VOLTAR}
                        </Button>
                    </Grid>
                </div>
            </div>
        );
    }
}

/* const mapStateToProps = state => ({ favorecidos: { ...getFavorecidosContaa(state.ContasReducer) } });

const mapDispatchToProps = dispatch => bindActionCreators({ getAutorizadosDaConta, insereAutorizadoEPoderes }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListarFavorecidos); */

export default ListarFavorecidos;