import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { FormGroup, ControlLabel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import ShowLineError from '../showLineError/show-line-error';
import { MASCARA_CAMPOS, LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import { connect } from 'react-redux';
import { solicitacaoToken, efetivaTransacao, solicitacaoTrocaSenha } from '../../../servicos/operacoes/operacoes-base';

const TIME_TOKEN_TIME = 60;
const ZERO_TOKEN_TIME = 0;

class TokenPassword extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            exibirLoading: this.props.exibirLoading,
            exibirModalFinalizacao: false,
            inputToken: '',
            nomeOperacao: '',
            time: {},
            solicitacaoToken: {
                erros: []
            },
            efetivaTransacao: {
                erros: []
            }
        };
        this.state = this.INITIAL_STATE;
    }

    clearState = () => {
        clearInterval(this.state.time.id);
        this.setState(this.INITIAL_STATE);
    }

    resetErrors = () => {
        this.setState({
            inputTokenErro: undefined,
            solicitacaoToken: {
                erros: []
            },
            efetivaTransacao: {
                erros: []
            }
        });
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        let valorDoCampo = campo != null && campo.value || campo != null && campo.target != null && campo.target.value;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }

    componentWillReceiveProps = (nextProps) => {
        this.iniciarContagem();
        if (this.props.exibirModal !== nextProps.exibirModal && nextProps.operacaoId && nextProps.exibirModal) {
            this.populateState(nextProps);
        }
    }

    populateState = async (nextProps) => {
        this.clearState();
        this.chamarSolicitacaoToken(nextProps);
    }

    iniciarContagem = () => {
        clearInterval(this.state.time.id);
        this.setState({
            time: {
                seconds: 60, id: setInterval(() => {
                    this.setState(this.validarTempoToken);
                }, 1000)
            }
        });
    }
    validarTempoToken = (state) => {
        if (state.time.seconds === 0) {
            clearInterval(state.time.id);
            return { time: { seconds: 0 } };
        }
        return { time: { ...state.time, seconds: state.time.seconds ? state.time.seconds - 1 : 0 } };
    }

    componentWillUnmount() {
        clearInterval(this.state.time.id);
        this.clearState();
    }

    

    clearFechandoModal = () => {
        this.clearState();
        this.props.fecharModal();
    }

    chamarEfetivaOperacao = () => {  
               
        this.resetErrors();
        this.setExibirLoading(true);
       
        
        solicitacaoTrocaSenha(this.props.dados.cpf, this.props.dados.token, this.state.inputToken, this.props.dados.password, this.props.dados.passwordNow, this.props.dados.telefone)
            .then(resp => {
                
                if (resp.data.erros == undefined){
                    this.props.resultadoCallback(resp);
                    this.clearState();
                    this.props.fecharModal();
                }else{
                    
                    this.setState({ inputTokenErro:true});

                    console.log(resp.data);

                    console.log(resp.data.erros.length);
                    
                    if (resp.data.erros.length > 2){
                        if ( 
                            (resp.data.erros[0].mensagem == 'tamanho deve estar entre 8 e 16' && resp.data.erros[1].mensagem == 'Senha inválida. Utilize pelo menos uma letra e um número') ||
                            (resp.data.erros[1].mensagem == 'tamanho deve estar entre 8 e 16' && resp.data.erros[0].mensagem == 'Senha inválida. Utilize pelo menos uma letra e um número') 
                        ){

                            resp.data.erros[0].mensagem ='Senha inválida.';
                            resp.data.erros[1].mensagem = 'A senha deve ter 8 à 16 caracteres contendo letra maiúscula, minúscula e número.';
                            this.setState({ efetivaTransacao: resp.data });
                        }else{
                            this.setState({ efetivaTransacao: resp.data });
                        }
                    }else{
                        this.setState({ efetivaTransacao:resp.data});
                    }
                    
                }


               
            })
            .catch(err => {
                console.log(err);
                // this.setState({ efetivaTransacao: err.data });
            })
            .finally(() => this.setExibirLoading(false));
    }

    bodyModalToken = () => {
        return (
            <div>
                <FormGroup validationState={this.state.inputTokenErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">{LABEL_COMUNS.TOKEN}: </ControlLabel>
                    <InputMask
                        className="mensagens-erro form-control"
                        value={this.state.inputToken}
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.inputToken ? '' : this.state.inputToken}
                        onChange={(e) => {                            
                            this.setPropsCampos(e, 'inputToken');                            
                            this.resetErrors();
                        }}
                        maskChar={null}
                        mask={MASCARA_CAMPOS.TOKEN} />

                    <ShowLineError error={this.state.inputTokenErro} />
                    {this.state.solicitacaoToken.erros.map((err, index) => {
                        return <ShowLineError key={index} error={err.mensagem} />;
                    })}
                    {this.state.efetivaTransacao.erros.map((err, index) => {
                        return <ShowLineError key={index} error={err.mensagem} />;
                    })}
                    <br />
                    {this.state.time.seconds === 0
                        ? <Button onClick={this.clearFechandoModal} >Deseja tentar novamente ?</Button>  
                        : <span style={{ color: '#6b6c6e' }}>Não recebeu o token? Aguarde <span style={{ color: '#4ebb9e' }}>{this.state.time.seconds}s</span> para enviar novamente</span>
                    }
                </FormGroup>
            </div>
        );
    }


 

    footerModalToken = () => {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={this.props.contextoBppPag}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn2Disabled={!this.state.inputToken || this.state.inputToken.length !== 6}
                btn2OnClick={this.chamarEfetivaOperacao}
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={this.clearFechandoModal}
            />
        );
    }


    render() {
        const exibirLoading = this.props.isLoading;
        return (
            <div>
                {exibirLoading && <LoadingFenix />}

                <ModalComponent exibirModal={this.props.exibirModal}
                    contextoBppPag={this.props.contextoBppPag}
                    header={this.props.titulo}
                    name="modal-componente-token"
                    body={this.bodyModalToken()}
                    footer={this.footerModalToken()} />
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    outorga: state.OutorgaOperacaoReducer
});

TokenPassword.propTypes = {
    titulo: PropTypes.string.isRequired,
    operacaoId: PropTypes.string.isRequired,
    dadosIntegracao: PropTypes.object.isRequired,
    fecharModal: PropTypes.func.isRequired,
    resultadoCallback: PropTypes.func.isRequired,
    isLoading:PropTypes.func.isRequired
};

export default connect(mapStateToProps)(TokenPassword);
