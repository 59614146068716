import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { podeAprovar, podeConsultar, podeCriar } from '../../../../reducer/outorga/outorga-operacao-reducer';
import Grid from '../../../componentes-uteis/grid/grid';
import PanelAcoes from '../../../componentes-uteis/panel-acoes/panel-acoes';
import * as NavegadorNivel from '../../../contas/conta-departamento/navegador-nivel';
import MostrarSaldoESaldoBloqueado from '../../../contas/saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { CONTA_SELECIONADA, LABEL_COMUNS, NAVEGADOR_NIVEL } from '../../../util/contantes-comuns/constantes-comuns';
import AgendamentoEAprovacoesDashboardBppCorp from './agendamento-e-aprovacoes-bpp-corp/agendamento-e-aprovacoes-dashboard-bpp-corp';
import ListaCartoesDashboardBppCorp from './cartoes/lista-cartoes-dashboard-bpp-corp';
import ConsultaDePedidosDashboardBppCorp from './consulta-de-pedidos/consulta-de-pedidos-dashboard-bpp-corp';
import PrestacaoDeContasDashboardBppCorp from './prestacao-de-contas/prestacao-de-contas-dashboard-bpp-corp';
import If from '../../../componentes-uteis/if/if';

class DashboardBppCorp extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirecionar: false,
            rota: ROTAS_CAMINHOS.DASHBOARD_CONTA,
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            rotaPedidoEmbossingCartao: ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_CORP
        };
    }

    render() {
        if (this.state.redirecionar) {
            return <Redirect to={this.state.rota} />;
        }
        const { contaSelecionada } = this.state;
        const { podeConsultar, podeCriar } = this.props.outorgaPerfils;
        reactLocalStorage.set(NAVEGADOR_NIVEL.VALUE, contaSelecionada.id);
        const className = 'panel-heading fundo-azul-claro';

        const outorga = podeCriar ? {
            labelBotao1: LABEL_COMUNS.PEDIR_CARTAO,
            labelBotao2: LABEL_COMUNS.CARGA_LOTE_CARTAO,
            btn1Link: ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_CORP,
            btn2Link: ROTAS_CAMINHOS.CARGA_LOTE_CARTAO
        } : {
            // labelBotao1: LABEL_COMUNS.CARGA_LOTE_CARTAO,
            // btn1Link: ROTAS_CAMINHOS.CARGA_LOTE_CARTAO
        };

        if (podeConsultar) {
            outorga.labelBotao3 = LABEL_COMUNS.RELATORIOS.PADRAO;
            outorga.btn3Link = ROTAS_CAMINHOS.DASHBOARD_RELATORIOS_BPP_CORP;
        }

        return (
            <div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <MostrarSaldoESaldoBloqueado
                                        className={className}
                                        contaSelecionada={contaSelecionada}
                                        exibirSaldo
                                        mostrarSaldoBloqueado
                                        podeConsultar={this.props.outorgaPerfils.podeConsultar}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ListaCartoesDashboardBppCorp
                                        produtosIdsBppCorp={this.props.produtosIdsBppCorp}
                                        className={className}
                                        contaSelecionada={contaSelecionada} />
                                </div>
                            </div>
                            {this.props.outorgaPerfils.podeConsultar &&
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        {this.props.outorgaPerfils.podeConsultar && <ConsultaDePedidosDashboardBppCorp produtosIdsBppCorp={this.props.produtosIdsBppCorp} conta={contaSelecionada} />}
                                    </div>
                                </div>}
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <PanelAcoes className={className} titulo={LABEL_COMUNS.ACOES_DO_PRODUTO} {...outorga} />
                                </div>
                            </div>
                            <If test={podeConsultar}>
                                <div className="row">
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <PrestacaoDeContasDashboardBppCorp produtosIdsBppCorp={this.props.produtosIdsBppCorp} conta={contaSelecionada} />
                                    </div>
                                </div>
                            </If>
                            <If test={podeConsultar}>
                                <div className="row">
                                    <AgendamentoEAprovacoesDashboardBppCorp {...this.props} titulo="Agendamentos e Aprovações" />
                                </div>
                            </If>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <Grid cols="12 12 12 12" className="alinhamento-direita">
                        <Button type="button" bsStyle="primary" onClick={() => {
                            var totalDeNivel = NavegadorNivel.anterior(NAVEGADOR_NIVEL.VALUE);
                            if (!totalDeNivel) {
                                this.setState({ rota: ROTAS_CAMINHOS.DASHBOARD_SOLUCOES });
                            }
                            this.setState({ redirecionar: true });
                        }}>{LABEL_COMUNS.VOLTAR}</Button>
                    </Grid>
                </div>
                <br />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

export default connect(mapStateToProps)(DashboardBppCorp);
