// ACTION TYPES  ----------------------------------------------------------------------------

// GET
export const OBTER_PARAMETROS_CLIENTE_PROJETO = 'OBTER_PARAMETROS_CLIENTE_PROJETO';
export const OBTER_PARAMETROS_CLASSIFICACAO = 'OBTER_PARAMETROS_CLASSIFICACAO';

export const SUCESSO_OBTER_PARAMETROS_CLIENTE_PROJETO = 'SUCESSO_OBTER_PARAMETROS_CLIENTE_PROJETO';
export const SUCESSO_OBTER_PARAMETROS_CLASSIFICACAO = 'SUCESSO_OBTER_PARAMETROS_CLASSIFICACAO';

export const ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO = 'ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO';
export const ERRO_OBTER_PARAMETROS_CLASSIFICACAO = 'ERRO_OBTER_PARAMETROS_CLASSIFICACAO';

// POST
export const CRIAR_PARAMETROS_CLIENTE_PROJETO = 'CRIAR_PARAMETROS_CLIENTE_PROJETO';
export const CRIAR_PARAMETROS_CLASSIFICACAO = 'CRIAR_PARAMETROS_CLASSIFICACAO';

export const SUCESSO_CRIAR_PARAMETROS_CLIENTE_PROJETO = 'SUCESSO_CRIAR_PARAMETROS_CLIENTE_PROJETO';
export const SUCESSO_CRIAR_PARAMETROS_CLASSIFICACAO = 'SUCESSO_CRIAR_PARAMETROS_CLASSIFICACAO';

export const ERRO_CRIAR_PARAMETROS_CLIENTE_PROJETO = 'ERRO_CRIAR_PARAMETROS_CLIENTE_PROJETO';
export const ERRO_CRIAR_PARAMETROS_CLASSIFICACAO = 'ERRO_CRIAR_PARAMETROS_CLASSIFICACAO';

// DELETE
export const REMOVER_PARAMETROS_CLIENTE_PROJETO = 'REMOVER_PARAMETROS_CLIENTE_PROJETO';
export const REMOVER_PARAMETROS_CLASSIFICACAO = 'REMOVER_PARAMETROS_CLASSIFICACAO';

export const SUCESSO_REMOVER_PARAMETROS_CLIENTE_PROJETO = 'SUCESSO_REMOVER_PARAMETROS_CLIENTE_PROJETO';
export const SUCESSO_REMOVER_PARAMETROS_CLASSIFICACAO = 'SUCESSO_REMOVER_PARAMETROS_CLASSIFICACAO';

export const ERRO_REMOVER_PARAMETROS_CLIENTE_PROJETO = 'ERRO_REMOVER_PARAMETROS_CLIENTE_PROJETO';
export const ERRO_REMOVER_PARAMETROS_CLASSIFICACAO = 'ERRO_REMOVER_PARAMETROS_CLASSIFICACAO';

// CONTROLES DA GUI

// EXIBIR ALERTA ERRO OBTER PARAMETROS
export const EXIBIR_ALERTA_ERRO_OBTER_CLIENTE_PROJETO = 'EXIBIR_ALERTA_ERRO_OBTER_CLIENTE_PROJETO';
export const EXIBIR_ALERTA_ERRO_OBTER_CLASSIFICACAO = 'EXIBIR_ALERTA_ERRO_OBTER_CLASSIFICACAO';

// ARMAZENAR VALORES DIGITADOS
export const ARMAZENAR_NOME_CLIENTE_PROJETO_DIGITADO = 'ARMAZENAR_NOME_CLIENTE_PROJETO_DIGITADO';
export const ARMAZENAR_NOME_CLASSIFICACAO_DIGITADO = 'ARMAZENAR_NOME_CLASSIFICACAO_DIGITADO';

// EXIBIR MODAL CRIACAO
export const EXIBIR_MODAL_CRIACAO_CLIENTE_PROJETO = 'EXIBIR_MODAL_CRIACAO_CLIENTE_PROJETO';
export const EXIBIR_MODAL_CRIACAO_CLASSIFICACAO = 'EXIBIR_MODAL_CRIACAO_CLASSIFICACAO';

// EXIBIR MODAL CONFIRMACAO CRIACAO
export const EXIBIR_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO = 'EXIBIR_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO';
export const EXIBIR_CONFIRMACAO_CRIACAO_CLASSIFICACAO = 'EXIBIR_CONFIRMACAO_CRIACAO_CLASSIFICACAO';

// EXIBIR MODAL SUCESSO CRIACAO
export const EXIBIR_SUCESSO_CRIACAO = 'EXIBIR_SUCESSO_CRIACAO';

// EXIBIR MODAL ERRO CRIACAO PARAMETROS
export const EXIBIR_ERRO_CRIACAO_CLIENTE_PROJETO = 'EXIBIR_ERRO_CRIACAO_CLIENTE_PROJETO';
export const EXIBIR_ERRO_CRIACAO_CLASSIFICACAO = 'EXIBIR_ERRO_CRIACAO_CLASSIFICACAO';

// ARMAZENAR PARAMETROS SELECIONADOS PARA REMOCAO
export const ARMAZENAR_CLIENTE_PROJETO_SELECIONADOS_PARA_REMOCAO = 'ARMAZENAR_CLIENTE_PROJETO_SELECIONADOS_PARA_REMOCAO';
export const ARMAZENAR_CLASSIFICACAO_SELECIONADAS_PARA_REMOCAO = 'ARMAZENAR_CLASSIFICACAO_SELECIONADAS_PARA_REMOCAO';

// EXIBIR MODAL CONFIRMACAO REMOCAO
export const EXIBIR_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO = 'EXIBIR_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO';
export const EXIBIR_CONFIRMACAO_REMOCAO_CLASSIFICACAO = 'EXIBIR_CONFIRMACAO_REMOCAO_CLASSIFICACAO';

// EXIBIR MODAL SUCESSO REMOCAO
export const EXIBIR_SUCESSO_REMOCAO = 'EXIBIR_SUCESSO_REMOCAO';

// EXIBIR MODAL ERRO CRIACAO PARAMETROS
export const EXIBIR_ERRO_REMOCAO_CLIENTE_PROJETO = 'EXIBIR_ERRO_REMOCAO_CLIENTE_PROJETO';
export const EXIBIR_ERRO_REMOCAO_CLASSIFICACAO = 'EXIBIR_ERRO_REMOCAO_CLASSIFICACAO';

// ACCORDIONS / PAINEIS
export const EXPANDIR_PAINEL_CLIENTE_PROJETO = 'EXPANDIR_PAINEL_CLIENTE_PROJETO';
export const EXPANDIR_PAINEL_CLASSIFICACAO = 'EXPANDIR_PAINEL_CLASSIFICACAO';

// CONSTANTES GERAIS ----------------------------------------------------------------------------

// LABELS
export const CABECALHO_PAINEL_PRINCIPAL = 'Parâmetros de Prestação de Contas';

export const TEXTO_BOTAO_ACCORDION_CLIENTE_PROJETO = 'Cliente/Projeto';
export const TEXTO_BOTAO_ACCORDION_CLASSIFICACAO = 'Classificação';

export const PLACEHOLDER_CAIXA_TEXTO_CLIENTE_PROJETO = 'Insira o nome do Cliente/Projeto';
export const PLACEHOLDER_CAIXA_TEXTO_CLASSIFICACAO = 'Insira o nome da Classificação';

export const TEXTO_BOTAO_CRIAR_PARAMETRO_CLIENTE_PROJETO = 'Incluir Cliente/Projeto';
export const TEXTO_BOTAO_CRIAR_PARAMETRO_CLASSIFICACAO = 'Incluir Classificação';

export const TEXTO_BOTAO_REMOVER_PARAMETRO_CLIENTE_PROJETO = 'Remover Cliente/Projeto';
export const TEXTO_BOTAO_REMOVER_PARAMETRO_CLASSIFICACAO = 'Remover Classificação';

export const TEXTO_BOTAO_CANCELAR = 'Cancelar';
export const TEXTO_BOTAO_OK = 'Ok';

export const TEXTO_CABECALHO_MODAL_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO = 'Incluir Novo Cliente/Projeto';
export const TEXTO_CABECALHO_MODAL_CONFIRMACAO_CRIACAO_CLASSIFICACAO = 'Incluir Nova Classificação';
export const TEXTO_CABECALHO_MODAL_SUCESSO_CRIACAO = 'Incluir Novo Parâmetro';

export const TEXTO_CABECALHO_MODAL_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO = 'Remover Cliente/Projeto';
export const TEXTO_CABECALHO_MODAL_CONFIRMACAO_REMOCAO_CLASSIFICACAO = 'Remover Classificação';
export const TEXTO_CABECALHO_MODAL_SUCESSO_REMOCAO = 'Remover Parâmetro';

export const TEXTO_SUCESSO_CRIACAO_PARAMETRO = 'Parâmetro criado com sucesso!';
export const TEXTO_SUCESSO_REMOCAO_PARAMETRO = 'Parâmetro(s) excluído(s) com sucesso!';

export const TEXTO_ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO = 'Erro ao obter os parâmetros de Cliente/Projeto cadastrados';
export const TEXTO_ERRO_OBTER_PARAMETROS_CLASSIFICACAO = 'Erro ao obter os parâmetros de Classificação cadastrados';

export const TEXTO_ERRO_CRIAR_PARAMETRO_CLIENTE_PROJETO = 'Erro ao incluir novo parâmetro de Cliente/Projeto';
export const TEXTO_ERRO_CRIAR_PARAMETRO_CLASSIFICACAO = 'Erro ao incluir novo parâmetro de Classificação';

export const TEXTO_ERRO_REMOVER_PARAMETRO_CLIENTE_PROJETO = 'Erro ao remover parâmetro(s) de Cliente/Projeto';
export const TEXTO_ERRO_REMOVER_PARAMETRO_CLASSIFICACAO = 'Erro ao remover parâmetro(s) de Classificação';

export const TEXTO_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO = 'Confirma a remoção do(s) parâmetro(s) de Cliente/Projeto?';
export const TEXTO_CONFIRMACAO_REMOCAO_CLASSIFICACAO = 'Confirma a remoção do(s) parâmetro(s) de Classificação?';

export const TEXTO_REGISTRO_JA_EXISTENTE = { 'projetoExiste': 'Projeto já existente', 'classificacaoExiste': 'Classificação já existente' };
