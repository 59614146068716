import React from 'react';
export const extrairMensagemDeRespostaBackend = (resposta) => {
    let mensagemParaRetornar;
    if (resposta.error) {
        const { data: { erros } } = resposta;
        erros.forEach(erro => {
            if (erro.hasOwnProperty('campo')) {
                if (mensagemParaRetornar === undefined) {
                    mensagemParaRetornar = {};
                }
                mensagemParaRetornar[erro.campo + 'Erro'] = erro.mensagem;
            } else {
                if (mensagemParaRetornar === undefined) {
                    mensagemParaRetornar = [];
                }
                mensagemParaRetornar.push({ erro: erro.mensagem });
            }
        });

        if (mensagemParaRetornar && mensagemParaRetornar instanceof Array) {
            mensagemParaRetornar = mensagemParaRetornar.map(({ erro }) => erro).join('<br />');
            mensagemParaRetornar = <div dangerouslySetInnerHTML={{ __html: mensagemParaRetornar }} />;
        }
    } else {
        const { data: { mensagem } } = resposta;
        const { data: { msg } } = resposta;
        mensagemParaRetornar = mensagem || msg;
    }

    return mensagemParaRetornar;
};

export const retornaObjetoTarget = (value) => {
    return { target: { value } };
};

export const retornaTelefoneSemDDI = (numeroTelefone) => {
    return numeroTelefone && numeroTelefone.substr(2, numeroTelefone.length);
};

export const isEmptyObject = (object) => (!Object.getOwnPropertyNames(object).length);

export const isNoEmptyObject = (object) => (!isEmptyObject(object));

export const hasPropertyName = (object, propertyName) => {
    return object && hasOwnProperty.call(object, propertyName);
};

/*
export const isEmptyObject = (object) => {
    console.log('object', object)
    return !Object.getOwnPropertyNames(object).length;
    //return false
} */
