import React, { Component } from 'react';
import { buscarSaldoConta, setMostrarSaldo, setAtualizandoSaldo } from './mostrar-saldo-action';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import iconeOcultar from '../../../imagens/icone_ocultar.png';
import iconeMostrar from '../../../imagens/icone_mostrar.png';
import { LABEL } from './mostrar-saldo-constantes';
import avatarConta from '../../../imagens/imagem-conta.PNG';
import { DESCRICAO_CONTA } from '../../util/contantes-comuns/constantes-comuns';
import { formatarCorTextoPorValor } from '../../util/extrato/extrato-util';
import { podeConsultar } from '../../../reducer/outorga/outorga-operacao-reducer';

class MostrarSaldo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            exibir: props.exibirSaldo
        };
        this.props.setAtualizandoSaldo();
    }

    componentDidMount() {
        if (this.props.exibirSaldo) {
            this.mostrarSaldo(this.props.contaSelecionada.id);
        }
    }

    mostrarSaldo(contaId) {
        this.setState({ exibir: true });
        this.props.buscarSaldoConta(contaId);
    }

    formataMoeda(numero = 0) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        });
        let valor = '0,00';
        try {
            valor = formatter.format(numero).replace('R$', '');
        } catch (err) { }
        return valor;
    }

    ocultarSaldo() {
        this.setState({ exibir: false });
    }

    render() {
        let button = null;
        let saldo = null;
        const className = formatarCorTextoPorValor(this.props.mostrarSaldoConta.saldo);
        const urlImagemAvatar = avatarConta;
        // if ((this.props.contaSelecionada.descricao || this.props.contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_CARTAO) || (this.props.contaSelecionada.idCartaoImpresso)) {
        //     urlImagemAvatar = avatarConta;
        // } else {
        //     urlImagemAvatar = this.props.contaSelecionada.urlAvatar || this.props.contaSelecionada.avatarPreSignedURL;
        // }

        if (this.state.exibir) {
            saldo =
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                            <img src={avatarConta} className="avatar-conta-selecionada" alt="imagem conta" />
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" style={{ fontWeight: 'bold', marginTop: '-10px' }}>
                            <div className={className}>
                                Saldo Disponível <br />
                                <span className={className}>{this.props.mostrarSaldoConta.saldo === undefined ? 'Atualizando...' : 'R$ ' + this.formataMoeda(this.props.mostrarSaldoConta.saldo)}</span>
                            </div>
                        </div>
                        {
              this.props.mostrarSaldoBloqueado
                ? <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 texto-saldo-bloqueado">
                                    Saldo Bloqueado <br />
                    {this.props.mostrarSaldoConta.saldoBloqueado === undefined ? 'Atualizando...' : 'R$ ' + this.formataMoeda(this.props.mostrarSaldoConta.saldoBloqueado)}
                </div>
                : <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 texto-saldo-bloqueado">&nbsp;</div>
            }
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 alinhamento-direita">
                            <img src={iconeOcultar} /* className="icone-ocultar" */ onClick={() => this.ocultarSaldo()} />
                        </div>
                    </div>
                </div >;
        } else {
            button =
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                            <img src={avatarConta} className="avatar-conta-selecionada" alt="imagem conta" />
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <div className="exibir-saldo">
                                {LABEL.EXIBIR_SALDO}
                            </div>
                        </div>
                        <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4" />
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 alinhamento-direita">
                            <img src={iconeMostrar} /* className="icone-mostrar" */ onClick={() => this.mostrarSaldo(this.props.contaSelecionada.id)} />
                        </div>
                    </div>
                </div>;
        }
        return (
            this.props.outorgaPerfils.podeConsultar && <div>
                {saldo}
                {button}
            </div>
        );

    /* if (this.props.mostrarSaldoConta.exibirSaldo) {
            saldo = <a href="#" className="label-verificar-saldo" onClick={() => this.ocultarSaldo()}>
                {'R$ ' + this.formataMoeda(this.props.mostrarSaldoConta.saldo)}
            </a>
        } else {
            button = <a href="#" className='label-verificar-saldo' onClick={() => this.mostrarSaldo(this.props.contaSelecionada.id)}>{LABEL.EXIBIR_SALDO}</a>
        }
        return (
            <div>
                {saldo}
                {button}
            </div>
        ) */
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
    },
    mostrarSaldoConta: state.MostrarSaldoReducer
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({
        buscarSaldoConta, setMostrarSaldo, setAtualizandoSaldo
    }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(MostrarSaldo);
