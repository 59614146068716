import React from 'react';
import If from '../../componentes-uteis/if/if';

const PedidoLabelCartaBerco = props => (
    <span>
        <If test={props.quantidade > 1}>
            Cartas Berço
        </If>

        <If test={props.quantidade <= 1}>
            Carta Berço
        </If>

    </span>
);
export default PedidoLabelCartaBerco;
