import { TIPO_IMAGEM, METODOS, LABELS } from './pedido-estoque-constantes';
import { preencheCamposDeErros, montaImagensParaCombo, getImagemById } from '../../servicos/pedidos/pedido-service';

const INITIAL_STATE = {
    quantidade: '',
    cartaoSelecionado: '',
    cartaoSelecionadoId: '',
    cartaBercoSelecionada: '',
    cartaBercoSelecionadaId: '',
    listaImagensTipoCartoes: [],
    listaImagensTipoCartaBerco: [],
    combolistaImagensTipoCartoes: [],
    combolistaImagensTipoCartaBerco: [],
    disableComboCartao: true,
    disableComboCartaBerco: true,
    disableButtonCancel: true,
    disabeButtonConfirm: true,
    exibeModalFinalizar: false,
    quantidadeErro: '',
    imagemCartaoErro: '',
    imagemCartaBercoErro: '',
    listarImagensErro: '',
    mensagemFinalizacao: '',
    statusFinalizacao: '',
    exibirLoading: true,
    exibeModalMensagemSucesso: false,
    exibeModalConfirmacaoCancelar: false
};

export default function reducer(state = INITIAL_STATE, action) {
    let disableButtons = true;
    switch (action.type) {
        case METODOS.QUANTIDADE_CARTOES_HANDLE_CHANGE:
            let disableComboCartao = true; let disableComboCartaBerco = true;
            if (action.payload && action.payload > 0) {
                disableComboCartao = false;
                disableComboCartaBerco = false;
            }
            disableButtons = desabilitaBotoes(action.payload, state.cartaoSelecionadoId, state.cartaBercoSelecionadaId);
            return {
                ...state,
                quantidade: action.payload,
                disableComboCartao: disableComboCartao,
                disableComboCartaBerco: disableComboCartaBerco,
                disableButtonCancel: disableButtons,
                disabeButtonConfirm: disableButtons,
                quantidadeErro: '',
                imagemCartaoErro: '',
                imagemCartaBercoErro: ''
            };
        case METODOS.IMAGEM_CARTAO_HANDLE_CHANGE:
            let cartaoSelecionadoId = action.payload ? action.payload.value : '';
            disableButtons = desabilitaBotoes(state.quantidade, cartaoSelecionadoId, state.cartaBercoSelecionadaId);
            if (cartaoSelecionadoId) {
                return {
                    ...state,
                    cartaoSelecionadoId: cartaoSelecionadoId,
                    disableButtonCancel: disableButtons,
                    disabeButtonConfirm: disableButtons,
                    cartaoSelecionado: getImagemById(state.listaImagensTipoCartoes, cartaoSelecionadoId),
                    quantidadeErro: '',
                    imagemCartaoErro: '',
                    imagemCartaBercoErro: ''
                };
            } else {
                return {
                    ...state,
                    cartaoSelecionadoId: '',
                    cartaoSelecionado: '',
                    disableButtonCancel: disableButtons,
                    disabeButtonConfirm: disableButtons,
                    quantidadeErro: '',
                    imagemCartaoErro: '',
                    imagemCartaBercoErro: ''
                };
            }
        case METODOS.IMAGEM_CARTA_BERCO_HANDLE_CHANGE:
            let cartaBercoSelecionadaId = action.payload ? action.payload.value : '';
            disableButtons = desabilitaBotoes(state.quantidade, state.cartaoSelecionadoId, cartaBercoSelecionadaId);
            if (cartaBercoSelecionadaId) {
                return {
                    ...state,
                    cartaBercoSelecionadaId: cartaBercoSelecionadaId,
                    disableButtonCancel: disableButtons,
                    disabeButtonConfirm: disableButtons,
                    cartaBercoSelecionada: getImagemById(state.listaImagensTipoCartaBerco, cartaBercoSelecionadaId),
                    quantidadeErro: '',
                    imagemCartaoErro: '',
                    imagemCartaBercoErro: ''
                };
            } else {
                return {
                    ...state,
                    cartaBercoSelecionada: '',
                    cartaBercoSelecionadaId: '',
                    disableButtonCancel: disableButtons,
                    disabeButtonConfirm: disableButtons,
                    quantidadeErro: '',
                    imagemCartaoErro: '',
                    imagemCartaBercoErro: ''
                };
            }

        case METODOS.EXIBE_LOADING_HANDLE_CHANGE:
            return {
                ...state, exibirLoading: action.payload
            };
        case METODOS.EXIBE_MODAL_FINALIZAR_HANDLE_CHANGE:

            return {
                ...state, exibeModalFinalizar: action.payload
            };

        case METODOS.EXIBE_MODAL_CONFIRMACAO_CANCELAR_HANDLE_CHANGE:

            return {
                ...state, exibeModalConfirmacaoCancelar: action.payload
            };

        case METODOS.SALVAR_PEDIDO_STATUS_OK:
            return {
                ...state,
                dadosForm: action.payload,
                exibeModalFinalizar: false,
                exibirLoading: false,
                mensagemFinalizacao: LABELS.STATUS_PEDIDO_SALVO_COM_SUCESSO,
                statusFinalizacao: true,
                exibeModalMensagemSucesso: true
            };

        case METODOS.LISTAR_IMAGENS_CARTAO_OK:
            return {
                ...state,
                listaImagensTipoCartoes: action.payload,
                combolistaImagensTipoCartoes: action.payload && action.payload ? montaImagensParaCombo(action.payload, TIPO_IMAGEM.CARTAO) : '',
                quantidadeErro: '',
                imagemCartaoErro: '',
                imagemCartaBercoErro: '',
                exibeModalFinalizar: false,
                exibirLoading: false
            };

        case METODOS.LISTAR_IMAGENS_CARTAO_ERRO:
            return {
                ...state,
                quantidadeErro: '',
                imagemCartaoErro: '',
                imagemCartaBercoErro: '',
                exibeModalFinalizar: false,
                exibirLoading: false
            };

        case METODOS.LISTAR_IMAGENS_CARTA_BERCO_OK:
            return {
                ...state,
                listaImagensTipoCartaBerco: action.payload,
                combolistaImagensTipoCartaBerco: action.payload && action.payload ? montaImagensParaCombo(action.payload, TIPO_IMAGEM.CARTA_BERCO) : '',
                quantidadeErro: '',
                imagemCartaoErro: '',
                imagemCartaBercoErro: '',
                exibeModalFinalizar: false,
                exibirLoading: false
            };

        case METODOS.LISTAR_IMAGENS_CARTA_BERCO_ERRO:
            return {
                ...state,
                quantidadeErro: '',
                imagemCartaoErro: '',
                imagemCartaBercoErro: '',
                exibeModalFinalizar: false,
                exibirLoading: false
            };

        case METODOS.SALVAR_PEDIDO_STATUS_ERRO:
            let camposDeErros = preencheCamposDeErros(action.payload.erros);
            return {
                ...state,
                imagemCartaBerco: action.payload,
                quantidadeErro: camposDeErros.quantidadeErro,
                imagemCartaoErro: camposDeErros.imagemCartaoErro,
                imagemCartaBercoErro: camposDeErros.imagemCartaBercoErro,
                exibeModalFinalizar: false,
                exibirLoading: false,
                mensagemFinalizacao: LABELS.STATUS_PEDIDO_NAO_SALVO,
                statusFinalizacao: false
            };
        default:
            return {
                ...state
            };
    }
}

function desabilitaBotoes(quantidade, cartaoSelecionadoId, cartaBercoSelecionadaId) {
    if ((quantidade) && (cartaoSelecionadoId || cartaBercoSelecionadaId)) {
        return false;
    }
    return true;
}
