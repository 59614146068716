import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { ControlLabel, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { PickList } from 'primereact/picklist';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, CAMPOS } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../../componentes-uteis/grid/grid';
import GridRow from '../../componentes-uteis/grid/grid-row';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../../comuns/showLineError/show-line-error';
import { retornaNumeroTelefonePessoa } from '../../../servicos/pessoas/pessoa-service';
import { excluirAutorizado, atualizaAutorizadoOutorga } from '../../../servicos/outorga/outorga-service';
import { retornaObjetoTarget, extrairMensagemDeRespostaBackend } from '../../../servicos/base/service-base';
import { retornaTelefoneOuCelularComMascara } from '../../util/formata-string';
import ModalExibirPerfilDetalhes from './modal-exibir-perfil-detalhes';

class ModalEditarPessoaPoderConta extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL_CAMPOS = {
            celular: '', celularErro: '', email: '', emailErro: '', permissoesConcedidas: [], cadastroParcial: undefined, excluirAutorizado: false,
            nomeCompleto: '', numeroDocumento: '', tituloModal: undefined, mensagemConfirmacao: undefined, mensagemFinalizacao: undefined,
            exibirModalPerfilDetalhes: false, permissoesConcedidasErro: undefined,
        };
        this.state = {
            ...this.ESTADO_INICIAL_CAMPOS,
            autorizadoSelecionado: props.autorizadoSelecionado, contaSelecionada: props.contaSelecionada,
            permissoesDisponiveis: [], exibirLoading: false, contaId: props.contaSelecionada.id,
            exibirModalConfirmacao: false, exibirModalFinalizacao: false, pessoaAlterada: false
        };
    }

    componentDidMount() {
        const { autorizadoSelecionado, contaSelecionada, listaDePerfisOutorga, listaDePoderesOutorga } = this.props;
        const { cadastroParcial, documento, email, id, nomeCompleto, outorga, telefone } = autorizadoSelecionado;

        this.setState({
            autorizadoSelecionado: autorizadoSelecionado,
            contaSelecionada,
            cadastroParcial: cadastroParcial,
            celular: telefone && retornaTelefoneOuCelularComMascara(retornaNumeroTelefonePessoa(telefone)) || '',
            email: email && email.email || '',
            nomeCompleto: nomeCompleto,
            numeroDocumento: documento.numero,
            contaId: contaSelecionada.id,
            pessoaId: id,
            listaDePoderesOutorga: listaDePoderesOutorga,
            permissoesDisponiveis: this.retornaListaDePermissoesDisponiveis(listaDePerfisOutorga, outorga.perfil),
            permissoesConcedidas: this.retornaListaDePermissoesConcedidas(listaDePerfisOutorga, outorga.perfil)
        });
    }

    retornaListaDePermissoesDisponiveis = (origem, destino) => {
        return origem.filter(p => !destino.includes(p.nomePerfil));
    }

    retornaListaDePermissoesConcedidas = (origem, destino) => {
        return origem.filter(p => destino.includes(p.nomePerfil));
    }

    render() {
        return (
            <div>
                <ModalComponent
                    bsSize="lg"
                    exibirModal={this.props.exibirModalAlterarPoderPessoa}
                    header={LABEL_COMUNS.CONTAS.TITULOS.EDITAR_AUTORIZADO}
                    body={this.retornaCorpoModal()}
                    footer={this.retornaRodapeModal()}
                />
                <ModalExibirPerfilDetalhes
                    exibirModalPerfilDetalhes={this.state.exibirModalPerfilDetalhes}
                    setExibirModalPerfilDetalhes={this.setExibirModalPerfilDetalhes}
                />
            </div>
        );
    }


    onChange = (event) => {
        this.setState({
            permissoesDisponiveis: event.source, permissoesConcedidas: event.target,
            permissoesConcedidasErro: event.target ? '' : this.state.permissoesConcedidasErro
        });
    }

    renderItems = (item) => {
        return (
            <div>
                {item.nomeExibicao}
            </div>
        );
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        let valorDoCampo = campo.target && campo.target.value;

        if (Object.keys(valorDoCampo).includes('perfilErro')) {
            valorDoCampo = valorDoCampo.perfilErro;
        }

        if (nomeDoCampo === CAMPOS.EMAIL || nomeDoCampo === CAMPOS.CELULAR) {
            this.setState({ pessoaAlterada: true });
        }

        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }

    limparForm = () => {
        this.setState(this.ESTADO_INICIAL_CAMPOS);
    }

    salvarAutorizado = () => {
        let erro = false;
        if (!this.state.permissoesConcedidas.length) {
            this.setState({ permissoesConcedidasErro: MENSAGENS.VALIDACAO_DE_CAMPOS.SELECIONE_AO_MENOS_UMA_PERMISSAO });
            erro = true;
        } else {
            const { nomeCompleto, numero, digito } = this.retornaNomePessaoNumeroContaEDigito();
            this.setState({ tituloModal: LABEL_COMUNS.CONTAS.TITULOS.EDITAR_AUTORIZADO });
            const mensagemConfirmacao = MENSAGENS.CONTAS.EDICAO.EDICAO_OUTORGA_PESSOA.CONFIRMACAO(nomeCompleto, numero, digito);
            this.setPropsCampos(retornaObjetoTarget(mensagemConfirmacao), CAMPOS.MENSAGEM_CONFIRMACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
        }
    }

    retornaNomePessaoNumeroContaEDigito = () => {
        const { nomeCompleto } = this.state.autorizadoSelecionado;
        const { numero, digito } = this.state.contaSelecionada;
        return { nomeCompleto, numero, digito };
    }

    atualizarAutorizadoEPoderes = async () => {
        this.props.setExibirLoading(true);
        const resposta = await atualizaAutorizadoOutorga(this.state);
        this.props.setExibirLoading(false);
        if (resposta) {
            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
            this.setPropsCampos(retornaObjetoTarget(extrairMensagemDeRespostaBackend(resposta)), CAMPOS.MENSAGEM_FINALIZACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
        }
    }

    excluirAutorizado = async () => {
        this.props.setExibirLoading(true);
        const mensagem = await excluirAutorizado(this.state.contaId, this.state.pessoaId);
        this.props.setExibirLoading(false);
        this.setPropsCampos(retornaObjetoTarget(mensagem), CAMPOS.MENSAGEM_FINALIZACAO);
        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
    }


    setExibirModalPerfilDetalhes = (exibirModalPerfilDetalhes) => {
        this.setState({ exibirModalPerfilDetalhes });
    }

    retornaCorpoModal = () => {
        return (
            <div>
                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={this.state.tituloModal}
                    body={this.state.mensagemConfirmacao}
                    footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent
                    exibirModal={this.state.exibirModalFinalizacao}
                    name="modal-finalizacao"
                    header={this.state.tituloModal}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()} />

                <GridRow>
                    <Grid cols="3 3 3 3">
                        <ControlLabel>{LABEL_COMUNS.CPF}</ControlLabel>
                        <FormGroup>
                            <InputMask
                                className="mensagens-erro form-control"
                                mask={MASCARA_CAMPOS.CPF} maskChar=""
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                placeholder={LABEL_COMUNS.CPF}
                                maxLength="50"
                                value={this.state.numeroDocumento}
                                disabled={true}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid cols="6 6 6 6">
                        <ControlLabel>{LABEL_COMUNS.NOME}</ControlLabel>
                        <FormGroup>
                            <FormControl
                                type="text"
                                id="nome"
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                className="mensagens-erro form-control"
                                placeholder={LABEL_COMUNS.NOME}
                                value={this.state.nomeCompleto}
                                disabled={true}
                                maxLength={100}
                            />

                        </FormGroup>
                    </Grid>
                </GridRow>
                <GridRow>
                    <Grid cols="3 3 3 3">
                        <ControlLabel>{LABEL_COMUNS.CELULAR}</ControlLabel>
                        <FormGroup validationState={this.state.celularErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputMask
                                className="mensagens-erro form-control"
                                style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                placeholder={LABEL_COMUNS.CELULAR}
                                maxLength="50"
                                disabled={!this.state.cadastroParcial}
                                value={this.state.celular}
                                onChange={(e) => this.setPropsCampos(e, CAMPOS.CELULAR)}
                                onBlur={this.montaTelefone}
                                disabled={true}
                            />
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.celularErro} />
                        </FormGroup>
                    </Grid>
                    <Grid cols="6 6 6 6">
                        <ControlLabel>{LABEL_COMUNS.EMAIL}</ControlLabel>
                        <FormGroup validationState={this.state.emailErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <InputGroup>
                                <InputGroup.Addon>@</InputGroup.Addon>
                                <FormControl className="mensagens-erro" id="digito" type="text"
                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                    className="form-control mensagens-erro" placeholder="Email"
                                    disabled={!this.state.cadastroParcial}
                                    maxLength={70}
                                    value={this.state.email}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.EMAIL)}
                                    disabled={true}
                                />
                            </InputGroup>
                            <FormControl.Feedback />
                            <ShowLineError error={this.state.emailErro} />
                        </FormGroup>
                    </Grid>
                </GridRow>
                <GridRow>
                    <Grid cols="10 10 10 10">
                        <ControlLabel>{LABEL_COMUNS.CONTAS.TITULOS.PERMISSOES}</ControlLabel>
                        <PickList
                            source={this.state.permissoesDisponiveis}
                            target={this.state.permissoesConcedidas}
                            itemTemplate={this.renderItems}
                            onChange={this.onChange}
                            showSourceControls={false}
                            showTargetControls={false}
                            responsive={true}
                            style={{ width: '800px' }}
                        />
                        <ShowLineError error={this.state.permissoesConcedidasErro} />
                    </Grid>
                </GridRow>
                {/*  <GridRow>
                    <Grid cols="11 11 11 11">
                        <a href="#" onClick={() => this.setExibirModalPerfilDetalhes(true)}> {LABEL_COMUNS.VISUALIZAR_INFORMACOES_DOS_PERFIS}</a>
                    </Grid>
                </GridRow> */}
            </div>
        );
    }

    retornaRodapeModalFinalizacao() {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.limparForm();
                    this.props.setExibirModalAlterarPoderPessoa(false);
                    this.props.getAutorizadosDaConta(this.state.contaId);
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
                }} />
        );
    }

    retornaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    const { excluirAutorizado } = this.state;
                    if (excluirAutorizado) {
                        this.excluirAutorizado();
                    } else {
                        this.atualizarAutorizadoEPoderes();
                    }
                    this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                }} />
        );
    }


    retornaRodapeModal = () => {
        const { nomeCompleto, numero, digito } = this.retornaNomePessaoNumeroContaEDigito();
        const mensagemConfirmacao = MENSAGENS.CONTAS.EDICAO.EXCLUSAO_OUTORGA.CONFIRMACAO(nomeCompleto, digito);
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.limparForm();
                    this.props.setExibirModalAlterarPoderPessoa(false);
                }}
                btn2Label={LABEL_COMUNS.EXCLUIR}
                btn2OnClick={() => {
                    this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                    this.setPropsCampos(retornaObjetoTarget(mensagemConfirmacao), CAMPOS.MENSAGEM_CONFIRMACAO);
                    this.setState({ excluirAutorizado: true, tituloModal: LABEL_COMUNS.CONTAS.TITULOS.EXCLUIR_AUTORIZADO });
                }}
                btn3Label={LABEL_COMUNS.SALVAR}
                btn3OnClick={() => this.salvarAutorizado()}
            />
        );
    }
}

export default ModalEditarPessoaPoderConta;