/**
 *  esse arquivo tem a finalidade de unir todos os
 * reducers e controlar em um unico objeto JSON
 * que será a referencia na hora de obter o estado
 * do componete pelo porps
 */
import { combineReducers } from 'redux';
import AgendamentoReducer from '../componentes/contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-reducer';
import AlteraCadastroEmpresa from '../componentes/empresa/altera-cadastro-empresa-reducer';
import AnexoContratualReducer from './anexo-contratual/anexo-contratual-reducer';
import ArquivoReducer from './arquivo/arquivo-reducer';
import AppReducer from '../AppReducer';
import CabecalhoReducer from '../componentes/cabecalho/cabecalho-reducer';
import CartoesReducer from './cartoes/cartoes-reducer';
import ComprovanteExtratoReducer from '../componentes/extrato/comprovante-extrato/comprovante-extrato-reducer';
import ConsultaCargaLoteReducer from '../componentes/carga-lote/consulta-carga-lote-reducer';
import ConsultaCargaLoteCartaoReducer from '../componentes/carga-lote-cartao/consulta-carga-lote-cartao-reducer';
import PedidosReducer from './pedidos/pedidos-reducer';
import ContaFavorecidoReducer from '../componentes/favorecido/componentes-interno/conta-favorecido/conta-favorecido-reducer';
import ContasReducer from './contas/contas-reducer';
import ContaSelecionadaReducer from '../componentes/contas/conta-selecionada-reducer';
import ContestacaoReducer from '../componentes/cartao/contestacao/contestacao-reducer';
import DadosPessoaFisicaReducer from '../componentes/listar-dados/pf/dados-pf-reducer';
import DadosPessoaJuridicaReducer from '../componentes/listar-dados/pj/dados-pj-reducer';
import ExtratoReducer from './extrato/extrato-reducer';
import ExibicaoContaReducer from '../componentes/contas/exibicao-conta/exibicao-conta-reducer';
import GerenciarPrestacaoContaReducer from '../componentes/prestacao-contas/gerenciar/gerenciar-prestacao-conta-reducer';
import ListaContaDepartamentoReducer from '../componentes/contas/conta-departamento/lista-conta-departamento/lista-conta-departamento-reducer';
import ListaFavorecidoReducer from '../componentes/favorecido/lista-favorecido/lista-favorecido-reducer';
import LocalidadeReducer from '../reducer/localidade/localidade-reducer';
import LoginReducer from './login/login-reducer';
import MostrarSaldoReducer from '../componentes/contas/saldo/mostrar-saldo-reducer';
import NovoCargaLoteReducer from '../componentes/carga-lote/novo/novo-carga-lote-reducer';
import NovoCargaLoteCartaoReducer from '../componentes/carga-lote-cartao/novo/novo-carga-lote-cartao-reducer';
import OutorgaReducer from './outorga/outorga-reducer';
import OutorgaOperacaoReducer from './outorga/outorga-operacao-reducer';
import PagamentoContas from '../componentes/componentes-uteis/pagamento/pagamento-reducer';
import ParametroPrestacaoReducer from '../componentes/contas/parametro-prestacao/parametro-prestacao-reducer';
import PedidoCartaoVirtualReducer from '../componentes/pedidos/cartao-virtual/pedido-cartao-virtual-reducer';
import PedidoEstoqueReducer from '../componentes/pedido-estoque/pedido-estoque-reducer';
import PedidoImagemPersonalizadaReducer from '../componentes/pedido-personalizacao/pedido/pedido-personalizacao-reducer';
import PedidoSolicitacaoReducer from '../componentes/pedido-carga/pedido-carga-solicitacao-reducer';
import PessoaReducer from './pessoas/pessoas-reducer';
import PrestacaoDeContasDashboardBppCorpReducer from '../componentes/solucoes/dashboard/bpp-corp/prestacao-de-contas/prestacao-de-contas-dashboard-bpp-corp-reducer';
import TrasferenciaEmLoteReducer from '../componentes/solucoes/dashboard/bpp-pag/transferencia-lote/transferencia-em-lote-reducer';
import VincularPortadorReducer from '../componentes/comuns/portador/vincular-portador/vincular-portador-reducer';

const todosReducers = combineReducers({
    AgendamentoReducer,
    AlteraCadastroEmpresa,
    AnexoContratualReducer,
    AppReducer,
    ArquivoReducer,
    CabecalhoReducer,
    CartoesReducer,
    ComprovanteExtratoReducer,
    ConsultaCargaLoteReducer,
    ConsultaCargaLoteCartaoReducer,
    ContaFavorecidoReducer,
    ContasReducer,
    ContaSelecionadaReducer,
    ContestacaoReducer,
    DadosPessoaFisicaReducer,
    DadosPessoaJuridicaReducer,
    ExibicaoContaReducer,
    ExtratoReducer,
    GerenciarPrestacaoContaReducer,
    ListaContaDepartamentoReducer,
    ListaFavorecidoReducer,
    LocalidadeReducer,
    LoginReducer,
    MostrarSaldoReducer,
    NovoCargaLoteReducer,
    NovoCargaLoteCartaoReducer,
    OutorgaReducer,
    OutorgaOperacaoReducer,
    PagamentoContas,
    ParametroPrestacaoReducer,
    PedidosReducer,
    PedidoCartaoVirtualReducer,
    PedidoEstoqueReducer,
    PedidoImagemPersonalizadaReducer,
    PedidoSolicitacaoReducer,
    PessoaReducer,
    PrestacaoDeContasDashboardBppCorpReducer,
    VincularPortadorReducer,
    TrasferenciaEmLoteReducer
});

export default todosReducers;
