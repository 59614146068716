import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    exibirLoadingOutorga: false,
    listaDePoderesOutorga: [],
    listaDePerfisOutorga: []
};

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case METODOS.OUTORGA.GET_PODERES_OUTORGA:
            return { ...state, ...payload };
        case METODOS.OUTORGA.GET_PODERES_OUTORGA_ERRO:
            return { ...state, ...payload };

        case METODOS.OUTORGA.SET_EXIBIR_LOADING_OUTORGA:
            return { ...state, exibirLoadingOutorga: payload };
        default:
            return state;
    }
};
