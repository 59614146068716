import React, { Component } from 'react';
import axios from 'axios';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import { STATUS_VALIDACAO_COMPONENTES } from 'componentes/util/estilo_para_componentes/estiloUtil';
import DataAgendamento from 'componentes/componentes-uteis/data-agendamento/data-agendamento';
import { LABEL_COMUNS, MENSAGENS, OPERACAO, TIPO_DE_TRANSACAO} from 'componentes/util/contantes-comuns/constantes-comuns';
import { valorMaiorQueZero } from 'componentes/util/numeros/numeroUtil';
import { HEADER_API, URL_GET_OPERACOES_TRANSACOES, URL_POST_OPERACOES } from 'servicos/url-servicos';
import { dataInvalida, dataMaiorQueHoje, validaDataMaiorQueUmAno} from 'componentes/util/data/dataUtil';
import { montaDadosParaSolicitarOperacao, montaDadosTransacao, preencheCamposDeErros} from 'componentes/util/cartoes/cartoes-util';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar} from 'reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';
import Token from 'componentes/comuns/token/token';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import If from 'componentes/componentes-uteis/if/if';

const MSG_ERRO_GENERICO = 'Erro ao Efetuar ';


class CargaEDescargaCartao extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = {
            contaSelecionada: props.contaSelecionada, cartaoSelecionado: '', operacaoSolicitada: '',
            valorOperacao: 0, msgErroValorDaOperacao: '', hoje: false, dataAgendamentoObrigatoriaErro: '',
            dataAgendamento: '', msgDataAgendamentoErro: '', erros: [], exibirModalFinalizacaoOperacao: false,
            erroGenerico: '', mensagemDeFinalizacao: '', tagCliente: '', loading: false, operacaoId: ''
        };
        this.state = this.ESTADO_INICIAL;
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.operacaoSelecionada === OPERACAO.NOME.DESCARGA_CARTAO) {
            this.setaDescargaDataParaHoje();
        }
    }

    setaDescargaDataParaHoje() {
        this.setState({ hoje: true });
    }

    limparState = () => {
        this.setState(this.ESTADO_INICIAL);
    }

    retornoDaEfetivacao = (retorno) => {
        this.setState({ showModalToken: false });
        this.montaMensagemFinalizacao(retorno.error);
        this.setState({ exibirModalFinalizacaoOperacao: true, loading: false, valorOperacao: 0, dataAgendamento: '', hoje: false });
    }

    render() {
        const tipoTransacao = this.state.dataAgendamento ? TIPO_DE_TRANSACAO.AGENDAMENTO : TIPO_DE_TRANSACAO.APROVACAO;
        return (
            <div>
                {this.state.loading && <LoadingFenix />}
                <ModalComponent bsSize="large" exibirModal={this.props.exibirModal} name="modal-carga-descarga-cartao" header={this.getTitulo()}
                    body={this.corpoModal()} footer={this.rodapeModal()} />
                <ModalComponent bsSize="large" exibirModal={this.state.exibirModalFinalizacaoOperacao} name="modal-carga-descarga-cartao" header={this.getTitulo()}
                    body={this.corpoModalFinalizacao()} footer={this.rodapeModalFinalizacao()} />

                <Token
                    titulo={this.getTitulo()}
                    operacaoId={this.state.operacaoId}
                    dadosIntegracao={tipoTransacao}
                    dadosOtp={{ canalToken: 'SMS', transacao: tipoTransacao.transacao }}
                    fecharModal={() => {
                        this.setState({ showModalToken: false });
                        this.limparState();
                    }}
                    resultadoCallback={this.retornoDaEfetivacao}
                    exibirModal={this.state.showModalToken} />
            </div>
        );
    }

    getTitulo() {
        if (this.props.operacaoSelecionada === OPERACAO.NOME.CARGA_CARTAO) {
            return LABEL_COMUNS.CARTOES.TITULOS.CARGA_CARTAO;
        }
        return LABEL_COMUNS.CARTOES.TITULOS.DESCARGA_CARTAO;
    }

    setPropsCartao(e, data) {
        this.setState({
            [data]: e.target.value,
        });
    }

    corpoModal() {
        const { operacaoSelecionada } = this.props;
        return (
            <div>
                <div className="row">
                    <Grid cols="4 4 4 4" className="alinhamento-direita">
                        <div className="alinhamento-esquerda">
                            <ControlLabel> Valor da {this.getTitulo()} </ControlLabel>
                        </div>
                        <FormGroup validationState={this.state.msgErroValorDescargaCartao ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <CurrencyInput decimalSeparator="," thousandSeparator="." className="form-control mensagens-erro" id="valor_descarga"
                                value={this.state.valorOperacao} onChange={(valorOperacao) => this.setState({ valorOperacao, msgErroValorDaOperacao: '' })} />
                            {this.state.msgErroValorDaOperacao ? <span className="mensagens-erro-span" role="alert">{this.state.msgErroValorDaOperacao}</span> : null}
                        </FormGroup>
                    </Grid>
                    <Grid cols="9 9 9 9"></Grid>
                    <Grid cols="12 12 12 12">
                        <ControlLabel>{LABEL_COMUNS.DESCRICAO}</ControlLabel>
                        <FormGroup>
                            <FormControl type="text" className="form-control" placeholder="Descrição"
                                style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }} maxLength="40"
                                value={this.state.tagCliente} onChange={(e) => this.setPropsCartao(e, 'tagCliente')}
                            />
                        </FormGroup>
                    </Grid>
                    <If test={operacaoSelecionada === OPERACAO.NOME.CARGA_CARTAO}>
                        <Grid cols="12 12 12 12">
                            <DataAgendamento
                                titulo="Data do pedido"
                                labelTransferenciaClassName="alinhamento-agendar-para-carga-descarga"
                                inputDataAgendamentoClassName="form-control alinhamento-campo-tela-transferencia"
                                inputDataAgendamentoMensagemErroClassName="mensagens-erro-tela-carga-descarga"
                                hojeIDOnchange={() => this.setState({ hoje: !this.state.hoje, dataAgendamentoObrigatoriaErro: '' })}
                                dataAgendamentoOnChange={(dataAgendamento) => this.setState({ dataAgendamento, msgDataAgendamentoErro: '', dataAgendamentoObrigatoriaErro: '' })}
                                dataAgendamentoErro={this.state.msgDataAgendamentoErro}
                                dataAgendamentoObrigatoriaErro={this.state.dataAgendamentoObrigatoriaErro}
                                dataAgendamentoValue={this.state.dataAgendamento}
                                paraHoje={this.state.hoje}
                            />
                        </Grid>
                    </If>
                </div>
            </div>
        );
    }

    rodapeModal() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.props.setShowChargeOrDischargeCard(false);
                    this.limparState();
                }}
                btn2Label={this.props.operacaoSelecionada === OPERACAO.NOME.CARGA_CARTAO ? LABEL_COMUNS.CARREGAR : LABEL_COMUNS.DESCARREGAR}
                btn2OnClick={() => {
                    this.setState({ operacaoSolicitada: this.props.operacaoSelecionada, cartaoSelecionado: this.props.cartaoSelecionado, loading: true });
                    let dadosForm = this.state;
                    dadosForm.cartaoSelecionado = this.props.cartaoSelecionado;
                    dadosForm.operacaoSolicitada = this.props.operacaoSelecionada;
                    dadosForm.contaSelecionada = this.props.contaSelecionada;
                    this.solicitarOperacaoDeCargaOuDescargaCartao(dadosForm);
                }} />
        );
    }

    solicitarOperacaoDeCargaOuDescargaCartao = (dadosForm) => {
        if (this.validarForm()){
            var validaResult = true;
            if (dadosForm.operacaoSolicitada === 'descargaCartao') {
                validaResult = this.validaValorMaximo();
            } 
            if (validaResult){
                let dadosSolicitacao = montaDadosParaSolicitarOperacao(this.state);
                const { operacaoSolicitada, dataAgendamento } = this.state;
                const outorgaOperacao = operacaoSolicitada === OPERACAO.CARGA_CARTAO ? this.props.outorgaCarga : this.props.outorgaDescarga;
                const necessitaToken = dataAgendamento ? outorgaOperacao.agendamento : outorgaOperacao.aprovacao;
                const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;

                if ((necessitaToken && outorgaPodeAprovar) || (necessitaToken && dataAgendamento)) {
                    axios.post(URL_POST_OPERACOES(), dadosSolicitacao, HEADER_API())
                        .then((response) => {
                            let operacaoId = response.data.rel;
                            let transacao = montaDadosTransacao(dadosForm);
                            this.props.setShowChargeOrDischargeCard(false);
                            this.setState({ operacaoId, showModalToken: true });
                        }).catch((err) => {
                           
                            this.setState({ erroGenerico: err && err.response && err.response.data ? err.response.data : MSG_ERRO_GENERICO.concat(this.getTitulo()) });
                        });
                } else if (outorgaPodeAprovar) {
                    axios.post(URL_POST_OPERACOES(), dadosSolicitacao, HEADER_API())
                        .then((response) => {
                            let operacaoId = response.data.rel;
                            let transacao = montaDadosTransacao(dadosForm);
                            this.efetivaTransacao(operacaoId, transacao);
                        }).catch((err) => {
                             
                            this.setState({
                                erroGenerico: err && err.response && err.response.data ? err.response.data : MSG_ERRO_GENERICO.concat(this.getTitulo()), loading: false, msgErroValorDaOperacao: err.response.data.erros[0].mensagem });
                        });
                } else {
                    axios.post(URL_POST_OPERACOES(), dadosSolicitacao, HEADER_API())
                        .then((response) => {
                            let operacaoId = response.data.rel;
                            let transacao = montaDadosTransacao(dadosForm);
                            this.montaMensagemFinalizacao();
                            this.setState({ exibirModalFinalizacaoOperacao: true, loading: false, valorOperacao: 0, dataAgendamento: '', hoje: false });
                            this.props.setShowChargeOrDischargeCard(false);
                        }).catch((err) => {
                            
                            this.setState({ erroGenerico: err && err.response && err.response.data ? err.response.data : MSG_ERRO_GENERICO.concat(this.getTitulo()) });
                        });
                }
            }
        }
    }

    efetivaTransacao(operacaoId, transacao) {
        axios.post(URL_GET_OPERACOES_TRANSACOES(operacaoId), transacao, HEADER_API())
            .then((response) => {
                this.montaMensagemFinalizacao();
                this.setState({ exibirModalFinalizacaoOperacao: true, loading: false, valorOperacao: 0, dataAgendamento: '', hoje: false });
                this.props.setShowChargeOrDischargeCard(false);

            }).catch((erros) => {
                
                 
                if (erros && erros.response && erros.response.data) {
                    let errosRetornados = preencheCamposDeErros(erros.response.data.erros);
                    this.setState({ msgDataAgendamentoErro: errosRetornados.cargaDescargaCartaoErro, valorOperacao: 0, dataAgendamento: '', erroGenerico: errosRetornados.cargaDescargaCartaoErro });
                } else {
                     
                    this.setState({ erroGenerico: erros && erros.response && erros.response.data ? erros.response.data : MSG_ERRO_GENERICO.concat(this.getTitulo()) });
                }
                this.props.setShowChargeOrDischargeCard(false);
                this.montaMensagemFinalizacao(true);
                this.setState({ exibirModalFinalizacaoOperacao: true, loading: false, valorOperacao: 0, dataAgendamento: '', hoje: false });

            });
    }

    montaMensagemFinalizacao(error) {
        let mensagemDeFinalizacao = '';
        const AGENDAMENTO = !this.state.hoje ? 'agendamento de ' : '';
        const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
        const APROVADOR = outorgaPodeAprovar ? 'e aprovação' : '';
        if (error) {
            let { erroGenerico } = this.state;
            if (this.state.operacaoSolicitada === OPERACAO.NOME.CARGA_CARTAO) {
                mensagemDeFinalizacao = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.ERRO.CARGA_CARTAO(AGENDAMENTO, APROVADOR, erroGenerico);
            } else {
                mensagemDeFinalizacao = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.ERRO.DESCARGA_CARTAO(AGENDAMENTO, APROVADOR, erroGenerico);
            }
        } else {
            if (this.state.operacaoSolicitada === OPERACAO.NOME.CARGA_CARTAO) {
                mensagemDeFinalizacao = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.SUCESSO.CARGA_CARTAO(AGENDAMENTO, APROVADOR);
            } else {
                mensagemDeFinalizacao = MENSAGENS.CARTAO.CARGA_E_DESCARGA.FINALIZACAO.SUCESSO.DESCARGA_CARTAO(AGENDAMENTO, APROVADOR);
            }
        }

        this.setState({ mensagemDeFinalizacao });
    }

    validaValorMaximo(){
        let naoExisteErro = true;
        let valorOperacao = parseFloat(this.state.valorOperacao);
        let valorMaximoDeDescarga = parseFloat('500.00');
        if (valorOperacao > valorMaximoDeDescarga) {
            this.setState({ msgErroValorDaOperacao: MENSAGENS.VALIDACAO_DE_CAMPOS.VALOR.VALOR_MAIOR_QUE_500 });
            naoExisteErro = false;
        }
        if (!naoExisteErro) {
            this.setState({ loading: false });
        }
        return naoExisteErro;
    }

    validarForm() {
        let naoExisteErro = true;
        if (!valorMaiorQueZero(this.state.valorOperacao)) {
            this.setState({ msgErroValorDaOperacao: MENSAGENS.VALIDACAO_DE_CAMPOS.VALOR.VALOR_MAIOR_QUE_ZERO });
            naoExisteErro = false;
        }
        if (!this.state.hoje && !this.state.dataAgendamento) {
            this.setState({ dataAgendamentoObrigatoriaErro: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.SELECIONAR_DATA_DE_AGENDAMENTO });
            naoExisteErro = false;
        } else if (!this.state.hoje) {
            if (dataInvalida(this.state.dataAgendamento)) {
                this.setState({ msgDataAgendamentoErro: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_INVALIDA });
                naoExisteErro = false;
            } else if (!dataMaiorQueHoje(this.state.dataAgendamento)) {
                this.setState({
                    msgDataAgendamentoErro: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_DEVE_SER_MAIOR_QUE_A_DATA_ATUAL()
                });
                naoExisteErro = false;
            } else if (validaDataMaiorQueUmAno(this.state.dataAgendamento)) {
                this.setState({
                    msgDataAgendamentoErro: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_MAIOR_QUE_UM_ANO()
                });
                naoExisteErro = false;
            }
        }
        if (!naoExisteErro) {
            this.setState({ loading: false });
        }
        return naoExisteErro;
    }

    corpoModalFinalizacao() {
        return (
            <div style={{ whiteSpace: 'pre-wrap' }}>
                {this.state.mensagemDeFinalizacao}
            </div>
        );
    }

    rodapeModalFinalizacao() {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.props.setShowChargeOrDischargeCard(false);
                    this.limparState();
                }}
            />
        );

    }
}

const mapStateToProps = (state) => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    outorgaCarga: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.CARGA_CARTAO),
    outorgaDescarga: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESCARGA_CARTAO)

});


export default connect(mapStateToProps)(CargaEDescargaCartao);

