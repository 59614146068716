import { bppFetch } from 'core/api/bppFetch';

export function downloadDadosFavoritosApi(contaId, anexoContratualOriginal, action) {

    const urlToCall = `/conta/${contaId}/favorito/download`;

    const options = {
        method: 'GET'
    };

    return bppFetch(urlToCall, options)
        .then(({ error, data }) => {
            const response = {
                error,
                data: JSON.parse(data)
            };
            return response;

        })
        .catch(error => {
            return 'error';
        });
}