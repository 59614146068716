import { DESCRICAO_CONTA, FILTROS, METODOS, OPERACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos, } from '../../servicos/url-servicos';
import { montaRespostaExtratoConta, montarRespostaExtratoContaCartao, retornaNomeDasOperacoesBppPag, ordenarExtratoPorDataDecrescente } from '../../componentes/util/extrato/extrato-util';
import { fetchGetExtrato } from '../../servicos/extrato/extrato-base';

export const setContaSelecionada = (contaSelecionada) => {
    return {
        type: METODOS.SET_CONTA_SELECIONADA,
        payload: contaSelecionada
    };
};
export const setLinhaDoExtratoSelecionada = (linhaDoExtratoSelecionada) => {
    return {
        type: METODOS.EXTRATO.SET_LINHA_DO_EXTRATO_SELECIONADA,
        payload: linhaDoExtratoSelecionada
    };
};

export const setExibirLoadingExtrato = (exibirLoadingExtrato) => {
    return {
        type: METODOS.EXTRATO.SET_EXIBIR_LOADING_EXTRATO,
        payload: exibirLoadingExtrato
    };
};

export const limparExtrato = () => {
    return { type: METODOS.EXTRATO.LIMPAR_EXTRATO };
};

export const setDetalhesTransacaoSelecionada = (transacao) => ({
    type: METODOS.EXTRATO.SET_DETALHES_TRANSACAO_SELECIONADA,
    payload: transacao
});

export const getExtratoCSV = (contaSelecionada, dataInicial, dataFinal) => (
    async dispatch => {
        await fetchGetExtrato(contaSelecionada.id, dataInicial, dataFinal, null, 1000)
            .then(result => montaRespostaExtratoConta(null, result.data))
            .then(result => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_CSV, payload: result }))
            .catch(err => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_ERRO, payload: err.data }));
    }
);

export const getExtrato = (contaSelecionada, dataInicial, dataFinal, ultimaChave, tamanhoPagina, linhasDoExtratoCarregadasAnteriormente = [], dashboard) => (
    async dispatch => {
        let fn = getExtratoContaCartaoEContaDepartamento;

        dispatch(setExibirLoadingExtrato(true));

        if (contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_MOVIMENTO) {
            fn = dashboard ? getExtratoDashBoardConta : getExtratoConta;
        }

        await fn(dispatch, contaSelecionada.id, dataInicial, dataFinal, ultimaChave, tamanhoPagina, linhasDoExtratoCarregadasAnteriormente);

        dispatch(setExibirLoadingExtrato(false));
    }
);

const getExtratoContaCartaoEContaDepartamento = (dispatch, contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina, linhasDoExtratoCarregadasAnteriormente) => {
    return fetchGetExtrato(contaId, dataInicial, dataFinal)
        .then((result) => montarRespostaExtratoContaCartao(linhasDoExtratoCarregadasAnteriormente, result.data, tamanhoPagina, ultimaChave))
        .then(result => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO, payload: result }))
        .catch(err => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_ERRO, payload: err.data }));
};

const getExtratoConta = (dispatch, contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina, linhasDoExtratoCarregadasAnteriormente) => {
    return fetchGetExtrato(contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina)
        .then(result => montaRespostaExtratoConta(linhasDoExtratoCarregadasAnteriormente, result.data))
        .then(result => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO, payload: result }))
        .catch(err => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_ERRO, payload: err.data }));
};

const getExtratoDashBoardConta = (dispatch, contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina) => {
    return fetchGetExtrato(contaId, dataInicial, dataFinal, ultimaChave, tamanhoPagina)
        .then(result => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_DASHBOARD_CONTA, payload: ordenarExtratoPorDataDecrescente(result.data) }))
        .catch((err) => dispatch({ type: METODOS.EXTRATO.GET_EXTRATO_DASHBOARD_CONTA_ERRO, payload: err.data }));
};

const getOperacoesDoExtrato = async (dadosExtrato) => {
    const extratoRetorno = { resultado: [], quantidadeTotalRegistros: dadosExtrato.quantidadeTotalRegistros, ultimaChave: dadosExtrato.ultimaChave };
    const linhasDoExtratoRecuperado = dadosExtrato.resultado;
    await Promise.all(linhasDoExtratoRecuperado.map(async linhaExtrato => {
        const { lancamentos } = linhaExtrato;
        let novosLancamentos = [];
        let lancamentoCargaCartaoDiferentesTitularidadesPag = {};
        let lancamentoTEDPag = {};
        let lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag = {};
        await Promise.all(lancamentos.map(async lancamento => {
            const operacaoExtratoRecuperada = await getOperacaoDoExtratoPorOperacaoId(lancamento.operacaoId);
            const { data: { operacao } } = operacaoExtratoRecuperada;
            switch (operacao) {
                case OPERACAO.NOME.CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG:
                    if (!Object.keys(lancamentoCargaCartaoDiferentesTitularidadesPag).length) {
                        lancamentoCargaCartaoDiferentesTitularidadesPag = {
                            ...lancamento,
                            nome: retornaNomeDasOperacoesBppPag(OPERACAO.NOME.CARGA_CARTAO_DIFERENTEStITULARIDADES_PAG),
                            listaDeTransacoes: new Array({ ...lancamento })
                        };
                    } else {
                        lancamentoCargaCartaoDiferentesTitularidadesPag.valor += lancamento.valor;
                        lancamentoCargaCartaoDiferentesTitularidadesPag.listaDeTransacoes.push({ ...lancamento });
                    }
                    break;
                case OPERACAO.NOME.TED_PAG:
                    if (!Object.keys(lancamentoTEDPag).length) {
                        lancamentoTEDPag = { ...lancamento, nome: retornaNomeDasOperacoesBppPag(OPERACAO.NOME.TED_PAG), listaDeTransacoes: new Array({ ...lancamento }) };
                    } else {
                        lancamentoTEDPag.valor += lancamento.valor;
                        lancamentoTEDPag.listaDeTransacoes.push({ ...lancamento });
                    }
                    break;
                case OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_PAG:
                    if (!Object.keys(lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag).length) {
                        lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag = {
                            ...lancamento,
                            nome: retornaNomeDasOperacoesBppPag(OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES_PAG),
                            listaDeTransacoes: new Array({ ...lancamento })
                        };
                    } else {
                        lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag.valor += lancamento.valor;
                        lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag.listaDeTransacoes.push({ ...lancamento });
                    }
                    break;
                default:
                    novosLancamentos.push({ ...lancamento });
                    break;
            }
        }));
        if (Object.keys(lancamentoTEDPag).length) {
            novosLancamentos.push({ ...lancamentoTEDPag });
        }
        if (Object.keys(lancamentoCargaCartaoDiferentesTitularidadesPag).length) {
            novosLancamentos.push({ ...lancamentoCargaCartaoDiferentesTitularidadesPag });
        }
        if (Object.keys(lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag).length) {
            novosLancamentos.push({ ...lancamentoTransferenciaEntreContasMovimentoDiferentesTitularidadesPag });
        }
        extratoRetorno.resultado.push({ data: linhaExtrato.data, moeda: linhaExtrato.moeda, saldoDia: linhaExtrato.saldoDia, lancamentos: novosLancamentos });
    }));
    return extratoRetorno;
};

const getOperacaoDoExtratoPorOperacaoId = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.OPERACAO_ID(operacaoId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers,
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));

};
