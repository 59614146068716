// const REMOVE_MASCARA_TELEFONE_CPF_CNPJ_PATTERN = /\.|-|\/|\(|\)/g;
const REMOVE_MASCARA_TELEFONE_CPF_CNPJ_PATTERN = /[^0-9]/g;

export function formatarMascaraCnpj(valor, opc = true) {
    if (opc) {
        valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    } else {
        if (valor !== null || valor !== undefined) {
            valor = valor
                .replace('.', '')
                .replace('.', '')
                .replace('-', '');
        }
    }
    return valor;
}

export function formatarMensagemDeErro(erros) { // formata menssagem de erro vinda do back-end
    // if(erros.length === undefined)return;
    /* var msgErros = erros.filter(
        (err) => err.campo === campo
    ) */
    if (erros && erros.length >= 0) {
        return erros[erros.length - 1].mensagem;// pega a ultima menssagem do array
    }
}

export function stringParaNumero(valor) {
    if (typeof valor === 'string') {
        valor = replaceAll(valor, '.', '');
        valor = replaceAll(valor, ',', '.');
    }

    return valor;
}

export function replaceAll(str, de, para) {
    var pos = str.indexOf(de);

    while (pos > -1) {
        str = str.replace(de, para);
        pos = str.indexOf(de);
    }

    return (str);
}

export function removeMascaraTelefoneCpfCnpj(dado) {
    if (dado === '' || dado === undefined) {
        return '';
    }
    return dado.replace(REMOVE_MASCARA_TELEFONE_CPF_CNPJ_PATTERN, '');
}

export const retornaTelefoneOuCelularComMascara = (numeroDeTelefoneOuCelular) => {
    numeroDeTelefoneOuCelular = numeroDeTelefoneOuCelular.replace(/\D/g, ''); // Remove tudo o que não é dígito
    numeroDeTelefoneOuCelular = numeroDeTelefoneOuCelular.replace(/^(\d{2})(\d)/g, '($1) $2'); // Coloca parênteses em volta dos dois primeiros dígitos
    numeroDeTelefoneOuCelular = numeroDeTelefoneOuCelular.replace(/(\d)(\d{4})$/, '$1-$2'); // Coloca hífen entre o quarto e o quinto dígitos
    return numeroDeTelefoneOuCelular;
};

export const formataNumeroCodigoDeBarras = (codigoDeBarras) => {
    return codigoDeBarras.replace(/(\d{5})(\d{5})(\d{5})(\d{6})(\d{5})(\d{6})(\d)(\d{14})/g, '$1.$2 $3.$4 $5.$6 $7 $8');
};