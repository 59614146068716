import { getHistoricoTransferenciaEmLote, getItensDoLote, getLotesDoArquivo, getDetalhesArquivo } from '../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import { FILTROS, METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

/**
 * Busca filtrada e paginada genérica.
 * */
const busca = (fns, params, linhasAnteriores = [], loteId) => {
    const { setLoading, criarFiltro, buscar, setValorBuscado, setErroAoBuscarValor } = fns;

    const filtro = criarFiltro(params);

    return (dispatch) => {
        dispatch(setLoading(true));

        if (loteId) {
            return buscar(loteId, filtro, linhasAnteriores)
                .then(resp => { if (resp.resultado) dispatch(setValorBuscado(resp)); else { const error = { error: resp }; dispatch(setErroAoBuscarValor(error)); } })
                .catch(err => dispatch(setErroAoBuscarValor(err)))
                .finally(() => dispatch(setLoading(false)));
        }

        return buscar(filtro, linhasAnteriores)
            .then(resp => { if (resp.resultado) dispatch(setValorBuscado(resp)); else { const error = { error: resp }; dispatch(setErroAoBuscarValor(error)); } })
            .catch(err => dispatch(setErroAoBuscarValor(err)))
            .finally(() => dispatch(setLoading(false)));
    };
};

const setLoadingHistory = (fns, loading) => {
    const { setLoading } = fns;
    return (dispatch) => {
        dispatch(setLoading(loading));
    };
};

export const setLotTransferHistoryLoading = (loading) => {
    const fns = {
        setLoading: setExibirLoadingTransferenciaEmLoteHistorico
    };
    return setLoadingHistory(fns, loading);
};

/**
 *  Detalhes do arquivo
 *  */
const setExibirLoadingDetalhesDoArquivo = (exibirLoading) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_EXIBIR_LOADING,
        payload: exibirLoading
    }
);

const setDetalhesDoArquivo = (detalhes) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_DETALHES_DO_ARQUIVO,
        payload: detalhes
    }
);

const setErroDetalhesDoArquivo = (erro) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.DETALHES_DO_ARQUIVO.SET_DETALHES_DO_ARQUIVO_ERRO,
        payload: erro
    }
);

export const buscarDetalhesDoArquivo = (params) => {
    const { arquivoId } = params;

    delete params.arquivoId;

    const fns = {
        setLoading: setExibirLoadingDetalhesDoArquivo,
        criarFiltro: () => { },
        buscar: () => getDetalhesArquivo(arquivoId, params),
        setValorBuscado: setDetalhesDoArquivo,
        setErroAoBuscarValor: setErroDetalhesDoArquivo
    };

    return busca(fns, params);
};

/**
 * Histórico de transferência em Lote
 * */
const setExibirLoadingTransferenciaEmLoteHistorico = (exibirLoading) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_EXIBIR_LOADING,
        payload: exibirLoading
    }
);

const setHistoricoTransferenciaEmLote = (historico) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_HISTORICO,
        payload: historico
    }
);

const setErroHistoricoTransferenciaEmLote = (erro) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.HISTORICO.SET_HISTORICO_ERRO,
        payload: erro
    }
);

const criarFiltroTransferenciaEmLoteHistorico = (params) => {
    const { contaId, pessoaId, dataInicial, dataFinal, produto, ultimaChave, transferenciaEmLoteId: id,
        operacaoTransferenciaEmLoteSelecionada, statusTransferenciaEmLoteSelecionado
    } = params;

    const operacao = operacaoTransferenciaEmLoteSelecionada && operacaoTransferenciaEmLoteSelecionada.value;
    const status = statusTransferenciaEmLoteSelecionado && statusTransferenciaEmLoteSelecionado.value;

    return FILTROS.TRANSFERENCIA_EM_LOTE.HISTORICO(contaId, pessoaId, produto, dataInicial, dataFinal, operacao, status, id, ultimaChave);
};

export const buscarHistoricoTransferenciaEmLote = (params, linhasAnteriores = []) => {
    const fns = {
        setLoading: setExibirLoadingTransferenciaEmLoteHistorico,
        criarFiltro: criarFiltroTransferenciaEmLoteHistorico,
        buscar: getHistoricoTransferenciaEmLote,
        setValorBuscado: setHistoricoTransferenciaEmLote,
        setErroAoBuscarValor: setErroHistoricoTransferenciaEmLote
    };

    return busca(fns, params, linhasAnteriores);
};

/**
 *  Lotes do arquivo (Lotes agrupados por data)
 *  */
const setExibirLoadingLotesDoArquivo = (exibirLoading) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_EXIBIR_LOADING,
        payload: exibirLoading
    }
);


const setLotesDoArquivo = (arquivos) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_ARQUIVOS_DO_LOTE,
        payload: arquivos
    }
);

const setErroLotesDoArquivo = (erro) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO.SET_ARQUIVOS_DO_LOTE_ERRO,
        payload: erro
    }
);

const criarFiltroLotesDoArquivo = (params) => {
    const { arquivoId, contaId, pessoaId, dataInicial,
        dataFinal, operacaoTransferenciaEmLoteSelecionada,
        statusTransferenciaEmLoteSelecionado, ultimaChave
    } = params;

    const operacao = operacaoTransferenciaEmLoteSelecionada && operacaoTransferenciaEmLoteSelecionada.value;
    const status = statusTransferenciaEmLoteSelecionado && statusTransferenciaEmLoteSelecionado.value;

    return FILTROS.TRANSFERENCIA_EM_LOTE.LOTES_DO_ARQUIVO(arquivoId, contaId, pessoaId, dataInicial, dataFinal, operacao, status, ultimaChave);
};

export const buscarLotesDoArquivo = (params, linhasAnteriores = []) => {
    const fns = {
        setLoading: setExibirLoadingLotesDoArquivo,
        criarFiltro: criarFiltroLotesDoArquivo,
        buscar: getLotesDoArquivo,
        setValorBuscado: setLotesDoArquivo,
        setErroAoBuscarValor: setErroLotesDoArquivo
    };

    return busca(fns, params, linhasAnteriores);
};

/**
 *  Itens do Lote desagrupados.
 *  */
const setExibirLoadingItensDoLote = (exibirLoading) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE_LOADING,
        payload: exibirLoading
    }
);

const setItensDoLote = (itens) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE,
        payload: itens
    }
);

const setErroItensDoLote = (erros) => (
    {
        type: METODOS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE.SET_ITENS_DO_LOTE_ERRO,
        payload: erros
    }
);

const criarFiltroItensDoLote = (params) => {
    const {
        loteId,
        valorDe,
        valorAte,
        statusLotesDoArquivoSelecionado,
        ultimaChave
    } = params;

    const status = statusLotesDoArquivoSelecionado && statusLotesDoArquivoSelecionado.value;

    return FILTROS.TRANSFERENCIA_EM_LOTE.ITENS_DO_LOTE(loteId, valorDe, valorAte, status, ultimaChave);
};

export const buscarItensDoLote = (params, linhasAnteriores = []) => {
    const { loteId } = params;

    delete params.loteId;

    const fns = {
        setLoading: setExibirLoadingItensDoLote,
        criarFiltro: criarFiltroItensDoLote,
        buscar: getItensDoLote,
        setValorBuscado: setItensDoLote,
        setErroAoBuscarValor: setErroItensDoLote
    };
    return busca(fns, params, linhasAnteriores, loteId);
};
