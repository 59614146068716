import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import React, { Component } from 'react';

import {
    dataAtualPtBr,
    formataHoraPtBr,
    formatarDataBR,
    FORMATO_DE_DATA_BR,
    retornaDataDeDiasPassados
} from '../../../util/data/dataUtil';
import {
    exibeTransacaoValor,
    formatarCampoValor,
    formataSaldoDiaExtratoDashboard
} from '../../../util/extrato/extrato-util';
import {
    getComprovantePDF,
    getHistoricoDeComprovantes,
    getOperacoesComprovante
} from '../../../../servicos/comprovante/comprovante-service';
import {
    CAMPOS,
    LABEL_COMUNS,
    TIPO_DE_MOVIMENTACAO,
    TIPO_PRESTACAO_CONTAS
} from '../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import * as actions from '../../../../action/extrato/extrato-action';
import ComprovanteModal from '../../../componentes-uteis/comprovante/comprovante-modal';
import DetalheExtrato from '../../../componentes-uteis/extrato/detalhe-extrato';
import DetalheExtratoTransacoes from '../../../componentes-uteis/extrato/detalhe-extrato-transacoes';
import FiltroGenerico from '../../../componentes-uteis/filtro-generico/filtro-generico';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import Grid from '../../../componentes-uteis/grid/grid';
import If from '../../../componentes-uteis/if/if';
import Loading from '../../../loading-fenix/loading';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';

class ExtratoDashboardConta extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            abrirCollapse: false,
            dataInicial: formatarDataBR(retornaDataDeDiasPassados()),
            dataFinal: dataAtualPtBr(FORMATO_DE_DATA_BR),
            exibirModalDetalhe: false,
            exibirModalDetalheTransacao: false,
            dadosComprovante: {},
            exibirModalComprovante: false,
            operacoesComprovante: [],
            exibirModalComprovanteErro: false
        };
        this.state = this.INITIAL_STATE;

        this.buscaOperacoesComprovante = this.buscaOperacoesComprovante.bind(this);
        this.getDadosComprovante = this.getDadosComprovante.bind(this);
        this.downloadComprovantePDF = this.downloadComprovantePDF.bind(this);
    }

    componentDidMount() {
        this.buscarEPreencherExtrato();
    }

    buscarEPreencherExtrato() {
        this.props.limparExtrato();
        let contaSelecionada = this.props.contaSelecionada;
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getExtrato(contaSelecionada, this.state.dataInicial, this.state.dataFinal, null, undefined, null, true);

        this.buscaOperacoesComprovante();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.extrato.erros && nextProps.extrato.erros !== this.props.extrato.erros) {
            this.setState(nextProps.extrato.erros);
        }
    }

    async buscaOperacoesComprovante() {
        const { data } = await getOperacoesComprovante('operacoesComprovante');
        this.setState({ operacoesComprovante: data.valor.reduce((acc, cur) => acc.concat(cur.operacao), []) });
    }

    async getDadosComprovante(operacaoId) {
        const { data } = await getHistoricoDeComprovantes(this.props.contaSelecionada.id, { operacaoId });

        if (data.quantidadeTotalRegistros > 0) {
            this.setState({
                dadosComprovante: data.resultado[0],
                exibirModalComprovante: true
            });
        } else {
            this.setState({ exibirModalComprovanteErro: true });
        }
    }

    async downloadComprovantePDF(comprovante) {
        const data = await getComprovantePDF(this.props.contaSelecionada.id, comprovante.operacaoId, comprovante.comprovanteId);

        const linkSource = URL.createObjectURL(data);
        const downloadLink = document.createElement('a');
        const fileName = 'Comprovante.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(linkSource);
    }

    setPropsCampos(e, nomeDoCampo) {
        const valorDoCampo = e.target.value;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: ''
        });
    }

    setPrestacaoDeContasRecuperada = (prestacaoDeContasRecuperada) => {
        this.setState({ prestacaoDeContasRecuperada });
    };

    render() {
        const contaSelecionada = this.props.contaSelecionada;
        const { erros, exibirLoadingExtrato, linhasDoExtratoDashboard } = this.props.extrato;
        const tipoPrestacaoDeContas = TIPO_PRESTACAO_CONTAS.DESCRICAO_CONTA_SELECIONADA(contaSelecionada.descricao);
        const TARIFA = 'tarifa';
        return (
            <div className="row">

                <DetalheExtrato
                    header={LABEL_COMUNS.DETALHES_DA_TRANSACAO(contaSelecionada.descricao)}
                    onClose={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE)}
                    exibirModal={this.state.exibirModalDetalhe}
                    linhaDoExtrato={this.props.extrato.detalhesTransacaoSelecionada} />

                <DetalheExtratoTransacoes
                    header={LABEL_COMUNS.DETALHES_DA_TRANSACAO(contaSelecionada.descricao)}
                    bsSize="lg"
                    onClose={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE_TRANSACAO)}
                    exibirModal={this.state.exibirModalDetalheTransacao}
                    linhaDoExtrato={this.props.extrato.linhaDoExtratoSelecionada}
                    setDetalhesTransacaoSelecionada={transacao => this.props.setDetalhesTransacaoSelecionada(transacao)}
                    setExibiModalDetalhes={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE)}
                />

                <ModalComponent
                    exibirModal={this.state.exibirModalComprovanteErro}
                    header="Comprovante"
                    body={<p>Algo inesperado aconteceu. Tente novamente!</p>}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label="Ok"
                            btn2OnClick={() => this.setState({ exibirModalComprovanteErro: false })}
                        />
                    }
                />

                {this.state.exibirModalComprovante && (
                    <ComprovanteModal
                        header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE(this.props.contaSelecionada.descricao)}
                        bsSize="lg"
                        onClose={() => this.setState({ exibirModalComprovante: false })}
                        exibirModal={this.state.exibirModalComprovante}
                        dadosComprovante={this.state.dadosComprovante}
                        downloadComprovantePDF={this.downloadComprovantePDF}
                    />
                )}

                <div className="panel panel-default">
                    <div className="panel-heading fundo-azul-claro" style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.EXTRATO}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <Button style={{ color: 'white', background: 'transparent', border: 'none' }} onClick={() => this.buscarEPreencherExtrato()} >
                                <i className="fa fa-refresh" />
                            </Button>
                            <Button className="estilo-filtro" onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            &nbsp;&nbsp;
                            <Dropdown id="menuOpcoes" pullRight>
                                <Dropdown.Toggle noCaret className="fundo-azul-claro dropdown-sem-bordas">
                                    <Glyphicon glyph="option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <li>
                                        <Link className="link_expandir" to={ROTAS_CAMINHOS.EXTRATO_CONTA}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeDatas={true}

                        labelDataInicial={LABEL_COMUNS.DATA_INICIAL}
                        dataInicial={this.state.dataInicial}
                        setDataInicial={(e) => this.setPropsCampos(e, CAMPOS.DATA_INICIAL)}
                        dataInicialErro={this.state.dataInicialErro}

                        labelDataFinal={LABEL_COMUNS.DATA_FINAL}
                        dataFinal={this.state.dataFinal}
                        setDataFinal={(e) => this.setPropsCampos(e, CAMPOS.DATA_FINAL)}
                        dataFinalErro={this.state.dataFinalErro}

                        erroGenerico={erros && erros.periodoExcedidoErro}
                        buscar={() => this.props.getExtrato(contaSelecionada, this.state.dataInicial, this.state.dataFinal, null, undefined, null, true)} />
                    <div className="panel-body" style={{ height: '430px' }}>
                        {
                            this.props.extrato.exibirLoadingExtrato ?
                                <div className="alinhamento-centro">
                                    <Loading />
                                </div>
                                :
                                exibirLoadingExtrato !== undefined &&
                                <If test={linhasDoExtratoDashboard && linhasDoExtratoDashboard.length > 0}>
                                    <div className="row">
                                        <Grid cols="12 12 12 12" className="grids-dashboards">
                                            <Grid cols="2 2 2 2">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DATA}</span>
                                            </Grid>
                                            <Grid cols="3 3 3 3">
                                                <span className="titulo-dashboards-conta-solucao"
                                                    style={{ marginLeft: '10px' }}>{LABEL_COMUNS.NOME}</span>
                                            </Grid>
                                            <Grid cols="3 3 3 3">
                                                <span className="titulo-dashboards-conta-solucao"
                                                    style={{ marginLeft: '15px' }}>{LABEL_COMUNS.DESCRICAO}</span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                <span className="titulo-dashboards-conta-solucao"
                                                    style={{ marginRight: '-15px' }}>{LABEL_COMUNS.VALOR}</span>
                                            </Grid>
                                            <Grid cols="1 1 1 1" className="alinhamento-direita">
                                                <span className="titulo-dashboards-conta-solucao"
                                                    style={{ marginLeft: '25px' }}>{LABEL_COMUNS.ACAO}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="row">
                                        <div className="grid-extrato-conta">
                                            {linhasDoExtratoDashboard && linhasDoExtratoDashboard.filter(({ lancamentos }) => lancamentos && lancamentos.length > 0).map((linhaExtrato, idx) => {
                                                linhaExtrato.tipoPrestacaoDeContas = tipoPrestacaoDeContas;
                                                linhaExtrato.saldoDoDiaTipo = linhaExtrato.saldoDia < 0 ? TIPO_DE_MOVIMENTACAO.DEBITO : TIPO_DE_MOVIMENTACAO.CREDITO;
                                                return (
                                                    <div key={idx}>
                                                        <Grid>
                                                            <span className="texto-dashboards-conta-solucao">
                                                                <b><span
                                                                    style={{ fontSize: '11px' }}>{formatarDataBR(linhaExtrato.data)}</span></b><br />
                                                            </span>
                                                        </Grid>
                                                        {linhaExtrato.lancamentos.map((lancamento, indice) => {
                                                            lancamento.tipoPrestacaoDeContas = tipoPrestacaoDeContas;
                                                            //const exibeComprovantes = this.state.operacoesComprovante.includes(lancamento.nome) && lancamento.tipo === TIPO_DE_MOVIMENTACAO.DEBITO && !lancamento.nomeExibicao.toLowerCase().includes(TARIFA);
                                                            const exibeComprovantes = false;
                                                            return (
                                                                <Grid key={indice}>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-esquerda"
                                                                        customStyle={{ padding: 0 }}>
                                                                        {/* <span style={{ fontSize: '11px' }}>
                                                                            {formataHoraPtBr(lancamento.dataHora)}
                                                                        </span> */}
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {lancamento.nomeExibicao}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3">
                                                                        <span style={{
                                                                            fontSize: '11px',
                                                                            marginLeft: '10px'
                                                                        }}>
                                                                            {lancamento.tagCliente}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                                        <span className="posicao-valor-grid-cartao">
                                                                            {formatarCampoValor(lancamento.tipo, lancamento.valor, lancamento.moeda)}
                                                                        </span>
                                                                        <If test={exibeTransacaoValor(lancamento)}>
                                                                            <span
                                                                                className="posicao-valor-grid-cartao">{formatarCampoValor(lancamento.tipo, lancamento.integracaoTransacaoValor, lancamento.integracaoTransacaoMoeda)}</span>
                                                                        </If>
                                                                    </Grid>
                                                                    <Grid cols="1 1 1 1" className="alinhamento-direita">
                                                                        <Dropdown id="menuOpcoes" pullRight style={{
                                                                            marginLeft: '8px',
                                                                            marginTop: '-15px'
                                                                        }}>
                                                                            <Dropdown.Toggle noCaret
                                                                                className="fundo-branco dropdown-sem-bordas">
                                                                                <Glyphicon glyph="option-horizontal" />
                                                                            </Dropdown.Toggle>
                                                                            <Dropdown.Menu>
                                                                                <If test={exibeComprovantes}>
                                                                                    <MenuItem
                                                                                        onSelect={() => this.props.setLinhaDoExtratoSelecionada(lancamento)}
                                                                                        onClick={() => {
                                                                                            this.getDadosComprovante(lancamento.operacaoId);
                                                                                        }}>
                                                                                        {LABEL_COMUNS.COMPROVANTE}
                                                                                    </MenuItem>
                                                                                </If>
                                                                                <MenuItem
                                                                                    onSelect={() => this.props.setLinhaDoExtratoSelecionada(lancamento)}
                                                                                    onClick={() => {
                                                                                        this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_DETALHE);
                                                                                    }}>{LABEL_COMUNS.DETALHES_DA_TRANSACAO()}
                                                                                </MenuItem>
                                                                            </Dropdown.Menu>
                                                                        </Dropdown>
                                                                    </Grid>
                                                                </Grid>
                                                            );
                                                        })}
                                                        <Grid cols="12 12 12 12" className="alinhamento-esquerda">
                                                            <span className="texto-dashboards-conta-solucao"
                                                                style={{ fontWeight: 'bolder' }}>Saldo final do dia: {formataSaldoDiaExtratoDashboard(linhaExtrato.saldoDoDiaTipo, linhaExtrato.saldoDia)}</span>
                                                        </Grid>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </If>

                        }
                        <If test={linhasDoExtratoDashboard.length <= 0 && !exibirLoadingExtrato}>
                            <span>{LABEL_COMUNS.NAO_HA_ITENS_EXTRATO}</span>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    extrato: state.ExtratoReducer
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExtratoDashboardConta);



