import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as actions from 'componentes/contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-action';
import { podeAprovar } from 'reducer/outorga/outorga-operacao-reducer';
import { ACAO, CAMPOS, COMBO, CONTA_SELECIONADA, FILTROS, LABEL_COMUNS, MENSAGENS, OPERACOES_TRANSFERENCIA_EM_LOTE, PRODUTOS, STATUS } from 'componentes/util/contantes-comuns/constantes-comuns';
import Grid from 'componentes/componentes-uteis/grid/grid';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import { Button, Dropdown, MenuItem } from 'react-bootstrap';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';
import { aprovarOuReprovarLote, getBodyApproveOrReproveLoteArquivo, getListaDeLoteArquivos } from 'servicos/transferencia-em-lote/transferencia-em-lote-service';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import If from 'componentes/componentes-uteis/if/if';
import { formatarCampoValor } from 'componentes/util/extrato/extrato-util';
import { formatarDataBR } from 'componentes/util/data/dataUtil';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import { retornaObjetoTarget } from 'servicos/base/service-base';

class AgendamentoEAprovacoesBppCorp extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            acaoSelecionada: '',
            anexoContratualId: '',
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            cargaLoteCartao: {},
            dataDeInclusaoDe: '',
            dataDeInclusaoDeErro: '',
            dataDeInclusaoAte: '',
            dataDeInclusaoAteErro: '',
            exibirLoading: false,
            exibirModalConfirmacao: false,
            exibirModalFinalizacao: false,
            listaCargaLoteCartao: [],
            mensagemConfirmacao: '',
            mensagemFinalizacao: '',
            operacaoSelecionadaId: '',
            statusSelecionado: '',
            idArquivoSequencialPorPessoaId: '',
            exibirMaisRegistros: false,
            ultimaChave: ''

        };

        this.state = this.INITIAL_STATE;

        this.onClickAprovar = this.onClickAprovar.bind(this);
        this.onClickReprovar = this.onClickReprovar.bind(this);
        this.limparFiltro = this.limparFiltro.bind(this);
    }

    componentDidMount = () => {
        const { contaSelecionada } = this.state;
        const { anexoContratualId } = contaSelecionada;

        this.setAnexoContratualId(anexoContratualId);
        const { id, pessoaTitularId } = contaSelecionada;
        this.setState({
            contaId: id,
            pessoaId: pessoaTitularId
        });
        this.buscarTransferenciasEmLote(id, pessoaTitularId);
    };

    setListaCargaLoteCartao = (listaCargaLoteCartao) => {
        this.setState({ listaCargaLoteCartao });
    };

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    };

    setExibirModalConfirmacao = (exibirModalConfirmacao) => {
        this.setState({ exibirModalConfirmacao });
    };

    setAnexoContratualId = (anexoContratualId) => {
        this.setState({ anexoContratualId });
    };

    setCargaLoteCartao = (cargaLoteCartao) => {
        this.setState({ cargaLoteCartao });
    };

    setAcaoSelecionada = (acaoSelecionada) => {
        this.setState({ acaoSelecionada });
    };

    setOperacaoSelecionadaId = (operacaoSelecionadaId) => {
        this.setState({ operacaoSelecionadaId });
    };

    setMensagemConfirmacao = (mensagemConfirmacao) => {
        this.setState({ mensagemConfirmacao });
    };

    setMensagemFinalizacao = (mensagemFinalizacao) => {
        this.setState({ mensagemFinalizacao });
    };

    setExibirModalFinalizacao = (exibirModalFinalizacao) => {
        this.setState({ exibirModalFinalizacao });
    };

    onClickDetalhes = (cargaLoteCartao) => {
        const { history } = this.props;
        history.push({
            pathname: ROTAS_CAMINHOS.DETALHES_BPP_CORP,
            state: { cargaLoteCartao }
        });
    };

    limparFiltro = () => {
        this.setState({
            dataDeInclusaoDe: '',
            dataDeInclusaoAte: '',
            operacaoSelecionadaId: '',
            statusSelecionado: '',
            idArquivoSequencialPorPessoaId: ''
        });
    };


    exibeMensagemMigracao(data) {
        if (data.entity && data.entity.codigo && data.entity.codigo === LABEL_COMUNS.CODIGO_ERRO_MIGRACAO_CORE) {
            return data.entity.erros[0].mensagem;
        } else {
            return data.mensagem;
        }
    }

    onClickAprovar = (cargaLoteCartao) => {
        this.setCargaLoteCartao(cargaLoteCartao);
        this.setExibirModalConfirmacao(true);
        const acaoSelecionada = ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value;
        this.setMensagemConfirmacao(MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO);
        this.setAcaoSelecionada(acaoSelecionada);
    };

    onClickReprovar = (cargaLoteCartao) => {
        this.setCargaLoteCartao(cargaLoteCartao);
        this.setExibirModalConfirmacao(true);
        const acaoSelecionada = ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.REPROVAR.value;
        this.setMensagemConfirmacao(MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.REPROVACAO);
        this.setAcaoSelecionada(acaoSelecionada);

    };

    onClickAprovarOuReprovar = async () => {
        const { acaoSelecionada, cargaLoteCartao: { id } } = this.state;
        const { contaSelecionada: { anexoContratualId } } = this.state;

        this.setExibirLoading(true);
        this.setExibirModalConfirmacao(false);
        const corpoTransacao = getBodyApproveOrReproveLoteArquivo(id, anexoContratualId, acaoSelecionada);
        const response = await aprovarOuReprovarLote(corpoTransacao)
            .then(response => ({ ...response }))
            .catch(error => ({ ...error }));

        let mensagemFinalizacao;
        if (response.error) {
            if (response.data && response.data.erros) {
                const { data: { erros } } = response;
                mensagemFinalizacao = erros[0].mensagem;
            } else {
                mensagemFinalizacao = MENSAGENS.ERRO_DE_REQUISICAO_GENERICA;
            }
        } else {
            mensagemFinalizacao = response.data.mensagem;
        }
        this.setMensagemFinalizacao(this.exibeMensagemMigracao(response.data));
        this.setExibirModalFinalizacao(true);
        this.setExibirLoading(false);
    };

    retornaRodapeModalFinalizacao() {
        const { contaId, pessoaId } = this.state;
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setState(this.INITIAL_STATE);
                    this.buscarTransferenciasEmLote(contaId, pessoaId);
                }}
            />
        );
    }

    buscarTransferenciasEmLote = async (contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId, listaAnteriorCargaLoteCartao) => {
        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
        let { ultimaChave } = listaAnteriorCargaLoteCartao ? this.state : '';

        const resultadoDaBusca = await getListaDeLoteArquivos(FILTROS.TRANSFERENCIA_EM_LOTE.AGENDAMENTOS_E_APROVACOES(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId,
            statusSelecionado, idArquivoSequencialPorPessoaId, PRODUTOS.corp, ultimaChave), listaAnteriorCargaLoteCartao);
        const { error, data } = resultadoDaBusca;
        if (!error) {
            this.setState({ listaCargaLoteCartao: data.resultado });
        }
        if (data.ultimaChave) {
            this.setState({ exibirMaisRegistros: true, ultimaChave: data.ultimaChave });
        } else {
            this.setState({ exibirMaisRegistros: false, ultimaChave: '' });
        }
        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
    };

    setPropsCampos = (e, data) => {
        let valorDoCampo = (e != null && e.value) || (e != null && e.target != null && e.target.value);
        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: ''
        });
    };

    setInitialState = () => {
        this.setState(this.INITIAL_STATE);
    };

    onClickCorrigir = (cargaLoteCartao) => {
        const { history } = this.props;

        if (history.location.pathname !== ROTAS_CAMINHOS.CARGA_LOTE_CARTAO) {
            history.push({
                pathname: ROTAS_CAMINHOS.CARGA_LOTE_CARTAO,
                state: {
                    acao: ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.CORRIGIR.value,
                    cargaLoteCartao
                }
            });
        } else {
            this.props.setArquivoEnviado(cargaLoteCartao);
            this.setState({
                exibirModalCorrecao: true,
                mensagemCorrecao: MENSAGENS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.CORRECAO.UPLOAD_ARQUIVO
            });
        }
    };

    render() {

        const {
            contaId, pessoaId, contaSelecionada, exibirLoading, exibirModalConfirmacao, exibirModalFinalizacao, listaCargaLoteCartao,
            mensagemConfirmacao, mensagemFinalizacao, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId
        } = this.state;

        let acoes = {
            APROVAR: {
                label: LABEL_COMUNS.APROVAR,
                acao: (cargaLoteCartao) => this.onClickAprovar(cargaLoteCartao)
            },
            REPROVAR: {
                label: LABEL_COMUNS.REPROVAR,
                acao: (cargaLoteCartao) => this.onClickReprovar(cargaLoteCartao)
            },
            DETALHES: {
                label: LABEL_COMUNS.DETALHES,
                acao: (cargaLoteCartao) => this.onClickDetalhes(cargaLoteCartao)
            },
            CORRIGIR: {
                label: LABEL_COMUNS.CORRIGIR,
                acao: (cargaLoteCartao) => this.onClickCorrigir(cargaLoteCartao)
            }
        };

        const perfilPodeAprovar = this.props.outorgaPerfils.podeAprovar;

        const menuOpcoesAcoes = [
            {
                status: STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value,
                opcoes: [perfilPodeAprovar && acoes.CORRIGIR, perfilPodeAprovar && acoes.REPROVAR, acoes.DETALHES].filter(elem => elem)
            },
            {
                status: STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value,
                opcoes: [perfilPodeAprovar && acoes.APROVAR, perfilPodeAprovar && acoes.REPROVAR, acoes.DETALHES].filter(elem => elem)
            },
            {
                status: STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.EM_VALIDACAO.value,
                opcoes: [acoes.DETALHES]
            },
            {
                status: STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.REPROVADO.value,
                opcoes: [acoes.DETALHES]
            },
            {
                status: STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.APROVADO.value,
                opcoes: [acoes.DETALHES]
            }
        ];

        return (
            <div>

                <ModalComponent
                    exibirModal={exibirModalFinalizacao}
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()}
                />
                <ModalComponent
                    exibirModal={exibirModalConfirmacao}
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={mensagemConfirmacao}
                    footer={
                        <FooterBotoesModalComponent
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setExibirModalConfirmacao(false)}
                            btn2OnClick={() => this.onClickAprovarOuReprovar()}
                        />
                    }
                />

                <Grid cols="12 12 12 12" className="espacamento_conteudo_paginas">
                    {contaSelecionada && <ContaSelecionada
                        contaSelecionada={contaSelecionada}

                        contextoBppPag={false}
                        mostrarFiltroDeDatas={true}
                        mostrarFiltroDeIdNumerico={true}
                        exibirFiltroDeOperacao={true}
                        exibirFiltroDeStatus={true}
                        exibirSaldo={true}

                        dataInicialLabel={LABEL_COMUNS.DATA_DE_INCLUSAO}
                        dataInicialPlaceHolder={LABEL_COMUNS.DATA_DE}
                        dataInicialValue={dataDeInclusaoDe}
                        dataIncialChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_DE)}
                        dataInicialErro={this.state.dataDeInclusaoDeErro}

                        dataFinalPlaceHolder={LABEL_COMUNS.DATA_ATE}
                        dataFinalValue={dataDeInclusaoAte}
                        dataFinalChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_ATE)}
                        dataFinalErro={this.state.dataDeInclusaoAteErro}

                        idNumerico={idArquivoSequencialPorPessoaId}
                        setIdNumerico={(e) => this.setPropsCampos(e, CAMPOS.ID_ARQUIVO_SEQUENCIAL_POR_PESSOA_ID)}
                        idNumericoErro={this.state.idArquivoSequencialPorPessoaIdErro}

                        operacaoSelecionadaId={operacaoSelecionadaId}
                        setOperacao={(e) => this.setPropsCampos(e, CAMPOS.OPERACAO_SELECIONADA_ID)}
                        listaDeOperacoes={COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE()}

                        listaDeStatus={COMBO.STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS}
                        setStatus={(e) => this.setPropsCampos(e, CAMPOS.STATUS_SELECIONADO)}
                        statusSelecionado={statusSelecionado}
                        statusErro={this.state.statusSelecionadoErro}

                        exibirBotaoLimpar={true}
                        limparFiltro={this.limparFiltro}
                        filtrar={() => this.buscarTransferenciasEmLote(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId)}
                    />}
                </Grid>
                <Grid cols="11 11 11 11">
                    <div className="panel panel-default">
                        <div className={ESTILO.COR_DE_FUNDO.TITULO.PADRAO}>
                            <b>{LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}</b>
                        </div>
                        <div className="panel-body">
                            <If test={exibirLoading}>
                                <div className="alinhamento-centro">
                                    <LoadingFenix />
                                </div>
                            </If>
                            <If test={listaCargaLoteCartao && listaCargaLoteCartao.length > 0 && !exibirLoading}>
                                <div className="row">
                                    <Grid cols="1 1 1 1" className="alinhamento-centro">
                                        <b>{LABEL_COMUNS.ID}</b>
                                    </Grid>
                                    <Grid cols="2 2 2 2" className="alinhamento-centro">
                                        <b>{LABEL_COMUNS.OPERACAO}</b>
                                    </Grid>
                                    <Grid cols="2 2 2 2" className="alinhamento-direita">
                                        <b>{LABEL_COMUNS.VALOR}</b>
                                    </Grid>
                                    <Grid cols="3 3 3 3" className="alinhamento-centro">
                                        <b>{LABEL_COMUNS.DATA_DE_INCLUSAO}</b>
                                    </Grid>
                                    <Grid cols="3 3 3 3" className="alinhamento-centro">
                                        <b>{LABEL_COMUNS.STATUS}</b>
                                    </Grid>
                                    <Grid cols="1 1 1 1" className="alinhamento-centro">
                                        <b>{LABEL_COMUNS.ACOES}</b>
                                    </Grid>
                                </div>
                                {listaCargaLoteCartao && listaCargaLoteCartao.map((cargaLoteCartao, idx) => {
                                    return (
                                        <div key={idx} className="row">
                                            <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                {cargaLoteCartao.idArquivoSequencialPorPessoaId}
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                {Object.values(OPERACOES_TRANSFERENCIA_EM_LOTE).find(({ value }) => value === cargaLoteCartao.operacao).label}
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                {formatarCampoValor(cargaLoteCartao.tipo, cargaLoteCartao.valorProcessadoLote, cargaLoteCartao.moeda)}
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                {formatarDataBR(cargaLoteCartao.dataCriacao)}
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                {cargaLoteCartao.statusExibicao}
                                            </Grid>
                                            <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                <Dropdown id="menuOpcoesAcoes" pullRight>
                                                    <Dropdown.Toggle noCaret style={{ border: 'none' }}>
                                                        <i className="glyphicon glyphicon-option-horizontal" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        {
                                                            menuOpcoesAcoes.some(({ status }) => status === cargaLoteCartao.status) &&
                                                            menuOpcoesAcoes.find(({ status }) => status === cargaLoteCartao.status).opcoes.map((opcao) => (
                                                                <MenuItem key={opcao.label} onClick={() => opcao.acao(cargaLoteCartao)}>
                                                                    {opcao.label}
                                                                </MenuItem>
                                                            ))
                                                        }
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Grid>
                                        </div>
                                    );
                                })}
                                <If test={this.state.exibirMaisRegistros}>
                                    <button
                                        type="button"
                                        className="btn btn-secondary btn-block fonte-tamanho-20"
                                        onClick={() => this.buscarTransferenciasEmLote(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId, listaCargaLoteCartao)}
                                        style={{ height: '50px' }}>
                                        <b>{LABEL_COMUNS.MOSTRAR_MAIS}</b>
                                    </button>
                                </If>
                            </If>
                            <If test={listaCargaLoteCartao && listaCargaLoteCartao.length <= 0 && !exibirLoading}>
                                <span>{LABEL_COMUNS.NENHUM_AGENDAMENTO_ENCONTRADO}</span>
                            </If>
                        </div>
                    </div>
                </Grid>

                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.PADRAO} onClick={() => {
                        this.props.history.goBack();
                        this.setInitialState();
                    }}>
                        {LABEL_COMUNS.VOLTAR}
                    </Button>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    agendamentoBppCorp: state.AgendamentoReducer,
    outorgaPerfils: {//const PEDIDO_EMBOSSING_CARTAO = 'pedidoEmbossingCartao';
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoEAprovacoesBppCorp);
