import { reactLocalStorage } from 'reactjs-localstorage';
import { environment } from '../configuracoes/environment';
import jwt_decode from 'jwt-decode';
import {
    DESCRICAO_CONTA
} from '../componentes/util/contantes-comuns/constantes-comuns';

const API = environment.urlAPI;
const API_V3 = environment.urlAPI_v3;

const API_MFA = environment.urlAPI_MFA;
const API_REPORT = environment.urlCorePlataform;
const API_WEBSHIM = environment.urlCoreWebShim;
const TOKEN = () => reactLocalStorage.get('token');

export const urlServicos = {
    ANEXO_CONTRATUAL: '/anexoContratual',
    ARQUIVO: '/arquivo',
    ARQUIVO_CONFIRMACAO: arquivoId => `/arquivo/${arquivoId}/confirmacao`,
    ARQUIVO_ID: arquivoId => `/arquivo/${arquivoId}`,
    ARQUIVO_DOCUMENTO_PESSOA: '/pessoa/{pessoaId}/documento/{documentoId}/arquivo',
    ARQUIVOS: '/arquivos',
    ARQUIVOS_CARGA: '/meiospagamento/arquivosCargas',
    ARQUIVOS_CARGAS_DADOSCONTA: '/conta/numeroConta/{numeroConta}',
    ARQUIVOS_CARGA_LOTE_ID: '/meiospagamento/arquivosCargas/{arquivoId}',
    ARQUIVOS_CARGA_USUARIOS: '/meiospagamento/arquivosCargas/usuarios',
    ARQUIVOS_URL: '/arquivo',
    CALENDAR_TED:'/calendar/ted',
    CARTOES: contaId => `/conta/${contaId}/cartao`,
    CARTOES_FAVORITOS_ADD: '/favorite/save',
    CARTOES_FAVORITOS_DELETE: favoriteId => `/favorite/remove/${favoriteId}`,
    CARTOES_FAVORITOS_FIND: contaId => `/conta/${contaId}/favorito`,
    CARTOES_FAVORITOS: contaId => `/conta/${contaId}/favorito`,
    CEPS: cep => `/enderecos/localidades/${cep}`,
    CIDADES: (paisId, estadoId) => `/paises/${paisId}/estados/${estadoId}/cidades`,
    CONTESTACAO_MOTIVO: '/conta/contestacao/motivo',
    COMBO_PEDIDO_IMPRESSO: '/comboImpressao/comboPadrao',
    COMBO_IMPRESSAO: '/comboImpressao',
    COMPROVANTE_HISTORICO: contaId => `/conta/${contaId}/comprovantes`,
    COMPROVANTE_ID: (contaId, operacaoId, comprovanteId) => `/conta/${contaId}/operacao/${operacaoId}/comprovante/${comprovanteId}`,
    COMPROVANTE_PDF: (contaId, operacaoId, comprovanteId) => `/conta/${contaId}/operacao/${operacaoId}/comprovante/${comprovanteId}/pdf`,
    CONSULTA_PEDIDOS: contaId => `/operacao/conta/${contaId}/listaPedido`,
    CONTA: '/conta',
    CONTA_CARTAO_PORTADOR_HISTORICO: (contaId, cartaoId) => `/conta/${contaId}/cartao/${cartaoId}/portador/historico`,
    CONTA_ID: contaId => `/conta/${contaId}`,
    CONTA_PESSOA_ID: pessoaId => `/conta/pessoa/${pessoaId}`,
    CONTESTACAO: '/conta/{contaDigitalId}/cartao/{cartaoId}/contestacao',
    DOCUMENTO_PESSOA: '/pessoa/{pessoaId}/documento',
    ENDERECOS: '/pessoa/{pessoaId}/endereco',
    ENDERECO_ID: '/pessoas/{pessoaId}/endereco/{enderecoId}',
    ESTADOS: paisId => `/paises/${paisId}/estados`,
    EMAIL_COMPROVANTE: '/conta/{contaDigitalId}/comprovante/email',
    EXTRATO_CONTA: '/conta/{contaDigitalId}/extrato',
    EXTRATO: contaId => `/conta/${contaId}/extrato`,
    FAVORECIDO: contaId => `/conta/${contaId}/favorecido`,
    FAVORECIDO_ID: (contaId, favorecidoId) => `/conta/${contaId}/favorecido/${favorecidoId}`,
    GATEWAYAPI_TOKEN: 'bpp.com.br/gatewayapi/oauth2/token',
    GET_CONTA_ID: contaId => `/conta/${contaId}`,
    GET_CONTA_FILHA: '/conta/{contaId}/contaFilha',
    GET_CARTAO: '/conta/cartao',
    IMAGEM_PERSONALIZADA: '/pedido/personalizacao',
    IMAGEM_PERSONALIZADA_ID: '/pedido/personalizacao/{pedidoId}',
    IMAGEM_PERSONALIZADA_STATUS: '/pedido/status?tipoPedido=personalizacao',
    LISTA_BANCOS: '/conta/tipoBanco',
    LISTA_FORMA_ENTREGA: '/negociacao/formaEntrega',
    LISTA_OPERACOES: '/negociacao/tipoOperacao',
    LOTE: '/lote',
    LOTE_ID: loteId => `/lote/${loteId}`,
    LOTE_ARQUIVO: '/lote/arquivo',
    LOTE_ARQUIVO_RETORNO: '/bppwebshim/cnab240',
    LOTE_ARQUIVO_ID: arquivoId => `/lote/arquivo/${arquivoId}`,
    NOVA_CONTA_POST: '/conta/anexoContratual/{anexoId}/conta',
    OPERACAO: '/operacao',
    OPERACAO_ID: operacaoId => `/operacao/${operacaoId}`,
    OPERACOES_TRANSACOES: operacaoId => `/operacao/${operacaoId}/transacao`,
    OUTORGA_CONTA_ID_PESSOA_PESSOA_ID: (contaId, pessoaId) => `/conta/${contaId}/outorga/pessoa/${pessoaId}`,
    OUTORGAS_CONTA_ID: contaId => `/conta/${contaId}/outorga/pessoa`,
    OUTORGAS: '/outorgas',
    PAISES: '/paises',
    PARAMETRO_CONTA: '/conta/{contaDigitalId}/parametro', // TODO remover assim que possivel. Usado na antiga tela de parametros
    PARAMETRO_OPERACAO: '/parametro/parametroOperacao',
    PEDIDOS: '/pedido',
    PEDIDO_CARTAO_IMPRESSO: '/pedido/cartao-impresso',
    PEDIDO_ESTOQUE_CARTAO: '/pedido/estoque-cartao',
    PESSOA: '/pessoa',
    PESSOA_ID: pessoaId => `/pessoa/${pessoaId}`,
    PESSOAS_DOCUMENTOS_ARQUIVOS_ID: '/pessoa/{pessoaId}/documento/{documentoId}/arquivo/{arquivoId}',
    PRESTACAO_CONTA: pessoaId => `/pessoa/${pessoaId}/prestacaoConta`,
    PRESTACAO_CONTA_ID: (pessoaId, prestacaoContaId) => `/pessoa/${pessoaId}/prestacaoConta/${prestacaoContaId}`,
    PRESTACAO_CONTA_PROJETO: pessoaId => `/pessoa/${pessoaId}/prestacaoConta/projeto`,
    PRESTACAO_CONTA_CLASSIFICACAO: pessoaId => `/pessoa/${pessoaId}/prestacaoConta/classificacao`,
    PRODUTO_PUBLICADO_PEDIDO_CARTAO_PESSOA_FISICA: produtoPublicadoId => `/negociacao/produtoPublicado/${produtoPublicadoId}`,
    RECUPERA_PARAMETROS: nome => `/parametro/${nome}`,
    REPORT_CARD_ACCOUNTABILITY: '/reports/cards/accountability',
    REPORT_CARD_ANALYTIC_TRANSACTIONS: '/reports/cards/analytic',
    REPORT_CARD_BALANCE: '/reports/cards/balance',
    REPORT_CARD_MANAGEMENT: '/reports/cards/management',
    REPORT_CARD_STATUS: '/reports/cards/status',
    SECURITY_USERS: '/fenix-seguranca/usuarios',
    SECURITY_CRETATE_BEARER: '/fenix-seguranca/usuarios/portador',
    SOLICITAR_TOKEN_OPERACAO: operacaoId => `/operacao/${operacaoId}/otp`,
    SOLICITAR_TOKEN_OTP: '/tokens/otp',
    REPRESENTANTES_PESSOA: '/pessoa/{pessoaId}/representante',
    SALDO_CARTAO: contaId => `/conta/${contaId}/saldo`,
    SALDO_CONTA: contaId => `/conta/${contaId}/saldo`,
    TIPO_BANCO_CONTA_TED: '/conta/tipoBanco/contaTed',
    TOKEN: '/oauth/token/',
    CRIACAO_MFA: '/mfa/persist',
    VALIDACAO_MFA: '/mfa/validate',
    TRANSFERENCIA_TITULARIDADE_CARTAO: contaId => `/conta/${contaId}/transferenciaTitularidadeConta`,
    TRANSFERENCIA_TITULARIDADE_CARTAO_TOKEN: contaId => `/conta/${contaId}/transferenciaTitularidadeConta/token`,
    ULTIMOS_LANCAMENTOS_CARTAO: '/operacoes/{contaId}/ultimos-lancamentos',
    USUARIO_ADMIN: pessoaId => `/pessoa/${pessoaId}/usuarioAdmin`,
    VALIDAR_TRANSFERENCIA_ENTRE_CONTAS: '/conta/validaTransferenciaEntreContas',
    VINCLULAR_CARTAO: contaId => `/conta/${contaId}/vincularCartao`,
    VINCULAR_PORTADOR: '/conta/cartao/vincularPortador',
    WHITE_LIST_APP: '/parametro/whiteListApp',
    WHITE_LIST_APP_FLAG: '/parametro/whiteListAppFlag',
};

export const HEADER_API = () => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: TOKEN(),
        parceiro: 'BPP',
        'Keep-Alive': 'timeout=60, max=100'
    // 'idioma': 'es_ES'
    }
});

export const HEADER_API_MFA = () => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Keep-Alive': 'timeout=60, max=100'
    }
});

export const HEADER_API_SEM_PARCEIRO = () => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: TOKEN()
    // 'idioma': 'es_ES'
    }
});

export const HEADER_WITHOUT_AUTORIZATION = () => ({
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export const HEADER_PDF = () => ({
    'Content-Type': 'application/pdf',
    Accept: 'application/pdf',
    Authorization: TOKEN(),
    parceiro: 'BPP'
});

export const HEADER_UPLOAD_FILE = {
    headers: {
        'Content-type': 'application/octet-stream'
    }
};

export const CONTENT_TYPE_OCTET_STREAM = {
    contentType: 'application/octet-stream'
};

export const HTTP_METODOS = {
    DELETE: 'DELETE',
    GET: 'GET',
    PATCH: 'PATCH',
    POST: 'POST',
    PUT: 'PUT'
};

export function URL_TOKEN() {
    let url = urlServicos.TOKEN;
    return API + url;
}

export function URL_PESSOA_ID() {
    verificarValidadeToken();
    let url =
    urlServicos.PESSOA +
    '?numeroDocumento=' +
    reactLocalStorage.get('usuario') +
    '&tipoPessoa=PF&ordem=ASC&parametroOrdenacao=DATACRIACAO';
    return API + url;
}

export function URL_PESSOA_ID_TIPO_PESSOA() {
    verificarValidadeToken();
    let url =
    urlServicos.PESSOA +
    '?numeroDocumento=' +
    reactLocalStorage.get('usuario') +
    '&tipoPessoa=PF&ordem=ASC&parametroOrdenacao=DATACRIACAO';
    return API + url;
}

export function URL_POST_PESSOA() {
    verificarValidadeToken();
    let url = urlServicos.PESSOA;
    return API + url;
}

export function URL_GET_USUARIO_ADMIN() {
    verificarValidadeToken();
    const pessoaId = reactLocalStorage.get('pessoaId');
    let url = urlServicos.USUARIO_ADMIN(pessoaId);
    return API + url;
}

export function URL_GET_CARTAO(cartaoId) {
    verificarValidadeToken();
    let url = urlServicos.GET_CARTAO + '?cartaoId=' + cartaoId;
    return API + url;
}

export function URL_GET_CONTA(contaId) {
    verificarValidadeToken();
    let url = urlServicos.GET_CONTA_ID(contaId);
    return API + url;
}

export function URL_GET_CONTAS(
    campoPesquisa,
    pessoaId,
    pessoaTitularId,
    posicao
) {
    verificarValidadeToken();
    let tamanho = 1000;
    let url =
    urlServicos.CONTA_PESSOA_ID(pessoaId) +
    '?pessoaTitularId=' +
    pessoaTitularId +
    '&tamanhoPagina=' +
    tamanho +
    '&exibeSaldo=' +
    true +
    '&contaDescricao=' +
    DESCRICAO_CONTA.CONTA_MOVIMENTO +
    '&status=ativo';
    if (posicao) {
        url = url + '&posicao=' + posicao;
    }

    if (campoPesquisa) {
        if (campoPesquisa.length > 0) {
            url = url + '&pesquisa=' + campoPesquisa;
        }
    }

    return API + url;
}

export function URL_CONTA_ID(contaId, checarPedidos) {
    verificarValidadeToken();
    let url = urlServicos.CONTA_ID(contaId);
    if (checarPedidos) {
        url = url + '?checarPedidos=true';
    }
    return API + url;
}

export function URL_POST_CONTA(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.CONTA_PESSOA_ID(pessoaId);
    return API + url;
}

export function URL_GET_SALDO_CONTA(contaId) {
    verificarValidadeToken();
    let url = urlServicos.SALDO_CONTA(contaId);
    return API + url;
}

export function URL_GET_REENVIO_TOKEN(operacaoId) {
    verificarValidadeToken();
    let url = urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId);
    return API + url;
}
/* ?canalToken=SMS */
export function URL_PARAMETRO_CONTA(contaDigitalId) {
    verificarValidadeToken();
    let url = urlServicos.PARAMETRO_CONTA.replace(
        '{contaDigitalId}',
        contaDigitalId
    );
    return API + url;
}

export function URL_COMPROVANTE_VIA_EMAIL(contaDigitalId) {
    verificarValidadeToken();
    let url = urlServicos.EMAIL_COMPROVANTE.replace(
        '{contaDigitalId}',
        contaDigitalId
    );
    return API + url;
}

export function URL_EXTRATO_CONTA(
    contaId,
    dataInicial,
    dataFinal,
    ultimaChave,
    tamanhoPagina,
    ordem
) {
    verificarValidadeToken();
    let url =
    urlServicos.EXTRATO(contaId) +
    '?dataInicial=' +
    dataInicial +
    '&dataFinal=' +
    dataFinal;

    if (ultimaChave) {
        url = url + '&ultimaChave=' + ultimaChave;
    }
    if (tamanhoPagina) {
        url = url + '&tamanhoPagina=' + tamanhoPagina;
    }
    if (ordem) {
        url = url + '&ordem=' + ordem;
    } else {
        url = url + '&ordem=ASC';
    }
    return API + url;
}

export function URL_GET_CARTOES(contaId, queryString, posicao) {
    verificarValidadeToken();
    let tamanho = 2000; // tamnho padrão para a tela de listagem de contas

    let url = urlServicos.CARTOES(contaId) + '?tamanhoPagina=' + tamanho;
    if (posicao) {
        url = url + '&posicao=' + posicao;
    }

    if (queryString) {
        url = url + queryString;
    }
    return API + url;
}

export function URL_GET_CARTOES_PAGINADO(contaId, posicao, quantidadeCartoes) {
    verificarValidadeToken();
    let tamanho = 100;
    if (quantidadeCartoes) {
        tamanho = quantidadeCartoes;
    }
    posicao = posicao * tamanho;

    let url = urlServicos.CARTOES(contaId) + '?tamanhoPagina=' + tamanho;
    if (posicao) {
        url = url + '&posicao=' + posicao;
    }

    return API + url;
}

export function URL_ARQUIVOS_CARGAS_USUARIOS() {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS_CARGA_USUARIOS;
    return API + url;
}

export function URL_GET_CARTOES_INATIVOS(contaId, queryString, posicao) {
    verificarValidadeToken();
    let tamanho = 2000; // tamnho padrão para a tela de listagem de contas

    let url =
    urlServicos.CARTOES(contaId) +
    '?tamanhoPagina=' +
    tamanho +
    '&statusContaCartao=inativo';

    if (posicao) {
        url = url + '&posicao=' + posicao;
    }

    if (queryString) {
        url = url + queryString;
    }

    return API + url;
}

export function URL_ARQUIVOS_CARGAS(
    nomeArquivo,
    dataInicio,
    dataFim,
    usuario,
    tipoCargaLote,
    contaId
) {
    verificarValidadeToken();
    let tamanho = 10; // tamnho padrão para a tela de consulta carga lote
    if (reactLocalStorage.get('tamanhoPaginaConsultaCargaLote') !== undefined) {
        tamanho = reactLocalStorage.get('tamanhoPaginaConsultaCargaLote');
    } else {
        reactLocalStorage.set('tamanhoPaginaConsultaCargaLote', tamanho);
    }
    let url =
    urlServicos.ARQUIVOS_CARGA +
    '?' +
    'nomeArquivo=' +
    nomeArquivo +
    '&tipoArquivoDeCarga=' +
    tipoCargaLote +
    '&contaId=' +
    contaId +
    '&dataInicio=' +
    dataInicio +
    '&dataFim=' +
    dataFim +
    '&usuario=' +
    usuario +
    '&size=' +
    tamanho;

    return API + url;
}

export function URL_ARQUIVOS_CARGAS_CSV(
    nomeArquivo,
    dataInicio,
    dataFim,
    usuario,
    tipoCargaLote,
    contaId
) {
    verificarValidadeToken();
    let tamanho = 10000;
    let url =
    urlServicos.ARQUIVOS_CARGA +
    '?' +
    'nomeArquivo=' +
    nomeArquivo +
    '&tipoArquivoDeCarga=' +
    tipoCargaLote +
    '&contaId=' +
    contaId +
    '&dataInicio=' +
    dataInicio +
    '&dataFim=' +
    dataFim +
    '&usuario=' +
    usuario +
    '&size=' +
    tamanho;

    return API + url;
}

export function URL_LISTA_BANCOS() {
    verificarValidadeToken();
    let url = urlServicos.LISTA_BANCOS;
    return API + url;
}

export function URL_FAVORECIDO(contaId, tipoOperacao, banco) {
    verificarValidadeToken();
    let tamanho = 1000;
    let url = urlServicos.FAVORECIDO(contaId) + '?tamanhoPagina=' + tamanho;
    if (tipoOperacao) {
        url = url + '&tipoOperacao=' + tipoOperacao;
    }
    if (banco) {
        url = url + '&banco=' + banco;
    }
    return API + url;
}

export function URL_ARQUIVOS() {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS;
    return API + url;
}

export function URL_ARQUIVOS_CARGA_LOTE_ID(arquivoId) {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS_CARGA_LOTE_ID.replace(
        '{arquivoId}',
        arquivoId
    );
    return API + url;
}

export function URL_ARQUIVOS_CARGAS_POST() {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS_CARGA;
    return API + url;
}

export function URL_ARQUIVOS_CARGAS_DADOSCONTA(numeroConta) {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS_CARGAS_DADOSCONTA.replace(
        '{numeroConta}',
        numeroConta
    );
    return API + url;
}
export function URL_GET_PESSOA_ID(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PESSOA_ID(pessoaId);
    return API + url;
}

export function URL_GET_REPRESENTANTES_PESSOA(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.REPRESENTANTES_PESSOA.replace('{pessoaId}', pessoaId);
    return API + url;
}

export function URL_GET_ENDERECOS(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.ENDERECOS.replace('{pessoaId}', pessoaId);
    return API + url;
}

export function URL_GET_CEP(cep) {
    verificarValidadeToken();
    let url = urlServicos.CEPS(cep);
    return API + url;
}

export function URL_GET_PAISES() {
    verificarValidadeToken();
    let url = urlServicos.PAISES;
    return API + url;
}

export function URL_GET_ESTADOS(paisId) {
    verificarValidadeToken();
    let url = urlServicos.ESTADOS(paisId);
    return API + url;
}

export function URL_GET_CIDADES(paisId, estadoId) {
    verificarValidadeToken();
    let url = urlServicos.CIDADES(paisId, estadoId);
    return API + url;
}

export function URL_GET_IMAGEM_PERSONALIZADA(
    contaDigitalId,
    dataInicial,
    dataFinal,
    tipoImagem,
    statusImagem,
    nomeImagem,
    statusAprovacao
) {
    verificarValidadeToken();
    let tamanho = 3;
    if (
        reactLocalStorage.get('tamanhoPaginaConsultaImagensPersonalizadas') !==
    undefined
    ) {
        tamanho = reactLocalStorage.get(
            'tamanhoPaginaConsultaImagensPersonalizadas'
        );
    } else {
        reactLocalStorage.set(
            'tamanhoPaginaConsultaImagensPersonalizadas',
            tamanho
        );
    }
    let url =
    urlServicos.IMAGEM_PERSONALIZADA +
    '?contaDigitalId=' +
    reactLocalStorage.getObject('contaSelecionada').id;
    if (dataInicial) {
        url = url + '&dataInicial=' + dataInicial;
    }
    if (dataFinal) {
        url = url + '&dataFinal=' + dataFinal;
    }
    if (tipoImagem) {
        url = url + '&tipo=' + tipoImagem;
    }
    if (statusImagem) {
        url = url + '&status=' + statusImagem;
    }
    if (nomeImagem) {
        url = url + '&nome=' + nomeImagem;
    }
    if (statusAprovacao) {
        url = url + '&statusAprovacao=' + statusAprovacao;
    }
    url = url + '&size=' + tamanho;
    return API + url;
}

export function URL_IMAGEM_PERSONALIZADA_ID(pedidoId) {
    verificarValidadeToken();
    let url = urlServicos.IMAGEM_PERSONALIZADA_ID.replace(
        '{pedidoId}',
        pedidoId
    );
    return API + url;
}

export function URL_GET_IMAGEM_PERSONALIZADA_STATUS() {
    verificarValidadeToken();
    let url = urlServicos.IMAGEM_PERSONALIZADA_STATUS;
    return API + url;
}

export function URL_EXTRATO(contaId) {
    verificarValidadeToken();
    let url = urlServicos.EXTRATO(contaId);
    return API + url;
}

export function URL_PUT_PESSOA_ID(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PESSOA_ID(pessoaId);
    return API + url;
}

export function URL_GET_PESSOA_DOCUMENTO(documento) {
    verificarValidadeToken();
    let url =
    urlServicos.PESSOA +
    '?numeroDocumento=' +
    documento +
    '&ordem=ASC&parametroOrdenacao=DATACRIACAO';
    return API + url;
}

export function URL_PESSOA_DOCUMENTO_TIPO_PESSOA(documento) {
    verificarValidadeToken();
    let url =
    urlServicos.PESSOA +
    '?numeroDocumento=' +
    documento +
    '&tipoPessoa=PF&ordem=ASC&parametroOrdenacao=DATACRIACAO';
    return API + url;
}

export function URL_PUT_ENDERECO_ID(pessoaId, enderecoId) {
    verificarValidadeToken();
    let url = urlServicos.ENDERECO_ID
        .replace('{pessoaId}', pessoaId)
        .replace('{enderecoId}', enderecoId);
    return API + url;
}

export function URL_CONTESTACAO_MOTIVO() {
    verificarValidadeToken();
    let url = urlServicos.CONTESTACAO_MOTIVO;
    return API + url;
}

export function POST_URL_PRE_ASSINADA() {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVOS_URL;
    return API + url;
}

export function POST_CONTESTACAO() {
    verificarValidadeToken();
    let url = urlServicos.CONTESTACAO;
    url = url.replace(
        '{contaDigitalId}',
        reactLocalStorage.getObject('contaSelecionada').id
    );
    url = url.replace(
        '{cartaoId}',
        reactLocalStorage.getObject('cartaoSelecionado').id
    );
    return API + url;
}

export function URL_POST_ARQUIVO_DOCUMENTO_PESSOA(pessoaId, documentoId) {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVO_DOCUMENTO_PESSOA
        .replace('{pessoaId}', pessoaId)
        .replace('{documentoId}', documentoId);
    return API + url;
}

export function URL_GET_DOCUMENTO_PESSOA(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.DOCUMENTO_PESSOA.replace('{pessoaId}', pessoaId);
    return API + url;
}

export function URL_DELETE_ARQUIVO_DOCUMENTO_PESSOA(
    pessoaId,
    documentoId,
    arquivoId
) {
    verificarValidadeToken();
    let url = urlServicos.PESSOAS_DOCUMENTOS_ARQUIVOS_ID
        .replace('{pessoaId}', pessoaId)
        .replace('{documentoId}', documentoId)
        .replace('{arquivoId}', arquivoId);
    return API + url;
}

export function URL_POST_PEDIDO_ESTOQUE_CARTAO(contaDigitalId) {
    verificarValidadeToken();
    let url = urlServicos.PEDIDO_ESTOQUE_CARTAO;
    return API + url;
}

export function URL_POST_PEDIDO_CARTAO_IMPRESSO() {
    verificarValidadeToken();
    return API + urlServicos.PEDIDO_CARTAO_IMPRESSO;
}

export function URL_GET_PEDIDOS(queryString) {
    verificarValidadeToken();
    let url =
    urlServicos.PEDIDOS +
    '?contaDigitalId=' +
    reactLocalStorage.getObject('contaSelecionada').id;
    url = url + queryString;
    return API + url;
}

export function URL_POST_OPERACOES() {
    verificarValidadeToken();
    let url = urlServicos.OPERACAO;
    return API + url;
}

export function URL_GET_CONSULTA_PEDIDOS(contaId, queryString) {
    verificarValidadeToken();
    let url = urlServicos.CONSULTA_PEDIDOS(contaId) + queryString;
    return API + url;
}

export function URL_GET_OPERACOES_TRANSACOES(operacaoId) {
    verificarValidadeToken();
    let url = urlServicos.OPERACOES_TRANSACOES(operacaoId);
    return API + url;
}

export function URL_GET_SALDO_CARTAO(contaId) {
    verificarValidadeToken();
    let url = urlServicos.SALDO_CARTAO(contaId);
    return API + url;
}

export function URL_ANEXO_PESSOA(pessoaTitularId, produtoId, contaDescricao) {
    verificarValidadeToken();
    let url =
    urlServicos.ANEXO_CONTRATUAL +
    '?pessoaTitularId=' +
    pessoaTitularId +
    '&status=ativo' +
    '&tamanhoPagina=' +
    100;
    if (contaDescricao) {
        url = url + '&contaDescricao=' + contaDescricao;
    }
    if (produtoId) {
        url = url + '&produtoId=' + produtoId;
    }
    return API + url;
}

export function URL_ANEXO_POR_ID(anexoId) {
    verificarValidadeToken();
    let url = urlServicos.ANEXO_CONTRATUAL + '?anexoContratualId=' + anexoId;
    return API + url;
}

export function URL_GET_TIPO_BANCO(codigoBanco) {
    verificarValidadeToken();
    let url = urlServicos.TIPO_BANCO_CONTA_TED + '?codigoBanco=' + codigoBanco;
    return API + url;
}

export function URL_NOVA_CONTA_POST(anexoId) {
    verificarValidadeToken();
    let url = urlServicos.NOVA_CONTA_POST.replace('{anexoId}', anexoId);
    return API + url;
}

export function URL_GET_ULTIMOS_LANCAMENTOS_EXTRATO_CARTAO(
    contaId,
    dataInicial,
    dataFinal,
    ultimaChave
) {
    verificarValidadeToken();
    let conta = reactLocalStorage.getObject('contaSelecionada').id;
    let url =
    urlServicos.EXTRATO(contaId) +
    '?dataInicial=' +
    dataInicial +
    '&dataFinal=' +
    dataFinal;
    if (ultimaChave) {
        url = url + '&ultimaChave=' + ultimaChave;
    }
    url = url + '&ordem=DESC';
    return API + url;
}

export function ANEXO_CONTRATUAL_POST() {
    verificarValidadeToken();
    let url = urlServicos.ANEXO_CONTRATUAL;
    return API + url;
}

export function verificarValidadeToken() {
    var token = reactLocalStorage.get('token');
    var decoded = jwt_decode(token);
    let timeStampToken = decoded.exp;
    let timeStampAtual = Date.now().toString();
    timeStampAtual = timeStampAtual.substring(0, 10);
    timeStampAtual = parseInt(timeStampAtual);
    if (timeStampAtual >= timeStampToken) {
        window.location.href = '/sair';
    }
}

export function URL_ARQUIVO() {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVO;
    return API + url;
}

export function URL_POST_PRESTACAO_CONTA(pessoaTitularId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA(pessoaTitularId);
    return API + url;
}

export function URL_GET_PRESTACAO_CONTA(pessoaTitularId, filtro) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA(pessoaTitularId);
    return API + url + filtro;
}

export function URL_GET_PRESTACAO_CONTA_EXTRATOID(contaRecuperada, filtro) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA(contaRecuperada.pessoaTitularId);
    return API + url + filtro;
}

export function URL_PUT_PRESTACAO_CONTA(pessoaTitularId, prestacaoContaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_ID(pessoaTitularId, prestacaoContaId);
    return API + url;
}

export function URL_PRESTACAO_CONTA_ID(pessoaTitularId, prestacaoContaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_ID(pessoaTitularId, prestacaoContaId);
    return API + url;
}

export function URL_GET_PRESTACAO_CONTA_PROJETO(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_PROJETO(pessoaId) + '?status=ativo';
    return API + url;
}

export function URL_POST_PRESTACAO_CONTA_PROJETO(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_PROJETO(pessoaId);
    return API + url;
}

export function URL_PATCH_PRESTACAO_CONTA_PROJETO(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_PROJETO(pessoaId);
    return API + url;
}

export function URL_GET_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId) {
    verificarValidadeToken();
    let url =
    urlServicos.PRESTACAO_CONTA_CLASSIFICACAO(pessoaId) + '?status=ativo';
    return API + url;
}

export function URL_POST_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_CLASSIFICACAO(pessoaId);
    return API + url;
}

export function URL_PATCH_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId) {
    verificarValidadeToken();
    let url = urlServicos.PRESTACAO_CONTA_CLASSIFICACAO(pessoaId);
    return API + url;
}

export function URL_POST_VINCULAR_PORTADOR() {
    verificarValidadeToken();
    let url = urlServicos.VINCULAR_PORTADOR;
    return API + url;
}

export function URL_GET_FORMA_ENTREGA() {
    verificarValidadeToken();
    let url = urlServicos.LISTA_FORMA_ENTREGA;
    return API + url;
}

export function URL_GET_COMBO_PEDIDO_IMPRESSO() {
    verificarValidadeToken();
    let url = urlServicos.COMBO_PEDIDO_IMPRESSO;
    return API + url;
}

export function URL_ANEXO_CONTRATUAL(queryStringParams) {
    verificarValidadeToken();
    let url = urlServicos.ANEXO_CONTRATUAL;
    if (queryStringParams) {
        url = url + queryStringParams;
    }
    return API + url;
}

export function URL_POST_VALIDA_TRANSFERENCIA_CONTAS() {
    verificarValidadeToken();
    let url = urlServicos.VALIDAR_TRANSFERENCIA_ENTRE_CONTAS;
    return API + url;
}

export function URL_GET_CONTAS_NUMERO_DIGITO(parametros) {
    verificarValidadeToken();
    let url = '';
    if (
        parametros !== undefined &&
    parametros.numero !== undefined &&
    parametros.digito !== undefined
    ) {
        url =
      urlServicos.CONTA +
      '?numero=' +
      parametros.numero +
      '&digito=' +
      parametros.digito;
    }
    return API + url;
}

export function URL_GET_LANCAMENTO_PEDIDOS(produto) {
    verificarValidadeToken();
    let url = urlServicos.RECUPERA_PARAMETROS('listaExibicaoPedido');
    return API + url + '?produto=' + produto;
}

export function URL_POST_OPERACOES_TRANSACOES(operacaoId) {
    verificarValidadeToken();
    let url = urlServicos.OPERACOES_TRANSACOES(operacaoId);
    return API + url;
}

export function URL_GET_PARAMETRO_WHITE_LIST_APP() {
    let url = urlServicos.WHITE_LIST_APP;
    return API + url;
}

export function URL_GET_PARAMETRO_WHITE_LIST_APP_FLAG() {
    let url = urlServicos.WHITE_LIST_APP_FLAG;
    return API + url;
}

export function URL_GET_PARAMETRO_PARAMETRO_OPERACAO() {
    let url = urlServicos.PARAMETRO_OPERACAO;
    return API + url;
}

export function montaUrlFetch(urlRecebida, parametros) {
    verificarValidadeToken();
    if (parametros) {
        let url = new URL(montaUrlFetchSemParametro(urlRecebida));
        let params = parametros;
        Object.keys(params).forEach(key => {
            params[key] !== undefined &&
        params[key] !== null &&
        url.searchParams.append(key, params[key]);
        });
        return url;
    }

    return montaUrlFetchSemParametro(urlRecebida);
}

export function montaUrlFetchReport(urlRecebida) {
    verificarValidadeToken();
    return API_REPORT + urlRecebida;
}

export function montaUrlFetchWebShim(urlRecebida, parametros) {
    verificarValidadeToken();
    let url = new URL(montarUrlFetchWebShimSemParametro(urlRecebida));
    if (parametros) {
        let params = parametros;
        Object.keys(params).forEach(key => {
            params[key] !== undefined &&
        params[key] !== null &&
        url.searchParams.append(key, params[key]);
        });
    }
    return url;
}

export const montarUrlFetchWebShimSemParametro = urlRecebida => {
    return API_WEBSHIM + urlRecebida;
};

export function montaUrlFetchSemParametro(urlRecebida) {
    return API + urlRecebida;
}

export function montaUrlMFAFetchSemParametro(urlRecebida) {
    return API_MFA + urlRecebida;
}

export function montaUrlPathParam(urlRecebida, contaId) {
    urlRecebida = urlRecebida.replace('{contaId}', contaId);
    return urlRecebida;
}

export function montaUrlPathParamOperacaoAprovacao(urlRecebida, contaId) {
    urlRecebida = urlRecebida.replace('{operacaoId}', contaId);
    return urlRecebida;
}

export function headerApiComParametros(paramsChamadaApi) {
    return {
        params: paramsChamadaApi,
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: reactLocalStorage.get('token'),
            parceiro: 'BPP'
        }
    };
}

export function URL_ARQUIVO_ID(arquivoId) {
    verificarValidadeToken();
    let url = urlServicos.ARQUIVO_ID(arquivoId);
    return API + url;
}

export function URL_GET_CONTA_CARTAO_PORTADOR_HISTORICO(contaId, cartaoId) {
    verificarValidadeToken();
    let url = urlServicos.CONTA_CARTAO_PORTADOR_HISTORICO(contaId, cartaoId);
    return API + url;
}
