import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos, URL_ARQUIVOS_CARGA_LOTE_ID } from '../url-servicos';
import Axios from 'axios';
const executaHttpRequest = (url, httpMetodo, params, corpo) => {
    const request = {
        method: httpMetodo,
        headers: HEADER_API().headers
    };

    if (corpo) {
        request.body = corpo;
    }

    return fetch(montaUrlFetch(url, params), request)
        .then(async (resp) => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetLoteArquivo = (params) => {
    return fetchLoteArquivo(HTTP_METODOS.GET, params);
};

export const fetchGetLoteArquivoId = (arquivoId, params) => {
    return fetchLoteArquivoPorArquivoId(HTTP_METODOS.GET, params, null, arquivoId);
};
export const fetchGetLoteRetorno = (params) => {
    return fetchLoteRetorno(HTTP_METODOS.GET, params, null);
};

export const fetchPostLoteArquivo = (corpoDaRequisicao) => {
    return fetchLoteArquivo(HTTP_METODOS.POST, null, corpoDaRequisicao);
};

export const fetchPutLoteArquivo = (arquivoId, corpoDaRequisicao) => {
    return fetchLoteArquivoPorArquivoId(HTTP_METODOS.PUT, null, corpoDaRequisicao, arquivoId);
};

export const fetchPatchLoteArquivo = (body) => (
    fetchLoteArquivo(HTTP_METODOS.PATCH, null, body)
);

export const fetchGetLotes = (params) => (
    fetchLotes(HTTP_METODOS.GET, params)
);

const fetchLotes = (httpMetodo, params, corpo, arquivoId) => (
    executaHttpRequest(urlServicos.LOTE, httpMetodo, params, corpo, arquivoId)
);

const fetchLoteArquivoPorArquivoId = (httpMetodo, params, corpo, arquivoId) => (
    executaHttpRequest(urlServicos.LOTE_ARQUIVO_ID(arquivoId), httpMetodo, params, corpo)
);

const fetchLoteArquivo = (httpMetodo, params, corpo) => (
    executaHttpRequest(urlServicos.LOTE_ARQUIVO, httpMetodo, params, corpo)
);

const fetchLoteRetorno = (httpMetodo, params, corpo) => (
    executaHttpRequest(urlServicos.LOTE_ARQUIVO_RETORNO, httpMetodo, params, corpo)
);

export const fetchGetItensDoLote = (loteId, params) => {
    return executaHttpRequest(urlServicos.LOTE_ID(loteId), HTTP_METODOS.GET, params);
};

export function getHistoricoLote(arquivoId, pessoaId, contaId) {
    return fetch(montaUrlFetch(urlServicos.LOTE, { 'arquivoId': arquivoId, 'pessoaId': pessoaId, 'contaId': contaId, tamanhoPagina: '10000' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async response => {
        return response.json();
    }).catch(erro => {
        return 'error';
    });
}

export async function getLoteByIdArquivo(idArquivo) {
    var resposta = Axios.get(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), HEADER_API()).catch((error) => { console.log(error); return 'error'; });
    return resposta;
}
