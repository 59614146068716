export default class ValidaCNPJ {
    BLACKLIST = [
        '00000000000000',
        '11111111111111',
        '22222222222222',
        '33333333333333',
        '44444444444444',
        '55555555555555',
        '66666666666666',
        '77777777777777',
        '88888888888888',
        '99999999999999'
    ];

    isValid(documento) {
        const DIGITO_PRIMARIO = 13;
        const DIGITO_SECUNDARIO = 14;

        var numero = documento.replace(/[^\d]/g, '');

        if (numero.length !== 14 || this.BLACKLIST.indexOf(numero) !== -1) {
            return false;
        }

        return this.isDigitValid(numero, DIGITO_PRIMARIO) && this.isDigitValid(numero, DIGITO_SECUNDARIO);
    }

    isDigitValid(numero, digito) {
        digito--;

        var soma = 0;
        var pos = digito - 7;

        for (var i = digito; i >= 1; i--) {
            soma += parseInt(numero.charAt(digito - i)) * pos--;

            if (pos < 2) {
                pos = 9;
            }
        }

        var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== parseInt(numero.charAt(digito))) {
            return false;
        }

        return true;
    }

    static validarCNPJ(cnpj) {

        cnpj = cnpj.replace(/[^\d]+/g, '');
        if (cnpj == '') return false;
        if (this.BLACKLIST.indexOf(cnpj) !== -1) return false;
        if (cnpj.length !== 14) return false;

        let tamanho = cnpj.length - 2;
        let numeros = cnpj.substring(0, tamanho);
        let digitos = cnpj.substring(tamanho);
        let soma = 0;
        let pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }

        let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(0))
            return false;

        tamanho = tamanho + 1;
        numeros = cnpj.substring(0, tamanho);
        soma = 0;
        pos = tamanho - 7;
        for (let i = tamanho; i >= 1; i--) {
            soma += numeros.charAt(tamanho - i) * pos--;
            if (pos < 2)
                pos = 9;
        }
        resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
        if (resultado !== digitos.charAt(1))
            return false;

        return true;
    }

}