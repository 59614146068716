export const METODOS = {
    QUANTIDADE_CARTOES_HANDLE_CHANGE: 'quantidadeCartoesHandleChange',
    IMAGEM_CARTAO_HANDLE_CHANGE: 'imagemCartaoHandLeChange',
    IMAGEM_CARTA_BERCO_HANDLE_CHANGE: 'imagemCartaBercoHandLeChange',
    LISTAR_IMAGENS_CARTAO_OK: 'listarImagensCartaoOk',
    LISTAR_IMAGENS_CARTAO_ERRO: 'listarImagensCartaoErro',
    LISTAR_IMAGENS_CARTA_BERCO_OK: 'listarImagensCartaBercoOk',
    LISTAR_IMAGENS_CARTA_BERCO_ERRO: 'listarImagensCartaBercoErro',
    EXIBE_CARTAO_SELECIONADO_HANDLE_CHANGE: 'exibeCartaoSelecionadoHadleChange',
    EXIBE_CARTA_BERCO_SELECIONADA_HANDLE_CHANGE: 'exibeCartaBercoSelecionadaHandleChange',
    EXIBE_MODAL_FINALIZAR_HANDLE_CHANGE: 'exibeModalFinalizarHandleChange',
    EXIBE_MODAL_CONFIRMACAO_CANCELAR_HANDLE_CHANGE: 'exibeModalConfirmacaoCancelarHandleChange',
    EXIBE_LOADING_HANDLE_CHANGE: 'exibeLoadingHandleChange',
    SALVAR_PEDIDO_STATUS_OK: 'SALVAR_PEDIDO_STATUS_OK',
    SALVAR_PEDIDO_STATUS_ERRO: 'SALVAR_PEDIDO_STATUS_ERRO'
};

export const LABELS = {
    QUANTIDADE_CARTOES: 'Quantidade de Cartões',
    IMAGEM_CARTAO: 'Imagem Cartão',
    IMAGEM_CARTA_BERCO: 'Imagem Carta Berço',
    INFORMACOES_GERAIS: 'INFORMAÇÕES GERAIS',
    RESUMO_DO_PEDIDO_CARTAO: 'RESUMO DO PEDIDO IMAGEM CARTÃO',
    RESUMO_DO_PEDIDO_CARTA_BERCO: 'RESUMO DO PEDIDO IMAGEM CARTA BERÇO',
    RESUMO_DO_PEDIDO_CONFIRMACAO: 'Ao confirmar a operação, os valores serão debitados diretamente  da Conta Digital, caso não haja saldo o sistema realizará novas tentativas diárias até o pedido ser liquidado ou cancelado.',
    ENVIO_DE_PEDIDO_ESTOQUE: 'Pedido Estoque',
    MENSAGEM_CONFIRMACAO: 'Confirma o envio do pedido de estoque?',
    MENSAGEM_CONFIRMACAO_CANCELAR: 'Confirma o cancelamento do pedido de estoque?',
    PEDIDO_REALIZADO_COM_SUCESSO: 'Pedido(s) realizado(s) com sucesso.',
    PEDIDO_ESTOQUE_CARTAO: 'Pedido Estoque Cartão',
    STATUS_PEDIDO_NAO_SALVO: 'Erro ao salvar pedido',
    PEDIDO_ESTOQUE: 'Pedido Estoque',
    NENHUMA_IMAGEM_ENCONTRADA: 'Nenhuma Imagem Encontrada',
    QUANTIDADE: 'Quantidade'
};

export const TIPO_IMAGEM = {
    CARTAO: 'CARTAO',
    CARTA_BERCO: 'CARTA_BERCO'
};

export const TIPO_PEDIDO = {
    ESTOQUE_CARTAO: 'ESTOQUE_CARTAO',
    ESTOQUE_CARTA_BERCO: 'ESTOQUE_CARTA_BERCO'
};

export const NOME_DOS_CAMPOS_DE_ERROS = {
    QUANTIDADE: { campo: 'quantidade' },
    IMAGEM_CARTAO: { campo: 'imagem_cartao' },
    IMAGEM_CARTA_BERCO: { campo: 'imagem_carta_berco' }

};
