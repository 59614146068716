import React, { Component } from 'react';
import { Button, FormControl, FormGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ExportarCSV from '../exportarCSV/exportarCSV';
import { LABEL_COMUNS, MASCARA_CAMPOS } from '../../util/contantes-comuns/constantes-comuns';
import Grid from '../grid/grid';
import { STATUS_VALIDACAO_COMPONENTES, ESTILO } from '../../util/estilo_para_componentes/estiloUtil';
import If from '../if/if';
import ModalComponent from '../../../componentes/componentes-uteis/modal-component/modal-component';
import Loading from '../../loading-fenix/loading';
import { removeMascaraTelefoneCpfCnpj } from 'componentes/util/formata-string';
import { adicionaNovoFavoritoFavorito } from '../../../../src/servicos/cartoes/cartoes-service';

const tooltip = <Tooltip id="tooltip">
    {LABEL_COMUNS.TOOLTIP_FILTRO_FAVORITO}
</Tooltip>;

class FiltroListaCartoes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            favoriteModalOpen: false, favoriteStep: 0, finalizedMessage: '', idCartao: '', numeroDocumento: '',
            contaSelecionada: props.contaSelecionada
        };
    }

    validaFavorito() {
        const { idCartao, numeroDocumento } = this.state;
        if (idCartao !== '' && numeroDocumento !== '') {
            return false;
        }
        return true;
    }

    cadastraFavorito = async () => {
        const { id } = this.state.contaSelecionada;
        this.setState({ favoriteStep: 3 });
        let body = {
            cartaoId: this.state.idCartao,
            documentoTitular: removeMascaraTelefoneCpfCnpj(this.state.numeroDocumento),
        };

        const resp = await adicionaNovoFavoritoFavorito(JSON.stringify(body), id);

        if (resp.error) {
            this.mensagensDeErro = resp.message.map((item, key) =>
                <li key={item.id}>{item.mensagem}</li>
            );
            this.setState({ finalizedMessage: this.mensagensDeErro, favoriteStep: 1 });
            return;
        }

        this.setState({ finalizedMessage: resp.message, favoriteStep: 1 });

    }

    corpoModalFavorito() {
        let step = this.state.favoriteStep;
        const { finalizedMessage } = this.state;

        if (step === 0) {
            return (
                <div style={{ padding: 3 }}>
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>ID Cartão *</span>
                        <InputMask
                            className="mensagens-erro form-control"
                            onChange={(e) => this.setState({ idCartao: e.target.value })}
                            placeholder={LABEL_COMUNS.ID_CARTAO}
                            maxLength="13"
                            value={this.state.idCartao}
                        />
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'row', marginRight: 3 }}>
                        <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>CPF *</span>
                        <InputMask
                            className="mensagens-erro form-control"
                            mask={MASCARA_CAMPOS.CPF} maskChar=""
                            onChange={(e) => this.setState({ numeroDocumento: e.target.value })}
                            placeholder={LABEL_COMUNS.CPF}
                            maxLength="50"
                            value={this.state.numeroDocumento}
                        />
                    </div>
                </div>
            );
        }
        else if (step === 1) {
            return (
                <div>
                    <span>{finalizedMessage}</span>
                </div>
            );
        }
        return (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Loading />
            </div>
        );
    }

    rodapeModalFavorito() {
        if (this.state.favoriteStep === 0) {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteModalOpen: false }); }} type="button" style={{ backgroundColor: 'white', color: 'black', borderColor: '#ddd' }} className="btn btn-primary">Cancelar</button>
                    <button disabled={this.validaFavorito()} onClick={() => this.cadastraFavorito()} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Cadastrar</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteModalOpen: false, favoriteStep: 0, idCartao: '', numeroDocumento: '' }); this.refresh(); }} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Ok</button>
                </div>
            );
        }
    }

    render() {
        const corDeFundoParaBotao = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG : ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        return (
            <div className="row">
                <ModalComponent
                    contextoBppPag={true}

                    bsSize="small"
                    exibirModal={this.state.favoriteModalOpen}
                    name="modal-favorite"
                    header={'Cadastrar Cartão Favorito'}
                    body={this.corpoModalFavorito()}
                    footer={this.rodapeModalFavorito()} />
                <br />
                <div className="col-sm-12 col-md-12 col-lg-12">
                    <form className="editForm" id="myForm">
                        <If test={this.props.exportarCsv}>
                            <div className="col-sm-1 col-md-1 col-lg-1 posicionamento_icone">
                                <ExportarCSV
                  listaParaSerconvertidaEmCSV={this.props.listaParaSerconvertidaEmCSV.map((e) => ({
                    'PAN': e.panFormatado || '',
                    'Id Cartão': e.idCartaoImpresso || '',
                    'Nome Portador': e.nomePortador || '',
                    'Documento Portador': e.documento ? e.documento.numero : ''
                  }))}
                  nomeDoArquivoCsv={this.props.nomeDoArquivoCsv} />
                            </div>
                        </If>
                        <div className="col-sm-3 col-md-3 col-lg-3 posicicao_filtro">
                            <b>{LABEL_COMUNS.ID_CARTAO}</b>
                            <FormGroup validationState={this.props.cartaoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask
                  mask={MASCARA_CAMPOS.CARTAO_ID} maskChar=""
                  className="form-control"
                  id={this.props.idCartaoID}
                  placeholder="ID do cartão"
                  style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                  maxLength="50"
                  value={this.props.cartaoId}
                  onChange={this.props.setCartaoId}
                />
                                {this.props.cartaoIdErro ? <span className="mensagens-erro-span" role="alert">{this.props.cartaoIdErro}</span> : null}
                            </FormGroup>
                        </div>
                        <If test={this.props.exibirNomeECpfDoPortador}>
                            <div className="col-sm-3 col-md-3 col-lg-3 posicicao_filtro campos">
                                {LABEL_COMUNS.NOME_PORTADOR}
                                <FormGroup validationState={this.props.nomePortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <FormControl type="text" className="form-control" id={this.props.idNomePortador}
                    placeholder="Nome do Portador"
                    style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                    maxLength="150"
                    value={this.props.nomePortador}
                    onChange={this.props.setNomePortador}
                  />
                                    {this.props.nomePortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.nomePortadorErro}</span> : null}
                                </FormGroup>
                            </div>
                            <div className="col-sm-3 col-md-2 col-lg-2 posicicao_filtro campos">
                                {LABEL_COMUNS.CPF_DO_PORTADOR}
                                <FormGroup validationState={this.props.numeroDocumentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                    id={this.props.idDocumentoPortador}
                    className="form-control mensagens-erro espacamento_campos"
                    mask={MASCARA_CAMPOS.CPF} maskChar=" " placeholder="CPF"
                    value={this.props.numeroDocumentoPortador}
                    onChange={this.props.setNumeroDocumentoPortador}
                  />
                                    {this.props.numeroDocumentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.numeroDocumentoPortadorErro}</span> : null}
                                </FormGroup>
                            </div>
                        </If>

                        <Grid cols="2 2 2" className="posicicao_filtro campos">
                            <b>{LABEL_COMUNS.CARTAO_QUATRO_ULTIMOS_DIGITOS}</b>
                            <FormGroup validationState={this.props.cartaoQuatroUltimosDigitosErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                  id={this.props.cartaoQuatroUltimosDigitos}
                  className="form-control mensagens-erro espacamento_campos"
                  mask={MASCARA_CAMPOS.CARTAO_QUATRO_ULTIMOS_DIGITOS} maskChar=""
                  placeholder={LABEL_COMUNS.CARTAO_QUATRO_ULTIMOS_DIGITOS}
                  value={this.props.cartaoQuatroUltimosDigitos}
                  onChange={this.props.setCartaoQuatroUltimosDigitos}
                />
                                {this.props.cartaoQuatroUltimosDigitosErro ? <span className="mensagens-erro-span" role="alert">{this.props.cartaoQuatroUltimosDigitosErro}</span> : null}
                            </FormGroup>

                        </Grid>

                        {this.props.contextoBppPag && <div style={{ paddingTop: 5 }}>
                            <OverlayTrigger lacement="top" overlay={tooltip}>
                                <span className="d-inline-block">
                                    <i className="fa fa-question-circle radio-modelo-arquivo-exemplo" style={{ marginLeft: 0, marginTop:10 }}/>
                                </span>
                            </OverlayTrigger>


                        </div>}

                        <div className="col-sm-10 col-md-10 col-lg-10 alinhamento-direita" style={{ width: '100%' }}>
                            <div className="botoes-filtro" style={{ width: '100%' }} >
                                <Button bsStyle={`${corDeFundoParaBotao}`}
                                    style={{ width: '150px', height: '30px', paddingTop: '4px', paddingLeft: '10px', marginRight: 20 }}
                                    onClick={() => this.setState({ favoriteModalOpen: true })}>
                                    {LABEL_COMUNS.CADASTRAR_FAVORITO}
                                </Button>
                                <Button bsStyle="default"
                                    style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px', marginRight: 20 }}
                  onClick={this.props.limparFiltro}>
                                    {LABEL_COMUNS.LIMPAR}
                                </Button>
                                <Button bsStyle={corDeFundoParaBotao} style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                  onClick={this.props.buscar}>
                                    {LABEL_COMUNS.SET_HISTORICO}
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}

export default FiltroListaCartoes;
