import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ExibirImagemComTitulo extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {this.props.tituloImagem} </b></div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row">
                                            <div className="col-md-4 alinhamento-cartao">
                                                <div className="row">
                                                    <img id="img-cartao" src={this.props.urlImagem}
                            alt="Cartão" style={{ position: 'absolute' }} width="371" height="237" />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>
            </div>
        );
    }
}

export default ExibirImagemComTitulo;
