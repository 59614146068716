import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Button, Dropdown, Glyphicon } from 'react-bootstrap';
import * as actions from '../../../../../action/cartoes/cartoes-action';
import { setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador } from '../../../../comuns/portador/vincular-portador/vincular-portador-action';
import { bindActionCreators } from 'redux';
import GridCartoesDashboardBppCorp from './grid-cartoes-dashboard-bpp-corp';
import CargaEDescargaCartao from '../../../../cartao/carga-e-descarga-cartao/carga-e-descarga-cartao';
import { LABEL_COMUNS, OPERACAO } from '../../../../util/contantes-comuns/constantes-comuns';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { ESTILO } from '../../../../util/estilo_para_componentes/estiloUtil';
import Loading from '../../../../loading-fenix/loading';
import DetalhesDoPortador from '../../../../comuns/portador/detalhes-do-portador';
import VincularPortador from '../../../../comuns/portador/vincular-portador/vincular-portador';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import AlterarPortador from '../../../../comuns/portador/alterar-portador/alterar-portador';
import BloquearCartao from '../../../../cartao/bloquear-cartao/bloquear-cartao';
import DesbloquearCartao from '../../../../cartao/desbloquear-cartao/desbloquear-cartao';
import CancelarCartao from '../../../../cartao/cancelar-cartao/cancelar-cartao';
import If from 'componentes/componentes-uteis/if/if';
import AtualizarPagina from 'componentes/comuns/atualizar-pagina/atualizar-pagina';

class ListaCartoesDashboardBppCorp extends Component {

    constructor(props) {
        super(props);
        this.state = {
            current: undefined, abrirCollapse: false, exibirModalVincularPortador: false, exibirModalAlterarPortador: false,
            isModalAlteraPortadorOpen: false, exibirModalDetalhePortador: false, isLoading: false,
            showCancelCardModal: false, showLockCardModal: false, showFinalizationModal: false, showUnlockCardModal: false,
            showUnlockFinalizationModal: false, showCancelFinalizationModal: false, showLockFinalizationModal: false,
            showChargeOrDischargeCard: false
        };
    }

    componentDidMount = () => {
        this.props.setClearState();
        this.props.limparCartoes();
        let contaSelecionada = this.props.contaSelecionada;
        this.props.setContaSelecionada(contaSelecionada);
        this.getCartoes();
        this.props.limparFiltroCartao();
    }

    componentWillReceiveProps = (nextProps) => {

        const naoExisteCartoes = !nextProps.estadoCartoes.listaDeCartoesAtivos.length
            && !nextProps.estadoCartoes.listaDeCartoesBloqueados.length
            && !nextProps.estadoCartoes.listaDeCartoesCanceladosEOutros.length
            && !nextProps.estadoCartoes.listaDeCartoesProntosParaAtivacao.length;

        if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesAtivos.length) {
            this.setState({ current: 0 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesBloqueados.length) {
            this.setState({ current: 1 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesCanceladosEOutros.length) {
            this.setState({ current: 2 });
        }
        else if (this.state.current === undefined && nextProps.estadoCartoes.listaDeCartoesProntosParaAtivacao.length) {
            this.setState({ current: 3 });
        }
        else if (naoExisteCartoes) {
            this.setState({ current: 0 });
        }
    }

    getCartoes = () => {
        this.props.getCartoesDashboard(this.props.contaSelecionada.id, this.props.produtosIdsBppCorp);
    };

    setShowLockCardModal = (showLockCardModal) => {
        this.setState({ showLockCardModal });
    };

    setShowUnlockCardModal = (showUnlockCardModal) => {
        this.setState({ showUnlockCardModal });
    };

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setShowUnlockFinalizationModal = (showUnlockFinalizationModal) => {
        this.setState({ showUnlockFinalizationModal });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    setExibirModalDetalhePortador = (exibirModalDetalhePortador) => {
        this.setState({ exibirModalDetalhePortador });
    }

    setExibirModalAlterarPortador = (exibirModalAlterarPortador) => {
        this.setState({ exibirModalAlterarPortador });
    }

    setShowChargeOrDischargeCard = (showChargeOrDischargeCard) => {
        this.setState({ showChargeOrDischargeCard });
    }

    render() {
        const { current, exibirModalAlterarPortador, showCancelCardModal, showLockCardModal, showUnlockCardModal,
            showChargeOrDischargeCard, showUnlockFinalizationModal, showCancelFinalizationModal, showLockFinalizationModal
        } = this.state;

        const { contaSelecionada, cartaoSelecionado, exibirLoadingCartao, operacaoSelecionada } = this.props.estadoCartoes;

        const { grantChangeStatusCard, grantUnlockCard,
            grantChargeCard, grantDisChargeCard, outorgaPerfils } = this.props;

        const underlineStyle = show => {
            return show ? { textDecoration: 'underline', fontWeight: 'bold' } : {};
        };

        return (
            <div>
                <CargaEDescargaCartao
                    exibirModal={showChargeOrDischargeCard}
                    contaSelecionada={contaSelecionada}
                    cartaoSelecionado={cartaoSelecionado}
                    operacaoSelecionada={operacaoSelecionada}
                    setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                    grantChargeCard={grantChargeCard}
                    grantDisChargeCard={grantDisChargeCard}
                    grantProfile={outorgaPerfils}
                />

                <VincularPortador
                    produtoId={this.props.produtosIdsBppCorp}
                    cartaoSelecionado={cartaoSelecionado}
                    contaSelecionada={contaSelecionada}
                    setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador} />

                <DetalhesDoPortador
                    cartaoSelecionado={cartaoSelecionado}
                    exibirModal={this.state.exibirModalDetalhePortador}
                    setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                />

                <BloquearCartao
                    showLockCardModal={showLockCardModal}
                    setShowLockLoading={this.props.setExibirLoadingCartao}
                    lockSelectedCard={cartaoSelecionado}
                    lockGetCards={this.getCartoes}
                    setShowLockCardModal={this.setShowLockCardModal}
                    showLockFinalizationModal={showLockFinalizationModal}
                    setShowLockFinalizationModal={this.setShowLockFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}

                />

                <DesbloquearCartao
                    showUnlockCardModal={showUnlockCardModal}
                    setShowUnlockLoading={this.props.setExibirLoadingCartao}
                    unlockSelectedCard={cartaoSelecionado}
                    unlockGetCards={this.getCartoes}
                    setShowUnlockCardModal={this.setShowUnlockCardModal}
                    showUnlockFinalizationModal={showUnlockFinalizationModal}
                    setShowUnlockFinalizationModal={this.setShowUnlockFinalizationModal}
                    grantUnlockCard={grantUnlockCard}
                    grantProfile={outorgaPerfils}

                />

                <CancelarCartao
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />

                <AlterarPortador
                    cartaoSelecionado={cartaoSelecionado}
                    getCartoes={this.getCartoes}
                    exibirModalAlterarPortador={exibirModalAlterarPortador}
                    setExibirModalAlterarPortador={this.setExibirModalAlterarPortador} />

                <div className="panel panel-default">
                    <div className={ESTILO.COR_DE_FUNDO.TITULO.PADRAO} style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.CARTOES.PADRAO}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <AtualizarPagina onClick={() => this.getCartoes()} />
                            <Button className="estilo-filtro" onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            &nbsp;&nbsp;
                            <Dropdown id="menuOpcoes" pullRight >
                                <Dropdown.Toggle noCaret className="fundo-azul-claro dropdown-sem-bordas">
                                    <Glyphicon glyph="option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <li>
                                        <Link className="link_expandir" to={{ pathname: ROTAS_CAMINHOS.CARTOES, state: { produtosIdsBppCorp: this.props.produtosIdsBppCorp } }}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico
                        abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeCartoes={true}
                        cartaoId={this.props.estadoCartoes.cartaoId}
                        setCartaoId={this.props.setCartaoId}
                        cartaoIdErro={this.props.estadoCartoes.portadorCartaoIdErro}
                        exibirNomeECpfDoPortador={true}

                        nomePortador={this.props.estadoCartoes.nomePortador}
                        setNomePortador={this.props.setNomePortadorCartao}
                        nomePortadorErro={this.props.estadoCartoes.nomePortadorErro}

                        numeroDocumentoPortador={this.props.estadoCartoes.numeroDocumentoPortador}
                        setNumeroDocumentoPortador={this.props.setNumeroDocumentoPortadorCartao}
                        numeroDocumentoPortadorErro={this.props.estadoCartoes.numeroDocumentoPortadorErro}

                        cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                        setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                        cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}

                        exibirBotaoLimpar={true}
                        limparFiltro={this.props.limparFiltroCartao}
                        buscar={() => this.props.getCartoes(this.props.estadoCartoes.contaSelecionada.id,
                            this.props.produtosIdsBppCorp, this.props.estadoCartoes.cartaoId, this.props.estadoCartoes.nomePortador,
                            this.props.estadoCartoes.numeroDocumentoPortador, this.props.estadoCartoes.cartaoQuatroUltimosDigitos)} />

                    <div className="panel-body" style={{ height: '350px' }}>
                        <If test={exibirLoadingCartao}>
                            <div className="alinhamento-centro"> <Loading /> </div>
                        </If>
                        <If test={!exibirLoadingCartao}>
                            <div>
                                <If test={current === 0}>
                                    <GridCartoesDashboardBppCorp
                                        outorgaPerfils={outorgaPerfils}
                                        setShowCancelCardModal={this.setShowCancelCardModal}
                                        setShowLockCardModal={this.setShowLockCardModal}
                                        setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.ATIVO}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesAtivos}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        setExibirModalAlterarPortador={this.setExibirModalAlterarPortador}
                                    />
                                </If>
                                <If test={current === 1}>
                                    <GridCartoesDashboardBppCorp
                                        outorgaPerfils={outorgaPerfils}
                                        statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.BLOQUEADO}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        setShowUnlockCardModal={this.setShowUnlockCardModal}
                                    />
                                </If>

                                <If test={current === 2}>
                                    <GridCartoesDashboardBppCorp
                                        outorgaPerfils={outorgaPerfils}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.CANCELADO_E_OUTRO}
                                        setShowUnlockCardModal={this.setShowUnlockCardModal}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros}
                                    />
                                </If>

                                <If test={current === 3}>
                                    <GridCartoesDashboardBppCorp
                                        outorgaPerfils={outorgaPerfils}
                                        statusCartao={LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.PRONTO_PARA_ATIVACAO}
                                        tipoDeAcesso={reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)}
                                        setExibirModalAlterarPortador={this.setExibirModalAlterarPortador}
                                        setArmazenarCartao={this.props.setArmazenarCartao}
                                        setCartaoSelecionadoParaVincularPortador={this.props.setCartaoSelecionadoParaVincularPortador}
                                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                                        setShowLockCardModal={this.setShowLockCardModal}
                                        setShowCancelCardModal={this.setShowCancelCardModal}
                                        setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                                        listaDeCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao} />
                                </If>
                            </div>
                        </If>
                    </div>

                    <div className="cartoes-status">
                        <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 0 })} style={underlineStyle(this.state.current === 0)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.ATIVOS}&nbsp;({this.props.estadoCartoes.listaDeCartoesAtivos.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 1 })} style={underlineStyle(this.state.current === 1)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.BLOQUEADOS}&nbsp;({this.props.estadoCartoes.listaDeCartoesBloqueados.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 2 })} style={underlineStyle(this.state.current === 2)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS_E_OUTROS}&nbsp;({this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length})</span>
                        &nbsp;| <span className="links-dashboard-cartao" onClick={() => this.setState({ current: 3 })} style={underlineStyle(this.state.current === 3)}>{LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}&nbsp;({this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length})</span>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    estadoCartoes: state.CartoesReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    grantChangeStatusCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERA_STATUS_CARTAO),
    grantUnlockCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESBLOQUEIO_CARTAO),
    grantChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.CARGA_CARTAO),
    grantDisChargeCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.DESCARGA_CARTAO)
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesDashboardBppCorp);
