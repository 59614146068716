/* export default class Controle {
    static buscarSaldoConta() {
        return "BUSCAR_SALDO_CONTA";
    }

    static erroSaldoConta() {
        return "ERRO_SALDO_CONTA";
    }
    static exibirModalEmbreve() {
        return "EXIBIR_MODAL_EM_BREVE";
    }
} */

export default class Controle {
    static buscarSaldoConta() {
        return 'BUSCAR_SALDO_CONTA';
    }
    static erroSaldoConta() {
        return 'ERRO_SALDO_CONTA';
    }
    static exibirEmbreveControl() {
        return 'EXIBIR_MODAL_EM_BREVE';
    }
}
