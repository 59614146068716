import { bppWebShimFetch, bppFetch } from 'core/api/bppFetch';

export function getCnabArchiveApi(customerId, accountId) {
    const urlToCall = `/cnab240/${customerId}/${accountId}`;

    const options = {
        method: 'GET',
    };

    return bppWebShimFetch(urlToCall, options)
        .then(({ error, data }) => {
            const response = {
                error,
                data
            };
            return response;
        })
        .catch(error => {
            return 'error';
        });
}

export function downloadCnabArchiveApi(fileName, operation) {
    const urlToCall = `/cnab240/download/${fileName}?operation=${operation}`;

    const options = {
        method: 'GET',
    };

    return bppWebShimFetch(urlToCall, options)
        .then(({ error, data }) => {
            const response = {
                error,
                data
            };
            return response;
        })
        .catch(error => {
            return 'error';
        });
}

export function approveOrReproveArchiveApi(id, anexoContratualOriginal, action) {

    const urlToCall = '/lote/arquivo';

    const options = {
        method: 'PATCH',
        body: JSON.stringify({
            id,
            anexoContratualOriginal,
            transacao: action
        })
    };

    return bppFetch(urlToCall, options)
        .then(({ error, data }) => {
            const response = {
                error,
                data: JSON.parse(data)
            };
            return response;
            
        })
        .catch(error => {
            return 'error';
        });
}

