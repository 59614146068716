import { METODOS } from './consulta-carga-lote-cartao-constantes';

const st = {
    defaultMaskPattern: '99/99/9999',
    usuarios: '',
    nomeArquivo: '',
    dataDe: '',
    dataAte: '',
    usuario: '',
    cargaLote: [],
    err: undefined,
    idDoArquivoProcessado: undefined,
    carregando: true,
    totalResultado: 0,
    cargaLoteParaCSV: [],
    exibirModalErro: false,
    ocultarGrid: true,
    erroDataInicio: undefined,
    erroDataFim: undefined

};

export default function reducer(state = st, action) {
    switch (action.type) {
    // Listar Cargas em Lote
        case METODOS.LISTAR_CARGA_LOTE:
            var exibirModal = action.payload.quantidadeTotalRegistros === 0;

            {
                return {
                    ...state,
                    cargaLote: action.payload.registros,
                    totalResultado: action.payload.quantidadeTotalRegistros,
                    // respostaProcessarArquivo: undefined,
                    idDoArquivoProcessado: undefined,
                    carregando: false,
                    exibirModalResultadosNaoEncontrados: exibirModal,
                    err: undefined,
                    ocultarGrid: false,
                    erroDataInicio: undefined,
                    erroDataFim: undefined
                };
            }
        // Buscar Usuários
        case METODOS.BUSCAR_USUARIOS_:
        {
            return {
                ...state,
                usuarios: action.payload,
                carregando: false
            };
        }
        // Alterar nome do Arquivo
        case METODOS.MUDAR_NOME_ARQUIVO_:
        {
            return {
                ...state,
                nomeArquivo: action.payload,
                carregando: false
            };
        }
        // Erro
        case METODOS.LISTAR_FALHA_: {
        // precisa decompor a mensagem de data inicial e final inválida
            var msgDataInicio = undefined;
            var msgDataFim = undefined;
            switch (action.payload) {
                case ('Data inicial inválida'):
                    msgDataInicio = action.payload;
                    break;
                case ('Data inicial e Data Final são inválidas'):
                    msgDataInicio = 'Data inicial inválida';
                    msgDataFim = 'Data final inválida';
                    break;
                case ('Data final inválida'):
                    msgDataFim = action.payload;
                    break;
                case ('Data inicial maior que data final'):
                    msgDataInicio = action.payload;
                    break;

                case ('Data inicial e data final são maiores que a data atual'):
                    msgDataInicio = 'Data inicial é maior que a data atual';
                    msgDataFim = 'Data final é maior que a data atual';
                    break;

                case ('Data inicial é maior que a data atual'):
                    msgDataInicio = 'Data inicial é maior que a data atual';
                    break;

                case ('Data final é maior que a data atual'):
                    msgDataFim = action.payload;
                    break;

                default: { msgDataInicio = undefined; msgDataFim = undefined; }
            }

            return {
                ...state,
                err: action.payload,
                carregando: false,
                erroDataInicio: msgDataInicio,
                erroDataFim: msgDataFim
            };
        }
        // Mudar data início
        case METODOS.MUDAR_DATA_INICIO_:
        {
            return {
                ...state,
                dataDe: action.payload,
                carregando: false
            };
        }

        // Mudar data fim
        case METODOS.MUDAR_DATA_FIM_:
        {
            return {
                ...state,
                dataAte: action.payload,
                carregando: false
            };
        }

        // Mudar usuario
        case METODOS.MUDAR_USUARIO_:
        {
            return {
                ...state,
                usuario: action.payload,
                carregando: false
            };
        }

        // Limpar Campos
        case METODOS.LIMPAR_CAMPOS_:
        {
            return {
                ...state,
                nomeArquivo: '',
                dataDe: '',
                dataAte: '',
                usuario: '',
                cargaLote: [],
                carregando: false,
                totalResultado: 0,
                err: undefined,
                ocultarGrid: true,
                erroDataInicio: undefined,
                erroDataFim: undefined

            };
        }

        // Setar buscando true
        case METODOS.SETAR_BUSCANDO_TRUE_:
        {
            return {
                ...state,
                carregando: action.payload

            };
        }

        // Buscar carga em lote para CSV
        case METODOS.BUSCAR_DADOS_PARA_CSV_:
        {
            return {
                ...state,
                cargaLoteParaCSV: action.payload.registros,
                carregando: false

            };
        }

        // Buscar carga em lote para CSV
        case METODOS.LIMPAR_DADOS_CSV_:
        {
            return {
                ...state,
                cargaLoteParaCSV: action.payload,
                erroDataInicio: undefined,
                erroDataFim: undefined

            };
        }

        // Setar exibir modal erro
        case METODOS.SETAR_MODAL_DADOS_NAO_ENCONTRADOS_:
        {
            return {
                ...state,
                exibirModalErro: true
            };
        }

        // ocultar modal erro
        case METODOS.SETAR_OCULTAR_ERRO_:
        {
            return {
                ...state,
                err: undefined,
                exibirModalErro: false
                // carregando: true

            };
        }
        default: return state;
    }
}
