export const METODOS = {
    SET_DATA_DE_NASCIMENTO_DO_PORTADOR: 'setDataDeNascimentoDoPortador',
    SET_NUMERO_DO_DOCUMENTO_DO_PORTADOR: 'setNumeroDoDocumentoDoPortador',
    SET_NOME_DO_PORTADOR: 'setNomeDoPortador',
    SET_CARTAO_SELECIONADO_PARA_VINCULAR_PORTADOR: 'setCartaoSelecionadoParaVincularPortador',
    SET_EXIBIR_MODAL_DE_VINCULAR_PORTADOR: 'setExibirModalDeVincularPortador',
    SET_EXIBIR_MENSAGEM: 'setExibirMensagem',
    VINCULAR_PORTADOR: 'vincularPortador',
    VINCULAR_PORTADOR_ERRO: 'vincularPortadorErro',
    LIMPAR_CAMPOS_DE_ERROS: 'limpaCamposDeErros'
};

export const NOME_MODAL = 'vincular-portador';

export const MENSAGENS_FINALIZACAO_PORTADOR = {
    SUCESSO: 'Portador vinculado com sucesso.',
    ERRO: 'Não foi possível vincular o portador.'
};
