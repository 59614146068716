export const METODOS = {
    SET_MENU: 'setMenu',
    SET_MENU_INICIO: 'setMenuInicio',
    SET_MENU_INICIO_CONTA_SELECIONADA: 'setMenuInicioContaSelecionada',
    SET_MENU_TITULAR: 'setMenuTitular',
    SET_MENU_TITULAR_CONTA_SELECIONADA: 'setMenuTitularContaSelecionada',
    SET_MENU_EMPRESA: 'setMenuEmpresa',
    SET_MENU_SOLUCAO: 'setMenuSolucao',
    SET_MENU_DEPARTAMENTO: 'setMenuDepartamento',
    SET_MENU_CONTA_DIGITAL: 'setMenuContaDigital',
    SET_MENU_SAIR: 'setMenuSair',
    SET_MODAL_ATENDIMENTO: 'setModalAtendimento',
    SET_CONTEXT_BPP_PAG: 'setContextBppPag',
    SET_CONTEXT_MEUS_DEPARTAMENTOS: 'setContextoMeusDepartamentos'
};
