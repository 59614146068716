export const CONTROLADOR_CONSULTA_CARGA_LOTE_ACTION_REDUX =
    {
        CLASSE_NAME: 'CONSULTA-CARGA-LOTE',
        COMANDO: [
            'LISTAR_CARGA_LOTE',
            'BUSCAR_USUARIOS_',
            'LISTAR_FALHA_',
            'MUDAR_NOME_ARQUIVO_',
            'MUDAR_DATA_INICIO_',
            'MUDAR_DATA_FIM_',
            'MUDAR_USUARIO_',
            'LIMPAR_CAMPOS_',
            '', // removido
            '', // removido
            'SETAR_BUSCANDO_TRUE_',
            'BUSCAR_DADOS_PARA_CSV_',
            'LIMPAR_DADOS_CSV_',
            'SETAR_MODAL_DADOS_NAO_ENCONTRADOS_',
            'SETAR_OCULTAR_ERRO_'

        ]
    };
