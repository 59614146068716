import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Dropdown, Glyphicon } from 'react-bootstrap';
import * as actions from '../../../../../action/pedidos/pedidos-action';
import If from '../../../../componentes-uteis/if/if';
import { LABEL_COMUNS, TIPO_DE_MOVIMENTACAO } from '../../../../util/contantes-comuns/constantes-comuns';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import Grid from '../../../../componentes-uteis/grid/grid';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { formatarCampoValor } from '../../../../util/extrato/extrato-util';
import Loading from '../../../../loading-fenix/loading';
import { ESTILO } from '../../../../util/estilo_para_componentes/estiloUtil';
import AtualizarPagina from 'componentes/comuns/atualizar-pagina/atualizar-pagina';

class ConsultaDePedidosDashboardBppPag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abrirCollapse: false
        };
    }

    componentDidMount() {
        let contaSelecionada = this.props.contaSelecionada;
        this.props.limparFiltroPedidos();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getPedidos(contaSelecionada.id, this.props.produtosIdsBppPag);
        this.props.getOperacoes(this.props.produtosIdsBppPag);
    }

    render() {
        const { contaSelecionada, dataInicial, dataFinal, listaDeOperacoes, listaDeStatus,
            valorDe, valorAte, operacaoSelecionadaId, statusSelecionado } = this.props.consultaDePedidosDashboard;
        return (
            <div className="row">
                <div className="panel panel-default">
                    <div className={ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG} style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.PEDIDOS.PADRAO}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <AtualizarPagina marginTop="5px" onClick={() => this.props.getPedidos(contaSelecionada.id, this.props.produtosIdsBppPag)} />
                            <Button className={ESTILO.COR_DE_FUNDO.FILTRO.BPP_PAG}
                                onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })}>
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            &nbsp;&nbsp;
                            <Dropdown id="menuOpcoes" pullRight>
                                <Dropdown.Toggle noCaret className={ESTILO.COR_DE_FUNDO.FILTRO.BPP_PAG}>
                                    <Glyphicon glyph="option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <li>
                                        <Link className="link_expandir" to={ROTAS_CAMINHOS.CONSULTA_PEDIDO_BPP_PAG}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico
                        contextoBppPag
                        abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeDatas
                        mostrarFiltroDeOperacao
                        dataInicial={dataInicial}
                        setDataInicial={this.props.setConsultaDePedidosDataInicial}
                        dataInicialErro={this.props.consultaDePedidosDashboard.dataInicialErro}
                        dataFinal={dataFinal}
                        setDataFinal={this.props.setConsultaDePedidosDataFinal}
                        dataFinalErro={this.props.consultaDePedidosDashboard.dataFinalErro}
                        operacaoSelecionadaId={operacaoSelecionadaId}
                        setOperacao={this.props.setConsultaDePedidosOperacao}
                        listaDeOperacoes={listaDeOperacoes}
                        mostrarFiltroDeValor
                        valorDe={valorDe}
                        setValorDe={(e) => this.props.setFiltroConsultaDePedidosPorValor({
                            data: { valorDe: e },
                            erro: { valorDeErro: '' }
                        })}
                        valorAte={valorAte}
                        setValorAte={(e) => this.props.setFiltroConsultaDePedidosPorValor({
                            data: { valorAte: e },
                            erro: { valorAteErro: '' }
                        })}
                        mostrarFiltroDeStatus
                        listaDeStatus={listaDeStatus}
                        setStatus={this.props.setConsultaDePedidosStatus}
                        statusSelecionado={statusSelecionado}
                        statusErro={this.props.consultaDePedidosDashboard.statusErro}
                        exibirBotaoLimpar
                        limparFiltro={this.props.limparFiltroPedidos}
                        buscar={() => this.props.getPedidos(contaSelecionada.id, this.props.produtosIdsBppPag,
                            dataInicial, dataFinal, null, valorDe, valorAte, operacaoSelecionadaId, statusSelecionado)} />

                    <div className="panel-body" style={{ height: '170px' }}>
                        {
                            this.props.consultaDePedidosDashboard.exibirLoadingDashboardConsultaPedido
                                ? <div className="alinhamento-centro">
                                    <Loading />
                                </div>
                                : this.props.consultaDePedidosDashboard.exibirLoadingDashboardConsultaPedido !== undefined &&
                                <If test={this.props.consultaDePedidosDashboard.listaDePedidos.length > 0}>
                                    <div className="row">
                                        <Grid cols="12 12 12 12" className="grids-dashboards">
                                            <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">Pedido</span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">Data</span>
                                            </Grid>
                                            <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">Operação</span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                <span className="titulo-dashboards-conta-solucao">Valor</span>
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">Status</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="grid-pedidos grids-dashboards">
                                        {this.props.consultaDePedidosDashboard.listaDePedidos.map((linhaConsulta, idx) => {
                                            return (
                                                <div className="row" key={idx}>
                                                    <Grid cols="12 12 12 12">
                                                        <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                            <span style={{ fontSize: '11px' }}>
                                                                {linhaConsulta.pedidoFelId}
                                                            </span>
                                                        </Grid>
                                                        <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                            <span style={{ fontSize: '11px' }}>
                                                                {linhaConsulta.dataCriacao}
                                                            </span>
                                                        </Grid>
                                                        <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                                                            <span style={{ fontSize: '11px' }}>
                                                                {linhaConsulta.lancamentoFinanceiroExibicao}
                                                            </span>
                                                        </Grid>
                                                        <Grid cols="2 2 2 2" className="alinhamento-direita">
                                                            <span style={{ fontSize: '11px' }}>
                                                                {formatarCampoValor(TIPO_DE_MOVIMENTACAO.DEBITO, linhaConsulta.valor)}
                                                            </span>
                                                        </Grid>
                                                        <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                            <span style={{ fontSize: '11px' }}>
                                                                {linhaConsulta.statusExibicao}
                                                            </span>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            );
                                        }
                                        )}
                                    </div>
                                </If>
                        }
                        <If test={this.props.consultaDePedidosDashboard.listaDePedidos.length <= 0 && !this.props.consultaDePedidosDashboard.exibirLoadingDashboardConsultaPedido}>
                            <span>{LABEL_COMUNS.NENHUM_PEDIDO_ENCONTRADO}</span>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    consultaDePedidosDashboard: state.PedidosReducer
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaDePedidosDashboardBppPag);
