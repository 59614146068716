import { Button, ControlLabel } from 'react-bootstrap';
import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';

import { COMPROVANTE_OPERACOES, COMPROVANTE_OPERACOES_FILTRO, COMPROVANTE_TIPO_OPERACAO, CONTA_SELECIONADA } from '../../util/contantes-comuns/constantes-comuns';
import { formatarDataUS } from '../../util/data/dataUtil';
import { getOperacoesComprovante } from '../../../servicos/comprovante/comprovante-service';
import MostrarSaldo from '../saldo/mostrar-saldo';

export default class FiltroComprovantes extends Component {
    constructor() {
        super();

        this.state = {
            dataCriacaoInicial: '',
            dataCriacaoFinal: '',
            operacao: '',
            operacaoId: '',
            tipo: '',
            valorDe: '',
            valorAte: '',
            dataCriacaoInicialError: false,
            dataCriacaoFinalError: false,
            operacoesComprovante: COMPROVANTE_OPERACOES,
            tiposOperacoes: COMPROVANTE_TIPO_OPERACAO
        };

        this.initialState = this.state;
        this.contaSelecionada = CONTA_SELECIONADA.OBJETO();

        this.handleChangeInput = this.handleChangeInput.bind(this);
        this.handleResetFields = this.handleResetFields.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        const operacoesComprovantePromise = getOperacoesComprovante('operacoesComprovante')
            .then(({ data }) => {
                const operacoesComprovante = data.valor.reduce((acc, cur) => acc.concat(cur.operacao), []);

                this.setState({
                    operacoesComprovante: COMPROVANTE_OPERACOES_FILTRO.filter(e => operacoesComprovante.includes(e.value))
                });
            }).catch((err) => {
                console.error(err);
            });
    }

    handleChangeInput(name, value) {
        this.setState({ [name]: value });
    }

    handleResetFields(e) {
        e.preventDefault();
        this.setState(this.initialState);
    }

    handleSubmit(e) {
        e.preventDefault();

        const {
            dataCriacaoInicial,
            dataCriacaoFinal,
            operacao,
            operacaoId,
            tipo,
            valorDe,
            valorAte,
            dataCriacaoInicialError,
            dataCriacaoFinalError
        } = this.state;

        let hasInvalidDate = false;

        if (dataCriacaoInicial && !moment(dataCriacaoInicial, 'DD/MM/YYYY', true).isValid()) {
            hasInvalidDate = true;
            this.setState({ dataCriacaoInicialError: true });
        } else {
            this.setState({ dataCriacaoInicialError: false });
        }

        if (dataCriacaoFinal && !moment(dataCriacaoFinal, 'DD/MM/YYYY', true).isValid()) {
            hasInvalidDate = true;
            this.setState({ dataCriacaoFinalError: true });
        } else {
            this.setState({ dataCriacaoFinalError: false });
        }

        if (!hasInvalidDate) {
            this.setState({ dataCriacaoInicialError: false, dataCriacaoFinalError: false });
            this.props.buscaHistoricoComprovante({
                ...(dataCriacaoInicial && { dataCriacaoInicial: formatarDataUS(dataCriacaoInicial) }),
                ...(dataCriacaoFinal && { dataCriacaoFinal: formatarDataUS(dataCriacaoFinal) }),
                ...(operacao && { operacao }),
                ...(operacaoId && { operacaoId }),
                ...(tipo && { tipo }),
                ...(valorDe && { valorDe: valorDe.replace('.', '').replace(',', '.') }),
                ...(valorAte && { valorAte: valorAte.replace('.', '').replace(',', '.') })
            });
        }
    }

    render() {
        const { descricao, apelido, agencia, numero, digito } = this.contaSelecionada;

        const tipoConta = descricao === 'contaMovimento' && 'Conta Digital' ||
      descricao === 'contaDepartamento' && 'Conta Departamento' ||
      descricao === 'contaCartao' && 'Conta Cartão';

        return (
            <div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                    <div className="panel panel-default">
                        <div className="panel-heading fundo-azul-claro">{tipoConta} - {apelido} - {agencia}/{numero}-{digito}</div>
                        <div className="panel-body">
                            <MostrarSaldo
                contaSelecionada={this.contaSelecionada}
                exibirSaldo
                mostrarSaldoBloqueado
              />
                            <div className="row" style={{ borderTop: '1px solid #ddd', marginTop: 15 }} />
                            <div style={{ marginTop: 15 }}>
                                <div className="row">
                                    {/*  <div className='col-sm-2 col-md-2 col-lg-2'>
                    <ControlLabel>ID</ControlLabel>
                    <FormControl
                      name='operacaoId'
                      type='text'
                      placeholder='ID da operação'
                      onChange={(e) => this.handleChangeInput(e.target.name, e.target.value)}
                      value={this.state.operacaoId}
                    />
                  </div> */}
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Data inicial</ControlLabel>
                                        <InputMask
                      id="dataCriacaoInicial"
                      name="dataCriacaoInicial"
                      className="mensagens-erro form-control"
                      mask="99/99/9999"
                      maskChar=""
                      placeholder="DD/MM/AAAA"
                      onChange={(e) => this.handleChangeInput(e.target.name, e.target.value)}
                      value={this.state.dataCriacaoInicial}
                    />
                                        {this.state.dataCriacaoInicialError ? <span className="mensagens-erro-span" role="alert">Data inválida</span> : null}
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Data final</ControlLabel>
                                        <InputMask
                      id="dataCriacaoFinal"
                      name="dataCriacaoFinal"
                      className="mensagens-erro form-control"
                      mask="99/99/9999"
                      maskChar=""
                      placeholder="DD/MM/AAAA"
                      onChange={(e) => this.handleChangeInput(e.target.name, e.target.value)}
                      value={this.state.dataCriacaoFinal}
                    />
                                        {this.state.dataCriacaoFinalError ? <span className="mensagens-erro-span" role="alert">Data inválida</span> : null}
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Operação</ControlLabel>
                                        <Select id="operacoesComprovante"
                      placeholder="Selecione"
                      name="operacao"
                      options={this.state.operacoesComprovante}
                      noResultsText="Nenhuma operação encontrada"
                      onChange={(e) => { this.handleChangeInput('operacao', e ? e.value : ''); }}
                      value={this.state.operacao}
                    />
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Tipo</ControlLabel>
                                        <Select id="tiposOperacoes"
                      placeholder="Selecione"
                      name="tipo"
                      options={this.state.tiposOperacoes}
                      noResultsText="Nenhum tipo encontrado"
                      onChange={(e) => { this.handleChangeInput('tipo', e ? e.value : ''); }}
                      value={this.state.tipo}
                    />
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Valor de</ControlLabel>
                                        <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      id="valorDe"
                      className="form-control mensagens-erro"
                      onChange={(value) => this.handleChangeInput('valorDe', value)}
                      value={this.state.valorDe}
                    />
                                    </div>
                                    <div className="col-sm-2 col-md-2 col-lg-2">
                                        <ControlLabel>Valor até</ControlLabel>
                                        <CurrencyInput
                      decimalSeparator=","
                      thousandSeparator="."
                      id="valorAte"
                      className="form-control mensagens-erro"
                      onChange={(value) => this.handleChangeInput('valorAte', value)}
                      value={this.state.valorAte}
                    />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="alinhamento-direita" style={{ padding: 15 }}>
                                        <Button onClick={this.handleResetFields}>Limpar</Button>{' '}
                                        <Button bsStyle="primary" onClick={this.handleSubmit}>Buscar</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
