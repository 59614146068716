export const LABEL = {
    SOLICITACAO_CARGA: 'Solicitação de Carga',
    TRANSFERIR: 'Transferir',
    GERENCIAR_MEUS_CARTOES: 'Gerenciar Meus Cartões'
};

export const METODOS = {
    GET_CARTOES: 'getCartoes',
    GET_CARTOES_ERRO: 'getCartoesErro',
    SET_EXIBIR_LOADING: 'setExibirLoading'
};

export const FILTRO_STATUS_CARTOES = {
    ATIVO: 'ativo',
    BLOQUEADO: 'bloqueado',
    CANCELADOS_E_OUTROS: 'canceladosEOutros',
    PRONTOS_PARA_ATIVACAO: 'prontoParaAtivacao'
};
