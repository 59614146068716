import React, { Component } from 'react';
import InputMask from 'react-input-mask';
import { Button, MenuItem, Glyphicon, Dropdown, FormGroup, HelpBlock, Tooltip, OverlayTrigger } from 'react-bootstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import ConsultaCargaLoteCartaoAction from './consulta-carga-lote-cartao-action';
import LoadingFenix from '../loading-fenix/loading-fenix';
import { reactLocalStorage } from 'reactjs-localstorage';
import './consulta-carga-lote-cartao.css';
import { CPF } from 'cpf_cnpj';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import Grid from '../componentes-uteis/grid/grid';
import { podeCriar } from '../../negocio/outorga/outorga-negocio';
const REMOVE_MASCARA_CPF_PATTERN = /\.|-|\/|\(|\)/g;

const consultaCargaLoteCartaoAction = new ConsultaCargaLoteCartaoAction();

const tooltip = (
    <Tooltip id="tooltip">
    Criar Carga em Lote Cartão
    </Tooltip>
);

class ConsultaCargaCartaoLote extends Component {

    componentDidMount() { }

    componentWillMount() {
        this.props.dispatch(consultaCargaLoteCartaoAction.buscarUsuarios());
    }

    /* FUNÇÕES PARA OS MODAIS */

    corpoModalOcultarErro() {
        return (
            <div>
                <span>Existe(m) erro(s) de validação. Favor corrigir.</span>
            </div>
        );
    }

    rodapeModalOcultarErro() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="primary" onClick={() => this.props.dispatch(consultaCargaLoteCartaoAction.setarOcultarErro())} >OK</Button>
                </div>
            </div>);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro"> Consulta de Arquivos de Carga em Lote de Cartão </div>
                            <div className="panel-body espacamento_div_filtro">
                                <div className="list-group">
                                    {this.exibirErro()}
                                    <form className="editForm" id="myForm">
                                        <div className="row form-group">
                                            <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2 lote_filtro">
                        Nome do Arquivo
                                                <input onChange={(e) => this.changeNomeArquivo(e)} type="text" maxLength="150" className="form-control" id="nomeArquivo" name="nomeArquivo" placeholder="Nome" style={{ height: '30px', paddingTop: '8px', paddingLeft: '10px' }} />
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2 lote_filtro">
                        Período de Importação
                                                <FormGroup validationState={this.props.meuStore.erroDataInicio != null ? 'error' : ''}>
                                                    <InputMask placeholder="DD/MM/AAAA" value={this.props.meuStore.dataDe} onChange={this.handleChangeDataDe} className="form-control" mask={this.props.meuStore.defaultMaskPattern} maskChar=" " id="dataDe" name="dataDe" style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                                                    <HelpBlock className="helpBlock dataHelper">{this.props.meuStore.erroDataInicio}</HelpBlock>
                                                </FormGroup>
                                            </div>
                                            <div className="col-xs-3 col-sm-3 col-md-2 col-lg-2 lote_filtro">
                                                <div style={{ marginTop: '17px' }}>
                                                    <FormGroup validationState={this.props.meuStore.erroDataFim != null ? 'error' : ''}>
                                                        <InputMask placeholder="DD/MM/AAAA" value={this.props.meuStore.dataAte} onChange={this.handleChangeDataAte} className="form-control" mask={this.props.meuStore.defaultMaskPattern} maskChar=" " id="dataAte" name="dataAte" style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                                                        <HelpBlock className="helpBlock dataHelper">{this.props.meuStore.erroDataFim}</HelpBlock>
                                                    </FormGroup>
                                                </div>
                                            </div>

                                            {/* <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 lote_filtro">
                        Usuário
                        <select id="usuario" name="usuario" className="form-control" onChange={this.handleChangeUsuario} style={{ height: "30px", paddingTop: "4px", paddingLeft: "10px" }}>
                          <option value=''>Selecione</option>
                          {this.carregarCombo()}
                        </select>
                      </div> */}
                                            <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 alinhamento-direita">
                                                <div style={{ marginTop: '20px' }}>
                                                    <Button bsStyle="primary" onClick={this.buscar} style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} >Buscar</Button>&nbsp;&nbsp;&nbsp;
                                                    <Button bsStyle="default" onClick={this.limparCampos} style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }} >Limpar</Button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                        <div className="div_flutuante">
                            {podeCriar(this.props.perfils) && <OverlayTrigger placement="top" overlay={tooltip}>
                                <Button bsStyle="primary" className="btn_ncrredondado"
                  block onClick={() => this.cargaLoteNovo()} >+</Button>
                            </OverlayTrigger>}
                            <br />
                            <Grid cols="11 11 11 11">
                                {/*<a href={ROTAS_CAMINHOS.TRANSFERENCIA}>
                  <Button style={{marginLeft: '-25px'}} type="button" bsStyle="primary">{'Voltar'}</Button>
                    </a>*/}
                                <Button style={{ marginLeft: '-25px' }} type="button" bsStyle="primary"
                  onClick={() => this.props.history.goBack()}
                >Voltar</Button>
                                <br /><br />
                            </Grid>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        {this.exibirLoading()}

                        {this.mostrarGrid()}
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div>
            </div >
        );
    }

  mostrarGrid = () => {
      if (this.props.meuStore.totalResultado === 0) {
          if (this.props) {
              if (!this.props.meuStore.ocultarGrid) {
                  return (
                      <div className="panel panel-default posicionamento_grid">
                          <div className="panel-body">
                              <div className="row">
                              </div>
                              <div className="list-group">
                                  <li className="list-group-item fundo-cinza-claro">
                                      <div className="row">
                                          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Nome Arquivo</b></div>
                                          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Rótulo</b></div>
                                          <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Data Importação</b></div>
                                          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Quantidade de Cargas</b></div>
                                          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Valor Total das Cargas</b></div>
                                          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2 titulo-tabelas lote alinhamento-centro"><b>Status</b></div>
                                          <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote alinhamento-centro"><b>Usuário</b></div>
                                          <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Ação</b></div>
                                      </div>
                                  </li>
                                  {this.arquivosList()}
                              </div>
                          </div>
                      </div>
                  );
              }
          }
      }
      else {
          return (
              <div className="panel panel-default posicionamento_grid">
                  <div className="panel-body">
                      <div className="row">
                          <div className="panel-heading">
                              <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 alinhamento-iconexcel">{this.exibirIconeExcel()}</div>
                              <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-total"><b>Total de arquivos de carga em lote de cartão: {this.props.meuStore.totalResultado}</b></div>
                          </div>
                      </div>
                      <div className="list-group">
                          <li className="list-group-item fundo-cinza-claro">
                              <div className="row">
                                  <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Nome Arquivo</b></div>
                                  <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote"><b>Rótulo</b></div>
                                  <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Data Importação</b></div>
                                  <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Quantidade de Cargas</b></div>
                                  <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Valor Total das Cargas</b></div>
                                  <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2 titulo-tabelas lote alinhamento-centro"><b>Status</b></div>
                                  <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 titulo-tabelas lote alinhamento-centro"><b>Usuário</b></div>
                                  <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 titulo-tabelas lote alinhamento-centro"><b>Ação</b></div>
                              </div>
                          </li>
                          {this.arquivosList()}
                      </div>
                      <br />
                      {this.botaoMaisregistros()}
                  </div>
              </div>
          );
      }
  }

  arquivosList = () => {

      const cargaLote = this.props.meuStore.cargaLote;
      if (cargaLote.length > 0) {
          const listItems = cargaLote.map((registro, idx) =>
              <li key={idx} className="list-group-item">
                  <div className="row">
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe">
                          <p className="carga_nomearquivo lote_grid_texto">{registro.nomeArquivoBucket}</p>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe">
                          <p className="carga_nomearquivo lote_grid_texto">{registro.rotulo}</p>
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-1 col-lg-1 texto-extrato-detalhe alinhamento-centro lote_grid_texto">
                          {moment(registro.dataImportacao).format('DD/MM/YYYY')}
                      </div>
                      <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 texto-extrato-detalhe alinhamento-centro lote_grid_texto">
                          {registro.quantidade}
                      </div>
                      <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 texto-extrato-detalhe alinhamento-centro lote_grid_texto">
              R$ {registro.valorTotalCargaFormatado}
                      </div>
                      <div className="col-xs-1 col-sm-1 col-md-2 col-lg-2 texto-extrato-detalhe alinhamento-centro lote_grid_texto" id={registro.id + '_status'}>
                          {this.formataStatus(registro.processado)}
                      </div>
                      <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 texto-extrato-detalhe alinhamento-centro lote_grid_texto">
                          {isNaN(registro.usuario) ? registro.usuario : CPF.format(registro.usuario)}
                      </div>
                      <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 alinhamento-centro">
                          {this.mostrarItemMenu(registro.processado, registro.id)}
                      </div>
                  </div>
              </li>
          );
          return (
              <div>{listItems}</div>
          );
      } else {
          return (
              <li className="list-group-item">
                  <div className="row">
                      <div className="col-md-12">
                          <span className="texto_nenhumregisto">Nenhum registro encontrado.</span>
                      </div>
                  </div>
              </li>
          );
      }
  }

  botaoMaisregistros() {
      let exibeBotao = false;
      if (this.props.meuStore.cargaLote.length > 0) {
          let tamanho = parseInt(reactLocalStorage.get('tamanhoPaginaConsultaCargaLote'), 10);
          if (this.props.meuStore.totalResultado > tamanho) {
              exibeBotao = true;
          }
      }
      if (exibeBotao) {
          return (
              <button
          type="button"
          className="btn btn-default btn-block fonte-tamanho-11"
          onClick={() => this.buscarMaisRegistros()}>
          VISUALIZAR MAIS REGISTROS
              </button>
          );
      } else {
          return (
              <div></div>
          );
      }
  }

  buscarMaisRegistros() {
      this.props.dispatch(consultaCargaLoteCartaoAction.setarBuscandoTrue(true));
      var dataDeFormatada = '';
      var dataAteFormatada = '';
      let tipoCargaLote = 'contaCartao';
      let contaId = reactLocalStorage.getObject('contaSelecionada').id;

      //caso exista string com nome e cpfs com mascara
      var usuario = this.props.meuStore.usuario.replace(REMOVE_MASCARA_CPF_PATTERN, '');
      if (isNaN(usuario)) {
      //se o resultado for uma string desfaz a remoção dos pontos
          usuario = this.props.meuStore.usuario;
      }
      usuario = reactLocalStorage.get('usuario');

      if (moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).isValid()) {
          dataDeFormatada = moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }
      if (moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).isValid()) {
          dataAteFormatada = moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }

      if (reactLocalStorage.get('tamanhoPaginaConsultaCargaLote') !== undefined) {
          let tamanho = parseInt(reactLocalStorage.get('tamanhoPaginaConsultaCargaLote'), 10);
          tamanho = tamanho + 10;
          reactLocalStorage.set('tamanhoPaginaConsultaCargaLote', tamanho);
      }
      this.props.dispatch(consultaCargaLoteCartaoAction.listarAction(this.props.meuStore.nomeArquivo, dataDeFormatada, dataAteFormatada, usuario, tipoCargaLote, contaId));

  }

  mostrarItemMenu = (status, idArquivo) => {
      if (this.props) {
          if (this.props.meuStore) {
              if (this.props.meuStore.idDoArquivoProcessado &&
          this.props.meuStore.idDoArquivoProcessado === idArquivo) {

                  document.getElementById(idArquivo + '_status').innerHTML = 'Processado';
                  document.getElementById(idArquivo).style.display = 'none';
              }
              else if (status === 0) return (
                  <div className="col-md-1" id={idArquivo}>
                      <Dropdown id="menuConta" pullRight >
                          <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas-lote">
                              <Glyphicon glyph="option-horizontal alinhamento-icone" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu >
                              <MenuItem onClick={(e) => this.gravarProcessarArquivo(e, idArquivo)}>Processar</MenuItem>
                          </Dropdown.Menu>
                      </Dropdown>
                  </div>);
          }
      }
  }

  formataStatus = (status) => {
      switch (status) {
          case (1): return 'Processado';
          default: return 'Importado';
      }
  }

  carregarCombo = () => {
      let usuarioCombo = reactLocalStorage.get('usuario');
      if (this.props.meuStore.usuarios !== undefined && this.props.meuStore.usuarios !== '') {
          return (
              <option>{isNaN(usuarioCombo) ? usuarioCombo : CPF.format(usuarioCombo)}</option>
          /* this.props.meuStore.usuarios.usuarios.map((registro, idx) => {
          return (
            <option key={idx}>{isNaN('68352478516') ? '68352478516' : CPF.format('68352478516')}</option>
          );
        }
        )*/
          );
      }
  }

  gravarProcessarArquivo = (ev, idArquivo) => {
      reactLocalStorage.set('idArquivoCargaLoteCartao', idArquivo);
      window.location.href = 'carga-lote-cartao-novo';
  }

  cargaLoteNovo() {
      reactLocalStorage.set('idArquivoCargaLoteCartao', '-1');
      window.location.href = 'carga-lote-cartao-novo';
  }

  changeNomeArquivo(event) {
      this.props.dispatch(consultaCargaLoteCartaoAction.changeNomeArquivo(event));
  }

  handleChangeDataDe = (ev) => {
      this.props.dispatch(consultaCargaLoteCartaoAction.changeDataDe(ev));
  }

  handleChangeDataAte = (ev) => {
      this.props.dispatch(consultaCargaLoteCartaoAction.changeDataAte(ev));
  }

  handleChangeUsuario = (ev) => {
      this.props.dispatch(consultaCargaLoteCartaoAction.changeUsuario(ev));
  }

  limparCampos = (ev) => {
      document.getElementById('nomeArquivo').value = '';
      // document.getElementById('usuario').value = '';   TODO entender porque estava sendo zerado o usuário. Dando erro Cannot set property 'value' of null
      this.props.dispatch(consultaCargaLoteCartaoAction.limparCampos());
  }

  buscar = (ev) => {
      this.props.dispatch(consultaCargaLoteCartaoAction.setarBuscandoTrue(true));
      var dataDeFormatada = this.props.meuStore.dataDe;
      var dataAteFormatada = this.props.meuStore.dataAte;
      let tipoCargaLote = 'contaCartao';
      let contaId = reactLocalStorage.getObject('contaSelecionada').id;

      //caso exista string com nome e cpfs com mascara
      var usuario = this.props.meuStore.usuario.replace(REMOVE_MASCARA_CPF_PATTERN, '');
      if (isNaN(usuario)) {
      //se o resultado for uma string desfaz a remoção dos pontos
          usuario = this.props.meuStore.usuario;
      }

      usuario = reactLocalStorage.get('usuario');

      if (moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).isValid()) {
          dataDeFormatada = moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }
      if (moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).isValid()) {
          dataAteFormatada = moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }
      reactLocalStorage.set('tamanhoPaginaConsultaCargaLote', 10);
      this.props.dispatch(consultaCargaLoteCartaoAction.listarAction(this.props.meuStore.nomeArquivo, dataDeFormatada, dataAteFormatada, usuario, tipoCargaLote, contaId));
  }

  exibirLoading = () => {
      if (this.props.meuStore.carregando) {
          return (
              <div className="alinhamento-centro">
                  <LoadingFenix />
              </div>
          );
      } else {
          return (
              <div></div>
          );
      }
  }

  exibirIconeExcel = () => {
      if (this.props) {
          if (this.props.meuStore.cargaLote.length > 0) {

              if (this.props.meuStore.cargaLoteParaCSV && this.props.meuStore.cargaLoteParaCSV.length > 0) {
                  downloadCSV({ filename: 'arquivos-cargas-lote-cartao.csv' }, this.props);
                  this.props.dispatch(consultaCargaLoteCartaoAction.limparDadosCSV());
                  return (
                      <div className="espacamento_excel">
                          <Button className="btn btn-success btn-sm" title="Exportar para .CSV" onClick={() => this.buscarCargaEmLoteParaCSV()}><i className="fa fa-file-excel-o" aria-hidden="true"></i></Button>
                      </div>);
              } else {
                  return (
                      <div className="espacamento_excel">
                          <Button className="btn btn-success btn-sm" title="Exportar para .CSV" onClick={() => this.buscarCargaEmLoteParaCSV()}><i className="fa fa-file-excel-o" aria-hidden="true"></i></Button>
                      </div>
                  );
              }
          }
      }
  }

  buscarCargaEmLoteParaCSV() {

      this.props.dispatch(consultaCargaLoteCartaoAction.setarBuscandoTrue(true));
      var dataDeFormatada = this.props.meuStore.dataDe;
      var dataAteFormatada = this.props.meuStore.dataAte;
      let tipoCargaLote = 'contaCartao';
      let contaId = reactLocalStorage.getObject('contaSelecionada').id;

      //caso exista string com nome e cpfs com mascara
      var usuario = this.props.meuStore.usuario.replace(REMOVE_MASCARA_CPF_PATTERN, '');
      if (isNaN(usuario)) {
      //se o resultado for uma string desfaz a remoção dos pontos
          usuario = this.props.meuStore.usuario;
      }

      usuario = reactLocalStorage.get('usuario');

      if (moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).isValid()) {
          dataDeFormatada = moment(this.props.meuStore.dataDe, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }
      if (moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).isValid()) {
          dataAteFormatada = moment(this.props.meuStore.dataAte, ['DD/MM/YYYY'], true).format('YYYY-MM-DD');
      }

      this.props.dispatch(consultaCargaLoteCartaoAction.buscarCargaLoteParaCSV(this.props.meuStore.nomeArquivo, dataDeFormatada, dataAteFormatada, usuario, tipoCargaLote, contaId));
  }

  exibirErro() {
      if (this.props.meuStore.err) {
          return (
              <ModalComponent name="ModalOcultarErro" exibirModal={this.props.meuStore.err}
          body={this.corpoModalOcultarErro()} header="Carga em Lote" footer={this.rodapeModalOcultarErro()}
        />
          );
      }
  }

  ocultarErro() {
      this.props.dispatch(consultaCargaLoteCartaoAction.setOcultarErro());
  }

}

function converterArrayParaCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data === null || !data.length) {
        return null;
    }

    columnDelimiter = args.columnDelimiter || ';';
    lineDelimiter = args.lineDelimiter || '\n';

    keys = Object.keys(data[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function(item) {
        ctr = 0;
        keys.forEach(function(key) {
            if (ctr > 0) result += columnDelimiter;

            result += item[key];
            ctr++;
        });
        result += lineDelimiter;
    });

    return result;
}

function downloadCSV(args, props) {
    var data, filename, link;
    var dados = [];

    var resultado = [];

    if (props.meuStore.cargaLoteParaCSV) {
        let arr = props.meuStore.cargaLoteParaCSV;
        for (let i in arr) {
            var item = {
                'NOME DO ARQUIVO': arr[i].nomeArquivoBucket,
                'RÓTULO': (arr[i].rotulo === null) ? '' : arr[i].rotulo,
                'DATA DA IMPORTAÇÃO': moment(arr[i].dataImportacao).format('DD/MM/YYYY'),
                'QUANTIDADE DE CARGAS': arr[i].quantidade,
                'VALOR TOTAL CARGAS': arr[i].valorTotalCarga,
                'STATUS': arr[i].processado ? 'PROCESSADO' : 'IMPORTADO',
                'USUÁRIO': arr[i].usuario
            };
            resultado.push(item);
        }

        dados = resultado;

        var csv = converterArrayParaCSV({
            data: dados
        });
        if (csv === null) return;

        filename = args.filename || 'export.csv';

        if (!csv.match(/^data:text\/csv/i)) {
            const universalBOM = '\uFEFF';
            csv = 'data:text/csv;charset=utf-8,' + universalBOM + csv;
        }
        data = encodeURI(csv);

        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', filename);
        link.click();
    }
}

function mapStateToProps(state) {

    return {
        meuStore: state.ConsultaCargaLoteCartaoReducer
    };
}
export default connect(mapStateToProps)(ConsultaCargaCartaoLote);
