import React, { Component } from 'react';

import DetalhesArquivo from '../../../../lote-arquivo/detalhes-arquivo/detalhes-arquivo';
import LoteArquivoHistorico from '../../../../lote-arquivo/historico/lote-arquivo-historico';
import { getLoteArquivoId } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { LABEL_COMUNS, CONTA_SELECIONADA } from '../../../../util/contantes-comuns/constantes-comuns';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';

class DetalhesBppPag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            arquivo: { historicos: [] },
            exibirModal: false,
            mensagem: '',
            loteArquivo: null,
        };
    }

    componentDidMount() {
        this.setState({ contaSelecionada: CONTA_SELECIONADA.OBJETO() });
        const { transferenciaEmLote } = this.props.history.location.state;
        const idArquivoDetalhe = transferenciaEmLote.id;
        this.carregaArquivoById(idArquivoDetalhe);
    }

    retornaRodape = () => (
        <FooterBotoesModalComponent
            btn1Label={LABEL_COMUNS.OK}
            btn1OnClick={() => this.setState({ exibirModal: false })}
        />
    );

    carregaArquivoById = idArquivoDetalhe => {
        getLoteArquivoId(idArquivoDetalhe).then(arquivoRecuperado => {
            if (arquivoRecuperado.errors) {
                const mensagem = 'teste';
                this.setState({ exibirModal: true, mensagem });
            } else {

                this.setState({
                    loteArquivo: arquivoRecuperado,
                });
            }
        });
    };

    render() {

        const { contaSelecionada, exibirModal, loteArquivo, mensagem } = this.state;

        return (
            <div>
                <ModalComponent
                    header={LABEL_COMUNS.DESTALHES_DO_ARQUIVO}
                    exibirModal={exibirModal}
                    body={mensagem}
                    footer={this.retornaRodape()}
                />
                {loteArquivo && <DetalhesArquivo contextoBppPag={true} history={this.props.history} contaSelecionada={contaSelecionada} arquivoLote={loteArquivo} />}
                {loteArquivo && <LoteArquivoHistorico contextoBppPag={true} history={this.props.history} loteArquivoHistorico={loteArquivo.historico} />}
            </div>
        );
    }
}
export default DetalhesBppPag;
