import React, { Component } from 'react';
import { Button, Dropdown, Glyphicon, FormGroup, ControlLabel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import ShowLineError from '../../../../comuns/showLineError/show-line-error';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { retornaObjetoTarget } from '../../../../../servicos/base/service-base';
import { getListaDeLoteArquivos } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import Grid from '../../../../componentes-uteis/grid/grid';
import If from '../../../../componentes-uteis/if/if';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import AtualizarPagina from '../../../../comuns/atualizar-pagina/atualizar-pagina';
import * as actions from '../../../../contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-action';
import Loading from '../../../../loading-fenix/loading';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { ACAO, CAMPOS, COMBO, CONTA_SELECIONADA, FILTROS, LABEL_COMUNS, MENSAGENS, PESSOA_LOGADA, MASCARA_CAMPOS, PRODUTOS, APROVAR_OU_REPROVAR_ARQUIVO } from '../../../../util/contantes-comuns/constantes-comuns';
import { formatarDataBR } from '../../../../util/data/dataUtil';
import { ESTILO, STATUS_VALIDACAO_COMPONENTES } from '../../../../util/estilo_para_componentes/estiloUtil';
import { formatarCampoValor } from '../../../../util/extrato/extrato-util';
import { aprovar, getMenuAcoes as MenuAcoes, reprovar } from './agendamentos-e-aprovacoes-bpp-pag-base';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { approveOrReproveArchiveApi } from 'core/api/transferenciaEmLoteRetornoApi';

export class AgendamentoEAprovacoesDashboardBppPag extends Component {
    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = {
            abrirCollapse: false, acaoArmazenada: '', dataDeInclusaoDe: '', dataDeInclusaoAte: '', idArquivoSequencialPorPessoaId: '',
            exibirModalConfirmacao: false, exibirModalTransferenciaEmLoteConfirmacao: false, exibirModalTransferenciaEmLoteFinalizacao: false,
            exibirModalTransferenciaEmLoteToken: false, exibirModalConfirmacaoAprovacao: false, exibirModalConfirmacaoReprovacao: false,
            mensagemConfirmacao: '', mensagemFinalizacao: '', operacaoSelecionadaId: '', transferenciaEmLote: {}, anexoContratualId: undefined,
            statusSelecionado: '', transferenciaEmLoteArmazenada: '', token: '', pessoaLogada: PESSOA_LOGADA(), exibirLoading: undefined,
            contaSelecionada: CONTA_SELECIONADA.OBJETO(), redirecionarParaTelaDeCarregarConta: false, redirecionarTelaDetalhesArquivo: false,
            listaDeTransferenciasEmLote: [],
        };
        this.state = this.ESTADO_INICIAL;
    }

    async componentDidMount() {
        const { contaSelecionada } = this.state;
        this.props.setContaSelecionada(contaSelecionada);
        const { id, pessoaTitularId } = contaSelecionada;
        this.setState({ contaId: id, pessoaId: pessoaTitularId });
        await this.buscarTransferenciasEmLote(id, pessoaTitularId);
    }

    buscarTransferenciasEmLote = async (contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId) => {
        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
        const resultadoDaBusca = await getListaDeLoteArquivos(FILTROS.TRANSFERENCIA_EM_LOTE.AGENDAMENTOS_E_APROVACOES(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId, PRODUTOS.pag));
        const { error, data: { resultado } } = resultadoDaBusca;
        if (!error) {
            this.setState({ listaDeTransferenciasEmLote: resultado });
        }
        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
    };

    componentWillReceiveProps(nextProps) {
        if (nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada && nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada !== this.props.agendamentoDashboardBppPag.transacaoEmLoteEfetuada) {
            this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.CONFIRMACAO);
            this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.FINALIZACAO);
            if (nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada.sucesso) {
                this.setPropsCampos(retornaObjetoTarget(MENSAGENS.TRANSFERENCIA_EM_LOTE.FINALIZACAO.SUCESSO.APROVAR_OU_REPROVAR_ARQUIVO(nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada.acaoRealizada.acao)), CAMPOS.MENSAGEM_FINALIZACAO);
            }
            else {
                this.setPropsCampos(retornaObjetoTarget(nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada.mensagem || MENSAGENS.TRANSFERENCIA_EM_LOTE.FINALIZACAO.ERRO.APROVAR_OU_REPROVAR_ARQUIVO(nextProps.agendamentoDashboardBppPag.transacaoEmLoteEfetuada.acaoRealizada.acao)), CAMPOS.MENSAGEM_FINALIZACAO);
            }
        }
    }

    setPropsCampos = (e, data) => {
        let valorDoCampo = e != null && e.value || e != null && e.target != null && e.target.value;
        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: '',
        });
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.agendamentoDashboardBppPag.solicitacaoTokenErro || this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token</ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.tokenId ? '' : this.state.tokenId}
                        onChange={(e) => this.setState({ tokenId: e.target.value })}
                        mask={MASCARA_CAMPOS.TOKEN}
                        maskChar={null}
                    />
                    <ShowLineError error={this.props.agendamentoDashboardBppPag.solicitacaoTokenErro || this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.verificaNecessitaOtp()}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken() {
        return (
            <FooterBotoesModalComponent
                contextoBppPag
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.props.setExibirModalToken(false);
                    this.setState({ tokenId: '' });
                }}
                btn2OnClick={() => {
                    if (this.state.tokenId && this.state.tokenId.length === 6) {
                        this.props.efetivaTransacao(this.state.operacao.id, this.state.operacao.op, this.state.tokenId);
                    } else {
                        this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
                    }
                }} />
        );
    }

    retornaRodapeModalTransferenciaEmLoteConfirmacao() {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.CONFIRMACAO)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    if (this.state.acaoArmazenada.acao === ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value) {
                        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.CONFIRMACAO);
                        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.TOKEN);
                        this.setPropsCampos(retornaObjetoTarget(''), CAMPOS.TOKEN);
                        this.props.solicitarTokenTransferenciaEmLote(this.state.pessoaLogada.id);
                    }
                    else {
                        this.props.efetivaTransacaoDeOperacoesEmLote(this.state.transferenciaEmLoteArmazenada.arquivoId, this.state.acaoArmazenada);
                    }
                }}

            />
        );
    }

    retornaRodapeModalFinalizacao() {
        const { contaId, pessoaId } = this.state;
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setState(this.ESTADO_INICIAL);
                    this.buscarTransferenciasEmLote(contaId, pessoaId);
                }}
            />
        );
    }

    limparFiltro = () => {
        this.setState({ dataDeInclusaoDe: '', dataDeInclusaoAte: '', operacaoSelecionadaId: '', statusSelecionado: '', idArquivoSequencialPorPessoaId: '' });
    };

    setRedirecionarParaTelaDeCarregarConta = (redirecionarParaTelaDeCarregarConta) => {
        this.setState({ redirecionarParaTelaDeCarregarConta });
    };

    retornaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                }}
                btn2Label={LABEL_COMUNS.CARREGAR_A_CONTA}
                btn2OnClick={() => this.setRedirecionarParaTelaDeCarregarConta(true)}
            />
        );
    }

    onClickAprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onClickReprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    // exibeMensagemMigracao(data) {
    //     if (data.entity && data.entity.codigo && data.entity.codigo === LABEL_COMUNS.CODIGO_ERRO_MIGRACAO_CORE) {
    //         return data.entity.erros[0].mensagem;
    //     }
    //     else return data.mensagem;
    // };

    onOpenModalFinalizacao = (nomeModalConfirmacao) => ((tipo, data) => {
        this.setState({
            exibirLoading: false,
            [nomeModalConfirmacao]: false,
            exibirModalTransferenciaEmLoteFinalizacao: true,
            mensagemFinalizacao: data.mensagem
        });
    }
    );

    onCloseModalConfirmacao = (nomeModalConfirmacao) => (
        () => (
            this.setState({
                [nomeModalConfirmacao]: false,
            })
        )
    )

    onAprovar = async () => {
        this.setState({ exibirLoading: true, exibirModalConfirmacaoAprovacao: false });

        const { transferenciaEmLote, anexoContratualId, } = this.state;

        const response = await approveOrReproveArchiveApi(transferenciaEmLote.id, anexoContratualId, APROVAR_OU_REPROVAR_ARQUIVO.ACAO.APROVAR);

        if (response.error || response === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.ERROR) {
            if (response.data && response.data.codigo === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.CODIGO) {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.MENSAGEM
                });
            } else {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.GENERICO
                });
            }
        }
        if (response.data && response.data.status === APROVAR_OU_REPROVAR_ARQUIVO.STATUS.APROVADO) {
            this.setState({
                exibirLoadinghHistoricoTransferenciaEmLote: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.SUCESSO.APROVADO
            });
        }

        this.setState({ exibirLoading: false });
    };

    onReprovar = () => {
        this.setState({ exibirLoading: true });

        const { transferenciaEmLote, anexoContratualId } = this.state;
        const functionName = 'exibirModalConfirmacaoReprovacao';

        reprovar(transferenciaEmLote.id, anexoContratualId, this.onCloseModalConfirmacao(functionName), this.onOpenModalFinalizacao(functionName));
    };


    render() {
        const {
            contaId, pessoaId, contaSelecionada, dataDeInclusaoDe, dataDeInclusaoDeErro, dataDeInclusaoAte, dataDeInclusaoAteErro, exibirLoading,
            idArquivoSequencialPorPessoaId, listaDeTransferenciasEmLote, operacaoSelecionadaId, statusSelecionado
        } = this.state;

        const { history } = this.props;
        if (this.state.redirecionarParaTelaDeCarregarConta) {
            return <Redirect to={ROTAS_CAMINHOS.PEDIDO_DE_CARGA} />;
        }
        if (this.state.redirecionarTelaDetalhesArquivo) {
            return <Redirect to={ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_DETALHES} />;
        }
        return (
            <div>
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemConfirmacao}
                    footer={this.retornaRodapeModalTransferenciaEmLoteConfirmacao()} />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteFinalizacao}
                    name="modalFinalizacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()} />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.SALDO_INSUFICIENTE}
                    footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoAprovacao}
                    name="modalConfirmacaoAprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao })}
                            btn2OnClick={this.onAprovar}
                        />
                    } />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoReprovacao}
                    name="modalConfirmacaoReprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.REPROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao })}
                            btn2OnClick={this.onReprovar}
                        />
                    } />
                <div className="panel panel-default">
                    <div className={this.props.className} style={{ height: '40px' }}>
                        <div className="col-sm-8 col-md-8 col-lg-8" style={{ marginLeft: '-15px' }}>
                            {LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                        </div>
                        <div className="col-sm-4 col-md-4 col-lg-4 botoes-cabecalho-dashboard">
                            <AtualizarPagina onClick={() => this.buscarTransferenciasEmLote(contaId, pessoaId)} />
                            <Button className={this.props.classNameBotao} onClick={() => this.setState({ abrirCollapse: !this.state.abrirCollapse })} >
                                <span className="glyphicon glyphicon-filter" />
                            </Button>
                            <Dropdown id="menuOpcoes" pullRight >
                                <Dropdown.Toggle noCaret className={ESTILO.COR_DE_FUNDO.FILTRO.BPP_PAG}>
                                    <Glyphicon glyph="option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <li>
                                        <Link className="link_expandir" to={ROTAS_CAMINHOS.AGENDAMENTO_APROVACOES_BPP_PAG}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>

                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico
                        contextoBppPag={this.props.contextoBppPag}
                        abrirCollapse={this.state.abrirCollapse}
                        mostrarFiltroDeDatas={true}
                        mostrarFiltroDeidNumerico={true}

                        mostrarFiltroDeOperacao={true}
                        mostrarFiltroDeStatus={true}

                        labelDataInicial={LABEL_COMUNS.DATA}
                        dataInicial={dataDeInclusaoDe}
                        setDataInicial={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_DE)}
                        dataInicialErro={dataDeInclusaoDeErro}

                        dataFinal={dataDeInclusaoAte}
                        setDataFinal={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_ATE)}
                        dataFinalErro={dataDeInclusaoAteErro}

                        operacaoSelecionadaId={operacaoSelecionadaId}
                        setOperacao={(e) => this.setPropsCampos(e, CAMPOS.OPERACAO_SELECIONADA_ID)}
                        listaDeOperacoes={COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE()}

                        listaDeStatus={COMBO.STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS}
                        setStatus={(e) => this.setPropsCampos(e, CAMPOS.STATUS_SELECIONADO)}
                        statusSelecionado={statusSelecionado}

                        idNumerico={idArquivoSequencialPorPessoaId}
                        idNumericoPlaceHolder={LABEL_COMUNS.TRANSFERENCIA_EM_LOTE.ID}
                        setIdNumerico={(e) => this.setPropsCampos(e, CAMPOS.ID_ARQUIVO_SEQUENCIAL_POR_PESSOA_ID)}

                        exibirBotaoLimpar={true}
                        limparFiltro={this.limparFiltro}
                        buscar={() => this.buscarTransferenciasEmLote(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId)}
                    />
                    <div className="panel-body" style={{ height: '350px', overflow: 'hidden'}}> 
                        {
                            exibirLoading ?
                                <div className="alinhamento-centro">
                                    <Loading />
                                </div>
                                :
                                exibirLoading !== undefined &&
                                <If test={listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.length > 0}>
                                    <div className="row">
                                        <Grid cols="12 12 12 12" className="grids-dashboards">
                                            <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ID}</span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao" >{LABEL_COMUNS.OPERACAO}</span>
                                            </Grid>

                                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.VALOR}</span>
                                            </Grid>

                                            <Grid cols="3 3 3 3">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DATA_DE_INCLUSAO}</span>
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.STATUS}</span>
                                            </Grid>
                                            <Grid cols="1 1 1 1" className="alinhamento-direita">
                                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ACAO}</span>
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <div className="row">
                                        <div className="grid-agendamentoDashboardBppPag">
                                            <div className="grid-agendamento-bpppag">
                                                {
                                                    listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.map((transferenciaEmLote, idx) => {
                                                        return (
                                                            <div className="row" key={idx}>
                                                                <Grid cols="12 12 12 12" className="alinhamento-centro">
                                                                    <Grid cols="1 1 1 1">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {transferenciaEmLote.idArquivoSequencialPorPessoaId}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {FILTROS.TRANSFERENCIA_EM_LOTE.OPERACAO(transferenciaEmLote.operacao).label}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {formatarCampoValor(transferenciaEmLote.tipo, transferenciaEmLote.valorProcessadoLote, transferenciaEmLote.moeda)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {formatarDataBR(transferenciaEmLote.dataCriacao)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {transferenciaEmLote.statusExibicao}
                                                                        </span>
                                                                    </Grid>
                                                                    {this.props.outorgaPerfils.podeAprovar &&
                                                                        <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                                            <MenuAcoes
                                                                                transferenciaEmLote={transferenciaEmLote}
                                                                                history={history}
                                                                                onClickAprovar={() => this.onClickAprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}
                                                                                onClickReprovar={() => this.onClickReprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}
                                                                                podeAprovar={this.props.outorgaPerfils.podeAprovar}

                                                                            />
                                                                        </Grid>}
                                                                </Grid>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </If>
                        }
                        <If test={listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.length <= 0 && !exibirLoading}>
                            <span>{LABEL_COMUNS.NENHUM_AGENDAMENTO_ENCONTRADO}</span>
                        </If>
                    </div>
                </div>
            </div>
        );
    }
}


const mapStateToProps = (state) => ({
    agendamentoDashboardBppPag: state.AgendamentoReducer, outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    }
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoEAprovacoesDashboardBppPag);
