import { STATUS_REPRESENTANTE } from './altera-cadastro-empresa-constantes';
import { removeMascaraTelefoneCpfCnpj } from '../util/formata-string';
import ValidaCpf from '../util/validador/validaCpf';
import ValidaCNPJ from '../util/validador/validaCnpj';

const validaCpf = new ValidaCpf();
const validaCNPJ = new ValidaCNPJ();

export function validarUsuarioAdmin(listaUsuariosAdmin, usuarioAdmin) {
    let erros = [];

    if (!verificarCamposVazios(usuarioAdmin.pessoaDocumento, usuarioAdmin.pessoaNome)) {
        if (temPessoaNaLista(listaUsuariosAdmin, usuarioAdmin)) {
            let erro = {};
            erro.campo = 'cpfUsuarioAdminErro';
            erro.mensagem = 'CPF já adicionado';
            erros.push(erro);
        } else {
            let errosValidaCpf = validarCpfUsuarioAdmin(usuarioAdmin.pessoaDocumento);

            errosValidaCpf.map((erro) => {
                return erros.push(erro);
            });

            if (usuarioAdmin.pessoaDocumento === '' || usuarioAdmin.pessoaDocumento === undefined) {
                let erro = {};
                erro.campo = 'cpfUsuarioAdminErro';
                erro.mensagem = 'Campo obrigatório';
                erros.push(erro);
            }

            if (usuarioAdmin.pessoaNome === '' || usuarioAdmin.pessoaNome === undefined) {
                let erro = {};
                erro.campo = 'nomeUsuarioAdminErro';
                erro.mensagem = 'Campo obrigatório';
                erros.push(erro);
            }
        }
    }

    return erros;
}

export function validarCpfUsuarioAdmin(documento) {
    let erros = [];

    if (!validaCpf.isValid(documento) && documento !== '') {
        let erro = {};
        erro.campo = 'cpfUsuarioAdminErro';
        erro.mensagem = 'CPF no formato inválido';
        erros.push(erro);
    }

    return erros;
}

export function validarRepresentanteLegal(listaRepresentanteLegal, representanteLegal, tipoMascara) {
    let erros = [];

    if (!verificarCamposVazios(representanteLegal.pessoaDocumento, representanteLegal.pessoaNome)) {
        if (temPessoaNaLista(listaRepresentanteLegal, representanteLegal)) {
            let erro = {};
            erro.campo = 'cpfRepresentanteLegalErro';
            erro.mensagem = 'Documento já adicionado';
            erros.push(erro);
        } else {
            let errosValidaCpfCnpj = validarCpfCnpjRepresentanteLegal(representanteLegal.pessoaDocumento, tipoMascara);

            errosValidaCpfCnpj.map((erro) => {
                return erros.push(erro);
            });

            if (representanteLegal.pessoaDocumento === '' || representanteLegal.pessoaDocumento === undefined) {
                let erro = {};
                erro.campo = 'cpfRepresentanteLegalErro';
                erro.mensagem = 'Campo obrigatório';
                erros.push(erro);
            }

            if (representanteLegal.pessoaNome === '' || representanteLegal.pessoaNome === undefined) {
                let erro = {};
                erro.campo = 'nomeRepresentanteLegalErro';
                erro.mensagem = 'Campo obrigatório';
                erros.push(erro);
            }
        }
    }

    return erros;
}

export function validarCpfCnpjRepresentanteLegal(documento, tipoMascara) {
    let erros = [];

    if (documento !== '' && tipoMascara.toUpperCase() === 'CPF' && !validaCpf.isValid(documento)) {
        let erro = {};
        erro.campo = 'cpfRepresentanteLegalErro';
        erro.mensagem = 'CPF no formato inválido';
        erros.push(erro);
    } else if (documento !== '' && tipoMascara.toUpperCase() === 'CNPJ' && !validaCNPJ.isValid(documento)) {
        let erro = {};
        erro.campo = 'cpfRepresentanteLegalErro';
        erro.mensagem = 'CNPJ no formato inválido';
        erros.push(erro);
    }

    return erros;
}

export function temPessoaNaLista(lista, pessoa) {
    let temNaLista = false;

    let listaResposta = lista.filter(
        (elem) => {
            let retornoTemNaLista = false;
            if (elem.status !== STATUS_REPRESENTANTE.REMOVE) {
                retornoTemNaLista = removeMascaraTelefoneCpfCnpj(elem.pessoaDocumento) === removeMascaraTelefoneCpfCnpj(pessoa.pessoaDocumento);
            }
            return retornoTemNaLista;
        }
    );

    if (listaResposta.length > 0) {
        temNaLista = true;
    }

    return temNaLista;
}

export function verificarCamposVazios(campo1, campo2) {
    let vazio = false;

    if ((!campo1) && (!campo2)) {
        vazio = true;
    }

    return vazio;
}

export function validarEmail(listaEmail, email) {
    let erros = [];

    listaEmail.map((emailAtual) => {
        if (emailAtual.email === email) {
            let erro = {};
            erro.campo = 'emailErro';
            erro.mensagem = 'Email já adicionado';
            return erros.push(erro);
        }
        return undefined;
    });

    return erros;
}

export function validarTelefone(telefone) {
    let erros = [];

    if (!verificarCamposVaziosTelefone(telefone.numero, telefone.contato, telefone.ramal, telefone.departamento)) {
        if (telefone.numero === '' || telefone.numero === undefined) {
            let erro = {};
            erro.campo = 'numeroTelefoneErro';
            erro.mensagem = 'Campo obrigatório';
            erros.push(erro);
        }

        if (telefone.contato === '' || telefone.contato === undefined) {
            let erro = {};
            erro.campo = 'contatoTelefoneErro';
            erro.mensagem = 'Campo obrigatório';
            erros.push(erro);
        }
    }

    return erros;
}

export function verificarCamposVaziosTelefone(campo1, campo2, campo3, campo4) {
    let vazio = false;

    if ((!campo1) && (!campo2) && (!campo3) && (!campo4)) {
        vazio = true;
    }

    return vazio;
}

export function validarCelular(celular) {
    let erros = [];

    if (!verificarCamposVazios(celular.numero, celular.contato)) {
        if (celular.numero === '' || celular.numero === undefined) {
            let erro = {};
            erro.campo = 'numeroCelularErro';
            erro.mensagem = 'Campo obrigatório';
            erros.push(erro);
        }

        if (celular.contato === '' || celular.contato === undefined) {
            let erro = {};
            erro.campo = 'contatoCelularErro';
            erro.mensagem = 'Campo obrigatório';
            erros.push(erro);
        }
    }

    return erros;
}
