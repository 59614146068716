import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import { CONTA_SELECIONADA, LABEL_COMUNS, OPERACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import * as cartaoAction from 'action/cartoes/cartoes-action';
import { setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador } from 'componentes/comuns/portador/vincular-portador/vincular-portador-action';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { Button } from 'react-bootstrap';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';
import GridCartoesBppPag from 'componentes/solucoes/dashboard/bpp-pag/cartoes/grid-cartoes-bpp-pag';
import If from 'componentes/componentes-uteis/if/if';
import VincularPortador from 'componentes/comuns/portador/vincular-portador/vincular-portador';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import { podeAprovar, podeConsultar, podeCriar, outorgaParametroOperacao } from 'reducer/outorga/outorga-operacao-reducer';
import CancelarCartao from 'componentes/cartao/cancelar-cartao/cancelar-cartao';
import GridCartoesBbpPagFavoritos from 'componentes/solucoes/dashboard/bpp-pag/cartoes/favoritos/grid-cartoes-bbp-pag-favoritos';

class ListaCartoesBppPag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showCancelCardModal: false, showLockCardModal: false, showFinalizationModal: false, showUnlockCardModal: false, showUnlockFinalizationModal: false,
            showCancelFinalizationModal: false, showLockFinalizationModal: false, abrirCartoesProntosParaAtivacao: false
        };
    }

    componentDidMount() {
        this.props.setClearState();
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.getFavoritos(contaSelecionada.id, this.props.produtosIdsBppPag);
        this.props.limparFiltroCartao();
        this.setState({ mounted: true });
    }

    setShowLockCardModal = (showLockCardModal) => {
        this.setState({ showLockCardModal });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    setAbrirCartoesProntosParaAtivacao = (abrirCartoesProntosParaAtivacao) => {
        this.setState({ abrirCartoesProntosParaAtivacao });
    };

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setAbrirCartoesCancelados = (abrirCartoesCancelados) => {
        this.setState({ abrirCartoesCancelados });
    }

    setAbrirCartoesProntosParaAtivacao = (abrirCartoesProntosParaAtivacao) => {
        this.setState({ abrirCartoesProntosParaAtivacao });
    }

    setAbrirCartoesFavoritos = (abrirCartoesFavoritos) => {
        this.setState({ abrirCartoesFavoritos });
    }


    getFavoritos(contaSelecionadaId, produtosIdsBppPag) {
        this.props.getCartoesFavoritos(contaSelecionadaId, produtosIdsBppPag, '', '', '', '', true);
    }

    buscarFiltro = async () => {
        this.setState({ loading: true });
        const { cartaoId, cartaoQuatroUltimosDigitos, contaSelecionada, quantidadeTotalRegistros, posicao} = this.props.estadoCartoes;
        const { produtosIdsBppPag } = this.props;
        this.props.getCartoesFavoritos(contaSelecionada.id, produtosIdsBppPag, cartaoId, cartaoQuatroUltimosDigitos, 20, posicao, false);
    }

    getCartoes = (constaSelecionadaId, produtosIdsBppPag) => {

        const { contaSelecionada } = this.props.estadoCartoes;
        const { produtosIdsBppPag: produtosPag } = this.props;
        this.props.getCartoes(constaSelecionadaId ? constaSelecionadaId : contaSelecionada.id, produtosIdsBppPag ? produtosIdsBppPag : produtosPag);
    }

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    render() {
        const { abrirCartoesCancelados, abrirCartoesProntosParaAtivacao, showCancelCardModal,
            showCancelFinalizationModal,
        } = this.state;

        const { cartaoSelecionado, contaSelecionada, exibirLoadingCartao, listaDeCartoesFavoritos, listaDeCartoesCancelados,
            listaDeCartoesProntosParaAtivacaoEBloqueados, posicao, quantidadeTotalRegistros} = this.props.estadoCartoes;
        const { grantChangeStatusCard, outorgaPerfils } = this.props;

        return (
            <div>

                <div className="alinhamento-centro">
                    <If test={exibirLoadingCartao}>
                        <LoadingFenix />
                    </If>
                </div>

                <VincularPortador
                    cartaoSelecionado={this.props.estadoCartoes.cartaoSelecionado}
                    contaSelecionada={this.props.estadoCartoes.contaSelecionada}
                    setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador} />


                <CancelarCartao
                    contextoBppPag={true}
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                    grantChangeStatusCard={grantChangeStatusCard}
                    grantProfile={outorgaPerfils}
                />

                <Grid cols="12 12 12 12" className="espacamento_conteudo_paginas">
                    <If test={contaSelecionada}>
                        <ContaSelecionada
                            contextoBppPag={true}
                            contaSelecionada={contaSelecionada}
                            exibirSaldo={true}
                            exibirFiltroCartoes={true}
                            cartaoId={this.props.estadoCartoes.cartaoId}
                            setCartaoId={this.props.setCartaoId}
                            cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                            setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                            cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}
                            buscar={() => { this.buscarFiltro(); }}
                            limparFiltro={this.props.limparFiltroCartao}
                            listaParaSerconvertidaEmCSV={this.props.estadoCartoes.listaDeCartoesRetornados}
                        />
                    </If>
                </Grid>
                <If test={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacaoEBloqueados.length > 0}>
                    <GridCartoesBppPag
                        status={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}
                        labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}
                        setArmazenarCartao={this.props.setArmazenarCartao}
                        quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacaoEBloqueados.length}
                        listaCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacaoEBloqueados}
                        abrirCartoes={abrirCartoesProntosParaAtivacao}
                        setAbriSecaoCartao={() => this.setAbrirCartoesProntosParaAtivacao(!this.state.abrirCartoesProntosParaAtivacao)}
                        tipoDeAcesso={reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)} exibirModalVincularPortador={() => this.props.setExibirModalVincularPortador(true)}
                        setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                        setShowUnlockCardModal={this.setShowUnlockCardModal}
                        setShowLockCardModal={this.setShowLockCardModal}
                        setShowCancelCardModal={this.setShowCancelCardModal}
                        setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                        setCartaoSelecionadoParaVincularPortador={this.props.setCartaoSelecionadoParaVincularPortador}
                        exibirMudarTitularidade={true}
                        outorgaPerfils={outorgaPerfils}
                    />
                </If>

                <If test={listaDeCartoesCancelados && listaDeCartoesCancelados.length > 0}>
                    <GridCartoesBppPag
                        status={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS}
                        contextoBppPag={true}
                        labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS}
                        setArmazenarCartao={this.props.setArmazenarCartao}
                        quantidadeCartoes={listaDeCartoesCancelados.length}
                        listaCartoes={listaDeCartoesCancelados}
                        abrirCartoes={abrirCartoesCancelados}
                        setAbriSecaoCartao={() => this.setAbrirCartoesCancelados(!abrirCartoesCancelados)}
                        setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        outorgaPerfils={outorgaPerfils}
                    />
                </If>

                <If test={listaDeCartoesFavoritos && listaDeCartoesFavoritos.length > 0}>
                    <GridCartoesBbpPagFavoritos
                        {...this.props}
                        excluiCartao={(cartao) => { this.excluiCartao(cartao); }}
                        labelTitulo={LABEL_COMUNS.FAVORITOS}
                        quantidadeCartoes={listaDeCartoesFavoritos.length}
                        tipoDeAcesso={reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)}
                        listaCartoes={listaDeCartoesFavoritos}
                        outorgaPerfils={outorgaPerfils}
                        contaSelecionada={contaSelecionada}
                        posicao={posicao}
                        quantidadeTotalRegistros={quantidadeTotalRegistros}
                        buscar={() => { this.buscarFiltro(); }}
                    />
                </If>
                <If test={(!exibirLoadingCartao && (listaDeCartoesFavoritos && listaDeCartoesFavoritos.length < 1) && (listaDeCartoesCancelados && listaDeCartoesCancelados.length < 1) && (listaDeCartoesProntosParaAtivacaoEBloqueados && listaDeCartoesProntosParaAtivacaoEBloqueados.length < 1))}>
                    <div className="alinhamento-centro">{LABEL_COMUNS.NAO_HA_REGISTROS}</div>
                </If>

                {/* {(!listaDeCartoesFavoritos || listaDeCartoesFavoritos.length === 0) && (!listaDeCartoesFavoritos || listaDeCartoesFavoritos.length === 0) && (!listaDeCartoesProntosParaAtivacaoEBloqueados || listaDeCartoesProntosParaAtivacaoEBloqueados.length == 0) && <div style={{ marginLeft: 20 }}>{LABEL_COMUNS.NAO_HA_REGISTROS}</div>} */}


                <Grid cols="11 11 11 11" className="alinhamento-direita espacamento_conteudo_paginas">
                    <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG} onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    estadoCartoes: state.CartoesReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    grantChangeStatusCard: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERA_STATUS_CARTAO),
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...cartaoAction, setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesBppPag);

