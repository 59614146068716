import React from 'react';
import { CARGA_LOTE_CARTAO_BPP_CORP, MENSAGENS, OPERACOES_TRANSFERENCIA_EM_LOTE } from '../../../../util/contantes-comuns/constantes-comuns';
import imagemExemploArquivoCsvCargaCartao from '../../../../../imagens/exemplo-arquivo-csv-carga-cartao.png';
import imagemExemploArquivoCsvEntreContas from '../../../../../imagens/exemplo-arquivo-csv-transferencia-entre-contas.png';
import imagemExemploArquivoCsvTed from '../../../../../imagens/exemplo-arquivo-csv-transferencia-ted.png';

const getMensagens = () => {
    const {
        MODAL_INSTRUCOES_LINK_ARQUIVO_MODELO,
        MODAL_TRANSFERENCIA_EM_LOTE_INSTRUCOES
    } = MENSAGENS.TRANSFERENCIA_EM_LOTE;

    const {
        ITEM_COMUM_1,
        ITEM_COMUM_2,
        ITEM_COMUM_3,
        ITENS_ESPECIFICOS,
        ITEM_COMUM_IMAGEM
    } = MODAL_TRANSFERENCIA_EM_LOTE_INSTRUCOES.CSV;

    return {
        getLink: MODAL_INSTRUCOES_LINK_ARQUIVO_MODELO,
        linha1: ITEM_COMUM_1,
        linha2: ITEM_COMUM_2,
        linha3: ITEM_COMUM_3,
        imagem: ITEM_COMUM_IMAGEM,
        getInstrucoes: ITENS_ESPECIFICOS
    };
};

const getImagemInstrucaoArquivoModeloCSV = (operacao) => ({
    [CARGA_LOTE_CARTAO_BPP_CORP.value]: imagemExemploArquivoCsvCargaCartao,
    [OPERACOES_TRANSFERENCIA_EM_LOTE.CARGA_CARTAO.value]: imagemExemploArquivoCsvCargaCartao,
    [OPERACOES_TRANSFERENCIA_EM_LOTE.TRANSFERENCIA_ENTRE_CONTAS.value]: imagemExemploArquivoCsvEntreContas,
    [OPERACOES_TRANSFERENCIA_EM_LOTE.TED.value]: imagemExemploArquivoCsvTed
})[operacao];

const ModalInstrucoesExemploCSV = (operacao) => {
    const {
        getLink,
        linha1,
        linha2,
        linha3,
        imagem,
        getInstrucoes
    } = getMensagens();

    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>1. </strong>
                        <i>
                            <u>
                                <a href={getLink ? getLink(operacao) : ''} download>
                                    {linha1}
                                </a>
                            </u>
                        </i>
                    </span>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>2. </strong>
                        {linha2}
                    </span>
                    <br />
                    <ul>
                        {getInstrucoes && getInstrucoes(operacao).map((instrucao, index) => (<li key={index}>{instrucao}</li>))}
                    </ul>
                    {imagem && <span>&nbsp;&nbsp;&nbsp;&nbsp;{imagem}</span>}
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <div id="img-exemplo-csv-scroll-modal" className="text-center">
                        <img src={getImagemInstrucaoArquivoModeloCSV(operacao)} alt={'Exemplo arquivo .CSV'} />
                    </div>
                </div>
            </div>
            <br />
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                    <span>
                        <strong>3. </strong>
                        {linha3}
                    </span>
                </div>
            </div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{marginTop: 30}}>
                    <span>
                        <strong>Obs.: </strong>
                        Para validação do arquivo a formatação da célula deverá estar como “texto” na coluna “documentoFavorecido”. Pois caso o documento inicie com o número zero e a célula esteja em outra formatação o arquivo ficará pendente de correção.
                    </span>
                </div>
            </div>
        </div>
    );
};

export default ModalInstrucoesExemploCSV;
