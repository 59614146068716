import Moment, * as moment from 'moment';
import { NOME_MESES } from '../contantes-comuns/constantes-comuns';

const LOCALE_PT_BR = 'pt-br';

export const NUMBER_PATTERN = /\d+/g;
export const MASCARA_DE_DATA_BR = '99/99/9999';
export const FORMATO_DE_DATA_BR = 'DD/MM/YYYY';
export const FORMATO_DE_DATA_ANO_DOIS_DIGITOS_BR = 'DD/MM/YY';
export const FORMATO_DE_DATA_US = 'YYYY-MM-DD';
export const FORMATO_PADRAO_LOCAL_DATE_TIME = 'YYYY-MM-DD HH:mm:ssZZ';
export const FORMATO_DE_DATA_HORA_BR = 'DD/MM/YYYY - HH:mm';
export const FORMATA_DATA_E_HORA_BR = 'DD/MM/YY - HH:mm';
export const FORMATO_DE_DATA_HORA_MINUTO_E_SEGUNDO = 'DD/MM/YYYY - HH:mm:ss';
export const FORMATO_DE_HORA_MINUTO_E_SEGUNDO = 'HH:mm:ss';
export const INVALID_DATE = 'Invalid date';
export const DATA_INVALIDA = 'Data inválida.';
const MONTH = 'month';

export function formatarDataUS(data) {
    return moment(data, FORMATO_DE_DATA_BR, true).format(FORMATO_DE_DATA_US);
}

export function dataBRParaMoment(data) {
    return moment(data, FORMATO_DE_DATA_BR, true);
}

export function formatarDataBR(data) {
    Moment.locale('pt-br');
    return (moment.utc(data).format(FORMATO_DE_DATA_BR));
}

export function converteLocalDateTimeParaDataPtBr(data) {
    return (moment.utc(data, FORMATO_PADRAO_LOCAL_DATE_TIME).format(FORMATO_DE_DATA_BR));
}

export function formatarDataHoraBR(data) {
    Moment.locale('pt-br');
    if (!data) {
        data = new Date();
    }
    return (Moment(data).format(FORMATA_DATA_E_HORA_BR));
}

export function formatarDataEHoraBR(data) {
    Moment.locale('pt-br');
    if (!data) {
        data = new Date();
    }
    return (Moment(data).format(FORMATO_DE_DATA_HORA_BR));
}

export function formatarDataCompletaBR(data) {
    Moment.locale('pt-br');
    if (!data) {
        data = new Date();
    }
    return moment.utc(data, FORMATO_PADRAO_LOCAL_DATE_TIME).format(FORMATO_DE_DATA_HORA_MINUTO_E_SEGUNDO);
}

export function recuperaNumerosDeUmaData(data) {
    return data.match(NUMBER_PATTERN);
}

export function converterDataBRParaUS(data) {
    const FORMATO_DE_DATA = data.length === 8 ? FORMATO_DE_DATA_ANO_DOIS_DIGITOS_BR : FORMATO_DE_DATA_BR;
    return moment(data, FORMATO_DE_DATA, true).format(FORMATO_DE_DATA_US);
}

export function validarDataBR(data) {
    const FORMATO_DE_DATA = data.length === 8 ? FORMATO_DE_DATA_ANO_DOIS_DIGITOS_BR : FORMATO_DE_DATA_BR;
    if (data.length !== 8 && data.length !== 10) {
        return false;
    }
    return moment.utc(data, FORMATO_DE_DATA).isValid();
}

export function dataInvalida(data) {
    if (data !== '__/__/____' && data !== '') {
        return (formatarDataUS(data) === INVALID_DATE);
    } else {
        return false;
    }
}

export function dataValida(data) {
    return !dataInvalida(data);
}

export function dataMaiorQueHoje(data) {
    let retorno = false;
    let partesData = data.split('/');
    let objData = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    if (objData > new Date()) {
        retorno = true;
    }
    return retorno;
}

export function dataMenorQueHoje(dataParaValidacao) {
    let partesData = dataParaValidacao.split('/');
    let data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    let parseHoje = hoje().split('-');
    let today = new Date(parseHoje[0], parseHoje[1] - 1, parseHoje[2]);
    if (data < today) {
        return true;
    }
    return false;
}

export function dataMenorIgualAHoje(dataParaValidacao) {
    let partesData = dataParaValidacao.split('/');
    let data = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    let parseHoje = hoje().split('-');
    let today = new Date(parseHoje[0], parseHoje[1] - 1, parseHoje[2]);
    if (data <= today) {
        return true;
    }
    return false;
}

export const dataAtualPtBr = (formato) => {
    Moment.globalLocale = LOCALE_PT_BR;
    let now = Moment().toDate();
    now = Moment(now).format(formato);
    return now;
};

export function retornaDataDeDiasPassados() {
    return moment().subtract(6, 'days').format(FORMATO_DE_DATA_US);
}

export function formatarDataMesExtenso(data) {
    Moment.locale('en');
    return (Moment(data).format('DD') + ' de ' + retornaMesExtenso(data));
}

export const ckeckDataIsFormated = (data) => {
    return (moment(data, FORMATO_DE_DATA_US).format(FORMATO_DE_DATA_US) === data);
};

function retornaMesExtenso(data) {
    switch (Moment(data).format('MM')) {
        case '01': {
            return 'Janeiro';
        }
        case '02': {
            return 'Fevereiro';
        }
        case '03': {
            return 'Março';
        }
        case '04': {
            return 'Abril';
        }
        case '05': {
            return 'Maio';
        }
        case '06': {
            return 'Junho';
        }
        case '07': {
            return 'Julho';
        }
        case '08': {
            return 'Agosto';
        }
        case '09': {
            return 'Setembro';
        }
        case '10': {
            return 'Outubro';
        }
        case '11': {
            return 'Novembro';
        }
        default:
            return 'Dezembro';
    }
}

export function hoje() {
    return (moment.utc(new Date()).format(FORMATO_DE_DATA_US));
}

export function formataHoraPtBr(data) {
    return (moment.utc(data, FORMATO_PADRAO_LOCAL_DATE_TIME).format(FORMATO_DE_HORA_MINUTO_E_SEGUNDO));
}

export const validaDataEntreIntervalo = (dataParaValidacao, dataInicioInclusive, dataFinalInclusive) =>
    (moment(dataParaValidacao, FORMATO_DE_DATA_BR).isSameOrAfter(moment(dataInicioInclusive, FORMATO_DE_DATA_BR)) &&
        moment(dataParaValidacao, FORMATO_DE_DATA_BR).isSameOrBefore(moment(dataFinalInclusive, FORMATO_DE_DATA_BR)));

export function validaDataMaiorQueUmAno(dataParaValidacao) {
    let partesData = dataParaValidacao.split('/');
    let dataConvertida = new Date(partesData[2], partesData[1] - 1, partesData[0]);
    let dataFutura = moment().add(1, 'years').format(FORMATO_DE_DATA_US);
    let parseDataFutura = dataFutura.split('-');
    let dataFuturaConvertida = new Date(parseDataFutura[0], parseDataFutura[1] - 1, parseDataFutura[2]);
    if (dataConvertida > dataFuturaConvertida) {
        return true;
    }
    return false;
}

export const validaFormatoDataPt_Br = data => {
    const regex = /(\d{2})\/(\d{2})\/(\d{4})/;
    return data.match(regex);
};

export const validaFormatoDataAnoComDoisDigitosPt_Br = data => {
    const regex = /(\d{2})\/(\d{2})\/(\d{2})/;
    return data.match(regex);
};

export const validaDiferencaDeDataEntreHojeEUmAno = date => {
    const d0 = moment();
    const maxDate = moment(d0).add(1, 'years');
    const aux = moment(date.replace(/\//g, ''), 'DDMMYYYY');
    return aux.isSameOrAfter(d0, 'day') && aux.isSameOrBefore(maxDate);
};

export const getCurrentYear = () => {
    return moment(hoje()).year();
};

export const getCurrentMonth = () => {
    return moment(hoje()).month();
};

export const getLastSixMonthCombo = () => {
    let hoje = new Date();
    let mesValue = { value: hoje, label: NOME_MESES[hoje.getMonth()] + ' ' + hoje.getFullYear() };
    let listaDeMeses = [];
    for (let i = 0; i < 6; i++) {
        let mesAnterior = new Date();
        let newMonth = mesAnterior.getMonth() - i;
        mesAnterior.setMonth(newMonth);
        let mesAnteriorObject = { value: mesAnterior, label: NOME_MESES[mesAnterior.getMonth()] + ' ' + mesAnterior.getFullYear() };
        listaDeMeses.push(mesAnteriorObject);
    }
    return listaDeMeses;
};

export const parseDate = (dateToParse) => {
    return moment(dateToParse);
};

export const setHourAndMinutesAndSeconds = (dateToAddTime) => {
    const strDate = dateToAddTime.toISOString().split('T').shift();
    const strTime = '23:59:59';
    const dateToReturn = moment(strDate + ' ' + strTime).toDate();
    return dateToReturn;
};

export const getFirstDayCurrentMoth = () => {
    return moment().startOf(MONTH).format(FORMATO_DE_DATA_BR);
};

export const getLastDayCurrentMoth = () => {
    return moment().endOf(MONTH).format(FORMATO_DE_DATA_BR);
};

export const getFirstDayByYearAndMonth = (year, month) => {
    const firstDayOfMonth = moment(new Date(year, month, 1)).format(FORMATO_DE_DATA_BR);
    return firstDayOfMonth;
};

export const getLastDayByYearAndMonth = (year, month) => {
    const lastDayOfMonth = moment(new Date(year, month, 1)).daysInMonth();
    return moment(new Date(year, month, lastDayOfMonth)).format(FORMATO_DE_DATA_BR);
};

export const getLastDayCurrentByYearAndMonth = (year, month) => {
    if (month === getCurrentMonth()) {
        return moment().subtract(1, 'day').format(FORMATO_DE_DATA_BR);
    }
    const lastDayOfMonth = moment(new Date(year, month, 1)).daysInMonth();
    return moment(new Date(year, month, lastDayOfMonth)).format(FORMATO_DE_DATA_BR);
};
