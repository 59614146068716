import { CPF, CNPJ } from 'cpf_cnpj';
import { getSaldoContaOuCartao, fetchGetFavorecidosConta, fetchCadastrarFavorecido, fetchGetBancos, fetchAlterarFavorecido, fetchExcluirFavorecido } from './contas-base';
import { BANCO, CAMPOS, DDI, LABEL_COMUNS, OPERACAO, TIPO_CONTA, TIPO_DE_PESSOA, TIPO_DE_TRANSACAO, TIPO_DOCUMENTO, TIPO_FAVORECIDO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { montaUrlFetch, urlServicos } from '../url-servicos';
import { reactLocalStorage } from 'reactjs-localstorage';
import { abreOperacao, efetivaTransacao } from '../operacoes/operacoes-base';

export const getSaldoPorContaId = async (contaId) => {
    return await getSaldoContaOuCartao(contaId)
        .then(({ data: { saldo, saldoBloqueado } }) => ({ saldo, saldoBloqueado }))
        .catch(error => error);
};

export const criarConta = (dadosConta) => {
    return abreOperacao(retornaJsonCriacaoDeConta(dadosConta))
        .then(({ data: { rel } }) => ({ operacaoId: rel }))
        .then(async ({ operacaoId }) => await efetivaTransacao(operacaoId, TIPO_DE_TRANSACAO.APROVACAO))
        .then((result) => {
            return result;
        }).catch((error) => {
            return error;
        });
};

export const saldoMaiorQueZero = async (contaId) => {
    const saldoRetornado = await getSaldoContaOuCartao(contaId);
    if (!saldoRetornado.error) {
        const { data: { saldo } } = saldoRetornado;
        return saldo > 0;
    }
    return false;
};

export const getBancos = async () => {
    let listaDeBancos = [];
    await fetchGetBancos()
        .then((result) => {
            listaDeBancos = retornaBancosParaCombo(result.data);
        }).catch((err) => {
            listaDeBancos = err.data;
        });
    return listaDeBancos;
};

export const salvarFavorecido = async (dadosFavorecido) => {
    const { contaId, favorecidoId } = dadosFavorecido;
    dadosFavorecido = retornaJsonDadosFavorecidoEnvioBackEnd(dadosFavorecido);
    let resposta;
    if (favorecidoId) {
        resposta = await fetchAlterarFavorecido(contaId, favorecidoId, dadosFavorecido).catch(error => error);
    } else {
        resposta = await fetchCadastrarFavorecido(contaId, dadosFavorecido).catch(error => error);
    }
    return resposta;
};

export const excluirFavorecido = async (contaId, favorecidoId) => {
    const resposta = await fetchExcluirFavorecido(contaId, favorecidoId).catch(error => error);
    return resposta;
};

export const getFavorecidosConta = async (contaId) => {
    let listaDeFavorecidos;
    await fetchGetFavorecidosConta(contaId)
        .then((result) => {
            const { data: { registros } } = result;
            listaDeFavorecidos = registros;
            listaDeFavorecidos.map(favorecido => {
                if (favorecido.tipoFavorecido === TIPO_FAVORECIDO.CONTA_INTERNA.value) {
                    favorecido.nomeBanco = BANCO.BPP.value;
                }

                if (favorecido.tipoDocumento === TIPO_DOCUMENTO.CPF.value) {
                    favorecido.numeroDoDocumento = CPF.format(favorecido.numeroDocumento);
                }
                if (favorecido.tipoDocumento === TIPO_DOCUMENTO.CNPJ.value) {
                    favorecido.numeroDoDocumento = CNPJ.format(favorecido.numeroDocumento);
                }

                let favorecidosOrdenados = Object.keys(favorecido)
                    .filter(f => f === CAMPOS.NOME_BANCO || f === CAMPOS.AGENCIA || f === CAMPOS.CONTA);
                favorecidosOrdenados.sort(ordenarPorChave);
                favorecido.dadosBancarios = favorecidosOrdenados
                    .map(key => tratarDadosBancarios(favorecido, key))
                    .join(' / ');
            });
        }).catch((err) => {
            listaDeFavorecidos = err;
        });
    return listaDeFavorecidos;
};

export const retornaBancosParaCombo = (bancos) => {
    let listaDeBancos = [];
    bancos.map((banco) => {
        return listaDeBancos.push({ value: banco.codigo, label: banco.codigo + ' - ' + banco.nome });
    });
    return listaDeBancos;
};

export const trataErrosFavorecidos = (erros) => {
    const errosParaRetornar = {};
    erros && erros.map(erro => {
        switch (erro.campo) {
            case CAMPOS.AGENCIA:
                errosParaRetornar.agenciaSelecionadaErro = erro.mensagem;
                break;
            case CAMPOS.BANCO:
                errosParaRetornar.bancoSelecionadoIdErro = erro.mensagem;
                break;
            case CAMPOS.CONTA:
                errosParaRetornar.contaFavorecidoSelecionadaErro = erro.mensagem;
                break;
            case CAMPOS.DIGITO:
                errosParaRetornar.digitoSelecionadoErro = erro.mensagem;
                break;
            case CAMPOS.EMAIL:
                errosParaRetornar.emailSelecionadoErro = erro.mensagem;
                break;
            case CAMPOS.NOME:
                errosParaRetornar.nomeSelecionadoErro = erro.mensagem;
                break;
            case CAMPOS.NUMERO_DOCUMENTO:
                errosParaRetornar.numeroDocumentoSelecionadoErro = erro.mensagem;
                break;
            case CAMPOS.TELEFONE:
                errosParaRetornar.telefoneSelecionadoErro = erro.mensagem;
                break;
            case CAMPOS.TIPO_CONTA:
                errosParaRetornar.tipoContaSelecionadaErro = erro.mensagem;
                break;
            default:
                break;
        }
    });
    return errosParaRetornar;
};

export const retornaJsonDadosFavorecidoEnvioBackEnd = (dadosForm) => {
    let dadosNormalizados = {
        tipoFavorecido: dadosForm.tipoFavorecidoSelecionado,
        nome: dadosForm.nomeSelecionado,
        tipoDocumento: dadosForm.tipoDocumentoSelecionado,
        numeroDocumento: removeMascaraTelefoneCpfCnpj(dadosForm.numeroDocumentoSelecionado),
        banco: dadosForm.bancoSelecionadoId,
        tipoConta: dadosForm.tipoContaSelecionada,
        agencia: dadosForm.agenciaSelecionada,
        conta: dadosForm.contaFavorecidoSelecionada,
        digito: dadosForm.digitoSelecionado,
        telefone: dadosForm.telefoneSelecionado ? DDI.BRASIL + removeMascaraTelefoneCpfCnpj(dadosForm.telefoneSelecionado) : null,
        email: dadosForm.emailSelecionado ? dadosForm.emailSelecionado : null
    };
    dadosNormalizados = JSON.stringify(dadosNormalizados);
    return dadosNormalizados;
};

export const montarContas = (contas, pessoaLogada) => {
    const empresas = reactLocalStorage.getObject(LABEL_COMUNS.LISTA_EMPRESAS);
    let empresasQueSouUsuarioAdmin = [];

    if (empresas instanceof Array) {
        empresasQueSouUsuarioAdmin = empresas;
    }

    contas.forEach(conta => {
        const { dadosPessoa, pessoaTitularId } = conta;
        const { tipo } = dadosPessoa || {};
        if (tipo === TIPO_DE_PESSOA.PESSOA_FISICA) {
            conta.dadosPessoa = montaDadosPessoaFisica(pessoaTitularId, dadosPessoa);
        } else {
            conta.dadosPessoa = montaDadosPessoaJuridica(pessoaTitularId, dadosPessoa);
        }
    });

    const contaQueSouTitular = [{
        contas: contas.filter(conta => conta.pessoaTitularId === pessoaLogada.id),
        pessoaSelecionada: pessoaLogada
    }];

    const contasQueNaoSouTitular = contas.filter(conta => conta.pessoaTitularId !== pessoaLogada.id) || [];

    contasQueNaoSouTitular.sort((a, b) => {
        if (a.dadosPessoa.tipo === TIPO_DE_PESSOA.PESSOA_FISICA && b.dadosPessoa.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
            return -1;
        }
        if (a.dadosPessoa.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA && b.dadosPessoa.tipo === TIPO_DE_PESSOA.PESSOA_FISICA) {
            return 1;
        }
        return 0;
    });

    const pessoasIdsDeContasQueNaoSouTitular = [...new Set([...contasQueNaoSouTitular.map(e => e.pessoaTitularId), ...empresasQueSouUsuarioAdmin.map(emp => emp.id)])];

    const contasQueSouAdmin = pessoasIdsDeContasQueNaoSouTitular.map(pessoIdDaContaQueNaoSouTitular => {
        const filtroDeContasQueNaoSouTitular = contasQueNaoSouTitular.find(c => c.pessoaTitularId === pessoIdDaContaQueNaoSouTitular);
        return {
            contas: contasQueNaoSouTitular.filter(c => c.pessoaTitularId === pessoIdDaContaQueNaoSouTitular),
            pessoaSelecionada: (filtroDeContasQueNaoSouTitular && filtroDeContasQueNaoSouTitular.dadosPessoa) || empresasQueSouUsuarioAdmin.find(emp => emp.id === pessoIdDaContaQueNaoSouTitular)
        };
    });

    return [...contaQueSouTitular, ...contasQueSouAdmin];
};

const montaDadosPessoaFisica = (pessoaTitularId, dadosPessoa) => {
    const { documento, nome, tipo, usuarioAdmin } = dadosPessoa;
    const dadosPessoaFisicaParaRetornar = { id: pessoaTitularId, documento: [], pessoaFisica: {}, tipo, usuarioAdmin };
    dadosPessoaFisicaParaRetornar.documento.push(documento);
    dadosPessoaFisicaParaRetornar.pessoaFisica.nomeCompleto = nome;
    return dadosPessoaFisicaParaRetornar;
};

const montaDadosPessoaJuridica = (pessoaTitularId, dadosPessoa) => {
    const { documento, nome, tipo, usuarioAdmin } = dadosPessoa;
    const dadosPessoaFisicaParaRetornar = { id: pessoaTitularId, documento: [], pessoaJuridica: {}, tipo, usuarioAdmin };
    dadosPessoaFisicaParaRetornar.documento.push(documento);
    dadosPessoaFisicaParaRetornar.pessoaJuridica.razaoSocial = nome;
    return dadosPessoaFisicaParaRetornar;
};

const tratarDadosBancarios = (favorecido, chave) => {
    if (chave === CAMPOS.CONTA) {
        return favorecido[chave].concat('-'.concat(favorecido.digito));
    }
    return favorecido[chave];
};

const ordenarPorChave = (a, b) => {
    if (a === CAMPOS.NOME_BANCO) {
        return -1;
    }

    if (a === CAMPOS.AGENCIA && b !== CAMPOS.NOME_BANCO) {
        return -1;
    } else {
        return 1;
    }

    if (a === CAMPOS.CONTA && (b !== CAMPOS.NOME_BANCO || b !== CAMPOS.AGENCIA)) {
        return -1;
    } else {
        return 1;
    }

    return 0;
};

const retornaJsonCriacaoDeConta = (dadosConta) => {
    const { apelidoConta, anexoContratualOriginalId } = dadosConta;
    const body = {
        operacao: OPERACAO.NOME.CRIACAO_CONTA_MOVIMENTO,
        valor: 0,
        anexoContratualOriginalId: anexoContratualOriginalId,
        dadosIntegracao: {
            apelido: apelidoConta || null
        }
    };
    return body;
};
