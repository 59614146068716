import { reactLocalStorage } from 'reactjs-localstorage';
import { CARTAO_STATUS, LABEL_COMUNS, METODOS, MENSAGENS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaTitulDialogCartao, filtraCartoes, trataErrosAlterarTitularidadeCartao } from '../../componentes/util/cartoes/cartoes-util';

const ESTADO_INICIAL = {
    contaSelecionada: '',
    cartaoSelecionado: '',
    exibirCartoesAtivos: true,
    exibirCartoesBloqueados: false,
    cartaoId: '',
    nomePortador: '',
    numeroDocumentoPortador: '',
    cartaoIdErro: '',
    nomePortadorErro: '',
    numeroDocumentoPortadorErro: '',
    exibirCartoesProntosParaAtivacao: false,
    exibirCartoesCartoesCanceladosEOutros: false,
    listaDeCartoesAtivos: [],
    listaDeCartoesBloqueados: [],
    listaDeCartoesCancelados: [],
    listaDeCartoesCanceladosEOutros: [],
    listaDeCartoesFavoritos: [],
    listaDeCartoesProntosParaAtivacao: [],
    listaDeCartoesProntosParaAtivacaoEBloqueados: [],
    listaDeCartoesRetornados: [],
    exibirModalCargaEDescargaCartao: false,
    operacaoSelecionada: '',
    cartaoQuatroUltimosDigitos: '',
    exibirModarConfirmacaoCartao: false,
    exibirModalFinalizacaoCartao: false,
    mensagemFinalizacao: '',
    tituloModalConfirmacao: '',
    tituloModalFinalizacao: '',
    exibirModalTokenCartao: false,
    tokenSMS: '',
    mensagemToken: '',
    exibirLoadingCartao: undefined,
    tokenTransferenciaCartaoId: '',
    tokenOperacaoId: '',
    operacaoComCartaoRecuperada: '',
    operacaoComCartaoRecuperadaErro: '',
    posicao: '',
    quantidadeTotalRegistros: '',
    exibirModalCartaoOperacaoEmAndamento: false,
    msgListaVazia: '',
    msgErroAoBuscarFavoritos: '',
    cartoesPaginados: []
};

export default (state = ESTADO_INICIAL, action) => {
    let cartoesRetornados;
    let cartoesExpandido;
    let quantidadeRegistros;
    let errosTransferenciaTitularidade;
    let mensagemErro;
    let operacaoRecuperada;
    switch (action.type) {
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.CARTAO.SET_ARMAZENAR_CARTAO:
            reactLocalStorage.setObject(LABEL_COMUNS.CARTAO_SELECIONADO, action.payload);
            return { ...state, cartaoSelecionado: action.payload };
        case METODOS.CARTAO.SET_EXIBIR_MODAL_CARGA_OU_DESCARGA_CARTAO:
            return { ...state, exibirModalCargaEDescargaCartao: action.payload };
        case METODOS.CARTAO.SET_OPERACAO_CARTAO_SELECIONADA:
            return {
                ...state, operacaoSelecionada: action.payload, tituloModalConfirmacao: retornaTitulDialogCartao(action.payload),
                tituloModalFinalizacao: retornaTitulDialogCartao(action.payload)
            };
        case METODOS.CARTAO.SET_CARTAO_ID:
            return { ...state, cartaoId: action.payload };
        case METODOS.CARTAO.SET_NOME_PORTADOR_CARTAO:
            return { ...state, nomePortador: action.payload, nomePortadorErro: '' };
        case METODOS.CARTAO.SET_NUMERO_DOCUMENTO_PORTADOR_CARTAO:
            return { ...state, numeroDocumentoPortador: action.payload, numeroDocumentoPortadorErro: '' };
        case METODOS.CARTAO.GET_CARTOES:
            cartoesRetornados = action.payload.registros;
            return {
                ...state,
                listaDeCartoesRetornados: cartoesRetornados,
                listaDeCartoesAtivos: filtraCartoes(cartoesRetornados, CARTAO_STATUS.ATIVO),
                listaDeCartoesBloqueados: filtraCartoes(cartoesRetornados, CARTAO_STATUS.BLOQUEADO),
                listaDeCartoesCancelados: filtraCartoes(cartoesRetornados, CARTAO_STATUS.CANCELADO),
                listaDeCartoesProntosParaAtivacao: filtraCartoes(cartoesRetornados, CARTAO_STATUS.PRONTOS_PARA_ATIVACAO),
                listaDeCartoesProntosParaAtivacaoEBloqueados: cartoesRetornados.filter(c => c.status === CARTAO_STATUS.BLOQUEADO || c.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO),
                listaDeCartoesCanceladosEOutros: filtraCartoes(cartoesRetornados, CARTAO_STATUS.CANCELADOS_E_OUTROS),
                msgListaVazia: cartoesRetornados.length > 0 ? '' : 'Não há registros para o filtro informado'
            };
        case METODOS.CARTAO.GET_CARTOES_PAGINADO:
            cartoesExpandido = action.payload.registros;
            quantidadeRegistros = action.payload.registros.length;
            state.cartoesPaginados.push(...cartoesExpandido);
            cartoesExpandido = state.cartoesPaginados;
            return {
                ...state,
                quantidadeRegistros,
                cartoesExpandido,
                listaDeCartoesRetornados: cartoesExpandido,
                listaDeCartoesAtivos: filtraCartoes(cartoesExpandido, CARTAO_STATUS.ATIVO),
                listaDeCartoesBloqueados: filtraCartoes(cartoesExpandido, CARTAO_STATUS.BLOQUEADO),
                listaDeCartoesCancelados: filtraCartoes(cartoesExpandido, CARTAO_STATUS.CANCELADO),
                listaDeCartoesProntosParaAtivacao: filtraCartoes(cartoesExpandido, CARTAO_STATUS.PRONTOS_PARA_ATIVACAO),
                listaDeCartoesProntosParaAtivacaoEBloqueados: cartoesExpandido.filter(c => c.status === CARTAO_STATUS.BLOQUEADO || c.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO),
                listaDeCartoesCanceladosEOutros: filtraCartoes(cartoesExpandido, CARTAO_STATUS.CANCELADOS_E_OUTROS),
                msgListaVazia: cartoesExpandido.length > 0 ? '' : 'Não há registros para o filtro informado'
            };
        case METODOS.CARTAO.LIMPAR_FILTRO_CARTAO:
            return {
                ...state, cartaoId: action.payload, nomePortador: action.payload,
                numeroDocumentoPortador: action.payload, cartaoQuatroUltimosDigitos: action.payload, msgListaVazia: ''
            };
        case METODOS.CARTAO.GET_CARTOES_ERRO:
            return { ...state, exibirLoading: false };
        case METODOS.CARTAO.SET_CARTAO_QUATRO_ULTIMOS_DIGITOS:
            return { ...state, cartaoQuatroUltimosDigitos: action.payload };
        case METODOS.CARTAO.CANCELAR_CARTAO_SALDO_ERRO:
            return {
                ...state, mensagemFinalizacao: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.ERRO_SALDO(action.payload.quatroUltimosDigitos),
                exibirModarConfirmacaoCartao: false, exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.CANCELAR_CARTAO_ERRO:
            return {
                ...state, mensagemFinalizacao: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.ERRO(),
                exibirModarConfirmacaoCartao: false, exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.CANCELAR_CARTAO:
            return {
                ...state, mensagemFinalizacao: MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.SUCESSO(),
                exibirModarConfirmacaoCartao: false, exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.BLOQUEAR_CARTAO:

            return {
                ...state,
                mensagemFinalizacao: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(state.operacaoSelecionada),
                exibirModarConfirmacaoCartao: false,
                exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.BLOQUEAR_CARTAO_ERRO:
            return {
                ...state,
                mensagemFinalizacao: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO(state.operacaoSelecionada),
                exibirModarConfirmacaoCartao: false,
                exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO:
            return {
                ...state, tokenOperacaoId: action.payload, exibirModarConfirmacaoCartao: false, exibirModalTokenCartao: true
            };
        case METODOS.CARTAO.SOLICITAR_DESBLOQUEIO_DE_CARTAO_ERRO:
            return {
                ...state,
                mensagemFinalizacao: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO(state.operacaoSelecionada),
                exibirModarConfirmacaoCartao: false,
                exibirModalFinalizacaoCartao: true
            };
        case METODOS.CARTAO.DESBLOQUEIO_DE_CARTAO:
            return {
                ...state,
                exibirModalTokenCartao: false,
                exibirModalFinalizacaoCartao: true,
                mensagemFinalizacao: MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO(state.operacaoSelecionada)
            };
        case METODOS.CARTAO.DESBLOQUEIO_DE_CARTAO_ERRO:
            return {
                ...state, mensagemToken: action.payload.erros[0].mensagem
            };
        case METODOS.CARTAO.SET_EXIBIR_MODAL_CONFIRMACAO_CARTAO:
            return { ...state, exibirModarConfirmacaoCartao: action.payload };
        case METODOS.CARTAO.SET_EXIBIR_MODAL_FINALIZACAO_CARTAO:
            return { ...state, exibirModalFinalizacaoCartao: action.payload };
        case METODOS.CARTAO.SET_EXIBIR_MODAL_TOKEN_CARTAO:
            return { ...state, exibirModalTokenCartao: action.payload, mensagemToken: '', tokenSMS: '' };
        case METODOS.CARTAO.SET_ARMAZENAR_TOKEN_SMS_CARTAO:
            return { ...state, tokenSMS: action.payload, mensagemToken: '' };
        case METODOS.CARTAO.SOLICITAR_TOKEN_CARTAO:
            return { ...state, tokenOperacaoId: action.payload.rel };
        case METODOS.CARTAO.SOLICITAR_TOKEN_CARTAO_ERRO:
            return { ...state, mensagemToken: action.payload.erros[0].mensagem };
        case METODOS.CARTAO.SET_EXIBIR_LOADING_CARTAO:
            return { ...state, exibirLoadingCartao: action.payload };
        case METODOS.CARTAO.SOLICITAR_TOKEN_ALTERAR_TITULARIDADE_CARTAO:
            return { ...state, tokenTransferenciaCartaoId: action.payload.id };
        case METODOS.CARTAO.SOLICITAR_TOKEN_ALTERAR_TITULARIDADE_CARTAO_ERRO:
            return { ...state, erroAoSolicitarToken: action.payload };
        case METODOS.CARTAO.SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO:
            return {
                ...state,
                sucessoTansferenciaTitularidadeCartao: !!action.payload,
                mensagemFinalizacaoAlteracaoTitularidadeCartao: MENSAGENS.CARTAO.ALTERAR_TITULARIDADE_CARTAO.FINALIZACAO.SUCESSO
            };
        case METODOS.CARTAO.SOLICITAR_TRANSFERENCIA_TITULARIDADE_CARTAO_ERRO:
            errosTransferenciaTitularidade = trataErrosAlterarTitularidadeCartao(action.payload.erros);
            if (errosTransferenciaTitularidade.erroGenerico) {
                mensagemErro = errosTransferenciaTitularidade.erroGenerico;
                delete errosTransferenciaTitularidade.erroGenerico;
            }
            return {
                ...state,
                errosAoEfetuarTranserenciaDeAlterarTitutaridadeCartao: errosTransferenciaTitularidade,
                mensagemErroDeAlteracaoTitularidadeCartao: mensagemErro
            };
        case METODOS.CARTAO.SET_EXIBIR_MODAL_CARTAO_OPERACAO_EM_ANDAMENTO:
            return { ...state, exibirModalCartaoOperacaoEmAndamento: action.payload };
        case METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO:
            operacaoRecuperada = action.payload.resultado[0];
            return { ...state, operacaoComCartaoRecuperada: operacaoRecuperada, exibirModalCartaoOperacaoEmAndamento: true, exibirModarConfirmacaoCartao: false };
        case METODOS.CARTAO.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO_ERRO:
            return { ...state, operacaoComCartaoRecuperada: action.payload, exibirModarConfirmacaoCartao: false };
        case METODOS.CARTAO.LIMPAR_ESTADO_CARTOES:
            return ESTADO_INICIAL;
        case METODOS.CARTAO.LIMPAR_MENSAGEM_DE_ERRO_DE_ALTERACAO_DE_TITULARIDADE_CARTAO:
            return { ...state, errosAoEfetuarTranserenciaDeAlterarTitutaridadeCartao: undefined, mensagemErroDeAlteracaoTitularidadeCartao: undefined };
        case METODOS.CARTAO.LIMPAR_CARTOES:
            return {
                ...state,
                cartoesPaginados: action.payload,
                listaDeCartoesRetornados: action.payload,
                listaDeCartoesAtivos: action.payload,
                listaDeCartoesCancelados: action.payload,
                listaDeCartoesBloqueados: action.payload,
                listaDeCartoesProntosParaAtivacao: action.payload,
                listaDeCartoesProntosParaAtivacaoEBloqueados: action.payload,
                listaDeCartoesCanceladosEOutros: action.payload,
                listaDeCartoesFavoritos: action.payload
            };
        case METODOS.CARTAO.SET_MSG_LISTA_VAZIA:
            return { ...state, msgListaVazia: action.payload };
        case METODOS.CARTAO.GET_CAROTES_FAVORITOS:
            const listaAnterior = state.listaDeCartoesFavoritos;
            action.payload.forEach(cartao => listaAnterior.push(cartao));
            return { ...state, listaDeCartoesFavoritos: listaAnterior };
        case METODOS.CARTAO.GET_CAROTES_FAVORITOS_POSICAO:
            return { ...state, posicao: action.payload};
        case METODOS.CARTAO.GET_CAROTES_FAVORITOS_QTD:
            return { ...state, quantidadeTotalRegistros: action.payload };
        case METODOS.CARTAO.DECREMENT_CAROTES_FAVORITOS_QTD:
            const novaQuantidade = state.quantidadeTotalRegistros - 1;
            return { ...state, quantidadeTotalRegistros: novaQuantidade };
        case METODOS.CARTAO.GET_CAROTES_FAVORITOS_ERRO:
            return { ...state, msgErroAoBuscarFavoritos: action.payload };
        case METODOS.CARTAO.SET_CARTOES_FAVORITOS:
            return { ...state, listaDeCartoesFavoritos: action.payload };
        case METODOS.CARTAO.SET_CLEAR_STATE:
            return ESTADO_INICIAL;
        default:
            return state;
    }
};
