import { METODOS } from './AppConstantes';

const initialState = {
    menuInicio: true,
    menuInicioContaSelecionada: false,
    menuTitular: true,
    menuTitularContaSelecionada: false,
    menuEmpresa: false,
    menuSolucao: false,
    menuDepartamento: false,
    menuContaDigital: false,
    menuSair: true,
    exibirModalAtendimento: false,
    contextBppPag: false,
    contextoMeusDepartamentos: false
};

const app = (state = initialState, action) => {
    switch (action.type) {
        case METODOS.SET_MODAL_ATENDIMENTO:
            return {
                ...state,
                exibirModalAtendimento: action.payload
            };
        case METODOS.SET_MENU_INICIO:
            return {
                ...state,
                menuInicio: action.payload
            };
        case METODOS.SET_MENU_INICIO_CONTA_SELECIONADA:
            return {
                ...state,
                menuInicioContaSelecionada: action.payload
            };
        case METODOS.SET_MENU_TITULAR:
            return {
                ...state,
                menuTitular: action.payload
            };
        case METODOS.SET_MENU_TITULAR_CONTA_SELECIONADA:
            return {
                ...state,
                menuTitularContaSelecionada: action.payload
            };
        case METODOS.SET_MENU_EMPRESA:
            return {
                ...state,
                menuEmpresa: action.payload
            };
        case METODOS.SET_MENU_SOLUCAO:
            return {
                ...state,
                menuSolucao: action.payload
            };
        case METODOS.SET_MENU_DEPARTAMENTO:
            return {
                ...state,
                menuDepartamento: action.payload
            };
        case METODOS.SET_MENU_CONTA_DIGITAL:
            return {
                ...state,
                menuContaDigital: action.payload
            };
        case METODOS.SET_MENU_SAIR:
            return {
                ...state, menuSair: action.payload
            };
        case METODOS.SET_CONTEXT_BPP_PAG:
            return {
                ...state, contextBppPag: action.payload
            };
        case METODOS.SET_CONTEXT_MEUS_DEPARTAMENTOS:
            return { ...state, contextoMeusDepartamentos: action.payload };
        default:
            return state;
    }
};

export default app;
