import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaPerfisEPoderesOutorga, retornaPoderesOutorga } from '../../servicos/outorga/outorga-base';

export const setExibirLoadingOutorga = (exibirLoadingOutorga) => ({
    type: METODOS.OUTORGA.SET_EXIBIR_LOADING_OUTORGA, payload: exibirLoadingOutorga
});

export const getPoderesOutorga = () => {
    return async dispatch => {
        dispatch(setExibirLoadingOutorga(true));
        const p = await Promise.all([
            retornaPerfisEPoderesOutorga(),
            retornaPoderesOutorga()
        ])
            .then((result) => {
                let listaDePerfisOutorga = result[0].data.valor.perfil;
                const listaDePoderesOutorga = result[1].data;
                dispatch({ type: METODOS.OUTORGA.GET_PODERES_OUTORGA, payload: { listaDePerfisOutorga, listaDePoderesOutorga } });
            }).catch((err) => {
                dispatch({ type: METODOS.OUTORGA.GET_PODERES_OUTORGA_ERRO, payload: err.data });
            }).finally(() => dispatch(setExibirLoadingOutorga(false)));
        return p;
    };
};

export const setListaParametroOperacao = (listaOperacao) => ({
    type: METODOS.OUTORGA.SET_LISTA_PARAMETRO_OPERACAO, payload: listaOperacao
});

export const cleanListaParametroOperacao = () => ({
    type: METODOS.OUTORGA.CLEAN_LISTA_PARAMETRO_OPERACAO
});

export const setPerfils = (perfils) => ({
    type: METODOS.OUTORGA.SET_PERFILS, payload: perfils
});
