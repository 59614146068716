/**
 * classe que herda as funcionalidades do reducer
 * porém é nescessario implementar o metodo reducer(), para
 * ter a passagem do state e action controlada pelo combineReducers()
 * que esta em Reducers()
 */

import { CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX } from './novo-carga-lote-cartao-controlador';

const st = {
    arquivo: undefined,
    dadosUrlPreAssinada: undefined,
    err: undefined,
    respostaPutArquivosS3: undefined,
    respostaPostConteudoArquivo: undefined,
    dadosContaTitular: undefined,
    respostaGravarArquivo: undefined,
    respostaProcessarArquivo: undefined,
    carregando: false,
    exibirModalGravar: false,
    exibirModalSucessoGravar: false,
    exibirModalProcessar: false,
    exibirModalSucessoProcessar: false,
    finalizado: false,
    rotuloArquivoCargaLote: ''
};

export default function reducer(state = st, action) {
    switch (action.type) {
    // Mudar nome de arquivo

        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[0] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                arquivo: action.payload,
                dadosUrlPreAssinada: undefined,
                err: undefined,
                respostaPutArquivosS3: undefined,
                respostaPostConteudoArquivo: undefined,
                dadosContaTitular: undefined,
                respostaGravarArquivo: undefined,
                respostaProcessarArquivo: undefined
                // carregando: false
            };
        }

        // obterUrlPreAssinada() {
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[1] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                dadosUrlPreAssinada: action.payload
                // carregando: true,
            };
        }

        // erro
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                err: action.payload,
                carregando: false,
                arquivo: undefined
            };
        }

        // putArquivosDocumentosEmS3(url, file)
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[3] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                respostaPutArquivosS3: action.payload
                // carregando: true
            };
        }

        // postConteudoArquivoCarga
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                respostaPostConteudoArquivo: action.payload,
                rotuloArquivoCargaLote: action.rotulo,
                carregando: false
            };
        }

        // procurarTitular
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[5] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                dadosContaTitular: action.payload
                // carregando: true
            };
        }

        // limparTitular
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[6] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                dadosContaTitular: undefined
                // carregando: true
            };
        }

        // gravar arquivo
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[7] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                respostaGravarArquivo: action.payload
                // carregando: true
            };
        }

        // gravar e processar arquivo
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[8] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                respostaProcessarArquivo: action.payload
                // carregando: true

            };
        }

        // ocultar erro
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[9] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                err: action.payload
                // carregando: true

            };
        }

        // criarErroArquivoNaoSelecionado
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                arquivo: undefined,
                dadosUrlPreAssinada: undefined,
                err: action.payload,
                respostaPutArquivosS3: undefined,
                respostaPostConteudoArquivo: undefined,
                dadosContaTitular: undefined,
                respostaGravarArquivo: undefined,
                respostaProcessarArquivo: undefined,
                carregando: false

            };
        }

        // carregar arquivo
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[11] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                respostaPostConteudoArquivo: action.payload,
                carregando: false

            };
        }

        // setar flag carregando
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[12] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                carregando: action.payload
            };
        }

        // setar flag exibir modal gravar
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[13] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                exibirModalGravar: action.payload
            };
        }

        // setar flag modal sucesso gravar
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[14] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                exibirModalSucessoGravar: action.payload
            };
        }
        // setar flag exibir modal gravar
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[15] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                exibirModalProcessar: action.payload
            };
        }

        // setar flag modal sucesso gravar
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[16] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                exibirModalSucessoProcessar: action.payload
            };
        }

        // setar flag finalizado
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[17] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                finalizado: action.payload
            };
        }
        // setar valor rotulo do arquivo
        case CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[18] +
            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME:
        {
            return {
                ...state,
                rotuloArquivoCargaLote: action.payload
            };
        }
    }
    return state;
}
