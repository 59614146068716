import { METODOS } from '../conta-departamento-constantes';

const initialState = {
    listaContaDepartamento: [],
    exibirModalEditarConta: false,
    exibirModalSucessoErroEditarConta: false,
    msgValidarImagemEditarConta: '',
    msgModalSucessoErroEditarConta: '',
    contaSelecionada: '',
    exibirModalCriarContaDepartamento: false,
    loading: true
};

export default function ListaContaDepartamentoReducer(state = initialState, action) {
    switch (action.type) {
        case METODOS.GET_LISTA_CONTA_DEPARTAMENTO: {
            return {
                ...state,
                listaContaDepartamento: action.payload,
                loading: action.loading
            };
        }
        case METODOS.GET_LISTA_CONTA_DEPARTAMENTO_ERRO: {
            return {
                ...state,
                loading: action.loading
            };
        }
        case METODOS.SET_EXIBIR_MODAL_EDITAR_CONTA:
            return {
                ...state,
                exibirModalEditarConta: action.payload,
                msgValidarImagemEditarConta: ''
            };
        case METODOS.SET_EXIBIR_MODAL_CRIAR_CONTA_DEPARTAMENTO:
            return {
                ...state,
                exibirModalCriarContaDepartamento: action.payload
            };

        case METODOS.SALVAR_EDITAR_CONTA:
            return {
                ...state,
                exibirModalSucessoErroEditarConta: true,
                exibirModalEditarConta: false,
                msgModalSucessoErroEditarConta: action.payload
            };
        case METODOS.SET_EXIBIR_MODAL_SUCESSO_ERRO_EDITAR_CONTA:
            return {
                ...state,
                exibirModalSucessoErroEditarConta: action.payload,
                exibirModalEditarConta: false
            };
        case METODOS.SET_CONTA_SELECIONADA:
            return {
                ...state, contaSelecionada: action.payload
            };
        case METODOS.SET_MSG_VALIDAR_IMAGEM_EDITAR_CONTA:
            return {
                ...state,
                msgValidarImagemEditarConta: action.payload
            };
        default:
            return state;
    }
}
