import Axios from 'axios';
import * as constantes from './constantes';
import { HEADER_API, URL_GET_PRESTACAO_CONTA_PROJETO, URL_GET_PRESTACAO_CONTA_CLASSIFICACAO, URL_POST_PRESTACAO_CONTA_PROJETO, URL_POST_PRESTACAO_CONTA_CLASSIFICACAO, URL_PATCH_PRESTACAO_CONTA_PROJETO, URL_PATCH_PRESTACAO_CONTA_CLASSIFICACAO } from '../../../servicos/url-servicos';

// GET
export function getObterParametrosDeClienteProjeto(pessoaId) {
    return (dispatch) => {
        Axios.get(URL_GET_PRESTACAO_CONTA_PROJETO(pessoaId), HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_OBTER_PARAMETROS_CLIENTE_PROJETO,
                    payload: response.data
                });
            })
            .catch((err) => {
                let erroIndefinido = 'erro ao eremover item';
                try {
                    erroIndefinido = err.response.data.erros[0].mensagem;
                    // eslint-disable-next-line no-empty
                } catch (error) {

                }
                dispatch({
                    type: constantes.ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO,
                    payload: erroIndefinido
                });
            });
    };
}


export function getObterParametrosDeClassificacao(pessoaId) {
    return (dispatch) => {
        Axios.get(URL_GET_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId), HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_OBTER_PARAMETROS_CLASSIFICACAO,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: constantes.ERRO_OBTER_PARAMETROS_CLASSIFICACAO,
                    payload: err.response.data
                });
            });
    };
}

// POST
export function setCriarParametrosDeClienteProjeto(pessoaId, nomeClienteProjeto) {
    let body = {
        nome: nomeClienteProjeto
    };

    return (dispatch) => {
        Axios.post(URL_POST_PRESTACAO_CONTA_PROJETO(pessoaId), body, HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_CRIAR_PARAMETROS_CLIENTE_PROJETO,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: constantes.ERRO_CRIAR_PARAMETROS_CLIENTE_PROJETO,
                    payload: err.response.data
                });
            });
    };
}

export function setCriarParametrosDeClassificacao(pessoaId, nomeClassificacao) {
    let body = {
        nome: nomeClassificacao
    };
    return (dispatch) => {
        Axios.post(URL_POST_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId), body, HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_CRIAR_PARAMETROS_CLASSIFICACAO,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: constantes.ERRO_CRIAR_PARAMETROS_CLASSIFICACAO,
                    payload: err.response.data
                });
            });
    };
}

export function setRemoverParametrosDeClienteProjeto(pessoaId, parametrosClienteProjeto) {
    let body = {
        ids: parametrosClienteProjeto.map((parametro) => {
            return parametro.id;
        }),
        status: 'inativo'
    };
    return (dispatch) => {
        Axios.patch(URL_PATCH_PRESTACAO_CONTA_PROJETO(pessoaId), body, HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_REMOVER_PARAMETROS_CLIENTE_PROJETO,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: constantes.ERRO_REMOVER_PARAMETROS_CLIENTE_PROJETO,
                    payload: err.response.data
                });
            });
    };
}

export function setRemoverParametrosDeClassificacao(pessoaId, parametrosClassificacao) {
    let body = {
        ids: parametrosClassificacao.map((parametro) => {
            return parametro.id;
        }),
        status: 'inativo'
    };
    return (dispatch) => {
        Axios.patch(URL_PATCH_PRESTACAO_CONTA_CLASSIFICACAO(pessoaId), body, HEADER_API())
            .then((response) => {
                dispatch({
                    type: constantes.SUCESSO_REMOVER_PARAMETROS_CLASSIFICACAO,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: constantes.ERRO_REMOVER_PARAMETROS_CLASSIFICACAO,
                    payload: err.response.data
                });
            });
    };
}

// CONTROLES DA GUI

// ARMAZENAR VALORES DIGITADOS
export function setArmazenarNomeClienteProjetoDigitado(event) {
    return {
        type: constantes.ARMAZENAR_NOME_CLIENTE_PROJETO_DIGITADO,
        payload: event.target.value
    };
}

export function setArmazenarNomeClassificacaoDigitado(event) {
    return {
        type: constantes.ARMAZENAR_NOME_CLASSIFICACAO_DIGITADO,
        payload: event.target.value
    };
}

// EXIBIR MODAL CONFIRMACAO CRIACAO
export function setExibirConfirmacaoCriacaoDeClienteProjeto(exibir) {
    return {
        type: constantes.EXIBIR_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO,
        payload: exibir
    };
}

export function setExibirConfirmacaoCriacaoDeClassificacao(exibir) {
    return {
        type: constantes.EXIBIR_CONFIRMACAO_CRIACAO_CLASSIFICACAO,
        payload: exibir
    };
}

// EXIBIR MODAL SUCESSO CRIACAO (genérico)
export function setExibirSucessoCriacao(exibir) {
    return {
        type: constantes.EXIBIR_SUCESSO_CRIACAO,
        payload: exibir
    };
}

// EXIBIR MODAL ERRO CRIACAO
export function setExibirModalErroCriacaoDeClienteProjeto(exibir) {
    return {
        // type: constantes.ERRO_CRIAR_PARAMETROS_CLIENTE_PROJETO,
        type: constantes.EXIBIR_ERRO_CRIACAO_CLIENTE_PROJETO,
        payload: exibir
    };
}

export function setExibirModalErroCriacaoDeClassificacao(exibir) {
    return {
        // type: constantes.ERRO_CRIAR_PARAMETROS_CLASSIFICACAO,
        type: constantes.EXIBIR_ERRO_CRIACAO_CLASSIFICACAO,
        payload: exibir
    };
}

// ARMAZENAR PARAMETROS SELECIONADOS PARA REMOCAO
export function setArmazenarClienteProjetoSelecionadosParaRemocao(clienteProjetoSelecionados) {
    return {
        type: constantes.ARMAZENAR_CLIENTE_PROJETO_SELECIONADOS_PARA_REMOCAO,
        payload: clienteProjetoSelecionados
    };
}

export function setArmazenarClassificaoSelecionadasParaRemocao(classificacaoSelecionadas) {
    return {
        type: constantes.ARMAZENAR_CLASSIFICACAO_SELECIONADAS_PARA_REMOCAO,
        payload: classificacaoSelecionadas
    };
}

// EXIBIR MODAL CONFIRMACAO REMOCAO
export function setExibirConfirmacaoRemocaoDeClienteProjeto(exibir) {
    return {
        type: constantes.EXIBIR_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO,
        payload: exibir
    };
}

export function setExibirConfirmacaoRemocaoDeClassificacao(exibir) {
    return {
        type: constantes.EXIBIR_CONFIRMACAO_REMOCAO_CLASSIFICACAO,
        payload: exibir
    };
}

// EXIBIR MODAL SUCESSO REMOCAO (genérico)
export function setExibirSucessoRemocao(exibir) {
    return {
        type: constantes.EXIBIR_SUCESSO_REMOCAO,
        payload: exibir
    };
}

// EXIBIR MODAL ERRO REMOCAO
export function setExibirModalErroRemocaoDeClienteProjeto(exibir) {
    return {
        type: constantes.EXIBIR_ERRO_REMOCAO_CLIENTE_PROJETO,
        payload: exibir
    };
}

export function setExibirModalErroRemocaoDeClassificacao(exibir) {
    return {
        type: constantes.EXIBIR_ERRO_REMOCAO_CLASSIFICACAO,
        payload: exibir
    };
}

// PAINEIS / ACCORDIONS
export function setExpandirPainelDeClienteProjeto(expandir) {
    return {
        type: constantes.EXPANDIR_PAINEL_CLIENTE_PROJETO,
        payload: expandir
    };
}

export function setExpandirPainelDeClassificacao(expandir) {
    return {
        type: constantes.EXPANDIR_PAINEL_CLASSIFICACAO,
        payload: expandir
    };
}

// EXIBIR MENSAGEM ERRO
export function setExibirAlertaErroAoObterClienteProjeto(exibir) {
    return {
        type: constantes.EXIBIR_ALERTA_ERRO_OBTER_CLIENTE_PROJETO,
        payload: exibir
    };
}

export function setExibirAlertaErroAoObterClassificacao(exibir) {
    return {
        type: constantes.EXIBIR_ALERTA_ERRO_OBTER_CLASSIFICACAO,
        payload: exibir
    };
}
