import Controle from './conta-favorecido-controlador';
const localState = {
    contas: []
};
export default function reducer(state = localState, action) {
    switch (action.type) {
        case Controle.getInicializarFavorecidoControle():
        {
            let conta = {
                'index': action.index,
                'id': action.payload.id,
                'tipoFavorecido': action.payload.tipoFavorecido,
                'tipoConta': action.payload.tipoConta,
                'nome': action.payload.nome,
                'contaId': action.payload.contaId,
                'agencia': action.payload.agencia,
                'conta': action.payload.conta,
                'digito': action.payload.digito,
                'banco': action.payload.banco,
                'nomeBanco': action.payload.nomeBanco,
                'tipoDocumento': action.payload.tipoDocumento,
                'numeroDocumento': action.payload.numeroDocumento,
                'telefone': action.payload.telefone,
                'email': action.payload.email,
                'ativo': action.payload.ativo,
                'valor': '',
                'descricao': '',
                'dispararSolicitacao': false,
                'msg': 'aguardando...'
            };
            state.contas.push(conta);
            return {
                ...state,
                contas: state.contas
            };
        }
        case Controle.getMsgFavorecidoControle(): {
            return {
                ...state,
                msg: action.payload
            };
        }
        case Controle.getUpdateFavorecidoControle(): {
            let oldContas = state.contas;
            let favorecido = state.contas.find(
                cFavorecido => {
                    if (cFavorecido.index === action.payload.index &&
                        cFavorecido.id === action.payload.id) {
                        cFavorecido.valor = action.payload.valor;
                        cFavorecido.descricao = action.payload.descricao;
                    }
                }
            );
            if (favorecido !== undefined && favorecido.id !== '') {
                oldContas = state.conta.filter(conta => conta !== action.payload);
                oldContas.push(favorecido);
            }
            return {
                ...state,
                contas: oldContas

            };
        }
        default:
            return state;
    }
}
