//import 'componentes/cartao/lista-cartoes/lista-cartoes.css';
import React, { Component } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import If from 'componentes/componentes-uteis/if/if';
import * as actions from 'action/cartoes/cartoes-action';
import { setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador } from 'componentes/comuns/portador/vincular-portador/vincular-portador-action';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import { CONTA_SELECIONADA, LABEL_COMUNS, OPERACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import GridCartoesBppConsumer from 'componentes/solucoes/dashboard/bpp-consumer/cartoes/grid-cartoes-bpp-consumer';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import CargaEDescargaCartao from 'componentes/cartao/carga-e-descarga-cartao/carga-e-descarga-cartao';
import DetalhesDoPortador from 'componentes/comuns/portador/detalhes-do-portador';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import BloquearCartao from 'componentes/cartao/bloquear-cartao/bloquear-cartao';
import CancelarCartao from 'componentes/cartao/cancelar-cartao/cancelar-cartao';
import DesbloquearCartao from 'componentes/cartao/desbloquear-cartao/desbloquear-cartao';
import AlterarSenhaCartao from 'componentes/comuns/cartao/alterar-senha-cartao';
import AtivarCartao from 'componentes/cartao/ativar-cartao/ativar-cartao';

let quantidadeDeChamadasAoGetCartoes = 0;

class ListaCartoesBppConsumer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            recarregarTela: false,
            exibirModalAlterarPortador: false,
            showModalAlterarSenhaCartao: false,
            showCancelCardModal: false,
            showLockCardModal: false,
            showFinalizationModal: false,
            showUnlockCardModal: false,
            showUnlockFinalizationModal: false,
            showCancelFinalizationModal: false,
            showLockFinalizationModal: false,
            abrirCartoesProntosParaAtivacao: false,
            abrirCartoesAtivos: false,
            abrirCartoesBloqueados: false,
            abrirCartoesCancelados: false,
            showActivateCardModal: false,
            showChargeOrDischargeCard: false
        };
    }

    componentDidMount() {
        this.getCartoes();
        this.props.limparFiltroCartao();
    }


    setShowLockCardModal = (showLockCardModal) => {
        this.setState({ showLockCardModal });
    };

    setShowUnlockCardModal = (showUnlockCardModal) => {
        this.setState({ showUnlockCardModal });
    };

    setShowCancelCardModal = (showCancelCardModal) => {
        this.setState({ showCancelCardModal });
    };

    setShowUnlockFinalizationModal = (showUnlockFinalizationModal) => {
        this.setState({ showUnlockFinalizationModal });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    setShowModalAlterarSenhaCartao = (showModalAlterarSenhaCartao) => {
        this.setState({ showModalAlterarSenhaCartao });
    };

    setAbrirCartoesProntosParaAtivacao = (abrirCartoesProntosParaAtivacao) => {
        this.setState({ abrirCartoesProntosParaAtivacao });
    };
    setAbrirCartoesAtivos = (abrirCartoesAtivos) => {
        this.setState({ abrirCartoesAtivos });
    };
    setAbrirCartoesBloqueados = (abrirCartoesBloqueados) => {
        this.setState({ abrirCartoesBloqueados });
    };
    setAbrirCartoesCancelados = (abrirCartoesCancelados) => {
        this.setState({ abrirCartoesCancelados });
    };

    setShowActivateCardModal = (showActivateCardModal) => {
        this.setState({ showActivateCardModal });
    };


    getCartoes = () => {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getCartoes(contaSelecionada.id, '', undefined, undefined, undefined, undefined, quantidadeDeChamadasAoGetCartoes * 2000);
    };


    setExibirModalDetalhePortador = (exibirModalDetalhePortador) => {
        this.setState({ exibirModalDetalhePortador });
    };


    setExibirModalAlterarPortador = (exibirModalAlterarPortador) => {
        this.setState({ exibirModalAlterarPortador });
    };
    setShowChargeOrDischargeCard = (showChargeOrDischargeCard) => {
        this.setState({ showChargeOrDischargeCard });
    }

    render() {

        const { showCancelCardModal,
            showLockCardModal, showUnlockCardModal, showUnlockFinalizationModal, showCancelFinalizationModal,
            showLockFinalizationModal, showModalAlterarSenhaCartao, showActivateCardModal, showChargeOrDischargeCard
        } = this.state;

        const { contaSelecionada, cartaoSelecionado, exibirLoadingCartao } = this.props.estadoCartoes;

        const { outorgaPerfils } = this.props;

        if (this.state.recarregarTela) {
            return <Redirect to={ROTAS_CAMINHOS.CARTOES} />;
        }

        return (
            <div>
                {exibirLoadingCartao ? <LoadingFenix /> : false}
                {contaSelecionada && <ContaSelecionada contaSelecionada={contaSelecionada}
                    exibirSaldo={true}
                    exibirFiltroCartoes={true}
                    exportarCsv={true}
                    exibirNomeECpfDoPortador={false}

                    cartaoId={this.props.estadoCartoes.cartaoId}
                    setCartaoId={this.props.setCartaoId}
                    cartaoIdErro={this.props.cartaoIdErro}

                    nomePortador={this.props.estadoCartoes.nomePortador}
                    setNomePortador={this.props.setNomePortadorCartao}
                    nomePortadorErro={this.props.estadoCartoes.nomePortadorErro}

                    numeroDocumentoPortador={this.props.estadoCartoes.numeroDocumentoPortador}
                    setNumeroDocumentoPortador={this.props.setNumeroDocumentoPortadorCartao}
                    numeroDocumentoPortadorErro={this.props.estadoCartoes.numeroDocumentoPortadorErro}

                    cartaoQuatroUltimosDigitos={this.props.estadoCartoes.cartaoQuatroUltimosDigitos}
                    setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                    cartaoQuatroUltimosDigitosErro={this.props.estadoCartoes.cartaoQuatroUltimosDigitosErro}

                    buscar={() => {
                        this.props.setExibirLoadingCartao(true);
                        this.setAbrirCartoesAtivos(false);
                        this.setAbrirCartoesProntosParaAtivacao(false);
                        this.setAbrirCartoesBloqueados(false);
                        this.setAbrirCartoesCancelados(false);
                        this.props.getCartoes(
                            this.props.estadoCartoes.contaSelecionada.id,
                            '',
                            this.props.estadoCartoes.cartaoId,
                            this.props.estadoCartoes.nomePortador,
                            this.props.estadoCartoes.numeroDocumentoPortador,
                            this.props.estadoCartoes.cartaoQuatroUltimosDigitos,
                            quantidadeDeChamadasAoGetCartoes
                        );
                    }}
                    limparFiltro={this.props.limparFiltroCartao}
                    listaParaSerconvertidaEmCSV={this.props.estadoCartoes.listaDeCartoesRetornados} />}

                <div className="alinhamento-centro">
                    <If test={this.props.estadoCartoes.exibirLoadingCartao}>
                        <LoadingFenix />
                    </If>
                </div>

                <CargaEDescargaCartao
                    exibirModal={showChargeOrDischargeCard}
                    contaSelecionada={this.props.estadoCartoes.contaSelecionada}
                    cartaoSelecionado={this.props.estadoCartoes.cartaoSelecionado}
                    operacaoSelecionada={this.props.estadoCartoes.operacaoSelecionada}
                    setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                    grantProfile={outorgaPerfils}
                />

                <DetalhesDoPortador
                    cartaoSelecionado={this.props.estadoCartoes.cartaoSelecionado}
                    exibirModal={this.state.exibirModalDetalhePortador}
                    setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                    showOnlyDetails={true}
                />

                <AlterarSenhaCartao
                    showModalAlterarSenhaCartao={showModalAlterarSenhaCartao}
                    selectedCard={cartaoSelecionado}
                    reloadCards={this.getCartoes}
                    needToken={this.props.outorgaAlteraPinCartao}
                    grantProfile={outorgaPerfils}
                    setShowModalAlterarSenhaCartao={this.setShowModalAlterarSenhaCartao}
                />

                <BloquearCartao
                    showLockCardModal={showLockCardModal}
                    setShowLockLoading={this.props.setExibirLoadingCartao}
                    lockSelectedCard={cartaoSelecionado}
                    lockGetCards={this.getCartoes}
                    setShowLockCardModal={this.setShowLockCardModal}
                    showLockFinalizationModal={showLockFinalizationModal}
                    setShowLockFinalizationModal={this.setShowLockFinalizationModal}
                />
                <DesbloquearCartao
                    showUnlockCardModal={showUnlockCardModal}
                    setShowUnlockLoading={this.props.setExibirLoadingCartao}
                    unlockSelectedCard={cartaoSelecionado}
                    unlockGetCards={this.getCartoes}
                    setShowUnlockCardModal={this.setShowUnlockCardModal}
                    showUnlockFinalizationModal={showUnlockFinalizationModal}
                    setShowUnlockFinalizationModal={this.setShowUnlockFinalizationModal}

                />

                <CancelarCartao
                    showCancelCardModal={showCancelCardModal}
                    setShowCancelLoading={this.props.setExibirLoadingCartao}
                    cancelSelectedCard={cartaoSelecionado}
                    cancelGetCards={this.getCartoes}
                    setShowCancelCardModal={this.setShowCancelCardModal}
                    showCancelFinalizationModal={showCancelFinalizationModal}
                    setShowCancelFinalizationModal={this.setShowCancelFinalizationModal}
                />

                <AtivarCartao
                    needToken={this.props.outorgaAlteraPinCartao}
                    grantProfile={outorgaPerfils}
                    showActivateCardModal={showActivateCardModal}
                    setShowActivateLoading={this.props.setExibirLoadingCartao}
                    selectedCard={cartaoSelecionado}
                    setShowActivateCardModal={this.setShowActivateCardModal}
                />

                <div className="row">
                    <If test={this.props.estadoCartoes.listaDeCartoesAtivos.length > 0}>
                        <GridCartoesBppConsumer
                            outorgaPerfils={outorgaPerfils}
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.ATIVOS}
                            setExibirModalAlerarPortadorCartao={this.setExibirModalAlterarPortador}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesAtivos.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesAtivos}
                            abrirCartoes={this.state.abrirCartoesAtivos}
                            setAbriSecaoCartao={() => this.setAbrirCartoesAtivos(!this.state.abrirCartoesAtivos)}
                            setShowChargeOrDischargeCard={this.setShowChargeOrDischargeCard}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowModalAlterarSenhaCartao={this.setShowModalAlterarSenhaCartao}
                            setShowCancelCardModal={this.setShowCancelCardModal}
                            setShowLockCardModal={this.setShowLockCardModal}
                            mostrarSaldoCartao={true}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length > 0}>
                        <GridCartoesBppConsumer
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.PRONTOS_PARA_ATIVACAO}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao.length}
                            vincularPortador={true}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesProntosParaAtivacao}
                            abrirCartoes={this.state.abrirCartoesProntosParaAtivacao}
                            setAbriSecaoCartao={() => this.setAbrirCartoesProntosParaAtivacao(!this.state.abrirCartoesProntosParaAtivacao)}
                            tipoDeAcesso={reactLocalStorage.get(LABEL_COMUNS.TIPO_ACESSO)} exibirModalVincularPortador={() => this.props.setExibirModalVincularPortador(true)}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowCancelCardModal={this.setShowCancelCardModal}
                            setShowLockCardModal={this.setShowLockCardModal}
                            setExibirModalDeVincularPortador={this.props.setExibirModalDeVincularPortador}
                            setCartaoSelecionadoParaVincularPortador={this.props.setCartaoSelecionadoParaVincularPortador}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                            setShowActivateCardModal={this.setShowActivateCardModal}
                            outorgaPerfils={outorgaPerfils}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesBloqueados.length > 0}>
                        <GridCartoesBppConsumer
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.BLOQUEADOS}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesBloqueados}
                            abrirCartoes={this.state.abrirCartoesBloqueados}
                            setAbriSecaoCartao={() => this.setAbrirCartoesBloqueados(!this.state.abrirCartoesBloqueados)}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setShowUnlockCardModal={this.setShowUnlockCardModal}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                            outorgaPerfils={outorgaPerfils}
                        />
                    </If>

                    <If test={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length > 0}>
                        <GridCartoesBppConsumer
                            labelTitulo={LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS_E_OUTROS}
                            setArmazenarCartao={this.props.setArmazenarCartao}
                            quantidadeCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros.length}
                            listaCartoes={this.props.estadoCartoes.listaDeCartoesCanceladosEOutros}
                            abrirCartoes={this.state.abrirCartoesCancelados}
                            setAbriSecaoCartao={() => this.setAbrirCartoesCancelados(!this.state.abrirCartoesCancelados)}
                            setShowUnlockCardModal={this.setShowUnlockCardModal}
                            setOperacaoCartaoSelecionada={this.props.setOperacaoCartaoSelecionada}
                            setExibirModalDetalhePortador={this.setExibirModalDetalhePortador}
                            outorgaPerfils={outorgaPerfils}
                        />
                    </If>
                </div>
                {this.props.estadoCartoes.msgListaVazia}
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <div className="row">
                        <Button type="button" bsStyle="primary" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                        <br /><br />
                    </div>
                </Grid>
            </div>
        );
    }

    recarregarTela() {
        this.setState({ recarregarTela: true });
    }
}

function mapStateToProps(state) {
    return {
        estadoCartoes: state.CartoesReducer,
        outorgaPerfils: {
            podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
            podeCriar: podeCriar(state.OutorgaOperacaoReducer),
            podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
        },
        outorgaAlteraPinCartao: outorgaParametroOperacao(state.OutorgaOperacaoReducer, OPERACAO.NOME.ALTERACAO_PIN_CARTAO)
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, setCartaoSelecionadoParaVincularPortador, setExibirModalDeVincularPortador }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListaCartoesBppConsumer);
