import React from 'react';
import { Modal } from 'react-bootstrap';

const classHeaderModalContext = props => {
    if (props.contextoBppPag) {
        return 'cabecalho_modal_bppPag';
    }

    if (props.alignHeaderCenter) {
        return 'cabecalho_modal alinhamento-centro';
    }
    return 'cabecalho_modal';
};

const ModalComponent = props => {
    return (
        <div className={props.className} name={props.name}>
            <Modal
        bsSize={props.bsSize}
        show={props.exibirModal}
        dialogClassName={props.dialogClassName}
        aria-labelledby={props.ariaLabelledby}>
                <Modal.Header
          closeButton={props.closeButton}
          onClick={props.closeOnclick}
          className={classHeaderModalContext(props)}>
                    {props.header}
                </Modal.Header>
                <Modal.Body bsClass={props.modalBodyClass}>
                    {props.body}
                </Modal.Body>
                <Modal.Footer>
                    {props.footer}
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default ModalComponent;
