import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from './dados-pj-action';
import DadosPessoaJuridica from './dados-pj';

function mapStateToProps(state) {
    return {
        dadospessoajuridicareducer: state.DadosPessoaJuridicaReducer
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DadosPessoaJuridica);
