import React, { Component } from 'react';
import axios from 'axios';
import { LABEL } from './mostrar-saldo-cartao-constantes';
import { URL_GET_SALDO_CARTAO, HEADER_API } from '../../../../servicos/url-servicos';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import _if from '../../../componentes-uteis/if/if';
import { podeConsultar } from '../../../../reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';

class MostrarSaldoCartao extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saldo: 0,
            mensagemDeErroNaBuscaDoSaldo: '',
            mostrarSaldoCartao: false,
            exibirSaldoCartao: false,
            exibirLoading: false
        };
    }

    componentDidMount() {
        if (this.props.exibirSaldoCartao) {
            this.props.setState({ mostrarSaldoCartao: true });
            this.getSaldoCartao(this.props.contaCartaoId);
        }
    }


    getSaldoCartao(contaCartaoId) {
        axios.get(URL_GET_SALDO_CARTAO(contaCartaoId), HEADER_API())
            .then((response) => {
                if (response && response.data && response.data.saldo) {
                    this.setState({ saldo: response.data.saldo, exibirSaldoCartao: true, exibirLoading: false });
                } else {
                    this.setState({ saldo: 0, exibirSaldoCartao: true, exibirLoading: false });
                }
            }).catch((err) => {
                this.setState({ exibirLoading: false, exibirSaldoCartao: false });
            });
    }

    mostrarSaldoCartao(contaCartaoId) {
        this.setState({ exibirLoading: true });
        this.getSaldoCartao(contaCartaoId);
        this.forceUpdate();
    }

    formataMoeda(numero) {
        var formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        });
        let valor = '0,00';
        try {
            valor = formatter.format(numero).replace('R$', '');
        } catch (err) { }
        return valor;
    }

    ocultarSaldoCartao() {
        this.setState({ exibirSaldoCartao: false });
        this.forceUpdate();
    }

    render() {
        let button = null;
        let saldo = null;
        if (this.state.exibirSaldoCartao) {
            saldo = <div className="mostrar-saldo-cartao" onClick={() => this.ocultarSaldoCartao()}>
                {'R$' + this.formataMoeda(this.state.saldo)}
            </div>;
        } else {
            button = <div className="mostrar-saldo-cartao" onClick={() => this.mostrarSaldoCartao(this.props.contaCartaoId)}>{LABEL.EXIBIR_SALDO}</div>;
        }
        return (
            this.props.outorgaPerfils.podeConsultar && <div>
                <_if test={this.state.exibirLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </_if>
                {saldo}
                {button}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
    }
});

export default connect(mapStateToProps)(MostrarSaldoCartao);