import React, { Component } from 'react';
import { getSaldoPorContaId } from 'servicos/contas/conta-service';

class BuscaMostraSaldo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mostrarSaldo: false,
            idConta: props.idConta,
            saldo: '',
            saldoRecuperado: false
        };
    }

    mostrarSaldo = () => {
        this.setState({ mostrarSaldo: true, saldoRecuperado: false });
        this.buscarSaldo();
    };

    buscarSaldo = async () => {
        const { saldo } = this.state;
        if (!saldo) {
            const saldoRetornado = await getSaldoPorContaId(this.state.idConta);
            if (saldoRetornado.error) {
                this.setState({ saldoRecuperado: false });
            } else {
                const { saldo } = saldoRetornado;
                this.setState({ saldo: saldo, saldoRecuperado: true });
            }
        } else {
            this.setState({
                mostrarSaldo: true, saldoRecuperado: true
            });
        }
    };

    formataMoeda(numero) {
        let formatter = new Intl.NumberFormat('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2
        });
        return formatter.format(numero).replace('R$', '');
    }

    ocultarSaldo() {
        this.setState({
            mostrarSaldo: false
        });
    }

    render() {
        let button = null;
        let saldo = null;
        if (this.state.mostrarSaldo) {
            saldo = <span className="label-verificar-saldo" style={{ cursor: 'pointer' }}
                          onClick={() => this.ocultarSaldo()}>
                {this.state.saldoRecuperado ? 'R$ ' + this.formataMoeda(this.state.saldo) : 'Atualizando...'}
            </span>;
        } else {
            button = <span className="label-verificar-saldo" style={{ cursor: 'pointer' }}
                           onClick={() => this.mostrarSaldo()}>Clique para exibir o saldo</span>;
        }
        return (
            <div>
                {saldo}
                {button}
            </div>
        );
    }
}

export default BuscaMostraSaldo;
