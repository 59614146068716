import { CONSTANTES } from './dados-pf-constantes';

const ESTADO_INICIAL = {
    dadosPessoaFisica: undefined,
    erroBuscarDadosPessoaFisica: undefined,
    senhaNaoConfere:false
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case CONSTANTES.OBTER_DADOS_PF_SUCESSO:
            return {
                ...state,
                dadosPessoaFisica: action.payload
            };

        case CONSTANTES.OBTER_DADOS_PF_ERRO:
            return {
                ...state,
                erroBuscarDadosPessoaFisica: action.payload
            };

        default:
            return state;
    }
};
