import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, Collapse, Dropdown, Glyphicon, MenuItem, Panel, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { LABEL_COMUNS, MENSAGENS } from 'componentes/util/contantes-comuns/constantes-comuns';
import { setArmazenarCartao } from 'action/cartoes/cartoes-action';
import { fetchRemoveCartaoFavorito } from 'servicos/cartoes/cartoes-base';
import { formatarStatus } from 'componentes/util/cartoes/cartoes-util';
import Loading from 'componentes/loading-fenix/loading';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { CPF } from 'cpf_cnpj';
import If from 'componentes/componentes-uteis/if/if';
import { removeMascaraTelefoneCpfCnpj } from 'componentes/util/formata-string';
import { downloadDadosFavoritosApi } from 'core/api/favoritosApi';
import { downloadCSV } from '../csv-cartoes-pag';
import imagemCartao from 'imagens/cartao-chip.jpg';
import * as actions from 'action/cartoes/cartoes-action';

const CARTOES = 'Cartões';

const tooltip = (
    <Tooltip id="tooltip">
        Ao exportar o relatório, serão visualizados apenas cartões com status ativo.
    </Tooltip>
);

class GridCartoesBppPagFavoritos extends Component {
    constructor(props) {
        super(props);
        this.state = { cartaoToRemove: null, favoriteRemoveModalOpen: false, favoriteRemoveStep: 0, 
            exibirLoadingCartao: true, collapsed: false, 
            listaCartoesFavoritos: this.props.listaCartoes && this.props.listaCartoes.length > 0 ? this.props.listaCartoes : [],
            contaSelecionada: props.contaSelecionada, downloadErrorModalOpen: false, loading: false };
    }

    excluiCartao(cartao) {
        this.setState({ favoriteRemoveModalOpen: true, cartaoToRemove: cartao });
    }

    removeFavorito() {
        const { id } = this.state.contaSelecionada;
        const { cartaoToRemove } = this.state;
        this.setState({ favoriteRemoveStep: 3 });
        let body = {
            id: cartaoToRemove.id,
            cartaoId: cartaoToRemove.favoriteCardId,
            documentoTitular: removeMascaraTelefoneCpfCnpj(cartaoToRemove.favoriteOwnerDocument)
        };

        fetchRemoveCartaoFavorito(JSON.stringify(body), id).then(response => {
            if (response === 'error') {
                this.setState({ favoriteRemoveStep: 2 });
            } else {
                this.setState({ favoriteRemoveStep: 1 });
            }
        });
    }

    rodapeRemoveFavorito() {
        if (this.state.favoriteRemoveStep === 0) {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteRemoveModalOpen: false }); }} type="button" style={{ backgroundColor: 'white', color: 'black', borderColor: '#ddd' }} className="btn btn-primary">Não</button>
                    <button onClick={() => this.removeFavorito()} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Sim</button>
                </div>
            );
        } else {
            return (
                <div>
                    <button onClick={() => { this.setState({ favoriteRemoveModalOpen: false, favoriteRemoveStep: 0 }); this.getFavoritos(); }} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Ok</button>
                </div>
            );
        }
    }

    getFavoritos() {
        if (this.state.favoriteRemoveStep !== 2) {

            let listaClone = [...this.state.listaCartoesFavoritos];

            
            listaClone = listaClone.filter(favorito => favorito.id !== this.state.cartaoToRemove.id);
            
            this.props.setListaCartoesFavoritos(listaClone);
            this.props.decrementQuantidadeTotalRegistros();

            this.setState({ listaCartoesFavoritos: listaClone });
        }
    }

    corpoRemoveFavorito() {
        let step = this.state.favoriteRemoveStep;
        if (step === 0) {
            return <span style={{ width: 120, marginTop: 10, marginLeft: 3, marginRight: 5 }}>{LABEL_COMUNS.DESEJA_REMOVER}</span>;
        } else if (step === 1) {
            return (
                <div>
                    <span>{MENSAGENS.CARTAO.FAVORITOS.EXCLUSAO.SUCESSO}.</span>
                </div>
            );
        } else if (step === 2) {
            return (
                <div>
                    <span>{LABEL_COMUNS.ERRO_SOLICITACAO}</span>
                </div>
            );
        }
        return (
            <div style={{ justifyContent: 'center', display: 'flex' }}>
                <Loading />
            </div>
        );
    }

    async startDownloadCSV() {
        this.setState({ loading: true});
        const downloadDadosFavoritosResponse = await downloadDadosFavoritosApi(this.state.contaSelecionada.id);
        if (!downloadDadosFavoritosResponse.error && downloadDadosFavoritosResponse !== 'error') {
            const dataURL = `data:text/plain;base64,${downloadDadosFavoritosResponse.data.file}`;

            let link = null;
            link = document.createElement('a');
            link.setAttribute('href', dataURL);
            link.setAttribute('download', 'cartoes-favoritos.csv');
            link.click();
        } else {
            this.setState({ downloadErrorModalOpen: true});
        }
        this.setState({ loading: false });
    }

    rodapeModalDownloadErro() {
        return (
            <div>
                <button onClick={() => { this.setState({ downloadErrorModalOpen: false });}} type="button" style={{ backgroundColor: 'rgb(146, 194, 124)', borderColor: 'rgb(76, 174, 76)' }} className="btn btn-primary">Ok</button>
            </div>
        );
    }

    corpoModalDownloadErro() {
        return (
            <span>
                No momento não foi possível gerar o arquivo solicitado. Tente novamente mais tarde.
            </span>
        );
        
    }

    mostraBotaoMostrarMais(){
        if (this.props.quantidadeTotalRegistros && 
            this.props.quantidadeTotalRegistros >= 20 && 
            this.props.quantidadeTotalRegistros % 20 !== 0 &&
            this.props.listaCartoes.length % 20 === 0){
            return (<button
                type="button"
                className="btn btn-default btn-block fonte-tamanho-11"
                onClick={() => { this.props.buscar(); }}
                >
                {LABEL_COMUNS.MOSTRAR_MAIS}
            </button>);
        }else return null;
    }

    render() {
        const listaDeCartoes = this.props.listaCartoes && this.props.listaCartoes.length > 0 ? this.props.listaCartoes : [];
        const { podeAprovar } = this.props.outorgaPerfils;
        return (
            <div id={this.props.id}>
                <ModalComponent
                    contextoBppPag={true}

                    bsSize="small"
                    exibirModal={this.state.downloadErrorModalOpen}
                    name="modal-favorite"
                    header={'Download Favoritos'}
                    body={this.corpoModalDownloadErro()}
                    footer={this.rodapeModalDownloadErro()} />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11" />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Button className="botao_informacoes" onClick={() => { this.setState({ collapsed: !this.state.collapsed }); }}>
                        {CARTOES + ' ' + this.props.labelTitulo} ({this.props.quantidadeTotalRegistros})
                        <span>&nbsp; &nbsp; </span>
                        {iconeBarraCartao(this.state.collapsed)}
                        <div style={{ position: 'absolute', right: 57, bottom: 6, top: 3 }} >
                            <If test={this.state.loading}>
                                <Loading />
                            </If>
                        </div>
                        <OverlayTrigger placement="top" overlay={tooltip}>
                            <Button className="btn btn-success btn-sm" style={{ position: 'absolute', right: 15, bottom: 6, cursor: 'pointer' }} title="Exportar para .CSV" onClick={(e) => {
                            e.stopPropagation();
                            this.startDownloadCSV('cartoes-favoritos.csv', listaDeCartoes, this.props.labelTitulo);
                        }}>
                                <i className="fa fa-file-excel-o" aria-hidden="true" />
                            </Button>
                        </OverlayTrigger>

                    </Button>
                </div>

                <ModalComponent
                    contextoBppPag
                    bsSize="small"
                    exibirModal={this.state.favoriteRemoveModalOpen}
                    name="modal-favorite"
                    header={LABEL_COMUNS.EXCLUIR_CARTAO_FAVORITO}
                    body={this.corpoRemoveFavorito()}
                    footer={this.rodapeRemoveFavorito()} />

                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Collapse in={this.state.collapsed}>
                        <Panel className="conteudo_cartoes" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="row list-group-item texto-extrato-detalhe">
                                <div className="col-sm-1 col-md-1 col-lg-1" />

                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.ID}
                                </div>
                                <div className="col-sm-3 col-md-3 col-lg-3 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.TITULAR}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.DOCUMENTO}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.STATUS}
                                </div>

                                <If test={podeAprovar}>
                                    <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro ">
                                        {LABEL_COMUNS.ACOES}
                                    </div>
                                </If>
                            </div>
                            <div className="list-group">
                                {listaDeCartoes.map((cartao, idx) => {
                                    return (
                                        <li key={idx} className="list-group-item texto-extrato-detalhe">
                                            <div className="row">
                                                {/* <div className="col-sm-1 col-md-1 col-lg-1" /> */}
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <img src={imagemCartao} className="avatar-cartao"
                                                        alt=" imagem cartao" />
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                                    {cartao.favoriteCardId}
                                                </div>
                                                <div className="col-sm-3 col-md-3 col-lg-3 textos_cartao alinhamento-centro">
                                                    {cartao.favoriteOwnerName}
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {CPF.format(cartao.favoriteOwnerDocument)}
                                                </div>

                                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {formatarStatus(cartao.favoriteCardStatus)}
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 alinhamento-centro botao_extrato_cartao">
                                                    <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-10px' }}>
                                                        <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                            <Glyphicon glyph="option-horizontal" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <MenuItem
                                                                onSelect={() => { this.props.setArmazenarCartao(cartao); }}
                                                                onClick={() => this.excluiCartao(cartao)}>{LABEL_COMUNS.EXCLUIR}
                                                            </MenuItem>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                                )}
                                {this.mostraBotaoMostrarMais()}
                                <br />
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div >
        );
    }
}

const iconeBarraCartao = (abrirCartao) => (
    <span className={`glyphicon glyphicon glyphicon glyphicon-chevron-${abrirCartao ? 'up' : 'down'}`} />
);

const mapDispatchToProps = dispatch => bindActionCreators({ setArmazenarCartao }, dispatch);

export default connect(mapDispatchToProps)(GridCartoesBppPagFavoritos);
