import axios from 'axios';
import { CPF } from 'cpf_cnpj';
import { HEADER_API, HTTP_METODOS, montaUrlFetch, montaUrlFetchWebShim, urlServicos, URL_FAVORECIDO, URL_LISTA_BANCOS } from '../../servicos/url-servicos';
import { METODOS, TIPO_TRANSFERENCIA_BANCARIA, MENSAGENS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaBancosParaCombo, retornaFavorecidosParaCombo } from '../../componentes/util/contas/contas-util';
import { montaDadosFavorecidoJSonOBackEnd, retornaCorpoParaBuscaDeContaPorNumeroEDigito, retornaCorpoDeContasParaValidarTransferencia, montaJsonDeTransferenciaBancariaParaBackEnd, retornaCorpoParaEfetivarTransacao, montaRequestBodyPostOperacaoTransacao, retornaJsonParaTransferenciaBancariaComContaDestino, montaRequestBodyPostOperacaoTransacaoAgendamento } from '../../componentes/transferencia/transferencia-bancaria-util';
import { removeMascaraTelefoneCpfCnpj, retornaTelefoneOuCelularComMascara } from '../../componentes/util/formata-string';
import { retornaNumeroTelefonePessoa } from '../../servicos/pessoas/pessoa-service';
import { salvarAvatar, salvarEdicaoDeContaEAvatar, fetchGetConta, fetGetContaById, fetchGetPermitirFazerTed } from '../../servicos/contas/contas-base';
import { gravaOutorga, retornaAutorizadosDaConta, retornaPerfisEPoderesOutorga } from '../../servicos/outorga/outorga-base';
import { fetchGetPessoaPorNumeroDocumento, fetchGravaPessoa } from '../../servicos/pessoas/pessoas-base';


export const setContaSelecionada = (contaSelecionada) => ({
    type: METODOS.SET_CONTA_SELECIONADA,
    payload: contaSelecionada
});

export const setExibirLoadingConta = (exibirLoadingConta) => {
    return {
        type: METODOS.CONTA.SET_EXIBIR_LOADING_CONTA,
        payload: exibirLoadingConta
    };
};

export function getContaPorId(contaId) {
    return dispatch => {
        return getContaById(dispatch, contaId);
    };
}

export const getContaPorNumeroEDigito = (dadosConta) => {
    return dispatch => {
        dispatch(setExibirLoadingConta(true));
        getContaByNumeroEDigito(dadosConta)
            .then(resp => {
                dispatch({
                    type: resp.error ? METODOS.CONTA.GET_CONTA_POR_NUMERO_E_DIGITO_ERRO : METODOS.CONTA.GET_CONTA_POR_NUMERO_E_DIGITO,
                    payload: resp.data
                });
            })
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const salvarEdicaoDeConta = (dadosConta) => {
    return dispatch => {
        dispatch(setExibirLoadingConta(true));
        salvarEdicaoDeContaEAvatar(dadosConta)
            .then(result => dispatch({ type: METODOS.CONTA.SALVAR_EDICAO_DE_CONTA, payload: result || result.data }))
            .catch(err => dispatch({ type: METODOS.CONTA.SALVAR_EDICAO_DE_CONTA_ERRO, payload: err || err.data }))
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const salvarAvatarConta = (contaId, arquivo) => {
    return dispatch => {
        return salvarAvatar(contaId, arquivo)
            .then(result => dispatch({ type: METODOS.CONTA.SALVAR_AVATAR, payload: result }))
            .catch(err => dispatch({ type: METODOS.CONTA.SALVAR_AVATAR_ERRO, payload: err || err.data }));
    };
};

export function buscarFavorecidos(conta, tipoOperacao) {
    return (dispatch) => {
        dispatch(setExibirLoadingConta(true));
        axios.get(URL_FAVORECIDO(conta.id, tipoOperacao), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.GET_FAVORECIDOS,
                    payload: response.data && retornaFavorecidosParaCombo(response.data.registros) || []
                });
            })
            .catch((err) => {
                let msgErro = 'erro de acesso ao endpoint favorecido';
                if (err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.erros !== undefined) {
                    msgErro = err.response.data.erros;
                }
                dispatch({
                    type: METODOS.GET_FAVORECIDOS_ERRO,
                    payload: msgErro
                });
            }).finally(() => dispatch(setExibirLoadingConta(false)));
    };
}

export function buscarBancos() {
    return (dispatch) => {
        dispatch(setExibirLoadingConta(true));
        axios.get(URL_LISTA_BANCOS(), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.GET_BANCOS,
                    payload: retornaBancosParaCombo(response.data)
                });
            })
            .catch((err) => {
                let msgErro = 'erro ao listar bancos';
                if (err !== undefined && err.response !== undefined && err.response.data !== undefined && err.response.data.erros !== undefined) {
                    msgErro = err.response.data.erros;
                }
                dispatch({
                    type: METODOS.GET_BANCOS_ERRO,
                    payload: msgErro
                });
            }).finally(() => dispatch(setExibirLoadingConta(false)));
    };
}

export const solicitarTransferenciaBancaria = (dadosTransferencia, necessitaToken, necessitaTokenDifTit, outorgaPodeAprovar, agendamento) => {
    return async dispatch => {
        if (dadosTransferencia.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value) {
            solicitarTransferenciaBancariaEntreContasBPP(dadosTransferencia, necessitaToken, necessitaTokenDifTit, outorgaPodeAprovar, dispatch);
        } else if (dadosTransferencia.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
            dispatch(setExibirLoadingConta(true));
            if(agendamento) {
                solicitarTransferenciaBancariaParaOutrosBancos(dadosTransferencia, necessitaToken, outorgaPodeAprovar, dispatch);
            } else {
                const { error, data, solicitacaoDeTransferenciaBancariaRealizada, action } = await fetchGetPermitirFazerTed().catch(() => dispatch({ type: action, payload: error}));                                               
                if (!error && data && data.valid){               
                    solicitarTransferenciaBancariaParaOutrosBancos(dadosTransferencia, necessitaToken, outorgaPodeAprovar, dispatch);
                }
                else{
                    if(data){
                        dispatch({ type: action, payload: { ...data } });
                    }
                    else{
                        dispatch({ type: action, payload: { solicitacaoDeTransferenciaBancariaRealizada } });
                    }               
                }
            }
            dispatch(setExibirLoadingConta(false));
        }
    };
};

export const getContas = (pessoaLogada) => {
    return dispatch => {
        dispatch(setExibirLoadingConta(true));
        const exibeSaldo = false;
        return fetchGetConta(pessoaLogada.id, '', exibeSaldo)
            .then(resp => {
                const { data: { registros } } = resp;
                dispatch({
                    type: METODOS.CONTA.GET_CONTAS,
                    payload: { contasRecuperadas: registros, pessoaLogada }
                });
            })
            .catch((err) => dispatch({ type: METODOS.CONTA.GET_CONTAS_ERRO, payload: err.data }))
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const getContasPessoaTitular = (pessoaLogada) => {
    const pessoaSelecionada = pessoaLogada;
    return fetchGetConta(pessoaLogada.id, pessoaLogada.id)
        .then(({ data: { registros } }) => ([{ contas: registros, pessoaSelecionada }]));
};

export const getContasEmpresas = async (pessoaLogadaId, listarDeEmpresas) => {
    return await Promise.all(listarDeEmpresas.map(async empresa => {
        const pessoaSelecionada = empresa;
        const { data: { registros } } = await fetchGetConta(pessoaLogadaId, empresa.id);
        return { contas: registros, pessoaSelecionada };
    }));
};

export const solicitarTokenTransferenciaBancaria = (operacaoId, dadosTransferencia) => {
    return dispatch => {
        dispatch(setExibirLoadingConta(true));
        solicitarToken(operacaoId, dadosTransferencia, dispatch)
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const solicitaEfetivacaoTransacaoDeTransferenciaBancaria = (operacaoId, tokenId, dataAgendamento, dadosTransferencia) => {
    return dispatch => {
        return efetivaTransacaoDeTransferenciaBancaria(operacaoId, tokenId, dataAgendamento, dadosTransferencia, dispatch);
    };
};

export const insereAutorizadoEPoderes = (dadosConta) => {
    const { contaId } = dadosConta;
    const numeroDocumento = removeMascaraTelefoneCpfCnpj(dadosConta.numeroDocumento);
    return dispatch => {
        dispatch(setExibirLoadingConta(true));
        fetchGetPessoaPorNumeroDocumento(numeroDocumento)
            .then(async ({ data: { registros } }) => !registros.length ? await fetchGravaPessoa(dadosConta) : await { data: (registros.find(r => r.id.indexOf('BPP') !== -1) || []) })
            .then(async ({ data }) => await gravaOutorga({ pessoaId: data.id || data[0].id, ...dadosConta }))
            .then(resp => dispatch({ type: METODOS.CONTA.INSERE_AUTORIZADO_E_PODERES, payload: resp.data }))
            .catch(err => dispatch({ type: METODOS.CONTA.INSERE_AUTORIZADO_E_PODERES_ERRO, payload: err.data }))
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const getAutorizadosDaConta = (contaId) => {
    return async dispatch => {
        dispatch(setExibirLoadingConta(true));
        return await Promise.all([
            retornaAutorizadosDaConta(contaId),
            retornaPerfisEPoderesOutorga()
        ])
            .then(result => {
                let listaDeAutorizados = result[0].data && result[0].data.resultado || result[0].resultado;
                let perfis = result[1].data && result[1].data.valor.perfil || result[1].valor.perfil;

                listaDeAutorizados = listaDeAutorizados.filter(autorizado => {
                    const { documento, email, telefone } = autorizado;
                    autorizado.email = email || '';
                    autorizado.numeroTelefone = telefone ? retornaTelefoneOuCelularComMascara(retornaNumeroTelefonePessoa(telefone)) : '';
                    autorizado.cpf = documento ? CPF.format(documento.numero) : '';

                    // retorna as permissões da lista de pessoas outorgadas para a conta.
                    autorizado.permissoes = perfis.filter(p =>
                        autorizado.outorga && autorizado.outorga.perfil && autorizado.outorga.perfil.find(pf => pf === p.nomePerfil) !== undefined
                    );

                    const isAutorizado = autorizado.permissoes.length > 0;

                    // caso exista permissões de outorga ele mapeia a forma de mostrar as permissões.
                    if (isAutorizado) {
                        autorizado.permissoes = autorizado.permissoes.map(el => el.nomeExibicao).join(', ');
                    }

                    return isAutorizado;
                });

                dispatch({ type: METODOS.CONTA.GET_AUTORIZADOS_CONTA, payload: listaDeAutorizados });
            })
            .catch(err => dispatch({ type: METODOS.CONTA.GET_AUTORIZADOS_CONTA_ERRO, payload: err.data }))
            .finally(() => dispatch(setExibirLoadingConta(false)));
    };
};

export const setHorarioPermitidoFazerTed = (horarioPermitidoTed) =>({
    type: METODOS.TRANSFERENCIA_BANCARIA.SET_HORARIO_PERMITIDO_FAZER_TED,
    payload: horarioPermitidoTed
});

const abreOperacaoDeTransferenciaBancaria = (dadosTransferencia) => {
    let body = montaJsonDeTransferenciaBancariaParaBackEnd(dadosTransferencia);
    return fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: body
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json(), action: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO }));
};

const solicitarTransferenciaBancariaEntreContasBPP = async (dadosTransferencia, necessitaToken, necessitaTokenDifTit, outorgaPodeAprovar, dispatch) => {
    dispatch(setExibirLoadingConta(true));
    const pagamentoAgendado = dadosTransferencia.dataAgendamento;
    let escolhaNecessitaToken;
    const contaRecuperada = await getContaByNumeroEDigito(retornaCorpoParaBuscaDeContaPorNumeroEDigito(dadosTransferencia))
        .then((resp) => {
            if (resp && resp.data && resp.data.registros && resp.data.registros.length) {
                const contaRecuperada = resp.data.registros[0];
                dadosTransferencia = retornaJsonParaTransferenciaBancariaComContaDestino(dadosTransferencia, contaRecuperada);
                escolhaNecessitaToken = dadosTransferencia.transferenciaDeMesmaTitularidade ? necessitaToken : necessitaTokenDifTit;
                return contaRecuperada;
            }
            return Promise.reject({ erros: [{ campo: 'numeroConta', mensagem: MENSAGENS.CONTAS.CONTA_OU_DIGITO_NAO_ENCONTRADA }], action: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO });
        })
        .catch((err) => {
            dispatch({
                type: err.action,
                payload: err
            });
            dispatch(setExibirLoadingConta(false));
        });
    if (contaRecuperada) {
        if ((escolhaNecessitaToken && outorgaPodeAprovar) || (escolhaNecessitaToken && pagamentoAgendado && outorgaPodeAprovar)) {
            validaTransferenciaEntreContas(retornaCorpoDeContasParaValidarTransferencia(dadosTransferencia.contaSelecionada.id, contaRecuperada.id))
                .then(async ({ data: { rel } }) => await abreOperacaoDeTransferenciaBancaria(dadosTransferencia))
                .then(async ({ data: { rel } }) => dadosTransferencia.transferenciaDeMesmaTitularidade ? await efetivaTransacaoDeTransferenciaBancaria(rel, dadosTransferencia.tokenId, dadosTransferencia.dataAgendamento, dadosTransferencia, dispatch) : await solicitarToken(rel, dadosTransferencia, dispatch))
                .then(async resp => resp && dispatch({
                    type: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA,
                    payload: resp
                }))
                .catch((err) => {
                    dispatch({
                        type: err.action,
                        payload: err
                    });
                }).finally(() => dispatch(setExibirLoadingConta(false)));
        } else if (outorgaPodeAprovar) {
            // 'Operação cadastrada e aprovada com sucesso.'
            validaTransferenciaEntreContas(retornaCorpoDeContasParaValidarTransferencia(dadosTransferencia.contaSelecionada.id, contaRecuperada.id))
                .then(async ({ data: { rel } }) => await abreOperacaoDeTransferenciaBancaria(dadosTransferencia))
                .then(async ({ data: { rel } }) => await efetivaTransacaoDeTransferenciaBancaria(rel, dadosTransferencia.tokenId, dadosTransferencia.dataAgendamento, dadosTransferencia, dispatch))
                .then((resp) => {
                    resp && resp.ok && dispatch({
                        type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA,
                        payload: 'Operação cadastrada e aprovada com sucesso.'
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: err.action,
                        payload: err
                    });
                })
                .finally(() => dispatch(setExibirLoadingConta(false)));
        } else {
            // 'Operação cadastrada e pendente de aprovação.'
            validaTransferenciaEntreContas(retornaCorpoDeContasParaValidarTransferencia(dadosTransferencia.contaSelecionada.id, contaRecuperada.id))
                .then(async ({ data: { rel } }) => await abreOperacaoDeTransferenciaBancaria(dadosTransferencia))
                .then(async resp => dadosTransferencia.salvarFavorecidoConta ? ({ resp: await salvarFavorecidoConta(dadosTransferencia.contaSelecionada.id, dadosTransferencia, dispatch), error: resp.error, data: resp.data }) : resp)
                .then(({ error, data }) => {
                    dispatch({
                        type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA,
                        payload: 'Operação cadastrada e pendente de aprovação.'
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: err.action,
                        payload: err
                    });
                })
                .finally(() => dispatch(setExibirLoadingConta(false)));
        }
    }
};

const solicitarTransferenciaBancariaParaOutrosBancos = (dadosTransferencia, necessitaToken, outorgaPodeAprovar, dispatch) => {
    dispatch(setExibirLoadingConta(true));
    const pagamentoAgendado = dadosTransferencia.dataAgendamento;
    if ((necessitaToken && outorgaPodeAprovar)) {
        abreOperacaoDeTransferenciaBancaria(dadosTransferencia)
            .then(async ({ data: { rel } }) => await solicitarToken(rel, dadosTransferencia, dispatch))
            .then(async resp => resp && dispatch({
                type: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA,
                payload: resp
            }))
            .catch((err) => {
                dispatch({
                    type: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO,
                    payload: err
                });
            }).finally(() => dispatch(setExibirLoadingConta(false)));
    } else if (outorgaPodeAprovar) {
    // 'Operação cadastrada e aprovada com sucesso.'
        abreOperacaoDeTransferenciaBancaria(dadosTransferencia)
            .then(async ({ data: { rel } }) => await efetivaTransacaoDeTransferenciaBancaria(rel, dadosTransferencia.tokenId, dadosTransferencia.dataAgendamento, dadosTransferencia, dispatch))
            .then((resp) => {
                resp && resp.ok && dispatch({
                    type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA,
                    payload: 'Operação cadastrada e aprovada com sucesso.'
                });
            })
            .catch((err) => {
                dispatch({
                    type: err.action,
                    payload: err
                });
            })
            .finally(() => dispatch(setExibirLoadingConta(false)));
    } else {
    // 'Operação cadastrada e pendente de aprovação.'
        abreOperacaoDeTransferenciaBancaria(dadosTransferencia)
            .then(async resp => dadosTransferencia.salvarFavorecidoConta ? ({ resp: await salvarFavorecidoConta(dadosTransferencia.contaSelecionada.id, dadosTransferencia, dispatch), error: resp.error, data: resp.data }) : resp)
            .then(async ({ error, data: { rel } }) => {
                !error && dispatch({
                    type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA,
                    payload: 'Operação cadastrada e pendente de aprovação.'
                });
            })
            .catch((err) => {
                dispatch({
                    type: err.action,
                    payload: err
                });
            })
            .finally(() => dispatch(setExibirLoadingConta(false)));
    }
};

// TODO: os requisitantes dessa chamada só podem executálos, quando tiver permissão de podeAprovar (outorga-negocio)
const efetivaTransacaoDeTransferenciaBancaria = (operacaoId, tokenId, dataAgendamento, dadosTransferencia, dispatch) => {
    let bodyTransacao = montaRequestBodyPostOperacaoTransacao(tokenId, dataAgendamento);
    dispatch(setExibirLoadingConta(true));
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: bodyTransacao
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json(), action: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO }))
        .then(async resp => dadosTransferencia.salvarFavorecidoConta ? ({ resp: await salvarFavorecidoConta(dadosTransferencia.contaSelecionada.id, dadosTransferencia, dispatch), error: resp.error, data: resp.data }) : resp)
        .then((resp) => {
            dispatch({
                type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA,
                payload: dataAgendamento ? 'Operação cadastrada e agendada com sucesso' : 'Operação cadastrada e aprovada com sucesso.'
            });
            return resp;
        }).catch((err) => {
            dispatch({
                type: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO,
                payload: err.data
            });
        }).finally(() => dispatch(setExibirLoadingConta(false)));
};

const validaTransferenciaEntreContas = (contasIdsParaValidar) => {
    return fetch(montaUrlFetch(urlServicos.VALIDAR_TRANSFERENCIA_ENTRE_CONTAS), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: contasIdsParaValidar
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: { mensagem: MENSAGENS.CONTAS.TRANSFERENCIA_BANCARIA.contasIdsParaValidar }, action: METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO }));
};

const solicitarToken = (operacaoId, dadosTransferencia, dispatch) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), retornaCorpoParaEfetivarTransacao(dadosTransferencia)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : await Promise.reject({ error: !resp.ok, data: await resp.json(), action: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO }))
        .then((result) => {
            dispatch({
                type: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA,
                payload: { ...result.data, operacaoId }
            });
        }).catch((err) => {
            dispatch({
                type: METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO,
                payload: err.data
            });
        });
};

const getContaById = async (dispatch, contaId) => {
    dispatch(setExibirLoadingConta(true));
    return await fetGetContaById(contaId)
        .then(response => {
            dispatch({
                type: METODOS.GET_CONTA_POR_ID,
                payload: response.data
            });
            return response.data;
        })
        .catch(err => {
            dispatch({
                type: METODOS.GET_CONTA_POR_ID_ERRO,
                payload: err.data
            });
        }).finally(() => dispatch(setExibirLoadingConta(false)));
};

const getContaByNumeroEDigito = (dadosConta) => {
    return fetch(montaUrlFetch(urlServicos.CONTA, dadosConta), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json(), action: METODOS.CONTA.GET_CONTA_POR_NUMERO_E_DIGITO_ERRO }));
};

const salvarFavorecidoConta = (contaId, dadosFavorecido, dispatch) => {
    return fetch(montaUrlFetch(urlServicos.FAVORECIDO(contaId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: montaDadosFavorecidoJSonOBackEnd(dadosFavorecido)
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }))
        .then((result) => {
            dispatch({
                type: METODOS.CONTA.SALVAR_FAVORECIDO_CONTA,
                payload: result.data
            });
        }).catch((err) => {
            dispatch({
                type: METODOS.CONTA.SALVAR_FAVORECIDO_CONTA_ERRO,
                payload: err.data
            });
        });
};
