import { METODOS, MENSAGENS } from '../../componentes/util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = { pessoaId: '', pessoaLogada: '', loginEfetuadoComSucesso: false, loginNaoEfetuado: '' };

export default (state = INITIAL_STATE, { type, payload }) => {
    switch (type) {
        case METODOS.LOGIN.POST_LOGIN:
            return { ...state, ...payload, loginNaoEfetuado: '' };
        case METODOS.LOGIN.POST_LOGIN_ERRO:
            return { ...state, loginNaoEfetuado: MENSAGENS.LOGIN.FINALIZACAO.ERRO };
        case METODOS.LOGIN.LIMPA_DADOS_LOGIN:
            return state;
        // return { ...state, dadosLogin: payload };
        default:
            return state;
    }
};
