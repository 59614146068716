import React from 'react';
import { Table } from 'react-bootstrap';
import { PERFIS, LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';

const textoAlinhadoNoCentro = { textAlign: 'center' };
const ModalExibirPerfilDetalhes = props => {
    const retornaCorpoModalExibirPerfilDetalhes = () => (
        <Table responsive striped bordered condensed hover>
            <thead>
                <tr style={{ backgroundColor: 'grey' }}>
                    <th style={textoAlinhadoNoCentro}>{LABEL_COMUNS.PERFIL}</th>
                    <th style={textoAlinhadoNoCentro}>{LABEL_COMUNS.CONSULTAR}</th>
                    <th style={textoAlinhadoNoCentro}>{LABEL_COMUNS.CRIAR}</th>
                    <th style={textoAlinhadoNoCentro}>{LABEL_COMUNS.APROVAR}</th>
                    <th style={textoAlinhadoNoCentro}>{LABEL_COMUNS.OBSERVACAO}</th>
                </tr>
            </thead>
            <tbody>
                {Object.keys(PERFIS).map((key, idx) => (
                    <tr key={idx}>
                        <td>{PERFIS[key].label}</td>
                        <td style={textoAlinhadoNoCentro}>{PERFIS[key].consultar}</td>
                        <td style={textoAlinhadoNoCentro}>{PERFIS[key].criar}</td>
                        <td style={textoAlinhadoNoCentro}>{PERFIS[key].aprovar}</td>
                        <td>{PERFIS[key].descricao}</td>

                    </tr>
        ))}
                {/*   {
                    Object.keys(PERFIS).map(key => Object.keys(PERFIS[key]).filter(f => f !== 'value').map(sk => PERFIS[key][sk])).map((e, idx) => (
                        <tr key={idx}>
                            {e.map((strg, i) => <td style={{ textAlign: (i === 1 || i === 2 || i === 3) && 'center' }} key={i}>{strg}</td>)}
                        </tr>
                    ))
                } */}
                {/*  {Object.keys(PERFIS).map(key => Object.keys(PERFIS[key]).filter(f => f !== 'value').map(sk => PERFIS[key][sk])).map((e, idx) => (
                    <tr key={idx}>
                        {e.map((strg, i) => <td style={{ textAlign: (i === 1 || i === 2 || i === 3) && 'center' }} key={i}>{strg}</td>)}
                    </tr>
                ))} */}

            </tbody>
        </Table>
    );

    const retornaRodapeModalPerfilDetalhes = () => (
        <FooterBotoesModalComponent
      btn2Label={LABEL_COMUNS.OK}
      btn2OnClick={() => props.setExibirModalPerfilDetalhes(false)}
    />
    );

    return (
        <div>
            <ModalComponent
        name="modal-exibir-perfil-detalhes"
        bsSize="lg"
        exibirModal={props.exibirModalPerfilDetalhes}
        header={LABEL_COMUNS.PERFIL}
        body={retornaCorpoModalExibirPerfilDetalhes()}
        footer={retornaRodapeModalPerfilDetalhes()}
      />
        </div >
    );
};
export default ModalExibirPerfilDetalhes;
