export const METODOS = {
    LISTAR_CARGA_LOTE: 'LISTAR_CARGA_LOTE',
    BUSCAR_USUARIOS_: 'BUSCAR_USUARIOS_',
    LISTAR_FALHA_: 'LISTAR_FALHA_',
    MUDAR_NOME_ARQUIVO_: 'MUDAR_NOME_ARQUIVO_',
    MUDAR_DATA_INICIO_: 'MUDAR_DATA_INICIO_',
    MUDAR_DATA_FIM_: 'MUDAR_DATA_FIM_',
    MUDAR_USUARIO_: 'MUDAR_USUARIO_',
    LIMPAR_CAMPOS_: 'LIMPAR_CAMPOS_',
    SETAR_BUSCANDO_TRUE_: 'SETAR_BUSCANDO_TRUE_',
    BUSCAR_DADOS_PARA_CSV_: 'BUSCAR_DADOS_PARA_CSV_',
    LIMPAR_DADOS_CSV_: 'LIMPAR_DADOS_CSV_',
    SETAR_MODAL_DADOS_NAO_ENCONTRADOS_: 'SETAR_MODAL_DADOS_NAO_ENCONTRADOS_',
    SETAR_OCULTAR_ERRO_: 'SETAR_OCULTAR_ERRO_'
};
