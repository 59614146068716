import React, { Component } from 'react';
import Grid from '../../../../componentes-uteis/grid/grid';
import {
    CARTAO_STATUS,
    LABEL_COMUNS,
    OPERACAO,
    TIPO_DE_ACESSO
} from '../../../../util/contantes-comuns/constantes-comuns';
import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import MostrarSaldoCartao from '../../../../contas/saldo/cartao/mostrar-saldo-cartao';
import If from '../../../../componentes-uteis/if/if';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { Link } from 'react-router-dom';
import { formatarStatus } from '../../../../util/cartoes/cartoes-util';

class GridCartoesDashboardBppCorp extends Component {
    render() {
        const { podeConsultar, podeCriar, podeAprovar } = this.props.outorgaPerfils;
        return (
            <div>
                <If test={this.props.listaDeCartoes && this.props.listaDeCartoes.length > 0}>
                    <div className="row">
                        <Grid cols="12 12 12 12" className="grids-dashboards">
                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                <span className="titulo-dashboards-conta-solucao">
                                    {LABEL_COMUNS.CARTOES.PORTADOR}
                                </span>
                            </Grid>
                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                <span className="titulo-dashboards-conta-solucao">
                                    {LABEL_COMUNS.CARTOES.NUMERO_DO_CARTAO}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                <span className="titulo-dashboards-conta-solucao">
                                    {LABEL_COMUNS.CARTOES.CARTAO_ID}
                                </span>
                            </Grid>
                            <If test={podeConsultar}>
                                <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.CARTOES.SALDO_DO_CARTAO}
                                    </span>
                                </Grid>
                            </If>
                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                <span className="titulo-dashboards-conta-solucao">
                                    {LABEL_COMUNS.STATUS}
                                </span>
                            </Grid>
                            <If test={podeConsultar}>
                                <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.ACOES}
                                    </span>
                                </Grid>
                            </If>
                        </Grid>
                    </div>
                    <div className="grid-agendamento grids-dashboards">
                        {this.props.listaDeCartoes.map((cartao, idx) => {
                            return (
                                <div className="row" key={idx}>
                                    <Grid cols="12 12 12 12">
                                        <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                            <span style={{ fontSize: '11px' }}>
                                                {cartao.nomePortador}
                                            </span>
                                        </Grid>
                                        <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                            <span style={{ fontSize: '11px' }}>
                                                {cartao.panFormatado}
                                            </span>
                                        </Grid>
                                        <Grid cols="2 2 2 2" className="alinhamento-esquerda grid-cartoes-bppcorp">
                                            <span style={{ fontSize: '11px' }}>
                                                <b>
                                                    {cartao.idCartaoImpresso}
                                                </b>
                                            </span>
                                        </Grid>
                                        <If test={podeConsultar}>
                                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                <span>
                                                    <MostrarSaldoCartao contaCartaoId={cartao.cartaoId} />
                                                </span>
                                            </Grid>
                                        </If>
                                        <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                            <span style={{ fontSize: '11px' }}>
                                                {formatarStatus(cartao.status)}
                                            </span>
                                        </Grid>
                                        <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                            <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-10px' }}>
                                                <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                    <Glyphicon glyph="option-horizontal" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>

                                                    <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.BLOQUEADO || cartao.status === CARTAO_STATUS.CANCELADO}>
                                                        <If test={podeConsultar}>
                                                            <li onClick={() => this.props.setArmazenarCartao(cartao)}>
                                                                <Link className="link_expandir"
                                                                    to={ROTAS_CAMINHOS.EXTRATO_CARTAO_BPP_CORP}>
                                                                    {LABEL_COMUNS.EXTRATO}
                                                                </Link>
                                                            </li>
                                                        </If>
                                                    </If>
                                                    <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                        <If test={podeCriar}>
                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setShowChargeOrDischargeCard(true);
                                                                this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CARGA_CARTAO);
                                                            }} >
                                                                {LABEL_COMUNS.CARREGAR_CARTAO}
                                                            </MenuItem>

                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setShowChargeOrDischargeCard(true);
                                                                this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESCARGA_CARTAO);
                                                            }}>
                                                                {LABEL_COMUNS.DESCARREGAR_CARTAO}
                                                            </MenuItem>
                                                        </If>
                                                    </If>

                                                    <MenuItem onSelect={() => this.props.setArmazenarCartao(cartao)}
                                                        onClick={() => this.props.setExibirModalDetalhePortador(true)}>
                                                        {LABEL_COMUNS.DETALHE_PORTADOR}
                                                    </MenuItem>

                                                    <If test={cartao.status === CARTAO_STATUS.ATIVO || (cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO && cartao.nomePortador !== '' && cartao.nomePortador !== undefined)}>
                                                        <If test={podeAprovar && podeCriar}>
                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setExibirModalAlterarPortador(true);
                                                            }}>
                                                                {LABEL_COMUNS.ALTERAR_PORTADOR}
                                                            </MenuItem>
                                                        </If>
                                                    </If>

                                                    <If test={podeCriar}>
                                                        <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                            <MenuItem
                                                                onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowLockCardModal(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.ALTERA_STATUS_CARTAO);
                                                                }} >
                                                                {LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                                                            </MenuItem>
                                                        </If>
                                                        <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setShowCancelCardModal(true);
                                                                this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CANCELA_CARTAO);
                                                            }} >
                                                                {LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                                                            </MenuItem>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.BLOQUEADO}>
                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setShowUnlockCardModal(true);
                                                                this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESBLOQUEIO_CARTAO);
                                                            }} >
                                                                {LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                                                            </MenuItem>
                                                        </If>
                                                    </If>

                                                    <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                        <If test={this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA && !cartao.portadorPessoaId}>
                                                            <MenuItem onSelect={() => {
                                                                this.props.setArmazenarCartao(cartao);
                                                                this.props.setCartaoSelecionadoParaVincularPortador(cartao);
                                                            }}
                                                                onClick={() => this.props.setExibirModalDeVincularPortador(true)} >
                                                                {LABEL_COMUNS.VINCULAR_PORTADOR}
                                                            </MenuItem>
                                                        </If>
                                                    </If>
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </Grid>
                                    </Grid>
                                </div>
                            );
                        })}
                    </div>
                </If>
                <If test={this.props.listaDeCartoes.length <= 0}>
                    <span>
                        {LABEL_COMUNS.NENHUM_CARTAO_ENCONTRADO(this.props.statusCartao)}
                    </span>
                </If>
            </div >
        );
    }
}

export default GridCartoesDashboardBppCorp;
