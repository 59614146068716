import React, { Component } from 'react';
import { connect } from 'react-redux';
import { LABEL_COMUNS, MENSAGENS, OPERACAO, TIPO_DE_TRANSACAO, CANAL_TOKEN } from '../../util/contantes-comuns/constantes-comuns';
import { podeConsultar, podeCriar, podeAprovar, outorgaParametroOperacao } from '../../../reducer/outorga/outorga-operacao-reducer';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { cancelarCartao } from '../../../servicos/cartoes/cartoes-service';
import Token from '../../comuns/token/token';

class CancelarCartao extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            showCancelModal: false, messageCancelModal: '', operationId: '', showCancelFinalizationModal: false,
            cancelFinalizationMessage: '', showCancelModalToken: false
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillReceiveProps = (nextProps) => {
        const { cancelSelectedCard } = nextProps;
        this.setCancelSelectedCard(cancelSelectedCard);
        this.setCancelMessageModal(MENSAGENS.CARTAO.CANCELAR_CARTAO.CONFIRMACAO(cancelSelectedCard.quatroUltimosDigitos));
    };

    setCancelSelectedCard = (cancelSelectedCard) => {
        this.setState({ cancelSelectedCard });
    };

    setCancelMessageModal = (messageCancelModal) => {
        this.setState({ messageCancelModal: messageCancelModal });
    };

    setCancelFinalizationMessage = (cancelFinalizationMessage) => {
        this.setState({ cancelFinalizationMessage: cancelFinalizationMessage });
    };

    setShowCancelModalToken = (showCancelModalToken) => {
        this.setState({ showCancelModalToken: showCancelModalToken });
    };

    setOperationId = (operationId) => {
        this.setState({ operationId });
    };

    setShowCancelFinalizationModal = (showCancelFinalizationModal) => {
        this.setState({ showCancelFinalizationModal });
    };

    onCancelCard = async () => {
        this.props.setShowCancelLoading(true);
        const { cancelSelectedCard } = this.state;
        const contaDestinoId = cancelSelectedCard.cartaoId;
        const { grantChangeStatusCard } = this.props;
        const necessitaOtp = grantChangeStatusCard.aprovacao;

        this.props.setShowCancelCardModal(false);
        const response = await cancelarCartao(contaDestinoId, cancelSelectedCard, necessitaOtp);
        const { message, operationId } = response;
        if (message) {
            this.setCancelFinalizationMessage(message);
            this.props.setShowCancelFinalizationModal(true);
        }
        else {
            if (operationId) {
                this.setOperationId(operationId);
                this.setShowCancelModalToken(true);
            }
        }
        this.props.cancelGetCards();


        this.props.setShowCancelLoading(false);
    };

    callBackTransaction = (retorno) => {
        let message = MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.SUCESSO();
        if (retorno.error) {
            message = MENSAGENS.CARTAO.CANCELAR_CARTAO.FINALIZACAO.ERRO();
        }
        this.setCancelFinalizationMessage(message);
        this.props.setShowCancelFinalizationModal(true);
    };

    clearState = () => {
        this.setState(this.INITIAL_STATE);
    };

    render() {
        const { showCancelCardModal, showCancelFinalizationModal } = this.props;
        const { messageCancelModal, operationId, showCancelModalToken, cancelFinalizationMessage } = this.state;
        const transacao = TIPO_DE_TRANSACAO.APROVACAO;


        return (
            <div>
                <ModalComponent exibirModal={showCancelCardModal}
                    name="modal-cancelar-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: messageCancelModal }} />}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={this.props.contextoBppPag}
                            btn1Label={LABEL_COMUNS.NAO}
                            btn1OnClick={() => {
                                this.props.setShowCancelCardModal(false);
                            }}
                            btn2Label={LABEL_COMUNS.SIM}
                            btn2OnClick={() => {
                                this.onCancelCard();

                            }}
                        />
                    } />
                <ModalComponent exibirModal={showCancelFinalizationModal}
                    name="modal-cancelar-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: cancelFinalizationMessage }} />}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label={LABEL_COMUNS.OK}
                            contextoBppPag={this.props.contextoBppPag}
                            btn2OnClick={() => {
                                this.props.setShowCancelFinalizationModal(false);
                                this.props.cancelGetCards();
                                this.clearState();
                            }}
                        />
                    } />

                <Token
                    contextoBppPag={this.props.contextoBppPag}
                    dadosIntegracao={transacao}
                    dadosOtp={{ ...CANAL_TOKEN.CORPO_REQUISICAO_SMS, ...transacao }}
                    exibirModal={showCancelModalToken}
                    fecharModal={() => {
                        this.setShowCancelModalToken(false);
                    }}
                    operacaoId={operationId}
                    titulo={LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                    resultadoCallback={this.callBackTransaction}
                />

            </div>
        );
    }
}
export default CancelarCartao;
