import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import './contas.css';
import { Button, Dropdown, Glyphicon } from 'react-bootstrap';
import MostrarSaldo from './saldo/mostrar-saldo';
import If from '../componentes-uteis/if/if';
import FiltroComponent from '../componentes-uteis/filtros/filtro-component';
import FiltroListaCartoes from '../componentes-uteis/filtros/filtro-lista-cartoes';
import { DESCRICAO_CONTA, LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import ContaSelecionadaAction from '../contas/conta-selecionada-action';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import { ESTILO } from '../util/estilo_para_componentes/estiloUtil';

let contaSelecionadaAction = new ContaSelecionadaAction();

class ContaSelecionada extends Component {
    constructor(props) {
        super(props);
        contaSelecionadaAction.setInicializarDispatch(this.props.dispatch);
    }
    mostrarExtrato() {
        window.location.href = '/extrato-conta';
    }
    irParaPedidoCartaoImpresso() {
        window.location.href = '/pedido-cartao-impresso';
    }
    exibirEmBreve() {
        alert('teste');
    }
    irParaPedidoCarga() {
        window.location.href = '/pedido-carga';
    }
    corpoModalEmBreve() {
        return (
            <div>
                <span>Em Breve </span>
            </div>
        );
    }
    rodapeModalEmBreve() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button
                        bsStyle="primary"
                        onClick={() => contaSelecionadaAction.setExibirEmBreveAction(false)}>OK
                    </Button>
                </div>
            </div>
        );
    }

    render() {
        const contaSelecionada = this.props.contaSelecionada;
        const mostrarSaldoESaldoBloqueado = contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_MOVIMENTO;
        const corDeFundoParaTitulo = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG : ESTILO.COR_DE_FUNDO.TITULO.PADRAO;
        const corDeFundoFiltro = this.props.contextoBppPag ? ESTILO.COR_DE_FUNDO.FILTRO.BPP_PAG : ESTILO.COR_DE_FUNDO.FILTRO.PADRAO;
        return (
            <div>
                <ModalComponent
                    name="ModalOcultarErro"
                    exibirModal={this.props.contaSelecionadaReducer.exibirEmBreve === '' ? false : this.props.contaSelecionadaReducer.exibirEmBreve}
                    body={this.corpoModalEmBreve()}
                    header="BPP Web" footer={this.rodapeModalEmBreve()}
                />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                        <div className="panel panel-default">
                            <div className={corDeFundoParaTitulo} style={{ height: '40px' }}>
                                <div className="col-sm-10 col-md-10 col-lg-10">
                                    <If test={contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_MOVIMENTO || contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO}>
                                        <div className="texto_dadosconta">{LABEL_COMUNS.CONTA_DIGITAL} - {contaSelecionada.apelido} - {contaSelecionada.agencia} / {contaSelecionada.numero}-{contaSelecionada.digito}</div>
                                    </If>
                                    <If test={contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_CARTAO}>
                                        <div className="texto_dadosconta">{LABEL_COMUNS.CARTAO_ID(contaSelecionada.idCartaoImpresso)}</div>
                                    </If>
                                </div>
                                <If test={this.props.mostrarExtrato}>
                                    <div className="col-sm-2 col-md-2 col-lg-2 alinhamento-direita botao_extrato">
                                        <Dropdown id="menuConta" pullRight>
                                            <Dropdown.Toggle noCaret className={corDeFundoFiltro}>
                                                <Glyphicon glyph="option-horizontal" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu >
                                                <li>
                                                    <Link className="link_expandir" to={ROTAS_CAMINHOS.EXTRATO_CONTA}>
                                                        {LABEL_COMUNS.EXPANDIR}
                                                    </Link>
                                                </li>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                                </If>
                            </div>
                            <div className="panel-body">
                                <div className="row">
                                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                        <MostrarSaldo
                                            exibirSaldo={mostrarSaldoESaldoBloqueado}
                                            contextoBppPag={this.props.contextoBppPag}
                                            mostrarSaldoBloqueado={mostrarSaldoESaldoBloqueado}
                                            contaSelecionada={contaSelecionada} />
                                    </div>
                                </div>
                                <div className="row">
                                    <If test={this.props.exibirFiltroCartoes}>
                                        <hr />
                                        <FiltroListaCartoes
                                            contextoBppPag={this.props.contextoBppPag}
                                            exibirNomeECpfDoPortador={this.props.exibirNomeECpfDoPortador}

                                            contaSelecionada={contaSelecionada}

                                            cartaoId={this.props.cartaoId}
                                            setCartaoId={this.props.setCartaoId}
                                            cartaoIdErro={this.props.cartaoIdErro}

                                            nomePortador={this.props.nomePortador}
                                            setNomePortador={this.props.setNomePortador}
                                            nomePortadorErro={this.props.nomePortadorErro}

                                            numeroDocumentoPortador={this.props.numeroDocumentoPortador}
                                            setNumeroDocumentoPortador={this.props.setNumeroDocumentoPortador}
                                            numeroDocumentoPortadorErro={this.props.numeroDocumentoPortadorErro}

                                            cartaoQuatroUltimosDigitos={this.props.cartaoQuatroUltimosDigitos}
                                            setCartaoQuatroUltimosDigitos={this.props.setCartaoQuatroUltimosDigitos}
                                            cartaoQuatroUltimosDigitosErro={this.props.cartaoQuatroUltimosDigitosErro}

                                            buscar={this.props.buscar}
                                            limparFiltro={this.props.limparFiltro}
                                            exportarCsv={this.props.exportarCsv}
                                            listaParaSerconvertidaEmCSV={this.props.listaParaSerconvertidaEmCSV}
                                            nomeDoArquivoCsv={this.props.nomeDoArquivoCsv}
                                        />
                                    </If>
                                    <If test={this.props.mostrarFiltroDeDatas}>
                                        <hr />
                                        <FiltroComponent
                                            contextoBppPag={this.props.contextoBppPag}

                                            exibirFiltroDeAnexoContratual={this.props.exibirFiltroDeAnexoContratual}
                                            exibirBotaoLimpar={this.props.exibirBotaoLimpar}
                                            exibirFiltroDeDatas2={this.props.exibirFiltroDeDatas2}
                                            mostrarFiltroDeIdNumerico={this.props.mostrarFiltroDeIdNumerico}

                                            exibirFiltroDeOperacao={this.props.exibirFiltroDeOperacao}
                                            exibirFiltroDeStatus={this.props.exibirFiltroDeStatus}
                                            exibirFiltroDeValor={this.props.exibirFiltroDeValor}

                                            exportarCsv={this.props.exportarCsv}
                                            listaParaSerconvertidaEmCSV={this.props.listaParaSerconvertidaEmCSV}
                                            nomeDoArquivoCsv={this.props.nomeDoArquivoCsv}

                                            dataInicialLabel={this.props.dataInicialLabel}
                                            dataInicialPlaceHolder={this.props.dataInicialPlaceHolder}
                                            dataInicialValue={this.props.dataInicialValue}
                                            dataIncialChange={this.props.dataIncialChange}
                                            dataInicialErro={this.props.dataInicialErro}

                                            dataFinalLabel={this.props.dataFinalLabel}
                                            dataFinalPlaceHolder={this.props.dataFinalPlaceHolder}
                                            dataFinalValue={this.props.dataFinalValue}
                                            dataFinalChange={this.props.dataFinalChange}
                                            dataFinalErro={this.props.dataFinalErro}

                                            dataInicial2Label={this.props.dataInicial2Label}
                                            dataInicial2PlaceHolder={this.props.dataInicial2PlaceHolder}
                                            dataInicial2={this.props.dataInicial2}
                                            setDataInicial2={this.props.setDataInicial2}
                                            dataInicial2Erro={this.props.dataInicial2Erro}

                                            dataFinal2Label={this.props.dataFinal2Label}
                                            dataFinal2PlaceHolder={this.props.dataFinal2PlaceHolder}
                                            dataFinal2={this.props.dataFinal2}
                                            setDataFinal2={this.props.setDataFinal2}
                                            dataFinal2Erro={this.props.dataFinal2Erro}

                                            idNumerico={this.props.idNumerico}
                                            idNumericoPlaceHolder={this.props.idNumericoPlaceHolder}
                                            setIdNumerico={this.props.setIdNumerico}
                                            idNumericoErro={this.props.idNumericoErro}

                                            anexoContratualSelecionadoId={this.props.anexoContratualSelecionadoId}
                                            setAnexoContratual={this.props.setAnexoContratual}
                                            listaDeAnexosContratuais={this.props.listaDeAnexosContratuais}

                                            operacaoSelecionadaId={this.props.operacaoSelecionadaId}
                                            setOperacao={this.props.setOperacao}
                                            listaDeOperacoes={this.props.listaDeOperacoes}

                                            valorDe={this.props.valorDe}
                                            setValorDe={this.props.setValorDe}
                                            valorDeErro={this.props.valorDeErro}

                                            valorAte={this.props.valorAte}
                                            setValorAte={this.props.setValorAte}
                                            valorAteErro={this.props.valorAteErro}

                                            listaDeStatus={this.props.listaDeStatus}
                                            setStatus={this.props.setStatus}
                                            statusSelecionado={this.props.statusSelecionado}
                                            statusErro={this.props.statusErro}

                                            limparFiltro={this.props.limparFiltro}
                                            filtrar={this.props.filtrar}
                                            filtrarCSV={this.props.filtrarCSV}
                                        />
                                    </If>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
            </div>
        );
    }
}
function mapStateToProps(state) {
    return {
        contaSelecionadaReducer: state.ContaSelecionadaReducer
    };
}
export default withRouter(connect(mapStateToProps, null)(ContaSelecionada));
