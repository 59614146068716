export default class Controle {
    static getInicializarFavorecidoControle() {
        return 'INICIALIZAR_FAVORECIDO_CONTROLE';
    }
    static getDescricaoFavorecidoControle() {
        return 'DESCRICAO_FAVORECIDO_CONTROLE';
    }
    static getMsgFavorecidoControle() {
        return 'MSG_FAVORECIDO_CONTROLE';
    }
    static getUpdateFavorecidoControle() {
        return 'UPDATE_FAVORECIDO';
    }
}
