import './pedido-carga.css';
import React, { Component } from 'react';
import Highlight from 'react-highlighter';
import { reactLocalStorage } from 'reactjs-localstorage';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button, FormControl, FormGroup, Modal, Panel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import ContaSelecionada from '../../componentes/contas/conta-selecionada';
import { LABELS } from './pedido-carga-constantes';
import * as actions from './pedido-carga-solicitacao-action';
import CurrencyInput from 'react-currency-input';
import { LABEL_COMUNS, DESCRICAO_CONTA } from '../util/contantes-comuns/constantes-comuns';
import Grid from '../componentes-uteis/grid/grid';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import LoadingFenix from '../loading-fenix/loading-fenix';
import If from '../componentes-uteis/if/if';
import DataAgendamento from '../componentes-uteis/data-agendamento/data-agendamento';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import { formatarDataUS } from '../util/data/dataUtil';
import { ROTAS_CAMINHOS } from '../rotas-constantes';

const BANCOS = { ITAU: { numero: 341 }, BRADESCO: { numero: 237 } };

class PedidoCargaSolicitacao extends Component {

    componentDidMount() {
        this.props.buscarBancos();
        let transferenciaSelecionadaId = +reactLocalStorage.getObject('transferenciaSelecionadaId');
        if (transferenciaSelecionadaId === BANCOS.ITAU.numero || transferenciaSelecionadaId === BANCOS.BRADESCO.numero) {
            this.props.bancoChange(transferenciaSelecionadaId);
            this.props.desabilitaComboBancoChange(true);
            this.props.bancoDestinoChange(transferenciaSelecionadaId);
        }
        else {
            this.props.bancoDestinoChange(transferenciaSelecionadaId);
        }
    }

    recarregarTela() {
        window.location.href = ROTAS_CAMINHOS.PEDIDO_DE_CARGA_SOLICITACAO;
    }

    recarregarTelaPedidoCartao() {
        window.location.href = ROTAS_CAMINHOS.PEDIDO_DE_CARGA;
    }

    retornaraParaDashboard() {
        window.location.href = ROTAS_CAMINHOS.DASHBOARD_CONTA;
    }

    voltarTela() {
        window.location.href = ROTAS_CAMINHOS.PEDIDO_DE_CARGA;
    }

    exibirPagina = (contaSelecionada) => (
        <div>
            <ContaSelecionada contaSelecionada={contaSelecionada} />
            <div className="row">
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div className="panel panel-default">
                        <div className="panel-body grid_dados">
                            <div className="list-group">
                                <form className="editForm" id="myForm">
                                    <div className="row form-group">
                                        <Grid cols="3 3 2 2">
                                            <strong>{LABELS.VALOR_CARGA}*</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgValorCargaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <CurrencyInput decimalSeparator="," thousandSeparator="." className="form-control mensagens-erro"
                                                    id="valorCarga" maxLength={14}
                                                    value={this.props.solicitacao.valorCarga}
                                                    onChange={this.props.valorCargaChange}
                                                />
                                                {this.props.solicitacao.msgValorCargaErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgValorCargaErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="3 3 3 3">
                                            <strong>{LABELS.BANCO}*</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgBancoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select id="banco"
                                                    placeholder="Selecione"
                                                    value={this.props.solicitacao.bancoSelecionadoId}
                                                    onChange={(selectValue) => this.props.bancoChange(selectValue)}
                                                    options={this.props.solicitacao.listaDeBancos}
                                                    disabled={this.props.solicitacao.desabilitaComboBanco}
                                                    noResultsText={LABEL_COMUNS.NENHUM_BANCO_ENCONTRADO}
                                                    style={this.props.solicitacao.msgBancoErro ? { borderColor: '#a94442' } : { borderColor: '#e7e7e7   ' }}
                                                />
                                                {this.props.solicitacao.msgBancoErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgBancoErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <strong>{LABELS.AGENCIA}*</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgAgenciaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <InputMask
                                                    id="conta"
                                                    className="form-control mensagens-erro"
                                                    value={this.props.solicitacao.agencia}
                                                    mask="9999999999" maskChar=""
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                                    placeholder="Agência"
                                                    onChange={this.props.agenciaChange} />
                                                {this.props.solicitacao.msgAgenciaErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgAgenciaErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <strong>{LABELS.CONTA}*</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgContaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl className="mensagens-erro" id="agencia" type="text"
                                                    placeholder="Conta"
                                                    value={this.props.solicitacao.conta}
                                                    onChange={this.props.contaChange}
                                                    maxLength={20}
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                                                {this.props.solicitacao.msgContaErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgContaErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <strong>{LABELS.DIGITO}*</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgDigitoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl className="mensagens-erro" id="digito" type="text"
                                                    placeholder="Dígito"
                                                    value={this.props.solicitacao.digito}
                                                    onChange={this.props.digitoChange}
                                                    maxLength={1}
                                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }} />
                                                {this.props.solicitacao.msgDigitoErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgDigitoErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        <Grid cols="12 12 11 11">
                                            <strong>{LABELS.DESCRICAO}</strong>
                                            <FormGroup validationState={this.props.solicitacao.msgDescricaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl id="descricao" componentClass="textarea"
                                                    placeholder="Descrição"
                                                    value={this.props.solicitacao.descricao}
                                                    onChange={this.props.descricaoChange}
                                                    maxLength={1500}
                                                />
                                                {this.props.solicitacao.msgDescricaoErro ? <span className="mensagens-erro-span" role="alert">{this.props.solicitacao.msgDescricaoErro}</span> : null}
                                            </FormGroup>
                                        </Grid>
                                        {/* <Grid cols="12 12 12 12">
                                            <DataAgendamento
                                                titulo="Data do pedido"
                                                hojeIDOnchange={() => this.props.setHoje(document.getElementById("checkboxHojeId").checked)}
                                                dataAgendamentoOnChange={() => this.props.setDataAgendada(document.getElementById("dataAgendamento").value)}
                                                dataAgendamentoErro={this.props.solicitacao.dataAgendamentoErro}
                                                dataAgendamentoObrigatoriaErro={this.props.solicitacao.dataAgendamentoObrigatoriaErro}
                                                dataAgendamentoValue={this.props.solicitacao.dataAgendada}
                                                checkedValue={this.props.solicitacao.hoje}
                                            />
                                        </Grid> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* <br /><br /> */}
                        <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita posicao-botao">
                                <Button bsStyle="default" onClick={() => this.props.exibirModalCancelarChange(true)}>{LABEL_COMUNS.CANCELAR}</Button>&nbsp;&nbsp;
                                <Button bsStyle="primary" onClick={
                                    () => {
                                        let validarParametroObrigatorio = this.props.validarParametroObrigatorio(this.props.solicitacao);
                                        // let validarDataDoPedido = this.props.validarDataDoPedido(this.props.solicitacao.hoje, this.props.solicitacao.dataAgendada)
                                        if (validarParametroObrigatorio) {
                                            this.props.exibirModalConfirmacaoChange(true);
                                        }
                                    }
                                }>{LABELS.BOTAO_SOLICITAR}</Button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                            <Button bsStyle="primary" className="botao-voltar" onClick={() => this.voltarTela()}>{LABELS.VOLTAR}</Button>
                        </div>
                    </div>
                </div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                </div>
            </div>
            <div className="row alinhamento-centro">
                <Grid cols="12 12 12 12">
                    <If test={this.props.solicitacao.exibirLoading}>
                        <LoadingFenix />
                    </If>
                </Grid>
            </div>
        </div>
    )

    exibirModalSucesso = () => (
        <ModalComponent name="mdConfirmacao" exibirModal={this.props.solicitacao.exibirModalSucesso}
            header={LABELS.MODAL_TITULO} body={this.montaBodyModalSucesso()}
            footer={this.montaRodapeModalSucesso()} />
    );

    montaBodyModalSucesso() {
        let valor = this.props.solicitacao.solicitacaoTransferenciaRealizada.valorCarga;
        const PEDIDO_DE_TRANSFERENCIA = `Faça uma transferência no valor ${valor} para o destino abaixo:`;
        const OBS = 'Após a transferência recebida, o prazo para liberação do valor em sua conta digital é de 30 minutos (em dias úteis horário comercial).';
        return (
            <div>
                {LABELS.MENSAGEM_CARGA_EFETUADA_COM_SUCESSO}<b>{this.props.solicitacao.solicitacaoTransferenciaRealizada.requisicaoId}</b><br />
                <div>
                    <If test={this.props.solicitacao.solicitacaoTransferenciaRealizada}><br />
                        <div><Highlight search={valor}>{PEDIDO_DE_TRANSFERENCIA}</Highlight></div><br />
                        <div>{this.props.solicitacao.solicitacaoTransferenciaRealizada.codigoBanco} - {this.props.solicitacao.solicitacaoTransferenciaRealizada.nomeBanco}</div>
                        <div>{LABELS.MENSAGEM_PEDIDO_TRANSFERENCIA_REALIZADO.DOCUMENTO}{this.props.solicitacao.solicitacaoTransferenciaRealizada.documento}</div>
                        <div>{LABELS.MENSAGEM_PEDIDO_TRANSFERENCIA_REALIZADO.AGENCIA}{this.props.solicitacao.solicitacaoTransferenciaRealizada.agencia} -
                            {LABELS.MENSAGEM_PEDIDO_TRANSFERENCIA_REALIZADO.CONTA}{this.props.solicitacao.solicitacaoTransferenciaRealizada.contaCorrente}</div>
                        <div>{this.props.solicitacao.solicitacaoTransferenciaRealizada.nomeBeneficiario}</div>
                        <br />
                        <div><p>{OBS}</p></div>
                    </If>
                </div>
            </div>
        );
    }

    montaRodapeModalSucesso() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => this.retornaraParaDashboard()} />
        );
    }

    exibirModalConfirmacao = () => (
        <ModalComponent name="mdConfirmacao" exibirModal={this.props.solicitacao.exibirModalConfirmacao}
            header={LABELS.MODAL_TITULO} body={LABELS.CONFIRMA_ENVIO_DO_PEDIDO}
            footer={this.montaRodapeModalConfirmacao()} />
    );
    montaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONTINUAR}
                btn1OnClick={() => this.props.exibirModalCancelarChange(true)}
                btn2OnClick={() => this.salvarSolicitacao(this.props.solicitacao)} />
        );
    }

    exibirModalCancelar = () => (
        <ModalComponent name="mdConfirmacao" exibirModal={this.props.solicitacao.exibirModalCancelar}
            header={LABELS.MODAL_TITULO} body={LABELS.MENSAGEM_CANCELAR}
            footer={this.montaRodapeModalCancelar()} />
    );
    montaRodapeModalCancelar() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.props.exibirModalCancelarChange(false)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => this.retornaraParaDashboard()} />
        );
    }

    salvarSolicitacao(solicitacao) {
        this.props.exibirLoadingChange(true);
        this.props.efetuarCarga(reactLocalStorage.getObject('contaSelecionada').id, solicitacao);

    }

    render() {
        const contaSelecionada = reactLocalStorage.getObject(LABEL_COMUNS.CONTA_SELECIONADA);
        return (
            <div>
                {this.exibirPagina(contaSelecionada)}
                {this.exibirModalCancelar()}
                {this.exibirModalConfirmacao()}
                {this.exibirModalSucesso()}
            </div>
        );
    }
}

function mapStateToProps(state) { return { solicitacao: state.PedidoSolicitacaoReducer }; }

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedidoCargaSolicitacao);