import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import moment from 'moment';
import React, { Component } from 'react';

import { COMPROVANTE_OPERACOES, COMPROVANTE_TIPO_OPERACAO, CONTA_SELECIONADA, LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import { CONTEXTO } from '../../rotas-constantes';
import { formataMoedaReal } from '../../util/numeros/numeroUtil';
import { getComprovantePDF, getHistoricoDeComprovantes } from '../../../servicos/comprovante/comprovante-service';
import ComprovanteModal from '../../componentes-uteis/comprovante/comprovante-modal';
import FiltroComprovantes from './filtro-comprovantes';
import Grid from '../../componentes-uteis/grid/grid';
import Loading from '../../loading-fenix/loading';

import imagePDF from '../../../imagens/pdf.png';

class ListarComprovantes extends Component {
    constructor() {
        super();

        this.state = {
            comprovantes: [],
            isComprovantesLoading: false,
            exibirModalComprovante: false,
            quantidadeTotalRegistros: 0,
            tamanhoPagina: 0,
            ultimaChave: '',
            comprovantesFiltros: {}
        };

        this.contaSelecionada = CONTA_SELECIONADA.OBJETO();

        this.buscaHistoricoComprovante = this.buscaHistoricoComprovante.bind(this);
        this.downloadComprovantePDF = this.downloadComprovantePDF.bind(this);
        this.setExibirModalComprovante = this.setExibirModalComprovante.bind(this);
    }

    componentDidMount = () => {
        this.buscaHistoricoComprovante();
    }

    buscaHistoricoComprovante = async (queryParams = {}) => {
        this.setState({ isComprovantesLoading: true });

        if (!queryParams.ultimaChave) {
            this.setState({ comprovantes: [], comprovantesFiltros: queryParams });
        }

        const { data } = await getHistoricoDeComprovantes(this.contaSelecionada.id, {
            ...(queryParams.ultimaChave && { ...this.state.comprovantesFiltros }),
            ...queryParams
        });

        this.setState({
            comprovantes: [
                ...this.state.comprovantes,
                ...data.resultado
            ],
            isComprovantesLoading: false,
            quantidadeTotalRegistros: data.quantidadeTotalRegistros,
            tamanhoPagina: data.tamanhoPagina,
            ultimaChave: data.ultimaChave
        });
    }

    setExibirModalComprovante = (dadosComprovante) => {
        this.setState({ exibirModalComprovante: true, dadosComprovante });
    }

    async downloadComprovantePDF(comprovante) {
        const data = await getComprovantePDF(this.contaSelecionada.id, comprovante.operacaoId, comprovante.comprovanteId);

        const linkSource = URL.createObjectURL(data);
        const downloadLink = document.createElement('a');
        const fileName = 'Comprovante.pdf';

        downloadLink.href = linkSource;
        downloadLink.download = fileName;

        document.body.appendChild(downloadLink);

        downloadLink.click();

        document.body.removeChild(downloadLink);

        URL.revokeObjectURL(linkSource);
    }

    setPropsCampos(e, nomeDoCampo) {
        const valorDoCampo = e.target.value;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    }

    render() {
        const { comprovantes } = this.state;

        const TOOLTIP = (comprovanteId) => {
            return (
                <Tooltip id="tooltip">
                    {comprovanteId}
                </Tooltip>
            );
        };

        return (
            <div className="row">
                {
                    this.state.exibirModalComprovante && (
                        <ComprovanteModal
                            header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE(this.contaSelecionada.descricao)}
                            bsSize="lg"
                            onClose={() => this.setState({ exibirModalComprovante: false })}
                            exibirModal={this.state.exibirModalComprovante}
                            dadosComprovante={this.state.dadosComprovante}
                            downloadComprovantePDF={this.downloadComprovantePDF}
                        />
                    )
                }
                <FiltroComprovantes buscaHistoricoComprovante={this.buscaHistoricoComprovante} />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                    <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.MEUS_COMPROVANTES}</div>
                    <div>
                        <table className="table" style={{ margin: '20px 0' }}>
                            <tbody>
                                <tr>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.ID}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.DATA_HORARIO}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.OPERACAO}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.TIPO}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.DESCRICAO}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.VALOR}</th>
                                    <th style={{ textAlign: 'center' }} className="fundo-azul-escuro">{LABEL_COMUNS.COMPROVANTE}</th>
                                </tr>
                                {this.state.comprovantes.length > 0 && this.state.comprovantes.map(comprovante => (
                                    <tr key={comprovante.comprovanteId}>
                                        <OverlayTrigger placement="top" overlay={TOOLTIP(comprovante.operacaoId)}>
                                            <td style={{ textAlign: 'center', textDecoration: 'underline', cursor: 'pointer', border: 'none' }} onClick={() => this.setExibirModalComprovante(comprovante)}>
                                                {comprovante.operacaoId.slice(0, 6)}
                                            </td>
                                        </OverlayTrigger>
                                        <td style={{ textAlign: 'center', border: 'none' }} >{moment(comprovante.dataCriacao, 'YYYY-MM-DDTHH:mm:ss.SSS').format('DD/MM/YYYY [-] HH:mm:ss')}</td>
                                        <td style={{ textAlign: 'center', border: 'none' }}>{COMPROVANTE_OPERACOES.find(e => e.value === comprovante.operacao).label || ''}</td>
                                        <td style={{ textAlign: 'center', border: 'none' }} >{COMPROVANTE_TIPO_OPERACAO.find(e => e.value === comprovante.contextoTemplate.transacao).label || ''}</td>
                                        <td style={{ textAlign: 'center', border: 'none' }}>{comprovante.descricao || ''}</td>
                                        <td style={{ textAlign: 'center', border: 'none' }}>{formataMoedaReal(comprovante.contextoTemplate.valorOperacao - (comprovante.contextoTemplate.valorTarifa || 0))}</td>
                                        <td style={{ textAlign: 'center', border: 'none' }}><img src={imagePDF} title="Salvar como PDF" onClick={() => this.downloadComprovantePDF(comprovante)} style={{ cursor: 'pointer' }} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {!this.state.isComprovantesLoading && this.state.comprovantes.length === 0 && (
                            <div style={{ display: 'flex', margin: '50px 0' }}>
                                <span style={{ color: '#c3c3c3', margin: '0 auto' }}>Nenhum comprovante disponível.</span>
                            </div>
                        )}
                        {this.state.isComprovantesLoading && (
                            <div className="alinhamento-centro" style={{ margin: '50px 0' }}>
                                <Loading />
                            </div>
                        )}
                        {!this.state.isComprovantesLoading &&
                            this.state.quantidadeTotalRegistros > this.state.comprovantes.length && (
                                <button
                                    type="button"
                                    className="btn btn-default btn-block fonte-tamanho-11"
                                    onClick={() => {
                                        this.buscaHistoricoComprovante({ ultimaChave: this.state.ultimaChave });
                                    }}>
                                    Mostrar mais
                                </button>
                            )}
                    </div>
                    <br />
                    <div>
                        <Grid className="alinhamento-direita">
                            <Button bsStyle="primary" className="botao-voltar-telas" onClick={() => this.props.history.push(CONTEXTO.MINHA_CONTA.concat(CONTEXTO.DASHBOARD))}>{LABEL_COMUNS.VOLTAR}</Button>
                        </Grid>
                    </div>
                    <br />
                </div>
            </div>
        );
    }
}
export default ListarComprovantes;