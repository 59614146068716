
/**
 * classe que herda as funcionalidades do reducer
 * porém é nescessario implementar o metodo reducer(), para
 * ter a passagem do state e action controlada pelo combineReducers()
 * que esta em Reducers()
 */
import controle from './altera-cadastro-empresa-controlador';
import { formatarDataBR } from '../util/data/dataUtil';
import { TIPO_DOCUMENTO, DEPARTAMENTOS, STATUS_REPRESENTANTE, ERRO_CARREGAR_PAGINA } from './altera-cadastro-empresa-constantes';
import { validarUsuarioAdmin, validarRepresentanteLegal } from './altera-cadastro-empresa-util';

const REMOVE_MASCARA_TELEFONE_CPF_CNPJ_PATTERN = /\.|-|\/|\(|\)/g;
const MASK_CPF = '999.999.999-99';
const MASK_CNPJ = '99.999.999/9999-99';

const st = {
    empresas: [],
    idEmpresaInicial: '',
    dadosPessoa: [],
    departamentos: [],
    departamento: '',
    representantes: [],
    listaRepresentanteLegal: [],
    representanteLegal: {},
    listaUsuarioAdmin: [],
    usuarioAdmin: {},
    responsavelComercial: {},
    cep: '',
    logradouro: '',
    numeroEndereco: '',
    paises: [],
    estados: [],
    cidades: [],
    listaEmail: [],
    email: '',
    pessoaJuridica: [],
    status: '',
    listaTelefone: [],
    telefone: {},
    listaCelular: [],
    celular: {},
    mascara: '',
    desabilitaCampoNomeResponsavelComercial: true,
    desabilitaCampoNomeRepresentanteLegal: true,
    desabilitaCampoNomeUsuarioAdmin: true,
    mascaraDocumentoRepresentateLegal: MASK_CPF,
    tipoMascaraDocumentoRepresentateLegal: 'CPF',
    tipoDocumento: TIPO_DOCUMENTO,
    arquivoDocumentoArray: [],
    mostrarModalSucessoAlterarPessoa: false,
    urlArquivoDocumentoDownload: '',
    fimProcessoAlteracao: false,
    fimProcessoDelete: false,
    mostrarModalVisualizarArquivoDocumento: false,
    mostrarModalConfirmarDeleteArquivo: false,
    mostrarModalSucessoDeleteArquivo: false,
    mostrarLoading: true,
    caracterRestanteComplemento: 50,
    mostrarModalConfirmaDeleteUsuarioAdmin: false,
    modalUsuarioAdminObj: {},
    mostrarModalConfirmaDeleteRepresentanteLegal: false,
    modalRepresentanteLegalObj: {},
    modalDeleteEmailObj: {},
    mostrarModalConfirmaDeleteEmail: false,
    modalDeleteTelefoneObj: {},
    mostrarModalConfirmaDeleteTelefone: false,
    modalDeleteCelularObj: {},
    mostrarModalConfirmaDeleteCelular: false,

    dataConstituicaoErro: '',
    cpfUsuarioAdminErro: '',
    nomeUsuarioAdminErro: '',
    cpfRepresentanteLegalErro: '',
    razaoSocialErro: '',
    emailErro: '',
    nomeRepresentanteLegalErro: '',
    emailNaListaErro: '',
    numeroTelefoneErro: '',
    contatoTelefoneErro: '',
    numeroCelularErro: '',
    contatoCelularErro: '',
    cepErro: '',
    cidadeErro: '',
    estadoErro: '',
    bairroErro: '',
    logradouroErro: '',
    numeroErro: '',
    listaGeralTelefone: '',
    processarServicoErro: '',
    testeFabiano: ''
};

export default function reducer(state = st, action) {
    let nResponsavelComercial = '';
    let novaPessoaJuridica = '';
    let desabilitaCampoNome = '';
    let nListaUsuarioAdmin = '';
    let nRepresentante = '';
    let nRepresentanteLegal = '';
    let listaErro = '';
    let listaErros = '';
    let novosEnderecos = '';
    let nListaCelular = '';
    let nEmail = '';
    let novaListaEmail = '';
    let nTelefone = '';
    let nListaTelefone = '';
    let listaGeralTelefone = '';
    // let adicaoValida = "";
    let nCelular = '';
    let telefone = '';
    let msgNumeroTelefoneErro = '';
    let msgContatoTelefoneErro = '';

    switch (action.type) {
        case controle.setLoadind():
            return {
                ...state,
                mostrarLoading: action.payload
            };
        case controle.getBuscarEmpresas():
            return {
                ...state,
                empresas: action.payload
            };
        case controle.getDepartamentos():
            return {
                ...state,
                departamentos: DEPARTAMENTOS
            };
        case controle.getRepresentantes():
            let representantes = action.payload;
            let nListaRepresentanteLegal = [];
            nListaUsuarioAdmin = [];
            nResponsavelComercial = state.responsavelComercial;
            let listaResponsavelComercial = [];

            representantes.map((representante, idx) => {
                if (representante.pessoaPapelNome === 'Usuário Admin') {
                    representante.status = STATUS_REPRESENTANTE.EXISTE;
                    nListaUsuarioAdmin.push(representante);
                } else if (representante.pessoaPapelNome === 'Representante Legal') {
                    representante.status = STATUS_REPRESENTANTE.EXISTE;
                    nListaRepresentanteLegal.push(representante);
                } else {
                    listaResponsavelComercial.push(representante);
                }
                return true;
            });

            if (listaResponsavelComercial.length > 0) {
                nResponsavelComercial.pessoaId = listaResponsavelComercial[0].pessoaId;
                nResponsavelComercial.pessoaDocumento = listaResponsavelComercial[0].pessoaDocumento;
                nResponsavelComercial.pessoaNome = listaResponsavelComercial[0].pessoaNome;
                nResponsavelComercial.pessoaTipo = listaResponsavelComercial[0].pessoaTipo;
                nResponsavelComercial.status = STATUS_REPRESENTANTE.EXISTE;
            } else {
                nResponsavelComercial = null;
            }

            return {
                ...state,
                representantes: action.payload,
                responsavelComercial: nResponsavelComercial,
                listaRepresentanteLegal: nListaRepresentanteLegal,
                listaUsuarioAdmin: nListaUsuarioAdmin
            };
        case controle.getEnderecos():
            return {
                ...state,
                enderecos: action.payload
            };

        // ----------------- RESPONSAVEL COMERCIAL ----------------------
        case controle.setCpfResponsavelComercial():

            let nCpfResponsavelComercial = state.responsavelComercial;
            nCpfResponsavelComercial.pessoaDocumento = action.payload;

            return {
                ...state,
                responsavelComercial: nCpfResponsavelComercial,
                desabilitaCampoNomeResponsavelComercial: true
            };

        case controle.setNomeResponsavelComercial():

            let nNomeResponsavelComercial = state.responsavelComercial;
            nNomeResponsavelComercial.pessoaNome = action.payload;

            return {
                ...state,
                responsavelComercial: nNomeResponsavelComercial
            };

        case controle.getPessoaPorDocumentoResponsavelComercial():
            nResponsavelComercial = state.responsavelComercial;
            desabilitaCampoNome = true;

            if (action.payload.registros.length > 0) {
                nResponsavelComercial.pessoaId = action.payload.registros[0].id;
                nResponsavelComercial.pessoaNome = action.payload.registros[0].pessoaFisica.nomeCompleto;
                nResponsavelComercial.pessoaTipo = action.payload.registros[0].tipo;
                nResponsavelComercial.status = STATUS_REPRESENTANTE.EXISTE;
                desabilitaCampoNome = true;
            } else {
                nResponsavelComercial.pessoaNome = '';
                nResponsavelComercial.pessoaId = 0;
                nResponsavelComercial.pessoaTipo = 'PF';
                nResponsavelComercial.status = STATUS_REPRESENTANTE.NOVO;
                desabilitaCampoNome = false;
            }

            return {
                ...state,
                responsavelComercial: nResponsavelComercial,
                desabilitaCampoNomeResponsavelComercial: desabilitaCampoNome
            };
        // --------------------- SELECIONAR EMPRESA ---------------------
        case controle.getPessoa():
            listaGeralTelefone = action.payload.telefones;
            nListaTelefone = [];
            nListaCelular = [];

            listaGeralTelefone.map((telefone, idx) => {
                if (telefone.tipo === 'CELULAR') {
                    nListaCelular.push(telefone);
                } else {
                    nListaTelefone.push(telefone);
                }
                return true;
            });

            novaPessoaJuridica = action.payload.pessoaJuridica;
            if (novaPessoaJuridica.dataConstituicao !== undefined) {
                novaPessoaJuridica.dataConstituicao = formatarDataBR(novaPessoaJuridica.dataConstituicao);
            }

            return {
                ...state,
                dadosPessoa: action.payload,
                listaEmail: action.payload.emails,
                pessoaJuridica: novaPessoaJuridica,
                listaTelefone: nListaTelefone,
                listaCelular: nListaCelular,
                status: action.payload.status,
                mostrarLoading: false
            };
        // --------------------- DADOS DA PJ ----------------------------
        case controle.setRazaoSocial():
            novaPessoaJuridica = state.pessoaJuridica;
            novaPessoaJuridica.razaoSocial = action.payload;

            return {
                ...state,
                pessoaJuridica: novaPessoaJuridica,
                razaoSocialErro: ''
            };
        case controle.setNomeFantasia():
            let nPessoaJuridica = state.pessoaJuridica;
            nPessoaJuridica.nomeFantasia = action.payload;

            return {
                ...state,
                pessoaJuridica: nPessoaJuridica
            };
        case controle.setInscricaoEstadual():
            nPessoaJuridica = state.pessoaJuridica;
            nPessoaJuridica.inscricaoEstadual = action.payload;

            return {
                ...state,
                pessoaJuridica: nPessoaJuridica
            };
        case controle.setDataConstituicao():
            nPessoaJuridica = state.pessoaJuridica;
            nPessoaJuridica.dataConstituicao = action.payload;

            return {
                ...state,
                pessoaJuridica: nPessoaJuridica,
                dataConstituicaoErro: ''
            };
        case controle.validarDataConstituicao():
            return {
                ...state,
                dataConstituicaoErro: action.payload,
                mostrarLoading: false
            };
        // --------------------- USUARIO ADMIN ----------------------------
        case controle.setCpfUsuarioAdmin():
            let nUsuarioAdmin = state.usuarioAdmin;
            nUsuarioAdmin.pessoaDocumento = '';
            nUsuarioAdmin.pessoaDocumento = action.payload;

            return {
                ...state,
                usuarioAdmin: nUsuarioAdmin,
                cpfUsuarioAdminErro: '',
                desabilitaCampoNomeUsuarioAdmin: true
            };
        case controle.setNomeUsuarioAdmin():
            nUsuarioAdmin = state.usuarioAdmin;
            nUsuarioAdmin.pessoaNome = action.payload;

            return {
                ...state,
                usuarioAdmin: nUsuarioAdmin,
                nomeUsuarioAdminErro: ''
            };
        case controle.getPessoaPorDocumentoUsuarioAdmin():
            nUsuarioAdmin = state.usuarioAdmin;
            let representanteLegal = state.representanteLegal;
            let listaRepresentanteLegal = state.listaRepresentanteLegal;
            desabilitaCampoNome = true;

            if (action.payload.registros.length > 0) {
                nUsuarioAdmin.pessoaId = action.payload.registros[0].id;
                nUsuarioAdmin.pessoaNome = action.payload.registros[0].pessoaFisica.nomeCompleto;
                nUsuarioAdmin.pessoaTipo = action.payload.registros[0].tipo;
            } else {
                nUsuarioAdmin.pessoaNome = '';

                listaRepresentanteLegal.map((representante, idx) => {
                    if (removeMascaraTelefoneCpfCnpj(nUsuarioAdmin.pessoaDocumento) === removeMascaraTelefoneCpfCnpj(representante.pessoaDocumento)) {
                        nUsuarioAdmin.pessoaNome = representante.pessoaNome;
                    }
                    return true;
                });

                if (removeMascaraTelefoneCpfCnpj(nUsuarioAdmin.pessoaDocumento) === removeMascaraTelefoneCpfCnpj(representanteLegal.pessoaDocumento)) {
                    nUsuarioAdmin.pessoaNome = representanteLegal.pessoaNome;
                }

                nUsuarioAdmin.pessoaId = 0;
                nUsuarioAdmin.pessoaTipo = 'PF';
                nUsuarioAdmin.status = STATUS_REPRESENTANTE.NOVO;
                desabilitaCampoNome = false;
            }
            return {
                ...state,
                usuarioAdmin: nUsuarioAdmin,
                desabilitaCampoNomeUsuarioAdmin: desabilitaCampoNome,
                mostrarLoading: false
            };
        case controle.addUsuarioAdmin():
            let nListaUsuariosAdmin = state.listaUsuarioAdmin;
            listaErro = validarUsuarioAdmin(state.listaUsuarioAdmin, action.payload);
            nUsuarioAdmin = {};
            // let usuarioAdminAdd = action.payload;

            if (listaErro.length === 0) {
                nUsuarioAdmin.pessoaDocumento = action.payload.pessoaDocumento;
                nUsuarioAdmin.pessoaId = action.payload.pessoaId;
                nUsuarioAdmin.pessoaNome = action.payload.pessoaNome;
                nUsuarioAdmin.pessoaTipo = action.payload.pessoaTipo;
                nUsuarioAdmin.status = STATUS_REPRESENTANTE.NOVO;

                nListaUsuariosAdmin.push(nUsuarioAdmin);
            }
            return {
                ...state,
                listaUsuarioAdmin: nListaUsuariosAdmin,
                cpfUsuarioAdminErro: '',
                desabilitaCampoNomeUsuarioAdmin: listaErro !== null
            };
        case controle.limparUsuarioAdmin():
            let usuarioAdminLocal = state.usuarioAdmin;
            usuarioAdminLocal.pessoaDocumento = '';
            usuarioAdminLocal.pessoaNome = '';
            return {
                ...state,
                usuarioAdmin: usuarioAdminLocal
            };
        case controle.erroAddUsuarioAdmin():
            listaErros = action.payload;
            let msgErroCPFUsuario = '';
            let msgErroNomeUsuario = '';

            listaErros.map((erro, idx) => {
                switch (erro.campo) {
                    case 'cpfUsuarioAdminErro': {
                        msgErroCPFUsuario = erro.mensagem;
                        break;
                    }
                    case 'nomeUsuarioAdminErro': {
                        msgErroNomeUsuario = erro.mensagem;
                        break;
                    }
                    default:
                        break;
                }
                return true;
            });
            return {
                ...state,
                cpfUsuarioAdminErro: msgErroCPFUsuario,
                nomeUsuarioAdminErro: msgErroNomeUsuario

            };
        case controle.mostrarModalConfirmaDeleteUsuarioAdmin():
            let nModalUsuarioAdminObj = state.modalUsuarioAdminObj;
            nModalUsuarioAdminObj.indice = action.payload.idx;
            nModalUsuarioAdminObj.pessoaNome = state.listaUsuarioAdmin[action.payload.idx].pessoaNome;

            return {
                ...state,
                mostrarModalConfirmaDeleteUsuarioAdmin: action.payload.valor,
                modalUsuarioAdminObj: nModalUsuarioAdminObj
            };
        case controle.excluirUsuarioAdmin():
            nListaUsuarioAdmin = state.listaUsuarioAdmin;

            if (nListaUsuarioAdmin[action.payload].status === STATUS_REPRESENTANTE.NOVO) {
                nListaUsuarioAdmin.pop(action.payload);
            } else {
                nListaUsuarioAdmin[action.payload].status = STATUS_REPRESENTANTE.REMOVE;
            }

            return {
                ...state,
                listaUsuarioAdmin: nListaUsuarioAdmin,
                mostrarModalConfirmaDeleteUsuarioAdmin: false
            };
        // --------------------- REPRESENTANTE LEGAL ----------------------
        case controle.setDocumentoRepresenanteLegal():
            nRepresentanteLegal = state.representanteLegal;
            nRepresentanteLegal.pessoaNome = '';
            nRepresentanteLegal.pessoaDocumento = removeMascaraTelefoneCpfCnpj(action.payload);

            return {
                ...state,
                representanteLegal: nRepresentanteLegal,
                cpfRepresentanteLegalErro: '',
                nomeRepresentanteLegalErro: '',
                desabilitaCampoNomeRepresentanteLegal: true
            };
        case controle.setNomeRepresenanteLegal():
            nRepresentante = state.representanteLegal;
            nRepresentante.pessoaNome = action.payload;

            return {
                ...state,
                representanteLegal: nRepresentante,
                nomeRepresentanteLegalErro: ''
            };
        case controle.getPessoaPorDocumentoRepresentanteLegal():
            nRepresentante = state.representanteLegal;
            let usuarioAdmin = state.usuarioAdmin;
            let listaUsuarioAdmin = state.listaUsuarioAdmin;
            desabilitaCampoNome = true;

            if (action.payload.registros.length > 0) {
                nRepresentante.pessoaId = action.payload.registros[0].id;
                nRepresentante.pessoaNome = action.payload.registros[0].pessoaFisica.nomeCompleto;
                nRepresentante.pessoaTipo = action.payload.registros[0].tipo;
            } else {
                nRepresentante.pessoaNome = '';

                listaUsuarioAdmin.map((usuario, idx) => {
                    if (removeMascaraTelefoneCpfCnpj(nRepresentante.pessoaDocumento) === removeMascaraTelefoneCpfCnpj(usuario.pessoaDocumento)) {
                        nRepresentante.pessoaNome = usuario.pessoaNome;
                    }
                    return true;
                });

                if (removeMascaraTelefoneCpfCnpj(nRepresentante.pessoaDocumento) === removeMascaraTelefoneCpfCnpj(usuarioAdmin.pessoaDocumento)) {
                    nRepresentante.pessoaNome = usuarioAdmin.pessoaNome;
                }

                nRepresentante.pessoaId = 0;
                nRepresentante.pessoaTipo = state.tipoMascaraDocumentoRepresentateLegal.toUpperCase() === 'CNPJ' ? 'PJ' : 'PF';
                nRepresentante.status = STATUS_REPRESENTANTE.NOVO;
                desabilitaCampoNome = false;
            }

            return {
                ...state,
                representanteLegal: nRepresentante,
                desabilitaCampoNomeRepresentanteLegal: desabilitaCampoNome,
                mostrarLoading: false
            };
        case controle.addRepresentanteLegal():

            nListaRepresentanteLegal = state.listaRepresentanteLegal;
            listaErro = validarRepresentanteLegal(state.listaRepresentanteLegal, action.payload, '');
            let novoRepresentanteLegal = {};
            // let RepresentanteLegalAdd = action.payload;

            if (listaErro.length === 0) {
                novoRepresentanteLegal.pessoaDocumento = action.payload.pessoaDocumento;
                novoRepresentanteLegal.pessoaId = action.payload.pessoaId;
                novoRepresentanteLegal.pessoaNome = action.payload.pessoaNome;
                novoRepresentanteLegal.pessoaTipo = action.payload.pessoaTipo;
                novoRepresentanteLegal.status = STATUS_REPRESENTANTE.NOVO;

                nListaRepresentanteLegal.push(novoRepresentanteLegal);
            }

            return {
                ...state,
                listaRepresentanteLegal: nListaRepresentanteLegal,
                desabilitaCampoNomeRepresentanteLegal: listaErro !== null
            };
        case controle.limparRepresentanteLegal():

            let representanteLegalLocal = state.representanteLegal;
            representanteLegalLocal.pessoaDocumento = '';
            representanteLegalLocal.pessoaNome = '';
            return {
                ...state,
                representanteLegal: representanteLegalLocal
            };
        case controle.erroAddRepresentanteLegal():
            listaErros = action.payload;
            let msgErroCPFRepresentante = '';
            let msgErroNomeRepresentante = '';

            listaErros.map((erro, idx) => {
                switch (erro.campo) {
                    case 'cpfRepresentanteLegalErro': {
                        msgErroCPFRepresentante = erro.mensagem;
                        break;
                    }
                    case 'nomeRepresentanteLegalErro': {
                        msgErroNomeRepresentante = erro.mensagem;
                        break;
                    }
                    default:
                        break;
                }
                return true;
            });

            return {
                ...state,
                cpfRepresentanteLegalErro: msgErroCPFRepresentante,
                nomeRepresentanteLegalErro: msgErroNomeRepresentante

            };
        case controle.mostrarModalConfirmaDeleteRepresentanteLegal():
            let nModalRepresentanteLegalObj = state.modalRepresentanteLegalObj;
            nModalRepresentanteLegalObj.indice = action.payload.idx;
            nModalRepresentanteLegalObj.pessoaNome = state.listaRepresentanteLegal[action.payload.idx].pessoaNome;

            return {
                ...state,
                mostrarModalConfirmaDeleteRepresentanteLegal: action.payload.valor,
                modalRepresentanteLegalObj: nModalRepresentanteLegalObj
            };
        case controle.excluirRepresentanteLegal():
            nListaRepresentanteLegal = state.listaRepresentanteLegal;
            // let mostraLista = false;

            if (nListaRepresentanteLegal[action.payload].status === STATUS_REPRESENTANTE.NOVO) {
                nListaRepresentanteLegal.pop(action.payload);
            } else {
                nListaRepresentanteLegal[action.payload].status = STATUS_REPRESENTANTE.REMOVE;
            }

            return {
                ...state,
                listaRepresentanteLegal: nListaRepresentanteLegal,
                mostrarModalConfirmaDeleteRepresentanteLegal: false
            };
        case controle.setMascaraDocumentoRepresentanteLegal():
            let nMascara = '';
            let tipoMascara = action.payload;

            if (tipoMascara === 'cpf') {
                nMascara = MASK_CPF;
            } else {
                nMascara = MASK_CNPJ;
            }

            return {
                ...state,
                mascaraDocumentoRepresentateLegal: nMascara,
                tipoMascaraDocumentoRepresentateLegal: tipoMascara,
                cpfRepresentanteLegalErro: ''
            };
        // ----------------------- ENDERECO -----------------------
        case controle.getEnderecoPorCep():
            novosEnderecos = state.enderecos;
            let novoEndereco = novosEnderecos[0];
            novoEndereco.bairro = action.payload.data.bairro === undefined ? '' : action.payload.data.bairro;
            novoEndereco.cep = action.payload.data.cep;
            novoEndereco.cidade.id = action.payload.data.cidadeId;
            novoEndereco.cidade.nome = action.payload.data.cidade;
            novoEndereco.dataAlteracao = new Date();
            novoEndereco.logradouro = action.payload.data.logradouro === undefined ? '' : action.payload.data.logradouro;
            novoEndereco.pais.id = action.payload.data.paisId;
            novoEndereco.pais.nome = action.payload.data.pais;
            novoEndereco.pais.sigla = action.payload.data.paisSigla;
            novoEndereco.tipo = action.payload.data.tipo === undefined ? '' : action.payload.data.tipo;
            novoEndereco.uf.id = action.payload.data.estadoId;
            novoEndereco.uf.sigla = action.payload.data.uf;
            novoEndereco.numero = '';
            novoEndereco.complemento = '';

            return {
                ...state,
                enderecos: novosEnderecos
            };
        case controle.setCep():
            let nEnderecos = state.enderecos;
            nEnderecos[0].cep = action.payload;

            return {
                ...state,
                enderecos: nEnderecos,
                cepErro: '',
                cidadeErro: '',
                estadoErro: '',
                bairroErro: '',
                logradouroErro: '',
                numeroErro: ''

            };
        case controle.setEstado():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].uf.id = action.payload;

            return {
                ...state,
                enderecos: novosEnderecos,
                estadoErro: ''
            };
        case controle.setCidade():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].cidade.id = action.payload;

            return {
                ...state,
                enderecos: novosEnderecos,
                cidadeErro: ''
            };
        case controle.setBairro():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].bairro = action.payload;

            return {
                ...state,
                enderecos: novosEnderecos,
                bairroErro: ''
            };
        case controle.setLogradouro():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].logradouro = action.payload;

            return {
                ...state,
                enderecos: novosEnderecos,
                logradouroErro: ''
            };
        case controle.setNumero():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].numero = action.payload;

            return {
                ...state,
                enderecos: novosEnderecos,
                numeroErro: ''
            };
        case controle.setComplemento():
            novosEnderecos = state.enderecos;
            novosEnderecos[0].complemento = action.payload;
            let quantidadeRestante = (50 - action.payload.length);

            return {
                ...state,
                enderecos: novosEnderecos,
                caracterRestanteComplemento: quantidadeRestante
            };
        case controle.getPaises():
            return {
                ...state,
                paises: action.payload.paises
            };
        case controle.getEstados():
            return {
                ...state,
                estados: action.payload.estados
            };
        case controle.getCidades():
            return {
                ...state,
                cidades: action.payload.cidades
            };
        case controle.setNire():
            novaPessoaJuridica = state.pessoaJuridica;
            novaPessoaJuridica.nire = action.payload;

            return {
                ...state,
                pessoaJuridica: novaPessoaJuridica
            };
        // --------------------- EMAIL ----------------------
        case controle.setEmail():
            nEmail = state.email;
            nEmail = action.payload;
            return {
                ...state,
                email: nEmail,
                emailErro: ''
            };
        case controle.setNumeroCelularLista():
            nListaCelular = state.listaCelular;
            let celular = separaDddNumero(action.payload.numero);
            nListaCelular[action.payload.idx].ddd = celular.ddd;
            nListaCelular[action.payload.idx].numero = celular.numero;
            nListaCelular[action.payload.idx].msgNumeroTelefoneErro = '';
            return {
                ...state,
                listaCelular: nListaCelular
            };
        case controle.setContatoCelularLista():
            nListaCelular = state.listaCelular;
            nListaCelular[action.payload.idx].contato = action.payload.contato;
            nListaCelular[action.payload.idx].msgContatoTelefoneErro = '';

            return {
                ...state,
                listaCelular: nListaCelular
            };
        case controle.addEmail():
            let nListaEmail = state.dadosPessoa.emails;
            let stEmail = state.email;

            nEmail = action.payload;

            if (nEmail !== '') {
                let email = montaEmail(action.payload, nListaEmail.length);
                nListaEmail.push(email);
                stEmail = '';
            }

            return {
                ...state,
                listaEmail: nListaEmail,
                emailErro: '',
                email: stEmail
            };
        case controle.setErrosEmail():
            let erros = action.payload;
            let msgErro = '';

            erros.map((erro) => {
                switch (erro.campo) {
                    case 'emailErro':
                        msgErro = erro.mensagem;
                        break;
                    default:
                        break;
                }
                return true;
            });
            return {
                ...state,
                emailErro: msgErro
            };
        case controle.alterarEmail():
            novaListaEmail = state.listaEmail;
            novaListaEmail[action.idElemento].email = action.payload;
            return {
                ...state,
                listaEmail: novaListaEmail,
                emailErro: ''
            };
        case controle.deleteEmail():
            novaListaEmail = state.listaEmail;
            novaListaEmail.pop(action.payload);
            return {
                ...state,
                listaEmail: novaListaEmail,
                mostrarModalConfirmaDeleteEmail: false,
                emailErro: ''
            };
        case controle.mostrarModalConfirmaDeleteEmail():
            let nModalDeleteEmailObj = state.modalDeleteEmailObj;
            nModalDeleteEmailObj.indice = action.payload.idx;
            nModalDeleteEmailObj.email = state.listaEmail[action.payload.idx].email;
            return {
                ...state,
                mostrarModalConfirmaDeleteEmail: action.payload.valor,
                modalDeleteEmailObj: nModalDeleteEmailObj
            };
        case controle.limparErroEmailNaLista():
            novaListaEmail = state.listaEmail;
            novaListaEmail[action.payload].msgEmailErro = '';
            return {
                ...state,
                listaEmail: novaListaEmail
            };
        // ----------------------- TELEFONE ----------------------------
        case controle.setNumeroTelefone():
            nTelefone = state.telefone;
            nTelefone.numero = action.payload;
            return {
                ...state,
                telefone: nTelefone,
                numeroTelefoneErro: ''
            };
        case controle.setRamalTelefone():
            nTelefone = state.telefone;
            nTelefone.ramal = action.payload;
            return {
                ...state,
                telefone: nTelefone,
                numeroTelefoneErro: ''
            };
        case controle.setDepartamentoTelefone():
            nTelefone = state.telefone;
            nTelefone.departamento = action.payload;
            return {
                ...state,
                telefone: nTelefone
            };
        case controle.setContatoTelefone():
            nTelefone = state.telefone;
            nTelefone.contato = action.payload;
            return {
                ...state,
                telefone: nTelefone,
                contatoTelefoneErro: ''
            };
        case controle.addTelefone():
            nListaTelefone = state.listaTelefone;
            let stTelefone = state.telefone;
            nTelefone = action.payload;

            telefone = montaTelefone(action.payload, nListaTelefone);
            nListaTelefone.push(telefone);
            stTelefone.numero = '';
            stTelefone.ramal = '';
            stTelefone.departamento = '';
            stTelefone.contato = '';

            return {
                ...state,
                listaTelefone: nListaTelefone,
                numeroTelefoneErro: '',
                contatoTelefoneErro: '',
                telefone: stTelefone
            };
        case controle.setErrosTelefone():
            erros = action.payload;
            msgNumeroTelefoneErro = '';
            msgContatoTelefoneErro = '';

            erros.map((erro) => {
                switch (erro.campo) {
                    case 'numeroTelefoneErro':
                        msgNumeroTelefoneErro = erro.mensagem;
                        break;
                    case 'contatoTelefoneErro':
                        msgContatoTelefoneErro = erro.mensagem;
                        break;
                    default:
                        break;
                }
                return true;
            });

            return {
                ...state,
                numeroTelefoneErro: msgNumeroTelefoneErro,
                contatoTelefoneErro: msgContatoTelefoneErro
            };
        case controle.alterarTelefone():
            let novaListaTelefone = state.listaTelefone;
            novaListaTelefone[action.payload.idx].ddd = action.payload.telefone.ddd;
            novaListaTelefone[action.payload.idx].numero = action.payload.telefone.numero;
            novaListaTelefone[action.payload.idx].ramal = action.payload.telefone.ramal;
            novaListaTelefone[action.payload.idx].departamento = action.payload.telefone.departamento;
            novaListaTelefone[action.payload.idx].contato = action.payload.telefone.contato;

            return {
                ...state,
                listaTelefone: novaListaTelefone
            };
        case controle.setNumeroTelefoneLista():
            nListaTelefone = state.listaTelefone;
            telefone = separaDddNumero(action.payload.numero);
            nListaTelefone[action.payload.idx].ddd = telefone.ddd;
            nListaTelefone[action.payload.idx].numero = telefone.numero;
            nListaTelefone[action.payload.idx].msgNumeroTelefoneErro = '';
            return {
                ...state,
                listaTelefone: nListaTelefone
            };
        case controle.setRamalTelefoneLista():
            nListaTelefone = state.listaTelefone;
            nListaTelefone[action.payload.idx].ramal = action.payload.ramal;
            return {
                ...state,
                listaTelefone: nListaTelefone
            };
        case controle.setDepartamentoTelefoneLista():
            nListaTelefone = state.listaTelefone;
            nListaTelefone[action.payload.idx].departamento = action.payload.departamento;
            return {
                ...state,
                listaTelefone: nListaTelefone
            };
        case controle.setContatoTelefoneLista():
            nListaTelefone = state.listaTelefone;
            nListaTelefone[action.payload.idx].contato = action.payload.contato;
            nListaTelefone[action.payload.idx].msgNumeroContatoErro = '';
            return {
                ...state,
                listaTelefone: nListaTelefone
            };
        case controle.setListaGeralTelefone():
            listaGeralTelefone = action.payload;
            return {
                ...state,
                listaGeralTelefone: listaGeralTelefone
            };
        case controle.excluirTelefone():
            nListaTelefone = state.listaTelefone;
            nListaTelefone.pop(action.payload);

            return {
                ...state,
                mostrarModalConfirmaDeleteTelefone: false,
                listaTelefone: nListaTelefone
            };
        case controle.mostrarModalConfirmaDeleteTelefone():
            let nModalDeleteTelefoneObj = state.modalDeleteTelefoneObj;
            nModalDeleteTelefoneObj.indice = action.payload.idx;
            nModalDeleteTelefoneObj.ddd = state.listaTelefone[action.payload.idx].ddd;
            nModalDeleteTelefoneObj.numero = state.listaTelefone[action.payload.idx].numero;
            return {
                ...state,
                mostrarModalConfirmaDeleteTelefone: action.payload.valor,
                modalDeleteTelefoneObj: nModalDeleteTelefoneObj
            };
        // ----------------------- CELULAR ----------------------------
        case controle.setNumeroCelular():
            nCelular = state.celular;
            nCelular.numero = action.payload;

            return {
                ...state,
                celular: nCelular,
                numeroCelularErro: ''
            };
        case controle.setContatoCelular():
            nCelular = state.celular;
            nCelular.contato = action.payload;

            return {
                ...state,
                celular: nCelular,
                contatoCelularErro: ''
            };
        case controle.addCelular():
            nListaCelular = state.listaCelular;
            nListaTelefone = state.listaTelefone;
            nCelular = action.payload;
            celular = montaCelular(action.payload, nListaCelular, nListaTelefone);
            let stCelular = state.celular;

            nListaCelular.push(celular);
            stCelular.numero = '';
            stCelular.contato = '';

            return {
                ...state,
                listaCelular: nListaCelular,
                celular: stCelular,
                numeroCelularErro: '',
                contatoCelularErro: ''
            };
        case controle.setErrosCelular():
            erros = action.payload;
            let msgNumeroCelularErro = '';
            let msgContatoCelularErro = '';

            erros.map((erro) => {
                switch (erro.campo) {
                    case 'numeroCelularErro':
                        msgNumeroCelularErro = erro.mensagem;
                        break;
                    case 'contatoCelularErro':
                        msgContatoCelularErro = erro.mensagem;
                        break;
                    default:
                        break;
                }
                return true;
            });

            return {
                ...state,
                numeroCelularErro: msgNumeroCelularErro,
                contatoCelularErro: msgContatoCelularErro
            };
        case controle.alterarCelular():
            nListaCelular = state.listaCelular;
            nListaCelular[action.payload.idx].ddd = action.payload.celular.ddd;
            nListaCelular[action.payload.idx].numero = action.payload.celular.numero;
            nListaCelular[action.payload.idx].contato = action.payload.celular.contato;

            return {
                ...state,
                listaCelular: nListaCelular
            };
        case controle.excluirCelular():
            nListaCelular = state.listaCelular;
            nListaCelular.pop(action.payload);

            return {
                ...state,
                mostrarModalConfirmaDeleteCelular: false,
                listaCelular: nListaCelular
            };
        case controle.mostrarModalConfirmaDeleteCelular():
            let nModalDeleteCelularObj = state.modalDeleteCelularObj;
            nModalDeleteCelularObj.indice = action.payload.idx;
            nModalDeleteCelularObj.ddd = state.listaCelular[action.payload.idx].ddd;
            nModalDeleteCelularObj.numero = state.listaCelular[action.payload.idx].numero;
            return {
                ...state,
                mostrarModalConfirmaDeleteCelular: action.payload.valor,
                modalDeleteCelularObj: nModalDeleteCelularObj
            };
        // ---------------- Arquivo Documento ---------------
        case controle.getArquivoDocumentoPessoa():
            return {
                ...state,
                arquivoDocumentoArray: action.payload,
                fimProcessoDelete: false
            };
        case controle.setArquivoDocumentoPessoa():
            return {
                ...state,
                arquivoDocumentoArray: action.payload
            };

        case controle.getArquivoDocumentoS3():
            let nMostrarModalVisualizarArquivoDocumento = false;
            if (action.payload.tipoAcao === 'visualizar') {
                nMostrarModalVisualizarArquivoDocumento = true;
            }

            let nArquivoDocumentoArray = state.arquivoDocumentoArray;
            nArquivoDocumentoArray[action.payload.idx].urlParaDownload = action.payload.url;

            return {
                ...state,
                urlArquivoDocumentoDownload: action.payload.url,
                arquivoDocumentoArray: nArquivoDocumentoArray,
                mostrarModalVisualizarArquivoDocumento: nMostrarModalVisualizarArquivoDocumento
            };
        case controle.fecharModalVisualizarArquivoDocumento():
            return {
                ...state,
                mostrarModalVisualizarArquivoDocumento: false
            };
        case controle.deleteArquivoDocumento(): {
            return {
                ...state,
                arquivoDocumentoArray: state.arquivoDocumentoArray,
                fimProcessoDelete: true,
                mostrarModalConfirmarDeleteArquivo: false
            };
        }
        case controle.deleteArquivoDocumentoNaoSalvo(): {
            let arquivoDocumentoArray = state.arquivoDocumentoArray;
            arquivoDocumentoArray.pop(action.payload);

            return {
                ...state,
                arquivoDocumentoArray: arquivoDocumentoArray,
                mostrarModalConfirmarDeleteArquivo: false
            };
        }
        case controle.mostrarModalConfirmarDeleteArquivo(): {
            return {
                ...state,
                mostrarModalConfirmarDeleteArquivo: action.payload
            };
        }
        case controle.sucessoUploadArquivoDocumento(): {
            return {
                ...state,
                fimProcessoAlteracao: true
            };
        }
        // ------------ Sucesso alterar pessoa -----------
        case controle.sucessoAlterarPessoa(): {
            return {
                ...state,
                mostrarModalSucessoAlterarPessoa: true,
                cpfRepresentanteLegalErro: '',
                nomeRepresentanteLegalErro: '',
                emailErro: '',
                emailNaListaErro: '',
                numeroTelefoneErro: '',
                contatoTelefoneErro: '',
                fimProcessoAlteracao: true
            };
        }
        case controle.fecharModalSucessoAlterarPessoa(): {
            return {
                ...state,
                mostrarModalSucessoAlterarPessoa: action.payload
            };
        }
        // ------------ Erro alterar Pessoa ----------------
        case controle.erroAlterarPessoa():
        {
            listaGeralTelefone = state.listaGeralTelefone;
            let listaEmail = state.listaEmail;
            let erros = action.payload.erros;
            nListaTelefone = [];
            nListaCelular = [];
            let camposDeErros = preencheCamposErroPessoa(action.payload.erros);
            let listaTelefoneComMensagemErro = preencheCamposErroTelefonePessoa(erros, listaGeralTelefone);
            let listaEmailComMensagemErro = preencheCamposErroEmailPessoa(erros, listaEmail);

            listaTelefoneComMensagemErro.map((telefone, idx) => {
                if (telefone.tipo === 'CELULAR') {
                    nListaCelular.push(telefone);
                } else {
                    nListaTelefone.push(telefone);
                }
                return true;
            });

            return {
                ...state,
                razaoSocialErro: camposDeErros.razaoSocialErro,
                listaTelefone: nListaTelefone,
                listaCelular: nListaCelular,
                listaEmail: listaEmailComMensagemErro,
                dataConstituicaoErro: camposDeErros.dataConstituicaoErro,
                mostrarLoading: false
            };
        }
        case controle.sucessoAlterarEndereco():
        {
            return {
                ...state,
                cepErro: '',
                cidadeErro: '',
                estadoErro: '',
                bairroErro: '',
                logradouroErro: '',
                numeroErro: ''
            };
        }
        case controle.getFalhaProcessarServico(): {
            let msgErroGenerica = ERRO_CARREGAR_PAGINA;

            if (action.payload.erros && action.payload.erros.length > 0) {
                msgErroGenerica = action.payload.erros[0].mensagem;
            }

            return {
                ...state,
                processarServicoErro: msgErroGenerica,
                mostrarLoading: false
            };
        }
        // ------------ Erro alterar endereço ----------------
        case controle.erroAlterarEndereco():
        {
            let camposDeErros = preencheCamposDeErrosEndereco(action.payload.erros);

            return {
                ...state,
                cepErro: camposDeErros.cepErro,
                estadoErro: camposDeErros.estadoErro,
                cidadeErro: camposDeErros.cidadeErro,
                bairroErro: camposDeErros.bairroErro,
                logradouroErro: camposDeErros.logradouroErro,
                numeroErro: camposDeErros.numeroErro,
                mostrarLoading: false
            };
        }
        default:
            return {
                ...state
            };
    }
}

// ------------------- Erro alterar pessoa ----------------------

function preencheCamposErroPessoa(erros) {
    let camposDeErros = {};
    // let emailsErro = [];
    // let indice = "";

    for (let i in erros) {
        switch (erros[i].campo) {
            case 'pessoaJuridica.razaoSocial': {
                camposDeErros.razaoSocialErro = erros[i].mensagem;
                break;
            }
            case 'pessoaJuridica.dataConstituicao': {
                camposDeErros.dataConstituicaoErro = erros[i].mensagem;
                break;
            }
            default:
                break;
        }
    }

    return camposDeErros;
}

function preencheCamposErroEmailPessoa(erros, listaEmail) {
    let nListaEmail = listaEmail;

    for (let idxErro in erros) {
        for (let idxEmail in listaEmail) {
            switch (erros[idxErro].campo) {
                case 'emails[' + idxEmail + '].email': {
                    nListaEmail[idxEmail].msgEmailErro = erros[idxErro].mensagem;
                    break;
                }
                default:
                    break;
            }
        }
    }

    return nListaEmail;
}

function preencheCamposErroTelefonePessoa(erros, listaGeralTelefone) {
    let nListaTelefone = listaGeralTelefone;

    for (let idxErro in erros) {
        for (let idxTelefone in listaGeralTelefone) {
            switch (erros[idxErro].campo) {
                case 'telefones[' + idxTelefone + '].numero': {
                    nListaTelefone[idxTelefone].msgNumeroTelefoneErro = erros[idxErro].mensagem;
                    break;
                }
                case 'telefones[' + idxTelefone + '].contato': {
                    nListaTelefone[idxTelefone].msgContatoTelefoneErro = erros[idxErro].mensagem;
                    break;
                }
                default:
                    break;
            }
        }
    }

    return nListaTelefone;
}

// ------------------- Erro alterar endereço ----------------------

function preencheCamposDeErrosEndereco(erros) {
    let camposDeErros = {};
    // let emailsErro = [];
    // let indice = "";

    for (let i in erros) {
        switch (erros[i].campo) {
            case 'cep': {
                camposDeErros.cepErro = erros[i].mensagem;
                break;
            }
            case 'ufId': {
                camposDeErros.estadoErro = erros[i].mensagem;
                break;
            }
            case 'cidadeId': {
                camposDeErros.cidadeErro = erros[i].mensagem;
                break;
            }
            case 'bairro': {
                camposDeErros.bairroErro = erros[i].mensagem;
                break;
            }
            case 'logradouro': {
                camposDeErros.logradouroErro = erros[i].mensagem;
                break;
            }
            case 'numero': {
                camposDeErros.numeroErro = erros[i].mensagem;
                break;
            }
            default:
                break;
        }
    }

    return camposDeErros;
}

// ----------------------- Funções ----------------------------

function montaEmail(nomeEmail, tamanhoListaEmail) {
    let novoEmail = {
        email: nomeEmail,
        tipo: 'COMERCIAL',
        principal: tamanhoListaEmail <= 0,
        dataCriacao: '',
        isValid: true
    };

    return novoEmail;
}

function montaTelefone(telefone, listaTelefone) {
    let novoTelefone = {
        contato: telefone.contato,
        dataCriacao: new Date(),
        ddd: telefone.numero.substring(1, 3),
        ddi: '55',
        numero: telefone.numero.substring(4),
        ramal: telefone.ramal,
        principal: !(listaTelefone.length > 0),
        tipo: 'COMERCIAL',
        departamento: telefone.departamento ? telefone.departamento.toUpperCase() : ''
    };

    return novoTelefone;
}

function montaCelular(celular, listaCelular, listaTelefone) {
    let principal = false;

    if (listaTelefone.length === 0 && listaCelular.length === 0) {
        principal = true;
    }

    let novoCelular = {
        contato: celular.contato,
        dataCriacao: new Date(),
        ddd: celular.numero.substring(1, 3),
        ddi: '55',
        numero: celular.numero.substring(4),
        ramal: celular.ramal,
        principal: principal,
        tipo: 'CELULAR'
    };

    return novoCelular;
}

function separaDddNumero(numero) {
    let telefone = {};
    // let numeroN = numero.replace(/^\s+|\s+$/g, "");
    telefone.ddd = numero.substring(1, 3);
    telefone.numero = numero.substring(4);
    return telefone;
}

function removeMascaraTelefoneCpfCnpj(dado) {
    if (dado === '' || dado === undefined) {
        return '';
    }

    return dado.replace(REMOVE_MASCARA_TELEFONE_CPF_CNPJ_PATTERN, '');
}
