import React, { Component } from 'react';
import { Glyphicon, Dropdown, MenuItem, Button } from 'react-bootstrap';
import Grid from '../../../componentes-uteis/grid/grid';
import ImagemDepartamento from '../../../../imagens/imagem-conta.PNG';
import { reactLocalStorage } from 'reactjs-localstorage';
import ListaContaComponente from './lista-conta-componente';
import ModalEditarConta from '../../../componentes-uteis/editar-conta/modal-editar-conta';
import { LABEL_COMUNS, CONTA_SELECIONADA, NAVEGADOR_NIVEL } from '../../../util/contantes-comuns/constantes-comuns';
import CriarContaDepartamento from '../criar-conta-departamento/criar-conta-departamento';
import { link } from 'fs';
import { Redirect } from 'react-router-dom';
import * as NavegadorNivel from '../navegador-nivel';
import Loading from '../../../loading-fenix/loading';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';


class ListaContaDepartamento extends Component {
    constructor(props) {
        super(props);
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.state = {
            empresas: JSON.parse(reactLocalStorage.get('empresas')),
            contaSelecionadaId: contaSelecionada.id,
            contaSelecionada: contaSelecionada,
            redirecionar: false
        };
        this.props.getListaContaDepartamento(NavegadorNivel.obter(NAVEGADOR_NIVEL.VALUE));
    }

    handleMarcarCheck = id => {
        this.setState({ marcarCheckId: id });
    }

    percorrerContas = (lista) => {
        let result = 0;
        lista.map((item,index,array) => {
            result += 1 + this.percorrerContas(item.contaFilha);
        });
        return result; 
    }

    calcularAncestrais = (lista = []) => {
        lista.map((item, index, array) => {
            if(index > 0 && array[index-1] && array[index-1].contaFilha){
                item['ancestrais'] = this.percorrerContas(array[index-1].contaFilha) + 1;
            }
            item.contaFilha && this.calcularAncestrais(item.contaFilha);
        });
        return lista;
    }

    render() {
        if (this.state.redirecionar) {
            return <Redirect to={this.state.rota} />;
        }
        let lista = this.calcularAncestrais(this.props.listaContaDepartamento.listaContaDepartamento);
        return (
            <div>
                <div className="flex-align">
                    <div className="container span7 col-md-11 espacamento_conteudo_paginas">
                        <div className="row">
                            <div className="panel panel-default">
                                <div className="panel-heading fundo-azul-claro">
                                    Lista de Contas
                                </div>
                                {this.props.listaContaDepartamento.loading ? <div className="alinhamento-centro"> <Loading /> </div> : ''}
                                <div className="panel-body">
                                    <div className="list-group">
                                        <ModalEditarConta
                                            {...this.props}
                                            exibirModalSucessoErro={this.props.listaContaDepartamento.exibirModalSucessoErroEditarConta}
                                            msgModalSucessoErro={this.props.listaContaDepartamento.msgModalSucessoErroEditarConta}
                                            setMsgValidarImagemEditarConta={this.props.setMsgValidarImagemEditarConta}
                                            msgValidarImagemEditarConta={this.props.listaContaDepartamento.msgValidarImagemEditarConta}
                                            exibirModalEditarConta={this.props.listaContaDepartamento.exibirModalEditarConta}
                                            contaSelecionada={this.props.listaContaDepartamento.contaSelecionada}
                                            flagListaContaDepartamento={true}
                                            getListaContaDepartamento={() => this.props.getListaContaDepartamento(this.state.contaSelecionadaId)}
                                        />
                                        <CriarContaDepartamento
                                            mostrarModalCriarConta={this.props.listaContaDepartamento.exibirModalCriarContaDepartamento}
                                            esconderModal={() => this.props.setExibirModalCriarContaDepartamento(false)}
                                            contaSelecionada={this.props.listaContaDepartamento.contaSelecionada}
                                            getListaContaDepartamento={() => this.props.getListaContaDepartamento(this.state.contaSelecionadaId)}
                                        />
                                        <ListaContaComponente
                                            parent
                                            handleMarcarCheck={this.handleMarcarCheck}
                                            marcarCheckId={this.state.marcarCheckId}
                                            lista={lista}
                                            setContaSelecionada={this.props.setContaSelecionada}
                                            setExibirModalCriarContaDepartamento={() => this.props.setExibirModalCriarContaDepartamento(true)}
                                            setExibirModalEditarConta={() => this.props.setExibirModalEditarConta(true)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita btn-voltar-departamento">
                    <Button type="button" bsStyle="primary" onClick={
                        (e) => {
                            var totalNivel = NavegadorNivel.anterior(NAVEGADOR_NIVEL.VALUE);
                            this.setState({ redirecionar: true });

                            if (totalNivel > 1) {
                                this.setState({ rota: ROTAS_CAMINHOS.DASHBOARD_DEPARTAMENTO });
                            } else {
                                this.setState({ rota: ROTAS_CAMINHOS.DASHBOARD_CONTA });
                            }
                        }
                    }>{LABEL_COMUNS.VOLTAR}</Button>
                    <br /><br />
                </Grid>
            </div>

        );
    }

}

export default ListaContaDepartamento;
