import React from 'react';
import MostrarSaldo from '../mostrar-saldo';
import { DESCRICAO_CONTA, LABEL_COMUNS } from '../../../util/contantes-comuns/constantes-comuns';
import { ESTILO } from '../../../util/estilo_para_componentes/estiloUtil';
import { podeConsultar, podeCriar, podeAprovar } from '../../../../reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';

let TIPO_CONTA = '';
const MostrarSaldoESaldoBloqueado = (props) => {
    const corDeFundoParaTitulo = props.contextoBppPag ? ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG : ESTILO.COR_DE_FUNDO.TITULO.PADRAO;
    const TIPO_CONTA = props.contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_MOVIMENTO ? LABEL_COMUNS.CONTA_DIGITAL : props.contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO ? LABEL_COMUNS.CONTA_DEPARTAMENTO : LABEL_COMUNS.CONTA_CARTAO;
    /*  if (props.contaSelecionada.descricao === 'contaMovimento') TIPO_CONTA = 'Conta Digital - '
     if (props.contaSelecionada.descricao === 'contaDepartamento') TIPO_CONTA = 'Conta Departamento - ' */
    return (
        <div className="panel panel-default">
            <div className={corDeFundoParaTitulo}> <p className="cabecalho-saldo-dashboard">{TIPO_CONTA} - {props.contaSelecionada.apelido} {props.contaSelecionada.agencia}/{props.contaSelecionada.numero}-{props.contaSelecionada.digito} </p></div>
            <div className="panel-body" style={{ height: '60px' }}>
                {props.outorgaPerfils.podeConsultar && <MostrarSaldo
          contaSelecionada={props.contaSelecionada}
          exibirSaldo={props.exibirSaldo}
          mostrarSaldoBloqueado={props.mostrarSaldoBloqueado} />}
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
    }
});

export default connect(mapStateToProps)(MostrarSaldoESaldoBloqueado);
