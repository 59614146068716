import React, { Component } from 'react';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import Grid from '../componentes-uteis/grid/grid';
import GridRow from '../componentes-uteis/grid/grid-row';
import FooterBotoesModalComponent from '../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';
import { LABEL_COMUNS, CAMPOS, MENSAGENS } from '../util/contantes-comuns/constantes-comuns';
import { validarErroImagem } from '../util/arquivos/arquivos-util';
import If from '../componentes-uteis/if/if';
import { salvarEdicaoDeContaEAvatar } from '../../servicos/contas/contas-base';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import ShowLineError from '../comuns/showLineError/show-line-error';


class EditarConta extends Component {

    constructor(props) {
        super(props);
        this.state = {
            contaId: '', contaSelecionada: '', apelido: '', apelidoErro: '', exibirModalCancelamento: false, exibirModalFinalizacao: false,
            exibirModalConfirmacao: false, avatarSelecionado: undefined, avatarSelecionadoErro: '', tipoPessoa: ''
        };
    }

    componentDidMount() {
        const { contaSelecionada, pessoaSelecionada, pessoaLogada, empresas } = this.props;
        this.setState({
            apelido: contaSelecionada.apelido,
            contaSelecionada: contaSelecionada,
            contaId: contaSelecionada.id,
            tipoPessoa: pessoaSelecionada.tipo,
            pessoaLogada,
            empresas
        });
    }


    setPropsCampos = (campo, nomeDoCampo) => {

        const valorDoCampo = campo.target && ((campo.target.files && campo.target.files[0]) || campo.target.value);
        if (nomeDoCampo === CAMPOS.ARQUIVO_SELECIONADO) {
            let reader = new FileReader();
            let avatarSelecionado = valorDoCampo;
            const avatarSelecionadoErro = validarErroImagem(avatarSelecionado);
            if (!avatarSelecionadoErro) {
                reader.onloadend = () => {
                    this.setState({
                        imagemAvatarUrl: reader.result,
                        avatarSelecionado, avatarSelecionadoErro: ''
                    });
                };
                reader.readAsDataURL(avatarSelecionado);
            }
            else {
                this.setState({ avatarSelecionadoErro });
            }
        } else {
            this.setState({
                [nomeDoCampo]: valorDoCampo,
                [nomeDoCampo + 'Erro']: '',
            });
        }
    }

    render() {
        const { numero, digito } = this.state.contaSelecionada;
        return (
            <GridRow>
                <ModalComponent
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.CONTAS.TITULOS.EDITAR_CONTA(numero, digito)}
                    body={MENSAGENS.CONTAS.EDICAO.CONFIRMACAO(numero, digito)}
                    footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent
                    exibirModal={this.state.exibirModalFinalizacao}
                    name="modal-cancelamento"
                    header={LABEL_COMUNS.CONTAS.TITULOS.EDITAR_CONTA(numero, digito)}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()} />

                <ModalComponent
                    exibirModal={this.props.exibirModalEditarConta}
                    header={LABEL_COMUNS.CONTAS.TITULOS.EDITAR_CONTA(numero, digito)}
                    body={this.retornaCorpoModalEditarConta()}
                    footer={this.retornaRodapeModalEditarConta()}
                />
            </GridRow>

        );
    }

    retornaCorpoModalEditarConta = () => {
        return (
            <GridRow>
                <Grid>
                    <GridRow>
                        <Grid>
                            <ControlLabel>{LABEL_COMUNS.APELIDO}</ControlLabel>
                            <FormGroup validationState={this.state.apelidoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <FormControl
                                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                                    type="text"
                                    id="apelido"
                                    className="mensagens-erro form-control"
                                    placeholder={LABEL_COMUNS.APELIDO}
                                    value={this.state.apelido}
                                    maxLength={20}
                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.APELIDO)}
                                />
                                <ShowLineError error={this.state.apelidoErro} />
                            </FormGroup>
                        </Grid>
                    </GridRow>
                    <br />
                    <GridRow>
                        <Grid>
                            <If test={this.state.contaSelecionada.urlAvatar}>
                                <img className="avatar-img" src={this.state.imagemAvatarUrl || this.state.contaSelecionada.urlAvatar} />
                            </If>
                            <input onChange={(e) => this.setPropsCampos(e, CAMPOS.ARQUIVO_SELECIONADO)} id="imagem1" type="file" accept="image/*" />
                            <label id="label-file" htmlFor="imagem1" className="alterar-avatar">{LABEL_COMUNS.CONTAS.TITULOS.ALTERAR_OU_INSERIR_AVATAR}</label>
                            <ShowLineError error={this.state.avatarSelecionadoErro} />
                        </Grid>
                    </GridRow>
                </Grid>
            </GridRow>
        );
    }

    retornaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO);
                    this.salvarEdicaoDeConta();
                }} />
        );
    }

    retornaRodapeModalFinalizacao() {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
                    this.props.setExibirModalEditarConta(false);
                    this.props.getContas(this.state.pessoaLogada);
                }} />
        );
    }

    retornaRodapeModalEditarConta = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => this.props.setExibirModalEditarConta(false)}
                btn2Label={LABEL_COMUNS.SALVAR}
                btn2OnClick={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO)}
            />
        );
    }

    salvarEdicaoDeConta = async () => {
        this.props.setExibirLoading(true);
        const resp = await salvarEdicaoDeContaEAvatar(this.state);
        if (!resp.error) {
            const { conta: { data: { msg } } } = resp;
            this.setPropsCampos({ target: { value: msg } }, CAMPOS.MENSAGEM_FINALIZACAO);
            this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO);
        }
        else {
            const { data: { erros } } = resp;
            const apelidoErro = erros[0].mensagem;
            this.setState({ apelidoErro });
        }
        this.props.setExibirLoading(false);
    }

}



export default EditarConta;
