import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
    CAMPOS,
    CONTA_SELECIONADA,
    LABEL_COMUNS,
    COMBO,
    MENSAGENS
} from '../../../../util/contantes-comuns/constantes-comuns';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import ComprovanteModal from '../../../../componentes-uteis/comprovante/comprovante-modal';

import Grid from '../../../../componentes-uteis/grid/grid';
import If from '../../../../componentes-uteis/if/if';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import Loading from '../../../../loading-fenix/loading';
import { buscarLotesDoArquivo, } from '../../../../../action/transferencia-em-lote/transferencia-em-lote-action';
import { getHistoricoLote } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-base';
import { buscarItensDoLote, } from '../../../../../action/transferencia-em-lote/transferencia-em-lote-action';

import './lote.css';
import { formatarDataBR, formatarDataHoraBR } from '../../../../util/data/dataUtil';
import { formataMoedaComSimboloESinalNegacao } from '../../../../util/numeros/numeroUtil';

class ItensDoLote extends Component {

    constructor(props) {
        super(props);


        this.state = this.getInitialState(props);

    }

    componentDidMount = () => (
        this.props.buscarItensDoLote({ loteId: this.state.id })
    );

    getInitialState = (props, abrirCollapse = false) => (
        {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            idLote: props.location && props.location.state && props.location.state.idLote,
            id: props.location && props.location.state && props.location.state.id,
            abrirCollapse: abrirCollapse,
            exbirMaisRegistros: false,
            dataInicial: '',
            dataInicialErro: '',
            dadosComprovante: {},
            dataFinal: '',
            dataFinalErro: '',
            operacaoTransferenciaEmLoteSelecionada: {},
            listaOperacoesTransferenciaEmLote: [],
            operacaoErro: '',
            operacaoExibicao: props.location && props.location.state && props.location.state.operacaoExibicao,
            exibirModalComprovante: false,
            statusLotesDoArquivoSelecionado: '',
            listaStatusTransferenciaEmLote: COMBO.STATUS.TRANSFERENCIA_EM_LOTE_ITENS || [],
            statusErro: '',
            valorDe: '',
            valorDeError: '',
            valorAte: '',
            valorAteError: '',
            ultimaChave: '',
            erroItens: false,
        }
    );


    componentWillReceiveProps = (nextProps) => {

        if (nextProps.itensDoLote.length > 0) {
        }
        else if (nextProps.erroItensDoLote.error) {
            this.setState({ erroItens: true });
        }

        if (nextProps.ultimaChave) {
            this.setState({ ultimaChave: nextProps.ultimaChave });
            this.setState({ exbirMaisRegistros: true });
        }
        if (!nextProps.ultimaChave) {
            this.setState({ exbirMaisRegistros: false });
        }
    }

    setPropsCampos = (campo, nomeDoCampo) => {
        const valorDoCampo = campo && campo.value || campo && campo.target && campo.target.value || campo;
        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        });
    };

    setDataInicial = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_INICIAL)
    );

    setDataFinal = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_FINAL)
    );

    setValorDe = (event) => {
        this.setPropsCampos(event, CAMPOS.VALOR_DE);
    };

    setValorAte = (event) => (
        this.setPropsCampos(event, CAMPOS.VALOR_ATE)
    );

    setOperacaoArquivosDoLote = (event) => (
        this.setPropsCampos(event, CAMPOS.OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA)
    );

    setStatusLotesDoArquivoSelecionado = (statusLotesDoArquivoSelecionado) => {
        this.setState({ statusLotesDoArquivoSelecionado });
    };

    onLimparFiltroClick = () => (
        this.setState(this.getInitialState(this.props, this.state.abrirCollapse))
    );

    onMouseEnterStatusTooltip(status) {

        let tooltipMessage = '';

        switch (status) {
            case 'processado':
                tooltipMessage = 'Operação realizada';
                break;
            case 'naoProcessado':
                tooltipMessage = 'Operação não realizada';
                break;
            default:
                break;
        }

        return (
            <Tooltip id="tooltip">{tooltipMessage}</Tooltip>
        );
    }

    buscarComFiltro(objeto) {
        this.setState({ ultimaChave: '' }, () => {
            this.props.buscarItensDoLote(
                {
                    ...objeto.params,
                    valorDe: objeto.valorDe,
                    valorAte: objeto.valorAte,
                    statusLotesDoArquivoSelecionado: objeto.statusLotesDoArquivoSelecionado,
                },
                []
            );
        });
    }

    downloadComprovantePDF() {
        console.log('download');
    }

    openModalComprovante(item) {
        const itemComprovante = { tipoPessoaContaOrigem: 'PF', transacao: '', valorOperacao: '', valorTarifa: '', nomePessoaContaOrigem: '', agenciaContaOrigem: '', numeroContaOrigem: '', digitoContaOrigem: '', dataAgendamento: '', descricao: '', nomeFavorecido: '', documentoFavorecido: '', bancoDestinoDescricao: '', bancoDestinoNumero: '' };
        const dadosComprovante = { contextoTemplate: itemComprovante, operacao: '' };

        // this.setState({
        //     dadosComprovante: dadosComprovante
        // }, () => {
        //     this.setState({exibirModalComprovante: true})
        // });        

    }


    render() {
        const {
            contaSelecionada,
            idLote,
            id,
            abrirCollapse,
            dataInicial,
            dataInicialErro,
            dataFinal,
            dataFinalErro,
            valorDe,
            valorDeError,
            valorAte,
            valorAteError,
            operacaoTransferenciaEmLoteSelecionada,
            listaOperacoesTransferenciaEmLote,
            operacaoErro,
            statusLotesDoArquivoSelecionado,
            listaStatusTransferenciaEmLote,
            statusErro,
        } = this.state;

        const {
            history,
            exibirLoading,
            itensDoLote,
        } = this.props;

        const {
            LOTES_DO_ARQUIVO,
            ID,
            OPERACAO,
            VALOR,
            BENEFICIARIO,
            DATA_HORARIO,
            STATUS,
            COMPROVANTE,
            VOLTAR,
            DATA_DE_INCLUSAO
        } = LABEL_COMUNS;

        const header = [ID, OPERACAO, VALOR, BENEFICIARIO, DATA_HORARIO, STATUS];

        const params = {
            loteId: id,
            valorDe,
            valorAte,
            statusLotesDoArquivoSelecionado,
            ultimaChave: this.state.ultimaChave,
        };

        const { ultimaChave } = this.state;

        return (
            <div>
                <ContaSelecionada contextoBppPag={true} contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel-sem-sombra">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }}>
                                <div className="cabecalho">
                                    <span>{LOTES_DO_ARQUIVO(idLote)}</span>
                                    <Button className="botao-fundo-verde-claro" onClick={() => this.setState({ abrirCollapse: !abrirCollapse })}>
                                        <span className="glyphicon glyphicon-filter" />
                                    </Button>
                                </div>
                            </div>
                            <FiltroGenerico
                                abrirCollapse={abrirCollapse}
                                contextoBppPag={true}
                                mostrarFiltroDeStatusMesmaLinhaDoValor={true}
                                mostrarFiltroDeValor={true}


                                valorDe={valorDe}
                                setValorDe={this.setValorDe}
                                valorDeError={valorDeError}
                                valorAte={valorAte}
                                setValorAte={this.setValorAte}
                                valorAteError={valorAteError}
                                classNameData={'col-sm-2 col-md-2 col-lg-2'}


                                listaDeStatus={listaStatusTransferenciaEmLote}
                                statusSelecionado={statusLotesDoArquivoSelecionado}
                                setStatus={this.setStatusLotesDoArquivoSelecionado}
                                statusErro={statusErro}

                                validationState={'success'}

                                buscar={() => this.buscarComFiltro({
                                    params: { ...params, ultimaChave: '' },
                                    valorDe,
                                    valorAte,
                                    statusLotesDoArquivoSelecionado,
                                })}

                                exibirBotaoLimpar={true}
                                limparFiltro={this.onLimparFiltroClick}

                            />

                            {
                                this.props.exibirLoadingItensDoLote ?
                                    <div className="alinhamento-centro"><Loading /></div> :
                                    this.props.itensDoLote.length > 0 ?
                                        <div className="panel-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {header.map((el, idx) => <th key={idx} style={{ textAlign: 'center' }} className="fundo-verde-escuro">{el}</th>)}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        (itensDoLote || []).map((el, idx) => (
                                                            // el && el.operacao &&
                                                            <tr key={idx}>
                                                                <td className="alinhamento-centro id" style={{ borderTop: 'none' }}>
                                                                    <b
                                                                        style={{ cursor: 'pointer' }}
                                                                        onClick={() => this.openModalComprovante(el)}>
                                                                        {el && el.loteDetalheId || ''}
                                                                    </b>
                                                                </td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{this.state.operacaoExibicao || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el ? formataMoedaComSimboloESinalNegacao(el.valor) : ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el ? el.nomeFavorecido : ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el ? formatarDataHoraBR(el.dataAlteracao) : ''}</td>

                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>
                                                                    {el ? el.statusExibicao : ''}
                                                                    <OverlayTrigger overlay={this.onMouseEnterStatusTooltip(el.status)}>
                                                                        <span className="d-inline-block">
                                                                            <i className="fa fa-question-circle radio-modelo-arquivo-exemplo" />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {/* <td className='textos_cartao alinhamento-centro'><Button className='btn btn-success btn-sm' style={{ marginLeft: 13, cursor: 'pointer' }} title='Exportar para .CSV'><i className='fa fa-file-excel-o' aria-hidden='true' /></Button></td> */}
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                            <If test={this.state.exbirMaisRegistros}>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block fonte-tamanho-20"
                                                    onClick={() => this.props.buscarItensDoLote(params, itensDoLote)}
                                                    style={{ height: '50px' }}
                                                >
                                                    <b>{LABEL_COMUNS.MOSTRAR_MAIS}</b>
                                                </button>
                                            </If>
                                        </div>
                                        : this.state.erroItens ?
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>{MENSAGENS.HISTORICO.OPERACOES.ERRO_CHAMADA} </p>
                                            </div>
                                            :
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.HISTORICO.OPERACOES.ERRO_VAZIO}</p>
                                            </div>
                            }
                            <Grid cols="12 12 12 12" className="alinhamento-direita">
                                <Button bsStyle="success" className="botao-voltar-telas" onClick={history.goBack}>{VOLTAR}</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
                {this.state.exibirModalComprovante && <ComprovanteModal
                    header={LABEL_COMUNS.DETALHES_DO_COMPROVANTE('')}
                    bsSize="lg"
                    contextoBppPag
                    onClose={() => this.setState({ exibirModalComprovante: false })}
                    exibirModal={this.state.exibirModalComprovante}
                    dadosComprovante={this.state.dadosComprovante}
                    downloadComprovantePDF={this.downloadComprovantePDF}
                />}


            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // exibirLoading: state.exibirLoadingItensDoLote,
    itensDoLote: state.itensDoLote,
    ...state.TrasferenciaEmLoteReducer
});

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        buscarItensDoLote,
        buscarLotesDoArquivo,
        getHistoricoLote,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(ItensDoLote);

