import React, { Component } from 'react';
import { connect } from 'react-redux';

import './loading-fenix.css';

class LoadingRotas extends Component {
    render() {
        const contextoBppPag = this.props.url && this.props.url.indexOf('bpp-pag') !== -1;
        return (
            <div className="row" style={{ marginTop: '200px' }}>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-centro">
                    <span>
                        <i className="glyphicon glyphicon-refresh gly-spin loading-rotas" style={contextoBppPag ? { color: 'green' } : {}} />
                    </span>
                </div>
            </div>
        );
    }
}

export default LoadingRotas;
