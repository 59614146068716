import React from 'react';
import Menu from './componentes/menu/menu';
import Cabecalho from './componentes/cabecalho/cabecalho';
import Rotas from './componentes/rotas';
import Login from './componentes/login/login';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Button } from 'react-bootstrap';
import {
    MenuContaDepartamento,
    MenuContaDigital,
    MenuEmpresaAdm,
    MenuInicio,
    MenuInicioContaSelecionada,
    MenuSair,
    MenuSolucao,
    MenuTitular,
    MenuTitularContaSelecionada
} from './componentes/menu/menu-constantes';
import ModalComponent from './componentes/componentes-uteis/modal-component/modal-component';
import imagensAtendimentoChatBppCorp from './imagens/atendimento_chat.png';
import imagensAtendimentoEmailBppCorp from './imagens/atendimento_email.png';
import imagensAtendimentoTelefoneBppCorp from './imagens/atendimento_telefone.png';
import imagensAtendimentoOuvidoriaBppCorp from './imagens/atendimento_ouvidoria.png';
import imagensAtendimentoChatBppPag from './imagens/atendimento_chat_pag.png';
import imagensAtendimentoEmailBppPag from './imagens/atendimento_email_pag.png';
import imagensAtendimentoTelefoneBppPag from './imagens/atendimento_telefone_pag.png';
import imagensAtendimentoOuvidoriaBppPag from './imagens/atendimento_ouvidoria_pag.png';
import { ESTILO } from './componentes/util/estilo_para_componentes/estiloUtil';
import { handleLocalStorage } from './servicos/login/login-service';

const App = props => {
    let menu = [];

    if (props.app.menuInicio) {
        menu = menu.concat(MenuInicio());
    }

    if (props.app.menuInicioContaSelecionada) {
        menu = menu.concat(MenuInicioContaSelecionada());
    }

    if (props.app.menuTitular) {
        menu = menu.concat(MenuTitular());
    }

    if (props.app.menuTitularContaSelecionada) {
        menu = menu.concat(MenuTitularContaSelecionada());
    }

    if (props.app.menuEmpresa) {
        menu = menu.concat(MenuEmpresaAdm(props.app.contextBppPag));
    }

    if (props.app.menuSolucao) {
        menu = menu.concat(MenuSolucao());
    }

    if (props.app.menuContaDigital) {
        menu = menu.concat(MenuContaDigital());
    }

    if (props.app.menuDepartamento) {
        menu = menu.concat(MenuContaDepartamento());
    }

    if (props.app.menuSair) {
        menu = menu.concat(MenuSair(() => props.modalAgendamento(true)));
    }

    const corpoModalAtendimento = () => {
        let IMAGEM_ATENDIMENTO_EMAIL = imagensAtendimentoEmailBppCorp;
        let IMAGEM_ATENDIMENTO_CHAT = imagensAtendimentoChatBppCorp;
        let IMAGEM_ATENDIMENTO_TELEFONE = imagensAtendimentoTelefoneBppCorp;
        let IMAGEM_ATENDIMENTO_OUVIDORIA = imagensAtendimentoOuvidoriaBppCorp;
        let corTituloAtendimento = ESTILO.COR_DE_FUNDO.TEXTO_ATENDIMENTO.PADRAO;
        let corTituloChatEmail = ESTILO.COR_DE_FUNDO.TEXTO_ATENDIMENTO_CHAT_EMAIL.PADRAO;

        if (props.app.contextBppPag) {
            IMAGEM_ATENDIMENTO_EMAIL = imagensAtendimentoEmailBppPag;
            IMAGEM_ATENDIMENTO_CHAT = imagensAtendimentoChatBppPag;
            IMAGEM_ATENDIMENTO_TELEFONE = imagensAtendimentoTelefoneBppPag;
            IMAGEM_ATENDIMENTO_OUVIDORIA = imagensAtendimentoOuvidoriaBppPag;
            corTituloAtendimento = ESTILO.COR_DE_FUNDO.TEXTO_ATENDIMENTO.BPP_PAG;
            corTituloChatEmail = ESTILO.COR_DE_FUNDO.TEXTO_ATENDIMENTO_CHAT_EMAIL.BPP_PAG;
        }

        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            <img className="avatar-conta" src={IMAGEM_ATENDIMENTO_TELEFONE} alt="Atendimento Telefônico" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <span className={corTituloAtendimento}>Atendimento Telefônico:</span><br />
              (11) 3580-0301<br />
              De seg à sex das 9h às 18h
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            <img className="avatar-conta" src={IMAGEM_ATENDIMENTO_CHAT} alt="Atendimento Chat" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <span className={corTituloChatEmail}><a href="https://contacomigo.bpp.com.br" className={corTituloChatEmail} target="_blank">Chat</a>:</span><br />
              De seg à sex  (exceto feriados)
                            <br />
              Das 9h às 18h
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            <img className="avatar-conta" src={IMAGEM_ATENDIMENTO_EMAIL} alt="Atendimento Email" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <span className={corTituloChatEmail}><a href="https://contacomigo.bpp.com.br/hc/pt-br/requests/new" className={corTituloChatEmail} target="_blank">Fale Conosco</a>:</span><br />
              Envie a qualquer momento sua solicitação
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            <img className="avatar-conta" src={IMAGEM_ATENDIMENTO_OUVIDORIA} alt="Atendimento Ouvidoria" />
                        </div>
                        <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                            <span className={corTituloAtendimento}>Ouvidoria:</span><br />
              0800 880 0185<br />
              Segunda à sexta das 8h às 12h e das 13h às 17h, exceto feriados<br />
              ouvidoria@bpp.com.br<br />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const rodapeModalAtendimento = () => {
        let corDeFundoBotao = ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        if (props.app.contextBppPag) {
            corDeFundoBotao = ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG;
        }
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle={corDeFundoBotao} onClick={() => props.modalAgendamento(false)} >OK</Button>
                </div>
            </div>
        );
    };

    if (reactLocalStorage.get('estaAutenticado') === undefined) {
        reactLocalStorage.set('estaAutenticado', 'NAO');
    }

    if (reactLocalStorage.get('estaAutenticado') === 'SIM') {
        return (
            <div>
                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                    <Menu menu={menu} contextBppPag={props.app.contextBppPag} url={props.location.pathname} />
                </div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <div className="row div-cabecalho">
                        <Cabecalho />
                    </div>
                    <div className="row div-conteudo">
                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo">
                            <Rotas {...props} />
                        </div>
                    </div>
                </div>
                <ModalComponent
          name="ModalAtendimento"
          contextoBppPag={props.app.contextBppPag}
          exibirModal={props.app.exibirModalAtendimento}
          header="Atendimento"
          body={corpoModalAtendimento()}
          footer={rodapeModalAtendimento()} />
            </div>
        );
    } else {
    // Mantem algumas chaves do localStorage quando redirecionado ao login
        handleLocalStorage();
        return <div><Login /></div>;
    }
};

export default App;
