import React, { Component } from 'react';
import { reactLocalStorage } from 'reactjs-localstorage';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Alert, Button, Collapse, ControlLabel, FormControl, FormGroup, Modal, Panel } from 'react-bootstrap';
import Select from 'react-select';
import { LABELS } from './pedido-estoque-constantes';
import PedidoEstoqueAction from './pedido-estoque-actions';
import imgCartao from '../../imagens/img_cartao_pedido.png';
import ContaSelecionada from '../../componentes/contas/conta-selecionada';
import { formatarDataHoraBR } from '../util/data/dataUtil';
import { STATUS_VALIDACAO_COMPONENTES } from '../util/estilo_para_componentes/estiloUtil';
import LoadingFenix from '../loading-fenix/loading-fenix';
import InputMask from 'react-input-mask';
import PedidoLabelCartaBerco from '../util/pedido/pedido-label-carta-berco';
import PedidoLabelCartao from '../util/pedido/pedido-label-cartao';
import ModalComponent from '../componentes-uteis/modal-component/modal-component';

const action = new PedidoEstoqueAction();

class PedidoEstoque extends Component {
    componentDidMount() {
        this.props.dispatch(action.buscarImagensCartao(reactLocalStorage.getObject('contaSelecionada').id));
        this.props.dispatch(action.buscarImagensCartaBerco(reactLocalStorage.getObject('contaSelecionada').id));
    }

    exibirLoading() {
        if (this.props.estoque.exibirLoading) {
            return (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
            );
        }
    }

    salvarPedido(dadosForm) {
        this.props.dispatch(action.exibeModalFinalizarHandleChange(false));
        this.props.dispatch(action.exibeLoadingHandleChange(true));
        this.props.dispatch(action.salvarPedido(dadosForm, reactLocalStorage.getObject('contaSelecionada').id));
    }

    recarregarTela() {
        window.location.href = '/pedido-estoque';
    }

    /* FUNÇÕES PARA OS MODAIS */

    corpoModalFinalizar() {
        return (
            <div>
                <span>{LABELS.MENSAGEM_CONFIRMACAO}</span>
            </div>
        );
    }

    rodapeModalFinalizar() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                        <Button onClick={() => this.props.dispatch(action.exibeModalFinalizarHandleChange(false))} bsStyle="default">Cancelar</Button>&nbsp;&nbsp;
                        <Button onClick={() => this.salvarPedido(this.props.estoque)} bsStyle="primary">Salvar</Button>
                    </div>
                </div>
            </div>);
    }

    corpoModalConfirmacaoCancelar() {
        return (
            <div>
                <span>{LABELS.MENSAGEM_CONFIRMACAO_CANCELAR}</span> <br />
            </div>
        );
    }

    rodapeModalConfirmacaoCancelar() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 alinhamento-direita">
                        <Button onClick={() => this.props.dispatch(action.exibeModalConfirmacaoCancelarHandleChange(false))} bsStyle="default">Cancelar</Button>&nbsp;&nbsp;
                        <Button onClick={() => this.recarregarTela()} bsStyle="primary">Confirmar</Button>
                    </div>
                </div>
            </div>);
    }

    corpoModalMensagemSucesso() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <span><b>{LABELS.PEDIDO_REALIZADO_COM_SUCESSO}</b></span>
                    </div>
                </div>
                <br />
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        {this.montarMsgPedidoSucesso()}
                    </div>
                </div>
            </div>
        );
    }

    rodapeModalMensagemSucesso() {
        return (
            <div>
                <div className="alinhamento-direira">
                    <Button bsStyle="primary" onClick={() => this.recarregarTela()}>OK</Button>
                </div>
            </div>);
    }

    render() {
        return (
            <div>
                <ContaSelecionada />
                <div id="resumo_pedido" className="row" >
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABELS.INFORMACOES_GERAIS} </b></div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                <div className="list-group">
                                                    {this.props.estoque.statusFinalizacao === true
                            ? <ModalComponent name="ModalMensagemSucesso" exibirModal={this.props.estoque.exibeModalMensagemSucesso}
                              body={this.corpoModalMensagemSucesso()} header={LABELS.PEDIDO_ESTOQUE} footer={this.rodapeModalMensagemSucesso()}
                            />
                            : this.props.estoque.statusFinalizacao === false
                              ? <Alert bsStyle={STATUS_VALIDACAO_COMPONENTES.PERIGO}>
                                  <strong>{this.props.estoque.mensagemFinalizacao}</strong>
                              </Alert>
                              : null
                          }
                                                    <div className="col-xs-4 col-sm-4 col-md-3 col-lg-3">
                                                        {LABELS.QUANTIDADE_CARTOES}
                                                        <FormGroup validationState={this.props.estoque.quantidadeErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <InputMask
                                id="txtQuantidade"
                                className="form-control mensagens-erro"
                                value={this.props.estoque.quantidade}
                                mask="9999999" maskChar=""
                                style={{ height: '36px', paddingTop: '8px', paddingLeft: '10px' }}
                                placeholder={LABELS.QUANTIDADE}
                                onChange={(selectedValue) => this.props.dispatch(action.quantidadeCartoesHandleChange(selectedValue))} />
                                                            {this.props.estoque.quantidadeErro ? <span className="mensagens-erro-span" role="alert">{this.props.estoque.quantidadeErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        {LABELS.IMAGEM_CARTAO}
                                                        <FormGroup validationState={this.props.estoque.imagemCartaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <Select id="slctCartaoSelecionadoId" className="mensagens-erro"
                                disabled={this.props.estoque.disableComboCartao}
                                placeholder="Selecione"
                                value={this.props.estoque.cartaoSelecionadoId}
                                onChange={(selectValue) => this.props.dispatch(action.imagemCartaoHandLeChange(selectValue))}
                                options={this.props.estoque.combolistaImagensTipoCartoes}
                                noResultsText={LABELS.NENHUMA_IMAGEM_ENCONTRADA}
                              />
                                                            {this.props.estoque.imagemCartaoErro ? <span className="mensagens-erro-span" role="alert">{this.props.estoque.imagemCartaoErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        {LABELS.IMAGEM_CARTA_BERCO}
                                                        <FormGroup validationState={this.props.estoque.imagemCartaBercoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <FormGroup >
                                                                <Select id="slctCartaBercoSelecionadaId" className="mensagens-erro"
                                  placeholder="Selecione"
                                  value={this.props.estoque.cartaBercoSelecionadaId}
                                  disabled={this.props.estoque.disableComboCartaBerco}
                                  onChange={(selectedValue) => this.props.dispatch(action.imagemCartaBercoHandLeChange(selectedValue))}
                                  options={this.props.estoque.combolistaImagensTipoCartaBerco}
                                  noResultsText={LABELS.NENHUMA_IMAGEM_ENCONTRADA} />
                                                                {this.props.estoque.imagemCartaBercoErro ? <span className="mensagens-erro-span" role="alert">{this.props.estoque.imagemCartaBercoErro}</span> : null}
                                                            </FormGroup>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-md-1 col-lg-1" />
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div >
                {this.props.estoque.cartaoSelecionadoId ? this.exibeCartoSelecionado() : ''}
                {this.exibirLoading()}
                {this.props.estoque.cartaBercoSelecionadaId ? this.exibeCartaBercoSelecionada() : ''}
                <div>
                    <div className="row">
                        <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-direita">
                            <Button disabled={this.props.estoque.disableButtonCancel} bsStyle="default" onClick={() => this.props.dispatch(action.exibeModalConfirmacaoCancelarHandleChange(true))}>Cancelar</Button>&nbsp;&nbsp;
                            <Button disabled={this.props.estoque.disabeButtonConfirm} bsStyle="primary" onClick={() => this.props.dispatch(action.exibeModalFinalizarHandleChange(true))}>Confirmar</Button>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                    </div>
                    <div className="clearfix" />
                    <br />
                </div>

                <ModalComponent name="ModalFinalizar" exibirModal={this.props.estoque.exibeModalFinalizar}
          body={this.corpoModalFinalizar()} header={LABELS.ENVIO_DE_PEDIDO_ESTOQUE} footer={this.rodapeModalFinalizar()}
        />
                <ModalComponent name="ModalConfirmacaoCancelar" exibirModal={this.props.estoque.exibeModalConfirmacaoCancelar}
          body={this.corpoModalConfirmacaoCancelar()} header={LABELS.PEDIDO_ESTOQUE} footer={this.rodapeModalConfirmacaoCancelar()}
        />
            </div>
        );
    }

    exibeCartoSelecionado() {
        if (this.props.estoque.cartaoSelecionadoId) {
            return (
                <div>
                    <div className="row">
                        <div id="informacoes_gerais">
                            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                                <div className="panel panel-default">
                                    <div className="panel-heading fundo-cinza-claro"><b> {LABELS.RESUMO_DO_PEDIDO_CARTAO} </b></div>
                                    <div className="panel-body">
                                        <div className="list-group">
                                            <form className="editForm" id="myForm">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="list-group">
                                                            <div className="col-xs-2 col-sm-3 col-md-2 col-lg-2">
                                                                <div className="row">
                                                                    <img id="img-cartao" src={this.props.estoque.cartaoSelecionado.urlImagem} alt="Cartão" style={{ position: 'absolute' }} width="107" height="67" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-10 col-sm-9 col-md-10 col-lg-10">
                                                                <div className="row">
                                                                    <span>Pedido de Impressão de Estoque de {this.props.estoque.quantidade} <PedidoLabelCartao quantidade={this.props.estoque.quantidade} /></span>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <span>Data do Pedido:  {formatarDataHoraBR(null)}</span>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <span><b>Valor Total da Impressão: R$ 0,00</b></span>
                                                                </div>
                                                                <br /><br />
                                                                <div className="row">
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginLeft: '-15px' }}>
                                                                        <span><i>{LABELS.RESUMO_DO_PEDIDO_CONFIRMACAO}</i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                        </div >
                    </div>
                </div>);
        } else {
            return (<div />);
        }
    }

    exibeCartaBercoSelecionada() {
        if (this.props.estoque.cartaBercoSelecionadaId) {
            return (
                <div>
                    <div className="row">
                        <div id="informacoes_gerais">
                            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                                <div className="panel panel-default">
                                    <div className="panel-heading fundo-cinza-claro"><b> {LABELS.RESUMO_DO_PEDIDO_CARTA_BERCO} </b></div>
                                    <div className="panel-body">
                                        <div className="list-group">
                                            <form className="editForm" id="myForm">
                                                <div className="row">
                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                        <div className="list-group">
                                                            <div className="col-xs-2 col-sm-3 col-md-2 col-lg-2">
                                                                <div className="row">
                                                                    <img id="img-cartao" src={this.props.estoque.cartaBercoSelecionada.urlImagem} alt="Cartão" style={{ position: 'absolute' }} width="107" height="67" />
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-10 col-sm-9 col-md-10 col-lg-10">
                                                                <div className="row">
                                                                    <span>Pedido de Impressão de Estoque de {this.props.estoque.quantidade} <PedidoLabelCartaBerco quantidade={this.props.estoque.quantidade} /></span>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <span>Data do Pedido:  {formatarDataHoraBR(null)}</span>
                                                                </div>
                                                                <br />
                                                                <div className="row">
                                                                    <span><b>Valor Total da Impressão: R$ 0,00</b></span>
                                                                </div>
                                                                <br /><br />
                                                                <div className="row">
                                                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ marginLeft: '-15px' }}>
                                                                        <span><i>{LABELS.RESUMO_DO_PEDIDO_CONFIRMACAO}</i></span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                        </div>
                    </div>
                </div>);
        } else {
            return (<div />);
        }
    }

    montarMsgPedidoSucesso() {
        let $dadosPedidoSucesso = (<div />);
        if (this.props.estoque.dadosForm.pedidos) {
            if (this.props.estoque.dadosForm.pedidos.length > 0) {
                let $dadosItem01 = (<div className="row">
                    <div className="col-md-5">{this.textoTipoPedido(this.props.estoque.dadosForm.pedidos[0].tipoPedido)}</div>
                    <div className="col-md-7"><b>{this.props.estoque.dadosForm.pedidos[0].id}</b></div>
                </div>);
                let $dadosItem02 = (<div />);
                if (this.props.estoque.dadosForm.pedidos.length > 1) {
                    $dadosItem02 = (<div className="row">
                        <div className="col-md-5">{this.textoTipoPedido(this.props.estoque.dadosForm.pedidos[1].tipoPedido)} </div>
                        <div className="col-md-7"><b>{this.props.estoque.dadosForm.pedidos[1].id}</b></div>
                    </div>);
                }
                $dadosPedidoSucesso = (<div> {$dadosItem01} {$dadosItem02} </div>);
            }
        }
        return $dadosPedidoSucesso;
    }

    textoTipoPedido(tipo) {
        if (tipo === 'ESTOQUE_CARTAO') {
            return 'Pedido Estoque Cartão';
        } else {
            return 'Pedido Estoque Carta Berço';
        }
    }
}

function mapStateToProps(state) {
    return {
        estoque: state.PedidoEstoqueReducer
    };
}

export default connect(mapStateToProps)(PedidoEstoque);
