import { jsonPagamentos, jsonAprovacaoTransacao } from '../pagamento/pagamento-util';
import { URL_POST_OPERACOES, HEADER_API, montaUrlFetch, HTTP_METODOS, urlServicos, URL_GET_OPERACOES_TRANSACOES } from '../../../servicos/url-servicos';
import { METODOS } from '../../util/contantes-comuns/constantes-comuns';

export const setExibirLoading = (value) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_EXIBIR_LOADING,
    payload: value
});

export const setSolicitacaoPagamentoContaSelecionada = (contaSelecionada) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_CONTA_SELECIONADA,
    payload: contaSelecionada
});

export const setSolicitacaoPagamentoCodigoBarras = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_CODIGO_BARRAS,
    payload: event.target.value
});

export const setSolicitacaoPagamentoValor = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_VALOR,
    payload: valor.toString().replace(',', '.')
});

export const setSolicitacaoPagamentoDataVencimento = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DATA_VENCIMENTO,
    payload: event.target.value
});

export const setSolicitacaoPagamentoTipoDocumento = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_TIPO_DOCUMENTO,
    payload: valor
});

export const setSolicitacaoPagamentoDocumentoBeneficiario = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DOCUMENTO_BENEFICIARIO,
    payload: event.target.value
});

export const setSolicitacaoPagamentoNomeBeneficiario = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_NOME_BENEFICIARIO,
    payload: event.target.value
});

export const setSolicitacaoPagamentoDescricao = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DESCRICAO,
    payload: event.target.value
});

export const setSolicitacaoPagamentoAgendamentoHoje = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_AGENDAMENTO_HOJE,
    payload: event.target.checked
});

export const setSolicitacaoPagamentoDataAgendamento = (event) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_SOLICITACAO_PAGAMENTO_DATA_AGENDAMENTO,
    payload: event.target.value
});

export const getSolicitacaoPagamentoExibirErroDataAgendamento = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroDataAgendamentoMenorIgualHoje = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MENORIGUAL_HOJE,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroDataAgendamentoMaiorDoQueUmAno = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_AGENDAMENTO_MAIOR_QUE_UM_ANO,
    payload: valor
});

export function setSolicitacaoPagamentoArmazenarTokenSMS(event) {
    return {
        type: METODOS.PAGAMENTOS_DE_CONTAS.ARMAZENAR_TOKEN_SMS,
        payload: event.target.value
    };
}

export const getSolicitacaoPagamentoExibirErroCodigoBarras = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_CODIGO_BARRAS,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroValor = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_VALOR,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroSaldo = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_SALDO,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroDataVencimento = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroDataVencimentoMenor = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DATA_VENCIMENTO_MENOR,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroNomeBeneficiario = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_NOME_BENEFICIARIO,
    payload: valor
});

export const getSolicitacaoPagamentoExibirErroDocumentoBeneficiario = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.GET_SOLICITACAO_PAGAMENTO_EXIBIR_ERRO_DOCUMENTO_BENEFICIARIO,
    payload: valor
});

export const setExibirModalToken = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.SET_EXIBIR_MODAL_TOKEN,
    payload: valor
});

export const exibirModalSucesso = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.EXIBIR_MODAL_SUCESSO,
    payload: valor
});

export const exibirModalErro = (valor) => ({
    type: METODOS.PAGAMENTOS_DE_CONTAS.EXIBIR_MODAL_ERRO,
    payload: valor
});

export const requisitarTokenPagamento = (dadosForm, necessitaToken, outorgaPodeAprovar) => {
    let pagamentoAgendado = !!((dadosForm.dataAgendamento !== '' && dadosForm.dataAgendamentoHoje === ''));
    dadosForm = jsonPagamentos(dadosForm);
    if ((necessitaToken && outorgaPodeAprovar) || (necessitaToken && pagamentoAgendado)) {
        return dispatch => {
            dispatch(setExibirLoading(true));
            fetch(montaUrlFetch(urlServicos.OPERACAO), {
                method: HTTP_METODOS.POST,
                headers: HEADER_API().headers,
                body: dadosForm
            })
                .then(response => response.json())
                .then(async dadosRetornados => ({ response: await fetchSolicitacaoPagamentoReenviarToken(dadosRetornados.rel, pagamentoAgendado), operacaoId: dadosRetornados.rel }))
                .then(({ response, operacaoId }) => {
                    dispatch({
                        type: response.ok ? METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_TOKEN_PAGAMENTO : METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_TOKEN_PAGAMENTO_ERRO,
                        payload: { rel: operacaoId }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                        payload: { erros: ['Tente novamente mais tarde'] }
                    });
                }).finally(() => dispatch(setExibirLoading(false)));
        };
    }
    if (outorgaPodeAprovar) {
        return dispatch => {
            dispatch(setExibirLoading(true));
            fetch(montaUrlFetch(urlServicos.OPERACAO), {
                method: HTTP_METODOS.POST,
                headers: HEADER_API().headers,
                body: dadosForm
            })
                .then(async resp => resp.ok ? resp.json() : Promise.reject({ error: !resp.ok, data: await resp.json(), authorized: !resp.statusText === '409' }))
                .then(dadosRetornados => ({ operacaoId: dadosRetornados.rel }))
                .then(({ operacaoId }) => {
                    const agendamento = dadosForm.dataAgendamento ? {
                        tipoTransacao: 'agendamento',
                        dataAgendamento: dadosForm.dataAgendamento
                    } : {
                        tipoTransacao: 'aprovacao'
                    };
                    return fetchSolicitarPagamentoTrans(operacaoId, '', agendamento, dispatch);
                })
                .then((success) => {
                    success && dispatch({
                        type: METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_PAGAMENTO_SUCCESS,
                        payload: { msg: 'Operação cadastrada e aprovada com sucesso.' }
                    });
                })
                .catch(err => {
                    dispatch({
                        type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                        payload: err.authorized ? { erros: ['Tente novamente mais tarde'] } : { erros: ['Não autorizado'] }
                    });
                }).finally(() => dispatch(setExibirLoading(false)));
        };
    }
    return dispatch => {
        dispatch(setExibirLoading(true));
        fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: dadosForm
        })
            .then(async resp => resp.ok ? resp.json() : Promise.reject({ error: !resp.ok, data: await resp.json(), authorized: !resp.statusText === '409' }))
            .then(dadosRetornados => ({ operacaoId: dadosRetornados.rel }))
            .then(({ operacaoId }) => {
                dispatch({
                    type: METODOS.PAGAMENTOS_DE_CONTAS.REQUISITAR_PAGAMENTO_SUCCESS,
                    payload: { rel: operacaoId, msg: 'Operação cadastrada e pendente de aprovação.' }
                });
            })
            .catch(err => {
                dispatch({
                    type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                    payload: err.authorized ? { erros: ['Tente novamente mais tarde'] } : { erros: ['Não autorizado'] }
                });
            }).finally(() => dispatch(setExibirLoading(false)));
    };
};

export const reenviarTokenPagamento = (operacaoId, pagamentoAgendado) => {
    return dispatch => {
        dispatch(setExibirLoading(true));
        fetchSolicitacaoPagamentoReenviarToken(operacaoId, pagamentoAgendado)
            .then(response => {
                response.json().then(dadosRetornados => {
                    dispatch({
                        type: response.ok ? METODOS.PAGAMENTOS_DE_CONTAS.REENVIAR_TOKEN_PAGAMENTO : METODOS.PAGAMENTOS_DE_CONTAS.REENVIAR_TOKEN_PAGAMENTO_ERRO,
                        payload: dadosRetornados
                    });
                });
            }).finally(() => dispatch(setExibirLoading(false)));
    };
};

const fetchSolicitacaoPagamentoReenviarToken = (operacaoId, pagamentoAgendado) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS', transacao: (pagamentoAgendado ? 'agendamento' : 'aprovacao') }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    });
};

// TODO: os requisitantes dessa chamada só podem executálos, quando tiver permissão de podeAprovar (outorga-negocio)
export const solicitarPagamento = (operacaoId, tokenSMS, agendamento) => {
    let dadosFormTransacao = jsonAprovacaoTransacao(tokenSMS, agendamento);
    const msg = agendamento.tipoTransacao === 'aprovacao' ? 'Operação cadastrada e aprovada com sucesso.' : 'Operação cadastrada e agendada com sucesso.';
    return dispatch => {
        dispatch(setExibirLoading(true));
        fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: dadosFormTransacao
        }).then(response => {
            response.json().then(dadosRetornados => {
                dispatch({
                    type: response.ok ? METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS : METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                    payload: { ...dadosRetornados, msg: response.statusText === '409' ? 'Não autorizado' : msg }
                });
            });
        }).finally(() => dispatch(setExibirLoading(false)));
    };
};

export const fetchSolicitarPagamentoTrans = (operacaoId, tokenSMS, agendamento, dispatch) => {
    let dadosFormTransacao = jsonAprovacaoTransacao(tokenSMS, agendamento);
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: dadosFormTransacao
    })
        .then(async response => ({ resp: response, dadosRetornados: await response.json() }))
        .then(({ resp, dadosRetornados }) => resp.ok);
};
