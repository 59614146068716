import { CNPJ, CPF } from 'cpf_cnpj';
import { dataInvalida, dataMaiorQueHoje, formatarDataUS, validaDataMaiorQueUmAno } from 'componentes/util/data/dataUtil';
import { trim } from 'componentes/util/string/string-util';
import {
    CAMPOS,
    CANAL_TOKEN,
    DDI,
    DESCRICAO_CONTA,
    MENSAGENS,
    OPERACAO,
    TIPO_DOCUMENTO,
    TIPO_FAVORECIDO,
    TIPO_TRANSFERENCIA_BANCARIA
} from 'componentes/util/contantes-comuns/constantes-comuns';
import { removeMascaraTelefoneCpfCnpj } from 'componentes/util/formata-string';

export function montaJsonDeTransferenciaBancariaParaBackEnd(dadosTransferencia) {
    let jsonTransferenciaBancaria = {};
    jsonTransferenciaBancaria.operacao = retornaNomeDaOperacaoDaTransferenciaBancaria(dadosTransferencia);
    jsonTransferenciaBancaria.contaOrigemId = dadosTransferencia.contaSelecionada.id;
    jsonTransferenciaBancaria.contaDestinoId = dadosTransferencia.contaDestinoId;
    jsonTransferenciaBancaria.valor = dadosTransferencia.valorDaTransferencia;
    if (dadosTransferencia.dataAgendamento) {
        jsonTransferenciaBancaria.dataAgendamento = formatarDataUS(dadosTransferencia.dataAgendamento);
    }

    if (dadosTransferencia.descricao) {
        let descricao = trim(dadosTransferencia.descricao);
        if (descricao !== '') {
            jsonTransferenciaBancaria.tagCliente = descricao;
        }
    }

    if (dadosTransferencia.telefone || dadosTransferencia.email || dadosTransferencia.favorecidoSelecionadoId || (dadosTransferencia.tipoTransferenciaSelecionada !== TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value)) {
        jsonTransferenciaBancaria.dadosIntegracao = {};
    }

    if (dadosTransferencia.telefone) {
        jsonTransferenciaBancaria.dadosIntegracao.telefone = dadosTransferencia.telefone;
    }

    if (dadosTransferencia.email) {
        jsonTransferenciaBancaria.dadosIntegracao.email = dadosTransferencia.email;
    }

    if (dadosTransferencia.favorecidoSelecionadoId) {
        jsonTransferenciaBancaria.dadosIntegracao.favorecidoId = dadosTransferencia.favorecidoSelecionadoId;
    }

    if (dadosTransferencia.transferenciaSelecionadaId !== TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value) {
        jsonTransferenciaBancaria.operacao = OPERACAO.NOME.TED;
        jsonTransferenciaBancaria.dadosIntegracao.nomeFavorecido = dadosTransferencia.nome;
        jsonTransferenciaBancaria.dadosIntegracao.codigoBanco = dadosTransferencia.bancoSelecionadoId;
        jsonTransferenciaBancaria.dadosIntegracao.agencia = dadosTransferencia.agencia;
        jsonTransferenciaBancaria.dadosIntegracao.tipoConta = dadosTransferencia.tipoConta;
        jsonTransferenciaBancaria.dadosIntegracao.conta = dadosTransferencia.numeroConta;
        jsonTransferenciaBancaria.dadosIntegracao.digito = dadosTransferencia.digito;
        jsonTransferenciaBancaria.dadosIntegracao.documento = {
            tipo: dadosTransferencia.tipoDocumento,
            numero: dadosTransferencia.numeroDocumento
        };
    }

    jsonTransferenciaBancaria = JSON.stringify(jsonTransferenciaBancaria);
    return jsonTransferenciaBancaria;
}

const retornaNomeDaOperacaoDaTransferenciaBancaria = (dadosTransferencia) => {
    let operacaoNome = dadosTransferencia.transferenciaDeMesmaTitularidade ? OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO : OPERACAO.NOME.TRANSFERENCIA_ENTRE_CONTAS_MOVIMENTO_DIFERENTES_TITULARIDADES;

    if (dadosTransferencia.contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_MOVIMENTO) {
        if (dadosTransferencia.contaDestinoDescricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO) {
            operacaoNome = OPERACAO.NOME.TRANSFERENCIA_CONTA_MOVIMENTO_CONTA_DEPARTAMENTO;
        }
    }

    if (dadosTransferencia.contaSelecionada.descricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO) {
        if (dadosTransferencia.contaDestinoDescricao === DESCRICAO_CONTA.CONTA_MOVIMENTO) {
            operacaoNome = OPERACAO.NOME.TRANSFERENCIA_CONTA_DEPARTAMENTO_CONTA_MOVIMENTO;
        }

        if (dadosTransferencia.contaDestinoDescricao === DESCRICAO_CONTA.CONTA_DEPARTAMENTO) {
            operacaoNome = OPERACAO.NOME.TRANSFERENCIA_CONTA_DEPARTAMENTO_CONTA_DEPARTAMENTO;
        }
    }

    return operacaoNome;
};

export function montaRequestBodyPostOperacaoTransacao(token, dataAgendamento) {
    if (dataAgendamento) {
        return JSON.stringify({
            transacao: 'agendamento',
            dataAgendamento: formatarDataUS(dataAgendamento),
            token
        });
    }

    if (token) {
        return JSON.stringify({
            transacao: 'aprovacao',
            token
        });
    }

    return JSON.stringify({
        transacao: 'aprovacao'
    });
}

export function montaDadosFavorecidoJSonOBackEnd(dadosFavorecido) {
    let dadosNormalizados = {
        tipoFavorecido: dadosFavorecido.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.CONTAS_BPP.value ? TIPO_FAVORECIDO.CONTA_INTERNA.value : TIPO_FAVORECIDO.CONTA_EXTERNA.value,
        nome: dadosFavorecido.nome,
        tipoDocumento: dadosFavorecido.tipoDocumento,
        numeroDocumento: removeMascaraTelefoneCpfCnpj(dadosFavorecido.numeroDocumento),
        banco: dadosFavorecido.bancoSelecionadoId,
        tipoConta: dadosFavorecido.tipoConta,
        agencia: dadosFavorecido.agencia,
        conta: dadosFavorecido.numeroConta,
        digito: dadosFavorecido.digito,
        telefone: dadosFavorecido.telefone ? DDI.BRASIL + removeMascaraTelefoneCpfCnpj(dadosFavorecido.telefone) : null,
        email: dadosFavorecido.email ? dadosFavorecido.email : null
    };
    dadosNormalizados = JSON.stringify(dadosNormalizados);
    return dadosNormalizados;
}

export const retornaErrosDaSolicitacaoDeTransferenciaBancaria = (erros) => {
    let camposDeErros = {};
    for (let i in erros) {
        switch (erros[i].campo) {
            case CAMPOS.DATA_AGENDAMENTO:
                camposDeErros.msgDataAgendadaErro = erros[i].mensagem;
                break;
            case CAMPOS.NUMERO_CONTA:
                camposDeErros.numeroContaErro = erros[i].mensagem;
                break;
                // Default foi deixado pra implementações futuras.
            default:
                const { mensagem } = erros[i];
                if (mensagem.indexOf('Token') !== -1) {
                    camposDeErros.tokenIdErro = mensagem;
                }
                if (mensagem.indexOf('Horário') !== -1) {
                    camposDeErros.horarioErro = mensagem;
                }
                break;
        }
    }
    return camposDeErros;
};

export const validaErrosTransferenciaBancaria = (dadosForm, transferenciaSelecionadaId) => {
    const erros = {};

    if (dadosForm.agencia === dadosForm.contaSelecionada.agencia &&
        dadosForm.numeroConta === dadosForm.contaSelecionada.numero &&
        dadosForm.digito === dadosForm.contaSelecionada.digito) {
        erros.mensagemErroMesmaConta = MENSAGENS.VALIDACAO_DE_CAMPOS.TRANSFERENCIA_BANCARIA.MESMO_FAVORECIDO_ERRO;
    }

    if (!dadosForm.nome) {
        erros.nomeErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
    }

    if (dadosForm.nome.length > 150) {
        erros.nomeErro = MENSAGENS.VALIDACAO_DE_CAMPOS.LIMITE_CARACTERE_EXCEDIDO;
    }

    if (!dadosForm.agencia) {
        erros.agenciaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
    }

    if (!dadosForm.numeroConta) {
        erros.numeroContaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
    }

    if (!dadosForm.digito) {
        erros.digitoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
    }

    if (!dadosForm.valorDaTransferencia) {
        erros.valorDaTransferenciaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
    }

    if (!dadosForm.hoje) {
        if (!dadosForm.dataAgendamento) {
            erros.dataAgendamentoObrigatoriaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.INFORME_A_DATA_PARA_TRANSFERENCIA;
        } else if (dataInvalida(dadosForm.dataAgendamento)) {
            erros.dataAgendamentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_INVALIDA;
        } else if (!dataMaiorQueHoje(dadosForm.dataAgendamento)) {
            erros.dataAgendamentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_DEVE_SER_MAIOR_QUE_A_DATA_ATUAL('de agendamento');
        } else if (validaDataMaiorQueUmAno(dadosForm.dataAgendamento)) {
            erros.dataAgendamentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_MAIOR_QUE_UM_ANO('do agendamento');
        }
    }

    if (dadosForm.hoje) {
        if (dadosForm.valorDaTransferencia > dadosForm.saldo) {
            erros.valorDaTransferenciaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.TRANSFERENCIA_BANCARIA.SALDO_INSUFICIENTE;
        }

        if (dadosForm.valorDaTransferencia <= 0) {
            erros.valorDaTransferenciaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.TRANSFERENCIA_BANCARIA.VALOR_INVALIDO;
        }
    }

    if (dadosForm.transferenciaSelecionadaId === TIPO_TRANSFERENCIA_BANCARIA.OUTROS_BANCOS.value) {
        if (dadosForm.tipoDocumento === TIPO_DOCUMENTO.CPF.value) {
            if (!CPF.isValid(dadosForm.numeroDocumento)) {
                erros.numeroDocumentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(TIPO_DOCUMENTO.CPF.value);
            }
        }

        if (dadosForm.tipoDocumento === TIPO_DOCUMENTO.CNPJ.value) {
            if (!CNPJ.isValid(dadosForm.numeroDocumento)) {
                erros.numeroDocumentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(TIPO_DOCUMENTO.CNPJ.value);
            }
        }

        if (!dadosForm.bancoSelecionadoId) {
            erros.bancoSelecionadoIdErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!dadosForm.numeroDocumento) {
            erros.numeroDocumentoErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!dadosForm.tipoConta) {
            erros.tipoContaErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }
    }
    return erros;
};

export const retornaCorpoParaBuscaDeContaPorNumeroEDigito = (dadosConta) => {
    return { numero: dadosConta.numeroConta, digito: dadosConta.digito };
};

export const retornaCorpoDeContasParaValidarTransferencia = (contaOrigemId, contaDestinoId) => {
    return JSON.stringify({ contaOrigemId, contaDestinoId });
};

export const retornaCorpoParaEfetivarTransacao = (dadosForm) => {
    let corpoOperacao = { canalToken: CANAL_TOKEN.SMS, transacao: dadosForm.dataAgendamento ? 'agendamento' : 'aprovacao' };
    return corpoOperacao;
};

export const retornaJsonParaTransferenciaBancariaComContaDestino = (dadosTransferencia, contaDestino) => {
    if (contaDestino) {
        dadosTransferencia.contaDestinoId = contaDestino.id;
        dadosTransferencia.contaDestinoDescricao = contaDestino.descricao;
        dadosTransferencia.transferenciaDeMesmaTitularidade = dadosTransferencia.contaSelecionada.pessoaTitularId === contaDestino.pessoaTitularId;
        dadosTransferencia.contaDestino = contaDestino;
    }
    return dadosTransferencia;
};
