import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { environment } from '../../configuracoes/environment';
import logoMenu from '../../imagens/logoMenuT.PNG';
import logoMenuInterno from '../../imagens/bpp-logo.png';
import './menu.css';
import { slide as MenuExibicao } from 'react-burger-menu';

class Menu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        };
    }

  handleStateChange = (state) => {
      this.setState({ menuOpen: state.isOpen });
  }

  closeMenu = () => {
      this.setState({ menuOpen: false });
  }

  render() {
      const contextBppPag = this.props.contextBppPag || this.props.url.indexOf('bpp-pag') !== -1;
      return (
          <div className={contextBppPag ? 'fundo-verde-claro' : 'fundo-azul-escuro'}>
              <div>
                  <div className="menu" style={contextBppPag ? { backgroundColor: '#274d13' } : {}}>
                      <img id="logo-menu" src={logoMenu} alt="BPP Web" />
                      <MenuExibicao menuClassName={contextBppPag ? 'fundo-verde-escuro' : 'fundo-azul-escuro'} isOpen={this.state.menuOpen} onStateChange={this.handleStateChange} >
                          <img id="logo-menu-interno" src={logoMenuInterno} alt="BPP Web" />
                          {
                this.props.menu && this.props.menu.map((menu, index, array) => {
                  return (
                      <div key={`menu_${index}`} style={{ display: 'flex', flexDirection: 'column' }}>
                          {menu.title && <b>{menu.title}</b>}
                          {
                        menu.links.map(({ to, label, url, onClick, target, external }, index) => {
                          return external
                            ? <a key={index} className="menu-item" style={{ cursor: 'pointer' }} href={external} target="_blank">{label}</a>
                            : to
                              ? <Link key={`${index}+ ${label} +${url} `} className="menu-item" onClick={this.closeMenu} to={to} target={target}>{label}</Link>
                              : <a key={`${index}+ ${label} +${url}`} className="menu-item" style={{ cursor: 'pointer' }} onClick={() => { onClick(); this.closeMenu(); }}>{label}</a>;
                        })
                      }
                          {index !== array.length - 1 && <hr />}
                      </div>
                  );
                })
              }
                          <div id="versao-interno"><p>Versão&nbsp;{environment.versao}</p></div>
                      </MenuExibicao>
                      <div id="versao"><p>Versão<br />&nbsp;{environment.versao}</p></div>
                  </div>
              </div>
          </div >
      );
  }
}

export default Menu;