import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { retornaErrosDaSolicitacaoDeTransferenciaBancaria } from '../../componentes/transferencia/transferencia-bancaria-util';
import { montarContas } from '../../servicos/contas/conta-service';

const ESTADO_INICIAL = {
    contaRecuperada: {},
    contaSelecionada: undefined,
    erroNaBuscaDaContaPorId: '',
    listaDeBancos: [],
    buscaBancosErro: '',
    listaDeFavorecidos: [],
    buscaFavorecidosErro: '',
    exibirLoadingConta: false,
    erroNaBuscaDaContaPorNumeroEDigito: '',
    exibirModalTokenTransferenciaBancaria: false,
    solicitacaoDeTransferenciaBancariaRealizada: { sucesso: '', erro: '' },
    solicitacaoTokenTransferenciaBancaria: { sucesso: '', erro: '' },
    responseSalvarFavorecido: { erro: '', sucesso: '' },
    contasRecuperadas: [],
    criacaoContaRealizada: { sucesso: '', erro: '' },
    inserirPoderes: { sucesso: '', erro: '' },
    listaDeAutorizados: [],
    edicaoDaConta: undefined,
    horarioPermitidoTed:true
};

export default (state = ESTADO_INICIAL, action) => {
    let mensagemDeErro;
    let horarioPermitidoTed;
    let erros;
    switch (action.type) {
        case METODOS.GET_BANCOS:
            return { ...state, listaDeBancos: action.payload };
        case METODOS.GET_BANCOS_ERRO:
            return { ...state, buscaBancosErro: action.payload };
        case METODOS.GET_FAVORECIDOS:
            return { ...state, listaDeFavorecidos: action.payload };
        case METODOS.GET_FAVORECIDOS_ERRO:
            return { ...state, buscaFavorecidosErro: action.payload };
        case METODOS.GET_CONTA_POR_ID:
            return { ...state, contaRecuperada: action.payload };
        case METODOS.GET_CONTA_POR_ID_ERRO:
            return { ...state, erroNaBuscaDaContaPorId: action.payload };
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload, msg: '' };
        case METODOS.CONTA.SET_EXIBIR_LOADING_CONTA:
            return { ...state, exibirLoadingConta: action.payload, edicaoDaConta: undefined };
        case METODOS.CONTA.GET_CONTA_POR_NUMERO_E_DIGITO:
            return { ...state, contaRecuperada: action.payload };
        case METODOS.CONTA.GET_CONTA_POR_NUMERO_E_DIGITO_ERRO:
            return { ...state, erroNaBuscaDaContaPorNumeroEDigito: action.payload };
        case METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA:
            return { ...state, transferenciaBancariaOperacaoId: action.payload.operacaoId, necessitaOtp: action.payload.necessitaOtp };
        case METODOS.CONTA.SOLICITAR_TOKEN_TRANSFERENCIA_BANCARIA_ERRO:
            mensagemDeErro = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            return { ...state, solicitacaoTokenTransferenciaBancaria: { erro: { mensagem: mensagemDeErro } } };
        case METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA:
            return { ...state, msg: action.payload, solicitacaoDeTransferenciaBancariaRealizada: { sucesso: true } };
        case METODOS.CONTA.SOLICITAR_TRANSFERENCIA_BANCARIA_ERRO:
            erros = action.payload && action.payload.erros;
            return { ...state, solicitacaoDeTransferenciaBancariaRealizada: { erro: true, erros: retornaErrosDaSolicitacaoDeTransferenciaBancaria(erros) } };
        case METODOS.CONTA.SALVAR_FAVORECIDO_CONTA:
            return { ...state, responseSalvarFavorecido: { sucesso: true } };
        case METODOS.CONTA.SALVAR_FAVORECIDO_CONTA_ERRO:
            mensagemDeErro = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            return { ...state, responseSalvarFavorecido: { erro: mensagemDeErro } };
        case METODOS.CONTA.GET_CONTAS:            
            //  const { contasQueSouAdmin, contaQueSouTitular } = action.payload
            // return { ...state, contasRecuperadas: [...contaQueSouTitular], refreshContas: false }
            return { ...state, contasRecuperadas: montarContas(action.payload.contasRecuperadas, action.payload.pessoaLogada), refreshContas: false };
        case METODOS.CONTA.GET_CONTAS_ERRO:
            return { ...state, erroNaBuscaDeContas: action.payload, refreshContas: false };
        case METODOS.CONTA.CRIAR_CONTA:
            return { ...state, criacaoContaRealizada: { sucesso: true } };
        case METODOS.CONTA.CRIAR_CONTA_ERRO:
            mensagemDeErro = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            return { ...state, criacaoContaRealizada: { erro: true, mensagem: mensagemDeErro } };
        case METODOS.CONTA.REFRESH_CONTAS:
            return { ...state, refreshContas: action.payload };
        case METODOS.CONTA.SALVAR_EDICAO_DE_CONTA:
            return { ...state, edicaoDaConta: { sucesso: true } };
        case METODOS.CONTA.SALVAR_EDICAO_DE_CONTA_ERRO:
            return { ...state, edicaoDaConta: { erro: true } };
        case METODOS.CONTA.SALVAR_AVATAR:
            return { ...state };
        case METODOS.CONTA.SALVAR_AVATAR_ERRO:
            return { ...state };
        case METODOS.CONTA.INSERE_AUTORIZADO_E_PODERES:
            const { id } = action.payload;
            return { ...state, inserirPoderes: { sucesso: true, outorgaId: id } };
        case METODOS.CONTA.INSERE_AUTORIZADO_E_PODERES_ERRO:
            return { ...state, inserirPoderes: { erro: true, mensagem: action.payload.erros[0].mensagem, outorgaId: null } };
        case METODOS.CONTA.GET_AUTORIZADOS_CONTA:
            return { ...state, listaDeAutorizados: action.payload || [] };
        case METODOS.CONTA.GET_AUTORIZADOS_CONTA_ERRO:
            return { ...state, listaDeAutorizados: [], ...action.payload };
        case METODOS.TRANSFERENCIA_BANCARIA.GET_HORARIO_PERMITIDO_FAZER_TED:
            horarioPermitidoTed = action.payload && action.payload.valid;       
            return { ...state, horarioPermitidoTed};
        case METODOS.TRANSFERENCIA_BANCARIA.GET_HORARIO_PERMITIDO_FAZER_TED_ERRO:
            return { ...state, ...action.payload };
        case METODOS.TRANSFERENCIA_BANCARIA.SET_HORARIO_PERMITIDO_FAZER_TED:
            return {...state, horarioPermitidoTed : action.payload };
        default:
            return state;
    }
};

export const getContaSelecionada = (state) => (state.contaSelecionada);

export const getExibirLoadingConta = (state) => ({ exibirLoadingConta: state.exibirLoadingConta });

export const getInserirPoderesConta = (state) => ({ inserirPoderes: state.inserirPoderes });

export const getFavorecidosConta = (state) => (state.listaDeFavorecidos);

// export const getAtualizarPoderesConta = (state) => ({ atualizarPoderes: state.atualizarPoderes });
