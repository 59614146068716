import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import { limpaDadosLogin } from '../../action/login/login-action';
import { handleLocalStorage } from '../../servicos/login/login-service';

class Sair extends Component {
    voltaLogin() {
        handleLocalStorage();
        this.props.limpaDadosLogin();
        window.location.href = '/';
    }

    render() {
        return (
            <div>
                {this.voltaLogin()}
            </div>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({ limpaDadosLogin }, dispatch);

export default connect(null, mapDispatchToProps)(Sair);
