export default class Controle {
    static getFavorecidosControle() {
        return 'GET_FAVORECIDOS_CONTROLE';
    }
    static getStatusShowMsgControle() {
        return 'SET_STATUS_SHOW_SMG';
    }
    static getStatusDispararTodasSolicitacoesControle() {
        return 'SET_STATUS_DISPARAR_SOLICITACOES';
    }
}
