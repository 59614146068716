import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos } from '../url-servicos';
import { environment } from '../../configuracoes/environment';

export const abreOperacao = (corpoDaRequisicao) => {
    return fetch(montaUrlFetch(urlServicos.OPERACAO), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(corpoDaRequisicao)
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

// TODO: os requisitantes dessa chamada só podem executálos, quando tiver permissão de podeAprovar (outorga-negocio)
export const efetivaTransacao = (operacaoId, corpoDaRequisicao, token) => {
    return fetch(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(operacaoId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify({
            ...corpoDaRequisicao,
            token
        })
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const solicitacaoToken = (operacaoId, dadosOtp) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), dadosOtp), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const solicitacaoTrocaSenha = (cpf,token, tokenTelefone, password,passwordNow,telefone)=>{
   

    return fetch(environment.urlAPIPass
        , {
            method: 'POST',
            body: JSON.stringify({ cpf: cpf, token: token , body: { 'senhaAtual': password, 'novaSenha': passwordNow, 'token': tokenTelefone, 'telefone': telefone } })
        })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};
