import React, { Component } from 'react';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { LABEL_COMUNS, MENSAGENS, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import { ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { STATUS_VALIDACAO_COMPONENTES } from 'componentes/util/estilo_para_componentes/estiloUtil';
import ShowLineError from 'componentes/comuns/showLineError/show-line-error';
import { changePasswordCard } from 'servicos/cartoes/cartoes-service';
import Token from 'componentes/comuns/token/token';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import If from 'componentes/componentes-uteis/if/if';

class AlterarSenhaCartao extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            cardId: '', canApprove: {}, selectedCard: {}, destinyAccountId: '', message: '', needToken: false, operationId: '',
            originalContractualAttachmentId: '', password: '', passwordConfirm: '', passwordErro: '',
            passwordConfirmError: '', showLoading: false, showModalConfirmation: false, showModalFinalization: false,
            showModalToken: false,
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillReceiveProps = (nextProps) => {
        const { selectedCard, grantProfile } = nextProps;
        const destinyAccountId = selectedCard.cartaoId;
        const cardId = selectedCard.idCartaoImpresso;
        const originalContractualAttachmentId = selectedCard.anexoContratualOriginal;
        const needToken = nextProps.needToken && nextProps.needToken.aprovacao;
        const canApprove = grantProfile.podeAprovar;
        this.setState({ cardId, canApprove, selectedCard, destinyAccountId, originalContractualAttachmentId, needToken });
    }

    setCardId = (cardId) => {
        this.setState({ cardId });
    }

    setOriginalContractualAttachmentId = (originalContractualAttachmentId) => {
        this.setState({ originalContractualAttachmentId });
    }

    setCanApprove = (canApprove) => {
        this.setState({ canApprove });
    }

    setNeedToken = (needToken) => {
        this.setState({ needToken });
    }

    setSelectedCard = (selectedCard) => {
        this.setState({ selectedCard }, () => {
            this.setOriginalContractualAttachmentId(selectedCard.anexoContratualOriginalId);
        });
    }

    setStateClear = () => {
        this.setState(this.INITIAL_STATE);
    }

    setShowModalConfirmation = (showModalConfirmation) => {
        this.setState({ showModalConfirmation });
    }

    setShowModalFinalization = (showModalFinalization) => {
        this.setState({ showModalFinalization });
    }

    setShowModalToken = (showModalToken) => {
        this.setState({ showModalToken });
    }

    setOperationId = (operationId) => {
        this.setState({ operationId });
    }

    setMessage = (message) => {
        this.setState({ message });
    }

    setShowLoading = (showLoading) => {
        this.setState({ showLoading });
    }

    solicitarAlteracaoDeSenha = async () => {
        const { canApprove, needToken } = this.state;
        let message = '';
        this.setShowModalConfirmation(false);
        this.setMessage('');
        this.setShowLoading(true);
        const response = await changePasswordCard(this.state);
        if (response.error) {
            const { data: { erros } } = response;
            message = erros[0].mensagem;
        } else {
            if (response.operationId) {
                const { operationId } = response;
                if (needToken && canApprove) {
                    this.setOperationId(operationId);
                    this.setShowModalToken(true);
                }
            } else {
                message = response.message;
                this.setMessage(message);
            }
        }
        this.setShowLoading(false);
        if (message) {
            this.setMessageFinalization(message);
            this.setShowModalFinalization(true);
        }

    }

    validForm = () => {
        const { selectedCard, password, passwordConfirm } = this.state;

        let passwordErro = undefined;
        let passwordConfirmError = undefined;

        if (!password) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!passwordErro && (password.length < 4 || password.length > 4)) {
            passwordErro = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.SENHA_INVALIDA;
        }
        if (!passwordConfirm) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_OBRIGATORIO;
        }

        if (!passwordConfirmError && (passwordConfirm.length < 4 || passwordConfirm.length > 4)) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;
        }
        if ((password && passwordConfirm) && (password !== passwordConfirm)) {
            passwordConfirmError = MENSAGENS.VALIDACAO_DE_CAMPOS.SENHA.CONFIRMACAO_SENHA_INVALIDA;

        }
        if (passwordErro || passwordConfirmError) {
            if (passwordErro) {
                this.setState({ passwordErro });
            }
            if (passwordConfirmError) {
                this.setState({ passwordConfirmError });
            }
        } else {
            this.setShowModalConfirmation(true);
            this.setMessage(MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.CONFIRMACAO(selectedCard.idCartaoImpresso));
        }
    }

    changeOnlyNumbersPassword(field) {
        const re = /^[0-9\b]+$/;
        if (field === '' || re.test(field)) {
            this.setState({
                password: field,
                passwordErro: ''
            });
        }
    }

    changeOnlyNumbersConfirmPassword(field) {
        const re = /^[0-9\b]+$/;
        if (field === '' || re.test(field)) {
            this.setState({
                passwordConfirm: field,
                passwordConfirmError: ''
            });
        }
    }


    setPropsCampos = (field, nameField) => {
        let valorDoCampo = field != null && field.value || field != null && field.target != null && field.target.value;
        this.setState({
            [nameField]: valorDoCampo,
            [nameField + 'Erro']: '',
        });
    }

    returnBodyModal = () => {
        const { cardId, selectedCard, password, passwordConfirm, passwordErro, passwordConfirmError } = this.state;
        return (
            <div className="panel-body">

                <div className="row">
                    <Grid cols="2 2 2 2" />
                    <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                        <span>
                            {LABEL_COMUNS.ID_CARTAO} - <strong>{cardId}</strong>
                        </span>
                    </Grid>
                    <Grid cols="5 5 5 5" className="alinhamento-esquerda">
                        <span>
                            {LABEL_COMUNS.NUMERO} - <strong>{selectedCard && selectedCard.panFormatado}</strong>
                        </span>
                    </Grid>
                    <Grid cols="2 2 2 2" />
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className="row">
                    <Grid cols="2 2 2 2" />
                    <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                        <ControlLabel bsClass="alterar-texto-campo">{LABEL_COMUNS.SENHA}*</ControlLabel>
                    </Grid>
                    <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                        <FormGroup validationState={passwordErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                id="password"
                                type="password"
                                className="mensagens-erro form-control changePassword"
                                placeholder={LABEL_COMUNS.SENHA}
                                value={password}
                                onChange={(e) => this.changeOnlyNumbersPassword(e.target.value)}
                                maxLength={4}
                            />
                            <ShowLineError error={passwordErro} />
                        </FormGroup>
                    </Grid>
                </div>
                <div className="row">
                    <Grid cols="2 2 2 2" />
                    <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                        <ControlLabel bsClass="alterar-texto-campo">{LABEL_COMUNS.CONFIRME_A_SENHA}*</ControlLabel>
                    </Grid>
                    <Grid cols="4 4 4 4" className="alinhamento-esquerda">
                        <FormGroup validationState={passwordConfirmError ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                            <FormControl
                                id="passwordConfirm"
                                type="password"
                                className="mensagens-erro form-control changePassword"
                                placeholder={LABEL_COMUNS.CONFIRME_A_SENHA}
                                value={passwordConfirm}
                                onChange={(e) => this.changeOnlyNumbersConfirmPassword(e.target.value)}
                                maxLength={4}
                            />
                            <ShowLineError error={passwordConfirmError} />
                        </FormGroup>
                    </Grid>

                </div>
            </div >
        );
    }

    returnFooterModal = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.props.setShowModalAlterarSenhaCartao(false);
                    this.setStateClear();
                }}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn2OnClick={this.validForm}
            />
        );
    }

    returnFooterModalConfirmation = () => {
        return (
            <FooterBotoesModalComponent
                btn1Label={LABEL_COMUNS.CANCELAR}
                btn1OnClick={() => {
                    this.setShowModalConfirmation(false);
                }}
                btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn2OnClick={() => {
                    this.solicitarAlteracaoDeSenha();
                }}
            />
        );
    }

    returnFooterModalFinalization = () => {
        return (
            <FooterBotoesModalComponent
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setShowModalFinalization(false);
                    this.setStateClear();
                    this.props.setShowModalAlterarSenhaCartao(false);
                    this.props.reloadCards();
                }}
            />
        );
    }

    retornoDaEfetivacao = (retorno) => {
        this.setShowModalToken(false);
        const { cardId, canApprove } = this.state;
        let message = undefined;
        if (retorno.error) {
            message = MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.FINALIZACAO.ERRO(cardId, canApprove);
        } else {
            message = MENSAGENS.CARTAO.ALTERAR_PIN_CARTAO.FINALIZACAO.SUCESSO(cardId, canApprove);
            this.props.setShowModalAlterarSenhaCartao(false);
        }
        this.setMessageFinalization(message);
    }

    closeModal = () => {
        this.setShowModalToken(false);
    }

    setMessageFinalization(message) {
        this.setMessage(message);
        this.setShowModalFinalization(true);

    }

    render() {
        const { operationId, showModalToken, showLoading } = this.state;
        return (
            <div>
                <If test={showLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>

                <Token
                    titulo={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                    operacaoId={operationId}
                    dadosIntegracao={TIPO_DE_TRANSACAO.APROVACAO}
                    dadosOtp={{ canalToken: 'SMS', transacao: 'aprovacao' }}
                    fecharModal={this.closeModal}
                    resultadoCallback={this.retornoDaEfetivacao}
                    exibirModal={showModalToken} />

                <ModalComponent
                    header={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                    exibirModal={this.props.showModalAlterarSenhaCartao}
                    name="modal-alterar-senha-cartao"
                    body={this.returnBodyModal()}
                    footer={this.returnFooterModal()}
                />

                <ModalComponent
                    header={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                    exibirModal={this.state.showModalConfirmation}
                    name="modal-alterar-senha-cartao"
                    body={<div dangerouslySetInnerHTML={{ __html: this.state.message }} />}
                    footer={this.returnFooterModalConfirmation()}
                />

                <ModalComponent
                    header={LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                    exibirModal={this.state.showModalFinalization}
                    name="modal-alterar-senha-cartao"
                    body={<div dangerouslySetInnerHTML={{ __html: this.state.message }} />}
                    footer={this.returnFooterModalFinalization()}
                />

            </div>
        );
    }
}

export default AlterarSenhaCartao;
