import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Grid from '../../../componentes-uteis/grid/grid';
import PanelAcoes from '../../../componentes-uteis/panel-acoes/panel-acoes';
import MostrarSaldoESaldoBloqueado from '../../../contas/saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { CONTA_SELECIONADA, LABEL_COMUNS } from '../../../util/contantes-comuns/constantes-comuns';
import ListaCartoesDashboardBppConsumer from './cartoes/lista-cartoes-dashboard-bpp-consumer';
import ConsultaDePedidosDashboardBppConsumer from '../../dashboard/bpp-consumer/consulta-de-pedidos/consulta-de-pedidos-dashboard-bpp-consumer';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';

class DashboardBppConsumer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: CONTA_SELECIONADA.OBJETO()
        };
    }

    render() {
        const { contaSelecionada } = this.state;
        const defaultStyle = ESTILO.COR_DE_FUNDO.TITULO.PADRAO;

        return (
            <div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <MostrarSaldoESaldoBloqueado className={defaultStyle} contaSelecionada={contaSelecionada} exibirSaldo mostrarSaldoBloqueado podeConsultar />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <ListaCartoesDashboardBppConsumer contaSelecionada={contaSelecionada} />
                                </div>
                            </div>
                        </div>

                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <PanelAcoes
                                        className={defaultStyle}
                                        titulo={LABEL_COMUNS.ACOES_DO_PRODUTO}
                                        labelBotao1={LABEL_COMUNS.CARTOES.TITULOS.PEDIR_CARTAO}
                                        btn1Link={ROTAS_CAMINHOS.PEDIR_CARTAO_BPP_CONSUMER}
                                        labelBotao2={LABEL_COMUNS.CARTOES.TITULOS.VINCULAR_CARTAO}
                                        btn2Link={ROTAS_CAMINHOS.VINCULAR_CARTAO_BPP_CONSUMER}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12" style={{ padding: 0 }}>
                                    <ConsultaDePedidosDashboardBppConsumer conta={contaSelecionada} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <Grid cols="12 12 12 12" className="alinhamento-direita">
                        <div>
                            <Grid className="alinhamento-direita">
                                <a href={ROTAS_CAMINHOS.DASHBOARD_CONTA}><Button type="button" bsStyle="primary">{LABEL_COMUNS.VOLTAR}</Button></a>
                            </Grid>
                        </div>
                    </Grid>
                </div>
                <br />
            </div>
        );
    }
}

export default DashboardBppConsumer;
