
import { METODOS } from './exibicao-conta-constantes';
import { filtraCartoes } from '../../util/cartoes/cartoes-util';
import { CARTAO_STATUS } from '../../util/contantes-comuns/constantes-comuns';

const INITIAL_STATE = {
    listaDeCartoesAtivos: [],
    listaDeCartoesBloqueados: [],
    listaDeCartoesProntosParaAtivacao: [],
    listaDeCartoesCanceladosEOutros: [],
    exibirLoading: true
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case METODOS.GET_CARTOES:
            return {
                ...state,
                listaDeCartoesAtivos: filtraCartoes(action.payload.registros, CARTAO_STATUS.ATIVO),
                listaDeCartoesBloqueados: filtraCartoes(action.payload.registros, CARTAO_STATUS.BLOQUEADO),
                listaDeCartoesProntosParaAtivacao: filtraCartoes(action.payload.registros, CARTAO_STATUS.PRONTOS_PARA_ATIVACAO),
                listaDeCartoesCanceladosEOutros: filtraCartoes(action.payload.registros, CARTAO_STATUS.CANCELADOS_E_OUTROS),
                exibirLoading: false
            };
        case METODOS.GET_CARTOES_ERRO:
            return {
                ...state,
                exibirLoading: false
            };
        case METODOS.SET_EXIBIR_LOADING:
            return {
                ...state,
                exibirLoading: action.payload
            };
        default:
            return state;
    }
};
