import { COMBO, METODOS, OPERACAO } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { preencheCamposDeErros } from '../../servicos/pedidos/pedido-service';

const INITIAL_STATE = {
    dataInicial: '',
    dataInicialErro: '',
    dataFinal: '',
    dataFinalErro: '',
    listaDeAnexosContratuais: [],
    anexoContratualSelecionadoId: '',
    listaDeOperacoes: [],
    operacaoSelecionadaId: '',
    listaDePedidos: [],
    quantidadeTotalRegistros: 0,
    tamanhoPagina: 0,
    totalElementos: 0,
    exbirMaisRegistros: false,
    ultimaChave: '',
    contaSelecionada: '',
    valorDe: 0,
    valorDeErro: '',
    valorAte: 0,
    valorAteErro: '',
    exibirModalToken: false,
    statusSelecionado: '',
    listaDeStatus: COMBO.STATUS.OPERACAO.filter(status => (status.value !== OPERACAO.STATUS.AGENDADO)),
    exibirLoadingDashboardConsultaPedido: undefined,
    urlImagem: {},
    exibirLoadingPedidoCartaoImpresso: false,
    solicitacaoDePedidoEmbossingCartao: {}
};

export default (state = INITIAL_STATE, action) => {
    let mensagemDeErro;
    switch (action.type) {
        case METODOS.SET_CONTA_SELECIONADA:
            return { ...state, contaSelecionada: action.payload };
        case METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_DATA_INICIAL:
            return { ...state, dataInicial: action.payload, dataInicialErro: '' };
        case METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_DATA_FINAL:
            return { ...state, dataFinal: action.payload, dataFinalErro: '' };
        case METODOS.PEDIDOS.CONSULTA.SET_ANEXO_CONTRATUAL_CONSULTA_DE_PEDIDOS:
            let anexoContratualId = action.payload !== null ? action.payload.value : '';
            return { ...state, anexoContratualSelecionadoId: anexoContratualId };
        case METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_OPERACAO:
            let operacaoId = action.payload ? action.payload.value : '';
            return { ...state, operacaoSelecionadaId: operacaoId };
        case METODOS.PEDIDOS.CONSULTA.GET_OPERACOES:
            return { ...state, listaDeOperacoes: COMBO.GET_OPERACOES(action.payload) };
        case METODOS.PEDIDOS.CONSULTA.GET_OPERACOES_ERROS:
            return { ...state, exibirLoading: false };
        case METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_PEDIDOS:
            let pedidos = action.payload.pedidos;
            let qtdeTotalDeRegistros = action.payload.quantidadeTotalRegistros;
            return {
                ...state,
                listaDePedidos: pedidos,
                dataInicialErro: '',
                dataFinalErro: '',
                ultimaChave: action.payload.ultimaChave,
                quantidadeTotalRegistros: qtdeTotalDeRegistros,
                tamanhoPagina: action.payload.tamanhoPagina,
                totalElementos: action.payload.totalElementos,
                exbirMaisRegistros: pedidos.length < qtdeTotalDeRegistros
            };
        case METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_PEDIDOS_ERRO:
            let camposDeErros = preencheCamposDeErros(action.payload.erros);
            return { ...state, dataInicialErro: camposDeErros.dataInicialErro, dataFinalErro: camposDeErros.dataFinalErro };

        case METODOS.PEDIDOS.LIMPAR_FILTRO_PEDIDOS:
            return {
                ...state,
                dataInicial: action.payload,
                dataFinal: action.payload,
                anexoContratualSelecionadoId: action.payload,
                operacaoSelecionadaId: action.payload,
                valorDe: 0,
                valorAte: 0,
                statusSelecionado: ''
            };
        case METODOS.PEDIDOS.CONSULTA.SET_FILTRO_CONSULTA_DE_PERIDOS_POR_VALOR:
            return { ...state, ...action.payload.data };

        case METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_STATUS:
            return { ...state, statusSelecionado: action.payload ? action.payload.value : '' };
        case METODOS.PEDIDOS.CONSULTA.SET_EXIBIR_LOADING_DASHBOARD_CONSULTA_PEDIDO:
            return { ...state, exibirLoadingDashboardConsultaPedido: action.payload, exibirLoading: action.payload };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID:
            let urlImagemRecuperada = {};
            urlImagemRecuperada.cartao = state.urlImagem && state.urlImagem.cartao ? state.urlImagem.cartao : action.payload.cartao && action.payload.cartao.urlPreAssinada;
            urlImagemRecuperada.cartaBerco = state.urlImagem && state.urlImagem.cartaBerco ? state.urlImagem.cartaBerco : action.payload.cartaBerco && action.payload.cartaBerco.urlPreAssinada;
            return { ...state, urlImagem: urlImagemRecuperada };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID_ERRO:
            return { ...state };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_EXIBIR_LOADING_PEDIDO_CARTAO_IMPRESSO:
            return { ...state, exibirLoadingPedidoCartaoImpresso: action.payload };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO:
            return { ...state, msg: action.message, solicitacaoDePedidoEmbossingCartao: { sucesso: true, requisicaoPedidoEmbossingId: action.payload.idRequisicao || action.payload.rel } };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO:
            mensagemDeErro = action.payload && action.payload.erros && action.payload.erros[0].mensagem;
            return { ...state, solicitacaoDePedidoEmbossingCartao: { erro: true, mensagemDeErro }, solicitacaoTokenErro: mensagemDeErro };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO_CORE:
            mensagemDeErro = action.payload;
            return { ...state, solicitacaoDePedidoEmbossingCartao: { erro: true, mensagemDeErro }, solicitacaoTokenErro: mensagemDeErro };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_REQUISICAO_PEDIDO_EMBOSSING_ID:
            return { ...state, requisicaoPedidoEmbossingId: action.payload };
        case METODOS.PEDIDOS.LIMPAR_PEDIDOS:
            return { ...state, listaDePedidos: action.payload };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_LIMPAR_SOLICITACAO_DE_PEDIDO_EMBOSSING_CARTAO:
            return { ...state, solicitacaoDePedidoEmbossingCartao: action.payload, msg: '' };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.REQUISITAR_TOKEN_MODAL:
            return { ...state, exibirModalToken: true, relId: action.payload.rel, exibirLoadingPedidoCartaoImpresso: false };
        case 'petoken':
            return { ...state, exibirModalToken: action.payload };
        case METODOS.PEDIDOS.EMBOSSING_CARTAO.RESETA_SOLICITACAO_PEDIDO_EMBOSSING_CARTAO: {
            return {...state, solicitacaoDePedidoEmbossingCartao: {}};
        }
        default:
            return state;
    }
};

export function pedidoCartaoImpressoBppCorp(state) {
    return {
        contaSelecionada: state.contaSelecionada,
        exibirLoadingPedidoCartaoImpresso: state.exibirLoadingPedidoCartaoImpresso,
        urlImagem: state.urlImagem,
        solicitacaoDePedidoEmbossingCartao: state.solicitacaoDePedidoEmbossingCartao
    };
}

export function pedidoCartaoImpressoBppPag(state) {
    return {
        contaSelecionada: state.contaSelecionada, urlImagem: state.urlImagem, solicitacaoDePedidoEmbossingCartao: state.solicitacaoDePedidoEmbossingCartao
    };
}
