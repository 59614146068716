
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { pedidoCartaoImpressoBppPag } from '../../../../reducer/pedidos/pedidos-reducer';
import Select from 'react-select';
import validator from 'email-validator';
import { CPF, CNPJ } from 'cpf_cnpj';
import InputMask from 'react-input-mask';
import { Alert, Button, ControlLabel, FormControl, FormGroup } from 'react-bootstrap';
import * as pedidosAction from '../../../../action/pedidos/pedidos-action';
import * as localidadeAction from '../../../../action/localidade/localidade-action';
import { getAnexosContratuais, getAnexoPorId } from '../../../../action/anexo-contratual/anexo-contratual-action';
import { CAMPOS, COMBO, CONTA_SELECIONADA, FORMA_ENTREGA, FORMATOS_PERMITIDOS_DE_DATA_NO_PEDIDO_EMBOSSING_CARTAO, INDICE_COLUNA_ARQUIVO, LABEL_COMUNS, MENSAGENS, TIPO_DE_ACESSO, TIPO_ENTREGA, TIPO_DE_ERRO, ENDERECO_BPP } from '../../../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES, ESTILO } from '../../../util/estilo_para_componentes/estiloUtil';
import If from '../../../componentes-uteis/if/if';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import DadosEntrega from '../../../comuns/dados-entrega/dados-entrega';
import ModalInstrucoesPedidoCartaoImpresso from '../../../comuns/pedidos/cartao-impresso/modal-instrucoes/modal-instrucoes-pedido-cartao-impresso';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import { dataInvalida, dataMenorIgualAHoje, validarDataBR } from '../../../util/data/dataUtil';
import { getAnexoParaPedidoEmbossingCartao, getAnexosCartaoBppPag } from '../../../../reducer/anexo-contratual/anexo-contratual-reducer';
import ContaSelecionada from '../../../contas/conta-selecionada';
import { retornaEnderecoBpp } from '../../../../servicos/localidade/localidade-service';
import { saldoSuficienteParaEfetuarPedidoEmbossingCartao } from '../../../../servicos/pedidos/pedido-service';
import ValidaSaldoParaPedidoEmbossingCartao from '../../../comuns/valida-saldo-para-pedido-embossing-cartao/valida-saldo-para-pedido-embossing-cartao';
import { retornaObjetoTarget } from '../../../../servicos/base/service-base';
import { fileDecodified } from '../../../util/handleCharset';
import { podeConsultar, podeCriar, podeAprovar } from '../../../../reducer/outorga/outorga-operacao-reducer';
import { environment } from 'configuracoes/environment';

export class PedidoEmbossingCartaoBppPag extends Component {

    constructor(props) {
        super(props);
        const stateFromRoute = props.location.state && { ...props.location.state, redirecionarParaTelaResumo: false };
        this.state = {
            anexoSelecionado: '', anexoSelecionadoId: '', anexoSelecionadoIdErro: '', comboFelSelecionado: '', comboFelIdSelecionado: '', quantidadeCartoes: '', tipoEntregaSelecionada: '', formaEntregaSelecionada: '',
            paisSelecionadoId: '', quantidadeAcima: false, cep: '', nomeDaCidadeSelecionada: '', siglaDoEstadoSelecionado: '', bairro: '', logradouro: '', numero: '', complemento: '', pontoReferencia: '',
            destinatario: '', aosCuidados: '', exibirModalInstrucao: false, arquivoSelecionado: undefined, dadosArquivoSelecionado: [], desabilitarBotaoConfirmar: true,
            redirecionarParaTelaResumo: false, rows: [], numeroDeCartoesIgualNumeroDeLinhasError: false, tipoAcesso: TIPO_DE_ACESSO.SELECIONADO(),
            exibirModal: false, mensagensErros: [], ...stateFromRoute
        };
    }

    fileReader;

    getUrlImagens = (comboFelSelecionado) => {
        this.props.getUrlPreAssinadaCartaoOuCartaBercoPorArquivoId(comboFelSelecionado.imagemCartao, true);
        this.props.getUrlPreAssinadaCartaoOuCartaBercoPorArquivoId(comboFelSelecionado.imagemCartaBerco);
    }

    componentDidMount() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.setContaSelecionada(contaSelecionada);
        this.setState({ contaSelecionada });
        this.state.anexoSelecionadoId && this.props.getAnexoPorId(this.state.anexoSelecionadoId);
        this.state.comboFelSelecionado && this.getUrlImagens(this.state.comboFelSelecionado);
        this.props.getPaises();
        this.state.cep && this.props.getLocalidadePorCep(this.state.cep);
        this.props.setRequisicaoPedidoEmbossingId(undefined);
        this.setState({ arquivoSelecionado: undefined }, () => { this.habilitaEDesabilitaBotaoConfirmar(this.state); });
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.pedido.localidade.localidadeRecuperada && nextProps.pedido.localidade.localidadeRecuperada !== this.props.pedido.localidade.localidadeRecuperada) {
            this.setLocalidade(nextProps.pedido.localidade.localidadeRecuperada);
        }
    }

    setPropsCampos = (e, nomeDoCampo) => {
        let valorDoCampo = e && e.value || e && e.target && e.target.value;
        if (nomeDoCampo === CAMPOS.ANEXO_SELECIONADO_ID) {
            if (!valorDoCampo) {
                this.setState({ anexoSelecionado: null, comboFelSelecionado: null, comboFelIdSelecionado: null });
            } else {
                this.props.getAnexoPorId(valorDoCampo);
            }
        }

        if (nomeDoCampo === CAMPOS.COMBO_FEL_ID_SELECIONADO) {
            if (!valorDoCampo) {
                this.setState({ comboFelSelecionado: null, comboFelIdSelecionado: null });
            } else {
                let comboFelSelecionado = e && e.comboFel;
                this.getUrlImagens(comboFelSelecionado);
                this.setState({ comboFelSelecionado });
            }
        }

        this.setState({
            [nomeDoCampo]: valorDoCampo,
            [nomeDoCampo + 'Erro']: '',
        }, () => {
            this.habilitaEDesabilitaBotaoConfirmar(this.state);
            if (nomeDoCampo === CAMPOS.QUANTIDADE_CARTOES) {
                this.tratarArquivoLido();
            }
        });
    }

    habilitaEDesabilitaBotaoConfirmar(campos) {
        let desabilitarBotaoConfirmar;
        if (campos.anexoSelecionadoId &&
            campos.comboFelIdSelecionado &&
            campos.quantidadeCartoes &&
            parseInt(campos.quantidadeCartoes) > 0 &&
            campos.tipoEntregaSelecionada &&
            campos.formaEntregaSelecionada
        ) {
            desabilitarBotaoConfirmar = false;
        } else {
            desabilitarBotaoConfirmar = true;
        }

        if (parseInt (campos.quantidadeCartoes) > 100) {
            desabilitarBotaoConfirmar = true;
            this.setState ({quantidadeAcima: true});
        }


        if (campos.tipoEntregaSelecionada === TIPO_ENTREGA.EM_LOTE.value
            && campos.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value) {
            if (!campos.cep
                || !campos.bairro
                || !campos.paisSelecionadoId
                || !campos.siglaDoEstadoSelecionado
                || !campos.nomeDaCidadeSelecionada
                || !campos.logradouro
                || !campos.numero
                || !campos.destinatario
                || !campos.aosCuidados) {
                desabilitarBotaoConfirmar = true;
            }
        }

        if (campos.tipoEntregaSelecionada === TIPO_ENTREGA.INDIVIDUAL.value
            && campos.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value) {
            if (campos.arquivoSelecionado) {
                desabilitarBotaoConfirmar = false;
            } else {
                desabilitarBotaoConfirmar = true;
            }
        }

        if (this.state.arquivoSelecionado && this.state.numeroDeCartoesDiferenteQueNumeroDeLinhas) {
            desabilitarBotaoConfirmar = true;
        }

        if (!campos.quantidadeCartoes || parseInt(campos.quantidadeCartoes) === 0) {
            desabilitarBotaoConfirmar = true;
        }

        let numeroDeCartoesDiferenteQueNumeroDeLinhas;
        if (this.state.arquivoSelecionado && this.state.quantidadeCartoes
            && this.state.rows.length > 0 && parseInt(this.state.quantidadeCartoes) !== this.state.rows.length) {
            numeroDeCartoesDiferenteQueNumeroDeLinhas = true;
        }
        this.setState({
            desabilitarBotaoConfirmar,
            numeroDeCartoesDiferenteQueNumeroDeLinhas
        }, () => this.setState({ mensagensErros: this.montarMensagensErros() }));
    }

    setLocalidade(localidadeRecuperada) {
        this.setState({ localidadeRecuperada });
        this.setPropsCampos({ target: { value: localidadeRecuperada.paisId } }, CAMPOS.PAIS_SELECIONADO_ID);
        this.setPropsCampos({ target: { value: localidadeRecuperada.uf } }, CAMPOS.SIGLA_DO_ESTADO_SELECIONADO);
        this.setPropsCampos({ target: { value: localidadeRecuperada.cidade } }, CAMPOS.NOME_DA_CIDADE_SELECIONADA);
        this.setPropsCampos({ target: { value: localidadeRecuperada.bairro } }, CAMPOS.BAIRRO);
        this.setPropsCampos({ target: { value: localidadeRecuperada.logradouro } }, CAMPOS.LOGRADOURO);
    }

    tratarArquivoLido = e => {
        if (this.fileReader && this.fileReader.result) {
            this.restaurarComportamentoInicialInput();
            let dadosArquivoSelecionado = [];
            const content = fileDecodified(this.fileReader.result);
            let rows = content.split('\n');

            if (rows[rows.length - 1].replace(/\s/g, '') === '') {
                rows.pop();
            }

            if (rows.length === 1) {
                this.setState({ arquivoInvalidoError: true });
            } else if (rows.length > 1001) {
                this.setState({ numeroDeLinhasDoArquivoExcedidas: true });
            }
            else {
                for (var i = 1; i < rows.length; i++) {
                    dadosArquivoSelecionado.push(rows[i]);
                }

                this.setState({ dadosArquivoSelecionado });

                if (rows[0].split(',').length > 1) {
                    rows.map((item, index, array) => {
                        array[index] = item.split(',').map((r, i, a) => a[i] = r.replace(/\n|\r/g, ''));
                    });
                } else {
                    rows.map((item, index, array) => {
                        array[index] = item.split(';').map((r, i, a) => a[i] = r.replace(/\n|\r/g, ''));
                    });
                }
                //Header
                const header = rows.shift().map((h, i, a) => a[i] = h.replace(/\n|\r/g, ''));
                this.validarArquivoParaUpload(rows, header);
            }
        }
    }

    restaurarComportamentoInicialInput = () => {
        let arquivoSelecionado, colunaError, nomeImpressoNoCartaoError, nomeImpressoNaCartaBercoError,
            cpfPortadorError, dataNascimentoError, dataError, ddiCelularPortadorError, dddCelularPortadorError,
            celularPortadorError, emailPortadorError, cepPortadorError, paisPortadorError, cidadePortadorError, ufPortadorError,
            logradouroPortadorError, bairroPortadorError, numeroPortadorError, camposDoEnderecoLoteNaoPreenchidosError, arquivoInvalidoError,
            numeroDeLinhasDoArquivoExcedidas, numeroDeCartoesDiferenteQueNumeroDeLinhas;

        this.setState({
            arquivoSelecionado, colunaError, nomeImpressoNoCartaoError, nomeImpressoNaCartaBercoError,
            cpfPortadorError, dataNascimentoError, dataError, ddiCelularPortadorError, dddCelularPortadorError,
            celularPortadorError, emailPortadorError, cepPortadorError, paisPortadorError, cidadePortadorError, ufPortadorError,
            logradouroPortadorError, bairroPortadorError, numeroPortadorError, camposDoEnderecoLoteNaoPreenchidosError,
            arquivoInvalidoError, numeroDeLinhasDoArquivoExcedidas, numeroDeCartoesDiferenteQueNumeroDeLinhas, mensagensErros: []
        }, () => {
            this.habilitaEDesabilitaBotaoConfirmar(this.state);
        });
    }

    tratarArquivoSelecionado = e => {
        let arquivoSelecionado = e.target.files[0];
        this.fileReader = new FileReader();
        this.fileReader.onloadend = this.tratarArquivoLido;

        if (arquivoSelecionado instanceof Blob) {
            this.fileReader.readAsArrayBuffer(arquivoSelecionado);
            this.arquivoSelecionado = arquivoSelecionado;
        }
    }

    handleFindRow = (rows, data) => {
        return rows.findIndex(e => e === data) + 2;
    }

    validarEndereco = (campoChave, row, index) => {
        const campoChavePreenchido = row[campoChave];
        if (campoChavePreenchido) {
            const linhaRowCampoChave = index + 2; // Começa no 0 e desconsidera o Header
            let listaCamposEnderecoNaoPreenchidos = [];
            const cepError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP];
            if (cepError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.CEP);
            }
            const paisError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS];
            if (paisError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.PAIS);
            }
            const cidadeError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE];
            if (cidadeError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.CIDADE);
            }
            const ufError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF];
            if (ufError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.UF);
            }
            const logradouroError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO];
            if (logradouroError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.LOGRADOURO);
            }
            const numeroError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO];
            if (numeroError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.NUMERO);
            }
            const bairroError = !row[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO];
            if (bairroError) {
                listaCamposEnderecoNaoPreenchidos.push(LABEL_COMUNS.BAIRRO);
            }

            if (listaCamposEnderecoNaoPreenchidos.length) {
                this.setState({
                    camposDoEnderecoLoteNaoPreenchidosError: {
                        row: linhaRowCampoChave,
                        data: listaCamposEnderecoNaoPreenchidos
                    }
                });
            }

            return !listaCamposEnderecoNaoPreenchidos.length;
        }
        return true;
    }

    montarMensagensErros = () => {
        let mensagensErros = [];
        if (this.state.arquivoInvalidoError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.ARQUIVO_INVALIDO, order: 0 });
            return mensagensErros;
        }
        if (this.state.colunaError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.LINHA_VAZIA(this.state.colunaError.row), order: 0 });
            return mensagensErros;
        }
        if (this.state.numeroDeLinhasDoArquivoExcedidas) {
            mensagensErros.push({ message: MENSAGENS.TRANSFERENCIA_EM_LOTE.NUMERO_DE_LINHAS_DO_ARQUIVO_EXCEDIDAS, order: 0 });
            return mensagensErros;
        }
        if (this.state.numeroDeCartoesDiferenteQueNumeroDeLinhas) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.NUMERO_DE_CARTOES_DIFERENTE_QUE_NUMERO_DE_LINHAS, order: 0 });
            return mensagensErros;
        }
        if (this.state.nomeImpressoNoCartaoError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.nomeImpressoNoCartaoError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO, this.state.nomeImpressoNoCartaoError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO], this.state.nomeImpressoNoCartaoError.erroData), order: this.state.nomeImpressoNoCartaoError.row });
        }
        if (this.state.nomeImpressoNaCartaBercoError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.nomeImpressoNaCartaBercoError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.NOME_IMPRESSO_NA_CARTA_BERCO, this.state.nomeImpressoNaCartaBercoError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTA_BERCO], this.state.nomeImpressoNaCartaBercoError.erroData), order: this.state.nomeImpressoNaCartaBercoError.row });
        }
        if (this.state.cpfPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.cpfPortadorError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.CPF_PORTADOR, this.state.cpfPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR], this.state.cpfPortadorError.erroData), order: this.state.cpfPortadorError.row });
        }
        if (this.state.dataNascimentoError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.dataNascimentoError.row, LABEL_COMUNS.DATA_NASCIMENTO, this.state.dataNascimentoError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR], this.state.dataNascimentoError.erroData), order: this.state.dataNascimentoError.row });
        }
        if (this.state.dataError) {
            mensagensErros.push({ message: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_DEVE_SER_IGUAL_OU_MENOR_QUE_A_DATA_ATUAL(this.state.dataError.row, this.state.dataError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]), order: this.state.dataError.row });
        }
        if (this.state.ddiCelularPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.ddiCelularPortadorError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.DDI_PORTADOR, this.state.ddiCelularPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR], this.state.ddiCelularPortadorError.erroData), order: this.state.ddiCelularPortadorError.row });
        }
        if (this.state.dddCelularPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.dddCelularPortadorError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.DDD_PORTADOR, this.state.dddCelularPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR], this.state.dddCelularPortadorError.erroData), order: this.state.dddCelularPortadorError.row });
        }
        if (this.state.celularPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.celularPortadorError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.CELULAR_PORTADOR, this.state.celularPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR], this.state.celularPortadorError.erroData), order: this.state.celularPortadorError.row });
        }
        if (this.state.emailPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.emailPortadorError.row, LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.EMAIL_PORTADOR, this.state.emailPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR], this.state.emailPortadorError.erroData), order: this.state.emailPortadorError.row });
        }
        if (this.state.cepPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.cepPortadorError.row, LABEL_COMUNS.CEP, this.state.cepPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP], this.state.cepPortadorError.erroData), order: this.state.cepPortadorError.row });
        }
        if (this.state.paisPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.paisPortadorError.row, LABEL_COMUNS.PAIS, this.state.paisPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS], this.state.paisPortadorError.erroData), order: this.state.paisPortadorError.row });
        }
        if (this.state.cidadePortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.cidadePortadorError.row, LABEL_COMUNS.CIDADE, this.state.cidadePortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE], this.state.cidadePortadorError.erroData), order: this.state.cidadePortadorError.row });
        }
        if (this.state.ufPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.ufPortadorError.row, LABEL_COMUNS.UF, this.state.ufPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF], this.state.ufPortadorError.erroData), order: this.state.ufPortadorError.row });
        }
        if (this.state.logradouroPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.logradouroPortadorError.row, LABEL_COMUNS.LOGRADOURO, this.state.logradouroPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO], this.state.logradouroPortadorError.erroData), order: this.state.logradouroPortadorError.row });
        }
        if (this.state.bairroPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.bairroPortadorError.row, LABEL_COMUNS.BAIRRO, this.state.bairroPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO], this.state.bairroPortadorError.erroData), order: this.state.bairroPortadorError.row });
        }
        if (this.state.numeroPortadorError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPO_INVALIDO(this.state.numeroPortadorError.row, LABEL_COMUNS.NUMERO, this.state.numeroPortadorError.data[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO], this.state.numeroPortadorError.erroData), order: this.state.numeroPortadorError.row });
        }
        if (this.state.camposDoEnderecoLoteNaoPreenchidosError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.CAMPOS_ENDERECO_LOTE_NAO_PREENCHIDOS(this.state.camposDoEnderecoLoteNaoPreenchidosError.row, this.state.camposDoEnderecoLoteNaoPreenchidosError.data), order: this.state.camposDoEnderecoLoteNaoPreenchidosError.row });
        }
        if (this.state.enderecoIndividualError) {
            mensagensErros.push({ message: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDADACAO_ARQUIVO.ENDERECO_NAO_INFORMADO(this.state.enderecoIndividualError.row, this.state.enderecoIndividualError.data), order: this.state.enderecoIndividualError.row });
        }

        mensagensErros.sort((a, b) => a.order - b.order);

        return mensagensErros;

    }

    validarArquivoParaUpload = (rows, header) => {
        let colunaError;
        const colunaErrorData = rows.find(e => e.length === 0);
        const colunasCompletas = rows.find(e => e.length > 2);
        const colunaErrorHeader = header.length !== 20;

        let cepPortadorError, paisPortadorError, cidadePortadorError, ufPortadorError, logradouroPortadorError, bairroPortadorError, numeroPortadorError,
            nomeImpressoNoCartaoError, nomeImpressoNaCartaBercoError, cpfPortadorError, dataError, dataNascimentoError, ddiCelularPortadorError,
            dddCelularPortadorError, celularPortadorError, emailPortadorError, camposDoEnderecoLoteNaoPreenchidosError, enderecoIndividualError,
            numeroDeCartoesDiferenteQueNumeroDeLinhas;

        let linhaVaziaErrorData = rows.find(e => !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTA_BERCO]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.COMPLEMENTO]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PONTO_REFERENCIA] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTA_BERCO]
            && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_CARTAO] && !e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CAMPO_LIVRE_TRILHA]);

        if (linhaVaziaErrorData) {
            let colunaError = { row: this.handleFindRow(rows, linhaVaziaErrorData), data: linhaVaziaErrorData };
            this.setState({ rows, header, colunaError });
        } else if (colunaErrorData) {
            let arquivoInvalidoDataError = colunaErrorData[0].split(',');
            if (arquivoInvalidoDataError) {
                let arquivoInvalidoError = { row: this.handleFindRow(rows, colunaErrorData), data: colunaErrorData };
                this.setState({ rows, header, arquivoInvalidoError });
            }
            else {
                colunaError = { row: this.handleFindRow(rows, colunaErrorData), data: colunaErrorData };
                this.setState({ rows, header, colunaError });
            }
        } else if (colunaErrorHeader) {
            colunaError = { row: 1, data: colunaErrorHeader };
            this.setState({ rows, header, colunaError });
        }
        else if (!colunasCompletas) {

            const nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO].length > 20);
            const nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTA_BERCO].length > 20);

            if (nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError) {
                nomeImpressoNoCartaoError = {
                    row: this.handleFindRow(rows, nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError), data: nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo Nome impresso na carta berco
            if (nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError) {
                nomeImpressoNaCartaBercoError = {
                    row: this.handleFindRow(rows, nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError), data: nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }
        }
        else {


            const nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTAO].length > 20);
            const nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NOME_IMPRESSO_CARTA_BERCO].length > 20);
            const cpfPortadorCampoInvalidoDataError = rows.find(e => !CPF.isValid(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR]));
            const dataNascimentoCampoInvalidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]
                ? !validarDataBR(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]) : undefined);
            const dataNascimentoMenorIgualQueHojeError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]
                ? !dataMenorIgualAHoje(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DATA_NASCIMENTO_PORTADOR]) : undefined);
            const ddiCelularPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR].length > 3);
            const ddiCelularPortadorCampoInvalidoDataError = rows.find(e => isNaN(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDI_CELULAR_PORTADOR]));
            const dddCelularPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR].length > 3);
            const dddCelularPortadorCampoInvalidoDataError = rows.find(e => isNaN(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.DDD_CELULAR_PORTADOR]));
            const celularPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR].length > 9);
            const celularPortadorCampoInvalidoDataError = rows.find(e => isNaN(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CELULAR_PORTADOR]));
            const emailPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR].length > 50);
            const emailPortadorCampoInvalidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR]
                ? !validator.validate(e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.EMAIL_PORTADOR]) : undefined);
            const cepPortadorCampoInvalidoDataError = rows.find(e => isNaN(!e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP]));
            const paisPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS].length > 20);
            const cidadePortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE].length > 20);
            const ufPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF].length > 2);
            const logradouroPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO].length > 20);
            const bairroPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO].length > 20);
            const numeroPortadorLimiteDeCaracterExcedidoDataError = rows.find(e => e[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO].length > 20);

            const camposParaValidar = [
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO,
                INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO
            ];

            for (var x = 0; x < camposParaValidar.length; x++) {
                const result = rows.every((row, index) => {
                    return this.validarEndereco(camposParaValidar[x], row, index);
                });
                if (!result) {
                    break;
                }
            }

            //Validar campo Nome impresso no cartao
            if (nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError) {
                nomeImpressoNoCartaoError = {
                    row: this.handleFindRow(rows, nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError), data: nomeImpressoNoCartaoLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo Nome impresso na carta berco
            if (nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError) {
                nomeImpressoNaCartaBercoError = {
                    row: this.handleFindRow(rows, nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError), data: nomeImpressoNaCartaBercoLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo CPF
            if (cpfPortadorCampoInvalidoDataError && cpfPortadorCampoInvalidoDataError[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CPF_PORTADOR]) {
                cpfPortadorError = {
                    row: this.handleFindRow(rows, cpfPortadorCampoInvalidoDataError), data: cpfPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            //Validar coluna Data Nascimento do Portador
            if (dataNascimentoCampoInvalidoDataError) {
                dataNascimentoError = {
                    row: this.handleFindRow(rows, dataNascimentoCampoInvalidoDataError), data: dataNascimentoCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            } else if (dataNascimentoMenorIgualQueHojeError) {
                dataError = { row: this.handleFindRow(rows, dataNascimentoMenorIgualQueHojeError), data: dataNascimentoMenorIgualQueHojeError };
            }

            //Validar coluna DDI do portador - numero e obrigatorio
            if (ddiCelularPortadorLimiteDeCaracterExcedidoDataError) {
                ddiCelularPortadorError = {
                    row: this.handleFindRow(rows, ddiCelularPortadorLimiteDeCaracterExcedidoDataError), data: ddiCelularPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '3' }
                };
            } else if (ddiCelularPortadorCampoInvalidoDataError) {
                ddiCelularPortadorError = {
                    row: this.handleFindRow(rows, ddiCelularPortadorCampoInvalidoDataError), data: ddiCelularPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            //Validar coluna DDD do portador - numero e obrigatorio
            if (dddCelularPortadorLimiteDeCaracterExcedidoDataError) {
                dddCelularPortadorError = {
                    row: this.handleFindRow(rows, dddCelularPortadorLimiteDeCaracterExcedidoDataError), data: dddCelularPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '3' }
                };
            } else if (dddCelularPortadorCampoInvalidoDataError) {
                dddCelularPortadorError = {
                    row: this.handleFindRow(rows, dddCelularPortadorCampoInvalidoDataError), data: dddCelularPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            //Validar coluna Celular do portador - numero e obrigatorio
            if (celularPortadorLimiteDeCaracterExcedidoDataError) {
                celularPortadorError = {
                    row: this.handleFindRow(rows, celularPortadorLimiteDeCaracterExcedidoDataError), data: celularPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '9' }
                };
            } else if (celularPortadorCampoInvalidoDataError) {
                celularPortadorError = {
                    row: this.handleFindRow(rows, celularPortadorCampoInvalidoDataError), data: celularPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            //Validar campo Email portador
            if (emailPortadorLimiteDeCaracterExcedidoDataError) {
                emailPortadorError = {
                    row: this.handleFindRow(rows, emailPortadorLimiteDeCaracterExcedidoDataError), data: emailPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '50' }
                };
            } else if (emailPortadorCampoInvalidoDataError) {
                emailPortadorError = {
                    row: this.handleFindRow(rows, emailPortadorCampoInvalidoDataError), data: emailPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            /* Se tiver um ou mais campos de endereço preenchidos, todos serão obrigatórios */

            //Validar campo cep do endereço de entrega
            if (cepPortadorCampoInvalidoDataError) {
                cepPortadorError = {
                    row: this.handleFindRow(rows, cepPortadorCampoInvalidoDataError), data: cepPortadorCampoInvalidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.CAMPO_INVALIDO }
                };
            }

            //Validar campo país do endereço de entrega
            if (paisPortadorLimiteDeCaracterExcedidoDataError) {
                paisPortadorError = {
                    row: this.handleFindRow(rows, paisPortadorLimiteDeCaracterExcedidoDataError), data: paisPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo cidade do endereço de entrega
            if (cidadePortadorLimiteDeCaracterExcedidoDataError) {
                cidadePortadorError = {
                    row: this.handleFindRow(rows, cidadePortadorLimiteDeCaracterExcedidoDataError), data: cidadePortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo UF do endereço de entrega
            if (ufPortadorLimiteDeCaracterExcedidoDataError) {
                ufPortadorError = {
                    row: this.handleFindRow(rows, ufPortadorLimiteDeCaracterExcedidoDataError), data: ufPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '2' }
                };
            }

            //Validar campo logradouro do endereço de entrega
            if (logradouroPortadorLimiteDeCaracterExcedidoDataError) {
                logradouroPortadorError = {
                    row: this.handleFindRow(rows, logradouroPortadorLimiteDeCaracterExcedidoDataError), data: logradouroPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            //Validar campo bairro do endereço de entrega
            if (bairroPortadorLimiteDeCaracterExcedidoDataError) {
                bairroPortadorError = {
                    row: this.handleFindRow(rows, bairroPortadorLimiteDeCaracterExcedidoDataError), data: bairroPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            if (numeroPortadorLimiteDeCaracterExcedidoDataError) {
                numeroPortadorError = {
                    row: this.handleFindRow(rows, numeroPortadorLimiteDeCaracterExcedidoDataError), data: numeroPortadorLimiteDeCaracterExcedidoDataError,
                    erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                };
            }

            if (this.state.tipoEntregaSelecionada === TIPO_ENTREGA.INDIVIDUAL.value && this.state.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value) {
                const linhaError = this.validarEnderecoArquivo(rows);
                if (linhaError) {
                    enderecoIndividualError = {
                        row: this.handleFindRow(rows, linhaError),
                        data: linhaError,
                        erroData: { tipo: TIPO_DE_ERRO.PEDIDO.EMBOSSING_CARTAO.LIMITE_DE_CARACTER_EXCEDIDO, numeroMaximoDeCaracter: '20' }
                    };
                }
            }

            this.setState({
                arquivoSelecionado: this.arquivoSelecionado, rows, header, colunaError, nomeImpressoNoCartaoError, nomeImpressoNaCartaBercoError,
                cpfPortadorError, dataNascimentoError, dataError, ddiCelularPortadorError, dddCelularPortadorError,
                celularPortadorError, emailPortadorError, cepPortadorError, paisPortadorError, cidadePortadorError, ufPortadorError,
                logradouroPortadorError, bairroPortadorError, numeroPortadorError, enderecoIndividualError, numeroDeCartoesDiferenteQueNumeroDeLinhas
            }, () => {
                this.habilitaEDesabilitaBotaoConfirmar(this.state);
            });
        }

        this.setState({
            arquivoSelecionado: this.arquivoSelecionado, rows, header, colunaError, nomeImpressoNoCartaoError, nomeImpressoNaCartaBercoError,
            cpfPortadorError, dataNascimentoError, dataError, ddiCelularPortadorError, dddCelularPortadorError,
            celularPortadorError, emailPortadorError, cepPortadorError, paisPortadorError, cidadePortadorError, ufPortadorError,
            logradouroPortadorError, bairroPortadorError, numeroPortadorError, enderecoIndividualError, numeroDeCartoesDiferenteQueNumeroDeLinhas
        }, () => {
            this.habilitaEDesabilitaBotaoConfirmar(this.state);
        });
    }

    validarEnderecoArquivo = (rows) => {
        return rows.find(r => !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CEP]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.LOGRADOURO]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.NUMERO]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.BAIRRO]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.CIDADE]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.PAIS]
            && !r[INDICE_COLUNA_ARQUIVO.PEDIDO_EMBOSSING_CARTAO.UF]
        );
    }

    setRedirecionarParaTelaResumo = () => {
        this.setState({ redirecionarParaTelaResumo: true });
    }

    renderFooterModalCancelamento = () => {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO} btn2Label={LABEL_COMUNS.SIM}
                btn1OnClick={() => this.setState({ exibirModalCancelar: false })}
                btn2OnClick={this.props.history.goBack} />
        );
    }

    actionBotaoVoltar = () => {
        if (this.state.anexoSelecionadoId || this.state.comboFelIdSelecionado || this.state.quantidadeCartoes ||
            this.state.tipoEntregaSelecionada || this.state.formaEntregaSelecionada || this.state.arquivoSelecionado) {
            this.setState({ exibirModalCancelar: true });
        } else {
            this.props.history.goBack();
        }
    }

    render() {
        const error =
            this.state.colunaError
            || (this.state.arquivoSelecionado && !this.state.rows.length)
            || this.state.nomeImpressoNoCartaoDataError
            || this.state.nomeImpressoNaCartaBercoError
            || this.state.cpfPortadorError
            || this.state.dataError
            || this.state.dataNascimentoError
            || this.state.ddiCelularPortadorError
            || this.state.dddCelularPortadorError
            || this.state.celularPortadorError
            || this.state.emailPortadorError
            || this.state.cepPortadorError
            || this.state.paisPortadorError
            || this.state.cidadePortadorError
            || this.state.ufPortadorError
            || this.state.logradouroPortadorError
            || this.state.bairroPortadorError
            || this.state.numeroPortadorError
            || this.state.camposDoEnderecoLoteNaoPreenchidosError
            || this.state.enderecoIndividualError
            || this.state.numeroDeCartoesDiferenteQueNumeroDeLinhas;

        if (this.state.redirecionarParaTelaResumo) {
            const { cartao, cartaBerco } = this.props.pedido.urlImagem;
            const { anexoSelecionado } = this.props.pedido;
            const combineJson = { [CAMPOS.URL_IMAGEM_CARTAO]: cartao, [CAMPOS.URL_IMAGEM_CARTA_BERCO]: cartaBerco };
            return <Redirect to={{ pathname: ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_PAG_RESUMO, state: { pedido: { ...this.state, ...combineJson, anexoSelecionado } } }} />;
        }

        return (
            <div>
                <ValidaSaldoParaPedidoEmbossingCartao exibirModal={this.state.exibirModal}
                    setExibirModal={() => this.setState({ exibirModal: false })}
                    contextoBppPag={true}
                />

                {this.props.pedido.contaSelecionada && <ContaSelecionada exibirSaldo={true}
                    contextoBppPag={true} contaSelecionada={this.props.pedido.contaSelecionada} />
                }

                <If test={!this.props.pedido.localidade.exibirLoadingLocalidade && this.props.pedido.exibirLoadingPedidoCartaoImpresso}>
                    <div className="alinhamento-centro"><LoadingFenix /></div>
                </If>

                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.ANEXO_COMBO_IMPRESSAO} </b></div>
                            <div className="panel-body espacamento_div">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-4 col-sm-5 col-md-5 col-lg-5">
                                            <b>{LABEL_COMUNS.SELECIONE_TIPO_DE_CARTAO}*</b>
                                            <FormGroup validationState={this.state.anexoSelecionadoIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null} >
                                                <Select id="comboImpressao"
                                                    placeholder={LABEL_COMUNS.SELECIONE_TIPO_DE_CARTAO}
                                                    value={this.state.anexoSelecionadoId}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.ANEXO_SELECIONADO_ID)}
                                                    options={this.props.pedido.listaDeAnexos.filter(lista => lista.anexoCompleto.produtoPublicadoId !== environment.produtoPublicadoPagInativo)}
                                                    noResultsText={LABEL_COMUNS.NENHUM_ANEXO} />
                                                {this.state.anexoSelecionadoIdErro ? <span className="mensagens-erro-span" role="alert">{this.state.anexoSelecionadoIdErro}</span> : null}
                                            </FormGroup>
                                        </div>

                                        <div className="col-xs-4 col-sm-5 col-md-5 col-lg-5">
                                            <b>{LABEL_COMUNS.SELECIONE_COMBO_IMPRESSAO}*</b>
                                            <FormGroup validationState={this.state.comboFelIdSelecionadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null} >
                                                <Select id="anexo"
                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                    disabled={!this.state.anexoSelecionadoId}
                                                    value={this.state.comboFelIdSelecionado}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.COMBO_FEL_ID_SELECIONADO)}
                                                    options={COMBO.PEDIDOS.IMPRESSAO.COMBOS_FEL(this.props.pedido.anexoSelecionado)}
                                                    noResultsText={LABEL_COMUNS.NENHUM_COMBO} />
                                                {this.state.comboFelIdSelecionadoErro ? <span className="mensagens-erro-span" role="alert">{this.state.comboFelIdSelecionadoErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.INFORMACOES_GERAIS} </b></div>
                            <div className="panel-body espacamento_div" style={{minHeight: 93}}>
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-2 col-sm-3 col-md-3 col-lg-3">
                                            <b>{LABEL_COMUNS.QTDE_CARTOES}*</b>
                                            <FormGroup validationState={this.state.quantidadeCartoesErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <InputMask
                                                    id="txtQuantidadeCartoes"
                                                    className="form-control mensagens-erro"
                                                    mask="9999" maskChar=""
                                                    style={{ height: '36px', paddingTop: '8px', paddingLeft: '10px' }}
                                                    value={this.state.quantidadeCartoes}
                                                    placeholder={LABEL_COMUNS.QUANTIDADE}
                                                    onChange={(e) => {this.setPropsCampos(e, CAMPOS.QUANTIDADE_CARTOES); this.setState ({quantidadeAcima: false});}}>
                                                </InputMask>
                                                {this.state.quantidadeCartoesErro ? <span className="mensagens-erro-span" role="alert">{this.state.quantidadeCartoesErro}</span> : null}
                                                {this.state.quantidadeAcima ? <span className="mensagens-erro-span" role="alert">{LABEL_COMUNS.LIMITE_CARTOES}</span> : null}
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                                            <b>{LABEL_COMUNS.TIPO_DE_ENTREGA}*</b>
                                            <FormGroup validationState={this.state.tipoEntregaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl className="mensagens-erro" id="tipoEntrega" componentClass="select" placeholder="Tipo de Conta"
                                                    value={this.state.tipoEntregaSelecionada}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.TIPO_ENTREGA_SELECIONADA)}
                                                    style={{ height: '35px', paddingTop: '6px', paddingLeft: '10px' }}>
                                                    <option value={-1} defaultValue>{LABEL_COMUNS.SELECIONE}</option>
                                                    {COMBO.PEDIDOS.IMPRESSAO.COMBO_TIPO_ENTREGA.map((tipoEntrega, idx) => {
                                                        return (
                                                            <option key={idx} value={tipoEntrega.value}>{tipoEntrega.label}</option>
                                                        );
                                                    })}
                                                </FormControl>
                                                {this.state.tipoEntregaErro ? <span className="mensagens-erro-span" role="alert">{this.state.tipoEntregaErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-3 col-sm-4 col-md-4 col-lg-4">
                                            <b>{LABEL_COMUNS.FORMA_DE_ENTREGA}*</b>
                                            <FormGroup validationState={this.state.formaEntregaErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <Select id="formaEntrega"
                                                    placeholder={LABEL_COMUNS.SELECIONE}
                                                    value={this.state.formaEntregaSelecionada}
                                                    onChange={(e) => this.setPropsCampos(e, CAMPOS.FORMA_ENTREGA_SELECIONADA)}
                                                    options={COMBO.PEDIDOS.IMPRESSAO.COMBO_FORMA_ENTREGA}
                                                    noResultsText={LABEL_COMUNS.NENHUMA_FORMA_ENTREGA_ENCONTRADA} />
                                                {this.state.formaEntregaErro ? <span className="mensagens-erro-span" role="alert">{this.state.formaEntregaErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-6 col-sm-3 col-md-3 col-lg-3" />
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>
                <If test={this.state.formaEntregaSelecionada && this.state.formaEntregaSelecionada !== FORMA_ENTREGA.RETIRADA_BPP.value
                    && this.state.tipoEntregaSelecionada !== TIPO_ENTREGA.INDIVIDUAL.value && this.state.tipoEntregaSelecionada.toString() !== '-1'}>
                    <DadosEntrega
                        exibirLoading={this.props.pedido.localidade.exibirLoadingLocalidade}
                        cep={this.state.cep}
                        setCep={(e) => this.setPropsCampos(e, CAMPOS.CEP)}
                        preencheCamposLocalidade={(e) => this.props.getLocalidadePorCep(this.state.cep)}
                        paisSelecionadoId={this.state.paisSelecionadoId}
                        setPais={(e) => {
                            let paisId = e && e.value;
                            this.setPropsCampos(e, CAMPOS.PAIS_SELECIONADO_ID);
                            if (paisId) {
                                this.props.getEstados(paisId);
                            } else {
                                this.setPropsCampos(retornaObjetoTarget(), CAMPOS.SIGLA_DO_ESTADO_SELECIONADO);
                                this.setPropsCampos(retornaObjetoTarget(), CAMPOS.NOME_DA_CIDADE_SELECIONADA);
                                this.setPropsCampos(retornaObjetoTarget(), CAMPOS.BAIRRO);
                                this.setPropsCampos(retornaObjetoTarget(), CAMPOS.LOGRADOURO);
                            }
                        }}
                        listaPaises={COMBO.LOCALIDADE.GET_PAIS_BRASIL(this.props.pedido.localidade.paises)}
                        siglaDoEstadoSelecionado={this.state.siglaDoEstadoSelecionado}
                        setEstado={(e) => {
                            let estadoSelecionado = e && e.estado;
                            this.setState({ estadoSelecionado });
                            this.setPropsCampos(e, CAMPOS.SIGLA_DO_ESTADO_SELECIONADO);
                            this.setPropsCampos(retornaObjetoTarget(), CAMPOS.NOME_DA_CIDADE_SELECIONADA);
                            this.setPropsCampos(retornaObjetoTarget(), CAMPOS.BAIRRO);
                            this.setPropsCampos(retornaObjetoTarget(), CAMPOS.LOGRADOURO);
                            if (estadoSelecionado) {
                                this.props.getCidades(this.state.paisSelecionadoId, estadoSelecionado.id);
                            }
                        }}
                        listaEstados={COMBO.LOCALIDADE.GET_ESTADOS(this.props.pedido.localidade.estados)}
                        nomeDaCidadeSelecionada={this.state.nomeDaCidadeSelecionada}
                        setCidade={(e) => {
                            let nomeDaCidadeSelecionada = e && e.cidade;
                            this.setState({ nomeDaCidadeSelecionada });
                            this.setPropsCampos(e, CAMPOS.NOME_DA_CIDADE_SELECIONADA);
                            this.setPropsCampos(retornaObjetoTarget(), CAMPOS.BAIRRO);
                            this.setPropsCampos(retornaObjetoTarget(), CAMPOS.LOGRADOURO);
                        }}
                        listaCidades={COMBO.LOCALIDADE.GET_CIDADES(this.props.pedido.localidade.cidades)}
                        bairro={this.state.bairro}
                        setBairro={(e) => this.setPropsCampos(e, CAMPOS.BAIRRO)}
                        logradouro={this.state.logradouro}
                        setLogradouro={(e) => this.setPropsCampos(e, CAMPOS.LOGRADOURO)}
                        numero={this.state.numero}
                        setNumero={(e) => this.setPropsCampos(e, CAMPOS.NUMERO)}
                        complemento={this.state.complemento}
                        setComplemento={(e) => this.setPropsCampos(e, CAMPOS.COMPLEMENTO)}
                        pontoReferencia={this.state.pontoReferencia}
                        setPontoReferencia={(e) => this.setPropsCampos(e, CAMPOS.PONTO_REFERENCIA)}
                        destinatarioErro={this.state.destinatarioErro}
                        destinatario={this.state.destinatario}
                        setDestinatario={(e) => this.setPropsCampos(e, CAMPOS.DESTINATARIO)}
                        aosCuidadosErro={this.state.aosCuidadosErro}
                        aosCuidados={this.state.aosCuidados}
                        setAosCuidados={(e) => this.setPropsCampos(e, CAMPOS.AOS_CUIDADOS)}
                    />
                </If>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.INFORMACOES_IMPRESSAS} </b></div>
                            <div className="panel-body espacamento_div_upload">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                            <FormGroup validationState={this.state.arquivoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                <FormControl
                                                    id="arquivo"
                                                    type="file"
                                                    accept=".csv"
                                                    inputRef={ref => this.fileInput = ref}
                                                    onClick={() => {
                                                        this.fileInput.value = null;
                                                        this.restaurarComportamentoInicialInput();
                                                    }}
                                                    onChange={this.tratarArquivoSelecionado}
                                                    disabled={!this.state.tipoEntregaSelecionada || !this.state.formaEntregaSelecionada}
                                                />
                                                {this.state.arquivoErro ? <span className="mensagens-erro-span" role="alert">{this.state.arquivoErro}</span> : null}
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 espacamento_arq_exemplo">
                                            <i><u><span style={{ cursor: 'pointer' }} onClick={() => this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_INSTRUCAO)}>{LABEL_COMUNS.VER_ARQUIVO_EXEMPLO}</span></u></i>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>

                {this.state.mensagensErros.map((mensagem, idx) => <p key={idx} className="mensagens-erro-span">{mensagem.message}</p>)}

                <ModalInstrucoesPedidoCartaoImpresso contextoBppPag={true}
                    setExibirModal={(valor) => this.setPropsCampos({ target: { value: valor } }, CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_INSTRUCAO)}
                    exibirModal={this.state.exibirModalInstrucao}
                />

                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-direita">
                    <Button
                        bsStyle="default"
                        style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                        onClick={this.actionBotaoVoltar}>{LABEL_COMUNS.VOLTAR}
                    </Button>&nbsp;&nbsp;
                    <Button
                        bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG}
                        style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                        onClick={async () => {
                            if (!this.state.arquivoSelecionado && this.state.tipoEntregaSelecionada === 'individual') {
                                this.setState({ mensagensErros: [...this.state.mensagensErros, { message: 'Não é permitido pedir cartão individual sem escolher um arquivo com os dados do portador', order: 0 }] });
                            } else {
                                const pedido = { ...this.props.pedido, quantidadeCartoes: this.state.quantidadeCartoes, tipoEntregaSelecionada: this.state.tipoEntregaSelecionada, formaEntregaSelecionada: this.state.formaEntregaSelecionada };
                                const saldoSuficienteParaEfetuarPedido = await saldoSuficienteParaEfetuarPedidoEmbossingCartao(pedido);
                                if (!saldoSuficienteParaEfetuarPedido) {
                                    this.setState({ exibirModal: true });
                                } else {
                                    this.setRedirecionarParaTelaResumo();
                                }
                            }
                        }}
                        disabled={!!(this.state.desabilitarBotaoConfirmar || error)}>
                        {LABEL_COMUNS.CONTINUAR}
                    </Button>
                </div>

                <ModalComponent name="mdCancelar" contextoBppPag={true}
                    exibirModal={this.state.exibirModalCancelar}
                    header={LABEL_COMUNS.PEDIDOS.EMBOSSING_CARTAO.MODAL_HEADER}
                    body={MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.CONFIRMACAO()}
                    footer={this.renderFooterModalCancelamento()} />

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    pedido: {
        ...pedidoCartaoImpressoBppPag(state.PedidosReducer),
        listaDeAnexos: getAnexosCartaoBppPag(state.AnexoContratualReducer),
        anexoSelecionado: getAnexoParaPedidoEmbossingCartao(state.AnexoContratualReducer),
        localidade: state.LocalidadeReducer
    },
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...localidadeAction, ...pedidosAction, getAnexoPorId, getAnexosContratuais }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedidoEmbossingCartaoBppPag);
