import { fetchReportCardAnalityc, fetchReportCardBalance, fetchTokenGatewayApi, fetchCardStatus, fetchReportCardManagement, fetchReportCardAccountability } from './relatorios-bbp-corp-base';
import { environment } from '../../configuracoes/environment';
import { urlServicos } from '../url-servicos';
import { fetchGetPrestacaoDeContasClassificacoes, fetchGetPrestacaoDeContasProjetos } from '../pessoas/pessoas-base';
import { getFirstDayCurrentMoth, getLastDayCurrentMoth } from '../../componentes/util/data/dataUtil';
import { formataMoedaReal } from '../../componentes/util/numeros/numeroUtil';
import { MENSAGENS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { isEmptyObject } from '../base/service-base';
import { reactLocalStorage } from 'reactjs-localstorage';
import jwt_decode from 'jwt-decode';

export const getReportCardBalance = (dadosBody) => {
    verificarValidadeToken();
    return fetchReportCardBalance(dadosBody)
        .then((result) => {
            return result;
        }).catch(erro => {
            return 'error';
        });
};

export function getReportCardAnalityc(dadosBody) {
    verificarValidadeToken();
    return fetchReportCardAnalityc(dadosBody)
        .then((result) => {
            return result;
        }).catch(erro => {
            return 'error';
        });
}

export const getReportCardStatus = async (body) => {
    verificarValidadeToken();
    const response = await fetchCardStatus(generateBodyReportCardStatus(body));
    if (!response.error) {
        return response;
    } else {
        return 'error';
    }
    // tratar os erros e retornar para a tela as devidas mensagens
};

export const getReportCardAccountability = async (body) => {
    verificarValidadeToken();
    const response = await fetchReportCardAccountability(generateBodyReportCardAccountability(body));
    if (!response.error) {
        return response;
    } else {
        return 'error';
    } // tratar os erros e retornar para a tela as devidas mensagens
};

export const getProjetos = async (pessoaId) => {
    verificarValidadeToken();
    let resposta = { existsProjetos: false, menssageError: '' };
    const respostaProjetos = await fetchGetPrestacaoDeContasProjetos(pessoaId);
    if (!respostaProjetos.error && respostaProjetos.data.length > 0) {
        resposta.existsProjetos = true;
    } else {
        resposta.existsProjetos = false;
        resposta.menssageError = MENSAGENS.REPORTS.EMPTY_CLIENTE_PROJETO;
    }

    return resposta;
};

export const getClassificacoes = async (pessoaId) => {
    verificarValidadeToken();
    let resposta = { existsClassificacoes: false, menssageError: '' };
    const respostaClassificacao = await fetchGetPrestacaoDeContasClassificacoes(pessoaId);
    if (!respostaClassificacao.error && respostaClassificacao.data.length > 0) {
        resposta.existsClassificacoes = true;
    } else {
        resposta.existsClassificacoes = false;
        resposta.menssageError = MENSAGENS.REPORTS.EMPTY_DESPESA;
    }

    return resposta;
};

export const getReportCardManagement = async (body) => {
    verificarValidadeToken();
    let resposta = {};

    const reponse = await Promise.all([
        fetchReportCardBalance(generateBodyReportCardBalance(body)),
        fetchReportCardManagement(generateBodyReportCardManagement(body))
    ]);
    if ((!reponse[0].error) && (!reponse[1].error)) {
        const cardsRetrivied = reponse[0].data.response;
        const totalCardsAmountFirstDay = cardsRetrivied.reduce((sum, { firstBalanceDay }) => sum + firstBalanceDay, 0);
        // console.log('totalCardsAmountFirstDay', totalCardsAmountFirstDay);
        const totalCardsAmountLastDay = cardsRetrivied.reduce((sum, { lastBalanceDay }) => sum + lastBalanceDay, 0);
        // console.log('totalCardsAmountLastDay', totalCardsAmountLastDay); formataMoeda

        if (isEmptyObject(reponse[1].data)) {
            resposta = {
                error: false,
                menssageError: MENSAGENS.REPORTS.MANAGEMENT.EMPTY_RESPONSE
            };
        } else {
            resposta = {
                balanceDays: {
                    totalCardsAmountFirstDay: formataMoedaReal(totalCardsAmountFirstDay),
                    totalCardsAmountLastDay: formataMoedaReal(totalCardsAmountLastDay)
                },
                error: false,
                transactions: { ...reponse[1].data }
            };
        }
    } else {
        resposta = {
            error: true,
            menssageError: MENSAGENS.REPORTS.MANAGEMENT.ERROR
        };
    }
    return resposta;
    // tratar os erros e retornar para a tela as devidas mensagens
};

const generateBodyReportCardAccountability = (formData) => {
    const body = {
        startDate: formData.startDate,
        endDate: formData.endDate,
        accountId: formData.accountId,
        token: formData.token
    };
    return JSON.stringify(body);
};

const generateBodyReportCardStatus = (formData) => {
    const body = {
        accountId: formData.accountId,
        token: formData.token
    };
    return JSON.stringify(body);
};

const generateBodyReportCardManagement = (formData) => {
    const { contaSelecionada, selectedDate, selectedToken } = formData;
    const body = {
        accountId: contaSelecionada.id,
        month: selectedDate.value.getMonth() + 1,
        year: selectedDate.value.getFullYear(),
        token: selectedToken
    };
    return JSON.stringify(body);
};
const generateBodyReportCardBalance = (formData) => {
    const { contaSelecionada, selectedDate, selectedDay, selectedToken } = formData;
    const body = {
        accountId: contaSelecionada.id,
        month: selectedDate.value.getMonth() + 1,
        year: selectedDate.value.getFullYear(),
        token: selectedToken
    };
    if (selectedDay) {
        body.day = selectedDay;
    }
    return body;
};

function verificarValidadeToken() {
    var token = reactLocalStorage.get('token');
    var decoded = jwt_decode(token);
    let timeStampToken = decoded.exp;
    let timeStampAtual = Date.now().toString();
    timeStampAtual = timeStampAtual.substring(0, 10);
    timeStampAtual = parseInt(timeStampAtual);
    if (timeStampAtual >= timeStampToken) {
        window.location.href = '/sair';
    }
}
