import React from 'react';
import { MENSAGENS } from '../contantes-comuns/constantes-comuns';

export function retornaExtensaoDoArquivo(arquivo) {
    if (arquivo instanceof File) {
        return arquivo.name.split('.').pop();
    }
    return '';
}

export function retornaTipoDoArquivo(arquivo) {
    let extensao = retornaExtensaoDoArquivo(arquivo);
    switch (extensao) {
        case 'csv':
            return 'text/csv';
        default:
            return arquivo.type;
    }
}

export const validarErroImagem = (arquivo) => {
    if (arquivo.size < 10e+6) {
        if (arquivo.type !== 'image/png' && arquivo.type !== 'image/jpeg' && arquivo.type !== 'image/jpg') {
            return <div dangerouslySetInnerHTML={{ __html: MENSAGENS.VALIDACAO_DE_CAMPOS.ARQUIVO.EXTENSAO_NAO_SUPORTADA.IMAGEM }} />;
        }
    } else {
        return <div dangerouslySetInnerHTML={{ __html: MENSAGENS.VALIDACAO_DE_CAMPOS.ARQUIVO.TAMANHO_NAO_SUPORTADO }} />;
    }
    return false;
};
