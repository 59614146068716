import React, { Component } from 'react';
import { FormGroup, Checkbox } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import { LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import { ControlLabel } from 'react-bootstrap';

class DataAgendamento extends Component {
    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <ControlLabel>{this.props.titulo}</ControlLabel>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5 alinhamento-checkbox-agendamento">
                            <FormGroup>
                                <Checkbox defaultChecked={this.props.paraHoje} onClick={this.props.hojeIDOnchange}
                                    checked={this.props.checkedValue} 
                                    disabled={this.props.disabledHoje} onChange={this.props.setHoje} ><strong>{LABEL_COMUNS.HOJE}</strong>
                                </Checkbox>
                            </FormGroup>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                            <span className={this.props.labelTransferenciaClassName}><strong>{LABEL_COMUNS.AGENDAR_PARA}</strong></span>
                        </div>
                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3">
                            <FormGroup validationState={this.props.dataAgendamentoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                <InputMask disabled={this.props.paraHoje} 
                                    mask="99/99/9999"
                                    maskChar=" "
                                    className={this.props.inputDataAgendamentoClassName}
                                    id="dataAgendamento"
                                    name="dataAgendamento"
                                    placeholder="DD/MM/AAAA"
                                    inputRef={(ref) => this.dataAgendamento = ref}
                                    value={this.props.paraHoje ? '' : this.props.dataAgendamentoValue}
                                    onChange={() => this.props.dataAgendamentoOnChange(this.dataAgendamento.value)}
                                    style={{ height: '30px', paddingTop: '10px', paddingLeft: '10px' }} />
                            </FormGroup>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5">
                            {this.props.dataAgendamentoObrigatoriaErro ? <span className="mensagens-erro-span" role="alert">{this.props.dataAgendamentoObrigatoriaErro}</span> : null}
                        </div>
                        <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8 alinhamento-direita">
                            {this.props.dataAgendamentoErro ? <span className={this.props.inputDataAgendamentoMensagemErroClassName} role="alert">{this.props.dataAgendamentoErro}</span> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default DataAgendamento;
