
import React from 'react';

const ShowLineError = props => {
    return (
        <div>
            {props.error ? <span className="mensagens-erro-span" role="alert">{props.error}</span> : null}
        </div>
    );
};
export default ShowLineError;
