import * as constantes from './constantes';
import { reactLocalStorage } from 'reactjs-localstorage';

const estadoInicial = {

    contaSelecionadaPessoaTitularId: reactLocalStorage.getObject('contaSelecionada').pessoaTitularId, // TODO: obter essa inicializacao do reducer correto no futuro :)

    // OBTER LISTAS DE PARAMETROS
    parametrosClienteProjeto: [],
    parametrosClassificacao: [],
    exibirAlertaErroAoObterClienteProjeto: false,
    exibirAlertaErroAoObterClassificacao: false,

    // CRIACAO PARAMETROS
    nomeDoClienteProjetoDigitado: undefined,
    nomeDaClassificacaoDigitado: undefined,
    exibirModalConfirmacaoCriacaoDeClienteProjeto: false,
    exibirModalConfirmacaoCriacaoDeClassificacao: false,
    exibirSucessoCriacao: false,
    exibirModalErroCriacaoDeClienteProjeto: false,
    exibirModalErroCriacaoDeClassificacao: false,
    mensagemErroCriacaoDeClienteProjeto: undefined,
    mensagemErroCriacaoDeClassificacao: undefined,

    // REMOCAO PARAMETROS
    parametrosClienteProjetoParaRemocao: [],
    parametrosClassificacaoParaRemocao: [],
    exibirModalConfirmacaoRemocaoDeClienteProjeto: false,
    exibirModalConfirmacaoRemocaoDeClassificacao: false,
    exibirSucessoRemocao: false,
    exibirModalErroRemocaoDeClienteProjeto: false,
    exibirModalErroRemocaoDeClassificacao: false,
    mensagemErroRemocaoDeClienteProjeto: undefined,
    mensagemErroRemocaoDeClassificacao: undefined,

    // CONTROLE GUI
    expandirPainelDeClienteProjeto: true,
    expandirPainelDeClassificacao: false,
    desabilitarBotaoCriarNovoClienteProjeto: true,
    desabilitarBotaoCriarNovaClassificacao: true
};

export default function reducer(state = estadoInicial, action = {}) {
    switch (action.type) {
    // GET SUCESSO
        case constantes.SUCESSO_OBTER_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                parametrosClienteProjeto: action.payload.sort((a, b) => {
                    let c1 = a.nome.toLowerCase(); let c2 = b.nome.toLowerCase();
                    if (c1 < c2) return -1;
                    if (c1 > c2) return 1;
                    return 0;
                })
            };

        case constantes.SUCESSO_OBTER_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                parametrosClassificacao: action.payload.sort((a, b) => {
                    let c1 = a.nome.toLowerCase(); let c2 = b.nome.toLowerCase();
                    if (c1 < c2) return -1;
                    if (c1 > c2) return 1;
                    return 0;
                })
            };

        // GET ERRO
        case constantes.ERRO_OBTER_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                exibirAlertaErroAoObterClienteProjeto: true
            };

        case constantes.ERRO_OBTER_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                exibirAlertaErroAoObterClassificacao: true
            };

        // POST SUCESSO
        case constantes.SUCESSO_CRIAR_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                parametrosClienteProjeto: [...state.parametrosClienteProjeto, action.payload].sort((a, b) => {
                    let c1 = a.nome.toLowerCase(); let c2 = b.nome.toLowerCase();
                    if (c1 < c2) return -1;
                    if (c1 > c2) return 1;
                    return 0;
                }),
                exibirModalConfirmacaoCriacaoDeClienteProjeto: false,
                desabilitarBotaoCriarNovoClienteProjeto: true,
                exibirSucessoCriacao: true
            };

        case constantes.SUCESSO_CRIAR_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                parametrosClassificacao: [...state.parametrosClassificacao, action.payload].sort((a, b) => {
                    let c1 = a.nome.toLowerCase(); let c2 = b.nome.toLowerCase();
                    if (c1 < c2) return -1;
                    if (c1 > c2) return 1;
                    return 0;
                }),
                exibirModalConfirmacaoCriacaoDeClassificacao: false,
                desabilitarBotaoCriarNovaClassificacao: true,
                exibirSucessoCriacao: true
            };

        // POST ERRO
        case constantes.ERRO_CRIAR_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                exibirModalErroCriacaoDeClienteProjeto: true,
                mensagemErroCriacaoDeClienteProjeto: action.payload
            };

        case constantes.ERRO_CRIAR_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                exibirModalErroCriacaoDeClassificacao: true,
                mensagemErroCriacaoDeClassificacao: action.payload
            };

        // REMOVER / PATCH SUCESSO
        case constantes.SUCESSO_REMOVER_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                parametrosClienteProjetoParaRemocao: [],
                exibirModalConfirmacaoRemocaoDeClienteProjeto: false,
                exibirSucessoRemocao: true
            };

        case constantes.SUCESSO_REMOVER_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                parametrosClassificacaoParaRemocao: [],
                exibirModalConfirmacaoRemocaoDeClassificacao: false,
                exibirSucessoRemocao: true
            };

        // REMOVER / PATCH ERRO
        case constantes.ERRO_REMOVER_PARAMETROS_CLIENTE_PROJETO:
            return {
                ...state,
                exibirModalErroRemocaoDeClienteProjeto: true,
                mensagemErroRemocaoDeClienteProjeto: action.payload
            };

        case constantes.ERRO_REMOVER_PARAMETROS_CLASSIFICACAO:
            return {
                ...state,
                exibirModalErroRemocaoDeClassificacao: true,
                mensagemErroRemocaoDeClassificacao: action.payload
            };

            // CONTROLES DA GUI

        // ALERTA DE ERRO AO OBTER LISTA DE PARAMETROS CADASTRADOS
        case constantes.EXIBIR_ALERTA_ERRO_OBTER_CLIENTE_PROJETO:
            return {
                ...state,
                exibirAlertaErroAoObterClienteProjeto: action.payload

            };

        case constantes.EXIBIR_ALERTA_ERRO_OBTER_CLASSIFICACAO:
            return {
                ...state,
                exibirAlertaErroAoObterClassificacao: action.payload
            };

        // ARMAZENAR VALORES DIGITADOS
        case constantes.ARMAZENAR_NOME_CLIENTE_PROJETO_DIGITADO:
            return {
                ...state,
                nomeDoClienteProjetoDigitado: action.payload,
                desabilitarBotaoCriarNovoClienteProjeto: action.payload.length === 0
            };

        case constantes.ARMAZENAR_NOME_CLASSIFICACAO_DIGITADO:
            return {
                ...state,
                nomeDaClassificacaoDigitado: action.payload,
                desabilitarBotaoCriarNovaClassificacao: action.payload.length === 0
            };

        // EXIBIR MODAL CONFIRMACAO DE CRIACAO
        case constantes.EXIBIR_CONFIRMACAO_CRIACAO_CLIENTE_PROJETO:
            return action.payload
                ? {
                    ...state,
                    exibirModalConfirmacaoCriacaoDeClienteProjeto: action.payload
                }
                : {
                    ...state,
                    exibirModalConfirmacaoCriacaoDeClienteProjeto: action.payload,
                    nomeDoClienteProjetoDigitado: undefined,
                    desabilitarBotaoCriarNovoClienteProjeto: true
                };

        case constantes.EXIBIR_CONFIRMACAO_CRIACAO_CLASSIFICACAO:
            return action.payload
                ? {
                    ...state,
                    exibirModalConfirmacaoCriacaoDeClassificacao: action.payload
                }
                : {
                    ...state,
                    exibirModalConfirmacaoCriacaoDeClassificacao: action.payload,
                    nomeDaClassificacaoDigitado: undefined,
                    desabilitarBotaoCriarNovaClassificacao: true
                };

        // EXIBIR MODAL SUCESSO DE CRIACAO
        case constantes.EXIBIR_SUCESSO_CRIACAO:
            return {
                ...state,
                exibirSucessoCriacao: action.payload,
                nomeDoClienteProjetoDigitado: undefined,
                nomeDaClassificacaoDigitado: undefined
            };

        // EXIBIR MODAL ERRO DE CRIACAO
        case constantes.EXIBIR_ERRO_CRIACAO_CLIENTE_PROJETO:
            return {
                ...state,
                exibirModalErroCriacaoDeClienteProjeto: false,
                mensagemErroCriacaoDeClienteProjeto: undefined,
                exibirModalConfirmacaoCriacaoDeClienteProjeto: false,
                nomeDoClienteProjetoDigitado: undefined,
                desabilitarBotaoCriarNovoClienteProjeto: true
            };

        case constantes.EXIBIR_ERRO_CRIACAO_CLASSIFICACAO:
            return {
                ...state,
                exibirModalErroCriacaoDeClassificacao: false,
                mensagemErroCriacaoDeClassificacao: undefined,
                exibirModalConfirmacaoCriacaoDeClassificacao: false,
                nomeDaClassificacaoDigitado: undefined,
                desabilitarBotaoCriarNovaClassificacao: true
            };

        // ARMAZENAR PARAMETROS SELECIONADOS PARA REMOCAO
        case constantes.ARMAZENAR_CLIENTE_PROJETO_SELECIONADOS_PARA_REMOCAO:
            return {
                ...state,
                parametrosClienteProjetoParaRemocao: action.payload,
                exibirModalConfirmacaoRemocaoDeClienteProjeto: true

            };

        case constantes.ARMAZENAR_CLASSIFICACAO_SELECIONADAS_PARA_REMOCAO:
            return {
                ...state,
                parametrosClassificacaoParaRemocao: action.payload,
                exibirModalConfirmacaoRemocaoDeClassificacao: true
            };

        // EXIBIR MODAL CONFIRMACAO DE REMOCAO
        case constantes.EXIBIR_CONFIRMACAO_REMOCAO_CLIENTE_PROJETO:
            return action.payload
                ? {
                    ...state,
                    exibirModalConfirmacaoRemocaoDeClienteProjeto: action.payload
                }
                : {
                    ...state,
                    exibirModalConfirmacaoRemocaoDeClienteProjeto: action.payload,
                    parametrosClienteProjetoParaRemocao: []
                };

        case constantes.EXIBIR_CONFIRMACAO_REMOCAO_CLASSIFICACAO:
            return action.payload
                ? {
                    ...state,
                    exibirModalConfirmacaoRemocaoDeClassificacao: action.payload
                }
                : {
                    ...state,
                    exibirModalConfirmacaoRemocaoDeClassificacao: action.payload,
                    parametrosClassificacaoParaRemocao: []
                };

        // EXIBIR MODAL SUCESSO NA REMOCAO
        case constantes.EXIBIR_SUCESSO_REMOCAO:
            return {
                ...state,
                exibirSucessoRemocao: action.payload,
                parametrosClienteProjetoParaRemocao: [],
                parametrosClassificacaoParaRemocao: []
            };

        // EXIBIR MODAL ERRO DE REMOCAO
        case constantes.EXIBIR_ERRO_REMOCAO_CLIENTE_PROJETO:
            return {
                ...state,
                exibirModalErroRemocaoDeClienteProjeto: false,
                mensagemErroRemocaoDeClienteProjeto: undefined,
                exibirModalConfirmacaoRemocaoDeClienteProjeto: false,
                parametrosClienteProjetoParaRemocao: []
            };

        case constantes.EXIBIR_ERRO_REMOCAO_CLASSIFICACAO:
            return {
                ...state,
                exibirModalErroRemocaoDeClassificacao: false,
                mensagemErroRemocaoDeClassificacao: undefined,
                exibirModalConfirmacaoRemocaoDeClassificacao: false,
                parametrosClassificacaoParaRemocao: []
            };

        // ACCORDIONS / PAINEIS
        case constantes.EXPANDIR_PAINEL_CLIENTE_PROJETO:
            return {
                ...state,
                expandirPainelDeClienteProjeto: action.payload
            };

        case constantes.EXPANDIR_PAINEL_CLASSIFICACAO:
            return {
                ...state,
                expandirPainelDeClassificacao: action.payload
            };

        default:
            return state;
    }
}
