import React from 'react';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { LABEL_COMUNS, MENSAGENS } from '../../util/contantes-comuns/constantes-comuns';

const ValidaSaldoParaPedidoEmbossingCartao = props => {
    const retornaRodapeModal = () => (
        <FooterBotoesModalComponent
      contextoBppPag={props.contextoBppPag}
      btn2Label={LABEL_COMUNS.OK}
      btn2OnClick={() => props.setExibirModal(false)}
    />
    );

    const corpoModal = () => (
        <div dangerouslySetInnerHTML={{ __html: MENSAGENS.PEDIDOS.EMBOSSING_CARTAO.VALIDACAO_DE_SALDO() }} />
    );

    return (
        <div>
            <ModalComponent
        header={LABEL_COMUNS.PEDIR_CARTAO}
        body={corpoModal()}
        exibirModal={props.exibirModal}
        contextoBppPag={props.contextoBppPag}
        footer={retornaRodapeModal()}
      />
        </div>
    );
};

export default ValidaSaldoParaPedidoEmbossingCartao;
