import { METODOS } from './pedido-personalizacao-constantes';

const st = {
    dadosUrlPreAssinadaCartao: null,
    dadosUploadCartao: '',
    dadosUrlPreAssinadaCartaBerco: null,
    dadosUploadCartaBerco: '',
    dadosPedido: null,
    dadosPedidos: { cartao: {}, cartaBerco: {} },
    processando: false,
    erro: null,
    idImagemCartao: '',
    msgErroSetIdImagemCartao: '',
    idImagemCartaBerco: '',
    msgErroSetIdImagemCartaBerco: '',
    requisicaoAprovacaoImagemCartaoId: '',
    requisicaoAprovacaoImagemCartaBercoId: ''

};

export default function reducer(state = st, action) {
    switch (action.type) {
    // URL PRE ASSINADA CARTAO
        case METODOS.URL_PRE_ASSINADA_CARTAO:
            return {
                ...state,
                processando: true,
                dadosUrlPreAssinadaCartao: action.payload
            };

        // URL PRE ASSINADA CARTA BERÇO
        case METODOS.URL_PRE_ASSINADA_CARTA_BERCO:
            return {
                ...state,
                processando: true,
                dadosUrlPreAssinadaCartaBerco: action.payload
            };

        // SALVAR IMAGEM CARTAO
        case METODOS.SALVAR_CARTAO:
            let dadosSalvarCartao = st.dadosPedidos;
            if (action.payload && action.payload.data && action.payload.data.rel) {
                dadosSalvarCartao.cartao.operacaoId = action.payload.data.rel;
            }

            return {
                ...state,
                dadosPedidos: dadosSalvarCartao
            };

        // SALVAR IMAGEM CARTAO ERRO
        case METODOS.SALVAR_CARTAO_ERRO:
            let dadosSalvarCartaoErro = st.dadosPedidos;

            if (action.payload) {
                dadosSalvarCartaoErro.cartao.erro = 'ERRO';
            }

            return {
                ...state,
                dadosPedidos: dadosSalvarCartaoErro
            };

        // SALVAR IMAGEM CARTA BERCO
        case METODOS.SALVAR_CARTA_BERCO:
            let dadosSalvarCartaBerco = st.dadosPedidos;
            if (action.payload && action.payload.data && action.payload.data.rel) {
                dadosSalvarCartaBerco.cartaBerco.operacaoId = action.payload.data.rel;
            }

            return {
                ...state,
                dadosPedidos: dadosSalvarCartaBerco
            };

        // SALVAR IMAGEM CARTA BERCO ERRO
        case METODOS.SALVAR_CARTA_BERCO_ERRO:
            let dadosSalvarCartaBercoErro = st.dadosPedidos;

            if (action.payload) {
                dadosSalvarCartaBercoErro.cartaBerco.erro = 'ERRO';
            }

            return {
                ...state,
                dadosPedidos: dadosSalvarCartaBercoErro
            };

        case METODOS.SET_ID_IMG_CARTAO:
            return {
                ...state,
                idImagemCartao: action.payload,
                dadosUploadCartao: 'OK'
            };

        case METODOS.SET_ID_IMG_CARTAO_ERRO:
            return {
                ...state,
                msgErroSetIdImagemCartao: action.payload
            };

        case METODOS.SET_ID_IMG_CARTA_BERCO:
            return {
                ...state,
                idImagemCartaBerco: action.payload,
                dadosUploadCartaBerco: 'OK'
            };

        case METODOS.SET_ID_IMG_CARTA_BERCO_ERRO:
            return {
                ...state,
                msgErroSetIdImagemCartaBerco: action.payload
            };

        case METODOS.SET_REQUISICAO_APROVACAO_IMAGEM_CARTAO:
            return {
                ...state,
                processando: false,
                requisicaoAprovacaoImagemCartaoId: action.payload
            };

        case METODOS.SET_REQUISICAO_APROVACAO_IMAGEM_CARTA_BERCO:
            return {
                ...state,
                processando: false,
                requisicaoAprovacaoImagemCartaBercoId: action.payload
            };

        case METODOS.ERRO:
            return {
                ...state,
                erro: action.payload,
                dadosPedidos: 'ERRO'
            };
        default:
            return state;
    }
}
