export const METODOS = {
    GET_SALDO_CARTAO: 'getSaldoCartao',
    GET_SALDO_CARTAO_ERRO: 'getSaldoCartaoErro',
    SET_MOSTRAR_SALDO_CARTAO: 'setMostrarSaldoCartao',
    SET_SALDO: 'setSaldo'

};

export const LABEL = {
    EXIBIR_SALDO: 'exibir saldo'
};
