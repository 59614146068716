




import { efetuarLoginMfa, criarMFA, validarMFA } from '../../servicos/login/login-service';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const postMfa = (dadosLogin) => {
    return dispatch => {
        return efetuarLoginMfa(dadosLogin)
            .then(result => {
                const requestId = result.requestId;
                const secretMFA = result.secretMFA;
                const urlQRCode = result.urlQRCode;
                const tokenJWT = result.tokenJWT;
                const document = removeMascaraTelefoneCpfCnpj(dadosLogin.usuario);
                const qrCodeChecked = urlQRCode ? false : true;
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN,
                    payload: { loginEfetuadoComSucesso: false, mfaCriadoComSucesso: false, qrCodeChecked, requestId, secretMFA, urlQRCode, tokenJWT, document },
                });
            })
            .catch(error => {
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN_ERRO,
                    payload: error,
                });
            });
    };
};

export const createMFA = (dadosMfa, codeMFA) => {
    return dispatch => {
        return criarMFA({...dadosMfa, codeMFA})
            .then(result => {
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN,
                    payload: { erroValidarMfa: false, loginEfetuadoComSucesso: false, mfaCriadoComSucesso: true},
                });
            })
            .catch(error => {
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN,
                    payload: { erroValidarMfa: true }
                });
            });
    };
};

export const validateMFA = (dadosMfa, codeMFA) => {
    return dispatch => {
        return validarMFA({ ...dadosMfa, codeMFA })
            .then(result => {
                const { pessoaId, pessoaLogada } = result;
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN,
                    payload: { erroValidarMfa: false, pessoaId, pessoaLogada, loginEfetuadoComSucesso: true }
                });
            })
            .catch(error => {
                dispatch({
                    type: METODOS.LOGIN.POST_LOGIN,
                    payload: { erroValidarMfa: true }
                });
            });
    };
};

export const limpaDadosLogin = () => ({
    type: METODOS.LOGIN.LIMPA_DADOS_LOGIN,
    payload: []
});
