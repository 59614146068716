import React from 'react';

const GridRow = props => {
    return (
        <div className="row">
            {props.children}
        </div>
    );
};

export default GridRow;
