import { montaUrlFetch, urlServicos, HTTP_METODOS, HEADER_API } from '../../servicos/url-servicos';
import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const setExibirLoadingLocalidade = (exibirLoadingLocalidade) => ({
    type: METODOS.LOCALIDADE.SET_EXIBIR_LOADING_LOCALIDADE,
    payload: exibirLoadingLocalidade
});

export const getLocalidadePorCep = (cep) => {
    return async dispatch => {
        dispatch(setExibirLoadingLocalidade(true));
        getFetchLocalidadePorCep(cep)
            .then(async respLocalidade => ({ resp: await getFecthPaises(dispatch), respLocalidade }))
            .then(async ({ respLocalidade }) => ({ resp: await getFecthEstados(respLocalidade.data.paisId, dispatch), respLocalidade }))
            .then(async ({ respLocalidade }) => ({ resp: await getFetchCidades(respLocalidade.data.paisId, respLocalidade.data.estadoId, dispatch), respLocalidade }))
            .then(({ respLocalidade }) =>
                dispatch({
                    type: !respLocalidade.error ? METODOS.LOCALIDADE.GET_LOCALIDADE_POR_CEP : METODOS.LOCALIDADE.GET_LOCALIDADE_POR_CEP_ERRO,
                    payload: respLocalidade.data
                })).finally(() => dispatch(setExibirLoadingLocalidade(false)));
    };
};

export const getPaises = () => {
    return async dispatch => (await getFecthPaises(dispatch));
};

export const getEstados = (paisId) => {
    return async dispatch => (await getFecthEstados(paisId, dispatch));
};

export const getCidades = (paisId, estadoId) => {
    return async dispatch => (await getFetchCidades(paisId, estadoId, dispatch));
};

const getFetchLocalidadePorCep = async (cep) => {
    return await fetch(montaUrlFetch(urlServicos.CEPS(cep)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }));
};

const getFecthPaises = async (dispatch) => {
    return await fetch(montaUrlFetch(urlServicos.PAISES), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then((resp) => {
            dispatch({
                type: !resp.error ? METODOS.LOCALIDADE.GET_PAISES : METODOS.LOCALIDADE.GET_PAISES_ERRO,
                payload: resp.data
            });
            /* return resp.data; */
        });
};

const getFecthEstados = async (paisId, dispatch) => {
    return await fetch(montaUrlFetch(urlServicos.ESTADOS(paisId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then((resp) => {
            dispatch({
                type: !resp.error ? METODOS.LOCALIDADE.GET_ESTADOS : METODOS.LOCALIDADE.GET_ESTADOS_ERRO,
                payload: resp.data
            });
            /* return resp.data; */
        });
};

const getFetchCidades = async (paisId, estadoId, dispatch) => {
    return await fetch(montaUrlFetch(urlServicos.CIDADES(paisId, estadoId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
        .then((resp) => {
            dispatch({
                type: !resp.error ? METODOS.LOCALIDADE.GET_CIDADES : METODOS.LOCALIDADE.GET_CIDADES_ERRO,
                payload: resp.data
            });
            /* return resp.data; */
        });
};
