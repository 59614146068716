import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Grid from '../../../../componentes-uteis/grid/grid';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import './lote.css';
import { getLoteByIdArquivo } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-base';
import { CONTA_SELECIONADA } from '../../../../util/contantes-comuns/constantes-comuns';
class ProcessamentoLoteHistorico extends Component {
    constructor(props) {
        super(props);
        let idArquivo = props.location.state.idArquivo;

        this.state = { idArquivo: idArquivo, lote: null, contaSelecionada: CONTA_SELECIONADA.OBJETO() };

        if (idArquivo) { this.getProcessamentoLote(idArquivo); }
    }

    async getProcessamentoLote(idArquivo) {
        var resposta = await getLoteByIdArquivo(idArquivo);
        this.setState({ lote: resposta });
    }

    render() {
        const { contaSelecionada } = this.state;

        return (
            <div>
                <ContaSelecionada contextoBppPag contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }}>
                                <div className="cabecalho">
                                    <span>Histórico do processamento do lote</span>
                                </div>
                            </div>

                            <Grid cols="11 11 11 11" className="alinhamento-direita">
                                <Button bsStyle="success" className="botao-voltar-telas" onClick={this.props.history.goBack}>VOLTAR</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProcessamentoLoteHistorico;
