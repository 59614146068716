import { CONSTANTES } from './dados-pj-constantes';

const ESTADO_INICIAL = {
    dadosPessoaJuridica: undefined,
    erroBuscarDadosPessoaJuridica: undefined
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case CONSTANTES.OBTER_DADOS_PJ_SUCESSO:
            return {
                ...state,
                dadosPessoaJuridica: action.payload
            };

        case CONSTANTES.OBTER_DADOS_PJ_ERRO:
            return {
                ...state,
                erroBuscarDadosPessoaJuridica: action.payload
            };

        default:
            return state;
    }
};
