
import axios from 'axios';
import { HEADER_API, URL_ANEXO_PESSOA, URL_GET_LANCAMENTO_PEDIDOS, URL_GET_CONSULTA_PEDIDOS, urlServicos, HTTP_METODOS, montaUrlFetch } from '../../servicos/url-servicos';
import { METODOS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { reactLocalStorage } from 'reactjs-localstorage';
import { environment } from '../../configuracoes/environment';
import { efetivaPedidoPedidoEmbossingCartao } from '../../servicos/pedidos/pedido-base';
import { montaJsonPedidoCartaoImpressoParaBackEnd, montaListaPedidos, montaFiltroQueryString, getJsonPedidoEmbossingBppConsumer } from '../../servicos/pedidos/pedido-service';

var syncRequest = require('sync-request');

export function resetaSolicitacaoPedidoEmbossingCartao() {
    return (dispacth) => {
        dispacth({
            type: METODOS.PEDIDOS.EMBOSSING_CARTAO.RESETA_SOLICITACAO_PEDIDO_EMBOSSING_CARTAO
        });
    };
}

export function setContaSelecionada(value) {
    return {
        type: METODOS.SET_CONTA_SELECIONADA,
        payload: value
    };
}

export function setConsultaDePedidosDataInicial(e) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_DATA_INICIAL,
        payload: e.target.value
    };
}

export function setConsultaDePedidosDataFinal(e) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_DATA_FINAL,
        payload: e.target.value
    };
}

export function setAnexoContratualConsultaDePedidos(anexoContratual) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_ANEXO_CONTRATUAL_CONSULTA_DE_PEDIDOS,
        payload: anexoContratual
    };
}

export function setConsultaDePedidosOperacao(operacao) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_OPERACAO,
        payload: operacao
    };
}

export function setFiltroConsultaDePedidosPorValor(obj) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_FILTRO_CONSULTA_DE_PERIDOS_POR_VALOR,
        payload: obj
    };
}

export function setConsultaDePedidosStatus(statusPedido) {
    return {
        type: METODOS.PEDIDOS.CONSULTA.SET_CONSULTA_DE_PEDIDOS_STATUS,
        payload: statusPedido
    };
}

export const setExibirLoadingDashboardConsultaPedido = (exibirLoadingDashboardConsultaPedido) => ({
    type: METODOS.PEDIDOS.CONSULTA.SET_EXIBIR_LOADING_DASHBOARD_CONSULTA_PEDIDO,
    payload: exibirLoadingDashboardConsultaPedido
});

export const setExibirLoadingPedidoCartaoImpresso = (exibirLoadingPedidoCartaoImpresso) => ({
    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_EXIBIR_LOADING_PEDIDO_CARTAO_IMPRESSO,
    payload: exibirLoadingPedidoCartaoImpresso
});

export const limparFiltroPedidos = () => ({
    type: METODOS.PEDIDOS.LIMPAR_FILTRO_PEDIDOS,
    payload: ''
});

export const setRequisicaoPedidoEmbossingId = (valor) => ({
    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_REQUISICAO_PEDIDO_EMBOSSING_ID,
    payload: valor
});

export const limparPedidos = () => ({
    type: METODOS.PEDIDOS.LIMPAR_PEDIDOS,
    payload: []
});

export function getOperacoes(produto) {
    return (dispatch) => {
        axios.get(URL_GET_LANCAMENTO_PEDIDOS(produto), HEADER_API())
            .then((response) => {
                dispatch({
                    type: METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_OPERACOES,
                    payload: response.data
                });
            })
            .catch((err) => {
                dispatch({
                    type: METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_OPERACOES_ERROS,
                    payload: err.response
                });
            });
    };
}

export function getPedidos(contaId, produto, dataInicial, dataFinal, anexoContratualId, valorDe, valorAte, operacao, status, ultimaChave, pedidosExistentes) {
    let resposta;
    return (dispatch) => {
        dispatch(setExibirLoadingDashboardConsultaPedido(true));
        axios.get(URL_GET_CONSULTA_PEDIDOS(contaId, montaFiltroQueryString(produto, dataInicial, dataFinal, anexoContratualId, valorDe, valorAte, operacao, status, ultimaChave)), HEADER_API())
            .then((response) => {
                resposta = montaListaPedidos(pedidosExistentes, response.data);
                dispatch(
                    {
                        type: METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_PEDIDOS,
                        payload: resposta
                    }
                );
            }).catch((err) => {
                let dataErr = { response: { data: '' } };
                try {
                    dataErr.response.data = err.response.data;
                } catch (error) {

                }
                dispatch(
                    {
                        type: METODOS.PEDIDOS.CONSULTA.GET_CONSULTA_DE_PEDIDOS_ERRO,
                        payload: dataErr.response.data
                    }
                );
            }).finally(() => dispatch(setExibirLoadingDashboardConsultaPedido(false)));
    };
}

export const getUrlPreAssinadaCartaoOuCartaBercoPorArquivoId = (arquivoId, cartao) => {
    return dispatch => {
        dispatch(setExibirLoadingPedidoCartaoImpresso(true));
        return fetch(montaUrlFetch(urlServicos.ARQUIVO_ID(arquivoId)), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        })
            .then(async resp => ({ error: !resp.ok, data: await resp.json() }))
            .then((resp) => {
                let urlImagem = {};
                if (cartao) {
                    urlImagem.cartao = resp.data;
                } else {
                    urlImagem.cartaBerco = resp.data;
                }
                dispatch({
                    type: !resp.error ? METODOS.PEDIDOS.EMBOSSING_CARTAO.GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID : METODOS.PEDIDOS.EMBOSSING_CARTAO.GET_URL_PRE_ASSINADA_CARTAO_OU_CARTA_BERCO_POR_ARQUIVO_ID_ERRO,
                    payload: urlImagem
                });
            }).finally(() => dispatch(setExibirLoadingPedidoCartaoImpresso(false)));
    };
};

export const exibiModalToken = (value) => {
    return dispatch => {
        dispatch({
            type: 'petoken',
            payload: value
        });
    };
};

export const efetuarPedidoEmbossingCartao = (dadosPedidoEmbossingCartao, necessitaToken, outorgaPodeAprovar, pedidoEmbossingBppConsumer) => {

    let bodyPedidoEmbossingCartao;

    if (!pedidoEmbossingBppConsumer) {
        bodyPedidoEmbossingCartao = montaJsonPedidoCartaoImpressoParaBackEnd(dadosPedidoEmbossingCartao);
    } else {
        bodyPedidoEmbossingCartao = getJsonPedidoEmbossingBppConsumer(pedidoEmbossingBppConsumer);
    }

    if (necessitaToken && outorgaPodeAprovar) {
        return dispatch => {
            fetch(montaUrlFetch(urlServicos.OPERACAO), {
                method: HTTP_METODOS.POST,
                headers: HEADER_API().headers,
                body: bodyPedidoEmbossingCartao
            })
                .then(response => response.json())
                .then(async dadosRetornados => ({ response: await fetchSolicitacaoReenviarToken(dadosRetornados.rel), operacaoId: dadosRetornados.rel }))
                .then(async ({ response, operacaoId }) => {
                    dispatch({
                        type: response.ok ? METODOS.PEDIDOS.EMBOSSING_CARTAO.REQUISITAR_TOKEN_MODAL : METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO,
                        payload: response.ok ? { rel: operacaoId } : await response.json()
                    });
                })
                .catch(err => {
                    dispatch({
                        type: METODOS.PAGAMENTOS_DE_CONTAS.SOLICITAR_PAGAMENTOS_ERRO,
                        payload: { erros: ['Tente novamente mais tarde' || err] }
                    });
                });
        };
    }
    if (outorgaPodeAprovar) {
        return dispatch => {
            fetch(montaUrlFetch(urlServicos.OPERACAO), {
                method: HTTP_METODOS.POST,
                headers: HEADER_API().headers,
                body: bodyPedidoEmbossingCartao
            })
                .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }))
                .then(async ({ data: { rel } }) => efetivaPedidoPedidoEmbossingCartao(rel, dadosPedidoEmbossingCartao ? dadosPedidoEmbossingCartao.valorEntrega : JSON.stringify(pedidoEmbossingBppConsumer.orderTax.orderValue)))
                .then((result) => {
                    dispatch({
                        type: METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO,
                        payload: result.data,
                        message: 'Operação cadastrada e aprovada com sucesso.'
                    });
                }).catch((err) => {
                    dispatch({
                        type: err.data && !err.data.codigo ? METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO_CORE : METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO,
                        payload: err.data && err.data.codigo ? err.data : ''
                    });
                });
        };
    }
    return dispatch => {
        fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: bodyPedidoEmbossingCartao
        })
            .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }))
            .then((result) => {
                dispatch({
                    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO,
                    payload: result.data,
                    message: 'Operação cadastrada e pendente de aprovação.'
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO,
                    payload: err.data
                });
            });
    };
};

export const fetchSolicitacaoReenviarToken = (operacaoId) => {
    return fetch(montaUrlFetch(urlServicos.SOLICITAR_TOKEN_OPERACAO(operacaoId), { canalToken: 'SMS', transacao: 'aprovacao' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    });
};

export const solicitacaoReenviarToken = (operacaoId) => {
    return dispatch => {
        return fetchSolicitacaoReenviarToken(operacaoId)
            .then(async (response) => {
                dispatch({
                    type: response.ok ? METODOS.PEDIDOS.EMBOSSING_CARTAO.REQUISITAR_TOKEN_MODAL : METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO,
                    payload: response.ok ? { rel: operacaoId } : await response.json()
                });
            });
    };
};

export const setLimparSolicitacaoDePedidoEmbossingCartao = () => ({
    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.SET_LIMPAR_SOLICITACAO_DE_PEDIDO_EMBOSSING_CARTAO,
    payload: {}
});

export const efetivarPedidoComToken = (id, valorEntrega, token) => {
    return dispatch => {
        return efetivaPedidoPedidoEmbossingCartao(id, valorEntrega, token)
            .then((result) => {
                dispatch({
                    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO,
                    payload: result.data,
                    message: 'Operação cadastrada e aprovada com sucesso.'
                });
            }).catch((err) => {
                dispatch({
                    type: METODOS.PEDIDOS.EMBOSSING_CARTAO.EFETUAR_PEDIDO_EMBOSSING_CARTAO_ERRO,
                    payload: err.data
                });
            });
    };
};
