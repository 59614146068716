import React, { Component } from 'react';
import './input-image-file-preview.css';
import { LABEL_COMUNS, MENSAGENS } from '../../util/contantes-comuns/constantes-comuns';
import ShowLineError from '../showLineError/show-line-error';
import If from '../../componentes-uteis/if/if';

class InputImageFilePreview extends Component {

    state = {
        imagePreview: this.props.imagePreview || {}, imagemErro: ''
    }

    onClearImagePreview = (e) => {
        this.setState({ imagePreview: {} });
        this.props.onChange && this.props.onChange();
        e.preventDefault();
    }

    componentWillReceiveProps = (nextProps) => {
        const { imagePreview } = nextProps;
        if (imagePreview) {
            this.setState({ imagePreview });
        }
    }


    isArquivoValido = (arquivo) => {
        if (arquivo.size < 10e+6) {
            if (arquivo.type !== 'image/png' && arquivo.type !== 'image/jpeg' && arquivo.type !== 'image/jpg' && arquivo.type !== 'application/pdf') {
                const imagemErro = <div dangerouslySetInnerHTML={{ __html: MENSAGENS.VALIDACAO_DE_CAMPOS.ARQUIVO.EXTENSAO_NAO_SUPORTADA.IMAGEM }} />;
                this.setState({ imagemErro });
                return false;
            }
            return true;
        }
        else {
            const imagemErro = <div dangerouslySetInnerHTML={{ __html: MENSAGENS.VALIDACAO_DE_CAMPOS.ARQUIVO.TAMANHO_NAO_SUPORTADO }} />;
            this.setState({ imagemErro });
            return false;
        }
    }

    onChangeImageFile = (e) => {
        const file = e.target.files[0];
        if (this.isArquivoValido(file)) {
            if (FileReader && file) {
                var fr = new FileReader();
                fr.onload = () => {
                    this.setState({ imagePreview: { urlMiniatura: fr.result, urlImagem: fr.result }, imagemErro: '', file });
                };
                fr.readAsDataURL(file);
            }
            this.props.onChange && this.props.onChange(file);
        }

    }


    render() {
        const EmptyState = () => (
            <div className="emptyStatePreview">
                <span className="glyphicon glyphicon-camera azul emptyStatePreviewIcon" />
            </div>
        );

        const ImagePreview = () => {
            return (
                <div className="imagePreviewContainer">
                    <img className="imagePreview" src={this.state.imagePreview.urlMiniatura} onClick={() => this.props.setExibirImagem(true, this.state.imagePreview.urlImagem)} />
                    <div className="imagePreviewClear" onClick={this.onClearImagePreview}>{LABEL_COMUNS.EXCLUIR}</div>
                </div >
            );
        };

        return (
            <div>
                <label>
                    <div>
                        {this.state.imagePreview.urlMiniatura ? <ImagePreview /> : <EmptyState />}
                    </div>
                    {!this.state.imagePreview.urlMiniatura &&
                        <input style={{ display: 'none' }} type="file"
                            ref={ref => this.fileInput = ref}
                            onChange={(e) => {
                                this.onChangeImageFile(e);
                                this.fileInput.value = null;
                            }} accept="image/*, application/pdf" />
                    }
                </label>
                <ShowLineError error={this.state.imagemErro} />
            </div>
        );
    }
}

export default InputImageFilePreview;