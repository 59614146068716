import React, { Component } from 'react';
import { CANAL_TOKEN, LABEL_COMUNS, MENSAGENS, OPERACAO, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { desbloquearCartao } from 'servicos/cartoes/cartoes-service';
import Token from 'componentes/comuns/token/token';

class DesbloquearCartao extends Component {

    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            showUnlockModal: false,
            messageUnlockModal: '',
            operationId: '',
            showUnlockFinalizationModal: false,
            unlockFinalizationMessage: '',
            showUnlockModalToken: false,
            canApprove: false
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillReceiveProps = (nextProps) => {
        const { grantProfile } = nextProps;
        const canApprove = grantProfile.podeAprovar;
        const { unlockSelectedCard } = nextProps;
        this.setState({ canApprove });
        this.setUnlockSelectedCard(unlockSelectedCard);
        this.setUnlockMessageModal(MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.CONFIRMACAO(OPERACAO.NOME.DESBLOQUEIO_CARTAO, unlockSelectedCard.idCartaoImpresso, unlockSelectedCard.panFormatado));
    };

    setUnlockSelectedCard = (unlockSelectedCard) => {
        this.setState({ unlockSelectedCard });
    };

    setUnlockMessageModal = (messageUnlockModal) => {
        this.setState({ messageUnlockModal });
    };

    setUnlockFinalizationMessage = (unlockFinalizationMessage) => {
        this.setState({ unlockFinalizationMessage });
    };

    setShowUnlockModalToken = (showUnlockModalToken) => {
        this.setState({ showUnlockModalToken });
    };

    setOperationId = (operationId) => {
        this.setState({ operationId });
    };

    setShowUnlockFinalizationModal = (showUnlockFinalizationModal) => {
        this.setState({ showUnlockFinalizationModal });
    };

    onUnlockCard = async () => {
        this.props.setShowUnlockLoading(true);
        const { grantUnlockCard } = this.props;
        const necessitaOtp = grantUnlockCard.aprovacao;

        this.props.setShowUnlockCardModal(false);
        const response = await desbloquearCartao(this.state, necessitaOtp);
        const { message, operationId } = response;
        if (message) {
            this.setUnlockFinalizationMessage(message);
            this.props.setShowUnlockFinalizationModal(true);
        }
        else {
            if (operationId) {
                this.setOperationId(operationId);
                this.setShowUnlockModalToken(true);
            }
        }
        this.props.unlockGetCards();


        this.props.setShowUnlockLoading(false);
    };

    callBackTransaction = (retorno) => {
        let message = MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO();
        if (retorno.error) {
            message = MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO();
        }
        this.setUnlockFinalizationMessage(message);
        this.props.setShowUnlockFinalizationModal(true);
    };

    clearState = () => {
        this.setState(this.INITIAL_STATE);
    };

    render() {
        const { showUnlockCardModal, showUnlockFinalizationModal } = this.props;
        const { messageUnlockModal, operationId, showUnlockModalToken, unlockFinalizationMessage } = this.state;
        const transacao = TIPO_DE_TRANSACAO.APROVACAO;


        return (
            <div>
                <ModalComponent exibirModal={showUnlockCardModal}
                    name="modal-desbloquear-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: messageUnlockModal }} />}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={this.props.contextoBppPag}
                            btn1Label={LABEL_COMUNS.NAO}
                            btn1OnClick={() => { this.props.setShowUnlockCardModal(false); }}
                            btn2Label={LABEL_COMUNS.SIM}
                            btn2OnClick={() => { this.onUnlockCard(); }}
                        />
                    } />
                <ModalComponent exibirModal={showUnlockFinalizationModal}
                    name="modal-desbloquear-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: unlockFinalizationMessage }} />}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={this.props.contextoBppPag}
                            btn2Label={LABEL_COMUNS.OK}
                            btn2OnClick={() => {
                                this.props.setShowUnlockFinalizationModal(false);
                                this.props.unlockGetCards();
                                this.clearState();
                            }}
                        />
                    } />

                <Token
                    contextoBppPag={this.props.contextoBppPag}
                    dadosIntegracao={transacao}
                    dadosOtp={{ ...CANAL_TOKEN.CORPO_REQUISICAO_SMS, ...transacao }}
                    exibirModal={showUnlockModalToken}
                    fecharModal={() => { this.setShowUnlockModalToken(false); }}
                    operacaoId={operationId}
                    titulo={LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                    resultadoCallback={this.callBackTransaction}
                />

            </div>
        );
    }
}

export default DesbloquearCartao;
