import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import If from '../../../../componentes-uteis/if/if';
import { CARTAO_STATUS, LABEL_COMUNS, OPERACAO } from '../../../../util/contantes-comuns/constantes-comuns';
import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import Grid from '../../../../componentes-uteis/grid/grid';
import MostrarSaldoCartao from '../../../../contas/saldo/cartao/mostrar-saldo-cartao';
import { formatarStatus } from '../../../../util/cartoes/cartoes-util';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';

class GridCartoesDashboardBppConsumer extends Component {
    render() {
        const { podeConsultar, podeCriar } = this.props.outorgaPerfils;
        return (
            <div>
                <div>
                    <If test={this.props.listaDeCartoes && this.props.listaDeCartoes.length > 0}>
                        <div className="row">
                            <Grid cols="12 12 12 12" className="grids-dashboards">
                                <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.PORTADOR}
                                    </span>
                                </Grid>
                                <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.CARTAO}
                                    </span>
                                </Grid>
                                <Grid cols="2 2 2 2" className="alinhamento-centro">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.CARTOES.CARTAO_ID}
                                    </span>
                                </Grid>
                                <If test={podeConsultar}>
                                    <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                        <span className="titulo-dashboards-conta-solucao">
                                            {LABEL_COMUNS.CARTOES.SALDO_DO_CARTAO}
                                        </span>
                                    </Grid>
                                </If>
                                <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                    <span className="titulo-dashboards-conta-solucao">
                                        {LABEL_COMUNS.STATUS}
                                    </span>
                                </Grid>
                                <If test={podeConsultar}>
                                    <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                        <span className="titulo-dashboards-conta-solucao">
                                            {LABEL_COMUNS.ACOES}
                                        </span>
                                    </Grid>
                                </If>
                            </Grid>
                        </div>
                        <div className="grid-agendamento grids-dashboards">
                            {this.props.listaDeCartoes.map((cartao, idx) => {
                                const desabilitaBotoesCartao = (cartao.status !== CARTAO_STATUS.ATIVO && cartao.status !== CARTAO_STATUS.BLOQUEADO && cartao.status !== CARTAO_STATUS.PRONTOS_PARA_ATIVACAO && cartao.status !== CARTAO_STATUS.CANCELADO);
                                return (
                                    <div className="row" key={idx}>
                                        <Grid cols="12 12 12 12">
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                <span style={{ fontSize: '11px', lineHeight: '2.2' }}>
                                                    {cartao.nomePortador}
                                                </span>
                                            </Grid>
                                            <Grid cols="3 3 3 3" className="alinhamento-esquerda">
                                                <span style={{ fontSize: '11px', lineHeight: '2.2' }}>
                                                    {cartao.panFormatado}
                                                </span>
                                            </Grid>
                                            <Grid cols="2 2 2 2" className="alinhamento-centro" >
                                                <span style={{ fontSize: '11px', lineHeight: '2.2' }}>
                                                    <strong>{cartao.idCartaoImpresso}</strong>
                                                </span>
                                            </Grid>
                                            <If test={podeConsultar}>
                                                <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                    <span>
                                                        <MostrarSaldoCartao contaCartaoId={cartao.cartaoId} />
                                                    </span>
                                                </Grid>
                                            </If>
                                            <Grid cols="2 2 2 2" className="alinhamento-esquerda">
                                                <span style={{ fontSize: '11px', lineHeight: '2.2' }}>
                                                    {formatarStatus(cartao.status)}
                                                </span>
                                            </Grid>
                                            <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-10px' }} disabled={desabilitaBotoesCartao}>
                                                    <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                        <Glyphicon glyph="option-horizontal" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                            <If test={podeCriar}>

                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowActivateCardModal(true);
                                                                }}>
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.ATIVAR}
                                                                </MenuItem>
                                                            </If>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.BLOQUEADO || cartao.status === CARTAO_STATUS.CANCELADO}>
                                                            <li onClick={() => this.props.setArmazenarCartao(cartao)}>
                                                                <Link className="link_expandir" to={ROTAS_CAMINHOS.EXTRATO_CARTAO_BPP_CONSUMER}>
                                                                    {LABEL_COMUNS.EXTRATO}
                                                                </Link>
                                                            </li>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                            <If test={podeCriar}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowChargeOrDischargeCard(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CARGA_CARTAO);
                                                                }}>
                                                                    {LABEL_COMUNS.CARREGAR_CARTAO}
                                                                </MenuItem>
                                                                <MenuItem
                                                                    onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowChargeOrDischargeCard(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESCARGA_CARTAO);
                                                                    }}>
                                                                    {LABEL_COMUNS.DESCARREGAR_CARTAO}
                                                                </MenuItem>
                                                            </If>

                                                            <MenuItem onSelect={() => this.props.setArmazenarCartao(cartao)}
                                                                onClick={() => this.props.setExibirModalDetalhePortador(true)}>
                                                                {LABEL_COMUNS.DETALHE_PORTADOR}
                                                            </MenuItem>

                                                            <If test={podeCriar}>
                                                                <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowModalAlterarSenhaCartao(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.ALTERACAO_PIN_CARTAO);
                                                                    }} >
                                                                        {LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                                                                    </MenuItem>
                                                                </If>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowLockCardModal(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.ALTERA_STATUS_CARTAO);
                                                                }}>
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                                                                </MenuItem>

                                                            </If>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.BLOQUEADO || cartao.status === CARTAO_STATUS.CANCELADO || cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                            <MenuItem onSelect={() => this.props.setArmazenarCartao(cartao)}
                                                                onClick={() => this.props.setExibirModalDetalhePortador(true)}>
                                                                {LABEL_COMUNS.DETALHE_PORTADOR}
                                                            </MenuItem>

                                                        </If>


                                                        <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                            <If test={podeCriar}>
                                                                <MenuItem
                                                                    onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowCancelCardModal(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CANCELA_CARTAO);
                                                                    }}
                                                                >
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                                                                </MenuItem>
                                                            </If>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.BLOQUEADO}>
                                                            <If test={podeCriar}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowUnlockCardModal(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESBLOQUEIO_CARTAO);
                                                                }}>
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                                                                </MenuItem>
                                                            </If>
                                                        </If>

                                                        <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO && this.props.vincularPortador}>
                                                            <If test={!cartao.portadorPessoaId}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setCartaoSelecionadoParaVincularPortador(cartao);
                                                                }} onClick={() => this.props.setExibirModalDeVincularPortador(true)}>{LABEL_COMUNS.VINCULAR_PORTADOR}
                                                                </MenuItem>
                                                            </If>
                                                        </If>

                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </div>
                    </If>
                    <If test={this.props.listaDeCartoes.length <= 0}>
                        <span>
                            {LABEL_COMUNS.NENHUM_CARTAO_ENCONTRADO(this.props.statusCartaoNaoEncontrado)}
                        </span>
                    </If>
                </div>
            </div>
        );
    }
}

export default GridCartoesDashboardBppConsumer;
