import { montaDadosJsonDoPortadorParaBackEnd } from '../../../util/cartoes/cartoes-util';
import { montaUrlFetch, urlServicos, HTTP_METODOS, HEADER_API } from '../../../../servicos/url-servicos';
import { METODOS } from './vincular-portador-contantes';

export const setCartaoSelecionadoParaVincularPortador = (cartaoSelecionado) => ({
    type: METODOS.SET_CARTAO_SELECIONADO_PARA_VINCULAR_PORTADOR,
    payload: cartaoSelecionado
});

export const setDataDeNascimentoDoPortador = (e) => ({
    type: METODOS.SET_DATA_DE_NASCIMENTO_DO_PORTADOR,
    payload: e.target.value
});

export const setNumeroDoDocumentoDoPortador = (e) => ({
    type: METODOS.SET_NUMERO_DO_DOCUMENTO_DO_PORTADOR,
    payload: e.target.value
});

export const setNomeDoPortador = (e) => ({
    type: METODOS.SET_NOME_DO_PORTADOR,
    payload: e.target.value
});

export const setExibirModalMensagem = (value) => ({
    type: METODOS.SET_EXIBIR_MENSAGEM,
    payload: value
});

export const setExibirModalDeVincularPortador = (value) => ({
    type: METODOS.SET_EXIBIR_MODAL_DE_VINCULAR_PORTADOR,
    payload: value
});

export const limparCamposDeErros = () => ({
    type: METODOS.LIMPAR_CAMPOS_DE_ERROS,
    payload: ''
});

export const vincularPortador = (dadosForm) => {
    dadosForm = montaDadosJsonDoPortadorParaBackEnd(dadosForm);
    return dispatch => {
        fetch(montaUrlFetch(urlServicos.VINCULAR_PORTADOR), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: dadosForm
        }).then(response => {
            response.json().then(dadosRetornados => {
                dispatch({
                    type: response.ok ? METODOS.VINCULAR_PORTADOR : METODOS.VINCULAR_PORTADOR_ERRO,
                    payload: dadosRetornados
                });
            });
        });
    };
};
