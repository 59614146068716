import Controle from './gerenciar-prestacao-conta-controlador';
import { TipoOperacao, PAGINACAO } from './gerenciar-prestacao-conta-constante';
import { preencheCamposDeErros, montaPrestacoes, montaComboDeClassificacoes, montaComboDeProjetos } from '../prestacao/prestacao-conta-util';
import { formatarDataUS } from '../../util/data/dataUtil';

const localState = {
    'contaDigital': null,
    'nomePortador': null,
    'listaTransacoes': [],
    'listaTipoDespesas': [],
    'listaClassificacao': [],
    'listaClienteProjeto': [],
    'listaDePrestacaoConta': [],
    'listaDePrestacaoContaCSV': [],
    'listCheckboxSelecionado': [
        {
            'clienteProjetoId': null,
            'comprovantes': null,
            'contaCartaoId': null,
            'contaDigitalId': null,
            'dataAlteracao': null,
            'dataCriacao': null,
            'observacao': null,
            'status': null,
            'tipoDespesaId': null,
            'transacaoId': null,
            'msgErro': '',
            'tipoErro': ''
        }
    ],
    'listaDePrestacaoContaBind': [],
    'exibirTelaDeConfirmacao': false,
    'tipoOperacao': TipoOperacao._APROVAR,
    'queryString': {
        'periodo': null,
        'dataInicial': '',
        'dataFinal': '',
        'status': null,
        'clienteProjetoId': null,
        'classificacao': null,
        'contaCartaoId': null,
        'cartaoId': null,
        'nomePortador': null,
        'documentoPortador': null,
        'ultimaChave': null,
        'tamanhoPagina': PAGINACAO,
        'totalElementos': 0,
        'quantidadeTotalRegistros': 0
    },
    'viewImagens': {},
    'mensagem': '',
    'showDialogImages': false,
    'showDialogSucesso': false,
    'showDialogErros': false,
    'loading': false,
    'msgDialogSucesso': '',
    'msgDialogErros': false,
    'msgErroExportarCSV': '',
    'toggleCheckAll': false,
    'response': [
        {
            'codigo': '',
            'erros': [
                {
                    'mensagem': null,
                    'campo': null
                }
            ]
        }
    ],
    'quantidadeTotalRegistros': 0,
    'btnLimpar': false,
    'errosNaBuscaDasPrestacoesDeContas': [],
    'reload': false,
    visualizarImagem: ''
};
export default function reducer(state = localState, action) {
    switch (action.type) {
        case Controle.getListaContaDigitalControl():
        {
            return {
                ...state,
                contaDigital: action.payload
            };
        }
        case Controle.getListaPortadorControl():
        {
            return {
                ...state,
                nomePortador: action.payload
            };
        }
        case Controle.getListaPrestacaoContaRegistrosControl(): {
            return {
                ...state,
                queryString: {
                    ...state.queryString,
                    quantidadeTotalRegistros: action.payload
                },
                quantidadeTotalRegistros: action.payload
            };
        }
        case Controle.getListaPrestacaoContaControl():
        {
            let listaTratadaDePrestacoes = montaPrestacoes(action.payload);
            let listCheckboxSelecionado = [
                {
                    'clienteProjetoId': null,
                    'comprovantes': null,
                    'contaCartaoId': null,
                    'contaDigitalId': null,
                    'dataAlteracao': null,
                    'dataCriacao': null,
                    'observacao': null,
                    'descricaoAprovacao': null,
                    'status': null,
                    'tipoDespesaId': null,
                    'transacaoId': null,
                    'msgErro': null
                }
            ];

            return {
                ...state,
                listaDePrestacaoConta: concatenarListas(state.listaDePrestacaoConta, listaTratadaDePrestacoes),
                listCheckboxSelecionado: listCheckboxSelecionado
            };
        }

        case Controle.getListaPrestacaoContaControlErro(): {
            return {
                ...state,
                errosNaBuscaDasPrestacoesDeContas: action.payload.data.erros
            };
        }

        case Controle.getLimparPrestacaoContaBindControl():
        {
            return {
                ...state,
                listaDePrestacaoContaBind: []
            };
        }
        case Controle.getLimparPrestacaoContaControl():
        {
            return {
                ...state,
                listaDePrestacaoConta: []
            };
        }
        case Controle.getListaTransacoesControl():
        {
            return {
                ...state,
                listaTransacoes: action.payload
            };
        }
        case Controle.getListaTipoDespesaControl():
        {
            return {
                ...state,
                listaTipoDespesas: action.payload
            };
        }
        case Controle.getListaPrestacaoContaListaClienteProjetoControl():
        {
            action.payload.prestacaoConta = state.listaDePrestacaoConta.concat(action.payload.prestacaoConta);
            return {
                ...state,
                listaDePrestacaoConta: action.payload.prestacaoConta,
                listaClienteProjeto: action.payload.parametrosConta ? action.payload.parametrosConta : []
            };
        }
        case Controle.getListaClienteProjetoControl():
        {
            return {
                ...state,
                listaClienteProjeto: action.payload
            };
        }
        case Controle.getBindPrestacaoContaControl():
        {
            state.listaDePrestacaoConta.map((prestacaoConta) => {
                let obj = {
                    'transacaoId': '',
                    'contaDigitalId': '',
                    'tipoDespesaId': '',
                    'clienteProjetoId': '',
                    'status': '',
                    'dataCriacao': '',
                    'dataAlteracao': '',
                    'observacao': '',
                    'descricaoAprovacao': '',
                    'comprovantes': [],
                    'clienteProjeto': '',
                    'valorTransacao': '',
                    'tipoDaDespesa': '',
                    'estabelecimento': '',
                };
                obj.transacaoId = prestacaoConta.transacaoId;
                obj.contaDigitalId = prestacaoConta.contaDigitalId;
                obj.tipoDespesaId = prestacaoConta.tipoDespesaId;
                obj.clienteProjetoId = prestacaoConta.clienteProjetoId;
                obj.status = prestacaoConta.status;
                obj.dataCriacao = prestacaoConta.dataCriacao;
                obj.dataAlteracao = prestacaoConta.dataAlteracao;
                obj.observacao = prestacaoConta.observacao;
                obj.descricaoAprovacao = prestacaoConta.descricaoAprovacao;
                obj.comprovantes = prestacaoConta.comprovantes;
                let clProjeto = state.listaClienteProjeto.find((clienteProjeto) => {
                    return obj.clienteProjetoId === clienteProjeto.id;
                });
                let transacao = state.listaTransacoes.find((transac) => {
                    return obj.transacaoId === transac.id;
                });
                let tipoDespesa = state.listaTipoDespesas.find((tipoDesp) => {
                    return obj.tipoDespesaId === tipoDesp.id;
                });
                obj.clienteProjeto = clProjeto !== undefined ? clProjeto.descricao : '';
                obj.valorTransacao = transacao !== undefined ? transacao.valorTransacao : '';
                obj.tipoDaDespesa = tipoDespesa !== undefined ? tipoDespesa.descricao : '';
                obj.estabelecimento = prestacaoConta.dadosExtrato.descricao;

                return state.listaDePrestacaoContaBind.push(obj);
            });
            return {
                ...state,
                listaDePrestacaoContaBind: state.listaDePrestacaoContaBind
            };
        }
        case Controle.getSelecionarCheckBoxControl():
        {
            var checkBoxs = state.listCheckboxSelecionado;
            var checkAll = state.toggleCheckAll;

            if (!action.isCheckAll) {
                let index = checkBoxs.findIndex(item => item.id === action.payload.id);
                if (index === -1) {
                    checkBoxs.push(action.payload);
                } else {
                    checkBoxs.splice(index, 1);
                    checkAll = false;
                }
            }

            return {
                ...state,
                listCheckboxSelecionado: checkBoxs,
                toggleCheckAll: checkAll
            };
        }
        case Controle.getLimparCheckBoxSelecionadoControl():
        {
            let listCheckboxSelecionado = [
                {
                    'clienteProjetoId': null,
                    'comprovantes': null,
                    'contaCartaoId': null,
                    'contaDigitalId': null,
                    'dataAlteracao': null,
                    'dataCriacao': null,
                    'observacao': null,
                    'status': null,
                    'tipoDespesaId': null,
                    'transacaoId': null,
                    'msgErro': '',
                    'tipoErro': ''
                }
            ];
            return {
                ...state,
                listCheckboxSelecionado: listCheckboxSelecionado
            };
        }
        case Controle.getPreencherObservacaoControl():
        {
            let checkBoxs = state.listCheckboxSelecionado;
            let index = checkBoxs.findIndex(item => item.id === action.payload.id);
            checkBoxs[index].descricaoAprovacao = action.payload.descricaoAprovacao;

            checkBoxs.splice(index, 1);
            checkBoxs.push(action.payload);

            return {
                ...state,
                listCheckboxSelecionado: checkBoxs
            };
        }
        case Controle.getAbrirTelaDeConfirmacaoControl():
        {
            return {
                ...state,
                exibirTelaDeConfirmacao: action.payload
            };
        }
        case Controle.getDefinirTipoOperacaoControl():
        {
            return {
                ...state,
                tipoOperacao: action.payload
            };
        }
        case Controle.getDefinirFiltrosControl():
        {
            if (action.payload.chave !== undefined && action.payload.chave === 'periodo') {
                state.queryString.periodo = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'dataInicial') {
                state.queryString.dataInicial = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'dataFinal') {
                state.queryString.dataFinal = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'status') {
                state.queryString.status = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'clienteProjetoId') {
                state.queryString.projeto = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'classificacao') {
                state.queryString.classificacao = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'contaCartaoId') {
                state.queryString.contaCartaoId = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'cartaoId') {
                state.queryString.cartaoId = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'nomePortador') {
                state.queryString.nomePortador = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'documentoPortador') {
                state.queryString.documentoPortador = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'tamanhoPagina') {
                state.queryString.tamanhoPagina = action.payload.valor;
            }
            if (action.payload.chave !== undefined && action.payload.chave === 'ultimaChave') {
                state.queryString.ultimaChave = action.payload.valor;
            }
            return {
                ...state,
                queryString: state.queryString,
                errosNaBuscaDasPrestacoesDeContas: []
            };
        }
        case Controle.getLimparFiltrosControl():
        {
            let queryString = {
                'periodo': null,
                'dataInicio': null,
                'dataFinal': null,
                'status': null,
                'clienteProjeto': null,
                'classificacao': null,
                'contaCartaoId': null,
                'cartaoId': null,
                'nomePortador': null,
                'documentoPortador': null,
                'ultimaChave': null,
                'tamanhoPagina': PAGINACAO
            };
            return {
                ...state,
                queryString: queryString
            };
        }

        case Controle.getDefinirMessagemOperacaoControl():
        {
            return {
                ...state,
                mensagem: action.payload
            };
        }
        case Controle.getLoadingControl():
        {
            return {
                ...state,
                loading: !state.loading
            };
        }
        case Controle.getViewImagensControl():
        {
            return {
                ...state,
                viewImagens: action.payload
            };
        }
        case Controle.getToggleTelaDeImagensControl():
        {
            return {
                ...state,
                showDialogImages: !state.showDialogImages
            };
        }
        case Controle.getMsgErroCheckBoxControl():
        {
            let checkBoxs = state.listCheckboxSelecionado;
            let index = checkBoxs.findIndex(item => item === action.payload);
            if (index === 1) {
                checkBoxs = checkBoxs.filter(item => item !== action.payload);
                checkBoxs.push(action.payload);
            }
            return {
                ...state,
                listCheckboxSelecionado: checkBoxs
            };
        }
        case Controle.getLimparMsgErroPrestacaoControl():
        {
            let newListaDePrestacaoConta = [];
            if (state.listaDePrestacaoConta !== undefined && state.listaDePrestacaoConta.length > 0) {
                state.listaDePrestacaoConta.map((item) => {
                    if (item.msgErro !== undefined && item.msgErro !== null) {
                        item.msgErro = null;
                    }
                    return newListaDePrestacaoConta.push(item);
                });
                return {
                    ...state,
                    listaDePrestacaoConta: newListaDePrestacaoConta
                };
            }
            break;
        }
        case Controle.getListaPrestacaoContaCSVControl():
        {
            return {
                ...state,
                listaDePrestacaoContaCSV: action.payload
            };
        }
        case Controle.limparDadosCSV():
        {
            return {
                ...state,
                listaDePrestacaoContaCSV: action.payload
            };
        }
        case Controle.getShowMsgDialogErrosControl():
        {
            return {
                ...state,
                showDialogErros: action.payload
            };
        }
        case Controle.getMsgDialogErrosControl():
        {
            return {
                ...state,
                msgDialogErros: action.payload
            };
        }
        case Controle.getShowMsgDialogSucessoControl(): {
            return {
                ...state,
                showDialogSucesso: action.payload
            };
        }
        case Controle.getMsgDialogSucessoControl(): {
            return {
                ...state,
                msgDialogSucesso: action.payload

            };
        }
        case Controle.setMsgErroExportarCSV():
        {
            return {
                ...state,
                msgErroExportarCSV: action.payload
            };
        }
        case Controle.getToggleCheckAllControl():
        {
            return {
                ...state,
                toggleCheckAll: action.payload
            };
        }
        case Controle.getResponseControl():
        {
            return {
                ...state,
                response: action.payload
            };
        }
        case Controle.getUltimaChavePaginacaoControl(): {
            let queryString = state.queryString;
            queryString.ultimaChave = action.payload;
            return {
                ...state,
                queryString: queryString
            };
        }
        case Controle.getResetarUltimaChaveControl(): {
            state.queryString.ultimaChave = null;
            return {
                ...state,
                queryString: state.queryString
            };
        }
        case Controle.getResetarUltimaChaveTamanhoPaginaControl(): {
            state.queryString.ultimaChave = null;
            state.queryString.tamanhoPagina = PAGINACAO;
            return {
                ...state,
                queryString: state.queryString
            };
        }
        case Controle.getQuantidadesRegistro(): {
            let queryStringAlterado = state.queryString;
            queryStringAlterado.totalElementos = action.payload.totalElementos;
            queryStringAlterado.quantidadeTotalRegistros = action.payload.quantidadeTotalRegistros;

            return {
                ...state,
                queryString: queryStringAlterado
            };
        }
        case Controle.getClickBtnLimparControl(): {
            return {
                ...state,
                btnLimpar: action.payload,
                errosNaBuscaDasPrestacoesDeContas: []
            };
        }
        case Controle.getLimparDataInicialErroControl(): {
            return {
                ...state,
                dataInicialErro: action.payload
            };
        }
        case Controle.getLimparDataFinalErroControl(): {
            return {
                ...state,
                dataFinalErro: action.payload
            };
        }
        case Controle.getListaClassificacaoControl(): {
            return {
                ...state,
                listaClassificacao: action.payload
            };
        }
        case Controle.getReloadControl():
        {
            return {
                ...state,
                reload: action.payload
            };
        }
        case Controle.setFiltro(): {
            return {
                ...state,
                queryString: action.payload
            };
        }
        case Controle.getVisualizarImagemControl(): {
            return {
                ...state,
                visualizarImagem: action.payload
            };
        }
        default:
            return state;
    }
}

function concatenarListas(lista1, lista2) {
    return [...new Set([...lista1, ...lista2])];
}
