import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos, montaUrlFetchReport, HEADER_API_SEM_PARCEIRO } from '../url-servicos';

export const fetchTokenGatewayApi = (clientId, secretKey) => {
    let dadosBody = {
        clientId: clientId,
        secretKey: secretKey
    };
    return fetch(montaUrlFetch(urlServicos.GATEWAYAPI_TOKEN), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: dadosBody
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchReportCardAnalityc = (dadosBody) => {
    return fetch(montaUrlFetchReport(urlServicos.REPORT_CARD_ANALYTIC_TRANSACTIONS), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(dadosBody)
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() })).catch(erro => {
            return { error: true, data: { error: true } };
        });
};

export const fetchReportCardBalance = (dadosBody) => {
    return fetch(montaUrlFetchReport(urlServicos.REPORT_CARD_BALANCE), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_SEM_PARCEIRO().headers,
        body: JSON.stringify(dadosBody)
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() })).catch(erro => {
        return { error: true, data: { error: true } };
    });
};

export const fetchCardStatus = (body) => {
    return fetch(montaUrlFetchReport(urlServicos.REPORT_CARD_STATUS), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_SEM_PARCEIRO().headers,
        body: body
    }).then(async response => {
        return response.json();
    }).catch(erro => {
        return 'error';
    });
};

export const fetchReportCardAccountability = (body) => {
    return fetch(montaUrlFetchReport(urlServicos.REPORT_CARD_ACCOUNTABILITY), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_SEM_PARCEIRO().headers,
        body: body
    }).then(async response => {
        return response.json();
    }).catch(erro => {
        return 'error';
    });
};

export const fetchReportCardManagement = (body) => {
    return fetch(montaUrlFetchReport(urlServicos.REPORT_CARD_MANAGEMENT), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API_SEM_PARCEIRO().headers,
        body: body
    }).then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() })).catch(erro => {
        return { error: true, data: { error: true } };
    });
};
