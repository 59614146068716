import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { Button, Collapse, Dropdown, Glyphicon, MenuItem, Panel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import If from 'componentes/componentes-uteis/if/if';
import MostrarSaldoCartao from 'componentes/contas/saldo/cartao/mostrar-saldo-cartao';
import imagemCartao from 'imagens/cartao-chip.jpg';
import { CARTAO_STATUS, LABEL_COMUNS, OPERACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import { setArmazenarCartao } from 'action/cartoes/cartoes-action';
import { formatarStatus } from 'componentes/util/cartoes/cartoes-util';
import Loading from 'componentes/loading-fenix/loading';
import moment from 'moment';

const tooltipAtivos = (
    <Tooltip id="tooltip">
        Ao exportar o relatório, serão visualizados apenas cartões com status pronto para ativação.
    </Tooltip>
);

const tooltipCancelados = (
    <Tooltip id="tooltip">
        Ao exportar o relatório, serão visualizados apenas cartões com status cancelado.
    </Tooltip>
);

class GridCartoesBppPag extends Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listaDeCartoes: this.props.listaCartoes && this.props.listaCartoes.length > 0 ? this.props.listaCartoes : []
        };
    }

    async startDownloadCSV() {
        this.setState({ loading: true });
        this.downloadCSV();
        this.setState({ loading: false });
    }

    downloadCSV() {
        var data, filename, link;
        var dados = [];

        var resultado = [];

        if (this.state.listaDeCartoes) {
            let arr = this.state.listaDeCartoes;
            for (let i in arr) {
                var item = {
                    'ID': arr[i].idCartaoImpresso,
                    'NÚMERO': arr[i].panFormatado,
                    'VENCIMENTO': moment(arr[i].validade).format('DD/MM/YYYY'),
                    'STATUS': arr[i].status,
                };
                resultado.push(item);
            }

            dados = resultado;

            var csv = this.converterArrayParaCSV({
                data: dados
            });
            if (csv === null) return;

            filename = 'cartoes.csv';

            if (!csv.match(/^data:text\/csv/i)) {
                const universalBOM = '\uFEFF';
                csv = 'data:text/csv;charset=utf-8,' + universalBOM + csv;
            }
            data = encodeURI(csv);

            link = document.createElement('a');
            link.setAttribute('href', data);
            link.setAttribute('download', filename);
            link.click();
        }
    }

    converterArrayParaCSV(args) {
        var result, ctr, keys, columnDelimiter, lineDelimiter, data;

        data = args.data || null;
        if (data === null || !data.length) {
            return null;
        }

        columnDelimiter = args.columnDelimiter || ';';
        lineDelimiter = args.lineDelimiter || '\n';

        keys = Object.keys(data[0]);

        result = '';
        result += keys.join(columnDelimiter);
        result += lineDelimiter;

        data.forEach(function(item) {
            ctr = 0;
            keys.forEach(function(key) {
                if (ctr > 0) result += columnDelimiter;

                result += item[key];
                ctr++;
            });
            result += lineDelimiter;
        });

        return result;
    }

    render() {
        const listaDeCartoes = this.props.listaCartoes && this.props.listaCartoes.length > 0 ? this.props.listaCartoes : [];
        const { podeConsultar, podeAprovar, podeCriar } = this.props.outorgaPerfils;
        return (
            <div id={this.props.id}>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11" />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Button className="botao_informacoes" onClick={this.props.setAbriSecaoCartao}>
                        {LABEL_COMUNS.CARTOES.PADRAO + ' ' + this.props.labelTitulo} ({this.props.quantidadeCartoes})
                        <span>&nbsp; &nbsp;</span>
                        {iconeBarraCartao(this.props.abrirCartoes)}
                        <div style={{ position: 'absolute', right: 57, bottom: 6, top: 3 }} >
                            <If test={this.state.loading}>
                                <Loading />
                            </If>
                        </div>
                        <OverlayTrigger placement="top" overlay={this.props.status === LABEL_COMUNS.CARTOES.STATUS_DO_CARTAO.CANCELADOS ? tooltipCancelados : tooltipAtivos}>
                            <Button className="btn btn-success btn-sm" style={{ position: 'absolute', right: 15, bottom: 6, cursor: 'pointer' }} title="Exportar para .CSV" onClick={(e) => {
                                e.stopPropagation();
                                this.startDownloadCSV('cartoes-favoritos.csv', listaDeCartoes, this.props.labelTitulo);
                            }}>
                                <i className="fa fa-file-excel-o" aria-hidden="true" />
                            </Button>
                        </OverlayTrigger>
                    </Button>
                    {/* Verificar se este trecho vai continuar existindo */}
                    <If test>
                        <div className="botao_saldos">
                            {/*   {this.montarBotaoSaldos()} */}
                        </div>
                    </If>
                    
                </div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Collapse in={this.props.abrirCartoes}>
                        <Panel className="conteudo_cartoes" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="row list-group-item texto-extrato-detalhe">
                                <div className="col-sm-1 col-md-1 col-lg-1" />

                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.ID}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.CARTAO}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.VENCIMENTO}
                                </div>
                                {/* <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.NOME_PORTADOR}
                                </div> */}
                                <If test={this.props.mostrarSaldoCartao && podeConsultar}>
                                    <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                        {LABEL_COMUNS.SALDO}
                                    </div>
                                </If>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.STATUS}
                                </div>
                                {podeAprovar &&
                                    <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro ">
                                        {LABEL_COMUNS.ACAO}
                                    </div>}
                            </div>
                            <div className="list-group">
                                {listaDeCartoes.map((cartao, idx) => {
                                    return (
                                        <li key={idx} className="list-group-item texto-extrato-detalhe">
                                            <div className="row">
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <img src={imagemCartao} className="avatar-cartao"
                                                        alt=" imagem cartao" />
                                                </div>
                                                <div
                                                    className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.idCartaoImpresso}
                                                </div>
                                                <div
                                                    className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.panFormatado}
                                                </div>
                                                <div
                                                    className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.validade}
                                                </div>
                                                {/* <div
                                                    className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.nomePortador}
                                                </div> */}
                                                <If test={this.props.mostrarSaldoCartao && podeConsultar}>
                                                    <div
                                                        className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                        <MostrarSaldoCartao contaCartaoId={cartao.cartaoId} />
                                                    </div>
                                                </If>

                                                <div
                                                    className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {formatarStatus(cartao.status)}
                                                </div>
                                                <div
                                                    className="col-sm-2 col-md-2 col-lg-2 alinhamento-centro botao_extrato_cartao">

                                                    <Dropdown id="menuConta" pullRight disabled={cartao.status === CARTAO_STATUS.CANCELADO}>
                                                        <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas-lote">
                                                            <Glyphicon glyph="option-horizontal alinhamento-icone" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <If test={podeCriar}>

                                                                <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowCancelCardModal(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CANCELA_CARTAO);
                                                                    }}>
                                                                        {LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                                                                    </MenuItem>
                                                                </If>

                                                                <li>
                                                                    <Link className="link_expandir" to={{
                                                                        pathname: ROTAS_CAMINHOS.ALTERAR_TITULARIDADE_CARTAO,
                                                                        state: { cartaoSelecionado: cartao, contextoBppPag: this.props.contextoBppPag }
                                                                    }}>
                                                                        {LABEL_COMUNS.CARTOES.MUDAR_TITULARIDADE}
                                                                    </Link>
                                                                </li>
                                                            </If>
                                                        </Dropdown.Menu>
                                                    </Dropdown>

                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                                )}
                                <br />

                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
        );
    }
}

const iconeBarraCartao = (abrirCartao) => (
    <span className={`glyphicon glyphicon glyphicon glyphicon-chevron-${abrirCartao ? 'up' : 'down'}`} />
);


const mapDispatchToProps = dispatch => bindActionCreators(dispatch);

export default connect(mapDispatchToProps)(GridCartoesBppPag);
