export function podeConsultar(perfils) {
    return !!perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeConsultar).length;
}

export function podeAprovar(perfils) {
    return !!perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeAprovar).length;
}

export function podeCriar(perfils) {
    return !!perfils.filter(perfil => perfil[Object.keys(perfil)[0]].podeCriar).length;
}
