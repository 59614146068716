import React, { Component } from 'react';
const arrayColor = ['#113f67', '#4089b4', '#226597', '#65c6c4', '#bdbdbd', '#113f67', '#cfcaca'];
var i = 0;
class BarChart extends Component {
    constructor(props) {
        super(props);
        let barListWithName = props.barList;
        let type = props.type;

        if (type === 'ranking') {
            for (let i = 0; i < barListWithName.length; i++) {
                barListWithName[i].name = barListWithName[i].ranking;
            }
        } else {
            for (let i = 0; i < barListWithName.length; i++) {
                barListWithName[i].name = barListWithName[i].project;
            }
        }

        if (barListWithName != null && barListWithName !== 'error') {
            barListWithName = barListWithName.sort((a, b) => b.percentage - a.percentage);

            barListWithName.sort(function(a, b) {
                if (a.percentage === b.percentage) {
                    if (a.name < b.name) { return -1; }
                    if (a.name > b.name) { return 1; }
                    return 0;
                } else {
                    return b.percentage - a.percentage;
                }
            });

            barListWithName = barListWithName.sort((a, b) => a.name - b.name);
        }

        this.state = {
            isLoading: false,
            barList: barListWithName,
            indexColor: 0
        };
    }

    geraCoresAzuis(index) {
        if (index > 5) {
            while (index > 5) {
                index = index - 5;
            }
            return arrayColor[index];
        } else {
            return arrayColor[index];
        }
    }

    render() {
        const barList = this.state.barList;
        return (
            <div style={{ overflow: 'auto', maxHeight: 420 }}>
                <p style={{ fontSize: '13px', width: '80px', marginLeft: '10px', fontWeight: 'bolder', marginTop: 13 }}> Descrição </p>
                {barList && barList.map((item, idx) => {
          return (
              <div key={idx} style={{ justifyContent: 'space-between', display: 'flex', flexDirection: 'row', flex: 1 }}>
                  <div style={{ width: '100%', display: 'flex' }}>
                      <p style={{ fontSize: '10px', width: '80px', marginLeft: '10px', minWidth: 110, maxWidth: 80, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>  {item.name} </p>
                      <div style={{ width: '100%' }}>
                          <div style={{ marginTop: '3px', backgroundColor: this.geraCoresAzuis(idx), height: 10, width: item.percentage + '%' }} />
                      </div>
                  </div>
                  <p> {parseFloat(item.percentage).toFixed(1) + '%'} </p>
              </div>);
        })}
            </div>
        );
    }
}

export default BarChart;

/*

*/
