import React, {Component} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {FormControl, FormGroup} from 'react-bootstrap';
import InputMask from 'react-input-mask';
import {NOME_MODAL} from './vincular-portador-contantes';
import * as actions from './vincular-portador-action';
import {getCartoes} from '../../../../action/cartoes/cartoes-action';
import FooterBotoesModalComponent from '../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import {LABEL_COMUNS, MASCARA_CAMPOS} from '../../../util/contantes-comuns/constantes-comuns';
import {STATUS_VALIDACAO_COMPONENTES} from '../../../util/estilo_para_componentes/estiloUtil';
import Grid from '../../../componentes-uteis/grid/grid';
import GridRow from '../../../componentes-uteis/grid/grid-row';

class VincularPortador extends Component {
    constructor(props) {
        super(props);
        this.state = {
            abrirModalConfirmacao: false
        };
    }

    render() {
        return (
            <div>
                <ModalComponent bsSize="small" exibirModal={this.props.estadoVincularPortador.exibirModalDeVincularPortador} name={NOME_MODAL} header={LABEL_COMUNS.VINCULAR_PORTADOR}
          body={this.corpoModal()} footer={this.rodapeModal()} />
                <ModalComponent bsSize="small" exibirModal={this.props.estadoVincularPortador.exibirMsgMensagem} name={NOME_MODAL} header={LABEL_COMUNS.VINCULAR_PORTADOR}
          body={this.corpoModalMensagem()} footer={this.rodapeModalMensagem()} />
            </div>
        );
    }

    corpoModal() {
        return (
            <div>
                <GridRow>
                    <Grid cols="12 12 12 12" className="alinhamento-direita">
                        <FormGroup>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="alinhamento-centro">
                                    <span style={{ fontSize: '14px' }}> <b>ID</b> {this.props.cartaoSelecionado.idCartaoImpresso} <br /> <b>Cartão</b> {this.props.cartaoSelecionado.panFormatado}</span>
                                </div>
                                <br />
                                <span><b> {LABEL_COMUNS.CPF} </b></span>
                                <FormGroup validationState={this.props.estadoVincularPortador.numeroDoDocumentoDoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <InputMask
                    id="numeroDocumento"
                    className="mensagens-erro form-control"
                    mask={MASCARA_CAMPOS.CPF} maskChar=""
                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                    placeholder={LABEL_COMUNS.CPF}
                    value={this.props.estadoVincularPortador.numeroDoDocumentoDoPortador}
                    onChange={this.props.setNumeroDoDocumentoDoPortador}
                  />
                                    {this.props.estadoVincularPortador.numeroDoDocumentoDoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoVincularPortador.numeroDoDocumentoDoPortadorErro}</span> : null}
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span><b>{LABEL_COMUNS.NOME_COMPLETO} </b></span>
                                <FormGroup validationState={this.props.estadoVincularPortador.nomeDoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <FormControl
                    type="text"
                    id="nomeCompleto"
                    className="mensagens-erro form-control"
                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                    placeholder={LABEL_COMUNS.NOME_COMPLETO}
                    value={this.props.estadoVincularPortador.nomeDoPortador}
                    onChange={this.props.setNomeDoPortador}
                  />
                                    {this.props.estadoVincularPortador.nomeDoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoVincularPortador.nomeDoPortadorErro}</span> : null}
                                </FormGroup>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <span><b>{LABEL_COMUNS.DATA_DE_NACIMENTO} </b></span>
                                <FormGroup validationState={this.props.estadoVincularPortador.dataDeNascimentoDoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                    <InputMask
                    id="dataNascimento"
                    className="mensagens-erro form-control"
                    mask={MASCARA_CAMPOS.DATA} maskChar=""
                    style={{ height: '32px', paddingTop: '6px', paddingLeft: '10px' }}
                    placeholder={LABEL_COMUNS.PLACEHOLDER_DATANASC}
                    value={this.props.estadoVincularPortador.dataDeNascimentoDoPortador}
                    onChange={this.props.setDataDeNascimentoDoPortador}
                  />
                                    {this.props.estadoVincularPortador.dataDeNascimentoDoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoVincularPortador.dataDeNascimentoDoPortadorErro}</span> : null}
                                </FormGroup>
                            </div>
                        </FormGroup>
                    </Grid>
                </GridRow>
            </div>
        );
    }

    rodapeModal() {
        return (
            <FooterBotoesModalComponent
        btn1Label={LABEL_COMUNS.CANCELAR}
        btn1OnClick={() => { this.props.setExibirModalDeVincularPortador(false); this.props.limparCamposDeErros(); }}
        btn2Label={LABEL_COMUNS.VINCULAR}
        btn2OnClick={() => this.props.vincularPortador(this.props.estadoVincularPortador)} />
        );
    }

    corpoModalMensagem() {
        return (
            <div>
                {this.props.estadoVincularPortador.mensagem}
            </div>
        );
    }

    rodapeModalMensagem() {
        return (
            <FooterBotoesModalComponent
        btn2Label={LABEL_COMUNS.OK}
        btn2OnClick={() => { this.props.setExibirModalMensagem(false); this.props.getCartoes(this.props.contaSelecionada.id, this.props.produtoId); }} />
        );
    }
}

const mapStateToProps = (state) => ({ estadoVincularPortador: state.VincularPortadorReducer });

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, getCartoes }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(VincularPortador);
