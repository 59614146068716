import {
    URL_GET_PARAMETRO_WHITE_LIST_APP_FLAG,
    URL_GET_PARAMETRO_WHITE_LIST_APP, 
    HEADER_API
} from '../../../servicos/url-servicos';
import { resolve } from 'url';

export default class ValidaCpf {

  BLACKLIST = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
      '12345678909'
  ];

  isValid(documento) {
      const DIGITO_PRIMARIO = 10;
      const DIGITO_SECUNDARIO = 11;

      var numero = documento.replace(/[^\d]/g, '');

      if (numero.length !== 11 || this.BLACKLIST.indexOf(numero) !== -1) {
          return false;
      }

      return this.isDigitValid(numero, DIGITO_PRIMARIO) && this.isDigitValid(numero, DIGITO_SECUNDARIO);
  }

  isDigitValid(numero, digito) {
      var add = 0;

      for (var i = 0; i < (digito - 1); i++) {
          add += parseInt(numero.charAt(i)) * (digito - i);
      }

      var rev = 11 - (add % 11);
      if (rev === 10 || rev === 11) {
          rev = 0;
      }

      if (rev !== parseInt(numero.charAt((digito - 1)))) {
          return false;
      }

      return true;
  }
  
  fetchExec(url){
      return fetch(url, {
          method:'GET',
          headers: HEADER_API().headers
      });
  }

  validarContentType(response){
      var contentType = response.headers.get('content-type');
      if(contentType && contentType.includes('application/json')) {
          return true;
      }
  }

  verificarCpfLiberadoWhiteList(cpfAPesquisar) {
      /*
    -->Esse metodo esta cmentado devido a task FXD-1890 caso no futuro proximo a whiteList volte é só descomentar o return;

    return this.fetchExec(URL_GET_PARAMETRO_WHITE_LIST_APP_FLAG())
      .then( isWhiteListAppFlag => {
             // ACESSO AO ENDPOINT
             // "true" indica que o aplicativo deve realizar a leitura da lista e verificar se o CPF do usuário existe nesta lista antes de permitir que o usuário efetue login ou cadastro e 
             // "false" indica que o processo de whitelist não está habilitado, permitindo assim que todos possam acessar o app independente de validação
          //console.log("passo 1", isWhiteListAppFlag)
          if(this.validarContentType( isWhiteListAppFlag )){
            return isWhiteListAppFlag.json();
          }
      })
      .then( response =>{
        //console.log("passo 2", response)
        if( response.valor ) {
          return this.fetchExec(URL_GET_PARAMETRO_WHITE_LIST_APP())
            .then( _whiteListApp =>{
                 // ACESSA O ENDPOINT
                 // traz a lista de todos os cpf cadastrado
              console.log("passo 3", _whiteListApp)
              if( this.validarContentType( _whiteListApp ) ) {
                  return _whiteListApp.json()
              }
            })
            .then( listaDeCpfLiberados =>{  
              //console.log("passo 4", listaDeCpfLiberados)
              return listaDeCpfLiberados.valor.find( cpf =>{
                return cpf === cpfAPesquisar
              })
            })
        }else{
          //console.log("passo 5")
          return new Promise(resolve =>{
            resolve(response.valor)
          })  
      }
    })*/
      return false;
  }
}