import { fetchComboFelByPublishedProductId } from './negociacoes-base';

export const getComboFelByPublishedProductId = async (publishedProductId) => {
    const response = await fetchComboFelByPublishedProductId(publishedProductId);
    if (!response.error) {
        const { data } = response;
        return data;
    } else {
        return response;
    }
};