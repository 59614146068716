export const CONSTANTES = {

    OBTER_DADOS_PJ_SUCESSO: 'OBTER_DADOS_PJ_SUCESSO',
    OBTER_DADOS_PJ_ERRO: 'OBTER_DADOS_PJ_ERRO',

    LABELS: {
        LISTA_DADOS_PJ: 'Dados da Pessoa Jurídica',
        ERRO_OBTER_DADOS: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
        CONTA_SELECIONADA: 'contaSelecionada'
    }
};
