import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { LABEL_COMUNS, CONTA_SELECIONADA, MENSAGENS } from '../../../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';
import Grid from '../../../../componentes-uteis/grid/grid';
import PieChart from '../../../../pie-chart/pie-chart';
import BarChart from '../../../../bar-chart/bar-chart';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import { reactLocalStorage } from 'reactjs-localstorage';
import * as moment from 'moment';
import { getReportCardAccountability, getReportCardStatus, getClassificacoes, getProjetos } from '../../../../../servicos/relatorios/relatorios-bpp-corp-service';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import MenuCustom from '../../../../menu-custom/menu-custom';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';

class DashboradRelatoriosBppCorp extends Component {
    constructor(props) {
        super(props);

        this.state = {
            erroFiltro: null,
            isLoadingStatus: true,
            isLoadingAccountability: true,
            dataInicialFiltro: '',
            dataFinalFiltro: '',
            isFilterOpen: false,
            abrirMenu: false,
            abrirCollapseFiltro: false,
            token: reactLocalStorage.get('token'),
            mensagemErroProjetos: '',
            mensagemErroClassificacoes: '',
            isLoading: false,
            contaSelecionada: {},
            prestacoesPie: [],
            dataStatusPie: [],
            classificacao: 'empty',
            projetos: 'empty'
        };
        this.limparFiltro = this.limparFiltro.bind(this);
    }

    ajustaCemPorcento() {
        let listaGraph = document.getElementsByTagName('text');
        for (let i = 0; i < listaGraph.length; i++) {
            if (listaGraph[i].innerHTML === '100.0%') {
                listaGraph[i].setAttribute('dx', 0);
            }
        }
    }

    async buscaDadosGraficosStatus(idContaMovimento) {
        let listaStatusPercent = await this.carregaPercentStatusCartao(idContaMovimento);
        if (listaStatusPercent === 'error') {
            listaStatusPercent = { cards: 'error' };
        }
        this.setState({ isLoadingStatus: false, dataStatusPie: listaStatusPercent.cards });
        this.ajustaCemPorcento();
    }

    async buscaDadosGraficosAccountability(idContaMovimento) {
        let listaAccountability = await this.carregaAccountabilityStatus(idContaMovimento);

        let accountability;
        let ranking;
        let project;
        if (listaAccountability === 'error') {
            accountability = 'error';
            ranking = 'error';
            project = 'error';
        } else {
            accountability = listaAccountability.accountability;
            ranking = listaAccountability.ranking;
            project = listaAccountability.project;
        }

        this.setState({ isLoadingAccountability: false, prestacoesPie: accountability, rankingBar: ranking, projectBar: project });
        this.ajustaCemPorcento();
    }

    carregaPercentStatusCartao(contaMovimento) {
        let token = this.state.token;
        let body = { accountId: contaMovimento, token: token };
        const resultado = getReportCardStatus(body);
        return resultado;
    }

    carregaAccountabilityStatus(contaMovimento) {
        let token = this.state.token;
        let diaFim = moment().format('YYYY-MM-DD');
        let diaInicioParts = diaFim.split('-');
        let diaInicio = diaInicioParts[0] + '-' + diaInicioParts[1] + '-' + '01';

        let body = { accountId: contaMovimento, token: token, startDate: diaInicio, endDate: diaFim };
        const resultado = getReportCardAccountability(body);
        return resultado;
    }

    async carregaAccountabilityStatusFilterDays(daysToSubtract) {
        let token = this.state.token;
        let diaFim = moment().format('YYYY-MM-DD');
        let diaInicio = moment().subtract(daysToSubtract, 'days').format('YYYY-MM-DD');
        let contaMovimento = this.state.contaSelecionada.id;

        let body = { accountId: contaMovimento, token: token, startDate: diaInicio, endDate: diaFim };
        let accountability;
        let ranking;
        let project;

        const resultado = await getReportCardAccountability(body);
        if (resultado === 'error') {
            accountability = 'error';
            ranking = 'error';
            project = 'error';
        } else {
            accountability = resultado.accountability;
            ranking = resultado.ranking;
            project = resultado.project;
        }

        this.setState({ isLoading: false, prestacoesPie: accountability, rankingBar: ranking, projectBar: project });
        this.ajustaCemPorcento();
    }

    async executaCustomFilter() {
        this.setState({ isLoading: true });
        let token = this.state.token;
        let diaInicioParts = this.state.dataInicialFiltro.split('/');
        let diaFimParts = this.state.dataFinalFiltro.split('/');

        const diaInicio = diaInicioParts[2] + '-' + diaInicioParts[1] + '-' + diaInicioParts[0];
        const diaFim = diaFimParts[2] + '-' + diaFimParts[1] + '-' + diaFimParts[0];

        let momentInitDate = moment(diaInicio);
        let momentFinishDate = moment(diaFim);
        let daysDiff = momentFinishDate.diff(momentInitDate, 'days');
        if (daysDiff > 90) {
            this.setState({ isLoading: false, erroFiltro: 'Não é possível filtrar períodos acima de 90 dias.' });
        } else {
            let contaMovimento = this.state.contaSelecionada.id;

            let body = { accountId: contaMovimento, token: token, startDate: diaInicio, endDate: diaFim };
            let accountability;
            let ranking;
            let project;

            const resultado = await getReportCardAccountability(body);
            if (resultado === 'error') {
                accountability = 'error';
                ranking = 'error';
                project = 'error';
            } else {
                accountability = resultado.accountability;
                ranking = resultado.ranking;
                project = resultado.project;
            }
            this.setState({ erroFiltro: null, isLoading: false, prestacoesPie: accountability, rankingBar: ranking, projectBar: project, abrirCollapseFiltro: false });
            this.ajustaCemPorcento();
        }
    }

    renderGraficoProjetos() {
        if (this.state.projetos === 'empty') {
            return (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
            );
        } else if (this.state.projetos === false) {
            return <p> {this.state.mensagemErroProjetos} <a href="/minha-conta/parametro-prestacao" className="cursor-pointer"> clique Aqui </a> </p>;
        } else if (this.state.projectBar === 'error') {
            return <div style={{ textAlign: 'center' }}>{MENSAGENS.REPORTS.MANAGEMENT.ERROR}</div>;
        } else if (!this.state.projectBar || this.state.projectBar.length === 0) {
            return <div style={{ textAlign: 'center' }}>{MENSAGENS.REPORTS.EMPTY_PRESTACAO}</div>;
        } else return <BarChart type="project" barList={this.state.projectBar} />;
    }

    renderGraficoClassificacies() {
        if (this.state.classificacao === 'empty') {
            return (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
            );
        } else if (this.state.classificacao === false) {
            return <p> {this.state.mensagemErroClassificacoes} <a href="/minha-conta/parametro-prestacao" className="cursor-pointer"> clique Aqui </a> </p>;
        } else if (this.state.rankingBar === 'error') {
            return <div style={{ textAlign: 'center' }}>{MENSAGENS.REPORTS.MANAGEMENT.ERROR}</div>;
        } else if (!this.state.rankingBar || this.state.projectBar.length === 0) return <div style={{ textAlign: 'center' }}>{MENSAGENS.REPORTS.EMPTY_PRESTACAO}</div>;
        else return <BarChart type="ranking" barList={this.state.rankingBar} />;
    }

    componentDidMount() {
        const conta = CONTA_SELECIONADA.OBJETO();
        this.setState({ contaSelecionada: conta });
        this.buscaDadosGraficosStatus(conta.id);
        this.buscaDadosGraficosAccountability(conta.id);
        this.getExisteClassificacoes(conta.pessoaTitularId);
        this.getExisteProjetos(conta.pessoaTitularId);
    }

    async getExisteProjetos(pessoaTitularId) {
        let respostaProjetos = await getProjetos(pessoaTitularId);
        if (!respostaProjetos.existsProjetos) {
            this.setState({ mensagemErroProjetos: respostaProjetos.menssageError });
            this.setState({ projetos: false });
        } else {
            this.setState({ projetos: true });
        }
    }

    async getExisteClassificacoes(pessoaTitularId) {
        let respostaClassificacao = await getClassificacoes(pessoaTitularId);
        if (!respostaClassificacao.existsClassificacoes) {
            this.setState({ mensagemErroClassificacoes: respostaClassificacao.menssageError });
            this.setState({ classificacao: false });
        } else {
            this.setState({ classificacao: true });
        }
    }

    filtrar(filtterType) {
        if (filtterType === 'other') {
            this.setState({ abrirCollapseFiltro: true, erroFiltro: null });
        } else {
            this.setState({ abrirCollapseFiltro: false, isLoading: true });
            this.carregaAccountabilityStatusFilterDays(filtterType);
        }
    }

    limparFiltro() {
        this.setState({ dataInicialFiltro: '', dataFinalFiltro: '', erroFiltro: null });
    }

    renderCustomMenu() {
        if (!this.state.isFilterOpen) {
            return false;
        }
        return (
            <div
                style={{
                    zIndex: '99',
                    top: '33px',
                    width: 200,
                    left: '1%',
                    position: 'absolute',
                    backgroundColor: 'white'
                }}
                >
                <MenuCustom
                    style={{ width: 200, height: 290, right: -6 }}
                    isOpen={this.state.isFilterOpen}
                    filtrar={(valor) => { this.filtrar(valor); }}
                    setOpen={() => this.setState({ isFilterOpen: !this.state.isFilterOpen })}
                />
            </div>
        );
    }

    render() {
        const { contaSelecionada } = this.state;
        const outorga = {};

        if (this.props.outorgaPerfils.podeCriar && this.props.outorgaPerfils.podeAprovar) {
            outorga.labelBotao1 = LABEL_COMUNS.RELATORIOS.ANALITICO;
            outorga.btn1Link = ROTAS_CAMINHOS.RELATORIO_ANALITICO;
        }

        if (this.state.isLoading || this.state.isLoadingStatus || this.state.isLoadingAccountability) {
            return (
                <div className="alinhamento-centro">
                    <LoadingFenix />
                </div>
            );
        }

        return (

            <div>
                <div >
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas" style={{ width: '100%' }} >
                        <div className="row" style={{ zIndex: 101 }} >

                            <Grid cols="1 1 1 1" >
                                <Button className="estilo-filtro-corp-reports" onClick={() => { this.setState({ isFilterOpen: true }); }}>
                                    <span className="glyphicon glyphicon-filter" />
                                </Button>
                            </Grid>
                            <div className="col-xs-5 col-sm-5 col-md-5 col-lg-5" style={{ height: 35 }} onClick={() => { if (this.state.isFilterOpen) { this.setState({ isFilterOpen: false }); } }} />
                            <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6" style={{ paddingRight: '3.7%', textAlign: 'right' }} onClick={() => { if (this.state.isFilterOpen) { this.setState({ isFilterOpen: false }); } }}>
                                <Button type="button" bsStyle="primary" className="report-butons" onClick={() => this.props.history.push({ pathname: ROTAS_CAMINHOS.RELATORIO_GERENCIAL, state: { contaSelecionada } })}>
                                    {LABEL_COMUNS.RELATORIOS.GERENCIAL}
                                </Button>
                                &nbsp;&nbsp;&nbsp;
                                <Button type="button" bsStyle="primary" className="report-butons" onClick={() => this.props.history.push({ pathname: ROTAS_CAMINHOS.RELATORIO_ANALITICO, state: { contaSelecionada } })}>
                                    {LABEL_COMUNS.RELATORIOS.ANALITICO}
                                </Button>
                            </div>
                        </div>
                        <br />

                        <div style={{ width: '47.5%' }} onClick={() => { this.setState({ isFilterOpen: false }); }}>
                            {this.renderCustomMenu()}

                            <FiltroGenerico abrirCollapse={this.state.abrirCollapseFiltro}
                                mostrarFiltroDeDatas
                                labelDataInicial={LABEL_COMUNS.DATA_INICIAL}
                                dataInicial={this.state.dataInicialFiltro}
                                setDataInicial={(e) => this.setState({ dataInicialFiltro: e.target.value })}
                                dataInicialErro={this.state.dataInicialErro}
                                labelDataFinal={LABEL_COMUNS.DATA_FINAL}
                                dataFinal={this.state.dataFinalFiltro}
                                setDataFinal={(e) => this.setState({ dataFinalFiltro: e.target.value })}
                                dataFinalErro={this.state.dataFinalErro}
                                exibirBotaoLimpar
                                erroGenerico={this.state.erroFiltro}
                                limparFiltro={this.limparFiltro}
                                buscar={() => this.executaCustomFilter()} />
                        </div>
                        <div style={{ zIndex: 101 }} onClick={() => { if (this.state.isFilterOpen) { this.setState({ isFilterOpen: false }); } }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                <PieChart dataState={this.state.dataStatusPie} name="Cartões" />
                                <PieChart dataState={this.state.prestacoesPie} prestacao name="Prestação de contas" />

                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div className="row" style={{ width: '100%' }}>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="panel panel-default" style={{ width: '95%' }}>
                                            <div className="panel-heading fundo-azul-claro" style={{ fontWeight: 900 }}>Prestação de contas</div>
                                            <div className="panel-body" style={{ overflow: 'auto', height: '250px' }} >
                                                <div style={{ fontWeight: 900 }}>Tipo de Despesa</div>
                                                {this.renderGraficoClassificacies()}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row" style={{ width: '100%' }}>
                                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                        <div className="panel panel-default" style={{ width: '95%' }}>
                                            <div className="panel-heading fundo-azul-claro" style={{ fontWeight: 900 }}>Prestação de contas</div>
                                            <div className="panel-body" style={{ overflow: 'auto', height: '250px' }} >
                                                <div style={{ fontWeight: 900 }}>Cliente/Projeto</div>
                                                {this.renderGraficoProjetos()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row" style={{ paddingRight: '2.7%' }}>
                            <Grid className="alinhamento-direita">
                                <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.PADRAO} onClick={() => {
                                    this.props.history.goBack();
                                }}>
                                    {LABEL_COMUNS.VOLTAR}
                                </Button>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

export default connect(mapStateToProps)(DashboradRelatoriosBppCorp);
