import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'react-bootstrap';
import { getAutorizadosDaConta, insereAutorizadoEPoderes } from '../../../action/contas/contas-action';
import { LABEL_COMUNS, CAMPOS, MENSAGENS, FILTRO_PRIME_REACT_DATA_TABLE } from '../../util/contantes-comuns/constantes-comuns';
import { retornaNumeroTelefonePessoa, retornaEmailPessoa, retornaNomePessoa, retornaTelefoneCompletoPessoa } from '../../../servicos/pessoas/pessoa-service';
import ModalInserirPoderPessoaConta from './modal-inserir-poder-pessoa-conta';
import ModalEditarPessoaPoderConta from './modal-editar-pessoa-poder-conta';
import If from '../../componentes-uteis/if/if';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import Grid from '../../componentes-uteis/grid/grid';

class ListarAutorizadosDaConta extends Component {

    constructor(props) {
        super(props);
        this.CONTA_SELECIONADA = props.location.state && props.location.state.contaSelecionada || {};
        this.state = {
            contaSelecionada: this.CONTA_SELECIONADA, autorizadoSelecionado: undefined, exibirLoading: false,
            exibirModalInserirPoderPessoa: false, exibirModalAlterarPoderPessoa: false
        };
    }

    componentDidMount() {
        this.props.getAutorizadosDaConta(this.CONTA_SELECIONADA.id);
    }

    selecionaAutorizado = (e) => {
        this.setState({ autorizadoSelecionado: e.value });
        this.setExibirModalAlterarPoderPessoa(true);
    }

    setExibirModalInserirPoderPessoa = (exibirModalInserirPoderPessoa) => {
        this.setState({ exibirModalInserirPoderPessoa });
    }

    setExibirModalAlterarPoderPessoa = (exibirModalAlterarPoderPessoa) => {
        if (!exibirModalAlterarPoderPessoa) {
            this.setState({ autorizadoSelecionado: undefined });
        }
        this.setState({ exibirModalAlterarPoderPessoa });
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    render() {
        const exibirLoading = this.props.listarAutorizados.exibirLoadingConta || this.props.listarAutorizados.exibirLoadingPessoa || this.state.exibirLoading;
        const listaDeAutorizados = this.props.listarAutorizados.listaDeAutorizados;
        const { CONTAINS } = FILTRO_PRIME_REACT_DATA_TABLE;
        const { contaSelecionada } = this.state;
        const tituloDaGrid = LABEL_COMUNS.LISTA_DE_AUTORIZADOS.toUpperCase().concat(' - '.concat(LABEL_COMUNS.CONTAS.TITULOS.CONTA.toUpperCase().concat(' '.concat(LABEL_COMUNS.CONTAS.TITULOS.APELIDO_CONTA_E_DIGITO(contaSelecionada)))));

        const header = (
            <div className="titulo-lista_autorizados"><span className="lista-autorizado">{tituloDaGrid}</span>
                <Button bsStyle="primary" style={{ position: 'absolute', left: 10 }} onClick={() => this.setExibirModalInserirPoderPessoa(true)}>
                    {LABEL_COMUNS.CONTAS.TITULOS.INCLUIR_AUTORIZADO}
                </Button>
            </div >
        );
        /* 
                const footer = (
                    <div className='titulo-lista_autorizados'>
                        <Button bsStyle="primary" style={{ position: 'absolute', left: 10 }} onClick={() => this.setExibirModalInserirPoderPessoa(true)}>
                            {LABEL_COMUNS.CONTAS.TITULOS.INCLUIR_AUTORIZADO}
                        </Button>
                    </div>
                ); */

        return (
            <div>
                <If test={exibirLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>
                {this.props.listarAutorizados.listaDePoderesOutorga.length > 0 &&
                    this.props.listarAutorizados.listaDePerfisOutorga.length > 0 &&
                    <ModalInserirPoderPessoaConta
                        listaDePerfisOutorga={this.props.listarAutorizados.listaDePerfisOutorga}
                        listaDePoderesOutorga={this.props.listarAutorizados.listaDePoderesOutorga}
                        contaSelecionada={this.state.contaSelecionada}
                        exibirModalInserirPoderPessoa={this.state.exibirModalInserirPoderPessoa}
                        setExibirModalInserirPoderPessoa={this.setExibirModalInserirPoderPessoa}
                        insereAutorizadoEPoderes={this.props.insereAutorizadoEPoderes}
                        getAutorizadosDaConta={this.props.getAutorizadosDaConta}

                    />
                }
                {this.props.listarAutorizados.listaDePoderesOutorga.length > 0 &&
                    this.props.listarAutorizados.listaDePerfisOutorga.length > 0 &&
                    this.state.autorizadoSelecionado &&
                    this.CONTA_SELECIONADA.dadosPessoa.usuarioAdmin == true &&
                    <ModalEditarPessoaPoderConta
                        listaDePerfisOutorga={this.props.listarAutorizados.listaDePerfisOutorga}
                        listaDePoderesOutorga={this.props.listarAutorizados.listaDePoderesOutorga}
                        autorizadoSelecionado={this.state.autorizadoSelecionado}
                        exibirModalAlterarPoderPessoa={this.state.exibirModalAlterarPoderPessoa}
                        contaSelecionada={this.state.contaSelecionada}
                        setExibirModalAlterarPoderPessoa={this.setExibirModalAlterarPoderPessoa}
                        getAutorizadosDaConta={this.props.getAutorizadosDaConta}
                        setExibirLoading={this.setExibirLoading}

                    />
                }
            
                {/* filterMatchMode="contains" */}
                <div className="espacamento_conteudo_lote">
                    <Grid cols="11 11 11 11">
                        {listaDeAutorizados &&
                            <DataTable
                                emptyMessage={LABEL_COMUNS.NENHUM_REGISTRO_ENCONTRADO}
                                ref={(el) => this.dt = el}
                                value={listaDeAutorizados}
                                paginator={true}
                                rows={10}
                                header={header}
                                //footer={footer}
                                selectionMode="single"
                                onRowSelect={this.selecionaAutorizado}
                                selection={this.state.autorizadoSelecionado} onSelectionChange={this.selecionaAutorizado}>
                                <Column field="nomeCompleto" header={LABEL_COMUNS.NOME} style={{ width: '35%' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.NOME} />
                                <Column field="cpf" header={LABEL_COMUNS.CPF} style={{ width: '15%', textAlign: 'center' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.CPF} />
                                <Column field="email.email" header={LABEL_COMUNS.EMAIL} style={{ width: '35%' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.EMAIL} />
                                <Column field="numeroTelefone" header={LABEL_COMUNS.CELULAR} style={{ width: '15%', textAlign: 'center' }} filter={true} filterMatchMode={CONTAINS} filterPlaceholder={LABEL_COMUNS.CELULAR} />
                                <Column field="permissoes" header="Permissões" style={{ width: '15%', textAlign: 'center' }} />
                            </DataTable>}
                        <br /><br />
                        <Button bsStyle="primary" style={{ position: 'absolute', right: 10 }} onClick={this.props.history.goBack}>
                            {LABEL_COMUNS.VOLTAR}
                        </Button>
                    </Grid>

                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({ listarAutorizados: { ...state.ContasReducer, ...state.OutorgaReducer, ...state.PessoaReducer } });

const mapDispatchToProps = dispatch => bindActionCreators({ getAutorizadosDaConta, insereAutorizadoEPoderes }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ListarAutorizadosDaConta);