import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import * as actions from 'componentes/contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-action';
import { Button } from 'react-bootstrap';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';
import { ACAO, CAMPOS, COMBO, CONTA_SELECIONADA, FILTROS, LABEL_COMUNS, MENSAGENS, PESSOA_LOGADA, PRODUTOS, TIPO_DE_ACESSO, APROVAR_OU_REPROVAR_ARQUIVO } from 'componentes/util/contantes-comuns/constantes-comuns';
import Grid from 'componentes/componentes-uteis/grid/grid';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import Loading from 'componentes/loading-fenix/loading';
import If from 'componentes/componentes-uteis/if/if';
import { formatarDataBR } from 'componentes/util/data/dataUtil';
import { formatarCampoValor } from 'componentes/util/extrato/extrato-util';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { aprovar, getMenuAcoes as MenuAcoes, reprovar } from 'componentes/solucoes/dashboard/bpp-pag/agendamentos-e-aprovacoes-bpp-pag/agendamentos-e-aprovacoes-bpp-pag-base';
import { getListaDeLoteArquivos } from 'servicos/transferencia-em-lote/transferencia-em-lote-service';
import { retornaObjetoTarget } from 'servicos/base/service-base';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import { podeAprovar, podeConsultar, podeCriar } from 'reducer/outorga/outorga-operacao-reducer';
import { approveOrReproveArchiveApi } from 'core/api/transferenciaEmLoteRetornoApi';

export class AgendamentoEAprovacoesBppPag extends Component {
    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = {
            abrirCollapse: false,
            acaoArmazenada: '',
            dataDeInclusaoDe: '',
            dataDeInclusaoAte: '',
            idArquivoSequencialPorPessoaId: '',
            exibirModalConfirmacao: false,
            exibirModalTransferenciaEmLoteConfirmacao: false,
            exibirModalTransferenciaEmLoteFinalizacao: false,
            exibirModalTransferenciaEmLoteToken: false,
            exibirModalConfirmacaoAprovacao: false,
            exibirModalConfirmacaoReprovacao: false,
            mensagemConfirmacao: '',
            mensagemFinalizacao: '',
            operacaoSelecionadaId: '',
            transferenciaEmLote: {},
            anexoContratualId: undefined,
            statusSelecionado: '',
            transferenciaEmLoteArmazenada: '',
            token: '',
            pessoaLogada: PESSOA_LOGADA(),
            exibirLoading: undefined,
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            redirecionarParaTelaDeCarregarConta: false,
            redirecionarTelaDetalhesArquivo: false,
            listaDeTransferenciasEmLote: [],
            exibirMaisRegistros: false,
            ultimaChave: ''
        };
        this.state = this.ESTADO_INICIAL;
    }

    async componentDidMount() {
        const { contaSelecionada } = this.state;
        this.props.setContaSelecionada(contaSelecionada);
        const { id, pessoaTitularId } = contaSelecionada;
        this.setState({ contaId: id, pessoaId: pessoaTitularId });
        await this.buscarTransferenciasEmLote(id, pessoaTitularId);
    }

    buscarTransferenciasEmLote = async (contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId, listaAnteriorCargaLoteCartao) => {
        this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
        let { ultimaChave } = listaAnteriorCargaLoteCartao ? this.state : '';
        const resultadoDaBusca = await getListaDeLoteArquivos(FILTROS.TRANSFERENCIA_EM_LOTE.AGENDAMENTOS_E_APROVACOES(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId,
            statusSelecionado, idArquivoSequencialPorPessoaId, PRODUTOS.pag, ultimaChave), listaAnteriorCargaLoteCartao);
        const { error, data } = resultadoDaBusca;
        if (!error) {
            this.setState({ listaDeTransferenciasEmLote: data.resultado });
        }

        if (data.ultimaChave) {
            this.setState({ exibirMaisRegistros: true, ultimaChave: data.ultimaChave });
        } else {
            this.setState({ exibirMaisRegistros: false, ultimaChave: '' });
        }
        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
    };


    setPropsCampos = (e, data) => {
        let valorDoCampo = (e != null && e.value) || (e != null && e.target != null && e.target.value);
        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: '',
        });
    };

    retornaRodapeModalConfirmacao() {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                btn1Label={LABEL_COMUNS.NAO}
                btn1OnClick={() => this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.CONFIRMACAO)}
                btn2Label={LABEL_COMUNS.SIM}
                btn2OnClick={() => {
                    if (this.state.acaoArmazenada.acao === ACAO.TRANSFERENCIA_EM_LOTE_ARQUIVO.APROVAR.value) {
                        this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.CONFIRMACAO);
                        this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL.TRANSFERENCIA_EM_LOTE.TOKEN);
                        this.setPropsCampos({ target: { value: '' } }, CAMPOS.TOKEN);
                        this.props.solicitarTokenTransferenciaEmLote(this.state.pessoaLogada.id);
                    }
                    else {
                        this.props.efetivaTransacaoDeOperacoesEmLote(this.state.transferenciaEmLoteArmazenada.arquivoId, this.state.acaoArmazenada);
                    }
                }}
            />
        );
    }

    retornaRodapeModalFinalizacao() {
        const { contaId, pessoaId } = this.state;
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setState(this.ESTADO_INICIAL);
                    this.buscarTransferenciasEmLote(contaId, pessoaId);
                }}
            />
        );
    }

    limparFiltro = () => {
        this.setState({ dataDeInclusaoDe: '', dataDeInclusaoAte: '', operacaoSelecionadaId: '', statusSelecionado: '', idArquivoSequencialPorPessoaId: '' });
    };

    onClickAprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onClickReprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    // exibeMensagemMigracao(data) {
    //     if (data.entity && data.entity.codigo && data.entity.codigo === LABEL_COMUNS.CODIGO_ERRO_MIGRACAO_CORE) {
    //         return data.entity.erros[0].mensagem;
    //     }
    //     else return data.mensagem;
    // };


    onOpenModalFinalizacao = (nomeModalConfirmacao) => (
        (tipo, data) => (

            this.setState({
                exibirLoading: false,
                [nomeModalConfirmacao]: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: data.mensagem
            })
        )
    );

    onCloseModalConfirmacao = (nomeModalConfirmacao) => (
        () => (
            this.setState({
                [nomeModalConfirmacao]: false,
            })
        )
    )

    onAprovar = async () => {
        this.setState({ exibirLoading: true, exibirModalConfirmacaoAprovacao: false });

        const { transferenciaEmLote, anexoContratualId, } = this.state;

        const response = await approveOrReproveArchiveApi(transferenciaEmLote.id, anexoContratualId, APROVAR_OU_REPROVAR_ARQUIVO.ACAO.APROVAR);

        if (response.error || response === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.ERROR) {
            if (response.data && response.data.codigo === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.CODIGO) {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.MENSAGEM
                });
            } else {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.GENERICO
                });
            }
        }
        if (response.data && response.data.status === APROVAR_OU_REPROVAR_ARQUIVO.STATUS.APROVADO) {
            this.setState({
                exibirLoadinghHistoricoTransferenciaEmLote: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.SUCESSO.APROVADO
            });
        }

        this.setState({ exibirLoading: false });
    };

    onReprovar = () => {
        this.setState({ exibirLoading: true });

        const { transferenciaEmLote, anexoContratualId } = this.state;
        const functionName = 'exibirModalConfirmacaoReprovacao';

        reprovar(transferenciaEmLote.id, anexoContratualId, this.onCloseModalConfirmacao(functionName), this.onOpenModalFinalizacao(functionName));
    };

    render() {
        const {
            contaId, pessoaId, contaSelecionada, dataDeInclusaoDe, dataDeInclusaoDeErro, dataDeInclusaoAte, dataDeInclusaoAteErro, exibirLoading,
            idArquivoSequencialPorPessoaId, listaDeTransferenciasEmLote, operacaoSelecionadaId, statusSelecionado
        } = this.state;


        const { history } = this.props;
        if (this.state.redirecionarParaTelaDeCarregarConta) {
            return <Redirect to={ROTAS_CAMINHOS.PEDIDO_DE_CARGA} />;
        }
        if (this.state.redirecionarTelaDetalhesArquivo) {
            return <Redirect to={ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_DETALHES} />;
        }
        return (
            <div>
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemConfirmacao}
                    footer={this.retornaRodapeModalConfirmacao()} />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteFinalizacao}
                    name="modalFinalizacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemFinalizacao} footer={this.retornaRodapeModalFinalizacao()} />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoAprovacao}
                    name="modalConfirmacaoAprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao })}
                            btn2OnClick={this.onAprovar}
                        />
                    } />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoReprovacao}
                    name="modalConfirmacaoAprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao })}
                            btn2OnClick={this.onReprovar}
                        />
                    } />

                <Grid cols="12 12 12 12" className="espacamento_conteudo_paginas">
                    {this.props.agendamentoBppPag.contaSelecionada && <ContaSelecionada
                        contextoBppPag={true}
                        contaSelecionada={this.props.agendamentoBppPag.contaSelecionada}
                        mostrarFiltroDeDatas={true}
                        mostrarFiltroDeIdNumerico={true}
                        exibirFiltroDeOperacao={true}
                        exibirFiltroDeStatus={true}
                        exibirSaldo={true}

                        dataInicialLabel={LABEL_COMUNS.DATA_DE_INCLUSAO}
                        dataInicialPlaceHolder={LABEL_COMUNS.DATA_DE}
                        dataInicialValue={dataDeInclusaoDe}
                        dataIncialChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_DE)}
                        dataInicialErro={this.state.dataDeInclusaoDeErro}

                        dataFinalPlaceHolder={LABEL_COMUNS.DATA_ATE}
                        dataFinalValue={dataDeInclusaoAte}
                        dataFinalChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_DE_INCLUSAO_ATE)}
                        dataFinalErro={this.state.dataDeInclusaoAteErro}

                        idNumerico={idArquivoSequencialPorPessoaId}
                        setIdNumerico={(e) => this.setPropsCampos(e, CAMPOS.ID_ARQUIVO_SEQUENCIAL_POR_PESSOA_ID)}
                        idNumericoErro={this.state.idArquivoSequencialPorPessoaIdErro}

                        operacaoSelecionadaId={operacaoSelecionadaId}
                        setOperacao={(e) => this.setPropsCampos(e, CAMPOS.OPERACAO_SELECIONADA_ID)}
                        listaDeOperacoes={COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE()}

                        listaDeStatus={COMBO.STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS}
                        setStatus={(e) => this.setPropsCampos(e, CAMPOS.STATUS_SELECIONADO)}
                        statusSelecionado={statusSelecionado}
                        statusErro={this.state.statusSelecionadoErro}

                        exibirBotaoLimpar={true}
                        limparFiltro={this.limparFiltro}
                        filtrar={() => this.buscarTransferenciasEmLote(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId)}

                    />}
                </Grid>
                <Grid cols="11 11 11 11">
                    <div className="panel panel-default ">
                        <div className={ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG}><b>{LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}</b></div>
                        <div className="panel-body">
                            {
                                exibirLoading ?
                                    <div className="alinhamento-centro">
                                        <Loading />
                                    </div>
                                    :
                                    exibirLoading !== undefined &&
                                    <If test={listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.length > 0}>
                                        <div className="row">
                                            <Grid cols="12 12 12 12" className="grids-dashboards">
                                                <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ID}</span>
                                                </Grid>
                                                <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao" >{LABEL_COMUNS.OPERACAO}</span>
                                                </Grid>

                                                <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.VALOR}</span>
                                                </Grid>

                                                <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DATA_DE_INCLUSAO}</span>
                                                </Grid>
                                                <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.STATUS}</span>
                                                </Grid>
                                                <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                    <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.ACAO}</span>
                                                </Grid>
                                            </Grid>
                                        </div>
                                        <div className="row">
                                            <div className={TIPO_DE_ACESSO.SELECIONADO() === 'PF' ? 'grid-agendamentoPf' : 'grid-agendamentoBppPag'}>
                                                {
                                                    listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.map((transferenciaEmLote, idx) => {
                                                        return (
                                                            <div className="row" key={idx}>
                                                                <Grid cols="12 12 12 12" className="grids-dashboards">
                                                                    <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {transferenciaEmLote.idArquivoSequencialPorPessoaId}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {FILTROS.TRANSFERENCIA_EM_LOTE.OPERACAO(transferenciaEmLote.operacao).label}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="2 2 2 2" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {formatarCampoValor(transferenciaEmLote.tipo, transferenciaEmLote.valorTotalLote, transferenciaEmLote.moeda)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px' }}>
                                                                            {formatarDataBR(transferenciaEmLote.dataCriacao)}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="3 3 3 3" className="alinhamento-centro">
                                                                        <span style={{ fontSize: '11px', }}>
                                                                            {transferenciaEmLote.statusExibicao}
                                                                        </span>
                                                                    </Grid>
                                                                    <Grid cols="1 1 1 1" className="alinhamento-centro">
                                                                        <MenuAcoes
                                                                            transferenciaEmLote={transferenciaEmLote}
                                                                            history={history}
                                                                            onClickAprovar={() => this.onClickAprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}
                                                                            onClickReprovar={() => this.onClickReprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}
                                                                            podeAprovar={this.props.outorgaPerfils.podeAprovar}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </div>
                                                        );
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </If>
                            }
                            <If test={this.state.exibirMaisRegistros}>
                                <button
                                    type="button"
                                    className="btn btn-secondary btn-block fonte-tamanho-20"
                                    onClick={() => this.buscarTransferenciasEmLote(contaId, pessoaId, dataDeInclusaoDe, dataDeInclusaoAte, operacaoSelecionadaId, statusSelecionado, idArquivoSequencialPorPessoaId, listaDeTransferenciasEmLote)}
                                    style={{ height: '50px' }}>
                                    <b>{LABEL_COMUNS.MOSTRAR_MAIS}</b>
                                </button>
                            </If>
                            <If test={listaDeTransferenciasEmLote && listaDeTransferenciasEmLote.length <= 0 && !exibirLoading}>
                                <span>{LABEL_COMUNS.NENHUM_AGENDAMENTO_ENCONTRADO}</span>
                            </If>
                        </div>
                    </div>
                </Grid>
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG} onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>

                </Grid>
            </div >
        );
    }
}

const mapStateToProps = (state) => ({
    agendamentoBppPag: state.AgendamentoReducer, outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    }
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoEAprovacoesBppPag);
