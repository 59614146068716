import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { EXPORT_EXCEL, LABEL_COMUNS } from 'componentes/util/contantes-comuns/constantes-comuns';
import { reactLocalStorage } from 'reactjs-localstorage';
import { getReportCardAnalityc, getReportCardBalance } from 'servicos/relatorios/relatorios-bpp-corp-service';
import { formataMoeda } from 'componentes/util/numeros/numeroUtil';

class RelatorioAnalyticRow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoading: false
        };
    }

    geraRelatorioBalance() {
        this.props.changeLoading(true);
        let dia = this.props.dia;
        let body = this.bodyBuilder(dia);
        this.geraRelatorioBalanceGetFunc(body);
    // downloadCSV("relatorio-balance.csv", reportData);
    }

    geraRelatorioAnalytic() {
        this.props.changeLoading(true);
        let dia = this.props.dia;
        let body = this.bodyBuilder(dia);
        this.geraRelatorioAnalyticGetFunc(body);
    }

    geraRelatorioBalanceGetFunc(body) {       
        getReportCardBalance(body).then(resultado => {
            this.props.changeLoading(false);         
            if (resultado === 'error') {
                this.props.setModalErrorGerarRelatorio(true);
            } else {
                this.downloadCSV('relatorio-saldo.csv', resultado.data.response, 'balance');
            }
        });
    }

    geraRelatorioAnalyticGetFunc(body) {
        getReportCardAnalityc(body).then(resultado => {
            this.props.changeLoading(false);
            if (resultado === 'error') {
                this.props.setModalErrorGerarRelatorio(true);
            } else {
                this.downloadCSV('relatorio-analitico.csv', resultado.data.response, 'analytics');
            }
        });
    }

    bodyBuilder(dia) {
        let contaMovimento = this.props.idContaMovimento;
        let body = {};
        let dateParts;
        let dateObject;

        if (this.props.precisaDia) {
            dateParts = dia.split('/');
            dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            body = { day: dateObject.getDate(), month: dateObject.getMonth() + 1, year: dateObject.getFullYear(), accountId: contaMovimento, token: reactLocalStorage.get('token') };
        } else {
            dia = dia.split(' - ')[0];
            dateParts = dia.split('/');
            dateObject = new Date(dateParts[2], dateParts[1] - 1, dateParts[0]);
            body = { month: dateObject.getMonth() + 1, year: dateObject.getFullYear(), accountId: contaMovimento, token: reactLocalStorage.get('token') };
        }

        return body;
    }

    downloadCSV(reportName, reportData, type) {     
        let data, link;
        const dados = this.getDadosItem(type, reportData);
        const jsonObject = JSON.stringify(dados);
        const universalBOM = '\uFEFF';
        let csvConverted = EXPORT_EXCEL.CONVERTER_ARRAY_PARA_CSV(jsonObject);

        data = 'data:text/csv;charset=UTF-8,' + universalBOM + this.geraCabecalho(type) + encodeURIComponent(csvConverted);
        link = document.createElement('a');
        link.setAttribute('href', data);
        link.setAttribute('download', reportName);
        link.click();
    }
    geraCabecalho(type) {
        const { ID_CARTAO, NOME_PORTADOR, CPF, DATA_TRANSACAO, HORA_TRANSACAO, TIPO_TRANSACAO, CODIGO_AUTORIZACAO, STATUS_TRANSACAO, MOTIVO_RECUSA_TRANSACAO, NOME_ESTABELECIMENTO, TIPO_ESTABELECIMENTO, MOEDA_CARTAO, VALOR_DEBITADO_BRL, MOEDA_TRANSACAO, VALOR_TRANSACAO, PRESTACAO_CONTAS, STATUS_PRESTACAO_CONTAS, DATA_PRESTACAO_CONTAS, CLASSIFICACAO_CLIENTE_PROJETO, CLASSIFICACAO_TIPO_DEPESA, DESCRICAO_PRESTACAO_CONTAS, DATA_ANALISE_PRESTACAO_CONTAS, OBSERVACAO_PRESTACAO_CONTA, IMAGEM_ANEXADA, SALDO_ATE_DIA_ANTERIOR, SALDO_DIA, STATUS_CARTAO, DATA_VALIDADE_CARTAO } = LABEL_COMUNS.RELATORIO.CABECALHO;
        if (type === 'analytics') {
            return `${ID_CARTAO};${NOME_PORTADOR};${CPF};${DATA_TRANSACAO};${HORA_TRANSACAO};${TIPO_TRANSACAO};${CODIGO_AUTORIZACAO};${STATUS_TRANSACAO};${MOTIVO_RECUSA_TRANSACAO};${NOME_ESTABELECIMENTO};${TIPO_ESTABELECIMENTO};${MOEDA_CARTAO};${VALOR_DEBITADO_BRL};${MOEDA_TRANSACAO};${VALOR_TRANSACAO};${PRESTACAO_CONTAS};${STATUS_PRESTACAO_CONTAS};${DATA_PRESTACAO_CONTAS};${CLASSIFICACAO_CLIENTE_PROJETO};${CLASSIFICACAO_TIPO_DEPESA};${DESCRICAO_PRESTACAO_CONTAS};${DATA_ANALISE_PRESTACAO_CONTAS};${OBSERVACAO_PRESTACAO_CONTA};${IMAGEM_ANEXADA}\r\n`;
        } else {
            return `${ID_CARTAO};${NOME_PORTADOR};${CPF};${SALDO_ATE_DIA_ANTERIOR};${SALDO_DIA};${STATUS_CARTAO};${DATA_VALIDADE_CARTAO}\r\n`;
        }
    }

    verificaNegativo(transactionType) {
        if (transactionType === 'DESCARGA' | 'COMPRA' | 'SAQUE' | 'TARIFA CONVERSAO OUTRO PAIS'){
            return '-';
        }
        else return '';
    }

    getDadosItem(type, reportData) {
        // Estamos usando ponto-e-virgula para separar as colunas no csv, então qualquer dado que tiver vírgula tem que ter aspas duplas.
        // https://tools.ietf.org/html/rfc4180 (página 2 no item 6).     
        var resultado = [];
        var item = '';

        if (type === 'balance') {
            let arr = reportData;
            for (let i in arr) {
                item = {
                    'Id Cartao': !arr[i].cardId ? '' : arr[i].cardId,
                    'Nome Portador': !arr[i].bearerName ? '' : arr[i].bearerName,
                    'CPF': !arr[i].documentNumber ? '' : arr[i].documentNumber,
                    'Saldo ate o dia Anterior': !arr[i].lastBalanceDay ? '' : `"${formataMoeda(arr[i].lastBalanceDay)}"`,
                    'Saldo (dia 01)': !arr[i].firstBalanceDay ? '' : `"${formataMoeda(arr[i].firstBalanceDay)}"`,
                    'Status Cartao': !arr[i].status ? '' : arr[i].status,
                    'Data de Validade Cartao': !arr[i].validateDate ? '' : arr[i].validateDate
                };
                resultado.push(item);
            }
        } else if (type === 'analytics') {
            let arr = reportData;
            for (let i in arr) {
                item = {
                    'ID Cartao': !arr[i].cardId ? '' : arr[i].cardId,
                    'Nome Portador': !arr[i].bearerName ? '' : arr[i].bearerName,
                    'CPF': !arr[i].documentNumber ? '' : arr[i].documentNumber,
                    'Data da Transacao': !arr[i].transactionDate ? '' : arr[i].transactionDate,
                    'Hora da Transacao': !arr[i].transactionTime ? '' : arr[i].transactionTime,
                    'Tipo de Transacao': !arr[i].transactionType ? '' : arr[i].transactionType,
                    'Codigo de Autorizacao': !arr[i].codeAuthorization ? '' : arr[i].codeAuthorization,
                    'Status de Transacao': !arr[i].transactionStatus ? '' : arr[i].transactionStatus,
                    'Motivo Recusa Transacao': '',
                    'Nome do Estabelecimento': !arr[i].establishmentName ? '' : arr[i].establishmentName,
                    'Tipo Estabelecimento': !arr[i].establishmentType ? '' : arr[i].establishmentType,
                    'Moeda do cartao': !arr[i].cardCurrency ? '' : arr[i].cardCurrency,
                    'Valor Debitado BRL': !arr[i].cardAmount ? '' : `"${this.verificaNegativo(arr[i].transactionType)}${formataMoeda(arr[i].cardAmount)}"`,
                    'Moeda da Transacao': !arr[i].transactionCurrency ? '' : arr[i].transactionCurrency,
                    'Valor da Transacao': !arr[i].transactionAmount ? '' : `"${formataMoeda(arr[i].transactionAmount)}"`,
                    'Prestacao de Contas': !arr[i].accountability ? '' : arr[i].accountability,
                    'Status Prestacao de Contas': !arr[i].accountabilityStatus ? '' : arr[i].accountabilityStatus,
                    'Data da Prestacao de Contas': !arr[i].accountabilityDate ? '' : arr[i].accountabilityDate,
                    'Classificacao Cliente ou Projeto': !arr[i].accountabilityProject ? '' : arr[i].accountabilityProject,
                    'Classificacao Tipo de Despesa': !arr[i].accountabilityClassification ? '' : arr[i].accountabilityClassification,
                    'Descricao da Prestacao de Contas': !arr[i].accountabilityDescription ? '' : arr[i].accountabilityDescription,
                    'Data da Analise de Prestacao de Contas': !arr[i].accountabilityDateAnalyze ? '' : arr[i].accountabilityDateAnalyze,
                    'Observação da Prestação de Conta': !arr[i].accountabilityRefusalReason ? '' : arr[i].accountabilityRefusalReason,
                    'Imagem Anexada': !arr[i].accountabilityAttachedImage ? '' : arr[i].accountabilityAttachedImage
                };
                resultado.push(item);
            }
        }
        return resultado;
    }

    render() {
        let dia = this.props.dia;

        return (
            <tr >
                <td style={{ color: 'black' }}>{dia}</td>
                <td style={{ padding: 5 }} ><Button className="btn btn-success btn-sm" style={{ marginLeft: 23, cursor: 'pointer' }} title="Exportar para .CSV" onClick={() => this.geraRelatorioAnalytic()}><i className="fa fa-file-excel-o" aria-hidden="true" /></Button></td>
                <td style={{ padding: 5 }}><Button className="btn btn-success btn-sm" style={{ marginLeft: 13, cursor: 'pointer' }} title="Exportar para .CSV" onClick={() => this.geraRelatorioBalance()}><i className="fa fa-file-excel-o" aria-hidden="true" /></Button></td>
            </tr>
        );
    }
}

export default RelatorioAnalyticRow;
