import { METODOS } from '../pedidos-constantes';
import { TIPO_DE_DOCUMENTO, TIPOS_DE_OPERACAO, MENSAGENS } from '../../util/contantes-comuns/constantes-comuns';

const ESTADO_INICIAL = {
    mensagem: ''
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case METODOS.PEDIDO_CARTAO_VIRTUAL.GET_IMAGEM:
            return { ...state, comboRecuperado: action.payload };
        case METODOS.PEDIDO_CARTAO_VIRTUAL.GET_IMAGEM_ERRO:
            return { ...state };
        case METODOS.PEDIDO_CARTAO_VIRTUAL.SAVE_FORM_PEDIDO_CARTAO_VIRTUAL:
            return { ...state, form: action.payload };
        case METODOS.PEDIDO_CARTAO_VIRTUAL.SOLICITAR_CARTAO_VIRTUAL:
            return { ...state, mensagem: MENSAGENS.PEDIDO_CARTAO_VIRTUAL.FINALIZACAO.SUCESSO };
        case METODOS.PEDIDO_CARTAO_VIRTUAL.SOLICITAR_CARTAO_VIRTUAL_ERRO:
            return { ...state, mensagem: MENSAGENS.PEDIDO_CARTAO_VIRTUAL.FINALIZACAO.ERRO };
        default:
            return state;
    }
};
