export function trim(stringToTrim) {
    return stringToTrim.replace(/^\s+|\s+$/g, '');
}

export function ltrim(stringToTrim) {
    return stringToTrim.replace(/^\s+/, '');
}

export function rtrim(stringToTrim) {
    return stringToTrim.replace(/\s+$/, '');
}

export function capitalizeFirstLetter(string) {
    return string && string.replace(/^./, string[0].toUpperCase());
}

export const generateUUID = () => {
    let today = new Date().getTime();
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        const r = (today + Math.random() * 16) % 16 | 0;
        today = Math.floor(today / 16);
        return (c === 'x' ? r : (r && 0x3 | 0x8)).toString(16);
    });
};

export const returnStringWithoutNumbers = (strData) => {
    const regex = new RegExp('^[?:a-zA-Z\sáàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$');
    if (strData === '' || regex.test(strData)){
        return strData;
    }
    return null;

};
/* export function retornarSomenteData(dataCompleta) {
    let dataFormatada = dataCompleta.substring(0, 10);
    return dataFormatada;
} */
