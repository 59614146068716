import { reactLocalStorage } from 'reactjs-localstorage';

let itens = [];
const SEPARADOR = ',';
const MAXIMO_PERMITIDO = 1;

export function proximo(id, sessao) { // cria uma lista de id para navegação
    reactLocalStorage.set(sessao, reactLocalStorage.get(sessao) + SEPARADOR + id);
}
export function anterior(sessao) { // retorna ao id anterior
    let result = 0;
    try {
        itens = reactLocalStorage.get(sessao).split(SEPARADOR);
        if (Array.isArray(itens)) {
            if (itens.length > MAXIMO_PERMITIDO) {
                itens.pop();
            }
            reactLocalStorage.set(sessao, itens.toString());
        }
    } catch (ex) {
        itens = reactLocalStorage.get(sessao);
    }
    try { result = itens.length; } catch (ex) { }
    return result;
}
export function obter(sessao) { // obtem o id corrente
    try {
        itens = reactLocalStorage.get(sessao).split(SEPARADOR);
        if (Array.isArray(itens)) {
            return itens[itens.length - 1];
        } else {
            return itens;
        }
    } catch (ex) {
        return reactLocalStorage.get(sessao);
    }
}
export function exibirLabel(sessao) { // apenas para exibir os id's com uma setinha indicando a direção do encadeamento
    var itenArray = new Array();
    try {
        let itensLocal = reactLocalStorage.get(sessao).split(SEPARADOR);
        for (var i = 0; i < itensLocal.length; i++) {
            itenArray.push(itensLocal[i] + '=>');
        }
    } catch (ex) { }
    return itenArray.toString();
}
