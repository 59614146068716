import { MENSAGEM, METODOS } from '../contestacao/contestacao-constantes';
import { preencheCamposDeErros } from '../contestacao/contestacao-compra-erros';

const estadoInicial = {
    listaDeMotivos: [],
    descricao: '',
    descricaoErro: '',
    valorTransacao: '',
    categoria: '',
    data: '',
    estabelecimento: '',
    motivoValue: '',
    motivoErro: '',
    salvarContestacao: '',
    erro: '',
    constestacaoErro: '',
    exibeModal: '',
    finalizacaoConstestacao: '',
    mensagemContestacao: ''
};

export default function reducer(state = estadoInicial, action = {}) {
    switch (action.type) {
        case METODOS.LISTAR_MOTIVOS_CONTESTACAO:
            return {
                ...state,
                listaDeMotivos: montaMotivosParaCombo(action.payload),
                finalizacaoConstestacao: '',
                mensagemContestacao: ''
            };
        case METODOS.LISTAR_MOTIVOS_CONTESTACAO_ERRO:
            return {
                ...state,
                constestacaoErro: action.payload,
                finalizacaoConstestacao: '',
                mensagemContestacao: 'Mensagem Erro Contestação'
            };
        case METODOS.MOTIVO_CHANGE_HANDLER:
            let motivoSelecionado = action.payload ? action.payload.value : '';
            return {
                ...state, motivoValue: motivoSelecionado, finalizacaoConstestacao: '', mensagemContestacao: '', motivoErro: ''
            };
        case METODOS.DESCRICAO_HANDLE_CHANGE:
            return {
                ...state, descricao: action.payload, descricaoErro: ''
            };
        case METODOS.SALVAR_CONTESTACAO:
            return {
                ...state, salvarContestacao: action.payload, finalizacaoConstestacao: true, mensagemContestacao: MENSAGEM.CONTESTACAO_SUCESSO, exibeModal: true
            };
        case METODOS.ERRO_SALVAR:
            let camposDeErros = preencheCamposDeErros(action.payload.erros);
            return {
                ...state, erro: action.payload, finalizacaoConstestacao: false, mensagemContestacao: MENSAGEM.CONTESTACAO_FALHA, exibeModal: false, motivoErro: camposDeErros.motivoErro, descricaoErro: camposDeErros.descricaoErro
            };
        case METODOS.FECHAR_MODAL:
            return {
                ...state, exibeModal: false
            };
        default:
            return state;
    }

    function montaMotivosParaCombo(motivos) {
        let listaDeMotivos = [];
        motivos.map((motivo, idx) => {
            return listaDeMotivos.push({ value: motivo.id, label: motivo.descricao });
        });
        return listaDeMotivos;
    }
}
