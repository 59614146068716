const extensoesAceitas = {
    padraoBPP: [ '.csv' ],
    cnab: [ '.rem', '.edi' ]
};

export const getModeloArquivoValido = (modeloArquivo) => (
    Object.keys(extensoesAceitas).find(e => modeloArquivo && e.toLocaleUpperCase() === modeloArquivo.toLocaleUpperCase())
);

export const isExtensaoValida = (extensao, modelo) => {
    if (!modelo || !extensao) {
        return false;
    }

    const key = getModeloArquivoValido(modelo);
    const extensoes = extensoesAceitas[key];

    if (!extensoes) {
        return false;
    }

    const extensoesvalidas = extensoes.find(e => e.toLocaleUpperCase() === extensao.toLocaleUpperCase());

    return extensoesvalidas !== undefined;
};

export const getExtensoesAceitasPorModeloArquivo = (modeloArquivo) => {
    let listaExtensoesAceitas = '';

    if (!modeloArquivo) {
        return listaExtensoesAceitas;
    }

    const modeloArquivoValido = getModeloArquivoValido(modeloArquivo);
    const extensaoPorModelo = extensoesAceitas[modeloArquivoValido];

    if (!extensaoPorModelo || extensaoPorModelo.length === 0) {
        return listaExtensoesAceitas;
    }

    extensaoPorModelo.forEach(e => listaExtensoesAceitas += `${e}, `);

    return listaExtensoesAceitas.substring(0, listaExtensoesAceitas.lastIndexOf(', '));
};
