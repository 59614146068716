export const METODOS = {
    BUSCAR_SALDO_CONTA: 'buscarSaldoConta',
    BUSCAR_SALDO_CONTA_ERRO: 'buscarSaldoContaErro',
    SET_MOSTRAR_SALDO: 'setMostrarSaldo',
    SET_SALDO: 'setSaldo',
    SET_ATUALIZANDO_SALDO: 'setAtualizandoSaldo'
};

export const LABEL = {
    EXIBIR_SALDO: 'Exibir o saldo'
};
