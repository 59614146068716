import './transferencia.css';
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import ContaSelecionada from '../../componentes/contas/conta-selecionada';
import { COMBO, CONTA_SELECIONADA, LABEL_COMUNS, TIPO_TRANSFERENCIA_BANCARIA } from '../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import Grid from '../componentes-uteis/grid/grid';

class TransferenciaBancaria extends Component {
    constructor(props) {
        super(props);
        this.state = {
            redirecionarCargaLoteConta: false,
            redirecionarTransferenciaSolicitacao: false,
            redirecionarDashboardConta: false,
        };
    }

    retornaLabelsTransferencias() {
        return COMBO.TRANSFERENCIA_BANCARIA.filter(lblTransferencia => (lblTransferencia.value !== TIPO_TRANSFERENCIA_BANCARIA.CARGA_LOTE_CONTA.value));
    }

    render() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        if (this.state.redirecionarCargaLoteConta) {
            return <Redirect to={this.state.pathCargaLote} />;
        }
        if (this.state.redirecionarDashboardConta) {
            if (this.props.contextoMeusDepartamentos) {
                return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_DEPARTAMENTO} />;
            }
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_CONTA} />;
        }
        if (this.state.redirecionarTransferenciaSolicitacao) {
            return <Redirect to={{ pathname: this.state.pathName, state: { transferenciaSelecionadaId: this.state.transferenciaSelecionadaId } }} />;
        }
        return (
            <div>
                <ContaSelecionada contaSelecionada={contaSelecionada} />
                <div className="row">
                    <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9 espacamento_topo">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-azul-claro">{LABEL_COMUNS.DESEJA_TRANSFERIR}</div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="row form-group">
                                            <div className="row">
                                                <div className="col-xs  -12 col-sm-12 col-md-12 col-lg-12 alinhamento-centro">
                                                    <br />
                                                    {
                                                        this.retornaLabelsTransferencias().map((tranferencia, idx) => {
                                                            return (
                                                                <label style={{ color: 'black' }} key={idx}>
                                                                    <input type="radio" name="transferencia" onChange={this.redirecionarParaTransferenciaSolicitacao} value={tranferencia.value} />&nbsp;{tranferencia.label} &nbsp;&nbsp;
                                                                </label>
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2" />
                </div>
                <br />
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button bsStyle="primary" className="botao-voltar-telas" onClick={() => this.redirecionarDashboardConta()}>{LABEL_COMUNS.VOLTAR}</Button>
                    <br /><br />
                </Grid>
            </div>
        );
    }

    redirecionarDashboardConta() {
        this.setState({ redirecionarDashboardConta: true });
    }

    redirecionarParaTransferenciaSolicitacao = (event) => {
        const transferenciaSelecionadaId = event.target.value;
        if (event.target.value === TIPO_TRANSFERENCIA_BANCARIA.CARGA_LOTE_CONTA.value) {
            const pathCargaLote = this.props.contextoMeusDepartamentos ? ROTAS_CAMINHOS.CARGA_LOTE_CONTA_DEPARTAMENTO : ROTAS_CAMINHOS.CARGA_LOTE_CONTA;
            this.setState({ redirecionarCargaLoteConta: true, transferenciaSelecionadaId, pathCargaLote });
        }
        else {
            const pathName = this.props.contextoMeusDepartamentos ? ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_SOLICITACAO_CONTA_DEPARTAMENTO : ROTAS_CAMINHOS.TRANSFERENCIA_BANCARIA_SOLICITACAO_CONTA_MOVIMENTO;
            this.setState({ redirecionarTransferenciaSolicitacao: true, transferenciaSelecionadaId, pathName });
        }
    }
}
export default TransferenciaBancaria;
