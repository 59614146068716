import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import { reactLocalStorage } from 'reactjs-localstorage';
import If from '../componentes-uteis/if/if';
import * as cabecalhoAction from './cabecalho-action';
import './cabecalho.css';
import { ROTAS_CAMINHOS } from '../rotas-constantes';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';

class Cabecalho extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        document.getElementById('root').children[0].children[1].style.marginBottom = '100px';
    }

    /* retornaPaginas() {
        return ((reactLocalStorage.get('tipoAcesso') === "PJ") && ((this.props.history.location.pathname === '/carga-lote') || (this.props.history.location.pathname === '/carga-lote-novo') || (this.props.history.location.pathname === '/cartoes') || (this.props.history.location.pathname === '/parametro-conta') || (this.props.history.location.pathname === '/gerenciar-prestacao-conta')));
    } */

    render() {
        /* let tipoAcesso = reactLocalStorage.get('tipoAcesso');
            let template = reactLocalStorage.getObject('contaSelecionada').templateNome; */
        let empresaSelecionada = reactLocalStorage.get('tipoAcesso') === 'PJ' ? reactLocalStorage.getObject('empresaSelecionada').pessoaJuridica.razaoSocial : reactLocalStorage.set('empresaSelecionada', '');
        let dashboard_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/dashboard';
        let carga_lote_cartao_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/carga-lote-cartao';
        let cartoes_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/cartoes';
        let prestacao_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/gerenciar-prestacao-conta';
        let pedido_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/consulta-pedido';
        let pedido_impresso_corp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/pedido-cartao-impresso';
        let dashboard_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/dashboard';
        let agendamento_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/agendamento-aprovacoes';
        let cartoes_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/cartoes';
        const relatoriosDashboardBppCorp = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/relatorios/dashboard';
        const relatorioBppCorpAnalitico = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/relatorios/relatorio-analitico';
        const relatorioBppCorpGerencial = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-corp/relatorios/relatorio-gerencial';
        let titularidade_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/alterar-titularidade-cartao';
        let transferencia_em_lote = this.props.history.location.pathname === ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE;
        let transferencia_em_lote_upload_arquivo = this.props.history.location.pathname === ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_NOVO;
        let transferencia_em_lote_historico = this.props.history.location.pathname === ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_HISTORICO;
        let transferencia_cartao_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/transferencia-lote/carga';
        let transferencia_detalhes_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/transferencia-lote/transferencia-lote-detalhes';
        let pedido_impresso_pag = this.props.history.location.pathname === '/minha-conta/minhas-solucoes/bpp-pag/pedido-cartao-impresso';
        let detalhes_corp = this.props.history.location.pathname === ROTAS_CAMINHOS.DETALHES_BPP_CORP;
        let detalhes_pag = this.props.history.location.pathname === ROTAS_CAMINHOS.DETALHES_BPP_PAG;
        const dashboardBppConsumer = this.props.history.location.pathname === ROTAS_CAMINHOS.DASHBOARD_BPP_CONSUMER;
        const pedirCartaoBppConsumer = this.props.history.location.pathname === ROTAS_CAMINHOS.PEDIR_CARTAO_BPP_CONSUMER;
        const vincularCartaoBppConsumer = this.props.history.location.pathname === ROTAS_CAMINHOS.VINCULAR_CARTAO_BPP_CONSUMER;

        return (
            <div>
                <div id="cabecalho">
                    <div id="conteudo-cabecalho">
                        <div id="texto-cabecalho">
                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                <If test={dashboardBppConsumer || pedirCartaoBppConsumer || vincularCartaoBppConsumer}>
                                    <span className="cabecalho-bpp-consumer">{LABEL_COMUNS.SOLUCAO.MEU_BPP}</span>
                                </If>
                                <If test={dashboard_corp || cartoes_corp || prestacao_corp || pedido_corp || pedido_impresso_corp || carga_lote_cartao_corp || detalhes_corp
                                    || relatoriosDashboardBppCorp || relatorioBppCorpAnalitico || relatorioBppCorpGerencial}>
                                    <span className="cabecalho-bppcorp">{LABEL_COMUNS.SOLUCAO.BPP_CORP}</span>
                                </If>
                                <If test={dashboard_pag || agendamento_pag || cartoes_pag || titularidade_pag ||
                                    transferencia_em_lote_upload_arquivo || transferencia_em_lote_historico || transferencia_cartao_pag ||
                                    transferencia_detalhes_pag || pedido_impresso_pag || detalhes_pag}>
                                    <span className="cabecalho-bppppag">{LABEL_COMUNS.BPP_PAG}</span>
                                </If>
                                {/* <Titulo titulo={template} mostrar={this.retornaPaginas()} /> */}
                            </div>
                            <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10 alinhamento_usuario">
                                <span> Olá, {reactLocalStorage.get('nomeUsuario')} </span>
                                {/* <span id="icone-menu"><Glyphicon glyph="bell" /></span> */}
                                <br />
                                <If test={reactLocalStorage.get('tipoAcesso') === 'PJ' && this.props.history.location.pathname !== '/'}>
                                    <span className="alinhamento-empresa"> Você está acessando uma conta de {empresaSelecionada} </span>
                                </If>
                            </div>
                            <br />
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <span>{this.props.cabecalho.texto}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        cabecalho: state.CabecalhoReducer
    };
}

const mapDispatchToProps = dispatch => bindActionCreators(cabecalhoAction, dispatch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Cabecalho));
