import React from 'react';
import Grid from '../grid/grid';
import { Card } from 'primereact/card';
import ModalComponent from '../modal-component/modal-component';
import FooterBotoesModalComponent from '../modal-component/footer-botoes-modal-component';
import { LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import GridRow from '../grid/grid-row';
import { formatarDataEHoraBR, formatarDataBR } from '../../util/data/dataUtil';
import { formatarCampoValor, exibeTransacaoValor } from '../../util/extrato/extrato-util';
import If from '../if/if';

const DetalheExtrato = (props) => {
    const corpoModalDetalheExtrato = () => {
        const extrato = { ...props.linhaDoExtrato };
        const extratoId = extrato.id || extrato.bppMovimentoProcessadoraId;
        return (
            <div>
                <Card style={{ backgroundColor: '#f5f5f5' }}>
                    <div className="alinhamento-centro">
                        <span className="texto-detalhe-extrato">{formatarCampoValor(extrato.tipo, extrato.valor, extrato.moeda)}</span><br />
                        <b>{extrato.nome}</b>
                    </div>
                </Card>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.DATA_HORA}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {formatarDataBR(extrato.dataHora)}
                    </Grid>
                </GridRow>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.DETALHE}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {extrato.descricao}
                    </Grid>
                </GridRow>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.DESCRICAO}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {extrato.tagCliente}
                    </Grid>
                </GridRow>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.VALOR}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {formatarCampoValor(extrato.tipo, extrato.valor, extrato.moeda)}
                        <If test={exibeTransacaoValor(extrato)}>
                            <span className="texto-transacao-detalhes">{formatarCampoValor(extrato.tipo, extrato.integracaoTransacaoValor, extrato.integracaoTransacaoMoeda)}</span>
                        </If>
                    </Grid>
                </GridRow>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.ID_CARTAO}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {extrato.cartaoId ? extrato.cartaoId : null}
                    </Grid>
                </GridRow>
                <br />
                <GridRow>
                    <Grid cols="2 2 2 2">
                        <b><span>{LABEL_COMUNS.ID}</span></b>
                    </Grid>
                    <Grid cols="10 10 10 10">
                        {extratoId}
                    </Grid>
                </GridRow>
            </div>
        );
    };

    const rodapeModalDetalheExtrato = () => {
        return (
            <FooterBotoesModalComponent
        parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
        btn2Label={LABEL_COMUNS.OK}
        btn2OnClick={props.onClose} />
        );
    };

    return (
        <ModalComponent exibirModal={props.exibirModal} name="detalhe-extrato" header={props.header}
      body={corpoModalDetalheExtrato()}
      footer={rodapeModalDetalheExtrato()} />
    );
};

export default DetalheExtrato;
