import React, { Component } from 'react';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import { CONTA_SELECIONADA, LABEL_COMUNS } from 'componentes/util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import { podeAprovar, podeConsultar, podeCriar } from 'reducer/outorga/outorga-operacao-reducer';
import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';

class ArquivosTransferenciaLote extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: CONTA_SELECIONADA.OBJETO()
        };
    }

    render() {
        const styleBotoes = {
            marginRight: '30px',
            width: '180px',
            height: '30px',
            paddingTop: '4px',
            paddingLeft: '10p',
            background: 'rgb(146, 194, 124)',
            color: 'rgb(255, 255, 255)',
            borderColor: 'rgb(76, 174, 76)'
        };
        const labelBotao1 = LABEL_COMUNS.ARQUIVO.TITULOS.NOVO_ARQUIVO;
        // const labelBotao2 = LABEL_COMUNS.CARTOES.TITULOS.PEDIR_CARTAO;
        const labelBotao2 = 'Histórico de Arquivos';
        const labelBotao3 = LABEL_COMUNS.ARQUIVO.TITULOS.ARQUIVO_RETORNO;

        return (
            <div>
                <ContaSelecionada
                    contextoBppPag
                    contaSelecionada={this.state.contaSelecionada}
                />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <Button
                                style={styleBotoes}
                                className={labelBotao1}
                                onClick={() => this.props.history.push(ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_NOVO)}
                            >
                                {labelBotao1}
                            </Button>
                            {/* <Button
                                style={styleBotoes}
                                className={labelBotao2}
                                onClick={() => this.props.history.push(ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_HISTORICO)}
                            >
                                {labelBotao2}
                            </Button> */}
                            <Button
                                style={styleBotoes}
                                className={labelBotao3}
                                onClick={() => this.props.history.push(ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_RETORNO)}
                            >
                                {labelBotao3}
                            </Button>
                        </div>
                        <div style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            height: '300px',
                            alignItems: 'flex-end',
                            paddingRight: '15px'
                        }}>
                            <Button
                                style={{ height: '30px' }}
                                bsStyle="success"
                                className="botao-voltar-telas"
                                onClick={this.props.history.goBack}
                            >
                                {LABEL_COMUNS.VOLTAR}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

export default connect(mapStateToProps)(ArquivosTransferenciaLote);
