import React, { Component } from 'react';
import { CANAL_TOKEN, LABEL_COMUNS, MENSAGENS, OPERACAO, TIPO_DE_TRANSACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import { bloquearCartao } from 'servicos/cartoes/cartoes-service';
import Token from 'componentes/comuns/token/token';

class BloquearCartao extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = {
            showLockModal: false,
            messageLockModal: '',
            operationId: '',
            showLockFinalizationModal: false,
            lockFinalizationMessage: '',
            showLockModalToken: false,
            canApprove: false
        };
        this.state = this.INITIAL_STATE;
    }

    componentWillReceiveProps = (nextProps) => {
        const { grantProfile } = nextProps;
        const canApprove = grantProfile.podeAprovar;
        const { lockSelectedCard } = nextProps;
        this.setState({ canApprove });
        this.setLockSelectedCard(lockSelectedCard);
        this.setLockMessageModal(MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.CONFIRMACAO(OPERACAO.NOME.ALTERA_STATUS_CARTAO, lockSelectedCard.idCartaoImpresso, lockSelectedCard.panFormatado));
    };

    setLockSelectedCard = (lockSelectedCard) => {
        this.setState({ lockSelectedCard });
    };

    setLockMessageModal = (messageLockModal) => {
        this.setState({ messageLockModal });
    };

    setLockFinalizationMessage = (lockFinalizationMessage) => {
        this.setState({ lockFinalizationMessage });
    };

    setShowLockModalToken = (showLockModalToken) => {
        this.setState({ showLockModalToken });
    };

    setOperationId = (operationId) => {
        this.setState({ operationId });
    };

    setShowLockFinalizationModal = (showLockFinalizationModal) => {
        this.setState({ showLockFinalizationModal });
    };

    onLockCard = async () => {
        this.props.setShowLockLoading(true);
        const { grantChangeStatusCard } = this.props;
        const necessitaOtp = grantChangeStatusCard.aprovacao;

        this.props.setShowLockCardModal(false);
        const response = await bloquearCartao(this.state, necessitaOtp);
        const { message, operationId } = response;
        if (message) {
            this.setLockFinalizationMessage(message);
            this.props.setShowLockFinalizationModal(true);
        }
        else {
            if (operationId) {
                this.setOperationId(operationId);
                this.setShowLockModalToken(true);
            }

        }

        this.props.setShowLockLoading(false);
    };

    callBackTransaction = (retorno) => {
        let message = MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.SUCESSO();
        if (retorno.error) {
            message = MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.FINALIZACAO.ERRO();
        }
        this.setLockFinalizationMessage(message);
        this.props.setShowLockFinalizationModal(true);
    };

    clearState = () => {
        this.setState(this.INITIAL_STATE);
    };

    render() {
        const { showLockCardModal, showLockFinalizationModal } = this.props;
        const { messageLockModal, operationId, showLockModalToken, lockFinalizationMessage } = this.state;
        const transacao = TIPO_DE_TRANSACAO.APROVACAO;

        return (
            <div>
                <ModalComponent exibirModal={showLockCardModal}
                    name="modal-bloquear-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: messageLockModal }} />}
                    footer={
                        <FooterBotoesModalComponent
                            btn1Label={LABEL_COMUNS.NAO}
                            contextoBppPag={this.props.contextoBppPag}
                            btn1OnClick={() => {
                                this.props.setShowLockCardModal(false);
                            }}
                            btn2Label={LABEL_COMUNS.SIM}
                            btn2OnClick={() => {
                                this.onLockCard();

                            }}
                        />
                    } />
                <ModalComponent exibirModal={showLockFinalizationModal}
                    name="modal-bloquear-cartao"
                    contextoBppPag={this.props.contextoBppPag}
                    header={LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                    body={<div dangerouslySetInnerHTML={{ __html: lockFinalizationMessage }} />}
                    footer={
                        <FooterBotoesModalComponent
                            btn2Label={LABEL_COMUNS.OK}
                            contextoBppPag={this.props.contextoBppPag}
                            btn2OnClick={() => {
                                this.props.setShowLockFinalizationModal(false);
                                this.props.lockGetCards();
                                this.clearState();
                            }}
                        />
                    } />

                <Token
                    contextoBppPag={this.props.contextoBppPag}
                    dadosIntegracao={transacao}
                    dadosOtp={{ ...CANAL_TOKEN.CORPO_REQUISICAO_SMS, ...transacao }}
                    exibirModal={showLockModalToken}
                    fecharModal={() => {
                        this.setShowLockModalToken(false);
                    }}
                    operacaoId={operationId}
                    titulo={LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                    resultadoCallback={this.callBackTransaction}
                />

            </div>
        );
    }
}

export default BloquearCartao;
