export const MENSAGENS = {
    'ERRO_401': 'token expirou',
    'ERRO_403': 'conexao recusada',
    'ERRO_CARREGAR_ARQUIVO': 'não foi possivel carregar o arquivo.',
    'ERRO_LER_ARQUIVO': 'não foi possivel ler o arquivo',
    'ERRO_FILE_INPUT_VAZIO': 'É necessário selecionar um arquivo antes de importar.',
    'ERRO_FILE_INPUT_FALHA': 'nao é possivel importar o arquivo, verifique se foi carregado com sucesso.',
    'ERRO_EXTENSAO_INVALIDA': 'Extensão de arquivo inválida.',
    'ERRO_FORMATO_ARQUIVO': 'Formato de arquivo inválido.',
    'STATUS_CARREGAR_ARQUIVO': {
        'SUCESSO': 'sucesso',
        'PENDENTE': 'pendente'
    }
};
