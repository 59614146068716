import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Alert, Button, ControlLabel, FormControl, FormGroup, Radio } from 'react-bootstrap';
import * as cartaoAction from '../../../../../action/cartoes/cartoes-action';
import { CAMPOS, LABEL_COMUNS, MASCARA_CAMPOS, TIPO_DOCUMENTO, MENSAGENS, CONTA_SELECIONADA } from '../../../../util/contantes-comuns/constantes-comuns';
import Grid from '../../../../componentes-uteis/grid/grid';
import { ESTILO, STATUS_VALIDACAO_COMPONENTES } from '../../../../util/estilo_para_componentes/estiloUtil';
import imagemCartao from '../../../../../imagens/cartao-chip.jpg';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import { formatarStatus, montaDadosParaAlteracaoDeTitularidadeCartao } from '../../../../util/cartoes/cartoes-util';
import { CPF, CNPJ } from 'cpf_cnpj';
import { dataInvalida } from '../../../../util/data/dataUtil';
import If from '../../../../componentes-uteis/if/if';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { solicitarAlteracaoTitularidadeCartao, limparMensagemDeErroDeAlteracaoDeTitularidadeCartao } from '../../../../../action/cartoes/cartoes-action';



class AlterarTitularidadeCartao extends Component {
    constructor(props) {
        super(props);

        this.state = {
            cartaoSelecionado: props.location.state.cartaoSelecionado,
            dashboardBppPag: props.location.state.dashboardBppPag || false,
            nomeTitular: '', tipoDocumentoTitular: '', numeroDocumentoTitular: '', dataNascimentoTitular: '', celularTitular: '',
            nomePortador: '', tipoDocumentoPortador: '', numeroDocumentoPortador: '', dataNascimentoPortador: '',
            celularPortador: '', mascaraDocumento: '', tokenSMSAlterarTitularidadeCartao: '', mensagemToken: '',
            exibirModalTokenAlteraTitularidadeCartao: false, desabilitaBotaoConfirmar: true, redirecionarTelaCartoesBppPag: false,
            exibirModalConfirmacaoAlteraTitularidadeCartao: false, exibirModalFinalizacaoAlteraTitularidadeCartao: false,
            exibirLoadingAlterarTitularidadeCartao: false, redirecionarDashboardBppPag: false, numeroDocumentoTitularErro: '',
            numeroDocumentoPortadorErro: '', dataNascimentoTitularErro: '', dataNascimentoPortadorErro: '',
            contaSelecionada: CONTA_SELECIONADA.OBJETO()
        };
        this.validarForm = this.validarForm.bind(this);

    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.estadoCartoes.sucessoTansferenciaTitularidadeCartao && nextProps.estadoCartoes.sucessoTansferenciaTitularidadeCartao !== this.props.estadoCartoes.sucessoTansferenciaTitularidadeCartao) {
            this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_LOADING_ALTERAR_TITULARIDADE_CARTAO);
            this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL_FINALIZACAO_ALTERA_TITULARIDADE_CARTAO);
        }

        if (nextProps.estadoCartoes.errosAoEfetuarTranserenciaDeAlterarTitutaridadeCartao && nextProps.estadoCartoes.errosAoEfetuarTranserenciaDeAlterarTitutaridadeCartao !== this.props.estadoCartoes.erroAoEfetuarTranserenciaDeAlterarTitutaridadeCartao) {
            this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_LOADING_ALTERAR_TITULARIDADE_CARTAO);
            this.setState(nextProps.estadoCartoes.errosAoEfetuarTranserenciaDeAlterarTitutaridadeCartao);

        }
    }

    setRedirecionarTelaCartoesBppPag = (redirecionarTelaCartoesBppPag) => {
        this.setState({ redirecionarTelaCartoesBppPag });
    }

    setRedirecionarDashboardBppPag = (redirecionarDashboardBppPag) => {
        this.setState({ redirecionarDashboardBppPag });
    }

    componentDidMount() {
        this.setState({
            tipoDocumentoTitular: TIPO_DOCUMENTO.CPF.value, tipoDocumentoPortador: TIPO_DOCUMENTO.CPF.value,
            mascaraDocumento: MASCARA_CAMPOS.CPF
        });
        this.props.limparMensagemDeErroDeAlteracaoDeTitularidadeCartao();
    }

    setPropsCampos(e, data) {
        let valorDoCampo = e.target.value;
        if (valorDoCampo === TIPO_DOCUMENTO.CPF.value) {
            this.setState({
                mascaraDocumento: MASCARA_CAMPOS.CPF, numeroDocumentoTitular: '',
                nomePortador: '', tipoDocumentoPortador: '', numeroDocumentoPortador: '', dataNascimentoPortador: '',
                celularPortador: '', numeroDocumentoTitularErro: undefined, numeroDocumentoPortadorErro: undefined,
                dataNascimentoPortadorErro: undefined
            });
        }

        if (valorDoCampo === TIPO_DOCUMENTO.CNPJ.value) {
            this.setState({ mascaraDocumento: MASCARA_CAMPOS.CNPJ, numeroDocumentoTitular: '', numeroDocumentoTitularErro: undefined, });
        }

        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: '',
        }, () => {
            this.habilitaEDesabilitaBotaoConfirmar(this.state);
        });
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.state.tokenSMSAlterarTitularidadeCartaoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">{LABEL_COMUNS.TOKEN}</ControlLabel>
                    <FormControl
                        id="tokenSMSAlterarTitularidadeCartao"
                        name="tokenSMSAlterarTitularidadeCartao"
                        type="password"
                        className="form-control mensagens-erro"
                        placeholder={LABEL_COMUNS.DIGITE_O_TOKEN}
                        maxLength={6}
                        required="true"
                        value={this.state.tokenSMSAlterarTitularidadeCartao}
                        onChange={(e) => this.setPropsCampos(e, CAMPOS.TOKEN_SMS_ALTERAR_TITULARIDADE_CARTAO)}
                       /*  onKeyPress={this.pessionarEnter} */ />
                    {this.state.tokenSMSAlterarTitularidadeCartaoErro ? <span className="mensagens-erro-span" role="alert">{this.state.tokenSMSAlterarTitularidadeCartaoErro}</span> : null}
                </FormGroup>
                {/*  <a style={{ cursor: "pointer" }} onClick={() => this.solicitarTokenAlterarTitularidadeDeCartao(this.props.estadoCartoes.tokenTransferenciaCartaoId)}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br /> */}

            </div>
        );
    }

    habilitaEDesabilitaBotaoConfirmar(campos) {
        if (campos.tipoDocumentoTitular === TIPO_DOCUMENTO.CPF.value) {
            this.setState({ desabilitaBotaoConfirmar: true });
            if ((campos.nomeTitular.length >= 4) && (campos.numeroDocumentoTitular.length >= 14) && (campos.dataNascimentoTitular.length >= 10) && (campos.celularTitular.length >= 14)) {
                this.setState({ desabilitaBotaoConfirmar: false });
            }
        } else {
            this.setState({ desabilitaBotaoConfirmar: true });
            if ((campos.nomeTitular.length >= 4) && (campos.numeroDocumentoTitular.length >= 14) && (campos.dataNascimentoTitular.length >= 10) && (campos.celularTitular.length >= 14)
                && (campos.nomePortador.length >= 4) && (campos.numeroDocumentoPortador.length >= 14) && (campos.dataNascimentoPortador.length >= 10) && (campos.celularPortador.length >= 14)) {
                this.setState({ desabilitaBotaoConfirmar: false });
            }
        }
    }

    retornaRodapeModalToken = () => {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => { this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL_TOKEN_ALTERA_TITULARIDADE_CARTAO); }}
                btn2OnClick={this.validaToken}
            />
        );
    }

    retornaRodapeModalConfimacao = () => {
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.NAO} btn2Label={LABEL_COMUNS.SIM}
                btn1OnClick={() => { this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL_CONFIRMACAO_ALTERA_TITULARIDADE_CARTAO); }}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL_CONFIRMACAO_ALTERA_TITULARIDADE_CARTAO);
                    /*  this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL_TOKEN_ALTERA_TITULARIDADE_CARTAO); */
                    /*  this.props.solicitarTokenAlterarTitularidadeDeCartao(this.state.cartaoSelecionado.cartaoId); */
                    this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_LOADING_ALTERAR_TITULARIDADE_CARTAO);
                    this.props.solicitarAlteracaoTitularidadeCartao(this.state.cartaoSelecionado.idCartaoImpresso, this.state.cartaoSelecionado.cartaoId, montaDadosParaAlteracaoDeTitularidadeCartao(this.state));
                }}
            />
        );
    }

    retornaRodapeModalFinalizacao = () => {
        const { dashboardBppPag } = this.state;
        return (
            <FooterBotoesModalComponent
                contextoBppPag={true}
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => {
                    this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL_FINALIZACAO_ALTERA_TITULARIDADE_CARTAO);
                    if (dashboardBppPag) {
                        this.setRedirecionarDashboardBppPag(true);
                    }
                    else {
                        this.setRedirecionarTelaCartoesBppPag(true);
                    }
                }}
            />
        );
    }

    validaToken = () => {
        if (this.state.tokenSMSAlterarTitularidadeCartao.length < 6) {
            this.setState({ tokenSMSAlterarTitularidadeCartaoErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
        }
        else {
            this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_LOADING_ALTERAR_TITULARIDADE_CARTAO);
            this.setPropsCampos({ target: { value: false } }, CAMPOS.EXIBIR_MODAL_TOKEN_ALTERA_TITULARIDADE_CARTAO);
            this.props.solicitarAlteracaoTitularidadeCartao(this.state.cartaoSelecionado.idCartaoImpresso, this.state.cartaoSelecionado.cartaoId, montaDadosParaAlteracaoDeTitularidadeCartao(this.state, this.props.estadoCartoes.tokenTransferenciaCartaoId));
        }
    }

    validarForm() {
        let erros = false;
        switch (this.state.tipoDocumentoTitular) {
            case TIPO_DOCUMENTO.CNPJ.value:
                if (!CNPJ.isValid(this.state.numeroDocumentoTitular)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(TIPO_DOCUMENTO.CNPJ.value) } }, CAMPOS.ERROS.NUMERO_DOCUMENTO_TITULAR_ERRO);
                }
                if (!CPF.isValid(this.state.numeroDocumentoPortador)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(TIPO_DOCUMENTO.CPF.value) } }, CAMPOS.ERROS.NUMERO_DOCUMENTO_PORTADOR_ERRO);
                }
                if (dataInvalida(this.state.dataNascimentoTitular)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_INVALIDA } }, CAMPOS.ERROS.DATA_NASCIMENTO_TITULAR_ERRO);
                }
                if (dataInvalida(this.state.dataNascimentoPortador)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_INVALIDA } }, CAMPOS.ERROS.DATA_NASCIMENTO_PORTADOR_ERRO);
                }
                break;
            default:
                if (dataInvalida(this.state.dataNascimentoTitular)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.DATA.DATA_INVALIDA } }, CAMPOS.ERROS.DATA_NASCIMENTO_TITULAR_ERRO);
                }
                if (!CPF.isValid(this.state.numeroDocumentoTitular)) {
                    erros = true;
                    this.setPropsCampos({ target: { value: MENSAGENS.VALIDACAO_DE_CAMPOS.CAMPO_INVALIDO(TIPO_DOCUMENTO.CPF.value) } }, CAMPOS.ERROS.NUMERO_DOCUMENTO_TITULAR_ERRO);
                }
                break;
        }

        if (!erros) {
            this.setPropsCampos({ target: { value: true } }, CAMPOS.EXIBIR_MODAL_CONFIRMACAO_ALTERA_TITULARIDADE_CARTAO);
        }
    }

    retornaRodapeModalOperacaoEmAndamento() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => this.props.setExibirModalCartaoOperacaoEmAndamento(false)}
            />
        );
    }
    render() {
        const contextoBppPag = this.props.contextBppPag;      
        const corDeFundoParaTitulo = contextoBppPag ? ESTILO.COR_DE_FUNDO.TITULO.BPP_PAG : ESTILO.COR_DE_FUNDO.TITULO.PADRAO;
        const corDeFundoParaBotao = contextoBppPag ? ESTILO.COR_DE_FUNDO.BOTAO.BPP_PAG : ESTILO.COR_DE_FUNDO.BOTAO.PADRAO;
        const { dashboardBppPag, redirecionarDashboardBppPag, redirecionarTelaCartoesBppPag } = this.state;       
        if (redirecionarDashboardBppPag) {
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_BPP_PAG} />;
        }

        if (redirecionarTelaCartoesBppPag) {
            return <Redirect to={ROTAS_CAMINHOS.CARTOES_BPP_PAG} />;
        }
        const erroGenerico = this.props.estadoCartoes.mensagemErroDeAlteracaoTitularidadeCartao;
        return (
            <div>
                <ModalComponent bsSize="small"
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTokenAlteraTitularidadeCartao}
                    name="modal-token"
                    header={LABEL_COMUNS.ALTERAR_TITULARIDADE_CARTAO.TITULOS.PADRAO}
                    body={this.montaCorpoModalToken()} footer={this.retornaRodapeModalToken()} />

                <ModalComponent bsSize="small"
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoAlteraTitularidadeCartao}
                    name="confirmacao"
                    header={LABEL_COMUNS.ALTERAR_TITULARIDADE_CARTAO.TITULOS.PADRAO}
                    body={MENSAGENS.CARTAO.ALTERAR_TITULARIDADE_CARTAO.CONFIRMACAO} footer={this.retornaRodapeModalConfimacao()} />


                <ModalComponent bsSize="large"
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalFinalizacaoAlteraTitularidadeCartao}
                    name="modalFinalizacao" header={LABEL_COMUNS.ALTERAR_TITULARIDADE_CARTAO.TITULOS.PADRAO}
                    body={this.props.estadoCartoes.mensagemFinalizacaoAlteracaoTitularidadeCartao}
                    footer={this.retornaRodapeModalFinalizacao()} />

                <ModalComponent bsSize="small" exibirModal={this.props.estadoCartoes.exibirModalCartaoOperacaoEmAndamento}
                    name="modal-operacao-em-andamento"
                    header={this.props.estadoCartoes.tituloModalConfirmacao}
                    body={MENSAGENS.CARTAO.BLOQUEAR_OU_DES_BLOQUEAR.OPERACAO_EM_ANDAMENTO} footer={this.retornaRodapeModalOperacaoEmAndamento()} />


                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_paginas">
                    <div className="panel panel-default">
                        <div className={corDeFundoParaTitulo}><b>{LABEL_COMUNS.ALTERAR_TITULARIDADE_CARTAO.TITULOS.PADRAO}</b></div>
                        <If test={this.state.exibirLoadingAlterarTitularidadeCartao}>
                            <div className="alinhamento-centro">
                                <LoadingFenix />
                            </div>
                        </If>
                        <div className="panel-body">
                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <img src={imagemCartao} className="avatar-cartao" alt=" imagem cartao" />
                            </Grid>
                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                <span style={{ fontSize: '11px' }}>
                                    {this.state.cartaoSelecionado.panFormatado}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <span style={{ fontSize: '11px' }}>
                                    {this.state.cartaoSelecionado.validade}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <span style={{ fontSize: '11px' }}>
                                    {formatarStatus(this.state.cartaoSelecionado.status)}
                                </span>
                            </Grid>
                        </div>
                        <div className="panel-body">
                            {erroGenerico && <Alert bsStyle={STATUS_VALIDACAO_COMPONENTES.PERIGO}>
                                {erroGenerico}
                            </Alert>}
                            <Grid>
                                <Grid cols="2 2 2 2">
                                    <ControlLabel>{LABEL_COMUNS.NOME_TITULAR}*</ControlLabel>
                                </Grid>
                                <Grid cols="6 6 6 6 6" className="alinhamento-esquerda">
                                    <FormGroup /* validationState={this.props.nomePortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null} */>
                                        <FormControl
                                            type="text"
                                            id="nomeCompleto"
                                            className="mensagens-erro form-control"
                                            placeholder={LABEL_COMUNS.NOME_TITULAR}
                                            value={this.state.nomeTitular} contextoBppPag
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.NOME_TITULAR)}
                                        />                                      
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid cols="2 2 2 2" />
                                <Grid cols="6 6 6 6 6" className="alinhamento-esquerda">
                                    <FormGroup>
                                        <Radio name="tipoDocumentoTitular" inline onChange={(e) => e.target.checked && this.setPropsCampos(e, CAMPOS.TIPO_DOCUMENTO_TITULAR)} value={LABEL_COMUNS.CPF} defaultChecked>
                                            {LABEL_COMUNS.CPF}
                                        </Radio>
                                        {/* <Radio name="tipoDocumentoTitular" inline onChange={(e) => e.target.checked && this.setPropsCampos(e, CAMPOS.TIPO_DOCUMENTO_TITULAR)} value={LABEL_COMUNS.CNPJ} >
                                            {LABEL_COMUNS.CNPJ}
                                        </Radio> */}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid cols="2 2 2 2">
                                    <ControlLabel>{LABEL_COMUNS.DOCUMENTO}*</ControlLabel>
                                </Grid>
                                <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                    <FormGroup validationState={this.state.numeroDocumentoTitularErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            className="mensagens-erro form-control"
                                            mask={this.state.mascaraDocumento} maskChar=""
                                            placeholder={LABEL_COMUNS.DOCUMENTO}
                                            maxLength="50"
                                            value={this.state.numeroDocumentoTitular}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_DOCUMENTO_TITULAR)}
                                        />
                                        {this.state.numeroDocumentoTitularErro ? <span className="mensagens-erro-span" role="alert">{this.state.numeroDocumentoTitularErro}</span> : null}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid cols="2 2 2 2">
                                    <ControlLabel>{LABEL_COMUNS.DATA_NASCIMENTO}*</ControlLabel>
                                </Grid>
                                <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                    <FormGroup validationState={this.state.dataNascimentoTitularErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            className="mensagens-erro form-control"
                                            mask={MASCARA_CAMPOS.DATA} maskChar=""
                                            placeholder={LABEL_COMUNS.DATA_NASCIMENTO}
                                            maxLength="50"
                                            value={this.state.dataNascimentoTitular}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_NASCIMENTO_TITULAR)}
                                        />
                                        {this.state.dataNascimentoTitularErro ? <span className="mensagens-erro-span" role="alert">{this.state.dataNascimentoTitularErro}</span> : null}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                            <Grid>
                                <Grid cols="2 2 2 2">
                                    <ControlLabel>{LABEL_COMUNS.CELULAR}*</ControlLabel>
                                </Grid>
                                <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                    <FormGroup validationState={this.state.celularTitularErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                        <InputMask
                                            className="mensagens-erro form-control"
                                            mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                            placeholder={LABEL_COMUNS.CELULAR}
                                            maxLength="50"
                                            value={this.state.celularTitular}
                                            onChange={(e) => this.setPropsCampos(e, CAMPOS.CELULAR_TITULAR)}
                                        />
                                        {this.state.celularTitularErro ? <span className="mensagens-erro-span" role="alert">{this.state.celularTitularErro}</span> : null}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </div>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                    </div>
                    <br />

                    {(this.state.tipoDocumentoTitular === TIPO_DOCUMENTO.CNPJ.value) && <div>
                        <div className="panel-heading fundo-cinza-claro"><b>{LABEL_COMUNS.CARTOES.PORTADOR}</b></div>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <Grid>
                                    <Grid cols="2 2 2 2">
                                        <ControlLabel>{LABEL_COMUNS.NOME_PORTADOR}*</ControlLabel>
                                    </Grid>
                                    <Grid cols="6 6 6 6 6" className="alinhamento-esquerda">
                                        <FormGroup /* validationState={this.props.nomePortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null} */>
                                            <FormControl
                                                type="text"
                                                id="nomeCompleto"
                                                className="mensagens-erro form-control"
                                                placeholder={LABEL_COMUNS.NOME_PORTADOR}
                                                value={this.state.nomePortador}
                                                onChange={(e) => this.setPropsCampos(e, CAMPOS.NOME_PORTADOR)}
                                            />
                                            {/*   {this.props.nomePortadorErro ? <span className="mensagens-erro-span" role="alert">{this.props.nomePortadorErro}</span> : null} */}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid cols="2 2 2 2">
                                        <ControlLabel>{LABEL_COMUNS.DOCUMENTO}*</ControlLabel>
                                    </Grid>
                                    <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                        <FormGroup validationState={this.state.numeroDocumentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                                                className="mensagens-erro form-control"
                                                mask={MASCARA_CAMPOS.CPF} maskChar=""
                                                placeholder={LABEL_COMUNS.DOCUMENTO}
                                                maxLength="50"
                                                value={this.state.numeroDocumentoPortador}
                                                onChange={(e) => this.setPropsCampos(e, CAMPOS.NUMERO_DOCUMENTO_PORTADOR)}
                                            />
                                            {this.state.numeroDocumentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.numeroDocumentoPortadorErro}</span> : null}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid cols="2 2 2 2">
                                        <ControlLabel>{LABEL_COMUNS.DATA_NASCIMENTO}*</ControlLabel>
                                    </Grid>
                                    <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                        <FormGroup validationState={this.state.dataNascimentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                            <InputMask
                                                className="mensagens-erro form-control"
                                                mask={MASCARA_CAMPOS.DATA} maskChar=""
                                                placeholder={LABEL_COMUNS.DATA_NASCIMENTO}

                                                maxLength="50"
                                                value={this.state.dataNascimentoPortador}
                                                onChange={(e) => this.setPropsCampos(e, CAMPOS.DATA_NASCIMENTO_PORTADOR)}
                                            />
                                            {this.state.dataNascimentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.dataNascimentoPortadorErro}</span> : null}
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid>
                                    <Grid cols="2 2 2 2">
                                        <ControlLabel>{LABEL_COMUNS.CELULAR}*</ControlLabel>
                                    </Grid>
                                    <Grid cols="6 6 6 6" className="alinhamento-esquerda">
                                        <FormGroup >
                                            <InputMask
                                                className="mensagens-erro form-control"
                                                mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                                placeholder={LABEL_COMUNS.CELULAR}

                                                maxLength="50"
                                                value={this.state.celularPortador}
                                                onChange={(e) => this.setPropsCampos(e, CAMPOS.CELULAR_PORTADOR)}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </div>}
                </div>
                <Grid cols="11 11 11 11" className="alinhamento-direita">

                    <Button type="button" bsStyle="default" onClick={this.props.history.goBack}>{LABEL_COMUNS.CANCELAR}</Button>&nbsp;&nbsp;

                    <Button type="button"
                        disabled={this.state.desabilitaBotaoConfirmar}
                        bsStyle={corDeFundoParaBotao} onClick={this.validarForm}>{LABEL_COMUNS.CONFIRMAR}</Button>

                </Grid>
            </div >
        );
    }
}

function mapStateToProps(state) { return { estadoCartoes: state.CartoesReducer, }; }

const mapDispatchToProps = dispatch => bindActionCreators({
    cartaoAction,
    solicitarAlteracaoTitularidadeCartao,
    limparMensagemDeErroDeAlteracaoDeTitularidadeCartao
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AlterarTitularidadeCartao);

