import Axios from 'axios';
import { reactLocalStorage } from 'reactjs-localstorage';
import { HEADER_API, URL_ARQUIVOS, URL_ARQUIVOS_CARGAS_POST, URL_ARQUIVOS_CARGA_LOTE_ID, URL_ARQUIVOS_CARGAS_DADOSCONTA, URL_GET_CARTAO, URL_GET_PESSOA_ID } from '../../../servicos/url-servicos';
import { CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX } from './novo-carga-lote-cartao-controlador';
import { environment } from '../../../configuracoes/environment';

const API = environment.urlAPI;

export default class novoCargaLoteCartaoAction {
    constructor() {

    }

    mudarArquivo(arquivo) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[0] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,

                    payload: arquivo
                }
            );
        };
    }

    obterUrlPreAssinada() {
        return function(dispatch) {
            Axios
                .post(URL_ARQUIVOS(), { bucket: environment.arquivosBancariosBucket }, HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[1] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }
    putArquivosDocumentosEmS3(url, file) {
        const HEADER = {
            headers: {
                'Content-type': 'application/octet-stream'
            }
        };
        return function(dispatch) {
            Axios
                .put(url, file, HEADER)
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[3] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }

    postConteudoArquivoCargaDois(idArquivo, nomeArquivoParam, usuarioParam, contaId) {
        var mapaDeTitulares = new Map();
        var mapaDeTitularesCartao = new Map();

        return async function(dispatch) {
            var resposta;

            let mensagem = '';
            try {
                resposta = await Axios.post(URL_ARQUIVOS_CARGAS_POST(),
                    { idArquivoS3: idArquivo, nomeArquivo: nomeArquivoParam, usuario: usuarioParam, tipoArquivoDeCarga: 'contaCartao', contaId: contaId },
                    HEADER_API()).catch((error) => {
                    mensagem = error.response.data.erros[0].mensagem;
                    throw new Error();
                });
            } catch (error) {
                dispatch(
                    {
                        type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                            CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                        payload: mensagem
                    }

                );
                return;
            }

            var promisesTitular = [];
            var promisesTitularCartao = [];

            if (resposta.status === 200) {
                resposta.data.detalhes.map((registro) => {
                    if (mapaDeTitulares.get(parseInt(registro.contaOrigem, 10).toString()) != '') {
                        promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaOrigem), HEADER_API()));
                        mapaDeTitulares.set(parseInt(registro.contaOrigem, 10).toString(), '');
                    }
                    if (mapaDeTitularesCartao.get(parseInt(registro.contaDestino, 10).toString()) != '') {
                        promisesTitularCartao.push(Axios.get(URL_GET_CARTAO(registro.contaDestino), HEADER_API()));
                        mapaDeTitularesCartao.set(parseInt(registro.contaDestino, 10).toString(), '');
                    }
                });
            } else {
                return function(dispatch) {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: 'Formato de arquivo inválido.'
                        }
                    );
                };
            }

            // Conta Titulares (origem)
            var listaTitulares = await Promise.all(promisesTitular.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitulares.length; i++) {
                if (listaTitulares[i]) {
                    mapaDeTitulares.set(parseInt(listaTitulares[i].data['numeroConta'], 10).toString(), listaTitulares[i].data['nomeTitular']);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularContaOrigem = mapaDeTitulares.get(parseInt(detalhe.contaOrigem).toString());
            });

            // Conta Cartão Titulares (destino)
            let listaTitularesCartao = await Promise.all(promisesTitularCartao.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitularesCartao.length; i++) {
                if (listaTitularesCartao[i]) {
                    let pessoaId = listaTitularesCartao[i].data['portadorPessoaId'];
                    let nomePortador;
                    if (pessoaId) {
                        let nomePortadorResponse = await Axios.get(URL_GET_PESSOA_ID(pessoaId), HEADER_API());
                        nomePortador = nomePortadorResponse.data.pessoaFisica.nomeCompleto;
                    } else {
                        nomePortador = '';
                    }
                    mapaDeTitularesCartao.set(parseInt(listaTitularesCartao[i].data['cartaoId'], 10).toString(), nomePortador);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularCartaoDestino = mapaDeTitularesCartao.get(parseInt(detalhe.contaDestino).toString());
            });

            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: resposta.data
                }
            );
        };
    }

    procurarTitular(numeroConta) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_POST(numeroConta), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[5] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }
                    );
                }).catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }

    limparTitular(numeroConta) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[6] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,

                    payload: undefined
                }
            );
        };
    }

    gravarArquivo(idArquivo, rotuloArquivoCargaLote) {
        return function(dispatch) {
            Axios
                .put(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), {
                    arquivoId: idArquivo,
                    importar: false,
                    gravar: true,
                    rotulo: rotuloArquivoCargaLote
                },
                HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[7] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }

    gravarProcessarArquivo(idArquivo, rotuloArquivoCargaLote) {
        return function(dispatch) {
            Axios
                .put(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), {
                    arquivoId: idArquivo,
                    importar: true,
                    token: reactLocalStorage.get('token'),
                    rotulo: rotuloArquivoCargaLote
                },
                HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[8] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[2] +
                                CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                            payload: err
                        }
                    );
                });
        };
    }
    setOcultarErro() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[9] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: undefined
                }
            );
        };
    }

    criarErroArquivoNaoSelecionado() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: 'É necessário selecionar um arquivo antes de executar o carregamento/processamento.'
                }
            );
        };
    }

    criarErroExtensaoArquivo() {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[10] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: 'Extensão de arquivo inválida.'
                }
            );
        };
    }
    obterDadosArquivo(idArquivo) {
        var mapaDeTitulares = new Map();
        var mapaDeTitularesCartao = new Map();

        return async function(dispatch) {
            var resposta = await Axios.get(URL_ARQUIVOS_CARGA_LOTE_ID(idArquivo), HEADER_API()).catch((error) => { });
            var promisesTitular = [];
            var promisesTitularCartao = [];

            resposta.data.detalhes.map((registro) => {
                if (mapaDeTitulares.get(parseInt(registro.contaOrigem, 10).toString()) != '') {
                    promisesTitular.push(Axios.get(URL_ARQUIVOS_CARGAS_DADOSCONTA(registro.contaOrigem), HEADER_API()));
                    mapaDeTitulares.set(parseInt(registro.contaOrigem, 10).toString(), '');
                }
                if (mapaDeTitularesCartao.get(parseInt(registro.contaDestino, 10).toString()) != '') {
                    promisesTitularCartao.push(Axios.get(URL_GET_CARTAO(registro.contaDestino), HEADER_API()));
                    mapaDeTitularesCartao.set(parseInt(registro.contaDestino, 10).toString(), '');
                }
            });

            // Conta Titulares (origem)
            var listaTitulares = await Promise.all(promisesTitular.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitulares.length; i++) {
                if (listaTitulares[i]) {
                    mapaDeTitulares.set(parseInt(listaTitulares[i].data['numeroConta'], 10).toString(), listaTitulares[i].data['nomeTitular']);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularContaOrigem = mapaDeTitulares.get(parseInt(detalhe.contaOrigem).toString());
            });

            // Conta Cartão Titulares (destino)
            let listaTitularesCartao = await Promise.all(promisesTitularCartao.map(p => p.catch(e => { })));
            for (var i = 0; i < listaTitularesCartao.length; i++) {
                if (listaTitularesCartao[i]) {
                    let pessoaId = listaTitularesCartao[i].data['portadorPessoaId'];
                    let nomePortador;
                    if (pessoaId) {
                        let nomePortadorResponse = await Axios.get(URL_GET_PESSOA_ID(pessoaId), HEADER_API());
                        nomePortador = nomePortadorResponse.data.pessoaFisica.nomeCompleto;
                    } else {
                        nomePortador = '';
                    }
                    mapaDeTitularesCartao.set(parseInt(listaTitularesCartao[i].data['cartaoId'], 10).toString(), nomePortador);
                }
            }
            resposta.data.detalhes.map(detalhe => {
                detalhe.titularCartaoDestino = mapaDeTitularesCartao.get(parseInt(detalhe.contaDestino).toString());
            });

            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[4] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: resposta.data,
                    rotulo: resposta.data.rotulo
                }
            );
        };
    }

    setarBuscandoTrue(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[12] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setarEstadoModalGravar(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[13] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setarEstadoModalGravarSucesso(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[14] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setarEstadoModalProcessar(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[15] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setarEstadoModalProcessarSucesso(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[16] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setarFinalizado(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[17] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: value
                }
            );
        };
    }

    setRotuloArquivoCargaLoteAction(valor) {
        return function(dispatch) {
            dispatch(
                {
                    type: CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.COMANDO[18] +
                        CONTROLADOR_NOVO_CARGA_LOTE_ACTION_REDUX.CLASSE_NAME,
                    payload: valor
                }
            );
        };
    }
}
