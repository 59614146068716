import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { CONTA_SELECIONADA, LABEL_COMUNS } from '../../../util/contantes-comuns/constantes-comuns';
import MostrarSaldoESaldoBloqueado from '../../../contas/saldo/mostrar-saldo-e-saldo-bloqueado/mostrar-saldo-e-saldo-bloqueado';
import AgendamentoEAprovacoesDashboardBppPag from './agendamentos-e-aprovacoes-bpp-pag/agendamento-e-aprovacoes-dashboard-bpp-pag';
import PanelAcoes from '../../../componentes-uteis/panel-acoes/panel-acoes';
import { ROTAS_CAMINHOS } from '../../../rotas-constantes';
import Grid from '../../../componentes-uteis/grid/grid';
import ListaCartoesDashBoardBppPag from './cartoes/lista-cartoes-dashboard-bpp-pag';
import ConsultaDePedidosDashboardBppPag from './consulta-de-pedidos/consulta-de-pedidos-dashboard-bpp-pag';
import { connect } from 'react-redux';
import { podeConsultar, podeCriar, podeAprovar } from '../../../../reducer/outorga/outorga-operacao-reducer';

class DashboardBppPag extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: CONTA_SELECIONADA.OBJETO()
        };
    }

    render() {
        const className = 'panel-heading fundo-verde-claro';
        const classNameFiltro = 'estilo-filtro-bbpppag';
        const outorga = this.props.outorgaPerfils.podeCriar ? {
            labelBotao1: LABEL_COMUNS.ARQUIVO.TITULOS.ARQUIVOS,
            labelBotao2: LABEL_COMUNS.CARTOES.TITULOS.PEDIR_CARTAO,
            btn1Link: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE,
            btn2Link: ROTAS_CAMINHOS.PEDIDO_EMBOSSING_CARTAO_BPP_PAG
        } : {
            labelBotao1: LABEL_COMUNS.HISTORICO,
            btn1Link: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_HISTORICO
        };
        return (
            <div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_conteudo_paginas">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <MostrarSaldoESaldoBloqueado
                                        {...this.props}
                                        className={className}
                                        contextoBppPag
                                        contaSelecionada={this.state.contaSelecionada}
                                        exibirSaldo
                                        mostrarSaldoBloqueado
                                        podeConsultar={this.props.outorgaPerfils.podeConsultar}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    {this.props.outorgaPerfils.podeConsultar && <AgendamentoEAprovacoesDashboardBppPag
                                        {...this.props}
                                        produtoId={this.props.produtosIdsBppPag}
                                        className={className}
                                        classNameBotao={classNameFiltro}
                                        contextoBppPag
                                        contaSelecionada={this.state.contaSelecionada} />}
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                {this.props.outorgaPerfils.podeConsultar && <ConsultaDePedidosDashboardBppPag
                                    {...this.props}
                                    produtosIdsBppPag={this.props.produtosIdsBppPag}
                                    contaSelecionada={this.state.contaSelecionada} />}
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                    <PanelAcoes
                                        {...this.props}
                                        className={className}
                                        titulo={LABEL_COMUNS.ACOES_DO_PRODUTO}
                                        contextoBppPag
                                        {...outorga} />
                                </div>
                            </div>
                            {this.props.outorgaPerfils.podeAprovar && this.props.outorgaPerfils.podeConsultar && this.props.outorgaPerfils.podeCriar &&
                                <div className="row">
                                    <ListaCartoesDashBoardBppPag
                                        {...this.props}
                                        produtosIdsBppPag={this.props.produtosIdsBppPag}
                                        contaSelecionada={this.state.contaSelecionada}
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
                <br />
                <div className="row">
                    <Grid cols="12 12 12 12" className="alinhamento-direita">
                        <Link to={ROTAS_CAMINHOS.DASHBOARD_CONTA}>
                            <Button type="button" bsStyle="success">{LABEL_COMUNS.VOLTAR}</Button>
                        </Link>
                    </Grid>
                </div>
                <br />
            </div >
        );
    }
}

const mapStateToProps = state => ({
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer)
    }

});

export default connect(mapStateToProps)(DashboardBppPag);
