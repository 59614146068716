import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CAMPOS, COMBO, CONTA_SELECIONADA, LABEL_COMUNS, MENSAGENS, STATUS, APROVAR_OU_REPROVAR_ARQUIVO } from '../../../../util/contantes-comuns/constantes-comuns';
import { formataMoedaComSimboloESinalNegacao } from '../../../../util/numeros/numeroUtil';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import Grid from '../../../../componentes-uteis/grid/grid';
import If from '../../../../componentes-uteis/if/if';
import { aprovar, getMenuAcoes as MenuAcoes, reprovar, aproveArchive } from '../agendamentos-e-aprovacoes-bpp-pag/agendamentos-e-aprovacoes-bpp-pag-base';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import Loading from '../../../../loading-fenix/loading';
import { buscarHistoricoTransferenciaEmLote, setLotTransferHistoryLoading } from '../../../../../action/transferencia-em-lote/transferencia-em-lote-action';
import { approveOrReproveArchiveApi } from 'core/api/transferenciaEmLoteRetornoApi';
import './lote.css';

import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import { formatarDataBR } from '../../../../util/data/dataUtil';

import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';


class TransferenciaEmLoteHistorico extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();
    }

    getInitialState = (abrirCollapse = false) => (
        {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            abrirCollapse: abrirCollapse,
            exibirModalConfirmacaoAprovacao: false,
            exibirModalConfirmacaoReprovacao: false,
            exibirModalTransferenciaEmLoteFinalizacao: false,
            exbirMaisRegistros: false,
            dataInicial: '',
            erroArquivo: false,
            dataInicialErro: '',
            dataFinal: '',
            dataFinalErro: '',
            transferenciaEmLote: {},
            anexoContratualId: undefined,
            transferenciaEmLoteId: '',
            transferenciaEmLoteIdErro: '',
            operacaoTransferenciaEmLoteSelecionada: {},
            listaOperacoesTransferenciaEmLote: COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE() || [],
            operacaoErro: '',
            statusTransferenciaEmLoteSelecionado: {},
            listaStatusTransferenciaEmLote: COMBO.STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS || [],
            statusErro: '',
            ultimaChave: '',
        }
    );

    componentDidMount = () => {
        const { contaSelecionada } = this.state;
        
        const contaId = contaSelecionada.id;
        const pessoaId = contaSelecionada.pessoaTitularId;
        const produto = LABEL_COMUNS.PRODUTO_PAG;
        const params = {
            contaId,
            pessoaId,
            produto
        };
        
        this.props.buscarHistoricoTransferenciaEmLote(params);
    };

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.erroHistoricoTransferenciaEmLote) {
            this.setState({ erroArquivo: true });
        }
        if (nextProps.ultimaChave) {
            this.setState({ ultimaChave: nextProps.ultimaChave });
            this.setState({ exbirMaisRegistros: true });
        }
        if (!nextProps.ultimaChave) {
            this.setState({ exbirMaisRegistros: false });
        }
    }

    setPropsCampos = (event, data) => (
        this.setState({
            [data]: event.target.value,
            [data + 'Erro']: '',
        })
    );

    setDataInicial = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_INICIAL)
    );

    setDataFinal = (event) => (
        this.setPropsCampos(event, CAMPOS.DATA_FINAL)
    );

    setTransferenciaEmLoteId = (event) => (
        this.setPropsCampos(event, CAMPOS.TRANSFERENCIA_EM_LOTE_ID)
    );

    setOperacaoTransferenciaEmLoteSelecionada = (value) => (
        this.setPropsCampos({ target: { value: value } }, CAMPOS.OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA)
    );

    setStatusTransferenciaEmLoteSelecionado = (value) => (
        this.setPropsCampos({ target: { value: value } }, CAMPOS.STATUS_TRANSFERENCIA_EM_LOTE_SELECIONADO)
    );

    onLimparFiltroClick = () => (
        this.setState(this.getInitialState(this.state.abrirCollapse))
    );

    onIdLoteHistoricoClick = (arquivo) => (
        this.props.history.push({ pathname: ROTAS_CAMINHOS.TRANSFERENCIA_EM_LOTE_LOTES_DO_ARQUIVO, state: { arquivo: arquivo } })
    );

    onClickAprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onClickReprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onOpenModalFinalizacao = (nomeModalConfirmacao) => (
        (tipo, mensagem) => (
            this.setState({
                exibirLoadinghHistoricoTransferenciaEmLote: false,
                [nomeModalConfirmacao]: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: mensagem
            })
        )
    );

    onCloseModalConfirmacao = (nomeModalConfirmacao) => (
        () => (
            this.setState({
                [nomeModalConfirmacao]: false,
            })
        )
    )

    onAprovar = async () => {
        this.props.setLotTransferHistoryLoading(true);
        this.setState({ exibirLoadinghHistoricoTransferenciaEmLote: true, exibirModalConfirmacaoAprovacao: false });

        const { transferenciaEmLote, anexoContratualId, } = this.state;

        const response = await approveOrReproveArchiveApi(transferenciaEmLote.id, anexoContratualId, APROVAR_OU_REPROVAR_ARQUIVO.ACAO.APROVAR);

        if (response.error || response === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.ERROR) {
            if (response.data && response.data.codigo === APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.CODIGO) {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.DATA_POSTERIOR.MENSAGEM
                });
            } else {
                this.setState({
                    exibirLoadinghHistoricoTransferenciaEmLote: false,
                    exibirModalTransferenciaEmLoteFinalizacao: true,
                    mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.ERRO.GENERICO
                });
            }
        }
        if (response.data && response.data.status === APROVAR_OU_REPROVAR_ARQUIVO.STATUS.APROVADO) {
            this.setState({
                exibirLoadinghHistoricoTransferenciaEmLote: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: APROVAR_OU_REPROVAR_ARQUIVO.MENSAGENS.SUCESSO.APROVADO
            });
        }
       
        this.props.setLotTransferHistoryLoading(false);

    };

    onReprovar = () => {
        this.setState({ exibirLoadinghHistoricoTransferenciaEmLote: true });

        const { transferenciaEmLote, anexoContratualId } = this.state;
        const functionName = 'exibirModalConfirmacaoReprovacao';

        reprovar(transferenciaEmLote.id, anexoContratualId, this.onCloseModalConfirmacao(functionName), this.onOpenModalFinalizacao(functionName));
    };

    onMouseEnterStatusTooltip(status) {
        let tooltipMessage = '';

        switch (status) {
            case 'emValidacao':
                tooltipMessage = 'O arquivo está sendo validado. A seguir ele ficará disponível para correção ou aprovação.';
                break;
            case 'pendenteCorrecao':
                tooltipMessage = 'O arquivo possui linhas com erro e precisa ser corrigido e importado novamente.';
                break;
            case 'pendenteAprovacao':
                tooltipMessage = 'O arquivo está aguardando aprovação para ser processado.';
                break;
            case 'reprovado':
                tooltipMessage = 'O arquivo foi reprovado pelo gestor da conta.';
                break;
            case 'cancelado':
                tooltipMessage = 'O arquivo foi cancelado por falta de aprovação ou correção.';
                break;
            case 'aprovado':
                tooltipMessage = 'O arquivo foi aprovado e os lotes foram gerados. Acompanhe o status do lote clicando no ID.';
                break;
            default:
                break;
        }

        return (
            <Tooltip id="tooltip">{tooltipMessage}</Tooltip>
        );
    }

    buscarComFiltro(objeto) {
        this.setState({ ultimaChave: '' }, () => {
            this.props.buscarHistoricoTransferenciaEmLote(
                {
                    ...objeto.params,
                    dataInicial: objeto.dataInicial,
                    dataFinal: objeto.dataFinal,
                    transferenciaEmLoteId: objeto.transferenciaEmLoteId,
                    operacaoTransferenciaEmLoteSelecionada: objeto.operacaoTransferenciaEmLoteSelecionada,
                    statusTransferenciaEmLoteSelecionado: objeto.statusTransferenciaEmLoteSelecionado,

                },
                []
            );
        });
    }

    render() {
        const {
            contaSelecionada,
            abrirCollapse,
            dataInicial,
            dataInicialErro,
            dataFinal,
            dataFinalErro,
            transferenciaEmLoteId,
            transferenciaEmLoteIdErro,
            operacaoTransferenciaEmLoteSelecionada,
            listaOperacoesTransferenciaEmLote,
            operacaoErro,
            statusTransferenciaEmLoteSelecionado,
            listaStatusTransferenciaEmLote,
            statusErro,
        } = this.state;

        const {
            history,
            historicoTransferenciaEmLote: historico,
            exibirLoadinghHistoricoTransferenciaEmLote: exibirLoading,            
        } = this.props;

        const {
            HISTORICO_DE_ARQUIVOS_ENVIADOS,
            ID,
            OPERACAO,
            VALOR,
            DATA_DE_INCLUSAO,
            QUANTIDADE_DE_LOTES,
            STATUS: STATUS_HEADER,
            ACOES,
            VOLTAR,
        } = LABEL_COMUNS;

        const header = [ID, OPERACAO, VALOR, DATA_DE_INCLUSAO, QUANTIDADE_DE_LOTES, STATUS_HEADER, ACOES];

        const params = {
            contaId: contaSelecionada.id,
            pessoaId: contaSelecionada.pessoaTitularId,
            produto: LABEL_COMUNS.PRODUTO_PAG,
            operacaoTransferenciaEmLoteSelecionada,
            dataInicial,
            dataFinal,
            statusTransferenciaEmLoteSelecionado,
            ultimaChave: this.state.ultimaChave
        };

        const statusQueNaoMostramQuantidade = [STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value,
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value,
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.EM_VALIDACAO.value,
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.REPROVADO,
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.R];


        const statusQueNaoMostramValor = [STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value,
            STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.EM_VALIDACAO.value];

        const isAprovado = (status) => status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.APROVADO.value;

        const { ultimaChave } = this.state;
        return (
            <div>
                {/* <ContaSelecionada contextoBppPag={true} contaSelecionada={contaSelecionada} /> */}
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoAprovacao}
                    name="modalConfirmacaoAprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao })}
                            btn2OnClick={this.onAprovar}
                        />
                    } />
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoReprovacao}
                    name="modalConfirmacaoReprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.REPROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao })}
                            btn2OnClick={this.onReprovar}
                        />
                    } />
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteFinalizacao}
                    name="modalFinalizacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemFinalizacao}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            btn2Label={LABEL_COMUNS.OK}
                            btn2OnClick={() => {
                                this.setState(this.getInitialState());
                                this.setState({ ultimaChave: '' });
                                params.ultimaChave = '';
                                this.props.buscarHistoricoTransferenciaEmLote(params);
                            }}
                        />
                    } />
                {/* TODO montar um componente com os modais de confirmação */}
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel-sem-sombra">
                            <div className="panel-heading fundo-verde-claro" style={{ marginTop: '30px', height: '40px' }}>
                                <div className="cabecalho">
                                    <span>{HISTORICO_DE_ARQUIVOS_ENVIADOS}</span>
                                    <Button className="botao-fundo-verde-claro" onClick={() => this.setState({ abrirCollapse: !abrirCollapse })}>
                                        <span className="glyphicon glyphicon-filter" />
                                    </Button>
                                </div>
                            </div>
                            <FiltroGenerico
                                contextoBppPag={true}
                                abrirCollapse={abrirCollapse}
                                mostrarFiltroDeDatas={true}
                                mostrarFiltroDeStatusMesmaLinha={true}
                                mostrarFiltroDeOperacoesMesmaLinha={true}

                                labelDataInicial={DATA_DE_INCLUSAO}

                                dataInicial={dataInicial}
                                dataInicialErro={dataInicialErro}
                                setDataInicial={this.setDataInicial}
                                classNameData={'col-sm-2 col-md-2 col-lg-2'}

                                dataFinal={dataFinal}
                                dataFinalErro={dataFinalErro}
                                setDataFinal={this.setDataFinal}



                                transferenciaEmLoteId={transferenciaEmLoteId}
                                setTransferenciaEmLoteId={this.setTransferenciaEmLoteId}
                                transferenciaEmLoteIdErro={transferenciaEmLoteIdErro}


                                listaDeOperacoes={listaOperacoesTransferenciaEmLote}
                                operacaoSelecionadaId={operacaoTransferenciaEmLoteSelecionada && operacaoTransferenciaEmLoteSelecionada.value}
                                setOperacao={this.setOperacaoTransferenciaEmLoteSelecionada}
                                operacaoErro={operacaoErro}



                                listaDeStatus={listaStatusTransferenciaEmLote}
                                statusSelecionado={statusTransferenciaEmLoteSelecionado && statusTransferenciaEmLoteSelecionado.value}
                                setStatus={this.setStatusTransferenciaEmLoteSelecionado}
                                statusErro={statusErro}

                                validationState={'success'}
                                buscar={() => this.buscarComFiltro({
                                    params: { ...params, ultimaChave: '' },
                                    dataInicial,
                                    dataFinal,
                                    transferenciaEmLoteId,
                                    operacaoTransferenciaEmLoteSelecionada,
                                    statusTransferenciaEmLoteSelecionado
                                })}

                                exibirBotaoLimpar={true}
                                limparFiltro={this.onLimparFiltroClick}
                            />
                            {
                                exibirLoading ?
                                    <div className="alinhamento-centro"><Loading /></div> :
                                    historico.length > 0 ?
                                        <div className="panel-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        {header.map((el, idx) => <th key={idx} style={{ textAlign: 'center' }} className="fundo-verde-escuro">{el}</th>)}
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        (historico).map((el, idx) =>

                                                            <tr key={idx}>
                                                                <td className="alinhamento-centro id" style={{ borderTop: 'none' }}>
                                                                    {
                                                                        isAprovado(el.status) ?
                                                                            <b
                                                                                style={{ cursor: 'pointer' }}
                                                                                onClick={() => this.onIdLoteHistoricoClick(el)}>
                                                                                {el ? el.idArquivoSequencialPorPessoaId : ''}
                                                                            </b> :
                                                                            <b style={{ cursor: 'not-allowed' }}>
                                                                                {el ? el.idArquivoSequencialPorPessoaId : ''}
                                                                            </b>
                                                                    }
                                                                </td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el && el.operacaoExibicao || ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el ? statusQueNaoMostramValor.find(s => s === el.status) ? 'R$ 0,00' : formataMoedaComSimboloESinalNegacao(el.valorTotalLote) : ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el ? formatarDataBR(el.dataCriacao) : ''}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}> {STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.APROVADO.value === el.status ? el.quantidade : '-'}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>
                                                                    {el ? el.statusExibicao : ''}
                                                                    <OverlayTrigger overlay={this.onMouseEnterStatusTooltip(el.status)}>
                                                                        <span className="d-inline-block">
                                                                            <i className="fa fa-question-circle radio-modelo-arquivo-exemplo" />
                                                                        </span>
                                                                    </OverlayTrigger>
                                                                </td>
                                                                {this.props.outorgaPerfils.podeAprovar &&
                                                                    <td className="alinhamento-centro" style={{ borderTop: 'none' }}>
                                                                        <div style={{ marginTop: '-5px' }}>

                                                                            <MenuAcoes
                                                                                transferenciaEmLote={el}
                                                                                history={history}
                                                                                onClickAprovar={() => this.onClickAprovar(el, contaSelecionada.anexoContratualId)}
                                                                                onClickReprovar={() => this.onClickReprovar(el, contaSelecionada.anexoContratualId)}
                                                                                podeAprovar={this.props.outorgaPerfils.podeAprovar}
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                }
                                                            </tr>
                                                        )

                                                    }
                                                </tbody>
                                            </table>
                                            <If test={this.state.exbirMaisRegistros}>
                                                <button
                                                    type="button"
                                                    className="btn btn-secondary btn-block fonte-tamanho-20"
                                                    onClick={() =>
                                                        this.props.buscarHistoricoTransferenciaEmLote(params, historico)
                                                    }
                                                    style={{
                                                        height: '50px'
                                                    }}
                                                >
                                                    <b>{LABEL_COMUNS.MOSTRAR_MAIS}</b>
                                                </button>
                                            </If>
                                        </div>
                                        : this.state.erroArquivo ?
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.HISTORICO.ARQUIVOS.ERRO_VAZIO}</p>
                                            </div>
                                            :
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.HISTORICO.ARQUIVOS.ERRO_CHAMADA}</p>
                                            </div>
                            }
                            <Grid cols="12 12 12 12" className="alinhamento-direita">
                                <Button bsStyle="success" className="botao-voltar-telas" onClick={history.goBack}>{VOLTAR}</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    ...state.TrasferenciaEmLoteReducer,
    agendamentoDashboardBppPag: state.AgendamentoReducer, outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    }
});

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        buscarHistoricoTransferenciaEmLote,
        setLotTransferHistoryLoading
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferenciaEmLoteHistorico);
