import React, { Component } from 'react';
import { Glyphicon, Dropdown, MenuItem, ControlLabel, SplitButton, Button, FormGroup, HelpBlock, FormControl, Grid } from 'react-bootstrap';
import TextareaAutosize from 'react-autosize-textarea';
import InputMask from 'react-input-mask';
import ModalComponent from '../../../componentes-uteis/modal-component/modal-component';
import If from '../../../componentes-uteis/if/if';
import { LABEL_COMUNS } from '../../../util/contantes-comuns/constantes-comuns';
import { montaUrlFetch, urlServicos, HTTP_METODOS, HEADER_API, montaUrlPathParamOperacaoAprovacao } from '../../../../servicos/url-servicos';
import { environment } from '../../../../configuracoes/environment';
import LoadingFenix from '../../../loading-fenix/loading-fenix';
import { dataAtualPtBr } from '../../../util/data/dataUtil';

class CriarContaDepartamento extends Component {
    constructor(props) {
        super(props);
        const contaSelecionada = this.props.contaSelecionada;
        this.state = {
            contaSelecionada: contaSelecionada,
            mostrarModalCriarConta: false,
            mostrarModalConfirmacao: false,
            apelidoDaPessoaAutorizada: '',
            apelidoDaPessoaAutorizadaErro: '',
            cpfDaPessoaAutorizada: '',
            cpfDaPessoaAutorizadaErro: '',
            nomeDaPessoaAutorizada: '',
            nomeDaPessoaAutorizadaErro: '',
            tituloModalSucessoErro: '',
            msgSucessoErro: '',
            exibirLoading: false,
            msgModalSucessoErro: ''
        };
    }

    render() {
        return (
            <div>
                <ModalComponent name="mdCriarContaDepartamento" exibirModal={this.props.mostrarModalCriarConta}
          header="Conta Departamento" body={this.corpoModalFormCriarConta(this.props)}
          footer={this.rodapeModalFormCriarConta()} />

                <ModalComponent name="mdPessoaNaoAprovada" exibirModal={this.state.mostrarModalConfirmacao}
          header="Conta Departamento" body={this.corpoModalConfirmacao()}
          footer={this.rodapeModalConfirmacao(this.props)} />

                <ModalComponent name="mdCriarContaDepartamentoSucessoErro" exibirModal={this.state.mostrarModalSucessoErro}
          header={this.state.tituloModalSucessoErro} body={this.corpoModalSucessoErro(this.state.msgModalSucessoErro)}
          footer={this.rodapeModalSucessoErro(this.props)} />

                <If test={this.state.exibirLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>
            </div>
        );
    }
    corpoModalFormCriarConta() {
        return (
            <div>
                <form>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="form-group">
                                <ControlLabel>Tipo da Conta</ControlLabel>
                                <div>
                                    <SplitButton
                    id="contaMovimentoId"
                    title={'Departamento'}
                    disabled>
                                        {'montaOpcoesTipoConta'}
                                    </SplitButton>
                                </div>
                                <br />
                                <div>
                                    <ControlLabel>Dados da Pessoa Jurídica*</ControlLabel>
                                    <FormGroup>
                                        <TextareaAutosize
                      id="empresas"
                      className="form-control"
                      style={{ paddingTop: '8px', paddingLeft: '10px', resize: 'none' }}
                      value={this.props.contaSelecionada.apelido}
                      placeholder="Empresa"
                      disabled
                    />
                                    </FormGroup>
                                    <div>
                                        <ControlLabel>CPF Autorizado</ControlLabel>
                                        <FormGroup>
                                            <InputMask
                        placeholder="Digite o CPF (Autorizado)"
                        mask="999.999.999-99"
                        className="form-control"
                        style={{ height: '36px', paddingTop: '7px', paddingLeft: '10px' }}
                        value={this.state.cpfDaPessoaAutorizada}
                        onChange={(e) => this.setState({ cpfDaPessoaAutorizada: e.target.value })} />
                                        </FormGroup>
                                        <br />
                                    </div>
                                    <div>
                                        <ControlLabel>Nome Autorizado</ControlLabel>
                                        <FormGroup>
                                            <FormControl
                        placeholder="Digite o Nome (Autorizado)"
                        id="idNomeOutorgado"
                        style={{ height: '36px', paddingTop: '7px', paddingLeft: '10px' }}
                        value={this.state.nomeDaPessoaAutorizada}
                        onChange={(e) => this.setState({ nomeDaPessoaAutorizada: e.target.value })} />
                                        </FormGroup>
                                        <br />
                                    </div>
                                    <div>
                                        <ControlLabel>Apelido</ControlLabel>
                                        <FormGroup>
                                            <FormControl
                        type="text"
                        className="form-control"
                        id="apelido"
                        name="apelido"
                        style={{ height: '36px', paddingTop: '7px', paddingLeft: '10px' }}
                        placeholder="Digite o Apelido da Conta"
                        maxLength="50"
                        value={this.state.apelidoDaPessoaAutorizada}
                        onChange={(e) => this.setState({ apelidoDaPessoaAutorizada: e.target.value })}
                      />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }

    rodapeModalFormCriarConta() {
        return (
            <div>
                <div
          className="alinhamento-direita">
                    <Button
            onClick={() => {
              this.limparStateLocal();
              this.props.esconderModal();
            }}
            bsStyle="default">{LABEL_COMUNS.CANCELAR}
                    </Button>
                    <Button onClick={() => {
            this.props.esconderModal();
            this.setState({ mostrarModalConfirmacao: true });
          }}
            bsStyle="primary">{LABEL_COMUNS.SALVAR}
                    </Button>
                </div>
            </div>
        );
    }

    salvarContaDepartamento() {
        this.getAnexoContratual()
            .then((responseGetAnexoContratual) => {
                this.regraParaSalvarContaDepartamento(responseGetAnexoContratual);
            });
    }

    chamarApiOperacaoEAprovacao(anexoContratualId) {
        this.postOperacao(anexoContratualId)
            .then(this.postOperacaoAprovacao)
            .then((result) => {
                if (result.ok) {
                    this.setState({
                        msgModalSucessoErro: 'Solicitação de criação de conta efetuada com sucesso!',
                        tituloModalSucessoErro: 'Sucesso'
                    });
                    this.props.getListaContaDepartamento();
                } else {
                    this.setState({
                        msgModalSucessoErro: 'Erro na criação da Conta Departamento',
                        tituloModalSucessoErro: 'Erro'
                    });
                }
                this.setState(
                    {
                        mostrarModalSucessoErro: true,
                        exibirLoading: false
                    }
                );
                this.props.esconderModal();
            })
            .catch(err => {
                this.setState({
                    mostrarModalSucessoErro: true,
                    msgModalSucessoErro: 'Erro na criação da Conta Departamento',
                    tituloModalSucessoErro: 'Erro',
                    exibirLoading: false
                });
                this.props.esconderModal();
            });
    }

    regraParaSalvarContaDepartamento(responseGetAnexoContratual) {
        if (responseGetAnexoContratual.ok) {
            responseGetAnexoContratual.json().then((jsonRetornado) => {
                if (jsonRetornado.quantidadeTotalRegistros > 0) {
                    this.chamarApiOperacaoEAprovacao(jsonRetornado.registros[0].id);
                } else {
                    this.postAnexoContratual().then((responsePostAnexoContratual) => {
                        if (responsePostAnexoContratual.ok) {
                            responsePostAnexoContratual.json().then((response) => {
                                if (response.quantidadeTotalRegistros > 0) {
                                    this.chamarApiOperacaoEAprovacao(response.registros[0].id);
                                }
                            });
                        }
                    });
                }
            });
        }
    }

    postOperacao(anexoContratualId) {
        return fetch(montaUrlFetch(urlServicos.OPERACAO), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: JSON.stringify({
                'operacao': 'criacaoContaDepartamento',
                'valor': 0,
                'anexoContratualOriginalId': anexoContratualId,
                'contaOrigemId': this.props.contaSelecionada.id,
                'dadosIntegracao': {
                    'apelido': this.state.apelidoDaPessoaAutorizada ? this.state.apelidoDaPessoaAutorizada : null,
                    'contaPaiId': this.props.contaSelecionada.id,
                    'produtoId': environment.produto
                }
            })
        }).then(response => response.json());
    }

    postOperacaoAprovacao(jsonRetornado) {
        return fetch(montaUrlPathParamOperacaoAprovacao(montaUrlFetch(urlServicos.OPERACOES_TRANSACOES(jsonRetornado.rel))), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: JSON.stringify({
                transacao: 'aprovacao'
            })
        }).then(response => ({ ok: response.ok, json: response.json() }));
    }

    getAnexoContratual() {
        let filtro = { 'pessoaTitularId': this.props.contaSelecionada.pessoaTitularId, 'contaDescricao': 'contaDepartamento' };
        return fetch(montaUrlFetch(urlServicos.ANEXO_CONTRATUAL, filtro), {
            method: HTTP_METODOS.GET,
            headers: HEADER_API().headers
        });
    }

    postAnexoContratual() {
        return fetch(montaUrlFetch(urlServicos.ANEXO_CONTRATUAL), {
            method: HTTP_METODOS.POST,
            headers: HEADER_API().headers,
            body: JSON.stringify({
                'pessoaTitularId': this.props.contaSelecionada.pessoaTitularId,
                'produtoPublicadoId': environment.produtoPublicado.contaDepartamento
            })
        });
    }

    corpoModalSucessoErro(mensagem) {
        return (
            <div>{mensagem}</div>
        );
    }

    rodapeModalSucessoErro(props) {
        return (
            <div className="alinhamento-direira">
                <Button bsStyle="primary" onClick={() => this.setState({ mostrarModalSucessoErro: false })} >OK</Button>
            </div>
        );
    }

    corpoModalConfirmacao() {
        return (
            <div>Deseja criar a nova conta departamento?</div>
        );
    }

    rodapeModalConfirmacao(props) {
        return (
            <div>
                <div className="alinhamento-direita">
                    <Button onClick={() => this.setState({ mostrarModalConfirmacao: false })} bsStyle="default">Não</Button>
                    <Button onClick={() => {
            this.setState({ mostrarModalConfirmacao: false, exibirLoading: true });
            this.salvarContaDepartamento(this.props);
          }} bsStyle="primary">Sim</Button>
                </div>
            </div>
        );
    }

    limparStateLocal() {
        this.setState({
            apelidoDaPessoaAutorizada: '',
            apelidoDaPessoaAutorizadaErro: '',
            cpfDaPessoaAutorizada: '',
            cpfDaPessoaAutorizadaErro: '',
            nomeDaPessoaAutorizada: '',
            nomeDaPessoaAutorizadaErro: '',
            tituloModalSucessoErro: '',
            msgSucessoErro: '',
            exibirLoading: false,
            msgModalSucessoErro: ''
        });
    }
}

export default CriarContaDepartamento;
