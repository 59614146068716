import React from 'react';
import If from '../../componentes-uteis/if/if';

const PedidoLabelCartao = props => (
    <span>
        <If test={props.quantidade > 1}>
            cartões
        </If>
        <If test={props.quantidade <= 1}>
            cartão
        </If>
    </span>
);
export default PedidoLabelCartao;
