import React, { Component } from 'react';
import { connect } from 'react-redux';

import './loading-fenix.css';

class Loading extends Component {
    render() {
        return (
            <span >
                <i className="glyphicon glyphicon-refresh gly-spin loading-fenix" style={this.props.contextoBppPag ? { color: 'green' } : {}} />
            </span>
        );
    }
}

const mapStateToProps = state => ({
    contextoBppPag: state.AppReducer.contextBppPag
});

export default connect(mapStateToProps, null)(Loading);
