import React, { Component } from 'react';
import { LABEL_COMUNS } from '../../../../../util/contantes-comuns/constantes-comuns';

import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import Grid from '../../../../../componentes-uteis/grid/grid';
import If from '../../../../../componentes-uteis/if/if';
import imagemCartao from '../../../../../../imagens/cartao-chip.jpg';
import { formatarStatus } from '../../../../../util/cartoes/cartoes-util';
import { CPF } from 'cpf_cnpj';

function formatarCpf(cpf) {
    return CPF.format(cpf);
}
class GridCartoesDashboardBppPagFavoritos extends Component {
    render() {
        return (
            <div>
                <If test={this.props.listaDeCartoes && this.props.listaDeCartoes.length > 0}>
                    <div className="row">
                        <Grid cols="12 12 12 12" className="grids-dashboards">
                            <Grid cols="2 2 2 2" />

                            <Grid cols="2 2 2 2" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.CARTOES.CARTAO_ID}</span>
                            </Grid>
                            <Grid cols="3 3 3 3 " className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.TITULAR}</span>
                            </Grid>
                            <Grid cols="3 3 3 3" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao">{LABEL_COMUNS.DOCUMENTO}</span>
                            </Grid>

                            <Grid cols="1 1 1 1" className="alinhamento-centro">
                                <span className="titulo-dashboards-conta-solucao acao-lista-cartoes-pag">{LABEL_COMUNS.ACAO}</span>
                            </Grid>
                        </Grid>
                    </div>
                    <div className="grid-agendamento grids-dashboards" style={{ height: 300, paddingRight: 13 }}>
                        {this.props.listaDeCartoes && this.props.listaDeCartoes.length > 0 && this.props.listaDeCartoes.map((cartao, idx) => {
                            return (
                                <div className="row" key={idx}>
                                    <Grid cols="12 12 12 12">
                                        <Grid cols="2 2 2 2" className="alinhamento-centro">
                                            <img src={imagemCartao} className="avatar-cartao" alt=" imagem cartao" />
                                        </Grid>
                                        <Grid cols="2 2 2 2" className="alinhamento-centro">
                                            <span style={{ fontSize: '11px' }}>
                                                {cartao.favoriteCardId}
                                            </span>
                                        </Grid>
                                        <Grid cols="3 3 3 3" className="alinhamento-centro">
                                            <span style={{ fontSize: '11px' }}>
                                                {cartao.favoriteOwnerName}
                                            </span>
                                        </Grid>
                                        <Grid cols="3 3 3 3" className="alinhamento-centro">
                                            <span style={{ fontSize: '11px' }}>
                                                <b>
                                                    {formatarCpf(cartao.favoriteOwnerDocument)}
                                                </b>
                                            </span>
                                        </Grid>
                                        {this.props.statusCartao !== LABEL_COMUNS.CARTAO_NAO_ENCONTRADO.CANCELADO_E_OUTRO &&
                                            <Grid cols="1 1 1 1" className="alinhamento-esquerda">
                                                <Dropdown id="menuOpcoes" pullRight style={{ marginLeft: '-10px' }}>
                                                    <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                        <Glyphicon glyph="option-horizontal" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>

                                                        <MenuItem onSelect={() => {
                                                            this.props.setArmazenarCartao(cartao);
                                                        }}
                                                            onClick={() => this.props.excluiCartao(cartao)}>{LABEL_COMUNS.EXCLUIR}
                                                        </MenuItem>

                                                    </Dropdown.Menu>
                                                </Dropdown>

                                            </Grid>}
                                    </Grid>
                                </div>
                            );
                        }
                        )}
                    </div>
                </If>
                <If test={!this.props.listaDeCartoes || this.props.listaDeCartoes.length <= 0}>
                    <div className="nenhum-cartao-bpppag">
                        <span>{LABEL_COMUNS.NENHUM_CARTAO_ENCONTRADO()}</span>
                    </div>
                </If>
            </div >
        );
    }
}
export default GridCartoesDashboardBppPagFavoritos;
