import { METODOS } from './AppConstantes';

export const menuInicio = (show) => ({
    type: METODOS.SET_MENU_INICIO,
    payload: show
});

export const menuInicioContaSelecionada = (show) => ({
    type: METODOS.SET_MENU_INICIO_CONTA_SELECIONADA,
    payload: show
});

export const menuTitular = (show) => ({
    type: METODOS.SET_MENU_TITULAR,
    payload: show
});

export const menuTitularContaSelecionada = (show) => ({
    type: METODOS.SET_MENU_TITULAR_CONTA_SELECIONADA,
    payload: show
});

export const menuEmpresa = (show) => ({
    type: METODOS.SET_MENU_EMPRESA,
    payload: show
});

export const menuSolucao = (show) => ({
    type: METODOS.SET_MENU_SOLUCAO,
    payload: show
});

export const menuDepartamento = (show) => ({
    type: METODOS.SET_MENU_DEPARTAMENTO,
    payload: show
});

export const menuContaDigital = (show) => ({
    type: METODOS.SET_MENU_CONTA_DIGITAL,
    payload: show
});

export const menuSair = (show) => ({
    type: METODOS.SET_MENU_SAIR,
    payload: show
});

export const modalAgendamento = (show) => ({
    type: METODOS.SET_MODAL_ATENDIMENTO,
    payload: show
});

export const setContextBppPag = (show) => ({
    type: METODOS.SET_CONTEXT_BPP_PAG,
    payload: show
});

export const setContextoMeusDepartamentos = (contextoMeusDepartamentos) => ({
    type: METODOS.SET_CONTEXT_MEUS_DEPARTAMENTOS,
    payload: contextoMeusDepartamentos
});
