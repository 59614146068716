import { reactLocalStorage } from 'reactjs-localstorage';
import { CAMPOS, LABEL_COMUNS } from '../../componentes/util/contantes-comuns/constantes-comuns';


import { fetchLogin, fetchLoginMfa, fetchCriacaoMfa, fetchValidarMfaCode } from './login-base';
import { environment } from '../../configuracoes/environment';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { getPessoaById } from '../pessoas/pessoa-service';
import { fetchGetUsuariosAdmin } from '../pessoas/pessoas-base';


export const efetuarLoginMfa = dadosLogin => {
    const corpoDadosLogin = retornaCorpoDadosLoginMfa(dadosLogin);
    return fetchLoginMfa(corpoDadosLogin)
        .then((resp) => {
            reactLocalStorage.set(CAMPOS.SECRET_MFA, resp.urlQRCode);
            reactLocalStorage.set(CAMPOS.TOKEN_JWT, resp.tokenJWT);
            return resp;
        });
};

export const criarMFA = (dadosMfa) => {
    const corpoDadosMfa = retornaCorpoDadosCriacaoMfa(dadosMfa);
    return fetchCriacaoMfa(corpoDadosMfa)
        .then((resp) => {
            return resp;
        });
};

export const validarMFA = (dadosMfa) => {
    const corpoDadosMfa = retornaCorpoDadosValidacaoMfa(dadosMfa);
    return fetchValidarMfaCode(corpoDadosMfa)
        .then((resp) => {
            const pessoaId = resp.pessoaId;
            const token = resp.tokenJWT;
            reactLocalStorage.set(CAMPOS.TOKEN, token);
            reactLocalStorage.set(CAMPOS.PESSOA_ID, pessoaId);
            return { token, pessoaId };
        })
        .then(async ({ pessoaId, token }) => ({
            resp: await fetchGetUsuariosAdmin(pessoaId),
            pessoaLogadaId: pessoaId,
            token,
        }))
        .then(async ({ resp: { data: { empresas } }, pessoaLogadaId, token }) => ({
            resp: await getPessoaById(pessoaLogadaId),
            empresas,
            pessoaLogadaId,
            token,
        }))
        .then(({ resp: { data }, empresas, pessoaLogadaId, token }) => {
            const pessoaLogada = data;
            const {
                email,
                pessoaFisica: { nomeCompleto },
                statusExterno,
                tipo,
            } = pessoaLogada;
            reactLocalStorage.setObject(LABEL_COMUNS.PESSOA_LOGADA, pessoaLogada);
            reactLocalStorage.set(CAMPOS.ESTA_AUTENTICADO, LABEL_COMUNS.SIM.toUpperCase());
            if (empresas.length) {
                reactLocalStorage.setObject(LABEL_COMUNS.LISTA_EMPRESAS, empresas);
                reactLocalStorage.set(CAMPOS.USUARIO_ADMIN, LABEL_COMUNS.SIM.toUpperCase());
            }
            reactLocalStorage.set(CAMPOS.NOME_USUARIO, nomeCompleto);
            reactLocalStorage.set(CAMPOS.STATUS_USUARIO, statusExterno);
            const emailPrincipal = email.find(e => e.principal);
            if (emailPrincipal) {
                reactLocalStorage.set(LABEL_COMUNS.EMAIL.toLocaleLowerCase(), emailPrincipal.email);
            }
            return { empresas, pessoaLogadaId, pessoaLogada, token };
        });
};


export const handleLocalStorage = () => {
    let lembrarMe = reactLocalStorage.get(CAMPOS.LEMBRAR_ME);
    let usuario = '';
    if (lembrarMe) {
        usuario = reactLocalStorage.get(CAMPOS.USUARIO);
    }

    reactLocalStorage.clear();
    if (usuario) {
        reactLocalStorage.set(CAMPOS.LEMBRAR_ME, true);
        reactLocalStorage.set(CAMPOS.USUARIO, usuario);
    }
};

export const validarDadosFormLogin = dadosLogin => {
    const erros = {};
    const { senha, usuario } = dadosLogin;
    if (!senha) {
        erros.senhaErro = 'Preencha o campo senha.';
    }
    if (!usuario) {
        erros.usuarioErro = 'Preencha o campo usuário.';
    }
    return erros;
};

export const validarMfaCode = code => {
    const erros = {};
    if (!code) {
        erros.codeErro = 'Preencha o campo de código para autenticação.';
    }
    return erros;
};

export const retornaCorpoDadosLogin = dadosLogin => {
    const { usuario, senha } = dadosLogin;
    return JSON.stringify({
        id: environment.tokenId,
        chaveSecreta: environment.tokenChaveSecreta,
        usuario: removeMascaraTelefoneCpfCnpj(usuario),
        senha,
    });
};

export const retornaCorpoDadosLoginMfa = dadosLogin => {
    const { usuario, senha } = dadosLogin;
    return JSON.stringify({
        document: removeMascaraTelefoneCpfCnpj(usuario),
        senha,
    });
};

export const retornaCorpoDadosCriacaoMfa = dadosMfa => {
    const { requestId, usuario, secretMFA, codeMFA, ip, tokenJWT, document } = dadosMfa;
    return JSON.stringify({
        requestId,
        document,
        secretMFA,
        codeMFA: codeMFA,
        ip: '255.255.255.0',
        tokenJWT
    });
};

export const retornaCorpoDadosValidacaoMfa = dadosMfa => {
    const { requestId, usuario, secretMFA, codeMFA, ip, tokenJWT, document } = dadosMfa;
    return JSON.stringify({
        requestId,
        document,
        tokenJWT,
        secretMFA,
        codeMFA: codeMFA
    });
};