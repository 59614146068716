import { MENSAGENS_FINALIZACAO_PORTADOR, METODOS } from './vincular-portador-contantes';
import { preencheCamposDeErrosVincularPortador } from '../../../util/cartoes/cartoes-util';

const ESTADO_INICIAL = {
    cartaoSelecionado: '',
    dataDeNascimentoDoPortador: '',
    dataDeNascimentoDoPortadorErro: '',
    numeroDoDocumentoDoPortador: '',
    numeroDoDocumentoDoPortadorErro: '',
    nomeDoPortador: '',
    nomeDoPortadorErro: '',
    exibirModalDeVincularPortador: false,
    exibirMsgMensagem: false,
    mensagem: ''
};

export default (state = ESTADO_INICIAL, action) => {
    switch (action.type) {
        case METODOS.SET_CARTAO_SELECIONADO_PARA_VINCULAR_PORTADOR:
            return { ...state, cartaoSelecionado: action.payload };
        case METODOS.SET_DATA_DE_NASCIMENTO_DO_PORTADOR:
            return { ...state, dataDeNascimentoDoPortador: action.payload, dataDeNascimentoDoPortadorErro: '' };
        case METODOS.SET_NUMERO_DO_DOCUMENTO_DO_PORTADOR:
            return { ...state, numeroDoDocumentoDoPortador: action.payload, numeroDoDocumentoDoPortadorErro: '' };
        case METODOS.SET_NOME_DO_PORTADOR:
            return { ...state, nomeDoPortador: action.payload, nomeDoPortadorErro: '' };
        case METODOS.SET_EXIBIR_MENSAGEM:
            return { ...state, exibirMsgMensagem: action.payload };
        case METODOS.SET_EXIBIR_MODAL_DE_VINCULAR_PORTADOR:
            return { ...state, exibirModalDeVincularPortador: action.payload };
        case METODOS.LIMPAR_CAMPOS_DE_ERROS:
            return ESTADO_INICIAL;
        case METODOS.VINCULAR_PORTADOR:
            return {
                ...state,
                exibirMsgMensagem: true,
                exibirModalDeVincularPortador: false,
                mensagem: MENSAGENS_FINALIZACAO_PORTADOR.SUCESSO,
                dataDeNascimentoDoPortador: '',
                numeroDoDocumentoDoPortador: '',
                nomeDoPortador: ''
            };
        case METODOS.VINCULAR_PORTADOR_ERRO:
            let erros = preencheCamposDeErrosVincularPortador(action.payload.erros);
            return {
                ...state,
                dataDeNascimentoDoPortadorErro: erros.dataDeNascimentoDoPortadorErro,
                numeroDoDocumentoDoPortadorErro: erros.numeroDoDocumentoDoPortadorErro,
                nomeDoPortadorErro: erros.nomeDoPortadorErro,
                mensagem: MENSAGENS_FINALIZACAO_PORTADOR.ERRO
            };
        default:
            return state;
    }
};
/*  dataDeNascimentoDoPortador: "", dataDeNascimentoDoPortadorErro: "",
        numeroDoDocumentoDoPortador: "", numeroDoDocumentoDoPortadorErro: "", nomeDoPortador: "", nomeDoPortadorErro: "", */
