import React, { Component } from 'react';
import { CONTA_SELECIONADA, LABEL_COMUNS, MENSAGENS, ARQUIVO_RETORNO } from 'componentes/util/contantes-comuns/constantes-comuns';
import LoadingFenix from 'componentes/loading-fenix/loading';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { Button } from 'react-bootstrap';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import { getCnabArchiveApi, downloadCnabArchiveApi } from 'core/api/transferenciaEmLoteRetornoApi';
import ModalComponent from 'componentes/componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from 'componentes/componentes-uteis/modal-component/footer-botoes-modal-component';
import { formataHoraPtBr, formatarDataUS, dataBRParaMoment } from 'componentes/util/data/dataUtil';
import Moment, * as moment from 'moment';

class TransferenciaEmLoteRetorno extends Component {

    constructor(props) {
        super(props);

        this.state = this.getInitialState();

        this.buscaArquivosConta = this.buscaArquivosConta.bind(this);
        this.downloadArquivo = this.downloadArquivo.bind(this);
    }

    getInitialState = () => ({
        arquivosRetorno: [],
        contaSelecionada: CONTA_SELECIONADA.OBJETO(),
        exibirLoading: false,
        exibirModal: false,
        mensagemFinalizacao: ''
    });

    componentDidMount() {
        this.buscaArquivosConta();
    }

    downloadArquivo = async (filename, operation) => {
        const downloadCnabArchiveResponse = await downloadCnabArchiveApi(filename, operation);

        if (downloadCnabArchiveResponse.error || downloadCnabArchiveResponse === ARQUIVO_RETORNO.MENSAGENS.ERRO.ERROR) {
            this.setState({ exibirModal: true, mensagemFinalizacao: ARQUIVO_RETORNO.MENSAGENS.ERRO.GENERICO });
            return;
        }

        const dataURL = `data:text/plain;base64,${downloadCnabArchiveResponse.data.file}`;


        let link = null;
        link = document.createElement('a');
        link.setAttribute('href', dataURL);
        link.setAttribute('download', `${filename}.RET`);
        link.click();
    };

    formataData(file){
        var newfile=[];
        newfile = file.map(e => ({
            ...e,
            date: moment(e.date).format('YYYY-MM-DD HH:mm:ss')
        }));
        return newfile;
    }

    buscaArquivosConta = async () => {
        this.setState({ exibirLoading: true });

        const { pessoaTitularId: customerId, id: accountId } = this.state.contaSelecionada;
        const cnabArchiveApiResponse = await getCnabArchiveApi(customerId, accountId);
        if (cnabArchiveApiResponse.error || cnabArchiveApiResponse === ARQUIVO_RETORNO.MENSAGENS.ERRO.ERROR) {
            this.setState({ arquivosRetorno: [] });
        } else {     
            this.setState({ arquivosRetorno: this.formataData(cnabArchiveApiResponse.data.files) });
        }
        this.setState({ exibirLoading: false});
    }

    render() {
        const { contaSelecionada, exibirLoading, arquivosRetorno } = this.state;
        const { history, } = this.props;
        return (
            <div>
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModal}
                    name="modalFinalizacao"
                    header={ARQUIVO_RETORNO.MODAL_TITULO}
                    body={this.state.mensagemFinalizacao}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn2Label={LABEL_COMUNS.FECHAR}
                            btn2OnClick={() => this.setState({exibirModal: false})}
                        />
                    } />
                <ContaSelecionada
                    contextoBppPag
                    contaSelecionada={contaSelecionada}
                />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel-sem-sombra">
                            <div className="panel-heading fundo-verde-claro" style={{ marginTop: '30px', height: '40px', display: 'flex', alignItems: ' center', justifyContent: 'space-between' }}>
                                <span>Retorno Arquivo</span>
                                <div style={{ height: '100%', display: 'flex', alignItems: 'center'}} >                                   
                                    <Button style={{ color: 'white', background: 'transparent', border: 'none' }} onClick={() => this.buscaArquivosConta()} >
                                        <i className="fa fa-refresh" />
                                    </Button>
                                </div>
                            </div>
                            {
                                exibirLoading ?
                                    <div className="alinhamento-centro" style={{marginTop: 10, height:100, paddingTop: 25 }} ><LoadingFenix /></div> :
                                    arquivosRetorno.length > 0 ?
                                        <div className="panel-body">
                                            <table className="table">
                                                <thead>
                                                    <tr>
                                                        <th style={{ textAlign: 'center' }}
                                                            className="fundo-verde-escuro">Lote
                                                        </th>
                                                        <th style={{ textAlign: 'center' }}
                                                            className="fundo-verde-escuro">Operação
                                                        </th>
                                                        <th style={{ textAlign: 'center' }}
                                                            className="fundo-verde-escuro">Data
                                                        </th>
                                                        <th style={{ textAlign: 'center' }}
                                                            className="fundo-verde-escuro">Arquivo
                                                        </th>
                                                        <th style={{ textAlign: 'center' }}
                                                            className="fundo-verde-escuro">Download
                                                        </th>
                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {
                                                        (arquivosRetorno).map((el, idx) =>

                                                            <tr key={idx} style={{ borderBottom: '1px solid #d2c9c9'}} >
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el.batchId}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el.operation}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el.date}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}>{el.filename}</td>
                                                                <td className="alinhamento-centro" style={{ borderTop: 'none' }}><Button label="Fazer download" className="pi pi-download" style={{ 'fontSize': '1.2em' }} onClick={() => this.downloadArquivo(el.id, el.operation)}/></td>
                                                            </tr>
                                                    )

                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        : arquivosRetorno &&
                                            <div>
                                                <p style={{ textAlign: 'center', height: '100px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}> {MENSAGENS.TRANSFERENCIA_EM_LOTE.ARQUIVOS_RETORNO.ERRO_VAZIO}</p>
                                            </div>
                            }
                            <Grid cols="12 12 12 12" className="alinhamento-direita">
                                <Button bsStyle="success" className="botao-voltar-telas" onClick={history.goBack}>Voltar</Button>
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default TransferenciaEmLoteRetorno;
