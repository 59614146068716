function ExtratoViewModel({
    dataHora = undefined,
    moeda = undefined,
    nome = undefined,
    tipoMovimentacao = undefined,
    valor = undefined
}={}){
    
    this.dataHora = dataHora;
    this.moeda = moeda;
    this.nome = nome;
    this.tipoMovimentacao = tipoMovimentacao;
    this.valor = valor;

}

ExtratoViewModel.buildMock = function() {
    return new ExtratoViewModel({
        dataHora: '2019-07-23T17:26:29',
        moeda: 'BRL',
        nome: 'Carga',
        tipoMovimentacao: 'CREDITO',
        valor: 1.11
    });
};

export default ExtratoViewModel;