import React, { Component } from 'react';
import { LABEL_COMUNS } from '../../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import { FormControl, FormGroup, ControlLabel } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Select from 'react-select';
import If from '../../componentes-uteis/if/if';
import LoadingFenix from '../../loading-fenix/loading-fenix';

class DadosEntrega extends Component {
    render() {
        return (
            <div>
                <If test={this.props.exibirLoading}>
                    <div className="alinhamento-centro"><LoadingFenix /></div>
                </If>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.DADOS_DE_ENTREGA} </b></div>
                            <div className="panel-body espacamento_div_endereco">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 espacamento">
                                                        <ControlLabel>{LABEL_COMUNS.CEP}*</ControlLabel>
                                                        <FormGroup validationState={this.props.cepErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="form-control mensagens-erro" mask="99999-999" maskChar=" "
                                                                placeholder={LABEL_COMUNS.CEP} id="cepPessoa"
                                                                value={this.props.cep || ''}
                                                                onChange={this.props.setCep}
                                                                onBlur={this.props.preencheCamposLocalidade}
                                                            />
                                                            {this.props.cepErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.cepErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 espacamento">
                                                        <ControlLabel>{LABEL_COMUNS.PAIS}*</ControlLabel>
                                                        <FormGroup validationState={this.props.paisErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <Select id="pais"
                                                                placeholder={LABEL_COMUNS.SELECIONE}
                                                                value={this.props.paisSelecionadoId || ''}
                                                                onChange={this.props.setPais}
                                                                options={this.props.listaPaises}
                                                                noResultsText={LABEL_COMUNS.NENHUM_PAIS} />
                                                            {this.props.paisErro ? <span className="mensagens-erro-span" role="alert">{this.props.paisErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento">
                                                        <ControlLabel>{LABEL_COMUNS.ESTADO}*</ControlLabel>
                                                        <FormGroup validationState={this.props.estadoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <Select id="estado"
                                                                placeholder={LABEL_COMUNS.SELECIONE}
                                                                value={this.props.siglaDoEstadoSelecionado || ''}
                                                                onChange={this.props.setEstado}
                                                                options={this.props.listaEstados}
                                                                disabled={!this.props.paisSelecionadoId}
                                                                noResultsText={LABEL_COMUNS.NENHUM_ESTADO} />
                                                            {this.props.estadoErro ? <span className="mensagens-erro-span" role="alert">{this.props.estadoErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento">
                                                        <ControlLabel>{LABEL_COMUNS.CIDADE}*</ControlLabel>
                                                        <FormGroup validationState={this.props.cidadeErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                            <Select id="cidade"
                                                                placeholder={LABEL_COMUNS.SELECIONE}
                                                                value={this.props.nomeDaCidadeSelecionada || ''}
                                                                onChange={this.props.setCidade}
                                                                options={this.props.listaCidades}
                                                                disabled={!this.props.paisSelecionadoId || !this.props.siglaDoEstadoSelecionado}
                                                                noResultsText={LABEL_COMUNS.NENHUMA_CIDADE} />
                                                            {this.props.cidadeErro ? <span className="mensagens-erro-span" role="alert">{this.props.cidadeErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        <ControlLabel>{LABEL_COMUNS.BAIRRO}*</ControlLabel>
                                                        <FormGroup validationState={this.props.bairroErro ? 'error' : null}>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="50" placeholder="Bairro"
                                                                value={this.props.bairro || ''}
                                                                onChange={this.props.setBairro} />
                                                            {this.props.bairroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.bairroErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <ControlLabel>{LABEL_COMUNS.LOGRADOURO}*</ControlLabel>
                                                        <FormGroup validationState={this.props.logradouroErro ? 'error' : null}>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="150" placeholder="Logradouro"
                                                                value={this.props.logradouro || ''}
                                                                onChange={this.props.setLogradouro} />
                                                            {this.props.logradouroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.logradouroErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                        <ControlLabel>{LABEL_COMUNS.NUMERO}*</ControlLabel>
                                                        <FormGroup validationState={this.props.numeroErro ? 'error' : null}>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="5" placeholder="Número"
                                                                value={this.props.numero}
                                                                onChange={this.props.setNumero} />
                                                            {this.props.numeroErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.numeroErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento_complemento">
                                                    <div className="col-xs-8 col-sm-8 col-md-8 col-lg-8">
                                                        <ControlLabel>{LABEL_COMUNS.COMPLEMENTO}</ControlLabel>
                                                        <FormGroup>
                                                            <FormGroup>
                                                                <FormControl style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                    maxLength="50" placeholder="Complemento"
                                                                    value={this.props.complemento}
                                                                    onChange={this.props.setComplemento} />
                                                            </FormGroup>
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        <ControlLabel>{LABEL_COMUNS.PONTO_REFERENCIA}</ControlLabel>
                                                        <FormGroup>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="50" placeholder="Ponto de Referência"
                                                                value={this.props.pontoReferencia}
                                                                onChange={this.props.setPontoReferencia} />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className="row">
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                                    <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                                                        <ControlLabel>{LABEL_COMUNS.DESTINATARIO}*</ControlLabel>
                                                        <FormGroup validationState={this.props.destinatarioErro ? 'error' : null}>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="50" placeholder="Destinatário"
                                                                value={this.props.destinatario}
                                                                onChange={this.props.setDestinatario} />
                                                            {this.props.destinatarioErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.destinatarioErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6">
                                                        <ControlLabel>{LABEL_COMUNS.AOS_CUIDADOS}*</ControlLabel>
                                                        <FormGroup validationState={this.props.aosCuidadosErro ? 'error' : null}>
                                                            <FormControl type="text" style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                                className="mensagens-erro" maxLength="150" placeholder="Aos cuidados"
                                                                value={this.props.aosCuidados}
                                                                onChange={this.props.setAosCuidados} />
                                                            {this.props.aosCuidadosErro ? <span style={{ color: 'red', margin: '-0em !important' }} role="alert">{this.props.aosCuidadosErro}</span> : null}
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                </div>

            </div>
        );
    }
}

export default DadosEntrega;
