import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ScrollPanel } from 'primereact/scrollpanel';
import { Dropdown, Glyphicon, MenuItem } from 'react-bootstrap';
import { LABEL_COMUNS, MENSAGENS, TIPO_DE_PESSOA } from '../../util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import Avatar from '../../listar-contas/componente/avatar';
import './exibir-contas.css';
import CriarConta from '../../contas/criar-conta';
import { setContaSelecionada } from '../../../action/contas/contas-action';
import If from '../../componentes-uteis/if/if';
import LoadingFenix from '../../loading-fenix/loading-fenix';
import EditarConta from '../../contas/editar-conta';
import { getContaSelecionada } from '../../../reducer/contas/contas-reducer';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import {
    fetchGetAnexosContratuais,
    fetchRecuperaOuGeraAnexoPessoaFisica
} from '../../../servicos/anexo-contratual/anexo-contratual-base';
import {
    retornaAnexosDoTipoContaMovimento,
    retornaAnexosParaCombo
} from '../../../servicos/anexo-contratual/anexo-contratual-service';
import { retornaCabecalhoPessoa, retornaNomePessoa } from '../../../servicos/pessoas/pessoa-service';
import BuscaMostraSaldo from 'componentes/util/busca-mostra-saldo';

class ExibirContas extends Component {

    constructor(props) {
        super(props);
        this.ESTADO_INICIAL = {
            exibirModalCriarConta: false, exibirModalEditarConta: false, exibirLoading: false, pessoaSelecionada: undefined,
            exibirModalContaSemAnexo: false, listaDeAnexosContratuais: []
        };
        this.state = this.ESTADO_INICIAL;
    }

    limparDados = () => {
        this.setState(this.ESTADO_INICIAL);
    }

    setExibirModalContaSemAnexo = (exibirModalContaSemAnexo) => {
        this.setState({ exibirModalContaSemAnexo });
    }

    setExibirModalCriarConta = (exibirModalCriarConta) => {
        if (!exibirModalCriarConta) {
            this.limparDados();
        }
        this.setState({ exibirModalCriarConta });
    }

    setExibirModalEditarConta = (exibirModalEditarConta) => {
        if (!exibirModalEditarConta) {
            this.limparDados();
        }
        this.setState({ exibirModalEditarConta });
    }

    setExibirLoading = (exibirLoading) => {
        this.setState({ exibirLoading });
    }

    retornaRodapeMensagemDePessoaJuridicaSemAnexo = () => (
        <FooterBotoesModalComponent
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={() => this.setExibirModalContaSemAnexo(false)}
        />
    );

    /*buscarAnexosContratuais = async (pessoaSelecionada) => {
        this.setExibirLoading(true);
        let listaDeAnexosContratuais = [];
        if (pessoaSelecionada.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
            const { data: { registros } } = await fetchGetAnexosContratuais(pessoaSelecionada.id);
            listaDeAnexosContratuais = retornaAnexosParaCombo(retornaAnexosDoTipoContaMovimento(registros));
            this.setState({ listaDeAnexosContratuais, tipoPessoa: pessoaSelecionada.tipo, nomePessoa: retornaNomePessoa(pessoaSelecionada), pessoaSelecionada });
        }
        else {
            const { data: { registros } } = await fetchRecuperaOuGeraAnexoPessoaFisica(pessoaSelecionada.id);
            listaDeAnexosContratuais = retornaAnexosDoTipoContaMovimento(registros);
            this.setState({ listaDeAnexosContratuais, tipoPessoa: pessoaSelecionada.tipo, nomePessoa: retornaNomePessoa(pessoaSelecionada), pessoaSelecionada });
        }
        if (listaDeAnexosContratuais.length) {
            this.setState({ exibirModalCriarConta: true });
        }
        else {
            this.setState({ exibirModalContaSemAnexo: true });
        }
        this.setExibirLoading(false);
    }*/

    render() {
        const listaDeContas = this.props.listaDeContas;
        const { contaSelecionada } = this.props.exibirContas;
        const { pessoaSelecionada } = this.state;
        const mensagemDePessoaJuridicaSemAnexo = <div dangerouslySetInnerHTML={{ __html: MENSAGENS.ANEXO_CONTRATUAL.ANEXO_NAO_ENCONTRADO_PARA_CRIACAO_DE_CONTAS }} />;
        return (
            <div >
                <ModalComponent
                    name="modal-mensagem-pessoa-juridica-sem-anexo"
                    header={LABEL_COMUNS.CONTAS.TITULOS.PADRAO}
                    exibirModal={this.state.exibirModalContaSemAnexo}
                    body={mensagemDePessoaJuridicaSemAnexo}
                    footer={this.retornaRodapeMensagemDePessoaJuridicaSemAnexo()}
                />
                {contaSelecionada && pessoaSelecionada &&
                    <EditarConta
                        pessoaLogada={this.props.pessoaLogada}
                        empresas={this.props.empresas}
                        getContas={this.props.getContas}
                        contaSelecionada={contaSelecionada}
                        pessoaSelecionada={pessoaSelecionada}
                        exibirModalEditarConta={this.state.exibirModalEditarConta}
                        setExibirModalEditarConta={this.setExibirModalEditarConta}
                        setExibirLoading={this.setExibirLoading}
                    />
                }

                <If test={this.state.exibirLoading}>
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                </If>
                <If test={this.state.listaDeAnexosContratuais.length}>
                    <CriarConta
                        exibirModal={this.state.exibirModalCriarConta}
                        tipoPessoa={this.state.tipoPessoa}
                        nomePessoa={this.state.nomePessoa}
                        pessoaSelecionada={this.state.pessoaSelecionada}
                        setExibirModalCriarConta={this.setExibirModalCriarConta}
                        setExibirLoading={this.setExibirLoading}
                        setExibirModalFinalizacaoCriacaoConta={this.props.setExibirModalFinalizacaoCriacaoConta}
                        setMensagemFinalizacaoCriacaoConta={this.props.setMensagemFinalizacaoCriacaoConta}
                        buscarContas={this.props.buscarContas}
                        listaDeAnexosContratuais={this.state.listaDeAnexosContratuais} />
                </If>

                {
                    listaDeContas.map((contaRecuperada, idx) => {
                        const { pessoaSelecionada, contas } = contaRecuperada;
                        const { 0: conta } = contas;
                        return (
                            <div key={idx}>
                                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                    <Accordion activeIndex={this.state[`activeIndex_${idx}`]} onTabChange={(e) => {
                                        this.setState({ [`activeIndex_${idx}`]: e.index });
                                    }}>
                                        <AccordionTab header={retornaCabecalhoPessoa(pessoaSelecionada)} contentStyle={{ position: 'relative' }}>
                                            <ScrollPanel style={{ width: '100%', height: '450px' }} className="scrollPanelCustom">
                                                {
                                                    contaRecuperada.contas.map(conta => (
                                                        <li key={conta.id} className="list-group-item">
                                                            <div className="row titulo-tabelas">
                                                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                                                    <Avatar conta={conta} /><br />
                                                                </div>
                                                                <div className="col-xs-10 col-sm-10 col-md-10 col-lg-10">
                                                                    {this.montarTipoConta(conta, pessoaSelecionada)}
                                                                </div>
                                                                <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1 alinhamento-direita">
                                                                    <Dropdown id="menuConta" pullRight >
                                                                        <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas">
                                                                            <Glyphicon glyph="option-horizontal" />
                                                                        </Dropdown.Toggle>
                                                                        <Dropdown.Menu >
                                                                            <MenuItem onSelect={() => {
                                                                                this.props.setContaSelecionada(conta);
                                                                                this.setState({ pessoaSelecionada });
                                                                            }}
                                                                                onClick={() => this.setExibirModalEditarConta(true)}>
                                                                                {LABEL_COMUNS.CONTAS.TITULOS.EDITAR_CONTA()}
                                                                            </MenuItem>

                                                                            <li>
                                                                                <Link className="link_expandir" to={{ pathname: '/poderes', state: { contaSelecionada: conta, pessoaSelecionada } }}
                                                                                    onClick={() => {
                                                                                        this.props.setContaSelecionada(conta);
                                                                                    }}>
                                                                                    {LABEL_COMUNS.CONTAS.TITULOS.PERMISSOES}
                                                                                </Link>
                                                                            </li>
                                                                        </Dropdown.Menu>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))
                                                }
                                            </ScrollPanel>
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                                {/*<div className="col-xs-1 col-sm-1 col-md-1 col-lg-1">
                                    {((!conta) || conta && conta.dadosPessoa && conta.dadosPessoa.usuarioAdmin) && <Dropdown id="menuConta" pullRight className="menu-tab-exibir-contas">
                                        <Dropdown.Toggle noCaret className="fundo-azul-claro dropdown-sem-bordas">
                                            <Glyphicon glyph="option-horizontal" />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu >
                                            <MenuItem className="menu-item-conta-dropdown" onClick={() => this.buscarAnexosContratuais(pessoaSelecionada)}>{LABEL_COMUNS.NOVA_CONTA}</MenuItem>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    }
                                </div>*/}
                            </div>
                        );
                    })
                }
            </div>
        );
    }
    /*
        montaTitulo(pessoaSelecionada) {
            let titulo;
            let numeroDoDocumento;
            if (pessoaSelecionada.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
                numeroDoDocumento = pessoaSelecionada.documento.find(d => (d.tipo === TIPO_DOCUMENTO.CNPJ.value)).numero
                const { pessoaJuridica: { razaoSocial } } = pessoaSelecionada;
                titulo = LABEL_COMUNS.EMPRESA.concat(': '.concat(razaoSocial.concat(', '.concat(TIPO_DOCUMENTO.CNPJ.value.concat(': '.concat(CNPJ.format(numeroDoDocumento)))))));
            } else {
                numeroDoDocumento = pessoaSelecionada.documento.find(d => (d.tipo === TIPO_DOCUMENTO.CPF.value)).numero
                const { pessoaFisica: { nomeCompleto } } = pessoaSelecionada;
                titulo = LABEL_COMUNS.MINHAS_CONTAS.concat(': '.concat(nomeCompleto.concat(', '.concat(TIPO_DOCUMENTO.CPF.value.concat(': '.concat(CPF.format(numeroDoDocumento)))))));
            }
            return titulo;
        } */



    montarTipoConta(conta, pessoaSelecionada) {
        let labelConta = conta.agencia + ' - ' + conta.numero + ' - ' + conta.digito;
        const { saldo } = conta;
        return (
            <div className="col-xs-4 col-sm-5 col-md-7 col-lg-7">
                <Link to={ROTAS_CAMINHOS.DASHBOARD_CONTA}>
                    <div className="row cursor-pointer" onClick={() => { this.acessarConta(pessoaSelecionada, conta, saldo); }}>
                        {conta.apelido}
                    </div>
                </Link>
                <div className="row">
                    {labelConta}
                </div>
                <div className="row"><BuscaMostraSaldo idConta={conta.id}/></div>
            </div>
        );
    }

    acessarConta(pessoaSelecionada, conta, saldo) {
        reactLocalStorage.set(LABEL_COMUNS.TAMANHO_PAGINA_LISTA_CARTOES, 3);
        reactLocalStorage.setObject(LABEL_COMUNS.CONTA_SELECIONADA, conta);
        reactLocalStorage.set(LABEL_COMUNS.SALDO_CONTA_SELECIONADA, saldo);
        if (pessoaSelecionada.tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
            reactLocalStorage.set(LABEL_COMUNS.TIPO_ACESSO, TIPO_DE_PESSOA.PESSOA_JURIDICA);
            reactLocalStorage.setObject(LABEL_COMUNS.EMPRESA_SELECIONADA, pessoaSelecionada);
        } else {
            reactLocalStorage.set(LABEL_COMUNS.TIPO_ACESSO, TIPO_DE_PESSOA.PESSOA_FISICA);
        }
    }
}

const mapStateToProps = state => ({
    exibirContas: {
        contaSelecionada: getContaSelecionada(state.ContasReducer)
    }
});

const mapDispatchToProps = dispatch => bindActionCreators({ setContaSelecionada }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ExibirContas);

