import React, { Component } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { ACAO, CONTA_SELECIONADA, LABEL_COMUNS, MENSAGENS, STATUS, OPERACOES_TRANSFERENCIA_EM_LOTE } from '../../../../util/contantes-comuns/constantes-comuns';

import { Button } from 'react-bootstrap';
import Grid from '../../../../componentes-uteis/grid/grid';
import Loading from '../../../../loading-fenix/loading';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';

import { aprovar, corrigir, reprovar } from '../agendamentos-e-aprovacoes-bpp-pag/agendamentos-e-aprovacoes-bpp-pag-base';
import { buscarDetalhesDoArquivo } from '../../../../../action/transferencia-em-lote/transferencia-em-lote-action';
import { formatarDataBR } from '../../../../util/data/dataUtil';
import { formataMoedaComSimboloESinalNegacao } from '../../../../util/numeros/numeroUtil';

class TransferenciaEmLoteDetalhes extends Component {

    constructor(props) {
        super(props);

        const transferenciaEmLote = props.location && props.location.state && props.location.state.transferenciaEmLote;

        this.state = this.getInitialState(props, transferenciaEmLote);
    }

    getInitialState = (props, transferenciaEmLote = {}) => (
        {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            exibirModalConfirmacaoAprovacao: false,
            exibirModalConfirmacaoReprovacao: false,
            exibirModalTransferenciaEmLoteFinalizacao: false,
            exibirLoadingDetalhesDoArquivo: false,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratual: undefined,
            arquivoId: transferenciaEmLote.id,

            // TODO REMOVER ESSE STATE, AO PASSAR TRANSFERENCIA EM LOTE PARA O REDUX.
            status: transferenciaEmLote.status,
        }
    );

    componentDidMount = () => {
        const {
            contaSelecionada,
            arquivoId
        } = this.state;

        const contaId = contaSelecionada.id;
        const pessoaId = contaSelecionada.pessoaTitularId;

        const params = {
            contaId,
            pessoaId,
            arquivoId,
        };

        this.props.buscarDetalhesDoArquivo(params);
    };

    onClickAprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onClickReprovar = (transferenciaEmLote, anexoContratualId) => (
        this.setState({
            exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao,
            transferenciaEmLote: transferenciaEmLote,
            anexoContratualId: anexoContratualId
        })
    );

    onOpenModalFinalizacao = (nomeModalConfirmacao) => (
        (tipo, mensagem) => (
            this.setState({
                exibirLoadingDetalhesDoArquivo: false,
                [nomeModalConfirmacao]: false,
                exibirModalTransferenciaEmLoteFinalizacao: true,
                mensagemFinalizacao: mensagem
            })
        )
    );

    onCloseModalConfirmacao = (nomeModalConfirmacao) => (
        () => (
            this.setState({
                [nomeModalConfirmacao]: false,
            })
        )
    )

    onAprovar = () => {
        this.setState({ exibirLoadingDetalhesDoArquivo: true });

        const { transferenciaEmLote, anexoContratualId, } = this.state;
        const functionName = 'exibirModalConfirmacaoAprovacao';

        aprovar(transferenciaEmLote.id, anexoContratualId, this.onCloseModalConfirmacao(functionName), this.onOpenModalFinalizacao(functionName));
    };

    onReprovar = () => {
        this.setState({ exibirLoadingDetalhesDoArquivo: true });

        const { transferenciaEmLote, anexoContratualId } = this.state;
        const functionName = 'exibirModalConfirmacaoReprovacao';

        reprovar(transferenciaEmLote.id, anexoContratualId, this.onCloseModalConfirmacao(functionName), this.onOpenModalFinalizacao(functionName));
    };

    render() {
        const {
            detalhesDoArquivo,
            location,
        } = this.props;

        const {
            status,
            contaSelecionada,
            exibirLoadingDetalhesDoArquivo
        } = this.state;

        const params = {
            contaId: contaSelecionada.id,
            pessoaId: contaSelecionada.pessoaTitularId,
            arquivoId: this.state.arquivoId,
        };

        const transferenciaEmLote = location.state.transferenciaEmLote;

        return (
            <div>
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoAprovacao}
                    name="modalConfirmacaoAprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.APROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoAprovacao: !this.state.exibirModalConfirmacaoAprovacao })}
                            btn2OnClick={this.onAprovar}
                        />
                    } />
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacaoReprovacao}
                    name="modalConfirmacaoReprovacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.REPROVACAO}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            parentClassName="row"
                            cols="12 12 12 12"
                            chieldClassName="alinhamento-direita"
                            btn1Label={LABEL_COMUNS.CANCELAR}
                            btn2Label={LABEL_COMUNS.CONFIRMAR}
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoReprovacao: !this.state.exibirModalConfirmacaoReprovacao })}
                            btn2OnClick={this.onReprovar}
                        />
                    } />
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalTransferenciaEmLoteFinalizacao}
                    name="modalFinalizacao"
                    header={LABEL_COMUNS.AGENDAMENTOS_APROVACOES.TITULOS.PADRAO}
                    body={this.state.mensagemFinalizacao}
                    footer={
                        <FooterBotoesModalComponent
                            contextoBppPag={true}
                            btn2Label={LABEL_COMUNS.OK}
                            btn2OnClick={() => {
                                this.props.buscarDetalhesDoArquivo(params)
                                    .then(data => {
                                        const status = data.payload && data.payload[0] && data.payload[0].status;

                                        const state = {
                                            ...this.getInitialState(this.props, this.state.transferenciaEmLote),
                                            status: status || STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value
                                        };

                                        this.setState(state);
                                    });
                            }}
                        />
                    } />
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }}>
                                {LABEL_COMUNS.DESTALHES_DO_ARQUIVO}
                            </div>
                            {
                                exibirLoadingDetalhesDoArquivo ?
                                    <div className="alinhamento-centro"><Loading /></div> :
                                    <div className="panel-body">
                                        <table className="table table-condensed">
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.ID}</th>
                                                    <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.OPERACAO}</th>
                                                    <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.VALOR}</th>
                                                    <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.STATUS}</th>
                                                    <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.DATA_DE_INCLUSAO}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    detalhesDoArquivo && detalhesDoArquivo.map((el, idx) =>
                                                        <tr key={idx}>
                                                            <td className="alinhamento-centro">{el ? el.numeroLinhaArquivo : ''}</td>
                                                            <td className="alinhamento-centro">{getOperacao(el.operacaoLoteExibicao)}</td>
                                                            <td className="alinhamento-centro">
                                                                {el ? formataMoedaComSimboloESinalNegacao(el.valor, el.valor < 0 && 'DEBITO') : ''}
                                                            </td>
                                                            <td className="alinhamento-centro">{el ? el.statusExibicao : ''}</td>
                                                            <td className="alinhamento-centro">{el ? formatarDataBR(el.dataCriacao) : ''}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                        <Grid cols="9 9 9 9" />
                                        <Grid cols="1 1 1 1" >
                                            <Button
                                                bsStyle="success"
                                                disabled={!permiteAprovar(status)}
                                                className="alinhamento-direita"
                                                onClick={() => this.onClickAprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}>
                                                {LABEL_COMUNS.APROVAR}
                                            </Button>
                                        </Grid>
                                        <Grid cols="1 1 1 1" >
                                            <Button
                                                bsStyle="success"
                                                disabled={!permiteReprovar(status)}
                                                className="alinhamento-direita"
                                                onClick={() => this.onClickReprovar(transferenciaEmLote, contaSelecionada.anexoContratualId)}>
                                                {LABEL_COMUNS.REPROVAR}
                                            </Button>
                                        </Grid>
                                        <Grid cols="1 1 1 1" >
                                            <Button
                                                bsStyle="success"
                                                disabled={!permiteCorrigir(status)}
                                                style={{ marginLeft: '7px' }}
                                                className="alinhamento-direita"
                                                onClick={() => corrigir(transferenciaEmLote, this.props.history)}>
                                                {LABEL_COMUNS.CORRIGIR}
                                            </Button>
                                        </Grid>
                                        <Grid />
                                    </div>
                            }
                        </div>
                        <br />
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }}>
                                {LABEL_COMUNS.HISTORICO_DE_ACOES}
                            </div>
                            <div className="panel-body">
                                <table className="table table-condensed">
                                    <tbody>
                                        <tr>
                                            <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.DATA_HORARIO}</th>
                                            <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.ACAO}</th>
                                            <th style={{ textAlign: 'center' }} className="fundo-verde-escuro">{LABEL_COMUNS.ARQUIVO.TITULOS.PADRAO}</th>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <Grid cols="11 11 11 11" className="alinhamento-direita">
                    <Button bsStyle="success" className="botao-voltar-telas" onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                </Grid>
            </div>
        );
    }
}

const getOperacao = (operacaoLoteExibicao) => {
    const operacao = Object.keys(OPERACOES_TRANSFERENCIA_EM_LOTE).find(key => OPERACOES_TRANSFERENCIA_EM_LOTE[key].value === operacaoLoteExibicao);
    return operacao ? OPERACOES_TRANSFERENCIA_EM_LOTE[operacao].label : '';
};

const permiteAprovar = (status) => (
    status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value
);

const permiteReprovar = (status) => (
    status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_APROVACAO.value || status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value
);

const permiteCorrigir = (status) => (
    status === STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS.PENDENTE_CORRECAO.value
);

const mapStateToProps = (state) => ({ ...state.TrasferenciaEmLoteReducer });

const mapDispatchToProps = (dispatch) => (
    bindActionCreators({
        buscarDetalhesDoArquivo,
    }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(TransferenciaEmLoteDetalhes);
