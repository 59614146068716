export default class Controle {
    static getBuscarEmpresas() {
        return 'BUSCAR_EMPRESAS_CADASTRO_EMPRESA';
    }

    static getFalhaProcessarServico() {
        return 'FALHA_PROCESSAR_SERVICO';
    }

    static getDepartamentos() {
        return 'CARREGAR_DEPARTAMENTOS_PESSOA';
    }

    static getRepresentantes() {
        return 'BUSCAR_REPRESENTANTES_PESSOA';
    }

    static getEnderecos() {
        return 'BUSCAR_ENDERECOS_PESSOA';
    }

    static getEnderecoPorCep() {
        return 'BUSCAR_ENDERECO_POR_CEP';
    }

    static getPaises() {
        return 'BUSCAR_PAISES';
    }

    static getEstados() {
        return 'BUSCAR_ESTADOS';
    }

    static getCidades() {
        return 'GET_CIDADE';
    }

    static setLoadind() {
        return 'SET_LOADING';
    }

    // -------------- Responsável Comercial ---------------

    static setCpfResponsavelComercial() {
        return 'ALTERAR_CPF_RESPONSAVEL_COMERCIAL';
    }

    static setNomeResponsavelComercial() {
        return 'ALTERAR_NOME_RESPONSAVEL_COMERCIAL';
    }

    static getPessoaPorDocumentoResponsavelComercial() {
        return 'GET_PESSOA_DOCUMENTO_RESPONSAVEL_COMERCIAL';
    }

    // ------------- Selecionar Empresa ----------------

    static getPessoa() {
        return 'BUSCAR_PESSOA';
    }

    // --------------- Dados PJ --------------------

    static setRazaoSocial() {
        return 'SET_RAZAO_SOCIAL';
    }

    static setNomeFantasia() {
        return 'SET_NOME_FANTASIA';
    }

    static setInscricaoEstadual() {
        return 'SET_INCRICAO_ESTADUAL';
    }

    static setDataConstituicao() {
        return 'SET_DATA_CONSTITUICAO';
    }

    static validarDataConstituicao() {
        return 'VALIDAR_DATA_CONSTITUICAO';
    }

    // ----------------- Usuário Admin ------------------

    static setCpfUsuarioAdmin() {
        return 'SET_CPF_USUARIO_ADMIN';
    }

    static setNomeUsuarioAdmin() {
        return 'SET_NOME_USUARIO_ADMIN';
    }

    static getPessoaPorDocumentoUsuarioAdmin() {
        return 'GET_PESSOA_DOCUMENTO_USUARIO_ADMIN';
    }

    static addUsuarioAdmin() {
        return 'ADICIONAR_USUARIO_ADMIN';
    }

    static limparUsuarioAdmin() {
        return 'LIMPAR_USUARIO_ADMIN';
    }

    static erroAddUsuarioAdmin() {
        return 'ERRO_ADD_USUARIO_ADMIN';
    }

    static mostrarModalConfirmaDeleteUsuarioAdmin() {
        return 'MOSTRAR_MODAL_CONFIRMA_DELETE_USUARIO_ADMIN';
    }

    static excluirUsuarioAdmin() {
        return 'EXCLUIR_USUARIO_ADMIN';
    }

    // --------------- Representante Legal -----------------

    static setDocumentoRepresenanteLegal() {
        return 'SET_DOCUMENTO_REPRESENTANTE_LEGAL';
    }

    static setNomeRepresenanteLegal() {
        return 'SET_NOME_REPRESENTANTE_LEGAL';
    }

    static getPessoaPorDocumentoRepresentanteLegal() {
        return 'GET_PESSOA_DOCUMENTO_REPRESENTANTE_LEGAL';
    }

    static addRepresentanteLegal() {
        return 'ADICIONAR_REPRESENTANTE_LEGAL';
    }

    static limparRepresentanteLegal() {
        return 'LIMPAR_REPRESENTANTE_LEGAL';
    }

    static erroAddRepresentanteLegal() {
        return 'ERRO_ADD_REPRESENTANTE_LEGAL';
    }

    static mostrarModalConfirmaDeleteRepresentanteLegal() {
        return 'MOSTRAR_MODAL_CONFIRMA_DELETE_REPRESENTANTE_LEGAL';
    }

    static excluirRepresentanteLegal() {
        return 'EXCLUIR_REPRESENTANTE_LEGAL';
    }

    static setMascaraDocumentoRepresentanteLegal() {
        return 'SET_MASCARA_DOCUMENTO_REPRESENTANTE_LEGAL';
    }

    // ---------------------- Endereço -----------------

    static setNire() {
        return 'SET_NIRE';
    }

    static alterarPessoa() {
        return 'ALTERAR_PESSOA';
    }

    static alterarEndereco() {
        return 'ALTERAR_ENDERECO';
    }

    static setCep() {
        return 'SET_CEP';
    }

    static setEstado() {
        return 'SET_ESTADO';
    }

    static setCidade() {
        return 'SET_CIDADE';
    }

    static setBairro() {
        return 'SET_BAIRRO';
    }

    static setLogradouro() {
        return 'SET_LOGRADOURO';
    }

    static setNumero() {
        return 'SET_NUMERO';
    }

    static setComplemento() {
        return 'SET_COMPLEMENTO';
    }

    static sucessoAlterarEndereco() {
        return 'SUCESSO_ALTERAR_ENDERECO';
    }

    static erroAlterarEndereco() {
        return 'ERRO_ALTERAR_ENDERECO';
    }

    // ----------------------- Email -------------------

    static setEmail() {
        return 'SET_EMAIL';
    }

    static addEmail() {
        return 'ADICIONAR_EMAIL';
    }

    static setErrosEmail() {
        return 'SET_ERROS_EMAIL';
    }

    static alterarEmail() {
        return 'ALTERAR_EMAIL';
    }

    static deleteEmail() {
        return 'DELETE_EMAIL';
    }

    static mostrarModalConfirmaDeleteEmail() {
        return 'MOSTRAR_MODAL_CONFIRMA_DELETE_EMAIL';
    }

    static limparErroEmailNaLista() {
        return 'LIMPAR_ERRO_EMAIL_NA_LISTA';
    }

    // --------------------- Telefone -----------------

    static setNumeroTelefone() {
        return 'SET_NUMERO_TELEFONE';
    }

    static setRamalTelefone() {
        return 'SET_RAMAL_TELEFONE';
    }

    static setDepartamentoTelefone() {
        return 'SET_DEPARTAMENTO_TELEFONE';
    }

    static setContatoTelefone() {
        return 'SET_CONTATO_TELEFONE';
    }

    static addTelefone() {
        return 'ADICIONAR_TELEFONE';
    }

    static setErrosTelefone() {
        return 'SET_ERROS_TELEFONE';
    }

    static excluirTelefone() {
        return 'EXCLUIR_TELEFONE';
    }

    static mostrarModalConfirmaDeleteTelefone() {
        return 'MOSTRAR_MODAL_CONFIRMA_DELETE_TELEFONE';
    }

    static alterarTelefone() {
        return 'ALTERAR_TELEFONE';
    }

    static setNumeroTelefoneLista() {
        return 'SET_NUMERO_TELEFONE_LISTA';
    }

    static setRamalTelefoneLista() {
        return 'SET_RAMAL_TELEFONE_LISTA';
    }

    static setDepartamentoTelefoneLista() {
        return 'SET_DEPARTAMENTO_TELEFONE_LISTA';
    }

    static setContatoTelefoneLista() {
        return 'SET_CONTATO_TELEFONE_LISTA';
    }

    static setListaGeralTelefone() {
        return 'SET_LISTA_GERAL_TELEFONE';
    }

    // ----------------------- Celular -----------------

    static setNumeroCelular() {
        return 'SET_NUMERO_CELULAR';
    }

    static setContatoCelular() {
        return 'SET_CONTATO_CELULAR';
    }

    static setNumeroCelularLista() {
        return 'SET_NUMERO_CELULAR_LISTA';
    }

    static setContatoCelularLista() {
        return 'SET_CONTATO_CELULAR_LISTA';
    }

    static addCelular() {
        return 'ADICIONAR_CELULAR';
    }

    static setErrosCelular() {
        return 'SET_ERROS_CELULAR';
    }

    static alterarCelular() {
        return 'ALTERAR_CELULAR';
    }

    static excluirCelular() {
        return 'EXCLUIR_CELULAR';
    }

    static mostrarModalConfirmaDeleteCelular() {
        return 'MOSTRAR_CONFIRMA_DELETE_CELULAR';
    }

    // -------------- Arquivo Documento -------------

    static getArquivoDocumentoPessoa() {
        return 'GET_ARQUIVO_DOCUMENTO_PESSOA';
    }

    static setArquivoDocumentoPessoa() {
        return 'SET_ARQUIVO_DOCUMENTO_PESSOA';
    }

    static getArquivoDocumentoS3() {
        return 'GET_ARQUIVO_DOCUMENTO_S3';
    }

    static deleteArquivoDocumento() {
        return 'DELETE_ARQUIVO_DOCUMENTO';
    }

    static mostrarModalConfirmarDeleteArquivo() {
        return 'MOSTRAR_MODAL_CONFIRMAR_DELETE_ARQUIVO';
    }

    static fecharModalVisualizarArquivoDocumento() {
        return 'FECAHR_MODAL_VISUALIZAR_ARQUIVO_DOCUMENTO';
    }

    static sucessoUploadArquivoDocumento() {
        return 'SUCESSO_UPLOAD_ARQUIVO_DOCUMENTO';
    }

    static deleteArquivoDocumentoNaoSalvo() {
        return 'DELETE_ARQUIVO_DOCUMENTO_NAO_SALVO';
    }

    // ---------------- Salvar Pessoa ---------------

    static sucessoAlterarPessoa() {
        return 'SUCESSO_ALTERAR_PESSOA';
    }

    static fecharModalSucessoAlterarPessoa() {
        return 'FECHAR_MODAL_SUCESSO_ALTERAR_PESSOA';
    }

    // ---------------- Erro altera PJ ---------------

    static erroAlterarPessoa() {
        return 'ERRO_ALTERAR_PESSOA';
    }
}
