import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button, Collapse, Dropdown, Glyphicon, MenuItem, Panel } from 'react-bootstrap';
import If from 'componentes/componentes-uteis/if/if';
import MostrarSaldoCartao from 'componentes/contas/saldo/cartao/mostrar-saldo-cartao';
import imagemCartao from 'imagens/cartao-chip.jpg';
import { CARTAO_STATUS, LABEL_COMUNS, OPERACAO, TIPO_DE_ACESSO } from 'componentes/util/contantes-comuns/constantes-comuns';
import { ROTAS_CAMINHOS } from 'componentes/rotas-constantes';
import { formatarStatus } from 'componentes/util/cartoes/cartoes-util';

const CARTOES = 'Cartões';

class GridCartoesBppConsumer extends Component {
    render() {
        const { podeAprovar, podeConsultar, podeCriar } = this.props.outorgaPerfils;
        const listaDeCartoes = this.props.listaCartoes && this.props.listaCartoes.length > 0 ? this.props.listaCartoes : [];
        const { id } = this.props;
        return (
            <div id={id}>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11" />
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Button className="botao_informacoes" onClick={this.props.setAbriSecaoCartao}>
                        {CARTOES + ' ' + this.props.labelTitulo} ({this.props.quantidadeCartoes})
                        <span>&nbsp; &nbsp;</span>
                        {iconeBarraCartao(this.props.abrirCartoes)}
                    </Button>
                    {/* Verificar se este trecho vai continuar existindo */}
                    <If test >
                        <div className="botao_saldos">
                            {/*   {this.montarBotaoSaldos()} */}
                        </div>
                    </If>
                </div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                    <Collapse in={this.props.abrirCartoes}>
                        <Panel className="conteudo_cartoes" style={{ overflowY: 'auto', overflowX: 'hidden' }}>
                            <div className="row list-group-item texto-extrato-detalhe">
                                <div className="col-sm-1 col-md-1 col-lg-1" />

                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.ID}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.CARTAO}
                                </div>
                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.VENCIMENTO}
                                </div>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.NOME_PORTADOR}
                                </div>
                                <If test={podeConsultar}>
                                    <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                        {LABEL_COMUNS.SALDO}
                                    </div>
                                </If>
                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                    {LABEL_COMUNS.STATUS}
                                </div>
                                {podeAprovar && <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro ">
                                    {LABEL_COMUNS.ACAO}
                                </div>}
                            </div>
                            <div className="list-group">
                                {listaDeCartoes.map((cartao, idx) => {
                                    return (
                                        <li key={idx} className="list-group-item texto-extrato-detalhe">
                                            <div className="row">
                                                <div className="col-sm-1 col-md-1 col-lg-1">
                                                    <img src={imagemCartao} className="avatar-cartao" alt=" imagem cartao" />
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                                    {cartao.idCartaoImpresso}
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.panFormatado}
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 textos_cartao alinhamento-centro">
                                                    {cartao.validade}
                                                </div>
                                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {cartao.nomePortador}
                                                </div>
                                                <If test={podeConsultar}>
                                                    <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                        <MostrarSaldoCartao contaCartaoId={cartao.cartaoId} />
                                                    </div>
                                                </If>

                                                <div className="col-sm-2 col-md-2 col-lg-2 textos_cartao alinhamento-centro">
                                                    {formatarStatus(cartao.status)}
                                                </div>
                                                <div className="col-sm-1 col-md-1 col-lg-1 alinhamento-centro botao_extrato_cartao">
                                                    <Dropdown id="menuConta" pullRight >
                                                        <Dropdown.Toggle noCaret className="fundo-branco dropdown-sem-bordas-lote">
                                                            <Glyphicon glyph="option-horizontal alinhamento-icone" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu >

                                                            <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                                <If test={podeCriar}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowActivateCardModal(true);
                                                                    }}>
                                                                        {LABEL_COMUNS.CARTOES.TITULOS.ATIVAR}
                                                                    </MenuItem>
                                                                </If>
                                                            </If>
                                                            <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.BLOQUEADO || cartao.status === CARTAO_STATUS.CANCELADO}>
                                                                <li onClick={() => this.props.setArmazenarCartao(cartao)}>
                                                                    <Link className="link_expandir" to={ROTAS_CAMINHOS.EXTRATO_CARTAO_BPP_CONSUMER}>
                                                                        {LABEL_COMUNS.EXTRATO}
                                                                    </Link>
                                                                </li>
                                                            </If>
                                                            <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowChargeOrDischargeCard(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CARGA_CARTAO);
                                                                }} onClick={this.props.exibirModalCargaCartao}>{LABEL_COMUNS.CARREGAR_CARTAO}
                                                                </MenuItem>

                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowChargeOrDischargeCard(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESCARGA_CARTAO);
                                                                }}
                                                                    onClick={this.props.exibirModalDescargaCartao}>{LABEL_COMUNS.DESCARREGAR_CARTAO}
                                                                </MenuItem>

                                                            </If>

                                                            <MenuItem onSelect={() => this.props.setArmazenarCartao(cartao)}
                                                                onClick={() => this.props.setExibirModalDetalhePortador(true)}>
                                                                {LABEL_COMUNS.DETALHE_PORTADOR}
                                                            </MenuItem>

                                                            <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowModalAlterarSenhaCartao(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.ALTERACAO_PIN_CARTAO);
                                                                }} >
                                                                    {LABEL_COMUNS.CARTOES.TITULOS.ALTERAR_SENHA_CARTAO}
                                                                </MenuItem>
                                                            </If>

                                                            <If test={podeCriar}>
                                                                <If test={cartao.status === CARTAO_STATUS.ATIVO}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowLockCardModal(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.ALTERA_STATUS_CARTAO);
                                                                    }}
                                                                        onClick={this.props.exibirModalDescargaCartao}>{LABEL_COMUNS.CARTOES.TITULOS.BLOQUEAR_CARTAO}
                                                                    </MenuItem>
                                                                </If>
                                                                <If test={cartao.status === CARTAO_STATUS.ATIVO || cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setShowCancelCardModal(true);
                                                                        this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.CANCELA_CARTAO);
                                                                    }}>
                                                                        {LABEL_COMUNS.CARTOES.TITULOS.CANCELAR_CARTAO}
                                                                    </MenuItem>
                                                                </If>
                                                            </If>
                                                            <If test={cartao.status === CARTAO_STATUS.BLOQUEADO}>

                                                                <MenuItem onSelect={() => {
                                                                    this.props.setArmazenarCartao(cartao);
                                                                    this.props.setShowUnlockCardModal(true);
                                                                    this.props.setOperacaoCartaoSelecionada(OPERACAO.NOME.DESBLOQUEIO_CARTAO);
                                                                }}
                                                                    onClick={this.props.exibirModalDescargaCartao}>{LABEL_COMUNS.CARTOES.TITULOS.DESBLOQUEAR_CARTAO}
                                                                </MenuItem>
                                                            </If>

                                                            <If test={cartao.status === CARTAO_STATUS.PRONTOS_PARA_ATIVACAO && this.props.vincularPortador}>
                                                                <If test={this.props.tipoDeAcesso === TIPO_DE_ACESSO.PESSOA_JURIDICA && !cartao.portadorPessoaId}>
                                                                    <MenuItem onSelect={() => {
                                                                        this.props.setArmazenarCartao(cartao);
                                                                        this.props.setCartaoSelecionadoParaVincularPortador(cartao);
                                                                    }} onClick={() => this.props.setExibirModalDeVincularPortador(true)}>{LABEL_COMUNS.VINCULAR_PORTADOR}
                                                                    </MenuItem>
                                                                </If>
                                                            </If>

                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }
                                )}
                                <br />

                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div >
        );
    }
}

const iconeBarraCartao = (abrirCartao) => (
    <span className={`glyphicon glyphicon glyphicon glyphicon-chevron-${abrirCartao ? 'up' : 'down'}`} />
);



export default GridCartoesBppConsumer;
