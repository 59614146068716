import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Radio } from 'react-bootstrap';
import { CAMPOS, COMBO, CONTA_SELECIONADA, LABEL_COMUNS, MENSAGENS, MODELO_ARQUIVO, OPERACOES_TRANSFERENCIA_EM_LOTE, PRODUTOS } from '../../../../util/contantes-comuns/constantes-comuns';
import ContaSelecionada from '../../../../contas/conta-selecionada';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import Grid from '../../../../componentes-uteis/grid/grid';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import ModalInstrucoesTransferenciaEmLote from './modal-instrucoes-transferencia-lote';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import { retornaObjetoTarget } from '../../../../../servicos/base/service-base';
import { salvarTransferenciaEmLote, } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';

import './lote.css';
import { getExtensoesAceitasPorModeloArquivo, getModeloArquivoValido, isExtensaoValida, } from '../../../../util/valida-extensao';
import { convertCharsetToUtf8 } from '../../../../util/handleCharset';


class TransferenciaEmLoteUploadArquivo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: CONTA_SELECIONADA.OBJETO(),
            exibirLoading: false,
            operacaoTransferenciaEmLoteSelecionada: '',
            modeloArquivoSelecionado: '',
            redirecionarDashboardBppPag: false,
            arquivoEnviado: null,
            exibirModalExemplo: false,
            modelo: '',
            acaoCorrecao: false,
            produto: '',
            habilitaBotaoImportar: false,
        };
    }

    componentDidMount() {
        const { id, pessoaTitularId } = this.state.contaSelecionada;
        let correcao = this.props.location &&
            this.props.location.state &&
            this.props.location.state.acao &&
            this.props.location.state.acao == 'corrigir'
            ? true
            : false;

        if (correcao) {
            this.setState({
                operacaoTransferenciaEmLoteSelecionada: this.props.location.state
                    .operacao,
            });
        }

        let transferenciaEmLote = this.props.location.state
            ? this.props.location.state.transferenciaEmLote
            : {};

        this.setState({
            arquivoSelecionado: [],
            arquivoEnviado: transferenciaEmLote,
            modeloArquivoSelecionado: transferenciaEmLote.formato || '',
            habilitaBotaoImportar: true,
            acaoCorrecao: correcao,
            operacaoTransferenciaEmLoteSelecionada: transferenciaEmLote.operacao || '',
            exibirModalConfirmacao: false,
            exibirModalFinalizacao: false,
            exibirModalExtensaoInvalida: false,
            contaId: id,
            pessoaId: pessoaTitularId,
            produto: PRODUTOS.pag
        });
    }

    limparArquivo = () => {
        this.setState(
            {
                idLimparArquivo: Math.random(),
                arquivoSelecionado: [],
            },
            this.habilitaBotaoImportar
        );
    };

    handleFileChosen = e => {
        const file = e.target.files[0];

        let modeloArquivo = getModeloArquivoValido(
            this.state.modeloArquivoSelecionado
        );

        if (file instanceof Blob) {
            if (file.name) {
                const extensao = file.name.substring(
                    file.name.lastIndexOf('.'),
                    file.name.length
                );

                if (!isExtensaoValida(extensao, modeloArquivo)) {
                    this.setState({
                        exibirModalExtensaoInvalida: true,
                        extensaoArquivo: extensao,
                        habilitaBotaoImpotar: false,
                    });
                    return;
                }
            }

            let mimeType = 'text/plan';
            if (modeloArquivo === 'padraoBPP') {
                mimeType = 'text/csv';
            }

            convertCharsetToUtf8(file, mimeType, file => {
                this.setState({
                    arquivoSelecionado: file,
                    habilitaBotaoImportar: true,
                });
            });
        }
    };

    setPropsCampos = (campo, nomeDoCampo) => {
        const valorDoCampo = campo && campo.target && campo.target.value;
        this.setState({ [nomeDoCampo]: valorDoCampo }, this.habilitaBotaoImportar);
    };

    habilitaBotaoImportar = () => {
        const {
            arquivoSelecionado,
            modeloArquivoSelecionado,
            operacaoTransferenciaEmLoteSelecionada,
        } = this.state;
        if (
            arquivoSelecionado &&
            arquivoSelecionado instanceof File &&
            modeloArquivoSelecionado &&
            operacaoTransferenciaEmLoteSelecionada
        ) {
            this.setState({ habilitaBotaoImportar: true });
        } else {
            this.setState({ habilitaBotaoImportar: false });
        }
    };

    salvar = async () => {
        const resultado = await salvarTransferenciaEmLote(this.state);
        const { error } = resultado;
        if (error) {
            this.setPropsCampos(
                retornaObjetoTarget(
                    MENSAGENS.TRANSFERENCIA_EM_LOTE.FINALIZACAO.ERRO.SOLICITACAO_ENVIO_ARQUIVO()
                ),
                CAMPOS.MENSAGEM_FINALIZACAO
            );
        } else {
            this.setPropsCampos(
                retornaObjetoTarget(
                    MENSAGENS.TRANSFERENCIA_EM_LOTE.FINALIZACAO.SUCESSO.SOLICITACAO_ENVIO_ARQUIVO()
                ),
                CAMPOS.MENSAGEM_FINALIZACAO
            );
        }
        this.setPropsCampos(retornaObjetoTarget(false), CAMPOS.EXIBIR_LOADING);
        this.setPropsCampos(
            retornaObjetoTarget(true),
            CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO
        );
    };

    retornaRodapeModalConfirmacao = () => (
        <FooterBotoesModalComponent
            contextoBppPag={true}
            btn1Label={LABEL_COMUNS.NAO}
            btn1OnClick={() =>
                this.setPropsCampos(
                    retornaObjetoTarget(false),
                    CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO
                )}
            btn2Label={LABEL_COMUNS.SIM}
            btn2OnClick={() => {
                this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_LOADING);
                this.setPropsCampos(
                    retornaObjetoTarget(false),
                    CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO
                );
                this.salvar();
            }}
        />
    );

    retornaRodapeModalFinalizacao = () => (
        <FooterBotoesModalComponent
            contextoBppPag={true}
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={() => {
                this.setPropsCampos(
                    retornaObjetoTarget(false),
                    CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_FINALIZACAO
                );
                this.setState({ redirecionarDashboardBppPag: true });
            }}
        />
    );

    retornaRodapeModalErroExtensao = () => (
        <FooterBotoesModalComponent
            contextoBppPag={true}
            btn2Label={LABEL_COMUNS.OK}
            btn2OnClick={() => this.setState({ exibirModalExtensaoInvalida: false })}
        />
    );

    onChangeModeloArquivoSelecionado = event => {
        this.setPropsCampos(event, CAMPOS.MODELO_ARQUIVO_SELECIONADO);
    };

    onClickOpenExemploModeloArquivo = modelo =>
        this.setState({
            ...this.state,
            modeloArquivo: modelo,
            exibirModalExemplo: true,
        });

    onClickCloseExemploModeloArquivo = () =>
        this.setState({
            ...this.state,
            exibirModalExemplo: false,
        });

    render() {
        const {
            habilitaBotaoImportar,
            modeloArquivoSelecionado,
            operacaoTransferenciaEmLoteSelecionada,
            redirecionarDashboardBppPag,
            modeloArquivo,
            exibirModalExemplo,
        } = this.state;

        if (redirecionarDashboardBppPag) {
            return <Redirect to={ROTAS_CAMINHOS.DASHBOARD_BPP_PAG} />;
        }

        return (
            <div>
                <ContaSelecionada
                    contextoBppPag={true}
                    contaSelecionada={this.state.contaSelecionada}
                />
                {this.state.exibirLoading && <LoadingFenix />}
                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalConfirmacao}
                    name="modal-confirmacao"
                    header={LABEL_COMUNS.ENVIO_DE_ARQUIVO}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.CONFIRMACAO.ENVIO.SOLICITACAO_ENVIO_ARQUIVO()}
                    footer={this.retornaRodapeModalConfirmacao()}
                />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalFinalizacao}
                    name="modalFinalizacao"
                    header={LABEL_COMUNS.ENVIO_DE_ARQUIVO}
                    body={this.state.mensagemFinalizacao}
                    footer={this.retornaRodapeModalFinalizacao()}
                />

                <ModalComponent
                    contextoBppPag={true}
                    exibirModal={this.state.exibirModalExtensaoInvalida}
                    name="modalExtensaoInvalida"
                    header={LABEL_COMUNS.EXTENSAO_INVALIDA}
                    body={MENSAGENS.TRANSFERENCIA_EM_LOTE.EXTENSAO_INVALIDA(
                        this.state.extensaoArquivo
                    )}
                    footer={this.retornaRodapeModalErroExtensao()}
                />

                <ModalInstrucoesTransferenciaEmLote
                    exibirModal={exibirModalExemplo}
                    operacao={operacaoTransferenciaEmLoteSelecionada}
                    contextBppPag={true}
                    fecharModal={this.onClickCloseExemploModeloArquivo}
                    tipoArquivo={modeloArquivo}
                />

                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 espacamento_conteudo_lote">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }} >
                                {LABEL_COMUNS.TRANSFERENCIA_EM_LOTE.TITULO()}
                            </div>
                            <div className="panel-body">
                                {COMBO.OPERACOES.OPERACOES_TRANSFERENCIA_EM_LOTE().map((operacao, idx) => (
                                    <Grid key={idx} cols="4 4 4 4" className="alinhamento-centro" >
                                        <Radio
                                            name={CAMPOS.OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA}
                                            value={operacao.value}
                                            checked={isOperacaoSelecionada(operacao, operacaoTransferenciaEmLoteSelecionada)}
                                            defaultChecked={isOperacaoSelecionada(operacao, operacaoTransferenciaEmLoteSelecionada)}
                                            disabled={!permiteOperacao(modeloArquivoSelecionado, operacao) || this.state.acaoCorrecao}
                                            onChange={e => this.setPropsCampos(e, CAMPOS.OPERACAO_TRANSFERENCIA_EM_LOTE_SELECIONADA)} >
                                            <span style={{ color: 'black', fontWeight: 'bold' }}>
                                                {operacao.label}
                                            </span>
                                        </Radio>
                                    </Grid>
                                ))}
                            </div>
                        </div>
                        <br />
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }} >
                                {LABEL_COMUNS.TRANSFERENCIA_EM_LOTE.QUAL_O_MODELO_DE_ARQUIVO}
                            </div>
                            <div className="panel-body">
                                {COMBO.MODELO_ARQUIVO_TRANSFERENCIA_EM_LOTE.map(
                                    (modeloArquivo, idx) => (
                                        <Grid key={idx} cols="6 6 6 6 6" className="alinhamento-centro radio-modelo-arquivo" >
                                            <Radio
                                                name={CAMPOS.MODELO_ARQUIVO_SELECIONADO}
                                                value={modeloArquivo.value}
                                                checked={isModeloSelecionado(modeloArquivo, modeloArquivoSelecionado)}
                                                disabled={!permiteModeloCNAB(modeloArquivo, operacaoTransferenciaEmLoteSelecionada)}
                                                onChange={this.onChangeModeloArquivoSelecionado}
                                            >
                                                <span style={{ color: 'black', fontWeight: 'bold' }}>
                                                    {' '}{modeloArquivo.label}
                                                </span>
                                            </Radio>
                                            <i onClick={() => this.onClickOpenExemploModeloArquivo(modeloArquivo.tipoArquivo)} className="fa fa-question-circle radio-modelo-arquivo-exemplo" style={{ cursor: 'pointer' }} />
                                        </Grid>
                                    )
                                )}
                            </div>
                        </div>
                        <br />
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-verde-claro" style={{ height: '40px' }} >
                                Incluir novo arquivo
                                {operacaoTransferenciaEmLoteSelecionada === 'cargaCartao' && ': Carga Cartão'}
                                {operacaoTransferenciaEmLoteSelecionada === 'TED' && ': TED'}
                                {operacaoTransferenciaEmLoteSelecionada === 'transferenciaEntreContas' && ': Transferência entre Contas'}
                            </div>
                            <div className="panel-body">
                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 fundo-cinza-claro" style={{ padding: 25 }} >
                                    <Grid cols="6 6 6 6" className="alinhamento-centro">
                                        <input type="file"
                                            key={this.state.idLimparArquivo}
                                            onChange={this.handleFileChosen}
                                            accept={getExtensoesAceitasPorModeloArquivo(
                                                this.state.modeloArquivoSelecionado
                                            )}
                                            ref={ref => (this.fileInput = ref)}
                                            onClick={() => {
                                                this.fileInput.value = null;
                                            }}
                                            placeholder="Arquivo"
                                            className="inputClass"
                                        />
                                    </Grid>
                                    <Grid cols="6 6 6 6" className="alinhamento-direita">
                                        <Button bsStyle="default" onClick={this.limparArquivo}>
                                            {LABEL_COMUNS.LIMPAR}
                                        </Button>
                                        &nbsp;&nbsp;
                                        <Button
                                            bsStyle="success"
                                            onClick={() => this.setPropsCampos(retornaObjetoTarget(true), CAMPOS.EXIBIR_MODAL.EXIBIR_MODAL_CONFIRMACAO)}
                                            disabled={!habilitaBotaoImportar}
                                        >
                                            {LABEL_COMUNS.IMPORTAR}
                                        </Button>
                                    </Grid>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-direita">
                    <Button
                        bsStyle="success"
                        className="botao-voltar-telas"
                        onClick={this.props.history.goBack}
                    >
                        {LABEL_COMUNS.VOLTAR}
                    </Button>
                </div>
            </div>
        );
    }
}

const isModeloSelecionado = (modeloAtual, modeloSelecionado) => {
    return !!(modeloSelecionado && modeloSelecionado === modeloAtual.value);
};

const isOperacaoSelecionada = (operacaoAtual, operacaoSelecionada) => {
    return !!(operacaoSelecionada && operacaoSelecionada === operacaoAtual.value);
};

const permiteOperacao = (modelo, operacao) => {
    return !(modelo === MODELO_ARQUIVO.MODELO_CNAB.value && operacao.value === OPERACOES_TRANSFERENCIA_EM_LOTE.CARGA_CARTAO.value);
};

const permiteModeloCNAB = (modelo, operacao) => {
    return !(modelo.value === MODELO_ARQUIVO.MODELO_CNAB.value && operacao === OPERACOES_TRANSFERENCIA_EM_LOTE.CARGA_CARTAO.value);
};

export default TransferenciaEmLoteUploadArquivo;
