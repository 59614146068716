import { HEADER_API, HTTP_METODOS, montaUrlFetch, urlServicos, HEADER_API_FN } from '../url-servicos';
import { retornaDadosPostPessoa } from './pessoa-service';

export const fetchGetPessoaPorNumeroDocumento = (numeroDocumento) => {
    return fetch(montaUrlFetch(urlServicos.PESSOA, { numeroDocumento }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchgetPessoaById = (pessoaId) => {
    return fetch(montaUrlFetch(urlServicos.PESSOA_ID(pessoaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGravaPessoa = (dadosPessoa) => {
    return fetch(montaUrlFetch(urlServicos.PESSOA), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(retornaDadosPostPessoa(dadosPessoa))
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchAtualizaDadosPessoa = async (pessoaId, dadosPessoa) => {
    return await fetch(montaUrlFetch(urlServicos.PESSOA_ID(pessoaId)), {
        method: HTTP_METODOS.PATCH,
        headers: HEADER_API().headers,
        body: dadosPessoa
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetPrestacaoDeContas = (pessoaTitularId, filtro) => {
    return fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA(pessoaTitularId), filtro), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetPrestacaoDeContasProjetos = (pessoaId) => {
    return fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA_PROJETO(pessoaId), { 'status': 'ativo' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetPrestacaoDeContasClassificacoes = (pessoaId) => {
    return fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA_CLASSIFICACAO(pessoaId), { 'status': 'ativo' }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchCadastrarPrestacaoDeContas = (pessoaId, dadosPrestacaoDeContas) => {
    return fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA(pessoaId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: dadosPrestacaoDeContas
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchAlterarPrestacaoDeContas = (pessoaId, prestacaoDeContasId, dadosPrestacaoDeContas) => {
    return fetch(montaUrlFetch(urlServicos.PRESTACAO_CONTA_ID(pessoaId, prestacaoDeContasId)), {
        method: HTTP_METODOS.PUT,
        headers: HEADER_API().headers,
        body: dadosPrestacaoDeContas
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetUsuariosAdmin = async (pessoaId) => {
    return await fetch(montaUrlFetch(urlServicos.USUARIO_ADMIN(pessoaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    })
        .then(async resp => resp.ok ? ({ error: !resp.ok, data: await resp.json() }) : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};
