export function formataMoeda(numero) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
    return formatter.format(numero).replace('R$', '');
}

export function formataMoedaComSimboloESinalNegacao(numero, tipo, moeda) {
    let valorMoeda = moeda || '';
    let valorRetorno = '';
    if ((tipo && tipo === 'DEBITO') && (!isNaN(numero) && numero !== 0)) {
        valorRetorno = '-';
    }

    if (valorMoeda === 'BRL' || valorMoeda === '') {
        moeda = 'R$';
    } else {
        moeda = 'US$';
    }

    if (numero && numero < 0) {
        numero = numero + '';
        numero = numero.replace('-', '');
    }

    const formatter = new Intl.NumberFormat('pt-BR', {
        minimumFractionDigits: 2
    });

    return valorRetorno + moeda + ' ' + formatter.format(numero);
}

export function recuperaSomenteNumerosdeUmaString(stringNumero) {
    return stringNumero.replace(/\D+/g, '');
}

export function formatarMascaraCnpj(valor, opc = true) {
    if (opc) {
        valor = valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    } else {
        if (valor !== null || valor !== undefined) {
            valor = valor
                .replace('.', '')
                .replace('.', '')
                .replace('-', '');
        }
    }
    return valor;
}

export function formataMoedaReal(numero) {
    const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });
    return formatter.format(numero).replace('-', '');
}

export function valorMaiorQueZero(valor) {
    let retorno = false;
    if (valor === 0) {
        retorno = false;
    } else if (parseFloat(valor.replace(',', '.')) > 0) {
        retorno = true;
    }
    return retorno;
}
