import { reactLocalStorage } from 'reactjs-localstorage';
import { LABEL_COMUNS } from '../util/contantes-comuns/constantes-comuns';

/*  Menu que será apresentado ao usuário quando o mesmo selecionar uma conta em que o mesmo é Titular */

export function MenuInicio() {
    const menu = [
        {
            title: '',
            links: [
                { label: 'Início', to: '/' }
            ]
        }
    ];
    return menu;
}

export function MenuInicioContaSelecionada() {
    let menu = [];
    if (reactLocalStorage.getObject('empresaSelecionada') || reactLocalStorage.get('tipoAcesso') === 'PJ') {
        menu = [
            {
                links: [
                    { label: 'Início', to: '/minha-conta/dashboard' },
                    { label: 'Acessar Outra Conta', to: '/' }
                ]
            }
        ];
    } else {
        menu = [
            {
                links: [
                    { label: 'Acessar Outra Conta', to: '/' }
                ]
            }
        ];
    }
    return menu;
}

/*  Menu que será apresentado ao usuário quando logar no sistema */

export function MenuTitular(nomePessoa = reactLocalStorage.get('nomeUsuario')) {
    const menu = [
        {
            title: nomePessoa,
            links: [
                { label: 'Meu Cadastro', to: '/dados-pessoa-fisica' },
                { label: 'Meus Termos de Uso', external: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/TERMO-DE-USO-DE-CARTOES.pdf', target: '_blank' }
            ]
        }
    ];
    return menu;
}

/*  Menu que será apresentado ao usuário quando logar no sistema */

export function MenuTitularContaSelecionada(nomePessoa = reactLocalStorage.get('nomeUsuario')) {
    const menu = [
        {
            title: nomePessoa,
            links: [
                { label: 'Meu Cadastro', to: '/minha-conta/dados-pessoa-fisica' },
                { label: 'Meus Termos de Uso', external: 'https://s3.amazonaws.com/fenix-arquivos-publicos-prod/TERMO-DE-USO-DE-CARTOES.pdf', target: '_blank' }
                // { label: "Meu Pacote de Produtos", to: "/meu-pacote-produtos" }, // Ver Jira FXD-826
                /*                 { label: "Parâmetros", to: "/gerenciar-prestacao-conta" } */
            ]
        }
    ];
    return menu;
}

/*  Menu que será apresentado ao usuário quando o mesmo selecionar uma conta de PJ o qual tem permissão de ADM */

export function MenuEmpresaAdm(contextoBpp) {
    const menu = [
        {
            title: reactLocalStorage.getObject(LABEL_COMUNS.EMPRESA_SELECIONADA).pessoaJuridica.razaoSocial,
            links:
        [
            { label: 'Cadastro', to: '/minha-conta/dados-pessoa-juridica' }
        ]
        }
    ];
    if (!contextoBpp) {
        menu[0].links.push({ label: 'Parâmetros', to: '/minha-conta/parametro-prestacao' });
    }
    return menu;
}

/*  Menu será apresentado quando for selecionado pelo usuário a solução desejada */

export function MenuSolucao(solucao = 'Solução') {
    const menu = [
        {
            title: solucao,
            links: [
                { label: 'Tarifas', to: '/tarifas-solucao' }
            ]
        }
    ];
    return menu;
}

/*  Menu de Conta Departamento que aparece somente quando estiver dentro de uma solução */

export function MenuContaDepartamento(nomeDepartamento = reactLocalStorage.getObject('contaSelecionada').apelido) {
    const menu = [
        {
            title: nomeDepartamento,
            links: [
                // { label: "Tarifas", to: "/tarifas-conta-departamento" }, // Ver Jira FXD-826
                { label: 'Favorecidos', to: '/minha-conta/favorecido' }
            ]
        }
    ];
    return menu;
}

/*  Menu que será apresentado sempre a um titular da conta ou que tem permissões de ADM na PJ */

export function MenuContaDigital(nomeContaDigital = reactLocalStorage.getObject('contaSelecionada').apelido) {
    if (nomeContaDigital) {
        return [
            {
                title: nomeContaDigital,
                links: [
                    // { label: "Tarifas", to: "/tarifas-conta" }, // Ver Jira FXD-826
                    { label: 'Favorecidos', to: '/minha-conta/favorecido' }
                ]
            }
        ];
    }
    return [];
}

/*  Menu será sempre exibido em todos os menus acima listados */

export function MenuSair(handleAtendimento) {
    const menu = [
        {
            links: [
                { label: 'FAQ', external: 'https://contacomigo.bpp.com.br/hc/pt-br', target: '_blank' },
                { label: 'Atendimento', onClick: handleAtendimento },
                { label: 'Sair', to: '/sair' }
            ]
        }
    ];
    return menu;
}

/* Se futuramente precisar acrescentar mais opções em um Menu, exemplo, mais de um PJ ser exibida
function MenuPJ(pjComMaisDeUmaEmpresa) {
    const menu =
    {
        title: "Menu PJ",
        links: [
            { label: "Meu Cadastro", to: "/contact" },
            { label: "Meus Termos de Uso", to: "/contact" },
            { label: "Meu Pacote de Produtos", to: "/contact" },
        ]
    }

    if (pjComMaisDeUmaEmpresa) {
        menu.links.append({ label: "Outras empresa", to: "/empresas" })
    }

    return menu;
} */
