import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from 'action/pedidos/pedidos-action';
import * as anexoContratualActions from 'action/anexo-contratual/anexo-contratual-action';
import { CONTA_SELECIONADA, LABEL_COMUNS, TIPO_DE_MOVIMENTACAO } from 'componentes/util/contantes-comuns/constantes-comuns';
import ContaSelecionada from 'componentes/contas/conta-selecionada';
import LoadingFenix from 'componentes/loading-fenix/loading-fenix';
import { FORMATO_DE_DATA_BR } from 'componentes/util/data/dataUtil';
import { ESTILO } from 'componentes/util/estilo_para_componentes/estiloUtil';
import { Button } from 'react-bootstrap';
import If from 'componentes/componentes-uteis/if/if';
import Grid from 'componentes/componentes-uteis/grid/grid';
import { formatarCampoValor } from 'componentes/util/extrato/extrato-util';

class ConsultaDePedidosBPPConsumer extends Component {

    componentDidMount = () => {

        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.limparFiltroPedidos();
        this.props.setContaSelecionada(contaSelecionada);
        this.props.getOperacoes();
        if (this.props.consultaPedidoBppConsumer.listaDePedidos.length <= 0) {
            this.props.getPedidos(contaSelecionada.id, '', '', '', '', '', '', '');
        }

    }

    render = () => {
        return (
            <div>
                <div>
                    <Grid cols="12 12 12 12" className="alinhamento-centro">
                        <If test={this.props.consultaPedidoBppConsumer.exibirLoading}>
                            <LoadingFenix />
                        </If>
                    </Grid>
                    <div>
                        {this.props.consultaPedidoBppConsumer.contaSelecionada && <ContaSelecionada
                            contaSelecionada={this.props.consultaPedidoBppConsumer.contaSelecionada}
                            mostrarFiltro
                            mostrarFiltroDeDatas
                            exibirFiltroDeStatus
                            exibirFiltroDeValor

                            dataInicialLabel={LABEL_COMUNS.DATA_INICIAL}
                            dataInicialPlaceHolder={FORMATO_DE_DATA_BR}
                            dataInicialValue={this.props.consultaPedidoBppConsumer.dataInicial}
                            dataIncialChange={this.props.setConsultaDePedidosDataInicial}
                            dataInicialErro={this.props.consultaPedidoBppConsumer.dataInicialErro}

                            dataFinalLabel={LABEL_COMUNS.DATA_FINAL}
                            dataFinalPlaceHolder={FORMATO_DE_DATA_BR}
                            dataFinalValue={this.props.consultaPedidoBppConsumer.dataFinal}
                            dataFinalChange={this.props.setConsultaDePedidosDataFinal}
                            dataFinalErro={this.props.consultaPedidoBppConsumer.dataFinalErro}

                            valorDe={this.props.consultaPedidoBppConsumer.valorDe}
                            setValorDe={(e) => this.props.setFiltroConsultaDePedidosPorValor({ data: { valorDe: e }, erro: { valorDeErro: '' } })}
                            valorAte={this.props.consultaPedidoBppConsumer.valorAte}
                            setValorAte={(e) => this.props.setFiltroConsultaDePedidosPorValor({ data: { valorAte: e }, erro: { valorAteErro: '' } })}
                            listaDeStatus={this.props.consultaPedidoBppConsumer.listaDeStatus}
                            setStatus={this.props.setConsultaDePedidosStatus}
                            statusSelecionado={this.props.consultaPedidoBppConsumer.statusSelecionado}
                            statusErro={this.props.consultaPedidoBppConsumer.statusErro}
                            exibirBotaoLimpar
                            limparFiltro={this.props.limparFiltroPedidos}
                            filtrar={() => this.props.getPedidos(this.props.consultaPedidoBppConsumer.contaSelecionada.id,
                                '',
                                this.props.consultaPedidoBppConsumer.dataInicial,
                                this.props.consultaPedidoBppConsumer.dataFinal,
                                this.props.consultaPedidoBppConsumer.anexoContratualSelecionadoId,
                                this.props.consultaPedidoBppConsumer.valorDe,
                                this.props.consultaPedidoBppConsumer.valorAte,
                                this.props.consultaPedidoBppConsumer.operacaoSelecionadaId,
                                this.props.consultaPedidoBppConsumer.statusSelecionado)} />}

                        <div className="row form-group">
                            <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                                <div className="panel panel-default">
                                    <div className={ESTILO.COR_DE_FUNDO.TITULO.PADRAO}>{LABEL_COMUNS.PEDIDOS.CONSULTA_DE_PEDIDOS}</div>
                                    <div className="panel-body">
                                        <If test={this.props.consultaPedidoBppConsumer.listaDePedidos.length > 0}>
                                            <div className="list-group">
                                                <li className="list-group-item fundo-cinza-claro">
                                                    <div className="row">
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                            <b><span className="titulo-tabelas">Data/Hora</span></b>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                            <b><span className="titulo-tabelas">Lançamento Financeiro</span></b>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                            <b><span className="titulo-tabelas">Quantidade</span></b>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                            <b><span className="titulo-tabelas">Status</span></b>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                            <b><span className="titulo-tabelas">Valor</span></b>
                                                        </div>
                                                    </div>
                                                </li>
                                                {this.props.consultaPedidoBppConsumer.listaDePedidos.map((linhaConsulta, idx) => {
                                                    return (
                                                        <li key={idx} className="list-group-item">
                                                            <div className="row">
                                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                                    <span className="texto-extrato-detalhe">{linhaConsulta.dataCriacao}</span>
                                                                </div>
                                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-esquerda">
                                                                    <span className="texto-consulta-pedido">{linhaConsulta.lancamentoFinanceiroExibicao}</span>
                                                                </div>
                                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                                    <span className="texto-extrato-detalhe">{linhaConsulta.quantidade}</span>
                                                                </div>
                                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-centro">
                                                                    <span className="texto-extrato-detalhe">{linhaConsulta.statusExibicao}</span>
                                                                </div>
                                                                <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2 alinhamento-direita">
                                                                    <span className="texto-extrato-detalhe">{formatarCampoValor(TIPO_DE_MOVIMENTACAO.DEBITO, linhaConsulta.valor)}</span>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    );
                                                }
                                                )}
                                                <If test={this.props.consultaPedidoBppConsumer.exbirMaisRegistros}>
                                                    <Button type="button" bsStyle="default"
                                                        className="btn btn-default btn-block fonte-tamanho-11"
                                                        onClick={() => this.props.getPedidos(this.props.consultaPedidoBppConsumer.contaSelecionada.id, '', '',
                                                            '', '', '', '', '', this.props.consultaPedidoBppConsumer.ultimaChave, this.props.consultaPedidoBppConsumer.listaDePedidos)}>
                                                        {LABEL_COMUNS.PEDIDOS.VISUALIZAR_MAIS_PEDIDOS}
                                                    </Button>
                                                    <br />
                                                </If>
                                            </div>
                                        </If>
                                        <If test={this.props.consultaPedidoBppConsumer.listaDePedidos.length <= 0}>
                                            <span>{LABEL_COMUNS.PEDIDOS.NAO_HA_ITENS_CONSULTA_PEDIDOS}</span>
                                        </If>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <Grid cols="11 11 11 11" className="alinhamento-direita">
                            <Button type="button" bsStyle={ESTILO.COR_DE_FUNDO.BOTAO.PADRAO} onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>
                            <br /><br />
                        </Grid>
                        <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1" />
                    </div>
                </div>
            </div>
        );
    }

}





const mapStateToProps = (state) => ({
    consultaPedidoBppConsumer: state.PedidosReducer,
    anexoContratual: state.AnexoContratualReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, ...anexoContratualActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ConsultaDePedidosBPPConsumer);
