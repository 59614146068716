import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import InputMask from 'react-input-mask';
import { Button, ControlLabel, Dropdown, FormGroup, MenuItem } from 'react-bootstrap';
import moment from 'moment';
import { aprovarOuReprovarLote, getListaDeLoteArquivos } from '../../../../../servicos/transferencia-em-lote/transferencia-em-lote-service';
import * as actions from '../../../../contas/dashboard/agendamento-e-aprovacoes-dashboard-conta/agendamento-e-aprovacoes-action';
import { LABEL_COMUNS, MASCARA_CAMPOS, MENSAGENS, PRODUTOS, STATUS, OPERACOES_TRANSFERENCIA_EM_LOTE } from '../../../../util/contantes-comuns/constantes-comuns';
import { outorgaParametroOperacao, podeAprovar, podeConsultar, podeCriar } from '../../../../../reducer/outorga/outorga-operacao-reducer';
import { ROTAS_CAMINHOS } from '../../../../rotas-constantes';
import FiltroGenerico from '../../../../componentes-uteis/filtro-generico/filtro-generico';
import FooterBotoesModalComponent from '../../../../componentes-uteis/modal-component/footer-botoes-modal-component';
import Grid from '../../../../componentes-uteis/grid/grid';
import Loading from '../../../../loading-fenix/loading';
import LoadingFenix from '../../../../loading-fenix/loading-fenix';
import ModalComponent from '../../../../componentes-uteis/modal-component/modal-component';
import ShowLineError from '../../../../comuns/showLineError/show-line-error';
import { STATUS_VALIDACAO_COMPONENTES } from '../../../../util/estilo_para_componentes/estiloUtil';
import { formatarCampoValor } from '../../../../util/extrato/extrato-util';

class AgendamentoEAprovacoesDashboardBppCorp extends Component {
    constructor() {
        super();

        this.state = {
            exibirLoading: false,
            isListaCargaLoteCartaoLoading: false,
            abrirCollapse: false,
            listaCargaLoteCartao: [],
            cargaLoteCartao: {},
            mensagemFinalizacao: '',
            mensagemCorrecao: '',
            exibirModalConfirmacaoAprovacao: false,
            exibirModalConfirmacaoReprovacao: false,
            exibirModalFinalizacao: false,
            exibirModalCorrecao: false,
            dataDeInclusaoDe: '',
            dataDeInclusaoDeErro: '',
            dataDeInclusaoAte: '',
            dataDeInclusaoAteErro: '',
            operacaoSelecionadaId: '',
            statusSelecionado: '',
            idArquivoSequencialPorPessoaId: ''
        };

        this.initialState = this.state;

        this.contaSelecionada = JSON.parse(localStorage.getItem('contaDepartamentoSelecionada')) || JSON.parse(localStorage.getItem('contaSelecionada'));

        this.onClickAprovar = this.onClickAprovar.bind(this);
        this.onAprovar = this.onAprovar.bind(this);
        this.onClickReprovar = this.onClickReprovar.bind(this);
        this.onReprovar = this.onReprovar.bind(this);
        this.limparFiltro = this.limparFiltro.bind(this);
    }

    componentDidMount() {
        this.buscaCargaLoteCartao();
        const { history } = this.props;

        if (history.location.state &&
            history.location.state.cargaLoteCartao &&
            history.location.state.acao &&
            history.location.state.acao === 'corrigir') {
            this.setState({
                exibirModalCorrecao: true,
                mensagemCorrecao: 'Faça upload do arquivo corrigido.'
            });
            this.props.setArquivoEnviado(history.location.state.cargaLoteCartao);
        }
    }

    async buscaCargaLoteCartao() {
        this.setState({ isListaCargaLoteCartaoLoading: true });

        const { id, pessoaTitularId } = this.contaSelecionada;
        const {
            dataDeInclusaoDe,
            dataDeInclusaoAte,
            idArquivoSequencialPorPessoaId,
            operacaoSelecionadaId,
            statusSelecionado
        } = this.state;
        const params = {
            contaId: id,
            produto: PRODUTOS.corp,
            pessoaId: pessoaTitularId,
            dataDeInclusaoDe: dataDeInclusaoDe ? moment(dataDeInclusaoDe, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            dataDeInclusaoAte: dataDeInclusaoAte ? moment(dataDeInclusaoAte, 'DD/MM/YYYY').format('YYYY-MM-DD') : '',
            status: statusSelecionado || '',
            idArquivoSequencialPorPessoaId,
            operacao: operacaoSelecionadaId || '',
            tamanhoPagina: 100
        };
        const { data, error } = await getListaDeLoteArquivos(params);

        if (!error && data) {
            this.setState({ listaCargaLoteCartao: data.resultado, isListaCargaLoteCartaoLoading: false });
        }
    }

    montaCorpoModalToken() {
        return (
            <div>
                <FormGroup validationState={this.props.AgendamentoEAprovacoesDashboardBppCorp.solicitacaoTokenErro || this.state.tokenIdErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                    <ControlLabel className="label-token">Token</ControlLabel>
                    <InputMask
                        id="token"
                        className="form-control mensagens-erro"
                        placeholder="Digite o TOKEN"
                        maxLength={6}
                        value={!this.state.tokenId ? '' : this.state.tokenId}
                        onChange={(e) => this.setState({ tokenId: e.target.value })}
                        mask={MASCARA_CAMPOS.TOKEN}
                        maskChar={null}
                    />
                    <ShowLineError error={this.props.AgendamentoEAprovacoesDashboardBppCorp.solicitacaoTokenErro || this.state.tokenIdErro} />
                </FormGroup>
                <a style={{ cursor: 'pointer' }} onClick={() => this.verificaNecessitaOtp()}>{LABEL_COMUNS.REENVIAR_TOKEN}</a><br />
            </div>
        );
    }

    montaRodapeModalToken() {
        return (
            <FooterBotoesModalComponent
                parentClassName="row" cols="12 12 12 12" chieldClassName="alinhamento-direita"
                btn1Label={LABEL_COMUNS.CANCELAR} btn2Label={LABEL_COMUNS.CONFIRMAR}
                btn1OnClick={() => {
                    this.props.setExibirModalToken(false);
                    this.setState({ tokenId: '' });
                }}
                btn2OnClick={() => {
                    if (this.state.tokenId && this.state.tokenId.length === 6) {
                        const necessitaToken = this.props.outorgaPedidoEmbossingCartao.aprovacao;
                        const outorgaPodeAprovar = this.props.outorgaPerfils.podeAprovar;
                        this.props.efetivaTransacao(this.state.operacao.id, this.state.operacao.op, this.state.tokenId);
                    } else {
                        this.setState({ tokenIdErro: MENSAGENS.VALIDACAO_DE_CAMPOS.TOKEN_INVALIDO });
                    }
                }} />
        );
    }

    onClickCorrigir(cargaLoteCartao) {
        const { history } = this.props;

        if (history.location.pathname !== ROTAS_CAMINHOS.CARGA_LOTE_CARTAO) {
            history.push({
                pathname: ROTAS_CAMINHOS.CARGA_LOTE_CARTAO,
                state: { acao: 'corrigir', cargaLoteCartao }
            });
        } else {
            this.props.setArquivoEnviado(cargaLoteCartao);
            this.setState({
                exibirModalCorrecao: true,
                mensagemCorrecao: 'Faça upload do arquivo corrigido.'
            });
        }
    }

    onClickDetalhes(cargaLoteCartao) {
        const { history } = this.props;
        history.push({
            pathname: ROTAS_CAMINHOS.DETALHES_BPP_CORP,
            state: { cargaLoteCartao }
        });
    }

    onClickAprovar(cargaLoteCartao) {
        const { anexoContratualId } = this.contaSelecionada;
        const { exibirModalConfirmacaoAprovacao } = this.state;

        this.setState({
            exibirModalConfirmacaoAprovacao: !exibirModalConfirmacaoAprovacao,
            cargaLoteCartao,
            anexoContratualId
        });
    }

    exibeMensagemMigracao(data) {
        if (data.entity && data.entity.codigo && data.entity.codigo === LABEL_COMUNS.CODIGO_ERRO_MIGRACAO_CORE) {
            return data.entity.erros[0].mensagem;
        }
        else return data.mensagem;
    }

    onAprovar() {
        this.setState({ exibirLoading: true });

        const { anexoContratualId } = this.contaSelecionada;
        const { cargaLoteCartao } = this.state;

        aprovarOuReprovarLote({
            id: cargaLoteCartao.id,
            anexoContratualOriginal: anexoContratualId,
            transacao: 'aprovar'
        }).then(({ data }) => {
            this.setState({
                exibirLoading: false,
                exibirModalConfirmacaoAprovacao: false,
                exibirModalCorrecao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: this.exibeMensagemMigracao(data),
                mensagemCorrecao: ''
            });
        }).catch((err) => {
            this.setState({
                exibirLoading: false,
                exibirModalConfirmacaoAprovacao: false,
                exibirModalCorrecao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: 'ERRO',
                mensagemCorrecao: ''
            });
        });
    }

    onClickReprovar(cargaLoteCartao) {
        const { anexoContratualId } = this.contaSelecionada;
        const { exibirModalConfirmacaoReprovacao } = this.state;

        this.setState({
            exibirModalConfirmacaoReprovacao: !exibirModalConfirmacaoReprovacao,
            cargaLoteCartao,
            anexoContratualId
        });
    }

    onReprovar() {
        this.setState({ exibirLoading: true });

        const { anexoContratualId } = this.contaSelecionada;
        const { cargaLoteCartao } = this.state;

        aprovarOuReprovarLote({
            id: cargaLoteCartao.id,
            anexoContratualOriginal: anexoContratualId,
            transacao: 'reprovar'
        }).then(({ data }) => {
            this.setState({
                exibirLoading: false,
                exibirModalConfirmacaoReprovacao: false,
                exibirModalCorrecao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: data.mensagem,
                mensagemCorrecao: ''
            });
        }).catch(() => {
            this.setState({
                exibirLoading: false,
                exibirModalCorrecao: false,
                exibirModalConfirmacaoReprovacao: false,
                exibirModalFinalizacao: true,
                mensagemFinalizacao: 'CANCELAR',
                mensagemCorrecao: ''
            });
        });
    }

    setPropsCampos = (e, data) => {
        let valorDoCampo = e !== null && e.value || e !== null && e.target !== null && e.target.value;
        this.setState({
            [data]: valorDoCampo,
            [data + 'Erro']: '',
        });
    }

    limparFiltro() {
        this.setState({
            dataDeInclusaoDe: '',
            dataDeInclusaoAte: '',
            operacaoSelecionadaId: '',
            statusSelecionado: '',
            idArquivoSequencialPorPessoaId: ''
        });
    }

    render() {
        const {
            abrirCollapse,
            listaCargaLoteCartao,
            exibirModalConfirmacaoAprovacao,
            exibirModalConfirmacaoReprovacao,
            exibirModalFinalizacao,
            exibirModalCorrecao,
            mensagemCorrecao,
            mensagemFinalizacao,
            exibirLoading,
            dataDeInclusaoDe,
            dataDeInclusaoDeErro,
            dataDeInclusaoAte,
            dataDeInclusaoAteErro,
            operacaoSelecionadaId,
            statusSelecionado,
            idArquivoSequencialPorPessoaId,
            isListaCargaLoteCartaoLoading
        } = this.state;



        let acoes = {
            APROVAR: { label: 'Aprovar', acao: (cargaLoteCartao) => this.onClickAprovar(cargaLoteCartao) },
            REPROVAR: { label: 'Reprovar', acao: (cargaLoteCartao) => this.onClickReprovar(cargaLoteCartao) },
            DETALHES: { label: 'Detalhes', acao: (cargaLoteCartao) => this.onClickDetalhes(cargaLoteCartao) },
            CORRIGIR: { label: 'Corrigir', acao: (cargaLoteCartao) => this.onClickCorrigir(cargaLoteCartao) }
        };

        const perfilPodeAprovar = this.props.outorgaPerfils.podeAprovar;

        const menuOpcoesAcoes = [
            { status: 'pendenteCorrecao', opcoes: [perfilPodeAprovar && acoes.CORRIGIR, perfilPodeAprovar && acoes.REPROVAR, acoes.DETALHES].filter(elem => elem) },
            { status: 'pendenteAprovacao', opcoes: [perfilPodeAprovar && acoes.APROVAR, perfilPodeAprovar && acoes.REPROVAR, acoes.DETALHES].filter(elem => elem) },
            { status: 'emValidacao', opcoes: [acoes.DETALHES] },
            { status: 'reprovado', opcoes: [acoes.DETALHES] },
            { status: 'cancelado', opcoes: [acoes.DETALHES] },
            { status: 'aprovado', opcoes: [acoes.DETALHES] }
        ];

        return (
            <div>
                {exibirLoading && (
                    <div className="alinhamento-centro">
                        <LoadingFenix />
                    </div>
                )}
                <div className="panel panel-default">
                    <div className="panel-heading fundo-azul-claro" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', height: 40 }}>
                        <span>{this.props.titulo}</span>
                        <div>
                            <Button style={{ color: 'white', background: 'transparent', border: 'none' }} onClick={() => this.buscaCargaLoteCartao()} >
                                <i className="fa fa-refresh" />
                            </Button>
                            <Button style={{ color: 'white', background: 'transparent', border: 'none' }} onClick={() => this.setState({ abrirCollapse: !abrirCollapse })}>
                                <i className="glyphicon glyphicon-filter" />
                            </Button>
                            <Dropdown id="menuOpcoes" pullRight>
                                <Dropdown.Toggle noCaret style={{ color: 'white', background: 'transparent', border: 'none' }}>
                                    <i className="glyphicon glyphicon-option-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu >
                                    <li>
                                        <Link className="link_expandir" to={ROTAS_CAMINHOS.AGENDAMENTO_APROVACOES_BPP_CORP}>
                                            {LABEL_COMUNS.EXPANDIR}
                                        </Link>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                    <FiltroGenerico
                        abrirCollapse={abrirCollapse}
                        mostrarFiltroDeDatas={true}
                        mostrarFiltroDeidNumerico={true}
                        mostrarFiltroDeOperacao={true}
                        mostrarFiltroDeStatus={true}
                        labelDataInicial="Data"
                        dataInicial={dataDeInclusaoDe}
                        setDataInicial={(e) => this.setPropsCampos(e, 'dataDeInclusaoDe')}
                        dataInicialErro={dataDeInclusaoDeErro}
                        dataFinal={dataDeInclusaoAte}
                        setDataFinal={(e) => this.setPropsCampos(e, 'dataDeInclusaoAte')}
                        dataFinalErro={dataDeInclusaoAteErro}
                        operacaoSelecionadaId={operacaoSelecionadaId}
                        setOperacao={(e) => this.setPropsCampos(e, 'operacaoSelecionadaId')}
                        listaDeOperacoes={Object.keys(OPERACOES_TRANSFERENCIA_EM_LOTE).map(key => (OPERACOES_TRANSFERENCIA_EM_LOTE[key]))}
                        listaDeStatus={Object.keys(STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS).map(key => (STATUS.TRANSFERENCIA_EM_LOTE_DE_ARQUIVOS[key]))}
                        setStatus={(e) => this.setPropsCampos(e, 'statusSelecionado')}
                        statusSelecionado={statusSelecionado}
                        idNumerico={idArquivoSequencialPorPessoaId}
                        idNumericoPlaceHolder="ID"
                        setIdNumerico={(e) => this.setPropsCampos(e, 'idArquivoSequencialPorPessoaId')}
                        exibirBotaoLimpar={true}
                        limparFiltro={this.limparFiltro}
                        buscar={() => this.buscaCargaLoteCartao()}
                    />
                    <div className="panel-body" style={{ height: '421px', overflowY: 'scroll' }}>
                        {listaCargaLoteCartao.length > 0 && !isListaCargaLoteCartaoLoading && (
                            <div className="row">
                                <Grid cols="12 12 12 12">
                                    <Grid cols="1 1 1 1">
                                        <strong><small>ID</small></strong>
                                    </Grid>
                                    <Grid cols="2 2 2 2">
                                        <strong><small>Operação</small></strong>
                                    </Grid>
                                    <Grid cols="2 2 2 2">
                                        <strong><small>Valor</small></strong>
                                    </Grid>
                                    <Grid cols="3 3 3 3">
                                        <strong><small>Data de Inclusão</small></strong>
                                    </Grid>
                                    <Grid cols="3 3 3 3">
                                        <strong><small>Status</small></strong>
                                    </Grid>
                                    <Grid cols="1 1 1 1" >
                                        <strong><small>Ações</small></strong>
                                    </Grid>
                                </Grid>
                            </div>
                        )}
                        {listaCargaLoteCartao.length > 0 && !isListaCargaLoteCartaoLoading &&
                            listaCargaLoteCartao.map((item) => (
                                <div className="row" key={item.id}>
                                    <Grid cols="12 12 12 12">
                                        <Grid cols="1 1 1 1">
                                            <span style={{ fontSize: 11 }}>
                                                {item.idArquivoSequencialPorPessoaId}
                                            </span>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <span style={{ fontSize: 11 }}>
                                                {Object.values(OPERACOES_TRANSFERENCIA_EM_LOTE).find(({ value }) => value === item.operacao).label}
                                            </span>
                                        </Grid>
                                        <Grid cols="2 2 2 2">
                                            <span style={{ fontSize: 11 }}>
                                                {formatarCampoValor(item.tipo, item.valorProcessadoLote, item.moeda)}
                                            </span>
                                        </Grid>
                                        <Grid cols="3 3 3 3">
                                            <span style={{ fontSize: 11 }}>
                                                {moment(item.dataCriacao, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                                            </span>
                                        </Grid>
                                        <Grid cols="3 3 3 3">
                                            <span style={{ fontSize: 11 }}>
                                                {item.statusExibicao}
                                            </span>
                                        </Grid>
                                        <Grid cols="1 1 1 1">
                                            <Dropdown id="menuOpcoesAcoes" pullRight>
                                                <Dropdown.Toggle
                                                    noCaret
                                                    style={{ border: 'none' }}>
                                                    <i className="glyphicon glyphicon-option-horizontal" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    {
                                                        menuOpcoesAcoes.some(({ status }) => status === item.status) &&
                                                        menuOpcoesAcoes.find(({ status }) => status === item.status).opcoes.map((opcao) => (
                                                            <MenuItem key={opcao.label} onClick={() => opcao.acao(item)}>
                                                                {opcao.label}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </Grid>
                                    </Grid>
                                </div>
                            ))}
                        {!listaCargaLoteCartao.length && !isListaCargaLoteCartaoLoading && (
                            <div className="alinhamento-centro">
                                <span>Nenhum agendamento encontrado</span>
                            </div>
                        )}
                        {isListaCargaLoteCartaoLoading && (
                            <div className="alinhamento-centro">
                                <Loading />
                            </div>
                        )}
                    </div>
                </div>
                <ModalComponent
                    exibirModal={exibirModalFinalizacao}
                    header="Agendamentos e Aprovações"
                    body={mensagemFinalizacao}
                    footer={
                        <FooterBotoesModalComponent
                            cols="12 12 12 12"
                            btn2Label="Ok"
                            btn2OnClick={() => {
                                this.setState(this.initialState);
                                this.buscaCargaLoteCartao();
                            }}
                        />
                    }
                />
                <ModalComponent
                    exibirModal={exibirModalConfirmacaoAprovacao}
                    header="Agendamentos e Aprovações"
                    body="Confirma a aprovação do arquivo?"
                    footer={
                        <FooterBotoesModalComponent
                            cols="12 12 12 12"
                            btn1Label="Cancelar"
                            btn2Label="Confirmar"
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoAprovacao: !exibirModalConfirmacaoAprovacao })}
                            btn2OnClick={this.onAprovar}
                        />
                    }
                />
                <ModalComponent
                    exibirModal={exibirModalConfirmacaoReprovacao}
                    header="Agendamentos e Aprovações"
                    body="Confirma a reprovação do arquivo?"
                    footer={
                        <FooterBotoesModalComponent
                            cols="12 12 12 12"
                            btn1Label="Cancelar"
                            btn2Label="Confirmar"
                            btn1OnClick={() => this.setState({ exibirModalConfirmacaoReprovacao: !exibirModalConfirmacaoReprovacao })}
                            btn2OnClick={this.onReprovar}
                        />
                    }
                />
                <ModalComponent
                    exibirModal={exibirModalCorrecao}
                    header="Corrigir arquivo"
                    body={
                        <div>
                            <p>{mensagemCorrecao}</p>
                            <input
                                type="file"
                                id="cargaLoteCartaoArquivoCorrecao"
                                accept=".csv"
                                multiple={false}
                                ref={ref => this.arquivoCorrecaoRef = ref}
                                onChange={this.props.onAlterarArquivo}
                            />

                        </div>
                    }
                    footer={
                        <FooterBotoesModalComponent
                            btn1Label="Cancelar"
                            btn1OnClick={() => {
                                this.setState({ exibirModalCorrecao: false, mensagemCorrecao: '' });
                                this.props.history.replace(ROTAS_CAMINHOS.CARGA_LOTE_CARTAO, undefined);
                                this.props.onLimpar();
                            }}
                            btn2Label="Limpar"
                            btn2OnClick={() => {
                                this.props.onLimpar();
                                this.arquivoCorrecaoRef.value = '';
                            }}
                            btn3Label="Importar"
                            btn3OnClick={() => {
                                this.setState({ exibirModalCorrecao: false, mensagemCorrecao: '' });
                                this.props.onImportar();
                                this.props.history.replace(ROTAS_CAMINHOS.CARGA_LOTE_CARTAO, undefined);
                            }}
                            btn3Disabled={!this.props.arquivoSelecionado}
                        />
                    }
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    AgendamentoEAprovacoesDashboardBppCorp: state.AgendamentoReducer,
    outorgaPerfils: {
        podeConsultar: podeConsultar(state.OutorgaOperacaoReducer),
        podeCriar: podeCriar(state.OutorgaOperacaoReducer),
        podeAprovar: podeAprovar(state.OutorgaOperacaoReducer),
    },
    outorgaPedidoEmbossingCartao: outorgaParametroOperacao(state.OutorgaOperacaoReducer, 'pedidoEmbossingCartao')
});

const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AgendamentoEAprovacoesDashboardBppCorp);
