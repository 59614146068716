// import Axios from 'axios';
import Controle from './conta-selecionada-controlador';
var dispatch = null;
export default class ContaSelecionadaAction {
    setInicializarDispatch(dispatch) {
        this.dispatch = dispatch;
    }
    setExibirEmBreveAction(value) {
        this.dispatch({
            type: Controle.exibirEmbreveControl(),
            payload: value
        });
    }
}
