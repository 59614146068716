import './pedido-cartao-virtual.css';
import React, { Component } from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { reactLocalStorage } from 'reactjs-localstorage';
import InputMask from 'react-input-mask';
import ContaSelecionada from '../../contas/conta-selecionada';
import { Button, FormControl, FormGroup, InputGroup } from 'react-bootstrap';
import { CAMPOS, CONTA_SELECIONADA, DDI, DESCRICAO_CONTA, LABEL_COMUNS, MASCARA_CAMPOS, TIPOS_DE_OPERACAO, TIPO_DE_DOCUMENTO, } from '../../util/contantes-comuns/constantes-comuns';
import { STATUS_VALIDACAO_COMPONENTES } from '../../util/estilo_para_componentes/estiloUtil';
import * as actions from './pedido-cartao-virtual-action';
import * as anexoContratualActions from '../../../action/anexo-contratual/anexo-contratual-action';

import { ROTAS_CAMINHOS } from '../../rotas-constantes';
import { validarFormPedidoCartaoVirtual } from '../../../servicos/pedidos/pedido-service';

const FEL_ID = 912;
class PedidoCartaoVirtual extends Component {
    constructor(props) {
        super(props);
        this.state = {
            contaSelecionada: {}, cartao: props.cartaoVirtual.form || {
                nomeImpresso: '', celularPortador: '',
                emailPortador: '', dataNascimentoPortador: '', numeroDocumentoPortador: ''
            },
            existeErros: undefined
        };
    }
    componentDidMount() {
        const contaSelecionada = CONTA_SELECIONADA.OBJETO();
        this.props.getAnexoPedidoVirtual(contaSelecionada.pessoaTitularId);
        this.setState({ contaSelecionada });
        this.props.getImagem(FEL_ID);
    }

    componentWillReceiveProps(nextProps) {
    }

    setPropsCartao(e, data) {
        this.setState({
            cartao: {
                ...this.state.cartao,
                [data]: e.target.value,
            },
            [data + 'Erro']: '',
        });
    }

    render() {
        if (this.state.existeErros === false) {
            let dadosParaSerMontado = { ...this.state, anexoContratual: this.props.anexo.anexoContratualCartaoVirtual, comboRecuperado: this.props.cartaoVirtual.comboRecuperado };
            this.props.saveFormPedidoCartaoVirtual(this.state.cartao);
            this.props.history.push({ pathname: ROTAS_CAMINHOS.PEDIDO_CARTAO_VIRTUAL_RESUMO, state: dadosParaSerMontado });
        }
        return (
            <div>
                {this.state.contaSelecionada && <ContaSelecionada contaSelecionada={this.state.contaSelecionada} />}

                < div className="row" >
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.ANEXO} </b></div>
                            <div className="panel-body espacamento_div">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-3 col-sm-3 col-md-3 col-lg-3 espacamento-anexo-bpp">
                                            <FormGroup>
                                                <FormControl className="mensagens-erro" id="digito" type="text"
                                                    id="anexoId"
                                                    disabled="true"
                                                    placeholder={LABEL_COMUNS.BPP_VIRTUAL}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-xs-9 col-sm-9 col-md-9 col-lg-9"></div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div >
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11">
                        <div className="panel panel-default">
                            <div className="panel-heading fundo-cinza-claro"><b> {LABEL_COMUNS.INFORMACOES} </b></div>
                            <div className="panel-body">
                                <div className="list-group">
                                    <form className="editForm" id="myForm">
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento-campos">
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento-anexo-bpp">
                                                {LABEL_COMUNS.NOME_COMPLETO}*
                                                <FormGroup validationState={this.state.nomeImpressoErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <input className="mensagens-erro" id="nome" type="text"
                                                        style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                        className="form-control mensagens-erro" placeholder="Nome Completo"
                                                        onChange={(e) => this.setPropsCartao(e, CAMPOS.NOME_IMPRESSO)}
                                                        value={this.state.cartao.nomeImpresso} />
                                                    {this.state.nomeImpressoErro ? <span className="mensagens-erro-span" role="alert">{this.state.nomeImpressoErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                <strong>{LABEL_COMUNS.CPF}*</strong>
                                                <FormGroup validationState={this.state.numeroDocumentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                        className="form-control mensagens-erro" mask={MASCARA_CAMPOS.CPF} maskChar=""
                                                        placeholder={LABEL_COMUNS.CPF}
                                                        onChange={(e) => this.setPropsCartao(e, CAMPOS.NUMERO_DOCUMENTO_PORTADOR)}
                                                        value={this.state.cartao.numeroDocumentoPortador}
                                                    />
                                                    {this.state.numeroDocumentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.numeroDocumentoPortadorErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                <strong>{LABEL_COMUNS.DATA_NASCIMENTO}*</strong>
                                                <FormGroup validationState={this.state.dataNascimentoPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <InputMask style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                        className="form-control mensagens-erro" mask={MASCARA_CAMPOS.DATA} maskChar=""
                                                        placeholder="Data de Nascimento"
                                                        onChange={(e) => this.setPropsCartao(e, CAMPOS.DATA_NASCIMENTO_PORTADOR)}
                                                        value={this.state.cartao.dataNascimentoPortador}
                                                    />
                                                    {this.state.dataNascimentoPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.dataNascimentoPortadorErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4"></div>
                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 espacamento-campos">
                                            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 espacamento-anexo-bpp">
                                                <strong>{LABEL_COMUNS.EMAIL}</strong>

                                                <FormGroup validationState={this.state.emailPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <InputGroup>
                                                        <InputGroup.Addon>@</InputGroup.Addon>
                                                        <FormControl className="mensagens-erro" id="digito" type="text"
                                                            style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                            className="form-control mensagens-erro" placeholder="Email"
                                                            onChange={(e) => this.setPropsCartao(e, CAMPOS.EMAIL_PORTADOR)}
                                                            value={this.state.cartao.emailPortador}
                                                        />
                                                    </InputGroup>
                                                    {this.state.emailPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.emailPortadorErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-md-2 col-lg-2">
                                                <strong>{LABEL_COMUNS.CELULAR}</strong>
                                                <FormGroup validationState={this.state.celularPortadorErro ? STATUS_VALIDACAO_COMPONENTES.ERRO : null}>
                                                    <InputMask
                                                        style={{ height: '32px', paddingTop: '8px', paddingLeft: '10px' }}
                                                        className="form-control mensagens-erro" mask={MASCARA_CAMPOS.MOBILE} maskChar=""
                                                        placeholder="Celular"
                                                        onChange={(e) => this.setPropsCartao(e, CAMPOS.CELULAR_PORTADOR)}
                                                        value={this.state.cartao.celularPortador}
                                                    />
                                                    {this.state.celularPortadorErro ? <span className="mensagens-erro-span" role="alert">{this.state.celularPortadorErro}</span> : null}
                                                </FormGroup>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div>
                <div className="row">
                    <div className="col-xs-11 col-sm-11 col-md-11 col-lg-11 alinhamento-direita">
                        <Button bsStyle="default" style={{ width: '90px', height: '30px', paddingTop: '4px', paddingLeft: '10px' }}
                            onClick={this.props.history.goBack}>{LABEL_COMUNS.VOLTAR}</Button>&nbsp;&nbsp;
                        <Button bsStyle="default">{LABEL_COMUNS.CANCELAR}</Button>&nbsp;&nbsp;
                        <Button bsStyle="primary" onClick={this.acessarResumo}>{LABEL_COMUNS.CONFIRMAR}</Button>
                        <br />
                    </div>
                    <div className="col-xs-1 col-sm-1 col-md-1 col-lg-1"></div>
                </div >
            </div >
        );
    }
    acessarResumo = () => {
        let erros = validarFormPedidoCartaoVirtual(this.state.cartao);
        if (Object.keys(erros).length > 0) {
            Object.keys(erros).forEach(key => {
                let value = erros[key];
                this.setState({ [key]: value, existeErros: true });
            });
        }
        else {
            this.setState({ existeErros: false });
        }
    }
}
const mapStateToProps = (state) => ({
    cartaoVirtual: state.PedidoCartaoVirtualReducer,
    anexo: state.AnexoContratualReducer
});

const mapDispatchToProps = dispatch => bindActionCreators({ ...actions, ...anexoContratualActions }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PedidoCartaoVirtual);
