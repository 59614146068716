export const CONSTANTES = {

    OBTER_DADOS_PF_SUCESSO: 'OBTER_DADOS_PF_SUCESSO',
    OBTER_DADOS_PF_ERRO: 'OBTER_DADOS_PF_ERRO',

    

    LABELS: {
        LISTA_DADOS_PF: 'Lista de Dados da Pessoa Física',
        ERRO_OBTER_DADOS: 'Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.',
        PESSOA_FISICA_ID: 'pessoaId',
        SENHA_NAO_CONFERE: 'As senhas novas não conferem.',
        SENHA_INVALIDA: 'Senha Inválida.'
    } 
};
export const STATUS_VALIDACAO_COMPONENTES = {
    AVISO: 'warning',
    ERRO: 'error',
    PERIGO: 'danger',
    SUCESSO: 'success'
};
