import { CNPJ, CPF } from 'cpf_cnpj';
import { CONTA_SELECIONADA, DDI, LABEL_COMUNS, PARCEIRO, TIPO_DE_EMAIL, TIPO_DE_PESSOA, TIPO_DE_TELEFONE, TIPO_DOCUMENTO, STATUS } from '../../componentes/util/contantes-comuns/constantes-comuns';
import { removeMascaraTelefoneCpfCnpj } from '../../componentes/util/formata-string';
import { fetchAlterarPrestacaoDeContas, fetchCadastrarPrestacaoDeContas, fetchgetPessoaById, fetchGetPrestacaoDeContas, fetchGetPrestacaoDeContasClassificacoes, fetchGetPrestacaoDeContasProjetos } from './pessoas-base';
import { uploadFile } from '../arquivo/arquivo-service';
import { fetchGetCardById } from '../cartoes/cartoes-base';
import { fetchGetExtrato } from '../extrato/extrato-base';
import PrestacaoContaViewModel from '../../componentes/prestacao-contas/gerenciar/prestacao-conta-view-model';
import ExtratoViewModel from '../../componentes/prestacao-contas/gerenciar/extrato-view-model';
import { ckeckDataIsFormated, formatarDataUS, formatarDataBR } from '../../componentes/util/data/dataUtil';

export const retornaDadosPostPessoa = (dadosPessoa) => {
    const { celular } = dadosPessoa;
    const dadosPessoasParaRetornar = {
        tipo: TIPO_DE_PESSOA.PESSOA_FISICA,
        parceiroId: PARCEIRO.BPP.value,
        origemPessoaId: PARCEIRO.BPP.value,
        pessoaFisica: {
            nomeCompleto: dadosPessoa.nome
        },
        documento: {
            numero: removeMascaraTelefoneCpfCnpj(dadosPessoa.numeroDocumento),
            tipo: dadosPessoa.tipoDocumento
        },
        email: [
            {
                tipo: TIPO_DE_EMAIL.PARTICULAR.value,
                email: dadosPessoa.email,
                principal: true
            }
        ],
        telefone: [
            {
                tipo: TIPO_DE_TELEFONE.CELULAR.value,
                numero: removeMascaraTelefoneCpfCnpj(celular).substr(2),
                ddd: removeMascaraTelefoneCpfCnpj(celular).substring(0, 2),
                ddi: DDI.BRASIL,
                principal: true
            }
        ]
    };
    return dadosPessoasParaRetornar;
};

export const retornaDocumentoPessoa = (pessoa) => {
    const { documento } = pessoa;
    return documento.filter(d => (d.tipo === LABEL_COMUNS.CPF || d.tipo === LABEL_COMUNS.CNPJ))[0];
};

export const retornaEmailPessoa = (pessoa) => {
    const { email } = pessoa;
    return email && email.filter(e => e.principal)[0];
};

export const retornaTelefoneCompletoPessoa = (pessoa) => {
    const { telefone } = pessoa;
    return telefone && telefone.filter(t => (t.principal))[0];
};

export const retornaNumeroTelefonePessoa = (telefone) => {
    return telefone && telefone.ddd.concat(telefone.numero);
};

export const retornaCabecalhoPessoa = (pessoaSelecionada) => {
    let titulo;
    let numeroDoDocumento;
    const { documento, nome, tipo, pessoaFisica, pessoaJuridica } = pessoaSelecionada;
    if (tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
        numeroDoDocumento = documento.find(d => (d.tipo === TIPO_DOCUMENTO.CNPJ.value)).numero;
        const { razaoSocial } = pessoaJuridica;
        titulo = LABEL_COMUNS.EMPRESA.concat(': '.concat(razaoSocial.concat(', '.concat(TIPO_DOCUMENTO.CNPJ.value.concat(': '.concat(CNPJ.format(numeroDoDocumento)))))));
    } else {
        numeroDoDocumento = documento.find(d => (d.tipo === TIPO_DOCUMENTO.CPF.value)).numero;
        const { nomeCompleto } = pessoaFisica;
        titulo = LABEL_COMUNS.NOME.concat(': '.concat(nomeCompleto.concat(', '.concat(TIPO_DOCUMENTO.CPF.value.concat(': '.concat(CPF.format(numeroDoDocumento)))))));
    }
    return titulo;
};

export const retornaNomePessoa = (pessoaSelecionada) => {
    const { tipo, nome, pessoaFisica, pessoaJuridica } = pessoaSelecionada;
    if (tipo === TIPO_DE_PESSOA.PESSOA_JURIDICA) {
        const { razaoSocial } = pessoaJuridica;
        return razaoSocial;
    }
    const { nomeCompleto } = pessoaFisica;
    return nomeCompleto;
};

export const retornaCabecalhoPessoaEdicaoDePoderes = (pessoaSelecionada) => {
    return pessoaSelecionada && pessoaSelecionada.nomeCompleto && pessoaSelecionada.nomeCompleto.concat(': '.concat(TIPO_DOCUMENTO.CPF.value.concat(': '.concat(CPF.format(pessoaSelecionada.documento.numero)))));
};

export const getPrestacaoDeContasProjetos = async (pessoaId) => {
    return await fetchGetPrestacaoDeContasProjetos(pessoaId)
        .then(({ data }) => data)
        .catch(error => error);
};

export const getPrestacaoDeContasClassificacoes = async (pessoaId) => {
    return await fetchGetPrestacaoDeContasClassificacoes(pessoaId)
        .then(({ data }) => data)
        .catch(error => error);
};

export const getPrestacaoDeContas = async (contaSelecionada, linhaDoExtratoSelecionada) => {
    const { pessoaTitularId } = contaSelecionada;
    const filtro = retornaFiltroPrestacaoDeContas(contaSelecionada, linhaDoExtratoSelecionada);
    return await fetchGetPrestacaoDeContas(pessoaTitularId, filtro)
        .then(resp => resp.data && resp.data.resultado && resp.data.resultado[0])
        .catch((err) => err);
};

export const salvarPrestacaoDeContas = async (dadosPrestacaoDeContas) => {
    const { imagens, pessoaId, prestacaoDeContasId } = dadosPrestacaoDeContas;
    const imagensDaPrestacaoDeContas = [];

    if (imagens.length) {
        await Promise.all(imagens.map(async imagem => {
            if (!imagem.existeImagemPrestacao) {
                imagensDaPrestacaoDeContas.push(await enviaImagemDaPrestacaoDeContas(imagem.file));
            } else {
                imagensDaPrestacaoDeContas.push(retornaImagemPrestacaoDeContasMontadaParaBackEnd(imagem));
            }
        }));
    }

    dadosPrestacaoDeContas.imagem = imagensDaPrestacaoDeContas || [];

    const dadosDaPrestacaoDeContas = montaJsonPrestacaoParaBackEnd(dadosPrestacaoDeContas);
    if (prestacaoDeContasId) {
        return await fetchAlterarPrestacaoDeContas(pessoaId, prestacaoDeContasId, dadosDaPrestacaoDeContas).catch(error => error);
    } else {
        return await fetchCadastrarPrestacaoDeContas(pessoaId, dadosDaPrestacaoDeContas).catch(error => error);
    }
};

export const getPessoaById = (pessoaId) => {
    return fetchgetPessoaById(pessoaId).catch(error => error);
};

export const getPrestacaoDeContasOrExtrato = async (queryParams) => {
    delete queryParams.totalElementos;
    delete queryParams.quantidadeTotalRegistros;

    if (ckeckDataIsFormated(queryParams.dataInicial)) {
        queryParams.dataInicial = formatarDataBR(queryParams.dataInicial);
    }

    if (ckeckDataIsFormated(queryParams.dataFinal)) {
        queryParams.dataFinal = formatarDataBR(queryParams.dataFinal);
    }

    const prestacoesDeContasRecuperadas = { ultimaChave: '', registros: [], quantidadeTotalRegistros: 0 };
    const contaSelecionada = CONTA_SELECIONADA.OBJETO();
    queryParams.contaPaiId = contaSelecionada.id;
    const { pessoaTitularId } = contaSelecionada;
    const { cartaoId, contaPaiId, dataInicial, dataFinal, status } = queryParams;

    if (status && status === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO) {
        const response = await fetchGetCardById(cartaoId)
            .then(({ data }) => ({ cartaoRecuperado: data }))
            .then(async ({ cartaoRecuperado }) => ({ responseExtrato: await fetchGetExtrato(cartaoRecuperado.contaId, dataInicial, dataFinal, null, 10000), cartaoRecuperado }))
            .then(({ cartaoRecuperado, responseExtrato }) => ({ cartaoRecuperado, linhasDoExtratoRecuperadas: responseExtrato.data.resultado, responseExtrato }))
            .catch(response => response);

        if (response.error) {
            return response;
        }

        const { cartaoRecuperado, linhasDoExtratoRecuperadas, responseExtrato } = response;

        prestacoesDeContasRecuperadas.ultimaChave = responseExtrato.data.ultimaChave;
        linhasDoExtratoRecuperadas.forEach(linhaExtrato => {
            linhaExtrato.lancamentos.filter(l => l.statusPrestacaoConta === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO).forEach(lancamento => {
                prestacoesDeContasRecuperadas.registros.push(new PrestacaoContaViewModel({
                    id: lancamento.bppMovimentoProcessadoraId,
                    cartaoId: cartaoRecuperado.cartaoId,
                    contaId: cartaoRecuperado.contaId,
                    nomeEstabelecimento: lancamento.nomeEstabelecimento,
                    quatroUltimosDigitos: cartaoRecuperado.quatroUltimosDigitos,
                    produtoPublicadoId: cartaoRecuperado.dadosConta.produtoPublicadoId,
                    pessoaTitularId: cartaoRecuperado.dadosConta.pessoaTitularId,
                    status: lancamento.statusPrestacaoConta,
                    statusExibicao: lancamento.statusPrestacaoContaExibicao,
                    dadosExtrato: new ExtratoViewModel({
                        dataHora: lancamento.dataHora,
                        moeda: lancamento.moeda,
                        nome: lancamento.nome,
                        tipoMovimentacao: lancamento.tipo,
                        valor: lancamento.valor
                    })
                }));
            });
        });
        prestacoesDeContasRecuperadas.quantidadeTotalRegistros = linhasDoExtratoRecuperadas
            .map(linhaExtrato => linhaExtrato.lancamentos.filter(l => l.statusPrestacaoConta === STATUS.PRESTACAO_DE_CONTAS.PENDENTE_PRESTACAO))
            .reduce((previousValue, currentValue) => previousValue + currentValue.length, 0);
    } else {
        if (queryParams.dataInicial) {
            queryParams.dataInicial = formatarDataUS(`${queryParams.dataInicial}`);
        }

        if (queryParams.dataFinal) {
            queryParams.dataFinal = formatarDataUS(`${queryParams.dataFinal}`);
        }
        const response = await fetchGetPrestacaoDeContas(pessoaTitularId, queryParams).catch(response => response);
        if (response.error) {
            return response;
        }
        const { data: { resultado, ultimaChave, quantidadeTotalRegistros, tamanhoPagina, totalElementos } } = response;
        resultado.forEach(res => {
            prestacoesDeContasRecuperadas.ultimaChave = ultimaChave;
            prestacoesDeContasRecuperadas.quantidadeTotalRegistros = quantidadeTotalRegistros;
            prestacoesDeContasRecuperadas.tamanhoPagina = tamanhoPagina;
            prestacoesDeContasRecuperadas.totalElementos = totalElementos;
            prestacoesDeContasRecuperadas.registros.push(new PrestacaoContaViewModel(res));
        });
    }

    return prestacoesDeContasRecuperadas;
};

const montaJsonPrestacaoParaBackEnd = (dadosDaPrestacaoDeContas) => {
    const { classificacaoSelecionada, contaSelecionada, descricaoSolicitacao, imagem, projetoSelecionado, linhaDoExtratoSelecionada } = dadosDaPrestacaoDeContas;

    const { dataHora, descricao, moeda, nome, tipo, transacaoMoeda, transacaoValor, valor } = linhaDoExtratoSelecionada;

    const contaId = contaSelecionada.id;

    let body = {
        contaId,
        classificacao: classificacaoSelecionada,
        descricaoSolicitacao: descricaoSolicitacao,
        projeto: projetoSelecionado,
        dadosExtrato: {
            dataHora: dataHora,
            nome: nome,
            descricao: descricao,
            moeda: moeda,
            valor: valor,
            transacaoMoeda: transacaoMoeda,
            transacaoValor: transacaoValor,
            tipoMovimentacao: tipo
        },
        imagem
    };
    return JSON.stringify({ ...body, ...retornaIdLinhaExtrato(linhaDoExtratoSelecionada) });
};

const enviaImagemDaPrestacaoDeContas = async (imagem) => {
    const respArquivo = await uploadFile(imagem);
    if (respArquivo && respArquivo.resp && respArquivo.resp.error) {
        return null;
    }
    return { nomeOriginal: imagem.name, imagem: respArquivo.imagem };
};

const retornaImagemPrestacaoDeContasMontadaParaBackEnd = (arquivo) => {
    const { imagem, nomeOriginal } = arquivo.file;
    return { imagem, nomeOriginal };
};

const retornaFiltroPrestacaoDeContas = (contaSelecionada, linhaDoExtratoSelecionada) => {
    const filtro = {};
    let contaPai = '';
    let extrato = retornaIdLinhaExtrato(linhaDoExtratoSelecionada);

    if (contaSelecionada.contaPaiId) {
        contaPai = contaSelecionada.contaPaiId;
    } else {
        contaPai = contaSelecionada.id;
    }

    filtro.contaPaiId = contaPai;

    if (extrato.extratoId) {
        filtro.extratoId = extrato.extratoId;
    }

    if (extrato.bppMovimentoProcessadoraId) {
        filtro.bppMovimentoProcessadoraId = extrato.bppMovimentoProcessadoraId;
    }

    return filtro;
};

const retornaDadosDeTelefoneEDeEmailParaAtualizacao = (dados) => {
    return { email: dados.email, telefone: dados.telefone };
};

const retornaIdLinhaExtrato = (linhaDoExtratoSelecionada) => {
    const { bppMovimentoProcessadoraId } = linhaDoExtratoSelecionada;
    if (bppMovimentoProcessadoraId) {
        return { bppMovimentoProcessadoraId };
    } else {
        const extratoId = linhaDoExtratoSelecionada.id;
        return { extratoId };
    }
};

const montaTelefone = (celular, telefoneAntigo) => {
    const numeroTelefone = removeMascaraTelefoneCpfCnpj(celular);
    const { ddi } = telefoneAntigo;
    const ddd = numeroTelefone.substr(0, 2);
    const numero = numeroTelefone.substr(2, numeroTelefone.length);
    return { ddi, ddd, numero };
};
