export const METODOS =
        {

            SET_ID_IMG_CARTAO: 'setIdImagemCartao',
            SET_ID_IMG_CARTAO_ERRO: 'setIdImagemCartaoErro',
            SET_ID_IMG_CARTA_BERCO: 'setIdImagemCartaBerco',
            SET_ID_IMG_CARTA_BERCO_ERRO: 'setIdImagemCartaBercoErro',
            URL_PRE_ASSINADA_CARTAO: 'setPreAssinadaCar',
            UPLOAD_IMAGEM_CARTAO: 'uploadImagemCartao',
            SALVAR: 'salvar',
            SALVAR_CARTAO: 'salvarCartao',
            SALVAR_CARTAO_ERRO: 'salvarCartaoErro',
            SALVAR_CARTA_BERCO: 'salvarCartaBerco',
            SALVAR_CARTA_BERCO_ERRO: 'salvarCartaoErro',
            ERRO: 'erro',
            URL_PRE_ASSINADA_CARTA_BERCO: 'urlPreAssinadaCartaBerco',
            UPLOAD_IMAGEM_CARTA_BERCO: 'uploadImagemCartaBerco',
            SET_REQUISICAO_APROVACAO_IMAGEM_CARTAO: 'setRequisicaoAprovacaoImagemCartao',
            SET_REQUISICAO_APROVACAO_IMAGEM_CARTA_BERCO: 'setRequisicaoAprovacaoImagemCartaBerco'
        };

export const OPERACOES = {
    PEDIDO_APROVACAO_IMAGEM_CARTAO: 'pedidoAprovacaoImagemPersonalizadaCartao',
    PEDIDO_APROVACAO_IMAGEM_CARTA_BERCO: 'pedidoAprovacaoImagemPersonalizadaCartaBerco'
};

export const PRODUTOS = {
    CARTAO: 'cartao',
    CARTA_BERCO: 'cartaBerco'
};
