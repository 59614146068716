import { HEADER_API, HTTP_METODOS, HEADER_WITHOUT_AUTORIZATION, montaUrlFetch, montaUrlFetchWebShim, urlServicos} from '../url-servicos';
import { FILTROS } from '../../componentes/util/contantes-comuns/constantes-comuns';

export const fetchGetCardById = cartaoId => {
    return fetch(montaUrlFetch(urlServicos.GET_CARTAO, { cartaoId }), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetCartoes = async (contaId, produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos, status) => {
    const queryParamsCartao = FILTROS.CARTOES(produtoId, IDCartao, nomePortador, numeroDocumentoPortador, cartaoQuatroUltimosDigitos, status);
    return await fetch(montaUrlFetch(urlServicos.CARTOES(contaId), queryParamsCartao), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchVinculaCartao = async (dadosVincular, contaId) => {
    return await fetch(montaUrlFetch(urlServicos.VINCLULAR_CARTAO(contaId)), {
        method: HTTP_METODOS.PUT,
        headers: HEADER_API().headers,
        body: dadosVincular
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchBuscaCartaoComOperacaoEmProcessamento = async cartaoId => {
    return await fetch(montaUrlFetch(urlServicos.OPERACAO, FILTROS.BUSCA_CARTOES_COM_OPERACAO_EM_PROCESSAMENTO(cartaoId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchAdicionaCartaoFavorito = async (body, contaId) => {
    return fetch(montaUrlFetch(urlServicos.CARTOES_FAVORITOS(contaId)), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: body
    })
        .then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }))
        .catch(async error => await error);
};

export const fetchRemoveCartaoFavorito = async (body, contaId) => {
    return fetch(montaUrlFetch(urlServicos.CARTOES_FAVORITOS(contaId)), {
        method: HTTP_METODOS.DELETE,
        headers: HEADER_API().headers,
        body: body
    })
        .then(async resp => resp.ok ? { code: resp.code } : Promise.reject({ error: !resp.ok, data: await resp.json() }))
        .catch(erro => { return 'error'; });
};

export const fetchGetCartoesFavoritos = (accountId, cardId, tamanhoPagina, ultimaPosicao) => {
    const queryParams = FILTROS.CARTOES_FAVORITOS(cardId, tamanhoPagina, ultimaPosicao);
    return fetch(montaUrlFetch(urlServicos.CARTOES_FAVORITOS(accountId), queryParams), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchCheckExistsCardUser = async paramsData => {
    return await fetch(montaUrlFetch(urlServicos.SECURITY_USERS, paramsData), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchCreateCardUser = async bodyData => {
    return await fetch(montaUrlFetch(urlServicos.SECURITY_CRETATE_BEARER), {
        method: HTTP_METODOS.POST,
        headers: HEADER_API().headers,
        body: JSON.stringify(bodyData)
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};

export const fetchGetSaldoContaECartao = async contaId => {
    return await fetch(montaUrlFetch(urlServicos.SALDO_CARTAO(contaId)), {
        method: HTTP_METODOS.GET,
        headers: HEADER_API().headers
    }).then(async resp => resp.ok ? { error: !resp.ok, data: await resp.json() } : Promise.reject({ error: !resp.ok, data: await resp.json() }));
};
