import Axios from 'axios';
import { HEADER_API, URL_ARQUIVOS_CARGAS_CSV, URL_ARQUIVOS_CARGAS, URL_ARQUIVOS_CARGAS_USUARIOS } from '../../servicos/url-servicos';
import { METODOS } from './consulta-carga-lote-cartao-constantes';

export default class ConsultaCargaLoteCartaoAction {
    listarAction(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_CARGA_LOTE,
                            payload: response.data,
                            carregando: true
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_FALHA_,
                            payload: err.response.data.erros[0].mensagem
                        }
                    );
                });
        };
    }

    buscarUsuarios() {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_USUARIOS(), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: METODOS.BUSCAR_USUARIOS_,
                            payload: response.data
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_FALHA_,
                            payload: err.data
                        }
                    );
                });
        };
    }

    changeNomeArquivo(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.MUDAR_NOME_ARQUIVO_,
                    payload: event.target.value
                }
            );
        };
    }

    changeDataDe(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.MUDAR_DATA_INICIO_,
                    payload: event.target.value
                }
            );
        };
    }

    changeDataAte(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.MUDAR_DATA_FIM_,
                    payload: event.target.value
                }
            );
        };
    }

    changeUsuario(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.MUDAR_USUARIO_,
                    payload: event.target.value
                }
            );
        };
    }

    limparCampos(event) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.LIMPAR_CAMPOS_,
                    payload: undefined
                }
            );
        };
    }

    setarBuscandoTrue(value) {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.LIMPAR_DADOS_CSV_,
                    payload: value
                }
            );
        };
    }

    buscarCargaLoteParaCSV(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId) {
        return function(dispatch) {
            Axios
                .get(URL_ARQUIVOS_CARGAS_CSV(nomeArquivo, dataInicio, dataFim, usuario, tipoCargaLote, contaId), HEADER_API())
                .then((response) => {
                    dispatch(
                        {
                            type: METODOS.BUSCAR_DADOS_PARA_CSV_,
                            payload: response.data,
                            carregando: false
                        }

                    );
                })
                .catch((err) => {
                    dispatch(
                        {
                            type: METODOS.LISTAR_FALHA_,
                            payload: err.response.data.erros[0].mensagem
                        }
                    );
                });
        };
    }

    limparDadosCSV() {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.LIMPAR_DADOS_CSV_,
                    payload: []
                }
            );
        };
    }

    setarExibirErroModal() {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.SETAR_MODAL_DADOS_NAO_ENCONTRADOS_
                }
            );
        };
    }

    setarOcultarErro() {
        return function(dispatch) {
            dispatch(
                {
                    type: METODOS.SETAR_OCULTAR_ERRO_
                }
            );
        };
    }
}
