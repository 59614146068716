import React, { Component } from 'react';
import ModalComponent from '../../componentes-uteis/modal-component/modal-component';
import { LABEL_COMUNS, OPERACAO } from '../../util/contantes-comuns/constantes-comuns';
import FooterBotoesModalComponent from '../../componentes-uteis/modal-component/footer-botoes-modal-component';
import Grid from '../../componentes-uteis/grid/grid';

class DetalhesLinhasArquivo extends Component {
    constructor(props) {
        super(props);
        this.INITIAL_STATE = { fileLines: props.fileLines, operation: props.operation, error: props.error };
        this.state = this.INITIAL_STATE;
    }

    componentDidMount = () => {
        this.setState({ fileLines: this.props.fileLines, operation: this.props.operation, error: this.props.error });
    }

    renderBodyModal = () => {
        const { fileLines, operation, error } = this.state;
        if (error) {
            return this.renderBodyError(error);
        }
        switch (operation) {
            case OPERACAO.NOME.CARGA_CARTAO:
                return this.renderBodyChargeCard(fileLines);
            case OPERACAO.NOME.TED:
                return this.renderBodyTED(fileLines);
            default:
                return this.renderBodyAccountTransfer(fileLines);
        }

    }

    renderBodyError(error) {
        return (
            <div>
                <h1 style={{ fontSize: '15px', fontWeight: 'bold', textAlign: 'center', color: 'red' }}>
                    {error.message}
                </h1>
            </div>
        );

    }



    renderBodyChargeCard = (fileLines) => {
        return (
            <div style={{display: 'flex', flexDirection: 'column', width: '100%', overflowX: 'hidden'}}>
                <div className="row" style={{
                    position: 'fixed',
                    width: '89vw',
                    backgroundColor: 'white',
                    zIndex: 1,
                    paddingBottom: '10px',
                    borderBottom: '1px solid #d2d2d2',
                    display: 'flex'
                }}>
                    <Grid cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.ID_CARTAO}
                        </span>
                    </Grid>
                    <Grid cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.FAVORECIDO}
                        </span>
                    </Grid>
                    <Grid cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.VALOR}
                        </span>
                    </Grid>
                    {/*    <Grid cols='1 1 1 1'>
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DESCRICAO}
                        </span>
                    </Grid> */}
                    <Grid cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DATA_PAGAMENTO}
                        </span>
                    </Grid>
                    <Grid cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DOCUMENTO_FAVORECIDO}
                        </span>
                    </Grid>
                    <Grid cols="3 3 3 3">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.OBSERVACAO}
                        </span>
                    </Grid>
                </div>
                <div style={{ height: '30px', width: '88vw' }}></div>
                {fileLines.map((fileLine, idx) => {

                    return (
                        <div className="row" key={idx}>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.cartaoId}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.nomeFavorecido}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.valor}
                                </span>
                            </Grid>
                            {/*   <Grid cols='1 1 1 1' customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'auto', fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.operacao}
                                </span>
                            </Grid> */}
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.dataPagamento}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.documentoFavorecido}
                                </span>
                            </Grid>
                            <Grid cols="3 3 3 3" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.observacao}
                                </span>
                            </Grid>
                        </div>
                    );

                })}
            </div >
        );
    }

    renderBodyTED = (fileLines) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowX: 'hidden' }}>
                <div className="row" style={{
                    position: 'fixed',
                    width: '89vw',
                    backgroundColor: 'white',
                    zIndex: 1,
                    paddingBottom: '10px',
                    borderBottom: '1px solid #d2d2d2',
                    display: 'flex'
                }}>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                            {LABEL_COMUNS.BANCO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                            {LABEL_COMUNS.AGENCIA}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.CONTA}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.FAVORECIDO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.VALOR}
                        </span>
                    </Grid>

                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DATA_PAGAMENTO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DOCUMENTO_FAVORECIDO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.TIPO_CONTA}
                        </span>
                    </Grid>
                    <Grid customStyle={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.OBSERVACAO}
                        </span>
                    </Grid>
                </div>
                <div style={{ height: '30px', width: '88vw' }}></div>
                {fileLines.map((fileLine, idx) => {

                    return (
                        <div className="row" key={idx}>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                                    {fileLine.banco}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                                    {fileLine.agencia}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'auto', fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.conta}-{fileLine.digito}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.nomeFavorecido}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.valor}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.dataPagamento}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.documentoFavorecido}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px',whiteSpace: 'nowrap', overflow: 'auto' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.tipoConta}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.observacao}
                                </span>
                            </Grid>
                        </div>
                    );

                })}
            </div >
        );
    }

    renderBodyAccountTransfer = (fileLines) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', overflowX: 'hidden' }}>
                <div className="row" style={{
                    backgroundColor: 'white',
                    display: 'flex',
                    width: '89vw'
                }}>
                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px', whiteSpace: 'nowrap', overflow: 'auto' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                            {LABEL_COMUNS.AGENCIA}
                        </span>
                    </Grid>
                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px', whiteSpace: 'nowrap', overflow: 'auto' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.CONTA}
                        </span>
                    </Grid>
                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px', whiteSpace: 'nowrap', overflow: 'auto' }} cols="3 3 3 3">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.FAVORECIDO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px', whiteSpace: 'nowrap', overflow: 'auto' }} cols="1 1 1 1">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.VALOR}
                        </span>
                    </Grid>

                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px', whiteSpace: 'nowrap', overflow: 'auto' }} cols="2 2 2 2">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.DATA_PAGAMENTO}
                        </span>
                    </Grid>
                    <Grid customStyle={{ border: '1px solid #d2d2d2', minHeight: '25px' }} cols="4 4 4 4">
                        <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                            {LABEL_COMUNS.OBSERVACAO}
                        </span>
                    </Grid>
                </div>

                {fileLines.map((fileLine, idx) => {

                    return (
                        <div className="row" key={idx}>
                            <Grid cols="1 1 1 1" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'center' }}>
                                    {fileLine.agencia}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'auto', fontSize: '10px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.conta}-{fileLine.digito}
                                </span>
                            </Grid>
                            <Grid cols="3 3 3 3" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.nomeFavorecido}
                                </span>
                            </Grid>
                            <Grid cols="1 1 1 1" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.valor}
                                </span>
                            </Grid>
                            <Grid cols="2 2 2 2" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.dataPagamento}
                                </span>
                            </Grid>
                            <Grid cols="4 4 4 4" customStyle={{ whiteSpace: 'nowrap', overflow: 'auto',border: '1px solid #d2d2d2', minHeight: '25px' }}>
                                <span style={{ fontSize: '11px', fontWeight: 'bold', textAlign: 'left' }}>
                                    {fileLine.observacao}
                                </span>
                            </Grid>
                        </div>
                    );

                })}
            </div >
        );
    }


    renderFooterModal = () => {
        const { contextoBppPag } = this.props;
        return (
            <FooterBotoesModalComponent
                contextoBppPag={contextoBppPag}
                btn2Label={LABEL_COMUNS.OK}
                btn2OnClick={() => { this.props.setShowModalDetailsFile(false); }}
            />
        );
    }

    render() {
        const { fileLines } = this.state;
        const { contextoBppPag, header, showModal } = this.props;
        return (
            <ModalComponent
                contextoBppPag={contextoBppPag}
                name="modal-file-details"
                exibirModal={showModal}
                header={header}
                body={this.renderBodyModal()}
                footer={this.renderFooterModal()}
                dialogClassName="dialog-content-linhas-arquivo"
                modalBodyClass="modal-body dialog-content-body-linhas-arquivo"
            />
        );
    }
}

export default DetalhesLinhasArquivo;
